import React from "react";

const CheckboxBaseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#1E90FF" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#1E90FF" />
    <path
      d="M14.6673 6.5L8.25065 12.9167L5.33398 10"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckboxBaseIcon;
