import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { colors } from "features/app/utils/constants/colors";
import ClockIcon from "features/payment/assets/icons/ClockIcon";
import XCloseIcon from "features/warehouse/assets/icons/XCloseIcon";
import BuyingIcon from "features/app/assets/icons/BuyingIcon";

export const partyProcessData: {
  [p: string]: {
    [k: string | number]: {
      text: string;
      icon?: JSX.Element | null;
      process_number: number;
      bg_color: string;
      icon_container_bg_color: string;
    };
  };
} = {
  "Ta'minot": {
    [StatusEnums.ORDERED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      process_number: 1,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.RECIEVED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      process_number: 1,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.PARTIALLY_RECIEVED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      process_number: 1,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.PENDING]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Partiya qilingan",
      process_number: 1,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.REJECTED]: {
      icon: <XCloseIcon color={colors.WHITE} width={16} />,
      text: "Bekor qilingan",
      process_number: 1,
      bg_color: colors.ERROR_100,
      icon_container_bg_color: colors.ERROR_500
    },
    [StatusEnums.OPEN]: {
      icon: null,
      text: "Kutilmoqda",
      process_number: 1,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.BUYING]: {
      icon: null,
      text: "Sotib olinmoqda",
      process_number: 1,
      bg_color: colors.WARNING_100,
      icon_container_bg_color: colors.WARNING_500
    }
  },
  Monitoring: {
    [StatusEnums.PENDING]: {
      icon: null,
      text: "Kutilmoqda",
      process_number: 2,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.RECIEVED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      process_number: 2,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.ORDERED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      process_number: 2,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.PARTIALLY_RECIEVED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Tasdiqlangan",
      process_number: 2,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.REJECTED]: {
      icon: <XCloseIcon color={colors.WHITE} width={16} />,
      text: "Bekor qilingan",
      process_number: 2,
      bg_color: colors.ERROR_100,
      icon_container_bg_color: colors.ERROR_500
    },

    [StatusEnums.OPEN]: {
      icon: null,
      text: "Kutilmoqda",
      process_number: 2,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.BUYING]: {
      icon: null,
      text: "Sotib olinmoqda",
      process_number: 2,
      bg_color: colors.WARNING_100,
      icon_container_bg_color: colors.WARNING_500
    }
  },
  "Oxirgi_Ta'minot": {
    [StatusEnums.ORDERED]: {
      icon: null,
      text: "Yopish",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.RECIEVED]: {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      text: "Yopilgan",
      process_number: 5,
      bg_color: colors.SUCCESS_100,
      icon_container_bg_color: colors.SUCCESS_500
    },
    [StatusEnums.PARTIALLY_RECIEVED]: {
      icon: null,
      text: "Yopish",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.PENDING]: {
      icon: null,
      text: "Yopish",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.REJECTED]: {
      icon: null,
      text: "Yopish",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },

    [StatusEnums.OPEN]: {
      icon: null,
      text: "Kutilmoqda",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    },
    [StatusEnums.BUYING]: {
      icon: null,
      text: "Yopish",
      process_number: 5,
      bg_color: colors.GRAY_100,
      icon_container_bg_color: colors.GRAY_600
    }
  }
};
