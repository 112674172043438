import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Dropdown, Form, Switch } from "antd";

import { PaymentContext } from "../../../hooks/PaymentContext";

import styles from "./settingDropdown.module.scss";

const { Item } = Form;

interface IProps {
  children: React.ReactNode;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const SettingDropdown: FC<IProps> = ({ children, open, setOpen }) => {
  const [form] = Form.useForm();
  const { settingTabs, setContext } = useContext(PaymentContext);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (setOpen) {
      setOpen(Boolean(visible));
    }
  }, [visible]);

  useEffect(() => {
    if (open !== undefined) {
      setVisible(open);
    }
  }, [open]);

  const handleClose = (values?: unknown, open?: boolean) => {
    setVisible(open || false);
    form.resetFields();
    form.setFieldsValue({
      ...(values || settingTabs)
    });
  };

  const onFinish = (values: Record<string, boolean | undefined>) => {
    setContext({ settingTabs: values });
    handleClose(values);
  };

  return (
    <Dropdown
      trigger={["click"]}
      placement="bottom"
      open={visible}
      onOpenChange={open => {
        handleClose(false, open);
      }}
      mouseLeaveDelay={0.5}
      mouseEnterDelay={1000}
      dropdownRender={() => (
        <div className={styles.setting}>
          <Form
            form={form}
            onFinish={onFinish}
            initialValues={{ ...settingTabs }}
            onFieldsChange={(changedFields, allFields) => {
              const allFieldsFilter = allFields?.filter(item => item?.value);

              if (allFieldsFilter.length === 0) {
                form.setFieldValue(changedFields[0]?.name[0], true);
              }
            }}
          >
            <Item className={styles.item} label="Kirim - Chiqim" name="income_or_expense" valuePropName="checked">
              <Switch />
            </Item>
            <Item className={styles.item} label="Kirim Shartnoma" name="income" valuePropName="checked">
              <Switch />
            </Item>
            <Item className={styles.item} label="Chiqim shartnoma" name="expense" valuePropName="checked">
              <Switch />
            </Item>
            <Item className={styles.item} label="Partiyalar" name="order" valuePropName="checked">
              <Switch />
            </Item>
            <Item className={styles.item} label="Ishlar" name="work" valuePropName="checked">
              <Switch />
            </Item>
            <Item className={styles.item} label="To'lov kalendari" name="calendar" valuePropName="checked">
              <Switch />
            </Item>
            <div className={styles.footer}>
              <Button onClick={handleClose}>Yopish</Button>
              <Button type="primary" htmlType="submit">
                Saqlash
              </Button>
            </div>
          </Form>
        </div>
      )}
    >
      {children}
    </Dropdown>
  );
};

export default SettingDropdown;
