import React from "react";
import { Drawer as AntDrawer } from "antd";

import { useQueryParams } from "hooks/useQueryParams";

import { useWidget } from "modules/dashboard";

import DrawerActions from "./actions/drawer-actions";
import WarehouseAmount from "./content/warehouse-amount/warehouse-amount";
import {
  ActiveCompanyPerson,
  CashStatus,
  CompanyPersonBalance,
  ConfirmationPaymentCount,
  ExpensePayment,
  ExpensePaymentByMonth,
  ProjectActivity,
  ProjectByResource,
  ProjectBySection,
  ProjectPerAreaPrice,
  WarehouseDefect} from "./content";

import styles from "./drawer.module.scss";

const Drawer: React.FC = () => {
  const { id, visible, title, key, methods } = useWidget();
  const { setWidgetDrawer } = methods;
  const { clearQuery } = useQueryParams();

  const content: Record<string, React.ReactNode> = {
    cash_status: <CashStatus />,
    expense_payment_by_month: <ExpensePaymentByMonth />,
    expense_payment: <ExpensePayment />,
    confirmation_payment_count: <ConfirmationPaymentCount />,
    warehouse_defect: <WarehouseDefect />,
    company_person_balance: <CompanyPersonBalance />,
    project_activity: <ProjectActivity />,
    project_by_section: <ProjectBySection isCustomField={false} />,
    project_by_custom_field: <ProjectBySection isCustomField={true} />,
    project_per_area_price: <ProjectPerAreaPrice />,
    active_company_person: <ActiveCompanyPerson />,
    project_sale_price: <ProjectPerAreaPrice />,
    warehouse_amount: <WarehouseAmount />,
    project_by_resource:<ProjectByResource/>
  };

  const onClose = () => {
    clearQuery();

    setWidgetDrawer({
      id,
      key,
      title,
      visible: false,
      props: null
    });
  };

  return (
    <AntDrawer
      open={visible}
      title={
        <div className="flex items-center justify-between">
          {title}
          <div className="mr-4">
            <DrawerActions />
          </div>
        </div>
      }
      footer={false}
      onClose={onClose}
      width="max-content"
      rootClassName={styles.drawer}
    >
      {content?.[key] || <></>}
    </AntDrawer>
  );
};

export default Drawer;
