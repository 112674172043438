const MarkIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1992 12C20.1992 16.4183 16.6175 20 12.1992 20M20.1992 12C20.1992 7.58172 16.6175 4 12.1992 4M20.1992 12H22.1992M12.1992 20C7.78094 20 4.19922 16.4183 4.19922 12M12.1992 20V22M4.19922 12C4.19922 7.58172 7.78094 4 12.1992 4M4.19922 12H2.19922M12.1992 4V2M15.1992 12C15.1992 13.6569 13.8561 15 12.1992 15C10.5424 15 9.19922 13.6569 9.19922 12C9.19922 10.3431 10.5424 9 12.1992 9C13.8561 9 15.1992 10.3431 15.1992 12Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MarkIcon;
