import React from "react";

import Section from "../items/section";

import Header from "./header";

type Props = {
  isCustomField: boolean;
};

const Wrapper: React.FC<Props> = ({ isCustomField }) => (
  <div className="flex flex-col transition-all ease-in-out">
    <Header />
    {isCustomField ? (
      <div className="p-4">
        <Section isCustomField />
      </div>
    ) : (
      <Section isCustomField={false} />
    )}
  </div>
);

export default Wrapper;
