import { Checkbox, Form, FormInstance } from "antd";
import classNames from "classnames";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../../../app/utils/models/customFIeldValueModel";

import LabelCustomFieldView from "./label-custom-field/LabelCustomFieldView";
import { EstimateCustomFieldsContent } from "./EstimateCustomFieldsContent";

interface IProps {
  values?: CustomFieldValueModel[];
  customFields?: CustomFieldSelectModel[];
  locations: CustomFieldLocationEnum;
  name?: string;
  onBlur?: () => void;
  className?: string;
  form?: FormInstance;
  isDynamicEstimate?: boolean;
}

const EstimateCustomField = ({
  form,
  values,
  onBlur,
  locations,
  className,
  customFields,
  isDynamicEstimate = false,
  name = "custom_field_values"
}: IProps) => {
  const { t } = useTranslation();

  const itemContent = (field: CustomFieldSelectModel, index: number) => {
    const type = EstimateCustomFieldsContent[field?.type];

    const defaultProps = {
      onBlur,
      field,
      form,
      name: [name, index],
      options: field?.custom_field_options,
      fileName: values?.find(custom => field?.id === custom?.custom_field?.id)?.file?.original_name
    };

    if (field?.type === "checkbox") {
      return type!({
        ...defaultProps,
        onChange: value => {
          if (value) {
            form?.setFieldValue([name, index], {
              custom_field_id: field?.id,
              value: field?.id
            });
          } else {
            form?.setFieldValue([name, index], {
              custom_field_id: field?.id,
              value: undefined
            });
          }
        }
      });
    }

    if (field?.type === "label") {
      return (
        <LabelCustomFieldView
          {...defaultProps}
          placeholder={t("app.Tanlang")}
          labelValue={values?.filter(item => item?.custom_field?.type === "label")?.map(item => Number(item?.value))}
        />
      );
    }

    return (
      type &&
      type({
        ...defaultProps
      })
    );
  };

  const dynamicEstimateItem = (field: CustomFieldSelectModel) => {
    const findCustomFields = values?.find(item => item?.custom_field?.id === field?.id);

    const type = findCustomFields?.custom_field?.type;

    if (type === "checkbox") {
      return (
        <div className="flex justify-end pr-4">
          <Checkbox checked={true} />
        </div>
      );
    }

    if (type === "label") {
      const labels = values?.filter(item => item?.custom_field?.id === field?.id);

      return (
        <div className="flex justify-end pr-4">
          {labels?.map((item, index) => `${index ? " , " : ""}${item?.custom_field_option?.name?.[i18n.language]}`)}
        </div>
      );
    }

    return (
      <div className="flex justify-end pr-4">
        {findCustomFields?.custom_field_option?.name?.[i18n.language] || findCustomFields?.value}
      </div>
    );
    // });
  };

  return (
    <>
      {customFields?.map(
        (field, index) => (
          // field?.custom_field_locations?.includes(locations) ? (
          <ConditionalRender key={field?.id} if={!isDynamicEstimate} else={dynamicEstimateItem(field)}>
            <Form.Item
              key={field?.id}
              name={[name, index]}
              getValueProps={value => value}
              className={classNames(className, { tag: field?.type === "label" })}
              getValueFromEvent={value => ({
                value: value ?? "",
                custom_field_id: field?.id
              })}
            >
              {itemContent(field, index)}
            </Form.Item>
          </ConditionalRender>
        )
        // ) : (
        //   <div key={field?.id} />
        // )
      )}
    </>
  );
};

export default EstimateCustomField;
