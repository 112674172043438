import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const OrderPartiallyRecieved = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/supply?tab=parties", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.order?.warehouse?.name} sizning{" "}
        <span className={styles.name}>P-{record?.order?.id}</span> raqamli
        partyanigizda kamchilik aniqladi.
      </p>
    </div>
  );
};

export default OrderPartiallyRecieved;
