import React from "react";

const FullScreenCloseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={props?.width || "20"}
    height={props?.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 2.5L12.5 7.5M12.5 7.5H17.5M12.5 7.5V2.5M2.5 2.5L7.5 7.5M7.5 7.5V2.5M7.5 7.5L2.5 7.5M2.5 17.5L7.5 12.5M7.5 12.5H2.5M7.5 12.5L7.5 17.5M17.5 17.5L12.5 12.5M12.5 12.5V17.5M12.5 12.5H17.5"
      stroke={props?.stroke || "#344054"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FullScreenCloseIcon;
