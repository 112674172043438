import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../hooks/redux";
import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { rootPaths } from "../../../../../routes/root/rootPaths";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import ArrowNarrowIcon from "../../../../app/assets/icons/ArrowNarrowIcon";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";

import FilterDrawerInventories from "./filter-drawer/FilterDrawerInventories";

import styles from "./top.module.scss";
import { useExportInventoryExcel } from "features/inventory/service/mutations";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

const Top = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { reqQueryParam } = useQueryParams();
  const { id } = parseParamsId(params?.id);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { actions } = useRoutePermissions("Inventar", routeSubmodules);
  const exportExcelMutate = useExportInventoryExcel();

  const inventoryActions = actions("Inventarlar");
  const { setInventoryModal } = inventoryReducerActions;

  const onCreateInventory = () => {
    dispatch(setInventoryModal({ visible: true }));
  };

  const toBack = () => {
    navigate({
      pathname: rootPaths.INVENTORY,
      search: reqQueryParam(queryParamsKeys.SEARCH, queryParamsKeys.COMPANY_PERSON_IDS_ARR, queryParamsKeys.WORK_STATUS)
    });
  };

  return (
    <div className={styles.top}>
      {id && (
        <div className={styles.top__left}>
          <Button className={styles.back} onClick={toBack}>
            <ArrowNarrowIcon />
          </Button>
        </div>
      )}
      {!id && <p />}
      <div className={styles.actions}>
        <div className={styles.actions__item}>
          {inventoryActions.create && (
            <Button className={styles.inventory_create} onClick={onCreateInventory}>
              <PlusIcon /> {t("Inventar.Inventar qo’shish")}
            </Button>
          )}
        </div>
        <div className={styles.actions__line} />
        <div className={styles.actions__item}>
          <div className={styles.search}>
            <PaymentSearch />
          </div>
          <FilterDrawerInventories
            exportExcelMutate={exportExcelMutate}
            customFieldEnum={CustomFieldLocationEnum.INVENTORY}
            tableKey={tableConfigKeys.INVENTORY}
          />
        </div>
      </div>
    </div>
  );
};

export default Top;
