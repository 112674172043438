import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Dropdown, Form, Input, Popover, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../hooks/redux";
import { dayjsFormats, isEmptyArr } from "../../../../../../modules/common";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../../app/components/not-image/NotUserImage";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { colors } from "../../../../../app/utils/constants/colors";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { cx } from "../../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import CalculateIcon from "../../../../../supply/assets/icons/CalculateIcon";
import CheckCircleIcon from "../../../../../supply/assets/icons/CheckCircleIcon";
import PhoneCallIcon from "../../../../../supply/assets/icons/PhoneCallIcon";
import OfferHistory from "../../../../../supply/components/offers/offer-history/OfferHistory";
import OfferModal from "../../../../../supply/components/offers/offer-modal/OfferModal";
import {
  useDeleteOrders,
  useOfferProductRecieved,
  useOfferProductRejected,
  useSupplyProductUpdate
} from "../../../../../supply/service/mutations";
import { StatusEnums } from "../../../../../supply/utils/enums/statusEnums";
import { ProductOfferModel } from "../../../../../supply/utils/models/OfferModel";
import XCircleIcon from "../../../../../warehouse/assets/icons/XCircleIcon";
import { paymentQueryKeys } from "../../../../utils/constants/paymentQueryKeys";
import { WarehouseProductModel } from "../../../../utils/models/paymentOrderDetailModel";
import { PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";

import styles from "./orderViewBottom.module.scss";

interface IProps {
  status: string;
  isLoading: boolean;
  isCompanyPerson: boolean;
  order?: PaymentOrderModel;
  data: WarehouseProductModel[] | undefined;
}

interface IUpdate {
  isUpdate: boolean;
  productId: number;
}

interface IProductForm {
  id: number;
  total: string;
  amount: string;
  quantity: string;
  productId: number;
  currencyId: number;
  warehouseId: number;
  projectId: number;
}

const { Item } = Form;

const OrderViewBottomTableGroup = ({ data, isLoading, isCompanyPerson, status, order }: IProps) => {
  const qc = useQueryClient();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const deleteProduct = useDeleteOrders();
  const { setOfferModal } = supplyOfferActions;
  const productUpdate = useSupplyProductUpdate();
  const { setOrderView } = paymentReducerActions;
  const acceptedOffer = useOfferProductRecieved();
  const rejectedOffer = useOfferProductRejected();
  const { orderId } = useAppSelector(state => state.paymentReducer.orderView);
  const [updateProduct, setUpdateProduct] = useState<IUpdate>({
    isUpdate: false,
    productId: 0
  });

  const isViewOffers = useMemo(
    () => data?.filter(item => isEmptyArr(item?.warehouse_product_offers))?.length === 0,
    [data]
  );

  const onDeleteProduct = (id: number) => () => deleteProduct.mutateAsync([id]);

  const onUpdateProduct = (productId: IUpdate["productId"]) => () => {
    setUpdateProduct({
      isUpdate: true,
      productId
    });
  };

  const onOpenOffer = (record: WarehouseProductModel) => () => {
    dispatch(
      setOfferModal({
        isEdit: !isEmptyArr(record.warehouse_product_offers),
        visible: true,
        viewPartySwitch: false,
        data: [
          {
            offers: record?.warehouse_product_offers?.map((item, index) => ({
              id: item?.id,
              uniqueId: uid(12),
              phone: item?.phone,
              ordinalNumber: index + 1,
              description: item?.description,
              currency_id: item?.currency?.id,
              company_person_id: item?.company_person?.id,
              amount: localeFormatter(String(item?.amount)),
              name: item.company_person ? item.company_person?.name : item.name
            })),
            amount: parseLocaledString(String(record?.amount)),
            quantity: parseLocaledString(String(record?.quantity)),
            delivery_date: order?.delivery_date
              ? dayjs(order.delivery_date, dayjsFormats.DATE).format(dayjsFormats.DATE)
              : "",
            product: record.product,
            id: record.id,
            unit: record.unit
          }
        ]
      })
    );
  };

  const menuItems = (record: WarehouseProductModel) => {
    const allItems = [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <CalculateIcon />
            Taklif berish
          </div>
        ),
        view: isEmptyArr(record?.warehouse_product_offers) && status !== StatusEnums.RECIEVED,
        onClick: onOpenOffer(record)
      },
      {
        key: "2",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            Taklifni tahrirlash
          </div>
        ),
        view: !isEmptyArr(record?.warehouse_product_offers) && status !== StatusEnums.RECIEVED,
        onClick: onOpenOffer(record)
      },
      {
        key: "3",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            Buyurtmani yangilash
          </div>
        ),
        onClick: onUpdateProduct(record?.id),
        view: status !== StatusEnums.RECIEVED
      },
      {
        key: "4",
        label: (
          <ModalConfirm title="Buyurtmani o'chirmoqchimisiz ?" onOk={onDeleteProduct(record.id)}>
            <div className="flex items-center gap-2">
              <DeleteIcon />
              Buyurtmani o'chirish
            </div>
          </ModalConfirm>
        ),
        view: status !== StatusEnums.RECIEVED && !record?.recieved_quantity
      }
    ];

    return allItems?.filter(item => item.view);
  };

  const onProduct = () => {
    form.submit();
  };

  const onChangeQuantity = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(value);
    const parseAmount = parseLocaledString(form.getFieldValue("amount"));

    form.setFieldsValue({
      quantity: localeFormatter(value),
      total: localeFormatter(String(parseValue * parseAmount))
    });
  };

  const onChangeAmount = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(value);
    const parseQuantity = parseLocaledString(form.getFieldValue("quantity"));

    form.setFieldsValue({
      amount: localeFormatter(value),
      total: localeFormatter(String(parseValue * parseQuantity))
    });
  };

  const onChangeTotal = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(value);
    const parseQuantity = parseLocaledString(form.getFieldValue("quantity"));

    form.setFieldsValue({
      total: localeFormatter(value),
      amount: localeFormatter(String(parseValue / parseQuantity))
    });
  };

  const isUpdate = (id: number) => updateProduct.isUpdate && updateProduct.productId === id;

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: colors.SUCCESS_500,
    [StatusEnums.REJECTED]: colors.ERROR_500
  };

  const notUserBg = (products: ProductOfferModel[]) => {
    const sliceProducts = products?.slice(2, products?.length);

    const isReceived = sliceProducts?.some(item => item.status === StatusEnums.RECIEVED);

    if (isReceived) return colors.SUCCESS_500;

    return "";
  };

  const onAfterCheck = () => {
    qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, isCompanyPerson], { type: "active" }).then();
  };

  const onCheckProduct = (item: ProductOfferModel, status: StatusEnums) => () => {
    if (status === StatusEnums.RECIEVED) {
      acceptedOffer.mutateAsync(item.id).then(onAfterCheck);
    }
  };

  const checkButtonClass = (item: ProductOfferModel, status: StatusEnums) => {
    if (item.status === status) {
      return styles[status];
    }
    if (item.status === StatusEnums.OPEN) {
      return styles[StatusEnums.OPEN];
    }
    return `${styles[StatusEnums.OPEN]}`;
  };

  const productsItems = (products: ProductOfferModel[]) => (
    <div className={styles.products__content}>
      {products?.map(item => (
        <div className={styles.products__label} key={item?.id}>
          <div className={styles.products__top}>
            <div className={styles.products__label__left}>
              <NotUserImage
                key={item?.id}
                name={item?.name ? item?.name : item?.company_person?.name}
                background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
              />
              <div className={styles.products__name}>
                <h5>{item?.name ? item?.name : item?.company_person?.name}</h5>
                <div className="flex items-center gap-1">
                  <p>
                    {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                  </p>
                  {item?.vat && (
                    <span className="flex w-5 items-center justify-center rounded-lg border border-solid border-primary-500 bg-primary-500 p-0.5 text-[0.5rem] font-medium text-white">
                      NDS
                    </span>
                  )}
                </div>
              </div>
            </div>
            <ConditionalRender if={status !== StatusEnums.RECIEVED}>
              <div className={styles.products__label__right}>
                <ModalConfirm
                  okText="Rad qilish"
                  cancelText="Yopish"
                  isDescription={true}
                  mutation={rejectedOffer}
                  payload={{ id: item.id }}
                  title="Taklifni rad qilish"
                  confirmStatus={item.status}
                  onAfterClose={onAfterCheck}
                  requestKey="rejected_description"
                  description="Nega taklifni rad etmoqdasiz sababini yozing."
                >
                  <Button className={classNames(styles.rejected__hover, checkButtonClass(item, StatusEnums.REJECTED))}>
                    <XCircleIcon />
                  </Button>
                </ModalConfirm>
                <Button
                  loading={acceptedOffer.isLoading}
                  onClick={onCheckProduct(item, StatusEnums.RECIEVED)}
                  className={classNames(styles.recieved__hover, checkButtonClass(item, StatusEnums.RECIEVED))}
                >
                  <CheckCircleIcon />
                </Button>
              </div>
            </ConditionalRender>
          </div>
          {(item?.phone || item?.description) && (
            <div className={styles.products__bottom}>
              {item?.phone && (
                <div className={styles.products__bottom__item}>
                  <PhoneCallIcon />
                  <span>{item?.phone}</span>
                </div>
              )}
              {item?.description && (
                <div className={styles.products__bottom__item}>
                  <XCircleIcon />
                  <span>{item?.description}</span>
                </div>
              )}
              {item?.rejected_description && (
                <div className={styles.products__bottom__item}>
                  <XCircleIcon />
                  <span>{item?.rejected_description}</span>
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: WarehouseProductModel) => (
        <div className={cx("resource")}>
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource?.symbol[i18n.language]}
          </div>
          <span>{record?.product?.name[i18n.language]}</span>
        </div>
      )
    },
    {
      title: "Miqdori",
      render: (record: WarehouseProductModel) =>
        isUpdate(record.id) ? (
          <Item
            name="quantity"
            className="m-0"
            rules={formRules()}
            rootClassName={styles.product_form}
            initialValue={record?.quantity.toLocaleString(RU)}
          >
            <Input placeholder="Miqdori" onChange={onChangeQuantity} />
          </Item>
        ) : (
          record?.quantity.toLocaleString(RU)
        )
    },
    {
      title: "Qabul qilindi",
      render: (record: WarehouseProductModel) => record?.recieved_quantity?.toLocaleString(RU)
    },
    {
      title: "Birligi",
      render: (record: WarehouseProductModel) => record?.unit?.symbol[i18n.language]
    },
    {
      title: "Birlik summasi",
      render: (record: WarehouseProductModel) =>
        isUpdate(record.id) ? (
          <Item name="amount" className="m-0" rules={formRules()} initialValue={record?.amount.toLocaleString(RU)}>
            <Input placeholder="Summa" onChange={onChangeAmount} />
          </Item>
        ) : (
          record?.amount.toLocaleString(RU)
        )
    },
    {
      title: "Umumiy summa",
      render: (record: WarehouseProductModel) =>
        isUpdate(record.id) ? (
          <Item
            name="total"
            className="m-0"
            rules={formRules()}
            initialValue={(record?.amount * record.expected_quantity).toLocaleString(RU)}
          >
            <Input placeholder="Umumiy summa" onChange={onChangeTotal} />
          </Item>
        ) : (
          <span className={styles.bold}>{(record?.amount * record.expected_quantity).toLocaleString(RU)}</span>
        )
    },
    isViewOffers
      ? {
          title: "Takliflar",
          render: (record: WarehouseProductModel) => (
            <ConditionalRender if={!isEmptyArr(record?.warehouse_product_offers)}>
              <Popover placement="top" trigger={["hover"]} content={productsItems(record.warehouse_product_offers)}>
                <div className={styles.products}>
                  {record?.warehouse_product_offers?.slice(0, 2)?.map(item => (
                    <div className={styles.products__item} key={item.id}>
                      <NotUserImage
                        key={item?.id}
                        name={item?.name ? item?.name : item?.company_person?.name}
                        background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
                      />
                    </div>
                  ))}
                  {record?.warehouse_product_offers?.length > 2 ? (
                    <div className={styles.products__item}>
                      <NotUserImage
                        background={notUserBg(record.warehouse_product_offers)}
                        title={`+${record?.warehouse_product_offers?.length - 2}`}
                      />
                    </div>
                  ) : null}
                </div>
              </Popover>
            </ConditionalRender>
          )
        }
      : {},
    {
      title: "",
      render: record => (
        <ConditionalRender if={!isEmptyArr(menuItems(record))}>
          {isUpdate(record.id) ? (
            <Spin indicator={LoadingIndicator} spinning={productUpdate.isLoading}>
              <div
                onClick={onProduct}
                className="box-border flex cursor-pointer items-center justify-center rounded-lg border border-solid border-primary-600 bg-primary-500 p-1.5"
              >
                <CheckCircleIcon color={colors.WHITE} />
              </div>
              <Item className="hidden" name="id" initialValue={record.id} />
              <Item className="hidden" name="productId" initialValue={record.product?.id} />
              <Item className="hidden" name="projectId" initialValue={record.project?.id} />
              <Item className="hidden" name="warehouseId" initialValue={record.warehouse?.id} />
              <Item className="hidden" name="currencyId" initialValue={record?.currency?.id} />
            </Spin>
          ) : (
            <Dropdown
              trigger={["click"]}
              menu={{
                items: menuItems(record) as never
              }}
            >
              <div className="flex cursor-pointer items-center justify-end">
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          )}
        </ConditionalRender>
      )
    }
  ];

  const onFinish = (values: IProductForm) => {
    productUpdate
      .mutateAsync({
        id: values?.id,
        product_id: values?.productId,
        currency_id: values?.currencyId,
        warehouse_id: values?.warehouseId,
        project_id: values?.projectId,
        quantity: parseLocaledString(values?.quantity || "0"),
        amount: parseLocaledString(values?.amount || "0")
      })
      .then(() => {
        setUpdateProduct({
          isUpdate: false,
          productId: 0
        });

        dispatch(
          setOrderView({
            visible: false,
            orderId: 0
          })
        );

        // qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, isCompanyPerson], {
        //   type: "active"
        // }).then();
      });
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Table<WarehouseProductModel>
        columns={columns}
        dataSource={data}
        pagination={false}
        className={styles.table}
        rowKey={record => record.id}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <OfferModal zIndex={11114} invalidateKey="payment" />
      <OfferHistory zIndex={11115} />
    </Form>
  );
};

export default OrderViewBottomTableGroup;
