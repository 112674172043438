import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { WorkModel } from "../../features/projects/utils/models/workModel";

const initialState: {
  workModalData: {
    visible: boolean,
    data?: WorkModel
  }
} = {
  workModalData: {
    visible: false,
    data: undefined,
  },
};

const workModalSlice = createSlice({
  name: sliceNames.WORK_MODAL,
  initialState,
  reducers: {
    setVisibleModal: (state, action: PayloadAction<boolean>) => {
      state.workModalData.visible = action.payload;
    },
    setModalData: (state, action: PayloadAction<WorkModel>) => {
      state.workModalData.data = action.payload;
    },
  },
});

export default workModalSlice.reducer;
export const workModalActions = workModalSlice.actions;