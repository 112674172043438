import BackwardIcon from "../../../../assets/icons/BackwardIcon";
import { useDeleteDefect } from "../../../../service/mutations";

import styles from "./deleteDefect.module.scss";

type Props = {
  id: number;
};

const DeleteDefect = ({ id }: Props) => {
  const deleteDefect = useDeleteDefect();

  const onDeleteDefect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    deleteDefect.mutate({ object_id: id });
  };

  return (
    <div className={styles.backward} onClick={onDeleteDefect}>
      <BackwardIcon /> Qaytarish
    </div>
  );
};

export default DeleteDefect;
