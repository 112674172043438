export enum tabKeys {
  ORDER = "order",
  WORK = "work",
  PAYMENT = "payment",
  OFFER = "offer",
  ALL = "all",
  SUBSCRIPTION = "subscription",
  MORTGAGE = "mortgage",
  INVESTING = "inventing",
  FOOD_AND_DINING = "food-and-dinning"
}
