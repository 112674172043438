type RouteSubmodules = [
  {
    key: "Inventarlar";
    actions: [
      { view: true; key: "Inventalar ro'yxati" },
      { create: true; key: "Inventar yaratish" },
      { edit: true; key: "Inventarni tahrirlash" },
      { delete: true; key: "Inventarni o'chirish" },
      { invalid: true; key: "Inventarni yaroqsizga chiqarish" },
      { toProject: true; key: "Loyihaga biriktirish" }
    ];
  },
  {
    key: "Inventarlar papkasi";
    actions: [
      { view: true; key: "Papkalar ro'yxati" },
      { create: true; key: "Papka yaratish" },
      { edit: true; key: "Papkani tahrirlash" },
      { delete: true; key: "Papkani o'chirish" }
    ];
  },
  {
    key: "So'rovlar";
    actions: [
      { view: true; key: "So'rovlar jadvali" },
      { recieved: true; key: "So'rovni qabul qilish yoki rad etish" }
    ];
  },
  {
    key: "Yaroqsiz inventarlar";
    actions: [{ view: true; key: "Yaroqsiz inventarlar ro'yxati" }];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "Inventarlar",
    actions: [
      { view: true, key: "Inventalar ro'yxati" },
      { create: true, key: "Inventar yaratish" },
      { edit: true, key: "Inventarni tahrirlash" },
      { delete: true, key: "Inventarni o'chirish" },
      { invalid: true, key: "Inventarni yaroqsizga chiqarish" },
      { toProject: true, key: "Loyihaga biriktirish" }
    ]
  },
  {
    key: "Inventarlar papkasi",
    actions: [
      { view: true, key: "Papkalar ro'yxati" },
      { create: true, key: "Papka yaratish" },
      { edit: true, key: "Papkani tahrirlash" },
      { delete: true, key: "Papkani o'chirish" }
    ]
  },
  {
    key: "So'rovlar",
    actions: [
      { view: true, key: "So'rovlar jadvali" },
      { recieved: true, key: "So'rovni qabul qilish yoki rad etish" }
    ]
  },
  {
    key: "Yaroqsiz inventarlar",
    actions: [{ view: true, key: "Yaroqsiz inventarlar ro'yxati" }]
  }
];
