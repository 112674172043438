import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { cx } from "features/app/utils/helpers/cx";
import { PaginationType } from "features/app/utils/models/PaginationType";
import DiffPopoverAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverAmount";
import DiffPopoverQuantity from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverQuantity";
import DiffPopoverTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/diff-popover/DiffPopoverTotalAmount";
import { DynamicEstimateProductModel } from "features/projects/utils/models/dynamicEstimateModel";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { isEmptyObj, RU } from "modules/common";
import { useWidget } from "modules/dashboard";

import styles from "./tab-content.module.scss";

interface IProps {
  data: PaginationType<DynamicEstimateProductModel[]>;
  isLoading: boolean;
}

const TabContent: React.FC<IProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { tab } = queries();
  const {
    id,
    props,
    methods: { setWidgetDetailedDrawer }
  } = useWidget();
  const comingData = props as ProjectReportProductSpendModel;
  const columns: ColumnsType<DynamicEstimateProductModel> = [
    {
      ...(Number(tab?.split("-")[0]) !== 0
        ? {
            title: `${t("dashboard.Nomi")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className={cx("resource")}>
                <div style={{ borderColor: record?.product?.resource?.color, color: record?.product?.resource?.color }}>
                  {record?.product?.resource?.symbol[i18n.language]}
                </div>
                <span>
                  <PopoverShortName title={record?.product?.name[i18n.language]} length={25} />
                </span>
              </div>
            ),
            width: 500
          }
        : {
            title: `${t("dashboard.Ish nomi va bo'limi")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium text-gray-900">
                  {record?.section?.place}.{record?.place} {record?.section?.name}
                </span>
                <span className="text-xs font-normal text-gray-900">{record?.section?.name}</span>
              </div>
            ),
            width: 400
          })
    },
    {
      title: `${t("dashboard.Birligi")}`,
      render: record => record?.unit?.symbol[i18n.language],
      width: 200
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("dashboard.Hajmi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("dashboard.Reja")} | {t("dashboard.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx("flex h-full items-center justify-between")}>
          <span className="text-sm font-medium text-gray-900">{record?.quantity?.toLocaleString(RU)}</span>
          <span className="h-[34px] border border-solid border-gray-200 bg-gray-200" />
          <DiffPopoverQuantity record={record} classNames="text-sm font-medium" isFeature={false} />
        </div>
      ),
      width: 300
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("dashboard.Birlik summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("dashboard.Reja")} | {t("dashboard.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx("flex items-center justify-between")}>
          <span className="text-sm font-medium text-gray-900">
            {record?.amount?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
          </span>
          <span className="h-[34px] border border-solid border-gray-200" />
          <div className="flex items-center gap-1">
            <DiffPopoverAmount record={record} classNames="text-sm font-medium" isFeature={false} />
            {record?.unit?.symbol[i18n.language]}
          </div>
        </div>
      ),
      width: 300
    },
    {
      title: (
        <div className="flex flex-col items-center gap-1">
          {t("dashboard.Umumiy summasi")}
          <span className="text-xs font-medium text-gray-500">
            ({t("dashboard.Reja")} | {t("dashboard.Fakt")})
          </span>
        </div>
      ),
      render: (record: DynamicEstimateProductModel) => (
        <div className={cx("flex items-center justify-between")}>
          <span className="text-sm font-medium text-gray-900">
            {record?.total_amount?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
          </span>
          <span className="h-[34px] border border-solid border-gray-200" />
          <div className="flex items-center gap-1">
            <DiffPopoverTotalAmount record={record} classNames="text-sm font-medium" isFeature={false} />{" "}
            {record?.unit?.symbol[i18n.language]}
          </div>
        </div>
      ),
      width: 300
    },
    {
      ...(Number(tab?.split("-")[0]) === 0
        ? {
            title: `${t("dashboard.Loyiha")}`,
            render: (record: DynamicEstimateProductModel) => (
              <div className="text-sm font-normal text-gray-800">{record?.section?.project?.name}</div>
            ),
            width: 250
          }
        : {})
    },
    {
      title: "",
      render: (record: DynamicEstimateProductModel) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      )
    }
  ];

  const onRow = (record: DynamicEstimateProductModel) => ({
    onClick: () => {
      setWidgetDetailedDrawer({
        id: record?.id,
        visible: true,
        title: Number(tab?.split("-")[0]) === 0 ? t("dashboard.Kassa holati") : t("dashboard.Tarix"),
        props: {
          product: record?.product,
          type: tab ? tab?.split("-")[1] : comingData?.data[0]?.type
        }
      });
    }
  });

  return (
    <div className="flex flex-col p-5">
      <Table
        dataSource={data?.data}
        columns={columns?.filter(el => !isEmptyObj(el))}
        pagination={false}
        className="footer_table"
        rowClassName={styles.table_row}
        onRow={onRow}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data.total,
            current: data.current_page
          }}
        />
      </div>
    </div>
  );
};

export default TabContent;
