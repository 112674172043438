import React from "react";

import { ChartType, Widget, WidgetKeys, WidgetType } from "../schema";

import WidgetContext, {
  IContext,
  IDetailedWidgetDrawerProps,
  ISettingsProps,
  ITableWidgetContentModalProps,
  IWidgetDrawerProps,
  IWidgetModal,
  IWidgetProps
} from "./context";

type Props = {
  children: React.ReactNode;
};

export const Provider: React.FC<Props> = ({ children }) => {
  const [state, setState] = React.useState<Omit<IContext, "methods">>({
    // data
    id: 0,
    key: "cash_status",
    type: "payment",
    chartType: "card",
    widget: undefined,
    widgetProps: {},

    // create or update modal
    visibleWidgetModal: false,

    // widget drawer
    title: "",
    visible: false,

    // widget detailed drawer
    detailedTitle: "",
    detailedVisible: false,
    detailedId: 0,

    // settings
    settingsVisible: false,
    // tableWidgetContentModal
    tableWidgetModalVisible: false
  });

  return (
    <WidgetContext.Provider
      value={{
        ...state,
        methods: {
          setWidgetModal: (payload: IWidgetModal) => {
            setState({
              ...state,
              ...payload
            });
          },
          setWidget: (widget: Widget) => {
            setState({
              ...state,
              widget
            });
          },
          setWidgetId: (id: number) => {
            setState({
              ...state,
              id
            });
          },
          setWidgetKey: (key: WidgetKeys) => {
            setState({
              ...state,
              key
            });
          },
          setWidgetChartType: (chartType: ChartType) => {
            setState({
              ...state,
              chartType
            });
          },
          setWidgetType: (type: WidgetType) => {
            setState({
              ...state,
              type
            });
          },
          setWidgetDrawer: (
            payload: Partial<
              IWidgetDrawerProps & {
                type: WidgetType;
                chartType: ChartType;
              }
            >
          ) => {
            setState({
              ...state,
              ...payload
            });
          },
          setWidgetDetailedDrawer: (payload: IDetailedWidgetDrawerProps) => {
            setState({
              ...state,
              detailedProps: payload.props,
              detailedVisible: payload.visible,
              detailedTitle: payload.title,
              detailedId: payload.id
            });
          },
          setSettings: (payload: ISettingsProps) => {
            setState({
              ...state,
              ...payload
            });
          },
          setWidgetProps: (payload: IWidgetProps) => {
            setState({
              ...state,
              widgetProps: { ...payload }
            })
          },
          setTableWidgetContentModal: (payload: ITableWidgetContentModalProps) => {
            setState({
              ...state,
              ...payload
            });
          }
        }
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
