import React, { ChangeEvent } from "react";
import { Form, Input, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { FormInstance } from "antd/lib";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { CurrencySelectModel } from "features/app/utils/models/currencySelectModel";
import { ProjectFormFieldsModel } from "features/projects/utils/models/projectFormFieldsModel";
import { useTranslation } from "react-i18next";

export interface IProps {
  styles: Record<string, string>;
  currencies: CurrencySelectModel[] | undefined;
  formInstance: FormInstance<ProjectFormFieldsModel>;
}

const CurrencyAnProfit: React.FC<IProps> = ({ currencies, styles, formInstance }) => {
  const currencySymbol = Form.useWatch("symbol", formInstance) as string;
  const { t } = useTranslation()

  const onProfitChange = (e: ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue("profit", localeFormatter(e.target.value));
  };

  const onCurrencyChange = (_: ChangeEvent<HTMLSelectElement>, option: DefaultOptionType | DefaultOptionType[]) => {
    const optionValue = option as DefaultOptionType;

    formInstance.setFieldValue("symbol", optionValue?.props?.symbol);
  };

  return (
    <div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Valyuta tanlash")}</span>
          <p>{t("project.Loyiha valyutasini tanlang")}</p>
        </div>
        <Form.Item name="currency_id" className={styles.right}>
          <Select
            placeholder={t("project.Valyuta")}
            suffixIcon={<SelectSuffixIcon />}
            className={`${styles.select} ${styles.currency}`}
            onChange={onCurrencyChange}
          >
            {currencies?.map((item, index) => (
              <Select.Option
                key={index}
                value={item.id}
                props={{
                  symbol: item.symbol
                }}
              >
                <div className={styles.currency_select__option}>
                  <img src={item.icon} alt={item.symbol} />
                  {item.symbol}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Foyda rejasi")}</span>
          <p>{t("project.Loyihadan kutilayotgan foyda")}</p>
        </div>
        <Form.Item name="profit" className={styles.right}>
          <Input placeholder={t("project.Foyda")} suffix={currencySymbol} onChange={onProfitChange} className={styles.input} />
        </Form.Item>
      </div>
    </div>
  );
};

export default CurrencyAnProfit;
