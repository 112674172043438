import { Collapse, CollapseProps, Modal, Radio } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { integrationActions } from "../../../../../../store/reducers/integrationReducer";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import LogoUysot from "../../assets/images/logo-uysot.png";
import styles from "./addProjects.module.scss";

const AddProjects = () => {
  const dispatch = useAppDispatch();
  const { visible } = useAppSelector(
    (state) => state.integrationReducer.projectsConnectModal
  );
  const { setProjectsConnectData } = integrationActions;

  const onCancel = () => {
    dispatch(setProjectsConnectData({ visible: false }));
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className={styles.label}>
          <input type="radio" name="radio" className={styles.label__radio}></input>
          <img src={LogoUysot} alt="LogoUysot" />
          <div className={styles.label__info}>
            <h3>Minor building</h3>
            <p>4 bino</p>
          </div>
        </div>
      ),
      children: <p>asd</p>,
    },
  ];

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  return (
    <Modal
      centered
      open={visible}
      closeIcon={<CloseIcon />}
      title="Loyihalarni sozlash"
      onCancel={onCancel}
      width={744}
      cancelText="Ortga"
      okText="Saqlash"
      className={styles.projects_connect}
    >
      <div className={styles.content}>
        <Collapse
          items={items}
          onChange={onChange}
          expandIconPosition="end"
          collapsible="icon"
          className={styles.collapse}
        />
        <Collapse
          items={items}
          onChange={onChange}
          expandIconPosition="end"
          collapsible="icon"
          className={styles.collapse}
        />
        <Collapse
          items={items}
          onChange={onChange}
          expandIconPosition="end"
          collapsible="icon"
          className={styles.collapse}
        />
      </div>
    </Modal>
  );
};

export default AddProjects;
