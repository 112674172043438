import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../../../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../../../../../../../app/utils/constants/queryParamsKeys";
import $api from "../../../../../../../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../../../../../../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../../../../../../../app/utils/models/responseType";
import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { GanttSectionData } from "../utils/models/GanttSection";
import { TaskUpdate } from "../utils/models/TaskUpdate";
import { AxiosError } from "axios";

export function useTaskUpdate(sectionId?: number, projectId?: number) {
  const { reqQueryParam, searchParams } = useQueryParams();
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const params = useParams();
  const project_id = projectId || Number(params?.projectId);
  const sections = qc.getQueryData<GanttSectionData>([queryKeys.GANTT_SECTION, project_id]);

  return useMutation<SuccessRes, ErrorRes, TaskUpdate>(
    async req => {
      const res = await $api.put(endPoints.TASK_UPDATE, req);

      return res.data;
    },
    {
      onError: err => {
        errorHandler(err);
        if (sectionId) {
          let url = endPoints.GANTT_TASK(sectionId || 0);

          if (searchParams && searchParams?.length > 0) {
            url += `&${reqQueryParam(
              queryParamsKeys.GENERAL_SEARCH,
              queryParamsKeys.MAX_DUE_DATE,
              queryParamsKeys.MIN_DUE_DATE,
              queryParamsKeys.MAX_START_DATE,
              queryParamsKeys.MIN_DUE_DATE,
              queryParamsKeys.SECTION_IDS_ARR,
              queryParamsKeys.TASK_STATUSES_ARR,
              queryParamsKeys.USER_IDS_ARR,
              queryParamsKeys.COMPANY_PERSON_IDS_ARR
            )}`;
          }
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId, url],
            refetchType: "active"
          });
          //   qc.fetchQuery<GanttSectionData>({
          //     queryKey: [queryKeys.GANTT_SECTION, project_id, url],
          //   }).then((data) => {
          //     qc.setQueryData([queryKeys.GANTT_SECTION, project_id, url], {
          //       ...data,
          //       sections: data?.sections?.map((item) => ({
          //         ...item,
          //         active: sections?.sections?.find((el) => el?.id === item?.id)
          //           ?.active,
          //       })),
          //     });
          //   });
        }
      },
      onSuccess: res => {
        let url = endPoints.GANTT_SECTION(project_id);
        if (searchParams && searchParams?.length > 0) {
          url += `&${reqQueryParam(
            queryParamsKeys.GENERAL_SEARCH,
            queryParamsKeys.MAX_DUE_DATE,
            queryParamsKeys.MIN_DUE_DATE,
            queryParamsKeys.MAX_START_DATE,
            queryParamsKeys.MIN_DUE_DATE,
            queryParamsKeys.SECTION_IDS_ARR,
            queryParamsKeys.TASK_STATUSES_ARR,
            queryParamsKeys.USER_IDS_ARR,
            queryParamsKeys.COMPANY_PERSON_IDS_ARR
          )}`;
        }
        if (sectionId) {
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId],
            refetchType: "active"
          });

          qc.fetchQuery<GanttSectionData>({
            queryKey: [queryKeys.GANTT_SECTION, project_id, url]
          }).then(data => {
            qc.setQueryData([queryKeys.GANTT_SECTION, project_id], {
              ...data,
              sections: data?.sections?.map(item => ({
                ...item,
                active: sections?.sections?.find(el => el?.id === item?.id)?.active
              }))
            });
          });
        }
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useSequenceCreate(sectionId1?: number, sectionId2?: number, projectId?: number) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const params = useParams();
  const project_id = projectId || Number(params?.projectId);
  const sections = qc.getQueryData<GanttSectionData>([queryKeys.GANTT_SECTION, project_id]);

  return useMutation<
    SuccessRes,
    ErrorRes,
    {
      task_id: number;
      task_child_id: number;
    }
  >(
    async req => {
      const res = await $api.post(endPoints.SEQUENCE_TASK.CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        if (sectionId1 || sectionId2) {
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId1],
            refetchType: "active"
          });
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId2],
            refetchType: "active"
          });
          qc.fetchQuery<GanttSectionData>({
            queryKey: [queryKeys.GANTT_SECTION, project_id]
          }).then(data => {
            qc.setQueryData([queryKeys.GANTT_SECTION, project_id], {
              ...data,
              sections: data?.sections?.map(item => ({
                ...item,
                active: sections?.sections?.find(el => el?.id === item?.id)?.active
              }))
            });
          });
        }
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useSequenceDelete(sectionId1?: number, sectionId2?: number, projectId?: number) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const params = useParams();
  const project_id = projectId || Number(params?.projectId);
  const sections = qc.getQueryData<GanttSectionData>([queryKeys.GANTT_SECTION, project_id]);

  return useMutation<SuccessRes, ErrorRes, number>(
    async req => {
      const res = await $api.delete(endPoints.SEQUENCE_TASK.DELETE(req));

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        if (sectionId1 || sectionId2) {
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId1],
            refetchType: "active"
          });
          qc.invalidateQueries({
            queryKey: [queryKeys.GANTT_TASK, sectionId2],
            refetchType: "active"
          });
          qc.fetchQuery<GanttSectionData>({
            queryKey: [queryKeys.GANTT_SECTION, project_id]
          }).then(data => {
            qc.setQueryData([queryKeys.GANTT_SECTION, project_id], {
              ...data,
              sections: data?.sections?.map(item => ({
                ...item,
                active: sections?.sections?.find(el => el?.id === item?.id)?.active
              }))
            });
          });
        }
        message.success(res.message[i18n.language]);
      }
    }
  );
}
