import React from "react";
import { Form, FormInstance, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetAllCashSelect, useGetCurrenciesSelect } from "../../../../../app/service/queries";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { CurrencySelectModel } from "../../../../../app/utils/models/currencySelectModel";
import { useGetCashMoneyPaymentTypeSelect } from "../../../../service/queries";

import Additions from "./additions/additions";

import styles from "../transfer.module.scss";

type Props = {
  formInstance: FormInstance;
};

const { Item, useWatch } = Form;
const { Option } = Select;

const Bottom: React.FC<Props> = ({ formInstance }) => {
  const { t } = useTranslation();
  const baseCurrency = getBaseCurrency();
  const cashId = useWatch(["income_payment", "cash_id"], formInstance);
  const currency = JSON.parse(useWatch("currency", formInstance) || "{}") as CurrencySelectModel;

  const { data: cash, isLoading: isLoadingCash } = useGetAllCashSelect();
  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCurrenciesSelect();
  const { data: paymentTypes, isLoading: isLoadingPaymentTypes } = useGetCashMoneyPaymentTypeSelect({
    cashId,
    type: PaymentTypeEnum.INCOME,
    enabled: Boolean(cashId)
  });

  const onChangeCash = (e: number) => {
    formInstance.setFieldsValue({
      income_payment: {
        cash_id: e,
        payment_type_id: undefined
      }
    });
  };

  return (
    <div className={styles.bottom}>
      <Item label={t("payment.Kassaga")} rules={formRules()} name={["income_payment", "cash_id"]}>
        <Select
          showSearch
          loading={isLoadingCash}
          onChange={onChangeCash}
          notFoundContent={<TableEmpty />}
          filterOption={selectFilterOption}
          suffixIcon={<SelectSuffixIcon />}
          placeholder={t("payment.Kassani tanlang")}
        >
          {cash?.map(item => (
            <Option
              value={item.id}
              key={item.id}
              props={{
                name: item?.name
              }}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      </Item>
      <div className="grid grid-cols-2 gap-2">
        <Item name={["income_payment", "payment_type_id"]} label={t("payment.To'lov turi")} rules={formRules()}>
          <Select
            showSearch
            disabled={!cashId}
            loading={isLoadingPaymentTypes}
            notFoundContent={<TableEmpty />}
            filterOption={selectFilterOption}
            suffixIcon={<SelectSuffixIcon />}
            placeholder={t("payment.To'lov turini tanlang")}
          >
            {paymentTypes?.map(item => (
              <Option
                value={item.id}
                key={item.id}
                props={{
                  name: item?.name
                }}
              >
                {item?.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={formRules()}
          label={t("payment.Valyuta")}
          initialValue={baseCurrency?.id}
          name={["income_payment", "currency_id"]}
        >
          <Select
            disabled
            loading={isLoadingCurrencies}
            notFoundContent={<TableEmpty />}
            suffixIcon={<SelectSuffixIcon />}
            placeholder={t("payment.Valyutani tanlang")}
          >
            {currencies?.map(item => (
              <Option value={item.id} key={item.id} props={{ currency: item }}>
                <div className="flex items-center gap-2">
                  <img src={item?.icon} alt={item?.symbol} className="h-6 w-8 rounded object-cover" />
                  <span>{item?.symbol}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
      </div>
      <Item label={t("payment.Summa")} name={["income_payment", "amount"]}>
        <Input disabled placeholder={t("payment.Summa")} suffix={currency?.symbol} />
      </Item>
      <Additions formInstance={formInstance} />
    </div>
  );
};

export default Bottom;
