import { useMemo, useState } from "react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { useGetCPInventoryWork, useGetInventoryWork } from "../../../../service/queries";
import { InventoryWorkModel } from "../../../../utils/models/inventoryWorkModel";

import WorkDetail from "./work-detail/WorkDetail";

import styles from "./works.module.scss";

type Props = {
  isCompanyPerson?: boolean;
};

const Works: React.FC<Props> = ({ isCompanyPerson }) => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [work, setWork] = useState<InventoryWorkModel>();

  const { data: inventoryWorks, isLoading: isLoadingInventoryLoading } = useGetInventoryWork(page, isCompanyPerson);

  const { data: companyPersonWorks, isLoading: isLoadingCompanyPersonLoading } = useGetCPInventoryWork(
    page,
    isCompanyPerson
  );

  const data = useMemo(() => {
    if (isCompanyPerson) return companyPersonWorks;
    return inventoryWorks;
  }, [inventoryWorks, companyPersonWorks, isCompanyPerson]);

  const onOpenDetail = (record: InventoryWorkModel) => {
    setOpen(true);
    setWork(record);
  };

  return (
    <div className={styles.works}>
      <Spin
        spinning={isCompanyPerson ? isLoadingCompanyPersonLoading : isLoadingInventoryLoading}
        indicator={LoadingIndicator}
      >
        {!isEmptyArr(data?.data) ? (
          data?.data?.map(work => (
            <div className={styles.work} key={work?.id}>
              <div className={styles.item}>
                <p>{work?.project?.name}</p>
                <p className={styles.detail} onClick={() => onOpenDetail(work)}>
                  {t("Inventar.Batafsil")}
                </p>
              </div>
              <div className={styles.item}>
                <p>{work?.section?.name}</p>
                <p>
                  {work?.quantity?.toLocaleString(RU) ?? 0} {work?.unit?.symbol[i18n.language]}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.total}>{work?.section?.name}</p>
                <p className={styles.total}>
                  {(work?.amount * work?.quantity).toLocaleString(RU) ?? 0} {work?.currency?.symbol}
                </p>
              </div>
            </div>
          ))
        ) : (
          <TableEmpty />
        )}
        <Pagination
          paginationProps={{
            pageSize: 5,
            total: data?.total,
            current: data?.current_page,
            onChange: page => {
              setPage(page);
            }
          }}
        />
      </Spin>
      <WorkDetail open={open} setOpen={setOpen} record={work} />
    </div>
  );
};

export default Works;
