import { Dropdown, MenuProps } from "antd";

import { ConfirmationStatusIcons } from "../../../inventory/utils/constants/confirmationStatusIcons";
import { DefectModel } from "../../../inventory/utils/models/defectModel";
import { WarehouseDefectModel } from "../../../warehouse/utils/models/warehouseDefectModel";
import { getFirstLetter } from "../../utils/helpers/getFirstLetter";
import { ConfirmationUserModel } from "../../utils/models/confirmationUser";

import styles from "./confirmationUsersList.module.scss";

type Props = {
  record: DefectModel | WarehouseDefectModel;
  length?: number;
};

const ConfirmationUsersList = ({ record, length = 10 }: Props) => {
  const onDetailConfirmationUser = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const confirmationUserItems = (users: ConfirmationUserModel[]): MenuProps["items"] => {
    return users?.map(user => ({
      key: user.user.id,
      label: (
        <div className={styles.user_option} onClick={onDetailConfirmationUser}>
          <div className={styles.user_option__left}>
            <div className={styles.user_option__left__name}>{getFirstLetter(user.user.full_name)}</div>

            <span>{user.user.full_name}</span>
          </div>
          <div className={styles.user_option__right}>{ConfirmationStatusIcons[user.status]}</div>
        </div>
      )
    }));
  };

  return (
    <Dropdown
      placement="top"
      trigger={["click", "hover"]}
      menu={{ items: confirmationUserItems(record.confirmation_users) }}
      rootClassName={styles.dropdown}
    >
      <div className={styles.confirmation_users}>
        {record?.confirmation_users?.slice(0, length).map((user, index) => (
          <div
            key={user.id}
            className={styles.confirmation_user}
            style={{
              left: index * 20
            }}
          >
            <div className={`${styles.confirmation_user__inner} ${styles[user?.status]}`}>
              {user?.user?.full_name?.split(" ")[0]?.split("")[0]?.toUpperCase()}
              {user?.user?.full_name?.split(" ")[1]?.split("")[0]?.toUpperCase()}
            </div>
          </div>
        ))}
        {record?.confirmation_users.length > length && (
          <div
            className={styles.confirmation_user}
            style={{
              left: length * 20
            }}
          >
            <div className={styles.confirmation_user__count}>
              +{record?.confirmation_users.length - Math.floor(length)}
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
};

export default ConfirmationUsersList;
