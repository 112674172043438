export const projectsPaths = {
  INDEX: "/",
  DETAILED_FOLDER: "/:folderId",
  DETAILED_PROJECT: {
    INDEX: "/project/:projectId",
    ESTIMATE: {
      INDEX: "estimate",
      GANTT: "gantt"
    },
    WORK: "work"
  }
};
