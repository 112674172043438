import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";

import { CreateWidget } from "../forms";
import { dashboardQueryKeys } from "../query-keys";
import { CompanyWidget } from "../schema";
import { defaultWidgetSizes } from "../constants";

export function useCreateWidget() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<CompanyWidget>, ErrorRes, CreateWidget>(
    async body => {
      const { data } = await $api.post("company-widget/create", body);

      return data;
    },
    {
      retry: false,
      onSuccess: async res => {
        await qc.invalidateQueries([dashboardQueryKeys.COMPANY_WIDGETS_SELECT]);
        await qc.invalidateQueries([dashboardQueryKeys.WIDGET_SELECT]);
        const widgetProps = defaultWidgetSizes[res?.data?.chart_type];

        qc.setQueryData([dashboardQueryKeys.COMPANY_WIDGETS], ({ companyWidgets }: any) => ({
          companyWidgets: [
            ...companyWidgets,
            { ...res?.data, props: JSON.stringify({ ...widgetProps, x: 0, y: 0, i: res?.data?.id }) }
          ].sort((a, b) => a.id - b.id)
        }));

        message.success(res.message[i18n.language]);
      }
    }
  );
}
