import React from "react";

const ArrowRightIcon: React.FC = () => (
  <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.25 9H14.75M14.75 9L9.5 3.75M14.75 9L9.5 14.25"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowRightIcon;
