import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormInstance, Input } from "antd";
import { useWatch } from "antd/es/form/Form";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";

import { useAppSelector } from "hooks/redux";

import { useSelector } from "store";
import { warehouseTransferActions } from "store/reducers/warehouseTransferReducer";

import styles from "./selectedProductsTable.module.scss";

type Props<TAction extends "create" | "update"> = TAction extends "create"
  ? {
      form: FormInstance<any>;
      record: WarehouseProductModel;
      uniqueId: string;
      action: TAction;
    }
  : {
      form: FormInstance<any>;
      record: ITransferringResourcesModel;
      uniqueId: string;
      action: TAction;
    };

const QuantityInput = <TAction extends "create" | "update">({ form, record, action }: Props<TAction>) => {
  const products = useWatch("products", form) as ITransferringResourcesModel[];
  const dispatch = useDispatch();
  const { setSelectProducts } = warehouseTransferActions;
  const { selectProducts } = useAppSelector(state => state.warehouseTransferReducer);
  const { id } = useSelector(state => state.transferMakingReducer);

  const onQuantityChange = (value: string) => {
    const quantity = Number(value);

    if (!id && action === "create") {
      if (quantity > 0) {
        const foundOne = products?.find(p => p?.product_id === record?.product?.id && p?.unit_id === record?.unit?.id);

        if (!foundOne) {
          const updatedRecord = {
            ...record,
            changed_quantity_value: quantity,
            selected_total_value: record.total_quantity
          };

          const newResource = {
            product_id: record?.product?.id,
            unit_id: record?.unit?.id,
            quantity,
            total_quantity: record?.total_quantity,
            uniqueId: `${record?.product?.id}-${record?.unit?.id}`
          };

          dispatch(setSelectProducts([...selectProducts!, updatedRecord]));

          form.setFieldsValue({
            products: [...products!, newResource]
          });
        } else {
          const updatedProducts = selectProducts!.map(p =>
            p?.product?.id === record?.product?.id && p?.unit?.id === record?.unit?.id
              ? { ...p, changed_quantity_value: quantity, selected_total_value: record?.total_quantity }
              : p
          );

          const updatedOnes = products!.map(product =>
            product?.product_id === record?.product?.id && product?.unit_id === record?.unit?.id
              ? {
                  ...product,
                  quantity
                }
              : product
          );

          dispatch(setSelectProducts(updatedProducts));

          form.setFieldsValue({
            products: [...updatedOnes!]
          });
        }
      } else {
        dispatch(
          setSelectProducts(
            selectProducts!.filter(p => !(p?.product?.id === record?.product?.id && p?.unit?.id === record?.unit?.id))
          )
        );

        form.setFieldsValue({
          products: products!.filter(
            product => !(product?.product_id === record?.product?.id && product?.unit_id === record?.unit?.id)
          )
        });
      }
    } else if (id && action === "update") {
      const productId = Number(String(record?.product_id)?.split("-")[0]);
      const unitId = record?.unit?.id;

      if (quantity > 0) {
        const updatedProducts = selectProducts!.map(p =>
          p?.product?.id === productId && p?.unit?.id === unitId
            ? { ...p, changed_quantity_value: quantity, selected_total_value: record?.total_quantity }
            : p
        );

        const updatedOnes = products!.map(product =>
          Number(String(product?.product_id)?.split("-")[0]) === productId && product?.unit_id === unitId
            ? { ...product, quantity }
            : product
        );

        dispatch(setSelectProducts(updatedProducts));

        form.setFieldsValue({
          products: updatedOnes
        });
      } else {
        const filteredProducts = selectProducts!.filter(p => !(p?.product?.id === productId && p?.unit?.id === unitId));

        const updatedOnes = products!.map(product =>
          Number(String(product?.product_id)?.split("-")[0]) === productId && product?.unit_id === unitId
            ? { ...product, quantity: undefined }
            : product
        );

        dispatch(setSelectProducts(filteredProducts));

        form.setFieldsValue({
          products: updatedOnes
        });
      }
    }
  };

  const getInputDefaultValue = useMemo(() => {
    let foundOne = {} as ITransferringResourcesModel | undefined;

    if (action === "create") {
      foundOne = products?.find(
        product => product?.product_id === record?.product?.id && product?.unit_id === record?.unit?.id
      );
    } else if (action === "update") {
      foundOne = products?.find(
        product =>
          Number(String(product?.product_id)?.split("-")[0]) === Number(String(record?.product_id)?.split("-")[0]) &&
          product?.unit_id === record?.unit?.id
      );
    }

    return foundOne?.quantity;
  }, [products, action]);

  const inputStatus = useMemo(() => {
    if (getInputDefaultValue! > record.total_quantity!) {
      return "error";
    }
    return "";
  }, [getInputDefaultValue]);

  return (
    <Input
      className={styles.quantity_input}
      placeholder="Soni"
      onChange={e => onQuantityChange(e.target.value)}
      status={inputStatus}
      value={getInputDefaultValue}
    />
  );
};

export default QuantityInput;
