import React from "react";
import { Button, Collapse } from "antd";
import { RU } from "features/app/utils/constants/languages";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import MessageIcon from "../../../../../inventory/assets/icons/MessageIcon";
import { ConfirmationStatusIcons } from "../../../../utils/constants/confirmationStatusIcons";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { ConfirmationUserStatusEnum } from "../../../../utils/enums/confirmationUserStatusEnum";
import { getFirstLetter } from "../../../../utils/helpers/getFirstLetter";
import { NotificationModel } from "../../../../utils/models/notificationModel";
import RecievedStatusConfirmationUser from "../../recieved-status-confirmation-user/recievedStatusConfirmationUser";
import RejectStatusConfirmationUser from "../../reject-status-confirmation-user/rejectStatusConfirmationUser";

import styles from "../../notification.module.scss";
import CheckCircleIcon from "features/app/assets/icons/CheckCircleIcon";
import { colors } from "features/app/utils/constants/colors";
import CloseCircleIcon from "features/app/assets/icons/CloseCircleIcon";
import { statusContent } from "features/app/utils/constants/statusContent";

type Props = {
  record: NotificationModel;
  onOpenChat: (objectId: number, type: string) => void;
};

const WarehouseDefectCreated = ({ record, onOpenChat }: Props) => {
  const { i18n, t } = useTranslation();
  const id = useAuth().user?.id;

  const status = statusContent();

  const checkToStatus = () => {
    const result = record?.warehouse_defect?.confirmation_users?.filter(user => {
      if (user?.user?.id === id && user?.status === ConfirmationUserStatusEnum.RECIEVED) {
        return true;
      }
      return false;
    });

    if (result && result?.length > 0) {
      return true;
    }
    return false;
  };

  const confirmationUserStatus = record?.warehouse_defect?.confirmation_users?.find(
    user => user?.user?.id === id
  )?.status;

  return (
    <div className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        <span className={styles.name}>{record?.user?.full_name}</span> yaroqsiz mahsulotlarni tasdiqlashni so’radi
      </p>
      <div className="flex flex-col">
        <div className={styles.products}>
          <div className="resource">
            <div
              style={{
                borderColor: record?.warehouse_defect?.product?.resource?.color,
                color: record?.warehouse_defect?.product?.resource?.color
              }}
            >
              {record?.warehouse_defect?.product?.resource?.symbol[i18n.language]}
            </div>
            <span className={styles.bold}>{record?.warehouse_defect.product?.name[i18n.language]}</span>
          </div>
          <div className={styles.products__count}>
            {record?.warehouse_defect?.quantity?.toLocaleString(RU)}{" "}
            {record?.warehouse_defect?.unit?.symbol[i18n.language]}
          </div>
        </div>
        {confirmationUserStatus !== ConfirmationUserStatusEnum.OPEN && (
          <div className={styles.products}>
            <div className="flex items-center gap-2">
              <span className="flex items-center">
                {status[confirmationUserStatus as keyof ReturnType<typeof statusContent>]?.icon}{" "}
              </span>
              <span
                style={{
                  color: status[confirmationUserStatus as keyof ReturnType<typeof statusContent>].color
                }}
                className="flex items-center text-sm font-medium"
              >
                {status[confirmationUserStatus as keyof ReturnType<typeof statusContent>]?.text}
              </span>
            </div>
          </div>
        )}
      </div>
      <Collapse
        className={styles.collapse}
        items={[
          {
            key: "1",
            label: "Guvohlar",
            children: (
              <>
                {record?.warehouse_defect?.confirmation_users?.map(user => (
                  <div className={styles.collapse__item} key={user.id}>
                    <div className={styles.collapse__item__info}>
                      {user?.user?.image ? (
                        <img
                          src={user?.user?.image}
                          alt={user?.user?.full_name}
                          className={styles.collapse__item__img}
                        />
                      ) : (
                        <span className={styles.collapse__item__img}>{getFirstLetter(user?.user?.full_name)}</span>
                      )}
                      <p>{user?.user?.full_name}</p>
                    </div>
                    <div>{ConfirmationStatusIcons[user?.status]}</div>
                  </div>
                ))}
              </>
            )
          }
        ]}
        expandIconPosition="end"
      />
      <div className={styles.actions}>
        <Button
          className={styles.actions__chat}
          onClick={() => onOpenChat(record?.warehouse_defect?.id, "warehouse_defects")}
        >
          <MessageIcon />
        </Button>
        {!(
          record?.warehouse_defect?.status === ConfirmationUserStatusEnum.RECIEVED ||
          record?.warehouse_defect?.status === ConfirmationUserStatusEnum.REJECTED ||
          checkToStatus()
        ) && <RejectStatusConfirmationUser users={record?.warehouse_defect?.confirmation_users} />}
        {!(
          record?.warehouse_defect?.status === ConfirmationUserStatusEnum.RECIEVED ||
          record?.warehouse_defect?.status === ConfirmationUserStatusEnum.REJECTED ||
          checkToStatus()
        ) && <RecievedStatusConfirmationUser users={record?.warehouse_defect?.confirmation_users} />}
      </div>
    </div>
  );
};

export default WarehouseDefectCreated;
