import React from "react";
import { Col, Row } from "antd";

import Left from "./components/left/left";
import Right from "./components/right/right";

import styles from "./login.module.scss";

const Login: React.FC = () => (
  <div className={styles.login}>
    <Row gutter={[16, 16]}>
      <Col span={14}>
        <Left />
      </Col>
      <Col span={10}>
        <Right />
      </Col>
    </Row>
  </div>
);

export default Login;
