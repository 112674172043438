export enum NotificationTypeEnum {
  WAREHOUSE_DEFECT_CREATED = "warehouse_defect_created",
  WAREHOUSE_DEFECT_RECIEVED = "warehouse_defect_recieved",
  WAREHOUSE_DEFECT_REJECTED = "warehouse_defect_rejected",
  WAREHOUSE_TRANSFER_CREATED = "warehouse_transfer_created",
  WAREHOUSE_TRANSFER_RECIEVED = "warehouse_transfer_recieved",
  WAREHOUSE_TRANSFER_REJECTED = "warehouse_transfer_rejected",
  WAREHOUSE_TRANSFER_ADDITIONAL_CREATED = "warehouse_transfer_additional_created",
  ORDER_CREATED = "order_created",
  ORDER_PENDING = "order_pending",
  ORDER_PARTIALLY_RECIEVED = "order_partially_recieved",
  ORDER_RECIEVED = "order_recieved",
  ORDER_REJECTED = "order_rejected",
  WAREHOUSE_PRODUCT_CREATED = "warehouse_product_created",
  WAREHOUSE_PRODUCT_RECIEVED = "warehouse_product_recieved",
  PAYMENT_TRANSFER_CREATED = "payment_transfer_created",
  PAYMENT_TRANSFER_RECIEVED = "payment_transfer_recieved",
  PAYMENT_TRANSFER_RECEIVED = "payment_transfer_received",
  PAYMENT_TRANSFER_REJECTED = "payment_transfer_rejected",
  INVENTORY_REQUEST_CREATED = "inventory_request_created",
  INVENTORY_REQUEST_RECIEVED = "inventory_request_received",
  INVENTORY_REQUEST_REJECTED = "inventory_request_rejected",
  INVENTORY_DEFECT_CREATED = "inventory_defect_created",
  INVENTORY_DEFECT_RECIEVED = "inventory_defect_received",
  INVENTORY_DEFECT_REJECTED = "inventory_defect_rejected",
  PAYMENT_EXPENSE_PENDING = "payment_expense_pending",
  PAYMENT_EXPENSE_CREATED = "payment_expense_created",
  PAYMENT_EXPENSE_RECIEVED = "payment_expense_recieved",
  PAYMENT_EXPENSE_REJECTED = "payment_expense_rejected",
  CONFIRMATION_WORK_PENDING = "confirmation_work_pending",
  CONFIRMATION_WORK_RECIEVED = "confirmation_work_recieved",
  CONFIRMATION_WORK_REJECTED = "confirmation_work_rejected",
  CONFIRMATION_ORDER_PENDING = "confirmation_order_pending",
  CONFIRMATION_ORDER_RECIEVED = "confirmation_order_recieved",
  CONFIRMATION_ORDER_REJECTED = "confirmation_order_rejected",
  CONFIRMATION_WORK_CREATED = "confirmation_work_created",
  CONFIRMATION_WORK_CREATED_REJECTED = "confirmation_work_created_rejected",
  CONFIRMATION_WORK_CREATED_RECEIVED = "confirmation_work_created_recieved"
}
