import dayjs from "dayjs";
import { PartyReqModel } from "features/supply/utils/models/partyReqModel";
import i18n from "i18next";
import { uid } from "uid";

import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { CustomFieldTypesEnum } from "../../../app/utils/enums/customFieldTypesEnum";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../app/utils/helpers/parseLocaledString";
import { ReqCustomFieldValueModel } from "../../../app/utils/models/reqCustomFieldValueModel";
import { CounterpartsPersonSelectModel } from "../../../counterparts/utils/models/counterpartsPersonSelectModel";
import { ProductModel as ProductDrawerDataModel } from "../../../settings/utils/models/product/productModel";
import { WarehouseProductModel } from "../models/orderModel";
import {
  PartyFormModel,
  PartyFormOfferModel,
  PartyFormPersonGroupModel,
  PartyFormProductsModel,
  PartyGroupChildModel,
  PartyGroupProductsModel
} from "../models/partyModalFormModel";
import { ProductModel } from "../models/productModel";
import { ProjectTaskProductModel } from "../models/projectModel";

import { numberRound } from "./numberRound";

export function formationWarehouseProductsDrawer(selectProducts: ProductModel[] | undefined) {
  return (
    selectProducts?.map(item => ({
      amount: localeFormatter(String(item?.amount)),
      id: item?.id,
      row_id: uid(12),
      unit_id: item.unit?.id,
      product_id: item.product?.id,
      project_id: item?.project?.id,
      warehouse_id: item.warehouse?.id,
      company_person_id: item?.company_person?.id,
      quantity: localeFormatter(String(item.quantity)),
      currency_id: item?.currency?.id,
      total: localeFormatter(String(numberRound(Number(item?.amount) * Number(item?.quantity))))
    })) ?? []
  );
}

export function formationWarehouseProducts(selectProducts: WarehouseProductModel[] | undefined) {
  let newOffers: PartyFormOfferModel[] = [];

  const newProducts =
    selectProducts?.map(item => {
      const generateRowId = uid(12);

      if (item?.warehouse_product_offers) {
        newOffers = [
          ...newOffers,
          ...(item?.warehouse_product_offers?.map(offer => ({
            ...offer,
            product_row_id: generateRowId
          })) ?? [])
        ];
      }

      return {
        amount: localeFormatter(String(item?.amount)),
        id: item.id,
        status: item.status,
        row_id: generateRowId,
        unit_id: item.unit?.id,
        product_id: item.product?.id,
        project_id: item?.project?.id,
        currency_id: item?.currency?.id,
        warehouse_id: item.warehouse?.id,
        this_product: JSON.stringify(item),
        company_person_id: item?.company_person?.id,
        received_quantity: item?.recieved_quantity,
        unit_symbol: item?.unit?.symbol[i18n.language],
        quantity: localeFormatter(String(item.quantity)),
        warehouse_total_quantity: item?.warehouse_total_quantity,
        market_price: JSON.stringify({
          market_price: item?.market_price,
          market_price_currency: item?.market_price_currency,
          market_price_date: item?.market_price_date,
          market_price_order_id: item?.market_price_order_id,
          change_amount_percent: item?.change_amount_percent
        }),
        total: localeFormatter(String(numberRound(Number(item?.amount) * Number(item?.quantity))))
      };
    }) ?? [];

  return {
    offers: newOffers,
    products: newProducts
  };
}

export function formationSelectProducts(selectProducts: ProductModel[] | WarehouseProductModel[] | undefined) {
  return (
    selectProducts?.map(item => ({
      id: item.product?.id,
      name: item?.product?.name,
      resource: item.product?.resource,
      price: 0,
      status: "",
      unit: item.unit
    })) ?? []
  );
}

export function generateReqGroupProducts(fields: PartyFormModel) {
  let newData: PartyGroupChildModel[] = [];

  fields?.group_products?.forEach(item => {
    newData = [...newData, ...(item?.children ?? [])];
  });

  return newData;
}

export function generateReqPersonGroupProducts(fields: PartyFormPersonGroupModel[]) {
  let newData: PartyFormProductsModel[] = [];

  fields?.forEach(item => {
    newData = [...newData, ...(item?.data ?? [])];
  });

  return newData;
}

export function filterDuplicateProducts(products: PartyFormProductsModel[]) {
  const filteredArray: PartyGroupProductsModel[] = [];

  const seen: { [p: string]: number } = {};

  for (let i = 0; i < products.length; i++) {
    const product = products[i];

    if (product?.product_id && product?.unit_id) {
      const key = `${product.product_id}-${product.unit_id}`;

      if (!seen[key]) {
        seen[key] = 1;
      } else {
        seen[key]++;
      }

      const someProduct = filteredArray?.some(item => item?.product_id === product?.product_id);

      if (seen[key] >= 2 && !someProduct) {
        filteredArray.push(product);
      }
    }
  }

  return filteredArray;
}

export const filterChildProducts = (
  item: PartyGroupProductsModel,
  products: PartyFormProductsModel[]
): PartyGroupChildModel[] => {
  const newChildProducts: PartyGroupChildModel[] = [];
  const propsKey = `${item.product_id}-${item.unit_id}`;

  products?.forEach(product => {
    if (product?.product_id && product?.unit_id) {
      const productKey = `${product?.product_id}-${product?.unit_id}`;

      if (productKey === propsKey) {
        newChildProducts.push({
          ...product,
          item_id: uid(13),
          parent_id: item?.group_id
        });
      }
    }
  });

  return newChildProducts;
};

export const generateGroupProducts = (
  filteredArray: PartyGroupProductsModel[],
  allWarehouseProducts: PartyFormProductsModel[]
): PartyGroupProductsModel[] =>
  filteredArray?.map(item => {
    const newGroupId = uid(10);

    return {
      ...item,
      quantity: "0",
      group_id: newGroupId,
      children: filterChildProducts(
        {
          ...item,
          group_id: newGroupId
        },
        allWarehouseProducts
      ) as PartyGroupChildModel[]
    };
  });

export const unGroupProducts = (groupProducts: PartyGroupProductsModel[]) => {
  let newData: PartyFormProductsModel[] = [];

  if (!isEmptyArr(groupProducts) && groupProducts?.[0]?.children) {
    groupProducts?.forEach(item => {
      const childProducts =
        item?.children?.map(product => ({
          ...product
        })) ?? [];

      newData = [...childProducts, ...newData];
    });
  }

  return newData;
};

export const generateWarehouseProducts = (
  filteredArray: PartyGroupProductsModel[],
  allWarehouseProducts: PartyFormProductsModel[]
) =>
  allWarehouseProducts?.filter(item => {
    const itemKey = `${item?.product_id}-${item?.unit_id}`;

    return !filteredArray?.some(e => {
      const productKey = `${e?.product_id}-${e?.unit_id}`;

      return productKey === itemKey;
    });
  });

export const importProductData = (
  oldProducts: PartyFormProductsModel[],
  oldSelectProducts: ProductModel[],
  products: ProductDrawerDataModel[] | undefined
) => {
  const lastProduct = oldProducts[oldProducts?.length - 1];

  return {
    warehouse_products: [
      ...(oldProducts?.filter((item: PartyFormProductsModel) => item.product_id) ?? []),
      ...(products?.map(item => ({
        amount: undefined,
        quantity: undefined,
        product_id: item?.id,
        row_id: uid(12),
        unit_id: item?.unit?.id,
        task_product_id: undefined,
        project_id: lastProduct?.project_id,
        currency_id: lastProduct?.currency_id,
        warehouse_id: lastProduct?.warehouse_id,
        company_person_id: lastProduct?.company_person_id
      })) ?? [])
    ],
    select_products: [
      ...(oldSelectProducts ?? []),
      ...(products?.map(item => ({
        id: item.id,
        name: item?.name,
        resource: item?.resource,
        price: item.price,
        status: item?.status,
        unit: item.unit
      })) ?? [])
    ]
  };
};

export const importPersonProductData = (
  oldProducts: PartyFormProductsModel[],
  oldSelectProducts: ProductModel[],
  products: ProductDrawerDataModel[] | undefined,
  oldPersonGroup: PartyFormPersonGroupModel[]
) => {
  const { select_products, warehouse_products } = importProductData(oldProducts, oldSelectProducts, products);

  return {
    select_products,
    person_group: oldPersonGroup?.map(item => {
      if (item?.id) {
        return item;
      }
      return {
        ...item,
        data: [...(item?.data ?? []), ...warehouse_products]
      };
    })
  };
};

export const importEstimateProductData = (
  oldProducts: PartyFormProductsModel[],
  oldSelectProducts: ProductModel[],
  estimateProducts: ProjectTaskProductModel[],
  warehouses: { id: number; name: string }[] | undefined,
  data: { id: number; name: string }[]
) => {
  const generateEstimateProducts = estimateProducts?.map(item => {
    const newQuantity = String(
      numberRound(item.quantity - item.total_ordered_quantity > 0 ? item.quantity - item.total_ordered_quantity : 0)
    );

    const lastProduct = oldProducts[oldProducts?.length - 1];

    return {
      row_id: uid(12),
      quantity: newQuantity,
      unit_id: item?.unit?.id,
      task_product_id: item.id,
      project_id: data?.[0]?.id,
      product_id: item?.product?.id,
      warehouse_id: warehouses?.[0]?.id,
      currency_id: lastProduct?.currency_id,
      company_person_id: lastProduct?.company_person_id,
      amount: localeFormatter(String(numberRound(item?.amount))),
      total: localeFormatter(String(numberRound(parseLocaledString(newQuantity) * item.amount)))
    };
  });

  return {
    warehouse_products: [
      ...(oldProducts?.filter((item: PartyFormProductsModel) => item?.product_id) ?? []),
      ...(generateEstimateProducts ?? [])
    ],
    select_products: [
      ...oldSelectProducts,
      ...(estimateProducts?.map(item => ({
        id: item.product?.id,
        name: item?.product?.name,
        resource: item.product?.resource,
        price: 0,
        status: item?.status,
        unit: item.unit
      })) ?? [])
    ]
  };
};

export const importEstimatePersonProductData = (
  oldProducts: PartyFormProductsModel[],
  oldSelectProducts: ProductModel[],
  estimateProducts: ProjectTaskProductModel[],
  warehouses: { id: number; name: string }[] | undefined,
  data: { id: number; name: string }[],
  oldPersonGroup: PartyFormPersonGroupModel[]
) => {
  const { select_products, warehouse_products } = importEstimateProductData(
    oldProducts,
    oldSelectProducts,
    estimateProducts,
    warehouses,
    data
  );

  const isSomeNotPerson = oldPersonGroup?.some(item => !item.id);

  return {
    select_products,
    person_group: isSomeNotPerson
      ? oldPersonGroup?.map(item => {
          if (item?.id) {
            return item;
          }
          return {
            id: 0,
            name: "Belgilanmagan",
            data: [...(item?.data ?? []), ...warehouse_products]
          };
        })
      : [
          ...oldPersonGroup,
          {
            id: 0,
            name: "Belgilanmagan",
            data: [...warehouse_products]
          }
        ]
  };
};

export const generatePersonGroupData = (
  warehouseProducts: PartyFormProductsModel[],
  companyPersons: CounterpartsPersonSelectModel[] | undefined
) => {
  const newData: {
    name?: string;
    id?: number | string;
    data: PartyFormProductsModel[];
  }[] = [];

  const notPersonsData = warehouseProducts?.filter(e => !e.company_person_id);

  if (!isEmptyArr(notPersonsData)) {
    newData.push({
      id: 0,
      name: "Belgilanmagan",
      data: notPersonsData
    });
  }

  const findCompanyPerson = (id: number | string) => companyPersons?.find(item => item.id === id)?.name;

  warehouseProducts.forEach(item => {
    if (item.company_person_id) {
      const isSomeCompanyPerson = newData?.some(c => c?.id === item?.company_person_id && c.id);

      !isSomeCompanyPerson &&
        newData.push({
          id: item.company_person_id,
          data: warehouseProducts?.filter(e => e.company_person_id === item.company_person_id),
          name: findCompanyPerson(item.company_person_id)
        });
    }
  });

  return newData;
};

export const generatePersonToWarehouseProduct = (personGroup: PartyFormPersonGroupModel[]) => {
  const newWarehouseProducts: PartyFormProductsModel[] = [];

  personGroup?.forEach(item => {
    item?.data?.forEach(product => {
      newWarehouseProducts.push(product);
    });
  });

  return newWarehouseProducts;
};

export const passedReqData = (products: PartyFormProductsModel[]) => {
  const result: PartyReqModel["warehouse_products"] = [];

  products?.forEach(item => {
    if (item?.amount && item?.quantity)
      result.push({
        id: item.id,
        unit_id: item.unit_id,
        product_id: item.product_id,
        project_id: item?.project_id || undefined,
        currency_id: item?.currency_id,
        warehouse_id: item.warehouse_id,
        task_product_id: item.task_product_id,
        amount: parseLocaledString(item.amount),
        company_person_id: item?.company_person_id,
        quantity: parseLocaledString(item.quantity)
      });
  });

  return result;
};

export const generateReqCustomFieldsData = (custom_field_values: ReqCustomFieldValueModel[] | undefined) => {
  const returnFieldData: ReqCustomFieldValueModel[] = [];

  custom_field_values?.forEach(item => {
    if (item?.type === CustomFieldTypesEnum.DATE) {
      returnFieldData.push({
        custom_field_id: item?.custom_field_id,
        value: item?.value ? dayjs(item.value).format(dayjsFormats.DATE) : undefined
      });
    } else if (item?.type === CustomFieldTypesEnum.SELECT) {
      returnFieldData.push({
        custom_field_id: item?.custom_field_id,
        value: item?.custom_field_option_id
      });
    } else if (item?.type === CustomFieldTypesEnum.FILE) {
      returnFieldData.push({
        custom_field_id: item?.custom_field_id,
        value: Number(item.value)
      });
    } else {
      item?.value &&
        returnFieldData.push({
          custom_field_id: item?.custom_field_id,
          value: item.value
        });
    }
  });

  return returnFieldData;
};
