import React, { useEffect } from "react";
import styles from "./ganttLineSquence.module.scss";
import CloseCircleIcon from "../../../../../../../../../../../assets/icons/CloseCircleIcon";
import { useSequenceDelete } from "../../../../../services/mutation";

interface IProps {
  squenceTaskId: number;
  squenceId: number;
  squenceSectionId?: number | null;
  id: number;
  taskSectionId: number;
  active: boolean;
}

const GanttLineSquence = ({
  id,
  squenceTaskId,
  active,
  squenceId,
  taskSectionId,
  squenceSectionId,
}: IProps) => {
  const squenceDelete = useSequenceDelete(taskSectionId, squenceSectionId!);
  const changePosition = () => {
    const squenceTaskElement = document.getElementById(`task_${squenceTaskId}`);
    const squenceElement = document.getElementById(`squence_${id}_${squenceTaskId}`);
    if (squenceTaskElement && squenceElement) {
      const line2 = squenceElement.querySelector(`.${styles.line2}`);
      const line3 = squenceElement.querySelector(`.${styles.line3}`);
      const line4 = squenceElement.querySelector(`.${styles.line4}`);
      const line5 = squenceElement.querySelector(`.${styles.line5}`);
      const squnecePosition = squenceElement?.getBoundingClientRect();
      const taksPosition = squenceTaskElement?.getBoundingClientRect();
      const height = squnecePosition.y - taksPosition.y;
      const width = squnecePosition.x + squnecePosition.width - taksPosition.x;
      let position = {
        height: 0,
        top: 10,
        width: 0,
        left: 0,
        line3Top: 0,
        line4Top: 0,
        line4Left: 0,
        line5Top: 0,
      };

      let obj = {
        height: position.height,
        top: position.top,
        width: position.width,
        left: position.left,
      };

      if (height > 0) {
        obj = {
          height: height - 40,
          top: -height + 42,
          width: width + 64 > 0 ? width + 64 : -1 * width - 64,
          left: width + 64 > 0 ? -1 * width - 30 : 32,
        };
        position = {
          ...obj,
          line3Top: obj.top >= 0 ? (height + 22) * -1 : (height - 40) * -1,
          line4Top: obj.top >= 0 ? height - 40 + 32 : (height - 40 + 32) * -1,
          line4Left: obj.left - 32 >= 0 ? obj.left + obj.width : obj.left,
          line5Top: obj.top >= 0 ? obj.height : (obj.height + 30) * -1,
        };
      } else {
        obj = {
          height: (height + 24) * -1,
          top: 0,
          width: width + 64 > 0 ? width + 64 : -1 * width - 64,
          left: width + 64 > 0 ? -1 * width - 30 : 32,
        };

        position = {
          ...obj,
          line3Top: obj.height,
          line4Top: (height - 40 + 32) * -1,
          line4Left: obj.left - 32 >= 0 ? obj.left + obj.width : obj.left,
          line5Top: obj.top >= 0 ? obj.height : (obj.height + 30) * -1,
        };
      }

      line2?.setAttribute(
        "style",
        `height: ${position.height}px; top: ${position.top}px;`
      );
      line3?.setAttribute(
        "style",
        `width: ${position.width}px; top: ${position.line3Top}px; left: ${position.left}px;`
      );
      line4?.setAttribute(
        "style",
        `width: 16px; top: ${position.line4Top}px; left: ${position.line4Left}px;`
      );
      line5?.setAttribute(
        "style",
        `height: 32px; top: ${position.line5Top}px; left: ${position.line4Left}px;`
      );
    }
  };

  useEffect(() => {
    const squenceTaskElement = document.getElementById(`task_${squenceTaskId}`);
    const squenceElement = document.getElementById(`task_${id}`);
    const squenceTaskElementObserver = new MutationObserver((entries) => {
      changePosition();
    });
    const squenceElementObserver = new MutationObserver((entries) => {
      changePosition();
    });
    if (squenceTaskElement && squenceElement) {
      squenceTaskElementObserver.observe(squenceTaskElement!, {
        attributes: true,
        attributeFilter: ["style"],
      });
      squenceElementObserver.observe(squenceElement!, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
    changePosition();

    return () => {
      changePosition();
      squenceTaskElementObserver.disconnect();
      squenceElementObserver.disconnect();
    };
  }, [active, squenceDelete.isSuccess]);

  const handleDelete = () => {
    squenceDelete.mutate(squenceId);
  };

  return (
    <div
      className={styles.container}
      id={`squence_${id}_${squenceTaskId}`}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <div className={styles.line1}></div>
      <div className={styles.line2}></div>
      <div className={styles.line3}></div>
      <div className={styles.line4}>
        <CloseCircleIcon className={styles.close} onClick={handleDelete} />
      </div>
      <div className={styles.line5}></div>
    </div>
  );
};

export default GanttLineSquence;
