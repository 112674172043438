import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import { PropsWithChildren, useState } from "react";

import { useAppDispatch } from "../../../../../hooks/redux";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import { useImpostOrderRejected } from "../../../service/mutations";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";

import styles from "./confirmModal.module.scss";

type Props = {
  id?: number;
};

const ConfirmModal = ({ id, children }: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  // const { setPartModal } = impostActions;
  const { setPartyModal } = supplyActions;
  const partRejected = useImpostOrderRejected();

  const onOpen = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onFinish = () => {
    partRejected.mutateAsync({ id }).then(() => {
      onCancel();
      dispatch(
        setPartyModal({
          visible: false
        })
      );
    });
  };

  const titleContent = (
    <div className={styles.rejected_title}>
      <div className={styles.rejected_title__inner}>
        <CloseIcon color="#f04438" />
      </div>
    </div>
  );

  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <Modal
        centered
        open={open}
        onCancel={onCancel}
        onOk={onFinish}
        className={styles.modal_confirm}
        title={titleContent}
        closeIcon={<CloseIcon />}
        okButtonProps={{
          danger: true,
          loading: partRejected.isLoading
        }}
        okText={t("Monitoring.Bekor qilish")}
        cancelText={t("Monitoring.Yopish")}
      >
        <div className={styles.modal_confirm__content}>
          <h3>{t("Monitoring.Partiyani bekor qilish")}</h3>
          <p>{t("Monitoring.Partiya bekor qilgandan so'ng unga boshqa o'zgartirish kiritib bo'lmaydi")}</p>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
