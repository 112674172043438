import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys } from "../query-keys";
import { ProjectColumnSection } from "../schema";

type Query = {
  sections: ProjectColumnSection[];
};

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
  project_id: number;
}

export function useProjectColumnSections({ enabled, project_id, id }: Params) {
  const initialData = {
    sections: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_COLUMN_SECTIONS, project_id, id],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-section/section-view?id=${id}&project_id=${project_id}`);

      return { sections: data };
    },
    { enabled: enabled && !!project_id }
  );

  return { ...data, ...arg };
}
