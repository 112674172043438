import React, { forwardRef } from "react";
import { ColumnType } from "antd/es/table/interface";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";

import { useAppSelector } from "hooks/redux";

import { paymentFilterType } from "../../../../utils/constants/paymentFilterType";
import { paymentSource } from "../../../../utils/helpers/paymentSource";

import styles from "./paymentIncomeExpenseTable.module.scss";

const IncomeExpensePrintContent = forwardRef<HTMLDivElement | null, { tableColumns: ColumnType<PaymentModel>[] }>(
  ({ tableColumns }, ref) => {
    const { incomeOrExpenseView } = useAppSelector(state => state.paymentReducer);

    const { record } = incomeOrExpenseView!;

    const tableData: Record<string, string> = {
      "counterparts-payment-type": paymentFilterType[record?.type as PaymentTypeEnum],
      "counterparts-payment-payment_type.name": record?.payment_type?.name as string,
      "counterparts-payment-creator.full_name": paymentSource(record)?.name as string,
      "payment-creator.full_name": record?.creator?.full_name as string
    };

    const filteredTableColumns = tableColumns
      ?.filter((col, i) => i !== tableColumns?.length - 1)
      .filter((col, i) => i !== 0);

    const content = (col: ColumnType<PaymentModel>, index: number) => {
      if (tableData[col?.className as keyof typeof tableData]) {
        return tableData[col?.className as keyof typeof tableData];
      }
      if (col?.dataIndex) {
        return (record as unknown as Record<string, any>)?.[col?.dataIndex as unknown as string];
      }

      return col?.render && col?.render(null, record as PaymentModel, index);
    };

    return (
      <div ref={ref} key={record?.id} className={styles.printCheck}>
        {record && (
          <h1>
            {paymentFilterType[record?.type as PaymentTypeEnum]?.toUpperCase()} - {record?.id}
          </h1>
        )}
        <div className={styles.printCheck_container}>
          <div className={styles.printCheck_container_item}>
            {filteredTableColumns?.slice(0, 4)?.map((col, index) => (
              <p key={col?.key}>
                <b>{col?.title as unknown as React.ReactNode}</b>
                <span>{content(col, index)}</span>
              </p>
            ))}
          </div>
          <div className={styles.printCheck_container_item}>
            {filteredTableColumns?.slice(4, filteredTableColumns?.length)?.map((col, index) => (
              <p key={col?.key}>
                <b>{col?.title as unknown as React.ReactNode}</b> <span>{content(col, index)}</span>
              </p>
            ))}
          </div>
        </div>
      </div>
    );
  }
);

export default IncomeExpensePrintContent;
