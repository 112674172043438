import React from "react";

import emptyImg from "../../assets/images/emptyPlan.png";

import styles from "./emptyEstimate.module.scss";

const EmptyEstimate: React.FC = () => {
  return (
    <div className={styles.empty}>
      <div className={styles.empty__content}>
        <img src={emptyImg} alt="EmptyPlan" />
        <h4>Smeta mavjud emas</h4>
        <p>Smetaga ishlarni kiriting, resurslarni biriktirib chiqing.</p>
      </div>
    </div>
  );
};

export default EmptyEstimate;
