import React from "react";
import { useLocation } from "react-router-dom";
import { Form, FormInstance, Input, Popover } from "antd";
import { formRules } from "features/app/utils/constants/formRules";
import { RU } from "features/app/utils/constants/languages";
import { cx } from "features/app/utils/helpers/cx";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { formatterPrice } from "features/supply/utils/helpers/partyModalTableActions";
import { MarketPriceModel } from "features/supply/utils/models/orderModel";
import { PartyFormProductsModel } from "features/supply/utils/models/partyModalFormModel";
import { rootPaths } from "routes/root/rootPaths";

import { useAppSelector } from "hooks/redux";

const { Item, useWatch } = Form;

type Props = {
  index: number;
  form: FormInstance;
  isWarehouse?: boolean;
};

const PartyAmount: React.FC<Props> = ({ index, form, isWarehouse }) => {
  const { pathname } = useLocation();
  const isImpostPage = pathname?.includes(rootPaths.IMPOST);
  const fieldName = ["warehouse_products", index, "amount"];
  const marketName = ["warehouse_products", index, "market_price"];
  const { isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const amount = useWatch(fieldName, form) as string;
  const market = JSON.parse(useWatch(marketName, form) || "{}") as MarketPriceModel;
  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const findProduct = products[index];
    const { value } = e.currentTarget;
    const newValue = localeFormatter(value);
    const productQuantity = parseLocaledString(localeFormatter(String(findProduct?.quantity)));

    form.setFieldValue(["warehouse_products", index, "amount"], localeFormatter(value));
    form.setFieldValue(
      ["warehouse_products", index, "total"],
      formatterPrice(parseLocaledString(newValue) * productQuantity)
    );

    if (!isEmptyArr(cacheProducts)) {
      const findIndex = cacheProducts?.findIndex(item => item.row_id === findProduct.row_id);

      form.setFieldValue(["cache_products", findIndex, "amount"], localeFormatter(value));
      form.setFieldValue(
        ["cache_products", findIndex, "total"],
        formatterPrice(parseLocaledString(value) * parseLocaledString(findProduct?.quantity))
      );
    }
  };

  const content = (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Oxirgi kirim</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-semibold text-gray-700">{market?.market_price?.toLocaleString(RU)}</span>
          <span className="text-sm font-medium text-gray-400">{market?.market_price_currency?.symbol}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Kirim sanasi</span>
        <span className="text-sm font-semibold text-gray-700">{market?.market_price_date}</span>
      </div>
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Partiya</span>
        <span className="text-sm font-semibold text-gray-700">P-{market?.market_price_order_id}</span>
      </div>
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Narx o'zgarishi</span>
        <div
          className={cx("text-sm font-semibold text-gray-500", {
            "text-success-500": market?.change_amount_percent && market?.change_amount_percent < 0,
            "text-error-500": market?.change_amount_percent && market?.change_amount_percent > 0
          })}
        >
          {market?.change_amount_percent && market?.change_amount_percent > 0 ? "+" : ""}
          {market?.change_amount_percent}%
        </div>
      </div>
    </div>
  );

  const isImpostUnitAmount =
    !isEditing &&
    isImpostPage &&
    !updateProduct?.index &&
    !updateProduct?.condition &&
    products?.[index]?.row_id !== updateProduct?.index;

  return (
    <>
      <Item name={[index, "market_price"]} className="hidden" />
      <Item name={[index, "amount"]} rules={isEditing && !isWarehouse ? formRules()! : []}>
        {isImpostUnitAmount ? (
          <div className="flex h-full items-center">
            <Popover arrow={false} placement="top" content={content}>
              <div
                className={cx("rounded-md px-2 py-1 text-sm font-medium", {
                  "bg-success-50 text-success-500": market?.change_amount_percent && market?.change_amount_percent < 0,
                  "bg-error-50 text-error-500": market?.change_amount_percent && market?.change_amount_percent > 0,
                  "bg-gray-100 text-gray-600": !market?.change_amount_percent
                })}
              >
                {amount}
              </div>
            </Popover>
          </div>
        ) : (
          <Input
            step="0.01"
            placeholder="Narxi"
            autoComplete="off"
            disabled={isView && !updateProduct?.condition}
            onChange={e => onChangeAmount(e, index)}
          />
        )}
      </Item>
    </>
  );
};

export default PartyAmount;
