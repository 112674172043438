import { ReactNode } from "react";
import { Tooltip } from "antd";
import { TFunction } from "i18next";

import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";
import { PaymentStatusEnums } from "../../../app/utils/enums/paymentStatusEnums";
import RefreshCwIcon from "../../assets/icons/RefreshCwIcon";
import SmallArrowNarrowDownLeftIcon from "../../assets/icons/SmallArrowNarrowDownLeftIcon";
import SmallArrowNarrowUpRightIcon from "../../assets/icons/SmallArrowNarrowUpRightIcon";
import SwitchHorizontalIcon from "../../assets/icons/SwitchHorizontalIcon";

export function PaymentTypeIcon(
  type: string,
  status?: PaymentStatusEnums,
  t?: TFunction<"translation", undefined>
): ReactNode {
  const tooltipTitles = {
    [PaymentTypeEnum.EXCHANGE]: t ? t("payment.Konvertatsiya") : "Konvertatsiya",
    [PaymentTypeEnum.INCOME]: t ? t("payment.Kirim") : "Kirim",
    [PaymentTypeEnum.EXPENSE]: t ? t("payment.Chiqim") : "Chiqim",
    [PaymentTypeEnum.TRANSFER]: t ? t("payment.O'tkazma") : "O'tkazma"
  };

  const tooltipWrapper = (children: ReactNode) =>
    t ? (
      <Tooltip title={tooltipTitles?.[type as keyof typeof tooltipTitles]}>
        <div className="flex items-center justify-center">{children}</div>
      </Tooltip>
    ) : (
      children
    );

  const icons = {
    [PaymentTypeEnum.EXCHANGE]: <RefreshCwIcon />,
    [PaymentTypeEnum.INCOME]: <SmallArrowNarrowDownLeftIcon />,
    [PaymentTypeEnum.EXPENSE]: <SmallArrowNarrowUpRightIcon />,
    [PaymentTypeEnum.TRANSFER]: <SwitchHorizontalIcon status={status} />
  };

  return tooltipWrapper(icons[type as keyof typeof icons]);
}
