import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";

export const paymentFilterType: Record<PaymentTypeEnum, string> = {
  [PaymentTypeEnum.INCOME]: "Kirim",
  [PaymentTypeEnum.EXPENSE]: "Chiqim",
  [PaymentTypeEnum.TRANSFER]: "O'tkazma",
  [PaymentTypeEnum.EXCHANGE]: "Konvertatsiya",
  [PaymentTypeEnum.DAILY]: "Kunlik",
  [PaymentTypeEnum.ANNUAL]: "Yillik",
  [PaymentTypeEnum.MONTHLY]: "Oylik",
};
