import React from "react";

const FileIcon: React.FC = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 1.13473V3.2C7 3.48003 7 3.62004 7.0545 3.727C7.10243 3.82108 7.17892 3.89757 7.273 3.9455C7.37996 4 7.51997 4 7.8 4H9.86527M10 4.99411V8.6C10 9.44008 10 9.86012 9.83651 10.181C9.6927 10.4632 9.46323 10.6927 9.18099 10.8365C8.86012 11 8.44008 11 7.6 11H4.4C3.55992 11 3.13988 11 2.81901 10.8365C2.53677 10.6927 2.3073 10.4632 2.16349 10.181C2 9.86012 2 9.44008 2 8.6V3.4C2 2.55992 2 2.13988 2.16349 1.81901C2.3073 1.53677 2.53677 1.3073 2.81901 1.16349C3.13988 1 3.55992 1 4.4 1H6.00589C6.37277 1 6.55622 1 6.72885 1.04145C6.8819 1.07819 7.02822 1.1388 7.16243 1.22104C7.3138 1.3138 7.44352 1.44352 7.70294 1.70294L9.29706 3.29706C9.55648 3.55648 9.6862 3.6862 9.77896 3.83757C9.8612 3.97178 9.92181 4.1181 9.95855 4.27115C10 4.44378 10 4.62723 10 4.99411Z"
        stroke="#7F56D9" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default FileIcon;