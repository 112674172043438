import React, { useEffect } from "react";
import { Button, Form, Input, Spin } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../../app/components/modal-confirm/ModalConfirm";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { areObjectsEqual } from "../../../../../../app/utils/helpers/areObjectsEqual";
import { useCreateTask, useDeleteTask, useUpdateTask } from "../../../../../service/mutations";

import styles from "../task.module.scss";
import titleStyles from "./title.module.scss";

type Props = {
  sectionId: number;
  id?: number;
  name?: string;
  afterSuccess?: () => void;
};

interface FormModel {
  name: string;
}

const { Item } = Form;

const Title: React.FC<Props> = ({ afterSuccess, sectionId, id, name }) => {
  const [formInstance] = Form.useForm<FormModel>();
  const createTitle = useCreateTask();
  const updateTitle = useUpdateTask();
  const deleteTitle = useDeleteTask();
  const { t } = useTranslation();

  useEffect(() => {
    if (name) {
      formInstance.setFieldsValue({
        name
      });
    } else {
      formInstance.setFieldsValue({
        name: undefined
      });
    }
  }, [name, formInstance]);

  const onFinish = (values: FormModel) => {
    const isEqual = areObjectsEqual<FormModel, FormModel>(values, {
      name: name ?? ""
    });

    if (isEqual) {
      if (id) {
        return updateTitle.mutateAsync({
          id,
          section_id: sectionId,
          is_title: true,
          name: values.name
        });
      }
      return createTitle
        .mutateAsync({
          name: values.name,
          section_id: sectionId,
          is_title: true
        })
        .then(() => {
          afterSuccess && afterSuccess();
          formInstance.resetFields();
        });
    }
  };

  const onBlurForm = () => {
    formInstance.submit();
  };

  const onDelete = () => deleteTitle.mutateAsync(id!);

  return (
    <Spin indicator={LoadingIndicator} spinning={createTitle.isLoading || updateTitle.isLoading}>
      <div className={styles.task__header}>
        <Form
          onFinish={onFinish}
          form={formInstance}
          autoComplete="off"
          onBlur={onBlurForm}
          className={titleStyles.form}
        >
          <Item name="name" rules={formRules()} className={classNames(styles.form__item, styles.title)}>
            <Input placeholder={t("project.Nomi")} />
          </Item>
          <Button className="hidden" htmlType="submit" />
          {name && (
            <div className={classNames(titleStyles.delete)}>
              <ModalConfirm onOk={onDelete}>
                <span>
                  <DeleteIcon />
                </span>
              </ModalConfirm>
            </div>
          )}
        </Form>
      </div>
    </Spin>
  );
};

export default Title;
