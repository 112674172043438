import { removeNaN } from "./removeNaN";

export const negativeLocaleFormatter = (value: string) => {
  const isNegative = value[0] === "-";
  const newValue = removeNaN(value);

  if ((isNegative && newValue.length === 0) || value === "0-") {
    return "-";
  }
  const arr = newValue?.split(".");

  if (isNegative) {
    if (arr.length === 1) {
      return newValue ? `-${(+newValue).toLocaleString("ru")}` : "";
    }
    if (arr.length === 2) {
      return newValue ? `-${(+arr[0]).toLocaleString("ru")}.${arr[1]?.slice(0, 20)}` : "";
    }
    return newValue ? `-${(+arr[0]).toLocaleString("ru")}.${arr[1]}` : "";
  }
  if (arr.length === 1) {
    return newValue ? (+newValue).toLocaleString("ru") : "";
  }
  if (arr.length === 2) {
    return newValue ? `${(+arr[0]).toLocaleString("ru")}.${arr[1]?.slice(0, 20)}` : "";
  }
  return newValue ? `${(+arr[0]).toLocaleString("ru")}.${arr[1]}` : "";
};
