import { CustomFieldLocationEnum } from "../../../app/utils/enums/customFieldLocationEnum";

export const CustomFieldLocationValue: Record<CustomFieldLocationEnum, string> = {
  [CustomFieldLocationEnum.TASK]: "Ishlar",
  [CustomFieldLocationEnum.ORDER]: "Partiyalar",
  [CustomFieldLocationEnum.PROJECT]: "Loyihalar",
  [CustomFieldLocationEnum.PAYMENT]: "To'lovlar",
  [CustomFieldLocationEnum.SECTION]: "Bo'limlar",
  [CustomFieldLocationEnum.INVENTORY]: "Inventar",
  [CustomFieldLocationEnum.COMPANY_PERSON]: "Kontragent",
  [CustomFieldLocationEnum.TASK_PROGRESS]: "Ish grafigi",
  [CustomFieldLocationEnum.TASK_PRODUCT]: "Ish mahsulotlari",
  [CustomFieldLocationEnum.WAREHOUSE_PRODUCT]: "Buyurtmalar",
  [CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER]: "Takliflar",
  [CustomFieldLocationEnum.INVENTORY_DEFECT]: "Yaroqsiz inventar",
  [CustomFieldLocationEnum.WAREHOUSE_DEFECT]: "Yaroqsiz mahsulotlar",
  [CustomFieldLocationEnum.WAREHOUSE_TRANSFER]: "Omborxona o'tkazmalar",
};
