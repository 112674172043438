import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, MenuProps, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { Table } from "components";

import { useAppDispatch } from "../../../../../hooks/redux";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ConfirmationUsersList from "../../../../app/components/confirmation-users-list/ConfirmationUsersList";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import PopoverShortName from "../../../../app/components/popover-short-name/PopoverShortName";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { cx } from "../../../../app/utils/helpers/cx";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import { GetComponentProps } from "../../../../app/utils/models/getComponentPropsType";
import { MenuEventHandlerModel } from "../../../../app/utils/models/menuEventHandlerModel";
// import { defectStatusIcons } from "../../../../warehouse/utils/constants/DefectStatusIcons";
// import { defectStatusValue } from "../../../../warehouse/utils/constants/defectStatusValue";
import { useGetDefects } from "../../../service/queries";
import { defectTypes } from "../../../utils/constants/defectTypes";
import { inventoryQueryKeys } from "../../../utils/constants/inventoryQueryKeys";
import { DefectStatusEnum } from "../../../utils/enums/defectStatusEnum";
import { DefectModel } from "../../../utils/models/defectModel";
import { InventoryDetailModel } from "../../../utils/models/inventoryModel";
import Top from "../top/Top";

import DefectView from "./defect-view/DefectView";
import DeleteDefect from "./delete-defect/DeleteDefect";

import styles from "./bottom.module.scss";

import useDebounce from "components/use-debounce/use-debounce";

const Bottom = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const [openDefect, setOpenDefect] = useState(false);
  const [record, setDefectId] = useState<DefectModel>();
  const { data: defects, isLoading } = useGetDefects();
  const { setVisible } = chatActions;
  const { setDefectModalData } = inventoryReducerActions;
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.INVENTORY_DEFECTS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.INVENTORY_DEFECTS);

  const onOpenChat = (record: DefectModel) => {
    setDefectId(record);
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.INVENTORY_DEFECTS,
        dataKeys: [inventoryQueryKeys.DEFECT_VIEW, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
  };

  const onRow: GetComponentProps<DefectModel> | undefined = record => ({
    onClick: () => {
      setDefectId(record);
      setOpenDefect(true);
    }
  });

  const onUpdateDefect = (defect: DefectModel) => {
    dispatch(
      setDefectModalData({
        visible: true,
        product: defect! as DefectModel & InventoryDetailModel
      })
    );
  };

  const dropdownItems = (record: DefectModel) => {
    const newItems = [
      {
        key: "1",
        label: (
          <div onClick={() => onOpenChat(record)} className={styles.dropdown__item}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      }
    ];

    if (record?.status === DefectStatusEnum.OPEN) {
      newItems.push({
        key: "2",
        label: (
          <div onClick={() => onUpdateDefect(record)} className={styles.dropdown__item}>
            <EditIcon />
            {t("inventory.Tahrirlash")}
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (record?.status === DefectStatusEnum.OPEN) {
      newItems.push({
        key: "3",
        label: <DeleteDefect id={record.id} />,
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    return newItems;
  };

  const [renderColumns, setColumns] = useState<ColumnsType<DefectModel>>([]);
  const columns = [
    {
      title: `${t("Inventory.Nomi")}`,
      render: (record: DefectModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "inventory-name")?.width;

        return (
          <div className={cx("resource")}>
            <div className={styles.resource}>MEX</div>
            {record?.inventory?.name && record?.inventory?.code && (
              <span className={styles.bold}>
                <PopoverShortName title={record?.inventory?.name} length={((columnWidth as number) + 150) / 10} />
                <span>
                  (<PopoverShortName title={record?.inventory?.code} length={((columnWidth as number) + 150) / 10} />)
                </span>
              </span>
            )}
          </div>
        );
      },
      width: 750,
      className: "inventory-name"
    },
    {
      title: `${t("Inventory.Kontragent")}`,
      render: (record: DefectModel) => (
        <div className={styles.agent}>
          <Popover title={record?.company_person?.name} overlayClassName={styles.popover}>
            <span className={styles.agent__name}>{getFirstLetter(record?.company_person?.name)}</span>
          </Popover>
        </div>
      ),
      width: 300,
      className: "inventory-agent"
    },
    {
      title: `${t("Inventory.Kiritilgan sana")}`,
      dataIndex: "created_at_inventory",
      width: 300,
      className: "inventory-date"
    },
    {
      title: `${t("Inventory.Yaroqsizga chiqarildi")}`,
      dataIndex: "created_at_defect",
      width: 350,
      className: "inventory-date"
    },
    {
      title: `${t("Inventory.Yaroqsizlik turi")}`,
      render: (record: DefectModel) => <span className={styles[record?.type]}>{defectTypes[record?.type]}</span>,
      width: 350,
      className: "inventory-defect-type"
    },
    {
      title: `${t("Inventory.Guvohlar")}`,
      render: (record: DefectModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "inventory-victims")?.width;

        return (
          <ConfirmationUsersList
            record={record}
            length={columnWidth ? (columnWidth as number) / record?.confirmation_users?.length : 10}
          />
        );
      },
      width: 350,
      className: "inventory-victims"
    },
    {
      title: "",
      render: (record: DefectModel) => (
        <Dropdown trigger={["click"]} menu={{ items: dropdownItems(record) as MenuProps["items"] }}>
          <UnreadMessageDots count={record?.unread_message_count} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
      width: 70,
      className: "action-dots"
    }
  ];

  const rowKey = (record: DefectModel) => record.id;

  const footer = () => (
    <div className={styles.pagination}>
      <Pagination
        paginationProps={{
          total: defects?.total
        }}
      />
    </div>
  );

  useEffect(() => {
    if (tableConfig?.width_data && tableConfig?.width_data?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }else{
      setColumns(columns);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.INVENTORY_DEFECTS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<DefectModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.bottom}>
      <div className={styles.table}>
        <Table<DefectModel>
          onChangeColumns={onChangeColumns}
          dataSource={defects?.data}
          columns={renderColumns}
          title={() => <Top />}
          pagination={false}
          rowKey={rowKey}
          onRow={onRow}
          rowClassName={() => styles.row}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{ spinning: isLoading, indicator: LoadingIndicator }}
          footer={defects?.total && defects?.total > 10 ? footer : () => false}
        />
      </div>
      <DefectView open={openDefect} setOpen={setOpenDefect} record={record!} setId={setDefectId as never} />
    </div>
  );
};

export default Bottom;
