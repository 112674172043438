import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import GanttChartDateSelect from "../gantt-chart-date-select/GanttChartDateSelect";
import styles from "./ganttChartFooter.module.scss";

const GanttChartFooter = () => {
  const { lineSize } = useAppSelector((state) => state.ganttReducer);
  return (
    <div
      className={styles.container}
      style={{ width: `calc(100vw - ${(lineSize || 0) + 48}px)` }}
    >
      <GanttChartDateSelect />
    </div>
  );
};

export default GanttChartFooter;
