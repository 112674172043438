import React from "react";
import { Segmented } from "antd";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import CompletedWorks from "../../../../components/detailed-project/work/tables/CompletedWorks";
import ResourcesWork from "../../../../components/detailed-project/work/tables/ResourcesWork";
import Works from "../../../../components/detailed-project/work/tables/Works";
import Top from "../../../../components/detailed-project/work/top/Top";
import TopActions from "../../../../components/detailed-project/work/top-actions/TopActions";
import { SegmentedEnums } from "../../../../utils/enums/segmentedEnum";

import styles from "./projectWork.module.scss";

export const ProjectWork: React.FC = () => {
  const { queries, append } = useQueryParams();
  const tabQueryValue = queries()?.[queryParamsKeys.TAB] ?? SegmentedEnums.WORKS;

  const onChange = (value: string | number) => {
    append(queryParamsKeys.TAB, value, true);
  };

  return (
    <div className={styles.project_work}>
      <TopActions />
      <div className={styles.content}>
        <Top />
        <div className={styles.content__inner}>
          <div className={styles.segmented}>
            <Segmented
              options={[
                { label: "Ishlar bo’limi", value: SegmentedEnums.WORKS },
                { label: "Bajarilgan ishlar", value: SegmentedEnums.COMPLETED_WORKS },
                { label: "Resurs sarfi", value: SegmentedEnums.RESOURCES }
              ]}
              value={tabQueryValue}
              onChange={onChange}
              className={styles.segmented__inner}
            />
          </div>
          {tabQueryValue === SegmentedEnums.WORKS && <Works />}
          {tabQueryValue === SegmentedEnums.COMPLETED_WORKS && <CompletedWorks />}
          {tabQueryValue === SegmentedEnums.RESOURCES && <ResourcesWork />}
        </div>
      </div>
    </div>
  );
};
