import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys, WidgetView } from "..";

interface Query {
  widgetViews: WidgetView[];
}

interface Params extends Pick<UseQueryOptions, "enabled"> {}

export function useWidgetView({ enabled = false }: Params) {
  const initialValue: Query = { widgetViews: [] };

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.WIDGET_VIEW],
    async () => {
      const { data } = await $api.get("widget/view");

      // const widgetViews: WidgetView[] = data?.data?.map((item: WidgetView) => widgetViewSchema.parse(item));

      return { widgetViews: data?.data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
