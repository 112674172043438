import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Popover } from "antd";
import { useTranslation } from "react-i18next";

import { warehouseProductsAction } from "../../../../../../../../store/reducers/warehouseProductsReducer";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { WarehouseProductModel } from "../../../../../../../warehouse/utils/models/WarehouseProductModel";

type Props = {
  name: (string | number)[];
  form: FormInstance;
};

const { useWatch } = Form;

const ResourceAvailability: React.FC<Props> = ({ form, name }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setResource } = warehouseProductsAction;
  const warehouseTotalQuantity = useWatch([...name, "warehouse_total_quantity"], form) as number;
  const quantity = parseLocaledString(useWatch([...name, "quantity"], form) as string);
  const symbol = useWatch([...name, "unit_symbol"], form) as string;
  const product = JSON.parse(useWatch([...name, "this_product"], form) || "{}") as WarehouseProductModel;

  const percent = useMemo(() => {
    const float = parseFloat(((warehouseTotalQuantity || 0) / quantity).toFixed(2));

    if (!quantity || float >= 1) return 100;

    return parseFloat((((warehouseTotalQuantity || 0) * 100) / quantity).toFixed(2));
  }, [quantity, warehouseTotalQuantity]);

  const content = (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Buyurtma qilingan")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{quantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Mavjud")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{warehouseTotalQuantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
    </div>
  );

  const onOpenResource = () => {
    dispatch(
      setResource({
        visible: true,
        product
      })
    );
  };

  return (
    <div className="flex h-full items-center pl-2.5 text-sm font-medium">
      <Popover arrow={false} placement="top" trigger={["hover"]} content={content}>
        <div
          onClick={onOpenResource}
          className={cx("cursor-pointer rounded-2xl px-3 py-1", {
            "bg-error-50 text-error-500": percent >= 0 && percent <= 30,
            "bg-warning-50 text-warning-500": percent > 30 && percent <= 70,
            "bg-success-50 text-success-500": percent > 70
          })}
        >
          {percent} %
        </div>
      </Popover>
    </div>
  );
};

export default ResourceAvailability;
