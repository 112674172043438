import React from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer, Form } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import { colors } from "../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { getBaseCurrency } from "../../../../app/utils/helpers/baseCurrency";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import { useCreateExchange } from "../../../service/mutation";
import { ExchangeFormModel } from "../../../utils/models/exchangeFormModel";

import Body from "./body/body";
import Bottom from "./bottom/bottom";
import Top from "./top/top";

import styles from "./exchange-drawer.module.scss";

const ExchangeDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm<ExchangeFormModel>();
  const { setExchange } = paymentReducerActions;
  const { visible } = useAppSelector(state => state.paymentReducer.exchange);

  const basicCurrency = getBaseCurrency();
  const createExchange = useCreateExchange();
  const secondCurrency = getBaseCurrency("second");

  const onAfterOpen = (open: boolean) => {
    if (open) {
      form.setFieldsValue({
        is_change_currency: false,
        first_currency: JSON.stringify(secondCurrency),
        second_currency: JSON.stringify(basicCurrency),
        currency_amount: localeFormatter(String(Number(secondCurrency?.amount) / Number(basicCurrency?.amount))),
        view_currency_amount: localeFormatter(String(Number(secondCurrency?.amount) / Number(basicCurrency?.amount))),
        expense_payment: {
          currency_id: secondCurrency?.id,
          payment_types: [
            {
              projects: [],
              id: undefined,
              financials: [],
              amount: undefined,
              max_amount: undefined
            }
          ]
        },
        income_payment: {
          currency_id: basicCurrency?.id
        }
      });
    } else {
      form.resetFields();
    }
  };

  const onClose = () => {
    dispatch(
      setExchange({
        visible: false
      })
    );
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: ExchangeFormModel) => {
    const reqData = {
      file_ids: values.file_ids,
      description: values.description,
      currency_amount: parseLocaledString(values?.currency_amount || "0"),
      date: values?.date ? dayjs(values?.date).format(dayjsFormats.DATE) : undefined,
      expense_payment: {
        cash_id: values.expense_payment?.cash_id,
        currency_id: values.expense_payment?.currency_id,
        payment_types: values?.expense_payment?.payment_types?.map(item => ({
          id: item.id,
          amount: parseLocaledString(item?.amount || "0"),
          projects: item?.projects?.map(project => ({
            id: project?.id,
            amount: parseLocaledString(project?.amount || "0")
          })),
          financials: item?.financials?.map(finance => ({
            id: finance?.id,
            amount: parseLocaledString(finance?.amount || "0")
          }))
        }))
      },
      income_payment: {
        cash_id: values?.income_payment?.cash_id,
        currency_id: values.income_payment?.currency_id,
        payment_type: {
          id: values?.income_payment?.payment_type?.id,
          amount: parseLocaledString(values?.income_payment?.payment_type?.amount || "0"),
          projects: values?.income_payment?.payment_type?.projects?.map(project => ({
            id: project?.id,
            amount: parseLocaledString(project?.amount || "0")
          }))
        }
      }
    };

    createExchange.mutateAsync(reqData).then(onClose);
  };

  const footer = (
    <div className={styles.footer}>
      <Button onClick={onClose}>{t("payment.Yopish")}</Button>
      <Button loading={createExchange.isLoading} onClick={onOk} type="primary">
        {t("payment.Konvertatsiya qilish")}
      </Button>
    </div>
  );

  return (
    <Drawer
      width="42rem"
      open={visible}
      footer={footer}
      closeIcon={
        <div onClick={onClose} className="flex h-max w-max items-center justify-center">
          <XCloseIcon width={20} color={colors.GRAY_400} />
        </div>
      }
      afterOpenChange={onAfterOpen}
      rootClassName={styles.exchange}
      title={t("payment.Konvertatsiya")}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <div className="flex flex-col gap-3">
          <Top form={form} />
          <Body form={form} />
          <Bottom form={form} />
        </div>
      </Form>
    </Drawer>
  );
};

export default ExchangeDrawer;
