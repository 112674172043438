import {
  Marker,
  Circle,
  TileLayer,
  MapContainer,
  useMapEvents,
} from "react-leaflet";
import React, { useEffect } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import styles from "../address.module.scss";


const iconPerson = new L.Icon({
  iconUrl: "/MapPin.svg",
  iconRetinaUrl: "/MapPin.svg",
  iconSize: new L.Point(25, 41),
});
interface IProps {
  center: {
    lat: number;
    lng: number;
  };
  setCenter: React.Dispatch<
    React.SetStateAction<{
      lat: number;
      lng: number;
    }>
    >;
}

const AddressMap: React.FC<IProps> = ({ center, setCenter }) => {
  const LocationMarker = () => {
    const map = useMapEvents({
      click(e) {
        setCenter(e.latlng);
      },
      locationfound() {
        map.flyTo(center);
      },
    });

    useEffect(() => {
     
      map.flyTo(center)
    }, [map,center]);

    useEffect(() => {
      setTimeout(() => {
        map.invalidateSize();
      }, 150);
    }, [map]);

    return center === null ? null : (
      <Marker position={center} icon={iconPerson}>
        <Circle center={center} radius={0} />
      </Marker>
    );
  };


  return (
    <MapContainer
      zoom={10}
      scrollWheelZoom={true}
      style={{ height: "100%" }}
      className={styles.address_map_container}
      center={{ lat: center?.lat, lng: center?.lng }}
    >
      <TileLayer
        url="https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        subdomains={["mt0", "mt1", "mt2", "mt3"]}
      />
      <LocationMarker />
    </MapContainer>
  );
};

export default AddressMap;
