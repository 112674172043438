import { ChatModel } from "features/app/utils/models/chatModel";
import React from "react";

import styles from "./chat.module.scss";

type Props = Pick<ChatModel, "files" | "message"> & { hour: string };

const OnlyMessage: React.FC<Props> = ({ files, message, hour }) => {
  return (
    <div>
      {message && files?.length === 0 && (
        <div className={styles.text}>
          <p>{message}</p>
          <p className={styles.date}>{hour}</p>
        </div>
      )}
    </div>
  );
};

export default OnlyMessage;
