// export const getLightColor = () => {
//   const letters = "ABCDE".split("");
//   let color = "#";

//   for (let i = 0; i < 3; i++) {
//     color += letters[Math.floor(Math.random() * letters.length)];
//   }

//   return color;
// };

export const getHueFromLetter = (letter: string) => {
  const charCode = letter?.toUpperCase().charCodeAt(0);
  return ((charCode - 65) * 360) / 26; // Map to 0-360 (A-Z)
};

export const getLightColor = (letter: string) => {
  const hue = getHueFromLetter(letter);
  return `hsl(${hue}, 80%, 95%)`; // Lightness at 80% for a light color
};
