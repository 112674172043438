import React from "react";

const SwitchCircleHorizontalIcon: React.FC<{ rotate?: number }> = ({ rotate = 0 }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${rotate}deg)`
    }}
  >
    <rect width="32" height="32" rx="16" fill="#1E90FF" />
    <path
      d="M21.3337 19.3333H10.667M10.667 19.3333L13.3337 16.6667M10.667 19.3333L13.3337 22M10.667 12.6667H21.3337M21.3337 12.6667L18.667 10M21.3337 12.6667L18.667 15.3333"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwitchCircleHorizontalIcon;
