import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import ProjectIcon from "features/app/assets/icons/ProjectIcon";
import FilterDateRange from "features/app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterPricesRange from "features/app/components/filter-drawer/filter-prices-range/FilterPricesRange";
import FilterSelect from "features/app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "features/app/components/filter-drawer/FilterDrawer";
import FiltersCount from "features/app/components/filter-drawer/filters-count/FiltersCount";
import { useGetCompanyPersons, useGetProjectsSelect, useProjectSelect } from "features/app/service/queries";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import UsersIcon from "features/payment/assets/icons/UsersIcon";
import ZapIcon from "features/projects/assets/icons/ZapIcon";
import { orderPaymentStatus } from "features/supply/utils/helpers/orderPaymentFunc";
import FilterLinesIcon from "features/warehouse/assets/icons/FilterLinesIcon";
import queryString from "query-string";

import { useQueryParams } from "hooks/useQueryParams";

import styles from "./workFilterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { paymentWorksTableDefaultData } from "features/payment/utils/constants/paymentWorksTableDefaultData";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useExportPaymentWorksExcel } from "features/payment/service/mutation";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { useTranslation } from "react-i18next";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { useRoutePermissions } from "hooks/useRoutePermissions";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";

const queryStringArr = [
  queryParamsKeys.MIN_COMPLETED_DATE,
  queryParamsKeys.MAX_COMPLETED_DATE,
  queryParamsKeys.MIN_TOTAL_AMOUNT,
  queryParamsKeys.MAX_TOTAL_AMOUNT,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.PAYMENT_STATUSES,
  queryParamsKeys.CONFIRMATION_PAYMENTS,
  queryParamsKeys.COMPANY_PERSON_IDS
];

const WorkDrawer: React.FC = () => {
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const workActions = actions("Ishlar uchun to'lovlar");
  const { queries, necessaryQueries } = useQueryParams();
  const { min_completed_date, max_completed_date, search } = queries();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const exportExcelMutate = useExportPaymentWorksExcel();

  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.AGENT_IDS] as string[] | null
  );

  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );

  const [sectionSelected, setSectionSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.SECTION_IDS_ARR] as string[] | null
  );

  const [paymentStatusSelected, setPaymentStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_STATUSES] as string[] | null
  );

  const [startPriceValue, setStartPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MIN_AMOUNT] as string
  );
  const [endPriceValue, setEndPriceValue] = useState<string>(
    queryString.parse(location.search)[queryParamsKeys.MAX_AMOUNT] as string
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([null, null]);

  const [projectSelected, setProjectSelected] = useState(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const { data: counterpartsSelect } = useGetCompanyPersons();
  const { data: projects } = useProjectSelect({ enabled: open });

  const counterparts = useMemo(() => counterpartsSelect?.map(item => ({ ...item })), [counterpartsSelect]);

  const paymentStatusData = useMemo(
    () => [
      {
        id: orderPaymentStatus.PAID,
        name: `${t("Counterparts.To'langan")}`
      },
      {
        id: orderPaymentStatus.PARTLY,
        name: `${t("Counterparts.Qisman")}`
      },
      {
        id: orderPaymentStatus.UNPAID,
        name: `${t("Counterparts.To'lanmagan")}`
      }
    ],
    []
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const filterButton = (
    <Button onClick={onOpenFilter}>
      <FilterLinesIcon /> {t("Counterparts.Filter")}
      <FiltersCount queryStringArr={queryStringArr} />
    </Button>
  );

  const dateDefaultValue = (isMin?: boolean) => {
    let queryDefaultDate = null;

    if (max_completed_date) {
      queryDefaultDate = dayjs(max_completed_date, dayjsFormats.DATE);
    }

    if (isMin) {
      if (min_completed_date) {
        queryDefaultDate = dayjs(min_completed_date, dayjsFormats.DATE);
      }

      return dateValue?.[0] ? dateValue[0] : queryDefaultDate;
    }

    return dateValue?.[1] ? dateValue[1] : queryDefaultDate;
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      setDateValue([dateDefaultValue(true), dateDefaultValue()]);
    }
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig: paymentWorksTableDefaultData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE
    );

    const reqBody = {
      columns: data,
      company_person_ids: companyPersonsSelected,
      project_ids: projectSelected,
      min_completed_date: dateValue![0] ? dayjs(dateValue![0]).format(dayjsFormats.DATE) : null,
      max_completed_date: dateValue![1] ? dayjs(dateValue![1]).format(dayjsFormats.DATE) : null,
      min_total_amount: Number(parseLocaledString(startPriceValue)),
      max_total_amount: Number(parseLocaledString(endPriceValue)),
      payment_statuses: paymentStatusSelected,
      ...otherParamsData
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = workActions?.create ? (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Counterparts.Yuklab olish")}
    </Button>
  ) : null;

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      height="max-content"
      footerContent={footerContent}
      afterOpenChange={onAfterOpen}
      filterButton={filterButton}
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.AGENT_IDS,
          selectedOptions: usersSelected
        },

        {
          queryKey: queryParamsKeys.PAYMENT_STATUSES,
          selectedOptions: paymentStatusSelected
        },

        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectSelected
        },
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        }
      ]}
      selectedValueArr={[
        {
          queryKey: queryParamsKeys.MIN_TOTAL_AMOUNT,
          selectedValue: startPriceValue
        },
        {
          queryKey: queryParamsKeys.MAX_TOTAL_AMOUNT,
          selectedValue: endPriceValue
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_COMPLETED_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_COMPLETED_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedValueArr={[setStartPriceValue, setEndPriceValue]}
      setSelectedOptionsArr={
        [
          setUsersSelected,
          setPaymentStatusSelected,
          setProjectSelected,
          setCompanyPersonsSelected,
          setSectionSelected
        ] as never
      }
    >
      <div className={styles.content}>
        <FilterTreeSelect
          data={projects}
          multiple={true}
          placeholder={t("Counterparts.Loyiha")}
          icon={<ProjectIcon />}
          selectedOptions={projectSelected}
          setSelectedOptions={setProjectSelected}
          showSearch
        />

        <FilterSelect
          showSearch
          data={counterparts}
          multiple="multiple"
          icon={<UsersIcon />}
          placeholder={t("Counterparts.Kontragent")}
          selectedOptions={companyPersonsSelected}
          setSelectedOptions={setCompanyPersonsSelected}
        />

        <FilterSelect
          showSearch
          icon={<ZapIcon />}
          multiple="multiple"
          data={paymentStatusData}
          placeholder={t("Counterparts.To'lov statusi")}
          selectedOptions={paymentStatusSelected}
          setSelectedOptions={setPaymentStatusSelected}
        />

        <FilterDateRange value={dateValue} setValue={setDateValue} />
        <FilterPricesRange
          placeholder={t("Counterparts.Umumiy summa")}
          endPriceValue={endPriceValue}
          startPriceValue={startPriceValue}
          setEndPriceValue={setEndPriceValue}
          setStartPriceValue={setStartPriceValue}
        />
      </div>
    </FilterDrawer>
  );
};

export default WorkDrawer;
