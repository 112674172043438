import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

export enum GanttDateType {
  DAY = "day",
  MONTH = "month",
  YEAR = "year"
}

export const GanttDateWidth = {
  [GanttDateType.DAY]: 40,
  [GanttDateType.MONTH]: 240,
  [GanttDateType.YEAR]: 360,
};

export interface GanttTableSetting {
  "--gantt-chart-table-name"?: number;
  "--gantt-chart-table-percent"?: number;
  "--gantt-chart-table-responsible"?: number;
  "--gantt-chart-table-status"?: number;
  "--gantt-chart-table-start-date"?: number;
  "--gantt-chart-table-due-date"?: number;
}

export interface GanttSettingsModel {
  percent: boolean;
  confirmation_person: boolean;
  status: boolean;
  start_date: boolean;
  due_date: boolean;
}

interface GanttReducerModel {
  type: GanttDateType;
  lineSize: number;
  settings: GanttSettingsModel;
  tableSetting: GanttTableSetting;
}

const initialState: GanttReducerModel = {
  type: GanttDateType.DAY,
  lineSize: Number(localStorage.getItem("ganttLineSize")) || 500,
  settings: localStorage.getItem("ganttTableHeaderSetting")
    ? JSON.parse(String(localStorage.getItem("ganttTableHeaderSetting")))
    : {
        percent: true,
        confirmation_person: true,
        status: true,
        start_date: true,
        due_date: true,
      },
  tableSetting: localStorage.getItem("ganttTableSetting")
    ? JSON.parse(String(localStorage.getItem("ganttTableSetting")))
    : {
        "--gantt-chart-table-name": 391,
        "--gantt-chart-table-percent": 96,
        "--gantt-chart-table-responsible": 200,
        "--gantt-chart-table-status": 150,
        "--gantt-chart-table-start-date": 200,
        "--gantt-chart-table-due-date": 200,
      },
};

const defectViewSlice = createSlice({
  name: sliceNames.GANTT,
  initialState,
  reducers: {
    setGanttType: (state, action: PayloadAction<GanttDateType>) => {
      state.type = action.payload;
    },
    setGanttLineSize: (state, action: PayloadAction<number>) => {
      state.lineSize = action.payload;
      localStorage.setItem("ganttLineSize", String(action.payload));
    },
    setGanttTableSetting: (state, action: PayloadAction<GanttTableSetting>) => {
      state.tableSetting = {
        ...state.tableSetting,
        ...action.payload,
      };

      localStorage.setItem(
        "ganttTableSetting",
        JSON.stringify({
          ...state.tableSetting,
          ...action.payload,
        })
      );
    },
    setGanttTableHeaderSettings: (state, action: PayloadAction<GanttSettingsModel>) => {
      state.settings = { ...state.settings, ...action.payload };
      localStorage.setItem("ganttTableHeaderSetting", JSON.stringify({ ...state.settings, ...action.payload }));
    },
  },
});

export default defectViewSlice.reducer;
export const { setGanttType, setGanttLineSize, setGanttTableSetting, setGanttTableHeaderSettings } = defectViewSlice.actions;
