import React, { useEffect, useMemo, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Input, Popover } from "antd";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import { SearchIcon } from "features/app/assets/icons/SearchIcon";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { useGetUsersSelect } from "features/app/service/queries";
import { useTranslation } from "react-i18next";

import { User } from "modules/common";
import { WidgetSettingsForm } from "modules/dashboard";
import { CreateWidgetForm, UpdateWidgetForm } from "modules/dashboard/forms/schema";

import styles from "./append-employees.module.scss";

type Props = {
  children: React.ReactNode;
  formInstance: UseFormReturn<CreateWidgetForm | UpdateWidgetForm | WidgetSettingsForm>;
  data: User[] | User | undefined;
  setData: (data: User[] | User | undefined) => void;
  initialValue?: User[] | User;
  notMe?: boolean;
  description?: string;
  title?: string;
};

const AppendEmployees: React.FC<Props> = ({
  children,
  formInstance,
  data,
  setData,
  initialValue,
  notMe,
  description,
  title
}) => {
  const { t } = useTranslation();
  const { data: users } = useGetUsersSelect(true, notMe);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (initialValue) {
      setData(initialValue);
    }
  }, []);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const filteredData: typeof users = useMemo(() => {
    if (searchText) {
      return users?.filter((user: User) => {
        const name = user.full_name.replace(/\s/g, "").toLowerCase();

        return name.includes(searchText);
      });
    }

    return users;
  }, [searchText, users]);

  const isSelected = (user: User) => {
    if (Array.isArray(data)) {
      const userIds = data.map(user => user.id);

      return userIds.includes(user.id);
    }
    return user.id === data?.id;
  };

  const onSelectUser = (user: User) => {
    let newData: User | User[] | undefined;

    if (isSelected(user)) {
      if (Array.isArray(data)) {
        newData = data.filter(item => item.id !== user.id);
      } else {
        newData = [];
      }
    } else if (Array.isArray(data)) {
      newData = [...data, user];
    } else {
      newData = user;
    }

    setData(newData);

    formInstance.setValue("user_ids", Array.isArray(newData) ? newData?.map(item => item.id) : [newData.id]);
  };

  const content = (
    <div className={styles.popover__content}>
      <div className={styles.popover__content__top}>
        <Input onChange={onSearch} prefix={<SearchIcon />} placeholder={t("Kassa.Qidiruv")} />
      </div>
      <div className={styles.popover__content__bottom}>
        {filteredData && filteredData?.length > 0 ? (
          filteredData?.map(user => (
            <div
              key={user.id}
              className={`${styles.user} ${isSelected(user) ? styles.active : ""}`}
              onClick={() => onSelectUser(user)}
            >
              {user?.image ? (
                <img alt={user.full_name} src={user.image} />
              ) : (
                <NotUserImage name={user?.full_name} width={24} />
              )}
              <p>{user.full_name}</p>
              <div className={styles.check_icon}>
                <CheckIcon />
              </div>
            </div>
          ))
        ) : (
          <SelectNotContent title={title} description={description} />
        )}
      </div>
    </div>
  );

  return (
    <Popover content={content} trigger="click" placement="bottomLeft" overlayClassName={styles.popover}>
      {children}
    </Popover>
  );
};

export default AppendEmployees;
