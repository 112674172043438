import React from "react";

const UyqurIcon: React.FC = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" fill="#1E90FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 10.752H9V48H16.5V48.0001H24V48.0003H31.5V47.9997H39V33.7917H31.5V26.1123H24V18.4321H16.5V10.752Z"
        fill="white"
      />
    </svg>
  );
};

export default UyqurIcon;
