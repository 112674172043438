export const settingsPaths = {
  DETAILED_WAREHOUSE: "/warehouse/:id",
  PRODUCTS: "/products",
  SUBCATEGORY: "/products/:id",
  DETAILED_SUBCATEGORY: "/products/:id/:second_id",
  FINANCE: "/finance",
  CASH: "/cash",
  DETAILED_CASH: "/cash/:id",
  WAREHOUSE: "/warehouse",
  ADMINS: "/admins",
  ADMINS_ROLES: "/admins/roles",
  FINANCIAL: "/financial",
  INTERFACE: "/interface",
  INTEGRATION: "/integration",
  BACK_UP: "/back-up",
  ROLES: "/roles",
  CUSTOM_FIELD: "/custom-field",
  TEMPLATE: "/template",
  INDEX: "/",
  BUILDERS: "/builders",
  ARCHIVE: "/archive",
  ARCHIVE_COUNTERPARTS: "/archive/counterparts",
  COUNTERPARTS: "/counterparts"
};
