import React from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { dailySelectItems } from "../../../../utils/constants/calendarItems";
import { CalendarMonthlyModel } from "../../../../utils/models/paymentCalendarModel";

import OrderOrPaymentAction from "./OrderOrPaymentAction";
import PaymentCalendarCardAndHeaderItem from "./PaymentCalendarCardAndHeaderItem";

import styles from "./paymentCalendarContent.module.scss";

type Props = CalendarMonthlyModel & {
  isCompanyPerson?: boolean;
};

const PaymentCalendarCardAndHeader: React.FC<Props> = ({ date, data, isCompanyPerson = false }) => {
  const { queries } = useQueryParams();
  const { view_type: viewType } = queries();
  const { t } = useTranslation();

  const header = () => {
    if (viewType === PaymentTypeEnum.MONTHLY) {
      const [month] = date.split(".");
      const findMonth = dailySelectItems?.find(item => item.vale === month);

      return findMonth?.name;
    }
    return date;
  };

  const isToday = () => {
    const [month, year] = date.split(".");
    const monthArr = dayjs().format(dayjsFormats.DATE)?.split(".");

    if (year) {
      return month === monthArr[1] && year === monthArr[2];
    }
    return month === monthArr[2];
  };

  return (
    <div className={styles.card_header}>
      <div className={styles.header__item}>{header()}</div>
      <div
        className={classNames(styles.card_header__bottom, {
          [styles.today]: isToday()
        })}
      >
        <div className={styles.active_day}>
          <OrderOrPaymentAction date={date} />
          <span
            className={classNames({
              [styles.active_day__disabled]: !isToday()
            })}
          >
            {viewType === PaymentTypeEnum.MONTHLY ? t("payment.Shu oy") : t("payment.Shu yil")}
          </span>
        </div>
        <div className={styles.card_header__contents}>
          {data?.map(item => <PaymentCalendarCardAndHeaderItem key={uid()} {...item} date={date} />)}
        </div>
      </div>
    </div>
  );
};

export default PaymentCalendarCardAndHeader;
