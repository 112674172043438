import React from "react";
import { Tooltip } from "antd";

import { sliceText } from "modules/common";

type Props = {
  title?: string;
  length?: number;
};

export const TooltipShortName: React.FC<Props> = ({ title = "", length = 10 }) =>
  title?.length > length ? <Tooltip title={title}>{sliceText(title, length)}</Tooltip> : title;
