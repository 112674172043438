import React from "react";

const QuantityIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_16471_47877)">
        <path
          d="M13.2813 13.2827C16.1334 12.9009 18.3332 10.458 18.3332 7.5013C18.3332 4.27964 15.7215 1.66797 12.4998 1.66797C9.54315 1.66797 7.10025 3.86769 6.71839 6.71986M6.24984 10.8346L7.49984 10.0013V14.5846M6.24984 14.5846H8.74984M13.3332 12.5013C13.3332 15.723 10.7215 18.3346 7.49984 18.3346C4.27818 18.3346 1.6665 15.723 1.6665 12.5013C1.6665 9.27964 4.27818 6.66797 7.49984 6.66797C10.7215 6.66797 13.3332 9.27964 13.3332 12.5013Z"
          stroke="#667085"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_16471_47877">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuantityIcon;
