import { FC } from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { useAppSelector } from "../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import { PageKeys } from "../../../../../app/components/statuses/statusEnums";
import Statuses from "../../../../../app/components/statuses/Statuses";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { GetComponentProps } from "../../../../../app/utils/models/getComponentPropsType";
import { PaginationType } from "../../../../../app/utils/models/PaginationType";
import { ICalendarHistoryOrderModel } from "../../../../utils/models/calendarHistoryOrderModel";

import styles from "./calendarHistory.module.scss";

type Props = {
  data: PaginationType<ICalendarHistoryOrderModel[]> | undefined;
  isLoading: boolean;
};

const CalendarOrderTable: FC<Props> = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const { setCalendarHistoryPage, setOrderView } = paymentReducerActions;
  const { page } = useAppSelector(state => state.paymentReducer.calendarHistory);

  const onChangePage = (e: number) => {
    dispatch(setCalendarHistoryPage(e));
  };

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total || 10,
        pageSize: 10,
        current: page,
        onChange: onChangePage,
        style: {
          padding: "1rem"
        }
      }}
    />
  );

  const columns: ColumnsType<ICalendarHistoryOrderModel> = [
    {
      title: "Partiya",
      dataIndex: "id",
      render: value => `P-${value}`
    },
    {
      title: "Summa",
      render: (_, record) => (
        <div className={styles.amount}>
          {record.total_amount?.toLocaleString(RU)}
          <span>{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: "Qoldiq",
      render: (_, record) => (
        <div className={styles.amount}>
          {record.debt_amount?.toLocaleString(RU)}
          <span>{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: "Holati",
      dataIndex: "status",
      render: value => <Statuses statusKey={value} pageKey={PageKeys.PARTY} />
    },
    {
      title: "Sana",
      dataIndex: "payment_date"
    }
  ];

  const onRow: GetComponentProps<ICalendarHistoryOrderModel> | undefined = record => ({
    onClick: () => {
      dispatch(
        setOrderView({
          orderId: record?.id,
          visible: true
        })
      );
    }
  });

  return (
    <>
      <Table<ICalendarHistoryOrderModel>
        onRow={onRow}
        footer={footer}
        columns={columns}
        pagination={false}
        rowClassName="c_p"
        dataSource={data?.data}
        className="footer_table"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      {/* <OrderView /> */}
    </>
  );
};

export default CalendarOrderTable;
