export const supplyQueryNames = {
  WAREHOUSE_PRODUCT: "supply-warehouse-product",
  WAREHOUSE_PRODUCT_COUNT: "supply-warehouse-product-count",
  COMPANY_PERSON: "supply-company-person",
  AGENTS: "supply-agents",
  ORDERS: "supply-orders",
  ORDER_DETAIL: "supply-order-detail",
  ADDITIONAL: "supply-additional",
  PROJECTS: "supply-projects",
  PROJECT: "supply-project",
  PROJECTS_SELECT: "supply-projects-select",
  WAREHOUSES: "supply-warehouses",
  TASK_PRODUCT_VIEW: "supply-task-product-view",
  SECTIONS: "project-sections",
  TASK_PRODUCT: "task-products",
  SELECT_PRODUCTS: "select-products",
  OFFERS: "offers",
  OFFER_HISTORY: "offer_history",
  PARTY_EXCEL: "party-excel",
  WAREHOUSE_PRODUCTS_IN_SUPPLY: "warehouse-products-in-supply"
};
