import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { useAuth } from "modules/auth/hooks";

import { queryKeys } from "../utils/constants/queryKeys";

export const useNotificationSubscribe = () => {
  const { accessToken, user } = useAuth();
  const queryClient = useQueryClient();
  const url = import.meta.env.VITE_WEBSOCKET_URL;

  useEffect(() => {
    const websocket = new WebSocket(url);

    websocket.onopen = () => {
      const message = JSON.stringify({
        data: { channel: `notification_${user?.id}` },
        event: "pusher:subscribe",
        Authorization: accessToken
      });

      websocket.send(message);
    };

    websocket.onmessage = event => {
      const data = JSON.parse(event.data);

      if (data?.data && JSON.parse(data?.data)?.created) {
        queryClient.invalidateQueries([queryKeys.NOTIFICATIONS]);
      }
    };

    return () => {
      websocket.close();
    };
  }, [queryClient, user]);
};
