import React from "react";
import { Spin } from "antd";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { CategoryDevModel } from "../../../../utils/models/product-dev/categoryDevModel";
import SettingsProductsTemplateFolderCard from "../folder/SettingsProductsTemplateFolderCard";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";

import styles from "./settingsProductsTemplateFolders.module.scss";

type Props = {
  data?: CategoryDevModel[];
  isLoading: boolean;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  isCategory: boolean;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
};

const SettingsProductsTemplateFolders: React.FC<Props> = ({
  data,
  isLoading,
  setTabKey,
  isCategory,
  setFolderId,
  setBreadcrumbItems,
}) => {
  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.folders}>
        {!isEmptyArr(data) &&
          data?.map((item) => (
            <SettingsProductsTemplateFolderCard
              {...item}
              key={item.id}
              setTabKey={setTabKey}
              isCategory={isCategory}
              setFolderId={setFolderId}
              setBreadcrumbItems={setBreadcrumbItems}
            />
          ))}
      </div>
    </Spin>
  );
};

export default SettingsProductsTemplateFolders;
