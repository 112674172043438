import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sliceNames } from "features/app/utils/constants/sliceNames";
import { TableDataTypeWidgetKey } from "modules/dashboard";

interface TableWidgetContentModalModel {
  visible: boolean;
  widgetKey: keyof TableDataTypeWidgetKey | null;
  title: React.ReactNode;
}

const initialState: TableWidgetContentModalModel = {
  visible: false,
  widgetKey: null,
  title: ""
};

const tableWidgetContentModalSlice = createSlice({
  name: sliceNames.TABLE_WIDGET_CONTENT_MODAL,
  initialState,
  reducers: {
    setTableWidgetContentModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        widgetKey: keyof TableDataTypeWidgetKey | null;
        title: React.ReactNode;
      }>
    ) => {
      state.visible = action.payload.visible;
      state.widgetKey = action.payload.widgetKey;
      state.title = action.payload.title;
    }
  }
});

export const tableWidgetContentModalActions = tableWidgetContentModalSlice.actions;
export default tableWidgetContentModalSlice.reducer;
