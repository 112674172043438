import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { OfferPartyErrorModel, OfferPartyLeftModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  unique_id: string;
};

const OfferPartyProductAmount: React.FC<Props> = ({ unique_id }) => {
  const dispatch = useDispatch();
  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;
  const { leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const leftDataItemValue = (uniqueId: string, key: keyof OfferPartyLeftModel) =>
    leftData?.find(item => item.unique_id === uniqueId)?.[key];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, uniqueId: string) => {
    const { value } = e.currentTarget;
    const parseValue = parseLocaledString(localeFormatter(String(value)));
    const leftDataItem = leftData?.find(item => item.unique_id === uniqueId);

    dispatch(
      setOfferPartyLefItemData({
        key: "amount",
        value: localeFormatter(String(value)),
        uniqueId
      })
    );

    dispatch(
      setOfferPartyLefItemData({
        key: "total",
        value: localeFormatter(String(parseValue * parseLocaledString(String(leftDataItem?.quantity)))),
        uniqueId
      })
    );

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "amount",
          uniqueId,
          action: !e
        })
      );
    }
  };

  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, unique_id) ? styles.item__error : "";

  return (
    <div className={`${styles.item} ${errorClassName("amount")}`}>
      <Input
        disabled
        placeholder="Narxi"
        autoComplete="off"
        value={leftDataItemValue(unique_id, "amount") as string}
        onChange={e => onChangeAmount(e, unique_id)}
      />
    </div>
  );
};

export default OfferPartyProductAmount;
