import { useMemo } from "react";
import classNames from "classnames";

import { useQueryParams } from "../../../../hooks/useQueryParams";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import ChevronHorizontalIcon from "../../assets/icons/ChevronHorizontalIcon";
import { SortOrderEnum } from "../../utils/enums/sortOrderEnum";

import styles from "./orderSortBy.module.scss";

type Props = {
  queryParamKey: string;
  title: string;
  classNames?: string;
  fieldId?: number;
};

const OrderSortByField = ({ queryParamKey, title, classNames: propsClass, fieldId }: Props) => {
  const { append, remove, queries } = useQueryParams();
  const queryParamValues = useMemo(
    () =>
      queries()
        // eslint-disable-next-line no-unexpected-multiline
        [queryParamKey]?.split(",")
        ?.reduce((p: Record<number, SortOrderEnum>, c) => {
          const cSplit = c?.split("_");

          if (cSplit && cSplit.length === 2) {
            p[Number(cSplit[0])] = cSplit[1] as SortOrderEnum;
          }
          return p;
        }, {}),
    [queries, queryParamKey]
  );

  const queryParamValue = queryParamValues && fieldId ? queryParamValues[fieldId] : undefined;

  const sortIcon = {
    [SortOrderEnum.DESC]: <ArrowDownIcon rotate={180} />,
    [SortOrderEnum.ASC]: <ArrowDownIcon />
  };

  const onChangeSort = () => {
    const queryParamValuesCopy = { ...queryParamValues };

    if (fieldId) {
      if (!queryParamValue) {
        queryParamValuesCopy[fieldId] = SortOrderEnum.ASC;
      }
      if (queryParamValue === SortOrderEnum.ASC) {
        queryParamValuesCopy[fieldId] = SortOrderEnum.DESC;
      }
      if (queryParamValue === SortOrderEnum.DESC) {
        delete queryParamValuesCopy[fieldId];
      }
      const queryParamValuesCopyKeys = Object.keys(queryParamValuesCopy || {});

      if (queryParamValuesCopyKeys.length === 0) {
        remove(queryParamKey);
      } else {
        const queryToString = queryParamValuesCopyKeys?.reduce((p, c) => {
          p = `${c}_${queryParamValuesCopy[Number(c)]}${p ? `,${p}` : ""}`;
          return p;
        }, "");

        append(queryParamKey, queryToString);
      }
    }
  };

  return (
    <div
      className={classNames(styles.order_sort_by, queryParamValue, {
        propsClass: Boolean(propsClass)
      })}
      onClick={onChangeSort}
    >
      <span>{title}</span>
      {queryParamValue ? sortIcon[queryParamValue] : <ChevronHorizontalIcon className="" />}
    </div>
  );
};

export default OrderSortByField;
