const LinkIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99967 11.3332H4.66634C2.82539 11.3332 1.33301 9.84079 1.33301 7.99984C1.33301 6.15889 2.82539 4.6665 4.66634 4.6665H5.99967M9.99967 11.3332H11.333C13.174 11.3332 14.6663 9.84079 14.6663 7.99984C14.6663 6.15889 13.174 4.6665 11.333 4.6665H9.99967M4.66634 7.99984L11.333 7.99984"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
