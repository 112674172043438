import React from "react";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";

import { cx, numberRound, RU } from "modules/common";
import {
  Count as CountType,
  getConditionalColor,
  getConditionalStyle,
  getIcon,
  makeSvgRotate,
  WidgetKeys,
  widgetStaticData
} from "modules/dashboard";
import { useTranslation } from "react-i18next";

type Props = {
  data: CountType;
  widgetKey: WidgetKeys;
};

const Count: React.FC<Props> = ({ widgetKey, data }) => {
  const { labels } = widgetStaticData[widgetKey];
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col gap-2">
      <div className="flex items-end  gap-1">
        <span
          className={cx("text-5xl font-medium text-error-500", { "text-gray-700": widgetKey !== "warehouse_defect" })}
        >
          {(data?.total || 0).toLocaleString(RU)}
        </span>
        <span className="text-3xl font-medium text-gray-500">{data?.currency?.symbol}</span>
      </div>
      <div className="flex items-center gap-1">
        <span
          className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
          style={{
            backgroundColor: getConditionalStyle(data?.percent || 0),
            borderColor: getConditionalStyle(data?.percent || 0)
          }}
        >
          <span>
            {getIcon(
              (data?.percent as unknown as number) || 0,
              <ArrowDownIcon
                width={14}
                stroke={getConditionalColor(data?.percent || 0)}
                rotate={makeSvgRotate(data?.percent || 0)}
              />
            )}
          </span>
          <span
            className="text-sm font-medium"
            style={{
              color: getConditionalColor(data?.percent || 0) || "black"
            }}
          >
            {numberRound(data?.percent || 0)}%
          </span>
        </span>
        <span className="text-sm font-normal text-gray-400">{t(`dashboard.${labels[0]}`)}</span>
      </div>
    </div>
  );
};

export default Count;
