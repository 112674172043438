import { ChangeEvent, FC, KeyboardEvent, MouseEvent, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { inventoryReducerActions } from "../../../../../../store/reducers/inventoryReducer";
import styles from "./editFolderName.module.scss";

type Props = {
  onFinish: (value: string) => Promise<unknown>;
  setIsEdit?: (isEdit: boolean) => void;
  className?: string;
  placeholder?: string;
  isEdit?: boolean;
  children?: string;
};

export const EditFolderName: FC<Props> = ({
  children,
  onFinish,
  className,
  placeholder,
  isEdit,
  setIsEdit,
}) => {
  const dispatch = useAppDispatch();
  const initialValueRef = useRef(children);
  const [value, setValue] = useState(children ?? "");
  const inputRef = useRef<HTMLInputElement>(null);
  const creatingFolder = useAppSelector(
    (state) => state.inventoryReducer.creatingFolder
  );
  const { setCreatingFolder } = inventoryReducerActions;

  const onUpdate = () => {
    setIsEdit && setIsEdit(false);
    onFinish(value).then(() => {
      initialValueRef.current = value;
      inputRef.current?.blur();
    });
  };

  const onClick = (e: MouseEvent<HTMLParagraphElement>) => {
    e?.stopPropagation();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onBlur = () => {
    if (value.length === 0 && creatingFolder) {
      dispatch(setCreatingFolder(false));
      return;
    }
    if (initialValueRef.current !== value) {
      onUpdate();
    } else {
      setIsEdit && setIsEdit(false);
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onUpdate();
    }

    if (e.key === "Escape") {
      inputRef.current?.blur();
    }
  };

  return (
    <div
      onClick={onClick}
      className={`${styles.editable} ${!value ? styles.empty : ""}`}
    >
      <input
        ref={inputRef}
        onBlur={onBlur}
        className={className}
        onKeyDown={onKeyDown}
        autoFocus={isEdit || creatingFolder}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={onChange}
      />
    </div>
  );
};
