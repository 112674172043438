import "./bootstrap";
import "dayjs/locale/en-gb";

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import { dayjs } from "./services";

dayjs.init();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
