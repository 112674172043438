import React from "react";
import { Form, FormInstance } from "antd";
import PlusCircleIcon from "features/app/assets/icons/PlusCircleIcon";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useSelector } from "store";

import { cx, isEmptyArr } from "modules/common";

import Card from "./card/Card";

import styles from "./content.module.scss";
import LeftNoData from "../no-data/LeftNoData";

const { useWatch, List } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
};

const Content: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const products = useWatch("products", form) as LaboratoryDrawerProducts[];
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);

  const onAddProduct = () => {
    if (products?.length > 0) {
      form.setFieldsValue({
        products: [
          ...products,
          {
            rowId: uid()
          }
        ]
      });
    } else {
      form.setFieldsValue({
        products: [
          {
            rowId: uid()
          }
        ]
      });
    }
  };

  return (
    <div className={cx("flex w-full flex-col gap-4 overflow-y-auto", isEmptyArr(products) ? "h-full" : "h-[20000vw]")}>
      <div className={cx("grid grid-cols-2 gap-4")}>
        <List name="products">
          {(fields, { remove }) => (
            <>
              {fields?.map((_product, index) => <Card key={_product?.key} form={form} index={index} remove={remove} />)}
            </>
          )}
        </List>
      </div>

      {!isViewing && (
        <span className={styles.table_footer} onClick={onAddProduct}>
          <PlusCircleIcon fill={true} />
          {t("warehouse.Resurs qo'shish")}
        </span>
      )}
      {isViewing && products && products?.length === 0 && (
        <div className={cx(styles.no_data_container)}>
          <LeftNoData />
        </div>
      )}
    </div>
  );
};

export default Content;
