const CustomColumnFileIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6673 1.89063V5.33274C11.6673 5.79945 11.6673 6.03281 11.7581 6.21107C11.838 6.36787 11.9655 6.49535 12.1223 6.57525C12.3006 6.66608 12.5339 6.66608 13.0007 6.66608H16.4428M11.6673 14.166H6.66732M13.334 10.8327H6.66732M16.6673 8.32287V14.3327C16.6673 15.7328 16.6673 16.4329 16.3948 16.9677C16.1552 17.4381 15.7727 17.8205 15.3023 18.0602C14.7675 18.3327 14.0674 18.3327 12.6673 18.3327H7.33398C5.93385 18.3327 5.23379 18.3327 4.69901 18.0602C4.2286 17.8205 3.84615 17.4381 3.60647 16.9677C3.33398 16.4329 3.33398 15.7328 3.33398 14.3327V5.66602C3.33398 4.26588 3.33398 3.56582 3.60647 3.03104C3.84615 2.56063 4.2286 2.17818 4.69901 1.9385C5.23379 1.66602 5.93385 1.66602 7.33398 1.66602H10.0105C10.6219 1.66602 10.9277 1.66602 11.2154 1.73509C11.4705 1.79633 11.7143 1.89734 11.938 2.03442C12.1903 2.18902 12.4065 2.40521 12.8389 2.83759L15.4957 5.49444C15.9281 5.92682 16.1443 6.14301 16.2989 6.3953C16.436 6.61898 16.537 6.86285 16.5982 7.11794C16.6673 7.40566 16.6673 7.71139 16.6673 8.32287Z"
        stroke="#1E90FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CustomColumnFileIcon;
