import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";

import CustomColumnFileIcon from "../../assets/icons/CustomColumnFileIcon";
import { useDownloadFile } from "../../service/mutation";
import { CustomFieldTypesEnum } from "../../utils/enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../../utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../utils/models/customFIeldValueModel";
import PopoverShortName from "../popover-short-name/PopoverShortName";

import styles from "./customColumnRender.module.scss";

type Props = {
  customField: CustomFieldSelectModel;
  record: { custom_field_values: CustomFieldValueModel[] };
};

const CustomColumnRender = ({ customField, record }: Props) => {
  const { i18n } = useTranslation();
  const downloadFile = useDownloadFile();

  const onDownloadFile = (id: number, name: string) => {
    downloadFile.mutateAsync({ id, name });
  };

  const customColumnRender = () => {
    const customElem = record?.custom_field_values?.find(elem => elem?.custom_field?.id === customField?.id);

    const labelCustomFields = record?.custom_field_values?.filter(
      item => item?.custom_field?.type === CustomFieldTypesEnum.LABEL
    );

    const type = customElem?.custom_field?.type;

    if (customElem) {
      if (type === CustomFieldTypesEnum.SELECT) {
        return customElem?.custom_field_option?.name[i18n.language];
      }
      if (type === CustomFieldTypesEnum.DATE) {
        return customElem?.value;
      }
      if (type === CustomFieldTypesEnum.TEXT) {
        return customElem?.value;
      }
      if (type === CustomFieldTypesEnum.FILE) {
        return (
          <div
            className={styles.file}
            onClick={() => onDownloadFile(customElem?.file?.id, customElem?.file?.original_name)}
          >
            <CustomColumnFileIcon />
            <PopoverShortName title={customElem?.file?.original_name} length={8} />
          </div>
        );
      }
      if (type === CustomFieldTypesEnum.CHECKBOX) {
        // <span className="status__active">{StatusNames[Status.ACTIVE]}</span>;
        return <Checkbox checked />;
      }
      if (type === CustomFieldTypesEnum.LABEL) {
        return (
          <span>
            {labelCustomFields?.map(
              (item, index) =>
                `${item.custom_field_option?.name[i18n.language]}${
                  index + 1 === labelCustomFields?.length ? " " : ", "
                }`
            )}
          </span>
        );
      }
    }

    return "-";
  };

  return customColumnRender();
};

export default CustomColumnRender;
