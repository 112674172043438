import React from "react";
import { Form, FormInstance, FormListOperation, Input } from "antd";

import { useAppSelector } from "../../../../../../../hooks/redux";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import MarkIcon from "../../../../../../app/assets/icons/MarkIcon";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { TransferBodyModel } from "../../../../../utils/models/transferBodyModel";

import styles from "./additional.module.scss";

type Props = {
  index: number;
  operation: FormListOperation;
  formInstance: FormInstance;
};

const { useWatch, Item } = Form;

const Card: React.FC<Props> = ({ formInstance, index, operation }) => {
  const { currency } = useAppSelector(state => state.paymentReducer.paymentOrderModal);

  const record = useWatch(
    ["financials", index],
    formInstance
  ) as TransferBodyModel["expense_payment"]["financials"][number];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatValue = localeFormatter(e?.target?.value, true);
    const parseValue = parseLocaledString(e?.target?.value || "0");
    const isPercentage = formatValue?.includes("%");
    const oldAmount = parseLocaledString(formInstance.getFieldValue("old_amount"));
    const financials = formInstance.getFieldValue("financials") as { amount: string; percent_amount?: number }[];

    const financialsAmount = financials?.reduce((previousValue, currentValue, financeIndex) => {
      if (index === financeIndex) {
        return previousValue;
      }

      return previousValue + parseLocaledString(currentValue?.percent_amount || currentValue?.amount || "0");
    }, 0);

    formInstance.setFieldValue(["financials", index, "amount"], formatValue);
    formInstance.setFieldValue(
      "amount",
      localeFormatter(
        String((isPercentage ? oldAmount / (parseValue / 100) : parseValue + oldAmount) + financialsAmount)
      )
    );

    if (isPercentage) {
      formInstance.setFieldValue(["financials", index, "percent_amount"], oldAmount / (parseValue / 100) - oldAmount);
    }
  };

  const onRemoveItem = () => {
    const total = parseLocaledString(formInstance.getFieldValue("amount"));
    const amount = parseLocaledString(formInstance.getFieldValue(["financials", index, "amount"]) || "0");
    const percentAmount = parseLocaledString(
      formInstance.getFieldValue(["financials", index, "percent_amount"]) || "0"
    );

    formInstance.setFieldValue("amount", localeFormatter(String(total - (percentAmount || amount))));

    operation.remove(index);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const value = e.currentTarget?.value;
      const isPercentage = value?.includes("%");
      const parseValue = parseLocaledString(value || "0");
      const oldAmount = parseLocaledString(formInstance.getFieldValue("old_amount"));

      if (isPercentage) {
        formInstance.setFieldValue(
          ["financials", index, "amount"],
          localeFormatter(String(oldAmount / (parseValue / 100) - oldAmount))
        );
        formInstance.setFieldValue(["financials", index, "percent_amount"], undefined);
      }
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <MarkIcon />
        <div className={styles.text}>
          <h3>{record?.name}</h3>
        </div>
      </div>
      <div className={styles.right}>
        <Item name={[index, "id"]} className="hidden" />
        <Item name={[index, "name"]} className="hidden" />
        <Item name={[index, "percent_amount"]} className="hidden" />
        <Item name={[index, "amount"]}>
          <Input placeholder="0" onChange={onChangeAmount} onKeyDown={onKeyDown} suffix={currency?.symbol} />
        </Item>
        <div className={styles.close} onClick={onRemoveItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default Card;
