import { ReactNode } from "react";

import SlashCircleIcon from "../../../app/assets/icons/SlashCircleIcons";
import CheckCircleIcon from "../../../payment/assets/icons/CheckCircleIcon";
import ClockCircleIcon from "../../../payment/assets/icons/ClockCircleIcon";
import { PaymentStatusEnums } from "../../../app/utils/enums/paymentStatusEnums";

export const ImpostPaymentStatusIcons: Record<PaymentStatusEnums, ReactNode> = {
  [PaymentStatusEnums.PENDING]: <ClockCircleIcon />,
  [PaymentStatusEnums.PASSIVE]: <ClockCircleIcon />,
  [PaymentStatusEnums.ACTIVE]: <CheckCircleIcon />,
  [PaymentStatusEnums.CANCEL]: <SlashCircleIcon />,
};
