import React, { Dispatch, SetStateAction } from "react";
import { DatePicker, Form, FormInstance } from "antd";
import TextArea from "antd/lib/input/TextArea";
import dayjs from "dayjs";
import CalendarPlusIcon from "features/app/assets/icons/CalendarPlusIcon";
import CustomUpload from "features/app/components/custom-upload/CustomUpload";
import { FileListType } from "features/app/utils/models/fileListModel";
import { LaboratoryDrawerFormModel } from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";

import { useSelector } from "store";

import { dayjsFormats } from "modules/common";

const { Item } = Form;

// import styles from "./additionalInfo.module.scss";

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
  fileList: FileListType[];
  setFileList: Dispatch<SetStateAction<FileListType[]>>;
};

const AdditionalInfo: React.FC<Props> = ({ form, fileList, setFileList }) => {
  const { t } = useTranslation();
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-4">
        <Item label={t("warehouse.Sana")} name="date">
          <DatePicker
            disabled={isViewing}
            placeholder={t("warehouse.Tanlang")}
            format={dayjsFormats.DATE}
            suffixIcon={<CalendarPlusIcon />}
            defaultValue={!isViewing ? dayjs() : null}
          />
        </Item>
        <Item label={t("warehouse.Izoh")} name="description">
          <TextArea disabled={isViewing} placeholder={t("warehouse.Ipoh kiting")} />
        </Item>
        {!isViewing && (
          <CustomUpload
            disabled={isViewing}
            isArray
            form={form}
            name="file_ids"
            fileList={fileList}
            setFileList={setFileList}
            description={isViewing ? t("warehouse.Yuklangan fayllar mavjud emas") : undefined}
          />
        )}
      </div>
    </div>
  );
};

export default AdditionalInfo;
