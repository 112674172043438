import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { ProductsEstimateDataModel } from "../../features/warehouse/utils/models/productsEstimateDataModel";
import { WarehouseProductModel } from "../../features/warehouse/utils/models/WarehouseProductModel";

interface WarehouseProductsSliceModel {
  reservation: {
    visible: boolean;
    importData: ProductsEstimateDataModel[];
  };
  estimate: {
    visible: boolean;
    data?: ProductsEstimateDataModel[];
  };
  projectId?: number;
  resource: {
    visible: boolean;
    product?: WarehouseProductModel;
  };
}

const initialState: WarehouseProductsSliceModel = {
  reservation: {
    visible: false,
    importData: []
  },
  estimate: {
    visible: false,
    data: undefined
  },
  resource: {
    visible: false
  }
};

const warehouseProductsSlice = createSlice({
  name: sliceNames.WAREHOUSE_PRODUCTS,
  initialState,
  reducers: {
    setReservationModal: (state, action: PayloadAction<boolean>) => {
      state.reservation.visible = action.payload;
    },
    setEstimateDrawer: (
      state,
      action: PayloadAction<{
        visible: boolean;
        data?: ProductsEstimateDataModel[];
      }>
    ) => {
      state.estimate.visible = action.payload.visible;
      state.estimate.data = action.payload.data;
    },
    setProjectIdEstimate: (state, action: PayloadAction<{ projectId: number }>) => {
      state.projectId = action.payload.projectId;
    },
    setReservationImportData: (state, action: PayloadAction<ProductsEstimateDataModel[] | []>) => {
      state.reservation.importData = action.payload;
    },
    setResource: (state, action: PayloadAction<WarehouseProductsSliceModel["resource"]>) => {
      state.resource = action.payload;
    }
  }
});

export default warehouseProductsSlice.reducer;
export const warehouseProductsAction = warehouseProductsSlice.actions;
