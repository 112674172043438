import React from "react";
import { useTranslation } from "react-i18next";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { RU, cx } from "modules/common";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { LoadingIndicator } from "components";
import { WarehouseAmountView } from "modules/dashboard";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import Pagination from "features/app/components/pagination/Pagination";

import styles from "./tab-content.module.scss";

interface IProps {
  data: PaginationType<WarehouseAmountView[]>;
  isLoading: boolean;
}

const TabContent: React.FC<IProps> = ({ data, isLoading }) => {
  const { t, i18n } = useTranslation();

  const columns: ColumnsType<WarehouseAmountView> = [
    {
      title: t("dashboard.Mahsulot turi va nomi"),
      render: (record: WarehouseAmountView) => (
        <div className={cx("resource")}>
          <div style={{ borderColor: record?.product?.resource?.color, color: record?.product?.resource?.color }}>
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={25} />
          </span>
        </div>
      )
    },
    {
      title: t("dashboard.Umumiy miqdor"),
      render: (record: WarehouseAmountView) => (
        <span className="text-gray-700">
          {record?.total_quantity?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
        </span>
      )
    },
    {
      title: t("dashboard.Umumiy summasi"),
      render: (record: WarehouseAmountView) => (
        <span className="text-gray-700">
          {record?.total_amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      )
    }
  ];
  return (
    <div className="flex flex-col p-5">
      <Table<WarehouseAmountView>
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        className="footer_table"
        rowClassName={styles.table_row}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data.total,
            current: data.current_page
          }}
        />
      </div>
    </div>
  );
};

export default TabContent;
