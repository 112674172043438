import React from "react";

const PlusCircleIcon: React.FC<{ fill?: boolean; width?: string; height?: string }> = ({ fill, width, height }) =>
  fill ? (
    <svg
      width={width || "21"}
      height={height || "20"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5001 18.3332C15.1025 18.3332 18.8334 14.6022 18.8334 9.99984C18.8334 5.39746 15.1025 1.6665 10.5001 1.6665C5.89771 1.6665 2.16675 5.39746 2.16675 9.99984C2.16675 14.6022 5.89771 18.3332 10.5001 18.3332Z"
        fill="#1E90FF"
      />
      <path
        d="M10.5003 6.66699V10.0003M10.5003 10.0003V13.3337M10.5003 10.0003H7.16699M10.5003 10.0003H13.8337"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width={width || "49"}
      height={height || "48"}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5003 18.3332C15.1027 18.3332 18.8337 14.6022 18.8337 9.99984C18.8337 5.39746 15.1027 1.6665 10.5003 1.6665C5.89795 1.6665 2.16699 5.39746 2.16699 9.99984C2.16699 14.6022 5.89795 18.3332 10.5003 18.3332Z"
        stroke="#d0d5dd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5003 6.66699V10.0003M10.5003 10.0003V13.3337M10.5003 10.0003H7.16699M10.5003 10.0003H13.8337"
        stroke="#d0d5dd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default PlusCircleIcon;
