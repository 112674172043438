import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, ExpensePaymentByMonth } from "..";

interface Query {
  paymentLineViewData: PaginationType<ExpensePaymentByMonth[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id: number;
  min_date: string;
  max_date: string;
}

export function useExpensePaymentByMonth({ enabled = false, id, max_date, min_date }: Params) {
  const initialValue: Query = { paymentLineViewData: { data: [], total: 0, current_page: 1 } };

  const { generateSearchParam, queries } = useQueryParams();
  const { tab, page } = queries();
  const searchParams = generateSearchParam({
    id,
    payment_type_id: tab !== "all" ? Number(tab) : undefined,
    page,
    min_date,
    max_date
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.COMPANY_PAYMENT_RADAR, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/expense-payment/by-month-view?${searchParams}`);

      return { paymentLineViewData: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
