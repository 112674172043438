import React, { useEffect } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import AvatarAddIcon from "features/projects/assets/icons/AvatarAddIcon";
import { useTranslation } from "react-i18next";

import { User as UserType } from "modules/common";
import { AppendEmployees, useWidget, WidgetSettingsForm } from "modules/dashboard";

import styles from "./settings.module.scss";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
  users: UserType[];
  setUsers: React.Dispatch<React.SetStateAction<UserType[]>>;
};

const User = ({ form, setUsers, users }: Props) => {
  const { settingsVisible, widget } = useWidget();

  const { t } = useTranslation();

  useEffect(() => {
    if (widget?.users && widget?.users?.length > 0 && settingsVisible) {
      setUsers(widget?.users);
    }

    if (!settingsVisible) {
      setUsers([]);
    }
  }, [widget, settingsVisible]);

  return (
    <AppendEmployees
      data={users}
      setData={setUsers as never}
      formInstance={form}
      title={t("dashboard.Vidjet xodimlari")}
    >
      <div className={styles.container}>
        <Controller
          name="user_ids"
          control={form.control}
          rules={{
            required: true
          }}
          render={({ field }) => (
            <>
              <span>{t("dashboard.Xodim")}</span>

              <div className={styles.participant_container}>
                <div className={styles.append}>
                  <AvatarAddIcon />
                </div>
                {users?.length > 0 && (
                  <div className={styles.box}>
                    {users.slice(0, 3)?.map(el => (
                      <div className={styles.participant} key={el.id}>
                        {el?.image ? (
                          <img src={el?.image} alt={el?.full_name} />
                        ) : (
                          <NotUserImage name={el?.full_name} isTooltip={false} />
                        )}
                      </div>
                    ))}
                    {users && users?.length > 3 && (
                      <div className={styles.participant}>
                        <NotUserImage isTooltip={false} title={`+${users.length - 3}`} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        />
      </div>
    </AppendEmployees>
  );
};

export default User;
