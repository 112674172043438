import { DataNode } from "antd/es/tree";

import { isEmptyArr } from "./isEmptyArr";

type PropsDataType = DataNode & {
  isFolder?: boolean;
};

export const stringifyTreeData = (data: PropsDataType[], isAll?: boolean) => {
  const result: string[] = [];

  const push = (data: PropsDataType[]) => {
    data.forEach(item => {
      if (!isEmptyArr(item.children)) {
        push(item.children!);
        isAll && result.push(item.key as string);
      } else if (!item?.isFolder) {
        result.push(item.key as string);
      }
    });
  };

  push(data);

  return result;
};
