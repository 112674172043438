import React from "react";
import { Button } from "antd";

import PlusIcon from "../../assets/icons/PlusIcon";
import SelectNotContentIcon from "../../assets/icons/SelectNotContentIcon";

import styles from "./selectNotContent.module.scss";

type Props = {
  title?: string;
  description?: string;
  action?: () => void;
};

const SelectNotContent: React.FC<Props> = ({ action, description, title }) => {
  return (
    <div className={styles.not_content}>
      <SelectNotContentIcon />
      <h4>{title ?? "Ma'lumot topilmadi"}</h4>
      <p>
        {description ? (
          <span>{description}</span>
        ) : (
          <span>
            Kechirasiz, biz siz izlayotgan ma'lumotni topa olmadik.
            <br /> Iltimos, qayta urinib ko'ring.
          </span>
        )}
      </p>
      {action && (
        <Button onClick={action} type="primary">
          <PlusIcon color="#fff" /> {title} yaratish
        </Button>
      )}
    </div>
  );
};

export default SelectNotContent;
