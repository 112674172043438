import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Col, Collapse, CollapseProps, Popover, Row, Spin } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../app/utils/constants/languages";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import ChevronDownIcon from "../../../../assets/icons/ChevronDownIcon";
import { supplyEndpoints } from "../../../../utils/constants/supplyEndpoints";
import { supplyQueryNames } from "../../../../utils/constants/supplyQueryNames";
import { ProjectSectionModel, ProjectTaskProductModel } from "../../../../utils/models/projectModel";
import EstimateProduct from "../product/EstimateProduct";

import styles from "./estimateSection.module.scss";

type Props = ProjectSectionModel & {
  isPrice?: boolean;
};

const EstimateSection: React.FC<Props> = ({ name, tasks, isPrice }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const { setEstimateData, setOrderTableView } = supplyActions;
  const [isLoading, setIsLoading] = useState(false);
  const { products, taskIds } = useAppSelector(state => state.supplyReducer.estimateData);

  const getProductData = async (id: number) => {
    setIsLoading(true);
    const res = await axios.get(`${import.meta.env.VITE_BASE_URL}${supplyEndpoints.TASK_PRODUCT_VIEW}?task_id=${id}`, {
      withCredentials: true
    });

    return res.data.data as ProjectTaskProductModel[];
  };

  const changeTaskData = (newValue: number) => {
    let newTaskIds = [...taskIds];

    if (!taskIds.some(item => item === newValue)) {
      newTaskIds.push(newValue);
    } else {
      newTaskIds = newTaskIds.filter(item => item !== newValue);
    }

    dispatch(
      setEstimateData({
        key: "taskIds",
        data: newTaskIds
      })
    );
  };

  const findEqualArr = (arr1: ProjectTaskProductModel[], arr2: ProjectTaskProductModel[]) => {
    const idsArr2 = arr2.map(item => item.id);

    return arr1.filter(item => !idsArr2.includes(item.id));
  };

  const onClickCheckbox = (e: React.MouseEvent<HTMLElement, MouseEvent>, newValue: number) => {
    e.stopPropagation();

    dispatch(setOrderTableView(true));

    changeTaskData(newValue);

    let newProducts = [...products];

    const productsData: ProjectTaskProductModel[] | undefined = qc.getQueryData([
      supplyQueryNames.TASK_PRODUCT,
      newValue
    ]);

    if (productsData) {
      if (!taskIds.some(item => item === newValue)) {
        productsData?.forEach(item => {
          if (!products?.some(product => product.id === item?.id)) {
            newProducts.push(item);
          }
        });
      } else {
        newProducts = findEqualArr(products, productsData);
      }

      dispatch(
        setEstimateData({
          key: "products",
          data: newProducts
        })
      );
    } else {
      getProductData(newValue).then(data => {
        if (!taskIds.some(item => item === newValue)) {
          data?.forEach(item => {
            if (!products?.some(product => product.id === item?.id)) {
              newProducts.push(item);
            }
          });
        } else {
          newProducts = findEqualArr(products, data);
        }

        dispatch(
          setEstimateData({
            key: "products",
            data: newProducts
          })
        );

        setIsLoading(false);
      });
    }
  };

  const checkValue = (value: number) => taskIds.some(item => item === value);

  const maxTextLength = isPrice ? 38 : 54;

  const items = (): CollapseProps["items"] =>
    tasks?.map(item => ({
      key: item.id,
      label: (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <Row gutter={0} className={styles.task}>
            <Col span={isPrice ? 9 : 12} className={styles.task__check}>
              <Checkbox checked={checkValue(item.id)} onClick={e => onClickCheckbox(e, item.id)} />
              {item?.name?.length > maxTextLength ? (
                <Popover title={item.name} className="popover">
                  {sliceText(item.name, maxTextLength)}
                </Popover>
              ) : (
                item?.name
              )}
            </Col>
            <Col span={3} className={styles.task__item}>
              {item.due_date}
            </Col>
            <Col span={3} className={styles.task__item}>
              {/* {item.place} */}
            </Col>
            <Col span={3} className={styles.task__item}>
              {item.quantity}
            </Col>
            <Col span={3} className={styles.task__item}>
              {item.unit?.symbol[i18n.language]}
            </Col>
            {isPrice ? (
              <Col span={3} className={styles.task__item}>
                {item.amount?.toLocaleString(RU)}
              </Col>
            ) : null}
          </Row>
        </Spin>
      ),
      children: <EstimateProduct taskId={item.id} isPrice={isPrice} />
    }));

  return (
    <div className={styles.section}>
      <div className={styles.section__name}>{name}</div>
      <Collapse ghost items={items()} expandIcon={panelProps => <ChevronDownIcon rotate={panelProps.isActive} />} />
    </div>
  );
};

export default EstimateSection;
