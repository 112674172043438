import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";

import OfferPartyModalGroup from "./group/OfferPartyModalGroup";
import OfferPartyModalTable from "./table/OfferPartyModalTable";

import styles from "./offerPartyModalLeft.module.scss";

const OfferPartyModalLeft: React.FC = () => {
  const dispatch = useDispatch();
  const { setPartyEstimate } = supplyActions;
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");

  const { setAddOfferProductLeftData, setOfferPartyIsGrouping } = supplyOfferActions;

  const { leftData, isGrouping } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onOpenEstimateDrawer = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const onAddProduct = () => {
    dispatch(
      setAddOfferProductLeftData({
        total: "0",
        amount: "0",
        quantity: "0",
        unit_id: undefined,
        product_id: undefined,
        project_id: undefined,
        warehouse_id: undefined,
        unique_id: uid(12),
        company_person: {
          id: undefined
        }
      })
    );
  };

  const onGrouping = () => {
    dispatch(setOfferPartyIsGrouping(!isGrouping));
  };

  return (
    <div className={styles.left}>
      <Button onClick={onGrouping} className={styles.group_btn}>
        {isGrouping ? "Guruhdan chiqarish" : "Guruhlash"}
      </Button>
      {isEmptyArr(leftData) ? (
        <div className={styles.not_found}>
          <h4>Buyurtmalar tanlanmagan!</h4>
          <p>Mahsulotlar ro’yxatidan kerakli mahsultolarni tanlab buyurtma berishingiz mumkin.</p>
          {offerActions.add_resource && (
            <div className={styles.not_found__bottom}>
              <Button onClick={onOpenEstimateDrawer}>Smetadagi resurslar</Button>
              <Button type="primary" onClick={onAddProduct}>
                <PlusIcon color="#fff" />
                Resurs qo'shish
              </Button>
            </div>
          )}
        </div>
      ) : isGrouping ? (
        <OfferPartyModalGroup />
      ) : (
        <OfferPartyModalTable fields={leftData} isViewCompanyPerson />
      )}
    </div>
  );
};

export default OfferPartyModalLeft;
