import React from "react";

const ClockCheckIcon: React.FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4921_36956)">
        <path
          d="M12.0837 15.8333L13.7503 17.5L17.5003 13.75M18.3213 10.4582C18.3295 10.3065 18.3337 10.1537 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39795 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.5295 5.28075 18.2149 9.7824 18.3305M10.0003 4.99996V9.99996L13.1156 11.5576"
          stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_4921_36956">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClockCheckIcon;