import React from "react";
import { Collapse, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import Projects from "../../top/additions/projects/projects";

import styles from "../../top/additions/projects/projects.module.scss";

const { Panel } = Collapse;

type Props = {
  formInstance: FormInstance;
};

const Additions: React.FC<Props> = ({ formInstance }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      rootClassName={styles.projects}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel key="1" header={t("payment.Qo'shimcha ma'lumotlar")}>
        <Projects name={["income_payment", "projects"]} formInstance={formInstance} />
      </Panel>
    </Collapse>
  );
};

export default Additions;
