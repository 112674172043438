import React from "react";
import { useTranslation } from "react-i18next";

import { statusData } from "./StatusData";
import { PageKeys, StatusEnums } from "./statusEnums";

import styles from "./statuses.module.scss";

const Statuses: React.FC<{ statusKey: StatusEnums; pageKey: PageKeys }> = ({ pageKey, statusKey }) => {
  const data = statusData[pageKey][statusKey];
  const { t } = useTranslation();

  return (
    <div className={`${styles.status} ${styles[`${pageKey}_${statusKey}`]}`}>
      {data?.icon}
      <span>{t(`statuses.${data?.text}`)}</span>
    </div>
  );
};

export default Statuses;
