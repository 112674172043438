import { Checkbox, Collapse, CollapseProps } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { memo, useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { TaskModel } from "../../../../../utils/models/estimateModel";
import { EditInput } from "../editInput/EditInput";
import Product from "../product/Product";

import styles from "./Task.module.scss";

type Props = {
  task: TaskModel;
  place: number;
  index: number;
  sectionIndex: number;
};

const Task = memo(({ task, place, index, sectionIndex }: Props) => {
  const dispatch = useAppDispatch();
  const sections = useAppSelector((state) => state.projectsReducer.sections);
  const { setEditTask } = projectsReducerActions;

  const setTotal = useCallback(() => {
    let res = 0;
    task?.products?.forEach((product) => {
      res += product.amount * +product.quantity;
    });
    if (!isEmptyArr(task.products)) {
      dispatch(
        setEditTask({
          sectionIndex,
          taskIndex: index,
          editKey: "amount",
          editValue: String(res / task.quantity),
        })
      );
    }
    return res.toFixed(2);
  }, [task?.products, task.quantity]);

  const onChange = (event: CheckboxChangeEvent) => {
    dispatch(
      setEditTask({
        sectionIndex,
        taskIndex: index,
        editKey: "checked",
        editValue: event.target.checked,
      })
    );
  };

  const isEmptyInput = () => {
    const products = task?.products;
    let flag = false;
    for (let i = 0; i < products?.length; i++) {
      if (
        products[i]?.amount <= 0 ||
        products[i]?.quantity <= 0 ||
        products[i]?.unit?.length <= 0
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: (
        <div className={`${styles.label}`}>
          <Checkbox
            onChange={onChange}
            disabled={!sections[sectionIndex].checked}
            checked={task.checked && sections[sectionIndex].checked}
          />
          <div className={styles.info}>
            <div className={styles.info__name}>
              {place}.{String(task.place)}
              <EditInput
                sectionIndex={sectionIndex}
                taskIndex={index}
                editKey="name"
              >
                {task.name}
              </EditInput>
            </div>
            <EditInput
              classNames={styles.info__quantity}
              sectionIndex={sectionIndex}
              taskIndex={index}
              editKey="quantity"
            >
              {String(task.quantity)}
            </EditInput>
            <EditInput
              classNames={styles.info__unit}
              sectionIndex={sectionIndex}
              taskIndex={index}
              editKey="unit"
            >
              {task.unit}
            </EditInput>
            <div className={styles.info__amount}>
              {(+setTotal() / task.quantity).toFixed(2)}
            </div>
            <div className={styles.info__total}>{setTotal()}</div>
          </div>
        </div>
      ),
      children: (
        <div className={styles.items}>
          {task?.products?.map((product, productIndex: number) => (
            <Product
              product={product}
              sectionIndex={sectionIndex}
              taskIndex={index}
              index={productIndex}
              key={product.name + productIndex}
            />
          ))}
        </div>
      ),
    },
  ];

  return (
    <div
      className={`${styles.task} ${
        !task.checked || !sections[sectionIndex].checked ? styles.disabled : ""
      } ${isEmptyInput() ? styles.empty_inputs : ""}`}
    >
      {task?.is_title ? (
        <div className={styles.title}>{task?.name}</div>
      ) : isEmptyArr(task.products) ? (
        <div className={`${styles.item}`}>
          <div className={styles.expendable} />
          <Checkbox
            onChange={onChange}
            disabled={!sections[sectionIndex].checked}
            checked={task.checked && sections[sectionIndex].checked}
          />
          <div className={styles.info}>
            <div className={styles.info__name}>
              {place}.{String(task.place)}
              <EditInput
                sectionIndex={sectionIndex}
                taskIndex={index}
                editKey="name"
              >
                {task.name}
              </EditInput>
            </div>
            <EditInput
              classNames={styles.info__quantity}
              sectionIndex={sectionIndex}
              taskIndex={index}
              editKey="quantity"
            >
              {String(task.quantity)}
            </EditInput>
            <EditInput
              classNames={styles.info__unit}
              sectionIndex={sectionIndex}
              taskIndex={index}
              editKey="unit"
            >
              {task.unit}
            </EditInput>
            <EditInput
              classNames={styles.info__amount}
              sectionIndex={sectionIndex}
              taskIndex={index}
              editKey="amount"
            >
              {String(task.amount)}
            </EditInput>
            <div className={styles.info__total}>
              {/* {(task.quantity * task.amount).toFixed(2)} */}
              {task.amount}
            </div>
          </div>
        </div>
      ) : (
        <Collapse
          collapsible="icon"
          // expandIcon={() => <SelectSuffixIcon />}
          items={items}
        />
      )}
    </div>
  );
});

export default Task;
