import React from "react";

import styles from "./custom-progress-bar.module.scss";

type Props = {
  plan: number;
  predictAmount: number;
  fact: number;
  color: string;
  predictColor: string;
  planColor: string;
};

const CustomProgressBar: React.FC<Props> = ({ plan, predictAmount, fact, color, predictColor, planColor }) => {
  const maxAmount = plan > predictAmount ? plan : predictAmount;
  const factPercent = maxAmount > 0 ? (fact / maxAmount) * 100 : 0;
  const planPercent = maxAmount > 0 ? (plan / maxAmount) * 100 : 0;
  const predictPercent = maxAmount > 0 ? (predictAmount / maxAmount) * 100 : 0;
  const excessPercent = factPercent - planPercent;

  return (
    <div className={styles.customProgressContainer}>
      <div
        className={styles.progressBackground}
        style={{
          backgroundColor: predictPercent > 0 ? predictColor : "",
          left: `${predictPercent < 100 ? predictPercent : 0}`,
          width: `${predictPercent}%`,
          borderColor: predictPercent > 0 ? predictColor : ""
        }}
      />
      <div
        className={styles.planProgress}
        style={{
          backgroundColor: planPercent > 0 ? "#fcfdfd" : "",
          left: `${planPercent < 100 ? planPercent : 0}`,
          width: `${planPercent}%`,
          borderColor: planPercent > 0 ? predictColor : ""
        }}
      />
      <div
        className={styles.factProgress}
        style={{
          backgroundColor: factPercent > 0 ? color : "",
          left: `${factPercent < 100 ? factPercent : 0}`,
          width: `${factPercent}%`,
          borderColor: factPercent > 0 ? predictColor : ""
        }}
      />
      {excessPercent > 0 && (
        <div
          className={styles.dashedStroke}
          style={{
            left: `${planPercent < 100 ? planPercent : 0}%`,
            width: `${planPercent < 100 ? excessPercent : 0}%`,
            backgroundColor: color
          }}
        />
      )}
      {factPercent === 0 && planPercent === 0 && predictPercent === 0 && (
        <div
          className={styles.factProgress}
          style={{
            left: `0%`,
            width: `100%`,
            backgroundColor: "#fcfdfd",
            borderColor: predictColor
          }}
        />
      )}
      <div className={styles.planLine} style={{ left: `${Math.abs(planPercent)}%`, backgroundColor: planColor }}>
        <div className={styles.planCircle} style={{ top: "0%", backgroundColor: planColor }} />

        <div className={styles.planCircle} style={{ top: "100%", backgroundColor: planColor }} />
      </div>
    </div>
  );
};

export default CustomProgressBar;
