import React from "react";

type Props = {
  rotate?: number;
  stroke?: string;
};

const ArrowNarrowIcon: React.FC<Props> = ({ rotate, stroke = "#344054" }) => {
  return (
    <svg
      style={{
        transform: `rotate(${rotate ?? 0}deg)`,
      }}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 10H3.3335M3.3335 10L8.3335 15M3.3335 10L8.3335 5"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowNarrowIcon;
