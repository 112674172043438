const RefreshCwIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.166 4.27108C15.9338 5.55903 17.0827 7.6453 17.0827 9.99992C17.0827 13.9119 13.9114 17.0833 9.99935 17.0833H9.58268M5.83268 15.7288C4.0649 14.4408 2.91602 12.3545 2.91602 9.99992C2.91602 6.0879 6.08733 2.91659 9.99935 2.91659H10.416M10.8327 18.6666L9.16602 16.9999L10.8327 15.3333M9.16602 4.66659L10.8327 2.99992L9.16602 1.33325"
        stroke="#1E90FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshCwIcon;
