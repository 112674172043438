import React from "react";
import { useDispatch } from "react-redux";
import { Col, Dropdown, DropdownProps, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { cx } from "../../../../app/utils/helpers/cx";
import { useDeleteVat } from "../../../service/mutations";
import { useGetVatView } from "../../../service/queries";
import { VatModel } from "../../../utils/models/financialModel";

import VatModal from "./vat-modal";
import { useTranslation } from "react-i18next";

const Vat: React.FC = () => {
  const dispatch = useDispatch();
  const deleteVat = useDeleteVat();
  const { setVat } = settingsActions;
  const { data, isLoading } = useGetVatView();
  const { t } = useTranslation();

  const onDelete = (id: number) => () => deleteVat?.mutateAsync({ id });

  const menu = (record: VatModel): DropdownProps["menu"] => ({
    items: [
      {
        key: "2",
        label: (
          <ModalConfirm onOk={onDelete(record?.id)} title="Ushbu NDS ni o'chirmoqchimisiz ?">
            <div className="flex items-center gap-2">
              <DeleteIcon />
              <span>{t("settings.O'chirish")}</span>
            </div>
          </ModalConfirm>
        )
      }
    ]
  });

  const columns: ColumnsType<VatModel> = [
    {
      title: t("settings.NDS foizi"),
      dataIndex: "value",
      render: (value, record) => (
        <div className="flex items-center gap-3">
          <span>{value}</span>
          <ConditionalRender if={record?.basic}>
            <div className="flex items-center gap-1 rounded-xl bg-success-50 px-1.5 py-0.5 text-success-500">
              <div className="h-2 w-2 rounded-full bg-success-500" />
              {t("settings.Asosiy")}
            </div>
          </ConditionalRender>
        </div>
      )
    },
    {
      title: "",
      width: 40,
      render: (_, record) => (
        <Dropdown trigger={["click"]} menu={menu(record)}>
          <div className="flex cursor-pointer items-center justify-end">
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      )
    }
  ];

  const onOpenNDS = () => {
    dispatch(
      setVat({
        visible: true
      })
    );
  };

  const footer = () => (
    <div className="flex px-4 py-2">
      <div className="flex cursor-pointer items-center gap-2" onClick={onOpenNDS}>
        <PlusCircleIcon fill />
        <span className="text-sm font-medium text-primary-500">{t("settings.NDS qo'shish")}</span>
      </div>
    </div>
  );

  return (
    <div className="flex justify-between">
      <Row gutter={32} className="flex-1">
        <Col span={6}>
          <div className="flex flex-col gap-1">
            <h4 className="m-0 text-base font-semibold text-gray-700">{t("settings.NDS")}</h4>
            <p className="m-0 text-sm font-normal text-gray-600">{t("settings.Bu yerda NDS qo'shishingiz mumkin")}</p>
          </div>
        </Col>
        <Col span={18}>
          <Table<VatModel>
            dataSource={data}
            footer={footer}
            columns={columns}
            pagination={false}
            className={cx("footer_table")}
            locale={{
              emptyText: <TableEmpty />
            }}
            loading={{
              spinning: isLoading,
              indicator: LoadingIndicator
            }}
          />
        </Col>
      </Row>
      <VatModal />
    </div>
  );
};

export default Vat;
