import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { FormInstance, Input, Popover } from "antd";

import { useAuth } from "modules/auth/hooks";

import { CheckIcon } from "../../assets/icons/CheckIcon";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { useGetUsersSelect } from "../../service/queries";
import { getFirstLetter } from "../../utils/helpers/getFirstLetter";
import { UserSelectModel } from "../../utils/models/user/userSelectModel";

import styles from "./confirmationUser.module.scss";

type Props = {
  name: string;
  children: ReactNode;
  form: FormInstance;
  data: UserSelectModel[] | UserSelectModel | undefined;
  setData: (data: UserSelectModel[] | UserSelectModel | undefined) => void;
  initialValue?: UserSelectModel[] | UserSelectModel;
};

const ConfirmationUser: React.FC<Props> = ({ children, data, form, name, setData, initialValue }) => {
  const { data: users } = useGetUsersSelect();
  const [searchText, setSearchText] = useState("");
  const { user } = useAuth();

  const filteredUsers = users?.filter(u => u.id !== user?.id);

  useEffect(() => {
    if (initialValue) setData(initialValue);
  }, [initialValue]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const filteredData: typeof users = useMemo(() => {
    if (searchText) {
      return filteredUsers?.filter(user => {
        const name = user.full_name.replace(/\s/g, "").toLowerCase();
        const filterSearchText = searchText.replace(/\s/g, "").toLowerCase();

        return name.includes(filterSearchText);
      });
    }

    return filteredUsers;
  }, [searchText, filteredUsers]);

  const isSelected = (user: UserSelectModel) => {
    if (Array.isArray(data)) {
      const userIds = data.map(user => user.id);

      return userIds.includes(user.id);
    }
    return user.id === data?.id;
  };

  const onSelectUser = (user: UserSelectModel) => {
    let newData;

    if (isSelected(user)) {
      if (Array.isArray(data)) {
        newData = data.filter(item => item.id !== user.id);
      } else {
        newData = undefined;
      }
    } else if (Array.isArray(data)) {
      newData = [...data, user];
    } else {
      newData = [user];
    }

    setData(newData);

    form.setFieldValue(name, Array.isArray(newData) ? newData.map(item => item.id) : newData);
  };

  const content = (
    <div className={styles.popover__content}>
      <div className={styles.popover__content__top}>
        <Input onChange={onSearch} prefix={<SearchIcon />} placeholder="Qidiruv" />
      </div>
      <div className={styles.popover__content__bottom}>
        {filteredData?.map(user => (
          <div
            key={user.id}
            className={`${styles.user} ${isSelected(user) ? styles.active : ""}`}
            onClick={() => onSelectUser(user)}
          >
            {user?.image ? (
              <img alt={user.full_name} src={user.image} />
            ) : (
              <span className={styles.non_image}>{getFirstLetter(user?.full_name)}</span>
            )}
            <p>{user.full_name}</p>
            <div className={styles.check_icon}>
              <CheckIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Popover content={content} trigger="click" placement="bottomLeft" overlayClassName={styles.popover}>
      {children}
    </Popover>
  );
};

export default ConfirmationUser;
