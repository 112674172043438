import React, { useEffect, useState } from "react";
import { Badge, Dropdown, Spin, Tabs } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import BellIcon from "../../assets/icons/BellIcon";
import CheckAllIcon from "../../assets/icons/CheckAllIcon";
import CloseIcon from "../../assets/icons/CloseIcon";
import { useCreateDeviceToken, useReadAllNotifications } from "../../service/mutation";
import { useGetNotifications } from "../../service/queries";
import { useNotificationSubscribe } from "../../service/websocket";
import { notificationsTabKeys } from "../../utils/constants/notificationsTabKeys";
import Chat from "../chat/Chat";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import { sendRequest } from "./firebase/firebase";
import NotificationsContent from "./notifications-content/NotificationsContent";

import styles from "./notification.module.scss";

const Notification: React.FC = () => {
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const [open, setOpen] = useState(false);
  const [tabKey, setTabKey] = useState<notificationsTabKeys>(notificationsTabKeys.UNREAD);
  const { data: notifications, isLoading, isFetching } = useGetNotifications(notificationsTabKeys.UNREAD);

  useNotificationSubscribe();
  const readAllNotifications = useReadAllNotifications();
  const createDeviceToken = useCreateDeviceToken();

  const onChangeTabs = (key: string) => {
    setTabKey(key as notificationsTabKeys);
  };

  const onReadAllNotifications = () => {
    readAllNotifications.mutate();
  };

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const items = [
    {
      key: notificationsTabKeys.UNREAD,
      label: "O‘qilmagan",
      children: <NotificationsContent setOpen={setOpen} type={notificationsTabKeys.UNREAD} />
    },
    {
      key: notificationsTabKeys.READ,
      label: "O‘qilgan",
      children: <NotificationsContent setOpen={setOpen} type={notificationsTabKeys.READ} />
    },
    {
      key: notificationsTabKeys.ALL,
      label: "Barchasi",
      children: <NotificationsContent setOpen={setOpen} type={notificationsTabKeys.ALL} />
    }
  ];

  const customDropdown = () => (
    <Spin spinning={isLoading || readAllNotifications.isLoading || isFetching} indicator={LoadingIndicator}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h3>{t("login.Bildirishnoma")}</h3>
          <div className={styles.actions}>
            {tabKey !== notificationsTabKeys.READ && (
              <div className={styles.actions__icon} onClick={onReadAllNotifications}>
                <CheckAllIcon />
              </div>
            )}
            <div onClick={onClose} className={styles.actions__close}>
              <CloseIcon />
            </div>
          </div>
        </div>
        <Tabs items={items} accessKey={tabKey} onChange={onChangeTabs} />
      </div>
    </Spin>
  );

  useEffect(() => {
    sendRequest(setToken);
    if (token) {
      createDeviceToken.mutate({ device_token: token });
    }
  }, [token]);

  return (
    <>
      <Dropdown
        open={open}
        dropdownRender={customDropdown}
        trigger={["click"]}
        overlayClassName={styles.notifications_dropdown}
        onOpenChange={e => setOpen(e.valueOf())}
      >
        <div className={styles.dropdown_button}>
          <div
            onClick={onOpen}
            className={classNames(styles.click_icon, {
              [styles.click_icon__opened]: open
            })}
          >
            <BellIcon />
          </div>
          <Badge count={notifications?.pages[0]?.unviewed_notifications_count as number} overflowCount={9} />
        </div>
      </Dropdown>
      <Chat />
    </>
  );
};

export default Notification;
