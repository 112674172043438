import React from "react";

const PhoneCallIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14030_446)">
        <path
          d="M9.36612 3.99992C10.0173 4.12696 10.6157 4.44542 11.0848 4.91454C11.5539 5.38366 11.8724 5.9821 11.9994 6.63325M9.36612 1.33325C10.719 1.48354 11.9805 2.08937 12.9436 3.05126C13.9067 4.01315 14.5141 5.27393 14.6661 6.62659M6.81744 9.24196C6.01638 8.44091 5.38386 7.53515 4.91986 6.56874C4.87995 6.48561 4.85999 6.44405 4.84466 6.39145C4.79018 6.20455 4.82931 5.97505 4.94265 5.81676C4.97455 5.77222 5.01265 5.73411 5.08886 5.65791C5.32193 5.42483 5.43847 5.3083 5.51466 5.19111C5.80199 4.74919 5.80199 4.17947 5.51466 3.73754C5.43847 3.62035 5.32193 3.50382 5.08886 3.27075L4.95895 3.14084C4.60465 2.78654 4.4275 2.60939 4.23725 2.51316C3.85888 2.32178 3.41203 2.32178 3.03366 2.51316C2.8434 2.60939 2.66626 2.78654 2.31196 3.14084L2.20687 3.24593C1.85379 3.59901 1.67725 3.77555 1.54242 4.01557C1.3928 4.28191 1.28523 4.69557 1.28614 5.00105C1.28696 5.27635 1.34036 5.4645 1.44716 5.84079C2.02114 7.86306 3.10413 9.7713 4.69611 11.3633C6.2881 12.9553 8.19634 14.0383 10.2186 14.6122C10.5949 14.719 10.7831 14.7724 11.0584 14.7733C11.3638 14.7742 11.7775 14.6666 12.0438 14.517C12.2839 14.3822 12.4604 14.2056 12.8135 13.8525L12.9186 13.7474C13.2729 13.3931 13.45 13.216 13.5462 13.0257C13.7376 12.6474 13.7376 12.2005 13.5462 11.8221C13.45 11.6319 13.2729 11.4547 12.9186 11.1005L12.7887 10.9705C12.5556 10.7375 12.439 10.6209 12.3219 10.5447C11.8799 10.2574 11.3102 10.2574 10.8683 10.5447C10.7511 10.6209 10.6346 10.7375 10.4015 10.9705C10.3253 11.0467 10.2872 11.0849 10.2426 11.1167C10.0844 11.2301 9.85485 11.2692 9.66795 11.2147C9.61535 11.1994 9.57379 11.1795 9.49066 11.1395C8.52425 10.6755 7.61849 10.043 6.81744 9.24196Z"
          stroke="#667085" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_14030_446">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default PhoneCallIcon;