import React, { Dispatch, useEffect } from "react";
import { FormInstance, Radio, Spin } from "antd";
import { PaymentCashMoneyModel } from "features/payment/utils/models/paymentCashMoneyModel";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../../app/utils/constants/languages";
import CheckboxBaseIcon from "../../../../../assets/icons/CheckboxBaseIcon";
import { useGetCashMoneyPaymentTypeSelect } from "../../../../../service/queries";

import styles from "./secondStep.module.scss";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { numberRound } from "modules/common";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  form: FormInstance;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<React.SetStateAction<boolean>>;
};

const SecondStep: React.FC<Props> = ({ setStep, form, autoAdvance, setAutoAdvance }) => {
  const dispatch = useAppDispatch();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const { paymentInventoryWorkModal } = useAppSelector(state => state.paymentReducer);

  const {
    data: paymentTypes,
    isLoading: isLoadingPaymentTypes,
    isFetching
  } = useGetCashMoneyPaymentTypeSelect({
    type: paymentInventoryWorkModal?.type,
    cashId: paymentInventoryWorkModal?.cash?.id,
    enabled: Boolean(paymentInventoryWorkModal?.cash?.id)
  });

  const onClickPayment = (value: string) => {
    const [currencyId, paymentTypeId] = value.split("-");

    const paymentType = paymentTypes?.find(item => item.id === Number(paymentTypeId));
    const { company_person, ...rest } = paymentType!;
    const selectCurrency = paymentType?.currencies?.find(item => item.id === Number(currencyId));

    dispatch(
      setPaymentInventoryWorkModal({
        ...paymentInventoryWorkModal,
        cash: paymentInventoryWorkModal?.cash,
        type: paymentInventoryWorkModal?.type,
        currency: selectCurrency,
        payment_type: {
          company_person: paymentInventoryWorkModal?.payment_type?.company_person
            ? paymentInventoryWorkModal?.payment_type?.company_person
            : {},
          ...rest
        }
      })
    );

    form.setFieldValue("project_id", undefined);
    form.setFieldValue("amount", undefined);

    setAutoAdvance(true);

    setStep(2);
  };

  useEffect(() => {
    if (paymentTypes && autoAdvance) {
      if (paymentTypes?.length === 1 && paymentTypes[0]?.currencies?.length === 1) {
        const { company_person, ...rest } = paymentTypes[0];

        dispatch(
          setPaymentInventoryWorkModal({
            ...paymentInventoryWorkModal,
            cash: paymentInventoryWorkModal?.cash,
            type: paymentInventoryWorkModal?.type,
            currency: paymentTypes[0]?.currencies[0],
            payment_type: {
              company_person: paymentInventoryWorkModal?.payment_type?.company_person
                ? paymentInventoryWorkModal?.payment_type?.company_person
                : {},
              ...rest
            }
          })
        );

        form.setFieldValue("project_id", undefined);
        form.setFieldValue("amount", undefined);

        setStep(state => {
          if (state === 1) {
            return state + 1;
          }
          return state - 1;
        });
      }
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingPaymentTypes || isFetching} indicator={LoadingIndicator}>
      <div className={styles.payment}>
        <div className={styles.cash}>
          <div className={styles.name}>
            <h4>{paymentInventoryWorkModal?.cash?.name}</h4>
            <p>Kassa</p>
          </div>
          <div className={styles.currency}>
            {paymentInventoryWorkModal?.cash?.currencies?.map(currency => (
              <div className={styles.currency__item} key={currency.id}>
                <img src={currency.icon} alt={currency.symbol} />
                <span>
                  {currency.amount?.toLocaleString(RU)} {currency.symbol}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottom}>
          {paymentTypes?.map(item => (
            <div key={item.id} className={styles.item}>
              <Radio.Group
                optionType="button"
                value={`${paymentInventoryWorkModal?.currency?.id}-${paymentInventoryWorkModal?.payment_type?.id}`}
              >
                <div className={styles.item__left}>{item.name}</div>
                {item?.currencies?.map(currency => (
                  <Radio
                    key={currency.id}
                    value={`${currency.id}-${item.id}`}
                    onClick={() => onClickPayment(`${currency.id}-${item.id}`)}
                  >
                    <div className={styles.payment_currency}>
                      <img src={currency.icon} alt={currency?.symbol} />
                      <span>
                        {currency.amount?.toLocaleString(RU)} {currency.symbol}
                      </span>
                    </div>
                    <CheckboxBaseIcon />
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default SecondStep;
