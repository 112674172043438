import React from "react";
import { Input } from "antd";

import { localeFormatter } from "../../../utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../utils/helpers/parseLocaledString";

import styles from "./filterPricesRange.module.scss";

type Props = {
  startPriceValue: string;
  endPriceValue: string;
  setStartPriceValue: (start: string) => void;
  setEndPriceValue: (start: string) => void;
  placeholder: string;
  isClear?: number;
  classNames?: string;
};

const FilterPricesRange = ({
  startPriceValue,
  endPriceValue,
  setStartPriceValue,
  setEndPriceValue,
  classNames,
  placeholder,
}: Props) => {
  const onChangeStart = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e?.target as HTMLInputElement;

    const newStartPrice = parseLocaledString(target?.value);
    setStartPriceValue(localeFormatter(String(newStartPrice)));
  };

  const onChangeEnd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e?.target as HTMLInputElement;
    const newEndPrice = parseLocaledString(target?.value);
    setEndPriceValue(localeFormatter(String(newEndPrice)));
  };

  return (
    <div className={`${styles.price__range} ${classNames}`}>
      <span>{placeholder}</span>
      <Input onChange={onChangeStart} value={startPriceValue} />
      -
      <Input onChange={onChangeEnd} value={endPriceValue} />
    </div>
  );
};

export default FilterPricesRange;
