import React from "react";

const ProjectReportIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      width={props?.width || "20"}
      height={props?.height || "18"}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 12.3333V16.5M14.9993 16.5L11.7065 13.756C11.0992 13.2499 10.7955 12.9968 10.4566 12.9001C10.1577 12.8149 9.84098 12.8149 9.54213 12.9001C9.20316 12.9968 8.89951 13.2499 8.29219 13.756L4.99935 16.5M6.66602 8.16667V9M9.99935 6.5V9M13.3327 4.83333V9M18.3327 1.5H1.66602M2.49935 1.5H17.4993V8.33333C17.4993 9.73346 17.4993 10.4335 17.2269 10.9683C16.9872 11.4387 16.6047 11.8212 16.1343 12.0608C15.5995 12.3333 14.8995 12.3333 13.4993 12.3333H6.49935C5.09922 12.3333 4.39915 12.3333 3.86437 12.0608C3.39397 11.8212 3.01152 11.4387 2.77183 10.9683C2.49935 10.4335 2.49935 9.73346 2.49935 8.33333V1.5Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProjectReportIcon;
