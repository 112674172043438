import React from "react";

const CalendarEmptyIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg {...props} width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="5" width="56" height="56" rx="28" fill="#D1E9FF" />
    <rect x="5" y="5" width="56" height="56" rx="28" stroke="#EFF8FF" strokeWidth="10" />
    <path
      d="M43.5 30.6667H22.5M37.6667 21.3334V26M28.3333 21.3334V26M28.1 44.6667H37.9C39.8602 44.6667 40.8403 44.6667 41.589 44.2852C42.2475 43.9497 42.783 43.4142 43.1185 42.7557C43.5 42.007 43.5 41.0269 43.5 39.0667V29.2667C43.5 27.3065 43.5 26.3264 43.1185 25.5777C42.783 24.9192 42.2475 24.3837 41.589 24.0482C40.8403 23.6667 39.8602 23.6667 37.9 23.6667H28.1C26.1398 23.6667 25.1597 23.6667 24.411 24.0482C23.7525 24.3837 23.217 24.9192 22.8815 25.5777C22.5 26.3264 22.5 27.3065 22.5 29.2667V39.0667C22.5 41.0269 22.5 42.007 22.8815 42.7557C23.217 43.4142 23.7525 43.9497 24.411 44.2852C25.1597 44.6667 26.1398 44.6667 28.1 44.6667Z"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarEmptyIcon;
