export enum ChatEnum {
  WAREHOUSE_DEFECTS = "warehouse_defects",
  WAREHOUSE_PRODUCTS = "warehouse_products",
  WAREHOUSE_TRANSFERS = "warehouse_transfers",
  TASK_PRODUCTS = "task_products",
  TASK_PROGRESSES = "task_progresses",
  ORDERS = "orders",
  INVENTORY_DEFECTS = "inventory_defects",
  PAYMENTS = "payments",
  TASKS = "tasks",
  LABORATORIES = 'laboratories'
}
