import { isEmptyArr } from "./isEmptyArr";

export function generateExcelPayload(obj: object) {
  let result = {};

  for (const key in obj) {
    const value = obj[key as keyof object];

    if (Array.isArray(value) && !isEmptyArr(value)) {
      result = {
        ...result,
        [key]: value
      };
    }

    if (!Array.isArray(value) && value) {
      result = {
        ...result,
        [key]: value
      };
    }
  }

  return result;
}
