import React from "react";
import { Spin } from "antd";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import TaskProductsTemplateFolderCard from "../folder/TaskProductsTemplateFolderCard";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { CategoryModel } from "../../../../../../../settings/utils/models/category/CategoryModel";

import styles from "./taskProductsTemplateFolders.module.scss";

type Props = {
  data?: CategoryModel[];
  isLoading: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  isCategory: boolean;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
};

const TaskProductsTemplateFolders: React.FC<Props> = ({
  data,
  setPage,
  isLoading,
  setTabKey,
  isCategory,
  setFolderId,
  setBreadcrumbItems,
}) => {
  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.folders}>
        {!isEmptyArr(data) &&
          data?.map((item) => (
            <TaskProductsTemplateFolderCard
              {...item}
              key={item.id}
              setPage={setPage}
              setTabKey={setTabKey}
              isCategory={isCategory}
              setFolderId={setFolderId}
              setBreadcrumbItems={setBreadcrumbItems}
            />
          ))}
      </div>
    </Spin>
  );
};

export default TaskProductsTemplateFolders;
