import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import { useAuth } from "modules/auth/hooks";

import Loading from "../../features/app/components/loading/Loading";
import SettingsLayout from "../../features/app/components/settings-layout/SettingsLayout";
import { exactRouteFixer } from "../../features/app/utils/helpers/exactRouterFixer";

import { settingsRoutes } from "./settingsRoutes";

const SettingsRouter: React.FC = () => {
  const { permissions } = useAuth();

  return (
    <SettingsLayout>
      <Routes>
        {settingsRoutes({ permissions }).map(({ path, isExact, element: Element }) => (
          <Route
            key={path}
            path={exactRouteFixer(path!, isExact)}
            element={
              <Suspense fallback={<Loading />}>
                <Element />
              </Suspense>
            }
          />
        ))}
      </Routes>
    </SettingsLayout>
  );
};

export default SettingsRouter;
