import dayjs from "dayjs";

import { dayjsFormats } from "../../common";

type DayjsFormat = typeof dayjsFormats;

type FormatType = {
  [K in keyof DayjsFormat]: DayjsFormat[K];
}[keyof DayjsFormat];

export function numberToDateFormat(number?: number, format?: FormatType): string {
  return dayjs((number || 1) * 1000)?.format(format || dayjsFormats.DATE_TIME);
}
