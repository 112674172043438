import React, { useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";
import PaymentView from "features/impost/components/payments/payment-view/PaymentVIew";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { useTranslation } from "react-i18next";

import { RU } from "modules/common";
import { ConfirmationPaymentView } from "modules/dashboard";

import styles from "./income-expense.module.scss";

interface IProps {
  data: PaginationType<ConfirmationPaymentView[]> | undefined;
  isLoading: boolean;
}

const IncomeExpense: React.FC<IProps> = ({ data, isLoading }) => {
  const { t } = useTranslation();
  const [record, setRecord] = useState<ConfirmationPaymentView>();
  const [visibleDetail, setVisibleDetail] = useState(false);

  const onDetailIncomeOrExpense = (record: ConfirmationPaymentView) => ({
    onClick: () => {
      setRecord(record);
      setVisibleDetail(true);
    }
  });

  const columns: ColumnsType<ConfirmationPaymentView> = [
    {
      title: `${t("dashboard.Manbaa")}`,
      render: (record: ConfirmationPaymentView) => <PaymentSourceElement record={record as never} />,
      width: "65%"
    },
    {
      title: `${t("dashboard.Sana")}`,
      dataIndex: "date"
    },
    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: (record: ConfirmationPaymentView) => (
        <>
          {record.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      )
    },

    {
      title: "",
      render: (record: ConfirmationPaymentView) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} />
        </div>
      ),
      width: "5%"
    }
  ];

  return (
    <div className={styles.payment}>
      <Table<ConfirmationPaymentView>
        className={`${styles.payment__table} footer_table`}
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        rowKey={record => record.id}
        onRow={onDetailIncomeOrExpense}
        rowClassName={styles.row}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
        />
      </div>
      <PaymentView visible={visibleDetail} setVisible={setVisibleDetail} record={record} />
    </div>
  );
};

export default IncomeExpense;
