import React from "react";

import EmptyPlan from "../../../../app/assets/images/emptyPlan.png";

import styles from "./dynamicEstimate.module.scss";

const EmptyDynamicEstimate: React.FC = () => {
  return (
    <div className={styles.empty_data}>
      <img src={EmptyPlan} alt="EmptyPlan" />
      <h4>Loyihani rejalashtirishni boshlang</h4>
      <p>
        Loyihaga ishlar kiriting, resurslar biriktiring, ish grafigini tuzib chiqing
      </p>
    </div>
  );
};

export default EmptyDynamicEstimate;
