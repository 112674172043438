import { Popover } from "antd";

import { useAppSelector } from "../../../../../../hooks/redux";
import { RU } from "../../../../../app/utils/constants/languages";
import { DynamicEstimateEnum } from "../../../../utils/enums/dynamicEstimateEnum";
import {
  DynamicEstimateProductModel,
  DynamicEstimateTaskModel,
} from "../../../../utils/models/dynamicEstimateModel";

import styles from "./diffPopover.module.scss";

type Props = {
  record: DynamicEstimateProductModel | DynamicEstimateTaskModel;
  classNames: string;
  isFeature: boolean;
  taskStatus?: DynamicEstimateEnum;
  getPopoverContainer?:()=>HTMLElement
};

const DiffPopoverAmount = ({ record, classNames, isFeature,getPopoverContainer }: Props) => {
  const currency = useAppSelector((state) => state.projectsReducer.projectCurrency);

  // const setAmount = () => {
  //   if (
  //     taskStatus === DynamicEstimateEnum.CLOSED ||
  //     taskStatus === DynamicEstimateEnum.COMPLETED
  //   ) {
  //     return record?.spend_total_quantity === 0
  //       ? 0
  //       : (
  //           record?.spend_total_amount / record?.spend_total_quantity
  //         )?.toLocaleString(RU);
  //   }
  //   return record?.quantity === 0
  //     ? 0
  //     : (
  //         (record?.spend_total_amount + record?.expected_total_amount) /
  //         record?.quantity
  //       )?.toLocaleString(RU);
  // };

  // const setDiffAmount = () => {
  //   if (
  //     taskStatus === DynamicEstimateEnum.CLOSED ||
  //     taskStatus === DynamicEstimateEnum.COMPLETED
  //   ) {
  //     return record?.spend_total_quantity === 0
  //       ? 0
  //       : record?.spend_total_amount / record?.spend_total_quantity;
  //   }
  //   return record?.quantity === 0
  //     ? 0
  //     : (record?.spend_total_amount + record?.expected_total_amount) /
  //         record?.quantity;
  // };

  const currentQuantity =
    record?.quantity > record?.spend_total_quantity
      ? record?.quantity
      : record?.spend_total_quantity;

  const setDiffAmount = () => {
    if (isFeature) {
      return currentQuantity === 0
        ? 0
        : (record?.spend_total_amount + record?.expected_total_amount) /
            currentQuantity;
    }
    return record?.spend_total_quantity === 0
      ? 0
      : record?.spend_total_amount / record?.spend_total_quantity;
  };

  const title = (record: DynamicEstimateProductModel | DynamicEstimateTaskModel) => (
    <div className={styles.popover__inner}>
      <div className={styles.popover__inner__item}>
        <p>Plan</p>
        <p>
          {record?.amount?.toLocaleString(RU) ?? 0} {currency?.symbol}
        </p>
      </div>
      <div className={styles.popover__inner__item}>
        <p>Fakt</p>
        <p>
          {record?.spend_total_quantity !== 0
            ? (
                record?.spend_total_amount / record?.spend_total_quantity
              ).toLocaleString(RU)
            : 0}{" "}
          {currency?.symbol}
        </p>
      </div>
      {isFeature && (
        <div className={styles.popover__inner__item}>
          <p>Bashorat</p>
          <p>
            {(
              (record?.spend_total_amount + record?.expected_total_amount) /
              currentQuantity
            ).toLocaleString(RU)}{" "}
            {currency?.symbol}
          </p>
        </div>
      )}
      <div className={styles.popover__inner__item}>
        <p>Tafovut</p>
        <p
          className={
            record?.amount - setDiffAmount() > 0
              ? styles.success_amount
              : styles.error_amount
          }
        >
          {Math.abs(record?.amount - setDiffAmount())?.toLocaleString(RU) ?? 0}{" "}
          {currency?.symbol}
        </p>
      </div>
    </div>
  );

  return (
    <Popover getPopupContainer={getPopoverContainer} title={title(record)} overlayClassName={styles.diff__popover}>
      <p
        className={`${classNames} ${
          record?.amount - setDiffAmount() > 0 ? styles.success : styles.error
        }`}
      >
        {setDiffAmount()?.toLocaleString(RU)}
      </p>
    </Popover>
  );
};

export default DiffPopoverAmount;
