import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { CashStatusDetailed, dashboardQueryKeys } from "..";

interface Query {
  data: CashStatusDetailed;
}

interface Params {
  cashId: number;
  id: number;
}

export function useCashStatusDetailed({ cashId, id }: Params) {
  const initialValue: Query = {
    data: {
      id: 0,
      name: "",
      projects: [],
      payment_types: [],
      currencies: []
    }
  };

  const { data = initialValue, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.ONE_COMPANY_WIDGETS, id, cashId],
    async () => {
      const { data: baseData } = await $api.get(
        `company-widget/cash-status/group-by-payment-type-view?id=${id}&cash_id=${cashId}`
      );

      // const data = paymentCardDetailedSchema.parse(baseData);

      return { data: baseData.data };
    },
    { enabled: !!id }
  );

  return { ...data, ...arg };
}
