import React from "react";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";

import { useWidget } from "modules/dashboard";
import { useGetProjectByResourceInDetailView } from "modules/dashboard/queries/use-get-project-by-resource-in-detail-view";
import { useGetProjectByResourceOthersInDetailView } from "modules/dashboard/queries/use-get-project-by-resource-others-in-detail";

import Bottom from "./bottom/bottom";
import OthersTable from "./others-table/others-table";
import Top from "./top/top";

const ProjectByResource: React.FC = () => {
  const { detailedId, detailedProps, detailedVisible } = useWidget();
  const { projectByResourceInDetailView, isLoading } = useGetProjectByResourceInDetailView({
    enabled: detailedVisible,
    detailedId,
    type: detailedProps?.type
  });

  const { projectByResourceOthersInDetailView, isLoading: isTheOthersLoading } =
    useGetProjectByResourceOthersInDetailView({ enabled: detailedVisible, detailedId, type: detailedProps?.type });

  return (
    <div className="flex w-max flex-col gap-4 p-5">
      {detailedProps?.type !== "another" && <Top product={detailedProps?.product} />}
      {detailedProps?.type !== "another" ? (
        <Bottom data={projectByResourceInDetailView} isLoading={isLoading} />
      ) : (
        <OthersTable data={projectByResourceOthersInDetailView} isLoading={isTheOthersLoading} />
      )}
      <IncomeExpenseView  />
    </div>
  );
};

export default ProjectByResource;
