import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { rootPaths } from "../../../../routes/root/rootPaths";
import SettingsRoles from "../../components/admins/roles/SettingsRoles";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import SettingsAdmins from "../../components/admins/admins/SettingsAdmins";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const employeesPath = `${rootPaths.SETTINGS}${settingsPaths.ADMINS}`;
  const rolesPath = `${rootPaths.SETTINGS}${settingsPaths.ADMINS_ROLES}`;

  // active
  const activeLink = (path: string) => {
    return path === location.pathname ? styles.active : "";
  };

  return (
    <div className={styles.employees}>
      <div className={styles.employees__top}>
        <Link to={employeesPath} className={activeLink(employeesPath)}>
          {t("Xodimlar.Xodimlar")}
        </Link>
        <Link to={rolesPath} className={activeLink(rolesPath)}>
          {t("Xodimlar.Lavozimlar")}
        </Link>
      </div>
      <div className={styles.employees_bottom}>
        <Routes>
          <Route path={settingsPaths.INDEX} element={<SettingsAdmins />} />
          <Route path={settingsPaths.ROLES} element={<SettingsRoles />} />
        </Routes>
      </div>
    </div>
  );
};

export default Index;
