import React from "react";

import { useProjectSelect } from "../../service/queries";
import {
  ProjectSelectBasePropsModel,
  ProjectSelectFormModel,
  ProjectSelectStateModel
} from "../../utils/models/projectSelectModel";

import Form from "./items/form";
import State from "./items/state";

type Props = (
  | ({
      mode?: "form";
    } & Partial<ProjectSelectFormModel>)
  | ({
      mode: "state";
    } & ProjectSelectStateModel)
) &
  Partial<ProjectSelectBasePropsModel>;

const ProjectSelect: React.FC<Props> = props => {
  const { mode, cashId, warehouseId, enabled, payloadData } = props;
  const { data, isLoading } = useProjectSelect({ cashId, warehouseId, enabled });

  return mode === "state" ? (
    <State {...props} data={payloadData || data} isLoading={isLoading} />
  ) : (
    <Form {...props} data={payloadData || data} isLoading={isLoading} />
  );
};

export default ProjectSelect;
