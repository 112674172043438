import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { sliceText } from "../../../../app/utils/helpers/sliceText";
import AddOfferIcon from "../../../../counterparts/assets/icons/AddOfferIcon";
import ClockFastForwardIcon from "../../../../supply/assets/icons/ClockFastForwardIcon";
import { OfferModalDataModel } from "../../../../supply/utils/models/OfferModel";
import { useDeleteProduct } from "../../../service/mutations";
import { ProductModel } from "../../../utils/models/product/productModel";

import styles from "./settingsProductTable.module.scss";

type Props = {
  setModalData: React.Dispatch<React.SetStateAction<{ visible: boolean; data?: ProductModel | undefined }>>;
  category_id?: string;
  sub_category_id?: string;
  data?: ProductModel[];
  isLoading: boolean;
};

const SettingsProductTable: React.FC<Props> = ({ setModalData, sub_category_id, category_id, data, isLoading }) => {
  const { i18n, t } = useTranslation();
  const deleteProduct = useDeleteProduct();
  const dispatch = useDispatch();
  const { setOfferModal, setOfferHistory } = supplyOfferActions;
  const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

  const onDelete = (id: number) => {
    let reqDeleteData: {
      id: number;
      category_id?: string;
      sub_category_id?: string;
    } = { id };

    category_id && (reqDeleteData = Object.assign(reqDeleteData, { category_id }));

    sub_category_id && (reqDeleteData = Object.assign(reqDeleteData, { sub_category_id }));

    return deleteProduct.mutateAsync(reqDeleteData);
  };

  // open modal
  const onOpenModal = (record: ProductModel) => {
    setModalData({
      visible: true,
      data: record
    });
  };

  const onOpenOfferModal = (record?: ProductModel) => {
    dispatch(
      setOfferModal({
        visible: true,
        viewPartySwitch: false,
        data: (record ? [record] : selectProducts) as OfferModalDataModel[],
        isCatalog: true
      })
    );
  };

  const onOpenOfferHistory = (record: ProductModel) => {
    dispatch(
      setOfferHistory({
        visible: true,
        productId: record?.id,
        isCheckedView: true
      })
    );
  };

  const actionItems = (record: ProductModel) => [
    {
      key: "1",
      label: (
        <div className={styles.dropdown_item}>
          <AddOfferIcon />
          <span>{t("products.Taklif qo'shish")}</span>
        </div>
      ),
      onClick: () => onOpenOfferModal(record)
    },
    {
      key: "2",
      label: (
        <div className={styles.dropdown_item}>
          <ClockFastForwardIcon />
          <span>{t("products.Takliflar tarixi")}</span>
        </div>
      ),
      onClick: () => onOpenOfferHistory(record)
    },
    {
      key: "3",
      label: (
        <div className={styles.dropdown_item}>
          <EditIcon /> {t("products.Tahrirlash")}
        </div>
      ),
      onClick: () => onOpenModal(record)
    },
    {
      key: "4",
      label: (
        <div className={styles.dropdown_item} onClick={() => onDelete(record.id)}>
          <DeleteIcon /> {t("products.O'chirish")}
        </div>
      )
    }
  ];

  const columns: ColumnsType<ProductModel> = [
    {
      title: `${t("products.Resurs turi va nomi")}`,
      render: (record: ProductModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.resource?.color,
              color: record?.resource?.color
            }}
          >
            {record?.resource?.symbol[i18n.language]}
          </div>
          {record?.name[i18n.language]?.length > 40 ? (
            <Popover title={record?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{sliceText(record?.name[i18n.language], 40)}</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.name[i18n.language]}</span>
          )}
        </div>
      )
    },
    {
      title: `${t("products.Birligi")}`,
      render: (record: ProductModel) => record.unit.symbol[i18n.language],
      width: 200
    },
    // {
    //   title: "Resurs",
    //   render: (record: ProductModel) => record.resource.name[i18n.language],
    // },
    {
      title: `${t("products.Kod")}`,
      render: (record: ProductModel) => record.code,
      width: 100
    },
    {
      title: `${t("products.Bozor narxi")}`,
      render: (record: ProductModel) => (
        <span className={styles.market_price}>
          {localeFormatter(String(record?.market_price))} {record.market_price_currency?.symbol}
        </span>
      ),
      width: 170
    },
    {
      title: `${t("products.Bozor narxi sanasi")}`,
      dataIndex: "market_price_date",
      width: 170
    },
    {
      title: "",
      render: (record: ProductModel) => (
        <Dropdown menu={{ items: actionItems(record) }} trigger={["click"]}>
          <div className="d_f ai_c jc_c c_p">
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      ),
      width: 30
    }
  ];

  return (
    <>
      <Table<ProductModel>
        columns={columns}
        className={styles.product_table}
        pagination={false}
        dataSource={data}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        rowKey={e => e.id}
      />
    </>
  );
};

export default SettingsProductTable;
