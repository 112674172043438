import { Modal, Spin } from "antd";
import { useParams } from "react-router-dom";

import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import Comments from "../../../../../app/components/comments/Comments";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetWork } from "../../../../service/queries";
import WorkModalLeft from "./left/WorkModalLeft";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../store/reducers/projectsReducer";
import styles from "./workModal.module.scss";

const WorkModal = () => {
  const { setWork } = projectsReducerActions;
  const dispatch = useAppDispatch();
  const { workModal } = useAppSelector((state) => state.projectsReducer);
  const { projectId } = useParams();
  const { data: work, isLoading } = useGetWork(
    workModal.visible,
    +projectId!,
    workModal?.id
  );

  const onCancel = () => {
    dispatch(setWork({ visible: false, id: 0 }));
  };

  const title = (
    <div className={styles.title}>
      <p>{`${work?.place}. ${work?.name}`}</p>
      <div className={styles.close_icon} onClick={onCancel}>
        <CloseIcon />
      </div>
    </div>
  );

  return (
    <Modal
      width={1162}
      centered
      footer={null}
      open={workModal.visible}
      title={title}
      onCancel={onCancel}
      closeIcon={false}
      className={styles.work_modal}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.content}>
          <WorkModalLeft id={workModal?.id} visible={workModal.visible} />
          <div className={styles.right}>
            <Comments type="tasks" objectId={workModal?.id} />
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default WorkModal;
