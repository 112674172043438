import { z } from "zod";

const statusSchema = z.enum(["active", "passive"]);

export const userSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  login: z.string(),
  phone: z.string(),
  image: z.string().nullable(),
  status: statusSchema,
  Authorization: z.string().nullable(),
  role_name: z.string(),
  company_name: z.string()
});

export type User = z.infer<typeof userSchema>;

export const PERMISSION_KEY = z.enum([
  "Loyiha",
  "Ta'minot",
  "Kassa",
  "Omborxona",
  "Kontragent",
  "Inventar",
  "Sozlamalar",
  "Monitoring",
  "Kontragent balansi",
  "Dashboard"
]);

export const SUBMODULE_KEY = z.enum([
  "Yaroqsiz inventarlar",
  "Papkalar",
  "Loyihalar",
  "Smeta",
  "Ishlar",
  "Buyurtmalar",
  "Partiyalar",
  "Smeta buyurtmalari",
  "Takliflar",
  "Kirim-chiqim",
  "Kutilayotgan to'lovlar",
  "Qarzlarim",
  "Buyurtmalarga to'lovlar",
  "Ishlar uchun to'lovlar",
  "Inventarlarga to'lovlar",
  "To'lov kalendari",
  "Omborxona",
  "O'tkazmalar",
  "Yaroqsiz mahsulotlar",
  "Kontragentlar",
  "Inventarlar",
  "Inventarlar papkasi",
  "So'rovlar",
  "Mahsulotlar",
  "Kategoriyalar",
  "Moliya",
  "Kassa",
  "Xodimlar",
  "Xarajat va daromad",
  "O'zgaruvchilar",
  "Hujjatlar",
  "Quruvchi",
  "Arxiv",
  "Inventar",
  "Act sverka",
  "Ishlarga to'lovlar"
]);

export const ACTION_KEY = z.enum([
  "Papkalarni ko'rish",
  "Papka yaratish",
  "Papkani yangilash",
  "Papkani o'chirish",
  "Loyihalarni ko'rish",
  "Loyihani yangilash",
  "Loyiha yaratish",
  "Loyihani o'chirish",
  "Smetani ko'rish",
  "Smetaga ish bo'limi qo'shish",
  "Ish bo'limini yangilash",
  "Ish bo'limini o'chirish",
  "Ishlar",
  "Ish qo'shish",
  "Smetani import qilib olish",
  "Gant chart",
  "Ishlar ro'yxati",
  "Ishlar bo'yicha statistika",
  "Buyurtmalarni ko'rish",
  "Buyurtma yaratish",
  "Buyurtmani o'chirish",
  "Partiyalar jadvalini ko'rish",
  "Partiyani tasdiqlash yoki rad etish",
  "Partiya yaratish",
  "Smeta buyurtmalari ro'yxati",
  "Kirim-chiqim yaratish",
  "O'tkazma qilish",
  "Statistika",
  "To'lovlarni ko'rish",
  "Kirim qilish",
  "Kutilayotgan to'lovni kiritish",
  "Kutilayotgan to'lovlar jadvali",
  "Statistika",
  "Statistika",
  "Qarzdorlikni kiritish",
  "Chiqim qilish",
  "Qazdorlik ro'yxati",
  "Statistika",
  "Buyurtmalarga to'lov qilish",
  "Buyurmalarga to'lovlar jadvali",
  "Statistika",
  "Ishlar uchun to'lovlar jadvali",
  "Statistika",
  "Inventarlar uchun to'lovlar ro'yxati",
  "Inventar uchun to'lov",
  "Omborxonadagi mahsulotlar",
  "Mahsulotni yaroqsizga chiqarish",
  "Mahsulotni o'tkazma qilish",
  "Partiya mahsulotlari ro'yxati",
  "Partiya yaratish",
  "O'tkazmalar ro'yxati",
  "O'tkazmani qabul qilish | Rad etish",
  "O'tkazmani tahrirlash",
  "Buyurtmalar ro'yxati",
  "Buyurtmani o'chirish",
  "Buyurtma berish",
  "Yaroqsiz mahsulotlar ro'yxati",
  "Yaroqsiz mahsulotni ortga qaytarish",
  "Yaroqsiz mahsulotni tahrirlash",
  "Papkalar ro'yxati",
  "Papka yaratish",
  "Papkani tahrirlash",
  "Papkani o'chirish",
  "Kontragent yaratish",
  "Kontragentlar ro'yxati",
  "Inventalar ro'yxati",
  "Inventar yaratish",
  "Inventarni tahrirlash",
  "Inventarni o'chirish",
  "Inventarni yaroqsizga chiqarish",
  "Papkalar ro'yxati",
  "Papka yaratish",
  "Papkani tahrirlash",
  "Papkani o'chirish",
  "So'rovlar jadvali",
  "So'rovni qabul qilish yoki rad etish",
  "Yaroqsiz inventarlar ro'yxati",
  "Shablon yaratish",
  "Kategoriyalarni ko'rish",
  "Kategoriya yaratish",
  "Kategoriyani yangilash (Tahrirlash)",
  "Kategoriyani o'chirish",
  "Subkategoriyalarni ko'rish",
  "Subkategoriya yaratish",
  "Subkategoriyani tahrirlash",
  "Subkategoriyani o'chirish",
  "Valyutalarni ko'rish",
  "Valyutani yangilash",
  "Valyutani avto yangilash",
  "To'lov turlari jadvali",
  "To'lov turi yaratish",
  "To'lov turini yangilash",
  "To'lov turini o'chirish",
  "Kassa folderlari ro'yxati",
  "Kassa folderini yaratish",
  "Kassa folderini o'chirish",
  "Kassa folderini yangilash",
  "Kassalar ro'yxati",
  "Kassa yaratish",
  "Kassa nomini yangilash",
  "Kassani o'chirish",
  "Papkalar ro'yxati",
  "Papka yaratish",
  "Papkani nomini o'zgartirish",
  "Papkani o'chirish",
  "Omborxona yaratish",
  "Omborxonani yangilash",
  "Omborxonani o'chirish",
  "Xodimlar ro'yxati",
  "Xodim yaratish",
  "Xodimni yangilash",
  "Xodimni o'chirish",
  "Lavozimlar ro'yxati",
  "Lavozim yaratish",
  "Lavozimni yangilash",
  "Lavozimni o'chirish",
  "Daromad turlari ro'yxatini ko'rish",
  "Xarajat turlari ro'yxatini ko'rish",
  "Daromad turini qo'shish",
  "Xarajat turini qo'shish",
  "Daromad va xarajat turini o'chirish",
  "Daromad va xarajat turini tahrirlash",
  "Yaroqsiz mahsulotlarda chat",
  "Ko'rish",
  "Ko'rish",
  "Ko'rish",
  "Loyihaga biriktirish",
  "Kirim-chiqimni ko'rish",
  "Kutilayotgan to'lovlarni ko'rish",
  "Qarzlarimni ko'rish",
  "Inventarlarni ko'rish",
  "Statistika",
  "Statistika",
  "Statistika",
  "Statistika",
  "Kontragentni tahrirlash",
  "Kontragentni o'chirish",
  "Omborxonalarni ko'rish",
  "O'tkazmani ko'rish",
  "To'lovlar arxivi",
  "Act sverka ko'rish",
  "Buyurtmalarni ko'rish",
  "Ishlarga to'lovlarni ko'rish",
  "Ko'rish",
  "Tasdiqlash",
  "Bekor qilish",
  "Kirim-chiqim chernovoy yaratish",
  "Partiyani o'zgartirish",
  "Partiyani yaratish",
  "Tasdiqlash",
  "Bekor qilish",
  "Tasdiqlash",
  "Bekor qilish",
  "Ko'rish",
  "Bekor qilish",
  "Tasdiqlash",
  "O'chirish",
  "Taklif yaratish",
  "Taklifni tahrirlash",
  "Ko'rish",
  "Buyurtmani yangilash",
  "Resurs qo'shish",
  "Partiyani bekor qilish",
  "Partiyani yakunlash",
  "Bekor qilish",
  "Tasdiqlash",
  "O'chirish",
  "Taklif yaratish",
  "Taklifni tahrirlash",
  "Resurs qo'shish",
  "Ko'rish",
  "Yaratish",
  "Tahrirlash",
  "O'chirish",
  "Sozlamalar",
  "Sozlamalarni tahrirlash yoki yaratish",
  "Barcha o'zgaruvchilarni ko'rish",
  "Ko'rish",
  "Yaratish",
  "Tahrirlash",
  "O'chirish",
  "Statistika",
  "To'lov kalendari",
  "To'lovni o'chirish",
  "Ko'rish",
  "Yaratish",
  "Yangilash",
  "O'chirish",
  "Buyurtmani tahrirlash",
  "Partiyani o'chirish",
  "Partiyani o'chirish",
  "Partiyani ochish",
  "Buyurtmalar to'lovini o'chirish",
  "Partiya to'lovini o'chirish",
  "Partiya to'lovini o'chirish",
  "Buyurtma to'lovlar tarixi",
  "Partiya to'lovlar tarixi",
  "Partiya to'lovlar tarixi",
  "Transferni tasdiqlash yoki rad etish",
  "Mahsulotni yuklab olish",
  "O'tkazmalarda chat",
  "O'tkazma qilish",
  "Mahsulotni yaroqsizga chiqarish",
  "Yaroqsiz  mahsulot ma'lumotlarini yuklash",
  "Buyurtmalarni yuklab olish",
  "Partiyalarni yuklab olish",
  "Partiyalarda chat",
  "Partiyani o'chirish",
  "Partiyani tahrirlash",
  "Akt Sverka ni ko'rish"
]);

export const actionSchema = z.object({
  id: z.number(),
  path: z.string(),
  name: z.object({
    uz: ACTION_KEY,
    ru: z.string().optional(),
    en: z.string().optional()
  })
});

export const subModuleSchema = z.object({
  name: z.record(SUBMODULE_KEY),
  icon: z.string().nullable(),
  actions: z.array(actionSchema)
});

export const permissionSchema = z.object({
  id: z.number(),
  name: z.record(PERMISSION_KEY),
  icon: z.string(),
  submodules: z.array(subModuleSchema)
});

export const CurrencyTypeEnum = z.enum(["base", "second"]);

export const currencySchema = z.object({
  id: z.number(),
  name: z.record(z.string()).optional(),
  symbol: z.string(),
  icon: z.string(),
  amount: z.number(),
  updated_at: z.number(),
  type: CurrencyTypeEnum
});

export type Permission = z.infer<typeof permissionSchema>;
export type SubModule = z.infer<typeof subModuleSchema>;
export type Action = z.infer<typeof actionSchema>;
export type PermissionKey = z.infer<typeof PERMISSION_KEY>;
export type SubmoduleKey = z.infer<typeof SUBMODULE_KEY>;
export type ActionKey = z.infer<typeof ACTION_KEY>;
export type CurrencyTypeEnum = z.infer<typeof CurrencyTypeEnum>;
export type Currency = z.infer<typeof currencySchema>;
