import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, FormInstance, Spin, TreeSelect } from "antd";
import { Rule } from "antd/es/form";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "../../../../store/reducers/paymentReducer";
import LayoutIcon from "../../../payment/assets/icons/LayoutIcon";
import ChevronDownIcon from "../../../supply/assets/icons/ChevronDownIcon";
import { useProjectSelect } from "../../service/queries";
import { cx } from "../../utils/helpers/cx";
import { selectFilterOption } from "../../utils/helpers/selectFilterOption";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import SelectNotContent from "../select-not-content/SelectNotContent";

import styles from "./project-select-with-hook-form.module.scss";
import { Controller, UseFormReturn } from "react-hook-form";

type Props = {
  label?: string;
  rule?: Rule[];
  cashId?: number;
  isTask?: boolean;
  enabled?: boolean;
  disabled?: boolean;
  form?: UseFormReturn;
  warehouseId?: number;
  allowClear?: boolean;
  placeholder?: string;
  suffixIcon?: ReactNode;
  rootClassName?: string;
  onAfterChange?: () => void;
  name?: string;
  onChange?: (value: number) => void;
  multiple?: boolean;
  maxTagCount?: number;
};

const ProjectSelectWithHookForm: React.FC<Props> = ({
  form,
  cashId,
  onChange,
  suffixIcon,
  warehouseId,
  placeholder,
  onAfterChange,
  enabled = true,
  isTask = false,
  disabled = false,
  allowClear = true,
  name = "project_id",
  label,
  maxTagCount,
  multiple,
  rootClassName
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setEstimateDrawer } = paymentReducerActions;
  const { data, isLoading } = useProjectSelect({ cashId, warehouseId, enabled });

  const onChangeBasic = (value: number) => {
    form?.setValue(name, value);

    onAfterChange && onAfterChange();
  };

  const onOpenEstimate = (id?: number) => () => {
    dispatch(
      setEstimateDrawer({
        visible: true,
        projectId: id
      })
    );
  };

  const generateProjectLabel = (name?: string, projectId?: number, projects?: Array<{ id: number }>) => {
    if (isTask && typeof projects === "undefined") {
      return (
        <div className={cx("project_name flex w-full items-center justify-between")}>
          <span>{name}</span>
          <Button
            className={cx("project_name__task box-border h-9 w-9 p-1.5 opacity-0")}
            onClick={onOpenEstimate(projectId)}
          >
            <LayoutIcon />
          </Button>
        </div>
      );
    }

    return name;
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <label className="text-sm font-medium text-gray-700">{label}</label>
      <Controller
        name={name}
        control={form?.control}
        render={({ field }) => (
          <TreeSelect
            {...field}
            showSearch
            disabled={disabled}
            suffixIcon={suffixIcon}
            multiple={multiple}
            maxTagCount={maxTagCount}
            allowClear={allowClear}
            popupMatchSelectWidth={false}
            rootClassName={rootClassName || styles.tree_select}
            onChange={onChange || onChangeBasic}
            filterTreeNode={selectFilterOption as never}
            placeholder={placeholder || t("app.Tanlang")}
            // @ts-ignore
            switcherIcon={({ expanded }) => <ChevronDownIcon rotate={expanded} />}
            treeData={data?.map(project => ({
              value: project?.id,
              label: generateProjectLabel(project?.name, project?.id, project?.projects),
              props: {
                name: project?.name
              },
              children: project?.projects?.map(child => ({
                value: child?.id,
                label: generateProjectLabel(child?.name, child?.id),
                props: {
                  name: child?.name
                }
              }))
            }))}
            notFoundContent={
              isLoading ? (
                <Spin spinning={isLoading} indicator={LoadingIndicator}>
                  <SelectNotContent />
                </Spin>
              ) : (
                <SelectNotContent />
              )
            }
          />
        )}
      />
    </div>
  );
};

export default ProjectSelectWithHookForm;
