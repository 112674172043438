import { useTranslation } from "react-i18next";

import { isEmptyArr } from "../features/app/utils/helpers/isEmptyArr";
import { CustomFieldSelectModel } from "../features/app/utils/models/customFieldSelectModel";
import { ColumnConfigModel, UserTableDataModel } from "../features/app/utils/models/user/userTableConfigModel";

type Props = {
  defaultData: UserTableDataModel | undefined;
  customFieldSelect: CustomFieldSelectModel[] | undefined;
};

const useGetTableColumns = ({ defaultData, customFieldSelect }: Props): UserTableDataModel | undefined => {
  const { i18n } = useTranslation();

  if (customFieldSelect) {
    let result: ColumnConfigModel[] = [];

    if (defaultData?.column) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      result = [...defaultData?.column];

      for (let i = 0; i < customFieldSelect.length; i++) {
        for (let j = 0; j < defaultData?.column.length; j++) {
          if (customFieldSelect[i]?.id === defaultData?.column[j]?.id) {
            result.splice(j, 1, {
              id: customFieldSelect[i]?.id,
              name: customFieldSelect[i]?.name[i18n.language],
              title: customFieldSelect[i]?.name[i18n.language],
              checked: defaultData?.column[j]?.checked
            });
            break;
          }
          if (j === defaultData?.column?.length - 1) {
            result.push({
              id: customFieldSelect[i]?.id,
              name: customFieldSelect[i]?.name[i18n.language],
              title: customFieldSelect[i]?.name[i18n.language],
              checked: false
            });
          }
        }
      }
    }

    if (defaultData && !isEmptyArr(defaultData?.column)) {
      return {
        ...defaultData,
        column: [...result]
      };
    }

    return { column: result, size: 10 };
  }

  return defaultData;
};

export default useGetTableColumns;
