import React from "react";
import { Button, Spin } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { useGetDetailedWarehouseFolder } from "../../service/queries";
import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import { settingsActions } from "../../../../store/reducers/settingsReducer";
import SettingsWarehouseModal from "../../components/warehouse/modal/SettingsWarehouseModal";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import SettingsWarehouseCard from "../../components/warehouse/card/default/SettingsWarehouseCard";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const navigate = useNavigate();
  const { setWarehouseModal } = settingsActions;
  const { data, isLoading } = useGetDetailedWarehouseFolder(id as string);

  const onOpenModal = () => {
    dispatch(
      setWarehouseModal({
        visible: true,
        data: undefined
      })
    );
  };

  const onBack = () => {
    navigate(`${rootPaths.SETTINGS}${settingsPaths.WAREHOUSE}`);
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.detailed_warehouse}>
        <div className={styles.detailed_warehouse__top}>
          <Button onClick={onBack}>
            <ArrowNarrowIcon />
          </Button>
          <h2>{data?.name}</h2>
        </div>
        <div className={styles.detailed_warehouse__bottom}>
          {data?.warehouses?.map((item, index) => <SettingsWarehouseCard {...item} key={index} />)}
          <div className={styles.card_add} onClick={onOpenModal}>
            <PlusCircleIcon fill={false} />
          </div>
        </div>
        <SettingsWarehouseModal />
      </div>
    </Spin>
  );
};

export default Index;
