import React from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Drawer, Dropdown, Spin, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { TransferViewModel } from "features/warehouse/utils/models/transferViewModel";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { RootState, transferMakingReducerActions } from "../../../../../store";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import { transferActions } from "../../../../../store/reducers/transferReducer";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { RU } from "../../../../app/utils/constants/languages";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import LinkIcon from "../../../assets/icons/LinkIcon";
import { useCheckStatusTransfer, useDeleteTransfer } from "../../../service/mutation";
import { useGetOneTransfer, useGetWarehouseSelect } from "../../../service/query";
import { DefectStatus } from "../../../utils/constants/DefectStatus";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { warehouseQueryNames } from "../../../utils/constants/warehouseQueryNames";
import { OneTransferViewModel } from "../../../utils/models/oneTransferViewModel";

import styles from "./transferView.module.scss";

const TransferView: React.FC = () => {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setVisible: setVisibleChat } = chatActions;
  const { setVisibleView, setVisibleExcess } = transferActions;
  const { user } = useAuth();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const transferAllActions = actions("O'tkazmalar");
  const { data: warehouseSelect } = useGetWarehouseSelect(true);
  const deleteMutation = useDeleteTransfer();

  const { visible, data } = useAppSelector((state: RootState) => state.transferReducer.view);
  const { setDrawerVisible } = transferMakingReducerActions;
  const checkTransferStatus = useCheckStatusTransfer(data?.id);
  const { data: products, isLoading } = useGetOneTransfer(data?.id);

  const isCompleted = user?.id === data?.sender_user?.id && data?.status === DefectStatus.RECIEVED;

  const isUser = user?.id === data?.sender_user?.id;

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      setVisibleView({
        visible: false,
        data: undefined
      });
    }
  };

  const onCancel = () => {
    dispatch(
      setVisibleView({
        data,
        visible: false
      })
    );
  };

  const onOpenTransferDrawer = () => {
    if (products) {
      dispatch(
        setDrawerVisible({
          visible: true,
          id: data?.id,
          data: {
            sender_warehouse_id: data?.sender_warehouse?.id as number,
            recieved_warehouse_id: data?.recieved_warehouse?.id as number,
            recieved_project_id: data?.project?.id as number,
            id: data?.id,
            date: data?.date as string,
            products: products?.map(item => ({
              unit: item?.unit,
              product: {
                id: item?.product?.id,
                name: item?.product?.name,
                resource: item?.product?.resource
              },
              project: item?.project,
              warehouse: item?.warehouse,
              resource: item?.product.resource,
              total_quantity: item?.warehouse_quantity,
              quantity: item?.initial_quantity
            })) as never
          }
        })
      );
    }

    onCancel();
  };

  const onCheckTransferStatus = (status: DefectStatus) => {
    checkTransferStatus
      .mutateAsync({
        id: data!.id!,
        status
      })
      .then(onCancel);
  };

  const onDeleteTransfer = () => deleteMutation.mutateAsync(data?.id!);

  const onOpenChat = () => {
    dispatch(
      setVisibleChat({
        visible: true,
        objectId: data?.id,
        type: ChatEnum.WAREHOUSE_TRANSFERS,
        // dataKeys: [warehouseQueryNames.TRANSFER_VIEW],
        onAfterOpen: () => {
          if (data?.unread_message_count && data?.unread_message_count > 0) {
            qc.setQueryData(
              [warehouseQueryNames.TRANSFER_VIEW, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as TransferViewModel[];

                return {
                  total,
                  current_page,
                  data: initialData?.map(item => {
                    if (item?.id === data?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
          }
        }
      })
    );

    dispatch(
      setVisibleView({
        visible: false,
        data: undefined
      })
    );
  };

  const isDifferenceValue = () => {
    if (user?.id !== data?.sender_user?.id) {
      if (data?.status === DefectStatus.RECIEVED) return true;
      return false;
    }
    return true;
  };

  const onOpenExcessModal = (record: OneTransferViewModel) => {
    dispatch(
      setVisibleExcess({
        data: { ...record, transfer_id: data?.id },
        visible: true,
        isDifference: isDifferenceValue()
      })
    );
  };

  const checkAction = () => {
    if (data?.status === DefectStatus.PENDING)
      return Boolean(
        transferAllActions?.check && warehouseSelect?.some(item => item?.id === data?.recieved_warehouse?.id)
      );
    if (data?.status === DefectStatus.OPEN)
      return Boolean(
        transferAllActions?.check && warehouseSelect?.some(item => item?.id === data?.sender_warehouse?.id)
      );

    return false;
  };

  const footer = (
    <div className={styles.footer}>
      <ConditionalRender if={checkAction()}>
        <Button onClick={() => onCheckTransferStatus(DefectStatus.REJECTED)} loading={checkTransferStatus.isLoading}>
          Bekor qilish
        </Button>
        <Button
          type="primary"
          onClick={() => onCheckTransferStatus(DefectStatus.RECIEVED)}
          loading={checkTransferStatus.isLoading}
        >
          Tasdiqlash
        </Button>
      </ConditionalRender>
    </div>
  );

  const isViewLink = () => {
    if (user?.id === data?.sender_user?.id) {
      return data?.status === DefectStatus.RECIEVED;
    }
    return true;
  };

  const columns: ColumnsType<OneTransferViewModel> = [
    {
      title: "Resus turi va nomi",
      render: (_, record) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource.symbol[i18n.language]}
          </div>
          <span>{record?.product.name[i18n.language]}</span>
        </div>
      )
    },
    {
      title: "Soni",
      render: (_, record) => <div>{record?.initial_quantity?.toLocaleString(RU)}</div>
    },
    isCompleted
      ? {
          title: "Qabul qildi",
          dataIndex: "quantity",
          render: (quantity: number) => quantity?.toLocaleString(RU)
        }
      : {
          className: "d_n"
        },
    // isCompleted
    //   ? {
    //       title: "Farq",
    //       render: (_, record) => <div>{Math.abs(record.initial_quantity - record?.quantity)?.toLocaleString(RU)}</div>
    //     }
    //   : {
    //       className: "d_n"
    //     },
    {
      title: "Birligi",
      render: (_, record) => record.unit.symbol[i18n.language]
    },
    {
      title: "",
      render: (_, record) =>
        isViewLink() && (
          <Tooltip title={isDifferenceValue() ? "Farqni ko'rish" : "Kamomat qilish"} placement="top">
            <div
              // className="kamomat"
              className="d_f ai_c jc_c c_p"
              onClick={() => onOpenExcessModal(record)}
            >
              <LinkIcon />
            </div>
          </Tooltip>
        )
    }
  ];

  const customItems = () => {
    const items = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item}>
            <UnreadMessageAction count={data?.unread_message_count} />
          </div>
        ),
        onClick: onOpenChat,
        isView: true
      },
      // {
      //   key: "2",
      //   label: (
      //     <div className={styles.dropdown__item}>
      //       <DownloadIcon /> {t("warehouse.Excel")}
      //     </div>
      //   ),
      //   isView: true
      // },
      {
        key: "3",
        label: (
          <ModalConfirm
            onOk={onDeleteTransfer}
            onAfterClose={() => {
              dispatch(
                setVisibleView({
                  data,
                  visible: false
                })
              );
            }}
          >
            <div className={styles.dropdown__item}>
              <DeleteIcon />
              {t("warehouse.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        isView: data?.status === "pending",
        permissionKey: "delete"
      },
      {
        key: "4",
        label: (
          <div className={styles.dropdown__item}>
            <EditIcon /> {t("warehouse.Tahrirlash")}
          </div>
        ),
        onClick: onOpenTransferDrawer,
        isView: isUser && data?.status === "pending",
        permissionKey: "edit"
      }
    ];

    return {
      items: items?.filter(el => {
        if (el?.permissionKey) return transferAllActions[el?.permissionKey] && el?.isView;
        return el?.isView;
      })
    };
  };

  const title = (
    <div className={styles.title}>
      <div>T-{data?.id}</div>
      <div className={styles.title__right}>
        <Dropdown menu={customItems()} trigger={["click", "contextMenu"]}>
          <Button>
            <UnreadMessageDots count={data?.unread_message_count} />
          </Button>
        </Dropdown>
        <div className={styles.title__close} onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  return (
    <Drawer
      open={visible}
      onClose={onCancel}
      title={title}
      closeIcon={false}
      afterOpenChange={onAfterOpen}
      className={styles.transfer_view}
      footer={footer}
      width="56.9rem"
    >
      <Spin spinning={checkTransferStatus.isLoading} indicator={LoadingIndicator}>
        <div className={styles.content}>
          <div className={styles.top}>
            <div className={styles.item}>
              <span>O’tkazma qilingan</span>
              <span>{data?.created_at}</span>
            </div>
            <div className={styles.item}>
              <span>O’tkazuvchi omborxona</span>
              <span>{data?.sender_warehouse?.name}</span>
            </div>
            <div className={styles.item}>
              <span>Yuboruvchi</span>
              <span>{data?.sender_user?.full_name ?? "-"}</span>
            </div>
            <div className={styles.item}>
              <span>Qabul qiluvchi omborxona</span>
              <span>{data?.recieved_warehouse?.name}</span>
            </div>
            <div className={styles.item}>
              <span>Qabul qiluvchi</span>
              <span>{data?.recieved_user?.full_name ?? "-"}</span>
            </div>
            <div className={styles.item}>
              <span>Loyiha</span>
              <span>{data?.project?.name}</span>
            </div>
          </div>
          <div className={styles.bottom}>
            <Table<OneTransferViewModel>
              columns={columns}
              dataSource={products}
              pagination={false}
              rowKey={(_, index) => index!}
              locale={{
                emptyText: <TableEmpty />
              }}
              loading={{
                spinning: isLoading,
                indicator: LoadingIndicator
              }}
            />
          </div>
        </div>
      </Spin>
    </Drawer>
  );
};

export default TransferView;
