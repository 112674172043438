import React from "react";
import { Modal as AntModal } from "antd";

import { useWidget } from "modules/dashboard";
import { Widget } from "modules/dashboard/forms";

import Content from "./content";

const Modal: React.FC = () => {
  const { widget, isUpdateWidget, visibleWidgetModal, methods } = useWidget();

  const onCancel = () => {
    methods.setWidgetModal({
      visibleWidgetModal: false,
      widget: widget!
    });
  };

  return (
    <AntModal
      centered
      footer={false}
      zIndex={99999}
      onCancel={onCancel}
      open={visibleWidgetModal}
      title={isUpdateWidget ? "Vidjetni tahrirlash" : "Vidjet yaratish"}
    >
      {isUpdateWidget ? (
        <Widget.Update key={widget?.id}>{form => <Content form={form as never} />}</Widget.Update>
      ) : (
        <Widget.Create key={widget?.id}>{form => <Content form={form as never} />}</Widget.Create>
      )}
    </AntModal>
  );
};

export default Modal;
