import React, { useMemo } from "react";
import { Spin } from "antd";
import { uid } from "uid";

import { useAuth } from "modules/auth/hooks";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetCalendarHistoryStatistics } from "../../../../service/queries";
import { paymnetInfoData } from "../../../../utils/constants/paymentInfoData";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";

import styles from "./calendarHistory.module.scss";

type Props = {
  isCompanyPerson?: boolean;
};

export const CalendarHistoryStatistics: React.FC<Props> = ({ isCompanyPerson }) => {
  const { data, isLoading } = useGetCalendarHistoryStatistics(isCompanyPerson);
  const { label } = paymnetInfoData[PaymentTabKeys.CALENDAR];
  const { queries } = useQueryParams();
  const { currency_id: currencyId } = queries();
  const { currencies } = useAuth();

  const currencySymbol = useMemo(
    () =>
      currencies?.find(item => {
        if (currencyId) return item.id === Number(currencyId);
        return item.type === "base";
      })?.symbol,
    [currencies, currencyId]
  );

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.statistics}>
        {label?.map(item => (
          <div className={styles.statistics__item} key={uid()}>
            <h4>{item?.name}</h4>
            <h3>
              {data?.[item?.key]?.toLocaleString(RU)}
              <span>{currencySymbol}</span>
            </h3>
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default CalendarHistoryStatistics;
