import React, { useEffect } from "react";
import { Form, FormInstance, Input, Select, SelectProps } from "antd";
import { Rule } from "antd/es/form";
import { useTranslation } from "react-i18next";

import { isEmptyObj } from "../../../../../../modules/common";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetCurrenciesSelect } from "../../../../../app/service/queries";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import { cx } from "../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { CurrencySelectModel } from "../../../../../app/utils/models/currencySelectModel";
import { useGetCashMoneyPaymentTypeSelect, useGetCashMoneySelect } from "../../../../service/queries";
import { PaymentCashMoneyModel } from "../../../../utils/models/paymentCashMoneyModel";
import { TransferBodyModel } from "../../../../utils/models/transferBodyModel";

import Additions from "./additions/additions";

import styles from "../transfer.module.scss";

type Props = {
  visible: boolean;
  formInstance: FormInstance<TransferBodyModel>;
};

const { Option } = Select;
const { Item, useWatch } = Form;

const Top: React.FC<Props> = ({ visible, formInstance }) => {
  const { t } = useTranslation();
  const baseCurrency = getBaseCurrency();

  const cashId = useWatch(["expense_payment", "cash_id"], formInstance);
  const currencyId = useWatch(["expense_payment", "currency_id"], formInstance);
  const paymentTypeId = useWatch(["expense_payment", "payment_type_id"], formInstance);
  const currency = JSON.parse(useWatch("currency", formInstance) || "{}") as CurrencySelectModel;

  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCurrenciesSelect();
  const { data: cash, isLoading: isLoadingCash } = useGetCashMoneySelect(visible, {
    type: PaymentTypeEnum.EXPENSE,
    currency_id: currencyId || baseCurrency?.id
  });
  const { data: paymentTypes, isLoading: isLoadingPaymentTypes } = useGetCashMoneyPaymentTypeSelect({
    cashId,
    type: PaymentTypeEnum.EXPENSE,
    currency_id: currencyId || baseCurrency?.id,
    enabled: Boolean(cashId && (currencyId || baseCurrency?.id))
  });

  const onChangeCash: SelectProps["onChange"] = e => {
    formInstance.setFieldsValue({
      expense_payment: {
        cash_id: e,
        currency_id: currencyId || baseCurrency?.id,
        payment_type_id: undefined,
        amount: undefined,
        projects: [],
        financials: []
      },
      income_payment: {
        amount: undefined,
        projects: []
      }
    });
  };

  const onChangePaymentType = (e: number) => {
    formInstance.setFieldsValue({
      expense_payment: {
        payment_type_id: e,
        currency_id: currencyId || baseCurrency?.id
      }
    });
  };

  const onChangeCurrency: SelectProps["onChange"] = (e, option: any) => {
    formInstance.setFieldsValue({
      currency: JSON.stringify(option?.props?.currency),
      expense_payment: {
        cash_id: undefined,
        payment_type_id: undefined,
        currency_id: e,
        amount: undefined,
        financials: [],
        projects: []
      },
      income_payment: {
        amount: undefined,
        cash_id: undefined,
        payment_type_id: undefined,
        projects: [],
        currency_id: e
      }
    });
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue(["expense_payment", "amount"], localeFormatter(e?.target?.value));
    formInstance.setFieldValue(["income_payment", "amount"], localeFormatter(e?.target?.value));
  };

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        let financialAmount = 0;
        const parseValue = parseLocaledString(value || "0");

        const financials = formInstance.getFieldValue([
          "expense_payment",
          "financials"
        ]) as TransferBodyModel["expense_payment"]["financials"];

        const checkCash = JSON.parse(
          formInstance.getFieldValue("cash") || "{}"
        ) as PaymentCashMoneyModel["currencies"][number];

        if (!isEmptyArr(financials)) {
          financialAmount = financials?.reduce(
            (first, second) =>
              parseLocaledString(String(second?.amount) || "0") + parseLocaledString(String(first) || "0"),
            0
          );
        }

        if (!isEmptyObj(checkCash)) {
          if (parseValue + financialAmount === 0) {
            return Promise.reject();
          }
          if (parseValue + financialAmount > checkCash?.amount) {
            return Promise.reject(
              `Kiritilgan miqdor ${checkCash?.amount?.toLocaleString(RU)} ${checkCash?.symbol} dan oshmasligi zarur`
            );
          }
        }

        return Promise.resolve();
      }
    }
  ];

  useEffect(() => {
    if (currencyId && paymentTypeId) {
      const oneCash = paymentTypes?.find(item => item?.id === paymentTypeId) as PaymentCashMoneyModel;

      const findCash: PaymentCashMoneyModel["currencies"][number] | undefined = oneCash?.currencies?.find(
        item => item?.id === currencyId
      );

      if (findCash) {
        formInstance.setFieldValue("cash", JSON.stringify(findCash));
      }
    }
  }, [currencyId, paymentTypeId]);

  return (
    <div className={styles.top}>
      <Item name={["expense_payment", "cash_id"]} label={t("payment.Kassadan")} rules={formRules()}>
        <Select
          showSearch
          loading={isLoadingCash}
          onChange={onChangeCash}
          notFoundContent={<TableEmpty />}
          filterOption={selectFilterOption}
          suffixIcon={<SelectSuffixIcon />}
          placeholder={t("payment.Kassani tanlang")}
        >
          {cash?.map(item => (
            <Option
              value={item.id}
              key={item.id}
              props={{
                name: item?.name,
                cash: item
              }}
            >
              <div className="flex flex-col">
                <div>{item?.name}</div>
                <div className={cx("flex items-center gap-2", "cash_amount")}>
                  {item?.currencies?.map(item => (
                    <div
                      key={item?.id}
                      className="border-r border-solid border-gray-700 pr-2 text-sm font-normal text-gray-600 last:border-none"
                    >
                      {item?.amount?.toLocaleString(RU)} {item?.symbol}
                    </div>
                  ))}
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </Item>
      <div className={styles.item}>
        <Item name={["expense_payment", "payment_type_id"]} label={t("payment.To'lov turi")} rules={formRules()}>
          <Select
            showSearch
            disabled={!cashId}
            onChange={onChangePaymentType}
            loading={isLoadingPaymentTypes}
            notFoundContent={<TableEmpty />}
            filterOption={selectFilterOption}
            suffixIcon={<SelectSuffixIcon />}
            placeholder={t("payment.To'lov turini tanlang")}
          >
            {paymentTypes?.map(item => (
              <Option
                value={item.id}
                key={item.id}
                props={{
                  name: item?.name
                }}
              >
                <div className="flex flex-col">
                  <div>{item?.name}</div>
                  <div className={cx("flex items-center gap-2", "cash_amount")}>
                    {item?.currencies?.map(item => (
                      <div
                        key={item?.id}
                        className="border-r border-solid border-gray-700 pr-2 text-sm font-normal text-gray-600 last:border-none"
                      >
                        {item?.amount?.toLocaleString(RU)} {item?.symbol}
                      </div>
                    ))}
                  </div>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          rules={formRules()}
          label={t("payment.Valyuta")}
          initialValue={baseCurrency?.id}
          name={["expense_payment", "currency_id"]}
        >
          <Select
            onChange={onChangeCurrency}
            loading={isLoadingCurrencies}
            notFoundContent={<TableEmpty />}
            suffixIcon={<SelectSuffixIcon />}
            // disabled={!cashId || !paymentTypeId}
            placeholder={t("payment.Valyutani tanlang")}
          >
            {currencies?.map(item => (
              <Option value={item.id} key={item.id} props={{ currency: item }}>
                <div className="flex items-center gap-2">
                  <img src={item?.icon} alt={item?.symbol} className="h-6 w-8 rounded object-cover" />
                  <span>{item?.symbol}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
      </div>
      <Item name={["expense_payment", "amount"]} label={t("payment.Summa")} rules={amountRule}>
        <Input
          onChange={onChangeAmount}
          suffix={currency?.symbol}
          placeholder={t("payment.Summa")}
          disabled={!(cashId && paymentTypeId && currencyId)}
        />
      </Item>
      <Additions formInstance={formInstance} />
      <Item className="hidden" name="currency" initialValue={JSON.stringify(baseCurrency)} />
      <Item className="hidden" name="cash" />
    </div>
  );
};

export default Top;
