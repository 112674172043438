import { Button, Drawer } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../../hooks/redux";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import SmallFolderIcon from "../../../../settings/assets/icons/SmallFolderIcon";
import CreateProductModal from "../../../../warehouse/components/orders/product-template/CreateProductModal";
import { useCreateProduct } from "../../../../warehouse/service/mutation";

import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import DrawerChildren from "../../drawer-children/DrawerChildren";
import styles from "./productDrawer.module.scss";
import { BreadcrumbItemType } from "antd/lib/breadcrumb/Breadcrumb";

type Props = {
  importAction: () => void;
  clearAction: () => void;
};

const ProductDrawer: React.FC<Props> = ({ importAction, clearAction }) => {
  const dispatch = useDispatch();
  const createProduct = useCreateProduct();
  const [page, setPage] = useState<number>(1);
  const [tabKey, setTabKey] = useState<string>("1");
  const { setProductDrawer, setProductDrawerData, setProductFolderId } = supplyActions;
  const [visibleCreateProduct, setVisibleCreateProduct] = useState(false);
  const { visible } = useAppSelector((state) => state.supplyReducer.productDrawer);

  const { products } = useAppSelector(
    (state) => state.supplyReducer.productDrawerData
  );

  // const initialFolderId = {
  //   category_id: undefined,
  //   sub_category_id: undefined,
  // };

  const initialBreadcrumbItem = [
    {
      title: <SmallFolderIcon />,
      onClick: () => {
        setTabKey("1");
        setPage(1);
        setBreadcrumbItems((value) => [value[0]]);
      },
    },
  ];

  const [breadcrumbItems, setBreadcrumbItems] =
    useState<BreadcrumbItemType[]>(initialBreadcrumbItem);

  // const [folderId, setFolderId] = useState<{
  //   category_id?: number;
  //   sub_category_id?: number;
  // }>(initialFolderId);

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      setTabKey("1");

      dispatch(setProductFolderId({
        category_id: undefined,
        sub_category_id: undefined,
      }));

      setBreadcrumbItems([
        {
          title: <SmallFolderIcon />,
          onClick: () => {
            setTabKey("1");
            setBreadcrumbItems((value) => [value[0]]);
            setPage(1);
          },
        },
      ]);

      dispatch(
        setProductDrawerData({
          ids: [],
          products: [],
        })
      );
    }
  };

  const onClose = () => {
    dispatch(
      setProductDrawer({
        visible: false,
      })
    );
  };

  const onImportProducts = () => {
    importAction();
    onClose();
  };

  const onClear = () => {
    dispatch(
      setProductDrawerData({
        ids: [],
        products: [],
      })
    );

    clearAction();
  };

  const footer = () => (
    <div className={styles.footer}>
      <p>Tanlangan mahsulotlar: {products?.length} ta</p>
      <div className={styles.footer__inner}>
        <Button onClick={onClose}>Yopish</Button>
        <Button
          className={styles.footer__clear}
          disabled={isEmptyArr(products)}
          onClick={onClear}
        >
          <XCloseIcon /> Qo’shilganlarni bekor qilish
        </Button>
        <Button
          type="primary"
          onClick={onImportProducts}
          disabled={isEmptyArr(products)}
          loading={createProduct.isLoading}
        >
          Qo'shish
        </Button>
      </div>
    </div>
  );

  return (
    <div className={styles.template}>
      <Drawer
        open={visible}
        footer={footer()}
        onClose={onClose}
        afterOpenChange={onAfterOpen}
        closeIcon={<CloseIcon />}
        title="Resurslar"
        placement="right"
        width="68.8rem"
      >
        <DrawerChildren
          isPrice
          page={page}
          tabKey={tabKey}
          visible={visible}
          setPage={setPage}
          // folderId={folderId}
          setTabKey={setTabKey}
          // setFolderId={setFolderId}
          breadcrumbItems={breadcrumbItems}
          setBreadcrumbItems={setBreadcrumbItems}
          setVisibleCreateProduct={setVisibleCreateProduct}
        />
      </Drawer>
      <CreateProductModal
        open={visibleCreateProduct}
        setOpen={setVisibleCreateProduct}
        tabKey={tabKey}
      />
    </div>
  );
};

export default ProductDrawer;
