import React from "react";

import PaymentInfo from "./info/PaymentInfo";
import PaymentTables from "./tables/PaymentTables";

import styles from "./paymentBottom.module.scss";

const PaymentBottom: React.FC = () => (
  <div className={styles.payment_bottom}>
    <PaymentInfo />
    <PaymentTables />
  </div>
);

export default PaymentBottom;
