import { FC } from "react";
import { Spin } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";

import { useGetTasks } from "../../../../../service/queries";
import { TaskModel } from "../../../../../utils/models/taskModel";
import { Task } from "../../task/Task";
import { TaskAppend } from "../../task/task-append/TaskAppend";
import Title from "../../task/title/Title";
import { TitleAppend } from "../../task/title-append/TitleAppend";

import styles from "../section.module.scss";

type Props = {
  plan: boolean;
  place: number;
  active: boolean;
  sectionId: number;
  isSearch: boolean;
  tasks: TaskModel[];
};

const SectionBody: FC<Props> = ({ plan, place, tasks, active, isSearch, sectionId }) => {
  const { data, isLoading } = useGetTasks(active, sectionId);

  return (
    <div className={styles.section__body}>
      {tasks?.length > 0 ? (
        tasks?.map(item =>
          item.is_title ? (
            <Title id={item.id} key={item.id} name={item.name} sectionId={sectionId} />
          ) : (
            <Task item={item} place={place} key={item.id} sectionPlan={plan} isSearch={isSearch} />
          )
        )
      ) : (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {data?.map(item =>
            item.is_title ? (
              <Title id={item.id} key={item.id} name={item.name} sectionId={sectionId} />
            ) : (
              <Task item={item} key={item.id} place={place} sectionPlan={plan} isSearch={isSearch} />
            )
          )}
        </Spin>
      )}
      <TaskAppend sectionId={sectionId} />
      <TitleAppend sectionId={sectionId} />
    </div>
  );
};

export default SectionBody;
