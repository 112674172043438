import React from "react";
import { Collapse, DatePicker, Dropdown, Form, FormInstance, FormListOperation, Input, message, Spin } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import DatepickerSuffixIcon from "../../../../../../app/assets/icons/DatepickerSuffixIcon";
import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { useGetFinancialSelect } from "../../../../../service/queries";
import { FinancialSelectModel } from "../../../../../utils/models/financialSelectModel";

import Card from "./card";

import styles from "../thirdStep.module.scss";

const { Item, List } = Form;
const { Panel } = Collapse;

type Props = {
  form: FormInstance;
};

const Additional: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetFinancialSelect(true, PaymentTypeEnum.EXPENSE);
  const { isOrderDelete } = useAppSelector(state => state.paymentReducer.paymentOrderModal);

  const onAddFinance = (item: FinancialSelectModel, operation: FormListOperation) => () => {
    const financials = form.getFieldValue("financials") as { id: number; name: string; amount: string }[];
    const findFinance = financials?.find(finance => finance?.id === item?.id);

    if (findFinance) {
      message.warning(t("payment.Ushbu xarajat turi kiritilgan"));
    } else {
      operation.add({
        amount: 0,
        id: item?.id,
        name: item?.name,
        percent_amount: undefined
      });
    }
  };

  return (
    <Collapse
      ghost
      rootClassName={styles.additional}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel key="1" header={<div className={styles.more_info__btn}>{t("payment.Qo'shimcha ma'lumotlar")}</div>}>
        <div className={styles.more_info}>
          <ConditionalRender if={!isOrderDelete}>
            <div className="mb-2.5">
              <div className={cx("ant-form-item-label", "mb-2")}>
                <label>{t("payment.Xarajatlar")}</label>
              </div>
              <Spin spinning={isLoading} indicator={LoadingIndicator}>
                <List name="financials">
                  {(fields, operation) => (
                    <div className="flex flex-col">
                      {fields.map((_, key) => (
                        <Card key={key} index={key} formInstance={form} operation={operation} />
                      ))}
                      <Dropdown
                        arrow={false}
                        placement="bottom"
                        trigger={["click"]}
                        menu={{
                          items:
                            data?.map(type => ({
                              key: type?.id,
                              label: type?.name,
                              onClick: onAddFinance(type, operation)
                            })) || []
                        }}
                      >
                        <div className="flex w-max cursor-pointer items-center gap-2 rounded-lg px-2 py-1 text-sm font-medium text-blue-500 duration-300 ease-in-out hover:bg-primary-50">
                          <PlusCircleIcon fill />
                          {t("payment.Xarajat qo'shish")}
                        </div>
                      </Dropdown>
                    </div>
                  )}
                </List>
              </Spin>
            </div>
          </ConditionalRender>
          <Item name="date" label={t("payment.Sana")} initialValue={dayjs()}>
            <DatePicker
              format={dayjsFormats.DATE}
              suffixIcon={<DatepickerSuffixIcon />}
              placeholder={t("payment.Sanani tanlang")}
            />
          </Item>
          <Item name="description" label={t("payment.Izoh")}>
            <Input.TextArea placeholder={t("payment.Izohini kiriting")} />
          </Item>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Additional;
