export const queryKeys = {
  RESOURCES: "resources",
  UNITS: "units",
  CURRENCY_SELECT: "currnecy-select",
  COMPANY_CURRENCY_BASIC: "company-curreny-basic",
  CASH_SELECT: "cash-select",
  MINE_CASH_SELECT: "mine-cash-select",
  ALL_CASH_SELECT: "all-cash-select",
  PRODUCT_SELECT: "product/select",
  ALL_PRODUCT_SELECT: "all-product-select",
  LANGUAGES: "languages",
  CHAT_VIEW: "chat/view",
  NOTIFICATIONS: "notifications",
  COMPANY_PERSON_SELECT: "company-person-select",
  PROJECTS_SELECT: "projects-select",
  SECTIONS_SELECT: "sections-select",
  PERMISSON: "permission",
  PERMISSION_SELECT: "permission-select",
  TABLE_CONFIGS: "table-configs",
  CUSTOM_FIELD_SELECT: "custom-field-select",
  PAYMENT: {
    TRANSFER: {
      HISTORY: "PAYMENT_TRANSFER_HISTORY"
    },
    EXCHANGE: {
      HISTORY: "PAYMENT_EXCHANGE_HISTORY"
    }
  },
  GLOBAL_CHAT: "global-chat",
  GLOBAL_CHAT_COUNT: "global-chat-count",
  FILE: "file",
  DISTRICTS: "districts",
  REGIONS: "regions",
  BUILDERS: "builders",
  BUILDERS_VIEW: "builders-view",
  OPEN_CAGE: "open-cage",
  UNIT_TREE: "unit-tree",
  PROJECT_SELECT: "project-select"
};
