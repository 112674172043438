import React from "react";
import { Popover, Spin } from "antd";
import dayjs from "dayjs";
import BuildingIcon from "features/app/assets/icons/BuildingIcon";
import { percentAndDateMark } from "features/app/utils/helpers/percentAndDateMark";
import { percentAndDateStyle } from "features/app/utils/helpers/percentAndDateStyle";
import { ProjectModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";

import { colors, cx, dayjsFormats, RU } from "modules/common";

import { LoadingIndicator } from "components";

import CustomTooltip from "../custom-tooltip/custom-tooltip";

import styles from "./amount-card.module.scss";

type Props = {
  data: ProjectModel;
  isLoading: boolean;
};

const AmountCard: React.FC<Props> = ({ data: amountData, isLoading: isAmountDataLoading }) => {
  const { t } = useTranslation();
  const differenceDay = dayjs(amountData?.prediction_date, dayjsFormats.DATE).diff(
    dayjs(amountData?.due_date, dayjsFormats.DATE),
    "day"
  );

  return (
    <Spin spinning={isAmountDataLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-3 rounded-xl border border-solid border-gray-200 p-5">
        <div className="flex items-center gap-3 text-lg font-medium text-gray-600">
          <BuildingIcon /> {t("project.Loyiha qiymati")}
        </div>
        <div className="flex w-full items-center gap-3">
          <div className="flex flex-1 items-center justify-between rounded-2xl border border-solid border-gray-200 bg-gray-50 px-4 py-3">
            <span className="text-base font-medium text-gray-600">{t("project.Qiymati")}</span>
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-1">
                <span className="text-xl font-medium text-gray-700">
                  {amountData?.spend_amount?.toLocaleString(RU)}
                </span>
                <span className="text-base font-medium text-gray-400">{amountData?.currency?.symbol}</span>
              </div>
              <Popover
                rootClassName={styles.popover}
                arrow={false}
                content={
                  <CustomTooltip
                    width={145}
                    firstTitle="Bashorat summa"
                    firstColor={colors.ERROR_500}
                    firstContent={String(amountData?.prediction_amount?.toLocaleString(RU))}
                    secondTitle="Plan summa"
                    secondContent={String(amountData?.total_amount?.toLocaleString(RU))}
                    secondColor={colors.WHITE}
                    thirdTitle="Fakt summa"
                    thirdColor={colors.SUCCESS_500}
                    thirdContent={String(amountData?.spend_amount?.toLocaleString(RU))}
                  />
                }
              >
                <span
                  className={cx(
                    "w-max cursor-pointer rounded-2xl border border-solid border-gray-500 px-[3px] py-[3px] text-xs font-medium",
                    percentAndDateStyle(amountData?.percent)
                  )}
                >
                  {percentAndDateMark(amountData?.percent)}
                  {Math.abs(amountData?.percent)}%
                </span>
              </Popover>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-between rounded-2xl border border-solid border-gray-200 bg-gray-50 px-4 py-3">
            <span className="text-base font-medium text-gray-600">{t("project.Muddati")}</span>
            <div className="flex items-center gap-3">
              <span className="text-xl font-medium text-gray-700">{amountData?.due_date}</span>
              <Popover
                rootClassName={styles.popover}
                arrow={false}
                content={
                  <CustomTooltip
                    width={110}
                    firstTitle="Bashorat sana"
                    firstColor={differenceDay > 0 ? colors.ERROR_500 : colors.SUCCESS_500}
                    firstContent={amountData ? amountData.prediction_date! : ""}
                    secondTitle="Plan sana"
                    secondContent={amountData ? amountData.due_date! : ""}
                    secondColor={colors.WHITE}
                    thirdTitle=""
                    thirdColor=""
                    thirdContent=""
                  />
                }
              >
                {amountData?.prediction_date && amountData?.due_date ? (
                  <span
                    className={cx(
                      "w-max cursor-pointer rounded-2xl border border-solid border-gray-300 px-[3px] py-[3px] text-xs font-medium",
                      percentAndDateStyle(differenceDay)
                    )}
                  >
                    {percentAndDateMark(differenceDay)}
                    {Math.abs(differenceDay)} {t("project.kun")}
                  </span>
                ) : (
                  <span className="text-xs font-medium text-gray-500">{t("warehouse.Belgilanmagan")}</span>
                )}
              </Popover>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default AmountCard;
