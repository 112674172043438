import { paymentOrderStatusType } from "../constants/paymentOrderStatusType";
import { PaymentOrderModel } from "../models/paymentOrderModel";

export const getStatusPaymentOrder = (
  record: PaymentOrderModel
): paymentOrderStatusType => {
  if (record?.debt_amount > 0) {
    return "pending";
  }
  return "success";
};
