import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import {
  useGetCPInventoryPayment,
  useGetInventoryPayment,
} from "../../../../service/queries";
import { InventoryPaymentModel } from "../../../../utils/models/inventoryPaymentModel";

import { useMemo, useState } from "react";
import styles from "./pays.module.scss";

type Props = {
  isCompanyPerson?: boolean;
};

const Pays: React.FC<Props> = ({ isCompanyPerson }) => {
  const {t}  = useTranslation()
  const [page, setPage] = useState<number>(1);
  const { data: inventoryPayments, isLoading: inventoryLoading } =
    useGetInventoryPayment(page, isCompanyPerson);
  const { data: companyPersonPayments, isLoading: companyPersonLoading } =
    useGetCPInventoryPayment(page, isCompanyPerson);

  const data = useMemo(() => {
    if (isCompanyPerson) return companyPersonPayments;
    else return inventoryPayments;
  }, [inventoryPayments, companyPersonPayments, isCompanyPerson]);

  const columns: ColumnsType<InventoryPaymentModel> = [
    {
      title: `${t("Monitoring.To’lov sanasi")}`,
      dataIndex: "date",
    },
    {
      title: `${t('Inventar.To’lov summasi')}`,
      render: (record: InventoryPaymentModel) => (
        <span className={styles.bold}>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
    },
    {
      title: `${t("Inventar.To’lov turi")}`,
      render: (record: InventoryPaymentModel) => (
        <div
          style={{ backgroundColor: `${record?.payment_type?.color}30` }}
          className={styles.payment}
        >
          <p
            style={{
              color: record?.payment_type?.color,
            }}
            className={styles.payment__paragraph}
          >
            {record?.payment_type?.name}
          </p>
        </div>
      ),
    },
    {
      title: `${t("Inventar.Kassa va kassir")}`,
      render: (record: InventoryPaymentModel) => (
        <div className={styles.creator}>
          <p>{record?.cash?.name}</p>
          <p>{record?.creator?.full_name}</p>
        </div>
      ),
    },
  ];

  const rowKey = (record: InventoryPaymentModel) => {
    return record.id;
  };

  return (
    <div className={styles.pays}>
      <Table<InventoryPaymentModel>
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />,
        }}
        loading={{
          spinning: isCompanyPerson ? companyPersonLoading : inventoryLoading,
          indicator: LoadingIndicator,
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            pageSize: 7,
            total: data?.total,
            current: data?.current_page,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Pays;
