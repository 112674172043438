import React from "react";
import { Form, Input } from "antd";
import ReactInputMask from "react-input-mask";
import { FormInstance } from "antd/lib";

import { PHONE_MASK } from "../../../utils/constants/phoneMask";
import { replacePhone } from "../../../utils/helpers/replacePhone";
// import { formRules } from "../../../utils/constants/formRules";

const { Item } = Form;

export interface Props {
  className: string;
  form: FormInstance;
  inputClassName: string;
}

const PhoneINNItem: React.FC<Props> = ({ className, form, inputClassName }) => {
  const onPastePhone = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = replacePhone(event.clipboardData.getData("Text"));

    form.setFieldValue("phone", value);

    event.preventDefault();
  };
  return (
    <div className={className}>
      <Item
        name="phone"
        label="Telefon raqami"
        // rules={formRules()}
        className={inputClassName}
      >
        <ReactInputMask
          maskChar=""
          mask={PHONE_MASK}
          onPaste={onPastePhone}
          placeholder="+998 99 999 99 99"
          className={`ant-input mask css-dev-only-do-not-override-tacjbp`}
        />
      </Item>
      <Item
        name="inn"
        label="INN"
        // rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
    </div>
  );
};

export default PhoneINNItem;
