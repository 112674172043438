import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Form, FormInstance, Input, Popover } from "antd";
import { formRules } from "features/app/utils/constants/formRules";
import { RU } from "features/app/utils/constants/languages";
import { cx } from "features/app/utils/helpers/cx";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { formatterPrice } from "features/supply/utils/helpers/partyModalTableActions";
import { MarketPriceModel } from "features/supply/utils/models/orderModel";
import { PartyFormProductsModel } from "features/supply/utils/models/partyModalFormModel";
import { rootPaths } from "routes/root/rootPaths";

import { useAppSelector } from "hooks/redux";

import { appReducerActions } from "store/reducers/appReducer";

import { ArrowUpIcon, TrendDownIcon } from "../../../../../../../../pages/dashboard/components/icons";
import ConditionalRender from "../../../../../../../app/components/conditional-render/ConditionalRender";

type Props = {
  index: number;
  groupIndex: number;
  form: FormInstance;
  isWarehouse?: boolean;
};

const { Item, useWatch } = Form;

const PersonAmount: React.FC<Props> = ({ index, form, groupIndex, isWarehouse }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isImpostPage = pathname.includes(rootPaths.IMPOST);
  const firstName = ["person_group", groupIndex, "data", index];
  const fieldName = [...firstName, "amount"];
  const totalFieldName = [...firstName, "total"];
  const marketName = [...firstName, "market_price"];
  const amount = useWatch(fieldName, form) as string;
  const { setProductMarketPrice, setProductMarketPriceModal } = appReducerActions;
  const market = JSON.parse(useWatch(marketName, form) || "{}") as MarketPriceModel;
  const products = useWatch(["person_group", groupIndex, "data"], form) as PartyFormProductsModel[];
  const { isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const findProduct = products[index];
    const { value } = e.currentTarget;
    const newValue = localeFormatter(value);
    const productQuantity = parseLocaledString(localeFormatter(String(findProduct?.quantity)));

    form.setFieldValue(fieldName, localeFormatter(value));
    form.setFieldValue(totalFieldName, formatterPrice(parseLocaledString(newValue) * productQuantity));
  };

  const onOpenMarketPrice = () => () => {
    const thisProduct = JSON.parse(form.getFieldValue([...firstName, "this_product"]) || "{}");

    dispatch(
      setProductMarketPrice({
        productId: thisProduct?.product?.id,
        unitId: thisProduct?.unit?.id,
        defaultProduct: structuredClone(thisProduct?.product),
        defaultUnit: structuredClone(thisProduct?.unit)
      })
    );

    dispatch(
      setProductMarketPriceModal({
        visible: true
      })
    );
  };

  const content = (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Oxirgi kirim</span>
        <div className="flex items-center gap-1">
          <span className="text-sm font-semibold text-gray-700">
            {market?.market_price?.toLocaleString(RU) || "Mavjud emas"}
          </span>
          <span className="text-sm font-medium text-gray-400">{market?.market_price_currency?.symbol}</span>
        </div>
      </div>
      <ConditionalRender if={market?.market_price_date}>
        <div className="flex items-center justify-between gap-10">
          <span className="text-sm font-medium text-gray-500">Kirim sanasi</span>
          <span className="text-sm font-semibold text-gray-700">{market?.market_price_date}</span>
        </div>
      </ConditionalRender>
      <ConditionalRender if={market?.market_price_order_id}>
        <div className="flex items-center justify-between gap-10">
          <span className="text-sm font-medium text-gray-500">Partiya</span>
          <span className="cursor-pointer text-sm font-semibold text-primary-500 hover:underline">
            P-{market?.market_price_order_id}
          </span>
        </div>
      </ConditionalRender>
      <div className="flex items-center justify-between gap-10">
        <span className="text-sm font-medium text-gray-500">Narx o'zgarishi</span>
        <div className="flex items-center gap-2">
          <div
            className={cx("flex items-center gap-1 rounded-xl px-2 py-1 text-sm font-semibold text-gray-500", {
              "bg-success-50 text-success-500": market?.change_amount_percent && market?.change_amount_percent < 0,
              "bg-error-50 text-error-500": market?.change_amount_percent && market?.change_amount_percent > 0
            })}
          >
            <ConditionalRender if={market?.change_amount_percent}>
              <ArrowUpIcon isRotate={!!(market?.change_amount_percent && market?.change_amount_percent > 0)} />
            </ConditionalRender>
            <span>{Math.abs(market?.change_amount_percent || 0)} %</span>
          </div>
          <div
            onClick={onOpenMarketPrice()}
            className="flex cursor-pointer items-center justify-center rounded-lg bg-gray-100 p-1"
          >
            <TrendDownIcon />
          </div>
        </div>
      </div>
    </div>
  );

  const isImpostUnitAmount =
    !isEditing &&
    isImpostPage &&
    !updateProduct?.index &&
    !updateProduct?.condition &&
    products?.[index]?.row_id !== updateProduct?.index;

  return (
    <>
      <Item name={marketName} className="hidden" />
      <Item name={[...firstName, "this_product"]} className="hidden" />
      <Item name={fieldName} rules={isEditing && !isWarehouse ? formRules()! : []}>
        {isImpostUnitAmount ? (
          <div className="flex items-center pl-3">
            <Popover arrow={false} placement="top" content={content}>
              <div
                className={cx("rounded-md px-2 py-1 text-sm font-medium", {
                  "bg-success-50 text-success-500": market?.change_amount_percent && market?.change_amount_percent < 0,
                  "bg-error-50 text-error-500": market?.change_amount_percent && market?.change_amount_percent > 0,
                  "bg-gray-50 text-gray-600": !market?.change_amount_percent
                })}
              >
                {amount}
              </div>
            </Popover>
          </div>
        ) : (
          <Input
            step="0.01"
            placeholder="Narxi"
            autoComplete="off"
            onChange={onChangeAmount}
            disabled={isView && !updateProduct?.condition}
          />
        )}
      </Item>
    </>
  );
};

export default PersonAmount;
