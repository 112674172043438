import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, WarehouseAmountView } from "..";

interface Query {
  warehouseAmountViewData: PaginationType<WarehouseAmountView[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id: number;
}

export function useWarehouseAmountView({ enabled = false, id }: Params) {
  const initialValue: Query = { warehouseAmountViewData: { data: [], total: 0, current_page: 1 } };

  const { generateSearchParam, queries } = useQueryParams();
  const { tab, page } = queries();
  const searchParams = generateSearchParam({
    id,
    warehouse_id: tab !== "all" ? Number(tab) : undefined,
    page
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.WAREHOUSE_AMOUNT_DATA, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/warehouse-amount/view?${searchParams}`);

      return { warehouseAmountViewData: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
