import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RowSelectMethod, TableRowSelection } from "antd/lib/table/interface";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { PaginationType } from "../../../../app/utils/models/PaginationType";
import { ProductModel } from "../../../../settings/utils/models/product/productModel";

import styles from "./drawerTable.module.scss";

type Props = {
  page: number;
  isPrice?: boolean;
  isLoading: boolean;
  data?: PaginationType<ProductModel[]>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const DrawerTable: React.FC<Props> = ({ isLoading, page, setPage, data, isPrice }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const { setProductDrawerData, setOrderTableView } = supplyActions;
  const { ids, products } = useAppSelector(state => state.supplyReducer.productDrawerData);

  const columns: ColumnsType<ProductModel> = [
    {
      title: "Nomi",
      render: (record: ProductModel) => (
        <span>
          <span
            className={styles.symbol}
            style={{
              borderColor: record?.resource?.color,
              color: record?.resource?.color
            }}
          >
            {record?.resource?.symbol[language]}
          </span>
          {record?.name[i18n.language]}
        </span>
      )
    },
    {
      title: "Birligi",
      render: (record: ProductModel) => record?.unit?.symbol[language]
    },
    {
      title: "Resursi",
      render: (record: ProductModel) => record?.resource?.name[language]
    },
    {
      title: "Kodi",
      dataIndex: "code"
    }
  ];

  if (isPrice) {
    columns.push({
      title: "Narxi",
      render: (_, record) => record?.price?.toLocaleString(RU)
    });
  }

  const onSelectProduct = (selectProduct: ProductModel) => {
    const newProducts = [...products!];
    const index = newProducts?.findIndex(item => item.id === selectProduct.id);

    if (index > -1) {
      newProducts.splice(index, 1);
    } else {
      newProducts.push(selectProduct);
    }

    dispatch(
      setProductDrawerData({
        ids: newProducts.map(item => item.id) ?? ([] as number[]),
        products: newProducts as ProductModel[]
      })
    );

    // dispatch(setPartyTableView(true));
    dispatch(setOrderTableView(true));
  };

  const rowSelection: TableRowSelection<ProductModel> | undefined = {
    onSelect: onSelectProduct,
    selectedRowKeys: ids,
    onChange: (_: React.Key[], selectedRows: ProductModel[], info: { type: RowSelectMethod }) => {
      if (info.type === "all") {
        // dispatch(setPartyTableView(true));
        dispatch(setOrderTableView(true));

        if (selectedRows?.length > 0) {
          const productSet = new Set([...(products ?? []), ...selectedRows]);
          const newProducts = Array.from(productSet);

          dispatch(
            setProductDrawerData({
              ids: newProducts.map(item => item.id) ?? ([] as number[]),
              products: newProducts as ProductModel[]
            })
          );
        } else {
          const newProducts = products?.filter(item => !data?.data?.some(second => item?.id !== second?.id));

          dispatch(
            setProductDrawerData({
              ids: newProducts?.map(item => item.id) ?? ([] as number[]),
              products: newProducts as ProductModel[]
            })
          );
        }
      }
    },
    hideSelectAll: isEmptyArr(data?.data)
  };

  return (
    <div className={styles.table}>
      <Table<ProductModel>
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        rowKey={row => row?.id}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        onRow={record => ({
          onClick: () => onSelectProduct(record)
        })}
      />
      <Pagination
        paginationProps={{
          style: {
            marginTop: "1rem"
          },
          total: data?.total,
          current: page,
          onChange: page => {
            setPage(page);
          }
        }}
      />
    </div>
  );
};

export default DrawerTable;
