import { FormInstance } from "antd";
import dayjs from "dayjs";

import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { CustomFieldTypesEnum } from "../../../app/utils/enums/customFieldTypesEnum";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { CustomFieldSelectModel } from "../../../app/utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../../app/utils/models/customFIeldValueModel";
import { ReqCustomFieldValueModel } from "../../../app/utils/models/reqCustomFieldValueModel";

export function generateCustomFieldData(
  customFields: ReqCustomFieldValueModel[] | undefined,
  customFieldsData: CustomFieldSelectModel[] | undefined
) {
  const returnReqFieldObj = {
    [CustomFieldTypesEnum.CHECKBOX]: (item: ReqCustomFieldValueModel) => {
      const findField = customFieldsData?.find(i => i.id === item?.custom_field_id);

      return {
        custom_field_id: item?.custom_field_id,
        value: item?.value ? findField?.id : undefined,
      };
    },
    [CustomFieldTypesEnum.DATE]: (item: ReqCustomFieldValueModel) => ({
      custom_field_id: item?.custom_field_id,
      value: item?.value ? dayjs(item?.value, dayjsFormats.DATE).format(dayjsFormats.DATE) : undefined,
    }),
    [CustomFieldTypesEnum.FILE]: (item: ReqCustomFieldValueModel) => ({
      custom_field_id: item?.custom_field_id,
      value: item?.value,
    }),
    [CustomFieldTypesEnum.LABEL]: (item: ReqCustomFieldValueModel) => ({
      custom_field_id: item?.custom_field_id,
      // @ts-ignore
      value: item?.value?.join(","),
    }),
    [CustomFieldTypesEnum.SELECT]: (item: ReqCustomFieldValueModel) => ({
      custom_field_id: item?.custom_field_id,
      value: item?.value,
    }),
    [CustomFieldTypesEnum.TEXT]: (item: ReqCustomFieldValueModel) => ({
      custom_field_id: item?.custom_field_id,
      value: item?.value,
    }),
  };

  return (
    customFields
      ?.map(item => ({
        ...returnReqFieldObj[item.type!](item),
      }))
      // @ts-ignore
      ?.filter(item => item?.value || item?.custom_field_option_id)
  );
}

export function passedCustomFieldData(
  form: FormInstance,
  customFieldValues: CustomFieldValueModel[] | undefined,
  customFields: CustomFieldSelectModel[] | undefined
) {
  customFields?.forEach((custom, index) => {
    const formName = ["custom_field_values", index, "value"];
    
    const currentProduct = customFieldValues?.filter(
      (item) => item.custom_field?.id === custom.id
    );

    const currentCustom = currentProduct?.[0];
    const type = currentCustom?.custom_field?.type;

    if (!isEmptyArr(currentProduct)) {
      if (type === CustomFieldTypesEnum.DATE) {
        const value = dayjs(currentCustom?.value, dayjsFormats.DATE);

        form.setFieldValue(formName, value);
      } else if (type === CustomFieldTypesEnum.SELECT) {
        const optionId = currentCustom?.custom_field_option?.id;

        form.setFieldValue(formName, optionId);
      } else if (type === CustomFieldTypesEnum.CHECKBOX) {
        const value = currentCustom?.value;

        form.setFieldValue(formName, Boolean(value));
      } else if (type === CustomFieldTypesEnum.LABEL) {
        const labelFields = customFieldValues
          ?.filter(item => item?.custom_field?.type === CustomFieldTypesEnum.LABEL)
          ?.map(item => item?.custom_field_option?.id);

        form.setFieldValue(formName, labelFields);
      } else {
        const value = currentCustom?.value;

        form.setFieldValue(formName, value);
      }
    }
  });
}
