type RouteSubmodules = [
  {
    key: "Hujjatlar";
    actions: [
      {
        view: true;
        key: "Ko'rish";
      },
      {
        create: true;
        key: "Yaratish";
      },
      {
        update: true;
        key: "Tahrirlash";
      },
      {
        delete: true;
        key: "O'chirish";
      }
    ];
  }
];

export const templateSubmodules: RouteSubmodules = [
  {
    key: "Hujjatlar",
    actions: [
      {
        view: true,
        key: "Ko'rish"
      },
      {
        create: true,
        key: "Yaratish"
      },
      {
        update: true,
        key: "Tahrirlash"
      },
      {
        delete: true,
        key: "O'chirish"
      }
    ]
  }
];
