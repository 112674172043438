import React from "react";
import { useDispatch } from "react-redux";
import { Button, Tabs, TabsProps } from "antd";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import OrderDeleteArchive from "features/payment/components/bottom/order-delete-archive/order-delete-archive";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../hooks/redux";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useRoutePermissions } from "../../hooks/useRoutePermissions";
import { supplyActions } from "../../store/reducers/supplyReducer";
import ConditionalRender from "../app/components/conditional-render/ConditionalRender";
import { queryParamsKeys } from "../app/utils/constants/queryParamsKeys";
import { ImpostStatusEnums } from "../app/utils/enums/impostStatusEnums";
import OfferModal from "../supply/components/offers/offer-modal/OfferModal";
import Offers from "../supply/components/offers/table/Offers";
import PartyModal from "../supply/components/parts/party-modal/PartyModal";

import ConfirmModal from "./components/parts/confirm-modal/ConfirmModal";
import Parts from "./components/parts/Parts";
import Payment from "./components/payments/Payments";
import Works from "./components/works/Works";
import { useImpostOrderConfirmation } from "./service/mutations";
import { useGetImpostCounts } from "./service/queries";
import { routeSubmodules } from "./utils/constants/routeSubmodules";
import { tabKeys } from "./utils/constants/tabKeys";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queries, append } = useQueryParams();
  const { data } = useGetImpostCounts();
  const { submodules, actions } = useRoutePermissions("Monitoring", routeSubmodules);
  const { tab } = queries();
  const { setPartyModal } = supplyActions;
  const partyConfirmation = useImpostOrderConfirmation();
  const { tableData } = useAppSelector(state => state.supplyReducer.partyModal);
  const partyActions = actions("Partiyalar");

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const tabDataCount = (text: string, key: keyof Record<tabKeys, number>) => (
    <p className={styles.tab}>
      {text}
      {data && data?.[key] > 0 && <span className={styles.tab__count}>{data?.[key]}</span>}
    </p>
  );

  const items = [
    {
      key: tabKeys.PAYMENT,
      label: tabDataCount(t("Monitoring.Chiqim"), tabKeys.PAYMENT),
      children: <Payment />,
      permissionKey: "Kirim-chiqim"
    },
    {
      key: tabKeys.ORDER,
      label: tabDataCount(t("Monitoring.Partiya"), tabKeys.ORDER),
      children: <Parts />,
      permissionKey: "Partiyalar"
    },
    {
      key: tabKeys.WORK,
      label: tabDataCount(t("Monitoring.Ishlar"), tabKeys.WORK),
      children: <Works />,
      permissionKey: "Ishlar"
    },
    {
      key: tabKeys.OFFER,
      label: tabDataCount(t("Monitoring.Takliflar"), tabKeys.OFFER),
      children: <Offers isImpost />,
      permissionKey: "Takliflar"
    }
    // {
    //   key: tabKeys.INVENTORIES,
    //   label: "Inventar",
    //   children: <Inventories />,
    //   permissionKey: "Inventar",
    // },
  ];

  const impostItems = (): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    items.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    return newItems;
  };

  const onConfirmation = () => {
    partyConfirmation.mutateAsync({ id: tableData?.id }).then(() => {
      dispatch(
        setPartyModal({
          visible: false
        })
      );
    });
  };

  const modalFooter = (
    <>
      <ConditionalRender if={tableData?.confirmation_payment === ImpostStatusEnums.PENDING}>
        <ConfirmModal id={tableData?.id}>
          <Button>{t("Xodimlar.Bekor qilish")}</Button>
        </ConfirmModal>
        <Button type="primary" onClick={onConfirmation} loading={partyConfirmation.isLoading}>
          {t("Monitoring.Tasdiqlash")}
        </Button>
      </ConditionalRender>
    </>
  );

  return (
    <div className={styles.impost}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab} items={impostItems()} onChange={onChangeTabs} />
      </div>
      <PartyModal customFooter={modalFooter} actions={actions as never} />
      <OfferModal invalidateKey={tab === tabKeys.ORDER ? "party" : undefined} />
      <OrderDeleteArchive actions={partyActions} />
      <IncomeExpenseView />
    </div>
  );
};

export default Index;
