import { FormInstance } from "antd";
import { numberRound } from "modules/common";

export const onQuantityChange = <T>(
  firstQuantity: number,
  secondQuantity: number,
  action: "multiply" | "add" | "subtract" | "divide",
  form: FormInstance<T>,
  name: string | any[]
) => {
  console.log("firstQuantity", firstQuantity);

  if (action === "multiply") {
    form.setFieldValue(name, firstQuantity * secondQuantity === 0 ? undefined : firstQuantity * secondQuantity);
  } else if (action === "add") {
    form.setFieldValue(name, firstQuantity + secondQuantity);
  } else if (action === "subtract") {
    form.setFieldValue(name, firstQuantity - secondQuantity);
  } else if (secondQuantity !== 0) {
    const quantity = Number.isInteger(firstQuantity / secondQuantity)
      ? firstQuantity / secondQuantity
      : (firstQuantity / secondQuantity).toFixed(firstQuantity / secondQuantity > 1 ? 2 : 4);
    form.setFieldValue(name, quantity === 0 ? undefined : quantity);
  }
  return;
};
