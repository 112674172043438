import { Button } from "antd";
import { useState } from "react";
import ChevronLeftIcon from "../../../../../../../../../assets/icons/ChevronLeftIcon";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../hooks/redux";
import {
  GanttDateType,
  setGanttType,
} from "../../../../../../../../../../../store/reducers/ganttReducer";
import FullScreenCloseIcon from "../../../../../../../../../assets/icons/FullScreenCloseIcon";
import FullScreenOpenIcon from "../../../../../../../../../assets/icons/FullScreenOpenIcon";
import styles from "./ganttChartDateSelect.module.scss";

const GanttChartDateSelect = () => {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.ganttReducer);

  const [open, setOpen] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const ganttContainer = document.getElementById("gantt") as HTMLElement;

  const onToggleSelect = () => {
    setOpen((prev) => !prev);
  };

  const onToggleFullscreen = () => {
    setFullscreen((prev) => !prev);
    if (fullscreen) {
      document.exitFullscreen();
    } else {
      ganttContainer.requestFullscreen();
    }
  };

  const onTodayDate = (value: GanttDateType) => {
    if (value === GanttDateType.DAY) {
      dispatch(setGanttType(GanttDateType.DAY));
    }
    if (value === GanttDateType.MONTH) {
      dispatch(setGanttType(GanttDateType.MONTH));
    }
    if (value === GanttDateType.YEAR) {
      dispatch(setGanttType(GanttDateType.YEAR));
    }
  };

  return (
    <div className={styles.date_select}>
      <Button onClick={onToggleSelect} type="primary" className={styles.toggle}>
        <ChevronLeftIcon rotate={open ? 180 : 0} />
      </Button>
      <div className={`${styles.buttons} ${open ? styles.active : ""}`}>
        <div className={styles.line} />
        <div
          className={`${styles.button} ${
            type === GanttDateType.DAY ? styles.active_button : ""
          }`}
          onClick={() => onTodayDate(GanttDateType.DAY)}
        >
          Kun
        </div>
        <div className={styles.line} />
        {/* <div
          className={`${styles.button} ${
            type === GanttDateType.DAY ? styles.active_button : ""
          }`}
          onClick={() => onTodayDate(GanttDateType.DAY)}
        >
          Haftalar
        </div> */}
        {/* <div className={styles.line} /> */}
        <div
          className={`${styles.button} ${
            type === GanttDateType.MONTH ? styles.active_button : ""
          }`}
          onClick={() => onTodayDate(GanttDateType.MONTH)}
        >
          Oy
        </div>
        <div className={styles.line} />
        <div
          className={`${styles.button} ${
            type === GanttDateType.YEAR ? styles.active_button : ""
          }`}
          onClick={() => onTodayDate(GanttDateType.YEAR)}
        >
          Yil
        </div>
        <div className={styles.line} />
        <div onClick={onToggleFullscreen} className={styles.fullscreen}>
          {fullscreen ? <FullScreenCloseIcon /> : <FullScreenOpenIcon />}
        </div>
      </div>
    </div>
  );
};

export default GanttChartDateSelect;
