import { Button, Modal, Tabs, TabsProps } from "antd";

import { useGetWorkProgress } from "../../../../../service/queries";
import { tabKeys } from "../../../../../utils/constants/tabkKeys";
import { WorkProductTypeEnum } from "../../../../../utils/enums/workProductTypeEnum";
import { WorkModel } from "../../../../../utils/models/workModel";
import ResourceTable from "./table/ResourceTable";

import styles from "./workModalTabs.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  work?: WorkModel;
  progressId?: number;
};

const WorkModalTabs = ({ open, setOpen, work, progressId }: Props) => {
  const { data: progresses, isLoading } = useGetWorkProgress(
    open,
    work?.id,
    progressId
  );

  const onCancel = () => {
    setOpen(false);
  };

  const footer = () => (
    <div className={styles.footer}>
      <Button onClick={onCancel}>Yopish</Button>
    </div>
  );

  const items: TabsProps["items"] = [
    {
      key: tabKeys.PRODUCTS,
      label: "Mahsulotlar",
      children: (
        <ResourceTable
          resources={progresses}
          type={WorkProductTypeEnum.PRODUCT}
          isLoading={isLoading}
          progressId={progressId}
          taskId={work?.id}
        />
      ),
    },
    {
      key: tabKeys.HUNAN_RESOURCE,
      label: "Inson resursi",
      children: (
        <ResourceTable
          resources={progresses}
          type={WorkProductTypeEnum.PERSON}
          isLoading={isLoading}
          progressId={progressId}
          taskId={work?.id}
        />
      ),
    },
    {
      key: tabKeys.MECHANISMS,
      label: "Mexanizmlar",
      children: (
        <ResourceTable
          resources={progresses}
          type={WorkProductTypeEnum.MECHANISM}
          isLoading={isLoading}
          progressId={progressId}
          taskId={work?.id}
        />
      ),
    },
  ];

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      title={false}
      closeIcon={false}
      footer={footer()}
      className={styles.work_modal}
      width={1111}
    >
      <Tabs items={items} />
    </Modal>
  );
};

export default WorkModalTabs;
