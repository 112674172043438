import { Button, Radio, RadioChangeEvent, Switch, Table } from "antd";

import SwitchIcon from "../../components/integration/assets/icons/SwitchIcon";
import LogoUysot from "../../components/integration/assets/images/logo-uysot.png";

import { SwitchChangeEventHandler } from "antd/es/switch";
import { ColumnsType } from "antd/es/table";
import React, { useState } from "react";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import AddProjects from "../../components/integration/components/add-projects/AddProjects";
import ConnectProjects from "../../components/integration/components/connect-projects/ConnectProjects";
import CustomDropdown from "../../components/integration/components/custom-dropdown/CustomDropdown";
import IntegrationModal from "../../components/integration/components/integration-create/IntegrationCreate";
import IntegrationSettings from "../../components/integration/components/integration-settings/IntegrationSettings";
import styles from "./index.module.scss";

enum ContentViewEnum {
  PAYS = "pays",
  PROJECTS = "projects",
}

const data = [{ id: 1, name: "Naqd" }];

const Index = () => {
  const { append, queries } = useQueryParams();
  const [open, setOpen] = useState(false);
  const [switchValue, setSwitchValue] = useState(false);
  const [tabPosition, setTabPosition] = useState<ContentViewEnum>(
    ContentViewEnum.PAYS
  );

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabPosition(e.target.value);
  };

  const onClick = () => {
    append("integration", "true");
  };

  const onChangeSwitch = (value: boolean) => {
    setSwitchValue(value);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Uyqur",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Uysot",
      render: (record: any) => (
        <div className={styles.actions}>
          <ArrowNarrowIcon rotate={180} />
          <Button>Naqd</Button>
          <Button>Terminal</Button>
          <CustomDropdown />
        </div>
      ),
    },
  ];

  const rowKey = (record: any) => {
    return record.id;
  };

  return (
    <div className={styles.integration}>
      <h2>Integratsiya</h2>
      {!queries().integration && (
        <div className={styles.content}>
          <div className={styles.item}>
            <div className={styles.item__body} onClick={onClick}>
              <img src={LogoUysot} alt="Logo Uysot" />
              <h3>Uysot</h3>
              <p>Ish uchun kerak bo'lgan hamma narsa bir joyda.</p>
            </div>
            <div className={styles.item__footer}>
              <Button className={styles.button}>
                <SwitchIcon /> Faollashtirish
              </Button>
              <Switch onChange={onChangeSwitch} />
            </div>
          </div>
        </div>
      )}
      {queries().integration && (
        <div className={styles.content}>
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button value={ContentViewEnum?.PAYS}>To’lovlar</Radio.Button>
            <Radio.Button value={ContentViewEnum?.PROJECTS}>Loyihalar</Radio.Button>
          </Radio.Group>
          {tabPosition === ContentViewEnum.PAYS && (
            <div className={styles.pays}>
              <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                rowKey={rowKey}
                locale={{
                  emptyText: <TableEmpty />,
                }}
                // loading={{
                //   spinning: isCompanyPerson ? companyPersonLoading : inventoryLoading,
                //   indicator: LoadingIndicator,
                // }}
                className={styles.pay_table}
              />
              <Button type="primary" className={styles.submit_btn}>
                Saqlash
              </Button>
            </div>
          )}
          {tabPosition === ContentViewEnum.PROJECTS && (
            <div className={styles.projects__content}>
              <div className={styles.accommodations}>
                <h3>Turar-joy majmuasi</h3>
                <div className={styles.accommodations__content}>
                  <div className={styles.connect_project__wrapper}>
                    <ConnectProjects isEmpty />
                  </div>
                  <div className={styles.connect_project__wrapper}>
                    <ConnectProjects isNeedSettingsIcon />
                  </div>
                </div>
              </div>
              <div className={styles.accommodations}>
                <h3>Loyihalar</h3>
                <div className={styles.accommodations__content}>
                  <div className={styles.connect_project__wrapper}>
                    <ConnectProjects isProject isEmpty />
                  </div>
                  <div className={styles.connect_project__wrapper}>
                    <ConnectProjects isProject isNeedSettingsIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <IntegrationModal setOpen={setOpen} open={open} />
      <IntegrationSettings />
      <AddProjects />
    </div>
  );
};

export default Index;
