import { useEffect, useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { Button, Input } from "antd";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import AvatarAddIcon from "features/projects/assets/icons/AvatarAddIcon";

import { User } from "modules/common";
import { AppendEmployees, useWidget } from "modules/dashboard";
import { CreateWidgetForm, UpdateWidgetForm } from "modules/dashboard/forms";

import styles from "./modal.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  form: UseFormReturn<CreateWidgetForm | UpdateWidgetForm>;
};

export default function Content({ form }: Props) {
  const { widget, methods, visibleWidgetModal } = useWidget();
  const [users, setUsers] = useState<User[]>([]);
  const { t } = useTranslation();

  const onCancel = () => {
    methods.setWidgetModal({
      visibleWidgetModal: false,
      isUpdateWidget: false,
      widget: widget!
    });
  };

  useEffect(() => {
    if (widget?.users && widget?.users?.length > 0 && visibleWidgetModal) {
      setUsers(widget?.users);
    }

    if (!visibleWidgetModal) {
      setUsers([]);
    }
  }, [widget, visibleWidgetModal]);

  return (
    <div className="flex flex-col gap-5">
      <Controller control={form.control} render={() => <div className="hidden" />} name="id" />
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-700">{t("dashboard.Nomi")}</label>
        <Controller
          name="name"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              placeholder={t("dashboard.Nomi")}
              status={error ? "error" : ""}
              onChange={e => field.onChange(e.target.value)}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-1.5">
        <label className="text-sm font-medium text-gray-700">{t("dashboard.Izoh")}</label>
        <Controller
          name="title"
          control={form.control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              placeholder={t("dashboard.Izoh")}
              status={error ? "error" : ""}
              onChange={e => field.onChange(e.target.value)}
            />
          )}
        />
      </div>
      <AppendEmployees
        data={users}
        setData={setUsers as never}
        formInstance={form as never}
        title={t("dashboard.Vidget xodimlari")}
      >
        <div className={styles.container}>
          <Controller
            name="user_ids"
            control={form.control}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <>
                <span>{t("dashboard.Xodim")}</span>

                <div className={styles.participant_container}>
                  <div className={styles.append}>
                    <AvatarAddIcon />
                  </div>
                  {users?.length > 0 && (
                    <div className={styles.box}>
                      {users.slice(0, 3)?.map(el => (
                        <div className={styles.participant} key={el.id}>
                          {el?.image ? (
                            <img src={el?.image} alt={el?.full_name} />
                          ) : (
                            <NotUserImage name={el?.full_name} isTooltip={false} />
                          )}
                        </div>
                      ))}
                      {users && users?.length > 3 && (
                        <div className={styles.participant}>
                          <NotUserImage isTooltip={false} title={`+${users.length - 3}`} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          />
        </div>
      </AppendEmployees>
      <div className="flex items-center justify-end gap-3">
        <Button onClick={onCancel}>{t("dashboard.Yopish")}</Button>
        <Button type="primary" htmlType="submit" loading={form.formState.isSubmitting}>
          {t("dashboard.Saqlash")}
        </Button>
      </div>
    </div>
  );
}
