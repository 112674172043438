import React from "react";
import { Tabs } from "antd";
import { useQueryParams } from "hooks/useQueryParams";
import { useTranslation } from "react-i18next";
import { Bar, useWidget } from "modules/dashboard";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tabKeys } from "features/impost/utils/constants/tabKeys";
import TabContent from "./tab-content/tab-content";
import { useWarehouseAmountView } from "modules/dashboard/queries/use-warehouse-amount-view";

import styles from "./warehouse-amount.module.scss";

const WarehouseAmount: React.FC = () => {
  const { queries, append } = useQueryParams();
  const { t } = useTranslation();

  const { tab } = queries();

  const { id, visible, props } = useWidget();

  const comingWidgetData = props as Bar;

  const { warehouseAmountViewData, isLoading } = useWarehouseAmountView({ enabled: visible, id });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = comingWidgetData?.values?.map(el => ({
    key: String(el.id),
    label: <p className={styles.tab}>{el.name as string}</p>,
    children: <TabContent data={warehouseAmountViewData} isLoading={isLoading} />
  }));

  const itemsWithAllTab = [
    {
      key: tabKeys.ALL,
      label: <p className={styles.tab}>{t("dashboard.Barchasi")}</p>,
      children: <TabContent data={warehouseAmountViewData} isLoading={isLoading} />
    },
    ...(items || [])
  ];

  return (
    <div className={styles.warehouse_bar}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab} items={itemsWithAllTab} onChange={onChangeTabs} />
      </div>
    </div>
  );
};

export default WarehouseAmount;
