import React from "react";

const BellIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79447 17.4993C8.38208 18.018 9.15395 18.3327 9.99932 18.3327C10.8447 18.3327 11.6166 18.018 12.2042 17.4993M14.9993 6.66602C14.9993 5.33993 14.4725 4.06816 13.5349 3.13048C12.5972 2.1928 11.3254 1.66602 9.99932 1.66602C8.67324 1.66602 7.40147 2.1928 6.46379 3.13048C5.52611 4.06816 4.99932 5.33993 4.99932 6.66602C4.99932 9.24117 4.34972 11.0043 3.62404 12.1705C3.01193 13.1542 2.70587 13.6461 2.71709 13.7833C2.72952 13.9352 2.76171 13.9932 2.88414 14.084C2.99471 14.166 3.49315 14.166 4.49004 14.166H15.5086C16.5055 14.166 17.0039 14.166 17.1145 14.084C17.2369 13.9932 17.2691 13.9352 17.2816 13.7833C17.2928 13.6461 16.9867 13.1542 16.3746 12.1705C15.6489 11.0043 14.9993 9.24117 14.9993 6.66602Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
