import { useEffect, useRef, useState } from "react";
import { Dropdown, MenuProps, Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableData } from "features/app/utils/constants/defaultTableData";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { useTranslation } from "react-i18next";

import { Table } from "components";

import { useAppDispatch } from "../../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../../hooks/useRoutePermissions";
import { inventoryReducerActions } from "../../../../../../store/reducers/inventoryReducer";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { GetComponentProps } from "../../../../../app/utils/models/getComponentPropsType";
import { MenuEventHandlerModel } from "../../../../../app/utils/models/menuEventHandlerModel";
import LogOutIcon from "../../../../assets/icons/LogOutIcon";
import PackageXIcon from "../../../../assets/icons/PackageXIcon";
import PlayIcon from "../../../../assets/icons/PlayIcon";
import PlusCircleIcon from "../../../../assets/icons/PlusCircleIcon";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";
import { DefectModel } from "../../../../utils/models/defectModel";
import { InventoryDetailModel } from "../../../../utils/models/inventoryModel";
import DeleteInventory from "../../inventory-view/delete-inventory/DeleteInventory";
import LeaveProject from "../../inventory-view/leave-project/LeaveProject";
import Top from "../../top/Top";

import styles from "./inventoryTable.module.scss";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import useDebounce from "components/use-debounce/use-debounce";


type Props = {
  inventories?: InventoryDetailModel[];
  isLoading: boolean;
  total?: number;
};

const InventoryTable = ({ inventories, isLoading, total }: Props) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const [visibleDeleteInventory, setVisibleDeleteInventory] = useState(false);
  const [visibleLeaveProject, setVisibleLeaveProject] = useState(false);
  const [inventory, setInventory] = useState<InventoryDetailModel>();
  const { setAttachModalData, setDefectModalData, setInventoryDetailModalData, setInventoryModal } =
    inventoryReducerActions;
  const { actions } = useRoutePermissions("Inventar", routeSubmodules);
  const inventoryActions = actions("Inventarlar");

  const onOpenAttachModal = (inventory_id: number) => {
    dispatch(setAttachModalData({ visible: true, inventory_id }));
  };

  const onOpenInvalidModal = (record: InventoryDetailModel) => {
    dispatch(
      setDefectModalData({
        visible: true,
        product: record as DefectModel & InventoryDetailModel
      })
    );
  };

  const onChangeProject = (inventory: InventoryDetailModel) => {
    dispatch(
      setAttachModalData({
        visible: true,
        inventory_id: inventory?.id,
        project_id: inventory?.project?.id,
        project_name: inventory?.project?.name,
        start_date: inventory?.start_date,
        due_date: inventory?.due_date
      })
    );
  };

  const onDeleteInventory = (record: InventoryDetailModel) => {
    setInventory(record);
    setVisibleDeleteInventory(true);
  };

  const onEditInventory = (inventory: InventoryDetailModel) => {
    dispatch(setInventoryModal({ visible: true, inventory }));
    setInventory(inventory);
  };

  const onLeaveProject = (record: InventoryDetailModel) => {
    setInventory(record);
    setVisibleLeaveProject(true);
  };

  const dropdownItems = (record: InventoryDetailModel) => {
    const newItems = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={() => onEditInventory(record)}>
            <EditIcon /> <span>{t("products.Tahrirlash")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      },
      {
        key: "2",
        label: (
          <div className={styles.dropdown__item} onClick={() => onDeleteInventory(record)}>
            <DeleteIcon /> <span>{t("products.O'chirish")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      }
    ];

    if (record?.project?.id) {
      newItems.push({
        key: "3",
        label: (
          <div className={styles.dropdown__item} onClick={() => onChangeProject(record)}>
            <PlusCircleIcon /> <span>{t("Inventar.Loyihani o’zgartirish")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (record?.project?.id) {
      newItems.push({
        key: "4",
        label: (
          <div className={styles.dropdown__item} onClick={() => onLeaveProject(record)}>
            <LogOutIcon /> <span>{t("Inventar.Loyihadan chiqarish")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (!record?.project?.id && inventoryActions.toProject) {
      newItems.push({
        key: "5",
        label: (
          <div onClick={() => onOpenAttachModal(record.id)} className={styles.dropdown__item}>
            <PlusIcon /> {t("Inventar.Loyihaga biriktirish")}
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (inventoryActions.invalid) {
      newItems.push({
        key: "6",
        label: (
          <div className={styles.dropdown__item} onClick={() => onOpenInvalidModal(record)}>
            <PackageXIcon /> {t("Inventar.Yaroqsiz")}
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    return newItems;
  };

  const onClose = () => {
    dispatch(setInventoryDetailModalData({ visible: false, inventory_id: undefined }));
  };

  const onRow: GetComponentProps<number> | undefined = (id?: number) => ({
    onClick: () => {
      dispatch(setInventoryDetailModalData({ visible: true, inventory_id: id }));
    }
  });

  const onStopBubbling = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
  };

  const showProjectInfo = (record: InventoryDetailModel) => (
    <div className={styles.project_popover} onClick={onStopBubbling}>
      <div className={styles.project_popover__item}>
        <p>{t("MainLayoutLinks.Loyiha")}</p>
        <p>{record?.project?.name}</p>
      </div>
      <div className={styles.project_popover__item}>
        <p>{t("Monitoring.Sana")}</p>
        <p>
          {record?.start_date} {record?.due_date}
        </p>
      </div>
    </div>
  );

  const [renderColumns, setColumns] = useState<ColumnsType<InventoryDetailModel>>([]);

  const columns: ColumnsType<InventoryDetailModel> = [
    {
      title: `${t("Monitoring.Resurs turi va nomi")}`,
      render: (record: InventoryDetailModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "resource_type_name")?.width;
        return (
          <div className="resource">
            <div className={styles.resource}>Mex</div>
            <PopoverShortName
              title={record?.name}
              length={((columnWidth as number) + 150) / 10}
            />
            {record?.project?.id && (
              <Popover content={() => showProjectInfo(record)} overlayClassName={styles.popover}>
                <span className={styles.project_info}>
                  <PlayIcon /> {t("Inventar.Ishlamoqda")}
                </span>
              </Popover>
            )}
          </div>
        );
      },
      width: 450,
      className: "resource_type_name"
    },

    { title: `${t("Inventar.Inventar kod")}`, dataIndex: "code", width: 120, className: "inventory_code" },
    {
      title: `${t("Inventar.Tan narxi")}`,
      render: (record: InventoryDetailModel) => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.amount_currency?.symbol}
        </>
      ),
      width: 120,
      className: "inventory_own_price"
    },
    {
      title: `${t("Inventar.Narxi")}`,
      render: (record: InventoryDetailModel) => (
        <span className={styles.bold}>
          {record?.service_amount?.toLocaleString(RU)} {record?.service_amount_currency?.symbol}
        </span>
      ),
      width: 150,
      className: "inventory_price"
    },
    {
      title: `${t("products.Birligi")}`,
      render: (record: InventoryDetailModel) => (
        <span className="inventory-unit">{record?.service_amount_unit?.symbol[i18n.language]}</span>
      ),
      width: 150,
      className: "inventory_unit"
    }
  ];

  const rowKey = (record: InventoryDetailModel) => record.id;

  const inventoryTableDefaultData = tableData(tableConfigKeys.INVENTORY, i18n.language);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.INVENTORY, true);

  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.INVENTORY, inventoryTableDefaultData);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.INVENTORY]);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.INVENTORY,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800,timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<InventoryDetailModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const footer = () => (
    <div className={styles.pagination}>
      <Pagination
        paginationProps={{
          total
        }}
      />
    </div>
  );

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              defaultData={inventoryTableDefaultData!}
              configKey={tableConfigKeys.INVENTORY}
              locations={[CustomFieldLocationEnum.INVENTORY]}
              isNeedSize
            />
          ),
          render: (record: InventoryDetailModel) => (
            <Dropdown trigger={["click"]} menu={{ items: dropdownItems(record) as MenuProps["items"] }}>
              <div className={styles.dots} onClick={e => e.stopPropagation()}>
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          ),
          width: 70,
          fixed: "right",
          className: "action_dots"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div className={styles.inventories}>
      <Table<InventoryDetailModel>
        dataSource={inventories}
        onChangeColumns={onChangeColumns}
        title={() => <Top />}
        columns={renderColumns as ColumnsType<InventoryDetailModel>}
        pagination={false}
        rowKey={rowKey}
        onRow={record => onRow(record?.id)}
        rowClassName={() => "inventory-row"}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        footer={total! > 10 ? footer : () => false}
      />

      <LeaveProject
        open={visibleLeaveProject}
        setOpen={setVisibleLeaveProject}
        id={inventory?.id}
        name={inventory?.name}
      />
      <DeleteInventory
        open={visibleDeleteInventory}
        setOpen={setVisibleDeleteInventory}
        id={inventory?.id}
        onClose={onClose}
      />
    </div>
  );
};

export default InventoryTable;
