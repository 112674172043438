import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { colors } from "features/app/utils/constants/colors";
import { FileType } from "features/app/utils/enums/fileType";
import { cx } from "features/app/utils/helpers/cx";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import XCloseIcon from "features/warehouse/assets/icons/XCloseIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { fileViewerActions } from "store/reducers/file-viewer";

// @ts-ignore
import styles from "./file-view.module.scss";

const FileViewer: React.FC = () => {
  const { format, isOpen, name, url } = useAppSelector(state => state.fileViewerReducer);
  const { handleClose: close } = fileViewerActions;
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(close());
  };

  const downloadFunc: React.MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    downloadFile(url, name, format as FileType);
    handleClose();
  };

  return (
    <div onClick={handleClose} className={cx(`${styles.container} ${isOpen ? styles.container_active : ""}`)}>
      <div className={styles.container_header}>
        <p onClick={e => e.stopPropagation()}>{name}</p>
        <div className={styles.container_header_right}>
          <Button className={styles.container_header_right_button} type="primary" onClick={downloadFunc}>
            <DownloadIcon stroke={colors.WHITE} /> {t("fileViewer.Yuklab_olish")}
          </Button>
          <div className={styles.close_icon_container}>
            <XCloseIcon color={colors.WHITE} width={24} />
          </div>
        </div>
      </div>
      <div onClick={e => e.stopPropagation()} className={styles.container_file}>
        {url &&
          (format === "pdf" ? (
            <iframe
              title="view file iframe"
              height="100%"
              width="100%"
              src={`https://docs.google.com/gview?url=${url}&embedded=true`}
            />
          ) : (
            <iframe
              title="view file iframe"
              height="100%"
              width="100%"
              src={`https://view.officeapps.live.com/op/embed.aspx?src=${url}`}
            />
          ))}
      </div>
    </div>
  );
};

export default FileViewer;
