import React from "react";
import { Form, FormInstance } from "antd";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "features/app/components/project-select/project-select";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";

import { useSelector } from "store";

const { useWatch } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
};

const LaboratoryProjectSelect: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const products = useWatch("products", form) as LaboratoryDrawerProducts[];
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);

  return (
    <div className="w-full">
      <ProjectSelect
        mode="form"
        label={t("warehouse.Loyiha")}
        allowClear
        form={form}
        name="project_id"
        placeholder={t("warehouse.Tanlang")}
        suffixIcon={<SelectSuffixIcon />}
        disabled={(products && products?.length > 0 && products?.some(item => item?.id)) || isViewing}
      />
    </div>
  );
};

export default LaboratoryProjectSelect;
