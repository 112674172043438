import { MouseEvent } from "react";
import { useDispatch } from "react-redux";
import { Button, Collapse } from "antd";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { RU } from "../../../../../../modules/common";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { StatusEnums } from "../../../../../app/components/statuses/statusEnums";
import { useGetCurrencies } from "../../../../../settings/service/queries";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import { PaymentOrderDetailModel, WarehouseProductModel } from "../../../../utils/models/paymentOrderDetailModel";
import { CompanyPersonModel, PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";

import OrderCompanyPersonName from "./OrderCompanyPersonName";
import OrderViewBottomTableGroup from "./OrderViewBottomTableGroup";

import styles from "./orderViewBottom.module.scss";

type Props = {
  isLoading: boolean;
  isCompanyPerson?: boolean;
  warehouseProducts?: WarehouseProductModel[];
  paymentOrderDetail: PaymentOrderDetailModel | undefined;
};

const OrderViewBottom = ({ isLoading, warehouseProducts, paymentOrderDetail, isCompanyPerson = false }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setPaymentOrderModal } = paymentReducerActions;
  const { data: currencies } = useGetCurrencies();

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const partyActions = actions("Buyurtmalarga to'lovlar");

  const dataToGroup = warehouseProducts?.reduce(
    (p, c) => ({
      ...p,
      [c?.company_person?.id || 0]: {
        status: c.status,
        company_person: {
          ...c?.company_person,
          person: {
            ...c?.company_person?.person,
            name: c?.company_person?.name || "Belgilanmagan"
          }
        },
        total_amount: {
          ...(p[c?.company_person?.id || 0]?.total_amount || {}),
          [c.currency.id]:
            (p[c?.company_person?.id || 0]?.total_amount?.[c.currency.id] || 0) +
            (paymentOrderDetail?.order?.status === StatusEnums.RECIEVED
              ? c?.calculated_amount || 0
              : c?.amount * c?.quantity)
        },
        debt_amount: {
          ...(p[c?.company_person?.id || 0]?.debt_amount || {}),
          [c.currency.id]:
            (p[c?.company_person?.id || 0]?.debt_amount?.[c.currency.id] || 0) +
            (paymentOrderDetail?.order?.status === StatusEnums.RECIEVED ? c?.must_pay_amount || 0 : c?.debt_amount || 0)
        },
        data: [...(p[c?.company_person?.id || 0]?.data || []), c]
      }
    }),
    {} as {
      [key: number]: {
        total_amount: Record<number, number>;
        debt_amount: Record<number, number>;
        status: string;
        company_person: CompanyPersonModel;
        data?: WarehouseProductModel[];
      };
    }
  );

  const generateUniversalTotal = (amountObj: Record<number, number>) => {
    let result = 0;

    Object.keys(amountObj)?.forEach(item => {
      result += amountObj[Number(item)];
    });

    return result;
  };

  const onPayment = (
    e: MouseEvent<HTMLElement, globalThis.MouseEvent>,
    company_person: CompanyPersonModel,
    debt_amount: number,
    currency: CurrencyModel
  ) => {
    e.stopPropagation();

    dispatch(
      setPaymentOrderModal({
        visible: true,
        isOrderDelete: false,
        order: {
          ...(paymentOrderDetail?.order as PaymentOrderModel),
          ...{ company_person, debt_amount, currency }
        }
      })
    );
  };

  const isViewPaymentBtn = (key: string) =>
    key !== "0" &&
    dataToGroup &&
    paymentOrderDetail?.order?.status !== StatusEnums.OPEN &&
    paymentOrderDetail?.order?.status !== StatusEnums.REJECTED &&
    generateUniversalTotal(dataToGroup[Number(key)]?.debt_amount) > 0;

  const debtOrOverpayment = (item: any) => {
    const result: Record<"over" | "debt", Record<number, number>> = {
      over: {},
      debt: {}
    };

    currencies?.data?.forEach(currency => {
      if (item?.debt_amount?.[currency?.id] > 0) {
        result.debt = {
          ...result?.debt,
          [currency?.id]: item?.debt_amount?.[currency?.id]
        };
      }

      if (item?.debt_amount?.[currency?.id] < 0) {
        result.over = {
          ...result?.over,
          [currency?.id]: item?.debt_amount?.[currency?.id]
        };
      }
    });

    return result;
  };

  return (
    <div className={styles.bottom}>
      {dataToGroup && (
        <Collapse
          className={styles.collapse}
          expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
          items={Object.keys(dataToGroup)?.map(key => {
            const item = dataToGroup[Number(key)];
            const { over, debt } = debtOrOverpayment(item);

            return {
              label: (
                <div className={styles.label}>
                  <OrderCompanyPersonName order={paymentOrderDetail?.order} person={item?.company_person} />
                  {/* <span className={styles.lable}>{item?.company_person?.person?.name}</span> */}
                  <div className={styles.total}>
                    <p className="flex items-center gap-1">
                      {t("payment.Jami")}:
                      <span>
                        {currencies?.data?.map((currency, index) =>
                          item?.total_amount?.[currency.id] >= 0
                            ? `${item?.total_amount?.[currency.id]?.toLocaleString(RU)} ${currency?.symbol} ${currencies?.data?.[index + 1] ? ", " : ""} `
                            : undefined
                        )}
                      </span>
                    </p>
                    <p className="flex items-center gap-1">
                      <ConditionalRender if={generateUniversalTotal(over) < 0}>
                        {t("payment.Ortiqcha to'lov")}:
                        {currencies?.data?.map(currency =>
                          over?.[currency.id] < 0
                            ? `${over?.[currency.id]?.toLocaleString(RU)} ${currency?.symbol} `
                            : undefined
                        )}
                      </ConditionalRender>
                      <ConditionalRender if={generateUniversalTotal(debt) > 0 && isViewPaymentBtn(key)}>
                        <div className="flex items-center gap-1.5">
                          {t("payment.Qoldiq")}:
                          {currencies?.data?.map(currency =>
                            debt?.[currency.id] >= 0 ? (
                              <div className="flex items-center gap-2" key={currency?.id}>
                                <span>
                                  {debt?.[currency.id]?.toLocaleString(RU)} {currency?.symbol}
                                </span>
                                {partyActions?.create && (
                                  <Button
                                    type="primary"
                                    className="text-white"
                                    onClick={e => onPayment(e, item?.company_person, debt?.[currency.id], currency)}
                                  >
                                    {t("payment.To'lov qilish")}
                                  </Button>
                                )}
                              </div>
                            ) : undefined
                          )}
                        </div>
                      </ConditionalRender>
                    </p>
                  </div>
                </div>
              ),
              key,
              children: (
                <OrderViewBottomTableGroup
                  isLoading={isLoading}
                  order={paymentOrderDetail?.order}
                  isCompanyPerson={isCompanyPerson}
                  data={dataToGroup[Number(key)]?.data}
                  status={paymentOrderDetail?.order?.status || "ordered"}
                />
              )
            };
          })}
        />
      )}
    </div>
  );
};

export default OrderViewBottom;
