export const parseParamsId = (params?: string) => {
  if (params) {
    const splitParams = params.split("-");
    const id = splitParams.pop();
    const name = splitParams.join("-");

    return { name, id };
  }
  return {
    name: undefined,
    id: undefined,
  };
};
