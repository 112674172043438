import { WorkStatusEnum } from "../enums/workStatusEnum";

type workStatusValue = "Yopilgan" | "Tugallangan" | "Jarayonda" | "Ochiq";

export const workStatusValue: Record<WorkStatusEnum, workStatusValue> = {
  [WorkStatusEnum.CLOSED]: "Yopilgan",
  [WorkStatusEnum.COMPLETED]: "Tugallangan",
  [WorkStatusEnum.OPEN]: "Ochiq",
  [WorkStatusEnum.PROCESS]: "Jarayonda",
};
