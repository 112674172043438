import React, { useEffect, useState } from "react";

import ResizeIcon from "../../../../../../../../../../assets/icons/ResizeIcon";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../hooks/redux";
import { setGanttLineSize } from "../../../../../../../../../../store/reducers/ganttReducer";
import LineChangeIcon from "../../../../../../../../assets/icons/LineChangeIcon";

import styles from "./ganttVerticalLine.module.scss";

const GanttVerticalLine = () => {
  const { lineSize } = useAppSelector((state) => state.ganttReducer);
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState({ dragging: false, clientX: 0 });

  useEffect(() => {
    const gantt_table = document.getElementById("gantt_table");
    const windowMouseMove = (e: MouseEvent) => {
      if (position.dragging) {
        const width = lineSize! + e.clientX - position.clientX;

        if (gantt_table && width > 300 && gantt_table && width < 1220) {
          gantt_table?.setAttribute(
            "style",
            `min-width: ${width}px; width: ${width}px;`
          );
        }
      }
    };

    const windowMouseUp = () => {
      if (position.dragging && gantt_table) {
        dispatch(setGanttLineSize(gantt_table.getBoundingClientRect().width));
        setPosition((prev) => ({
          ...prev,
          dragging: false,
        }));
      }
    };

    window.addEventListener("mousemove", windowMouseMove);
    window.addEventListener("mouseup", windowMouseUp);

    return () => {
      window.removeEventListener("mousemove", windowMouseMove);
      window.removeEventListener("mouseup", windowMouseUp);
    };
  }, [position.dragging]);

  const mouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPosition({
      clientX: e.clientX,
      dragging: true,
    });
  };

  return (
    <div className={styles.container} onMouseDown={mouseDown}>
      <ResizeIcon className={styles.resize} />
      <LineChangeIcon />
      <LineChangeIcon />
      <LineChangeIcon />
    </div>
  );
};

export default GanttVerticalLine;
