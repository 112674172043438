import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { colors } from "features/app/utils/constants/colors";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { cx } from "features/app/utils/helpers/cx";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { PaymentTypeIcon } from "features/payment/utils/helpers/paymentTypeIcon";

import { useAppDispatch } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { RU } from "modules/common";
import { ExpensePaymentByMonth } from "modules/dashboard";

import styles from "./tab-content.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  data: PaginationType<ExpensePaymentByMonth[]>;
  isLoading: boolean;
}

const TabContent: React.FC<IProps> = ({ data, isLoading }) => {
  const { setIncomeOrExpenseViewVisible, setTransferHistoryModal, setConversionInfoModal } = paymentReducerActions;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const columns: ColumnsType = [
    {
      title: `${t("dashboard.Turi")}`,
      render: (record: ExpensePaymentByMonth) => PaymentTypeIcon(record?.type, record?.status as never)
    },
    {
      title: `${t("dashboard.Manbaa")}`,
      render: record =>
        PaymentSourceElement({
          isViewKey: false,
          record
        }),
      width: 500
    },
    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: record => (
        <span className={cx("text-base font-medium text-gray-700")}>
          {record.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      )
    },
    {
      title: `${t("dashboard.To'lov sanasi")}`,
      render: record => <span className="text-xs font-normal text-gray-700">{record?.date}</span>
    },

    {
      title: "",
      render: (record: ExpensePaymentByMonth) => (
        <CustomAvatar image={record?.creator?.image ? record?.creator?.image : ""} name={record?.creator?.full_name} />
      )
    },
    {
      title: "",
      render: () => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      )
    }
  ];

  const onRow = (record: ExpensePaymentByMonth) => ({
    onClick: () => {
      if (record?.type === PaymentTypeEnum.EXCHANGE) {
        dispatch(setConversionInfoModal(record as never));
      } else if (record?.type === PaymentTypeEnum.TRANSFER) {
        dispatch(setTransferHistoryModal(record as never));
      } else {
        dispatch(
          setIncomeOrExpenseViewVisible({
            visible: true,
            record: record as never,
            isNotDelete: true
          })
        );
      }
    }
  });

  return (
    <div className="flex flex-col p-5">
      <Table
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        className="footer_table"
        rowClassName={styles.table_row}
        onRow={onRow}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data.total,
            current: data.current_page
          }}
        />
      </div>
    </div>
  );
};

export default TabContent;
