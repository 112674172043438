import dayjs from "dayjs";

import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { CustomFieldTypesEnum } from "../../../app/utils/enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../../../app/utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../../app/utils/models/customFIeldValueModel";
import { PaymentCustomFieldValueModel } from "../models/paymentCustomFieldValue";

export function generateCustomFieldsData(values: PaymentCustomFieldValueModel[] | undefined) {
  return (
    values
      ?.filter(item => item?.value)
      ?.map(item => {
        if (item?.type === CustomFieldTypesEnum.DATE) {
          return {
            custom_field_id: item?.custom_field_id,
            value: dayjs(item?.value as never, dayjsFormats.DATE).format(dayjsFormats.DATE),
          };
        }

        if (item?.type === CustomFieldTypesEnum.LABEL) {
          const labelValue = item?.value as number[];
          let result = "";

          labelValue?.forEach((i, index) => {
            result += `${index === 0 ? "" : ","}${i}`;
          });

          return {
            custom_field_id: item?.custom_field_id,
            value: result,
          };
        }

        return {
          custom_field_id: item?.custom_field_id,
          value: item?.value,
        };
      }) || []
  );
}

export function passedCustomFieldsData(
  values: CustomFieldValueModel[] | undefined,
  customFields: CustomFieldSelectModel[] | undefined
) {
  const fieldValue = (record: CustomFieldSelectModel) => {
    const type = record?.type;

    const value = values?.find(item => item?.custom_field?.id === record?.id)?.value;

    if (type === CustomFieldTypesEnum.DATE) {
      return value ? dayjs(value, dayjsFormats.DATE) : null;
    }

    if (type === CustomFieldTypesEnum.LABEL) {
      const result: number[] = [];

      values?.forEach(item => {
        if (item?.custom_field?.type === CustomFieldTypesEnum.LABEL) {
          result.push(Number(item?.value));
        }
      });

      return result;
    }

    if (type === CustomFieldTypesEnum.SELECT) {
      return value ? Number(value) : undefined;
    }

    return value;
  };

  return (
    customFields?.map(item => ({
      custom_field_id: item?.id,
      type: item?.type,
      value: fieldValue(item),
    })) || []
  );
}
