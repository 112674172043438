import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LocalStorage } from "../../features/app/service/LocalStorage";
import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { EstimateGroupType } from "../../features/app/utils/models/estimateGroupType";
import { LocalStorageReducerModel } from "../types/LocalStorageReducerModel";

const initialState: LocalStorageReducerModel = {
  estimate_group: LocalStorage.get<EstimateGroupType[]>("estimate_group") || []
};

const localStorageSlice = createSlice({
  name: sliceNames.LOCALSTORAGE,
  initialState,
  reducers: {
    setEstimateGroup: (
      state,
      action: PayloadAction<{ data: EstimateGroupType; isDelete: false } | { isDelete: true; projectId: string }>
    ) => {
      const props = action.payload;
      let result: LocalStorageReducerModel["estimate_group"] = [];
      const projectId = props.isDelete ? props?.projectId : props?.data?.projectId;
      const findProject = state.estimate_group?.find(item => item?.projectId === String(projectId));

      if (props.isDelete) {
        result = state?.estimate_group?.filter(item => item?.projectId !== String(projectId));
      } else if (findProject) {
        result = state?.estimate_group?.map(item => {
          if (item?.projectId === String(findProject?.projectId)) {
            return { ...props?.data };
          }

          return item;
        });
      } else {
        result = [...(state?.estimate_group || []), props?.data];
      }

      state.estimate_group = result;
      LocalStorage.set("estimate_group", result);
    }
  }
});

export default localStorageSlice.reducer;
export const localStorageActions = localStorageSlice.actions;
