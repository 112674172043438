import { DatePicker } from "antd";
import dayjs from "dayjs";

import DatepickerSuffixIcon from "../../assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../utils/constants/dayjsFormats";
import { CustomFieldProps } from "../../utils/helpers/customFields";

const DateCustomField = ({
  disabled,
  placeholder,
  suffixIcon,
  onChange,
  value,
  onBlur,
}: CustomFieldProps) => {
  return (
    <>
      <DatePicker
        value={
          value &&
          dayjs(value, dayjsFormats.DATE).isValid() &&
          dayjs(value, dayjsFormats.DATE)
        }
        placeholder={placeholder || "Tanlang"}
        format={dayjsFormats.DATE}
        suffixIcon={suffixIcon || <DatepickerSuffixIcon />}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(value, dateString) => {
          if (onChange && typeof dateString == "string") {
            onChange(dateString);
          }
        }}
      />
    </>
  );
};

export default DateCustomField;
