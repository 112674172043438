import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import ExpectedExpenseIcon from "features/payment/assets/icons/ExpectedExpenseIcon";
import ExpectedIncomeIcon from "features/payment/assets/icons/ExpectedIncomeIcon";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { colors } from "../../../../../app/utils/constants/colors";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { tableIndex } from "../../../../../app/utils/helpers/tableIndex";
import { GetComponentProps } from "../../../../../app/utils/models/getComponentPropsType";
import { PaymentModel } from "../../../../../app/utils/models/payment/paymentModel";
import { useGetExpectedPayments } from "../../../../service/queries";
import { ExpectedPaymentModel } from "../../../../utils/models/expectedPaymentModel";
import { PaymentSourceElement } from "../../income-expense-view/PaymentSourceElement";

import styles from "./expectedPaymentTable.module.scss";

const ExpectedPaymentTable: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const incomeExpenseActions = actions("Kirim-chiqim");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const { data, isLoading } = useGetExpectedPayments();
  const { setPayModal, setExpectedPaymentView } = paymentReducerActions;
  const { type } = queries();

  const changeTableConfig = useChangeTableConfig(
    type === "income" ? tableConfigKeys.PAYMENT_EXPECTED_INCOME : tableConfigKeys.PAYMENT_EXPECTED_EXPENSE,
    true
  );
  const { data: tableConfig } = useGetTableConfigs(
    type === "income" ? tableConfigKeys.PAYMENT_EXPECTED_INCOME : tableConfigKeys.PAYMENT_EXPECTED_EXPENSE
  );

  const onPayment = (e: React.MouseEvent<HTMLElement, MouseEvent>, record: ExpectedPaymentModel) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      setPayModal({
        isTemplate: false,
        visible: true,
        type: type as PaymentTypeEnum,
        form_data: {
          id: record.id,
          amount: record.debt_amount,
          project_id: record?.project?.id,
          currency_id: record.currency.id,
          description: record?.description
        }
      })
    );
  };

  const diffDate = (date: string) => {
    const [day, month, year] = date.split(".");
    const day1 = dayjs(`${month}.${day}.${year}`);
    const diff = dayjs().diff(day1, "days");

    return diff > 0 ? (
      <span
        style={{
          color: colors.ERROR_500
        }}
      >
        {diff} kun
      </span>
    ) : (
      "-"
    );
  };

  const [columns, setColumns] = useState<ColumnsType<ExpectedPaymentModel>>([
    {
      title: "№",
      render: (_, __, index) => tableIndex(index, 1),
      width: 150,
      className: "index-column"
    },
    {
      title: `${t("payment.Sana")}`,
      dataIndex: "date",
      width: 250,
      className: "date-column"
    },
    {
      title: `${t("payment.Kechikish")}`,
      render: (_, record) => diffDate(record.date),
      width: 250,
      className: "delay-column"
    },
    {
      title: `${t("payment.Summa")}`,
      render: (record: ExpectedPaymentModel) => (
        <span>
          {record.amount?.toLocaleString(RU)} {record.currency.symbol}
        </span>
      ),
      width: 250,
      className: "amount-column"
    },
    {
      title: `${t("payment.Manbaa")}`,
      render: (record: PaymentModel) => <PaymentSourceElement record={record} isViewCompanyPersonBalance={false} />,
      width: 300,
      className: "payment-source-column"
    },
    {
      title: `${t("payment.Loyiha")}`,
      render: (record: PaymentModel) => record.project?.name,
      width: 250,
      className: "payment-project-column"
    },
    {
      title: `${t("payment.Xodim")}`,
      align: "right",
      render: (record: ExpectedPaymentModel) => (
        <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
      ),
      width: 150,
      className: "payment-creator"
    },
    {
      ...(incomeExpenseActions?.create
        ? {
            title: "",
            width: 180,
            render: record => (
              <div className={styles.action}>
                <Button onClick={e => onPayment(e, record)}>
                  {type === PaymentTypeEnum.INCOME ? <ExpectedIncomeIcon /> : <ExpectedExpenseIcon />}
                  <span>
                    {type === PaymentTypeEnum.INCOME ? t("payment.Kirim qilish") : t("payment.Qarzni to'lash")}
                  </span>
                </Button>
              </div>
            ),
            className: "payment-actions"
          }
        : {})
    }
  ]);

  const onRow: GetComponentProps<ExpectedPaymentModel> | undefined = record => ({
    onClick: () => {
      dispatch(
        setExpectedPaymentView({
          visible: true,
          id: record?.id
        })
      );
    }
  });

  useEffect(() => {
    // @ts-ignore
    if (tableConfig?.width_data?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key: type === "income" ? tableConfigKeys.PAYMENT_EXPECTED_INCOME : tableConfigKeys.PAYMENT_EXPECTED_EXPENSE,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ExpectedPaymentModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.expected_income_or_expense}>
      <div className={styles.top}>
        <Table<ExpectedPaymentModel>
          onChangeColumns={onChangeColumns}
          onRow={onRow}
          columns={columns}
          pagination={false}
          rowClassName={styles.on_row}
          dataSource={data?.data}
          rowKey={record => record.id}
          scroll={{ y: "65vh" }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
        />
      </div>
    </div>
  );
};

export default ExpectedPaymentTable;
