import React from "react";
import { notification } from "antd";

import useNetworkStatus from "hooks/use-network-status";

const Index: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { online } = useNetworkStatus();

  if (!online) {
    api.warning({
      key: "network",
      placement: "topRight",
      showProgress: true,
      duration: null,
      style: {
        width: 600
      },
      message: "Internetga ulanishda muammo mavjud.",
      description:
        "Internet tarmog'iga ulanishda muammolar yuz berdi, iltimos qurilmani internet tarmog'iga ulanganligini tekshiring."
    });
  } else {
    api.destroy("network");
  }

  return contextHolder;
};

export default Index;
