import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const OrderPending = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/warehouse?tab=orders", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        Ta’minot bo’limi <span>P - {record?.order?.id}</span> raqamli mahsulotlar
        partiyasini yubordi. Partiya kelish vaqti {record?.order?.delivery_date}
      </p>
    </div>
  );
};

export default OrderPending;
