import React from "react";
import { useDispatch } from "react-redux";
import { Drawer, Tabs, TabsProps } from "antd";
import classNames from "classnames";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { useGetCalendarOrderData, useGetCalendarPaymentData } from "../../../../service/queries";
import { PaymentFilterKeys } from "../../../../utils/enums/paymentFilterKeys";

import { CalendarHistoryStatistics } from "./CalendarHistoryStatistics";
import CalendarOrderTable from "./CalendarOrderTable";
import CalendarPaymentTable from "./CalendarPaymentTable";

import styles from "./calendarHistory.module.scss";

type Props = {
  isCompanyPerson?: boolean;
};

const CalendarHistory: React.FC<Props> = ({ isCompanyPerson = false }) => {
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const { filter_type: filterType } = queries();
  const { setCalendarHistory, setCalendarHistoryTab } = paymentReducerActions;
  const { data: payments, isLoading: isLoadingPayments } = useGetCalendarPaymentData(isCompanyPerson);
  const { data: orders, isLoading: isLoadingOrder } = useGetCalendarOrderData(isCompanyPerson);

  const { visible, tabKey, minDate, maxDate } = useAppSelector(state => state.paymentReducer.calendarHistory);

  const onCancel = () => {
    dispatch(
      setCalendarHistory({
        visible: false,
        page: 1,
        tabKey: PaymentFilterKeys.ORDER,
        minDate,
        maxDate
      })
    );
  };

  const items: TabsProps["items"] = [
    {
      key: PaymentFilterKeys.ORDER,
      label: (
        <div
          className={classNames(styles.label, {
            [styles.label__active]: tabKey === PaymentFilterKeys.ORDER
          })}
        >
          <span>Partiyalar</span>
          <div>{orders?.total}</div>
        </div>
      ),
      children: (
        <div className={styles.history__content}>
          <CalendarHistoryStatistics isCompanyPerson={isCompanyPerson} />
          <CalendarOrderTable data={orders} isLoading={isLoadingOrder} />
        </div>
      )
    },
    {
      key: PaymentFilterKeys.EXPECTED_PAYMENT,
      label: (
        <div
          className={classNames(styles.label, {
            [styles.label__active]: tabKey === PaymentFilterKeys.EXPECTED_PAYMENT
          })}
        >
          <span>Kutilayotgan to'lovlar</span>
          <div>{payments?.total}</div>
        </div>
      ),
      children: (
        <div className={styles.history__content}>
          <CalendarHistoryStatistics isCompanyPerson={isCompanyPerson} />
          <CalendarPaymentTable data={payments} isLoading={isLoadingPayments} />
        </div>
      )
    }
  ];

  const onChangeTab = (e: string) => {
    dispatch(setCalendarHistoryTab(e as PaymentFilterKeys));
  };

  return (
    <Drawer width="65rem" open={visible} title="Tarix" footer={null} onClose={onCancel}>
      {filterType ? (
        items?.find(item => item?.key === filterType)?.children
      ) : (
        <Tabs items={items} activeKey={tabKey} onChange={onChangeTab} />
      )}
    </Drawer>
  );
};

export default CalendarHistory;
