import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SettingsCurrencies from "../../components/finance/currency/SettingsCurrencies";
import SettingsPaymentTypes from "../../components/finance/payment-type/SettingsPaymentTypes";
import Vat from "../../components/finance/vat/vat";
import SettingsFinancialModal from "../../components/financial/modal/SettingsFinancialModal";
import SettingsFinancialTable from "../../components/financial/table/SettingsFinancialTable";
import { useGetFinancial } from "../../service/queries";
import { EXPENSE, INCOME } from "../../utils/constants/financialType";
import { FinancialModalPropsModel } from "../../utils/models/financialModel";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetFinancial();
  const [modalProps, setModalProps] = useState<FinancialModalPropsModel>();

  return (
    <div className={styles.finance}>
      <h3>{t("settings.interface.Moliya")}</h3>
      <div className={styles.finance__bottom}>
        <SettingsPaymentTypes />
        <SettingsFinancialTable
          type={INCOME}
          isLoading={isLoading}
          data={data?.data.income}
          setModalProps={setModalProps}
        />
        <Vat />
        <SettingsFinancialTable
          type={EXPENSE}
          isLoading={isLoading}
          data={data?.data.expense}
          setModalProps={setModalProps}
        />
        <SettingsCurrencies />
      </div>
      <SettingsFinancialModal modalProps={modalProps} setModalProps={setModalProps} />
    </div>
  );
};

export default Index;
