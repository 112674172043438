import React from "react";
import { Control, ControllerRenderProps, FieldValues, useController } from "react-hook-form";

import { cx } from "../../features/app/utils/helpers/cx";

import cls from "./wrapper.module.scss";

export interface BaseProps {
  control: Control<any>;
  label?: React.ReactNode;
  withLabel?: boolean;
  withoutMessage?: boolean;
  labelClassName?: string;
}

interface WrapperProps extends BaseProps {
  name: string;
  disabled?: boolean;
  children: (field: ControllerRenderProps<FieldValues, any>, error: string) => React.ReactNode;
}

const Wrapper: React.FC<WrapperProps> = ({
  control,
  name,
  label = "",
  withoutMessage = false,
  labelClassName,
  children
}) => {
  const { field, fieldState } = useController({ control, name });

  const error = fieldState.error?.message || "";

  return (
    <div className={cx("flex w-full flex-col gap-1.5", cls.wrapper)}>
      <label htmlFor={name} className={cx("text-sm font-medium text-gray-700", labelClassName)}>
        {label}
      </label>
      {children(field, error)}
      {!withoutMessage && error && <div className="text-sm text-error-500">{error}</div>}
    </div>
  );
};

export default React.memo(Wrapper);
