import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Col, Popover, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import SelectNotContentIcon from "../../../../../app/assets/icons/SelectNotContentIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import { useGetTaskProducts } from "../../../../service/queries";
import { ProjectTaskProductModel } from "../../../../utils/models/projectModel";

import styles from "./estimateProduct.module.scss";

type Props = {
  taskId: number;
  isPrice?: boolean;
};

const EstimateProduct: React.FC<Props> = ({ taskId, isPrice }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setEstimateData, setOrderTableView } = supplyActions;
  const { data: allProducts, isLoading } = useGetTaskProducts(taskId);
  const { products } = useAppSelector(state => state.supplyReducer.estimateData);

  const onCheck = (newValue: ProjectTaskProductModel) => () => {
    let newProducts = [...products];
    // dispatch(setPartyTableView(true));

    dispatch(setOrderTableView(true));

    if (!newProducts.some(item => item.id === newValue.id)) {
      newProducts.push(newValue);
    } else {
      newProducts = newProducts.filter(item => item.id !== newValue.id);
    }

    dispatch(
      setEstimateData({
        key: "products",
        data: newProducts
      })
    );
  };

  const checkValue = (value: number) => products.some(item => item.id === value);

  const maxTextLength = isPrice ? 32 : 44;

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.products}>
        {!isEmptyArr(allProducts) ? (
          allProducts?.map(item => (
            <Row gutter={0} key={item.id} className={styles.products__row} onClick={onCheck(item)}>
              <Col span={isPrice ? 12 : 15} className={styles.products__name}>
                <Checkbox key={item.id} value={item.id} checked={checkValue(item.id)} />
                <div className="resource">
                  <div
                    style={{
                      borderColor: item.product?.resource?.color,
                      color: item.product?.resource?.color
                    }}
                  >
                    {item.product?.resource?.symbol[i18n.language]}
                  </div>
                </div>
                {item.product?.name[i18n.language]?.length > maxTextLength ? (
                  <Popover title={item.product?.name[i18n.language]} className="popover">
                    {sliceText(item.product?.name[i18n.language], maxTextLength)}
                  </Popover>
                ) : (
                  item.product?.name[i18n.language]
                )}
              </Col>
              <Col span={3} className={styles.products__item}>
                {item.total_ordered_quantity}
              </Col>
              <Col span={3} className={styles.products__item}>
                {item.quantity}
              </Col>
              <Col span={3} className={styles.products__item}>
                {item.unit?.symbol[i18n.language]}
              </Col>
              {isPrice ? (
                <Col span={3} className={styles.products__item}>
                  {item.amount?.toLocaleString(RU)}
                </Col>
              ) : null}
            </Row>
          ))
        ) : (
          <div className={styles.empty}>
            <TableEmpty description=" " icon={<SelectNotContentIcon />} />
          </div>
        )}
      </div>
    </Spin>
  );
};

export default EstimateProduct;
