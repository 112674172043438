import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { calCulatingPaymentPercent } from "features/payment/utils/helpers/calCulatingPaymentPercent";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetInventoryPayments } from "../../../../service/queries";
import { PaymentInventoryWorkModel } from "../../../../utils/models/paymentInventoryModel";
import InventoryWorkPaymentView from "../../inventory-work-payment-view/InventoryWorkPaymentView";

import styles from "./inventoryTable.module.scss";

const InventoryTable = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [visibleInventoryPayment, setVisibleInventoryPayment] = useState(false);
  const [inventoryPaymentData, setInventoryPaymentData] = useState<PaymentInventoryWorkModel>();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const { data: inventories, isLoading } = useGetInventoryPayments();

  const onDetailInventory = (record: PaymentInventoryWorkModel) => ({
    onClick: () => {
      setVisibleInventoryPayment(true);
      setInventoryPaymentData(record);
    }
  });

  const onVisiblePayment = (event: MouseEvent<HTMLDivElement>, record?: PaymentInventoryWorkModel) => {
    event.stopPropagation();

    dispatch(
      setPaymentInventoryWorkModal({
        visible: true,
        amount: record?.amount,
        id: record?.id,
        personId: record?.company_person?.id,
        debtAmount: record?.debt_amount,
        defaultCurrency: record?.currency
      })
    );
  };

  const getStatus = (record: PaymentInventoryWorkModel) => {
    if (record?.debt_amount === 0) {
      return <div className={styles.success}>To’langan</div>;
    }
    return <div className={styles.error}>To’lanmagan</div>;
  };

  const columns: ColumnsType<PaymentInventoryWorkModel> = [
    {
      title: "Ish nomi va loyiha",
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.name}>
          {record?.task?.name?.length > 25 ? (
            <Popover title={record?.task?.name} overlayClassName={styles.popover}>
              <p>
                {record?.place} {record?.task?.name.substring(0, 25)}...
              </p>
            </Popover>
          ) : (
            <p>
              {record?.place} {record?.task?.name}
            </p>
          )}

          <p>{record?.project?.name}</p>
        </div>
      )
    },

    {
      title: "Ish tugallandi",
      dataIndex: "completed_date"
    },
    {
      title: "Inventar nomi",
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.name}>
          {record?.inventory?.name?.length > 25 ? (
            <Popover title={record?.inventory?.name} overlayClassName={styles.popover}>
              <p>{record?.inventory?.name.substring(0, 25)}...</p>
            </Popover>
          ) : (
            <p>{record?.inventory?.name}</p>
          )}
        </div>
      )
    },
    {
      title: "Ishni bajardi",
      render: (record: PaymentInventoryWorkModel) => record?.company_person?.name
    },
    {
      title: "Ish hajmi",
      render: (record: PaymentInventoryWorkModel) => (
        <span className={styles.bold}>
          {record?.quantity} {record?.unit?.symbol[i18n.language]}
        </span>
      )
    },

    {
      title: "Summasi",
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.name}>
          <p>
            {(record?.amount * record?.quantity)?.toLocaleString(RU) ?? 0} {record?.currency?.symbol}
          </p>
        </div>
      )
    },

    {
      title: "Xodim",
      render: (record: PaymentInventoryWorkModel) => (
        <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
      ),
      align: "center"
    },
    {
      title: "To'lov holati",
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.creator}>
          {calCulatingPaymentPercent(record?.amount * record?.quantity, record?.debt_amount)}
          {!(record?.debt_amount === 0) && (
            <div className={styles.action} onClick={event => onVisiblePayment(event, record)}>
              <Button type="primary">To’lov qilish</Button>
            </div>
          )}
        </div>
      ),
      width: "10%",
      align: "center"
    }
  ];

  return (
    <div className={styles.inventory_payment}>
      <Table<PaymentInventoryWorkModel>
        columns={columns}
        pagination={false}
        dataSource={inventories?.data}
        rowKey={record => record.id}
        onRow={onDetailInventory}
        rowClassName={() => styles.row}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        className={styles.table}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: inventories?.total
          }}
        />
      </div>
      <InventoryWorkPaymentView
        open={visibleInventoryPayment}
        setOpen={setVisibleInventoryPayment}
        record={inventoryPaymentData}
        isInventory
      />
    </div>
  );
};

export default InventoryTable;
