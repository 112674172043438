import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Col, Dropdown, MenuProps, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { cx } from "../../../../app/utils/helpers/cx";
import { Status, StatusNames } from "../../../../app/utils/models/status";
import { useDeleteFinancial } from "../../../service/mutations";
import { INCOME } from "../../../utils/constants/financialType";
import { settingsQueryNames } from "../../../utils/constants/settingsQueryNames";
import { ExpenseModel, FinancialModalPropsModel, IncomeModel } from "../../../utils/models/financialModel";

import styles from "./settingsFinancialTable.module.scss";

type Props = {
  type: string;
  data?: ExpenseModel[] | IncomeModel[];
  isLoading: boolean;
  setModalProps: React.Dispatch<React.SetStateAction<FinancialModalPropsModel | undefined>>;
};

const SettingsFinancialTable: React.FC<Props> = ({ type, data, isLoading, setModalProps }) => {
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const title = type === INCOME ? `${t("Xarajat.Daromad")}` : `${t("Xarajat.Xarajat")}`;

  const deleteFinancial = useDeleteFinancial();

  // delete admin
  const onDelete = (id: number) => deleteFinancial.mutateAsync(id);

  // open modal
  const onOpenModal = (record: IncomeModel) => () => {
    setModalProps({
      type,
      title,
      open: true,
      oneFinancial: record,
      status: record.status
    });
  };

  // open modal to create
  const openCreateModal = (title: string, type: string) => {
    setModalProps({
      open: true,
      title,
      type,
      status: Status.ACTIVE
    });

    qc.invalidateQueries([settingsQueryNames.FINANCIAL_CATEGORY, type]).then();
    qc.invalidateQueries([settingsQueryNames.ACTIVITY_TYPE, type]).then();
  };
  const menu = (record: IncomeModel): MenuProps => ({
    items: [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            <span>{t("settings.Tahrirlash")}</span>
          </div>
        ),
        onClick: onOpenModal(record)
      },
      {
        key: "2",
        label: (
          <ModalConfirm onOk={() => onDelete(record.id!)}>
            <div className="flex items-center gap-2">
              <DeleteIcon />
              <span>{t("settings.O'chirish")}</span>
            </div>
          </ModalConfirm>
        )
      }
    ]
  });

  // table column
  const columns: ColumnsType<IncomeModel | ExpenseModel> = [
    {
      title: `${t("Xarajat.Turkumi")}`,
      render: (record: IncomeModel) => record.financial_category?.name[i18n.language],
      width: "16%"
    },
    {
      title: `${t("Xarajat.Faoliyat turi")}`,
      render: (record: IncomeModel) => record.activity_type?.name[i18n.language],
      width: "15%"
    },
    {
      title: `${t("products.Nomi")}`,
      dataIndex: "name",
      width: "20%"
    },
    {
      title: `${t("Xodimlar.Izoh")}`,
      dataIndex: "description"
    },
    {
      title: `${t("settings.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            status__active: value === Status.ACTIVE,
            status__passive: value === Status.PASSIVE
          })}
        >
          {StatusNames[value]}
        </span>
      )
    },
    {
      title: "",
      render: (record: IncomeModel) => (
        <Dropdown trigger={["click"]} menu={menu(record)}>
          <div className="flex cursor-pointer items-center justify-end gap-2">
            <UnreadMessageDots />
          </div>
        </Dropdown>
      ),
      width: "10%"
    }
  ];

  const tableFooter = () => (
    <div className={styles.footer}>
      <Button type="text" className={styles.add_btn} onClick={() => openCreateModal(title, type)}>
        <PlusCircleIcon fill={true} /> {title}
      </Button>
    </div>
  );

  return (
    <Row gutter={32} className={styles.financial_table}>
      <Col span={6} className={styles.left}>
        <h4>{title}</h4>
        <p>{`${t("Xarajat.Bu yerda")} ${title.toLocaleLowerCase()} ${t("Xarajat.qo'shishingiz mumkin")}`}</p>
      </Col>
      <Col span={18} className={styles.right}>
        <Table<IncomeModel>
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading || deleteFinancial.isLoading,
            indicator: LoadingIndicator
          }}
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey={e => e.id!}
          footer={tableFooter}
          className={cx("footer_table")}
        />
      </Col>
    </Row>
  );
};

export default SettingsFinancialTable;
