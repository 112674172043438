import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Popover, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../../../app/utils/helpers/sliceText";
import { ProductSelectModel } from "../../../../../../../app/utils/models/productSelectModel";
import { TimeoutModel } from "../../../../../../../app/utils/models/TimeoutModel";
import { useGetProductsSelect } from "../../../../../../service/queries";
import { OfferPartyErrorModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  unique_id: string;
  isViewCompanyPerson?: boolean;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const { Option } = Select;

const OfferPartyProductSelect: React.FC<Props> = ({ unique_id, isViewCompanyPerson }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [time, setTime] = useState<TimeoutModel>();
  const { data, isLoading, fetchNextPage } = useGetProductsSelect(searchValue);

  const { setCreateProductModal } = supplyActions;

  const { selectProducts, leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearchValue(e);
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: "#E62E05",
            color: "#E62E05"
          }}
        >
          --
        </div>
      </div>
      <div className={styles.product__placeholder}>Resurs nomi</div>
    </div>
  );

  const pagesData = () => {
    let newPagesData: ProductSelectModel[] = [];

    data?.pages?.forEach(item => {
      newPagesData = [...newPagesData, ...(item?.data ?? [])];
    });

    return newPagesData;
  };

  const productsData = () => {
    const newData: ProductSelectModel[] = [];

    [...(pagesData() ?? []), ...(selectProducts ?? [])]?.forEach(item => {
      if (!newData?.some(value => value?.id === item.id)) {
        newData.push(item);
      }
    });

    return newData;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const optionValue = option as DefaultOptionType;

    dispatch(
      setOfferPartyLefItemData({
        key: "product_id",
        value: e,
        uniqueId: unique_id
      })
    );

    dispatch(
      setOfferPartyLefItemData({
        key: "unit_id",
        value: optionValue?.props?.unit_id as number,
        uniqueId: unique_id
      })
    );

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "product",
          uniqueId: unique_id,
          action: !e
        })
      );
      dispatch(
        setOfferPartyModalErrorItem({
          key: "unit",
          uniqueId: unique_id,
          action: !optionValue?.props?.unit_id
        })
      );
    }
  };

  const productValue = () => leftData?.find(item => item.unique_id === unique_id)?.product_id;
  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, unique_id) ? styles.item__error : "";

  const onAddProduct = () => {
    dispatch(
      setCreateProductModal({
        index: unique_id,
        name: searchValue,
        visible: true
      })
    );
  };

  return (
    <div className={`${styles.item} ${errorClassName("product")}`}>
      <Select
        disabled
        showSearch
        suffixIcon={null}
        onSearch={onSearch}
        value={productValue()}
        className="product_select"
        onChange={onChangeProduct}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        notFoundContent={
          <Spin spinning={isLoading && !!searchValue} indicator={LoadingIndicator}>
            <SelectNotContent title="Mahsulot" action={onAddProduct} />
          </Spin>
        }
      >
        {productsData().map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language],
              unit_id: item?.unit?.id
            }}
          >
            <div className={styles.product}>
              <div className="resource">
                <div
                  style={{
                    borderColor: item?.resource?.color ?? "#E62E05",
                    color: item?.resource?.color ?? "#E62E05"
                  }}
                >
                  {item.resource?.symbol[i18n.language]}
                </div>
              </div>
              <div className={styles.product__name}>
                {item.name[i18n.language]?.length > (isViewCompanyPerson ? 12 : 16) ? (
                  <Popover title={item.name[i18n.language]} zIndex={9999999}>
                    {sliceText(item.name[i18n.language], isViewCompanyPerson ? 12 : 16)}
                  </Popover>
                ) : (
                  sliceText(item.name[i18n.language], isViewCompanyPerson ? 12 : 16)
                )}
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfferPartyProductSelect;
