import React from "react";
import { Form, FormInstance, Select } from "antd";

import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "../../../../../../../warehouse/service/query";
import { useTranslation } from "react-i18next";

type Props = {
  index: number;
  form: FormInstance;
};

const { Item } = Form;
const { Option } = Select;

const OrderWarehouseSelect: React.FC<Props> = ({ index, form }) => {
  const { data: warehouses } = useGetWarehouseSelect(false, true);
  const { t } = useTranslation();

  return (
    <Item name={[index, "warehouse_id"]} rules={formRules()}>
      <Select
        showSearch
        suffixIcon={null}
        placeholder={t("supply.Tanlang")}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        notFoundContent={<TableEmpty description={t("supply.Siz qidirgan omborxona mavjud emas")} />}
      >
        {warehouses?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name
            }}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default OrderWarehouseSelect;
