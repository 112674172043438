import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { ActiveCompanyPersonView, dashboardQueryKeys } from "..";

interface Query {
  activeCompanyPersonView: PaginationType<ActiveCompanyPersonView[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id: number;
  company_person_id: number;
  page: number;
}

export function useActiveCompanyPersonView({ enabled = false, id, company_person_id, page }: Params) {
  const initialValue: Query = { activeCompanyPersonView: { data: [], total: 0, current_page: 1 } };

  const { generateSearchParam, queries } = useQueryParams();
  const searchParams = generateSearchParam({
    page,
    id,
    company_person_id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.COMPANY_WAREHOUSE_DEFECT_COUNT, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/active-company-person/view?${searchParams}`);

      return { activeCompanyPersonView: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
