import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Popover, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../../../app/utils/helpers/sliceText";
import { ProductSelectModel } from "../../../../../../../app/utils/models/productSelectModel";
import { TimeoutModel } from "../../../../../../../app/utils/models/TimeoutModel";
import { useGetProductsSelect } from "../../../../../../service/queries";

import styles from "../partyPersonGroup.module.scss";

const { Option } = Select;
const { Item, useWatch } = Form;

type Props = {
  form: FormInstance;
  index: number;
  groupIndex: number;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const PersonProductSelect: React.FC<Props> = ({ form, index, groupIndex }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setCreateProductModal } = supplyActions;

  const searchSelect = useWatch("select_search", form);
  const formProductSelect = useWatch("select_products", form) as ProductSelectModel[];
  const status = useWatch("status", form) as StatusEnums;

  const [time, setTime] = useState<TimeoutModel>();
  const { data, isLoading, fetchNextPage } = useGetProductsSelect(searchSelect);
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        form.setFieldsValue({
          select_search: e
        });
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: "#E62E05",
            color: "#E62E05"
          }}
        >
          --
        </div>
      </div>
      <div className={styles.product__placeholder}>Resurs nomi</div>
    </div>
  );

  const pagesData = () => {
    let newPagesData: ProductSelectModel[] = [];

    data?.pages?.forEach(item => {
      newPagesData = [...newPagesData, ...(item?.data ?? [])];
    });

    return newPagesData;
  };

  const productsData = () => {
    const newData: ProductSelectModel[] = [];

    [...(pagesData() ?? []), ...(formProductSelect ?? [])]?.forEach(item => {
      if (!newData?.some(value => value?.id === item.id)) {
        newData.push(item);
      }
    });

    return newData;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const optionValue = option as DefaultOptionType;

    form.setFieldValue(["person_group", groupIndex, "data", index, "unit_id"], optionValue?.props?.unit_id);
  };

  const onAddProduct = () => {
    dispatch(
      setCreateProductModal({
        visible: true,
        index,
        name: searchSelect,
        fieldName: ["person_group", groupIndex, "data", index]
      })
    );
  };

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  // const selectedId = () => form.getFieldValue(["person_group", groupIndex, "data", index, "product_id"]);
  //
  // const selected = productsData().find(item => selectedId() === item.id);

  return (
    <>
      {/* <PerfItem */}
      {/*  placeholder={ */}
      {/*    <div className="flex items-center p-[0.62rem]"> */}
      {/*      <div className={styles.product}> */}
      {/*        <div className="resource"> */}
      {/*          <div */}
      {/*            style={{ */}
      {/*              borderColor: selected?.resource?.color ?? "#E62E05", */}
      {/*              color: selected?.resource?.color ?? "#E62E05" */}
      {/*            }} */}
      {/*          > */}
      {/*            {selected?.resource?.symbol[i18n.language]} */}
      {/*          </div> */}
      {/*        </div> */}
      {/*        <div className={styles.product__name}>{sliceText(selected?.name[i18n.language] || "", 18)}</div> */}
      {/*      </div> */}
      {/*    </div> */}
      {/*  } */}
      {/*  visible={!(isView && disabled())} */}
      {/* > */}
      <Item name={["person_group", groupIndex, "data", index, "product_id"]} rules={formRules()}>
        <Select
          showSearch
          suffixIcon={null}
          onSearch={onSearch}
          className="product_select"
          onChange={onChangeProduct}
          popupMatchSelectWidth={false}
          onPopupScroll={onPopupScroll}
          disabled={isView && disabled()}
          placeholder={productPlaceholder}
          filterOption={selectFilterOption}
          notFoundContent={
            <Spin spinning={isLoading && searchSelect} indicator={LoadingIndicator}>
              <SelectNotContent title="Mahsulot" action={onAddProduct} />
            </Spin>
          }
        >
          {productsData().map(item => (
            <Option
              key={item.id}
              value={item.id}
              props={{
                name: item?.name[i18n.language],
                unit_id: item?.unit?.id
              }}
            >
              <div className={styles.product}>
                <div className="resource">
                  <div
                    style={{
                      borderColor: item?.resource?.color ?? "#E62E05",
                      color: item?.resource?.color ?? "#E62E05"
                    }}
                  >
                    {item.resource?.symbol[i18n.language]}
                  </div>
                </div>
                <div className={styles.product__name}>
                  {item.name[i18n.language]?.length > 18 ? (
                    <Popover title={item.name[i18n.language]} zIndex={9999999}>
                      {sliceText(item.name[i18n.language], 18)}
                    </Popover>
                  ) : (
                    sliceText(item.name[i18n.language], 18)
                  )}
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </Item>
      {/* </PerfItem> */}
    </>
  );
};

export default PersonProductSelect;
