import React, { useMemo } from "react";
import { DatePicker, Form, FormInstance, Select } from "antd";
import dayjs from "dayjs";
import CalendarPlusIcon from "features/app/assets/icons/CalendarPlusIcon";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "features/app/components/project-select/project-select";
import { colors } from "features/app/utils/constants/colors";
import { formRules } from "features/app/utils/constants/formRules";
import ArrowDownRightIcon from "features/payment/assets/icons/ArrowDownRightIcon";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "store";
import { warehouseTransferActions } from "store/reducers/warehouseTransferReducer";

import { dayjsFormats } from "modules/common";

import styles from "../transferMakingDrawer.module.scss";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance<TransferBodyModel>;
  setPage: (page: number) => void;
};

const Header: React.FC<Props> = ({ form, setPage }) => {
  const { t } = useTranslation();

  const { visible, id } = useSelector(state => state.transferMakingReducer);
  const { data: ownWarehouses } = useGetWarehouseSelect(true, visible);
  const senderWarehouseId = useWatch("sender_warehouse_id", form);
  const receiverWarehouseId = useWatch("recieved_warehouse_id", form);
  const { data: warehouses } = useGetWarehouseSelect(false, visible);
  const { setSelectProducts } = warehouseTransferActions;
  const dispatch = useDispatch();
  const onClear = () => {
    form.setFieldsValue({ products: [] });
    setPage(1);
  };

  const onSelectChange = (e: number) => {
    if (e) {
      form.setFieldsValue({ products: [] });
      dispatch(setSelectProducts([]));
      setPage(1);
    }
  };

  return (
    <div className={styles.header_container}>
      <Item
        name="sender_warehouse_id"
        label="Omborxonadan"
        rules={[...formRules()]}
        rootClassName={styles.select}
        shouldUpdate
      >
        <Select
          allowClear
          placeholder="Tanlang"
          suffixIcon={<SelectSuffixIcon />}
          onClear={onClear}
          onChange={onSelectChange}
          disabled={Boolean(id)}
          filterOption={selectFilterOption}
          showSearch
          notFoundContent={
            <SelectNotContent
              title={t("warehouse.Jo'natuvchi omborxona")}
              description={t("warehouse.Jo'natuvchi omborxonalar topilmadi")}
            />
          }
        >
          {ownWarehouses?.map(item => (
            <Option
              key={item?.id}
              value={item?.id}
              props={{
                name: item?.name
              }}
            >
              {item?.name}
            </Option>
          ))}
        </Select>
      </Item>
      <div className={styles.to_warehouse_container}>
        <ArrowDownRightIcon fill={colors.WHITE} rotate="45" />
      </div>
      <Item name="recieved_warehouse_id" label="Omborxonaga" rootClassName={styles.select} rules={[...formRules()]}>
        <Select
          allowClear
          placeholder="Tanlang"
          suffixIcon={<SelectSuffixIcon />}
          filterOption={selectFilterOption}
          showSearch
          notFoundContent={
            <SelectNotContent
              title={t("warehouse.Qabul qiluvchi omborxona")}
              description={t("warehouse.Qabul qiluvchi omborxonalar topilmadi")}
            />
          }
        >
          {warehouses?.map(item => (
            <Option
              key={item?.id}
              value={item?.id}
              props={{
                name: item?.name
              }}
            >
              {item?.name}
            </Option>
          ))}
        </Select>
      </Item>
      <ProjectSelect
        allowClear
        label="Loyiha uchun"
        name="recieved_project_id"
        rootClassName={styles.select}
        suffixIcon={<SelectSuffixIcon />}
      />
      <Item name="date" rootClassName={styles.datePicker}>
        <DatePicker
          format={dayjsFormats.DATE}
          suffixIcon={<CalendarPlusIcon />}
          placeholder={t("warehouse.Sana")}
          defaultValue={!id ? dayjs(dayjs(), dayjsFormats.DATE) : form.getFieldValue("date")}
        />
      </Item>
    </div>
  );
};

export default Header;
