import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import FolderIcon from "../../../../../app/assets/icons/FolderIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { useCreateInventoryFolder } from "../../../../service/mutations";
import { EditFolderName } from "../edit-folder-name/EditFolderName";

import styles from "./createFolder.module.scss";

type Props = {
  afterSuccess: () => void;
};

const CreateFolder = ({ afterSuccess }: Props) => {
  const {t} = useTranslation()
  const createProjectFolder = useCreateInventoryFolder();

  const onFinish = (name: string) => {
    return createProjectFolder
      .mutateAsync({ name, color: "#1E90FF" })
      .then(afterSuccess);
  };

  return (
    <Spin indicator={LoadingIndicator} spinning={createProjectFolder.isLoading}>
      <div className={styles.card}>
        <div className={styles.card__left}>
          <FolderIcon />
        </div>
        <div className={styles.card__right}>
          <EditFolderName
            onFinish={onFinish}
            className={styles.name}
          ></EditFolderName>
          <p>0 {t("Inventar.inventarlar")}</p>
        </div>
      </div>
    </Spin>
  );
};

export default CreateFolder;
