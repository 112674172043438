import React, { useEffect, useRef, useState } from "react";
import { ColumnsType } from "antd/es/table";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useExportMonitoringPartsExcel } from "features/impost/service/mutations";
import { useTranslation } from "react-i18next";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { supplyActions } from "../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../app/components/pagination/Pagination";
import { PageKeys } from "../../../app/components/statuses/statusEnums";
import Statuses from "../../../app/components/statuses/Statuses";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import TableSettings from "../../../app/components/table-settings/TableSettings";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../app/service/queries";
import { ImpostStatusIcons } from "../../../app/utils/constants/importStatusIcons";
import { RU } from "../../../app/utils/constants/languages";
import { tableConfigKeys } from "../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../app/utils/helpers/cx";
import { filterColumns } from "../../../app/utils/helpers/filterColumns";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import { useGetPartImpost, useGetPartsImpost } from "../../service/queries";
import { impostTableDefaultData } from "../../utils/constants/impostTableDefaultData";
import { PartImpostModel } from "../../utils/models/partImpostModel";

import ActionDropdown from "./action-dropdown/ActionDropdown";
import FilterDrawerParts from "./filter-drawer/FilterDrawerParts";

import styles from "./parts.module.scss";

const Parts = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setPartyModal } = supplyActions;
  const { visible, tableData: data } = useAppSelector(state => state.supplyReducer.partyModal);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.IMPOST_ORDER_CONFIG, true);
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.IMPOST_ORDER_CONFIG, impostTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const { data: orders, isLoading: isLoadingOrders } = useGetPartsImpost();

  const exportExcelMutate = useExportMonitoringPartsExcel();

  useGetPartImpost(visible, data?.id);

  const onDetailPart = (record: PartImpostModel) => ({
    onClick: () => {
      dispatch(
        setPartyModal({
          visible: true,
          isView: true,
          party_id: record?.id,
          tableData: record,
          selectProducts: record?.warehouse_products as never
        })
      );
    }
  });

  const columns: ColumnsType<PartImpostModel> = [
    {
      title: `${t("Monitoring.Partya")}`,
      render: (record: PartImpostModel) => <span className={styles.bold}>P-{record?.id}</span>,
      fixed: "left",
      width: 100,
      className: "monitoring-part"
    },
    {
      title: `${t("Monitoring.Vositachi")}`,
      render: (record: PartImpostModel) => <div className={styles.person}>{record?.agent?.full_name ?? "-"}</div>,
      key: "agent",
      width: 250,
      className: "agent"
    },
    {
      title: `${t("Monitoring.Buyurtma sanasi")}`,
      dataIndex: "ordered_date",
      key: "new_ordered_date",
      width: 170,
      className: "ordered_date"
    },
    {
      title: `${t("Monitoring.Resurs mavjudligi")}`,
      dataIndex: "resource_availability",
      render: percent => (
        <div
          className={cx("flex cursor-pointer items-center justify-center rounded-2xl px-3 py-1", {
            "bg-error-50 text-error-500": percent >= 0 && percent <= 30,
            "bg-warning-50 text-warning-500": percent > 30 && percent <= 70,
            "bg-success-50 text-success-500": percent > 70
          })}
        >
          {percent} %
        </div>
      ),
      key: "resource_availability",
      width: 110,
      className: "resource_availability"
    },
    {
      title: `${t("Monitoring.Yetkazilish sanasi")}`,
      dataIndex: "delivery_date",
      key: "delivery_date",
      width: 170,
      className: "delivery_date"
    },
    {
      title: `${t("Monitoring.To'lov sanasi")}`,
      dataIndex: "payment_date",
      key: "payment_date",
      width: 170,
      className: "payment_date"
    },
    {
      title: `${t("Monitoring.Umumiy summa")}`,
      render: (record: PartImpostModel) => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      ),
      key: "total_amount",
      width: 200,
      className: "total_amount"
    },
    {
      title: `${t("Monitoring.Holati")}`,
      render: (record: PartImpostModel) => <Statuses statusKey={record?.status} pageKey={PageKeys.PARTY} />,
      key: "status",
      width: 170,
      className: "monitoring-status"
    },
    {
      title: `${t("Xodimlar.Xodim")}`,
      render: (record: PartImpostModel) => (
        <div className={styles.person}>
          <CustomAvatar image={record?.creator?.image} name={record?.creator?.full_name} />
        </div>
      ),
      key: "creator",
      width: 100,
      className: "monitoring-creator"
    },
    {
      title: `${t("Monitoring.Tasdiqlash")}`,
      render: (record: PartImpostModel) => (
        <div className={styles.status}>{ImpostStatusIcons[record?.confirmation_payment]}</div>
      ),
      width: 120,
      key: "confirmation_payment",
      align: "center",
      className: "monitoring-confirmation_payment"
    }
  ];

  const tableTitle = () => (
    <div className={styles.title}>
      <div className={styles.search}>
        <PaymentSearch />
      </div>
      <FilterDrawerParts exportExcelMutate={exportExcelMutate} />
    </div>
  );

  const rowKey = (record: PartImpostModel) => record.id;

  const [renderColumns, setColumns] = useState<ColumnsType<PartImpostModel>>([]);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.IMPOST_ORDER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PartImpostModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={impostTableDefaultData}
              locations={[CustomFieldLocationEnum.ORDER]}
              configKey={tableConfigKeys.IMPOST_ORDER_CONFIG}
            />
          ),

          render: (record: PartImpostModel) => <ActionDropdown record={record} />,
          className: "monitoring-parts-actions",
          fixed: "right",
          width: 75
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  const footer = () => (
    <Pagination
      paginationProps={{
        total: orders?.total,
        pageSize: tableConfigData?.size || 10
      }}
    />
  );

  return (
    <div className={styles.parties}>
      <div className={styles.top}>
        <Table<PartImpostModel>
          rowKey={rowKey}
          title={tableTitle}
          onChangeColumns={onChangeColumns}
          pagination={false}
          onRow={onDetailPart}
          dataSource={orders?.data}
          scroll={{ y: "calc(100vh - 18.125rem)" }}
          columns={renderColumns as ColumnsType<PartImpostModel>}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{ spinning: isLoadingOrders, indicator: LoadingIndicator }}
          footer={footer}
        />
      </div>
    </div>
  );
};

export default Parts;
