import React, { ReactNode, useMemo } from "react";
import { Collapse, CollapseProps, Form, FormInstance, Spin } from "antd";

import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { useGetCurrencies } from "../../../../../settings/service/queries";
import { OneOrderModel } from "../../../../utils/models/oneOrderModel";

import LeftTable from "./LeftTable";

import styles from "../../../../../supply/components/parts/party-modal/left/person-group/partyPersonGroup.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  form: FormInstance;
  isLoading: boolean;
  data: OneOrderModel[] | undefined;
  popoverContent: (quantity: number, receivedQuantity: number) => ReactNode;
};

interface IPersonGroup {
  id?: number;
  name: string;
  data?: OneOrderModel[] | undefined;
}

const { useWatch } = Form;

const LeftGroup: React.FC<Props> = ({ form, data, isLoading, popoverContent }) => {
  const personKeys = useWatch("personKeys", form) as string[];
  const { t } = useTranslation();

  const { data: currencies } = useGetCurrencies();

  const items: CollapseProps["items"] = useMemo(() => {
    const personGroup: IPersonGroup[] = [];

    const notPersonsData = data?.filter(e => !e.company_person);

    if (!isEmptyArr(notPersonsData)) {
      personGroup.push({
        id: 0,
        name: t("orderModal.Belgilanmagan"),
        data: notPersonsData
      });
    }

    data?.forEach(item => {
      if (item?.company_person) {
        const isSomePerson = personGroup?.find(person => person.id === item.company_person?.id);

        if (!isSomePerson) {
          personGroup.push({
            id: item.company_person?.id,
            name: item?.company_person?.name,
            data: data?.filter(e => e.company_person?.id === item.company_person?.id)
          });
        }
      }
    });

    form.setFieldValue(
      "personKeys",
      personGroup?.map(item => item?.id)
    );

    return personGroup?.map(item => ({
      key: `${item?.id}`,
      label: (
        <div className={styles.label}>
          <div className={`${styles.label__left} ${item.id === 0 && styles.label__not_data}`}>{item.name}</div>
          <div className={styles.total}>
            <span>Jami: </span>
            <div>{item?.data?.length || 0} ta</div>
          </div>
        </div>
      ),
      children: <LeftTable form={form} data={item?.data} isLoading={isLoading} popoverContent={popoverContent} />,
      defaultOpen: true
    }));
  }, [form, data, currencies?.data, isLoading, popoverContent]);

  const onChangeCollapse = (e: string[] | string) => {
    if (Array.isArray(e)) {
      form.setFieldValue("personKeys", e);
    }
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.group}>
        <Collapse
          items={items}
          activeKey={personKeys}
          onChange={onChangeCollapse}
          expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
        />
      </div>
    </Spin>
  );
};

export default LeftGroup;
