import React from "react";

const GantIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_20428_43767)">
      <path
        d="M2.25 2.09998V21.6C2.25 21.6828 2.31716 21.75 2.4 21.75H21.9"
        stroke="#1E90FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M21.418 2.84985L16.0452 8.22264C15.7809 8.48694 15.3623 8.51629 15.0637 8.29144L11.2678 5.43307C10.9601 5.20137 10.5269 5.24042 10.2656 5.52342L5.70083 10.4672"
        stroke="#1E90FF"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M10.8008 9.44995L10.8008 19.35" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M20.6992 10.35L20.6992 19.35" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M15.75 12.9L15.75 19.35" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
      <path d="M5.84961 14.4L5.84961 19.35" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
    </g>
    <defs>
      <clipPath id="clip0_20428_43767">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default GantIcon;
