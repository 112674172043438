import classNames from "classnames";
import DotsVerticalIcon from "../../assets/icons/DotsVerticalIcon";

import styles from "./unreadMessage.module.scss";

type Props = {
  count?: number;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
};

const UnreadMessageDots = ({ count, onClick, className }: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.unread_message_dots, className)}
    >
      <DotsVerticalIcon />
      {count! > 0 && <div className={styles.unread_dots} />}
    </div>
  );
};

export default UnreadMessageDots;
