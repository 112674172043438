import React, { useEffect, useState } from "react";
import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { FormInstance } from "antd";

import { ColumnConfigModel } from "../../../utils/models/user/userTableConfigModel";

import DndItem from "./DndItem";

type Props = {
  form: FormInstance;
  fieldName: "top" | "bottom";
  allData: ColumnConfigModel[];
};

const ConfigDnd: React.FC<Props> = ({ form, allData, fieldName }) => {
  const [defaultColumns, setDefaultColumns] = useState<ColumnConfigModel[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1
      }
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDefaultColumns(oldColumns => {
        const activeIndex = oldColumns.findIndex(i => i.id === active.id);
        const overIndex = oldColumns.findIndex(i => i.id === over?.id);
        const newColumns = arrayMove(oldColumns, activeIndex, overIndex);
        const oldFormColumnTop = form.getFieldValue(["column", fieldName]);
        const formColumnTopKeys = Object.keys(oldFormColumnTop);

        form.setFieldValue(
          ["column", fieldName],
          newColumns?.reduce((acc, cur, currentIndex) => {
            const isSomeKey = formColumnTopKeys?.some(key => key === cur.name);

            if (isSomeKey) {
              return {
                ...acc,
                [cur.name]: {
                  check: oldFormColumnTop[cur.name]?.check,
                  place: currentIndex
                }
              };
            }
            return acc;
          }, {})
        );

        return newColumns;
      });
    }
  };

  useEffect(() => {
    setDefaultColumns(allData);
  }, [allData]);

  const sortableItems = () => defaultColumns.map(i => i.id);

  return (
    <DndContext sensors={sensors} onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]}>
      <SortableContext items={sortableItems()} strategy={verticalListSortingStrategy}>
        {defaultColumns.map(item => (
          <DndItem item={item} fieldName={fieldName} key={item.id} />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default ConfigDnd;
