import React, { useMemo, useState } from "react";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import SettingsIcon from "features/app/assets/icons/SettingsIcon";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CalendarFilterDrawer } from "features/payment/components/top/filter-drawer/calendar/CalendarFilterDrawer";
import WorkDrawer from "features/payment/components/top/filter-drawer/work/WorkDrawer";
import SettingDropdown from "features/payment/components/top/setting-dropdown/SettingDropdown";
import { usePaymentOrderExportExcel } from "features/payment/service/mutation";
import { PaymentTabKeys } from "features/payment/utils/constants/paymentTabKeys";
import FilterDrawerParts from "features/supply/components/parts/filter-drawer/FilterDrawerParts";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { companyPersonActions } from "store/reducers/companyPersonReducer";

import FileCheckIcon from "../../../../payment/assets/icons/FileCheckIcon";
import PaymentFilterDrawer from "../../../../payment/components/top/filter-drawer/payment/PaymentFilterDrawer";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";

import styles from "./counterpartsExtraContent.module.scss";

const CounterpartsExtraContent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { queries } = useQueryParams();
  const { statistics_type } = queries();
  const { setVisibleAct } = companyPersonActions;

  const [openSetting, setOpenSetting] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const paymentOrderExportExcel = usePaymentOrderExportExcel();

  const onOpenAct = () => {
    dispatch(
      setVisibleAct({
        visible: true
      })
    );
    setOpenDropdown(false);
  };

  const actionItems = () =>
    [
      {
        key: "1",
        label: (
          <div
            className={styles.dropdown_item}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onOpenAct();
            }}
          >
            <FileCheckIcon width={20} stroke="#667085" /> {t("Counterparts.AKT SVERKA")}
          </div>
        )
      },
      {
        key: "2",
        label: (
          <SettingDropdown setOpen={setOpenSetting} open={openSetting}>
            <div
              className={styles.dropdown_item}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <SettingsIcon size={20} /> {t("Counterparts.Sozlamalar")}
            </div>
          </SettingDropdown>
        )
      }
    ] as ItemType[];

  const onOpenChangeDropdown = (open: boolean) => {
    if (!openSetting) {
      setOpenDropdown(open);
    }
  };

  const filterContent = useMemo(() => {
    const allFilterContent = {
      [PaymentTabKeys.CALENDAR]: <CalendarFilterDrawer />,
      [PaymentTabKeys.INCOME_EXPENSE]: <PaymentFilterDrawer />,
      [PaymentTabKeys.ORDER]: (
        <FilterDrawerParts
          exportExcelMutate={paymentOrderExportExcel}
          tableKey={tableConfigKeys.PAYMENT_ORDER_CONFIG}
          customFieldEnum={CustomFieldLocationEnum.ORDER}
        />
      ),
      [PaymentTabKeys.WORK]: <WorkDrawer />
    };

    return statistics_type ? (
      allFilterContent[statistics_type as keyof typeof allFilterContent]
    ) : (
      <PaymentFilterDrawer />
    );
  }, [paymentOrderExportExcel, statistics_type]);

  return (
    <div className={styles.extra_content}>
      <div className={styles.extra_content__right}>
        <PaymentSearch />
        {filterContent}
        <Dropdown
          menu={{
            items: actionItems()
          }}
          trigger={["click"]}
          open={openDropdown}
          mouseLeaveDelay={0.2}
          mouseEnterDelay={1000}
          onOpenChange={onOpenChangeDropdown}
        >
          <Button>
            <DotsVerticalIcon />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export default CounterpartsExtraContent;
