import { Dropdown } from "antd";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";

import PopoverShortName from "../../../../../../../../../../app/components/popover-short-name/PopoverShortName";
import { dayjsFormats } from "../../../../../../../../../../app/utils/constants/dayjsFormats";
import { useGetDetailedProject } from "../../../../../../../../../service/queries";
import { statusOptions } from "../../../../../../../../../utils/constants/statusOptions";
import { taskStatus } from "../../../utils/constants/taskStatus";
import { GanttSectionData } from "../../../utils/models/GanttSection";

import { getFirstLetter } from "../../../../../../../../../../app/utils/helpers/getFirstLetter";
import { projectStatuses } from "../../../../../../../../../utils/enums/projectStatuses";
import styles from "./ganttTableFooter.module.scss";
import { useTranslation } from "react-i18next";

interface IProps {
  data: GanttSectionData | undefined;
}

const GanttTableFooter = ({ data }: IProps) => {
  const { t } = useTranslation()
  const { projectId } = useParams();
  const { data: project } = useGetDetailedProject(Number(projectId));
  const formatDate = "DD MMM YYYY";

  const factDay = dayjs(data?.prediction_date, dayjsFormats.DATE).diff(
    dayjs(data?.due_date, dayjsFormats.DATE),
    "day"
  );

  return (
    <div className={styles.container}>
      <div className={`${styles.item} ${styles.item_name}`}>{t("project.Umumiy loyiha")}</div>
      <div className={`${styles.item} ${styles.item_percent}`}>
        {data?.done_percentage}
      </div>
      <div className={`${styles.item} ${styles.item_confirmation}`}>
        {data?.foreman?.image ? (
          <img src={data?.foreman?.image} className={styles.img} />
        ) : (
          <span className={styles.item_confirmation_img}>
            {data && getFirstLetter(data?.foreman?.full_name)}
          </span>
        )}
        <PopoverShortName title={data?.foreman?.full_name} length={10} />
      </div>
      <div className={`${styles.item} ${styles.item_status}`}>
        <div
          className={`${styles.status} ${
            styles[project?.status as projectStatuses]
          }`}
        >
          {statusOptions.filter((item) => item.value === project?.status)[0]?.icon}
          {t(`project.${statusOptions.filter((item) => item.value === project?.status)[0]?.label}`)}
        </div>
      </div>
      <div className={`${styles.item} ${styles.item_startDate}`}>
        {data?.start_date
          ? dayjs(data?.start_date, dayjsFormats.DATE).format(formatDate)
          : "--"}
      </div>
      <Dropdown
        dropdownRender={() => (
          <div className={styles.faktDropdown}>
            <p>
              {t("project.Reja")}{" "}
              <span>
                {data?.due_date
                  ? dayjs(data?.due_date, dayjsFormats.DATE).format(formatDate)
                  : "--"}
              </span>
            </p>
            <p>
              {t("project.Farq")}{" "}
              {factDay > 0 ? (
                <span style={{ color: "#F04438" }}>
                  +{!isNaN(factDay) ? <>{factDay} kun</> : "--"}
                </span>
              ) : (
                <span style={{ color: "#12B76A" }}>
                  {!isNaN(factDay) ? <>{factDay} kun</> : "--"}
                </span>
              )}
            </p>
            <p>
              {t("project.Fakt")}{" "}
              <span>
                {data?.prediction_date
                  ? dayjs(data?.prediction_date, dayjsFormats.DATE).format(
                      formatDate
                    )
                  : "--"}
              </span>
            </p>
          </div>
        )}
      >
        <div className={`${styles.item} ${styles.item_dueDate}`}>
          {data?.due_date
            ? dayjs(data?.due_date, dayjsFormats.DATE).format(formatDate)
            : "--"}
        </div>
      </Dropdown>
    </div>
  );
};

export default GanttTableFooter;
