import React from "react";

const PencilIcon: React.FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_807_26764)">
        <path
          d="M11.9997 1.3335L14.6663 4.00016M1.33301 14.6668L2.18394 11.5467C2.23946 11.3432 2.26722 11.2414 2.30983 11.1465C2.34766 11.0622 2.39416 10.9821 2.44855 10.9074C2.50982 10.8234 2.58441 10.7488 2.73361 10.5996L9.62255 3.71062C9.75456 3.57861 9.82056 3.51261 9.89667 3.48788C9.96362 3.46613 10.0357 3.46613 10.1027 3.48788C10.1788 3.51261 10.2448 3.57861 10.3768 3.71062L12.2892 5.62304C12.4212 5.75505 12.4872 5.82105 12.512 5.89716C12.5337 5.9641 12.5337 6.03622 12.512 6.10317C12.4872 6.17928 12.4212 6.24528 12.2892 6.37729L5.40028 13.2662C5.25108 13.4154 5.17648 13.49 5.0924 13.5513C5.01774 13.6057 4.93762 13.6522 4.85335 13.69C4.75844 13.7326 4.65666 13.7604 4.4531 13.8159L1.33301 14.6668Z"
          stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_807_26764">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;