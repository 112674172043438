import React, { useLayoutEffect, useState } from "react";
import { Spin, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../../supply/assets/icons/ChevronDownIcon";
import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { selectFilterOption } from "../../../utils/helpers/selectFilterOption";
import {
  ProjectSelectBasePropsModel,
  ProjectSelectModel,
  ProjectSelectStateModel
} from "../../../utils/models/projectSelectModel";
import { LoadingIndicator } from "../../loading-indicator/LoadingIndicator";
import SelectNotContent from "../../select-not-content/SelectNotContent";

import styles from "../project-select.module.scss";
import {cx} from "modules/common";

type Props = Partial<ProjectSelectBasePropsModel> &
  ProjectSelectStateModel & {
    data: ProjectSelectModel[] | undefined;
    isLoading: boolean;
    isCustomLabel: boolean;
  };

const State: React.FC<Props> = ({
  data,
  value,
  disabled,
  onChange,
  isLoading,
  placeholder,
  isNotValue = false,
  allowClear = true,
  suffixIcon = <SelectSuffixIcon />,
  isCustomLabel
}) => {
  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState(value);

  const onBasicChange = (e: number) => {
    onChange(e);

    if (!isNotValue) {
      setLocalValue(e);
    }
  };

  useLayoutEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <TreeSelect
      showSearch
      multiple={false}
      disabled={disabled}
      suffixIcon={suffixIcon}
      allowClear={allowClear}
      popupMatchSelectWidth={false}
      rootClassName={cx(styles.tree_select,styles.state_tree_select)}
      onChange={onChange || onBasicChange}
      value={isNotValue ? undefined : localValue}
      filterTreeNode={selectFilterOption as never}
      placeholder={placeholder || t("app.Tanlang")}
      // @ts-ignore
      treeData={data?.map(project => ({
        value: project?.id,
        label: isCustomLabel ? (
          <>
            <span>{project?.name}</span>
            <span>{t("app.Loyiha")}</span>
          </>
        ) : (
          project?.name
        ),
        props: {
          name: project?.name
        },
        children: project?.projects?.map(child => ({
          value: child?.id,
          label: isCustomLabel ? (
            <>
              <span>{child?.name}</span>
              <span>{t("app.Loyiha")}</span>
            </>
          ) : (
            child?.name
          ),
          props: {
            name: child?.name
          }
        }))
      }))}
      notFoundContent={
        isLoading ? (
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            <SelectNotContent />
          </Spin>
        ) : (
          <SelectNotContent />
        )
      }
    />
  );
};

export default State;
