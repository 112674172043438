import React from "react";

const CoinsStackedIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      {...props}
      width={props?.width || "32"}
      height={props?.height || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0003 22.6667C16.0003 26.3486 18.9851 29.3333 22.667 29.3333C26.3489 29.3333 29.3337 26.3486 29.3337 22.6667C29.3337 18.9848 26.3489 16 22.667 16C18.9851 16 16.0003 18.9848 16.0003 22.6667ZM16.0003 22.6667C16.0003 21.1656 16.4964 19.7803 17.3337 18.666V6.66667M16.0003 22.6667C16.0003 23.7671 16.267 24.8053 16.7392 25.7202C15.6159 26.669 13.0215 27.3333 10.0003 27.3333C5.95024 27.3333 2.66699 26.1394 2.66699 24.6667V6.66667M17.3337 6.66667C17.3337 8.13943 14.0504 9.33333 10.0003 9.33333C5.95024 9.33333 2.66699 8.13943 2.66699 6.66667M17.3337 6.66667C17.3337 5.19391 14.0504 4 10.0003 4C5.95024 4 2.66699 5.19391 2.66699 6.66667M2.66699 18.6667C2.66699 20.1394 5.95024 21.3333 10.0003 21.3333C12.919 21.3333 15.4394 20.7133 16.6198 19.8157M17.3337 12.6667C17.3337 14.1394 14.0504 15.3333 10.0003 15.3333C5.95024 15.3333 2.66699 14.1394 2.66699 12.6667"
        stroke={props?.stroke || "#1E90FF"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CoinsStackedIcon;
