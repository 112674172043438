import React from "react";
import { Form, FormInstance, FormListOperation, Input } from "antd";
import { Rule } from "antd/es/form";

import BuildingIcon from "../../../../../../app/assets/icons/BuildingIcon";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../../app/utils/constants/languages";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { CurrencySelectModel } from "../../../../../../app/utils/models/currencySelectModel";
import { TransferBodyModel } from "../../../../../utils/models/transferBodyModel";

import styles from "./project-card.module.scss";

type Props = {
  index: number;
  name: string[];
  operation: FormListOperation;
  formInstance: FormInstance<TransferBodyModel>;
};

const { Item, useWatch } = Form;

const ProjectCard: React.FC<Props> = ({ index, formInstance, operation, name }) => {
  const currency = JSON.parse(useWatch("currency", formInstance) || "{}") as CurrencySelectModel;

  const record = useWatch([...name, index], formInstance) as TransferBodyModel["income_payment"]["projects"][number];

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const parseValue = parseLocaledString(value || "0");
        const projects = formInstance.getFieldValue(name) as TransferBodyModel["expense_payment"]["projects"];
        const totalAmount = parseLocaledString(formInstance.getFieldValue(["expense_payment", "amount"]) || "0");

        if (totalAmount) {
          const totalProjectAmount =
            projects?.reduce(
              (first, second) =>
                parseLocaledString(String(second?.amount) || "0") + parseLocaledString(String(first) || "0"),
              0
            ) - parseValue;

          const diffAmount = totalAmount - totalProjectAmount;

          if (parseValue > diffAmount) {
            return Promise.reject(
              `Kiritilgan miqdor ${diffAmount?.toLocaleString(RU)} ${currency?.symbol} dan oshmasligi zarur`
            );
          }

          return Promise.resolve();
        }

        return Promise.reject();
      }
    }
  ];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue([...name, index, "amount"], localeFormatter(e?.target?.value));
  };

  const onRemoveItem = () => {
    operation.remove(index);
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <BuildingIcon />
        <div className={styles.text}>
          <h3>{record?.name}</h3>
        </div>
      </div>
      <div className={styles.right}>
        <Item name={[index, "id"]} className="hidden" />
        <Item name={[index, "amount"]} rules={amountRule}>
          <Input placeholder="0" onChange={onChangeAmount} suffix={currency?.symbol} />
        </Item>
        <div className={styles.close} onClick={onRemoveItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
