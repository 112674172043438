import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Popover, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../../../app/utils/helpers/sliceText";
import { ProductSelectModel } from "../../../../../../../app/utils/models/productSelectModel";
import { TimeoutModel } from "../../../../../../../app/utils/models/TimeoutModel";
import { useGetProductsSelect } from "../../../../../../service/queries";

import styles from "../../orderModalLeft.module.scss";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  index: number;
  form: FormInstance;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const OrderProductSelect: React.FC<Props> = ({ index, form }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setCreateProductModal } = supplyActions;
  const { isEdit } = useAppSelector(state => state.supplyReducer.orderModal);

  const [time, setTime] = useState<TimeoutModel>();
  const searchSelect = useWatch("select_search", form);
  const formProductSelect = useWatch("select_products", form) as ProductSelectModel[];

  const { data, isLoading, fetchNextPage } = useGetProductsSelect(searchSelect);

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        form.setFieldsValue({
          select_search: e
        });
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className={cx("resource")}>
        <div
          style={{
            borderColor: "#E62E05",
            color: "#E62E05"
          }}
        >
          --
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("supply.Resurs nomi")}</div>
    </div>
  );

  const pagesData = () => {
    let newPagesData: ProductSelectModel[] = [];

    data?.pages?.forEach(item => {
      newPagesData = [...newPagesData, ...(item?.data ?? [])];
    });

    return newPagesData;
  };

  const productsData = () => {
    const newData: ProductSelectModel[] = [];

    [...(pagesData() ?? []), ...(formProductSelect ?? [])]?.forEach(item => {
      if (!newData?.some(value => value?.id === item?.id)) {
        newData.push(item);
      }
    });

    return newData;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 1) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (_: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const optionValue = option as DefaultOptionType;

    form.setFieldValue(["task_products", index, "unit_id"], optionValue?.props?.unit_id);
  };

  const onAddProduct = () => {
    dispatch(
      setCreateProductModal({
        index,
        visible: true,
        name: searchSelect,
        fieldName: ["task_products", index]
      })
    );
  };

  return (
    <Item name={[index, "product_id"]} rules={formRules()}>
      <Select
        showSearch
        suffixIcon={null}
        disabled={isEdit}
        onSearch={onSearch}
        onChange={onChangeProduct}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        className={cx("product_select")}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        notFoundContent={
          <Spin spinning={isLoading && searchSelect} indicator={LoadingIndicator}>
            <SelectNotContent title={t("supply.Mahsulot")} action={onAddProduct} />
          </Spin>
        }
      >
        {productsData()?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language],
              unit_id: item?.unit?.id
            }}
          >
            <div className={styles.product}>
              <div className={cx("resource")}>
                <div
                  style={{
                    borderColor: item?.resource?.color ?? "#E62E05",
                    color: item?.resource?.color ?? "#E62E05"
                  }}
                >
                  {item.resource?.symbol[i18n.language]}
                </div>
              </div>
              <div className={styles.product__name}>
                {item.name[i18n.language]?.length > 20 ? (
                  <Popover title={item.name[i18n.language]} zIndex={9999999}>
                    {sliceText(item.name[i18n.language], 20)}
                  </Popover>
                ) : (
                  sliceText(item.name[i18n.language], 20)
                )}
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default OrderProductSelect;
