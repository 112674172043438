import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, Form, MenuProps, Modal } from "antd";
import CloseIcon from "features/app/assets/icons/CloseIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import CreateProductModal from "features/supply/components/create-product-modal/CreateProductModal";
import { uid } from "uid";

import { useAppSelector } from "../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../store/reducers/companyPersonReducer";
import Pagination from "../../../../app/components/pagination/Pagination";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { useCreateOffer } from "../../../service/mutation";
import { useGetCompanyPersonOfferView } from "../../../service/queries";
import { IProductList } from "../../../utils/models/productListModel";
import ProductListImportModal from "../product-list-import-modal/ProductListImportModal";

import Header from "./header/Header";
import ProductListTable from "./table/ProductListTable";

import styles from "./productListModal.module.scss";

const { Item, useWatch, useForm } = Form;

const ProductListModal: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const [form] = useForm();
  const { setProductListModalOpen } = companyPersonActions;
  const { visible, company_person_id, company_person_name } = useAppSelector(
    state => state.companyPersonReducer.productListModal
  );
  const baseCurrency = getBaseCurrency();
  const { data, isLoading } = useGetCompanyPersonOfferView(company_person_id);
  const products = useWatch(["products"], { ...form }) as IProductList[];
  const size = useWatch("size", form);

  const mutation = useCreateOffer(company_person_id!);

  const [importVisible, setImportVisible] = useState(false);

  const onOpenImportModal = () => {
    setImportVisible(true);
  };

  const onCancel = () => {
    dispatch(
      setProductListModalOpen({
        visible: false,
        id: undefined,
        company_person_name: ""
      })
    );
  };

  const onOk = () => {
    form.submit();
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      form.setFieldValue("products", []);
    }
  };

  const onFinish = () => {
    const filteredProducts = products
      .filter(product => typeof product.rowId === "string")
      .map(p => {
        const { code, resource_id, ...rest } = p;

        return rest;
      });
    const requestBody = {
      company_person_id: company_person_id!,
      products: filteredProducts?.map(product => ({
        ...product,
        amount: parseLocaledString(String(product.amount))
      }))
    };

    mutation.mutateAsync(requestBody).then(() => onCancel());
  };

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      form.setFieldsValue({
        size: 1,
        products: data?.data?.map(item => ({
          rowId: item?.id,
          unit_id: item?.unit?.id,
          product_id: item?.product?.id,
          currency_id: item?.currency?.id,
          amount: localeFormatter(String(item?.amount) || "0"),
          offers: item?.offers
        })),
        selectedProducts: data?.data?.map(item => ({
          id: item.product?.id,
          name: item?.product?.name,
          resource: item.product?.resource,
          price: 0,
          status: "",
          unit: item.unit
        }))
      });
    }
  }, [data, form]);

  const onAddProduct = () => {
    if (products?.length > 0) {
      form.setFieldValue("products", [
        ...products,
        {
          rowId: uid(),
          currency_id: baseCurrency?.id
        }
      ]);
    } else {
      form.setFieldValue("products", [
        {
          rowId: uid(),
          currency_id: baseCurrency?.id
        }
      ]);
    }
  };

  const onDelete = (rowId: string | number) => {
    form.setFieldValue(
      "products",
      products?.filter(product => product?.rowId !== rowId)
    );
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          className="flex items-center justify-center gap-1.5"
          onClick={e => {
            e.stopPropagation(); //
            onOpenImportModal();
          }}
        >
          <DownloadIcon />
          {t("Counterparts.Import")}
        </div>
      )
    }
  ];

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      okText={t("Counterparts.Saqlash")}
      onCancel={onCancel}
      closeIcon={false}
      cancelText={t("Counterparts.Yopish")}
      afterOpenChange={onAfterOpen}
      title={
        <div className="flex items-center justify-between">
          <span>{company_person_name} {t("Counterparts.resurslari")}</span>
          <div className="flex items-center gap-4">
            <Dropdown trigger={["click"]} menu={{ items: items as MenuProps["items"] }}>
              <Button onClick={e => e.stopPropagation()}>
                <DotsVerticalIcon />
              </Button>
            </Dropdown>
            <CloseIcon onClick={onCancel} />
          </div>
        </div>
      }
      wrapClassName={styles.product_list_modal_wrapper}
      okButtonProps={{
        loading: mutation.isLoading
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <div className={styles.body}>
          <div className={styles.header}>
            <Header amountOfProduct={data?.data?.length} />
            <Item className="d_n" name="size" />
            <Item name="products" shouldUpdate className="d_n" />
            <Item className="d_n" name="company_person_id" />
            <Item className="d_n" name="select_search" />
            <Item className="d_n" name="selectedProducts" />
          </div>
          <div className={styles.content}>
            {visible && (
              <Item shouldUpdate>
                {field => (
                  <ProductListTable
                    onDelete={onDelete}
                    field={field}
                    form={form}
                    isLoading={isLoading}
                    onAddProduct={onAddProduct}
                  />
                )}
              </Item>
            )}
            <Pagination
              paginationProps={{
                total: data?.total,
                current: size,
                onChange: e => {
                  form.setFieldValue("size", e);
                }
              }}
            />
          </div>
        </div>
      </Form>
      <CreateProductModal afterFunc={() => {}} />
      <ProductListImportModal
        title="Kontragent resurslari"
        defaultLink="Kontragent%20resurs%20import%20shablon.xlsx"
        form={form}
        visible={importVisible}
        setVisibleModal={setImportVisible}
      />
    </Modal>
  );
};

export default ProductListModal;
