import React from "react";
import { useDispatch } from "react-redux";
import { FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import CloseIcon from "../../../../../../app/assets/icons/CloseIcon";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import ProjectSelect from "../../../../../../app/components/project-select/project-select";
import TooltipShortName from "../../../../../../app/components/tooltip-short-name/TooltipShortName";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import LayoutIcon from "../../../../../assets/icons/LayoutIcon";

import styles from "../thirdStep.module.scss";

type Props = {
  form: FormInstance;
};

const ProjectItem: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setEstimateDrawer, setIncomeExpenseTask } = paymentReducerActions;
  const { task, type, defaultData } = useAppSelector(state => state.paymentReducer.income_expense_modal);

  const onOpenEstimate = (id?: number) => () => {
    dispatch(
      setEstimateDrawer({
        visible: true,
        projectId: id
      })
    );
  };

  const onClearTask = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(setIncomeExpenseTask(undefined));
  };

  return (
    <div className={styles.project_item}>
      <ProjectSelect
        mode="form"
        form={form}
        name="project_id"
        label={t("payment.Loyiha")}
        suffixIcon={<SelectSuffixIcon />}
        isTask={type === PaymentTypeEnum.EXPENSE}
        placeholder={defaultData?.id ? t("payment.Tanlanmagan") : t("payment.Loyiha")}
      />
      <ConditionalRender if={task}>
        <div onClick={onOpenEstimate(task?.projectId)} className={styles.task}>
          <div className={styles.task__left}>
            <LayoutIcon />
            <div className={styles.task__name}>
              <h4>
                <span>{task?.index}. </span>
                <TooltipShortName title={task?.sectionName} length={30} />
              </h4>
              <TooltipShortName title={task?.name} length={38} />
            </div>
          </div>
          <div className={styles.task__right} onClick={onClearTask}>
            <CloseIcon />
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default ProjectItem;
