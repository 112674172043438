import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";

type InitialState = {
  visible: boolean;
  id?: number;
  data?: Omit<TransferBodyModel, "products"> & { products: ITransferringResourcesModel[] };
};

const initialState: InitialState = {
  visible: false
};

const transferMakingReducer = createSlice({
  name: "transferMaking",
  initialState,
  reducers: {
    setDrawerVisible: (
      state,
      action: PayloadAction<{
        visible: boolean;
        id?: number;
        data?: Omit<TransferBodyModel, "products"> & { products: ITransferringResourcesModel[] };
      }>
    ) => {
      state.visible = action.payload.visible;
      state.id = action.payload.id;
      state.data = action.payload.data;
    }
  }
});

export default transferMakingReducer.reducer;
export const transferMakingReducerActions = transferMakingReducer.actions;
