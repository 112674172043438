import React from "react";
import { Navigate, Outlet, To } from "react-router-dom";

export interface ProtectedProps {
  layout?: React.FC<{ children: React.ReactNode }>;
  allow: boolean;
  to: To;
}

const Protected: React.FC<ProtectedProps> = ({ to, allow, layout: Layout = React.Fragment }) => {
  if (!allow) return <Navigate to={to} />;

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default Protected;
