import React, { useMemo } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { dayjsFormats, isEmptyArr, RU } from "modules/common";
import { GanttLine as GanttLineType, useProductAnalyze } from "modules/dashboard";

import ConditionalRender from "../../features/app/components/conditional-render/ConditionalRender";
import { dailySelectItems } from "../../features/payment/utils/constants/calendarItems";
import { useAppSelector } from "../../hooks/redux";
import { numberToDateFormat } from "../../modules/dashboard/helpers/number-to-date-format";
import GanttLine from "../../pages/dashboard/components/widgets/items/gantt/gantt-line";
import { LoadingIndicator } from "../loading-indicator";

import ProductSelect from "./product-select";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { unitId, productId } = useAppSelector(state => state.appReducer.productMarketPrice);

  const { products, isLoading } = useProductAnalyze({ unitId, productId });

  const lastProduct = (type: "offer" | "income") => {
    const reverseData = [...(products?.slice()?.reverse() || [])];

    return reverseData?.find(item => (type === "income" ? item?.order_id : !item?.order_id));
  };

  const data = useMemo(
    (): GanttLineType => [
      ...((products || [])?.map(item => ({
        name: numberToDateFormat(item?.date),
        uv: item?.order_id ? item?.value : null,
        pv: !item?.order_id ? item?.value : null,
        item: { ...item }
      })) || [])
    ],
    [products]
  );

  const generateDate = (number?: number) => {
    if (number) {
      const date = numberToDateFormat(number, dayjsFormats.DATE);
      const diff = dayjs(date, dayjsFormats.DATE).diff(dayjs(), "day");

      const defaultDates: Record<number, string> = {
        [-1]: "1 kun oldingi holat bo'yicha",
        0: "Bugungi holat bo'yicha",
        1: "Ertangi holat bo'yicha",
        [-2]: "2 kun oldingi holat bo'yicha"
      };

      const [day, month] = date.split(".");
      const monthName = dailySelectItems.find(item => item.vale === month)?.name?.toLowerCase();

      return defaultDates[diff] || `${day}-${monthName} holati bo'yicha`;
    }

    return "";
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="mt-6 flex w-[1026px] flex-col gap-6">
        <ProductSelect />
        <div className="grid grid-cols-2 gap-4">
          <ConditionalRender if={lastProduct("offer")}>
            <div className="box-border flex justify-between gap-4 rounded-xl bg-primary-50 p-3">
              <div className="flex gap-4">
                <div className="h-full w-2 rounded-xl bg-primary-500" />
                <div className="flex flex-col gap-3">
                  <h4 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Bozor narxi")}</h4>
                  <h2 className="m-0 flex items-center gap-2 text-base font-medium text-gray-700">
                    {lastProduct("offer")?.amount?.toLocaleString(RU)}
                    <span className="text-sm font-semibold text-gray-400">
                      {lastProduct("offer")?.currency?.symbol}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-400">{generateDate(lastProduct("offer")?.date)}</div>
            </div>
          </ConditionalRender>
          <ConditionalRender if={lastProduct("income")}>
            <div className="box-border flex justify-between gap-4 rounded-xl bg-success-50 p-3">
              <div className="flex gap-4">
                <div className="h-full w-2 rounded-xl bg-success-500" />
                <div className="flex flex-col gap-3">
                  <h4 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Kirim narxi")}</h4>
                  <h2 className="m-0 flex items-center gap-2 text-base font-medium text-gray-700">
                    {lastProduct("income")?.amount?.toLocaleString(RU)}
                    <span className="text-sm font-semibold text-gray-400">
                      {lastProduct("income")?.currency?.symbol}
                    </span>
                  </h2>
                </div>
              </div>
              <div className="text-sm font-normal text-gray-400">{generateDate(lastProduct("income")?.date)}</div>
            </div>
          </ConditionalRender>
        </div>
        <div className="h-[26.5rem] w-full">
          <GanttLine data={data} />
        </div>
        <ConditionalRender if={!isEmptyArr(products)}>
          <div className="ml-6 flex items-center gap-6">
            <div className="flex items-center gap-2">
              <div className="h-5 w-5 rounded-full bg-success-500" />
              <span className="text-sm font-medium text-gray-600">Sizning kirimlaringiz</span>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-5 w-5 rounded-full bg-primary-500" />
              <span className="text-sm font-medium text-gray-600">Takliflar</span>
            </div>
          </div>
        </ConditionalRender>
      </div>
    </Spin>
  );
};

export default Index;
