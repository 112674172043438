import React from "react";

const DragIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg {...props} width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="1.5" r="1.5" fill="#D0D5DD" />
    <circle cx="6.5" cy="1.5" r="1.5" fill="#D0D5DD" />
    <circle cx="1.5" cy="6.5" r="1.5" fill="#D0D5DD" />
    <circle cx="6.5" cy="6.5" r="1.5" fill="#D0D5DD" />
    <circle cx="1.5" cy="11.5" r="1.5" fill="#D0D5DD" />
    <circle cx="6.5" cy="11.5" r="1.5" fill="#D0D5DD" />
    <circle cx="1.5" cy="16.5" r="1.5" fill="#D0D5DD" />
    <circle cx="6.5" cy="16.5" r="1.5" fill="#D0D5DD" />
  </svg>
);

export default DragIcon;
