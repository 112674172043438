import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import $api from "../../../features/app/utils/helpers/axiosInstance";
import { IdAndName } from "../../common";
import { useQueryParams } from "hooks/useQueryParams";

type Query = {
  select: IdAndName[];
};

type Params = Pick<UseQueryOptions, "enabled"> & {
  endPoint: string;
  queryKey: string;
  params?: Record<string, string | number>;
};

export function useDynamicSettingsSelect({ enabled = false, queryKey, endPoint, params }: Params) {
  const initialData: Query = {
    select: []
  };
  let url = endPoint;
  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam(params || {});

  if (searchParams) {
    url = `${endPoint}?${searchParams}`;
  }

  const { data = initialData, ...arg } = useQuery<Query>(
    [queryKey, searchParams],
    async () => {
      const res = await $api.get(url);

      return { select: res.data?.data };
    },
    { enabled, staleTime: Infinity, cacheTime: Infinity }
  );

  return { ...data, ...arg };
}
