import React from "react";
import { Form, Input } from "antd";
// import { formRules } from "../../../utils/constants/formRules";

const { Item } = Form;

export interface Props {
  className: string;
  inputClassName: string;
}

const BankAccountNumberItem: React.FC<Props> = ({ className, inputClassName }) => {
  return (
    <div className={className}>
      <Item
        name="bank_name"
        label="Bank nomi"
        // rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
      <Item
        name="account_number"
        label="Hisob raqami"
        // rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
    </div>
  );
};

export default BankAccountNumberItem;
