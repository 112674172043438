import React, { useEffect, useState } from "react";
import { Button, Drawer, Form } from "antd";
import dayjs from "dayjs";
import { FileListType } from "features/app/utils/models/fileListModel";
import CreateProductModal from "features/supply/components/create-product-modal/CreateProductModal";
import { useCreateLaboratory } from "features/warehouse/service/mutation";
import { useGetLaboratoryById } from "features/warehouse/service/query";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";

import { laboratoryDrawerActions, useDispatch, useSelector } from "store";

import { dayjsFormats } from "modules/common";

import ActionDropDown from "./action-dropown/ActionDropDown";
import Left from "./left/Left";
import Right from "./right/Right";

import styles from "./laboratoryDrawer.module.scss";

const { useForm, useWatch } = Form;

const LaboratoryDrawer: React.FC = () => {
  const [form] = useForm<LaboratoryDrawerFormModel>();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setOpenDrawer } = laboratoryDrawerActions;
  const { visible, id, isUpdating, isViewing, record } = useSelector(state => state.laboratoryDrawerReducer);
  const [fileList, setFileList] = useState<FileListType[]>([]);
  const createLaboratory = useCreateLaboratory();
  const { data: laboratoryById, isLoading } = useGetLaboratoryById(id!);
  const products = useWatch("products", form) as LaboratoryDrawerProducts[];

  const onClose = () => {
    dispatch(
      setOpenDrawer({
        visible: false,
        id: null,
        isUpdating: false,
        isViewing: false
      })
    );
    form.resetFields();
    setFileList([]);
  };

  const onFinish = (values: LaboratoryDrawerFormModel) => {
    const reqBody = {
      ...values,
      products: values?.products?.map(item => {
        const { total_quantity, rowId, amount, unit, product, ...rest } = item;

        return {
          ...rest,
          id: Number(String(rest?.id)?.split("-")[0])
        };
      })
    };

    const filterReqBody = Object.entries(reqBody)
      .filter(([key, value]) => value)
      .reduce((result: Record<string, any>, [key, value]) => {
        result[key] = value;
        return result;
      }, {});

    createLaboratory.mutateAsync(filterReqBody as never).then(() => onClose());
  };

  const onSave = () => {
    form.submit();
  };

  const footer = (
    <div className="flex items-center justify-end gap-3">
      <Button onClick={onClose}>{t("warehouse.Yopish")}</Button>
      {!isViewing && (
        <Button
          loading={createLaboratory.isLoading}
          disabled={createLaboratory.isLoading || !products || (products && products?.length === 0)}
          type="primary"
          onClick={onSave}
        >
          {t("warehouse.Saqlash")}
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    if (visible && id) {
      form.setFieldsValue({
        warehouse_id: laboratoryById?.laboratory?.warehouse?.id,
        project_id: laboratoryById?.laboratory?.project?.id,
        date: dayjs(laboratoryById?.laboratory?.date, dayjsFormats.DATE) as never,
        description: laboratoryById?.laboratory?.description,
        products: laboratoryById?.products?.map(item => ({
          expense_unit_id: item?.expense_unit?.id,
          amount: Number.isInteger(item?.income_quantity / item?.expense_quantity)
            ? item?.income_quantity / item?.expense_quantity
            : Number(
                (item?.income_quantity / item?.expense_quantity).toFixed(
                  item?.income_quantity / item?.expense_quantity > 1 ? 2 : 4
                )
              ),
          expense_quantity: item?.expense_quantity,
          total_quantity: item?.total_quantity,
          id: `${item?.product?.id}-${item?.expense_unit?.id}` as never,
          income_quantity: item?.income_quantity,
          income_unit_id: item?.income_unit?.id,
          rowId: item?.product?.id,
          unit_symbol: item?.expense_unit?.symbol[i18n.language],
          product: item?.product,
          unit: item?.expense_unit
        }))
      });
    }
  }, [visible, laboratoryById]);

  return (
    <Drawer
      rootClassName={styles.drawer}
      footer={footer}
      title={
        <span className="flex items-center justify-between">
          {isViewing ? `${t("warehouse.Laboratoriya")} - ${id}` : t("warehouse.Laboratoriya")}
          {isViewing && <ActionDropDown record={record!} />}
        </span>
      }
      open={visible}
      onClose={onClose}
    >
      <Form className="flex h-full" form={form} onFinish={onFinish} layout="vertical">
        <Left form={form} isLoading={isLoading} />
        <Right form={form} fileList={fileList} setFileList={setFileList} />
      </Form>
      <CreateProductModal afterFunc={() => {}} />
    </Drawer>
  );
};

export default LaboratoryDrawer;
