import React from "react";

type Props = {
  color?: string | null;
};

const FolderIcon: React.FC<Props> = ({ color }) => {
  return (
    <svg
      width="62"
      height="48"
      viewBox="0 0 62 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.882812 3.59557V44.347C0.882812 46.0888 2.29476 47.5007 4.03649 47.5007H57.9644C59.7062 47.5007 61.1181 46.0888 61.1181 44.347V10.0269C61.1181 8.28522 59.7062 6.87327 57.9644 6.87327H29.6596C28.4336 6.87327 27.3231 6.15769 26.6818 5.11275C25.5131 3.20864 23.4057 0.441895 21.0269 0.441895H4.03156C2.28983 0.441895 0.882812 1.85384 0.882812 3.59557Z"
        fill={color ?? "#53B1FD"}
      />
      <rect
        x="0.882812"
        y="6.82373"
        width="60.2353"
        height="40.7059"
        rx="2.996"
        fill={color ?? "#53B1FD"}
      />
    </svg>
  );
};

export default FolderIcon;
