export const settingsEndPoints = {
  ADMINS: "admin/view",
  ADMIN_DELETE: "admin/delete",
  ADMIN_CREATE: "admin/create",
  ADMIN_UPDATE: "admin/update",
  ROLES: "role/view",
  ROLES_SELECT: "role/select",
  ROLE_DELETE: "role/delete",
  ROLE_CREATE: "role/create",
  ROLE_UPDATE: "role/update",
  FINANCIAL: "financial/view",
  FINANCIAL_SELECT: "financial/select",
  FINANCIAL_CATEGORY: "financial-category/select",
  ACTIVITY_TYPE: "activity-type/select",
  FINANCIAL_CREATE: "financial/create",
  FINANCIAL_UPDATE: "financial/update",
  FINANCIAL_DELETE: "financial/delete",
  CURRENCIES: "company-currency/view",
  CURRENCIES_LIST: "company-currency/list",
  CURRENCIES_CREATE: "company-currency/create",
  CURRENCIES_UPDATE: "company-currency/update",
  CURRENCIES_DELETE: "company-currency/delete",
  CURRENCIES_AUTO_REFRESH: "company-currency/auto-refresh",
  CURRENCIES_CHANGE_BASIC: "company-currency/change-basic",
  PAYMENT_TYPES: "payment-type/view",
  PAYMENT_TYPES_CREATE: "payment-type/create",
  PAYMENT_TYPES_UPDATE: "payment-type/update",
  PAYMENT_TYPES_DELETE: "payment-type/delete",
  WAREHOUSES: "warehouse/view",
  WAREHOUSE_CREATE: "warehouse/create",
  WAREHOUSE_UPDATE: "warehouse/update",
  WAREHOUSE_DELETE: "warehouse/delete",
  WAREHOUSE_FOLDER: "warehouse-folder/view",
  WAREHOUSE_FOLDER_CREATE: "warehouse-folder/create",
  WAREHOUSE_FOLDERS_UPDATE: "warehouse-folder/update",
  WAREHOUSE_FOLDER_DELETE: "warehouse-folder/delete",
  PROJECT_TREE: "project/tree",
  CATEGORIES: "category/view",
  CATEGORY_CREATE: "category/create",
  CATEGORY_UPDATE: "category/update",
  CATEGORY_DELETE: "category/delete",
  SUB_CATEGORIES: "category/view/subcategory",
  SUB_CATEGORY_CREATE: "subcategory/create",
  SUB_CATEGORY_UPDATE: "subcategory/update",
  SUB_CATEGORY_DELETE: "subcategory/delete",
  CATEGORY_PRODUCTS: "category/view/product",
  SUB_CATEGORY_PRODUCTS: "subcategory/view",
  PRODUCT_DELETE: "product/delete",
  PRODUCT_CREATE: "product/create",
  PRODUCT_UPDATE: "product/update",
  CATEGORIES_DEV: "category-import/view",
  SUB_CATEGORIES_DEV: "category-import/view/subcategory",
  PRODUCTS_DEV: "category-import/view/product",
  PRODUCT_IMPORT_DEV: "category-import/import",
  PRODUCTS_SELECT: "product/select",
  CUSTOM_FIELDS_VIEW: "custom-field/view",
  CUSTOM_FIELD_CREATE: "custom-field/create",
  CUSTOM_FIELD_UPDATE: "custom-field/update",
  CUSTOM_FIELD_DELETE: "custom-field/delete",
  TEMPLATE_VIEW: "template/view",
  TEMPLATE_CREATE: "template/create",
  TEMPLATE_UPDATE: "template/update",
  TEMPLATE_DELETE: "template/delete",
  COMPANY_UNIT_CREATE: "company-unit/create",
  PRODUCT_OFFER_CREATE: "product/offer-create",
  CATALOG_PRODUCT_CREATE: "product/import"
};
