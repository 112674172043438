import React, { useEffect, useState } from "react";
import { Button, Form, Popover, Select } from "antd";
import { FormInstance } from "antd/lib";
import { useTranslation } from "react-i18next";

import ChevronDownIcon from "../../../supply/assets/icons/ChevronDownIcon";
import { useGetDistrictsSelect, useGetOpenCageData, useGetRegionsSelect } from "../../service/queries";
import { defaultMapCenter } from "../../utils/constants/deafultMapCenter";
import { addressValidator } from "../../utils/helpers/addressValidator";
import SelectNotContent from "../select-not-content/SelectNotContent";

import AddressMap from "./map/AddressMap";

import styles from "./address.module.scss";

const { Option } = Select;
const { useForm, Item, useWatch } = Form;

export interface IProps {
  children?: React.ReactNode;
  visible: boolean;
  setVisibleModal: (value: boolean) => void;
  formInstance: FormInstance<IFormValues>;
}

export interface IFormValues {
  district_id?: number;
  region_id?: number;
  address?: string;
  center?: {
    lat?: number;
    lng?: number;
  };
}

const AddressModal: React.FC<IProps> = ({ children, visible, setVisibleModal, formInstance }) => {
  const { i18n } = useTranslation();
  const [form] = useForm();
  const [selectedAddress, setSelectedAdress] = useState<string>("");
  const { data: mapData } = useGetOpenCageData(selectedAddress?.length > 0 ? `q=${selectedAddress}` : "");

  const regionId = useWatch("region_id", form);
  const districtId = useWatch("district_id", form);
  const comingLatitude = useWatch("latitude", formInstance);
  const comingLongitude = useWatch("longitude", formInstance);
  const comingDistrict = useWatch("district_id", formInstance);
  const comingRegionId = useWatch("region_id", formInstance);

  const { data: regions } = useGetRegionsSelect();
  const { data: districts } = useGetDistrictsSelect(regionId);

  const [center, setCenter] = useState<{
    lat: number;
    lng: number;
  }>(defaultMapCenter);

  const onCancel = () => {
    setVisibleModal(false);
    form.resetFields();
  };

  const onFinish = (values: IFormValues) => {
    const { district_id, region_id } = values;

    if (district_id || regionId) {
      const regionName = regions?.find(region => region.id === region_id)?.name[i18n.language];
      const districtName = districts?.find(district => district.id === district_id)?.name[i18n.language];

      const address = `${regionName ?? ""}${regionName && districtName ? " , " : ""}${districtName ?? ""}`;

      formInstance.setFieldValue("address", address);
      formInstance.setFieldValue("region_id", region_id!);
      formInstance.setFieldValue("district_id", district_id!);
    }
    if (center.lat && center.lng) {
      formInstance.setFieldValue("latitude", center.lat);
      formInstance.setFieldValue("longitude", center.lng);
    }

    setVisibleModal(false);
  };

  const onChangeRegion = (e: number) => {
    if (e) {
      form.setFieldsValue({ district_id: undefined });
    }
  };

  const onClearRegion = () => {
    form.setFieldsValue({ district_id: undefined });
  };

  const content = (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <div className={styles.form_content}>
        <div className={styles.top_container}>
          <div className={styles.region_and_city}>
            <Item name="center" className="hidden" />
            <Item label="Viloyat" name="region_id" className={styles.region}>
              <Select
                allowClear
                placeholder="Tanlash..."
                className={styles.select}
                onChange={onChangeRegion}
                notFoundContent={<SelectNotContent title="Viloyatlar" description="Viloyatlar mavjud emas!" />}
                suffixIcon={<ChevronDownIcon rotate={true} />}
                onClear={onClearRegion}
              >
                {regions?.map(region => (
                  <Option key={region.id} value={region.id}>
                    {region.name[i18n.language]}
                  </Option>
                ))}
              </Select>
            </Item>
            <Item
              name="district_id"
              className={styles.city}
              label="Tuman yoki shahar"
              rules={[
                {
                  validator: () => addressValidator(regionId!, districtId!),
                  required: true
                }
              ]}
              status="error"
            >
              <Select
                allowClear
                placeholder="Tanlash..."
                className={styles.select}
                notFoundContent={<SelectNotContent title="Tumanlar" description="Tuman yoki shaharlar mavjud emas!" />}
                suffixIcon={<ChevronDownIcon rotate={true} />}
              >
                {districts?.map(district => (
                  <Option key={district.id} value={district.id}>
                    {district.name[i18n.language]}
                  </Option>
                ))}
              </Select>
            </Item>
          </div>
        </div>
        <div className={styles.map_container}>
          <AddressMap center={center} setCenter={setCenter} />
        </div>
      </div>
      <div className={styles.footer_container}>
        <div className={styles.footer}>
          <Button type="default" onClick={onCancel}>
            Yopish
          </Button>
          <Button type="primary" htmlType="submit">
            Saqlash
          </Button>
        </div>
      </div>
    </Form>
  );
  const onAfterOpenChange = (visible: boolean) => {
    if (visible) {
      if (comingLatitude && comingLongitude) {
        setCenter({ lat: comingLatitude, lng: comingLongitude });
      }
      if (comingDistrict || comingRegionId) {
        form.setFieldsValue({
          district_id: comingDistrict,
          region_id: comingRegionId
        });
      }
    }
  };

  useEffect(() => {
    function myLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setCenter({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            });
          },
          () => {
            setCenter(defaultMapCenter);
          }
        );
      } else {
        setCenter(defaultMapCenter);
      }
    }
    return () => {
      myLocation();
    };
  }, []);

  useEffect(() => {
    if (regionId && districtId) {
      const regionName = regions?.find(region => region.id === regionId)?.name[i18n.language];
      const districtName = districts?.find(district => district.id === districtId)?.name[i18n.language];

      const address = `${regionName ?? ""}${regionName && districtName ? " , " : ""}${districtName ?? ""}`;

      setSelectedAdress(address);
    }
  }, [regionId, districtId, regions, districts, i18n]);

  useEffect(() => {
    if (mapData) {
      setCenter(mapData.results[0].geometry);
    }
  }, [mapData]);

  return (
    <Popover
      open={visible}
      trigger={["click"]}
      overlayClassName={styles.address_modal}
      content={content}
      onOpenChange={onCancel}
      afterOpenChange={onAfterOpenChange}
      placement="topRight"
      arrow={false}
    >
      {children}
    </Popover>
  );
};

export default AddressModal;
