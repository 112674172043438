import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";

import { useWidget } from "../hooks";
import { dashboardQueryKeys } from "../query-keys";
import { CompanyWidget, Widget } from "../schema";

export function useDeleteWidget() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { setWidgetDrawer } = useWidget().methods;

  return useMutation<SuccessRes<CompanyWidget>, ErrorRes, { id: number }>(
    async param => {
      const { data } = await $api.delete(`company-widget/delete?id=${param.id}`);

      return data;
    },
    {
      retry: false,
      onSuccess: async (res, variables) => {
        await qc.invalidateQueries([dashboardQueryKeys.COMPANY_WIDGETS_SELECT]);
        await qc.invalidateQueries([dashboardQueryKeys.WIDGET_SELECT]);

        qc.setQueryData([dashboardQueryKeys.COMPANY_WIDGETS], ({ companyWidgets }: any) => ({
          companyWidgets: (companyWidgets as Widget[]).filter(item => item.id !== variables.id)
        }));

        setWidgetDrawer({
          visible: false
        });

        message.success(res.message[i18n.language]);
      }
    }
  );
}
