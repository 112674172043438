import { BackendFetch, DevTools, I18nextPlugin, Tolgee, withTolgee } from "@tolgee/i18next";
import i18n from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

interface IProps {
  languages: string[];
  defaultLanguage: string;
  ns: string;
}

export const init = ({ languages, defaultLanguage, ns }: IProps) => {
  const tolgee = Tolgee()
    .use(DevTools())
    .use(BackendFetch())
    .use(I18nextPlugin())
    .init({
      apiUrl: import.meta.env.VITE_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_TOLGEE_API_KEY,
      projectId: import.meta.env.VITE_TOLGEE_PROJECT_ID,
      ns: [ns],
      availableLanguages: languages,
      defaultLanguage
    });

  withTolgee(i18n, tolgee)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
      detection: { order: ["localStorage", "sessionStorage", "navigator"] },
      supportedLngs: languages,
      ns,
      react: { useSuspense: false }
    });

  return i18n;
};
