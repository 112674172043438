import React from "react";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import ProductSpentWidget from "features/projects/components/project-report-modal/product-spent-widget/product-spent-widget";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { CompanyWidget, useWidget } from "modules/dashboard";

type Props = {
  widget: CompanyWidget;
};

const StreamGraph: React.FC<Props> = ({ widget }) => {
  const { id, key, name } = widget;
  const data = widget?.data as ProjectReportProductSpendModel;
  const baseCurrency = getBaseCurrency();
  const { setWidgetDrawer } = useWidget().methods;
  const { i18n } = useTranslation();
  const { append } = useQueryParams();

  const onOpenDrawer = (itemId: string) => {
    setWidgetDrawer({
      id,
      key,
      widget,
      title: name[i18n.language],
      props: data,
      visible: true
    });
    append(queryParamsKeys.TAB, itemId, true);
  };

  return (
    <div>
      <ProductSpentWidget
        data={data}
        isLoading={false}
        baseCurrency={baseCurrency}
        isNotInProjects={true}
        onOpenWidgetDrawer={onOpenDrawer}
      />
    </div>
  );
};

export default StreamGraph;
