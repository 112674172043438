import { MouseEvent, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import { useChangeTableConfig } from "features/app/service/mutation";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { Table } from "components";

import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { useGetWorkPayments } from "../../../../service/queries";
import { PaymentInventoryWorkModel } from "../../../../utils/models/paymentInventoryModel";
import CompanyPersonSourceElement from "../../income-expense-view/CompanyPersonSourceElement";
import InventoryWorkPaymentView from "../../inventory-work-payment-view/InventoryWorkPaymentView";

import styles from "./workPaymentTable.module.scss";
import useDebounce from "components/use-debounce/use-debounce";

const WorkPaymentTable = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const [visibleWorkPayment, setVisibleWorkPayment] = useState(false);
  const [workPaymentData, setWorkPaymentData] = useState<PaymentInventoryWorkModel>();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const { data: paymentsWork, isLoading } = useGetWorkPayments();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PAYMENT_WORKS, true);

  const onDetailWork = (record: PaymentInventoryWorkModel) => ({
    onClick: () => {
      setVisibleWorkPayment(true);
      setWorkPaymentData(record);
    }
  });

  const onVisiblePayment = (event: MouseEvent<HTMLDivElement>, record?: PaymentInventoryWorkModel) => {
    event.stopPropagation();

    dispatch(
      setPaymentInventoryWorkModal({
        visible: true,
        id: record?.id,
        type: PaymentTypeEnum.EXPENSE,
        debtAmount: record?.debt_amount,
        defaultCurrency: record?.currency,
        personId: record?.company_person?.id,
        amount: Number(record?.amount) * Number(record?.quantity),
        payment_type: record as never
      })
    );
  };

  const [columns, setColumns] = useState<ColumnsType<PaymentInventoryWorkModel>>([
    {
      title: `${t("payment.Ish nomi va loyiha")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.name}>
          {record?.task?.name.length > 35 ? (
            <Popover title={record?.task?.name} overlayClassName={styles.popover}>
              <p>
                {record?.place}. {record?.task?.name.substring(0, 35)}...
              </p>
            </Popover>
          ) : (
            <p>
              {record?.place}. {record?.task?.name}
            </p>
          )}

          <p>{record?.project?.name}</p>
        </div>
      ),
      className: "payment-work-name-and-project",
      width: 750
    },
    {
      title: `${t("payment.Ish tugallandi")}`,
      dataIndex: "completed_date",
      width: 200,
      className: "payment-work-completed-date"
    },
    {
      title: `${t("payment.Ishni bajardi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.paymentAction}>
          <CompanyPersonSourceElement
            isViewCompanyPersonBalance
            record={record?.company_person}
            name={record?.company_person?.name}
          />
        </div>
      ),
      width: 250,
      className: "payment-work-payment-action"
    },
    {
      title: `${t("payment.Ish hajmi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <span>
          {record?.quantity.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
        </span>
      ),
      width: 250,
      className: "payment-work-quantity"
    },
    {
      title: `${t("payment.Summasi")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <span>
          {record?.total_amount.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
      width: 350,
      className: "payment-work-total-amount"
    },
    {
      title: `${t("payment.Xodim")}`,
      render: (record: PaymentInventoryWorkModel) => (
        <div className={styles.creator}>
          <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
        </div>
      ),
      width: 200,
      className: "payment-work-creator"
    },
    {
      ...(paymentActions?.create
        ? {
            title: `${t("payment.To'lov holati")}`,
            render: (record: PaymentInventoryWorkModel) => (
              <span>
                {record?.payment_percent}%
                {!(record?.debt_amount === 0) && (
                  <div className={styles.action} onClick={event => onVisiblePayment(event, record)}>
                    <Button type="primary">{t("payment.To’lov qilish")}</Button>
                  </div>
                )}
              </span>
            ),
            align: "center",
            width: 300,
            className: "payment-work-payment-percent"
          }
        : {})
    }
  ]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key: tableConfigKeys.PAYMENT_WORKS,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentInventoryWorkModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.work_payment}>
      <div className={styles.top}>
        <Table<PaymentInventoryWorkModel>
          onChangeColumns={onChangeColumns}
          columns={columns}
          pagination={false}
          dataSource={paymentsWork?.data}
          rowKey={record => record.id}
          onRow={onDetailWork}
          rowClassName={() => styles.row}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: paymentsWork?.total
          }}
        />
      </div>
      <InventoryWorkPaymentView open={visibleWorkPayment} setOpen={setVisibleWorkPayment} record={workPaymentData} />
    </div>
  );
};

export default WorkPaymentTable;
