import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Drawer, Row, Spin } from "antd";
import classNames from "classnames";
import i18n from "i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import EmptyEstimate from "../../../../../../app/components/empty-estimate/EmptyEstimate";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { LocalStorage } from "../../../../../../app/service/LocalStorage";
import { colors } from "../../../../../../app/utils/constants/colors";
import { getBaseCurrency } from "../../../../../../app/utils/helpers/baseCurrency";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { EstimateGroupType } from "../../../../../../app/utils/models/estimateGroupType";
import EstimateGroup from "../../../../../../projects/components/detailed-project/estimate-group/EstimateGroup";
import { groupTypes } from "../../../../../../projects/utils/enums/groupTypes";
import { GroupSectionModel } from "../../../../../../projects/utils/models/sectionModel";
import { useGetPaymentSectionView } from "../../../../../service/queries";

import EstimateDrawerSection from "./EstimateDrawerSection";

import styles from "./estimateDrawer.module.scss";

const EstimateDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { setIncomeExpenseTask, setEstimateDrawer } = paymentReducerActions;
  const [checkedValue, setCheckedValue] = useState<{ id?: number; type?: groupTypes }>({});

  const baseCurrency = getBaseCurrency();
  const { visible, task: estimateTask, projectId } = useAppSelector(state => state.paymentReducer.estimateDrawer);

  const { data, isLoading } = useGetPaymentSectionView(checkedValue);

  const onCancel = () => {
    dispatch(
      setEstimateDrawer({
        projectId,
        visible: false,
        task: estimateTask
      })
    );
  };

  const onOk = () => {
    dispatch(setIncomeExpenseTask(estimateTask!));

    onCancel();
  };

  const footer = (
    <div className={styles.footer}>
      <Button onClick={onCancel}>Yopish</Button>
      <Button type="primary" onClick={onOk} disabled={!estimateTask?.id}>
        Qo'shish
      </Button>
    </div>
  );

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(
        setEstimateDrawer({
          visible: false,
          projectId: undefined,
          task: undefined
        })
      );

      setCheckedValue({});
    } else {
      const localEstimateGroup =
        LocalStorage.get<EstimateGroupType[]>("estimate_group")?.find(item => item?.projectId === String(projectId)) ||
        {};

      setCheckedValue({ ...localEstimateGroup });
    }
  };

  const emptyContent = (
    <div className="flex items-center justify-center">
      <EmptyEstimate />
    </div>
  );

  const generateContent = () => {
    if (checkedValue?.id) {
      const groupData = [...(data || [])] as unknown as GroupSectionModel[];

      return groupData?.map(item => (
        <div
          key={item?.id || 0}
          className="box-border flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-5"
        >
          <h3
            className="m-0 text-2xl font-medium"
            style={{
              color: item?.color || colors.GRAY_600
            }}
          >
            {item?.name[i18n.language]}
          </h3>
          {item?.sections?.map(section => (
            <ConditionalRender key={item?.id} if={!isEmptyArr(section?.tasks)}>
              <EstimateDrawerSection {...section} />
            </ConditionalRender>
          ))}
        </div>
      ));
    }

    return data?.map(item => (
      <ConditionalRender key={item?.id} if={!isEmptyArr(item?.tasks)}>
        <EstimateDrawerSection {...item} />
      </ConditionalRender>
    ));
  };

  return (
    <Drawer
      open={visible}
      footer={footer}
      onClose={onCancel}
      title="Ish biriktirish"
      afterOpenChange={onAfterOpen}
      rootClassName={styles.estimate}
    >
      <div className={styles.content}>
        <Row gutter={0} className={styles.header}>
          <Col span={15} className={styles.header__item}>
            Bo'limlar va ishlar
          </Col>
          <Col span={3} className={styles.header__item}>
            Hajmi
          </Col>
          <Col span={3} className={styles.header__item}>
            Birligi
          </Col>
          <Col span={3} className={styles.header__item}>
            Narxi ({baseCurrency?.symbol})
          </Col>
        </Row>
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <div
            className={classNames(styles.body, {
              [styles.body__empty]: isEmptyArr(data)
            })}
          >
            <ConditionalRender if={!isEmptyArr(data)} else={emptyContent}>
              <div className="flex items-center justify-end">
                <EstimateGroup
                  iconWidth={24}
                  projectId={projectId}
                  setValue={setCheckedValue}
                  initialValue={checkedValue}
                  className="box-border cursor-pointer rounded-lg border border-solid border-gray-200 bg-white px-3.5 py-2.5 text-base text-gray-600 duration-300 ease-in-out hover:border-primary-500"
                />
              </div>
              {generateContent()}
            </ConditionalRender>
          </div>
        </Spin>
      </div>
    </Drawer>
  );
};

export default EstimateDrawer;
