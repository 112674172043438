import { useParams } from "react-router-dom";
import { useGetDetailedProject } from "../../../../../../../../../service/queries";
import { GanttSectionData } from "../../../utils/models/GanttSection";
import GanttTableSection from "./GanttTableSection";

type Props = {
  activeAll: boolean;
  data?: GanttSectionData;
};

const GanttTableSections = ({ activeAll, data }: Props) => {
  const params = useParams();
  const projectId = Number(params?.projectId);
  const { data: project } = useGetDetailedProject(Number(projectId));

  return (
    <>
      {data?.sections
        ?.sort((a, b) => a.place - b.place)
        ?.map((item) => (
          <GanttTableSection
            key={item?.id}
            {...item}
            projectStatus={project?.status}
            elementType="section"
            sectionId={item?.id}
            activeAll={activeAll}
          />
        ))}
    </>
  );
};

export default GanttTableSections;
