import React from "react";
import { Form, FormInstance, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";

import { useAppSelector } from "../../../../../../hooks/redux";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { useGetCompanyCurrency } from "../../../../../inventory/service/queries";
import { IProductList } from "../../../../utils/models/productListModel";
import { useTranslation } from "react-i18next";

const { Item } = Form;
const { Option } = Select;

type Props = {
  index: number;
  rowId: number | string;
  form: FormInstance;
};

const ProductListCurrencySelect: React.FC<Props> = ({ index, form, rowId }) => {
  const { visible } = useAppSelector(state => state.companyPersonReducer.productListModal);
  const { data: currencies } = useGetCompanyCurrency(visible);
  const fieldName = ["products", index, "currency_id"];
  const { t } = useTranslation()

  const onChangeUnit = () => (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const unitOption = option as DefaultOptionType;
    const products = form.getFieldValue("products") as IProductList[];

    if (!unitOption?.props?.isPiece) {
      form.setFieldValue(
        "products",
        products?.map(item => {
          if (item?.rowId === rowId) return { ...item, currency_id: e };
          return item;
        })
      );
    }
  };

  return (
    <Item
      name={fieldName}
      rules={
        !(typeof rowId === "number")
          ? [
              {
                validator(rule, value, callback) {
                  if (typeof value === "number") {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                }
              }
            ]
          : []
      }
    >
      <Select
        showSearch
        suffixIcon={null}
        placeholder={t("Counterparts.Valyuta")}
        onChange={onChangeUnit}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        disabled={typeof rowId === "number"}
        notFoundContent={<TableEmpty description={t("Counterparts.Kerakli valyuta topilmadi")} />}
      >
        {currencies?.map(item => (
          <Option key={item.id} value={item.id}>
            {item.symbol}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductListCurrencySelect;
