import React from "react";
// import { Segmented } from "antd";
// import { SegmentedLabeledOption, SegmentedValue } from "antd/es/segmented";
// import { useDispatch } from "react-redux";

// import ViewTypeCardIcon from "../../../../../projects/assets/icons/ViewTypeCardIcon";
// import ViewTypeListIcon from "../../../../../projects/assets/icons/ViewTypeListIcon";
// import { viewTypes } from "../../../../../projects/utils/constants/viewTypes";
// import { companyPersonActions } from "../../../../../../store/reducers/companyPersonReducer";
// import { useAppSelector } from "../../../../../../hooks/redux";

import styles from "./header.module.scss";

export type Props = {
  amountOfProduct: number | undefined;
};

const Header: React.FC<Props> = ({ amountOfProduct }) => {
  // const dispatch = useDispatch();

  // const { setViewType } = companyPersonActions;
  // const { viewType } = useAppSelector((state) => state.companyPersonReducer);

  // const viewTypeItems: (SegmentedValue | SegmentedLabeledOption)[] = [
  //   {
  //     value: viewTypes.CARD,
  //     label: <ViewTypeCardIcon />,
  //   },
  //   {
  //     value: viewTypes.LIST,
  //     label: <ViewTypeListIcon />,
  //   },
  // ];
  // const onChangeViewType = (value: SegmentedValue) => {
  //   dispatch(setViewType(value as string));
  // };

  return (
    <div className={styles.header_container}>
      <span className={styles.amount}>
        {amountOfProduct! > 0 ? `${amountOfProduct} ta resurs` : null}
      </span>
      {/* <span className={styles.segment_container}>
        <Segmented
          options={viewTypeItems}
          onChange={onChangeViewType}
          value={viewType}
        />
      </span> */}
    </div>
  );
};

export default Header;
