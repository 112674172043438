const SwitchIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66699 11.3333H13.3337M13.3337 11.3333L10.667 8.66667M13.3337 11.3333L10.667 14M13.3337 4.66667H2.66699M2.66699 4.66667L5.33366 2M2.66699 4.66667L5.33366 7.33333"
        stroke="#98A2B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwitchIcon;
