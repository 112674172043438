export const USER = "user";
export const TOKEN = "token";
export const IS_AUTH = "is_auth";
export const ROLE_NAME = "role_name";
export const TABLE = "table";
export const LAYOUT = "layout";
export const IS_PARTY_PROCESS = "is_party_process";
export const PAYMENT_CURRENCY = "payment_currency";
export const ESTIMATE_GROUP = "estimate_group";
export const LOCALE = "locale";
