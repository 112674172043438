import { DefectStatus } from "./DefectStatus";
import CheckDimCircle from "../../assets/icons/CheckDimCircle";
import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import XCircleIcon from "../../assets/icons/XCircleIcon";

export const defectStatusIcons = {
  [DefectStatus.OPEN]: <CheckDimCircle />,
  [DefectStatus.PENDING]: <CheckDimCircle />,
  [DefectStatus.RECIEVED]: <CheckCircleIcon />,
  [DefectStatus.REJECTED]: <XCircleIcon />,
};
