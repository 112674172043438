export const endPoints = {
  RESOURCE_SELECT: "resource/select",
  UNIT_SELECT: "unit/select",
  CURRENCY_SELECT: "company-currency/select",
  COMPANY_CURRENCY_BASIC: "company-currency/basic",
  CASH_SELECT: "cash/select",
  PRODUCT_SELECT: "product/select",
  LANGUAGES: "language/select",
  CHAT_VIEW: "chat/view",
  CREATE_CHAT: "chat/create",
  UPLOAD_FILE: "file/upload",
  NOTIFICATION: "notification/view",
  READ_NOTIFICATION: "notification/read-all",
  CONFIRMATION_USER_STATUS: "confirmation-user/status",
  CREATE_DEVICE_TOKEN: "notification/device/create",
  COMPANY_PERSON_SELECT: "company-person/select",
  PROJECTS_SELECT: "project/select",
  SECTIONS_SELECT: "section/select",
  PERMISSON: "auth/permission",
  PERMISSON_SELECT: "auth/permission-select",
  TABLE_CONFIGS: "user-table-setting/view",
  TABLE_CONFIG_CREATE: "user-table-setting/create-or-update",
  TABLE_CONFIG_DELETE: "user-table-setting/delete",
  CUSTOM_FIELD_SELECT: "custom-field/select",
  CUSTOM_FIELD_ATTACH: "custom-field/attach",
  DOWNLOAD_FIELD: "file/download",
  CREATE_TABLE_CONFIG: "user-table-settings/create-or-update",
  PAYMENT: {
    TRANSFER: {
      HISTORY: (id: number | string) => `payment/transfer/history?id=${id}`
    },
    EXCHANGE: {
      HISTORY: (id: number | string) => `payment/exchange/history?id=${id}`
    }
  },
  GLOBAL_CHAT: "chat/unread-message-view",
  GLOBAL_CHAT_COUNT: "chat/unread-message-count",
  GET_FILE_DATA: "file/view",
  TEMPLATE_GENERATE: "template/generate",
  REGIONS_SELECT: "region/select",
  DISTRICTS_SELECT: (region_id: number) => `district/select?region_id=${region_id}`,
  BUILDERS_SELECT: "builder/select",
  CREATE_BUILDER: "builder/create",
  UPDATE_BUILDER: "builder/update",
  DELETE_BUILDER: "builder/delete",
  BUILDERS_VIEW: {
    WITH_ID: (id: number | undefined) => `builder/view?id=${id}`,
    WITHOUT_ID: `builder/view`
  },
  UNIT_TREE: "unit/tree",
  PAYMENT_VIEW: "payment/view",
  PAYMENT_DELETED_VIEW: "payment/view-deleted"
};
