import { useState } from "react";
import { useTranslation } from "react-i18next";

import useGetTableColumns from "../../../../../hooks/useGetTableColumns";
import SettingsIcon from "../../../../app/assets/icons/SettingsIcon";
import TableConfig from "../../../../app/components/table-config/TableConfig";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../app/service/queries";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { UserTableDataModel } from "../../../../app/utils/models/user/userTableConfigModel";
import { estimateTableDefaultData } from "../../../utils/constants/estimateTableDefaultData";

import styles from "./estimateSettings.module.scss";

type Props = {
  smetaContainer?: HTMLElement;
};
const EstimateSettings: React.FC<Props> = ({ smetaContainer }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);

  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const data = useGetTableColumns({
    defaultData: tableConfigData,
    customFieldSelect: customFields
  });

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <TableConfig
      open={open}
      setOpen={setOpen}
      customFields={customFields}
      data={data as unknown as UserTableDataModel}
      tableConfigKey={tableConfigKeys.ESTIMATE_CONFIG}
      defaultData={estimateTableDefaultData as UserTableDataModel | undefined}
      smetaContainer={smetaContainer}
    >
      <div className={styles.settings} onClick={onOpen}>
        <SettingsIcon size={18} /> {t("project.Sozlash")}
      </div>
    </TableConfig>
  );
};

export default EstimateSettings;
