import { colors } from "features/app/utils/constants/colors";

import { PaymentStatusEnums } from "../../../app/utils/enums/paymentStatusEnums";

export const paymentTypeColors: Record<PaymentStatusEnums, string> = {
  [PaymentStatusEnums.ACTIVE]: colors.PRIMARY,
  [PaymentStatusEnums.CANCEL]: colors.ERROR_500,
  [PaymentStatusEnums.PENDING]: colors.WARNING_500,
  [PaymentStatusEnums.PASSIVE]: colors.WARNING_500,
  [PaymentStatusEnums.DELETED]: colors.PRIMARY
};
