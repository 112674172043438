import React from "react";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

// import { CategoryModel } from "../../../../../settings/utils/models/category/CategoryModel";
// import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import DrawerCard from "../card/DrawerCard";
// import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { Spin } from "antd";

import styles from "./drawerFolders.module.scss";
import { CategoryModel } from "../../../../settings/utils/models/category/CategoryModel";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";

type Props = {
  data?: CategoryModel[];
  isLoading: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  isCategory: boolean;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  // setFolderId: React.Dispatch<
  //   React.SetStateAction<{
  //     category_id?: number | undefined;
  //     sub_category_id?: number | undefined;
  //   }>
  // >;
};

const DrawerFolders: React.FC<Props> = ({
  data,
  setPage,
  isLoading,
  setTabKey,
  isCategory,
  // setFolderId,
  setBreadcrumbItems,
}) => {
  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.folders}>
        {!isEmptyArr(data) &&
          data?.map((item) => (
            <DrawerCard
              {...item}
              key={item.id}
              setPage={setPage}
              setTabKey={setTabKey}
              isCategory={isCategory}
              // setFolderId={setFolderId}
              setBreadcrumbItems={setBreadcrumbItems}
            />
          ))}
      </div>
    </Spin>
  );
};

export default DrawerFolders;
