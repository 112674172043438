import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../../store/reducers/supplyReducer";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import FilterSearchIcon from "../../../../../../projects/assets/icons/FilterSearchIcon";
import { routeSubmodules } from "../../../../../utils/constants/routeSubmodules";
import { OfferPartyLeftModel } from "../../../../../utils/models/offerPartyModel";
import CreateProductModal from "../../../../create-product-modal/CreateProductModal";

import OfferPartyModalTableHeader from "./header/OfferPartyModalTableHeader";
import OfferPartyCurrencySelect from "./items/OfferPartyCurrencySelect";
import OfferPartyPersonSelect from "./items/OfferPartyPersonSelect";
import OfferPartyProductAmount from "./items/OfferPartyProductAmount";
import OfferPartyProductQuantity from "./items/OfferPartyProductQuantity";
import OfferPartyProductSelect from "./items/OfferPartyProductSelect";
import OfferPartyProductTotal from "./items/OfferPartyProductTotal";
import OfferPartyProductUnit from "./items/OfferPartyProductUnit";
import OfferPartyProjectSelect from "./items/OfferPartyProjectSelect";
import OfferPartyWarehouseSelect from "./items/OfferPartyWarehouseSelect";

import styles from "../offerPartyModalLeft.module.scss";

type Props = {
  forGroup?: boolean;
  fields: OfferPartyLeftModel[];
  isViewCompanyPerson?: boolean;
};

const OfferPartyModalTable: React.FC<Props> = ({ fields, forGroup, isViewCompanyPerson }) => {
  const dispatch = useDispatch();
  const { setProductDrawer } = supplyActions;
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");

  const {
    setCheckData,
    setOfferPartyLefItemData,
    setDeleteOfferPartProduct,
    setAddOfferProductLeftData,
    setOfferPartySelectProducts
  } = supplyOfferActions;

  const { leftData, selectProducts, checkData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onOpenProductDrawer = () => {
    dispatch(
      setProductDrawer({
        visible: true
      })
    );
  };

  const onAddProduct = () => {
    dispatch(
      setAddOfferProductLeftData({
        total: "0",
        amount: "0",
        quantity: "0",
        unit_id: undefined,
        product_id: undefined,
        project_id: undefined,
        warehouse_id: undefined,
        unique_id: uid(12),
        company_person: {
          id: undefined
        }
      })
    );
  };

  const onDeleteProduct = (unique_id: string) => () => {
    dispatch(
      setDeleteOfferPartProduct({
        unique_id
      })
    );
  };

  const isProduct = (uniqueId: string) => {
    const leftDataItem = leftData?.find(item => item.unique_id === uniqueId);

    return !leftDataItem?.product_id;
  };

  const onAfterProductAdded = (productId: number, unitId: number, index: string | number, res: ProductSelectModel) => {
    dispatch(setOfferPartySelectProducts([res, ...selectProducts]));

    dispatch(
      setOfferPartyLefItemData({
        key: "product_id",
        value: productId,
        uniqueId: index as string
      })
    );

    dispatch(
      setOfferPartyLefItemData({
        key: "unit_id",
        value: unitId,
        uniqueId: index as string
      })
    );
  };

  const onCheck = (e: CheckboxChangeEvent, record: OfferPartyLeftModel) => {
    if (e.target.checked) {
      dispatch(setCheckData([...checkData, record]));
    } else {
      dispatch(setCheckData(checkData?.filter(item => item.unique_id !== record.unique_id)));
    }
  };

  const checkValue = (uniqueId: string): boolean => Boolean(checkData?.some(item => item.unique_id === uniqueId));

  return (
    <div className={`${styles.table} ${forGroup ? styles.table__group : ""}`}>
      <OfferPartyModalTableHeader fields={fields} isViewCompanyPerson={isViewCompanyPerson} />
      <div className={styles.table__content}>
        {fields?.map(item => (
          <Row gutter={0} key={item.unique_id} className={styles.table__content__row}>
            <Col span={1} className={`${styles.table__content__item} ${styles.table__content__check}`}>
              <Checkbox onChange={e => onCheck(e, item)} checked={checkValue(item.unique_id)} />
            </Col>
            <Col
              span={isViewCompanyPerson ? 4 : 5}
              className={`${styles.table__content__item} ${styles.table__content__resource}`}
            >
              <OfferPartyProductSelect unique_id={item.unique_id} isViewCompanyPerson={isViewCompanyPerson} />
              {isProduct(item.unique_id) && (
                <div className={styles.add_resource} onClick={onOpenProductDrawer}>
                  <FilterSearchIcon />
                </div>
              )}
            </Col>
            <Col span={2} className={styles.table__content__item}>
              <OfferPartyProductUnit unique_id={item.unique_id} />
            </Col>
            <Col span={2} className={styles.table__content__item}>
              <OfferPartyProductQuantity unique_id={item.unique_id} />
            </Col>
            <Col span={2} className={styles.table__content__item}>
              <OfferPartyProductAmount unique_id={item.unique_id} />
            </Col>
            <Col span={isViewCompanyPerson ? 2 : 3} className={styles.table__content__item}>
              <OfferPartyWarehouseSelect uniqueId={item.unique_id} />
            </Col>
            <Col span={isViewCompanyPerson ? 2 : 3} className={styles.table__content__item}>
              <OfferPartyProjectSelect uniqueId={item.unique_id} />
            </Col>
            {isViewCompanyPerson && (
              <Col span={3} className={styles.table__content__item}>
                <OfferPartyPersonSelect uniqueId={item.unique_id} isViewCompanyPerson={isViewCompanyPerson} />
              </Col>
            )}
            <Col span={isViewCompanyPerson ? 2 : 2} className={styles.table__content__item}>
              <OfferPartyCurrencySelect uniqueId={item.unique_id} />
            </Col>
            <Col span={4} className={styles.table__content__item}>
              <div className={styles.total}>
                <OfferPartyProductTotal uniqueId={item.unique_id} />
                <div className={cx("delete")} onClick={onDeleteProduct(item.unique_id)}>
                  <DeleteIcon />
                </div>
              </div>
            </Col>
          </Row>
        ))}
      </div>
      {offerActions.add_resource && (
        <div className={styles.table__footer}>
          <div className={styles.add_resource} onClick={onAddProduct}>
            <PlusCircleIcon fill />
            Resurs qo'shish
          </div>
        </div>
      )}
      <CreateProductModal afterFunc={onAfterProductAdded} />
    </div>
  );
};

export default OfferPartyModalTable;
