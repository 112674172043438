import React from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Collapse, CollapseProps, Form, FormInstance } from "antd";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import { ErrorRes, SuccessRes } from "../../../../../../app/utils/models/responseType";
import { PartyFormPersonGroupModel } from "../../../../../utils/models/partyModalFormModel";

import PartyPersonGroupTable from "./PartyPersonGroupTable";
import PersonGroupHeader from "./PersonGroupHeader";

import styles from "./partyPersonGroup.module.scss";

type Props = {
  form: FormInstance;
  isWarehouse?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { useWatch } = Form;

const PartyPersonGroup: React.FC<Props> = ({ form, isWarehouse, deleteProduct }) => {
  const personGroup = useWatch("person_group", form) as PartyFormPersonGroupModel[];
  const personKeys = useWatch("person_keys", form) as string[];

  const items = (): CollapseProps["items"] =>
    personGroup?.map((item, index) => ({
      key: `${item?.id}`,
      label: <PersonGroupHeader {...item} isWarehouse={isWarehouse} />,
      children: (
        <PartyPersonGroupTable
          form={form}
          groupIndex={index}
          isWarehouse={isWarehouse}
          deleteProduct={deleteProduct}
          isNotCompanyPerson={!!item?.id}
          data={item?.data?.filter(item => item.row_id)}
        />
      ),
      defaultOpen: true
    }));

  const onChangeCollapse = (e: string[] | string) => {
    if (Array.isArray(e)) {
      form.setFieldValue("person_keys", e);
    }
  };

  return (
    <div className={styles.group}>
      <Collapse
        items={items()}
        activeKey={personKeys}
        onChange={onChangeCollapse}
        expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
      />
    </div>
  );
};

export default PartyPersonGroup;
