import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RU } from "../../../../app/utils/constants/languages";
import PartyPrintCard from "../../../../supply/components/parts/party-modal/party-print/PartyPrintCard";
import PartyPrintTable from "../../../../supply/components/parts/party-modal/party-print/PartyPrintTable";
import { PartyPrintDataModel, PartyPrintGenerateDataModel } from "../../../../supply/utils/models/PartyPrintDataModel";
import { useGetWarehouseSelect } from "../../../../warehouse/service/query";
import { OneOrderModel } from "../../../../warehouse/utils/models/oneOrderModel";
import { useGetProjectSelect } from "../../../service/queries";
import { PaymentOrderModel } from "../../../utils/models/paymentOrderModel";

import styles from "../../../../supply/components/parts/party-modal/party-print/partyPrint.module.scss";

interface IPrintPartyDataModel {
  id: number;
  data: OneOrderModel[];
  oneData: PaymentOrderModel | undefined;
}

type Props = {
  data: IPrintPartyDataModel;
};

interface INameAndId {
  id: number;
  name: string;
}

interface IOldPartyPrintData extends Omit<PartyPrintGenerateDataModel, "sameProject" | "sameWarehouse"> {
  project?: INameAndId;
  warehouse?: INameAndId;
}

const OrderPrint = forwardRef<HTMLDivElement | null, Props>(({ data: propsData }, ref) => {
  const { data, id, oneData } = propsData;
  const { i18n } = useTranslation();
  const { data: projects } = useGetProjectSelect({ enabled: true });
  const { data: warehouses } = useGetWarehouseSelect(true, true);

  const tableData = useMemo(() => {
    const result: IOldPartyPrintData = {
      data: []
    };

    let warehouse: INameAndId | undefined = data?.[0]?.warehouse as INameAndId;
    let project: INameAndId | undefined = data?.[0]?.project as INameAndId;

    const generateTotal = (item: OneOrderModel) => {
      if (item?.total_amount) {
        return item?.total_amount?.toLocaleString(RU);
      }
      return (Number(item?.quantity) * Number(item?.amount))?.toLocaleString(RU);
    };

    const generateResultData = (id: number) =>
      data
        ?.filter(item => item.company_person?.id === id)
        ?.map(item => ({
          id: item?.id,
          productName: item?.product?.name[i18n.language],
          unitName: item?.unit?.symbol[i18n.language],
          quantity: item?.quantity?.toLocaleString(RU),
          amount: item?.amount?.toLocaleString(RU),
          projectId: item?.project?.id,
          warehouseId: item?.warehouse?.id,
          total: generateTotal(item)
        })) as PartyPrintDataModel;

    data?.forEach(item => {
      const isSomeCompanyPerson = result?.data?.some(c => c?.id === item?.company_person?.id);

      warehouse = warehouse?.id === item.warehouse?.id ? warehouse : undefined;

      project = project?.id === item.project?.id ? project : undefined;

      !isSomeCompanyPerson &&
        result.data.push({
          id: item?.company_person?.id,
          name: item?.company_person?.name,
          data: generateResultData(item?.company_person?.id) as never
        });
    });

    return {
      ...result,
      sameProject: project?.name,
      sameWarehouse: warehouse?.name
    };
  }, [data, i18n.language]);

  return (
    <div className={styles.party_print} ref={ref}>
      <PartyPrintCard
        partyId={id}
        sameProject={tableData?.sameProject}
        sameWarehouse={tableData?.sameWarehouse}
        oneOrder={oneData as never}
      />
      <PartyPrintTable
        data={tableData}
        projects={projects}
        warehouses={warehouses}
        totalPrice={oneData?.total_amount}
      />
    </div>
  );
});

export default OrderPrint;
