import { Input } from "antd";
import React from "react";

import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";

type Props = {
  item: CustomFieldSelectModel;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingFieldInput: React.FC<Props> = ({
  setData,
  item: propsField,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setData(
      (old) =>
        old?.map((item) => ({
          // @ts-ignore
          ...item,
          // @ts-ignore
          custom_field_values: item?.custom_field_values?.map((field) => ({
            ...field,
            value: field?.custom_field_id === propsField?.id ? value : field?.value,
          })),
        }))
    );
  };

  return <Input placeholder="Kiriting" onChange={onChange} />;
};

export default GeneralEditingFieldInput;
