import React from "react";

import { cx } from "../../../../../modules/common";
import { colors } from "../../../../app/utils/constants/colors";

type Props = {
  firstTitle: string;
  secondTitle: string;
  thirdTitle: string;
  firstContent: string;
  secondContent: string;
  thirdContent: string;
  firstColor: string;
  secondColor: string;
  thirdColor: string;
  width?: number;
  bgColor?: string;
};

const CustomTooltip: React.FC<Props> = ({
  firstContent,
  firstTitle,
  secondContent,
  secondTitle,
  thirdContent,
  thirdTitle,
  firstColor,
  secondColor,
  thirdColor,
  width,
  bgColor
}) => (
    <div
      className={cx("flex flex-col gap-2 rounded-lg",!bgColor ? "border border-solid border-gray-900 px-3 py-2":"p-[4px]")}
      style={{ width: `${width}px`,backgroundColor:bgColor || colors.GRAY_800 }}
    >
      <div className="flex flex-col gap-1">
        <span className="text-[10px] font-medium text-white">{firstTitle}</span>
        <span
          className="flex items-center border-b-0 border-l-2 border-r-0 border-t-0 border-solid pl-2 text-sm font-semibold"
          style={{
            color: firstColor,
            borderLeftColor: firstColor
          }}
        >
          {firstContent}
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-[10px] font-medium text-white">{secondTitle}</span>
        <span
          className="flex items-center border-b-0 border-l-2 border-r-0 border-t-0 border-solid pl-2 text-sm font-semibold"
          style={{
            color: secondColor,
            borderLeftColor: secondColor
          }}
        >
          {secondContent}
        </span>
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-[10px] font-medium text-white">{thirdTitle}</span>
        <span
          className="flex items-center border-b-0 border-l-2 border-r-0 border-t-0 border-solid pl-2 text-sm font-semibold"
          style={{
            color: thirdColor,
            borderLeftColor: thirdColor
          }}
        >
          {thirdContent}
        </span>
      </div>
    </div>
  );

export default CustomTooltip;
