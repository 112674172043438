import React, { forwardRef, ReactNode, useCallback, useMemo } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { RU } from "features/app/utils/constants/languages";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { convert as convertNumberToWordsRu } from "number-to-words-ru";

import { useAuth } from "modules/auth/hooks";

import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { ActViewModel, ActViewPaymentModel } from "../../../../../utils/models/actViewModel";
import { CounterpartsPersonModel } from "../../../../../utils/models/counterpartsPersonModel";

import styles from "./actPrint.module.scss";

type Props = {
  name?: string;
  minDate?: Date;
  maxDate?: Date;
  mutual?: boolean;
  children?: ReactNode;
  person?: CounterpartsPersonModel;
  data?: ActViewModel | undefined;
};

const ActPrint = forwardRef<HTMLDivElement | null, Props>(({ minDate, maxDate, name, data, mutual }, ref) => {
  const { user } = useAuth();

  const tableData = useMemo(() => {
    let newTableData = data?.payments ?? [];

    data?.balances?.forEach(item => {
      newTableData = [
        ...newTableData,
        {
          currency: item?.currency,
          amount: item.amount,
          start_amount: item?.start_amount,
          id: item.id,
          date: item.start_date,
          sources: "",
          isOperation: true
        },
        {
          currency: item?.currency,
          amount: item.amount,
          start_amount: item?.start_amount,
          id: item.id,
          date: item.start_date,
          sources: "",
          isFinal: true
        }
      ];
    });

    return newTableData;
  }, [data]);

  const showSpan = (record: ActViewPaymentModel) => {
    if (record?.isFinal || record?.isOperation) {
      return { colSpan: 0, className: styles.table__item };
    }

    return {};
  };

  const generateTransactionsAmounts = useCallback(
    (type: "income" | "expense") => {
      const amounts: { amount: number; id: number }[] = [];

      data?.payments
        ?.filter(item => type === item?.type)
        ?.forEach(item => {
          const findAmountIndex = amounts?.findIndex(i => i?.id === item?.currency?.currency_id);

          if (findAmountIndex < 0) {
            amounts.push({
              amount: item?.amount,
              id: item?.currency?.currency_id
            });
          } else {
            amounts[findAmountIndex] = {
              ...amounts[findAmountIndex],
              amount: item?.amount + amounts[findAmountIndex]?.amount
            };
          }
        });

      return amounts;
    },
    [data?.payments]
  );

  const userDebt = (record: ActViewPaymentModel) => {
    // const isDebt = record?.amount - record.start_amount! <= 0;
    const isDebtFinal = record?.amount <= 0;

    if (record?.isOperation) {
      const amounts = generateTransactionsAmounts("income");

      return amounts?.map((item, index) => `${item?.amount?.toLocaleString(RU)} ${amounts[index + 1] ? "," : ""}`);
    }
    if (record?.isFinal) {
      return isDebtFinal ? `${record?.amount?.toLocaleString(RU)}` : null;
    }
    return record?.type === PaymentTypeEnum.INCOME ? `${record?.amount?.toLocaleString(RU)}` : null;
  };

  const userCredit = (record: ActViewPaymentModel) => {
    // const isCredit = record?.amount - record.start_amount! >= 0;
    const isCreditFinal = record?.amount >= 0;

    if (record?.isOperation) {
      const amounts = generateTransactionsAmounts("expense");

      return amounts?.map((item, index) => `${item?.amount?.toLocaleString(RU)} ${amounts[index + 1] ? "," : ""}`);
    }

    if (record?.isFinal) {
      return isCreditFinal ? `${record?.amount?.toLocaleString(RU)}` : null;
    }

    return record?.type === PaymentTypeEnum.EXPENSE ? `${record?.amount?.toLocaleString(RU)}` : null;
  };

  const columns: ColumnsType<ActViewPaymentModel> = [
    {
      title: "Дата",
      className: styles.right_border,
      render: (_, record) => {
        if (record?.isOperation) {
          return "Обороты за пепериод ";
        }

        return record?.isFinal ? `Окончательный баланс` : record?.date;
      },
      onCell: record => ({
        colSpan: record?.isFinal || record?.isOperation ? 2 : 1,
        className: record?.isFinal || record?.isOperation ? styles.table__bottom__item : ""
      })
    },
    {
      title: "Документ",
      className: styles.right_border,
      dataIndex: "sources",
      render: (value: string, record) => value || `Оплата-${record?.id}`,
      onCell: showSpan
    },
    {
      title: name,
      className: styles.company_name,
      children: [
        {
          title: "Дебит",
          className: styles.right_border,
          render: (_, item) => userDebt(item),
          onCell: record => ({
            className: record?.isFinal || record?.isOperation ? styles.table__amount : "",
            style: {
              textAlign: "right"
            }
          })
        },
        {
          title: "Кредит",
          className: styles.right_border,
          render: (_, item) => userCredit(item),
          onCell: record => ({
            className: record?.isFinal || record?.isOperation ? styles.table__amount : "",
            style: {
              textAlign: "right",
              fontSize: "10px"
            }
          })
        }
      ]
    },
    {
      title: user?.company_name,
      className: styles.company_name,
      children: [
        {
          title: "Дебит",
          className: styles.right_border,
          render: (_, item) => userCredit(item),
          onCell: record => ({
            className: record?.isFinal || record?.isOperation ? styles.table__amount : "",
            style: {
              textAlign: "right"
            }
          })
        },
        {
          title: "Кредит",
          className: styles.right_border,
          render: (_, item) => userDebt(item),
          onCell: record => ({
            className: record?.isFinal || record?.isOperation ? styles.table__amount : "",
            style: {
              textAlign: "right"
            }
          })
        }
      ]
    }
  ];

  return (
    <div className={styles.act_print} ref={ref}>
      <h3>
        Акт сверки взаиморасчетов по основания: {minDate ? `${dayjs(minDate).format(dayjsFormats.DATE)} - ` : ""}
        {maxDate ? dayjs(maxDate).format(dayjsFormats.DATE) : ""}
      </h3>
      <div className={styles.table}>
        <Table
          columns={mutual ? columns : columns.slice(0, -1)}
          pagination={false}
          dataSource={tableData}
          className={styles.act_print__table}
          locale={{
            emptyText: <TableEmpty />
          }}
        />
      </div>
      <p>
        в пользу <span>СП ООО ”{name}”</span> {Math.abs(data?.balances[0]?.amount as number).toLocaleString(RU)}{" "}
        {data?.balances[0]?.currency?.symbol} (
        {convertNumberToWordsRu(Math.abs(data?.balances[0]?.amount as number).toLocaleString(RU), {
          currency: {
            currencyNameCases: ["сум", "сумов", "сумов"],
            fractionalPartNameCases: ["тийин", "тийин", "тийин"]
          },
          convertNumberToWords: {
            integer: true,
            fractional: true
          }
        })}
        )
      </p>
    </div>
  );
});

export default ActPrint;
