import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialState {
  visible: boolean;
  id?: number;
  props?: any;
  title?: React.ReactNode;
}

const initialState: InitialState = {
  visible: false
};

const productSpentWidgetDetailDrawerSlice = createSlice({
  name: "productSpentWidgetDetailDrawer",
  initialState,
  reducers: {
    setOpenDetailDrawer: (
      state,
      action: PayloadAction<{ visible: boolean; props?: any; title?: React.ReactNode; id?: number }>
    ) => {
      state.props = action.payload.props;
      state.visible = action.payload.visible;
      state.title = action.payload.title;
      state.id = action.payload.id;
    }
  }
});

export const productSpentWidgetDetailDrawerActions = productSpentWidgetDetailDrawerSlice.actions;
export default productSpentWidgetDetailDrawerSlice.reducer;
