import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { LocalStorage } from "features/app/service/LocalStorage";

import { cx, RU } from "modules/common";

import { percentIcon } from "./helpers/percentIcon";
import { chartTypeValues, ProductPriceAnalyze, StaticWidget, TableDataTypeWidgetKey, WidgetKeys } from "./schema";
// const { i18n, t } = useTranslation();
const language = (LocalStorage.get("locale") as string) || "uz";

export const defaultWidgetSizes = {
  [chartTypeValues.card]: {
    w: 14,
    h: 28,
    minW: 14,
    maxW: 28,
    maxH: 56,
    minH: 28
  },
  [chartTypeValues.range]: {
    w: 16,
    h: 28,
    maxW: 32,
    maxH: 56
  },
  [chartTypeValues.bar]: {
    w: 14,
    h: 80,
    minW: 14,
    minH: 80,
    maxW: 28,
    maxH: 160
  },
  [chartTypeValues.area]: {
    w: 16,
    h: 28,
    minW: 16,
    minH: 28,
    maxW: 32,
    maxH: 56
  },
  [chartTypeValues.histogram]: {
    w: 28,
    h: 40,
    minH: 40,
    minW: 28,
    maxW: 56,
    maxH: 80
  },
  [chartTypeValues.table]: {
    w: 32,
    h: 52,
    maxW: 64,
    maxH: 52,
    minW: 32,
    minH: 52
  },
  [chartTypeValues.gantt]: {
    w: 32,
    h: 70,
    maxW: 32,
    maxH: 70,
    minW: 32,
    minH: 70
  },
  [chartTypeValues.table_card]: {
    w: 14,
    h: 70,
    minW: 14,
    maxW: 28,
    maxH: 140,
    minH: 70
  },
  [chartTypeValues.radar]: {
    w: 14,
    h: 32,
    minW: 14,
    minH: 32,
    maxW: 28,
    maxH: 64
  },
  [chartTypeValues.count]: {
    w: 14,
    h: 18,
    minW: 14,
    minH: 18,
    maxW: 28,
    maxH: 36
  },
  [chartTypeValues.column]: {
    w: 28,
    h: 100,
    minW: 28,
    maxW: 56,
    minH: 100,
    maxH: 200
  },
  [chartTypeValues.stream_graph]: {
    w: 32,
    h: 36,
    maxW: 64,
    maxH: 84,
    minW: 32,
    minH: 36
  },
  [chartTypeValues.line]: {
    w: 28,
    h: 42,
    minW: 28,
    minH: 42,
    maxW: 56,
    maxH: 84
  },
  [chartTypeValues.calendar]: {
    w: 28,
    h: 35,
    minH: 35,
    minW: 28,
    maxW: 56,
    maxH: 70
  }
};

export const widgetStaticData: Record<WidgetKeys, StaticWidget> = {
  project_by_custom_field: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      segments: [
        {
          title: "Bo'lim",
          value: "section",
          select: {
            isNotMultiple: true,
            name: "section_custom_field_id",
            queryKey: "section_custom_fields",
            endPoint: "custom-field/select?locations%5B%5D=section&selectable=true"
          }
        },
        {
          title: "Ish",
          value: "task",
          select: {
            isNotMultiple: true,
            name: "task_custom_field_id",
            queryKey: "task_custom_fields",
            endPoint: "custom-field/select?locations%5B%5D=task&selectable=true"
          }
        }
      ],
      isNotDate: true,
      isNotLimit: true,
      isNotProject: false,
      isRequiredProject: true,
      isNotMultipleProjectSelect: false
    }
  },
  warehouse_defect: {
    labels: ["o'tgan oyga nisbatan"],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    }
  },
  active_company_person: {
    labels: [],
    isNotDrawer: true,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: false,
      isNotProject: false,
      isCompanyPersonType: true
    }
  },
  cash_status: {
    labels: ["Umumiy balans", "Kirim", "Chiqim"],
    isNotDrawer: false,
    settings: {
      form: [
        {
          name: "cash_ids",
          endPoint: "cash/select",
          label: "Kassa",
          queryKey: "cashes"
        },
        {
          name: "payment_type_ids",
          endPoint: "payment-type/select",
          label: "To'lov turi",
          queryKey: "paymentTypes"
        }
      ],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    }
  },
  company_person_balance: {
    labels: ["Umumiy balans", "Kreditor", "Debitor"],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: true,
      isCompanyPersonType: true
    }
  },
  confirmation_payment_count: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    }
  },
  delivery_effective: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: false,
      isNotProject: false
    }
  },
  delivery_not_effective: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: false,
      isNotProject: false
    }
  },
  expense_payment: {
    labels: ["o'tgan kunga nisbatan"],
    isNotDrawer: false,
    settings: {
      form: [
        {
          label: "Kassa",
          name: "cash_ids",
          endPoint: "cash/select",
          queryKey: "cashes"
        }
      ],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    },
    isNotEmpty: true
  },
  last_payment: {
    labels: [],
    isNotDrawer: true,
    settings: {
      form: [
        {
          name: "cash_ids",
          endPoint: "cash/select",
          label: "Kassa",
          queryKey: "cashes"
        },
        {
          name: "payment_type_ids",
          endPoint: "payment-type/select",
          label: "To'lov turi",
          queryKey: "paymentTypes"
        }
      ],
      isNotDate: false,
      isNotLimit: false,
      isNotProject: false
    }
  },
  product_market_price_analyze: {
    labels: [],
    isNotEmpty: true,
    isNotDrawer: true,
    dateOptions: [
      {
        label: "Shu hafta",
        value: "this_week",
        props: {
          name: "Shu hafta"
        }
      },
      {
        label: "Oxirgi hafta",
        value: "last_week",
        props: {
          name: "Oxirgi hafta"
        }
      },
      {
        label: "Keyingi hafta",
        value: "next_week",
        props: {
          name: "Keyingi hafta"
        }
      }
    ],
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: true
    }
  },
  product_price_analyze: {
    labels: [],
    isNotDrawer: true,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false,
      isCompanyPersonType: true
    },
    columns: [
      {
        title: "№",
        align: "center",
        width: 50,
        fixed: "left",
        render: (_, __, index: number) => <span>{index + 1}</span>
      },
      {
        title: "Nomi",
        width: 550,
        render: (_, record: ProductPriceAnalyze) => (
          <div className={cx("resource")}>
            <div style={{ borderColor: record?.product?.resource?.color, color: record?.product?.resource?.color }}>
              {record?.product.resource?.symbol[language]}
            </div>
            <span>
              <PopoverShortName title={record?.product?.name[language]} length={50} /> {/* conflict dan oldin 25 edi */}
            </span>
          </div>
        )
      },
      {
        title: "Birligi",
        width: 100,
        render: (_, record: ProductPriceAnalyze) => record.unit?.symbol[language]
      },
      {
        title: "Narxi o'zgarishi",
        width: 150,
        render: (_, record: ProductPriceAnalyze) => (
          <div
            className={cx("flex items-center justify-center gap-1 rounded-2xl px-2 py-1 text-sm font-medium", {
              "bg-success-50 text-success-500": record?.change_amount_percent && record?.change_amount_percent < 0,
              "bg-error-50 text-error-500": record?.change_amount_percent && record?.change_amount_percent > 0,
              "bg-gray-200 text-gray-700": record?.change_amount_percent === 0
            })}
          >
            {percentIcon(Number(record?.change_amount_percent) || 0)}
            <span>{Math.abs(Number(record?.change_amount_percent) || 0)}%</span>
          </div>
        )
      },
      {
        title: "Sana",
        dataIndex: "date",
        align: "center",
        width: 150
      },
      {
        title: "So'nggi kirim narxi",
        width: 200,
        render: (_, record: ProductPriceAnalyze) => (
          <div className="flex items-center gap-1">
            <span className="text-sm font-medium text-gray-800">{record?.amount?.toLocaleString(RU)}</span>
            <span className="text-sm font-medium text-gray-600">{record?.currency?.symbol}</span>
          </div>
        )
      },
      {
        title: "",
        width: 70,
        render: () => (
          <div className={cx("arrow_icon_container")}>
            <ArrowNarrowIcon rotate={180} />
          </div>
        )
      }
    ]
  },
  project_activity: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [
        {
          label: "Bo'lim",
          name: "section_ids",
          queryKey: "sections-select",
          endPoint: "section/select",
          forDisabled: {
            fieldKey: "project_ids",
            requestKey: "project_ids[]"
          }
        }
      ],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false,
      isNotMultipleProjectSelect: false
    },
    dateOptions: [
      {
        label: "Shu oy",
        value: "this_month",
        props: {
          name: "Shu oy"
        }
      },
      {
        label: "Shu yil",
        value: "this_year",
        props: {
          name: "Shu yil"
        }
      },
      {
        label: "Oxirgi oy",
        value: "last_month",
        props: {
          name: "Oxirgi oy"
        }
      },
      {
        label: "Oxirgi yil",
        value: "last_year",
        props: {
          name: "Oxirgi yil"
        }
      },
      {
        label: "Keyingi oy",
        value: "next_month",
        props: {
          name: "Keyingi oy"
        }
      },
      {
        label: "Keyingi yil",
        value: "next_year",
        props: {
          name: "Keyingi yil"
        }
      }
    ],
    isNotSpecialDatPicker: true
  },
  project_by_resource: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    }
  },
  project_by_section: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [
        {
          label: "Bo'lim",
          name: "section_ids",
          queryKey: "sections-select",
          endPoint: "section/select",
          forDisabled: {
            fieldKey: "project_id",
            requestKey: "project_id"
          }
        }
      ],
      isNotDate: true,
      isNotLimit: true,
      isNotProject: false,
      isRequiredProject: true,
      isNotMultipleProjectSelect: true
    }
  },
  project_per_area_price: {
    labels: ["rejaga nisbatan"],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: true,
      isNotLimit: false,
      isNotProject: false
    }
  },
  project_sale_price: {
    labels: ["rejaga nisbatan"],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: true,
      isNotLimit: false,
      isNotProject: false
    }
  },
  top_company_person: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [],
      isNotDate: false,
      isNotLimit: false,
      isNotProject: false
    }
  },
  expense_payment_by_month: {
    labels: [],
    isNotDrawer: true,
    settings: {
      form: [
        {
          label: "Kassa",
          name: "cash_ids",
          endPoint: "cash/select",
          queryKey: "cashes"
        }
      ],
      isNotDate: false,
      isNotLimit: true,
      isNotProject: false
    }
  },
  warehouse_amount: {
    labels: [],
    isNotDrawer: false,
    settings: {
      form: [
        {
          label: "Omborxonalar",
          name: "warehouse_ids",
          endPoint: "warehouse/select",
          queryKey: "warehouses"
        }
      ],
      isNotDate: true,
      isNotLimit: true,
      isNotProject: true
    }
  }
};

// export const ColumnDarkColors = ["#FAC858", "#91CC75", "#5470C6", "#EE6666", "#73C0DE"];
// export const ColumnLightColors = ["#FEF7E6", "#EFF8EA", "#E6EAF7", "#FDE8E8", "#EAF6FA"];

export const defaultWidgetDateOptions = [
  {
    label: "Bugun",
    value: "today",
    props: {
      name: "Bugun"
    }
  },
  {
    label: "Shu hafta",
    value: "this_week",
    props: {
      name: "Shu hafta"
    }
  },
  {
    label: "Shu oy",
    value: "this_month",
    props: {
      name: "Shu oy"
    }
  },
  {
    label: "Shu yil",
    value: "this_year",
    props: {
      name: "Shu yil"
    }
  },
  {
    value: "yesterday",
    label: "Kecha",
    props: {
      name: "Kecha"
    }
  },
  {
    label: "Oxirgi hafta",
    value: "last_week",
    props: {
      name: "Oxirgi hafta"
    }
  },
  {
    label: "Oxirgi oy",
    value: "last_month",
    props: {
      name: "Oxirgi oy"
    }
  },
  {
    label: "Oxirgi yil",
    value: "last_year",
    props: {
      name: "Oxirgi yil"
    }
  },

  {
    value: "tomorrow",
    label: "Ertaga",
    props: {
      name: "Ertaga"
    }
  },
  {
    label: "Keyingi hafta",
    value: "next_week",
    props: {
      name: "Keyingi hafta"
    }
  },
  {
    label: "Keyingi oy",
    value: "next_month",
    props: {
      name: "Keyingi oy"
    }
  },
  {
    label: "Keyingi yil",
    value: "next_year",
    props: {
      name: "Keyingi yil"
    }
  }
];

// export const defaultLayout = [
//   { w: 14, h: 28, x: 0, y: 0, i: "1", minW: 14, minH: 28, maxH: 28, moved: false, static: false },
//   { w: 15, h: 70, x: 14, y: 0, i: "25", minW: 14, minH: 70, moved: false, static: false },
//   { w: 19, h: 28, x: 29, y: 0, i: "26", minW: 14, minH: 28, maxH: 28, moved: false, static: false },
//   { w: 19, h: 42, x: 29, y: 28, i: "27", minW: 14, minH: 32, moved: false, static: false },
//   { w: 14, h: 18, x: 0, y: 141, i: "29", minW: 14, minH: 18, moved: false, static: false },
//   { w: 14, h: 33, x: 0, y: 28, i: "40", minW: 14, minH: 30, moved: false, static: false },
//   { w: 34, h: 100, x: 14, y: 70, i: "41", minW: 28, minH: 100, moved: false, static: false },
//   { w: 14, h: 80, x: 0, y: 61, i: "43", minW: 14, minH: 80, moved: false, static: false },
//   { w: 14, h: 18, x: 0, y: 159, i: "44", minW: 14, minH: 18, moved: false, static: false },
//   { w: 14, h: 18, x: 14, y: 170, i: "45", minW: 14, minH: 18, moved: false, static: false },
//   { w: 48, h: 46, x: 0, y: 188, i: "47", minW: 28, minH: 40, moved: false, static: false },
//   { w: 48, h: 60, x: 0, y: 234, i: "49", minW: 28, minH: 42, moved: false, static: false },
//   { w: 28, h: 91, x: 0, y: 294, i: "83", minW: 28, minH: 35, moved: false, static: false }
// ];

export const tableDataEndpoints: {
  [k in keyof TableDataTypeWidgetKey]: string;
} = {
  product_price_analyze: "product-price-analyze"
};
