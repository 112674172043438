import React, { SetStateAction, useEffect } from "react";
import { FormInstance, Radio, Spin } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { RU } from "features/app/utils/constants/languages";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { useGetCashMoneyPaymentTypeSelect } from "features/payment/service/queries";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import CheckboxBaseIcon from "../../../../assets/icons/CheckboxBaseIcon";

import styles from "./secondStep.module.scss";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  form: FormInstance;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<SetStateAction<boolean>>;
};

const SecondStep: React.FC<Props> = ({ setStep, form, setAutoAdvance, autoAdvance }) => {
  const dispatch = useAppDispatch();
  const { setPaymentOrderModal } = paymentReducerActions;
  const payload = useAppSelector(state => state.paymentReducer.paymentOrderModal);
  const { type, cash, payment_type, currency } = payload;

  const {
    data: paymentTypes,
    isLoading: isLoadingPaymentTypes,
    isFetching
  } = useGetCashMoneyPaymentTypeSelect({
    type: type || ("expense" as PaymentTypeEnum),
    cashId: cash?.id,
    enabled: Boolean(cash?.id)
  });

  const onClickPayment = (value: string) => {
    const [currencyId, paymentTypeId] = value.split("-");

    const paymentType = paymentTypes?.find(item => item.id === Number(paymentTypeId));
    const selectCurrency = paymentType?.currencies?.find(item => item.id === Number(currencyId));

    dispatch(
      setPaymentOrderModal({
        ...payload,
        cash,
        type,
        currency: selectCurrency,
        payment_type: paymentType
      })
    );

    form.setFieldValue("project_id", undefined);
    form.setFieldValue("amount", undefined);

    setAutoAdvance(true);
    setStep(state => state + 1);
  };

  useEffect(() => {
    if (paymentTypes && autoAdvance) {
      if (paymentTypes?.length === 1) {
        if (paymentTypes[0]?.currencies?.length === 1) {
          dispatch(
            setPaymentOrderModal({
              ...payload,
              cash,
              type,
              currency: paymentTypes[0]?.currencies[0],
              payment_type: paymentTypes[0]
            })
          );

          form.setFieldValue("project_id", undefined);
          form.setFieldValue("amount", undefined);

          setStep(state => {
            if (state === 1) {
              return state + 1;
            }
            return state - 1;
          });
        }
      }
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingPaymentTypes || isFetching} indicator={LoadingIndicator}>
      <div className={styles.payment}>
        <div className={styles.cash}>
          <div className={styles.name}>
            <h4>{cash?.name}</h4>
            <p>Kassa</p>
          </div>
          <div className={styles.currency}>
            {cash?.currencies?.map(currency => (
              <div className={styles.currency__item} key={currency.id}>
                <img src={currency.icon} alt={currency.symbol} />
                <span>
                  {currency.amount?.toLocaleString(RU)} {currency.symbol}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.bottom}>
          {paymentTypes?.map(item => (
            <div key={item.id} className={styles.item}>
              <Radio.Group optionType="button" value={`${currency?.id}-${payment_type?.id}`}>
                <div className={styles.item__left}>{item.name}</div>
                {item?.currencies?.map(currency => (
                  <Radio
                    key={currency.id}
                    value={`${currency.id}-${item.id}`}
                    onClick={() => onClickPayment(`${currency.id}-${item.id}`)}
                  >
                    <div className={styles.payment_currency}>
                      <img src={currency.icon} alt={currency?.symbol} />
                      <span>
                        {currency.amount?.toLocaleString(RU)} {currency.symbol}
                      </span>
                    </div>
                    <CheckboxBaseIcon />
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default SecondStep;
