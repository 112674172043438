import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { projectsReducerActions } from "../../../../../store/reducers/projectsReducer";
import DownloadIcon from "../../../../app/assets/icons/DownloadIcon";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";

import EmptyPlan from "../../../../app/assets/images/emptyPlan.png";

import styles from "./staticEstimate.module.scss";

type Props = {
  setStartPlanning: React.Dispatch<React.SetStateAction<boolean>>;
};

const EmptyStaticEstimate: React.FC<Props> = ({ setStartPlanning }) => {
  const dispatch = useDispatch();
  const { setOpenImportModal } = projectsReducerActions;

  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const estimateActions = actions("Smeta");

  const onOpenImportModal = () => {
    dispatch(setOpenImportModal(true));
  };

  const onStartPlanning = () => {
    setStartPlanning(true);
  };

  return (
    <div className={styles.empty_data}>
      <img src={EmptyPlan} alt="EmptyPlan" />
      <h4>Loyihani rejalashtirishni boshlang</h4>
      <p>Loyihaga ishlar kiriting, resurslar biriktiring, ish grafigini tuzib chiqing</p>
      <div className={styles.empty_data__footer}>
        {estimateActions?.import && (
          <Button onClick={onOpenImportModal} className={styles.button}>
            <DownloadIcon />
            Import
          </Button>
        )}
        <Button type="primary" className={styles.start_btn} onClick={onStartPlanning}>
          <PlusIcon /> Boshlash
        </Button>
      </div>
    </div>
  );
};

export default EmptyStaticEstimate;
