import React, { useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Pagination as AntdPagination, PaginationProps } from "antd";
import { useTranslation } from "react-i18next";

import ArrowNarrowIcon from "../../assets/icons/ArrowNarrowIcon";
import { TABLE } from "../../utils/constants/localStorageKeys";
import { queryParamsKeys } from "../../utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../utils/constants/tableConfigKeys";

interface PaginationInterface {
  paginationProps?: PaginationProps;
  configKey?: tableConfigKeys;
}

const Pagination: React.FC<PaginationInterface> = ({ paginationProps, configKey }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onChangePage = (page: number) => {
    const urlSearch = new URLSearchParams(location.search);

    urlSearch.set(queryParamsKeys.PAGE, String(page));

    navigate({
      search: urlSearch.toString()
    });
  };

  const itemRender = (
    _: number,
    type: "page" | "prev" | "next" | "jump-prev" | "jump-next",
    element: React.ReactNode
  ) => {
    if (type === "prev") {
      return (
        <a>
          <ArrowNarrowIcon /> {t("Xodimlar.Oldingi")}
        </a>
      );
    }
    if (type === "next") {
      return (
        <a>
          {t("Xodimlar.Keyingi")}
          <ArrowNarrowIcon rotate={180} />
        </a>
      );
    }
    return element;
  };

  const current = () => {
    if (paginationProps?.current) return paginationProps?.current;
    if (searchParams.get(queryParamsKeys.PAGE)) return Number(searchParams.get(queryParamsKeys.PAGE));
    return 1;
  };

  const pageSize = useMemo(() => {
    if (configKey) {
      const tableSize = JSON.parse(localStorage.getItem(TABLE) || "{}")?.[configKey];

      return Number(searchParams.get(queryParamsKeys.SIZE)) || tableSize || 10;
    }
    return Number(searchParams.get(queryParamsKeys.SIZE)) || 10;
  }, [configKey, searchParams]);

  return (
    <AntdPagination
      current={current()}
      pageSize={pageSize}
      {...paginationProps}
      showSizeChanger={false}
      hideOnSinglePage={true}
      itemRender={itemRender}
      onChange={paginationProps?.onChange ?? onChangePage}
    />
  );
};

export default Pagination;
