import React from "react";
import { Tooltip } from "antd";

import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { PaymentModel } from "../../../../app/utils/models/payment/paymentModel";
import { paymentSource } from "../../../utils/helpers/paymentSource";

import CompanyPersonSourceElement from "./CompanyPersonSourceElement";

import styles from "./incomeExpenseView.module.scss";

type Props = {
  record?: Partial<PaymentModel>;
  isViewKey?: boolean;
  isViewCompanyPersonBalance?: boolean;
};

export const PaymentSourceElement: React.FC<Props> = ({
  record,
  isViewKey = true,
  isViewCompanyPersonBalance = true
}) => {
  const getSource = paymentSource(record as never);

  const generateName = () => {
    if (record?.company_person) {
      return (
        <CompanyPersonSourceElement
          name={getSource?.name}
          record={record?.company_person}
          isViewCompanyPersonBalance={isViewCompanyPersonBalance}
        />
      );
    }
    return (
      <span>
        {getSource?.name && getSource?.name?.length > 25 ? (
          <Tooltip title={getSource?.name}>{getSource?.name?.substring(0, 25)}...</Tooltip>
        ) : (
          getSource?.name
        )}
      </span>
    );
  };

  return (
    <div className={styles.source}>
      <ConditionalRender if={isViewKey}>
        <Tooltip title={getSource?.label} rootClassName="">
          <div className={styles.source__key} style={{ background: getSource?.background, color: getSource?.color }}>
            {getSource?.key}
          </div>
        </Tooltip>
      </ConditionalRender>
      {generateName()}
    </div>
  );
};

export default PaymentSourceElement;
