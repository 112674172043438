import { ReactNode } from "react";

import PendingIcon from "../../assets/icons/PendingIcon";
import RecievedIcon from "../../assets/icons/RecievedIcon";
import RejectedIcon from "../../assets/icons/RejectedIcon";
import { ConfirmationStatusEnum } from "../enums/confirmationStatusEnum";

export const ConfirmationStatusIcons: Record<ConfirmationStatusEnum, ReactNode> = {
  [ConfirmationStatusEnum.OPEN]: <PendingIcon />,
  [ConfirmationStatusEnum.RECIEVED]: <RecievedIcon />,
  [ConfirmationStatusEnum.REJECTED]: <RejectedIcon />,
};
