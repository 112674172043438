import React from "react";
import { Checkbox } from "antd";
import i18n from "i18next";

import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../../../app/utils/models/customFIeldValueModel";

interface IProps {
  values?: CustomFieldValueModel[];
  customFields?: CustomFieldSelectModel[];
  locations: CustomFieldLocationEnum;
}

const DynamicCustomField: React.FC<IProps> = ({ values, locations, customFields }) =>
  customFields?.map(field => {
    // if (field?.custom_field_locations?.includes(locations)) {
    const findCustomFields = values?.find(item => item?.custom_field?.id === field?.id);

    const type = findCustomFields?.custom_field?.type;

    if (type === "checkbox") {
      return (
        <div key={field?.id} className="flex justify-end pr-4">
          <Checkbox checked={true} />
        </div>
      );
    }

    if (type === "label") {
      const labels = values?.filter(item => item?.custom_field?.id === field?.id);

      return (
        <div key={field?.id} className="flex justify-end pr-4">
          {labels?.map((item, index) => `${index ? " , " : ""}${item?.custom_field_option?.name?.[i18n.language]}`)}
        </div>
      );
    }

    return (
      <div key={field?.id} className="flex justify-end pr-4">
        {findCustomFields?.custom_field_option?.name?.[i18n.language] || findCustomFields?.value}
      </div>
    );
    // }

    // return <div key={field?.id} />;
  });

export default DynamicCustomField;
