import React from "react";
import { Tabs, TabsProps } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { typeValues } from "modules/dashboard";

import Wrapper from "./items/wrapper";

import styles from "./company-person-balance.module.scss";

const CompanyPersonBalance: React.FC = () => {
  const { append, queries } = useQueryParams();
  const { balance_total_amount_type } = queries();
  const { t } = useTranslation();

  const items: TabsProps["items"] = [
    {
      key: typeValues.creditor,
      label: `${t("dashboard.Kreditor")}`,
      children: <Wrapper />
    },
    {
      key: typeValues.debtor,
      label: `${t("dashboard.Debtor")}`,
      children: <Wrapper />
    }
  ];

  const onChange = (e: string) => {
    append(queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE, e);
  };

  return (
    <div className="w-[60rem]">
      <Tabs
        items={items}
        onChange={onChange}
        rootClassName={styles.tab}
        activeKey={balance_total_amount_type || typeValues.creditor}
      />
    </div>
  );
};

export default CompanyPersonBalance;
