import { useMemo, useState } from "react";
import { Collapse, Popover } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { StatusEnums } from "../../../../../app/components/statuses/statusEnums";
import { RU } from "../../../../../app/utils/constants/languages";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import ClockIcon from "../../../../assets/icons/ClockIcon";
import { WarehouseProductModel } from "../../../../utils/models/paymentOrderDetailModel";
import { PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";

import ExcessView from "./excess-view/ExcessView";

import styles from "./orderViewTop.module.scss";

type Props = {
  order?: PaymentOrderModel;
  warehouse_products: WarehouseProductModel[] | undefined;
};

const OrderViewTop = ({ order, warehouse_products }: Props) => {
  const { i18n } = useTranslation();
  const [openExcess, setOpenExcess] = useState<boolean>(false);

  const onOpenExcess = () => {
    setOpenExcess(true);
  };

  const sortOrder =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    warehouse_products?.reduce((prev: any, current) => {
      prev[current?.unit?.symbol[i18n.language]] =
        (prev[current?.unit?.symbol[i18n.language]] || 0) + parseLocaledString(String(current?.quantity));
      return prev;
    }, {}) || {};
  const sortOrderKeys = Object.keys(sortOrder);

  const allUnitText = useMemo(
    () => (
      <div className={styles.units}>
        {sortOrderKeys?.map((key, index) => (
          <div className={styles.units__item} key={index}>
            <p>{sortOrder[key]}</p>
            <span>{key}</span>
            {index + 1 !== sortOrderKeys?.length && <p>,</p>}
          </div>
        ))}
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [warehouse_products, i18n.language]
  );

  return (
    <div className={styles.top}>
      <div className={styles.left}>
        <div className={styles.left__item}>
          <p>Yaratilgan sana</p>
          <p>{order?.created_at}</p>
        </div>
        <div className={styles.left__item}>
          <p>Yetkazish sanasi</p>
          <p>{order?.delivery_date || "-"}</p>
        </div>
        <div className={styles.left__item}>
          <p>To'lov sanasi</p>
          <p>{order?.payment_date || "-"}</p>
        </div>
        <div className={styles.left__item}>
          <p>Jami mahsulotlar</p>
          <Popover title={allUnitText} placement="left">
            <p>{sortOrderKeys?.length ?? 0} birlik</p>
          </Popover>
        </div>
        {order?.expected_amount !== order?.amount && (
          <div className={styles.left__item}>
            <p>To’lanishi kerak</p>
            <p className={styles.excess} onClick={onOpenExcess}>
              {order?.expected_amount?.toLocaleString(RU) ?? 0} {order?.currency?.symbol}
            </p>
          </div>
        )}
        {order?.custom_field_values && order?.custom_field_values?.length > 0 && (
          <Collapse
            ghost
            items={[
              {
                label: <span className={styles.customField_title}>O’zgaruvchilar</span>,
                key: "1",
                children: (
                  <>
                    {order?.custom_field_values?.map(item => (
                      <div className={styles.left__item} key={item.id}>
                        <p>{item?.custom_field?.name[i18n.language]}</p>
                        <p>{item?.value}</p>
                      </div>
                    ))}
                  </>
                )
              }
            ]}
            className={classNames(styles.customField, "simpleCollapse")}
            expandIcon={({ isActive }) => (
              <SelectSuffixIcon stroke="#98A2B3" placement={isActive ? "bottom" : "right"} />
            )}
          />
        )}
      </div>
      {order && order?.amount === order?.debt_amount && order?.debt_amount > 0 ? (
        <div className={styles.right}>
          <div className={styles.right__inner}>
            <ClockIcon />
            <p>To’lov qilish kutilmoqda!</p>
          </div>
        </div>
      ) : (
        <div className={styles.right_amount}>
          <div className={styles.right_amount__item}>
            <p>Umumiy summa</p>
            <p>
              {order?.total_amount?.toLocaleString(RU)} {order?.currency?.symbol}
            </p>
          </div>
          <div className={styles.right_amount__item}>
            <p>Hisoblangan summa</p>
            <p>
              {order?.calculated_amount?.toLocaleString(RU)} {order?.currency?.symbol}
            </p>
          </div>
          <div className={styles.right_amount__item}>
            <p>To’landi</p>
            <div className="flex items-center gap-2">
              <div className="m-0 flex items-center gap-1">
                <span>{(order && order?.total_amount - order?.debt_amount)?.toLocaleString(RU)}</span>
                <span>{order?.currency?.symbol}</span>
              </div>
            </div>
          </div>
          <ConditionalRender if={order?.total_expense_amount}>
            <div className={styles.right_amount__item}>
              <p>Xarajat summasi</p>
              <div className="flex items-center gap-2">
                <div className="m-0 flex items-center gap-1">
                  <span>{(order && order?.total_expense_amount)?.toLocaleString(RU)}</span>
                  <span>{order?.currency?.symbol}</span>
                </div>
              </div>
            </div>
          </ConditionalRender>
          <div className={styles.right_amount__item}>
            <p>Qoldiq</p>
            <p className="flex items-center gap-1.5">
              <span>
                {(order?.status === StatusEnums.RECIEVED
                  ? order?.must_pay_amount || 0
                  : order?.debt_amount
                )?.toLocaleString(RU)}
              </span>
              <span>{order?.currency?.symbol}</span>
            </p>
          </div>
        </div>
      )}
      <ExcessView open={openExcess} setOpen={setOpenExcess} orderId={order?.id} />
    </div>
  );
};

export default OrderViewTop;
