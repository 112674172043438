import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Modal, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { addressReducerActions } from "../../../../store/reducers/addressReducer";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import BuilderModal from "../../../app/components/builder-modal/BuilderModal";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import { useGetBuildersSelect } from "../../../app/service/queries";
import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { formRules } from "../../../app/utils/constants/formRules";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../app/utils/helpers/parseLocaledString";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import { parseTreeValue } from "../../../app/utils/helpers/parseTreeValue";
import { CashParsedTreeModel } from "../../../app/utils/models/cash/cashParsedTreeModel";
import { WarehouseParsedTreeModel } from "../../../app/utils/models/warehouse/warehouseParsedTreeModel";
import SettingsAdminsModal from "../../../settings/components/admins/admins/modal/SettingsAdminsModal";
import SettingsWarehouseModal from "../../../settings/components/warehouse/modal/SettingsWarehouseModal";
import { useCreateProject, useUpdateProject } from "../../service/mutations";
import { useGetDetailedProject } from "../../service/queries";
import { ProjectBodyModel } from "../../utils/models/projectBodyModel";
import { ProjectFormFieldsModel } from "../../utils/models/projectFormFieldsModel";

import ProjectParameters from "./components/ProjectParameters";
import { EmplyoyeeTypes, ProjectParticipants } from "./components/ProjectParticipants";
import { ProjectSettings } from "./components/ProjectSettings";

import styles from "./projectModal.module.scss";

const { Item } = Form;

export const ProjectModal: React.FC = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { id: folderId } = parseParamsId(params?.folderId);
  const [formInstance] = Form.useForm<ProjectFormFieldsModel>();
  const [tabKey, setTabKey] = useState("1");
  const [currencySymbol, setCurrencySymbol] = useState<string>("");

  const dispatch = useAppDispatch();
  const createProject = useCreateProject();
  const updateProject = useUpdateProject();
  const { projectModalData: modalData } = useAppSelector(state => state.projectsReducer);

  const { visible } = useAppSelector(state => state.settingsReducer.warehouse);
  const { visible: adminModalVisible } = useAppSelector(state => state.settingsReducer.admin);

  const { visible: builderModalVisble } = useAppSelector(state => state.builderReducer.builderModal);

  const { data, isLoading } = useGetDetailedProject(modalData.id);
  const { setProjectModalData } = projectsReducerActions;
  const { data: builders } = useGetBuildersSelect();
  const { setAddressDataToEmpty } = addressReducerActions;
  const [dateError, setDateError] = useState<string>("");

  const onCancel = () => {
    dispatch(setProjectModalData({ visible: false }));
    formInstance.resetFields();
  };

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      dispatch(setAddressDataToEmpty());
      formInstance.resetFields();
      setTabKey("1");
    }
  };

  const onOk = () => {
    formInstance
      .validateFields()
      .then(() => {
        formInstance.submit();
      })
      .catch(error =>
        setDateError(
          error?.errorFields?.length === 2 ? error?.errorFields[1]?.errors[0] : error?.errorFields[0]?.errors[0]
        )
      );
  };

  const tabsItems = [
    {
      key: "1",
      label: `${t("project.Parametrlar")}`,
      children: <ProjectParameters formInstance={formInstance} data={data} builders={builders!} />,
      forceRender: true
    },
    {
      key: "2",
      label: `${t("project.Loyiha ishtrokchilari")}`,
      children: <ProjectParticipants data={data} formInstance={formInstance} />,
      forceRender: true
    },
    {
      key: "3",
      label: `${t("project.Sozlamalar")}`,
      children: <ProjectSettings formInstance={formInstance} data={data} />,
      forceRender: true
    }
  ];

  const onFinish = (fields: ProjectFormFieldsModel) => {
    const cashParsedTree: CashParsedTreeModel = {
      cash_ids: [],
      cash_folder_ids: []
    };

    const managerUsers =
      fields?.manager_ids && fields?.manager_ids?.length > 0
        ? fields?.manager_ids?.map(el => ({
            id: el,
            type: "manager"
          }))
        : [];
    const foremanUsers =
      fields?.foreman_ids && fields?.foreman_ids?.length > 0
        ? fields?.foreman_ids?.map(el => ({
            id: el,
            type: "foreman"
          }))
        : [];
    const participantUsers =
      fields?.participant_ids && fields?.participant_ids?.length > 0
        ? fields?.participant_ids?.map(el => ({
            id: el,
            type: "participant"
          }))
        : [];
    const users = [...managerUsers!, ...foremanUsers!, ...participantUsers!];

    const warehouseParsedTree: WarehouseParsedTreeModel = {
      warehouse_ids: [],
      warehouse_folder_ids: []
    };

    const req: ProjectBodyModel & CashParsedTreeModel & WarehouseParsedTreeModel = {
      name: fields.name,
      currency_id: fields.currency_id,
      project_users: users?.length > 0 ? users : undefined,
      start_date:
        fields.dates && fields.dates?.length > 0 ? dayjs(fields.dates![0]).format(dayjsFormats.DATE) : undefined,
      due_date:
        fields.dates && fields.dates?.length > 0 ? dayjs(fields.dates![1]).format(dayjsFormats.DATE) : undefined,
      address: fields.address!,
      area: fields.area ? parseLocaledString(String(fields.area)) : undefined,
      builder_id: fields.builder_id!,
      profit: fields.profit ? parseLocaledString(String(fields.profit)) : undefined,
      project_folder_id: folderId ? Number(folderId) : undefined,
      resource_auto_expense: fields.resource_auto_expense,
      ...parseTreeValue<CashParsedTreeModel>(fields.cash_ids ?? [], cashParsedTree),
      ...parseTreeValue<WarehouseParsedTreeModel>(fields.warehouse_ids ?? [], warehouseParsedTree),
      district_id: fields.district_id || undefined,
      latitude: fields?.latitude || undefined,
      longitude: fields.longitude || undefined
    };

    delete req.cash_folder_ids;
    delete req.warehouse_folder_ids;

    let reqKey: keyof typeof req;

    for (reqKey in req) {
      if (!(reqKey in req) || (Array.isArray(req[reqKey]) && isEmptyArr(req[reqKey] as Array<unknown>))) {
        delete req[reqKey];
      }
    }

    if (isEmptyArr(fields?.cash_ids) && fields?.cash_ids !== undefined) req.cash_ids = [];
    if (isEmptyArr(fields?.warehouse_ids) && fields?.warehouse_ids !== undefined) req.warehouse_ids = [];

    if (modalData.id) {
      updateProject.mutateAsync({ ...req, id: modalData.id }).then(onCancel);
    } else {
      createProject.mutateAsync({ ...req }).then(onCancel);
    }
  };

  const title = () => {
    const text = data ? t("project.Loyiha tahrirlash") : t("project.Loyiha yaratish");

    return (
      <div className={styles.title}>
        {text}
        {modalData.id && isLoading && <Spin indicator={LoadingIndicator} />}
      </div>
    );
  };

  const onChangeKey = (key: string) => setTabKey(key);

  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        name: data?.name,
        currency_id: data?.currency?.id,
        resource_auto_expense: data?.resource_auto_expense,
        symbol: data?.currency?.symbol,
        foreman_ids: data?.project_users?.filter(el => el.type === EmplyoyeeTypes.FOREMAN).map(el => el.id),
        manager_ids: data?.project_users?.filter(el => el?.type === EmplyoyeeTypes.MANAGER).map(el => el.id),
        participant_ids: data?.project_users?.filter(el => el.type === EmplyoyeeTypes.PARTICIPANT).map(el => el.id),
        profit: localeFormatter(String(data?.profit)) as unknown as number
      });
    }
  }, [visible, data, formInstance]);

  return (
    <Modal
      centered
      onOk={onOk}
      title={title()}
      okText={t("project.Saqlash")}
      onCancel={onCancel}
      cancelText={t("project.Yopish")}
      open={modalData.visible}
      className={styles.modal}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createProject.isLoading || updateProject.isLoading
      }}
    >
      <Form layout="vertical" onFinish={onFinish} form={formInstance}>
        <Item label="Nomi" name="name" rules={formRules()}>
          <Input placeholder={t("project.Nomini kiriting")} />
        </Item>
        <Item name="symbol" className="hidden" />
        {tabKey !== "1" && dateError && <p className={styles.date_error}>{dateError}</p>}
        <Tabs className={styles.tabs} items={tabsItems} activeKey={tabKey} onChange={onChangeKey} />
      </Form>
      {visible && <SettingsWarehouseModal />}
      {adminModalVisible && <SettingsAdminsModal />}
      {builderModalVisble && <BuilderModal />}
    </Modal>
  );
};
