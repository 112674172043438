import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { cx } from "features/app/utils/helpers/cx";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { useQueryParams } from "hooks/useQueryParams";

import DatepickerSuffixIcon from "../../../../../../app/assets/icons/DatepickerSuffixIcon";
import CalendarCheckIcon from "../../../../../assets/icons/CalendarCheckIcon";

import styles from "./paymentDatePicker.module.scss";

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>>[0];

type Props = {
  className?: string;
  suffixIcon?: React.ReactNode;
  dateQueryParamsKeys?: string[];
  setDateRange?: Dispatch<SetStateAction<{ min_date: string; max_date: string }>>;
  dateRange?: { min_date: string; max_date: string };
};

const PaymentDatePicker: React.FC<Props> = ({
  className,
  suffixIcon,
  dateQueryParamsKeys,
  setDateRange,
  dateRange
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { appends } = useQueryParams();
  const { t } = useTranslation();

  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState({
    visible: false,
    specialVisible: false,
    specialChange: false
  });
  const [special, setSpecial] = useState({ value: [dayjs(), dayjs()], param: "" });

  const onChangeRangePicker = (values: RangeValue, formatString: [string, string]) => {
    const minDate = formatString[0] ? values?.[0]?.format(dayjsFormats.DATE) : "";
    const maxDate = formatString[0] ? values?.[1]?.format(dayjsFormats.DATE) : "";

    setSpecial(() => ({
      value: formatString[0] ? ([values?.[0], values?.[1]] as any) : ["", ""],
      param: JSON.stringify({
        minDate,
        maxDate
      })
    }));

    setDropdown(prev => ({ ...prev, specialChange: true }));

    if (!setDateRange) {
      appends([
        {
          key: dateQueryParamsKeys![0],
          value: minDate as string
        },
        {
          key: dateQueryParamsKeys![1],
          value: maxDate as string
        }
      ]);
    } else {
      setDateRange({ min_date: minDate!, max_date: maxDate! });
    }
  };

  const onChangeDate = (e?: { value: string }) => {
    if (e?.value !== "special") {
      if (e?.value) {
        const { value } = e;
        const urlSearch = new URLSearchParams(location.search);
        const { minDate, maxDate } = JSON.parse(value) as { [key: string]: string };

        if (!setDateRange) {
          urlSearch.set(dateQueryParamsKeys![0], minDate);
          urlSearch.set(dateQueryParamsKeys![1], maxDate);

          navigate({
            search: urlSearch.toString()
          });
        } else {
          setDateRange({ min_date: minDate!, max_date: maxDate! });
        }
      } else {
        const urlSearch = new URLSearchParams(location.search);

        if (!setDateRange) {
          urlSearch.delete(dateQueryParamsKeys![0]);
          urlSearch.delete(dateQueryParamsKeys![1]);

          navigate({
            search: urlSearch.toString()
          });
        } else {
          setDateRange({ min_date: "", max_date: "" });
        }
      }
    } else {
      setDropdown(prev => ({ ...prev, specialVisible: false }));
    }
  };

  const onOpenChangeSpecialPicker = (open: boolean) => {
    if (!open && dropdown?.specialChange) {
      onChangeDate({ value: special.param });
    }

    setDropdown(prev => ({
      ...prev,
      specialVisible: open,
      specialChange: false
    }));
  };

  const dateOptions: DefaultOptionType[] | undefined = [
    {
      value: JSON.stringify({
        minDate: dayjs().format(dayjsFormats.DATE),
        maxDate: dayjs().add(1, "day").format(dayjsFormats.DATE)
      }),
      label: "Bugun"
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("week")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("week").format(dayjsFormats.DATE)
      }),
      label: "Oxirgi hafta"
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("month")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("month").format(dayjsFormats.DATE)
      }),
      label: "Oxirgi oy"
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("year")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("year").format(dayjsFormats.DATE)
      }),
      label: "Oxirgi yil"
    },
    {
      value: "special",
      className: styles.specialOption,
      label: (
        <div
          className={cx("flex cursor-pointer items-center gap-2", styles.specialOption_item)}
          onClick={e => e.stopPropagation()}
        >
          <span>Maxsus</span>
          <DatePicker.RangePicker
            size="small"
            onChange={onChangeRangePicker}
            className={styles.rangePicker}
            open={dropdown?.specialVisible}
            placeholder={["Boshlanishi", "Tugashi"]}
            onOpenChange={onOpenChangeSpecialPicker}
            suffixIcon={<DatepickerSuffixIcon size={20} />}
            format={[dayjsFormats.DATE, dayjsFormats.DATE]}
            value={[special.value[0] as any, special.value[1] as any]}
          />
        </div>
      )
    }
  ];

  const dateValue = () => {
    const urlSearch = new URLSearchParams(location.search);
    const minDate = !setDateRange ? urlSearch.get(dateQueryParamsKeys![0]) : dateRange?.min_date;
    const maxDate = !setDateRange ? urlSearch.get(dateQueryParamsKeys![1]) : dateRange?.max_date;

    if (minDate?.length! > 0 && maxDate?.length! > 0) {
      return {
        value: JSON.stringify({
          minDate,
          maxDate
        })
      };
    }
    return undefined;
  };

  useEffect(() => {
    const urlSearch = new URLSearchParams(location.search);
    const minDate = !setDateRange ? urlSearch.get(dateQueryParamsKeys![0]) : dateRange?.min_date;
    const maxDate = !setDateRange ? urlSearch.get(dateQueryParamsKeys![1]) : dateRange?.max_date;

    if (!dateOptions.find(item => item?.value === dateValue()?.value)) {
      if (minDate?.length! > 0 && maxDate?.length! > 0) {
        setValue(`${minDate} - ${maxDate}`);

        setSpecial({
          param: JSON.stringify({
            minDate,
            maxDate
          }),
          value: [dayjs(minDate, dayjsFormats.DATE), dayjs(maxDate, dayjsFormats.DATE)]
        });
      } else {
        setValue("");
      }
    } else {
      setValue(String(dateOptions.find(item => item?.value === dateValue()?.value)?.label));
    }

    setDropdown(prev => ({
      ...prev,
      visible: false,
      specialVisible: false,
      specialChange: false
    }));
  }, [location.search, dateRange]);

  const onVisibleChangeDropdown = (open: boolean) => {
    if (!dropdown?.specialVisible) {
      setDropdown(prev => ({ ...prev, visible: open, specialChange: false }));
    }
  };

  return (
    <Select
      allowClear
      labelInValue
      placeholder={t("datePicker.Sana")}
      options={dateOptions}
      open={dropdown.visible}
      onChange={onChangeDate}
      popupMatchSelectWidth={false}
      className={className || styles.paymentDatePicker}
      value={value ? { value } : undefined}
      suffixIcon={suffixIcon || <CalendarCheckIcon />}
      onDropdownVisibleChange={onVisibleChangeDropdown}
    />
  );
};

export default PaymentDatePicker;
