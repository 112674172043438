import React, { useMemo } from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { cx, RU } from "modules/common";
import { Column as ColumnType, generateAmounts, getDarkColor, getLightColor, WidgetKeys } from "modules/dashboard";

import { AmountFixer } from "components";

import { WidgetCustomTooltip } from "../tooltip";

type Props = {
  data: ColumnType;
  widgetKey: WidgetKeys;
};

const Column: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const maxAmount = data?.prediction_total > data.total ? data.prediction_total : data.total;

  const createWidgetHeight = (amount: number, max?: number) => `${(amount * 100) / (max || maxAmount)}%`;

  const amounts = useMemo(() => generateAmounts(maxAmount), [maxAmount]);

  const customTooltipStatic = (label: string | Record<string, string>, value: number, color: string) => {
    let name = "";

    if (typeof label === "object") {
      name = label[i18n.language];
    }

    if (typeof label === "string") {
      name = label;
    }

    return (
      <div className="flex flex-col gap-4">
        <span>{name}</span>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-normal text-gray-300">{t("dashboard.Reja summa")}</span>
          <div
            style={{ borderColor: color }}
            className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
          >
            <span className="text-sm font-semibold text-white">{value?.toLocaleString(RU)}</span>
          </div>
        </div>
      </div>
    );
  };

  const customTooltipDynamic = (
    label: string | Record<string, string>,
    predicted_amount: number,
    fact_amount: number,
    color: string
  ) => {
    let name = "";

    if (typeof label === "object") {
      name = label[i18n.language];
    }

    if (typeof label === "string") {
      name = label;
    }

    return (
      <div className="flex flex-col gap-4">
        <span>{name}</span>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <span className="text-sm font-normal text-gray-300">{t("dashboard.Bashorat summa")}</span>
            <div
              style={{ borderColor: color }}
              className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
            >
              <span className="text-sm font-semibold text-white">{predicted_amount.toLocaleString(RU)}</span>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-normal text-gray-300">{t("dashboard.Fakt summa")}</span>
            <div
              style={{ borderColor: color }}
              className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
            >
              <span className="text-sm font-semibold text-white">{fact_amount.toLocaleString(RU)}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="box-border flex h-full flex-col gap-3 pr-[5.375rem]">
      <div className="flex flex-1 gap-10">
        <div className="flex w-36 gap-4">
          <div className="flex h-full flex-col items-end justify-end">
            {amounts?.map(item => (
              <div key={Math.random()} style={{ height: `${Number(createWidgetHeight(item)?.split("%")[0]) + 1000}%` }}>
                <AmountFixer children={item} className="m-0 text-end text-sm font-medium text-gray-600" />
              </div>
            ))}
            <div className="text-end text-sm font-medium text-gray-600">0</div>
          </div>
          <div className="h-full w-0.5 rounded-[0.188rem] bg-primary-500" />
        </div>
        <div className="flex h-full w-full items-end justify-between">
          <div className="flex h-full flex-col justify-end gap-1">
            {data?.data?.map(item => (
              <WidgetCustomTooltip
                isToolTip
                key={item.id}
                content={customTooltipStatic(
                  item.name,
                  item.total,
                  item.color || getLightColor(typeof item?.name === "string" ? item?.name : item?.name[i18n.language])
                )}
              >
                <div
                  key={item.id}
                  className="w-[18.75rem] cursor-pointer rounded-xl"
                  style={{
                    background:
                      item.color ||
                      getLightColor(typeof item?.name === "string" ? item?.name : item?.name[i18n.language]),
                    height: createWidgetHeight(item.total)
                  }}
                />
              </WidgetCustomTooltip>
            ))}
          </div>
          <div className="flex h-full flex-col justify-end gap-1">
            {data?.data?.map(item => (
              <WidgetCustomTooltip
                key={item.id}
                isToolTip
                content={customTooltipDynamic(
                  item.name,
                  item.prediction_total,
                  item.spend_total,
                  item.color || getLightColor(typeof item?.name === "string" ? item?.name : item?.name[i18n.language])
                )}
              >
                <div
                  key={item.id}
                  className="flex w-[18.75rem] cursor-pointer items-end overflow-hidden rounded-xl"
                  style={{
                    background:
                      item.color ||
                      getLightColor(typeof item?.name === "string" ? item?.name : item?.name[i18n.language]),
                    height: createWidgetHeight(item?.prediction_total),
                    transition: "all 0.3s ease-in-out"
                  }}
                >
                  <div
                    className="w-full cursor-pointer"
                    style={{
                      background:
                        item.color ||
                        getDarkColor(typeof item?.name === "string" ? item?.name : item?.name[i18n.language]),
                      height: createWidgetHeight(item.spend_total, item.prediction_total),
                      transition: "all 0.3s ease-in-out"
                    }}
                  />
                </div>
              </WidgetCustomTooltip>
            ))}
          </div>
        </div>
      </div>
      <div className="flex w-full justify-between">
        <div className="flex gap-4">
          <div className="w-36" />
          <div className="box-border flex w-[18.75rem] flex-col gap-2 rounded-xl bg-gray-50 px-4 py-3">
            <p className="m-0 text-base font-medium text-gray-700">{t("dashboard.Statik smeta")}</p>
            <h3 className="m-0 flex items-center gap-1 text-2xl font-medium text-gray-800">
              {data.total?.toLocaleString(RU)}
              <span className="text-sm font-semibold text-gray-400">{data?.currency?.symbol}</span>
            </h3>
          </div>
        </div>
        <div className="box-border flex w-[18.75rem] flex-col gap-2 rounded-xl bg-gray-50 px-4 py-3">
          <div className="m-0 flex items-center gap-2 text-base font-medium text-gray-700">
            <span>{t("dashboard.Dinamik smeta")}</span>
            <div
              className={cx("box-border flex w-max items-center justify-center rounded-2xl px-2 py-0.5", {
                "bg-error-100 text-error-500": data.percent > 0,
                "bg-gray-200 text-gray-500": !data.percent,
                "bg-success-100 text-success-500": data.percent < 0
              })}
            >
              {data.percent || 0} %
            </div>
          </div>
          <h3 className="m-0 flex items-center gap-1 text-2xl font-medium text-gray-800">
            {data.prediction_total?.toLocaleString(RU)}
            <span className="text-sm font-semibold text-gray-400">{data?.currency?.symbol}</span>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default Column;
