import { ReactNode } from "react";

import ArrowNarrowIcon from "../../assets/icons/ArrowNarrowIcon";
import NotificationOrderIcon from "../../assets/icons/NotificationOrderIcon";
import NotificationPaymentIcon from "../../assets/icons/NotificationPaymentIcon";
import NotificationProductIcon from "../../assets/icons/NotificationProductIcon";
import NotificationTransferIcon from "../../assets/icons/NotificationTransferIcon";
import NotificationWorkIcon from "../../assets/icons/NotificationWorkIcon";
import { NotificationTypeEnum } from "../enums/notificationTypeEnum";
import NotificationWorkCreatedIcon from "features/app/assets/icons/NotificationWorkCreatedIcon";
import NotificationWorkCreatedReceived from "features/app/assets/icons/NotificationWorkCreatedReceived";
import NotificationWorkCreatedRejected from "features/app/assets/icons/NotificationWorkCreatedRejected";
import PackageXIcon from "features/inventory/assets/icons/PackageXIcon";

type TitleValueType = "Invetarlar" | "Partiya" | "Kassa" | "Yaroqsiz mahsulotlar" | "O’tkazma" | "Chiqim" | "Ishlar";

export const notificationTypesTitleValue: Record<NotificationTypeEnum, TitleValueType> = {
  [NotificationTypeEnum.INVENTORY_DEFECT_CREATED]: "Invetarlar",
  [NotificationTypeEnum.INVENTORY_DEFECT_RECIEVED]: "Invetarlar",
  [NotificationTypeEnum.INVENTORY_DEFECT_REJECTED]: "Invetarlar",
  [NotificationTypeEnum.INVENTORY_REQUEST_CREATED]: "Invetarlar",
  [NotificationTypeEnum.INVENTORY_REQUEST_RECIEVED]: "Invetarlar",
  [NotificationTypeEnum.INVENTORY_REQUEST_REJECTED]: "Invetarlar",
  [NotificationTypeEnum.ORDER_CREATED]: "Partiya",
  [NotificationTypeEnum.ORDER_PARTIALLY_RECIEVED]: "Partiya",
  [NotificationTypeEnum.ORDER_PENDING]: "Partiya",
  [NotificationTypeEnum.ORDER_RECIEVED]: "Partiya",
  [NotificationTypeEnum.ORDER_REJECTED]: "Partiya",
  [NotificationTypeEnum.PAYMENT_TRANSFER_CREATED]: "Kassa",
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECIEVED]: "Kassa",
  [NotificationTypeEnum.PAYMENT_TRANSFER_REJECTED]: "Kassa",
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECEIVED]: "Kassa",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED]: "Yaroqsiz mahsulotlar",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED]: "Yaroqsiz mahsulotlar",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED]: "Yaroqsiz mahsulotlar",
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_CREATED]: "O’tkazma",
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_RECIEVED]: "O’tkazma",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_ADDITIONAL_CREATED]: "O’tkazma",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_CREATED]: "O’tkazma",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_RECIEVED]: "O’tkazma",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_REJECTED]: "O’tkazma",
  [NotificationTypeEnum.PAYMENT_EXPENSE_PENDING]: "Chiqim",
  [NotificationTypeEnum.PAYMENT_EXPENSE_CREATED]: "Chiqim",
  [NotificationTypeEnum.PAYMENT_EXPENSE_RECIEVED]: "Chiqim",
  [NotificationTypeEnum.PAYMENT_EXPENSE_REJECTED]: "Chiqim",
  [NotificationTypeEnum.CONFIRMATION_ORDER_PENDING]: "Partiya",
  [NotificationTypeEnum.CONFIRMATION_ORDER_RECIEVED]: "Partiya",
  [NotificationTypeEnum.CONFIRMATION_ORDER_REJECTED]: "Partiya",
  [NotificationTypeEnum.CONFIRMATION_WORK_PENDING]: "Ishlar",
  [NotificationTypeEnum.CONFIRMATION_WORK_RECIEVED]: "Ishlar",
  [NotificationTypeEnum.CONFIRMATION_WORK_REJECTED]: "Ishlar",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED]: "Ishlar",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_RECEIVED]: "Ishlar",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_REJECTED]: "Ishlar"
};

export const notificationTypesIcons: Record<string, ReactNode> = {
  [NotificationTypeEnum.ORDER_CREATED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.ORDER_PARTIALLY_RECIEVED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.ORDER_PENDING]: <NotificationOrderIcon />,
  [NotificationTypeEnum.ORDER_RECIEVED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.ORDER_REJECTED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.PAYMENT_TRANSFER_CREATED]: <NotificationPaymentIcon />,
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECIEVED]: <NotificationPaymentIcon />,
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECEIVED]: <NotificationPaymentIcon />,
  [NotificationTypeEnum.PAYMENT_TRANSFER_REJECTED]: <NotificationPaymentIcon />,
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_CREATED]: <NotificationProductIcon />,
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_RECIEVED]: <NotificationProductIcon />,
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_ADDITIONAL_CREATED]: <NotificationTransferIcon />,
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_CREATED]: <NotificationTransferIcon />,
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_RECIEVED]: <NotificationTransferIcon />,
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_REJECTED]: <NotificationTransferIcon />,
  [NotificationTypeEnum.PAYMENT_EXPENSE_PENDING]: <ArrowNarrowIcon rotate={135} />,
  [NotificationTypeEnum.PAYMENT_EXPENSE_CREATED]: <ArrowNarrowIcon rotate={135} />,
  [NotificationTypeEnum.PAYMENT_EXPENSE_RECIEVED]: <ArrowNarrowIcon rotate={135} />,
  [NotificationTypeEnum.PAYMENT_EXPENSE_REJECTED]: <ArrowNarrowIcon rotate={135} />,
  [NotificationTypeEnum.CONFIRMATION_ORDER_PENDING]: <NotificationOrderIcon />,
  [NotificationTypeEnum.CONFIRMATION_ORDER_RECIEVED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.CONFIRMATION_ORDER_REJECTED]: <NotificationOrderIcon />,
  [NotificationTypeEnum.CONFIRMATION_WORK_PENDING]: <NotificationWorkIcon />,
  [NotificationTypeEnum.CONFIRMATION_WORK_RECIEVED]: <NotificationWorkIcon />,
  [NotificationTypeEnum.CONFIRMATION_WORK_REJECTED]: <NotificationWorkIcon />,
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED]: <NotificationWorkCreatedIcon />,
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_RECEIVED]: <NotificationWorkCreatedReceived />,
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_REJECTED]: <NotificationWorkCreatedRejected />,
  [NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED]: <PackageXIcon  />,
  [NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED]: <PackageXIcon  />,
  [NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED]: <PackageXIcon  />
};

export const notificationWithoutIcons = [
  NotificationTypeEnum.INVENTORY_DEFECT_CREATED,
  NotificationTypeEnum.INVENTORY_DEFECT_RECIEVED,
  NotificationTypeEnum.INVENTORY_DEFECT_REJECTED,
  NotificationTypeEnum.INVENTORY_REQUEST_CREATED,
  NotificationTypeEnum.INVENTORY_REQUEST_RECIEVED,
  NotificationTypeEnum.INVENTORY_REQUEST_REJECTED,
  NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED,
  NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED,
  NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED
];

type classNameType = "pending" | "recieved" | "rejected" | "created";

export const notificationClassNames: Record<string, classNameType> = {
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_CREATED]: "pending",
  [NotificationTypeEnum.ORDER_PENDING]: "pending",
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_CREATED]: "pending",
  [NotificationTypeEnum.PAYMENT_TRANSFER_CREATED]: "pending",
  [NotificationTypeEnum.INVENTORY_DEFECT_CREATED]: "pending",
  [NotificationTypeEnum.PAYMENT_EXPENSE_CREATED]: "pending",
  [NotificationTypeEnum.CONFIRMATION_ORDER_PENDING]: "pending",
  [NotificationTypeEnum.CONFIRMATION_WORK_PENDING]: "pending",
  [NotificationTypeEnum.ORDER_RECIEVED]: "recieved",
  [NotificationTypeEnum.INVENTORY_DEFECT_RECIEVED]: "recieved",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_RECIEVED]: "recieved",
  [NotificationTypeEnum.ORDER_CREATED]: "recieved",
  [NotificationTypeEnum.PAYMENT_EXPENSE_RECIEVED]: "recieved",
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECIEVED]: "recieved",
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECEIVED]: "recieved",
  [NotificationTypeEnum.CONFIRMATION_ORDER_RECIEVED]: "recieved",
  [NotificationTypeEnum.CONFIRMATION_WORK_RECIEVED]: "recieved",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_REJECTED]: "rejected",
  [NotificationTypeEnum.ORDER_REJECTED]: "rejected",
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_ADDITIONAL_CREATED]: "rejected",
  [NotificationTypeEnum.ORDER_PARTIALLY_RECIEVED]: "rejected",
  [NotificationTypeEnum.PAYMENT_TRANSFER_REJECTED]: "rejected",
  [NotificationTypeEnum.INVENTORY_DEFECT_REJECTED]: "rejected",
  [NotificationTypeEnum.PAYMENT_EXPENSE_REJECTED]: "rejected",
  [NotificationTypeEnum.CONFIRMATION_ORDER_REJECTED]: "rejected",
  [NotificationTypeEnum.CONFIRMATION_WORK_REJECTED]: "rejected",
  [NotificationTypeEnum.PAYMENT_EXPENSE_PENDING]: "created",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED]: "created",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_RECEIVED]: "recieved",
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_REJECTED]: "rejected",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED]: "created",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED]: "recieved",
  [NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED]: "rejected"
};
