import { useQuery } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { tableDataEndpoints } from "../constants";
import { dashboardQueryKeys } from "../query-keys";
import { TableDataTypeWidgetKey } from "../schema";

interface Query<TKey extends keyof TableDataTypeWidgetKey> {
  data: PaginationType<TableDataTypeWidgetKey[TKey][]>;
}

interface Params {
  page: number;
  widgetKey: keyof TableDataTypeWidgetKey;
  id: number;
}

export const useGetTableWidgetData = <TKey extends keyof TableDataTypeWidgetKey>({ page, widgetKey, id }: Params) => {
  const initialValue: Query<TKey> = { data: { data: [], current_page: 1, total: 0 } };

  const { data = initialValue, ...args } = useQuery<Query<TKey>>(
    [dashboardQueryKeys.TABLE_WIDGET_DATA, widgetKey, page],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/${tableDataEndpoints[widgetKey]}/view?id=${id}&page=${page}&size=5`);

      return { data };
    },
    { enabled: page > 1 }
  );

  return { data, ...args };
};
