import { PaymentStatusEnums } from "../../../app/utils/enums/paymentStatusEnums";
import { paymentTypeColors } from "../../utils/constants/paymentTypeColors";

type Props = {
  status?: PaymentStatusEnums;
  stroke?: string;
};

const SwitchHorizontalIcon = ({ status, stroke }: Props) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6673 14.1667H3.33398M3.33398 14.1667L6.66732 10.8333M3.33398 14.1667L6.66732 17.5M3.33398 5.83333H16.6673M16.6673 5.83333L13.334 2.5M16.6673 5.83333L13.334 9.16667"
      stroke={status ? paymentTypeColors[status] : stroke || "#273B42"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SwitchHorizontalIcon;
