import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectModel } from "features/projects/utils/models/projectModel";

type InitialStateModel = {
  data?: ProjectModel | undefined;
  visible: boolean;
};

const initialState: InitialStateModel = {
  visible: false
};

const projectReportModalSlice = createSlice({
  name: "projectReportModal",
  initialState,
  reducers: {
    setVisible: (state, action: PayloadAction<{ data: ProjectModel | undefined; visible: boolean }>) => {
      state.data = action.payload.data;
      state.visible = action.payload.visible;
    }
  }
});

export default projectReportModalSlice.reducer;
export const projectReportModalActions = projectReportModalSlice.actions;
