import React, { useMemo, useState } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { useTranslation } from "react-i18next";

import { CalendarItemModel } from "../../../../utils/models/paymentCalendarModel";

import OrderOrPaymentAction from "./OrderOrPaymentAction";
import PaymentCalendarItem from "./PaymentCalendarItem";

import styles from "./paymentCalendarContent.module.scss";

type Props = {
  index: number;
  date: string;
  isCompanyPerson?: boolean;
  data: CalendarItemModel[];
};

const PaymentCalendarCard: React.FC<Props> = ({ index, data, date, isCompanyPerson = false }) => {
  const { t } = useTranslation();
  const isToday = date === dayjs().format(dayjsFormats.DATE);
  const [isOpenOtherCards, setIsOpenOtherCards] = useState(false);

  const onOpenOtherCards = () => {
    setIsOpenOtherCards(!isOpenOtherCards);
  };

  const generateData = useMemo(() => {
    if (isOpenOtherCards) {
      return data;
    }
    return data?.slice(0, 2);
  }, [isOpenOtherCards, data]);

  return (
    <div className={classNames(styles.card, { [styles.today]: isToday })}>
      <div className={styles.card__index}>
        <div className={styles.card__index__left}>
          <span>{index}</span>
          <OrderOrPaymentAction date={date} />
        </div>
        <ConditionalRender if={isToday}>
          <span>{t("payment.Bugun")}</span>
        </ConditionalRender>
      </div>
      <div className={styles.card__bottom}>
        {generateData?.map(item => <PaymentCalendarItem key={item.id} {...item} isCompanyPerson={isCompanyPerson} />)}
        <ConditionalRender if={data?.length > 2}>
          <div className={styles.open_other_card} onClick={onOpenOtherCards}>
            {isOpenOtherCards ? "- " : "+ "}
            {data?.slice(2, data?.length)?.length} ta
            {isOpenOtherCards ? ` ${t("payment.yopish")}` : ` ${t("payment.ochish")}`}
          </div>
        </ConditionalRender>
      </div>
    </div>
  );
};

export default PaymentCalendarCard;
