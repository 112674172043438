import React, { SetStateAction, useEffect } from "react";
import { Form, Radio, Spin, Tooltip } from "antd";
import { useGetCashMoneySelect } from "features/payment/service/queries";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { isEmptyArr } from "modules/common";

import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { PaymentCashMoneyModel } from "../../../../utils/models/paymentCashMoneyModel";

import styles from "./firstStep.module.scss";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<SetStateAction<boolean>>;
  visible: boolean;
};

const { Item } = Form;

const FirstStep: React.FC<Props> = ({ setStep, autoAdvance, setAutoAdvance, visible }) => {
  const dispatch = useAppDispatch();
  const { setPaymentOrderModal } = paymentReducerActions;
  const payload = useAppSelector(state => state.paymentReducer.paymentOrderModal);
  const { type, cash } = payload;

  const {
    data: cashes,
    isLoading: isLoadingCash,
    isFetching
  } = useGetCashMoneySelect(visible, { type: type || "expense" });

  const onChangeCash = (e: number) => {
    const selectCash = cashes?.find(item => item.id === e);

    dispatch(
      setPaymentOrderModal({
        ...payload,
        type,
        cash: selectCash
      })
    );
    setAutoAdvance(true);
    setStep(1);
  };

  const projectsTooltip = (item: PaymentCashMoneyModel) => {
    let tooltipTitle = "";
    const len = item?.projects?.length;

    item?.projects?.slice(1, len! - 1)?.forEach((project, index) => {
      tooltipTitle += `${project?.name}${index + 1 === item?.projects?.slice(1, len! - 1)?.length ? "" : " , "}`;
    });

    return (
      <Tooltip title={tooltipTitle}>
        <div className="flex items-center justify-center rounded-2xl bg-gray-100 px-2 py-1 font-medium text-gray-700">
          +{item?.projects && item?.projects?.slice(1, item?.projects?.length - 1)?.length}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (cashes && cashes.length === 1 && autoAdvance) {
      dispatch(
        setPaymentOrderModal({
          ...payload,
          type,
          cash: cashes[0]
        })
      );

      setStep(prev => {
        if (prev === 0) {
          return prev + 1;
        }
        return prev - 1;
      });
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingCash} indicator={LoadingIndicator}>
      {isEmptyArr(cashes) ? (
        <TableEmpty description="Kassalar mavjud emas" />
      ) : (
        <Item name="cash_id">
          <Radio.Group value={cash?.id}>
            <div className={styles.cash}>
              {cashes?.map(item => (
                <Radio key={item.id} value={item.id} onClick={() => onChangeCash(item.id)}>
                  <div className={styles.item}>
                    <h3>{item.name}</h3>
                    <ConditionalRender if={!isEmptyArr(item?.projects)}>
                      <div className="flex items-center gap-2">
                        <span className={styles.project_name}>{`${item?.projects![0]?.name}`}</span>
                        {item?.projects && item?.projects?.length > 2 && projectsTooltip(item)}
                      </div>
                    </ConditionalRender>
                    {/* <p>Kassa</p> */}
                    <div className={styles.currency}>
                      {item?.currencies?.map(currency => (
                        <div className={styles.currency__item} key={currency?.id}>
                          <img src={currency?.icon} alt={currency?.symbol} />
                          <span>
                            {currency?.amount?.toLocaleString("ru")} {currency?.symbol}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </Radio>
              ))}
            </div>
          </Radio.Group>
        </Item>
      )}
    </Spin>
  );
};

export default FirstStep;
