import React, { useState } from "react";
import dayjs from "dayjs";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { dayNames } from "features/app/utils/constants/dayNames";

import { cx } from "modules/common";

import cls from "./left.module.scss";

const Left: React.FC = () => {
  const [currentTime, setCurrentTime] = useState<string>(dayjs().format(dayjsFormats.TIME));

  const className = React.useMemo(() => {
    const hour = Number(currentTime.split(":")[0]);

    switch (true) {
      case hour >= 4 && hour < 6:
        return cls.bg_0406;
      case hour >= 6 && hour < 10:
        return cls.bg_0610;
      case hour >= 10 && hour < 18:
        return cls.bg_1018;
      case (hour >= 18 && hour < 24) || (hour < 0 && hour > 4):
        return cls.bg_1804;
      default:
        return "";
    }
  }, [currentTime]);

  React.useEffect(() => {
    const intervalID = window.setInterval(() => setCurrentTime(dayjs().format(dayjsFormats.TIME)), 1000);

    return () => window.clearInterval(intervalID);
  }, []);

  return (
    <div className={cx(cls.left, className)}>
      <div className={cls.top}>
        <h1>{currentTime}</h1>
        <div className={cls.day_name}>
          <span>{dayjs().format(dayjsFormats.DATE)}</span>
          <div className={cls.day_name__circle} />
          <span>
            {dayNames[dayjs().format(dayjsFormats.DATE_NAME) as keyof typeof dayNames] ??
              dayjs().format(dayjsFormats.DATE_NAME)}
          </span>
        </div>
      </div>
      <div className={cls.bottom}>
        <h3>Uyqur - qurilish obyektlarini boshqarishning zamonaviy usulini kashf eting!</h3>
        <p>"Uyqur jamoasi"</p>
      </div>
    </div>
  );
};

export default Left;
