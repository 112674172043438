import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

interface IntegrationSliceModel {
  settingsModal: IntegrationSettingsModel;
  projectsConnectModal: ProjectsConnectModel;
}

interface IntegrationSettingsModel {
  visible: boolean;
  data?: any;
}

interface ProjectsConnectModel {
  visible: boolean;
  data?: any;
}

const initialState: IntegrationSliceModel = {
  settingsModal: { visible: false, data: [] },
  projectsConnectModal: { visible: false, data: [] },
};

const integrationSlice = createSlice({
  name: sliceNames.INTEGRATION,
  initialState,
  reducers: {
    setSettingsData: (state, action: PayloadAction<IntegrationSettingsModel>) => {
      state.settingsModal.visible = action.payload.visible;
      state.settingsModal.data = action.payload.data;
    },
    setProjectsConnectData: (
      state,
      action: PayloadAction<IntegrationSettingsModel>
    ) => {
      state.projectsConnectModal.visible = action.payload.visible;
      state.projectsConnectModal.data = action.payload.data;
    },
  },
});

export default integrationSlice.reducer;
export const integrationActions = integrationSlice.actions;
