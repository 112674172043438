import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useWidget } from "../hooks";
import { dashboardQueryKeys } from "../query-keys";
import { ProjectColumnProject } from "../schema";

type Query = {
  projects: ProjectColumnProject[];
};

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
}

export function useProjectColumnProjects({ id, enabled }: Params) {
  const { methods } = useWidget();

  const initialData = {
    projects: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_COLUMN_PROJECTS],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-section/project-view?id=${id}`);

      return { projects: data };
    },
    {
      enabled,
      onSuccess: ({ projects }) => {
        methods.setWidgetDrawer({
          props: {
            projectId: projects?.[0]?.id
          }
        });
      }
    }
  );

  return { ...data, ...arg };
}
