import React from "react";
import { useTranslation } from "react-i18next";
import { isImage, onDownloadFile } from "./ChatMessage";
import { Button, Image } from "antd";
import { ChatModel } from "features/app/utils/models/chatModel";
import UploadFileIcon from "features/warehouse/assets/icons/UploadFileIcon";
import { colors, cx } from "modules/common";

import styles from "./chat.module.scss";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";

type Props = Pick<ChatModel, "files" | "message"> & {
  onOpenFileViewer: (format: string, url: string, name: string) => void;
  hour: string;
  isActiveUser: boolean;
};

const FilesAndMessage: React.FC<Props> = ({ files, hour, message, onOpenFileViewer, isActiveUser }) => {
  const { t } = useTranslation();
  return (
    <div>
      {message && files?.length > 0 && (
        <div
          className={cx(
            "flex flex-col gap-2  rounded-b-lg rounded-tr-lg border border-solid border-white bg-white px-[0.875rem] py-[0.625rem] shadow-sm",
            isActiveUser && "rounded-tl-lg rounded-tr-none bg-primary-500"
          )}
        >
          <div className="flex min-h-max flex-col gap-4 overflow-y-auto">
            {files.map(file => (
              <div key={file.id}>
                {isImage(file.name) ? (
                  <div className="flex gap-3">
                    <div className={cx(styles.image_container, "relative cursor-pointer")}>
                      <Image
                        rootClassName={styles.image}
                        src={file.name}
                        alt={file.name}
                        className={styles.image}
                        preview={{
                          destroyOnClose: true,
                          imageRender: () => (
                            <div className="flex h-full w-full flex-col gap-32 py-8">
                              <div className="flex w-[96%] items-center justify-end">
                                <Button type="primary" onClick={() => onDownloadFile(file.name)}>
                                  <DownloadIcon stroke={colors.WHITE} /> {t("chat.Yuklab olish")}
                                </Button>
                              </div>
                              <div className="flex w-full  flex-col items-center justify-center">
                                <img className="w-[25%]" src={file.name} alt={file.name} />
                              </div>
                            </div>
                          ),
                          toolbarRender: () => null
                        }}
                      />
                    </div>
                    <div>
                      <h4 className={cx("text-sm font-medium text-gray-700", isActiveUser && "text-white")}>
                        {file.original_name}
                      </h4>
                      <p className={cx("text-sm font-normal text-gray-400", isActiveUser && "text-white")}>
                        {(file.size / 1024 / 1000).toFixed(3)} {t("chat.MB")}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex gap-3">
                    <div
                      className="cursor-pointer"
                      onClick={() => onOpenFileViewer(file.original_name?.split(".")[1], file.name, file.original_name)}
                    >
                      <UploadFileIcon />
                    </div>
                    <div>
                      <h4 className={cx("text-sm font-medium text-gray-700", isActiveUser && "text-white")}>
                        {file.original_name}
                      </h4>
                      <p className={cx("text-sm font-normal text-gray-400", isActiveUser && "text-white")}>
                        {(file.size / 1024 / 1000).toFixed(3)} {t("chat.MB")}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className={cx("text-base font-normal text-gray-700", isActiveUser && "text-white")}>{message}</div>
          <p
            className={cx("flex items-center justify-end text-[0.625rem] text-gray-400", isActiveUser && "text-white")}
          >
            {hour}
          </p>
        </div>
      )}
    </div>
  );
};

export default FilesAndMessage;
