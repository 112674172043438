import React from "react";

const UnitIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6668 11.668C16.6668 15.3499 13.6821 18.3346 10.0002 18.3346C6.31826 18.3346 3.3335 15.3499 3.3335 11.668C3.3335 10.7839 3.50559 9.94 3.81809 9.16797C4.80707 6.72478 10.0002 1.66797 10.0002 1.66797C10.0002 1.66797 15.1933 6.72478 16.1822 9.16797C16.4947 9.94 16.6668 10.7839 16.6668 11.668Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UnitIcon;
