import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Select, Switch } from "antd";

import {
  useGetActivityType,
  useGetFinancialCategory,
} from "../../../service/queries";
import {
  FinancialFormModel,
  FinancialModalPropsModel,
} from "../../../utils/models/financialModel";
import { formRules } from "../../../../app/utils/constants/formRules";
import { useCreateFinancial, useUpdateFinancial } from "../../../service/mutations";

import styles from "./settingsFinancialModal.module.scss";
import { Status } from "../../../../app/utils/models/status";

type Props = {
  modalProps?: FinancialModalPropsModel;
  setModalProps: (value: FinancialModalPropsModel) => void;
};

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;

const SettingsFinancialModal: React.FC<Props> = ({ modalProps, setModalProps }) => {
  const [form] = Form.useForm<FinancialFormModel>();
  const { data: categories, isLoading: isLoadingCategory } = useGetFinancialCategory(
    modalProps?.type
  );
  const { data: types, isLoading: isLoadingType } = useGetActivityType(
    modalProps?.type
  );
  const { i18n, t } = useTranslation();

  const onAfterOpenModal = () => {
    if (modalProps && modalProps.open) {
      form.setFieldsValue({
        ...modalProps.oneFinancial,
        status: modalProps.status === Status.ACTIVE,
        activity_type_id: modalProps.oneFinancial?.activity_type?.id,
        financial_category_id: modalProps.oneFinancial?.financial_category?.id,
      });
    }
  };

  const onCancel = () => {
    setModalProps({
      ...modalProps!,
      open: false,
    });
    form.resetFields();
  };

  const updateFinancial = useUpdateFinancial(onCancel);
  const createFinancial = useCreateFinancial(onCancel);

  const onFinish = (values: FinancialFormModel) => {
    const financial_id = modalProps?.oneFinancial?.id;

    if (financial_id) {
      updateFinancial.mutate({
        ...values,
        id: financial_id,
        status: values.status ? Status.ACTIVE : Status.PASSIVE,
      });
    } else {
      createFinancial.mutate({
        ...values,
        status: values.status ? Status.ACTIVE : Status.PASSIVE,
      });
    }
  };

  return (
    <Modal
      className={styles.financial_modal}
      open={modalProps?.open}
      title={modalProps?.title}
      onCancel={onCancel}
      afterOpenChange={onAfterOpenModal}
      onOk={() => form.submit()}
      okButtonProps={{
        loading: createFinancial.isLoading || updateFinancial.isLoading,
      }}
      cancelText={t("products.Yopish")}
      okText={t("products.Saqlash")}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" label={t("products.Nomi")} rules={formRules()}>
          <Input placeholder={t("products.Nomini kiriting")} />
        </Item>
        <Item
          name="financial_category_id"
          label={t("Xarajat.Turkumi")}
          rules={formRules()}
        >
          <Select
            placeholder={t("products.Turkumni tanlang")}
            loading={isLoadingCategory}
          >
            {categories?.data?.map((category, index) => (
              <Option key={index} value={category.id}>
                {category.name[i18n.language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item
          name="activity_type_id"
          label={t("Xarajat.Faoliyat turi")}
          rules={formRules()}
        >
          <Select
            placeholder={t("products.Turkumni tanlang")}
            loading={isLoadingType}
          >
            {types?.data?.map((category, index) => (
              <Option key={index} value={category.id}>
                {category.name[i18n.language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="description" label={t("Xodimlar.Izohi")}>
          <TextArea placeholder={t("products.Nomini kiriting")} />
        </Item>
        <Item
          name="status"
          initialValue={true}
          valuePropName="checked"
          label={t("Xodimlar.Joriy holati")}
        >
          <Switch />
        </Item>
      </Form>
    </Modal>
  );
};

export default SettingsFinancialModal;
