/* eslint-disable react-refresh/only-export-components */
import { Permission, SubmoduleKey } from "modules/auth";

import { customLazy } from "../../features/app/components/custom-lazy/CustomLazy";
import { RouteModel } from "../../features/app/utils/models/RouteModel";

import { settingsPaths } from "./settingsPaths";

const Admins = customLazy(() => import("../../features/settings/pages/admins/Index"));
const Products = customLazy(() => import("../../features/settings/pages/products/Index"));
const Interface = customLazy(() => import("../../features/settings/pages/interface/Index"));
const Cash = customLazy(() => import("../../features/settings/pages/cash/Index"));
const Warehouse = customLazy(() => import("../../features/settings/pages/warehouse/Index"));
// const Financial = customLazy(
//   () => import("../../features/settings/pages/financial/Index")
// );
const Integration = customLazy(() => import("../../features/settings/pages/integration/Index"));
const Finance = customLazy(() => import("../../features/settings/pages/finance/Index"));
const DetailedWarehouse = customLazy(() => import("../../features/settings/pages/detailed-warehouse/Index"));
const BackUp = customLazy(() => import("../../features/settings/pages/back-up/Index"));
const DetailedCash = customLazy(() => import("../../features/settings/pages/detailed-cash/Index"));
const SubCategory = customLazy(() => import("../../features/settings/pages/sub-categories/Index"));
const DetailedSubCategory = customLazy(() => import("../../features/settings/pages/detailed-sub-category/Index"));

const CustomField = customLazy(() => import("../../features/settings/pages/custom-field/Index"));

const Template = customLazy(() => import("../../features/settings/pages/template/Index"));

const Builder = customLazy(() => import("../../features/settings/pages/builder/Index"));

const Archive = customLazy(() => import("../../features/settings/pages/archive/Index"));

export const settingRoutes: RouteModel<SubmoduleKey>[] = [
  {
    element: Products,
    path: settingsPaths.PRODUCTS,
    isExact: true,
    permissionName: "Kategoriyalar"
  },
  {
    element: Finance,
    path: settingsPaths.FINANCE,
    isExact: true,
    permissionName: "Moliya"
  },
  {
    element: Cash,
    path: settingsPaths.CASH,
    isExact: true,
    permissionName: "Kassa"
  },
  {
    element: Warehouse,
    path: settingsPaths.WAREHOUSE,
    isExact: true,
    permissionName: "Omborxona"
  },
  {
    element: Admins,
    path: settingsPaths.ADMINS,
    isExact: false,
    permissionName: "Xodimlar"
  },
  {
    element: CustomField,
    path: settingsPaths.CUSTOM_FIELD,
    isExact: true,
    permissionName: "O'zgaruvchilar"
  },
  {
    element: Template,
    path: settingsPaths.TEMPLATE,
    isExact: true,
    permissionName: "Hujjatlar"
  },
  {
    element: Interface,
    path: settingsPaths.INTERFACE,
    isExact: true
  },

  {
    element: DetailedCash,
    path: settingsPaths.DETAILED_CASH,
    isExact: true,
    permissionModuleName: "Kassa",
    permissionName: "Kassa folderlari ro'yxati",
    isDetailed: true
  },

  // {
  //   element: Financial,
  //   path: settingsPaths.FINANCIAL,
  //   isExact: true,
  //   permissionName: "Xarajat va daromad",
  // },
  {
    element: Integration,
    path: settingsPaths.INTEGRATION,
    isExact: true
  },

  {
    element: DetailedWarehouse,
    path: settingsPaths.DETAILED_WAREHOUSE,
    isExact: true,
    isDetailed: true,
    permissionModuleName: "Omborxona",
    permissionName: "Papkalar ro'yxati"
  },
  {
    element: BackUp,
    path: settingsPaths.BACK_UP,
    isExact: true
  },

  {
    element: SubCategory,
    path: settingsPaths.SUBCATEGORY,
    isExact: true,
    isDetailed: true,
    permissionModuleName: "Kategoriyalar",
    permissionName: "Kategoriyalarni ko'rish"
  },
  {
    element: DetailedSubCategory,
    path: settingsPaths.DETAILED_SUBCATEGORY,
    isExact: true,
    isDetailed: true,
    permissionModuleName: "Kategoriyalar",
    permissionName: "Subkategoriyalarni ko'rish"
  },
  {
    element: Builder,
    path: settingsPaths.BUILDERS,
    isExact: true,
    permissionName: "Quruvchi"
  },
  {
    element: Archive,
    path: settingsPaths.ARCHIVE,
    isExact: false,
    permissionName: "Arxiv"
  }
];

export const settingsRoutes = ({ permissions }: { permissions?: Permission[] }) => {
  const isAccessSettings = permissions?.some(item => item.name?.uz.includes("Sozlamalar"));

  const routes: RouteModel[] = [
    {
      element: Interface,
      path: settingsPaths.INTERFACE,
      isExact: true
    }
  ];

  if (isAccessSettings) {
    const settingsPermission = permissions?.find(item => item.name?.uz.includes("Sozlamalar"))?.submodules;

    settingRoutes?.forEach(route => {
      if (route.isDetailed) {
        const findPermission = settingsPermission?.find(item =>
          item.name.uz.includes(route.permissionModuleName! || route.permissionName)
        );

        const findAction = findPermission?.actions?.some(item => item.name?.uz === route.permissionName!);

        if (findAction) routes.push(route);
      } else {
        const somePermission = settingsPermission?.some(item => item.name?.uz === route.permissionName!);

        if (somePermission) routes.push(route);
      }
    });
  }

  return routes;
};
