import { useExportInventoryDefectExcel } from "features/inventory/service/mutations";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";
import FilterDrawerDefects from "./filter-drawer/FilterDrawerDefects";

import styles from "./top.module.scss";

const Top = () => {
  const exportExcelMutate = useExportInventoryDefectExcel();
  return (
    <div className={styles.top}>
      <p></p>
      <div className={styles.top__actions}>
        <div className={styles.search}>
          <PaymentSearch />
        </div>
        <FilterDrawerDefects exportExcelMutate={exportExcelMutate} />
      </div>
    </div>
  );
};

export default Top;
