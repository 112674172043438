import React from "react";
import { Form, FormInstance, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetCashSelect } from "../../../../../app/service/queries";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { cx } from "../../../../../app/utils/helpers/cx";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import { useGetCashMoneyPaymentTypeSelect } from "../../../../service/queries";

import Additions from "./additions";

type Props = {
  form: FormInstance;
};

const { Item, useWatch } = Form;
const { Option } = Select;

const Bottom: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { visible } = useAppSelector(state => state.paymentReducer.exchange);
  const cashId = useWatch(["income_payment", "cash_id"], form);
  const secondCurrency = JSON.parse(useWatch(["second_currency"], form) || "{}") as CurrencyModel;

  const { data: cashes, isLoading: isLoadingCashes } = useGetCashSelect(visible, true);
  const { data: paymentTypes, isLoading } = useGetCashMoneyPaymentTypeSelect({
    cashId,
    currency_id: secondCurrency?.id,
    enabled: Boolean(cashId && secondCurrency?.id)
  });

  const onChangeCash = () => {
    form.setFieldValue(["income_payment", "payment_type", "id"], undefined);
  };

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-sm font-medium text-gray-400">{t("payment.Kirim konvertatsiya ma’lumotlari")}</h3>
      <div className="box-border flex flex-col gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-4">
        <Item className="hidden" name={["income_payment", "currency_id"]} />
        <Item name={["income_payment", "cash_id"]} label={t("payment.Kassa")} className="m-0" rules={formRules()}>
          <Select
            loading={isLoadingCashes}
            onChange={onChangeCash}
            placeholder={t("payment.Kassa")}
            notFoundContent={<TableEmpty />}
            suffixIcon={<SelectSuffixIcon />}
          >
            {cashes?.map(cash => (
              <Option value={cash?.id} key={cash?.id}>
                {cash?.name}
              </Option>
            ))}
          </Select>
        </Item>
        <div className="grid grid-cols-2 gap-2">
          <Item
            className="m-0"
            rules={formRules()}
            label={t("payment.Summa")}
            name={["income_payment", "payment_type", "amount"]}
          >
            <Input disabled rootClassName="h-12" suffix={secondCurrency?.symbol} placeholder={t("payment.Summa")} />
          </Item>
          <Item
            className="m-0"
            rules={formRules()}
            label={t("payment.To'lov turi")}
            name={["income_payment", "payment_type", "id"]}
          >
            <Select
              className="h-12"
              disabled={!cashId}
              loading={isLoading}
              popupMatchSelectWidth={false}
              notFoundContent={<TableEmpty />}
              suffixIcon={<SelectSuffixIcon />}
              placeholder={t("paymnet.To'lov turi")}
            >
              {paymentTypes?.map(paymentType => (
                <Option key={paymentType?.id} value={paymentType?.id}>
                  <div className="flex flex-col">
                    <div>{paymentType?.name}</div>
                    <div className={cx("flex items-center gap-2", "cash_amount")}>
                      {paymentType?.currencies?.map(item => (
                        <div
                          key={item?.id}
                          className="border-r border-solid border-gray-700 pr-2 text-sm font-normal text-gray-600 last:border-none"
                        >
                          {item?.amount?.toLocaleString(RU)} {item?.symbol}
                        </div>
                      ))}
                    </div>
                  </div>
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <Additions form={form} />
      </div>
    </div>
  );
};

export default Bottom;
