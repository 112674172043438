import React from "react";

const CodepenIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8018_15043)">
        <path
          d="M10.0003 7.50089L3.38688 11.7996C2.81367 12.1722 2.52707 12.3585 2.4278 12.5948C2.34106 12.8012 2.34106 13.0339 2.4278 13.2404M10.0003 7.50089L16.6138 11.7996C17.187 12.1722 17.4736 12.3585 17.5728 12.5948C17.6596 12.8012 17.6596 13.0339 17.5728 13.2404M10.0003 7.50089V2.08422M10.0003 12.5009L3.38688 8.20215C2.81367 7.82956 2.52707 7.64327 2.4278 7.40702C2.34106 7.20056 2.34106 6.96788 2.4278 6.76142M10.0003 12.5009L16.6138 8.20215C17.187 7.82956 17.4736 7.64327 17.5728 7.40702C17.6596 7.20056 17.6596 6.96788 17.5728 6.76142M10.0003 12.5009V17.9176M17.727 13.3119L10.727 17.8619C10.464 18.0329 10.3324 18.1183 10.1908 18.1516C10.0655 18.181 9.93515 18.181 9.8099 18.1516C9.6682 18.1183 9.53669 18.0329 9.27367 17.8619L2.27367 13.3119C2.052 13.1678 1.94117 13.0958 1.86087 12.9997C1.78978 12.9146 1.73638 12.8162 1.7038 12.7102C1.66699 12.5905 1.66699 12.4584 1.66699 12.194V7.8078C1.66699 7.54342 1.66699 7.41123 1.7038 7.29152C1.73638 7.18556 1.78978 7.08717 1.86087 7.0021C1.94117 6.90601 2.052 6.83396 2.27368 6.68988L9.27367 2.13988C9.53669 1.96891 9.6682 1.88343 9.8099 1.85017C9.93515 1.82078 10.0655 1.82078 10.1908 1.85017C10.3324 1.88343 10.464 1.96891 10.727 2.13988L17.727 6.68988C17.9486 6.83396 18.0595 6.90601 18.1398 7.0021C18.2109 7.08717 18.2643 7.18556 18.2969 7.29152C18.3337 7.41123 18.3337 7.54342 18.3337 7.8078V12.194C18.3337 12.4584 18.3337 12.5905 18.2969 12.7102C18.2643 12.8162 18.2109 12.9146 18.1398 12.9997C18.0595 13.0958 17.9486 13.1678 17.727 13.3119Z"
          stroke="#F79009"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_8018_15043">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CodepenIcon;
