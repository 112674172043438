import React from "react";

const UploadFileIcon: React.FC = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="32" height="32" rx="16" fill="#D1E9FF" />
      <path
        d="M19.3337 11.5136V14.2673C19.3337 14.6407 19.3337 14.8274 19.4063 14.97C19.4702 15.0954 19.5722 15.1974 19.6977 15.2613C19.8403 15.334 20.027 15.334 20.4003 15.334H23.154M23.3337 16.6595V21.4673C23.3337 22.5874 23.3337 23.1475 23.1157 23.5753C22.9239 23.9516 22.618 24.2576 22.2416 24.4493C21.8138 24.6673 21.2538 24.6673 20.1337 24.6673H15.867C14.7469 24.6673 14.1868 24.6673 13.759 24.4493C13.3827 24.2576 13.0767 23.9516 12.885 23.5753C12.667 23.1475 12.667 22.5874 12.667 21.4673V14.534C12.667 13.4139 12.667 12.8538 12.885 12.426C13.0767 12.0497 13.3827 11.7437 13.759 11.552C14.1868 11.334 14.7469 11.334 15.867 11.334H18.0082C18.4974 11.334 18.7419 11.334 18.9721 11.3892C19.1762 11.4382 19.3713 11.519 19.5502 11.6287C19.7521 11.7524 19.925 11.9253 20.2709 12.2712L22.3964 14.3967C22.7423 14.7426 22.9153 14.9156 23.0389 15.1174C23.1486 15.2964 23.2294 15.4914 23.2784 15.6955C23.3337 15.9257 23.3337 16.1703 23.3337 16.6595Z"
        stroke="#1E90FF"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="2"
        width="32"
        height="32"
        rx="16"
        stroke="#EFF8FF"
        strokeWidth="4"
      />
    </svg>
  );
};

export default UploadFileIcon;
