import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, FormInstance, FormListOperation, Input, Row, Select } from "antd";
import { Rule } from "antd/es/form";
import ChevroDownIcon from "features/projects/assets/icons/ChevroDownIcon";
import OrderTableHeaderWarehouseSelect from "features/supply/components/orders/order-modal/left/table/items/OrderTableHeaderWarehouseSelect";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { colors } from "modules/common";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../../store/reducers/supplyReducer";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import ProjectSelect from "../../../../../../app/components/project-select/project-select";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { useGetUnitSelect } from "../../../../../../app/service/queries";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { pieceRemoveNaN } from "../../../../../../app/utils/helpers/pieceRemoveNaN";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import FilterSearchIcon from "../../../../../../projects/assets/icons/FilterSearchIcon";
import { OrderFormProductModel } from "../../../../../utils/models/orderModalFormModel";
import CreateProductModal from "../../../../create-product-modal/CreateProductModal";

import OrderProductSelect from "./items/OrderProductSelect";
import OrderWarehouseSelect from "./items/OrderWarehouseSelect";

import styles from "../orderModalLeft.module.scss";

const { List, Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance;
};

const OrderModalTable: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setOrderDrawer } = supplyActions;
  const { data: units } = useGetUnitSelect();
  const searchValue = useWatch("search", form) as string;
  const products = useWatch("task_products", form) as OrderFormProductModel[];
  const cacheProducts = useWatch("cache_products", form) as OrderFormProductModel[];
  const { isEdit } = useAppSelector(state => state.supplyReducer.orderModal);

  const onAddProduct = (operation: FormListOperation) => () => {
    const warehouseId = form.getFieldValue("warehouse_id");
    const projectId = form.getFieldValue("project_id");
    let lastProduct: Partial<OrderFormProductModel> = {
      warehouse_id: undefined,
      project_id: undefined
    };

    if (!isEmptyArr(products)) {
      lastProduct = products[products?.length - 1];
    }

    operation.add({
      id: undefined,
      product_id: undefined,
      unit_id: undefined,
      warehouse_id: warehouseId ?? lastProduct?.warehouse_id,
      project_id: projectId ?? lastProduct?.project_id,
      quantity: undefined,
      row_id: uid(12)
    });
  };

  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const findProduct = products[index];
    const unitValue = units?.find(item => item.id === findProduct.unit_id);
    const removeNaNValue = pieceRemoveNaN(e.currentTarget.value, unitValue?.is_piece);
    const newValue = localeFormatter(removeNaNValue);

    form.setFieldValue(["task_products", index, "quantity"], newValue);

    if (searchValue) {
      const findIndex = cacheProducts?.findIndex(item => item.row_id === findProduct.row_id);

      form.setFieldValue(["cache_products", findIndex, "quantity"], newValue);
    }
  };

  const onDeleteProduct = (index: number) => () => {
    const findProduct = products[index];

    form.setFieldsValue({
      task_products: products?.filter(item => item.row_id !== findProduct?.row_id)
    });

    if (searchValue) {
      form.setFieldsValue({
        cache_products: cacheProducts?.filter(item => item.row_id !== findProduct?.row_id)
      });
    }
    // operation.remove(index);
  };

  const onOpenProductDrawer = () => {
    dispatch(
      setOrderDrawer({
        visible: true
      })
    );
  };

  const isProduct = useCallback(
    (index: number) => {
      const findProduct = Boolean(products?.[index]?.product_id);

      return !findProduct;
    },
    [products]
  );

  const quantityRules: Rule[] = [
    ...formRules()!,
    {
      validator: (_, value: string) => {
        const parseQuantity = parseLocaledString(String(value));

        if (parseQuantity > 0) return Promise.resolve();
        return Promise.reject();
      }
    }
  ];

  const onAfterProductAdded = (
    productId: number,
    unitId: number,
    res: ProductSelectModel,
    fieldName: string | Array<string | number>
  ) => {
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];

    if (Array.isArray(fieldName)) {
      form.setFieldValue([...fieldName, "product_id"], productId);
      form.setFieldValue([...fieldName, "unit_id"], unitId);
      form.setFieldValue(["select_products"], [res, ...oldSelectProducts]);
    }
  };

  const changeSelectItem = (value: unknown, key: string) => {
    form.setFieldsValue({
      task_products: [
        ...(products?.map(item => ({
          ...item,
          [key]: value
        })) || [])
      ]
    });
  };

  const onProjectSelectChange = (e: number) => {
    changeSelectItem(e, "project_id");
  };

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        <Row gutter={0}>
          <Col span={!isEdit ? 8 : 9} className={styles.table__header__item}>
            {t("supply.Resurs turi va nomi")}
          </Col>
          <Col span={3} className={styles.table__header__item}>
            {t("supply.Birligi")}
          </Col>
          <Col span={3} className={styles.table__header__item}>
            {t("supply.Soni")}
          </Col>
          <Col span={4} className={styles.table__header__item}>
            <OrderTableHeaderWarehouseSelect changeSelectItem={changeSelectItem} />
          </Col>
          <Col span={5} className={styles.table__header__item}>
            <ProjectSelect
              onChange={onProjectSelectChange}
              mode="state"
              value={undefined}
              isNotValue={true}
              allowClear={false}
              suffixIcon={<ChevroDownIcon active={true} stroke={colors.GRAY_500} />}
              placeholder={<span className='text-gray-600'>{t("supply.Loyiha")}</span>}
              isCustomLabel={true}
            />
          </Col>
          {!isEdit && <Col span={1} className={styles.table__header__item} />}
        </Row>
      </div>
      <Item name="select_search" className="hidden" />
      <List name="cache_products">{() => <></>}</List>
      <List name="select_products">{() => <></>}</List>
      <List name="task_products">
        {(fields, operation) => (
          <>
            <div className={styles.table__content}>
              {fields?.map((_, index) => (
                <Row gutter={0} key={index} className={styles.table__content__row}>
                  <Item name={[index, "row_id"]} className="hidden" />
                  <Item name={[index, "amount"]} className="hidden" />
                  <Col
                    span={!isEdit ? 8 : 9}
                    className={`${styles.table__content__item} ${styles.table__content__resource}`}
                  >
                    <OrderProductSelect key={index} index={index} form={form} />
                    {isProduct(index) && (
                      <div className={styles.add_resource} onClick={onOpenProductDrawer}>
                        <FilterSearchIcon />
                      </div>
                    )}
                  </Col>
                  <Col span={3} className={styles.table__content__item}>
                    <Item name={[index, "unit_id"]} rules={formRules()}>
                      <Select
                        showSearch
                        suffixIcon={null}
                        placeholder={t("supply.Birligi")}
                        popupMatchSelectWidth={false}
                        filterOption={selectFilterOption}
                        notFoundContent={<TableEmpty description="Kerakli resurs topilmadi" />}
                      >
                        {units?.map(item => (
                          <Option
                            key={item.id}
                            value={item.id}
                            props={{
                              name: item?.symbol[i18n.language]
                            }}
                          >
                            {item.symbol[i18n.language]}
                          </Option>
                        ))}
                      </Select>
                    </Item>
                  </Col>
                  <Col span={3} className={styles.table__content__item}>
                    <Item name={[index, "quantity"]} rules={quantityRules}>
                      <Input
                        placeholder={t("supply.Soni")}
                        autoComplete="off"
                        onChange={e => onChangeCount(e, index)}
                      />
                    </Item>
                  </Col>
                  <Col span={4} className={styles.table__content__item}>
                    <OrderWarehouseSelect key={index} index={index} form={form} />
                  </Col>
                  <Col span={5} className={styles.table__content__item}>
                    <div className={styles.total}>
                      <ProjectSelect label={null} suffixIcon={null} name={[index, "project_id"]} form={form} />
                    </div>
                  </Col>
                  {!isEdit && (
                    <Col span={1} className={styles.table__content__item}>
                      <div className={cx(styles.delete)} onClick={onDeleteProduct(index)}>
                        <DeleteIcon />
                      </div>
                    </Col>
                  )}
                  <Item name={[index, "id"]} className="hidden" />
                </Row>
              ))}
            </div>
            {!searchValue && !isEdit && (
              <div className={styles.table__footer}>
                <div className={styles.add_resource} onClick={onAddProduct(operation)}>
                  <PlusCircleIcon fill />
                  {t("supply.Resurs qo'shish")}
                </div>
              </div>
            )}
          </>
        )}
      </List>
      <CreateProductModal afterFunc={onAfterProductAdded as never} />
    </div>
  );
};

export default OrderModalTable;
