const FileXIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6673 1.8916V5.33372C11.6673 5.80043 11.6673 6.03378 11.7581 6.21204C11.838 6.36885 11.9655 6.49633 12.1223 6.57622C12.3006 6.66705 12.5339 6.66705 13.0007 6.66705H16.4428M7.91732 10.0003L12.084 14.167M12.084 10.0003L7.91732 14.167M11.6673 1.66699H7.33398C5.93385 1.66699 5.23379 1.66699 4.69901 1.93948C4.2286 2.17916 3.84615 2.56161 3.60647 3.03202C3.33398 3.5668 3.33398 4.26686 3.33398 5.66699V14.3337C3.33398 15.7338 3.33398 16.4339 3.60647 16.9686C3.84615 17.439 4.2286 17.8215 4.69901 18.0612C5.23379 18.3337 5.93385 18.3337 7.33398 18.3337H12.6673C14.0674 18.3337 14.7675 18.3337 15.3023 18.0612C15.7727 17.8215 16.1552 17.439 16.3948 16.9686C16.6673 16.4339 16.6673 15.7338 16.6673 14.3337V6.66699L11.6673 1.66699Z"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileXIcon;
