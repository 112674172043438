import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, WarehouseDefect } from "..";

interface Query {
  companyWarehouseDefectView: PaginationType<WarehouseDefect[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id: number;
}

export function useWarehouseDefect({ enabled = false, id }: Params) {
  const initialValue: Query = { companyWarehouseDefectView: { data: [], total: 0, current_page: 1 } };

  const { generateSearchParam, queries } = useQueryParams();
  const { page } = queries();
  const searchParams = generateSearchParam({
    page,
    id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.COMPANY_WAREHOUSE_DEFECT_COUNT, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/warehouse-defect/product-view?${searchParams}`);

      return { companyWarehouseDefectView: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
