import { RU } from "../../../../../../app/utils/constants/languages";
import { DynamicEstimateEnum } from "../../../../../utils/enums/dynamicEstimateEnum";
import { diffFactAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateProductModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverAmount from "../../diff-popover/DiffPopoverAmount";

import styles from "../product.module.scss";

type Props = {
  product: DynamicEstimateProductModel;
  taskStatus?: DynamicEstimateEnum;
  getPopoverContainer?:()=>HTMLElement
};

const SpendAmount = ({ product, taskStatus,getPopoverContainer }: Props) => {
  return (
    <>
      {diffFactAmount(product?.total_amount, product?.spend_total_amount) !== 0 ? (
        <DiffPopoverAmount
          record={product}
          classNames={styles.amount}
          taskStatus={taskStatus}
          isFeature={false}
        />
      ) : (
        <p className={styles.amount}>{product?.amount?.toLocaleString(RU) ?? 0} </p>
      )}
    </>
  );
};

export default SpendAmount;
