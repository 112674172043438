import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { useTranslation } from "react-i18next";

import { Table } from "components";

import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { tableIndex } from "../../../../../../app/utils/helpers/tableIndex";
import ExpectedExpenseIcon from "../../../../../../payment/assets/icons/ExpectedExpenseIcon";
import ExpectedIncomeIcon from "../../../../../../payment/assets/icons/ExpectedIncomeIcon";
import TableInformationsModal from "../../../../../../payment/components/bottom/expected-payment-modal/table-informations-modal/TableInformationsModal";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { ExpectedPaymentTemplateModel } from "../../../../../../payment/utils/models/expectedPaymentTemplateModel";
import { useGetCompanyPersonExpectedTemplatePayment } from "../../../../../service/queries";

import styles from "../../../../../../payment/components/bottom/tables/exected-payment-template/expectedPaymentTemplateTable.module.scss";
import useDebounce from "components/use-debounce/use-debounce";


interface InfoModalDataModel {
  visible: boolean;
  id?: number;
}

type Props = {
  defaultTabKey?: string;
};

const ExpectedTemplatePaymentTable: React.FC<Props> = ({ defaultTabKey }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setPayModal } = paymentReducerActions;
  const { queries } = useQueryParams();
  const { type, statistics_type: statisticsType } = queries();
  const enabled = statisticsType === "expected" || defaultTabKey === PaymentTabKeys.PAYMENT;
  const { data, isLoading } = useGetCompanyPersonExpectedTemplatePayment(enabled);
  const [infoModalData, setInfoModalData] = useState<InfoModalDataModel>({
    visible: false
  });

  const changeTableConfig = useChangeTableConfig(
    type === "income"
      ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_INCOME
      : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_EXPENSE,
    true
  );
  const { data: tableConfig } = useGetTableConfigs(
    type === "income"
      ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_INCOME
      : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_EXPENSE
  );

  const onPayment = (record: ExpectedPaymentTemplateModel) => {
    dispatch(
      setPayModal({
        isTemplate: true,
        visible: true,
        type: type as any,
        form_data: {
          id: record.id,
          currency_id: record.currency.id,
          amount: record.debt_amount,
          description: ""
        }
      })
    );
  };

  const onOpenView = (record: ExpectedPaymentTemplateModel) => {
    setInfoModalData({
      visible: true,
      id: record.id
    });
  };

  const paidAmountPercent = (paidAmount: number, amount: number) => {
    const percentAmount = (paidAmount / amount) * 100;
    const percent = percentAmount || 0;
    const percentFixed = percent?.toFixed(2);
    let color = "#344054";

    if (percent <= 10) {
      color = "#F04438";
    }
    if (percent >= 99) {
      color = "#12B76A";
    }

    return (
      <span
        style={{
          color
        }}
      >
        {percent ? percentFixed : 0} %
      </span>
    );
  };

  const [columns, setColumns] = useState<ColumnsType<ExpectedPaymentTemplateModel>>([
    {
      title: "№",
      render: (_, __, index) => tableIndex(index, data?.current_page),
      width: 120,
      className: "index-column"
    },
    {
      title: `${t("Counterparts.Umumiy summa")}`,
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "currency-column"
    },
    {
      title: `${t("Counterparts.Qoldiq summa")}`,
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.debt_amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "the-other-amount-column"
    },
    {
      title: `${t("Counterparts.To'landi (foizda)")}`,
      render: (_, record) => paidAmountPercent(record.paid_amount, record.amount),
      width: 300,
      className: "currency-column"
    },
    {
      title: `${t("Counterparts.Manbaa")}`,
      render: (record: ExpectedPaymentTemplateModel) => record.project?.name,
      width: 300,
      className: "project-column"
    },
    {
      title: `${t("Counterparts.Xodim")}`,
      render: (_, record) => (
        <div className={`${styles.action} action`}>
          <img src={record.creator.image} alt={record.creator.full_name} className={styles.creator} />
          <Button
            onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
              e.stopPropagation();
              onPayment(record);
            }}
          >
            {type === PaymentTypeEnum.INCOME ? <ExpectedIncomeIcon /> : <ExpectedExpenseIcon />}
            <span>{type === PaymentTypeEnum.INCOME ? "Kirim qilish" : "Qarzni to'lash"}</span>
          </Button>
        </div>
      ),
      width: 250,
      className: "action-column"
    }
  ]);

  useEffect(() => {
    // @ts-ignore
    if (tableConfig?.width_data?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key:
        type === "income"
          ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_INCOME
          : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_TEMPLATE_EXPENSE,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800,timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ExpectedPaymentTemplateModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.pending_payments}>
      <Table<ExpectedPaymentTemplateModel>
        onChangeColumns={onChangeColumns}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        rowKey={row => row.id}
        rowClassName="cursor-pointer"
        onRow={record => ({
          onClick: () => onOpenView(record)
        })}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total ?? 10
          }}
        />
      </div>
      <TableInformationsModal state={infoModalData} setState={setInfoModalData} />
    </div>
  );
};

export default ExpectedTemplatePaymentTable;
