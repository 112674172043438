import { Dropdown } from "antd";

import { useAppDispatch } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import { useDeleteTemplate } from "../../../service/mutations";
import { templateSubmodules } from "../../../utils/constants/templateSubmodules";
import { TemplateModel } from "../../../utils/models/template/templateModel";
import { useTranslation } from "react-i18next";

type Props = {
  record: TemplateModel;
};

const ActionDots = ({ record }: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { setTemplateModal } = settingsActions;
  const deleteTemplate = useDeleteTemplate();
  const routePermissions = useRoutePermissions("Sozlamalar", templateSubmodules);
  const actions = routePermissions.actions("Hujjatlar");

  const onDelete = () => {
    return deleteTemplate.mutateAsync(record?.id);
  };

  const onEdit = (id: number) => {
    dispatch(setTemplateModal({ visible: true, id }));
  };

  const menuItems = (record: TemplateModel) => {
    const defaultItems = [
      {
        key: "0",
        label: (
          <div className="d_f ai_c cg_8" onClick={() => onEdit(record?.id)}>
            <EditIcon />
            {t("settings.Tahrirlash")}
          </div>
        ),
        permissionKey: "update",
      },
      {
        key: "1",
        label: (
          <ModalConfirm onOk={onDelete}>
            <div className="d_f ai_c cg_8">
              <DeleteIcon />
              {t("settings.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        permissionKey: "delete",
      },
    ];

    return { items: defaultItems?.filter((item) => actions[item.permissionKey]) };
  };

  return (
    <Dropdown menu={menuItems(record)} trigger={["click"]}>
      <div className="d_f ai_c jc_c c_p">
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default ActionDots;
