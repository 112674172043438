import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import FilterDrawer from "../../../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetCompanyPersons, useGetSectionsSelect } from "../../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import UserSquareIcon from "../../../../../../payment/assets/icons/UserSquareIcon";
import FilterLinesIcon from "../../../../../../warehouse/assets/icons/FilterLinesIcon";
import ListIcon from "../../../../../assets/icons/ListIcon";
import ZapIcon from "../../../../../assets/icons/ZapIcon";

import styles from "../topActions.module.scss";

const queryStringArr = [
  queryParamsKeys.STATUSES,
  queryParamsKeys.SECTION_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.MAX_DATE,
  queryParamsKeys.MIN_DATE
];

const WorkFilter = () => {
  const { queries } = useQueryParams();
  const { projectId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [sectionsSelected, setSectionsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.SECTION_IDS] as string[] | null
  );
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);
  const { data: companyPersons } = useGetCompanyPersons(open);
  const { data: sections } = useGetSectionsSelect(open, projectId);

  const persons = useMemo(() => {
    const result = companyPersons?.map(item => {
      return { ...item };
    });

    return result;
  }, [companyPersons]);

  const workTypeData = useMemo(() => {
    return [
      {
        id: "completed",
        name: "Tugallangan"
      },
      {
        id: "open",
        name: "Ochiq"
      },
      {
        id: "process",
        name: "Jarayonda"
      }
    ];
  }, []);

  const onOpenFilterDrawer = () => {
    setOpen(true);
  };

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilterDrawer}>
          <FilterLinesIcon />
          Filter
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      queryStringArr={queryStringArr}
      height={165}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        },
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.SECTION_IDS,
          selectedOptions: sectionsSelected
        }
      ]}
      setSelectedOptionsArr={[setStatusSelected, setCompanyPersonsSelected, setSectionsSelected] as never}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={statusSelected}
          setSelectedOptions={setStatusSelected}
          data={workTypeData}
          icon={<ZapIcon />}
          placeholder="Status"
          multiple="multiple"
        />
        <FilterSelect
          selectedOptions={companyPersonsSelected}
          setSelectedOptions={setCompanyPersonsSelected}
          data={persons}
          multiple="multiple"
          icon={<UserSquareIcon />}
          placeholder="Masu'l shaxs"
          showSearch
        />
        <FilterSelect
          selectedOptions={sectionsSelected}
          setSelectedOptions={setSectionsSelected}
          data={sections}
          multiple="multiple"
          icon={<ListIcon />}
          placeholder="Loyiha bo'limlari"
          showSearch
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default WorkFilter;
