import React from "react";
import BaseInput, { InputProps as BaseInputProps } from "antd/lib/input";
import { cx } from "features/app/utils/helpers/cx";

import Wrapper, { BaseProps } from "./wrapper";

interface InputProps extends BaseProps, BaseInputProps {
  name: string;
  type?: "number" | "text";
  loading?: boolean;
}

const Input: React.FC<InputProps> = ({
  labelClassName,
  name = "",
  control,
  disabled,
  loading = false,
  label,
  withLabel,
  withoutMessage,
  ...args
}) => {
  label = label || withLabel ? args.placeholder : null;

  return (
    <Wrapper {...{ control, name, label, labelClassName, withoutMessage, disabled }}>
      {(field, error) => (
        <BaseInput
          {...args}
          {...field}
          id={name}
          value={field.value || undefined}
          status={error ? "error" : undefined}
          className={cx("input", args.className)}
          disabled={disabled || field.disabled || loading}
        />
      )}
    </Wrapper>
  );
};

export default Input;
