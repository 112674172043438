import React from "react";
import { PieTooltipProps, ResponsivePie } from "@nivo/pie";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { colors, numberRound, RU } from "modules/common";
import {
  Bar as BarType,
  CompanyWidget,
  getConditionalColor,
  getConditionalStyle,
  getDarkColor,
  getIcon,
  makeSvgRotate,
  useWidget,
  WidgetKeys,
  widgetStaticData
} from "modules/dashboard";

import { AmountFixer, TooltipShortName } from "components";

import { WidgetCustomTooltip } from "../tooltip";

interface IProps {
  data: BarType;
  widgetKey: WidgetKeys;
  id: number;
  name: Record<string, string>;
  widget: CompanyWidget;
}

const Bar: React.FC<IProps> = ({ data, widgetKey, id, name, widget }) => {
  const { methods } = useWidget();
  const { setWidgetDrawer } = methods;
  const { labels } = widgetStaticData[widgetKey];
  const currency = getBaseCurrency();
  const { t, i18n } = useTranslation();
  const { append } = useQueryParams();

  const total = Math.abs(data.total);

  const chartData =
    data?.values?.length > 0
      ? data?.values?.map((value, i) => ({
          id: value.name,
          label: value.name,
          value: Math.abs(value.total),
          color: value?.color,
          index: i,
          valueId: value?.id
        }))
      : [
          {
            id: "",
            label: "",
            value: true,
            color: colors.PRIMARY_200
          }
        ];

  const chartColors =
    data?.values?.length > 0
      ? data?.values?.map(value => (value?.color ? value?.color : getDarkColor(value?.name)))
      : [colors.PRIMARY_200];

  const customTooltip = (label: React.ReactNode, value: number, color: string) => (
      <div className="flex flex-col gap-4">
        <span className="text-sm font-normal text-gray-300">{label}</span>
        <div
          style={{ borderColor: color }}
          className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
        >
          <span className="text-sm font-semibold text-white">
            {value.toLocaleString(RU)} {currency?.symbol}
          </span>
          {total > 0 && (
            <span style={{ color }} className="w-max rounded-3xl bg-white px-2 py-1">
              {((value / total) * 100)?.toFixed(2)}%
            </span>
          )}
        </div>
      </div>
    );

  const onOpenDrawer = (valueId: number) => {
    append(queryParamsKeys.TAB, valueId, true);
    setWidgetDrawer({
      id,
      visible: true,
      key: widgetKey,
      props: data,
      title: name[i18n.language],
      widget
    });
  };
  
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-8">
      <div className="relative h-3/5 w-full">
        <div className="h-full w-full">
          <ResponsivePie
            fit={true}
            padAngle={0.5}
            data={chartData as never}
            cornerRadius={0}
            innerRadius={0.75}
            colors={chartColors}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            onClick={e => onOpenDrawer(e.data?.valueId)}
            valueFormat={value => String(numberRound(value).toLocaleString(RU))}
            tooltip={(
              props: PieTooltipProps<{
                id: number;
                label: string;
                value: number | boolean;
                index: number;
                valueId: number;
              }>
            ) => (
                <WidgetCustomTooltip
                  children={customTooltip(
                    props.datum.label as string,
                    typeof props.datum.value === "boolean" ? 0 : props.datum.value,
                    props.datum.color
                  )}
                />
              )}
          />
        </div>
        <div className="absolute bottom-1/2 top-1/2 flex w-full flex-col items-center justify-center gap-2">
          <span className="flex items-center justify-center gap-3 text-4xl font-semibold text-black">
            <AmountFixer children={total!} /> {currency?.symbol}
          </span>
          {labels?.length > 0 && (
            <div className="flex items-center gap-2">
              <span
                className="flex items-center justify-center gap-1 rounded-2xl px-2 py-1"
                style={{
                  backgroundColor: getConditionalStyle(data?.percent || 0),
                  borderColor: getConditionalStyle(data?.percent || 0)
                }}
              >
                <span>
                  {getIcon(
                    data?.percent || 0,
                    <ArrowDownIcon
                      width={12}
                      rotate={makeSvgRotate(data?.percent || 0)}
                      stroke={getConditionalColor(data?.percent || 0)}
                    />
                  )}
                </span>
                <span
                  className="text-sm font-medium"
                  style={{
                    color: getConditionalColor(data?.percent || 0) || "black"
                  }}
                >
                  {data?.percent || 0}%
                </span>
              </span>
              <span className="text-sm font-normal text-gray-400">{t(`dashboard.${labels[0]}`)}</span>
            </div>
          )}
        </div>
      </div>
      <div className="grid w-full grid-flow-dense justify-items-center gap-4 sm:grid-cols-3">
        {data?.values?.length > 0 &&
          chartData?.map(el => (
            <div className="flex justify-center gap-[0.563rem]" key={el.id}>
              <span
                className="mt-[0.313rem] h-2.5 w-2.5 rounded-[50%]"
                style={{
                  backgroundColor: el.color || getDarkColor(el?.label),
                  borderColor: el.color || getDarkColor(el?.label)
                }}
              />
              <div className="flex flex-col gap-1">
                <span className="text-sm font-medium text-gray-600">
                  <TooltipShortName title={el.id} length={12} />
                </span>
                <div className="flex gap-1">
                  <AmountFixer
                    children={el.value! as never}
                    symbolClassName="text-base"
                    className="m-0 text-xl font-semibold text-black"
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Bar;
