import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { UseMutationResult } from "@tanstack/react-query";
import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

import CloseIcon from "../../assets/icons/CloseIcon";
import DeleteCircleIcon from "../../assets/icons/DeleteCircleIcon";
import { formRules } from "../../utils/constants/formRules";
import { ErrorRes, SuccessRes } from "../../utils/models/responseType";
import { StatusEnums } from "../statuses/statusEnums";

import styles from "./modalConfirm.module.scss";

type Props = {
  title?: string;
  children?: ReactNode;
  description?: string;
  isDescription?: boolean;
  onOk?: () => Promise<unknown> | undefined;
  payload?: { [key: string]: string | number | undefined };
  mutation?: UseMutationResult<SuccessRes, ErrorRes, any>;
  setVisibleParentModal?: ActionCreatorWithPayload<any>;
  onAfterClose?: () => void;
  visible?: boolean;
  setVisible?: (visible: boolean) => void;
  okText?: string;
  cancelText?: string;
  confirmStatus?: string;
  requestKey?: string;
  getPopupContainer?: () => HTMLElement;
};

const ModalConfirm: React.FC<Props> = ({
  onOk,
  title,
  payload,
  children,
  mutation,
  description,
  onAfterClose,
  isDescription,
  setVisibleParentModal,
  visible = false,
  setVisible,
  okText,
  cancelText,
  confirmStatus,
  requestKey,
  getPopupContainer
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(visible);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const onOpening = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    setOpen(true);
    e.preventDefault();
    e.stopPropagation();
  };

  const onDelete = () => {
    if (isDescription) {
      form.submit();
    } else {
      setIsLoading(true);

      onOk &&
        onOk()?.finally(() => {
          setOpen(false);
          setIsLoading(false);
          form.resetFields();
          onAfterClose && onAfterClose();
        });
    }
  };

  const onFinish = (fields: { description: string }) => {
    if (payload) {
      setIsLoading(true);

      mutation &&
        mutation.mutateAsync({ ...payload, ...fields }).finally(() => {
          setOpen(false);
          setIsLoading(false);

          setVisibleParentModal &&
            dispatch(
              setVisibleParentModal({
                visible: false
              })
            );

          onAfterClose && onAfterClose();
        });
    }
  };

  const onFinishFailed = () => {};

  useEffect(() => {
    setOpen(visible);
  }, [visible]);

  return (
    <>
      {children && (
        <div onClick={onOpening} className={confirmStatus === StatusEnums.REJECTED ? "pointer-events-none" : ""}>
          {children}
        </div>
      )}
      <Modal
        centered
        open={open}
        onOk={onDelete}
        onCancel={onCancel}
        zIndex={9999999999}
        closeIcon={<CloseIcon />}
        title={<DeleteCircleIcon />}
        className={styles.modal_confirm}
        okText={okText ?? t("delete.O'chirish")}
        cancelText={cancelText ?? t("delete.Bekor qilish")}
        getContainer={getPopupContainer}
        okButtonProps={{
          danger: true,
          loading: isLoading
        }}
        afterOpenChange={open => {
          form.resetFields();
          setVisible && setVisible(open);
        }}
      >
        <div className={styles.modal_confirm__content}>
          <h3 className="m-0">{title ?? t("delete.O'chirmoqchimisiz ?")}</h3>
          <p className="m-0">{description ?? t("delete.Ushbu element bir necha vaqt oralig'ida o'chib ketadi.")}</p>
          {isDescription && (
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} layout="vertical">
              <Form.Item name={requestKey || "description"} rules={formRules()} label={t("delete.Izoh")}>
                <Input placeholder={t("delete.Izohni kiriting")} />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ModalConfirm;
