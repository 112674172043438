import { FormInstance, Input, Popover } from "antd";
import React, { ReactNode, useEffect, useMemo, useState } from "react";

import { useGetUsersSelect } from "../../service/queries";
import { SearchIcon } from "../../assets/icons/SearchIcon";
import { UserSelectModel } from "../../utils/models/user/userSelectModel";
import { CheckIcon } from "../../assets/icons/CheckIcon";
import { useTranslation } from "react-i18next";

import styles from "./appendEmployee.module.scss";
import { useConfirmationUserDefect } from "../../../warehouse/service/mutation";
import NotUserImage from "../not-image/NotUserImage";
import SelectNotContent from "../select-not-content/SelectNotContent";
import { settingsActions } from "../../../../store/reducers/settingsReducer";
import { useDispatch } from "react-redux";

type Props = {
  children: ReactNode;
  name: string;
  formInstance: FormInstance;
  data: UserSelectModel[] | UserSelectModel | undefined;
  setData: (data: UserSelectModel[] | UserSelectModel | undefined) => void;
  initialValue?: UserSelectModel[] | UserSelectModel;
  isConfirmationUser?: {
    object_id?: number;
    type: string;
  };
  notMe?: boolean;
  description?: string;
  title?: string;
};

export const AppendEmployee: React.FC<Props> = ({
  children,
  data,
  setData,
  formInstance,
  name,
  initialValue,
  isConfirmationUser,
  notMe,
  description,
  title,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data: users } = useGetUsersSelect(true, notMe);
  const [searchText, setSearchText] = useState("");
  const confirmationUser = useConfirmationUserDefect();
  const { setAdminModal } = settingsActions;

  useEffect(() => {
    if (initialValue) {
      setData(initialValue);
    }
  }, []);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.currentTarget.value);
  };

  const filteredData: typeof users = useMemo(() => {
    if (searchText) {
      return users?.filter((user) => {
        const name = user.full_name.replace(/\s/g, "").toLowerCase();

        return name.includes(searchText);
      });
    }

    return users;
  }, [searchText, users]);

  const isSelected = (user: UserSelectModel) => {
    if (Array.isArray(data)) {
      const userIds = data.map((user) => user.id);

      return userIds.includes(user.id);
    } else {
      return user.id === data?.id;
    }
  };

  const onSelectUser = (user: UserSelectModel) => {
    let newData: UserSelectModel | UserSelectModel[] | undefined;

    isConfirmationUser &&
      confirmationUser.mutate({ ...isConfirmationUser, user_id: user.id });

    if (isSelected(user)) {
      if (Array.isArray(data)) {
        newData = data.filter((item) => item.id !== user.id);
      } else {
        newData = [];
      }
    } else {
      if (Array.isArray(data)) {
        newData = [...data, user];
      } else {
        newData = user;
      }
    }

    setData(newData);

    formInstance.setFieldValue(
      name,
      Array.isArray(newData) ? newData?.map((item) => item.id) : newData.id
    );
  };

  const onOpenCreateAdminModal = () => {
    dispatch(
      setAdminModal({
        visible: true,
        full_name: searchText,
        isEdited: true,
      })
    );
  };

  const content = (
    <div className={styles.popover__content}>
      <div className={styles.popover__content__top}>
        <Input
          onChange={onSearch}
          prefix={<SearchIcon />}
          placeholder={t("Kassa.Qidiruv")}
        />
      </div>
      <div className={styles.popover__content__bottom}>
        {filteredData && filteredData?.length > 0 ? (
          filteredData?.map((user) => (
            <div
              key={user.id}
              className={`${styles.user} ${isSelected(user) ? styles.active : ""}`}
              onClick={() => onSelectUser(user)}
            >
              {user?.image ? (
                <img alt={user.full_name} src={user.image} />
              ) : (
                <NotUserImage name={user?.full_name} width={24} />
              )}
              <p>{user.full_name}</p>
              <div className={styles.check_icon}>
                <CheckIcon />
              </div>
            </div>
          ))
        ) : (
          <SelectNotContent
            title={title}
            description={description}
            action={onOpenCreateAdminModal}
          />
        )}
      </div>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomLeft"
      overlayClassName={styles.popover}
    >
      {children}
    </Popover>
  );
};
