import { CustomFieldTypesEnum } from "../enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../models/customFieldSelectModel";
import { CustomFieldValueModel } from "../models/customFIeldValueModel";

const getValueKey = (field: CustomFieldSelectModel) => {
  if (field.type === CustomFieldTypesEnum.SELECT) {
    return "custom_field_option";
  }
  if (field.type === CustomFieldTypesEnum.FILE) {
    return "file";
  }
  return "value";
};

export function generateCustomFieldData(customFields: CustomFieldSelectModel[], fieldValues: CustomFieldValueModel[]) {
  const findField = (id: number) => fieldValues?.find(item => item?.custom_field?.id === id);

  const resultValue = (id: number) => {
    const returnValue =
      findField(id)?.[
        // @ts-ignore
        getValueKey(findField(id)?.custom_field)
      ];

    if (typeof returnValue === "object") {
      return returnValue?.id;
    }
    return returnValue;
  };

  return customFields.map(item => ({
    custom_field_id: item?.id,
    value: resultValue(item?.id),
  }));
}

export function generateGeneralEditingProductData(selectOffers: any[], customFields: CustomFieldSelectModel[]) {
  return selectOffers?.map(item => ({
    id: item?.id,
    delivery_date: item?.delivery_date,
    status: item?.status,
    product_id: item?.product?.id,
    unit_id: item?.unit?.id,
    warehouse_id: item?.warehouse?.id,
    project_id: item?.project?.id,
    quantity: item?.quantity,
    custom_field_values: generateCustomFieldData(customFields, item?.custom_field_values),
  }));
}

export function generateGeneralEditingPartyData(selectParties: any[], customFields: CustomFieldSelectModel[]) {
  return selectParties?.map(item => ({
    id: item?.id,
    delivery_date: item?.delivery_date,
    status: item?.status,
    payment_date: item?.payment_date,
    agent_id: item?.agent?.id,
    custom_field_values: generateCustomFieldData(customFields, item?.custom_field_values),
  }));
}

export function generateGeneralEditingOrderProductData(selectProducts: any[], customFields: CustomFieldSelectModel[]) {
  return selectProducts?.map(item => ({
    id: item?.id,
    delivery_date: item?.delivery_date,
    product_id: item?.product?.id,
    unit_id: item?.unit?.id,
    warehouse_id: item?.warehouse?.id,
    project_id: item?.project?.id,
    quantity: item?.quantity,
    custom_field_values: generateCustomFieldData(customFields, item?.custom_field_values),
  }));
}

export function generateGeneralEditingPaymentData(selectProducts: any[], customFields: CustomFieldSelectModel[]) {
  return selectProducts?.map(item => ({
    id: item?.id,
    date: item?.date,
    description: item?.description,
    custom_field_values: generateCustomFieldData(customFields, item?.custom_field_values),
  }));
}
