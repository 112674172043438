import React from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbItemType } from "antd/lib/breadcrumb/Breadcrumb";

// import { CategoryModel } from "../../../../../settings/utils/models/category/CategoryModel";
// import FolderIcon from "../../../../../app/assets/icons/FolderIcon";
// import { sliceText } from "../../../../../app/utils/helpers/sliceText";

import styles from "./drawerCard.module.scss";
import { CategoryModel } from "../../../../settings/utils/models/category/CategoryModel";
import FolderIcon from "../../../../app/assets/icons/FolderIcon";
import { sliceText } from "../../../../app/utils/helpers/sliceText";
import { useDispatch } from "react-redux";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import { useAppSelector } from "../../../../../hooks/redux";

interface Props extends CategoryModel {
  isCategory: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  // setFolderId: React.Dispatch<
  //   React.SetStateAction<{
  //     category_id?: number | undefined;
  //     sub_category_id?: number | undefined;
  //   }>
  // >;
}

const ProductDrawerCard: React.FC<Props> = ({
  id,
  setPage,
  products_count,
  name,
  color,
  setTabKey,
  isCategory,
  // setFolderId,
  setBreadcrumbItems,
}) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setProductFolderId } = supplyActions;
  const { folderId } = useAppSelector((state) => state.supplyReducer.productDrawer);

  const changeFolderId = () => {
    let newValueFolderId = {};

    if (isCategory) {
      newValueFolderId = {
        category_id: id,
        sub_category_id: folderId.sub_category_id,
      };
    } else {
      newValueFolderId = {
        category_id: folderId.category_id,
        sub_category_id: id,
      };
    }

    dispatch(setProductFolderId(newValueFolderId));
  };

  const onClick = () => {
    setTabKey(isCategory ? "2" : "3");
    changeFolderId();
    setPage && setPage(1);

    setBreadcrumbItems((value) => {
      return [
        ...value,
        {
          title: name[i18n.language],
          onClick: () => {
            changeFolderId();
            setPage && setPage(1);
            setTabKey(isCategory ? "2" : "3");
            setBreadcrumbItems((value) =>
              isCategory ? [value[0], value[1]] : value
            );
          },
        },
      ];
    });
  };

  return (
    <div className={styles.folder} onClick={onClick}>
      <FolderIcon color={color} />
      <div className={styles.right}>
        <h4>{sliceText(name[i18n.language], 16)}</h4>
        <p>{products_count} shablon</p>
      </div>
    </div>
  );
};

export default ProductDrawerCard;
