import { Button, Select } from "antd";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import UserIcon from "../../../../assets/icons/UserIcon";
import { useAppSelector } from "../../../../../../hooks/redux";
import BuildingIcon from "../../../../assets/icons/BuildingIcon";
import BankNoteIcon from "../../../../assets/icons/BankNoteIcon";
import { RU } from "../../../../../app/utils/constants/languages";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { useGetCurrenciesSelect } from "../../../../../app/service/queries";
import { useGetProjectSelect } from "../../../../../payment/service/queries";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import BuildingProjectIcon from "../../../../assets/icons/BuildingProjectIcon";
import { useGetWarehouseSelect } from "../../../../../warehouse/service/query";
import { OfferPartyLeftModel } from "../../../../utils/models/offerPartyModel";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";

import styles from "./offerPartyModalTitle.module.scss";
import { DefaultOptionType } from "antd/es/select";

const { Option } = Select;

const OfferPartyModalTitle: React.FC = () => {
  const dispatch = useDispatch();
  const { setOfferPartyModal, setCheckData, setOfferPartyLeftData } =
    supplyOfferActions;

  const { checkData, leftData } = useAppSelector(
    (state) => state.supplyOfferReducer.partyModal
  );

  const { data: currencies } = useGetCurrenciesSelect();
  const { data: companyPerson } = useGetCompanyPersonSelect();
  const { data: warehouses } = useGetWarehouseSelect(true, true);
  const { data: projects } = useGetProjectSelect({
    enabled: true,
  });

  const onClose = () => {
    dispatch(
      setOfferPartyModal({
        visible: false,
      })
    );
  };

  const totalAmount = useMemo(() => {
    return checkData?.reduce(
      (first, second) =>
        first +
        parseLocaledString(second?.amount ? second?.amount : "0") *
          parseLocaledString(second?.quantity ? String(second?.quantity) : "0"),
      0
    );
  }, [checkData]);

  const onClearCheckData = () => {
    dispatch(setCheckData([]));
  };

  const onDelete = () => {
    dispatch(
      setOfferPartyLeftData(
        leftData.filter(
          (item) => !checkData.some((s) => s.unique_id === item.unique_id)
        )
      )
    );

    dispatch(setCheckData([]));
  };

  const onChangeSelect = (
    value: number,
    key: keyof OfferPartyLeftModel,
    option?: DefaultOptionType
  ) => {
    dispatch(
      setOfferPartyLeftData(
        leftData.map((item) => {
          const isSomeElem = checkData?.some((c) => c.unique_id === item.unique_id);

          if (isSomeElem) {
            return {
              ...item,
              [key]: option
                ? {
                    id: value,
                    name: option.props?.name,
                    phone: option?.props?.phone,
                  }
                : value,
            };
          } else return item;
        })
      )
    );
  };

  return (
    <div className={styles.title}>
      {isEmptyArr(checkData) ? (
        <div className={styles.old}>
          <span>Partiya yaratish</span>
          <div className={styles.close} onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      ) : (
        <div className={styles.filter}>
          <div className={styles.filter__left}>
            <h4>Belgilandi ({checkData?.length}) </h4>
            <h3>Umumiy summa: {totalAmount?.toLocaleString(RU)}</h3>
          </div>
          <div className={styles.filter__right}>
            <Button className={styles.clear} onClick={onClearCheckData}>
              <CloseIcon /> Yopish
            </Button>
            <Select
              placeholder="Valyuta"
              popupMatchSelectWidth={false}
              suffixIcon={<BankNoteIcon />}
              onChange={(e) => onChangeSelect(e, "currency_id")}
            >
              {currencies?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item.symbol,
                  }}
                >
                  {item.symbol}
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              placeholder="Ta'minotchi"
              suffixIcon={<UserIcon />}
              popupMatchSelectWidth={false}
              filterOption={selectFilterOption}
              onChange={(e) => onChangeSelect(e, "company_person")}
              notFoundContent={
                <TableEmpty description="Siz qidirgan ta'minotchi mavjud emas" />
              }
            >
              {companyPerson?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item.person?.name,
                    phone: item?.person?.phone,
                  }}
                >
                  {item.person?.name}
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              placeholder="Omborxona"
              popupMatchSelectWidth={false}
              suffixIcon={<BuildingIcon />}
              filterOption={selectFilterOption}
              onChange={(e) => onChangeSelect(e, "warehouse_id")}
              notFoundContent={
                <TableEmpty description="Siz qidirgan omborxona mavjud emas" />
              }
            >
              {warehouses?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item?.name,
                  }}
                >
                  {item?.name}
                </Option>
              ))}
            </Select>
            <Select
              showSearch
              placeholder="Loyiha"
              popupMatchSelectWidth={false}
              filterOption={selectFilterOption}
              suffixIcon={<BuildingProjectIcon />}
              onChange={(e) => onChangeSelect(e, "project_id")}
              notFoundContent={
                <TableEmpty description="Siz qidirgan loyiha mavjud emas" />
              }
            >
              {projects?.map((item) => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item?.name,
                  }}
                >
                  {item?.name}
                </Option>
              ))}
            </Select>
            <Button
              type="primary"
              danger
              className={styles.delete}
              onClick={onDelete}
            >
              <DeleteIcon /> O'chirish
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferPartyModalTitle;
