import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { UseMutationResult } from "@tanstack/react-query";
import { Button, Form, FormInstance } from "antd";
import classNames from "classnames";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { ErrorRes, SuccessRes } from "features/app/utils/models/responseType";
import { useGetCompanyPersonSelect } from "features/counterparts/service/queries";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import {
  generatePersonGroupData,
  generatePersonToWarehouseProduct
} from "../../../../utils/helpers/generateProductsData";
import { PartyFormPersonGroupModel, PartyFormProductsModel } from "../../../../utils/models/partyModalFormModel";

import PartyPersonGroup from "./person-group/PartyPersonGroup";
import PartyModalTable from "./table/PartyModalTable";

import styles from "./partyModalLeft.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  form: FormInstance;
  isWarehouse?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { Item, useWatch } = Form;

const PartyModalLeft: React.FC<Props> = ({ form, isWarehouse, deleteProduct }) => {
  // const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const { setPartyEstimate } = supplyActions;
  const { party_id } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: companyPersons } = useGetCompanyPersonSelect();

  const isTable = useWatch("is_table", form) as boolean;
  // const searchValue = useWatch("search", form) as string;
  const isGroupPerson = useWatch("is_group_person", form) as boolean;
  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  // const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];

  const onAddProduct = () => {
    form.setFieldValue("is_table", true);

    let lastProduct: Partial<PartyFormProductsModel> = {
      warehouse_id: undefined,
      project_id: undefined
    };

    if (!isEmptyArr(products)) {
      lastProduct = products[products?.length - 1];
    }

    form.setFieldValue("warehouse_products", [
      {
        id: undefined,
        product_id: undefined,
        unit_id: undefined,
        warehouse_id: lastProduct?.warehouse_id,
        project_id: lastProduct?.project_id,
        quantity: undefined,
        row_id: uid(12)
      }
    ]);
  };

  const onOpenEstimateDrawer = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const notFoundContent = (
    <div className={styles.not_found}>
      <h4>{t("partyModal.Buyurtmalar tanlanmagan")}!</h4>
      <p>{t("partyModal.Mahsulotlar ro’yxatidan kerakli mahsultolarni tanlab buyurtma berishingiz mumkin")}.</p>
      <div className={styles.not_found__bottom}>
        <Button onClick={onOpenEstimateDrawer}>{t("partyModal.Smetadagi resurslar")}</Button>
        <Button type="primary" onClick={onAddProduct}>
          <PlusIcon color="#fff" />
          {t("partyModal.Resurs qo'shish")}
        </Button>
      </div>
    </div>
  );

  // const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = e.currentTarget;
  //
  //   form.setFieldValue("search", value);
  //   const formSelectSearch = form.getFieldValue("select_search") as string;
  //
  //   const formSelectProducts = form.getFieldValue("select_products") as ProductSelectModel[];
  //
  //   const data = qc.getQueryData([supplyQueryNames.SELECT_PRODUCTS, formSelectSearch]) as
  //     | InfiniteData<PaginationType<ProductSelectModel[]>>
  //     | undefined;
  //
  //   const allProducts: ProductSelectModel[] | undefined = SelectProducts(data, formSelectProducts);
  //
  //   const cacheProducts: PartyFormProductsModel[] = form.getFieldValue("cache_products");
  //
  //   if (value) {
  //     let firstCacheProducts: PartyFormProductsModel[] = [];
  //
  //     if (isEmptyArr(cacheProducts)) {
  //       form.setFieldsValue({
  //         cache_products: products
  //       });
  //       firstCacheProducts = products;
  //     } else {
  //       firstCacheProducts = cacheProducts;
  //     }
  //
  //     const searchProducts = allProducts?.filter(item =>
  //       item.name[i18n.language].toUpperCase().includes(value.toUpperCase())
  //     );
  //
  //     form.setFieldsValue({
  //       warehouse_products: firstCacheProducts?.filter(item =>
  //         searchProducts?.some(product => product.id === item.product_id)
  //       )
  //     });
  //   } else {
  //     form.setFieldsValue({
  //       cache_products: [],
  //       warehouse_products: cacheProducts
  //     });
  //   }
  // };

  // const filterElemClass = () => {
  //   const isEmptyData = isEmptyArr(products) && !searchValue && isEmptyArr(cacheProducts);
  //
  //   return classNames(styles.filter, {
  //     [styles.filter__disabled]: isEmptyData,
  //     [styles.filter__active]: !isEmptyData
  //   });
  // };

  const visibleTable = useMemo(() => isTable || party_id, [isTable, party_id]);

  const onGroupingCompanyPerson = () => {
    // const groupProducts = form.getFieldValue("group_products") as PartyGroupProductsModel[];

    form.setFieldValue("is_group_person", !isGroupPerson);

    if (!isGroupPerson) {
      // let warehouseProducts = products;

      // if (!isEmptyArr(groupProducts)) {
      //   warehouseProducts = [...warehouseProducts, ...unGroupProducts(groupProducts)];
      // }

      form.setFieldsValue({
        person_group: generatePersonGroupData(products, companyPersons),
        warehouse_products: []
        // group_products: []
      });
    } else {
      const personGroup = form.getFieldValue("person_group") as PartyFormPersonGroupModel[];

      form.setFieldsValue({
        person_group: [],
        warehouse_products: generatePersonToWarehouseProduct(personGroup)
      });
    }
  };

  return (
    <div className={styles.left}>
      <div className={styles.left__top}>
        <Item name="offers" className="hidden" />
        <Item name="status" className="hidden" />
        <Item name="is_table" className="hidden" />
        <Item name="person_keys" className="hidden" />
        <Item name="person_group" className="hidden" />
        <Item name="select_search" className="hidden" />
        {/* <Item name="group_products" className="hidden" /> */}
        <Item name="cache_products" className="hidden" />
        <Item name="warehouse_products" className="hidden" />
        <Item name="select_products" className="hidden" />
        <Item name="is_group_person" className="hidden" />
        <Button
          onClick={onGroupingCompanyPerson}
          className={classNames(visibleTable ? styles.group_btn : styles.group_btn__ds)}
        >
          {isGroupPerson ? t("partyModal.Guruhdan chiqarish") : t("partyModal.Guruhlash")}
        </Button>
        {/* <div className={filterElemClass()}> */}
        {/*  <div className={styles.search}> */}
        {/*    <Item name="search" style={{ margin: 0 }}> */}
        {/*      <Input prefix={<SearchIcon />} placeholder="Qidiruv" onChange={onSearch} /> */}
        {/*    </Item> */}
        {/*  </div> */}
        {/* </div> */}
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isGroupPerson ? (
        <PartyPersonGroup form={form} isWarehouse={isWarehouse} deleteProduct={deleteProduct} />
      ) : visibleTable ? (
        <PartyModalTable form={form} isWarehouse={isWarehouse} deleteProduct={deleteProduct} />
      ) : (
        notFoundContent
      )}
    </div>
  );
};

export default PartyModalLeft;
