import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { cx } from "features/app/utils/helpers/cx";
import ProductListImportModal from "features/counterparts/components/counterparts/product-list-import-modal/ProductListImportModal";
import CategoriesProductListModal from "features/settings/components/sub-categories/modal/CategoriesProductListModal";
import { SubCategoryModel } from "features/settings/utils/models/sub-category/subCategoryModel";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { settingsActions } from "store/reducers/settingsReducer";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import FolderCard from "../../../app/components/folder-card/FolderCard";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../app/components/not-data/NotData";
import Pagination from "../../../app/components/pagination/Pagination";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import { parseToCrumbName } from "../../../app/utils/helpers/parseToCrumbName";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import OfferHistory from "../../../supply/components/offers/offer-history/OfferHistory";
import OfferModal from "../../../supply/components/offers/offer-modal/OfferModal";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";
import SettingsProductModal from "../../components/sub-categories/modal/SettingsProductModal";
import SettingsProductTable from "../../components/sub-categories/table/SettingsProductTable";
import { useCreateSubCategory, useDeleteSubCategory, useUpdateSubCategory } from "../../service/mutations";
import { useGetCategoryAllProducts, useGetSubCategories } from "../../service/queries";
import { settingsQueryNames } from "../../utils/constants/settingsQueryNames";
import { folderName } from "../../utils/helper/folderName";
import { ProductModel } from "../../utils/models/product/productModel";

import emptyImage from "../../assets/images/products.png";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const params = useParams();
  const [form] = useForm();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { id, name } = parseParamsId(params?.id);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { searchParams, reqQueryParam } = useQueryParams();
  const [importVisible, setImportVisible] = useState(false);
  const { setCategoriesProductsModal } = settingsActions;
  const dispatch = useDispatch();

  const createSubCategory = useCreateSubCategory();
  const updateSubCategory = useUpdateSubCategory();
  const deleteSubCategory = useDeleteSubCategory();
  const { data, isLoading } = useGetSubCategories(+id!);
  const { data: products, isLoading: isLoadingProducts } = useGetCategoryAllProducts(+id!);

  const [modalData, setModalData] = useState<{
    visible: boolean;
    data?: ProductModel;
  }>({
    visible: false,
    data: undefined
  });

  const goBack = () => {
    navigate(-1);
  };

  const onCreateFolder = () => {
    setIsCreating(true);
  };

  const onOpenProductModal = () => {
    setModalData({
      visible: true,
      data: undefined
    });
  };

  const folderPath = (record: SubCategoryModel): string => {
    let path = `${rootPaths.SETTINGS}${
      settingsPaths.PRODUCTS
    }/${name}-${id}/${parseToCrumbName(record.name[i18n.language])}-${record.id}`;

    if (searchParams && searchParams?.length > 0) {
      path += `?${reqQueryParam(queryParamsKeys.SEARCH)}`;
    }
    return path;
  };

  const onOpenImportModal = () => {
    setImportVisible(true);
  };

  const onOpenProductListModal = () => {
    dispatch(
      setCategoriesProductsModal({
        visible: true,
        folderId: Number(id),
        folderName: name
      })
    );
  };

  return (
    <div className={styles.sub_category}>
      <div className={styles.sub_category__top}>
        <div className={styles.left}>
          <Button onClick={goBack}>
            <ArrowNarrowIcon />
          </Button>
          <h2>{name}</h2>
        </div>
        <div className={styles.right}>
          <Button onClick={onOpenImportModal}>
            <DownloadIcon /> {t("products.Import")}
          </Button>
          <Button onClick={onOpenProductModal}>
            <PlusIcon /> {t("products.Mahsulot")}
          </Button>
        </div>
      </div>
      <div className={styles.sub_category__bottom}>
        {!isEmptyArr(data) && (
          <div className={styles.sub_category__bottom__header}>
            <h4> {t("products.Kataloglar")} </h4>
            <div>
              <PaymentSearch />
            </div>
          </div>
        )}
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {isEmptyArr(data) && isEmptyArr(products?.data) && !isCreating && !isLoadingProducts && (
            <div className={styles.no_data_container}>
              <div className="w-max">
                {isCreating && (
                  <FolderCard
                    isReqNameObject
                    placeholder={t("products.mahsulot")}
                    isCreating={isCreating}
                    createBody={{ category_id: id }}
                    setIsCreating={setIsCreating}
                    createMutation={createSubCategory}
                    queryName={settingsQueryNames.SUB_CATEGORIES_VIEW}
                  />
                )}
                <div className={styles.new_folder} onClick={onCreateFolder}>
                  <FolderPlusIcon /> {t("products.Katalog yaratish")}
                </div>
              </div>
              <div className={styles.search_container}>
                <PaymentSearch />
              </div>
              <NotData
                containerClassName={styles.empty__container}
                img={{
                  src: emptyImage,
                  className: styles.empty_image
                }}
                name={{ text: `${t("products.Mahsulotlar mavjud emas")}` }}
                text={{
                  text: `${t("products.Bu yerda mahsulotlarni qo’shish orqali mahsulotlaringizni boshqaring")}`
                }}
                primaryButton={{
                  text: `${t("products.Maxsulot qo'shish")}`,
                  onClick: onOpenProductModal,
                  icon: <PlusIcon color="#fff" />
                }}
              />
            </div>
          )}
          {!isEmptyArr(data) && (
            <div className={styles.folders}>
              {data?.map(item => (
                <FolderCard
                  id={item.id}
                  key={item.id}
                  isReqNameObject
                  color={item.color}
                  sliceTextCount={32}
                  placeholder={t("products.mahsulot")}
                  count={item.products_count}
                  name={folderName(item.name)}
                  deleteBody={{
                    category_id: id,
                    id: item.id
                  }}
                  updateMutation={updateSubCategory}
                  deleteMutation={deleteSubCategory}
                  path={folderPath(item)}
                />
              ))}
              {isCreating && (
                <FolderCard
                  isReqNameObject
                  placeholder={t("products.mahsulot")}
                  isCreating={isCreating}
                  createBody={{ category_id: id }}
                  setIsCreating={setIsCreating}
                  createMutation={createSubCategory}
                  queryName={settingsQueryNames.SUB_CATEGORIES_VIEW}
                />
              )}
              <div className={styles.new_folder} onClick={onCreateFolder}>
                <FolderPlusIcon /> {t("products.Katalog yaratish")}
              </div>
            </div>
          )}
        </Spin>
        <div className={styles.products}>
          {data?.length === 0 &&
            ((products?.data && products?.data?.length > 0) || products?.data?.length === 0) &&
            isCreating && (
              <div className={styles.table_search_container}>
                <div className={styles.folder_creating}>
                  {isCreating && (
                    <FolderCard
                      isReqNameObject
                      placeholder={t("products.mahsulot")}
                      isCreating={isCreating}
                      createBody={{ category_id: id }}
                      setIsCreating={setIsCreating}
                      createMutation={createSubCategory}
                      queryName={settingsQueryNames.SUB_CATEGORIES_VIEW}
                    />
                  )}
                  <div className={styles.new_folder} onClick={onCreateFolder}>
                    <FolderPlusIcon /> {t("products.Katalog yaratish")}
                  </div>
                </div>
                <PaymentSearch />
              </div>
            )}

          {(!isEmptyArr(products?.data) || isLoadingProducts) && (
            <>
              {!data ||
                (data?.length === 0 && (
                  <div className={cx("flex", isCreating ? "justify-end" : "justify-between")}>
                    {!isCreating && (
                      <div>
                        <div className={styles.new_folder} onClick={onCreateFolder}>
                          <FolderPlusIcon /> {t("products.Katalog yaratish")}
                        </div>
                      </div>
                    )}
                    {!isCreating && (
                      <div>
                        <PaymentSearch />
                      </div>
                    )}
                  </div>
                ))}
              <SettingsProductTable isLoading={isLoadingProducts} data={products?.data} setModalData={setModalData} />
              <Pagination
                paginationProps={{
                  total: products?.total,
                  current: products?.current_page
                }}
              />
            </>
          )}
        </div>
        <SettingsProductModal modalData={modalData} setModalData={setModalData} />
        <OfferModal />
        <OfferHistory />
        <CategoriesProductListModal form={form} />
        <ProductListImportModal
          defaultLink="Mahsulot%20import%20shablon.xlsx"
          title="Katalog mahsulotlari"
          form={form}
          setVisibleModal={setImportVisible}
          visible={importVisible}
          onAfterFunc={onOpenProductListModal}
          isCatalog
        />
      </div>
    </div>
  );
};

export default Index;
