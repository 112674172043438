import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

export const transferMakingDrawerTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "resource_name_and_type",
      name: "resource_name_and_type",
      checked: true,
      title: "Resurs turi va nomi"
    },
    {
      id: "total_quantity",
      name: "total_quantity",
      checked: true,
      title: "Miqdori"
    },
    {
      id: "transfer_quantity",
      name: "transfer_quantity",
      checked: true,
      title: "O'tkazma miqdori"
    },
    {
      id: "unit.symbol",
      name: "unit.symbol",
      checked: true,
      title: "Birligi"
    }
  ],
  size: 10
};
