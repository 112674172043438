import React from "react";

const BarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_20427_47505" fill="white">
      <path d="M20.75 12C21.3023 12 21.7554 12.4489 21.6976 12.9982C21.5264 14.6233 20.9391 16.1893 19.9804 17.5557C18.8267 19.2002 17.1868 20.4819 15.2682 21.2388C13.3496 21.9957 11.2384 22.1937 9.20155 21.8079C7.16475 21.422 5.29383 20.4696 3.82538 19.0711C2.35693 17.6725 1.3569 15.8907 0.951755 13.9509C0.54661 12.0111 0.754545 10.0004 1.54926 8.17317C2.34399 6.3459 3.6898 4.78412 5.41651 3.6853C6.86514 2.76345 8.52728 2.2022 10.2513 2.04533C10.8014 1.99528 11.25 2.44772 11.25 3L11.25 11C11.25 11.5523 11.6977 12 12.25 12H20.75Z" />
    </mask>
    <path
      d="M20.75 12C21.3023 12 21.7554 12.4489 21.6976 12.9982C21.5264 14.6233 20.9391 16.1893 19.9804 17.5557C18.8267 19.2002 17.1868 20.4819 15.2682 21.2388C13.3496 21.9957 11.2384 22.1937 9.20155 21.8079C7.16475 21.422 5.29383 20.4696 3.82538 19.0711C2.35693 17.6725 1.3569 15.8907 0.951755 13.9509C0.54661 12.0111 0.754545 10.0004 1.54926 8.17317C2.34399 6.3459 3.6898 4.78412 5.41651 3.6853C6.86514 2.76345 8.52728 2.2022 10.2513 2.04533C10.8014 1.99528 11.25 2.44772 11.25 3L11.25 11C11.25 11.5523 11.6977 12 12.25 12H20.75Z"
      stroke="#1E90FF"
      strokeWidth="3"
      mask="url(#path-1-inside-1_20427_47505)"
    />
    <path
      d="M13.1509 1.80357C13.843 1.89352 14.429 2.09757 15.2898 2.45411C16.4121 2.91897 17.4318 3.60032 18.2907 4.45926C19.1497 5.3182 19.831 6.33792 20.2959 7.46018C20.6425 8.29699 20.8637 9.1786 20.9538 10.0765C20.9579 10.117 20.9463 10.1519 20.9143 10.1849C20.8793 10.221 20.8218 10.25 20.75 10.25L13.25 10.25C13.1119 10.25 13 10.1381 13 10L13 2C13 1.92724 13.0299 1.86899 13.0647 1.83535C13.095 1.80607 13.1214 1.79974 13.1509 1.80357Z"
      stroke="#1E90FF"
      strokeWidth="1.5"
    />
  </svg>
);

export default BarIcon;
