import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { useAppSelector } from "../../../../../hooks/redux";
import { localStorageActions } from "../../../../../store/reducers/localStorageReducer";
import { LocalStorage } from "../../../../app/service/LocalStorage";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../../app/utils/helpers/cx";
import { EstimateGroupType } from "../../../../app/utils/models/estimateGroupType";
import LayersThreeIcon from "../../../assets/icons/LayersThreeIcon";
import { groupTypes } from "../../../utils/enums/groupTypes";

import Content from "./content";

import styles from "./estimateGroup.module.scss";

type Props = {
  className?: string;
  iconWidth?: number;
  projectId?: number | string;
  initialValue?: { id?: number; type?: groupTypes };
  setValue?: React.Dispatch<React.SetStateAction<{ id?: number; type?: groupTypes }>>;
  smetaContainer?: HTMLElement;
};

type CheckedType = {
  id?: number;
  type?: groupTypes;
};

const EstimateGroup: React.FC<Props> = ({
  className,
  iconWidth,
  setValue,
  initialValue,
  projectId,
  smetaContainer
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setEstimateGroup } = localStorageActions;
  const { queries, appends, severalRemove } = useQueryParams();
  const { estimate_group: estimateGroupId, type } = queries();
  const { estimate_group } = useAppSelector(state => state.localStorageReducer);
  const [checkedValue, setCheckedValue] = useState<CheckedType>({});

  const onOpen = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      let newValue: CheckedType = {};
      const localGroupData = estimate_group?.find(item => item?.projectId === projectId);

      if (initialValue) {
        newValue = {
          id: Number(initialValue.id),
          type: initialValue.type
        };
      } else if (estimateGroupId && type) {
        newValue = {
          type: type as groupTypes,
          id: +estimateGroupId || 0
        };
      } else if (localGroupData) {
        newValue = {
          id: Number(localGroupData?.id),
          type: localGroupData?.type
        };
      }

      setCheckedValue(newValue);
    }
  };

  const onOk = () => {
    if (checkedValue?.id) {
      dispatch(
        setEstimateGroup({
          data: {
            type: checkedValue.type!,
            id: String(checkedValue.id!),
            projectId: String(projectId)
          },
          isDelete: false
        })
      );
    } else {
      dispatch(
        setEstimateGroup({
          isDelete: true,
          projectId: String(projectId)
        })
      );
    }

    if (setValue) {
      setValue(checkedValue);
    } else if (checkedValue.id && checkedValue.type) {
      appends([
        {
          key: queryParamsKeys.TYPE,
          value: checkedValue.type as string
        },
        {
          key: queryParamsKeys.ESTIMATE_GROUP,
          value: String(checkedValue.id)
        }
      ]);
    } else {
      severalRemove(queryParamsKeys.TYPE, queryParamsKeys.ESTIMATE_GROUP);
    }

    onCancel();
  };

  const tabItems: TabsProps["items"] = [
    {
      key: groupTypes.SECTION,
      label: `${t("project.Bo'lim")}`,
      children: (
        <Content
          type={groupTypes.SECTION}
          checkedValue={checkedValue}
          setCheckedValue={setCheckedValue}
          location={CustomFieldLocationEnum.SECTION}
        />
      )
    },
    {
      key: groupTypes.TASK,
      label: `${t("project.Ish")}`,
      children: (
        <Content
          type={groupTypes.TASK}
          checkedValue={checkedValue}
          setCheckedValue={setCheckedValue}
          location={CustomFieldLocationEnum.TASK}
        />
      )
    }
  ];

  const isGroupCustomField = () => {
    const localGroupField = LocalStorage.get<EstimateGroupType[]>("estimate_group");

    const findLocalField = localGroupField?.find(item => item?.projectId === String(projectId));

    return checkedValue?.id || findLocalField;
  };

  return (
    <>
      <div className={cx(styles.dropdown__item, { [className || ""]: className })} onClick={onOpen}>
        <LayersThreeIcon width={iconWidth} /> {t("project.Guruhlash")}{" "}
        {isGroupCustomField() && <span className={styles.group_length}>1</span>}
      </div>
      <Modal
        centered
        width={444}
        open={open}
        onOk={onOk}
        onCancel={onCancel}
        className={styles.modal}
        afterOpenChange={onAfterOpen}
        okText={t("project.Saqlash")}
        title={t("project.Guruhlash")}
        cancelText={t("project.Yopish")}
        getContainer={() => smetaContainer!}
      >
        <Tabs items={tabItems} />
      </Modal>
    </>
  );
};

export default EstimateGroup;
