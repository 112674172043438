import { lazy } from "react";

export function customLazy(importPage: any) {
  return lazy(
    () =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve(importPage());
        }, 1500);
      })
  );
}
