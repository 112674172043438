import React from "react";
import { Form, FormInstance, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetUnitSelect } from "../../../../../app/service/queries";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { IProductList } from "../../../../utils/models/productListModel";

import styles from "./productListModalTable.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  index: number;
  rowId: number | string;
  form: FormInstance;
};

const ProductListUnitSelect: React.FC<Props> = ({ index, form, rowId }) => {
  const { i18n, t } = useTranslation();
  const { data: units } = useGetUnitSelect();
  const fieldName = ["products", index, "unit_id"];

  const onChangeUnit = () => (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const unitOption = option as DefaultOptionType;
    const products = form.getFieldValue("products") as IProductList[];

    if (!unitOption?.props?.isPiece) {
      form.setFieldValue(
        "products",
        products?.map(item => {
          if (item?.rowId === rowId) return { ...item, unit_id: e };
          return item;
        })
      );
      // form.setFieldValue(fieldName, Math.round(oldQuantity));
    }
  };

  const selectPlaceholder = (
    <div className={styles.product}>
      <div className={styles.product__placeholder}>Birligi</div>
    </div>
  );

  return (
    <Item
      name={fieldName}
      rules={
        !(typeof rowId === "number")
          ? [
              {
                validator(rule, value, callback) {
                  if (typeof value === "number") {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                }
              }
            ]
          : []
      }
    >
      <Select
        showSearch
        suffixIcon={null}
        placeholder={selectPlaceholder}
        onChange={onChangeUnit}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        disabled={typeof rowId === "number"}
        notFoundContent={<TableEmpty description={t("Counterparts.Kerakli resurs topilmadi")} />}
      >
        {units?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.symbol[i18n.language],
              isPiece: item?.is_piece
            }}
          >
            {item.symbol[i18n.language]}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductListUnitSelect;
