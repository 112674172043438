import { RU } from "../constants/languages";

import { isEmptyArr } from "./isEmptyArr";
import { removeNaN } from "./removeNaN";
import { removePercent } from "./removePercent";

export const localeFormatter = (value: string, isPercent?: boolean) => {
  const parseValue = removeNaN(value, isPercent);
  const isPercentage = parseValue?.endsWith("%") && isPercent;
  const newValue = isPercentage ? removePercent(parseValue?.slice(0, -1)) : parseValue;
  const arr = newValue?.split(".");

  if (arr.length === 2) {
    if (!isEmptyArr(arr)) {
      const fractional = removePercent(arr[1])?.slice(0, 20);
      const decimalPlace = `${(+removePercent(arr[0])).toLocaleString(RU)}.${fractional}`;

      return isPercentage ? `${decimalPlace}%` : decimalPlace;
    }

    return "";
  }

  if (isPercentage) {
    return parseValue ? `${(+newValue)?.toLocaleString(RU)}%` : "";
  }

  return parseValue ? (+newValue)?.toLocaleString(RU) : "";
};
