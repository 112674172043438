import styles from "../../notification.module.scss";

const ComingSoon = () => {
  return (
    <div className={styles.description}>
      <h3>Tez kunda</h3>
      <p>Ajoyib yangilik: Tez orada ajoyib narsa keladi!</p>
    </div>
  );
};

export default ComingSoon;
