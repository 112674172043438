import React from "react";
import { Col, Row, Spin } from "antd";

import EstimateSection from "./section/EstimateSection";
import { useAppSelector } from "../../../../../hooks/redux";
import { useGetSupplySections } from "../../../service/queries";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import EmptyEstimate from "../../../../app/components/empty-estimate/EmptyEstimate";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";

import styles from "./estimateChildren.module.scss";

type Props = {
  currencySymbol?: string;
  isPrice?: boolean;
};

const EstimateChildren: React.FC<Props> = ({ currencySymbol, isPrice }) => {
  const { projectId } = useAppSelector((state) => state.supplyReducer.estimateData);
  const { data: project, isLoading } = useGetSupplySections(projectId);

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.children}>
        <div className={styles.title}>
          <Row gutter={0}>
            <Col span={isPrice ? 9 : 12} className={styles.title__item}>
              Ishlar va resurslar
            </Col>
            <Col span={3} className={styles.title__item}>
              Sana
            </Col>
            <Col span={3} className={styles.title__item}>
              Buyurtma
            </Col>
            <Col span={3} className={styles.title__item}>
              Hajmi
            </Col>
            <Col span={3} className={styles.title__item}>
              Birligi
            </Col>
            {isPrice ? (
              <Col span={3} className={styles.title__item}>
                Narxi ({currencySymbol})
              </Col>
            ) : null}
          </Row>
        </div>
        <div className={styles.content}>
          <ConditionalRender if={!isEmptyArr(project)} else={<EmptyEstimate />}>
            {project?.map((item) => (
              <EstimateSection key={item.id} {...item} isPrice={isPrice} />
            ))}
          </ConditionalRender>
        </div>
      </div>
    </Spin>
  );
};

export default EstimateChildren;
