export const impostEndPoints = {
  WORKS: "confirmation-payment/work-view",
  WORKS_RECIEVED: "confirmation-payment/work-recieved",
  WORKS_REJECTED: "confirmation-payment/work-rejected",
  INVENTORIES: "confirmation-payment/inventory-view",
  INVENTORIES_RECIEVED: "confirmation-payment/inventory-recieved",
  INVETORIES_REJECTED: "confirmation-payment/inventory-rejected",
  PARTS: "confirmation-payment/order-view",
  PARTS_RECIEVED: "confirmation-payment/order-recieved",
  PARTS_REJECTED: "confirmation-payment/order-rejected",
  PAYMENTS: "confirmation-payment/payment-view",
  PAYMENTS_RECIEVED: "confirmation-payment/payment-recieved",
  PAYMENTS_REJECTED: "confirmation-payment/payment-rejected",
  ALL_COUNTS: "confirmation-payment/all-count",
  OFFERS: "confirmation-payment/warehouse-product-offer",
  MONITORING_PAYMENT_EXCEL_EXPORT: "confirmation-payment/payment-export-excel",
  MONITORING_PARTS_EXCEL_EXPORT: "confirmation-payment/order-export-excel",
  MONITORING_WORKS_EXCEL_EXPORT: "confirmation-payment/work-export-excel",
  DELETE_ORDER: "confirmation-payment/order-delete"
};
