import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import PaymentSearch from "../../../../../payment/components/top/search/PaymentSearch";
import { SegmentedEnums } from "../../../../utils/enums/segmentedEnum";

import CompletedWorkFilter from "./filters/CompletedWorkFilter";
import WorkFilter from "./filters/WorkFilter";
import WorkResourcesFilter from "./filters/WorkResourcesFilter";

import styles from "./topActions.module.scss";

const TopActions = () => {
  const { queries } = useQueryParams();
  const tabQueryValue = queries()?.[queryParamsKeys.TAB] ?? SegmentedEnums.WORKS;

  return (
    <div className={styles.actions}>
      <PaymentSearch />
      {tabQueryValue === SegmentedEnums.WORKS && <WorkFilter />}
      {tabQueryValue === SegmentedEnums.COMPLETED_WORKS && <CompletedWorkFilter />}
      {tabQueryValue === SegmentedEnums.RESOURCES && <WorkResourcesFilter />}
    </div>
  );
};

export default TopActions;
