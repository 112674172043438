import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

const initialState = {
  visible: false,
};

const notificationSlice = createSlice({
  name: sliceNames.NOTIFICATION,
  initialState,
  reducers: {
    setVisibleNotification: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
});

export default notificationSlice.reducer;
export const notificationActions = notificationSlice.actions;
