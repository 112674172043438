import React from "react";

const CountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.75 19H8.25C3.25 19 2 18 2 14V10C2 6 3.25 5 8.25 5H15.75C20.75 5 22 6 22 10V14C22 18 20.75 19 15.75 19Z"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.38086 10.0007C6.38086 11.4441 6.38086 12.4369 6.38086 14.0007M10.002 10.0005C10.002 11.4439 10.002 12.4367 10.002 14.0005M13.502 10.0007C13.502 11.4441 13.502 12.4369 13.502 14.0007M17.002 10.0007C17.002 11.4441 17.002 12.4369 17.002 14.0007"
      stroke="#1E90FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CountIcon;
