import React from "react";
import { Button, Modal, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { useAuth } from "modules/auth/hooks";

import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { tableIndex } from "../../../../../app/utils/helpers/tableIndex";
import CoinsHandIcon from "../../../../assets/icons/CoinsHandIcon";
import CoinsStackedIcon from "../../../../assets/icons/CoinsStackedIcon";
import CurrencyDollarCircleIcon from "../../../../assets/icons/CurrencyDollarCircleIcon";
import { useDeleteExpectedPaymentTemplate } from "../../../../service/mutation";
import { useGetOneExpectedPaymentTemplate } from "../../../../service/queries";
import { getAmountStatus } from "../../../../utils/helpers/getAmountStatus";

import styles from "./tableInformationsModal.module.scss";

interface StateModel {
  visible: boolean;
  id?: number;
  creatorId?: number;
  type?: PaymentTypeEnum;
}

interface ExpectedPaymentModel {
  id: number;
  date: string;
  amount: number;
  debt_amount: number;
}

type Props = {
  state?: StateModel;
  setState: (value: StateModel) => void;
};

const TableInformationsModal: React.FC<Props> = ({ setState, state }) => {
  const userId = useAuth().user?.id;
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const incomeExpenseActions = actions("Kutilayotgan to'lovlar");
  const expenseActions = actions("Qarzlarim");
  const { data, isLoading } = useGetOneExpectedPaymentTemplate(state?.id);

  const deleteExpectedPaymentTemplate = useDeleteExpectedPaymentTemplate();

  const onCancel = () => {
    setState({
      visible: false
    });
  };

  const columns: ColumnsType<ExpectedPaymentModel> = [
    {
      title: "№",
      render: (_, __, index) => tableIndex(index, 1)
    },
    {
      title: `${t("payment.Sana")}`,
      dataIndex: "date"
    },
    {
      title: `${t("payment.Umumiy summa")}`,
      render: (record: ExpectedPaymentModel) => (
        <span>
          {record.amount?.toLocaleString()} {data?.currency?.symbol}
        </span>
      )
    },
    {
      title: `${t("payment.Qoldiq summa")}`,
      render: (record: ExpectedPaymentModel) => (
        <span>
          {record.debt_amount?.toLocaleString()} {data?.currency?.symbol}
        </span>
      )
    },
    {
      title: `${t("payment.Holati")}`,
      render: (record: ExpectedPaymentModel) => {
        const status = getAmountStatus(record.debt_amount, record.amount);

        return (
          <div
            className={styles.status}
            style={{
              background: status.background,
              color: status.color
            }}
          >
            {status.title}
          </div>
        );
      }
    }
  ];

  const footer = () => (
    <div className={styles.footer}>
      {state?.creatorId === userId && state?.type === PaymentTypeEnum.INCOME && incomeExpenseActions?.delete && (
        <ModalConfirm
          isDescription
          mutation={deleteExpectedPaymentTemplate}
          payload={{ id: state?.id }}
          // @ts-expect-error
          setVisibleParentModal={setState}
        >
          <Button>
            <DeleteIcon /> {t("payment.Jadvalni o’chirish")}
          </Button>
        </ModalConfirm>
      )}
      {state?.creatorId === userId && state?.type === PaymentTypeEnum.EXPENSE && expenseActions?.delete && (
        <ModalConfirm
          isDescription
          mutation={deleteExpectedPaymentTemplate}
          payload={{ id: state?.id }}
          // @ts-ignore
          setVisibleParentModal={setState}
        >
          <Button>
            <DeleteIcon /> {t("payment.Jadvalni o’chirish")}
          </Button>
        </ModalConfirm>
      )}
      <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
    </div>
  );

  return (
    <Modal
      centered
      title="Jadval ma'lumotlari"
      open={state?.visible}
      footer={footer()}
      onCancel={onCancel}
      className={styles.table_info}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.table_info__content}>
          <div className={styles.left}>
            <Table<ExpectedPaymentModel>
              pagination={false}
              rowKey={row => row.id}
              locale={{
                emptyText: <TableEmpty />
              }}
              columns={columns}
              dataSource={data?.expected_payments}
            />
          </div>
          <div className={styles.right}>
            <div className={styles.amount}>
              <div className={styles.icon}>
                <CurrencyDollarCircleIcon />
              </div>
              <p>Jadvaldagi summa</p>
              <h3>
                {data?.amount?.toLocaleString()} {data?.currency?.symbol}
              </h3>
            </div>
            <div className={styles.amount}>
              <div className={styles.icon}>
                <CoinsHandIcon />
              </div>
              <p>Qoldiq summa</p>
              <h3>
                {data?.debt_amount?.toLocaleString()} {data?.currency?.symbol}
              </h3>
            </div>
            <div className={styles.amount}>
              <div className={styles.icon}>
                <CoinsStackedIcon />
              </div>
              <p>To'langan summa</p>
              <h3>
                {data?.paid_amount?.toLocaleString()} {data?.currency?.symbol}
              </h3>
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default TableInformationsModal;
