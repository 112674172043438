import { ReactNode } from "react";

import { NotificationTypeEnum } from "../../../utils/enums/notificationTypeEnum";
import { NotificationModel } from "../../../utils/models/notificationModel";
import ConfirmationOrderPending from "./confirmation/ConfirmationOrderPending";
import ConfirmationOrderRecieved from "./confirmation/ConfirmationOrderRecieved";
import ConfirmationOrderRejected from "./confirmation/ConfirmationOrderRejected";
import ConfirmationWorkPending from "./confirmation/ConfirmationWorkPending";
import ConfirmationWorkRecieved from "./confirmation/ConfirmationWorkRecieved";
import InventoryDefectCreated from "./inventory/InventoryDefectCreated";
import InventoryDefectRecieved from "./inventory/InventoryDefectRecieved";
import InventoryDefectRejected from "./inventory/InventoryDefectRejected";
import InventoryRequestCreated from "./inventory/InventoryRequestCreated";
import InventoryRequestRecieved from "./inventory/InventoryRequestRecieved";
import InventoryRequestRejected from "./inventory/InventoryRequestRejected";
import OrderCreated from "./order/OrderCreated";
import OrderPartiallyRecieved from "./order/OrderPartiallyRecieved";
import OrderPending from "./order/OrderPending";
import OrderRecieved from "./order/OrderRecieved";
import OrderRejected from "./order/OrderRejected";
import PaymentExpenseCreated from "./payment/PaymentExpenseCreated";
import PaymentExpensePending from "./payment/PaymentExpensePending";
import PaymentExpenseRecieved from "./payment/PaymentExpenseRecieved";
import PaymentExpenseRejected from "./payment/PaymentExpenseRejected";
import PaymentTransferCreated from "./payment/PaymentTransferCreated";
import PaymentTransferRecieved from "./payment/PaymentTransferRecieved";
import PaymentTransferRejected from "./payment/PaymentTransferRejected";
import WarehouseDefectCreated from "./warehouse/WarehouseDefectCreated";
import WarehouseDefectRecieved from "./warehouse/WarehouseDefectRecieved";
import WarehouseDefectRejected from "./warehouse/WarehouseDefectRejected";
import WarehouseProductCreated from "./warehouse/WarehouseProductCreated";
import WarehouseTransferAdditionalCreated from "./warehouse/WarehouseTransferAdditionalCreated";
import WarehouseTransferCreated from "./warehouse/WarehouseTransferCreated";
import WarehouseTransferRecieved from "./warehouse/WarehouseTransferRecieved";
import WarehouseTransferRejected from "./warehouse/WarehouseTransferRejected";
import ConfirmationWorkCreated from "./confirmation/ConfirmationWorkCreated";
import ConfirmationWorkCreatedReceived from "./confirmation/ConfirmationWorkCreatedReceived";
import ConfirmationWorkCreatedRejected from "./confirmation/ConfirmationWorkCreatedRejected";

export const NotificationTypes: Record<
  NotificationTypeEnum,
  (
    record: NotificationModel,
    onNavigate: (url: string, record: NotificationModel) => void,
    onOpenChat?: (objectId: number, type: string) => void
  ) => ReactNode
> = {
  [NotificationTypeEnum.INVENTORY_DEFECT_CREATED]: (record, _, onOpenChat) => (
    <InventoryDefectCreated record={record} onOpenChat={onOpenChat!} />
  ),
  [NotificationTypeEnum.INVENTORY_DEFECT_RECIEVED]: (record, onNavigate) => (
    <InventoryDefectRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.INVENTORY_DEFECT_REJECTED]: (record, onNavigate) => (
    <InventoryDefectRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.INVENTORY_REQUEST_CREATED]: (record, onNavigate) => (
    <InventoryRequestCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.INVENTORY_REQUEST_RECIEVED]: (record, onNavigate) => (
    <InventoryRequestRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.INVENTORY_REQUEST_REJECTED]: (record, onNavigate) => (
    <InventoryRequestRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.ORDER_CREATED]: (record, onNavigate) => (
    <OrderCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.ORDER_PARTIALLY_RECIEVED]: (record, onNavigate) => (
    <OrderPartiallyRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.ORDER_PENDING]: (record, onNavigate) => (
    <OrderPending record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.ORDER_RECIEVED]: (record, onNavigate) => (
    <OrderRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.ORDER_REJECTED]: (record, onNavigate) => (
    <OrderRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_TRANSFER_CREATED]: (record, onNavigate) => (
    <PaymentTransferCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECIEVED]: (record, onNavigate) => (
    <PaymentTransferRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_TRANSFER_RECEIVED]: (record, onNavigate) => (
    <PaymentTransferRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_TRANSFER_REJECTED]: (record, onNavigate) => (
    <PaymentTransferRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED]: (record, _, onOpenChat) => (
    <WarehouseDefectCreated record={record} onOpenChat={onOpenChat!} />
  ),
  [NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED]: (record, onNavigate) => (
    <WarehouseDefectRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED]: (record, onNavigate) => (
    <WarehouseDefectRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_CREATED]: (record, onNavigate) => (
    <WarehouseProductCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_PRODUCT_RECIEVED]: (record, onNavigate) => (
    <WarehouseProductCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_CREATED]: (record, onNavigate) => (
    <WarehouseTransferCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_RECIEVED]: (record, onNavigate) => (
    <WarehouseTransferRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_REJECTED]: (record, onNavigate) => (
    <WarehouseTransferRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.WAREHOUSE_TRANSFER_ADDITIONAL_CREATED]: (record, onNavigate) => (
    <WarehouseTransferAdditionalCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_EXPENSE_CREATED]: (record, onNavigate) => (
    <PaymentExpenseCreated record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_EXPENSE_PENDING]: (record, onNavigate) => (
    <PaymentExpensePending record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_EXPENSE_RECIEVED]: (record, onNavigate) => (
    <PaymentExpenseRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.PAYMENT_EXPENSE_REJECTED]: (record, onNavigate) => (
    <PaymentExpenseRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_ORDER_PENDING]: (record, onNavigate) => (
    <ConfirmationOrderPending record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_ORDER_RECIEVED]: (record, onNavigate) => (
    <ConfirmationOrderRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_ORDER_REJECTED]: (record, onNavigate) => (
    <ConfirmationOrderRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_PENDING]: (record, onNavigate) => (
    <ConfirmationWorkPending record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_RECIEVED]: (record, onNavigate) => (
    <ConfirmationWorkRecieved record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_REJECTED]: (record, onNavigate) => (
    <ConfirmationOrderRejected record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED]: (record, _, onOpenChat) => (
    <ConfirmationWorkCreated record={record} onOpenChat={onOpenChat!} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_RECEIVED]: (record, onNavigate) => (
    <ConfirmationWorkCreatedReceived record={record} onNavigatePage={onNavigate} />
  ),
  [NotificationTypeEnum.CONFIRMATION_WORK_CREATED_REJECTED]: (record, onNavigate) => (
    <ConfirmationWorkCreatedRejected record={record} onNavigatePage={onNavigate} />
  )
};
