import { DatePicker, Form, Modal, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import DatepickerSuffixIcon from "../../../../app/assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { formRules } from "../../../../app/utils/constants/formRules";
import { useConnectToProject } from "../../../service/mutations";
import { useGetProjectsSelect } from "../../../service/queries";
import { ConnectProjectBodyModel } from "../../../utils/models/connectProjectBodyModel";
import { ProjectSelectModel } from "../../../utils/models/projectSelectModel";

import styles from "./attachToProject.module.scss";

const { Item, useForm } = Form;

const AttachToProject = () => {
  const {t} = useTranslation()
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const attachModalData = useAppSelector(
    (state) => state.inventoryReducer.attachModalData
  );
  const { data: projects } = useGetProjectsSelect();
  const { setAttachModalData } = inventoryReducerActions;
  const connectToProject = useConnectToProject();

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch(setAttachModalData({ visible: false, inventory_id: undefined }));
    form.resetFields();
  };

  const disabledDate = (current: Dayjs) => {
    return current && current <= dayjs().endOf("day");
  };

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      if (attachModalData?.project_id) {
        form.setFieldsValue({
          project_id: JSON.stringify({
            id: attachModalData?.project_id,
            name: attachModalData?.project_name,
          }),
        });
      }
    }
  };

  const onFinish = (values: ConnectProjectBodyModel) => {
    connectToProject
      .mutateAsync({
        project_id: (JSON.parse(values.project_id.toString()) as ProjectSelectModel)
          .id,
        start_date: dayjs(values.start_date).format(dayjsFormats.DATE),
        due_date: dayjs(values.due_date).format(dayjsFormats.DATE),
        inventory_id: attachModalData.inventory_id!,
      })
      .then(() => onCancel());
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={attachModalData.visible}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      closeIcon={<CloseIcon />}
      width={548}
      okButtonProps={{
        loading: connectToProject.isLoading,
      }}
      title={t("Inventar.Loyihaga biriktirish")}
      cancelText={t("Inventar.Ortga")}
      okText={t("products.Saqlash")}
      className={styles.attach}
    >
      {attachModalData?.project_name && (
        <div className={styles.name}>
          <p>{t("Inventar.Loiha")}</p>
          <p>{attachModalData?.project_name}</p>
        </div>
      )}
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        className={styles.form}
      >
        <Item<ConnectProjectBodyModel>
          name="project_id"
          label={t("MainLayoutLinks.Loyiha")}
          rules={formRules()}
        >
          <Select
            showSearch
            popupClassName={styles.project_modal_popup}
            placeholder={t("Inventar.Loyiha qidirish")}
            filterOption={(input, option) => {
              const text = JSON.parse(option?.value ?? "");

              if (text?.name?.toLowerCase().includes(input.toLowerCase())) {
                return true;
              }
              return false;
            }}
            filterSort={(optionA, optionB) =>
              optionA?.value?.toString().localeCompare(optionB?.value?.toString())
            }
            options={projects?.map((item) => ({
              label: <p>{item.name}</p>,
              value: JSON.stringify(item),
            }))}
          />
        </Item>
        <div className={styles.dates}>
          <Item<ConnectProjectBodyModel>
            name="start_date"
            label={t("Inventar.Boshlanish sanasi")}
            className={styles.date_picker}
            initialValue={
              attachModalData?.start_date
                ? dayjs(attachModalData?.start_date, dayjsFormats.DATE)
                : dayjs()
            }
            rules={formRules()}
          >
            <DatePicker format={dayjsFormats.DATE} suffixIcon={false} disabled />
          </Item>
          <Item<ConnectProjectBodyModel>
            name="due_date"
            label={t("Inventar.Yakunlash sanasi")}
            className={styles.date_picker}
            initialValue={
              attachModalData?.due_date
                ? dayjs(attachModalData?.due_date, dayjsFormats.DATE)
                : dayjs().add(1, "w")
            }
            rules={formRules()}
          >
            <DatePicker
              format={dayjsFormats.DATE}
              suffixIcon={<DatepickerSuffixIcon />}
              placeholder={t("Inventar.Sanani tanlang")}
              disabledDate={disabledDate}
            />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AttachToProject;
