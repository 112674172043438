import React from "react";
import { Table } from "antd";
import classNames from "classnames";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import ActionDots from "../action-dots/ActionDots";
import { useGetCustomFieldsView } from "../../../service/queries";
import Pagination from "../../../../app/components/pagination/Pagination";
import { Status, StatusNames } from "../../../../app/utils/models/status";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { CustomFieldModel } from "../../../utils/models/custom-field/customFieldModel";
import { CustomFieldLocationValue } from "../../../utils/constants/customFieldLocationValue";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";

import styles from "./customFieldTable.module.scss";

const CustomFieldTable = () => {
  const { i18n,t } = useTranslation();
  const { data, isLoading } = useGetCustomFieldsView();
  const routePermissions = useRoutePermissions("Sozlamalar", routeSubmodules);
  const actions = routePermissions.actions("O'zgaruvchilar");

  const positionsView = (record: CustomFieldModel) => {
    return record?.custom_field_locations?.map((item, index, arr) => {
      if (arr.length === 0 || index === arr.length - 1) {
        return CustomFieldLocationValue[item];
      }
      return CustomFieldLocationValue[item] + ", ";
    });
  };

  const columns: ColumnsType<CustomFieldModel> = [
    {
      title: `${t("settings.Nomi")}`,
      render: (record: CustomFieldModel) => record?.name[i18n.language],
    },
    {
      title: `${t("settings.Joylashuv")}`,
      render: (record: CustomFieldModel) => positionsView(record),
      width: "24rem",
    },
    {
      title: `${t("settings.Yaratilgan sana")}`,
      dataIndex: "created_date",
    },
    {
      title: `${t("settings.Yaratuvchi")}`,
      render: (record: CustomFieldModel) => (
        <React.Fragment>
          {record?.creator ? (
            <div className={styles.creator}>
              {record?.creator?.image ? (
                <img
                  alt={record?.creator?.image}
                  src={record?.creator?.image}
                  className={styles.creator__avatar}
                />
              ) : (
                <div className={styles.creator__avatar}>
                  {getFirstLetter(record?.creator?.full_name)}
                </div>
              )}
              <span>{record?.creator?.full_name}</span>
            </div>
          ) : (
            "-"
          )}
        </React.Fragment>
      ),
    },
    {
      title: `${t("settings.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            ["status__active"]: value === Status.ACTIVE,
            ["status__passive"]: value === Status.PASSIVE,
          })}
        >
          {StatusNames[value]}
        </span>
      ),
    },
    {
      title: "",
      render: (record: CustomFieldModel) => (
        <ConditionalRender if={actions["delete"] || actions["update"]}>
          <ActionDots record={record} />
        </ConditionalRender>
      ),
      width: "5%",
    },
  ];

  const rowKey = (record: CustomFieldModel) => {
    return record.id;
  };

  return (
    <>
      <Table<CustomFieldModel>
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />,
        }}
        loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        className={styles.table}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total,
          }}
        />
      </div>
    </>
  );
};

export default CustomFieldTable;
