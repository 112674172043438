import { AxiosError } from "axios";
import { z } from "zod";

export type SuccessRes<T = unknown> = { data: T; message: Record<string, string> };

export type ErrorRes = AxiosError<{ error: string | null; message: Record<string, string> }>;

export const baseErrorSchema = z.object({
  error: z.string().nullable(),
  message: z.record(z.string(), z.string()).or(z.string())
});

export const customFieldTypesEnum = z.enum(["text", "select", "date", "checkbox", "label"]);

export const customFieldLocationEnum = z.enum([
  "project",
  "section",
  "task",
  "task_product",
  "payment",
  "warehouse_product",
  "warehouse_product_offer",
  "warehouse_transfer",
  "warehouse_defect",
  "inventory_defect",
  "company_person",
  "task_progress",
  "inventory",
  "order"
]);

export const languageSelectSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: z.string(),
  icon: z.string().nullable(),
  symbol: z.string()
});

export const currencySchema = z.object({
  id: z.number(),
  icon: z.string(),
  symbol: z.string(),
  amount: z.number().optional(),
  name: z.record(z.string(), z.string())
});

export const unitSchema = z.object({
  id: z.number(),
  name: z.record(z.string(), z.string()),
  status: z.string(),
  icon: z.string().nullable(),
  symbol: z.record(z.string(), z.string()),
  description: z.record(z.string(), z.string()),
  is_piece: z.boolean().nullable()
});

export const resourceSchema = z.object({
  id: z.number(),
  name: z.record(z.string(), z.string()),
  color: z.string(),
  status: z.string(),
  description: z.record(z.string(), z.string()),
  symbol: z.record(z.string(), z.string())
});

export const productSchema = z.object({
  id: z.number(),
  name: z.record(z.string(), z.string()),
  code: z.string(),
  resource: resourceSchema
});

export const customFieldSchema = z.object({
  id: z.number(),
  name: z.record(z.string(), z.string()),
  type: customFieldTypesEnum,
  custom_field_options: z.array(
    z.object({
      id: z.number(),
      name: z.record(z.string(), z.string()),
      color: z.string()
    })
  ),
  custom_field_locations: z.array(customFieldLocationEnum)
});

export const customFieldValueSchema = z.object({
  value: z.string(),
  custom_field: customFieldSchema,
  custom_field_option: z.object({
    id: z.number(),
    name: z.record(z.string(), z.string()),
    color: z.string()
  }),
  file: z.object({ id: z.number(), name: z.string(), original_name: z.string(), size: z.number() }),
  id: z.number()
});

export const idAndNameSchema = z.object({
  id: z.number(),
  name: z.string().or(z.record(z.string(), z.string())).nullable()
});

export const userSchema = z.object({
  id: z.number(),
  full_name: z.string(),
  image: z.string().optional().nullable()
});

const alignValues = z.enum(["left", "right", "center"]);

const fixedValues = z.enum(["left", "right"]);

export const columnsSchema = z.object({
  title: z.union([z.string(), z.function().args(z.any()).returns(z.any())]),
  dataIndex: z.string().optional(),
  key: z.string().optional(),
  render: z.function().args(z.string(), z.any(), z.number()).returns(z.any()).optional().optional(),
  width: z.union([z.number(), z.string()]).optional(),
  fixed: z.union([fixedValues, z.boolean()]).optional(),
  align: alignValues.optional()
});

export type LanguageSelect = z.infer<typeof languageSelectSchema>;
export type Currency = z.infer<typeof currencySchema>;
export type Unit = z.infer<typeof unitSchema>;
export type Resource = z.infer<typeof resourceSchema>;
export type Product = z.infer<typeof productSchema>;
export type CustomField = z.infer<typeof customFieldSchema>;
export type CustomFieldValue = z.infer<typeof customFieldValueSchema>;
export type IdAndName = z.infer<typeof idAndNameSchema>;
export type User = z.infer<typeof userSchema>;
export type BaseError = AxiosError<z.infer<typeof baseErrorSchema>>;
export type ResBaseError = z.infer<typeof baseErrorSchema>;
export type Columns = z.infer<typeof columnsSchema>;
