import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Popover } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import ArrowNarrowIcon from "../../../../../app/assets/icons/ArrowNarrowIcon";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryKeys } from "../../../../../app/utils/constants/queryKeys";
import { UnitModel } from "../../../../../app/utils/constants/unitModel";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import CodepenIcon from "../../../../../warehouse/assets/icons/CodepenIcon";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";

import AgentSelect from "./AgentSelect";

import styles from "./offerPartyModalRight.module.scss";

const OfferPartyModalRight: React.FC = () => {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");

  const { setPartyEstimate } = supplyActions;
  const { setOfferPartyRightItemData } = supplyOfferActions;

  const { rightData, leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const products = useMemo(() => leftData, [leftData]);

  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);

  const onOpenEstimate = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const onChangeDeliveryDate = (e: Dayjs | null) => {
    dispatch(
      setOfferPartyRightItemData({
        key: "delivery_date",
        value: e
      })
    );
  };

  const deliveryDateValue = () => {
    const findValue = rightData?.delivery_date;

    return findValue ? dayjs(findValue, dayjsFormats.DATE) : null;
  };

  const onChangePaymentDate = (e: Dayjs | null) => {
    dispatch(
      setOfferPartyRightItemData({
        key: "payment_date",
        value: e
      })
    );
  };

  const paymentDateValue = () => {
    const findValue = rightData?.payment_date;

    return findValue ? dayjs(findValue, dayjsFormats.DATE) : null;
  };

  const totalElemClass = `${styles.total} ${isEmptyArr(products) ? styles.total__disabled : styles.total__active}`;

  const totalAmount = useMemo(
    () =>
      products?.reduce(
        (first, second) =>
          first +
          parseLocaledString(second?.amount ? second?.amount : "0") *
            parseLocaledString(second?.quantity ? String(second?.quantity) : "0"),
        0
      ),
    [products]
  );

  const sortOrder = useMemo(
    () =>
      products?.reduce((prev: { [key: string]: number }, current) => {
        const findUnit = unitData?.find(unit => unit?.id === current?.unit_id);

        if (findUnit?.name[i18n.language]) {
          prev[findUnit?.name[i18n.language]] =
            (prev[findUnit?.name[i18n.language]] ?? 0) + parseLocaledString(String(current?.quantity ?? 0));
        }
        return prev;
      }, {}) || {},
    [products, i18n.language, unitData]
  );

  const sortOrderKeys = Object.keys(sortOrder);

  const allUnitText = useMemo(
    () => (
      <div className={styles.units}>
        {sortOrderKeys?.map((key, index) => (
          <div className={styles.units__item} key={index}>
            <p>{sortOrder[key]}</p>
            <span>{key}</span>
            {index + 1 !== sortOrderKeys?.length && <p>,</p>}
          </div>
        ))}
      </div>
    ),
    [sortOrderKeys, sortOrder]
  );

  return (
    <div className={styles.right}>
      <div className={styles.right__top}>
        <AgentSelect />
        <div className={styles.item}>
          <label>Yetkazish sanasi</label>
          <DatePicker
            placeholder="Tanlang"
            format={dayjsFormats.DATE}
            value={deliveryDateValue()}
            onChange={onChangeDeliveryDate}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </div>
        <div className={styles.item}>
          <label>To'lash sanasi</label>
          <DatePicker
            placeholder="Tanlang"
            format={dayjsFormats.DATE}
            value={paymentDateValue()}
            onChange={onChangePaymentDate}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </div>
      </div>
      <div className={styles.right__bottom}>
        {offerActions.add_resource && (
          <div className={styles.card} onClick={onOpenEstimate}>
            <div className={styles.card__left}>
              <div className={styles.icon}>
                <CodepenIcon />
              </div>
              <h4>Smeta bo’yicha keltirilishi kerak resurslar</h4>
            </div>
            <div className={styles.card__right}>
              <ArrowNarrowIcon rotate={180} />
            </div>
          </div>
        )}
        <div className={totalElemClass}>
          <div className={styles.total__item}>
            <h4>Jami mahsulotlar:</h4>
            <Popover title={allUnitText} placement="left">
              <p>{sortOrderKeys?.length ?? 0} birlik</p>
            </Popover>
          </div>
          <div className={styles.total__item}>
            <h4>Summa:</h4>
            <p>{totalAmount ? totalAmount?.toLocaleString(RU) : 0}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferPartyModalRight;
