import { useTranslation } from "react-i18next";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseDefectRecieved = ({ record, onNavigatePage }: Props) => {
  const { i18n } = useTranslation();
  return (
    <div
      onClick={() => onNavigatePage("/warehouse?tab=defect", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} yaroqsizga chiqarilgan{" "}
        {record?.warehouse_defect?.product?.name[i18n.language]}ni tasdiqlamadi.
      </p>
    </div>
  );
};

export default WarehouseDefectRecieved;
