import React, { ReactNode } from "react";
import { Dropdown, Table } from "antd";
import { ItemType } from "antd/es/menu/interface";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import DeletePopconfirm from "../../../../../app/components/delete-popconfirm/DeletePopconfirm";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import EyeIcon from "../../../../../projects/assets/icons/EyeIcon";
import { useDeleteRole } from "../../../../service/mutations";
import { useGetRoles } from "../../../../service/queries";
import { RoleModel } from "../../../../utils/models/roles/roleModel";
import SettingsRolesModal from "../modal/SettingsRolesModal";

interface StateModel {
  visible: boolean;
  isView?: boolean;
  id?: number;
}

type Props = {
  modalData: StateModel;
  setModalData: React.Dispatch<React.SetStateAction<StateModel>>;
};

const SettingsRolesTable: React.FC<Props> = ({ modalData, setModalData }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetRoles();
  const deleteRole = useDeleteRole();

  // delete role
  const onDeleteRole = (id: number) => {
    deleteRole.mutate(id);
  };

  // update role
  const onUpdateRole = (record: RoleModel, isView: boolean) => () => {
    setModalData({
      visible: true,
      id: record.id,
      isView
    });
  };

  const items = (record: RoleModel): ItemType[] => [
    {
      key: "3",
      label: (
        <div className="d_f ai_c cg_8">
          <EyeIcon />
          <span>{t("settings.Ko'rish")}</span>
        </div>
      ),
      onClick: onUpdateRole(record, true)
    },
    {
      key: "1",
      label: (
        <div className="d_f ai_c cg_8">
          <EditIcon />
          <span>{t("settings.Tahrirlash")}</span>
        </div>
      ),
      onClick: onUpdateRole(record, false)
    },
    {
      key: "2",
      label: (
        <DeletePopconfirm onDelete={() => onDeleteRole(record.id)}>
          <div className="d_f ai_c cg_8">
            <DeleteIcon />
            <span>{t("settings.O'chirish")}</span>
          </div>
        </DeletePopconfirm>
      )
    }
  ];

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const dropdownRender = (origin: ReactNode) => <div onClick={stopPropagation}>{origin}</div>;

  // table columns
  const columns: ColumnsType<RoleModel> = [
    {
      title: `${t("products.Nomi")}`,
      dataIndex: "name"
    },
    {
      title: `${t("Xodimlar.Izoh")}`,
      dataIndex: "description"
    },
    {
      title: "",
      render: (record: RoleModel) => (
        <Dropdown trigger={["click"]} menu={{ items: items(record) }} dropdownRender={dropdownRender}>
          <div className="d_f jc_fe ai_c c_p" onClick={stopPropagation}>
            <UnreadMessageDots />
          </div>
        </Dropdown>
        // <div className="action">
        //   <DeletePopconfirm onDelete={() => onDeleteRole(record.id)}>
        //     <Button className="action__delete" type="link">
        //       <DeleteIcon />
        //     </Button>
        //   </DeletePopconfirm>
        //   <Button
        //     className="action__edit"
        //     type="link"
        //     onClick={() => onUpdateRole(record)}
        //   >
        //     <EditIcon />
        //   </Button>
        // </div>
      )
    }
  ];

  return (
    <>
      <Table
        dataSource={data?.data.data}
        columns={columns}
        rowKey={record => record.id}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{
          spinning: isLoading || deleteRole.isLoading,
          indicator: LoadingIndicator
        }}
        onRow={record => ({
          onClick: onUpdateRole(record, true),
          className: "c_p"
        })}
        pagination={false}
      />
      <SettingsRolesModal setData={setModalData} data={modalData} />
    </>
  );
};

export default SettingsRolesTable;
