import React from "react";

import { useAppSelector } from "../../../../../hooks/redux";
import { generalEditKeys } from "../../../utils/constants/generalEditData";

import OfferButtons from "./OfferButtons";
import OrderProductsButtons from "./OrderProductsButtons";

const GeneralEditingExtraContent = () => {
  const { generalEdit } = useAppSelector(state => state.appReducer);

  const contents = {
    [generalEditKeys.WAREHOUSE_PRODUCTS]: <OfferButtons />,
    [generalEditKeys.ORDERS]: <></>,
    [generalEditKeys.ORDER_PRODUCTS]: <OrderProductsButtons />,
    [generalEditKeys.PAYMENT]: <></>,
    [generalEditKeys.PAYMENT_ORDER]: <></>
  };

  return contents[generalEdit.key as keyof typeof contents];
};

export default GeneralEditingExtraContent;
