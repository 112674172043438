import React from "react";
import { useDispatch } from "react-redux";
import { Button, Modal, Spin } from "antd";
import dayjs from "dayjs";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { useAppSelector } from "../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { colors } from "../../../../app/utils/constants/colors";
import { RU } from "../../../../app/utils/constants/languages";
import { useGetOneExpectedPayment } from "../../../service/queries";
import { PaymentSourceElement } from "../income-expense-view/PaymentSourceElement";

import styles from "./expectedPaymentView.module.scss";

const ExpectedPaymentView: React.FC = () => {
  const { t } = useTranslation();
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");
  const dispatch = useDispatch();
  const { setExpectedPaymentView, setPayModal } = paymentReducerActions;
  const { visible, id, isNotPay, forPayQueryKeys } = useAppSelector(state => state.paymentReducer.expectedPaymentView);
  const { data, isLoading } = useGetOneExpectedPayment(id, visible);

  const onCancel = () => {
    dispatch(
      setExpectedPaymentView({
        visible: false,
        id: undefined
      })
    );
  };

  const onOpenPaymentModal = () => {
    if (data)
      dispatch(
        setPayModal({
          visible: true,
          isTemplate: false,
          queryKeys: forPayQueryKeys,
          type: PaymentTypeEnum.EXPENSE,
          payment_type: data as never,
          form_data: {
            id: data?.id,
            date: data?.date,
            amount: data?.debt_amount,
            project_id: data?.project?.id,
            description: data?.description,
            currency_id: data?.currency.id
          }
        })
      );
  };

  const footer = () => (
    <div className={styles.footer} onClick={onCancel}>
      <Button>{t("payment.Yopish")}</Button>
      <ConditionalRender if={paymentActions?.create && !isNotPay && data?.debt_amount && data?.debt_amount > 0}>
        <Button type="primary" onClick={onOpenPaymentModal}>
          {t("payment.To'lov qilish")}
        </Button>
      </ConditionalRender>
    </div>
  );

  const diffDate = () => {
    if (data?.date) {
      const [day, month, year] = data.date.split(".");
      const day1 = dayjs(`${month}.${day}.${year}`);
      const diff = dayjs().diff(day1, "days");

      return diff > 0 ? (
        <span
          style={{
            color: colors.ERROR_500
          }}
        >
          {diff} kun
        </span>
      ) : (
        "-"
      );
    }
    return "-";
  };

  return (
    <Modal centered open={visible} zIndex={11111} footer={footer} onCancel={onCancel} title="Kutilayotgan to'lov">
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.view}>
          <div className={styles.item}>
            <p>{t("payment.To'lash sanasi")}</p>
            <p className={styles.value}>{data?.date}</p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Kechikish")}</p>
            <p className={styles.value}>{diffDate()}</p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Lohiya")}</p>
            <p className={styles.value}>{data?.project?.name}</p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Umumiy summa")}</p>
            <p className={styles.value}>
              <span>{data?.amount?.toLocaleString(RU)}</span>
              <span>{data?.currency?.symbol}</span>
            </p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Qoldiq summa")}</p>
            <p className={styles.value}>
              <span>{data?.debt_amount?.toLocaleString(RU)}</span>
              <span>{data?.currency?.symbol}</span>
            </p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Manbaa")}</p>
            <p className={styles.value}>
              <PaymentSourceElement record={{ ...data, type: PaymentTypeEnum.EXPENSE } as never} />
            </p>
          </div>
          <div className={styles.item}>
            <p>{t("payment.Xodim")}</p>
            <p className={styles.value}>{data?.user?.full_name || "-"}</p>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ExpectedPaymentView;
