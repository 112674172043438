import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tabs, TabsProps } from "antd";
import { PaymentContext, PaymentContextDataState } from "features/payment/hooks/PaymentContext";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import ConversionInfoModal from "../../../app/components/conversion-info-modal/ConversionInfoModal";
import TransferHistoryModal from "../../../app/components/transfer-history-modal/TransferHistoryModal";
import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../app/utils/constants/queryParamsKeys";
import DefectModal from "../../../inventory/components/defect-modal/DefectModal";
import AttachToProject from "../../../inventory/components/inventories/attach-to-project/AttachToProject";
import InventoryCreate from "../../../inventory/components/inventories/inventory-create/InventoryCreate";
import InventoryView from "../../../inventory/components/inventories/inventory-view/InventoryView";
import CalendarHistory from "../../../payment/components/bottom/calendar/history/CalendarHistory";
import ExpectedPaymentModal from "../../../payment/components/bottom/expected-payment-modal/ExpectedPaymentModal";
import ExpectedPaymentView from "../../../payment/components/bottom/expected-payment-view/ExpectedPaymentView";
import IncomeExpenseView from "../../../payment/components/bottom/income-expense-view/IncomeExpenseView";
import IncomeOrExpenseModal from "../../../payment/components/bottom/income-or-expense/IncomeOrExpenseModal";
import OrderDeleteArchive from "../../../payment/components/bottom/order-delete-archive/order-delete-archive";
import OrderView from "../../../payment/components/bottom/order-view/OrderView";
import PayModal from "../../../payment/components/bottom/pay-modal/PayModal";
import { PaymentTabKeys } from "../../../payment/utils/constants/paymentTabKeys";
import PartyModal from "../../../supply/components/parts/party-modal/PartyModal";
import CounterpartsContent from "../../components/detailed-counterparts/content/CounterpartsContent";
import ActModal from "../../components/detailed-counterparts/content/modals/act/ActModal";
import InventoryModal from "../../components/detailed-counterparts/content/modals/inventory-modal/InventoryModal";
import CounterpartsExtraContent from "../../components/detailed-counterparts/extra-content/CounterpartsExtraContent";
import { companyPersonBalanceSubmodules } from "../../utils/constants/routeSubmodules";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const { queries } = useQueryParams();
  const { search } = queries();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const type = searchParams.get(queryParamsKeys.TYPE);
  const { submodules } = useRoutePermissions("Kontragent balansi", companyPersonBalanceSubmodules);

  const defaultSettingTabs = localStorage.getItem("companyPersonTabs")
    ? JSON.parse(localStorage.getItem("companyPersonTabs") || "")
    : null;

  const [paymentContext, setPaymentContext] = useState<PaymentContextDataState>({
    settingTabs: defaultSettingTabs || {
      income_or_expense: true,
      order: true,
      work: true,
      inventory: true,
      calendar: true,
      income: true,
      expense: true
    },
    infoModalData: { visible: false }
  });

  const allItems = [
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INCOME_EXPENSE}`,
      label: `${t("Counterparts.Kirim-chiqim")}`,
      tabKey: PaymentTabKeys.INCOME_EXPENSE,
      children: <CounterpartsContent />,
      permissionKey: "Kirim-chiqim"
    },
    {
      key: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.INCOME}`,
      label: `${t("Counterparts.Kirim shartnoma")}`,
      tabKey: PaymentTypeEnum.INCOME,
      children: <CounterpartsContent />,
      permissionKey: "Kutilayotgan to'lovlar"
    },
    {
      key: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.EXPENSE}`,
      label: `${t("Supply.Chiqim shartnoma")}`,
      tabKey: PaymentTypeEnum.EXPENSE,
      children: <CounterpartsContent />,
      permissionKey: "Qarzlarim"
    },
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.ORDER}`,
      label: `${t("Counterparts.Partiyalar")}`,
      tabKey: PaymentTabKeys.ORDER,
      children: <CounterpartsContent />,
      permissionKey: "Buyurtmalar"
    },
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.WORK}`,
      label: `${t("Counterparts.Ishlar")}`,
      tabKey: PaymentTabKeys.WORK,
      children: <CounterpartsContent />,
      permissionKey: "Ishlarga to'lovlar"
    },
    {
      key: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INVENTORY}`,
      label: `${t("Counterparts.Inventarlar")}`,
      children: <CounterpartsContent />,
      permissionKey: "Inventarlar"
    }
  ].map(el => {
    if (search) {
      return {
        ...el,
        key: `${el?.key}&${queryParamsKeys.SEARCH}=${search}`
      };
    }
    return el;
  });
  const onChangeTab = (key: string) => {
    navigate({ search: `${key}` });
  };

  const tabItems = useCallback((): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    allItems.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    newItems?.push({
      key: search
        ? `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.CALENDAR}&${queryParamsKeys.SEARCH}=${search}`
        : `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.CALENDAR}`,
      label: `${t("Counterparts.To'lov kalendari")}`,
      children: <CounterpartsContent />,
      tabKey: PaymentTabKeys.CALENDAR
    });

    return newItems?.filter(
      item => paymentContext?.settingTabs![item?.tabKey as keyof typeof paymentContext.settingTabs]
    );
  }, [paymentContext, allItems, submodules, t]);

  const setContext = (value: PaymentContextDataState) => {
    setPaymentContext(prev => ({ ...prev, ...value }));

    if ("settingTabs" in value) {
      const redirect = Object.keys(value?.settingTabs || {})?.reduce(
        (p, key: string) => {
          if (!value?.settingTabs![tabKey as keyof typeof value.settingTabs]) {
            p.open = true;
          }
          if (!p.url && value?.settingTabs![key as keyof typeof value.settingTabs]) {
            p.url = allItems?.find(item => item?.key === key)?.key || "";
          }
          return p;
        },
        {
          open: false,
          url: ""
        }
      );

      localStorage.setItem("companyPersonTabs", JSON.stringify(value.settingTabs));

      if (redirect?.open && redirect?.url) {
        if (search) {
          onChangeTab(`${redirect?.url}&${queryParamsKeys.SEARCH}=${search}`);
        } else {
          onChangeTab(redirect?.url);
        }
      }
    }
  };

  const activeKey = useMemo(() => {
    if (tabKey) {
      if (type)
        return search
          ? `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.PAYMENT}&type=${type}&${queryParamsKeys.SEARCH}=${search}`
          : `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.PAYMENT}&type=${type}`;
      return search
        ? `${queryParamsKeys.STATISTICS_TYPE}=${tabKey}&${queryParamsKeys.SEARCH}=${search}`
        : `${queryParamsKeys.STATISTICS_TYPE}=${tabKey}`;
    }
    return tabItems()?.[0]?.key;
  }, [tabItems(), tabKey, type]);

  useEffect(() => {
    if (!searchParams?.size && paymentContext?.settingTabs) {
      const redirect = Object.keys(paymentContext?.settingTabs)?.reduce(
        (p, key: string) => {
          if (!paymentContext?.settingTabs![tabKey as keyof typeof paymentContext.settingTabs]) {
            p.open = true;
          }
          if (!p.url && paymentContext?.settingTabs![key as keyof typeof paymentContext.settingTabs]) {
            p.url = allItems?.find(item => item?.tabKey === key)?.key || "";
          }
          return p;
        },
        {
          open: false,
          url: ""
        }
      );

      if (redirect?.open && redirect?.url) {
        if (search) {
          onChangeTab(`${redirect?.url}&${queryParamsKeys.SEARCH}=${search}`);
        } else {
          onChangeTab(redirect?.url);
        }
      }
    }
  }, [allItems, onChangeTab, paymentContext, searchParams?.size, tabKey]);

  return (
    <PaymentContext.Provider
      value={{
        ...paymentContext,
        setContext
      }}
    >
      <div className={styles.detailed_counterparts}>
        <Tabs
          items={tabItems()}
          activeKey={activeKey}
          onChange={onChangeTab}
          tabBarExtraContent={<CounterpartsExtraContent />}
        />
        <IncomeOrExpenseModal />
        <InventoryModal />
        <PayModal />
        <ExpectedPaymentView />
        <ExpectedPaymentModal isCounterParts />
        <InventoryView isCompanyPerson />
        <AttachToProject />
        <DefectModal />
        <InventoryCreate />
        <ActModal />
        <CalendarHistory isCompanyPerson />
        <OrderView isCounterpart />
        <OrderDeleteArchive />
        <ConversionInfoModal />
        <TransferHistoryModal />
        <PartyModal />
        <IncomeExpenseView />
      </div>
    </PaymentContext.Provider>
  );
};

export default Index;
