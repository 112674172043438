import React from "react";

const SmallFolderIcon: React.FC = () => {
  return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8333 5.83333L9.90368 3.9741C9.63613 3.439 9.50235 3.17144 9.30277 2.97597C9.12628 2.80311 8.91356 2.67164 8.68002 2.59109C8.41593 2.5 8.1168 2.5 7.51854 2.5H4.33329C3.39987 2.5 2.93316 2.5 2.57664 2.68166C2.26304 2.84144 2.00807 3.09641 1.84828 3.41002C1.66663 3.76654 1.66663 4.23325 1.66663 5.16667V5.83333M1.66663 5.83333H14.3333C15.7334 5.83333 16.4335 5.83333 16.9683 6.10582C17.4387 6.3455 17.8211 6.72795 18.0608 7.19836C18.3333 7.73314 18.3333 8.4332 18.3333 9.83333V13.5C18.3333 14.9001 18.3333 15.6002 18.0608 16.135C17.8211 16.6054 17.4387 16.9878 16.9683 17.2275C16.4335 17.5 15.7334 17.5 14.3333 17.5H5.66663C4.26649 17.5 3.56643 17.5 3.03165 17.2275C2.56124 16.9878 2.17879 16.6054 1.93911 16.135C1.66663 15.6002 1.66663 14.9001 1.66663 13.5V5.83333Z"
      stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>;
};

export default SmallFolderIcon;