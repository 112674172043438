import React from "react";
import { Form, FormInstance, FormListOperation, Input } from "antd";
import { Rule } from "antd/es/form";

import DeleteIcon from "../../../../../../../app/assets/icons/DeleteIcon";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { CurrencyModel } from "../../../../../../../settings/utils/models/currency/currencyModel";
import MarkIcon from "../../../../../../assets/icons/MarkIcon";
import { ExchangeFormModel } from "../../../../../../utils/models/exchangeFormModel";
import { TransferBodyModel } from "../../../../../../utils/models/transferBodyModel";

import styles from "../../../../transfer-drawer/top/project-card/project-card.module.scss";

type Props = {
  index: number;
  operation: FormListOperation;
  parentName: (string | number)[];
  form: FormInstance<TransferBodyModel>;
};

const { Item, useWatch } = Form;

const Card: React.FC<Props> = ({ form, operation, index, parentName }) => {
  const currency = JSON.parse(useWatch("first_currency", form) || "{}") as CurrencyModel;

  const record = useWatch(
    [...parentName, "financials", index],
    form
  ) as ExchangeFormModel["expense_payment"]["payment_types"][number]["financials"][number];

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const parseValue = parseLocaledString(value || "0");
        const financials = form.getFieldValue([
          ...parentName,
          "financials"
        ]) as ExchangeFormModel["expense_payment"]["payment_types"][number]["financials"];
        const maxAmount = form.getFieldValue([...parentName, "max_amount"]) as number;
        const paymentAmount = parseLocaledString(form.getFieldValue([...parentName, "amount"]) || "0");
        const financialsAmount = financials?.reduce((previousValue, currentValue, currentIndex) => {
          if (currentIndex !== index) {
            return previousValue + parseLocaledString(currentValue?.amount || "0");
          }

          return previousValue;
        }, 0);

        if (maxAmount < paymentAmount + financialsAmount + parseValue) {
          return Promise.reject(
            `Kiritilgan miqdor ${maxAmount - paymentAmount - financialsAmount} ${currency?.symbol} dan kichik bo'lishi zarur`
          );
        }

        return Promise.resolve();
      }
    }
  ];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue([...parentName, "financials", index, "amount"], localeFormatter(e?.target?.value));
  };

  const onRemoveItem = () => {
    operation.remove(index);
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <MarkIcon />
        <div className={styles.text}>
          <h3>{record?.name}</h3>
        </div>
      </div>
      <div className={styles.right}>
        <Item name={[index, "id"]} className="hidden" />
        <Item name={[index, "amount"]} rules={amountRule}>
          <Input placeholder="0" onChange={onChangeAmount} suffix={currency?.symbol} />
        </Item>
        <div className={styles.close} onClick={onRemoveItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default Card;
