import React, { useState } from "react";
import { DataNode } from "antd/es/tree";
import { Button, Form, Input, Modal, Spin, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { LoadingOutlined } from "@ant-design/icons";

import { Tree } from "../../../../app/components/tree/Tree";
import { formRules } from "../../../../app/utils/constants/formRules";
import { parseTreeValue } from "../../../../app/utils/helpers/parseTreeValue";
import { useCreateWarehouse, useUpdateWarehouse } from "../../../service/mutations";
import { useGetOneWarehouse, useGetProjectTree } from "../../../service/queries";
import { settingsQueryNames } from "../../../utils/constants/settingsQueryNames";
import { ProjectTreeModel } from "../../../utils/models/project/projectTreeModel";
import { WarehouseBodyModel } from "../../../utils/models/warehouse/warehouseBodyModel";
import { WarehouseFormModel } from "../../../utils/models/warehouse/warehouseFormModel";
import { AppendEmployee } from "../../../../app/components/append-employee/AppendEmployee";
import { UserSelectModel } from "../../../../app/utils/models/user/userSelectModel";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { ProjectParsedTreeModel } from "../../../utils/models/project/projectParsedTreeModel";
import { useTranslation } from "react-i18next";

import styles from "./settingsWarehouseModal.module.scss";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { useDispatch } from "react-redux";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import { useAppSelector } from "../../../../../hooks/redux";

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => void;

const SettingsWarehouseModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setWarehouseModal } = settingsActions;
  const [form] = Form.useForm();
  const { visible, data, name } = useAppSelector(
    (state) => state.settingsReducer.warehouse
  );
  const [users, setUsers] = useState<UserSelectModel[] | undefined>([]);
  const createWarehouse = useCreateWarehouse();
  const updateWarehouse = useUpdateWarehouse();
  const { data: projects, isLoading: isLoadingProjects } = useGetProjectTree(
    visible!
  );
  const { data: oneWarehouse } = useGetOneWarehouse(data?.id);
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const qc = useQueryClient();

  const onCancel = () => {
    dispatch(
      setWarehouseModal({
        visible: false,
        data,
        name: "",
      })
    );
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      if (name) {
        form.setFieldsValue({
          ...data,
          user_ids: data?.users?.map((item) => item?.id),
          name,
        });
      } else {
        form.setFieldsValue({
          ...data,
          user_ids: data?.users?.map((item) => item?.id),
        });
      }

      data && setUsers(data?.users);
    } else {
      dispatch(
        setWarehouseModal({
          visible: false,
          data: undefined,
        })
      );

      setUsers([]);
      form.resetFields();
    }
  };

  const onSuccess = () => {
    onCancel();

    if (id) qc.invalidateQueries([settingsQueryNames.DETAILED_WAREHOUSE_FOLDER, id]);
  };

  const onFinish = (values: WarehouseFormModel) => {
    const warehouse_id = data?.id;

    const parsedTree: ProjectParsedTreeModel = {
      project_folder_ids: [],
      project_ids: [],
    };

    let req: WarehouseBodyModel = {
      ...values,
      ...parseTreeValue<ProjectParsedTreeModel>(values.project ?? [], parsedTree),
    };

    delete req?.project;
    // delete req?.folder_ids;

    if (id) {
      req = Object.assign(req, { warehouse_folder_id: +id });
    }

    if (warehouse_id) {
      updateWarehouse.mutateAsync({ ...req, id: warehouse_id }).then(onSuccess);
    } else {
      createWarehouse.mutateAsync(req).then(onSuccess);
    }
  };

  const treeData: (projects: ProjectTreeModel | undefined) => DataNode[] = (
    propsData
  ) => {
    return (propsData?.project_folders ?? [])
      .map((folder) => ({
        title: folder.name,
        key: JSON.stringify({
          value: folder.id,
          key: "folder_ids",
        }),
        children: folder.projects.map((project) => ({
          title: project.name,
          key: JSON.stringify({
            value: project.id,
            key: "project_ids",
          }),
        })),
      }))
      .concat(
        (propsData?.projects ?? []).map((project) => ({
          title: project.name,
          key: JSON.stringify({
            value: project.id,
            key: "project_ids",
          }),
          children: [],
        }))
      );
  };

  return (
    <Modal
      title={t("settings.interface.Omborxona")}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
      onOk={() => form.submit()}
      open={visible}
      onCancel={onCancel}
      centered
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createWarehouse.isLoading || updateWarehouse.isLoading,
      }}
      className={styles.modal}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        className={styles.modal__form}
      >
        <div className={styles.top}>
          <Form.Item
            name="name"
            label={t("Omborxona.Omborxona nomi")}
            rules={formRules()}
          >
            <Input placeholder={t("Omborxona.Omborxona nomini kirting")} />
          </Form.Item>
          <AppendEmployee
            data={users}
            setData={setUsers as SetUserType}
            formInstance={form}
            name="user_ids"
            initialValue={[...(oneWarehouse?.users ?? []), ...(data?.users ?? [])]}
          >
            <Form.Item
              name="user_ids"
              label={t("Xodimlar.Xodim")}
              className={styles.users_cont}
            >
              {isEmptyArr(users) ? (
                <Button className={styles.add_user} type="dashed" shape="circle">
                  <PlusIcon />
                </Button>
              ) : (
                <div className={styles.users}>
                  {users?.map((user) => (
                    <Tooltip key={user.id} title={user.full_name}>
                      {user?.image ? (
                        <img src={user.image} alt={user?.full_name} />
                      ) : (
                        <NotUserImage name={user?.full_name} isTooltip />
                      )}
                    </Tooltip>
                  ))}
                </div>
              )}
            </Form.Item>
          </AppendEmployee>
        </div>
        <Spin spinning={isLoadingProjects} indicator={<LoadingOutlined spin />}>
          <div className={styles.bottom}>
            <Tree
              data={treeData(projects)}
              initialData={treeData({
                project_folders: [],
                projects: oneWarehouse?.projects ?? [],
              })}
              formInstance={form}
              title={t("Kassa.Barcha loyihalar")}
              name="project"
            />
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default SettingsWarehouseModal;
