import React, { useState } from "react";
import { Select } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { colors } from "../../../../../app/utils/constants/colors";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import ChevroDownIcon from "../../../../../projects/assets/icons/ChevroDownIcon";
import { CompanyPersonEnum } from "../../../../utils/enum/companyPersonEnums";
import {
  CounterpartsAmountStatisticsCurrencyModel,
  CounterpartsAmountStatisticsModel
} from "../../../../utils/models/counterpartsStatisticsModel";

import styles from "./counterpartsStatistics.module.scss";

const { Option } = Select;

const statisticsType = {
  ALL: "all",
  IN_CURRENCY: "currency"
};

type Props = {
  data: CounterpartsAmountStatisticsModel | undefined;
};

const CounterpartsStatistics: React.FC<Props> = ({ data }) => {
  const basicCurrency = getBaseCurrency();
  const { queries, append, remove } = useQueryParams();
  const { balance_total_amount_type: balanceType } = queries();
  const { t } = useTranslation();

  const [type, setType] = useState(statisticsType.ALL);

  const generateAmountClassName = (amount: number | undefined, isDebtor?: boolean) => {
    if (isDebtor) {
      return styles.green;
    }
    if (amount && amount > 0) {
      return styles.green;
    }
    if (amount && amount < 0) {
      return styles.red;
    }
    return styles.gray;
  };

  const onChangeType = (e: string) => {
    setType(e);
  };

  const amountItem = (amount?: number, symbol?: string, isDebtor?: boolean, isTotalAmount?: boolean) => (
    <div className={styles.amount}>
      <h1 className={isDebtor ? styles.red : isTotalAmount ? "" : generateAmountClassName(amount, isDebtor)}>
        {amount?.toLocaleString(RU)}
      </h1>
      <span>{symbol || basicCurrency?.symbol}</span>
    </div>
  );

  const content = (
    key: keyof CounterpartsAmountStatisticsModel,
    balanceKey: keyof CounterpartsAmountStatisticsCurrencyModel,
    isDebtor?: boolean,
    isTotalAmount?: boolean
  ) => (
    <div className={styles.item__balance}>
      <ConditionalRender if={type === statisticsType.IN_CURRENCY}>
        {data?.currencies?.map(item => <div key={item?.currency_id}>
          {amountItem(item?.[balanceKey] as never, item?.symbol, isDebtor, isTotalAmount)}
        </div>)}
      </ConditionalRender>
      <ConditionalRender if={type === statisticsType.ALL}>
        {amountItem(data?.[key] as never, basicCurrency?.symbol, isDebtor, isTotalAmount)}
      </ConditionalRender>
    </div>
  );

  const onChangeStatisticsType = (type?: CompanyPersonEnum) => () => {
    if (type) append(queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE, type, true);
    else remove(queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE);
  };

  return (
    <div className={styles.statistics}>
      <div className={styles.top}>
        <h2>{t("counterparts.Kontragentlar statistikasi")}</h2>
        <Select
          onChange={onChangeType}
          defaultValue={statisticsType.ALL}
          suffixIcon={<ChevroDownIcon stroke={colors.GRAY_500} active />}
        >
          <Option value={statisticsType.ALL}>{t("counterparts.Umumiy valyuta")}</Option>
          <Option value={statisticsType.IN_CURRENCY}>{t("counterparts.Valyutalar kesimida")}</Option>
        </Select>
      </div>
      <div className={styles.bottom}>
        <div className={styles.item} onClick={onChangeStatisticsType()}>
          <h4>{t("counterparts.Umumiy balans")}</h4>
          {content("total_amount", "amount", false, true)}
        </div>
        <div
          onClick={onChangeStatisticsType(CompanyPersonEnum.DEBITOR)}
          className={classNames(styles.item, {
            [styles.debitor]: balanceType === CompanyPersonEnum.DEBITOR
          })}
        >
          <h4>{t("Counterparts.Debitorlar")}</h4>
          {content("debtor_total_amount", "debtor_amount", true)}
        </div>
        <div
          onClick={onChangeStatisticsType(CompanyPersonEnum.CREDITOR)}
          className={classNames(styles.item, {
            [styles.creditor]: balanceType === CompanyPersonEnum.CREDITOR
          })}
        >
          <h4>{t("counterparts.Kreditorlar")}</h4>
          {content("creditor_total_amount", "creditor_amount")}
        </div>
      </div>
    </div>
  );
};

export default CounterpartsStatistics;
