import { TABLE } from "../features/app/utils/constants/localStorageKeys";
import { queryParamsKeys } from "../features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../features/app/utils/constants/tableConfigKeys";
import { useQueryParams } from "./useQueryParams";

type Props = {
  endpoint: string;
  tableConfigKey: tableConfigKeys;
};

const useGetTableSize = ({ endpoint, tableConfigKey }: Props) => {
  const { queries } = useQueryParams();
  const { size } = queries();

  const defaultSize =
    size || JSON.parse(localStorage.getItem(TABLE) || "{}")?.[tableConfigKey];

  const url = `${endpoint}${`?${queryParamsKeys.SIZE}=${defaultSize || 10}`}`;

  return url;
};

export default useGetTableSize;
