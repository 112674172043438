import { ReactNode } from "react";

import CheckCircleIcon from "../../../payment/assets/icons/CheckCircleIcon";
import ClockCircleIcon from "../../../supply/assets/icons/ClockCircleIcon";
import SlashCircleIcon from "../../assets/icons/SlashCircleIcons";
import { ImpostStatusEnums } from "../enums/impostStatusEnums";

export const ImpostStatusIcons: Record<ImpostStatusEnums, ReactNode> = {
  [ImpostStatusEnums.PENDING]: <ClockCircleIcon />,
  [ImpostStatusEnums.RECIEVED]: <CheckCircleIcon />,
  [ImpostStatusEnums.REJECTED]: <SlashCircleIcon />,
};

export const ImpostStatusText = {
  [ImpostStatusEnums.PENDING]: "Qisman",
  [ImpostStatusEnums.RECIEVED]: "To'langan",
  [ImpostStatusEnums.REJECTED]: "To'lanmagan",
}