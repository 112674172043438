import React from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Form, FormInstance, Popover, Select } from "antd";

import { useGetCustomFieldSelect, useGetUsersSelect } from "../../../../../app/service/queries";
import { useGetOneOrder } from "../../../../service/query";
import { useAppSelector } from "../../../../../../hooks/redux";
import { UnitModel } from "../../../../../app/utils/constants/unitModel";
import { queryKeys } from "../../../../../app/utils/constants/queryKeys";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import ModalContentCustomFields from "../../../../../app/components/modal-content-custom-fields/ModalContentCustomFields";

import styles from "./right.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance;
};

const Right: React.FC<Props> = ({ form }) => {
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();

  const { visible, data: order } = useAppSelector(state => state.warehouseOrderReducer.view);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const { data: agents } = useGetUsersSelect(visible);
  const { data: warehouseProducts } = useGetOneOrder(order?.id, visible);
  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);

  const allUnitText = () => {
    const text: { quantity?: number; unit: string }[] = [];

    warehouseProducts?.forEach(item => {
      const findUnit = unitData?.find(unit => unit?.id === item?.unit?.id);

      text.push({
        quantity: item?.quantity ? item?.quantity : 0,
        unit: findUnit?.name[i18n.language] ?? "ta"
      });
    });

    return (
      <div className={styles.units}>
        {text?.map((item, index) => (
          <div className={styles.units__item} key={index}>
            <p>{item?.quantity ?? 0}</p>
            <span>{item?.unit}</span>
            {index + 1 !== text?.length && <p>,</p>}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.right}>
      <div className={styles.right__top}>
        <Item label={t("Monitoring.Vositachi")} name="agent_id">
          <Select disabled suffixIcon={null} placeholder={t("orderModal.Tanlanmagan")}>
            {agents?.map(item => (
              <Option value={item.id} key={item.id}>
                {item?.full_name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label={t("Monitoring.Yetkazish sanasi")} name="delivery_date">
          <DatePicker
            disabled
            format={dayjsFormats.DATE}
            placeholder={t("orderModal.Tanlanmagan")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        <Item label={t("Monitoring.To'lash sanasi")} name="payment_date">
          <DatePicker
            disabled
            format={dayjsFormats.DATE}
            placeholder={t("orderModal.Tanlanmagan")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        {!isEmptyArr(customFields) && (
          <ModalContentCustomFields form={form} disabled={true} visible={visible} customFields={customFields} />
        )}
      </div>
      <div className={styles.right__bottom}>
        <div className={styles.total}>
          <div className={styles.total__item}>
            <h4>{t("Monitoring.Jami mahsulotlar")}:</h4>
            <Popover title={allUnitText} placement="left">
              <p>
                {warehouseProducts?.length ?? 0} {t("Monitoring.birlik")}
              </p>
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Right;
