import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import merge from "lodash.merge";

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500
}

class Http {
  private instance: AxiosInstance | undefined;

  private readonly defaults: AxiosRequestConfig = {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8"
    }
  };

  private get http(): AxiosInstance {
    return this.instance != null ? this.instance : this.init();
  }

  public get request(): AxiosInstance {
    return this.http;
  }

  public init({
    config,
    configFn
  }: {
    config?: AxiosRequestConfig;
    configFn?: (config: InternalAxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
  } = {}) {
    const http = axios.create(merge(this.defaults, config));

    http.interceptors.request.use(
      async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> => {
        if (configFn) {
          const requestConfig = await configFn(config);

          if (requestConfig instanceof Promise) {
            return requestConfig.then(newConfig => Promise.resolve(merge(config, newConfig)));
          }

          const data = merge(config, requestConfig || {});

          return data;
        }

        return config;
      },
      error => Promise.reject(error)
    );

    // http.interceptors.response.use(
    //   response => response,
    //   error => {
    //     const { response } = error;

    //     return Http.handleError(response);
    //   }
    // );

    this.instance = http;
    return http;
  }

  private static handleError(error: AxiosError) {
    const { status } = error || {};

    switch (status) {
      case StatusCode.InternalServerError: {
        // Handle InternalServerError
        break;
      }
      case StatusCode.Forbidden: {
        // Handle Forbidden
        break;
      }
      case StatusCode.Unauthorized: {
        // Handle Unauthorized
        break;
      }
      case StatusCode.TooManyRequests: {
        // Handle TooManyRequests
        break;
      }
      default:
        return Promise.reject(error);
    }

    return Promise.reject(error);
  }
}

export const http = new Http();
