import { Button, Spin } from "antd";
import React, { useState } from "react";

import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";
import SettingsWarehouseCard from "../../components/warehouse/card/default/SettingsWarehouseCard";
import SettingsWarehouseModal from "../../components/warehouse/modal/SettingsWarehouseModal";
import { useGetWarehouses } from "../../service/queries";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import FolderCard from "../../../app/components/folder-card/FolderCard";
import NotData from "../../../app/components/not-data/NotData";
import emptyImage from "../../assets/images/warehouse.png";
import { useCreateWarehouseFolder, useDeleteWarehouseFolder, useUpdateWarehouseFolder } from "../../service/mutations";
import { folderName } from "../../utils/helper/folderName";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import { useDispatch } from "react-redux";
import { settingsActions } from "../../../../store/reducers/settingsReducer";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setWarehouseModal } = settingsActions;
  // checked
  const { data, isLoading } = useGetWarehouses();

  const updateFolder = useUpdateWarehouseFolder();
  const deleteFolder = useDeleteWarehouseFolder();
  const createFolder = useCreateWarehouseFolder();

  const [isCreating, setIsCreating] = useState<boolean>(false);

  const onCreateFolder = () => {
    setIsCreating(true);
  };

  const onOpenModal = () => {
    dispatch(
      setWarehouseModal({
        visible: true,
        data: undefined
      })
    );
  };

  const isAddWarehouse = () => {
    if (data?.warehouses && data?.warehouses?.length > 0) return true;
    if (data?.warehouse_folders && data?.warehouse_folders?.length > 0) return true;
    else return false;
  };

  return (
    <div className={styles.warehouse}>
      <div className={styles.warehouse__top}>
        <div className={styles.left}>
          <h3>{t("settings.interface.Omborxona")}</h3>
          <p>{t("Omborxona.Bu yerda siz omborxona yaratishingiz mumkin")}</p>
        </div>
        <Button onClick={onCreateFolder}>
          <FolderPlusIcon />
          {t("Kassa.Papka yaratish")}
        </Button>
      </div>
      <div className={styles.warehouse__bottom}>
        {!isEmptyArr(data?.warehouse_folders) && <h4>{t("Kassa.Papkalar")}</h4>}
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {isEmptyArr(data?.warehouse_folders) && isEmptyArr(data?.warehouses) && !isCreating && (
            <NotData
              containerClassName={styles.empty__container}
              img={{
                src: emptyImage,
                className: styles.empty_image
              }}
              name={{ text: t("Omborxona.Omborxonalar mavjud emas") }}
              text={{
                text: t("Omborxona.Omborxonalarni qo’shish orqali mahsulotlaringizni bir joyda jamlang")
              }}
              defaultButton={{
                text: `${t("Kassa.Papka yaratish")}`,
                onClick: onCreateFolder,
                icon: <FolderPlusIcon />
              }}
              primaryButton={{
                text: `${t("Omborxona.Omborxona qo'shish")}`,
                onClick: onOpenModal,
                icon: <PlusIcon color="#fff" />
              }}
            />
          )}
          <div className={styles.folders}>
            {!isEmptyArr(data?.warehouse_folders) &&
              data?.warehouse_folders?.map(item => (
                <FolderCard
                  id={item.id}
                  key={item.id}
                  color={item.color}
                  sliceTextCount={32}
                  placeholder={t("settings.interface.Omborxona")}
                  count={item.warehouses_count}
                  name={folderName(item.name)}
                  updateMutation={updateFolder}
                  deleteMutation={deleteFolder}
                  path={`${rootPaths.SETTINGS}${settingsPaths.WAREHOUSE}/${item.name}-${item.id}`}
                />
              ))}
            {isCreating && (
              <FolderCard
                placeholder={t("settings.interface.Omborxona")}
                isCreating={isCreating}
                setIsCreating={setIsCreating}
                createMutation={createFolder}
              />
            )}
          </div>
          <div className={styles.cards}>
            {!isEmptyArr(data?.warehouses) &&
              data?.warehouses?.map((card, index) => <SettingsWarehouseCard {...card} key={index} />)}
            {isAddWarehouse() && (
              <div className={styles.card_add} onClick={onOpenModal}>
                <PlusCircleIcon fill={false} />
                <p>{t("Omborxona.Omborxona qo'shish")}</p>
              </div>
            )}
            <SettingsWarehouseModal />
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Index;
