import React from "react";
import { ChatModel } from "features/app/utils/models/chatModel";
import { isImage, onDownloadFile } from "./ChatMessage";
import { Image } from "antd";
import EyeIcon from "features/projects/assets/icons/EyeIcon";
import { colors } from "modules/common";
import UploadFileIcon from "features/warehouse/assets/icons/UploadFileIcon";

import styles from "./chat.module.scss";

type Props = Pick<ChatModel, "files" | "message"> & {
  onOpenFileViewer: (format: string, url: string, name: string) => void;
  hour: string;
};

const OnlyFiles: React.FC<Props> = ({ files, message, onOpenFileViewer, hour }) => {
  return (
    <div>
      {!message &&
        files?.length > 0 &&
        files.map(file => (
          <div className={styles.file} key={file.id}>
            {isImage(file.name) ? (
              <Image
                src={file.name}
                alt={file.name}
                className={styles.image}
                preview={{
                  mask: (
                    <div className="flex items-center gap-1">
                      <EyeIcon color={colors.WHITE} /> Ko'rish
                    </div>
                  )
                }}
              />
            ) : (
              <div
                className={styles.file__top}
                onClick={() => onOpenFileViewer(file.original_name?.split(".")[1], file.name, file.original_name)}
              >
                <UploadFileIcon />
                <div className={styles.file_name}>
                  <h4>{file.original_name}</h4>
                  <p>{(file.size / 1024 / 1000).toFixed(3)} MB</p>
                </div>
              </div>
            )}
            <div className={styles.file__upload}>
              <div className={styles.upload_btn} onClick={() => onDownloadFile(file.name)}>
                Yuklab olish
              </div>
              <p>{hour}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default OnlyFiles;
