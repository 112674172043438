import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import dayjs from "dayjs";
import { uid } from "uid";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import CheckCircleIcon from "../../../../assets/icons/CheckCircleIcon";
import { PaymentFilterKeys } from "../../../../utils/enums/paymentFilterKeys";
import { CalendarMonthlyItemModel } from "../../../../utils/models/paymentCalendarModel";

import styles from "./paymentCalendarContent.module.scss";

type Props = CalendarMonthlyItemModel & {
  date: string;
};

const PaymentCalendarCardAndHeaderItem: React.FC<Props> = ({ type, percent, data, date }) => {
  const dispatch = useDispatch();
  const { queries } = useQueryParams();
  const { setCalendarHistory } = paymentReducerActions;
  const { view_type: viewType, filter_type: filterType } = queries();

  const names = {
    order: "Partiyalar",
    expected_payment: "To'lovlar",
  };

  const isComplete = percent === 100;

  const itemClassName = classNames({
    [styles.card__item__complete]: isComplete,
    [styles.card__item__order]: !isComplete && type === "order",
    [styles.card__item__payment]: !isComplete && type !== "order",
  });

  const generateDate = () => {
    if (viewType === PaymentTypeEnum.MONTHLY) {
      const lastDay = dayjs(`01.${date}`, dayjsFormats.DATE)
        .endOf("month")
        .format(dayjsFormats.DATE);

      return {
        minDate: `01.${date}`,
        maxDate: lastDay,
      };
    }
    return {
      minDate: `01.01.${date}`,
      maxDate: `31.12.${date}`,
    };
  };

  const onOpenCalendarHistory = () => {
    dispatch(
      setCalendarHistory({
        visible: true,
        page: 1,
        tabKey: filterType ? (filterType as PaymentFilterKeys) : (type as PaymentFilterKeys),
        minDate: generateDate()?.minDate,
        maxDate: generateDate()?.maxDate,
      })
    );
  };

  return (
    <div className={classNames(styles.card__item, itemClassName)} onClick={onOpenCalendarHistory}>
      <div className={styles.item__top}>
        <h3>{names[type]}</h3>
        <p>{isComplete ? <CheckCircleIcon /> : `${percent} %`}</p>
      </div>
      <div className={styles.item__bottom}>
        {data?.map(item => (
          <span key={uid()}>
            {isComplete
              ? `${item?.total_amount?.toLocaleString(RU)} ${item?.currency_symbol}`
              : `${item?.paid_amount?.toLocaleString(RU)} / ${item?.total_amount?.toLocaleString(
                  RU
                )} ${item?.currency_symbol}`}
          </span>
        ))}
      </div>
    </div>
  );
};

export default PaymentCalendarCardAndHeaderItem;
