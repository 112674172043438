import React from "react";

export const InfoHexagonIcon: React.FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_18521_26903)">
      <path
        d="M8 5.33344L8 8.00011M8 10.6668L7.99333 10.6668M14 10.7058L14 5.2944C14 5.06597 14 4.95176 13.9663 4.84989C13.9366 4.75978 13.8879 4.67706 13.8236 4.60726C13.7509 4.52837 13.651 4.4729 13.4514 4.36197L8.51802 1.62123C8.32895 1.51619 8.23441 1.46367 8.1343 1.44308C8.04569 1.42486 7.95431 1.42486 7.8657 1.44308C7.76559 1.46367 7.67105 1.51619 7.48198 1.62123L2.54865 4.36197C2.34896 4.4729 2.24912 4.52837 2.17642 4.60726C2.1121 4.67705 2.06343 4.75978 2.03365 4.84989C2 4.95176 2 5.06597 2 5.2944L2 10.7058C2 10.9342 2 11.0485 2.03365 11.1503C2.06343 11.2404 2.1121 11.3232 2.17642 11.3929C2.24912 11.4718 2.34896 11.5273 2.54864 11.6382L7.48198 14.379C7.67105 14.484 7.76558 14.5365 7.8657 14.5571C7.9543 14.5754 8.04569 14.5754 8.1343 14.5571C8.23441 14.5365 8.32895 14.484 8.51802 14.379L13.4514 11.6382C13.651 11.5273 13.7509 11.4718 13.8236 11.393C13.8879 11.3232 13.9366 11.2404 13.9663 11.1503C14 11.0485 14 10.9342 14 10.7058Z"
        stroke="#98A2B3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_18521_26903">
        <rect width="16" height="16" fill="white" transform="translate(16 16) rotate(-180)" />
      </clipPath>
    </defs>
  </svg>
);

export default InfoHexagonIcon;
