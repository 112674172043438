import React, { SetStateAction, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Radio, Spin, Tooltip } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { PaymentCashMoneyModel } from "features/payment/utils/models/paymentCashMoneyModel";

import { useAppSelector } from "../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../app/utils/constants/languages";

import styles from "./firstStep.module.scss";
import { useGetCashMoneySelect } from "features/payment/service/queries";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isExpectedPayment?: boolean;
  autoAdvance: boolean;
  setAutoAdvance: React.Dispatch<SetStateAction<boolean>>;
};

const { Item } = Form;

const FirstStep: React.FC<Props> = ({ setStep, isExpectedPayment, autoAdvance, setAutoAdvance }) => {
  const dispatch = useDispatch();
  const { setIncomeExpenseModal, setPayModal } = paymentReducerActions;
  const { visible, type, cash, companyPersonId, isVisibleMoreInfo, ...other } = useAppSelector(
    state => state.paymentReducer.income_expense_modal
  );
  const payModal = useAppSelector(state => state.paymentReducer.pay_modal);

  const {
    data: cashes,
    isLoading: isLoadingCash,
    isFetching
  } = useGetCashMoneySelect(isExpectedPayment ? payModal.visible : visible, {
    type: isExpectedPayment ? payModal?.type : type
  });

  const onChangeCash = (e: number) => {
    const selectCash = cashes?.find(item => item.id === e);

    if (!isExpectedPayment) {
      dispatch(
        setIncomeExpenseModal({
          type,
          visible,
          companyPersonId,
          cash: selectCash,
          isVisibleMoreInfo,
          ...other
        })
      );
    } else {
      dispatch(
        setPayModal({
          ...payModal,
          cash: selectCash
        })
      );
    }
    setAutoAdvance(true);
    setStep(step => step + 1);
  };

  const cashValue = () => {
    if (isExpectedPayment) return payModal?.cash?.id;
    return cash?.id;
  };

  const emptyContent = (
    <div className="my-10 flex items-center justify-center">
      <TableEmpty description="Kassalar mavjud emas" />
    </div>
  );

  const projectsTooltip = (item: PaymentCashMoneyModel) => {
    let tooltipTitle = "";
    const len = item?.projects?.length;

    item?.projects?.slice(1, len! - 1)?.forEach((project, index) => {
      tooltipTitle += `${project?.name}${index + 1 === item?.projects?.slice(1, len! - 1)?.length ? "" : " , "}`;
    });

    return (
      <Tooltip title={tooltipTitle}>
        <div className="flex items-center justify-center rounded-2xl bg-gray-100 px-2 py-1 font-medium text-gray-700">
          +{item?.projects && item?.projects?.slice(1, item?.projects?.length - 1)?.length}
        </div>
      </Tooltip>
    );
  };

  useEffect(() => {
    if (cashes && autoAdvance) {
      if (cashes?.length === 1) {
        if (!isExpectedPayment) {
          dispatch(
            setIncomeExpenseModal({
              type,
              visible,
              companyPersonId,
              cash: cashes[0],
              isVisibleMoreInfo,
              ...other
            })
          );
        } else {
          dispatch(
            setPayModal({
              ...payModal,
              cash: cashes[0]
            })
          );
        }

        setStep(state => {
          if (state === 0) {
            return state + 1;
          }

          return state - 1;
        });
      }
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingCash || isFetching} indicator={LoadingIndicator}>
      <ConditionalRender if={!isEmptyArr(cashes)} else={emptyContent}>
        <Item name="cash_id" className="my-5">
          <Radio.Group value={cashValue()}>
            <div className={styles.cash}>
              {cashes?.map(item => (
                <Radio key={item.id} value={item.id} onClick={() => onChangeCash(item.id)}>
                  <div className={styles.item}>
                    <h3>{item.name}</h3>
                    <ConditionalRender if={!isEmptyArr(item?.projects)}>
                      <div className="flex items-center gap-2">
                        <span className={styles.project_name}>{`${item?.projects![0]?.name}`}</span>
                        {item?.projects && item?.projects?.length > 2 && projectsTooltip(item)}
                      </div>
                    </ConditionalRender>
                    <div className={styles.currency}>
                      {item?.currencies?.map(currency => (
                        <div className={styles.currency__item} key={currency.id}>
                          <img src={currency.icon} alt={currency.symbol} />
                          <span>
                            {currency.amount?.toLocaleString(RU)} {currency.symbol}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </Radio>
              ))}
            </div>
          </Radio.Group>
        </Item>
      </ConditionalRender>
    </Spin>
  );
};

export default FirstStep;
