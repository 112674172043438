import React, { useRef, useState } from "react";
import { Form, Input, InputRef } from "antd";
import { useTranslation } from "react-i18next";

import { formRules } from "../../../utils/constants/formRules";
import { sliceText } from "../../../utils/helpers/sliceText";

type Props = {
  id?: number;
  text?: string;
  isEdit: boolean;
  isCreating?: boolean;
  onFinish: (values: { name: string }) => void;
  sliceTextCount?: number;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Editable: React.FC<Props> = ({
  text,
  isEdit,
  onFinish,
  setEdit,
  setIsCreating,
  sliceTextCount = 20,
  isCreating,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [value, setValue] = useState<string | undefined>(text);
  const inputRef = useRef<InputRef | null>(null);

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const { value } = target;
    const { key } = event;

    if (key === "Enter") {
      inputRef.current && inputRef.current.blur();

      if (!value) {
        form.setFieldsValue({
          name: text,
        });
        setValue(text);
      } else {
        form.submit();
      }
    }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    const value = e.target.value;
    setEdit && setEdit(false);

    if (!value) {
      form.setFieldsValue({
        name: text,
      });

      setIsCreating && setIsCreating(false);
    }

    if (value !== text && value) {
      form.submit();
    }
  };

  const onClicked = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.currentTarget?.value);
  };

  return isEdit ? (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name="name" initialValue={value} rules={formRules()}>
        <Input
          ref={inputRef}
          onBlur={onBlur}
          autoComplete="off"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onContextMenu={onClicked}
          onClick={onClicked}
          autoFocus={isCreating || isEdit}
          placeholder={t("products.Papka nomini kiriting")}
        />
      </Form.Item>
    </Form>
  ) : (
    <h4>{sliceText(value ?? "", sliceTextCount)}</h4>
  );
};

export default Editable;
