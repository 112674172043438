import React, { useEffect } from "react";
import { Form, FormInstance, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { colors } from "features/app/utils/constants/colors";
import { useTranslation } from "react-i18next";

import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetResourceSelect } from "../../../../../app/service/queries";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { IProductList } from "../../../../utils/models/productListModel";

import styles from "./productListModalTable.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  index: number;
  rowId: number | string;
  form: FormInstance;
};

const ProductListResourceSelect: React.FC<Props> = ({ index, form, rowId }) => {
  const { i18n,t } = useTranslation();
  const { data: resources } = useGetResourceSelect();
  const fieldName = ["products", index, "resource_id"];

  const onChangeResource = () => (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const products = form.getFieldValue("products") as IProductList[];

    form.setFieldValue(
      "products",
      products?.map(item => {
        if (item?.rowId === rowId) return { ...item, resource_id: e };
        return item;
      })
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: colors.ORANGE_600,
            color: colors.ORANGE_600
          }}
        >
          ---
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("Counterparts.Resurs nomi")}</div>
    </div>
  );

  useEffect(() => {
    if (resources && resources?.length > 0) {
      form.setFieldValue(fieldName, resources![1].id);
    }
  }, [resources]);

  return (
    <Item
      name={fieldName}
      rules={
        !(typeof rowId === "number")
          ? [
              {
                validator(rule, value, callback) {
                  if (typeof value === "number") {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                }
              }
            ]
          : []
      }
    >
      <Select
        showSearch
        suffixIcon={null}
        onChange={onChangeResource}
        popupMatchSelectWidth={false}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        disabled={typeof rowId === "number"}
        notFoundContent={<TableEmpty description={t("Counterparts.Kerakli resurs topilmadi")} />}
      >
        {resources?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language]
            }}
          >
            <div className="flex items-center gap-2">
              <div
                className="flex w-8 items-center justify-center rounded border border-solid px-2 py-0.5 text-xs font-medium"
                style={{
                  borderColor: item.color,
                  color: item.color
                }}
              >
                {item.symbol[i18n.language]}
              </div>
              {item.name[i18n.language]}
            </div>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductListResourceSelect;
