import { CompletedIcon } from "../../assets/icons/CompletedIcon";
import { PlanningIcon } from "../../assets/icons/PlanningIcon";
import { ProcessIcon } from "../../assets/icons/ProcessIcon";
import { SuspendedIcon } from "../../assets/icons/SuspendedIcon";
import { projectStatuses } from "../enums/projectStatuses";

export const statusOptions = [
  {
    label: "Rejalanmoqda",
    value: projectStatuses.PLANNING,
    icon: <PlanningIcon />,
    stroke: "#dc6803",
  },
  {
    label: "Jarayonda",
    value: projectStatuses.PROCESS,
    icon: <ProcessIcon />,
    stroke: "#1570ef",
  },
  {
    label: "Yopildi",
    value: projectStatuses.COMPLETED,
    icon: <CompletedIcon />,
    stroke: "#039855",
  },
  {
    label: "To’xtatilgan",
    value: projectStatuses.SUSPENDED,
    icon: <SuspendedIcon />,
    stroke: "#475467",
  },
];
