import React, { useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Form, FormInstance, Popover } from "antd";
import { queryKeys } from "features/app/utils/constants/queryKeys";
import { UnitModel } from "features/app/utils/constants/unitModel";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { LaboratoryDrawerFormModel } from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { RU } from "modules/common";

import styles from "./footer.module.scss";

const { useWatch } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
};

const Footer: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);
  const products = useWatch("products", form);
  const sortOrderExpense =
    products?.reduce((prev: { [key: string]: number }, current) => {
      const findUnit = unitData?.find(unit => unit?.id === current?.expense_unit_id);

      if (findUnit?.name[i18next.language]) {
        prev[findUnit?.name[i18next.language]] =
          (prev[findUnit?.name[i18next.language]] ?? 0) + parseLocaledString(String(current?.expense_quantity ?? 0));
      }
      return prev;
    }, {}) || {};
  const sortOrderIncome =
    products?.reduce((prev: { [key: string]: number }, current) => {
      const findUnit = unitData?.find(unit => unit?.id === current?.income_unit_id);

      if (findUnit?.name[i18next.language]) {
        prev[findUnit?.name[i18next.language]] =
          (prev[findUnit?.name[i18next.language]] ?? 0) + parseLocaledString(String(current?.income_quantity ?? 0));
      }
      return prev;
    }, {}) || {};

  const sortedExpenseKeys = Object.keys(sortOrderExpense);
  const sortedIncomeKeys = Object.keys(sortOrderIncome);

  const allUnitText = useMemo(
    () => (
      <div className={styles.units}>
        <div className="flex flex-wrap items-center gap-1">
          <span className="text-sm font-medium text-gray-600">{t("warehouse.Joriy birliklar")}:</span>
          {sortedExpenseKeys && sortedExpenseKeys?.length > 0 ? (
            sortedExpenseKeys?.map((key, index) => (
              <div className={styles.units__item} key={index}>
                <p>{sortOrderExpense[key]}</p>
                <span>{key}</span>
                {index + 1 !== sortedExpenseKeys?.length && <p>,</p>}
              </div>
            ))
          ) : (
            <span>{t("warehouse.Mavjud emas")}</span>
          )}
        </div>
        <div className="flex flex-wrap items-center gap-1">
          <span className="text-sm font-medium text-gray-600">{t("warehouse.O'tuvchi birliklar")}:</span>
          {sortedIncomeKeys && sortedIncomeKeys?.length > 0 ? (
            sortedIncomeKeys?.map((key: keyof typeof sortOrderIncome, index: number) => (
              <div className={styles.units__item} key={index}>
                <p>{sortOrderIncome[key]}</p>
                <span>{key}</span>
                {index + 1 !== sortedIncomeKeys?.length && <p>,</p>}
              </div>
            ))
          ) : (
            <span>{t("warehouse.Mavjud emas")}</span>
          )}
        </div>
      </div>
    ),
    [sortedExpenseKeys, sortedExpenseKeys]
  );

  const total_resources = useMemo(() => products?.length, [products]);

  return (
    <div className="flex flex-col gap-3 rounded-xl border border-solid border-gray-200 p-4">
      <div className="flex items-center justify-between">
        <span className="text-base font-medium text-gray-600">{t("warehouse.Jami resurslar")}:</span>
        <span className="text-base font-medium text-primary-500">
          <Popover placement="left" title={allUnitText}>
            {total_resources?.toLocaleString(RU) ?? 0} birlik
          </Popover>
        </span>
      </div>
    </div>
  );
};

export default Footer;
