import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { UseMutationResult } from "@tanstack/react-query";
import { Col, Form, FormInstance, FormListOperation, Row } from "antd";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { cx } from "features/app/utils/helpers/cx";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { ProductSelectModel } from "features/app/utils/models/productSelectModel";
import { ErrorRes, SuccessRes } from "features/app/utils/models/responseType";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import FilterSearchIcon from "../../../../../../projects/assets/icons/FilterSearchIcon";
import { PartyFormOfferModel, PartyFormProductsModel } from "../../../../../utils/models/partyModalFormModel";
import CreateProductModal from "../../../../create-product-modal/CreateProductModal";

import PartyAmount from "./items/PartyAmount";
import PartyCompanyPersonSelect from "./items/PartyCompanyPersonSelect";
import PartyCurrencySelect from "./items/PartyCurrencySelect";
import PartyProductSelect from "./items/PartyProductSelect";
import PartyProjectSelect from "./items/PartyProjectSelect";
import PartyQuantity from "./items/PartyQuantity";
import PartyTotalAmount from "./items/PartyTotalAmount";
import PartyUnitSelect from "./items/PartyUnitSelect";
import PartyWarehouseSelect from "./items/PartyWarehouseSelect";
import PartyModalTableHeader from "./PartyModalTableHeader";
import PartyModalTableOffers from "./PartyModalTableOffers";

import styles from "../partyModalLeft.module.scss";

type Props = {
  form: FormInstance;
  isWarehouse?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { Item, List, useWatch } = Form;

const PartyModalTable: React.FC<Props> = ({ form, isWarehouse, deleteProduct }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setProductDrawer } = supplyActions;

  const { isView } = useAppSelector(state => state.supplyReducer.partyModal);

  const status = useWatch("status", form);
  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];
  const offers = useWatch("offers", form) as PartyFormOfferModel[];
  const searchValue = useWatch("search", form) as string;

  const onAddProduct = (operation: FormListOperation) => () => {
    let lastProduct: Partial<PartyFormProductsModel> = {
      warehouse_id: undefined,
      project_id: undefined,
      company_person_id: undefined,
      currency_id: undefined
    };

    if (!isEmptyArr(products)) {
      lastProduct = products[products?.length - 1];
    }

    operation.add({
      id: undefined,
      total: undefined,
      amount: undefined,
      unit_id: undefined,
      quantity: undefined,
      product_id: undefined,
      row_id: uid(12),
      project_id: lastProduct?.project_id,
      currency_id: lastProduct?.currency_id,
      warehouse_id: lastProduct?.warehouse_id,
      company_person_id: lastProduct?.company_person_id
    });
  };

  const onOpenProductDrawer = () => {
    dispatch(
      setProductDrawer({
        visible: true
      })
    );
  };

  const isProduct = useCallback(
    (index: number) => {
      const findProduct = Boolean(products?.[index]?.product_id);

      return !findProduct;
    },
    [products]
  );

  const onAfterProductAdded = (
    productId: number,
    unitId: number,
    res: ProductSelectModel,
    fieldName: string | Array<string | number>
  ) => {
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];

    form.setFieldValue([...fieldName, "product_id"], productId);
    form.setFieldValue([...fieldName, "unit_id"], unitId);
    form.setFieldValue(["select_products"], [res, ...oldSelectProducts]);
  };

  const viewOffers = useMemo(() => isView && !isEmptyArr(offers), [isView, offers]);

  const viewReceived = status === StatusEnums.RECIEVED || status === StatusEnums.PARTIALLY_RECIEVED;

  return (
    <div className={`${styles.table} ${isView ? styles.table__view : ""}`}>
      <PartyModalTableHeader form={form} />
      <List name="warehouse_products">
        {(fields, operation) => (
          <>
            <div className={styles.table__content}>
              {fields?.map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Row gutter={0} key={index} className={styles.table__content__row}>
                  <Item name={[index, "id"]} className="hidden" />
                  <Item name={[index, "status"]} className="hidden" />
                  <Item name={[index, "row_id"]} className="hidden" />
                  <Item name={[index, "task_product_id"]} className="hidden" />
                  <Item name={[index, "warehouse_total_quantity"]} className="hidden" />
                  <Item name={[index, "market_price"]} className="hidden" />
                  <Item name={[index, "this_product"]} className="hidden" />
                  <Col
                    span={viewReceived ? 3 : 5}
                    className={`${styles.table__content__item} ${styles.table__content__resource}`}
                  >
                    <PartyProductSelect form={form} index={index} />
                    {isProduct(index) && (
                      <div className={styles.add_resource} onClick={onOpenProductDrawer}>
                        <FilterSearchIcon />
                      </div>
                    )}
                  </Col>
                  <Col span={2} className={styles.table__content__item}>
                    <PartyUnitSelect index={index} form={form} />
                  </Col>
                  <Col span={2} className={styles.table__content__item}>
                    <PartyQuantity index={index} form={form} />
                  </Col>
                  {viewReceived && (
                    <Col span={2} className={styles.table__content__item}>
                      {products[index]?.received_quantity}
                    </Col>
                  )}
                  <Col span={2} className={styles.table__content__item}>
                    <PartyAmount form={form} index={index} isWarehouse={isWarehouse} />
                  </Col>
                  <Col span={viewOffers ? 2 : 3} className={styles.table__content__item}>
                    {/* eslint-disable-next-line react/no-array-index-key */}
                    <PartyWarehouseSelect form={form} index={index} key={index} />
                  </Col>
                  <Col span={2} className={styles.table__content__item}>
                    {/* <ProjectSelect */}
                    {/*  form={form} */}
                    {/*  label={null} */}
                    {/*  suffixIcon={null} */}
                    {/*  name={[index, "project_id"]} */}
                    {/*  disabled={isView && disabled()} */}
                    {/* /> */}
                    <PartyProjectSelect
                      form={form}
                      renderName={[index, "project_id"]}
                      name={["warehouse_products", index, "project_id"]}
                    />
                  </Col>
                  <Col span={viewOffers ? 2 : 3} className={styles.table__content__item}>
                    <PartyCompanyPersonSelect form={form} index={index} />
                  </Col>
                  <Col span={2} className={styles.table__content__item}>
                    <PartyCurrencySelect form={form} isWarehouse={isWarehouse} index={index} />
                  </Col>
                  {/* {isImpostPage && ( */}
                  {/*  <Col span={2}> */}
                  {/*    <ResourceAvailability name={["warehouse_products", index]} form={form} /> */}
                  {/*  </Col> */}
                  {/* )} */}
                  <Col span={3} className={styles.table__content__item}>
                    <PartyTotalAmount
                      form={form}
                      index={index}
                      isWarehouse={isWarehouse}
                      deleteProduct={deleteProduct}
                      rowId={products?.[index]?.row_id}
                    />
                  </Col>
                  {viewOffers && (
                    <Col span={2} className={cx(styles.table__content__item, "justify-end")}>
                      <PartyModalTableOffers
                        form={form}
                        index={index}
                        data={offers}
                        deleteProduct={deleteProduct}
                        rowId={products?.[index]?.row_id}
                      />
                    </Col>
                  )}
                </Row>
              ))}
            </div>
            {!isView && !searchValue && isEmptyArr(cacheProducts) && (
              <div className={styles.table__footer}>
                <div className={styles.add_resource} onClick={onAddProduct(operation)}>
                  <PlusCircleIcon fill />
                  {t("payment.Resurs qo'shish")}
                </div>
              </div>
            )}
          </>
        )}
      </List>
      <CreateProductModal afterFunc={onAfterProductAdded} />
    </div>
  );
};

export default PartyModalTable;
