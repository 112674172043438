type RouteSubmodules = [
  {
    key: "Omborxona";
    actions: [
      { view: true; key: "Omborxonadagi mahsulotlar" },
      { invalid: true; key: "Mahsulotni yaroqsizga chiqarish" },
      { transfer: true; key: "Mahsulotni o'tkazma qilish" },
      { upload: true; key: "Mahsulotni yuklab olish" }
    ];
  },
  {
    key: "Partiyalar";
    actions: [
      { view: true; key: "Partiya mahsulotlari ro'yxati" },
      { create: true; key: "Partiya yaratish" },
      { upload: true; key: "Partiyalarni yuklab olish" },
      { chatView: true; key: "Partiyalarda chat" },
      { delete: true; key: "Partiyani o'chirish" },
      { update: true; key: "Partiyani tahrirlash" },
      {
        acceptanceView: true;
        key: "Partiya qabul tarixini ko'rish";
      },
      {
        deleteAcceptanceView: true;
        key: "Partiya qabul tarixini o'chirish";
      }
    ];
  },
  {
    key: "O'tkazmalar";
    actions: [
      { view: true; key: "O'tkazmalar ro'yxati" },
      { check: true; key: "O'tkazmani qabul qilish | Rad etish" },
      { edit: true; key: "O'tkazmani tahrirlash" },
      { chatView: true; key: "O'tkazmalarda chat" },
      { makeTransfer: true; key: "O'tkazma qilish" },
      {
        delete: true;
        key: "O'tkazmani o'chirish";
      }
    ];
  },
  {
    key: "Buyurtmalar";
    actions: [
      { view: true; key: "Buyurtmalar ro'yxati" },
      { delete: true; key: "Buyurtmani o'chirish" },
      { create: true; key: "Buyurtma berish" },
      { update: true; key: "Buyurtmani tahrirlash" },
      { upload: true; key: "Buyurtmalarni yuklab olish" }
    ];
  },
  {
    key: "Yaroqsiz mahsulotlar";
    actions: [
      { view: true; key: "Yaroqsiz mahsulotlar ro'yxati" },
      { rejected: true; key: "Yaroqsiz mahsulotni ortga qaytarish" },
      { edit: true; key: "Yaroqsiz mahsulotni tahrirlash" },
      { chat: true; key: "Yaroqsiz mahsulotlarda chat" },
      { makeExpired: true; key: "Mahsulotni yaroqsizga chiqarish" },
      { upload: true; key: "Yaroqsiz mahsulot ma'lumotlarini yuklash" }
    ];
  },
  {
    key: "Laboratoriya";
    actions: [
      {
        key: "Laboratoriya yaratish";
        create: true;
      },
      {
        key: "Laboratoriyani o'chirish";
        delete: true;
      }
    ];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "Omborxona",
    actions: [
      { view: true, key: "Omborxonadagi mahsulotlar" },
      { invalid: true, key: "Mahsulotni yaroqsizga chiqarish" },
      { transfer: true, key: "Mahsulotni o'tkazma qilish" },
      { upload: true, key: "Mahsulotni yuklab olish" }
    ]
  },
  {
    key: "Partiyalar",
    actions: [
      { view: true, key: "Partiya mahsulotlari ro'yxati" },
      { create: true, key: "Partiya yaratish" },
      { upload: true, key: "Partiyalarni yuklab olish" },
      { chatView: true, key: "Partiyalarda chat" },
      { delete: true, key: "Partiyani o'chirish" },
      { update: true, key: "Partiyani tahrirlash" },
      {
        acceptanceView: true,
        key: "Partiya qabul tarixini ko'rish"
      },
      {
        deleteAcceptanceView: true,
        key: "Partiya qabul tarixini o'chirish"
      }
    ]
  },
  {
    key: "O'tkazmalar",
    actions: [
      { view: true, key: "O'tkazmalar ro'yxati" },
      { check: true, key: "O'tkazmani qabul qilish | Rad etish" },
      { edit: true, key: "O'tkazmani tahrirlash" },
      { chatView: true, key: "O'tkazmalarda chat" },
      { makeTransfer: true, key: "O'tkazma qilish" },
      {
        delete: true,
        key: "O'tkazmani o'chirish"
      }
    ]
  },
  {
    key: "Buyurtmalar",
    actions: [
      { view: true, key: "Buyurtmalar ro'yxati" },
      { delete: true, key: "Buyurtmani o'chirish" },
      { create: true, key: "Buyurtma berish" },
      { update: true, key: "Buyurtmani tahrirlash" },
      { upload: true, key: "Buyurtmalarni yuklab olish" }
    ]
  },
  {
    key: "Yaroqsiz mahsulotlar",
    actions: [
      { view: true, key: "Yaroqsiz mahsulotlar ro'yxati" },
      { rejected: true, key: "Yaroqsiz mahsulotni ortga qaytarish" },
      { edit: true, key: "Yaroqsiz mahsulotni tahrirlash" },
      { chat: true, key: "Yaroqsiz mahsulotlarda chat" },
      { makeExpired: true, key: "Mahsulotni yaroqsizga chiqarish" },
      { upload: true, key: "Yaroqsiz mahsulot ma'lumotlarini yuklash" }
    ]
  },
  {
    key: "Laboratoriya",
    actions: [
      {
        key: "Laboratoriya yaratish",
        create: true
      },
      {
        key: "Laboratoriyani o'chirish",
        delete: true
      }
    ]
  }
];
