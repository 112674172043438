import React from "react";

const HistogramIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.002 4.80005L3.75195 4.80005" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M16.2031 14.6985L3.75312 14.6985" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M20.1016 9.74927L3.75156 9.74927" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M9.45312 19.6516L3.75313 19.6516" stroke="#1E90FF" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export default HistogramIcon;
