import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

export const paymentWorksTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "task.name",
      name: "task.name",
      title: "Ish nomi",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
        id: "completed_date",
        name: "completed_date",
        title: "Ish tugallandi",
        checked: true
    },
    {
        id: "company_person.name",
        name: "company_person.name",
        title: "Ishni bajardi",
        checked: true
    },
    {
        id: "total_amount",
        name: "total_amount",
        title: "Summasi",
        checked: true
    },{
        id: "creator.full_name",
        name: "creator.full_name",
        title: "Xodim",
        checked: true
    }
  ],
  size: 10
};
