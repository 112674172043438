import { Tabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../hooks/useQueryParams";
import { useRoutePermissions } from "../../hooks/useRoutePermissions";
import { queryParamsKeys } from "../app/utils/constants/queryParamsKeys";

import InvalidModal from "./components/defect-modal/DefectModal";
import Invalids from "./components/defects/Defects";
import Inventories from "./components/inventories/Inventories";
import Requests from "./components/requests/Requests";
import { useGetRequestsCount } from "./service/queries";
import { routeSubmodules } from "./utils/constants/routeSubmodules";
import { tabKeys } from "./utils/constants/tabKeys";

import styles from "./index.module.scss";

const Index = () => {
  const { t } = useTranslation();
  const { queries, append } = useQueryParams();
  const { permissions } = useRoutePermissions("Inventar", routeSubmodules);
  const { data: requests } = useGetRequestsCount();

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const allItems = [
    {
      key: tabKeys.INVENTORIES,
      label: `${t("Inventar.Inventarlar")}`,
      children: <Inventories />,
      permissionKey: "Inventarlar"
    },
    {
      key: tabKeys.REQUESTS,
      label: (
        <div className={styles.requests}>
          {t("Inventar.So'rovlar")}{" "}
          {requests && requests?.total > 0 && <span className={styles.requests__count}>{requests?.total}</span>}
        </div>
      ),
      children: <Requests />,
      permissionKey: "So'rovlar"
    },
    {
      key: tabKeys.INVALIDS,
      label: `${t("Inventar.Yaroqsizlar")}`,
      children: <Invalids />,
      permissionKey: "Yaroqsiz inventarlar"
    }
  ];

  const items = (): TabsProps["items"] => {
    const newItems: typeof allItems = [];

    permissions?.submodules?.forEach(item => {
      const findItem = allItems?.find(tabItem => tabItem.permissionKey === item.name.uz);

      if (findItem && findItem?.permissionKey) {
        newItems.push(findItem);
      }
    });

    return newItems;
  };

  return (
    <div className={styles.inventory}>
      <div className={styles.tabs}>
        <Tabs activeKey={queries()?.[queryParamsKeys.TAB]} items={items()} onChange={onChangeTabs} />
      </div>
      <InvalidModal />
    </div>
  );
};

export default Index;
