import { useDispatch } from "react-redux";
import { Button, Divider, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { paymentReducerActions } from "../../../../store/reducers/paymentReducer";
import { useDeletePayment } from "../../../payment/service/mutation";
import { routeSubmodules } from "../../../payment/utils/constants/routeSubmodules";
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { usePaymentExchangeHistory } from "../../service/queries";
import { RU } from "../../utils/constants/languages";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import ConditionalRender from "../conditional-render/ConditionalRender";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import ModalConfirm from "../modal-confirm/ModalConfirm";

import styles from "./conversionInfoModal.module.scss";

const ConversionInfoModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setConversionInfoModal } = paymentReducerActions;
  const record = useAppSelector(state => state.paymentReducer.conversionInfoModal);
  const { data, isLoading } = usePaymentExchangeHistory(record?.id, record?.isDeleted);
  const deleteExchange = useDeletePayment();

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");

  const onCancel = () => {
    dispatch(setConversionInfoModal(undefined));
  };

  const footer = (
    <ConditionalRender if={paymentActions?.delete && !record?.isDeleted}>
      <div className="flex items-center gap-3">
        <ModalConfirm
          isDescription
          onAfterClose={onCancel}
          mutation={deleteExchange}
          payload={{ id: data?.id }}
          title={`${t("payment.Konvertatsiya")} - ${record?.id}`}
        >
          <Button>
            <DeleteIcon />
            {t("payment.O'chirish")}
          </Button>
        </ModalConfirm>
        <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
      </div>
    </ConditionalRender>
  );

  return (
    <Modal
      centered
      width={679}
      zIndex={19999}
      open={!!record}
      footer={footer}
      onCancel={onCancel}
      className={styles.exchange}
      title={`${t("payment.Konvertatsiya")} - ${record?.id} `}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className="flex flex-col gap-2">
          <div className="box-border flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4">
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Kassadan")}</p>
              <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                <span>{data?.expense_payment?.cash?.name}</span>
              </p>
            </div>
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Valyuta")}</p>
              <div className="m-0 flex gap-1 rounded-xl bg-gray-100 px-2 py-0.5 text-sm font-medium text-gray-700">
                <span>{data?.expense_payment?.currency?.symbol}</span>
                <span>
                  <ArrowRightIcon />
                </span>
                <span>{data?.income_payment?.currency?.symbol}</span>
              </div>
            </div>
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Valyuta kursi")}</p>
              <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                <span>{data?.currency_amount?.toLocaleString(RU)}</span>
                <span>{data?.income_payment?.currency?.symbol}</span>
              </p>
            </div>
          </div>
          <p className="text-sm font-medium text-gray-400">{t("payment.Chiqim konvertatsiya ma’lumotlari")}</p>
          {data?.expense_payment?.payment_types?.map(paymentType => (
            <div
              key={paymentType?.id}
              className="box-border flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4"
            >
              <div className="flex w-full items-center justify-between">
                <p className="m-0 text-sm font-normal text-gray-600">{t("payment.To'lov turi")}</p>
                <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">{paymentType?.name}</p>
              </div>
              <div className="flex w-full items-center justify-between">
                <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
                <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                  <span>{paymentType?.amount?.toLocaleString(RU)}</span>
                  <span>{data?.expense_payment?.currency?.symbol}</span>
                </p>
              </div>
              <ConditionalRender if={!isEmptyArr(paymentType?.projects)}>
                <div className="flex flex-col gap-3">
                  <Divider>{t("payment.Birikkan loyihalar")}</Divider>
                  {paymentType?.projects?.map(project => (
                    <div key={project?.id} className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 p-3">
                      <div className="flex w-full items-center justify-between">
                        <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Loyiha")}</p>
                        <p className="m-0 text-sm font-medium text-gray-700">{project?.name}</p>
                      </div>
                      <div className="flex w-full items-center justify-between">
                        <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
                        <p className="m-0 flex items-center gap-1 text-sm font-medium text-gray-700">
                          <span>{project?.amount?.toLocaleString(RU)}</span>
                          <span>{data?.expense_payment?.currency?.symbol}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </ConditionalRender>
              <ConditionalRender if={!isEmptyArr(paymentType?.financials)}>
                <div className="flex flex-col gap-3">
                  <Divider>{t("payment.Birikkan xarajatlar")}</Divider>
                  {paymentType?.financials?.map(finance => (
                    <div key={finance?.id} className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 p-3">
                      <div className="flex w-full items-center justify-between">
                        <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Xarajat turi")}</p>
                        <p className="m-0 text-sm font-medium text-gray-700">{finance?.name}</p>
                      </div>
                      <div className="flex w-full items-center justify-between">
                        <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
                        <p className="m-0 flex items-center gap-1 text-sm font-medium text-gray-700">
                          <span>{finance?.amount?.toLocaleString(RU)}</span>
                          <span>{data?.expense_payment?.currency?.symbol}</span>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </ConditionalRender>
            </div>
          ))}
          <p className="text-sm font-medium text-gray-400">{t("payment.Kirim konvertatsiya ma’lumotlari")}</p>
          <div className="box-border flex flex-col gap-3 rounded-xl border border-solid border-gray-200 bg-white p-4">
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Kassaga")}</p>
              <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                <span>{data?.income_payment?.cash?.name}</span>
              </p>
            </div>
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.To'lov turi")}</p>
              <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                <span>{data?.income_payment?.payment_type?.name}</span>
              </p>
            </div>
            <div className="flex w-full items-center justify-between">
              <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
              <p className="m-0 flex gap-1 text-sm font-medium text-gray-700">
                <span>{data?.income_payment?.payment_type?.amount?.toLocaleString(RU)}</span>
                <span>{data?.income_payment?.currency?.symbol}</span>
              </p>
            </div>
            <ConditionalRender if={!isEmptyArr(data?.income_payment?.payment_type?.projects)}>
              <div className="flex flex-col gap-3">
                <Divider>{t("payment.Birikkan loyihalar")}</Divider>
                {data?.income_payment?.payment_type?.projects?.map(project => (
                  <div key={project?.id} className="box-border flex flex-col gap-3 rounded-xl bg-gray-100 p-3">
                    <div className="flex w-full items-center justify-between">
                      <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Loyiha")}</p>
                      <p className="m-0 text-sm font-medium text-gray-700">{project?.name}</p>
                    </div>
                    <div className="flex w-full items-center justify-between">
                      <p className="m-0 text-sm font-normal text-gray-600">{t("payment.Summa")}</p>
                      <p className="m-0 flex items-center gap-1 text-sm font-medium text-gray-700">
                        <span>{project?.amount?.toLocaleString(RU)}</span>
                        <span>{data?.expense_payment?.currency?.symbol}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </ConditionalRender>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ConversionInfoModal;
