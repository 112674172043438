import React from "react";
import { Col, Row } from "antd";
import classNames from "classnames";

import {
  PartyPrintDataModel,
  PartyPrintGenerateDataModel,
  PartyPrintModel,
} from "../../../../utils/models/PartyPrintDataModel";
import { RU } from "../../../../../app/utils/constants/languages";
import { numberRound } from "../../../../utils/helpers/numberRound";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";

import styles from "./partyPrint.module.scss";

type Props = {
  data: PartyPrintGenerateDataModel;
  totalPrice: number | undefined;
  projects?: Record<string, string | number>[];
  warehouses?: Record<string, string | number>[];
};

const PartyPrintTable: React.FC<Props> = ({
  data: propsData,
  totalPrice,
  projects,
  warehouses,
}) => {
  const { data, sameWarehouse, sameProject } = propsData;

  const generateTotal = (item: PartyPrintModel) => {
    return item?.data?.reduce(
      (first, second) => first + (parseLocaledString(String(second?.total)) ?? 0),
      0
    );
  };

  const findProject = (id?: number) =>
    projects?.find((item) => item.id === id)?.name;

  const findWarehouse = (id?: number) =>
    warehouses?.find((item) => item.id === id)?.name;

  const generateSpan = (defaultSpan: number, isMinus?: boolean) => {
    if (sameWarehouse && sameProject) {
      return defaultSpan + (isMinus ? -6 : 6);
    }
    if (sameWarehouse || sameProject) {
      return defaultSpan + (isMinus ? -3 : 3);
    }

    return defaultSpan;
  };

  function getOrdinal() {
    let count = 0;

    return function () {
      count++;
      return count;
    };
  }

  const getNextOrdinal = getOrdinal();

  const generateCol = (item: PartyPrintDataModel[]) => {
    return item?.map((product) => (
      <Row className={styles.table__row}>
        <Col span={1} className={styles.table__row__col}>
          {getNextOrdinal()}
        </Col>
        <Col span={generateSpan(6)} className={styles.table__row__col}>
          {product?.productName}
        </Col>
        <Col span={3} className={styles.table__row__col}>
          {product?.unitName}
        </Col>
        <Col span={2} className={styles.table__row__col}>
          {product?.quantity}
        </Col>
        <ConditionalRender if={!sameProject}>
          <Col span={3} className={styles.table__row__col}>
            {findProject(product?.projectId)}
          </Col>
        </ConditionalRender>
        <ConditionalRender if={!sameWarehouse}>
          <Col span={3} className={styles.table__row__col}>
            {findWarehouse(product?.warehouseId)}
          </Col>
        </ConditionalRender>
        <Col span={3} className={styles.table__row__col}>
          {product?.amount}
        </Col>
        <Col span={3} className={styles.table__row__col}>
          {numberRound(
            parseLocaledString(String(product?.total ?? "0")),
            100
          )?.toLocaleString(RU)}
        </Col>
      </Row>
    ));
  };

  return (
    <div className={styles.table}>
      <Row className={styles.table__header}>
        <Col span={1} className={styles.table__header__col}>
          №
        </Col>
        <Col span={generateSpan(6)} className={styles.table__header__col}>
          Mahsulot nomi
        </Col>
        <Col span={3} className={styles.table__header__col}>
          Birligi
        </Col>
        <Col span={2} className={styles.table__header__col}>
          Soni
        </Col>
        <ConditionalRender if={!sameProject}>
          <Col span={3} className={styles.table__header__col}>
            Loyiha
          </Col>
        </ConditionalRender>
        <ConditionalRender if={!sameWarehouse}>
          <Col span={3} className={styles.table__header__col}>
            Omborxona
          </Col>
        </ConditionalRender>
        <div className={styles.amount}>
          <div className={styles.amount__top}>Narxi</div>
          <div className={styles.amount__bottom}>
            <span>Birlik</span>
            <span>Umumiy</span>
          </div>
        </div>
      </Row>
      {data?.map((item) =>
        !item?.id ? (
          generateCol(item?.data)
        ) : (
          <div className={styles.person}>
            <Row>
              <Col span={generateSpan(7)} className={styles.person__col}>
                {item?.name}
              </Col>
              <Col
                span={generateSpan(17, true)}
                className={classNames(styles.person__col, styles.person__total)}
              >
                Jami: {generateTotal(item)?.toLocaleString(RU)}
              </Col>
            </Row>
            {generateCol(item?.data)}
          </div>
        )
      )}
      <div className={styles.footer}>
        <Row>
          <Col span={generateSpan(7)} className={styles.footer__col}>
            Jami:
          </Col>
          <Col
            span={generateSpan(17, true)}
            className={classNames(styles.footer__col, styles.footer__total)}
          >
            {totalPrice?.toLocaleString(RU)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PartyPrintTable;
