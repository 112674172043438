import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import ProductListImportModal from "features/counterparts/components/counterparts/product-list-import-modal/ProductListImportModal";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";
import CategoriesProductListModal from "features/settings/components/sub-categories/modal/CategoriesProductListModal";
import OfferHistory from "features/supply/components/offers/offer-history/OfferHistory";
import OfferModal from "features/supply/components/offers/offer-modal/OfferModal";
import { useTranslation } from "react-i18next";

import { settingsActions } from "store/reducers/settingsReducer";

import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import Pagination from "../../../app/components/pagination/Pagination";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import SettingsProductModal from "../../components/sub-categories/modal/SettingsProductModal";
import SettingsProductTable from "../../components/sub-categories/table/SettingsProductTable";
import { useGetSubCategoryProducts } from "../../service/queries";
import { ProductModel } from "../../utils/models/product/productModel";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams();
  const { id: second_id } = parseParamsId(params?.second_id);
  const { id, name } = parseParamsId(params?.id);
  const [form] = useForm();
  const navigate = useNavigate();
  const { data: products, isLoading: isLoadingProducts } = useGetSubCategoryProducts(+second_id!, undefined);
  const [importVisible, setImportVisible] = useState(false);
  const { setCategoriesProductsModal } = settingsActions;
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState<{
    visible: boolean;
    data?: ProductModel;
  }>({
    visible: false,
    data: undefined
  });

  const goBack = () => {
    navigate(-1);
  };

  const onOpenProductModal = () => {
    setModalData({
      visible: true,
      data: undefined
    });
  };

  const onOpenImportModal = () => {
    setImportVisible(true);
  };

  const onOpenProductListModal = () => {
    dispatch(
      setCategoriesProductsModal({
        visible: true,
        folderId: Number(id),
        folderName: name,
        subCategoryId: Number(second_id)
      })
    );
  };

  return (
    <div className={styles.detailed_sub_category}>
      <div className={styles.detailed_sub_category__top}>
        <div className={styles.left}>
          <Button onClick={goBack}>
            <ArrowNarrowIcon />
          </Button>
          <h2>{t("products.Mahsulotlar")}</h2>
        </div>
        <div className={styles.right}>
          <Button onClick={onOpenImportModal}>
            <DownloadIcon /> {t("products.Import")}
          </Button>
          <Button onClick={onOpenProductModal}>
            <PlusIcon /> {t("products.Mahsulot")}
          </Button>
        </div>
      </div>
      <div className={styles.detailed_sub_category__bottom}>
        <div className={styles.header}>
          <h4>{t("products.Mahsulotlar")}</h4>
          <PaymentSearch />
        </div>
        <div className={styles.products}>
          <SettingsProductTable isLoading={isLoadingProducts} data={products?.data} setModalData={setModalData} />
          <Pagination
            paginationProps={{
              total: products?.total,
              current: products?.current_page
            }}
          />
        </div>
        <SettingsProductModal modalData={modalData} setModalData={setModalData} />
      </div>
      <OfferModal />
      <OfferHistory />
      <CategoriesProductListModal form={form} isNotSubCategory />
      <ProductListImportModal
        defaultLink="Mahsulot%20import%20shablon.xlsx"
        title="Katalog mahsulotlari"
        form={form}
        setVisibleModal={setImportVisible}
        visible={importVisible}
        onAfterFunc={onOpenProductListModal}
        isCatalog
      />
    </div>
  );
};

export default Index;
