import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { OfferPartyLeftModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  uniqueId: string;
};

const OfferPartyProductTotal: React.FC<Props> = ({ uniqueId: unique_id }) => {
  const dispatch = useDispatch();
  const { setOfferPartyLefItemData } = supplyOfferActions;
  const { leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const leftDataItemValue = (uniqueId: string, key: keyof OfferPartyLeftModel) =>
    leftData?.find(item => item.unique_id === uniqueId)?.[key];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, uniqueId: string) => {
    const { value } = e.currentTarget;
    const parseValue = parseLocaledString(localeFormatter(String(value)));
    const leftDataItem = leftData?.find(item => item.unique_id === uniqueId);

    dispatch(
      setOfferPartyLefItemData({
        key: "total",
        value: localeFormatter(String(value)),
        uniqueId
      })
    );

    dispatch(
      setOfferPartyLefItemData({
        key: "amount",
        value: localeFormatter(String(parseValue / parseLocaledString(String(leftDataItem?.quantity)))),
        uniqueId
      })
    );
  };

  return (
    <div className={styles.item}>
      <Input
        placeholder="Narxi"
        autoComplete="off"
        value={leftDataItemValue(unique_id, "total") as string}
        onChange={e => onChangeAmount(e, unique_id)}
      />
    </div>
  );
};

export default OfferPartyProductTotal;
