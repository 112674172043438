import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { IBuilders } from "../../features/app/utils/models/buildersSelectModel";

export interface IBuilderModel {
  builderModal: {
    visible: boolean;
    data?: IBuilders;
    name?: string;
  };
}

const initialState: IBuilderModel = {
  builderModal: {
    visible: false,
    data: undefined,
  },
};

const builderSlice = createSlice({
  name: sliceNames.BUILDER,
  initialState,
  reducers: {
    setBuilderModal: (state, action: PayloadAction<IBuilderModel & { name?: string }>) => {
      state.builderModal.visible = action.payload.builderModal.visible;
      state.builderModal.data = action.payload.builderModal.data;
      state.builderModal.name = action.payload.name;
    },
  },
});

export default builderSlice.reducer;
export const builderActions = builderSlice.actions;
