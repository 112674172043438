import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { rootPaths } from "../../routes/root/rootPaths";
import { allRootRoutes } from "../../routes/root/rootRoutes";
import { LoadingIndicator } from "../app/components/loading-indicator/LoadingIndicator";
import { UZ } from "../app/utils/constants/languages";

import NotFoundIcon from "./assets/icons/NotFoundIcon";

import styles from "./index.module.scss";

const Index: React.FC<{ errorBoundary?: boolean }> = ({ errorBoundary }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { permissions } = useAuth();
  const [loading, setLoading] = React.useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  const toProject = () => {
    const firstRoute = allRootRoutes?.find(item => item?.permissionName === permissions?.[0]?.name[UZ]);

    navigate(firstRoute?.path ?? rootPaths.PROJECTS);

    window.location.reload();
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin spinning={loading} indicator={LoadingIndicator} size="large" />
    </div>
  ) : (
    <div className={styles.not_found}>
      <h6>{t("NotFound.Obbooooo ! ")}</h6>
      <h1>
        {errorBoundary ? `${t("NotFound.Tizimda xatolik yuz berdi")}` : `${t("NotFound.Ushbu sahifa topilmadi!")}`}
      </h1>
      <span>
        <NotFoundIcon />
      </span>
      <p>
        {errorBoundary
          ? `${t("NotFound.Kechirasiz_tizimda_xatolik_mavjud")}`
          : `${t("NotFound.Kechirasiz, siz qidirayotgan sahifa mavjud emas yoki ko‘chirilgan.")}`}
      </p>
      <Button type="primary" onClick={toProject}>
        {t("NotFound.Bosh sahifaga qaytish")}
      </Button>
    </div>
  );
};

export default Index;
