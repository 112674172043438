import { FC } from "react";
import { Progress } from "antd";

import styles from "./projectProgress.module.scss";

type Props = {
  percent?: number;
};

export const ProjectProgress: FC<Props> = ({ percent }) => (
  // const differAmount = prediction - total;
  // const spendPercent = (100 * spend) / prediction;
  // const damagePercent = (100 * differAmount) / prediction;
  // const benefit = differAmount < 0;

  // <div
  //   className={`${styles.progress_cont} ${
  //     labelPlacement ? styles[labelPlacement] : ""
  //   }`}
  // >
  //   <div className={styles.labels}>
  //     <AmountFixer className={styles.spend}>{spend}</AmountFixer>
  //     {total ? <AmountFixer className={styles.total}>{total}</AmountFixer> : null}
  //     {differAmount ? (
  //       <AmountFixer className={`${benefit ? styles.benefit : styles.damage}`}>
  //         {differAmount}
  //       </AmountFixer>
  //     ) : null}
  //   </div>
  //   <div className={styles.progress}>
  //     <div
  //       className={styles.spend}
  //       style={{ flex: spendPercent / 100 || 0 }}
  //     ></div>
  //     <div
  //       className={styles.damage}
  //       style={{ flex: damagePercent / 100 || 0 }}
  //     ></div>
  //     {benefit && (
  //       <div
  //         className={styles.benefit}
  //         style={{ flex: Math.abs(damagePercent / 100) || 0 }}
  //       ></div>
  //     )}
  //   </div>
  // </div>
  <div className={styles.progress_cont}>
    <p>{percent ?? 0} %</p>
    <Progress percent={percent ?? 0} showInfo={false} />
  </div>
);
