import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

const { Item } = Form;
const { Option } = Select;

type Props = {
  customField: CustomFieldSelectModel;
  index: number;
  disabled?: boolean;
};

const CustomFieldSelect: React.FC<Props> = ({ customField, index, disabled = false }) => {
  const { i18n, t } = useTranslation();

  return (
    <React.Fragment>
      <Item className="d_n" name={["custom_field_values", index, "custom_field_id"]} initialValue={customField?.id} />
      <Item className="d_n" name={["custom_field_values", index, "type"]} initialValue={customField?.type} />
      <Item label={customField?.name[i18n.language]} name={["custom_field_values", index, "value"]}>
        <Select suffixIcon={<SelectSuffixIcon />} placeholder={t("customFields.Tanlang")} disabled={disabled}>
          {customField?.custom_field_options?.map(item => (
            <Option value={item?.id} key={item?.id}>
              {item?.name[i18n.language]}
            </Option>
          ))}
        </Select>
      </Item>
    </React.Fragment>
  );
};

export default CustomFieldSelect;
