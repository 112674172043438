import React, { ChangeEvent, FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { Button, Form, Input, Select, Spin } from "antd";
import classNames from "classnames";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { useGetCustomFieldSelect, useGetTableConfigs, useGetUnitSelect } from "features/app/service/queries";
import { formRules } from "features/app/utils/constants/formRules";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { areObjectsEqual, isEmptyObj } from "features/app/utils/helpers/areObjectsEqual";
import { localeFormatter } from "features/app/utils/helpers/localeFormatter";
import { numberToFixed } from "features/app/utils/helpers/numberToFixed";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { CustomFieldSelectModel } from "features/app/utils/models/customFieldSelectModel";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import { useTranslation } from "react-i18next";

import { useCreateTask, useDeleteTask, useUpdateTask } from "../../../../../service/mutations";
import { estimateTableDefaultData } from "../../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../../utils/helpers/estimateColumnsWidth";
import { TaskFormFieldsModel } from "../../../../../utils/models/taskFormFieldsModel";
import { TaskModel } from "../../../../../utils/models/taskModel";
import EstimateCustomField from "../../../estimate-custom-field/EstimateCustomField";

import CreateTaskName from "./CreateTaskName";
import TaskName from "./TaskName";

import styles from "../task.module.scss";

const { Item } = Form;
const { Option } = Select;

type Props = {
  data?: TaskModel;
  create?: {
    sectionId: number;
    afterSuccess: () => void;
  };
  sectionPlan?: boolean;
  sectionPlace?: number;
  setIsActive?: (isActive: boolean) => void;
  isActive?: boolean;
};

export const TaskHeader: FC<Props> = ({ data, create, sectionPlan, sectionPlace, setIsActive, isActive }) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const ref = useRef<HTMLDivElement | null>(null);
  const { i18n, t } = useTranslation();
  const [formInstance] = Form.useForm<TaskFormFieldsModel>();
  const [active, setActive] = useState(false);
  const createTask = useCreateTask();
  const updateTask = useUpdateTask();
  const deleteTask = useDeleteTask();
  const { data: units } = useGetUnitSelect();
  const isTaskProductsCount = data && data?.task_products_count > 0;
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const onTask = () => {
    setIsActive && setIsActive(!isActive);
  };

  const onChangeQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    const quantity = parseLocaledString(event.currentTarget?.value);
    const amount = Number(parseLocaledString(formInstance.getFieldValue("amount")));
    const totalAmount = parseLocaledString(String(+quantity * amount));

    if (isTaskProductsCount) {
      const formTotalAmount = parseLocaledString(formInstance.getFieldValue("total_amount"));

      formInstance.setFieldValue("amount", localeFormatter(String(numberToFixed(formTotalAmount / (quantity || 1)))));
    } else {
      formInstance.setFieldValue("total_amount", localeFormatter(String(totalAmount)));
    }
  };

  const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const amount = parseLocaledString(e.currentTarget.value);
    const quantity = Number(formInstance.getFieldValue("quantity"));

    formInstance.setFieldValue("amount", localeFormatter(e.currentTarget.value));
    formInstance.setFieldValue("total_amount", localeFormatter(String(amount * quantity)));
  };

  const onAfterCreateTask = () => {
    create?.afterSuccess();
    formInstance.resetFields();
    setActive(false);
  };

  const onFinish = (fields: TaskFormFieldsModel) => {
    const oldData = {
      custom_field_values: data?.custom_field_values.map(item => ({
        custom_field_id: item?.custom_field?.id,
        value: item?.value
      })),
      name: data?.name,
      unit_id: data?.unit?.id,
      quantity: data?.quantity,
      amount: localeFormatter(String(data?.amount)),
      total_amount: localeFormatter(String(data?.total_amount))
    };

    const isEqual = areObjectsEqual<TaskFormFieldsModel, unknown>(
      {
        ...fields,
        custom_field_values: fields?.custom_field_values?.filter(item => !isEmptyObj(item))
      },
      oldData
    );

    if (isEqual) {
      if (create) {
        createTask
          .mutateAsync({
            ...fields,
            is_title: false,
            section_id: create.sectionId,
            amount: parseLocaledString(fields?.total_amount ?? "0") / Number(fields?.quantity),
            total_amount: parseLocaledString(fields?.total_amount ?? "0"),
            custom_field_values: fields?.custom_field_values?.filter(item => item?.value)
          })
          .then(onAfterCreateTask);
      } else {
        updateTask
          .mutateAsync({
            ...fields,
            id: data?.id,
            is_title: false,
            amount: parseLocaledString(fields?.total_amount ?? "0") / Number(fields?.quantity),
            total_amount: parseLocaledString(fields?.total_amount ?? "0"),
            custom_field_values: fields?.custom_field_values?.filter(item => item?.value)
          })
          .finally(() => {
            setActive(false);
          });
      }
    }
  };

  const onBlurForm = () => {
    let oldData;
    const newData = formInstance.getFieldsValue();

    // @ts-ignore
    delete newData.name;

    const checkNewData = {
      ...newData,
      amount: parseLocaledString(newData?.amount ?? ""),
      total_amount: parseLocaledString(newData?.total_amount ?? ""),
      custom_field_values: newData?.custom_field_values?.filter(item => !isEmptyObj(item))
    };

    if (data) {
      oldData = {
        quantity: data?.quantity,
        amount: data?.amount,
        total_amount: data?.total_amount,
        unit_id: data?.unit?.id,
        custom_field_values: data?.custom_field_values?.map(item => ({
          custom_field_id: item?.custom_field?.id,
          value: item?.value
        }))
      };
    }

    if (!oldData) {
      formInstance.submit();
    } else {
      areObjectsEqual(oldData, checkNewData) && formInstance.submit();
    }

    setActive(false);
  };

  const onFocusForm = () => {
    setActive(false);
  };

  const onDelete = () => deleteTask.mutateAsync(data!.id);

  const onChangeTotalAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const totalAmount = parseLocaledString(e.currentTarget.value);
    const quantity = Number(formInstance.getFieldValue("quantity"));

    formInstance.setFieldValue("total_amount", localeFormatter(e.currentTarget.value));
    formInstance.setFieldValue("amount", localeFormatter(String(totalAmount / quantity)));
  };

  const defaultColumnsRender = () => {
    const defaultColumns: ReactNode[] = [];

    tableConfigData?.column?.forEach(column => {
      if (column?.checked && column?.id === "quantity") {
        defaultColumns.push(
          <Item
            name="quantity"
            className={classNames(styles.form__item, styles.amount, {
              [styles.form__item_disabled]: isTaskProductsCount
            })}
          >
            <Input step="any" type="number" onChange={onChangeQuantity} placeholder={t("project.Soni")} />
          </Item>
        );
      }
      if (column?.checked && column?.id === "unit") {
        defaultColumns.push(
          <Item name="unit_id" className={classNames(styles.form__item, styles.unit)}>
            <Select
              showSearch
              suffixIcon={null}
              onBlur={onBlurForm}
              placeholder={t("project.Birligi")}
              popupMatchSelectWidth={false}
              filterOption={selectFilterOption}
              getPopupContainer={() => smetaContainer}
            >
              {units?.map(item => (
                <Option
                  key={item.id}
                  value={item.id}
                  props={{
                    name: item?.symbol[i18n.language]
                  }}
                >
                  {item?.symbol[i18n.language]}
                </Option>
              ))}
            </Select>
          </Item>
        );
      }
      if (column?.checked && column?.id === "amount") {
        defaultColumns.push(
          <Item
            name="amount"
            className={classNames(styles.form__item, styles.price, {
              [styles.form__item_disabled]: isTaskProductsCount
            })}
          >
            <Input placeholder={t("project.Narxi")} disabled={isTaskProductsCount} onChange={onChangeAmount} />
          </Item>
        );
      }
    });

    return defaultColumns;
  };

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        name: data?.name,
        quantity: data?.quantity,
        unit_id: data?.unit?.id,
        amount: localeFormatter(String(data?.amount) ?? "0"),
        total_amount: localeFormatter(String(data?.total_amount) ?? "0"),
        custom_field_values: customFieldsColumnRender?.map(item => {
          const currentValue = data?.custom_field_values?.find(custom => item?.id === custom?.custom_field?.id);

          return {
            value: currentValue?.value,
            custom_field_id: currentValue?.custom_field?.id
          };
        })
      });
    } else {
      formInstance.setFieldsValue({
        quantity: 1,
        amount: "0"
      });
    }
  }, [customFieldsColumnRender, data, formInstance]);

  const gridStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth(tableConfigData)} 16.25rem`
  };

  return (
    <Spin spinning={createTask.isLoading || updateTask.isLoading || deleteTask.isLoading} indicator={LoadingIndicator}>
      <div ref={ref} className={styles.task__header}>
        <Form
          style={gridStyle}
          autoComplete="off"
          onFinish={onFinish}
          form={formInstance}
          onFocus={onFocusForm}
          className={styles.form}
        >
          <Item
            name="name"
            rules={formRules()}
            className={classNames(styles.form__item, styles.name, styles.task_name)}
          >
            {create ? (
              <CreateTaskName
                form={formInstance}
                mutation={createTask as never}
                sectionId={create.sectionId}
                afterFunc={onAfterCreateTask}
              />
            ) : (
              <TaskName
                onTask={onTask}
                plan={data?.plan}
                place={data?.place}
                isActive={isActive}
                sectionPlan={sectionPlan}
                sectionPlace={sectionPlace}
              />
            )}
          </Item>
          {defaultColumnsRender()}
          {EstimateCustomField({
            form: formInstance,
            onBlur: onBlurForm,
            values: data?.custom_field_values,
            locations: CustomFieldLocationEnum.TASK,
            customFields: customFieldsColumnRender,
            className: classNames(styles.form__item)
          })}
          <div className={classNames(styles.last_column, styles.last_column__task)}>
            <Item
              name="total_amount"
              className={classNames(
                styles.form__item,
                styles.amount_quantity,
                {
                  [styles.form__item_disabled]: isTaskProductsCount
                },
                { [styles.task__append]: !data }
              )}
            >
              <Input placeholder="Umumiy" disabled={isTaskProductsCount} onChange={onChangeTotalAmount} />
            </Item>
            {data && (
              <div className={classNames(styles.delete, { [styles.active]: active })}>
                <ModalConfirm getPopupContainer={() => smetaContainer} onOk={onDelete}>
                  <span>
                    <DeleteIcon />
                  </span>
                </ModalConfirm>
              </div>
            )}
          </div>
          <Button className={styles.submit} htmlType="submit" />
        </Form>
      </div>
    </Spin>
  );
};
