import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, FormInstance } from "antd";

import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import CustomFieldModal from "../../../../settings/components/custom-field/modal/CustomFieldModal";
import PlusCircleIcon from "../../../assets/icons/PlusCircleIcon";
import { queryKeys } from "../../../utils/constants/queryKeys";
import { tableConfigKeys } from "../../../utils/constants/tableConfigKeys";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";
import { ColumnConfigModel, UserTableDataModel } from "../../../utils/models/user/userTableConfigModel";
import ConditionalRender from "../../conditional-render/ConditionalRender";

import ConfigDnd from "./ConfigDnd";

import styles from "./columnConfig.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  form: FormInstance;
  columnData?: ColumnConfigModel[];
  defaultData?: UserTableDataModel;
  tableConfigKey: tableConfigKeys;
  actions: Record<string, boolean>;
  customFields?: CustomFieldSelectModel[];
};

const ColumnConfig: React.FC<Props> = ({ form, actions, columnData, defaultData, tableConfigKey, customFields }) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { setCustomFieldModal } = settingsActions;
  const { t } = useTranslation();

  const customFieldColumns = useMemo(
    () =>
      columnData?.filter(
        item =>
          !defaultData?.column.find(elem => elem?.id === item?.id) && customFields?.find(elem => elem.id === item.id)
      ),
    [columnData, customFields, defaultData?.column]
  );

  const onOpenVariableModal = () => {
    dispatch(setCustomFieldModal({ visible: true }));
  };

  const dndData = useMemo(
    () => columnData?.filter(item => defaultData?.column?.find(elem => elem?.id === item?.id)) ?? [],
    [columnData, defaultData]
  );

  const onAfterFunc = () => {
    qc.invalidateQueries([queryKeys.TABLE_CONFIGS, tableConfigKey]).then();
  };

  return (
    <>
      <ConfigDnd form={form} fieldName="top" allData={dndData} />
      <div className={styles.custom_field_columns}>
        <ConditionalRender if={actions.select}>
          {!isEmptyArr(customFieldColumns) && (
            <>
              <div className={styles.title}>{t("tableSettings.O'zgaruvchilar")}</div>
              <ConfigDnd form={form} fieldName="bottom" allData={customFieldColumns ?? []} />
            </>
          )}
        </ConditionalRender>
        <ConditionalRender if={actions.settings_create_or_update}>
          <Button className={styles.add_variable} onClick={onOpenVariableModal}>
            <PlusCircleIcon />
            <span>{t("tableSettings.O'zgaruvchi yaratish")}</span>
          </Button>
        </ConditionalRender>
      </div>
      <ConditionalRender if={actions.settings_create_or_update}>
        <CustomFieldModal afterFunc={onAfterFunc} />
      </ConditionalRender>
    </>
  );
};

export default ColumnConfig;
