import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { ErrorRes, SuccessRes } from "../../common";
import { dashboardQueryKeys } from "../query-keys";
import { CompanyWidget, WidgetPropsUpdate } from "../schema";

export function useUpdateWidgetProps() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { company_widgets: WidgetPropsUpdate[] }>(
    async body => {
      const { data } = await $api.put("company-widget/props-update", body);

      return data;
    },
    {
      retry: false,
      onSuccess: async (_, variables) => {
        qc.setQueryData([dashboardQueryKeys.COMPANY_WIDGETS], data => {
          const { companyWidgets } = data as { companyWidgets: CompanyWidget[] };

          return {
            companyWidgets: companyWidgets?.map((widget, index) => ({
              ...widget,
              props: variables?.company_widgets[index]?.props
            }))
          };
        });
      }
    }
  );
}
