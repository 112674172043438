import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormInstance } from "antd";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { CurrencySelectModel } from "../../features/app/utils/models/currencySelectModel";
import { CustomFieldSelectModel } from "../../features/app/utils/models/customFieldSelectModel";
import { SectionModel } from "../../features/projects/utils/models/estimateModel";
import { CreateProductModel, initialStateModel } from "../types/ProjectReducerModel";

const initialState: initialStateModel = {
  projectModalData: {
    visible: false
  },
  sections: [],
  emptyInputs: 0,
  workModal: {
    visible: false,
    id: 0
  },
  evictionModal: {
    visible: false,
    id: undefined,
    folderId: undefined
  },
  viewType: undefined,
  visibleImportModal: false,
  planScroll: false,
  createProduct: {
    visible: false
  },
  labelModal: {
    visible: false,
    field: undefined
  }
};

const projectsSlice = createSlice({
  name: sliceNames.PROJECTS,
  initialState,
  reducers: {
    setProjectModalData: (state, action: PayloadAction<{ visible: boolean; id?: number }>) => {
      state.projectModalData = action.payload;
    },
    setSections: (state, action: PayloadAction<SectionModel[]>) => {
      state.sections = action.payload;
    },
    setEmptyInput: state => {
      let res = 0;

      state.sections?.forEach(section => {
        if (section?.name?.length <= 0) {
          res += 1;
        }
        section.tasks?.forEach(task => {
          if (task.name?.length <= 0 || task.quantity <= 0 || task.unit?.length <= 0) {
            res += 1;
          }
          task.products?.forEach(product => {
            if (
              product.name?.length <= 0 ||
              product.amount <= 0 ||
              product.quantity <= 0 ||
              product.unit?.length <= 0
            ) {
              res += 1;
            }
          });
        });
      });
      state.emptyInputs = res;
    },
    setPricesEstimate: (
      state,
      action: PayloadAction<{
        code: Record<string, number>;
        name: Record<string, number>;
      }>
    ) => {
      const pricesByCode = action.payload.code;
      const pricesByName = action.payload.name;
      const data: SectionModel[] = state.sections.map(section => ({
        ...section,
        tasks: section.tasks.map(task => {
          if (task.products.length > 0) {
            return {
              ...task,
              amount: Math.abs(
                task.products.reduce(
                  (acc, init) =>
                    +acc + pricesByCode[init.code]
                      ? Math.abs(pricesByCode[init.code])
                      : pricesByName[init.name]
                        ? Math.abs(pricesByName[init.name])
                        : 0,
                  0
                )
              ),
              products: task.products.map(product => ({
                ...product,
                amount: pricesByCode[product.code]
                  ? Math.abs(pricesByCode[product.code])
                  : pricesByName[product.name]
                    ? Math.abs(pricesByName[product.name])
                    : 0
              }))
            };
          }
          return {
            ...task,
            amount: pricesByName[task.name] ? pricesByName[task.name] : 0,
            products: []
          };
        })
      }));

      state.sections = data;
    },
    setEditSection: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        editKey: string;
        editValue: string | boolean;
      }>
    ) => {
      const { sectionIndex, editKey, editValue } = action.payload;
      // @ts-ignore

      state.sections[sectionIndex][editKey] = editValue;
    },
    setEditTask: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        taskIndex: number;
        editKey: string;
        editValue: string | boolean;
      }>
    ) => {
      const { sectionIndex, taskIndex, editKey, editValue } = action.payload;
      // @ts-ignore

      state.sections[sectionIndex].tasks[taskIndex][editKey] = editValue;
    },
    setEditProduct: (
      state,
      action: PayloadAction<{
        sectionIndex: number;
        taskIndex: number;
        productIndex: number;
        editKey: string;
        editValue: string | boolean;
      }>
    ) => {
      const { sectionIndex, taskIndex, productIndex, editKey, editValue } = action.payload;
      // @ts-ignore

      state.sections[sectionIndex].tasks[taskIndex].products[productIndex][editKey] = editValue;
    },
    setProjectCurrency: (state, action: PayloadAction<CurrencySelectModel>) => {
      state.projectCurrency = action.payload;
    },
    setWork: (
      state,
      action: PayloadAction<{
        visible: boolean;
        id?: number;
      }>
    ) => {
      state.workModal = action.payload;
    },
    setEvictionModal: (state, action: PayloadAction<{ visible: boolean; id?: number; folderId?: number }>) => {
      state.evictionModal.visible = action.payload.visible;
      state.evictionModal.id = action.payload.id;
      state.evictionModal.folderId = action.payload.folderId;
    },
    setViewType: (state, action: PayloadAction<string>) => {
      state.viewType = action.payload;
    },
    setOpenImportModal: (state, action: PayloadAction<boolean>) => {
      state.visibleImportModal = action.payload;
    },
    setPlanScroll: (state, action: PayloadAction<boolean>) => {
      state.planScroll = action.payload;
    },
    setLabelModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        form?: FormInstance;
        name?: unknown[];
        field?: CustomFieldSelectModel;
      }>
    ) => {
      state.labelModal = action.payload;
    },
    setCreateProduct: (state, action: PayloadAction<CreateProductModel>) => {
      state.createProduct = action.payload;
    }
  }
});

export default projectsSlice.reducer;
export const projectsReducerActions = projectsSlice.actions;
