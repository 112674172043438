import React from "react";
import { Skeleton } from "antd";

import { WidgetView } from "modules/dashboard/schema/schema";

import WidgetModalCard from "./card";

type Props = {
  data: WidgetView[];
  isLoading?: boolean;
};

const WidgetModalList: React.FC<Props> = ({ data, isLoading }) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-4 gap-4">
        {[...Array(4)]?.map(() => <Skeleton className="w-[17.625rem]" key={Math.random()} active />)}
      </div>
    );
  }

  return data?.map(item => (
    <div className="flex flex-col gap-3" key={item?.type}>
      <h4 className="m-0 text-lg font-semibold text-gray-900">{item?.name}</h4>
      <div className="grid grid-cols-4 gap-4">
        {item?.widgets?.map(widget => {
          const { key, ...rest } = widget;

          return <WidgetModalCard key={widget.id} widgetKey={widget.key} {...rest} />;
        })}
      </div>
    </div>
  ));
};

export default WidgetModalList;
