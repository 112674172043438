type RouteSubmodules = [
  {
    key: "O'zgaruvchilar";
    actions: [
      {
        view: true;
        key: "Ko'rish";
      },
      {
        create: true;
        key: "Yaratish";
      },
      {
        update: true;
        key: "Tahrirlash";
      },
      {
        delete: true;
        key: "O'chirish";
      },
      {
        settings: true;
        key: "Sozlamalar";
      },
      {
        settings_create_or_update: true;
        key: "Sozlamalarni tahrirlash yoki yaratish";
      },
      {
        select: true;
        key: "Barcha o'zgaruvchilarni ko'rish";
      }
    ];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "O'zgaruvchilar",
    actions: [
      {
        view: true,
        key: "Ko'rish"
      },
      {
        create: true,
        key: "Yaratish"
      },
      {
        update: true,
        key: "Tahrirlash"
      },
      {
        delete: true,
        key: "O'chirish"
      },
      {
        settings: true,
        key: "Sozlamalar"
      },
      {
        settings_create_or_update: true,
        key: "Sozlamalarni tahrirlash yoki yaratish"
      },
      {
        select: true,
        key: "Barcha o'zgaruvchilarni ko'rish"
      }
    ]
  }
];
