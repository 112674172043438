import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Collapse, Drawer, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { CollapseProps } from "antd/lib";
import { useTranslation } from "react-i18next";

import { RU } from "modules/common";

import { useAppSelector } from "../../../../../hooks/redux";
import { warehouseProductsAction } from "../../../../../store/reducers/warehouseProductsReducer";
import MainWarehouseIcon from "../../../../app/assets/icons/MainWarehouseIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../../app/components/not-data/NotData";
import PopoverShortName from "../../../../app/components/popover-short-name/PopoverShortName";
import { colors } from "../../../../app/utils/constants/colors";
import { cx } from "../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import ChevroDownIcon from "../../../../projects/assets/icons/ChevroDownIcon";
import { useGetWareHouseProductsByWareHouseId, useGetWareHousesByProductId } from "../../../service/query";
import { IProducts, IWareHouses } from "../../../utils/models/wareHouses";

import CollapseItemTable from "./table/CollapseItemTable";

import styles from "./resourceInfoDrawer.module.scss";

const ResourceInfoDrawer: React.FC = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { setResource } = warehouseProductsAction;
  const { visible, product } = useAppSelector(state => state.warehouseProducts?.resource);
  const [wareHouseId, setWareHouseId] = useState<number>();

  const { data: wareHouses, isLoading: isWareHousesLoading } = useGetWareHousesByProductId(
    product?.product?.id,
    product?.unit?.id
  );

  const { data: wareHouseProducts, isLoading } = useGetWareHouseProductsByWareHouseId(
    wareHouseId,
    product?.product?.id,
    product?.unit?.id
  );

  const columns: ColumnsType<IProducts> = [
    {
      title: `${t("warehouse.Loyiha")}`,
      dataIndex: "name",
      key: "name",
      render: (record: string) => <span>{record}</span>
    },
    {
      title: `${t("warehouse.Soni")}`,
      dataIndex: "total_quantity",
      key: "total_quantity",
      render: (record: number) => (
        <div className={styles.total_amount}>
          <span>{record?.toLocaleString(RU)}</span> {product?.unit?.symbol[i18n.language]}
        </div>
      ),
      align: "right"
    }
  ];

  const onCancel = () => {
    dispatch(
      setResource({
        visible: false
      })
    );
  };

  const onCollapseChange = (activeKey: string, warehouseId: number) => {
    setWareHouseId(activeKey?.length > 0 ? warehouseId : undefined);
  };

  const items = (record: IWareHouses) => {
    const collapseItems: CollapseProps["items"] = [
      {
        key: record.id,
        label: (
          <div className={styles.collapse_label_container}>
            <span className={styles.warehouse_name_container}>
              <MainWarehouseIcon />
              {record.name}
            </span>
            <div className={styles.total_amount}>
              <span>{record.total_quantity?.toLocaleString(RU)}</span> {product?.unit?.symbol[i18n.language]}
            </div>
          </div>
        ),
        children: (
          <CollapseItemTable
            key={record?.id}
            columns={columns}
            isLoading={isLoading}
            wareHouseProducts={wareHouseProducts}
          />
        )
      }
    ];

    return collapseItems;
  };

  return (
    <Drawer
      open={visible}
      zIndex={11116}
      placement="right"
      onClose={onCancel}
      title={t("supply.Resurs ma'lumoti")}
      rootClassName={styles.resource_drawer}
    >
      <div className={styles.body}>
        <div className={styles.resource_name_container}>
          <p>Nomi</p>
          <div className={styles.container}>
            <div className={cx("resource")}>
              <div
                style={{
                  borderColor: product?.product?.resource?.color,
                  color: product?.product?.resource?.color
                }}
              >
                {product?.product?.resource?.symbol[i18n.language]}
              </div>
              <span className={styles.warehouse_name}>
                <PopoverShortName title={product?.product?.name[i18n.language]} length={35} />
              </span>
            </div>
            <div className={styles.total_amount}>
              <span>{product?.total_quantity?.toLocaleString(RU)}</span> {product?.unit?.symbol[i18n.language]}
            </div>
          </div>
        </div>
        <Spin spinning={isWareHousesLoading} indicator={LoadingIndicator}>
          <div className={styles.resource_info_container}>
            {wareHouses?.map(item => (
              <Collapse
                key={item.id}
                expandIcon={props => {
                  if (props.isActive) {
                    return <ChevroDownIcon active stroke={colors.GRAY_500} />;
                  }
                  return <ChevroDownIcon stroke={colors.GRAY_500} />;
                }}
                activeKey={wareHouseId}
                onChange={e => onCollapseChange(e as string, item.id)}
                items={items(item)}
              />
            ))}
            {isEmptyArr(wareHouses) && (
              <NotData
                text={{
                  text: "Omborxonalar mavjud emas!"
                }}
              />
            )}
          </div>
        </Spin>
      </div>
    </Drawer>
  );
};

export default ResourceInfoDrawer;
