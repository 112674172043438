import React, { Dispatch, SetStateAction, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Input } from "antd";
import { Form, FormInstance } from "antd/lib";
import { SearchIcon } from "features/app/assets/icons/SearchIcon";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { ErrorRes, SuccessRes } from "features/app/utils/models/responseType";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { useTranslation } from "react-i18next";

import { useSelector } from "store";

import { cx } from "modules/common";

import { TransferBodyModel } from "../../../../utils/models/transferBodyModel";
import { WarehouseProductModel } from "../../../../utils/models/WarehouseProductModel";

import CreateTable from "./CreateTable";
import Footer from "./footer";
import UpdateTable from "./UpdateTable";

import styles from "./selectedProductsTable.module.scss";

const { useWatch } = Form;

export interface IProps {
  form: FormInstance<TransferBodyModel>;
  createTransfer: UseMutationResult<SuccessRes, ErrorRes, TransferBodyModel, unknown>;
  data: PaginationType<WarehouseProductModel[]> | undefined;
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onPageChange: (page: number) => void;
  page: number;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  setPage: Dispatch<SetStateAction<number>>;
}

const SelectedProductsTable: React.FC<IProps> = ({ data, isLoading, onPageChange, setPage, setSearchText, form }) => {
  const { t } = useTranslation();
  const { id } = useSelector(state => state.transferMakingReducer);
  const [time, setTime] = useState<TimeoutModel>();
  const warehouseId = useWatch("sender_warehouse_id", form);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setPage(1);
        setSearchText(value);

        form.validateFields();
      }, 800)
    );
  };

  return (
    <div>
      <div className={styles.form_body}>
        <div className={cx(styles.top, id && "mt-3")}>
          <span>{t("warehouse.O'tkazma mahsulotlari")}</span>
          {!id && (
            <Input onChange={onSearch} placeholder={t("warehouse.Qidiruv")} prefix={<SearchIcon />} className="w-80" />
          )}
        </div>

        <div className={cx(id && "mt-2")}>
          <div className={styles.body_top}>
            <ConditionalRender if={warehouseId} else={<></>}>
              <>
                {!id ? (
                  <CreateTable isLoading={isLoading} data={data} form={form} />
                ) : (
                  <UpdateTable form={form} isLoading={isLoading} />
                )}
              </>
            </ConditionalRender>
          </div>
          <Footer data={data} form={form} onPageChange={onPageChange} />
        </div>
      </div>
    </div>
  );
};

export default SelectedProductsTable;
