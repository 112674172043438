import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";

import { useTranslation } from "react-i18next";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useDownloadFile } from "../../../../app/service/mutation";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import { Status, StatusNames } from "../../../../app/utils/models/status";
import FileIcon from "../../../../projects/assets/icons/FileIcon";
import { useGetTemplatesView } from "../../../service/queries";
import { CustomFieldLocationValue } from "../../../utils/constants/customFieldLocationValue";
import { templateSubmodules } from "../../../utils/constants/templateSubmodules";
import { TemplateModel } from "../../../utils/models/template/templateModel";
import ActionDots from "../action-dots/ActionDots";

import styles from "./templateTable.module.scss";

const TemplateTable = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetTemplatesView();
  const routePermissions = useRoutePermissions("Sozlamalar", templateSubmodules);
  const actions = routePermissions.actions("Hujjatlar");
  const downloadFile = useDownloadFile();

  const onDownloadFile = (id: number, name: string) => {
    downloadFile.mutate({ id, name });
  };

  const columns: ColumnsType<TemplateModel> = [
    {
      title: `${t("settings.Nomi")}`,
      render: (record: TemplateModel) => (
        <div
          className={styles.table__name}
          onClick={() =>
            onDownloadFile(record?.file?.id, record?.file?.original_name)
          }
        >
          <div className={styles.table__file_icon}>
            <FileIcon />
          </div>
          {record?.file?.original_name}
        </div>
      ),
    },
    {
      title: `${t("settings.Joylashuv")}`,
      render: (record: TemplateModel) => CustomFieldLocationValue[record?.location],
    },
    {
      title: `${t("settings.Yaratilgan sana")}`,
      dataIndex: "created_at",
    },
    {
      title: `${t("settings.Yaratuvchi")}`,
      render: (record: TemplateModel) => (
        <>
          {record?.creator ? (
            <div className={styles.creator}>
              {record?.creator?.image ? (
                <img
                  alt={record?.creator?.image}
                  src={record?.creator?.image}
                  className={styles.creator__avatar}
                />
              ) : (
                <span className={styles.creator__avatar}>
                  {getFirstLetter(record?.creator?.full_name)}
                </span>
              )}
              <span>{record?.creator?.full_name}</span>
            </div>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: `${t("settings.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            ["status__active"]: value === Status.ACTIVE,
            ["status__passive"]: value === Status.PASSIVE,
          })}
        >
          {StatusNames[value]}
        </span>
      ),
    },
    {
      title: "",
      render: (record: TemplateModel) => (
        <ConditionalRender if={actions["delete"] || actions["update"]}>
          <ActionDots record={record} />
        </ConditionalRender>
      ),
      width: "5%",
    },
  ];

  const rowKey = (record: TemplateModel) => {
    return record.id;
  };

  return (
    <>
      <Table<TemplateModel>
        dataSource={data?.data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />,
        }}
        loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        className={styles.table}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total,
          }}
        />
      </div>
    </>
  );
};

export default TemplateTable;
