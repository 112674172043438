import React, { useState } from "react";

import { useWidget } from "modules/dashboard";
import { useActiveCompanyPersonView } from "modules/dashboard/queries/use-active-company-person-view";

import ActiveCompanyPersonTable from "./active-company-person-table";

const ActiveCompanyPerson: React.FC = () => {
  const { visible, id, props } = useWidget();
  const [page, setPage] = useState(1);
  const { activeCompanyPersonView, isLoading } = useActiveCompanyPersonView({
    enabled: visible,
    id,
    company_person_id: props?.company_person_id,
    page
  });

  return (
    <div className="w-[45.75rem] p-5">
      <ActiveCompanyPersonTable data={activeCompanyPersonView} isLoading={isLoading} setPage={setPage} />
    </div>
  );
};

export default ActiveCompanyPerson;
