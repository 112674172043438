import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { integrationActions } from "../../../../../../store/reducers/integrationReducer";
import ArrowNarrowIcon from "../../../../../app/assets/icons/ArrowNarrowIcon";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import ConnectProjects from "../connect-projects/ConnectProjects";
import CustomDropdown from "../custom-dropdown/CustomDropdown";
import styles from "./integrationSettings.module.scss";

const data = [{ id: 1, name: "Naqd" }];

const IntegrationSettings = () => {
  const dispatch = useAppDispatch();
  const { settingsModal } = useAppSelector((state) => state.integrationReducer);
  const { setSettingsData } = integrationActions;

  const onCancel = () => {
    dispatch(setSettingsData({ visible: false, data: [] }));
  };

  const columns: ColumnsType<any> = [
    {
      title: "Uyqur",
      dataIndex: "name",
      width: "20%",
    },
    {
      title: "Uysot",
      render: (record: any) => (
        <div className={styles.actions}>
          <ArrowNarrowIcon rotate={180} />
          <Button>1-bino</Button>
          <Button>2-bino</Button>
          <CustomDropdown />
        </div>
      ),
    },
  ];

  const rowKey = (record: any) => {
    return record.id;
  };

  return (
    <Modal
      centered
      open={settingsModal.visible}
      closeIcon={<CloseIcon />}
      title="Loyihalarni sozlash"
      onCancel={onCancel}
      cancelText="Ortga"
      okText="Saqlash"
      width={1186}
      className={styles.settings_modal}
    >
      <ConnectProjects />
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />,
        }}
        // loading={{
        //   spinning: isCompanyPerson ? companyPersonLoading : inventoryLoading,
        //   indicator: LoadingIndicator,
        // }}
        className={styles.table}
      />
    </Modal>
  );
};

export default IntegrationSettings;
