import React from "react";
import LaboratoryNoDataIcon from "features/warehouse/assets/icons/LaboratoryNoDataIcon";
import { useTranslation } from "react-i18next";

import styles from "./leftNodata.module.scss";

const LeftNoData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <LaboratoryNoDataIcon />
      <span>
        <h1>{t("warehouse.Laboratoriya dan foydalanish uchun omborxona tanlang")} !</h1>
        <p>{t("warehouse.Laboratoriya orqali bir birlikdan boshqa birlikka o’tkazing.")}</p>
      </span>
    </div>
  );
};

export default LeftNoData;
