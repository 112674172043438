import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import CaretCircleDoubleDownIcon from "../../../../../../../../../assets/icons/CaretCircleDoubleDownIcon";
import GanttTableHeaderResizeItem from "./gantt-table-header-resize-item/GanttTableHeaderResizeItem";
import GanttTableHeaderSettings from "./gantt-table-header-settings/GanttTableHeaderSettings";

import styles from "./ganttTableHeader.module.scss";

type Props = {
  onToggleActiveAll: () => void;
  activeAll: boolean;
};

const GanttTableHeader = ({ onToggleActiveAll, activeAll }: Props) => {
  const { t } = useTranslation()
  const { tableSetting } = useAppSelector((state) => state.ganttReducer);
  const { percent, confirmation_person, status, start_date, due_date } =
    useAppSelector((state) => state.ganttReducer.settings);
  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.item} ${styles.item_name}`}>
          {/* <TableSettingIcon />  */}
          <GanttTableHeaderSettings />
          <div onClick={onToggleActiveAll} className={styles.toggle_active}>
            <CaretCircleDoubleDownIcon rotate={activeAll} />
            {t("project.ISH NOMI")}
          </div>
          <GanttTableHeaderResizeItem
            className={styles.resize}
            rootName="--gantt-chart-table-name"
            maxWidth={750}
          />
        </div>
        {percent && (
          <div className={`${styles.item} ${styles.item_percent}`}>
            %{" "}
            {/* <GanttTableHeaderResizeItem
              className={styles.resize}
              rootName="--gantt-chart-table-percent"
              maxWidth={100}
            /> */}
          </div>
        )}
        {confirmation_person && (
          <div className={`${styles.item} ${styles.item_confirmation}`}>
            {t("project.MAS’UL XODIM")}{" "}
            {/* <GanttTableHeaderResizeItem
              className={styles.resize}
              rootName="--gantt-chart-table-responsible"
              maxWidth={100}
            /> */}
          </div>
        )}
        {status && (
          <div className={`${styles.item} ${styles.item_status}`}>
            {t("project.HOLATI")}{" "}
            {/* <GanttTableHeaderResizeItem
              className={styles.resize}
              rootName="--gantt-chart-table-status"
            /> */}
          </div>
        )}
        {start_date && (
          <div className={`${styles.item} ${styles.item_startDate}`}>
            {t("project.BOSHLANISH")}{" "}
            {/* <GanttTableHeaderResizeItem
              className={styles.resize}
              rootName="--gantt-chart-table-start-date"
            /> */}
          </div>
        )}
        {due_date && (
          <div className={`${styles.item} ${styles.item_dueDate}`}>{t("project.TUGASH")}</div>
        )}
      </div>
      <style>
        {`
          :root{
            --gantt-chart-table-name: ${tableSetting["--gantt-chart-table-name"]}px;
            --gantt-chart-table-percent: ${tableSetting["--gantt-chart-table-percent"]}px;
            --gantt-chart-table-responsible: ${tableSetting["--gantt-chart-table-responsible"]}px;
            --gantt-chart-table-status: ${tableSetting["--gantt-chart-table-status"]}px;
            --gantt-chart-table-start-date: ${tableSetting["--gantt-chart-table-start-date"]}px;
            --gantt-chart-table-due-date: ${tableSetting["--gantt-chart-table-due-date"]}px;
          }
        `}
      </style>
    </>
  );
};

export default GanttTableHeader;
