import { Col, Form, Input, Modal, Row, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../store/reducers/companyPersonReducer";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { useGetCurrenciesSelect, useGetUnitSelect } from "../../../../../../app/service/queries";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { useCreateInventory, useUpdateInventory } from "../../../../../service/mutation";
import { useGetInventoryFolderSelect } from "../../../../../service/queries";
import { InventoryBodyModel } from "../../../../../utils/models/counterpartsInventoryBodyModel";

import styles from "./inventoryModal.module.scss";

const { Item } = Form;
const { Option } = Select;

const InventoryModal: React.FC = () => {
  const { i18n } = useTranslation();
  const [form] = Form.useForm<InventoryBodyModel>();
  const dispatch = useDispatch();
  // const { id: company_person_id } = useParams();
  const location = useLocation();

  const company_person_id = location.pathname?.split("/")[2]?.split("-")[1];
  const { setVisibleInventory } = companyPersonActions;
  const { visible, data } = useAppSelector(state => state.companyPersonReducer.inventory.modal);

  const createInventory = useCreateInventory();
  const updateInventory = useUpdateInventory();
  const { data: units, isLoading: isLoadingUnits } = useGetUnitSelect();
  const { data: currency } = useGetCurrenciesSelect(visible);
  const { data: inventoryGroups } = useGetInventoryFolderSelect(visible);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      form.setFieldsValue({ ...data });
    } else {
      dispatch(
        setVisibleInventory({
          visible: false,
          data: undefined
        })
      );
      form.resetFields();
    }
  };

  const onFinish = (values: InventoryBodyModel) => {
    if (data?.id) {
      updateInventory.mutateAsync({ ...values, company_person_id, id: data?.id }).then(onCancel);
    } else {
      createInventory.mutateAsync({ ...values, company_person_id }).then(onCancel);
    }
  };

  const onCancel = () => {
    dispatch(setVisibleInventory({ visible: false, data }));
  };

  const onOk = () => form.submit();

  return (
    <Modal
      centered
      afterOpenChange={onAfterOpen}
      title="Inventar qo’shish"
      okText="Saqlash"
      cancelText="Yopish"
      open={visible}
      onCancel={onCancel}
      onOk={onOk}
      className={styles.inventory_modal}
      okButtonProps={{
        loading: createInventory.isLoading || updateInventory.isLoading
      }}
      width="34.3rem"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" label="Nomi" rules={formRules()}>
          <Input />
        </Item>
        <Item name="code" label="Kodi" rules={formRules()}>
          <Input />
        </Item>
        <Row gutter={[16, 16]}>
          <Col span={18}>
            <Item name="amount" label="Tan narxi" rules={formRules()}>
              <Input type="number" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="amount_currency_id" label="Valyuta" rules={formRules()}>
              <Select suffixIcon={<SelectSuffixIcon />}>
                {currency?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.symbol}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Item name="service_amount" label="Ish narxi" rules={formRules()}>
              <Input type="number" />
            </Item>
          </Col>
          <Col span={6}>
            <Item name="service_amount_currency_id" label="Valyuta" rules={formRules()}>
              <Select suffixIcon={<SelectSuffixIcon />}>
                {currency?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.symbol}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={8}>
            <Item name="service_amount_unit_id" label="Birligi">
              <Select
                placeholder="Birligi"
                loading={isLoadingUnits}
                notFoundContent={<TableEmpty />}
                suffixIcon={<SelectSuffixIcon />}
              >
                {units?.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name[i18n.language]}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>
        <Item name="inventory_folder_id" label="Guruh">
          <Select suffixIcon={<SelectSuffixIcon />} disabled={!!data?.inventory_folder_id}>
            {inventoryGroups?.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
      </Form>
    </Modal>
  );
};

export default InventoryModal;
