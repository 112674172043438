import React from "react";
import { Tooltip } from "antd";

import styles from "./custom-tooltip.module.scss";

type Props = {
  children?: React.ReactNode;
  content?: React.ReactNode;
  isToolTip?: boolean;
};

const CustomTooltip: React.FC<Props> = ({ children, content, isToolTip }) => {
  if (!isToolTip) return <div className={styles.tooltip_container}>{children}</div>;

  return (
    <Tooltip
      arrow={false}
      placement="top"
      rootClassName={styles.tooltip}
      title={<div className={styles.tooltip_container}>{content}</div>}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
