import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Button, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { useTranslation } from "react-i18next";

import { Table } from "components";

import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { cx } from "../../../../../../app/utils/helpers/cx";
import ExpectedExpenseIcon from "../../../../../../payment/assets/icons/ExpectedExpenseIcon";
import ExpectedIncomeIcon from "../../../../../../payment/assets/icons/ExpectedIncomeIcon";
import { ExpectedPaymentModel } from "../../../../../../payment/utils/models/expectedPaymentModel";
import { useGetCompanyPersonExpectedPayment } from "../../../../../service/queries";

import styles from "../../../../../../payment/components/bottom/tables/expected-payment/expectedPaymentTable.module.scss";
import secondStyles from "./expectedPayment.module.scss";
import useDebounce from "components/use-debounce/use-debounce";



type Props = {
  tabKeys: { key: string; type?: string };
};

const ExpectedPaymentTable: React.FC<Props> = ({ tabKeys }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { setPayModal } = paymentReducerActions;
  const [searchParams] = useSearchParams();
  const type = searchParams.get(queryParamsKeys.TYPE) as PaymentTypeEnum;
  const { t } = useTranslation();

  const changeTableConfig = useChangeTableConfig(
    type === "income"
      ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_INCOME
      : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_EXPENSE,
    true
  );
  const { data: tableConfig } = useGetTableConfigs(
    type === "income"
      ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_INCOME
      : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_EXPENSE
  );

  const { data, isLoading } = useGetCompanyPersonExpectedPayment(tabKeys);

  const onPayment = (record: ExpectedPaymentModel) => {
    dispatch(
      setPayModal({
        isTemplate: false,
        visible: true,
        type,
        form_data: {
          id: record.id,
          currency_id: record.currency.id,
          amount: record.debt_amount,
          description: ""
        }
      })
    );
  };

  const diffDate = (date: string) => {
    const [day, month, year] = date.split(".");
    const day1 = dayjs(`${month}.${day}.${year}`);
    const diff = dayjs().diff(day1, "days");

    return diff > 0 ? (
      <span
        style={{
          color: "#F04438"
        }}
      >
        {diff} kun
      </span>
    ) : (
      "-"
    );
  };

  const [columns, setColumns] = useState<ColumnsType<ExpectedPaymentModel>>([
    {
      title: `${t("Counterparts.Sana")}`,
      dataIndex: "date",
      width: 250,
      className: "date-column"
    },
    {
      title: `${t("Counterparts.Kechikish")}`,
      render: (_, record) => diffDate(record.date),
      width: 400,
      className: "delay-column"
    },
    {
      title: `${t("Counterparts.Summa")}`,
      render: (record: ExpectedPaymentModel) => (
        <span>
          {record.amount?.toLocaleString(RU)} {record.currency.symbol}
        </span>
      ),
      width: 300,
      className: "amount-column"
    },
    {
      title: `${t("Counterparts.Manbaa")}`,
      render: (record: ExpectedPaymentModel) =>
        PaymentSourceElement({ record: record as unknown as Partial<PaymentModel> }),
      width: 300,
      className: "payment-source-column"
    },
    {
      title: `${t("Counterparts.Xodim")}`,
      render: (record: ExpectedPaymentModel) => (
        <div className="flex items-center justify-between">
          <Tooltip title={record?.creator?.full_name}>
            <img
              src={record.creator.image}
              alt={record.creator.full_name}
              className="h-8 w-8 rounded-full object-cover"
            />
          </Tooltip>
          <Button onClick={() => onPayment(record)} className={cx("creator-column-btn")}>
            {type === PaymentTypeEnum.INCOME ? <ExpectedIncomeIcon /> : <ExpectedExpenseIcon />}
            <span>{type === PaymentTypeEnum.INCOME ? "Kirim qilish" : "Qarzni to'lash"}</span>
          </Button>
        </div>
      ),
      width: 300,
      className: "creator-column"
    }
  ]);

  useEffect(() => {
    if (tableConfig?.width_data?.length && tableConfig?.width_data?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key:
        type === "income"
          ? tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_INCOME
          : tableConfigKeys.COUNTERPARTS_EXPECTED_PAYMENT_EXPENSE,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800,timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ExpectedPaymentModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <>
      <Table<ExpectedPaymentModel>
        columns={columns}
        onChangeColumns={onChangeColumns}
        pagination={false}
        dataSource={data?.data}
        rootClassName={secondStyles.table}
        rowKey={record => record.id}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
        />
      </div>
    </>
  );
};

export default ExpectedPaymentTable;
