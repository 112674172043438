import React, { useEffect, useRef, useState } from "react";
import { DatePicker, notification, Spin } from "antd";
import dayjs from "dayjs";

import { useAppDispatch, useAppSelector } from "../../../../../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../../../../../store/reducers/projectsReducer";
import { LoadingIndicator } from "../../../../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { dayjsFormats } from "../../../../../../../../../../app/utils/constants/dayjsFormats";
import { projectStatuses } from "../../../../../../../../../utils/enums/projectStatuses";
import { useTaskUpdate } from "../../../services/mutation";
import { GanttSection } from "../../../utils/models/GanttSection";
import { GanttTaskType } from "../../../utils/models/GanttTaskType";
import { TaskStatus } from "../../../utils/models/TaskStatus";

import GanttTableConfirmationUsers from "./gantt-table-confirmation-users/GanttTableConfirmationUsers";
import GanttTableTaskStatus from "./gantt-table-task-status/GanttTableTaskStatus";

import styles from "./ganttTableTask.module.scss";

interface IProps extends GanttSection {
  sectionId: number;
  sectionPlace: number;
  elementType?: GanttTaskType;
  projectStatus?: projectStatuses;
  isSearch?: boolean;
}

const GanttTableTask = (props: IProps) => {
  const { setWork } = projectsReducerActions;
  const { tableSetting } = useAppSelector(state => state.ganttReducer);
  const settingsValue = useAppSelector(state => state.ganttReducer.settings);
  const dispatch = useAppDispatch();
  const {
    id,
    name,
    place,
    start_date,
    due_date,
    elementType,
    sectionId,
    company_persons,
    sectionPlace,
    projectStatus
  } = props;

  const taskUpdate = useTaskUpdate(sectionId);

  const handleTask = () => {
    const gantt_chart = document.getElementById("gantt_chart");
    const task = document.getElementById(`task_${id}`);

    if (task && gantt_chart) {
      gantt_chart.scrollTo({
        left: task.offsetLeft,
        behavior: "smooth"
      });
    }
  };

  const [_, contextHolder] = notification.useNotification();

  const changeTaskDate = (dates: Array<string>) => {
    if (dates) {
      taskUpdate.mutate({
        id,
        start_date: dates[0] ? dayjs(dates[0] || "", dayjsFormats.DATE).format(dayjsFormats.DATE) : "",
        due_date: dates[1] ? dayjs(dates[1] || "", dayjsFormats.DATE).format(dayjsFormats.DATE) : ""
      });
    } else {
      taskUpdate.mutate({
        id,
        start_date: "",
        due_date: ""
      });
    }
  };

  const formatDate = "DD MMM YYYY";

  const nameSize = (tableSetting["--gantt-chart-table-name"] || 10) / 10;

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleCalendarChange = (dates: Array<string>) => {
    changeTaskDate(dates);
    if (dates && dates.length === 2) {
      setPickerOpen(false);
    } else {
      setPickerOpen(true);
    }
  };

  const handleOpenChange = (open: boolean) => {
    setPickerOpen(open);
  };

  return (
    <div className={styles.container}>
      {contextHolder}
      <div
        className={`${styles.item} ${styles.item_name} ${styles.item_task_name}`}
        onClick={() => {
          if (!props?.is_title) dispatch(setWork({ visible: true, id }));
        }}
        onContextMenu={e => {
          e.preventDefault();
          if (elementType !== "section") {
            handleTask();
          }
        }}
        title={name}
      >
        {!props?.is_title ? (
          <div className={styles.not_title}>
            {sectionPlace}.{place} {name.length > nameSize ? `${name.slice(0, nameSize)}...` : name}
          </div>
        ) : (
          <span className={styles.title}>{name}</span>
        )}
      </div>
      {settingsValue?.percent && (
        <div
          className={`${styles.item} ${styles.item_percent} ${
            props?.status === TaskStatus.COMPLETED ? styles.completed : ""
          }`}
        >
          {!props?.is_title ? props?.done_percentage : ""}
        </div>
      )}
      {settingsValue?.confirmation_person && !props?.is_title && (
        <GanttTableConfirmationUsers
          companyPersons={company_persons}
          users={props?.users}
          id={id}
          sectionId={sectionId}
          projectStatus={projectStatus}
          elementType={elementType}
        />
      )}
      {settingsValue?.status && (
        <div className={`${styles.item} ${styles.item_status}`}>
          {props?.is_title ? (
            ""
          ) : (
            <GanttTableTaskStatus
              id={id}
              sectionId={sectionId}
              status={props?.status}
              elementType={elementType}
              projectStatus={projectStatus}
            />
          )}
        </div>
      )}
      {settingsValue?.start_date && (
        <div className={`flex items-center justify-center`}>
          {props?.is_title ? (
            ""
          ) : (
            <>
              {elementType === "section" || props?.projectStatus !== projectStatuses.PLANNING ? (
                start_date ? (
                  dayjs(start_date, dayjsFormats.DATE).format(formatDate)
                ) : (
                  "--"
                )
              ) : (
                <Spin spinning={taskUpdate.isLoading} indicator={LoadingIndicator}>
                  <div>
                    <DatePicker.RangePicker
                      size="small"
                      placeholder={["--", "--"]}
                      onChange={handleCalendarChange as never}
                      value={[
                        start_date ? dayjs(start_date, dayjsFormats.DATE) : null,
                        due_date ? dayjs(due_date, dayjsFormats.DATE) : null
                      ]}
                      open={pickerOpen}
                      separator={false}
                      onOpenChange={handleOpenChange}
                      onClick={() => setPickerOpen(true)}
                      suffixIcon={false}
                      format={formatDate}
                      className={styles.datePicker}
                      variant="borderless"
                      allowClear={true}
                    />
                  </div>
                </Spin>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GanttTableTask;
