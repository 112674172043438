import React, { useMemo } from "react";

import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { companyPersonBalanceSubmodules } from "../../../utils/constants/routeSubmodules";

import CounterpartsInfo from "./info/CounterpartsInfo";
import CounterpartsTables from "./tables/CounterpartsTables";

import styles from "./counterpartsContent.module.scss";

const CounterpartsContent: React.FC = () => {
  const { submodules } = useRoutePermissions("Kontragent balansi", companyPersonBalanceSubmodules);

  const returnTabKeys = useMemo(() => {
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [first, second] = submodules[0]?.tabKey?.split("&");
    const key = first?.split("=")?.[1];
    const type = second?.split("=")?.[1];

    return { key, type };
  }, [submodules]);

  return (
    <div className={styles.content}>
      <CounterpartsInfo defaultTabKey={returnTabKeys?.key} />
      <CounterpartsTables tabKeys={returnTabKeys} />
    </div>
  );
};

export default CounterpartsContent;
