import React from "react";

const CloseCircleIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 7.5013L7.50002 12.5013M7.50002 7.5013L12.5 12.5013M18.3334 10.0013C18.3334 14.6037 14.6024 18.3346 10 18.3346C5.39765 18.3346 1.66669 14.6037 1.66669 10.0013C1.66669 5.39893 5.39765 1.66797 10 1.66797C14.6024 1.66797 18.3334 5.39893 18.3334 10.0013Z"
        stroke="#F04438"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseCircleIcon;
