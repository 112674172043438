import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "modules/common";
import { Calendar, getColor, useWidget, WidgetKeys } from "modules/dashboard";
import { getWeekdaysBetweenDates } from "modules/dashboard/helpers/get-weekday-between-dates";

import { WidgetCustomTooltip } from "../../tooltip";

interface IProps {
  id: number;
  data: Calendar[];
  widgetKey: WidgetKeys;
  name: Record<string, string>;
}

const MonthlyCalendar: React.FC<IProps> = ({ data, id, name, widgetKey }) => {
  const weekDays = getWeekdaysBetweenDates(data[0]?.date, data[data?.length - 1]?.date);
  const { t } = useTranslation();

  const {
    methods: { setWidgetDrawer }
  } = useWidget();

  const { i18n } = useTranslation();

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenViewDrawer = ({ day, e }: { day: string; e: React.MouseEvent<HTMLDivElement, MouseEvent> }) => {
    stopPropagation(e);

    setWidgetDrawer({
      id,
      visible: true,
      props: { date: day },
      key: widgetKey,
      title: (
        <div className="flex gap-1">
          <span className="text-lg font-semibold text-gray-900">{name[i18n.language]}</span>
          <span className="text-lg font-semibold text-gray-400">({day})</span>
        </div>
      )
    });
  };
  const customTooltip = (label: string, value: number, color: string) => (
    <div className="flex flex-col gap-4">
      <span className="text-sm font-normal text-gray-300">
        {dayjs(label, dayjsFormats.DATE).format(dayjsFormats.DATE_WITH_MONTH_NAME)}
      </span>
      <div
        style={{ borderColor: color }}
        className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
      >
        <span className="text-sm font-semibold text-white">
          {t("dashboard.Progress")}: {value}
        </span>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        {weekDays?.map(weekDay => (
          <span className="flex w-full items-center justify-center text-xs font-normal text-gray-500" key={weekDay}>
            {weekDay}
          </span>
        ))}
      </div>
      <div className="grid grid-flow-row-dense grid-cols-7 gap-2">
        {data?.map(el => (
          <WidgetCustomTooltip key={el.date} isToolTip content={customTooltip(el.date, el.count, getColor(el.count!))}>
            <div
              onClick={e => onOpenViewDrawer({ day: el.date, e })}
              onTouchStart={stopPropagation as never}
              onMouseDown={stopPropagation}
              className="h-[1.993rem] w-full cursor-pointer rounded-md"
              style={{
                backgroundColor: getColor(el.count!),
                border: `0.0625rem solid ${getColor(el.count!)}`
              }}
              key={el.date + el.count}
            />
          </WidgetCustomTooltip>
        ))}
      </div>
    </div>
  );
};

export default MonthlyCalendar;
