import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const OrderCreated = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/supply?tab=parties", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.order?.warehouse?.name}{" "}
        <span className={styles.name}>P - {record?.order?.id}</span> raqamli
        mahsulotlar partiyasini taminot bo’limisiz qabul qildi.
      </p>
    </div>
  );
};

export default OrderCreated;
