import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { counterpartsEndPoints } from "../utils/constants/counterpartsEndPoints";
import { counterpartsQueryKeys } from "../utils/constants/counterpartsQueryKeys";
import { CounterpartsFormModel } from "../utils/models/counterpartsFormModel";
import { InventoryBodyModel } from "../utils/models/counterpartsInventoryBodyModel";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import { FileType } from "features/app/utils/enums/fileType";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

export function useCreateCounterpartsFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id?: number; name?: string; color?: string }>(
    async data => {
      const res = await $api.post(counterpartsEndPoints.CREATE_FOLDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.FOLDERS_VIEW]);
      }
    }
  );
}

export function useUpdateCounterpartsFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id?: number; name?: string; color?: string }>(
    async data => {
      const res = await $api.put(counterpartsEndPoints.UPDATE_FOLDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.FOLDERS_VIEW]);
      }
    }
  );
}

export function useDeleteCounterpartsFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${counterpartsEndPoints.DELETE_FOLDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.FOLDERS_VIEW]);
      }
    }
  );
}

export function useDeletePerson(folder_id?: string) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${counterpartsEndPoints.DELETE_PERSON}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        folder_id
          ? qc.invalidateQueries([counterpartsQueryKeys.ONE_FOLDER_VIEW, folder_id])
          : qc.invalidateQueries([counterpartsQueryKeys.PERSONS_VIEW]);
      }
    }
  );
}

export function useCreatePerson() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<{ id: number; name: string; phone: string }>, ErrorRes, CounterpartsFormModel>(
    async data => {
      const res = await $api.post(counterpartsEndPoints.CREATE_PERSON, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: (data, variables) => {
        message.success(data.message[i18n.language]);
        variables.company_person_folder_id
          ? qc.invalidateQueries([counterpartsQueryKeys.ONE_FOLDER_VIEW, variables.company_person_folder_id])
          : qc.invalidateQueries([counterpartsQueryKeys.PERSONS_VIEW]);

        qc.invalidateQueries([counterpartsQueryKeys.COMPANY_PERSON_SELECT]);
      }
    }
  );
}

export function useUpdatePerson() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { id } = useParams();

  return useMutation<SuccessRes, ErrorRes, Partial<CounterpartsFormModel>>(
    async data => {
      const res = await $api.put(counterpartsEndPoints.UPDATE_PERSON, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        if (id) {
          qc.invalidateQueries([counterpartsQueryKeys.ONE_FOLDER_VIEW]);
        } else {
          qc.invalidateQueries([counterpartsQueryKeys.PERSONS_VIEW]);
          qc.invalidateQueries([counterpartsQueryKeys.FOLDERS_VIEW]);
        }
      }
    }
  );
}

export function useCreateInventoryGroup() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { name: string }>(
    async data => {
      const res = await $api.post(counterpartsEndPoints.CREATE_INVENTORY_GROUP, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.INVENTORY]);
      }
    }
  );
}

export function useCreateInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryBodyModel>(
    async data => {
      const res = await $api.post(counterpartsEndPoints.CREATE_INVENTORY, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.INVENTORY]);
      }
    }
  );
}

export function useUpdateInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryBodyModel>(
    async data => {
      const res = await $api.put(counterpartsEndPoints.UPDATE_INVENTORY, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.INVENTORY]);
      }
    }
  );
}

export function useDeleteInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async data => {
      const res = await $api.delete(`${counterpartsEndPoints.DELETE_INVENTORY}?id=${data}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.INVENTORY]);
      }
    }
  );
}

export function useCreateOffer(company_person_id: number) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: {
      company_person_id: number;
      products: Array<{
        product_id: number;
        amount: number;
        currency_id: number;
        unit_id: number;
      }>;
    }) => {
      const res = await $api.post(counterpartsEndPoints.OFFER_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([counterpartsQueryKeys.PERSONS_OFFER_VIEW_ID, company_person_id]);
      }
    }
  );
}

export function useExportCounterpartsExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(`${counterpartsEndPoints.EXPORT_EXCEL}`, { ...data }, { responseType: "blob" });

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Counterparts", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
