import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { supplyOfferActions } from "store/reducers/supplyOfferReducer";

import ProjectSelect from "../../../../../../../app/components/project-select/project-select";

const HeaderProjectSelect: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setOfferPartyLeftData } = supplyOfferActions;
  const { leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onChange = (e: number) => {
    dispatch(
      setOfferPartyLeftData(
        leftData?.map(item => ({
          ...item,
          project_id: e
        }))
      )
    );
  };

  return (
    <ProjectSelect
      mode="state"
      value={undefined}
      allowClear={false}
      onChange={onChange}
      placeholder={t("supply.Loyiha")}
    />
  );
};

export default HeaderProjectSelect;
