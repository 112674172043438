import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { useTranslation } from "react-i18next";

import { ProjectActivity } from "modules/dashboard";

interface IProps {
  data: PaginationType<ProjectActivity[]>;
  isLoading: boolean;
}

const ProjectActiveViewTable: React.FC<IProps> = ({ data, isLoading }) => {
  const { i18n, t } = useTranslation();
  const columns: ColumnsType<ProjectActivity> = [
    {
      title: `${t("dashboard.Ish nomi va bo'limi")}`,
      render: (record: ProjectActivity) => (
        <div className="flex flex-col gap-1">
          <span className="text-sm font-medium text-gray-800">
            {record?.place} {record?.section.name}
          </span>
          <span className="text-xs font-normal text-gray-800">{record?.task?.name}</span>
        </div>
      )
    },
    {
      title: `${t("dashboard.Bajarildi")}`,
      render: (record: ProjectActivity) => (
        <div className="text-sm font-medium text-gray-900">
          {record?.quantity}{" "}
          <span className="text-sm font-medium text-gray-400">{record?.unit?.symbol[i18n.language]}</span>
        </div>
      )
    },
    {
      title: `${t("dashboard.Sana")}`,
      render: record => <span className="text-sm font-medium text-gray-600">{record?.date}</span>
    },
    {
      title: `${t("dashboard.Bajardi")}`,
      render: (record: ProjectActivity) => (
        <CustomAvatar image={record?.user?.image ? record?.user?.image : ""} name={record?.user?.full_name} />
      )
    }
  ];

  return (
    <div>
      <Table
        dataSource={data?.data}
        columns={columns}
        className="footer_table"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        pagination={false}
        rowKey={(record: ProjectActivity) => record?.id + record?.unit?.id + record?.section?.id}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      {data?.total > 10 && (
        <div className="rounded-bl-xl rounded-br-xl border-b border-l border-r border-t-0 border-solid border-b-gray-200 border-l-gray-200 border-r-gray-200 bg-white px-4 py-3">
          <Pagination
            paginationProps={{
              total: data?.total,
              current: data?.current_page
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectActiveViewTable;
