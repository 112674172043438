import { Tabs, TabsProps } from "antd";
import { useDispatch } from "react-redux";
import React, { useLayoutEffect, useMemo } from "react";

import { useAppSelector } from "../../../../hooks/redux";
import { ProjectModel } from "../../utils/models/projectModel";
import EstimateChildren from "./estimate-children/EstimateChildren";
import { supplyActions } from "../../../../store/reducers/supplyReducer";

type Props = {
  projects: ProjectModel[] | undefined;
  isPrice?: boolean
};

const EstimateTabs: React.FC<Props> = ({ projects, isPrice }) => {
  const dispatch = useDispatch();
  const { setEstimateProjectId } = supplyActions;
  const { projectId } = useAppSelector((state) => state.supplyReducer.estimateData);

  useLayoutEffect(() => {
    if (!projectId) {
      dispatch(setEstimateProjectId(String(projects?.[0]?.id)));
    }
  }, [projectId, projects]);

  const items: TabsProps["items"] = useMemo(() => {
    return projects?.map((item) => ({
      key: String(item.id),
      label: (
        <div className="project_name">
          {item.name}
          <div className="project_name__count">{item.task_products_count}</div>
        </div>
      ),
      children: <EstimateChildren currencySymbol={item.currency?.symbol} isPrice={isPrice} />,
    })) as TabsProps["items"];
  }, [projects]);

  const onChangeTab = (e: string) => {
    dispatch(setEstimateProjectId(e));
  };

  return <Tabs items={items} onChange={onChangeTab} activeKey={String(projectId)} />;
};

export default EstimateTabs;
