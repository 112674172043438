import { useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useDeletePart } from "features/impost/service/mutations";
import { routeSubmodules } from "features/impost/utils/constants/routeSubmodules";
import ClockFastForwardIcon from "features/supply/assets/icons/ClockFastForwardIcon";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { chatActions } from "../../../../../store/reducers/chatReducer";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { impostQueryKeys } from "../../../utils/constants/impostQueryKeys";
import { PartImpostModel } from "../../../utils/models/partImpostModel";

import styles from "./actionDropdown.module.scss";

type Props = {
  record?: PartImpostModel;
};

const ActionDropdown = ({ record }: Props) => {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const { setVisible } = chatActions;
  const { setOrderDeleteArchive } = paymentReducerActions;
  const { actions } = useRoutePermissions("Monitoring", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const deleteParty = useDeletePart();

  const onDeleteParty = (partyId: number) => deleteParty.mutateAsync(partyId);

  const onToggleDropdown = () => {
    setVisibleDropdown(prev => !prev);
  };

  const onOpenChat = (e: React.MouseEvent<HTMLElement>, record: PartImpostModel | undefined) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.ORDERS,
        dataKeys: [impostQueryKeys.PARTS, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
    setVisibleDropdown(false);
    e.stopPropagation();
  };

  const onOpenOrderDeleteArchive = (orderId: number) => {
    dispatch(
      setOrderDeleteArchive({
        orderId,
        visible: true
      })
    );
  };

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const onClickActionIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: React.ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const customItems = () => {
    const items = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenChat(e, record)}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        style: {
          padding: 0
        },
        isView: true
      },
      {
        key: "2",
        style: {
          padding: 0
        },
        label: (
          <ModalConfirm title="Ushbu partiyani o'chirmoqchimisiz ?" onOk={() => onDeleteParty(record?.id!)}>
            <div className={styles.dropdown__item}>
              <DeleteIcon /> {t("Monitoring.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        onClick: () => {},
        permissionKey: "delete",
        isView: true
      },
      {
        key: "3",
        style: {
          padding: 0
        },
        label: (
          <div
            className={styles.dropdown__item}
            onClick={e => {
              onOpenOrderDeleteArchive(record?.id!);
            }}
          >
            <ClockFastForwardIcon /> {t("Monitoring.To'lovlar tarixi")}
          </div>
        ),
        permissionKey: "orderHistory",
        isView: true
      }
    ];

    return items?.filter(item => {
      if (item.permissionKey) return partyActions[item.permissionKey] && item.isView;
      return item.isView;
    });
  };

  return (
    <Dropdown
      menu={{ items: customItems() }}
      dropdownRender={dropdownRender}
      trigger={["click", "contextMenu"]}
      open={visibleDropdown}
      onOpenChange={onToggleDropdown}
    >
      <UnreadMessageDots count={record?.unread_message_count} onClick={onClickActionIcon} />
    </Dropdown>
  );
};

export default ActionDropdown;
