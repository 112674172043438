import React from "react";

export const ArrowUpIcon: React.FC<{ isRotate?: boolean; width?: number }> = ({ isRotate = false, width }) => (
  <svg
    style={{
      rotate: isRotate ? "180deg" : "0deg"
    }}
    width={width || "12"}
    height={width || "12"}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 2.5L6 9.5M6 9.5L9.5 6M6 9.5L2.5 6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpIcon;
