import React from "react";
import { Collapse, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";

import Expenses from "./expenses/expenses";
import Others from "./others/others";
import Projects from "./projects/projects";

import styles from "./projects/projects.module.scss";

type Props = {
  formInstance: FormInstance;
};

const { Panel } = Collapse;

const Additions: React.FC<Props> = ({ formInstance }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      rootClassName={styles.projects}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel key="1" header={t("payment.Qo'shimcha ma'lumotlar")}>
        <Projects name={["expense_payment", "projects"]} formInstance={formInstance} />
        <Expenses formInstance={formInstance} />
        <Others form={formInstance} />
      </Panel>
    </Collapse>
  );
};

export default Additions;
