import React from "react";

const WIthNDSIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.49935 7.50008H7.50768M12.4993 12.5001H12.5077M13.3327 6.66675L6.66602 13.3334M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334C5.39698 18.3334 1.66602 14.6025 1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675C14.6017 1.66675 18.3327 5.39771 18.3327 10.0001ZM7.91602 7.50008C7.91602 7.7302 7.72947 7.91675 7.49935 7.91675C7.26923 7.91675 7.08268 7.7302 7.08268 7.50008C7.08268 7.26996 7.26923 7.08341 7.49935 7.08341C7.72947 7.08341 7.91602 7.26996 7.91602 7.50008ZM12.916 12.5001C12.916 12.7302 12.7295 12.9167 12.4993 12.9167C12.2692 12.9167 12.0827 12.7302 12.0827 12.5001C12.0827 12.27 12.2692 12.0834 12.4993 12.0834C12.7295 12.0834 12.916 12.27 12.916 12.5001Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default WIthNDSIcon;
