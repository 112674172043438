import React from "react";
import { Spin } from "antd";

import DownloadIcon from "../../assets/icons/DownloadIcon";
import { useGenerateTemplate } from "../../../supply/service/mutations";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import { CustomFieldLocationEnum } from "../../utils/enums/customFieldLocationEnum";
import { useTranslation } from "react-i18next";

export type Props = {
  location: CustomFieldLocationEnum;
  object_id: number;
  styles?: Record<string, string>;
};

const TemplateDownload: React.FC<Props> = ({ location, object_id }) => {
  const generateTemplateMutation = useGenerateTemplate();
  const { t } = useTranslation()

  const onDownloadPartyTemplate = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();

    generateTemplateMutation.mutateAsync({
      object_id: object_id,
      location: location,
    });
  };
  return (
    <Spin spinning={generateTemplateMutation.isLoading} indicator={LoadingIndicator}>
      <div className="d_f ai_c cg_8" onClick={(e) => onDownloadPartyTemplate(e)}>
        <DownloadIcon /> {t("TemplateDownload.Yuklab olish")}
      </div>
    </Spin>
  );
};

export default TemplateDownload;
