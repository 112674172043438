import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Button, message, Modal, Switch } from "antd";
import dayjs from "dayjs";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import { useProductOfferCreate } from "features/settings/service/mutations";
import { ProductModel } from "features/settings/utils/models/product/productModel";
import { DragDropContext, DraggableLocation, DragStart, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { replacePhone } from "../../../../app/utils/helpers/replacePhone";
import { paymentQueryKeys } from "../../../../payment/utils/constants/paymentQueryKeys";
import { useCreateOffer, useUpdateOffer } from "../../../service/mutations";
import { supplyQueryNames } from "../../../utils/constants/supplyQueryNames";
import { OfferErrorModel, OfferFormProductModel, WarehouseProductOffers } from "../../../utils/models/OfferFormModel";
import { OfferProductReqBodyModel, OfferReqBodyModel } from "../../../utils/models/OfferReqBodyModel";

import OfferProduct from "./offer-product/OfferProduct";
import ConfirmPartyModal from "./party-modal/ConfirmPartyModal";

import styles from "./offerModal.module.scss";

type InvalidateKey = "party" | "payment";

type Props = {
  zIndex?: number;
  invalidateKey?: InvalidateKey;
};

const OfferModal: React.FC<Props> = ({ invalidateKey, zIndex }) => {
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const { i18n } = useTranslation();
  const createOffer = useCreateOffer();
  const updateOffer = useUpdateOffer();
  const { setSelectOrderProducts } = supplyActions;
  const [dropId, setDropId] = useState<string>();

  const {
    setOfferModal,
    setWarehouseOffers,
    setOfferModalError,
    setOfferIsParty,
    setConfirmPartyModalItem,
    setAddIndexOfferItem
  } = supplyOfferActions;

  const { data, isEdit, isParty, visible, partyModal, warehouseOffers, viewPartySwitch, isCatalog } = useAppSelector(
    state => state.supplyOfferReducer.offerModal
  );

  const { party_id } = useAppSelector(state => state.supplyReducer.partyModal);
  const { orderId } = useAppSelector(state => state.paymentReducer.orderView);
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const productOfferCreate = useProductOfferCreate(+id!);

  const onCancel = () => {
    if (isCatalog) {
      dispatch(
        setOfferModal({
          visible: false,
          data: []
        })
      );
      dispatch(setSelectOrderProducts([]));
    } else {
      dispatch(
        setOfferModal({
          visible: false
        })
      );
      dispatch(setSelectOrderProducts([]));
    }
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(setOfferIsParty(false));
      dispatch(setWarehouseOffers([]));
    }
  };

  const isEmptyOffer = () => {
    let isEmpty = false;

    for (const i of warehouseOffers) {
      if (isEmptyArr(i.offers)) {
        isEmpty = true;
        break;
      }
    }

    return isEmpty;
  };

  const onAfterFinish = () => {
    onCancel();

    dispatch(setOfferIsParty(false));
    dispatch(
      setConfirmPartyModalItem({
        key: "visible",
        value: false as never
      })
    );
    dispatch(setWarehouseOffers([]));
    dispatch(setSelectOrderProducts([]));

    if (invalidateKey) {
      if (invalidateKey === "party") qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]).then();
      if (invalidateKey === "payment") {
        const isCompanyPerson = location.pathname.includes("detailed-counterpart");

        qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, isCompanyPerson], {
          type: "active"
        }).then();
      }
    }
  };

  const onFinish = () => {
    const offerErrors: OfferErrorModel[] = [];
    let isEditing = false;

    const generateOffers = (item: WarehouseProductOffers) => {
      const generateOfferData: OfferProductReqBodyModel[] = [];

      // @ts-ignore
      item?.offers?.forEach(offer => {
        const newOffer = {
          amount: parseLocaledString(String(offer?.amount)),
          phone: replacePhone(offer?.phone ?? ""),
          currency_id: offer?.currency_id,
          description: offer?.description,
          company_person_id: offer?.company_person_id,
          name: offer?.name,
          id: offer?.id,
          vat: offer.vat,
          payment_type_id: offer?.payment_type_id
        };

        if (offer?.company_person_id) {
          // @ts-ignore
          delete newOffer.name;
        } else {
          // @ts-ignore
          delete newOffer.company_person_id;
        }

        if (!offer.id) {
          // @ts-ignore
          delete newOffer.id;
        }

        if (!newOffer.phone) {
          // @ts-ignore
          delete newOffer.phone;
        }

        isEditing = !!offer.id;

        if (offer?.name && offer?.amount) {
          generateOfferData.push(newOffer);
        } else {
          offerErrors.push({
            uniqueId: offer?.uniqueId,
            name: !offer?.name,
            amount: !offer?.amount
          });
        }
      });

      return generateOfferData;
    };

    let reqData: OfferReqBodyModel = {
      warehouse_product_offers: warehouseOffers?.map(item => ({
        warehouse_product_id: item?.id,
        offers: generateOffers(item)
      }))
    };

    const catalogReqData = {
      offers: warehouseOffers
        ?.flatMap(
          item =>
            item.offers?.map(el => ({
              name: el?.name,
              amount: parseLocaledString(String(el?.amount)),
              phone: replacePhone(el?.phone ?? ""),
              currency_id: el?.currency_id,
              description: el?.description,
              company_person_id: el?.company_person_id,
              product_id: (data![0] as unknown as ProductModel)?.id,
              unit_id: (data![0] as unknown as ProductModel)?.unit?.id
            })) as unknown as (Omit<OfferFormProductModel, "id" | "uniqueId" | "ordinalNumber"> & {
              product_id: number;
              unit_id: number;
            })[]
        )
        .filter(offer => offer !== undefined) // Filter out undefined offers
    };

    if (isParty && !isEditing) {
      reqData = {
        ...reqData,
        order: {
          agent_id: partyModal?.agentId,
          delivery_date: partyModal?.deliveryDate
            ? dayjs(partyModal?.deliveryDate).format(dayjsFormats.DATE)
            : undefined,
          payment_date: partyModal?.paymentDate ? dayjs(partyModal?.paymentDate).format(dayjsFormats.DATE) : undefined
        }
      };
    }

    dispatch(setOfferModalError(offerErrors));

    if (isEmptyOffer()) {
      message.info("Kamida bitta taklif kiritilishi zarur").then();
    } else if (!isCatalog) {
      !(offerErrors?.length > 0) &&
        (isEditing
          ? updateOffer.mutateAsync(reqData).then(onAfterFinish)
          : createOffer.mutateAsync(reqData).then(onAfterFinish));
    } else {
      productOfferCreate.mutateAsync(catalogReqData).then(onAfterFinish);
    }
  };

  const onOk = () => {
    if (isParty) {
      const offerErrors: OfferErrorModel[] = [];

      warehouseOffers?.forEach(item => {
        item.offers?.forEach(offer => {
          if (!(offer?.name && offer?.amount)) {
            offerErrors.push({
              uniqueId: offer?.uniqueId,
              name: !offer?.name,
              amount: !offer?.amount
            });
          }
        });
      });

      dispatch(setOfferModalError(offerErrors));

      if (isEmptyOffer()) {
        message.info("Kamida bitta taklif kiritilishi zarur").then();
      } else if (offerErrors?.length === 0) {
        dispatch(
          setConfirmPartyModalItem({
            key: "visible",
            value: true as never
          })
        );
      }
    } else {
      onFinish();
    }
  };

  const onChangeSwitch = (checked: boolean) => {
    dispatch(setOfferIsParty(checked));
  };

  const footer = (
    <div className={styles.footer}>
      <div className={styles.footer__left}>
        {data && data?.length > 1 && (
          <>
            <span>Tanlanganlar: </span>
            <span>{data?.length} ta</span>
          </>
        )}
      </div>
      <div className={styles.footer__right}>
        {!isEdit && viewPartySwitch && (
          <div className={styles.party_switch}>
            <span>Partiya qilib saqlash</span>
            <Switch checked={isParty} onChange={onChangeSwitch} />
          </div>
        )}
        <Button onClick={onCancel}>Yopish</Button>
        <Button
          type="primary"
          onClick={onOk}
          loading={(createOffer.isLoading || updateOffer.isLoading || productOfferCreate.isLoading) && !isParty}
        >
          Saqlash
        </Button>
      </div>
    </div>
  );

  const copy = (source: DraggableLocation, destination: DraggableLocation, draggableId: string) => {
    if (source.droppableId !== destination.droppableId) {
      const findOffer = warehouseOffers
        ?.find(item => item.productKey === source.droppableId)
        ?.offers?.find(item => item.uniqueId === draggableId);

      dispatch(
        setAddIndexOfferItem({
          data: { ...findOffer!, uniqueId: uid(12), id: undefined },
          index: destination?.index,
          productKey: destination.droppableId
        })
      );
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    copy(source, destination, draggableId);
  };

  const onDragStart = (result: DragStart) => {
    setDropId(result.source.droppableId);
  };

  useLayoutEffect(() => {
    dispatch(
      setWarehouseOffers(
        data?.map(item => ({
          warehouse_product: {
            id: item?.product?.id,
            name: item?.product?.name[i18n.language]
          },
          warehouse_resource: {
            color: item?.product?.resource?.color,
            name: item?.product?.resource?.symbol[i18n.language]
          },
          count: item?.quantity,
          unitId: item?.unit?.id,
          date: item?.delivery_date,
          productKey: uid(5),
          offers: item.offers,
          id: item?.id
        })) ?? []
      )
    );
  }, [data, i18n.language]);

  return (
    <Modal
      centered
      open={visible}
      footer={footer}
      onCancel={onCancel}
      title="Taklif berish"
      zIndex={zIndex || 1003}
      afterOpenChange={onAfterOpen}
      className={styles.offer_modal}
    >
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className={styles.products}>
          {warehouseOffers?.map(item => (
            <Droppable
              key={item?.productKey}
              droppableId={item?.productKey}
              isDropDisabled={dropId === item?.productKey}
            >
              {provided => (
                <div ref={provided.innerRef}>
                  <OfferProduct {...item} zIndex={zIndex} key={item.productKey} />
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>
      <ConfirmPartyModal onFinish={onFinish} isLoading={createOffer?.isLoading} />
    </Modal>
  );
};

export default OfferModal;
