import { useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { FileType } from "features/app/utils/enums/fileType";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../hooks/redux";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { rootPaths } from "../../../routes/root/rootPaths";
import { endPoints } from "../../app/utils/constants/endPoints";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { CustomFieldLocationEnum } from "../../app/utils/enums/customFieldLocationEnum";
import $api from "../../app/utils/helpers/axiosInstance";
import { downloadFile } from "../../app/utils/helpers/downloadFile";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { impostQueryKeys } from "../../impost/utils/constants/impostQueryKeys";
import { paymentEndPoints } from "../../payment/utils/constants/paymentEndPoints";
import { paymentQueryKeys } from "../../payment/utils/constants/paymentQueryKeys";
import { PaymentTabKeys } from "../../payment/utils/constants/paymentTabKeys";
import { supplyEndpoints } from "../utils/constants/supplyEndpoints";
import { supplyQueryNames } from "../utils/constants/supplyQueryNames";
import { CompanyPersonPaymentTypeModel } from "../utils/models/companyPersonPaymentTypeModel";
import { OfferReqBodyModel } from "../utils/models/OfferReqBodyModel";
import { OrderReqModel, OrderUpdateReqModel } from "../utils/models/orderReqModel";
import { PartyReqModel } from "../utils/models/partyReqModel";

// export function useCreateOrders() {
//   const qc = useQueryClient();
//   const { i18n } = useTranslation();
//
//   return useMutation<SuccessRes, ErrorRes, CreateProductModel>(
//     async data => {
//       const res = await $api.post(supplyEndpoints.CREATE_ORDER, data);
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: res => {
//         qc.invalidateQueries([supplyQueryNames.ORDERS]);
//         qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

export function useDeleteOrders() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { searchParams, severalSearchParams } = useQueryParams();
  const isCompanyPerson = pathname?.includes(rootPaths.DETAILED_COUNTERPART);
  const { orderId } = useAppSelector(state => state.paymentReducer.orderView);
  const statisticsSearchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);

  return useMutation<SuccessRes, ErrorRes, number[]>(
    async ids => {
      const queryIds = queryString.stringify({ ids }, { arrayFormat: "bracket" });
      const res = await $api.delete(`${supplyEndpoints.WAREHOUSE_PRODUCT_DELETE}?${queryIds}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT_COUNT]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW, searchParams]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, statisticsSearchParams]);

        if (orderId) await qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, orderId, isCompanyPerson]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

// export function useConfirmationOrders() {
//   const qc = useQueryClient();
//   const { i18n } = useTranslation();
//
//   return useMutation<SuccessRes, ErrorRes, CreateProductModel>(
//     async data => {
//       const res = await $api.put(supplyEndpoints.CONFIRMATION_ORDERS, data);
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: res => {
//         qc.invalidateQueries([supplyQueryNames.ORDERS]);
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

// export function useConfirmationAdditional() {
//   const qc = useQueryClient();
//   const { i18n } = useTranslation();
//
//   return useMutation<SuccessRes, ErrorRes, AdditionalConfirmModel>(
//     async data => {
//       const res = await $api.put(supplyEndpoints.ADDITIONAL_CONFIRM, data);
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: res => {
//         qc.invalidateQueries([supplyQueryNames.ADDITIONAL]);
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

// export function useCreateWarehouseProduct() {
//   const qc = useQueryClient();
//   const { i18n } = useTranslation();
//
//   return useMutation<SuccessRes, ErrorRes, CreateProductModel>(
//     async data => {
//       const res = await $api.post(supplyEndpoints.WAREHOUSE_PRODUCT_CREATE, data);
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: res => {
//         qc.invalidateQueries([supplyQueryNames.ORDERS]);
//         qc.invalidateQueries([supplyQueryNames.PROJECTS]);
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

export function useDeletePart() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${supplyEndpoints.DELETE_ORDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([impostQueryKeys.PARTS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useRefundPart() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.put(`${supplyEndpoints.REFUND_ORDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([impostQueryKeys.PARTS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateParty() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { queries, severalSearchParams, searchParams } = useQueryParams();
  const { statistics_type: type } = queries();
  const calendarSearchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);

  return useMutation<SuccessRes, ErrorRes, PartyReqModel>(
    async data => {
      const res = await $api.post(supplyEndpoints.CREATE_ORDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);

        if (pathname.includes(rootPaths.PAYMENT) && type === PaymentTabKeys.CALENDAR) {
          await qc.invalidateQueries([paymentQueryKeys.CALENDAR, calendarSearchParams]);
          await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, searchParams], { type: "active" });
        }

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateParty() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, PartyReqModel>(
    async data => {
      const res = await $api.put(supplyEndpoints.UPDATE_ORDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([impostQueryKeys.PARTS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateOrder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, OrderReqModel>(
    async data => {
      const res = await $api.post(supplyEndpoints.PRODUCT_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT_COUNT]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateOrder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, OrderUpdateReqModel>(
    async data => {
      const res = await $api.put(supplyEndpoints.PRODUCT_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteOffer() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${supplyEndpoints.DELETE_ORDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useOfferProductRecieved() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.put(supplyEndpoints.OFFER_RECIEVED, { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useOfferProductRejected() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number; description?: string }>(
    async data => {
      const res = await $api.put(supplyEndpoints.OFFER_REJECTED, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateOffer() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, OfferReqBodyModel>(
    async data => {
      const res = await $api.post(supplyEndpoints.CREATE_OFFER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT]);
        await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParams]);
        await qc.invalidateQueries([impostQueryKeys.PARTS, searchParams]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateOffer() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, OfferReqBodyModel>(
    async data => {
      const res = await $api.put(supplyEndpoints.UPDATE_OFFER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.OFFERS]);
        await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParams]);
        await qc.invalidateQueries([impostQueryKeys.PARTS, searchParams]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useGetWarehouseProjects() {
  return useMutation<SuccessRes<{ id: number; name: string }[]>, ErrorRes, number | undefined>(
    async id => {
      const res = await $api.get(`${paymentEndPoints.PROJECT_SELECT}?warehouse_id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler
    }
  );
}

export function useOrderRecieved() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number | undefined>(
    async id => {
      const res = await $api.put(supplyEndpoints.RECIEVED_ORDER, { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useOrderRejected() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number | undefined>(
    async id => {
      const res = await $api.put(supplyEndpoints.REJECTED_ORDER, { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([supplyQueryNames.ORDERS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useProductGeneralEditing(isImpost?: boolean) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, Record<string, unknown[]>>(
    async data => {
      const res = await $api.put(supplyEndpoints.PRODUCT_GENERAL_EDITING, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([isImpost ? impostQueryKeys.OFFERS : supplyQueryNames.OFFERS, searchParam]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function usePartyGeneralEditing(isPayment?: boolean) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams, searchParams: allSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, Record<string, unknown[]>>(
    async data => {
      const res = await $api.put(supplyEndpoints.PARTY_GENERAL_EDITING, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([
          isPayment ? paymentQueryKeys.PAYMENTS_ORDER_VIEW : supplyQueryNames.ORDERS,
          isPayment ? allSearchParams : searchParam
        ]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useOrderProductsGeneralEditing() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  return useMutation<SuccessRes, ErrorRes, Record<string, unknown[]>>(
    async data => {
      const res = await $api.put(supplyEndpoints.PRODUCT_GENERAL_EDITING, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries([supplyQueryNames.WAREHOUSE_PRODUCT, searchParam]);
      }
    }
  );
}

export function useGenerateTemplate() {
  return useMutation(
    async (data: { object_id: number; location: CustomFieldLocationEnum }) => {
      const res = await $api.post(endPoints.TEMPLATE_GENERATE, data, {
        responseType: "blob"
      });

      return res.data;
    },
    {
      onSuccess: data => {
        if (data) {
          downloadFile(data, "Template");
        } else {
          message.warning("Fayl mavjud emas");
        }
      },
      onError: errorHandler
    }
  );
}

export function useGetPartyExcelFile() {
  const { i18n } = useTranslation();

  return useMutation(
    async body => {
      const res = await $api.post(supplyEndpoints.PARTY_EXCEL, body, {
        responseType: "blob"
      });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Ta'minot-partiyasi", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useSupplyProductUpdate() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams, searchParams: allSearchParams } = useQueryParams();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);
  const statisticsSearchParam = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  const { party_id } = useAppSelector(state => state.supplyReducer.partyModal);

  return useMutation<SuccessRes, ErrorRes, Record<string, string | number>>(
    async data => {
      const res = await $api.put(supplyEndpoints.SUPPLY_PRODUCT_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([impostQueryKeys.PARTS, searchParam]);
        await qc.invalidateQueries([supplyQueryNames.ORDERS, searchParam]);
        await qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW, allSearchParams]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, statisticsSearchParam]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useExportSupplyOrdersExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: IExportExcelParam) => {
      const res = await $api.post(
        `${supplyEndpoints.SUPPLY_ORDERS_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: response => {
        const data = response?.data;

        downloadFile(data, "Supply-orders", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useCreateCompanyPersonPaymentType() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const isCounterpart = pathname?.includes(rootPaths.DETAILED_COUNTERPART);

  return useMutation<SuccessRes, ErrorRes, CompanyPersonPaymentTypeModel>(
    async data => {
      const res = await $api.post(supplyEndpoints.COMPANY_PERSON_PAYMENT_TYPE_CREATE, data);

      return res.data;
    },
    {
      onSuccess: async (res, variables) => {
        await qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, variables?.order_id]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW, variables?.order_id, Boolean(isCounterpart)]);

        message.success(res.message?.[i18n.language]);
      }
    }
  );
}
