import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys, Widget } from "..";

type Query = {
  widgetsSelect: Widget[];
};

interface Params extends Pick<UseQueryOptions, "enabled"> {
  isRecommended?: boolean;
}

export function useWidgetSelect({ enabled, isRecommended }: Params) {
  const initialData: Query = { widgetsSelect: [] };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.WIDGET_SELECT],
    async () => {
      const { data } = await $api.get("widget/select");

      // const baseWidgetsSelect = data.data?.map((item: Widget) => widgetSchema.parse(item));

      return {
        widgetsSelect: isRecommended ? data.data?.filter((item: Widget) => item?.recommended) : data.data
      };
    },
    {
      enabled
    }
  );

  return { ...data, ...arg };
}
