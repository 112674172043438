import { useRef, useEffect, useCallback, MutableRefObject } from "react";

const useDebounce = <T extends (...args: any[]) => void>(callback: T, delay: number,timeoutRef:MutableRefObject<NodeJS.Timeout | null>) => {
  // const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const debouncedCallback = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      callback();
    }, delay);
  };

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  return debouncedCallback;
};

export default useDebounce;
