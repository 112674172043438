import { useState } from "react";
import { Spin, Upload, UploadFile, UploadProps } from "antd";
import classNames from "classnames";

import UploadIcon from "../../../../../app/assets/icons/UploadIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { useUploadFile } from "../../../../../app/service/mutation";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { CustomFieldProps } from "../EstimateCustomFieldsContent";

import styles from "./fileCustomField.module.scss";

const FileCustomField = ({ disabled, onChange, fileName, onBlur }: CustomFieldProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const uploadFile = useUploadFile();

  const props: UploadProps = {
    onRemove: () => {
      setFileList([]);
      onChange && onChange("");
    },
    beforeUpload: file => {
      const formData = new FormData();

      formData.append("files[]", file as Blob);

      uploadFile.mutateAsync(formData).then(res => {
        onChange && onChange(String(res[0]));
        onBlur && onBlur();
      });
      return false;
    },
    fileList
  };

  return (
    <>
      {fileName || (
        <Upload
          {...props}
          className={classNames(styles.upload, { [styles.disabled]: disabled })}
          disabled={disabled}
          listType="picture"
        >
          <Spin spinning={uploadFile?.isLoading} indicator={LoadingIndicator}>
            {(isEmptyArr(fileList) || uploadFile?.isLoading) && (
              <div className={styles.upload_contents}>
                <UploadIcon />
                <span>Yuklang</span>{" "}
              </div>
            )}
          </Spin>
        </Upload>
      )}
    </>
  );
};

export default FileCustomField;
