import React from "react";

export const TrendDownIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83398 14.1667L14.1673 5.83341M14.1673 5.83341H5.83398M14.1673 5.83341V14.1667"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TrendDownIcon;
