import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Form, FormInstance, Popover } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import ArrowNarrowIcon from "../../../../../app/assets/icons/ArrowNarrowIcon";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import ModalContentCustomFields from "../../../../../app/components/modal-content-custom-fields/ModalContentCustomFields";
import { useGetCustomFieldSelect } from "../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryKeys } from "../../../../../app/utils/constants/queryKeys";
import { UnitModel } from "../../../../../app/utils/constants/unitModel";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import CounterpartsModal from "../../../../../counterparts/components/counterparts/modal/CounterpartsModal";
import SettingsAdminsModal from "../../../../../settings/components/admins/admins/modal/SettingsAdminsModal";
import { useGetCurrencies } from "../../../../../settings/service/queries";
import CodepenIcon from "../../../../../warehouse/assets/icons/CodepenIcon";
import { generatePersonToWarehouseProduct } from "../../../../utils/helpers/generateProductsData";
import { generateTotalAmount } from "../../../../utils/helpers/generateTotalAmount";
import { PartyFormPersonGroupModel, PartyFormProductsModel } from "../../../../utils/models/partyModalFormModel";

import PartyAgentSelect from "./selects/PartyAgentSelect";

import styles from "./partyModalRight.module.scss";

const { Item, useWatch } = Form;

const PartyModalRight: React.FC<{ form: FormInstance }> = ({ form }) => {
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { setPartyEstimate } = supplyActions;

  const { data: currencies } = useGetCurrencies();
  const { isView, visible } = useAppSelector(state => state.supplyReducer.partyModal);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  const isPersonGroup = useWatch("is_group_person", form) as boolean;
  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const personGroup = useWatch("person_group", form) as PartyFormPersonGroupModel[];
  const unitData: UnitModel[] | undefined = qc.getQueryData([queryKeys.UNITS]);

  // const totalAmount = useMemo(
  //   () =>
  //     (isPersonGroup ? generatePersonToWarehouseProduct(personGroup) : products)?.reduce(
  //       (first, second) =>
  //         first +
  //         (parseLocaledString(second?.amount ? String(second?.amount) : "0") *
  //           parseLocaledString(second?.quantity ? String(second?.quantity) : "0") ?? 0),
  //       0
  //     ),
  //   [products, isPersonGroup, personGroup]
  // );

  const sortOrder =
    (isPersonGroup ? generatePersonToWarehouseProduct(personGroup) : products)?.reduce(
      (prev: { [key: string]: number }, current) => {
        const findUnit = unitData?.find(unit => unit?.id === current?.unit_id);

        if (findUnit?.name[i18n.language]) {
          prev[findUnit?.name[i18n.language]] =
            (prev[findUnit?.name[i18n.language]] ?? 0) + parseLocaledString(String(current?.quantity ?? 0));
        }
        return prev;
      },
      {}
    ) || {};

  const sortOrderKeys = Object.keys(sortOrder);

  const allUnitText = useMemo(
    () => (
      <div className={styles.units}>
        {sortOrderKeys?.map((key, index) => (
          <div className={styles.units__item} key={index}>
            <p>{sortOrder[key]}</p>
            <span>{key}</span>
            {index + 1 !== sortOrderKeys?.length && <p>,</p>}
          </div>
        ))}
      </div>
    ),
    [sortOrderKeys]
  );

  const onOpenEstimate = () => {
    dispatch(
      setPartyEstimate({
        visible: true
      })
    );
  };

  const totalElemClass = `${styles.total} ${
    (isPersonGroup ? isEmptyArr(generatePersonToWarehouseProduct(personGroup)) : isEmptyArr(products))
      ? styles.total__disabled
      : styles.total__active
  }`;

  return (
    <div className={styles.right}>
      <div
        className={classNames({
          [styles.right__top]: !isView,
          [styles.not_view]: isView
        })}
      >
        <PartyAgentSelect form={form} />
        <Item name="custom_field_values" className="hidden" />
        <Item label={t("partyModal.Yetkazish sanasi")} name="delivery_date">
          <DatePicker
            disabled={isView}
            placeholder={isView ? t("partyModal.Tanlanmagan") : t("partyModal.Tanlang")}
            format={dayjsFormats.DATE}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        <Item label={t("partyModal.To'lash sanasi")} name="payment_date">
          <DatePicker
            disabled={isView}
            placeholder={isView ? t("partyModal.Tanlanmagan") : t("partyModal.Tanlang")}
            format={dayjsFormats.DATE}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        {!isEmptyArr(customFields) && (
          <ModalContentCustomFields
            form={form}
            isView={isView}
            disabled={isView}
            visible={visible}
            customFields={customFields}
          />
        )}
      </div>
      <div className={styles.right__bottom}>
        {!isView && (
          <div className={styles.card} onClick={onOpenEstimate}>
            <div className={styles.card__left}>
              <div className={styles.icon}>
                <CodepenIcon />
              </div>
              <h4>{t("partyModal.Smeta bo’yicha keltirilishi kerak resurslar")}</h4>
            </div>
            <div className={styles.card__right}>
              <ArrowNarrowIcon rotate={180} />
            </div>
          </div>
        )}
        <div className={totalElemClass}>
          <div className={styles.total__item}>
            <h4>{t("partyModal.Mahsulotlar")}:</h4>
            <Popover title={allUnitText} placement="left">
              <p>
                {sortOrderKeys?.length ?? 0} {t("partyModal.birlik")}
              </p>
            </Popover>
          </div>
          <div className={styles.total__item}>
            <h4>{t("partyModal.Summa")}:</h4>
            <div className={cx("flex flex-wrap gap-1", styles.total_currency)}>
              {currencies?.data?.map(currency => {
                const amount = generateTotalAmount(
                  isPersonGroup ? generatePersonToWarehouseProduct(personGroup) : products
                )?.[String(currency?.id)];

                return amount >= 0 ? (
                  <>
                    <p className="flex items-center gap-1">
                      <span>{amount?.toLocaleString(RU)}</span>
                      <span>{currency?.symbol}</span>
                    </p>
                    <p>,</p>
                  </>
                ) : null;
              })}
              {/* {totalAmount ? totalAmount?.toLocaleString(RU) : 0} {currency?.symbol} */}
            </div>
          </div>
        </div>
      </div>
      <CounterpartsModal />
      <SettingsAdminsModal />
    </div>
  );
};

export default PartyModalRight;
