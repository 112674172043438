import { colors } from "features/app/utils/constants/colors";

type Props = {
  rotate?: number;
  stroke?: string;
  width?: number;
};

const ArrowDownIcon = ({ rotate, stroke = colors.GRAY_600, width = 16 }: Props) => (
  <svg
    style={{
      transform: `rotate(${rotate ?? 0}deg)`,
    }}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99992 3.3335V12.6668M7.99992 12.6668L12.6666 8.00016M7.99992 12.6668L3.33325 8.00016"
      stroke={stroke}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowDownIcon;
