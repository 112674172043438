import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { AdminModel } from "../../features/settings/utils/models/admin/adminModel";
import { WarehouseModel } from "../../features/settings/utils/models/warehouse/warehouseModel";
import { SettingsStateModel } from "../types/SettingsReducerModel";

const initialState: SettingsStateModel = {
  admin: {
    visible: false,
    full_name: ""
  },
  warehouse: {
    visible: false,
    data: undefined,
    name: ""
  },
  customField: {
    visible: false
  },
  template: {
    visible: false
  },
  categoriesProducts: {
    visible: false
  },
  vat: {
    visible: false
  }
};

const settingsSlice = createSlice({
  name: sliceNames.SETTINGS,
  initialState,
  reducers: {
    setAdminModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        data?: AdminModel;
        isEdited?: boolean;
        initialName?: string;
        afterFunc?: (id: number) => void;
        full_name?: string;
      }>
    ) => {
      state.admin.data = action.payload.data;
      state.admin.visible = action.payload.visible;
      state.admin.isEdited = action.payload.isEdited;
      state.admin.afterFunc = action.payload.afterFunc;
      state.admin.initialName = action.payload.initialName;
      state.admin.full_name = action.payload.full_name;
    },
    setWarehouseModal: (
      state,
      action: PayloadAction<{
        visible: boolean;
        data?: WarehouseModel;
        name?: string;
      }>
    ) => {
      state.warehouse.visible = action.payload.visible;
      state.warehouse.data = action.payload.data;
      state.warehouse.name = action.payload.name;
    },
    setCustomFieldModal: (state, action: PayloadAction<{ visible: boolean; id?: number }>) => {
      state.customField.visible = action.payload.visible;
      state.customField.id = action.payload.id;
    },
    setTemplateModal: (state, action: PayloadAction<{ visible: boolean; id?: number }>) => {
      state.template.visible = action.payload.visible;
      state.template.id = action.payload.id;
    },
    setCategoriesProductsModal: (
      state,
      action: PayloadAction<{ visible: boolean; folderId?: number; folderName?: string; subCategoryId?: number }>
    ) => {
      state.categoriesProducts.visible = action.payload.visible;
      state.categoriesProducts.folderId = action.payload.folderId;
      state.categoriesProducts.subCategoryId = action.payload.subCategoryId;
      state.categoriesProducts.folderName = action.payload.folderName;
    },
    setVat: (state, action: PayloadAction<SettingsStateModel["vat"]>) => {
      state.vat = action.payload;
    }
  }
});

export const settingsActions = settingsSlice.actions;
export default settingsSlice.reducer;
