import React, { ReactNode } from "react";
import { Button } from "antd";

import styles from "./notData.module.scss";


type Props = {
  containerStyle?: React.CSSProperties | undefined;
  style?: React.CSSProperties | undefined;
  containerClassName?: string;
  className?: string;
  contentWidth?: number;
  img?: {
    src?: string;
    className?: string;
  };
  name?: {
    text?: string;
    className?: string;
  };
  text?: {
    text?: string;
    className?: string;
  };
  defaultButton?: {
    text?: string;
    icon?: ReactNode | string;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
  };
  primaryButton?: {
    text?: string;
    icon?: ReactNode | string;
    className?: string;
    onClick?: () => void;
    loading?: boolean;
  };
}

const NotData = (props: Props) => {
  return (
    <div
      className={`${styles.container} ${props?.containerClassName || ""}`}
      style={props?.containerStyle}
    >
      <div
        className={`${styles.content} ${props?.className || ""}`}
        style={{
          ...props?.style,
          width: props?.contentWidth ? props?.contentWidth : 360,
        }}
      >
        {props?.img && (
          <img
            src={props?.img?.src}
            className={props?.img?.className || ""}
            alt=""
          />
        )}
        {props?.name && (
          <h3 className={props?.name?.className || ""}>{props?.name?.text}</h3>
        )}
        {props?.text && (
          <p className={props?.text?.className || ""}>{props?.text?.text}</p>
        )}
        {(props?.defaultButton || props?.primaryButton) && (
          <div className={styles.footer}>
            {props?.defaultButton && (
              <Button
                onClick={props?.defaultButton?.onClick}
                className={`${styles.defaultButton} ${
                  props?.defaultButton?.className || ""
                }`}
                loading={props?.defaultButton?.loading}
              >
                {props?.defaultButton?.icon} {props?.defaultButton?.text}
              </Button>
            )}
            {props?.primaryButton && (
              <Button
                type="primary"
                onClick={props?.primaryButton?.onClick}
                className={props?.primaryButton?.className || ""}
                loading={props?.primaryButton?.loading}
              >
                {props?.primaryButton?.icon} {props?.primaryButton?.text}
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotData;
