import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetProjectSelect } from "../../../../../payment/service/queries";
import PartyPrintCard from "../../../../../supply/components/parts/party-modal/party-print/PartyPrintCard";
import {
  PartyPrintDataModel,
  PartyPrintGenerateDataModel
} from "../../../../../supply/utils/models/PartyPrintDataModel";
import { useGetWarehouseSelect } from "../../../../service/query";
import { OneOrderModel } from "../../../../utils/models/oneOrderModel";
import { WarehouseOrderModel } from "../../../../utils/models/warehouseOrderModel";

import PrintWarehousePartyTable from "./PrintWarehousePartyTable";

import styles from "../../../../../supply/components/parts/party-modal/party-print/partyPrint.module.scss";

type Props = {
  partyId?: number;
  isPayment?: boolean;
  onePaymentOrder?: WarehouseOrderModel;
  data?: OneOrderModel[];
};

interface INameAndId {
  id: number;
  name: string;
}

interface IOldPartyPrintData extends Omit<PartyPrintGenerateDataModel, "sameProject" | "sameWarehouse"> {
  project?: INameAndId;
  warehouse?: INameAndId;
}

const PrintWarehouseParty = forwardRef<HTMLDivElement | null, Props>(
  ({ data, partyId, isPayment, onePaymentOrder }, ref) => {
    const { i18n } = useTranslation();
    const { data: projects } = useGetProjectSelect({ enabled: true });
    const { data: warehouses } = useGetWarehouseSelect(true, true);

    const { data: order } = useAppSelector(state => state.warehouseOrderReducer.view);

    const tableData = useMemo(() => {
      const result: IOldPartyPrintData = {
        data: []
      };

      let warehouse: INameAndId | undefined = data?.[0]?.warehouse as INameAndId;
      let project: INameAndId | undefined = data?.[0]?.project as INameAndId;

      const generateResultData = (id: number) =>
        data
          ?.filter(item => item.company_person?.id === id)
          ?.map(item => ({
            id: item?.id,
            productName: item?.product?.name[i18n.language],
            unitName: item?.unit?.symbol[i18n.language],
            quantity: item?.quantity?.toLocaleString(RU),
            amount: item?.amount?.toLocaleString(RU),
            projectId: item?.project?.id,
            warehouseId: item?.warehouse?.id,
            total: item?.total_amount
          })) as PartyPrintDataModel;

      data?.forEach(item => {
        const isSomeCompanyPerson = result?.data?.some(c => c?.id === item?.company_person?.id);

        warehouse = warehouse?.id === item.warehouse?.id ? warehouse : undefined;

        project = project?.id === item.project?.id ? project : undefined;

        !isSomeCompanyPerson &&
          result.data.push({
            id: item?.company_person?.id,
            name: item?.company_person?.name,
            data: generateResultData(item?.company_person?.id) as never
          });
      });

      return {
        ...result,
        sameProject: project?.name,
        sameWarehouse: warehouse?.name
      };
    }, [data, i18n.language]);

    return (
      <div className={styles.party_print} ref={ref}>
        <PartyPrintCard
          partyId={partyId}
          sameProject={tableData?.sameProject}
          sameWarehouse={tableData?.sameWarehouse}
          oneOrder={isPayment ? (onePaymentOrder as never) : (order as never)}
        />
        <PrintWarehousePartyTable
          data={tableData}
          projects={projects}
          warehouses={warehouses}
          totalPrice={isPayment ? onePaymentOrder?.amount : order?.amount}
        />
      </div>
    );
  }
);

export default PrintWarehouseParty;
