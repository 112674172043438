import { Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../../app/utils/constants/languages";
import { CurrencyTypeEnum } from "../../../../../../app/utils/enums/currencyTypeEnum";
import { useGetPaymentOrderRejectAdditional } from "../../../../../service/queries";

import styles from "./excessView.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId?: number;
};

const ExcessView = ({ open, setOpen, orderId }: Props) => {
  const { i18n } = useTranslation();
  const { currencies } = useAuth();
  const basicCurrency = currencies.find(item => item?.type === CurrencyTypeEnum.BASE);

  const { data: excesses, isLoading } = useGetPaymentOrderRejectAdditional(open, orderId);

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      centered
      onCancel={onCancel}
      open={open}
      footer={false}
      title="Kamomatli resurslar"
      closeIcon={false}
      width={545}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.excess_view}>
          {excesses?.map(excess => (
            <div className={styles.excess} key={excess.id}>
              <div className={styles.name}>
                <div className="resource">
                  <div
                    style={{
                      borderColor: excess?.product?.resource?.color,
                      color: excess?.product?.resource?.color
                    }}
                  >
                    {excess?.product.resource?.symbol[i18n.language]}
                  </div>
                </div>
                <div>
                  <p>{excess.product.name[i18n.language]}</p>
                  <p>{excess.warehouse.name}</p>
                </div>
              </div>
              <div className={styles.amount}>
                <p>
                  {excess.initial_quantity.toLocaleString(RU)} {excess.unit.symbol[i18n.language]}
                </p>
                <p>
                  {excess.total_amount.toLocaleString(RU)} {basicCurrency?.symbol}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </Modal>
  );
};

export default ExcessView;
