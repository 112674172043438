/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Dropdown, MenuProps, Spin } from "antd";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../assets/icons/DeleteIcon";
import EditIcon from "../../assets/icons/EditIcon";
import FolderIcon from "../../assets/icons/FolderIcon";
import { useGetLanguages } from "../../service/queries";
import { menuColors } from "../../utils/constants/menuColors";
import { ErrorRes, SuccessRes } from "../../utils/models/responseType";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import Editable from "./editable/Editable";

import styles from "./folderCard.module.scss";

type Props = {
  id?: number;
  name?: string;
  count?: number;
  color?: string;
  queryName?: string;
  isCreating?: boolean;
  placeholder?: string;
  sliceTextCount?: number;
  isReqNameObject?: boolean;
  path?: string | Record<string, string | undefined>;
  deleteBody?: { [key: string]: string | number | undefined };
  createBody?: { [key: string]: string | number | undefined };
  setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
  updateMutation?: UseMutationResult<SuccessRes, ErrorRes, any>;
  createMutation?: UseMutationResult<SuccessRes, ErrorRes, any>;
  deleteMutation?: UseMutationResult<SuccessRes, ErrorRes, any>;
};

const FolderCard: React.FC<Props> = ({
  id,
  path,
  name,
  count,
  color,
  queryName,
  deleteBody,
  createBody,
  isCreating,
  placeholder,
  setIsCreating,
  sliceTextCount,
  createMutation,
  updateMutation,
  deleteMutation,
  isReqNameObject
}) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(isCreating));

  const { data: languages } = useGetLanguages(isReqNameObject);

  const onChangeColor = (color: string) => {
    setLoading(true);
    updateMutation?.mutateAsync({ color, id }).finally(() => setLoading(false));
  };

  const onOpenEdit = () => {
    setIsEditing(true);
  };

  const onDelete = () => {
    setLoading(true);

    deleteMutation?.mutateAsync(deleteBody || id!).finally(() => setLoading(false));
  };

  const toNextPage = () => {
    if (!isEditing) path && navigate(path);
  };

  const reqNameObject = (value: string) => {
    let names = {};

    languages?.forEach(lang => {
      names = Object.assign(names, { [lang.symbol]: value });
    });

    return names;
  };

  const onFinish = (values: { name: string }) => {
    setLoading(true);

    if (id) {
      updateMutation &&
        updateMutation
          .mutateAsync({
            id,
            name: isReqNameObject ? reqNameObject(values.name) : values.name
          })
          .finally(() => {
            setLoading(false);
            queryName && qc.invalidateQueries([queryName]);
          });
    } else {
      let reqBody = {
        name: isReqNameObject ? reqNameObject(values.name) : values.name
      };

      if (createBody) reqBody = Object.assign(reqBody, createBody);

      createMutation &&
        createMutation.mutateAsync(reqBody).finally(() => {
          setLoading(false);
          setIsEditing(false);
          setIsCreating && setIsCreating(false);
          queryName && qc.invalidateQueries([queryName]);
        });
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className={styles.colors}>
          {menuColors.map((color, index) => (
            <span
              key={index}
              className={styles.item}
              onClick={() => onChangeColor(color.background)}
              style={{
                background: color.border
              }}
            >
              <span
                style={{
                  background: color.background
                }}
              />
            </span>
          ))}
        </div>
      )
    },
    {
      key: "2",
      label: (
        <div className={styles.update}>
          <EditIcon /> <span>{t("products.Tahrirlash")}</span>
        </div>
      ),
      onClick: onOpenEdit
    },
    {
      key: "3",
      label: (
        <div className={styles.delete}>
          <DeleteIcon />
          <span>{t("products.O'chirish")}</span>
        </div>
      ),
      onClick: onDelete
    }
  ];

  return (
    <Spin spinning={loading} indicator={LoadingIndicator}>
      <Dropdown menu={{ items }} trigger={["contextMenu"]}>
        <div className={styles.folder_card} onClick={toNextPage}>
          <FolderIcon color={color} />
          <div className={styles.right}>
            <Editable
              text={name}
              isEdit={isEditing}
              onFinish={onFinish}
              setEdit={setIsEditing}
              isCreating={isCreating}
              setIsCreating={setIsCreating}
              sliceTextCount={sliceTextCount}
            />
            {!isCreating && (
              <p>
                {count ?? 0} {placeholder ?? "kontragent"}
              </p>
            )}
          </div>
        </div>
      </Dropdown>
    </Spin>
  );
};

export default FolderCard;
