import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const companyPersonTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "name",
      name: "name",
      title: "Nomi",
      checked: true
    },
    {
      id: "phone",
      name: "phone",
      title: "Telefon raqam",
      checked: true
    },
    {
      id: "tin",
      name: "tin",
      title: "INN",
      checked: true
    },
    {
      id: "address",
      name: "address",
      title: "Manzil",
      checked: false
    },
    {
      id: "total_amount",
      name: "total_amount",
      title: "Umummiy balans",
      checked: false
    },
    {
      id: "balances",
      name: "balances",
      title: "Balanslar",
      checked: false
    },
    {
      id: "debt_amount",
      name: "debt_amount",
      title: "Debit",
      checked: true
    },
    {
      id: "credit_amount",
      name: "credit_amount",
      title: "Kredit",
      checked: true
    }
  ],
  size: 10
};

export const companyPersonTableDefaultDataExportExcel: UserTableDataModel = {
  column: [
    {
      id: "name",
      name: "name",
      title: "Nomi",
      checked: true
    },
    {
      id: "person.phone",
      name: "person.phone",
      title: "Telefon raqam",
      checked: true
    },

    {
      id: "total_amount",
      name: "total_amount",
      title: "Umummiy balans",
      checked: false
    },

    {
      id: "debt_amount",
      name: "debt_amount",
      title: "Debitor",
      checked: true
    }
  ],
  size: 10
};
