import React, { useContext } from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import i18n from "i18next";

import { useLanguagesSelect } from "modules/common/queries";

import { WidgetContext } from "../../context";
import { useCreateWidget } from "../../mutations";
import { CreateWidgetForm, createWidgetFormSchema } from "../schema";

type Props = {
  children: (props: UseFormReturn<CreateWidgetForm>) => React.ReactNode;
};

export const Create: React.FC<Props> = ({ children }) => {
  const { widget, methods } = useContext(WidgetContext);
  const { languages } = useLanguagesSelect();
  const form = useForm<CreateWidgetForm>({
    resolver: zodResolver(createWidgetFormSchema),
    defaultValues: {
      name: widget?.name[i18n.language],
      id: widget?.id,
      user_ids: []
    }
  });

  const mutation = useCreateWidget();

  const onSubmit = async (values: CreateWidgetForm) => {
    await new Promise(onSettled => {
      const { id, ...rest } = values;

      const body = { ...rest };

      let generateName: Record<string, string> = {};

      let generateTitle: Record<string, string> = {};

      languages?.forEach(lang => {
        generateName = {
          ...generateName,
          [lang.symbol]: values.name
        };
        generateTitle = {
          ...generateTitle,
          [lang.symbol]: values.title!
        };
      });

      mutation.mutate(
        { ...body, name: generateName, widget_id: values.id, title: generateTitle },
        {
          onSettled,
          onSuccess: () => {
            methods.setWidgetModal({
              visibleWidgetModal: false,
              isUpdateWidget: false,
              widget: widget!
            });
          }
        }
      );
    });
  };

  const onError = (error: FieldErrors) => {
    throw new Error(error.root?.message);
  };

  return <form onSubmit={form.handleSubmit(onSubmit, onError)}>{children(form)}</form>;
};

export default Create;
