export const endpoints = {
  PROJECT_VIEW: "/project/view",
  PROJECT_CREATE: "/project/create",
  PROJECT_UPDATE: "/project/update",
  PROJECT_DELETE: "/project/delete",
  PROJECT_FOLDER_VIEW: "/project-folder/view",
  PROJECT_FOLDER_CREATE: "/project-folder/create",
  PROJECT_FOLDER_UPDATE: "/project-folder/update",
  PROJECT_FOLDER_DELETE: "/project-folder/delete",
  SECTION_VIEW: "/section/view",
  SECTION_CREATE: "/section/create",
  SECTION_UPDATE: "/section/update",
  SECTION_DELETE: "/section/delete",
  TASK_VIEW: "task/view",
  TASK_CREATE: "/task/create",
  TASK_UPDATE: "/task/update",
  TASK_DELETE: "/task/delete",
  WORK_VIEW: "work/view",
  TASK_PROGRESS: "work/task-progress",
  TASK_PROGRESS_DETAIL: "work/task-progress-detail",
  WORK_STATISTICS: "work/statistics",
  WORK_PROGRESS: "work/progress",
  WORK_PROGRESS_RESOURCE: "work/progress/resource",
  UPDATE_WORK: "work/update",
  WORK_SELECT: "work/select",
  TASK_PRODUCTS: "task-product/view",
  CREATE_PRODUCT: "task-product/create",
  UPDATE_PRODUCT: "task-product/update",
  DELETE_PRODUCT: "task-product/delete",
  SECTION_IMPORT_DATA: "section/import-data",
  DYNAMIC_ESTIMATE_SECTION: "dynamic/section-view",
  DYNAMIC_ESTIMATE_TASK: "dynamic/task-view",
  DYNAMIC_ESTIMATE_PRODUCT: "dynamic/task-product-view",
  DYNAMIC_ESTIMATE_TASK_PROGRESS: "dynamic/task-progress-detail-view",
  SECTIONS_STATISTICS: "section/statistics",
  GENERAL_SEARCH_SECTIONS: "section/general-search",
  GROUP_CUSTOM_FIELD: "section/group-custom-field",
  DYNAMIC_CUSTOM_FIELD: "dynamic/group-custom-field",
  TASK_SELECT: "task/select",
  PROJECT_COPY: "project/copy",
  PROJECT_REPORT_AMOUNT: "project-report/amount",
  PROJECT_REPORT_CASH_FLOW: "project-report/cash-flow",
  PROJECT_REPORT_PRODUCT_SPEND: "project-report/product-spend",
  SECTIONS_SELECT: "section/select?collection_type=paginate"
} as const;
