import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";

import { PaymentTabKeys } from "./paymentTabKeys";

export const paymnetInfoData = {
  [PaymentTabKeys.INCOME_EXPENSE]: {
    name: "Kassa",
    label: [
      { name: "Umumiy balans", key: "total_amount" },
      { name: "Kirim", key: "total_income_amount" },
      { name: "Chiqim", key: "total_expense_amount" }
    ]
  },
  [`${PaymentTabKeys.PAYMENT}-${PaymentTypeEnum.INCOME}`]: {
    name: "Kirim shartnomalar",
    label: [
      { name: "Rejadagi to’lovlar", key: "total_amount" },
      { name: "Kutilayotgan to'lovlar", key: "total_expected_amount" },
      { name: "Undirilgan to'lovlar", key: "total_collected_amount" },
      { name: "Vaqti o'tgan to'lovlar", key: "total_expired_amount" }
    ]
  },
  [`${PaymentTabKeys.PAYMENT}-${PaymentTypeEnum.EXPENSE}`]: {
    name: "Chiqim shartnomalar",
    label: [
      { name: "Rejadagi qarzlar", key: "total_amount" },
      { name: "To’lanishi kerak qarzlar", key: "total_expected_amount" },
      { name: "Kechiktirilgan qarzlar", key: "total_expired_amount" },
      { name: "Qoplangan qarzlar", key: "total_collected_amount" }
    ]
  },
  [PaymentTabKeys.ORDER]: {
    name: "Partiyalar uchun to’lovlar",
    label: [
      { name: "Umumiy partiya", key: "total_amount" },
      { name: "Partiyaga to'langan", key: "total_paid_amount" },
      { name: "To'lanishi kerak", key: "total_should_pay_amount" },
      { name: "To’lov kechiktirilgan", key: "total_delayed_amount" }
    ]
  },
  [PaymentTabKeys.WORK]: {
    name: "Ishlar uchun to’lovlar",
    label: [
      {
        name: "Umumiy ishlar summasi",
        key: "total_amount"
      },
      {
        name: "To’langan summa",
        key: "total_paid_amount"
      },
      {
        name: "To’lanishi kerak bo’lgan summa",
        key: "total_debt_amount"
      }
    ]
  },
  [PaymentTabKeys.INVENTORY]: {
    name: "Inventarlarga to’lovlar",
    label: [
      {
        name: "Umumiy inventar summasi",
        key: "total_amount"
      },
      {
        name: "Inventar uchun to’langan summa",
        key: "total_paid_amount"
      },
      {
        name: "To’lanishi kerak bo’lgan summa",
        key: "total_should_pay_amount"
      },
      {
        name: "Inventarlar xarajatlari",
        key: "total_expense_amount"
      }
    ]
  },
  // [PaymentTabKeys.RESOURCE]: {
  //   name: "Resursga to'lovlar",
  //   label: [
  //     "Umumiy ishlar summasi",
  //     "To’langan summa",
  //     "To’lanishi kerak bo’lgan summa",
  //   ],
  // },
  [PaymentTabKeys.CALENDAR]: {
    name: "To'lov kalendari",
    label: [
      // "Umumiy summa", "To'langan summa", "Qoldiq", "Kechikkan"
      {
        name: "Umumiy summa",
        key: "total_amount"
      },
      {
        name: "To'langan summa",
        key: "total_paid_amount"
      },
      {
        name: "Qoldiq",
        key: "total_pay_amount"
      },
      {
        name: "Kechikkan",
        key: "total_deferred_amount"
      }
    ]
  }
};
