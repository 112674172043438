export const orderPaymentStatus = {
  PAID: "paid", // to'langan
  UNPAID: "unpaid", // to'lanmagan
  PARTLY: "partially_paid", // qisman to'langan
};

export function orderPaymentGenerateStatus(amount: number, debt_amount: number) {
  if (amount === debt_amount) return orderPaymentStatus.UNPAID;
  if (debt_amount !== amount && debt_amount > 0) return orderPaymentStatus.PARTLY;
  if (debt_amount === 0) return orderPaymentStatus.PAID;
  return orderPaymentStatus.PAID;
}

export function orderPaymentPercentStatus(paymentPercent?: number) {
  if (paymentPercent === 0) return orderPaymentStatus.UNPAID;
  if (paymentPercent === 100) return orderPaymentStatus.PAID;
  return orderPaymentStatus.PARTLY;
}

export const orderPaymentStatusText = {
  [orderPaymentStatus.PAID]: "To'langan",
  [orderPaymentStatus.UNPAID]: "To'lanmagan",
  [orderPaymentStatus.PARTLY]: "Qisman",
};
