import { colors } from "features/app/utils/constants/colors";

export const getColor = (progress_count: number) => {
  if (progress_count === 0) return colors.GRAY_100;
  if (progress_count > 0 && progress_count <= 5) return colors.PRIMARY_200;
  if (progress_count > 5 && progress_count <= 10) return colors.PRIMARY_300;
  if (progress_count > 10 && progress_count <= 30) return colors.PRIMARY_400;
  if (progress_count > 30 && progress_count <= 50) return colors.PRIMARY;

  return colors.PRIMARY_600;
};
