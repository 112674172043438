import { useState } from "react";
import { useParams } from "react-router-dom";
import { Avatar, Dropdown, Popover, Progress } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { dayjsUnits } from "../../../../../../app/utils/enums/dayjsUnits";
import { getFirstLetter } from "../../../../../../app/utils/helpers/getFirstLetter";
import ClockCheckIcon from "../../../../../assets/icons/ClockCheckIcon";
import ClockIcon from "../../../../../assets/icons/ClockIcon";
import DotPointsIcon from "../../../../../assets/icons/DotPointsIcon";
import { useGetWork } from "../../../../../service/queries";
import { workStatusValue } from "../../../../../utils/constants/workStatusValue";
import { WorkStatusEnum } from "../../../../../utils/enums/workStatusEnum";
import WorkPlan from "../plan/WorkPlan";
import WorkModalTabs from "../tabs/WorkModalTabs";

import styles from "./workModalLeft.module.scss";

type Props = {
  visible: boolean;
  id?: number;
};

const WorkModalLeft = ({ visible, id }: Props) => {
  const { i18n } = useTranslation();
  const { projectId } = useParams();
  const [progressId, setProgressId] = useState(0);
  const [openProgress, setOpenProgress] = useState(false);
  const [openPlan, setOpenPlan] = useState(false);
  const { data: work } = useGetWork(visible, +projectId!, id);

  const onOpenAllResources = () => {
    setProgressId(0);
    setOpenProgress(true);
  };

  const onOpenOneResource = (id: number) => {
    setProgressId(id);
    setOpenProgress(true);
  };

  const usersData = ():
    | {
        id: number;
        image: string;
        name: string;
      }[]
    | undefined => {
    if (work?.company_persons && work?.company_persons?.length > 0) {
      return work?.company_persons?.map(item => ({
        id: item?.id,
        name: item?.name,
        image: ""
      }));
    }
    if (work?.users && work?.users?.length > 0) {
      return work?.users?.map(item => ({
        id: item?.id,
        name: item?.full_name,
        image: item?.image
      }));
    }
    return undefined;
  };

  const dateDiff = (startDate?: string, endDate?: string) => {
    const diff = dayjs(startDate, dayjsFormats.DATE).diff(dayjs(endDate, dayjsFormats.DATE), dayjsUnits.DAY);

    if (diff > 0) {
      return "success";
    }
    if (diff < 0) {
      return "error";
    }
    return "";
  };

  const popoverContent = (plan_date: string, fact_date: string, diff: number) => (
    <div className={styles.popover__content}>
      <div className={styles.popover__item}>
        <p>Reja</p>
        <p>{plan_date ?? "Malum emas"}</p>
      </div>
      <div className={styles.popover__item}>
        <p>Farq</p>
        {diff ? (
          <p className={` ${diff > 0 ? styles.success : styles.error}`}>
            {diff > 0 ? "+" : "-"} {Math.abs(diff)} kun
          </p>
        ) : (
          "Malum emas"
        )}
      </div>
      <div className={styles.popover__item}>
        <p>Fakt</p>
        <p>{fact_date ?? "Malum emas"}</p>
      </div>
    </div>
  );

  const diffDateProject = (diff: number) => {
    if (diff > 0) {
      return "success";
    }
    return "error";
  };

  const dateContent = (start_date: string, end_date: string) => {
    const diff = dayjs(start_date, dayjsFormats.DATE).diff(dayjs(end_date, dayjsFormats.DATE), dayjsUnits.DAY);

    return (
      <>
        {end_date ? (
          <Popover title={popoverContent(start_date, end_date, diff)} overlayClassName={styles.popover}>
            <span className={`${styles[diffDateProject(diff)]}`}>{start_date}</span>
          </Popover>
        ) : (
          start_date
        )}
      </>
    );
  };

  return (
    <div className={styles.left}>
      <div className={styles.info}>
        <div className={styles.status}>
          <p>Holati</p>
          <div className={styles[work?.status as WorkStatusEnum]}>
            {workStatusValue[work?.status as WorkStatusEnum]}
          </div>
        </div>
        <div className={styles.work_date}>
          <p>Boshlanish mudati</p>
          <div>
            <ClockIcon />
            <span className={styles[dateDiff(work?.start_date, work?.begin_date)]}>
              {dateContent(work?.start_date as string, work?.begin_date as string)}
            </span>
          </div>
        </div>
        <div className={styles.work_date}>
          <p>Tugash mudati</p>
          <div>
            <ClockCheckIcon />
            <span className={styles[dateDiff(work?.due_date, work?.completed_date)]}>
              {dateContent(work?.due_date as string, work?.completed_date as string)}
            </span>
          </div>
        </div>
        <div className={styles.users_info}>
          <p>Mas’ul xodim</p>
          <Dropdown
            trigger={["click", "hover"]}
            {...(!usersData() && { open: false })}
            dropdownRender={() => (
              <div className={styles.dropdown_content}>
                {usersData()?.map(item => (
                  <div key={item?.id} className={styles.dropdown_content_item}>
                    <Avatar src={item?.image}>{item?.name[0]}</Avatar>
                    {item?.name}
                  </div>
                ))}
              </div>
            )}
            mouseEnterDelay={1000}
            mouseLeaveDelay={0.1}
          >
            <div className={styles.item}>
              <Avatar.Group maxCount={4} maxStyle={{ color: "#475467", backgroundColor: "#F2F4F7" }}>
                {usersData()
                  ? usersData()?.map(item => (
                      <Avatar src={item?.image} key={item.id}>
                        {item?.name[0]}
                      </Avatar>
                    ))
                  : ""}
              </Avatar.Group>
            </div>
          </Dropdown>
        </div>
      </div>
      <div className={styles.works}>
        <p>Progress:</p>
        <div className={styles.end_works}>
          <div className={styles.end_works__volume}>
            <p>Ish hajmi</p>
            <div>
              <h4>
                {work?.quantity} <span>{work?.unit?.symbol[i18n.language]}</span>
              </h4>
            </div>
          </div>
          <div className={styles.end_works__volume}>
            <p>Bajarildi</p>
            <div>
              <h4>
                {work && work?.total_progress} <span>{work?.unit?.name[i18n.language]}</span>
              </h4>
            </div>
          </div>
          {/* <div className={styles.end_works__resource} onClick={onOpenPlan}>
            <div className={styles.end_works__inner}>
              <DotPointsIcon />
              <div>
                <h4>Reja</h4>
              </div>
            </div>
          </div> */}
          <div className={styles.end_works__resource} onClick={onOpenAllResources}>
            <div className={styles.end_works__inner}>
              <DotPointsIcon />
              <div>
                <h4>Resurslar</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.progress}>
        {/* <h5>Jarayonda: </h5> */}
        <Progress
          percent={work && +((work?.total_progress / work?.quantity) * 100).toFixed(1)}
          format={() => work && `${+((work?.total_progress / work?.quantity) * 100).toFixed(1)}%`}
        />
      </div>
      <div className={styles.work_progresses}>
        {work?.progress?.map((item, index, arr) => (
          <div className={styles.work_progress} key={item.id} onClick={() => onOpenOneResource(item.id)}>
            <div className={styles.work_progress__left}>
              <span className={styles.work_progress__left_volume}>
                {item?.quantity}{" "}
                <span className={styles.work_progress__left_unit}>{work?.unit?.symbol[i18n.language]}</span>
              </span>
              <span className={styles.work_progress__left_count}>Bajarildi №{arr.length - index}</span>
            </div>
            <div className={styles.work_progress__right}>
              <span className={styles.work_progress__right_work}>{dayjs(item?.date).format(dayjsFormats.DATE)}</span>
              <Popover title={item?.user?.full_name} overlayClassName={styles.popover__confirmation}>
                {item?.user?.image ? (
                  <img src={item?.user?.image} className={styles.work_progress__avatar} />
                ) : (
                  <div className={styles.work_progress__avatar}>{getFirstLetter(item?.user?.full_name)}</div>
                )}
              </Popover>
            </div>
          </div>
        ))}
      </div>
      <WorkModalTabs open={openProgress} setOpen={setOpenProgress} work={work} progressId={progressId} />
      <WorkPlan open={openPlan} setOpen={setOpenPlan} id={work?.id} />
    </div>
  );
};

export default WorkModalLeft;
