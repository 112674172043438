export const expectedPaymentsLastId = (data?: { id: number; amount: number; date: string }[]) => {
  if (data) {
    let lastData = data[0];

    if (lastData) {
      for (let i = 1; i < data.length ?? 1; i++) {
        if (data[i].id > lastData.id) {
          lastData = data[i];
        }
      }
      return lastData.id;
    }
    return 0;
  }
  return 0;
};
