import React from "react";
import { useParams } from "react-router-dom";
import { Form, Input, Modal, Popover, Select } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useGetLanguages, useGetResourceSelect, useGetUnitSelect } from "../../../../app/service/queries";
import { formRules } from "../../../../app/utils/constants/formRules";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import { selectFilterOption } from "../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../app/utils/helpers/sliceText";
import { useCreateProduct, useUpdateProduct } from "../../../service/mutations";
import { useGetCategories, useGetSubCategories } from "../../../service/queries";
import { ProductBodyModel } from "../../../utils/models/product/productBodyModel";
import { ProductFormModel } from "../../../utils/models/product/productFormModel";
import { ProductModel } from "../../../utils/models/product/productModel";

import styles from "../table/settingsProductTable.module.scss";

type Props = {
  modalData: { visible: boolean; data?: ProductModel };
  setModalData: React.Dispatch<React.SetStateAction<{ visible: boolean; data?: ProductModel }>>;
};

const { Item } = Form;
const { Option } = Select;

const SettingsProductModal: React.FC<Props> = ({ modalData, setModalData }) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const params = useParams();
  const { id: category_id } = parseParamsId(params?.id);
  const { id: sub_category_id } = parseParamsId(params?.second_id);
  const [form] = Form.useForm<ProductFormModel>();

  const categoryId = Form.useWatch("category_id", form) as number | undefined;

  const { data: languages } = useGetLanguages(modalData.visible);
  const { data: resources } = useGetResourceSelect();
  const { data: units } = useGetUnitSelect(modalData.visible);
  const { data: categories } = useGetCategories(modalData.visible);
  const { data: subCategories } = useGetSubCategories(categoryId);

  const createProduct = useCreateProduct();
  const updateProduct = useUpdateProduct();
  const oneProduct = modalData.data;

  const onCancel = () => {
    setModalData({
      visible: false,
      data: undefined
    });

    form.resetFields();
  };

  const onFinish = (values: ProductFormModel) => {
    const { subcategory_id, category_id, ...rest } = values;

    let reqData: ProductBodyModel = { ...rest, name: {} };

    if (values?.subcategory_id) {
      reqData = Object.assign(reqData, {
        subcategory_id: values?.subcategory_id
      });
    } else {
      reqData = Object.assign(reqData, { category_id: values?.category_id });
    }

    languages?.forEach(lang => {
      reqData = Object.assign(reqData, {
        name: {
          ...reqData.name,
          [lang.symbol]: values.name
        }
      });
    });

    if (oneProduct && oneProduct.id) {
      reqData = Object.assign(reqData, { id: oneProduct.id });

      updateProduct.mutateAsync(reqData).then(onCancel);
    } else {
      createProduct.mutateAsync(reqData).then(onCancel);
    }
  };

  const onOk = () => form.submit();

  const onAfterOpen = (open: boolean) => {
    if (open && oneProduct) {
      form.setFieldsValue({
        name: oneProduct.name[i18n.language] as string,
        resource_id: oneProduct.resource.id,
        code: oneProduct.code,
        unit_id: oneProduct.unit.id,
        category_id: Number(category_id),
        subcategory_id: sub_category_id ? Number(sub_category_id) : undefined
      });
    }
    if (open && category_id && !sub_category_id) {
      form.setFieldValue("category_id", Number(category_id));
    }
    if (open && category_id && sub_category_id) {
      form.setFieldsValue({
        category_id: Number(category_id),
        subcategory_id: Number(sub_category_id)
      });
    }
  };

  return (
    <Modal
      centered
      open={modalData.visible}
      onCancel={onCancel}
      onOk={onOk}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: updateProduct.isLoading || createProduct.isLoading
      }}
      title={t("products.Mahsulot")}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" rules={formRules()} label={t("products.Nomi")}>
          <Input placeholder={t("products.Nomini kiriting")} />
        </Item>
        <Item label={t("products.Resursi")} name="resource_id" rules={formRules()}>
          <Select placeholder={t("products.Resursni tanlang")} suffixIcon={<SelectSuffixIcon />}>
            {resources?.map(item => (
              <Option key={item.id} value={item.id}>
                <div className="resource">
                  <div
                    style={{
                      borderColor: item?.color,
                      color: item?.color
                    }}
                  >
                    {item?.symbol[language]}
                  </div>
                  {item?.name[language]?.length > 40 ? (
                    <Popover title={item?.name[language]} overlayClassName={styles.popover}>
                      <span className={styles.bold}>{sliceText(item?.name[language], 40)}</span>
                    </Popover>
                  ) : (
                    <span className={styles.bold}>{item?.name[language]}</span>
                  )}
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item label={t("products.Birligi")} name="unit_id" rules={formRules()}>
          <Select
            showSearch
            suffixIcon={false}
            placeholder={t("products.Birligini tanlang")}
            filterOption={selectFilterOption}
            allowClear
          >
            {units?.map(item => (
              <Option
                key={item.id}
                value={item.id}
                props={{
                  name: item?.name[language]
                }}
              >
                {item.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="code" label={t("products.Mahsulot kodi")}>
          <Input placeholder={t("products.Kodini kiriting")} />
        </Item>
        <Item name="category_id" label={t("products.Katalog")} rules={formRules()}>
          <Select
            placeholder={t("products.Katalogni tanlang")}
            suffixIcon={<SelectSuffixIcon />}
            showSearch
            filterOption={selectFilterOption}
            allowClear
            onChange={() => {
              form.setFieldValue("subcategory_id", undefined);
            }}
            notFoundContent={<TableEmpty description={t("products.Kataloglar mavjud emas")} />}
          >
            {categories?.map(item => (
              <Option
                key={item?.id}
                value={item?.id}
                props={{
                  name: item?.name[language]
                }}
              >
                {item?.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        {categoryId && (
          <Item name="subcategory_id" label={t("products.Kategoriya")}>
            <Select
              allowClear
              showSearch
              placeholder={t("products.Kategoriyani tanlang")}
              filterOption={selectFilterOption}
              suffixIcon={<SelectSuffixIcon />}
              notFoundContent={<TableEmpty description={t("products.Kategoriyalar mavjud emas")} />}
            >
              {subCategories?.map(item => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  props={{
                    name: item?.name[language]
                  }}
                >
                  {item?.name[language]}
                </Option>
              ))}
            </Select>
          </Item>
        )}
      </Form>
    </Modal>
  );
};

export default SettingsProductModal;
