import React from "react";
import { Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import { cx } from "../../../../../app/utils/helpers/cx";
import Select from "../../transfer-drawer/top/additions/projects/select";

import Card from "./card";

type Props = {
  form: FormInstance;
  parentName: string[];
  name: (string | number)[];
  currencyFormName: "first_currency" | "second_currency";
};

const { List } = Form;

const Projects: React.FC<Props> = ({ form, name, parentName, currencyFormName }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-2.5">
      <div className={cx("ant-form-item-label", "mb-2")}>
        <label>{t("payment.Loyihalar")}</label>
      </div>
      <List name={name}>
        {(fields, operation) => (
          <>
            {fields.map((_, key) => (
              <Card
                key={key}
                index={key}
                form={form}
                name={name}
                operation={operation}
                parentName={parentName}
                currencyFormName={currencyFormName}
              />
            ))}
            <Select operation={operation} />
          </>
        )}
      </List>
    </div>
  );
};

export default Projects;
