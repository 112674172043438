import React from "react";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  return (
    <div className={styles.back_up}>
      <div className={styles.bacl_up__top}>TOP - backUp</div>
      <div className={styles.bacl_up__bottom}>BOTTOM - backUp</div>
    </div>
  );
};

export default Index;
