import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Spin } from "antd";
import { DataNode } from "antd/es/tree";
import { useTranslation } from "react-i18next";

import { changeUser } from "store";

import { Tree } from "../../../../../app/components/tree/Tree";
import { LocalStorage } from "../../../../../app/service/LocalStorage";
import { USER } from "../../../../../app/utils/constants/localStorageKeys";
import { parseTreeValue } from "../../../../../app/utils/helpers/parseTreeValue";
import { useCreateRole, useUpdateRole } from "../../../../service/mutations";
import { useGetOneRole, useGetPermissions } from "../../../../service/queries";
import { PermissionModel } from "../../../../utils/models/roles/permissionModel";
import { RoleModel } from "../../../../utils/models/roles/roleModel";
import { RolesBodyModel } from "../../../../utils/models/roles/rolesBodyModel";
import { RolesFormFieldsModel } from "../../../../utils/models/roles/rolesFormFieldsModel";
import { RolesParsedTreeModel } from "../../../../utils/models/roles/rolesParsedTreeModel";

import styles from "./settingsRolesModal.module.scss";

type Props = {
  data: { visible: boolean; id?: number; isView?: boolean; name?: string };
  setData: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      isView?: boolean;
      id?: number;
      name?: string;
    }>
  >;
};

const { Item } = Form;

const SettingsRolesModal: React.FC<Props> = ({ setData, data }) => {
  const dispatch = useDispatch();
  const { data: permisisons, isLoading: isLoadingPermissions } = useGetPermissions(data.visible);
  const { data: oneRole } = useGetOneRole(data.id);
  const [formInstance] = Form.useForm();
  const { i18n, t } = useTranslation();
  const locale = i18n.language;
  const createRole = useCreateRole();
  const updateRole = useUpdateRole();

  // is updating
  const isUpdating = useMemo(() => Boolean(oneRole), [oneRole]);

  // initial form fields
  useEffect(() => {
    if (isUpdating) {
      formInstance.setFieldsValue({
        ...oneRole?.role
      });
    }
  }, [isUpdating]);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      if (isUpdating) {
        formInstance.setFieldsValue({
          ...oneRole?.role
        });
      }
      if (data?.name) {
        formInstance.setFieldValue("name", data?.name);
      }
    } else {
      formInstance.resetFields();
    }
  };

  // cancel modal
  const onCancel = () => {
    setData({ visible: false, name: "" });
  };

  // on ok
  const onOk = () => {
    formInstance.submit();
  };

  // on finish form
  const onFinish = (values: RolesFormFieldsModel) => {
    const parsedTree: RolesParsedTreeModel = {
      module_ids: [],
      submodule_ids: [],
      action_ids: []
    };

    const req: RolesBodyModel = {
      ...values,
      ...parseTreeValue<RolesParsedTreeModel>(values?.permissions ?? [], parsedTree)
    };

    delete req.permissions;

    if (isUpdating) {
      updateRole.mutateAsync({ ...req, id: data.id! }).then(() => {
        if (oneRole?.role?.name === LocalStorage.get<RoleModel>(USER)?.name) {
          const user = { ...LocalStorage.get<RoleModel>(USER), role_name: req?.name };

          LocalStorage.set(USER, JSON.stringify(user));

          dispatch(changeUser(user as any));
        }
      });
    } else {
      createRole.mutateAsync(req).then(onCancel);
    }
  };

  // tree
  const treeData: (permisisons: PermissionModel | undefined) => DataNode[] = permisisons =>
    (permisisons ?? []).map(module => ({
      title: module.name[locale],
      key: JSON.stringify({
        value: module.id,
        key: "module_ids"
      }),
      children: module.submodules.map(submodule => ({
        title: submodule.name[locale],
        key: JSON.stringify({
          value: submodule.id,
          key: "submodule_ids"
        }),
        children: submodule.actions.map(action => ({
          title: action.name[locale],
          key: JSON.stringify({
            value: action.id,
            key: "action_ids"
          })
        }))
      }))
    }));

  return (
    <Modal
      centered
      onOk={onOk}
      onCancel={onCancel}
      open={data.visible}
      className={styles.modal}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createRole.isLoading || updateRole.isLoading
      }}
      title={t("Xodimlar.Lavozim")}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
    >
      <Form form={formInstance} layout="vertical" onFinish={onFinish} className={styles.modal__form}>
        <div className={styles.top}>
          <Item name="name" label={t("Xodimlar.Lavozim nomi")} rules={[{ required: true, message: "" }]}>
            <Input disabled={data?.isView} placeholder={t("Xodimlar.Lavozim nomi")} />
          </Item>
          <Item name="description" label={t("Xodimlar.Izoh")}>
            <Input disabled={data?.isView} placeholder={t("Xodimlar.Izoh")} />
          </Item>
        </div>
        <Spin spinning={isLoadingPermissions} indicator={<LoadingOutlined spin />}>
          <div className={styles.bottom}>
            <Tree
              name="permissions"
              disabled={data?.isView}
              formInstance={formInstance}
              data={treeData(permisisons)}
              title={t("Xodimlar.Barcha bo'limlar")}
              initialData={treeData(oneRole?.permissions)}
            />
          </div>
        </Spin>
      </Form>
    </Modal>
  );
};

export default SettingsRolesModal;
