import React, { useState } from "react";
import { Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import CoinsStackedIcon from "features/payment/assets/icons/CoinsStackedIcon";
import PaymentDatePicker from "features/payment/components/bottom/info/left/payment-date-picker/PaymentDatePicker";
import { ProjectReportCashFlowModel } from "features/projects/utils/models/projectModel";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { useAuth } from "modules/auth/hooks";
import { colors, RU } from "modules/common";

import { LoadingIndicator } from "components";

import styles from "./cash-flow-card.module.scss";

const { Option } = Select;

type Props = {
  data: ProjectReportCashFlowModel;
  isLoading: boolean;
  baseCurrency: {
    symbol: string;
    id: number;
    amount: number;
    type: "base" | "second";
    icon: string;
    updated_at: number;
    name?: Record<string, string> | undefined;
  };
};

const CashFlowCard: React.FC<Props> = ({ data: cashFlowData, isLoading: isCashFlowDataLoading, baseCurrency }) => {
  const { t } = useTranslation();
  const { appends, queries } = useQueryParams();
  const { currencies } = useAuth();
  const { currency_id } = queries();
  const [currencySymbol, setCurrencySymbol] = useState();

  const allCurrencyItems = [
    ...(currencies?.map(item => ({
      symbol: item?.symbol,
      key: item?.id,
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img src={item?.icon} alt={item?.symbol} className="h-4 w-6 object-cover" />
            <span>{item?.symbol}</span>
          </div>
          <ConditionalRender if={String(item?.id) === currency_id}>
            <CheckIcon />
          </ConditionalRender>
        </div>
      )
    })) || [])
    // {
    //   key: PaymentFilterKeys.ALL,
    //   label: (
    //     <div className="flex items-center justify-between gap-6">
    //       <div className="flex items-center gap-2">
    //         <div className="flex items-center gap-2">
    //           <img src={currencies[0]?.icon} alt={currencies[0]?.symbol} className="h-4 w-6 object-cover" />
    //           <span>{currencies[0]?.symbol}</span>
    //         </div>
    //         <span>+</span>
    //         <div className="flex items-center gap-2">
    //           <img src={currencies[1]?.icon} alt={currencies[1]?.symbol} className="h-4 w-6 object-cover" />
    //           <span>{currencies[1]?.symbol}</span>
    //         </div>
    //       </div>
    //       <ConditionalRender if={currency_id === PaymentFilterKeys.ALL}>
    //         <CheckIcon />
    //       </ConditionalRender>
    //     </div>
    //   )
    // }
  ];

  return (
    <Spin spinning={isCashFlowDataLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-5 rounded-xl border border-solid border-gray-200 p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <CoinsStackedIcon stroke={colors.GRAY_700} width={27} height={27} />
            <span className="text-lg font-medium text-gray-700">{t("project.Pul oqimi")}</span>
            <Select
              allowClear
              onChange={(e, option: DefaultOptionType) => {
                const { symbol } = option!.props;

                appends([{ key: queryParamsKeys.CURRENCY_ID, value: e }]);
                setCurrencySymbol(symbol);
              }}
              className={styles.select}
              onClear={() => {
                appends([{ key: queryParamsKeys.CURRENCY_ID, value: undefined }]);
                setCurrencySymbol(undefined);
              }}
              suffixIcon={<ChevronDownIcon rotate stroke={colors.GRAY_500} width={18} />}
              placeholder={
                <span className="flex cursor-pointer items-center text-gray-500 hover:text-gray-900">
                  {t("project.Barcha valyutalarni tanlash")}{" "}
                </span>
              }
            >
              {allCurrencyItems?.map(item => (
                <Option
                  props={{
                    symbol: item?.symbol
                  }}
                  key={item?.key}
                  value={item?.key}
                >
                  {item?.label}
                </Option>
              ))}
            </Select>
          </div>
          <PaymentDatePicker
            dateQueryParamsKeys={[queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE]}
            className={styles.datePicker}
          />
        </div>
        <div className="flex items-center gap-2">
          <div className="flex flex-1 items-center gap-2 rounded-[10px] border border-solid border-success-50 bg-success-50 p-3">
            <span className="h-[66px] w-[4px] rounded-[5px] border border-solid border-success-500 bg-success-500" />
            <div className="flex h-[66px] flex-col justify-between">
              <span className="tex-base font-medium text-gray-700">{t("project.Kirim")}</span>
              <div className="flex items-center gap-1">
                <span className="text-2xl font-medium text-success-500">
                  {cashFlowData?.total_income_amount?.toLocaleString(RU)}
                </span>
                <span className="mt-1 text-sm font-semibold text-gray-400">
                  {currencySymbol || baseCurrency?.symbol}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-1 items-center gap-2 rounded-[10px] border border-solid border-error-50 bg-error-50 p-3">
            <span className="h-[66px] w-[4px] rounded-[5px] border border-solid border-error-500 bg-error-500" />
            <div className="flex h-[66px] flex-col justify-between">
              <span className="tex-base font-medium text-gray-700">{t("project.Chiqim")}</span>
              <div className="flex items-center gap-1">
                <span className="text-2xl font-medium text-error-500">
                  {Math.abs(cashFlowData?.total_expense_amount)?.toLocaleString(RU)}
                </span>
                <span className="mt-1 text-sm font-semibold text-gray-400">
                  {currencySymbol || baseCurrency?.symbol}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default CashFlowCard;
