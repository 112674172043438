import React from "react";
import { Form, FormInstance, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import RefreshCircleIcon from "../../../../assets/icons/RefreshCircleIcon";
import { useGetCashMoneySelect } from "../../../../service/queries";
import { ExchangeFormModel } from "../../../../utils/models/exchangeFormModel";

import styles from "../exchange-drawer.module.scss";

type Props = {
  form: FormInstance;
};

const { Item, useWatch } = Form;
const { Option } = Select;

const Top: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { visible } = useAppSelector(state => state.paymentReducer.exchange);

  const payments = useWatch([
    "expense_payment",
    "payment_types"
  ]) as ExchangeFormModel["expense_payment"]["payment_types"];
  const firstCurrency = JSON.parse(useWatch("first_currency", form) || "{}") as CurrencyModel;
  const secondCurrency = JSON.parse(useWatch("second_currency", form) || "{}") as CurrencyModel;
  const viewCurrencyAmount = useWatch("view_currency_amount", form);
  const isChangeCurrency = useWatch("is_change_currency", form) as boolean;
  const { data: cashes, isLoading: isLoadingCashes } = useGetCashMoneySelect(visible, {
    type: PaymentTypeEnum.EXPENSE,
    currency_id: firstCurrency?.id
  });

  const onChangeCash = () => {
    form.setFieldsValue({
      income_payment: {
        payment_type: {
          amount: undefined
        }
      },
      expense_payment: {
        payment_types: [
          {
            amount: undefined,
            id: undefined
          }
        ]
      }
    });
  };

  const onChangeCurrencyAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parseValue = parseLocaledString(e?.target?.value || "0");
    const calculateValue = isChangeCurrency ? 1 / parseValue : parseValue;

    const total = payments?.reduce(
      (previousValue, currentValue) =>
        parseLocaledString(previousValue || "0") + parseLocaledString(currentValue?.amount || "0"),
      0
    );

    form.setFieldValue("view_currency_amount", localeFormatter(e?.target?.value));
    form.setFieldValue("currency_amount", isChangeCurrency ? localeFormatter(String(1 / parseValue)) : parseValue);
    form.setFieldValue(["income_payment", "payment_type", "amount"], localeFormatter(String(total * calculateValue)));
  };

  const onReplacementCurrency = () => {
    form.setFieldsValue({
      is_change_currency: !isChangeCurrency,
      first_currency: JSON.stringify(secondCurrency),
      second_currency: JSON.stringify(firstCurrency),
      currency_amount: isChangeCurrency
        ? viewCurrencyAmount
        : localeFormatter(String(Number(secondCurrency?.amount) / Number(firstCurrency?.amount))),
      income_payment: {
        currency_id: firstCurrency?.id,
        payment_type: {
          amount: undefined,
          projects: []
        }
      },
      expense_payment: {
        currency_id: secondCurrency?.id,
        payment_types: [
          {
            id: undefined,
            amount: undefined,
            max_amount: undefined,
            projects: [],
            financials: []
          }
        ]
      }
    });
  };

  return (
    <div className={styles.top}>
      <Item className="hidden" name="first_currency" />
      <Item className="hidden" name="second_currency" />
      <Item name={["expense_payment", "cash_id"]} label={t("payment.Kassa")} rules={formRules()}>
        <Select
          showSearch
          className="h-12"
          onChange={onChangeCash}
          loading={isLoadingCashes}
          notFoundContent={<TableEmpty />}
          filterOption={selectFilterOption}
          suffixIcon={<SelectSuffixIcon />}
          placeholder={t("payment.Kassani tanlang")}
        >
          {cashes?.map(item => (
            <Option
              value={item.id}
              key={item.id}
              props={{
                name: item?.name
              }}
            >
              <div className="flex flex-col">
                <div>{item?.name}</div>
                <div className={cx("flex items-center gap-2", "cash_amount")}>
                  {item?.currencies?.map(item => (
                    <div
                      key={item?.id}
                      className="border-r border-solid border-gray-700 pr-2 text-sm font-normal text-gray-600 last:border-none"
                    >
                      {item?.amount?.toLocaleString(RU)} {item?.symbol}
                    </div>
                  ))}
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </Item>
      <div className="flex gap-2">
        <Item name={["expense_payment", "currency_id"]} label={t("payment.Valyuta")} className="w-4/5">
          <div className={styles.currency}>
            <div className={styles.currency__item}>
              <img src={firstCurrency?.icon} alt={firstCurrency?.symbol} />
              <span>{firstCurrency?.symbol}</span>
            </div>
            <div onClick={onReplacementCurrency} className={styles.currency__icon}>
              <RefreshCircleIcon />
            </div>
            <div className={styles.currency__item}>
              <img src={secondCurrency?.icon} alt={secondCurrency?.symbol} />
              <span>{secondCurrency?.symbol}</span>
            </div>
          </div>
        </Item>
        <Item name="currency_amount" />
        <Item name="is_change_currency" />
        <Item rules={formRules()} label={t("payment.Valyuta kursi")} name="view_currency_amount">
          <Input suffix={null} className={styles.curreny_amount} onChange={onChangeCurrencyAmount} />
        </Item>
      </div>
    </div>
  );
};

export default Top;
