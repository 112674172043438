import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { ReqCustomFieldValueModel } from "features/app/utils/models/reqCustomFieldValueModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { WorkStatusEnum } from "../../app/utils/enums/WorkStatusEnum";
import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { paymentQueryKeys } from "../../payment/utils/constants/paymentQueryKeys";
import { openEstimateNotification } from "../components/detailed-project/estimate-notification/EstiamteNotification";
import { endpoints } from "../utils/constants/endpoints";
import { projectsQueryParamsKeys } from "../utils/constants/projectsQueryParamsKeys";
import { queryKeys } from "../utils/constants/queryKeys";
import { projectStatuses } from "../utils/enums/projectStatuses";
import { EstimateModel } from "../utils/models/estimateModel";
import { ProjectBodyModel } from "../utils/models/projectBodyModel";
import { ProjectFolderBodyModel } from "../utils/models/projectFolderBodyModel";
import { SectionBodyModel } from "../utils/models/sectionBodyModel";
import { TaskBodyModel } from "../utils/models/taskBodyModel";
import { TaskProductBodyModel } from "../utils/models/taskProductBodyModel";
import { WorkBodyModel } from "../utils/models/workBodyModel";

import { useGetDetailedProject } from "./queries";

export function useCreateProject() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, ProjectBodyModel>(
    async req => {
      const res = await $api.post(endpoints.PROJECT_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries({
          queryKey: [queryKeys.PROJECTS],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: [queryKeys.DETAILED_PROJECT_FOLDER],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: [paymentQueryKeys.PROJECT_SELECT],
          refetchType: "active"
        });

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateProject() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, ProjectBodyModel>(
    async req => {
      const res = await $api.put(endpoints.PROJECT_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries({
          queryKey: [queryKeys.PROJECTS],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: [queryKeys.DETAILED_PROJECT_FOLDER],
          refetchType: "active"
        });
        await qc.invalidateQueries({
          queryKey: [queryKeys.DETAILED_PROJECT]
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteProject() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${endpoints.PROJECT_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.DETAILED_PROJECT_FOLDER]);
        await qc.invalidateQueries([queryKeys.PROJECTS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateProjectFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<ProjectFolderBodyModel>, ErrorRes, ProjectFolderBodyModel>(
    async req => {
      const res = await $api.post(endpoints.PROJECT_FOLDER_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.PROJECTS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateProjectFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<ProjectFolderBodyModel>, ErrorRes, ProjectFolderBodyModel>(
    async req => {
      const res = await $api.put(endpoints.PROJECT_FOLDER_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.PROJECTS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteProjectFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number | string>(
    async id => {
      const res = await $api.delete(`${endpoints.PROJECT_FOLDER_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.PROJECTS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateSection() {
  const params = useParams();
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { data: projectData } = useGetDetailedProject(Number(params.projectId));

  return useMutation<SuccessRes, ErrorRes, SectionBodyModel>(
    async req => {
      const res = await $api.post(endpoints.SECTION_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);

        message.success(res.message[i18n.language]);

        if (projectData?.status === projectStatuses.PROCESS) {
          openEstimateNotification("ish bo'lim");
        }
      }
    }
  );
}

export function useUpdateSection() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, SectionBodyModel>(
    async req => {
      const res = await $api.put(endpoints.SECTION_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteSection() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${endpoints.SECTION_DELETE}?${projectsQueryParamsKeys.SECTION_ID}=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateTask() {
  const params = useParams();
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { data: projectData } = useGetDetailedProject(Number(params.projectId));

  return useMutation<SuccessRes, ErrorRes, TaskBodyModel>(
    async req => {
      const res = await $api.post(endpoints.TASK_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);

        message.success(res.message[i18n.language]);

        if (projectData?.status === projectStatuses.PROCESS) {
          openEstimateNotification("ishi");
        }
      }
    }
  );
}

export function useUpdateTask() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, TaskBodyModel>(
    async req => {
      const res = await $api.put(endpoints.TASK_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteTask() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${endpoints.TASK_DELETE}?${queryParamsKeys.ID}=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

// export function useUpdateStatusWork() {
//   const { i18n } = useTranslation();
//
//   return useMutation<SuccessRes, ErrorRes, { id: number; status: WorkStatusEnum }>(
//     async data => {
//       const res = await $api.put(endpoints.UPDATE_WORK, data);
//
//       return res.data;
//     },
//     {
//       onError: errorHandler,
//       onSuccess: async res => {
//         message.success(res.message[i18n.language]);
//       }
//     }
//   );
// }

export function useUpdateWork() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, Partial<WorkBodyModel>>(
    async data => {
      const res = await $api.put(endpoints.UPDATE_WORK, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        Object.values(WorkStatusEnum).forEach(status => {
          qc.invalidateQueries([queryKeys.WORKS, status]);
        });

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteProduct() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: { id: number; task_id: number }) => {
      const res = await $api.delete(`${endpoints.DELETE_PRODUCT}?id=${data.id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, data) => {
        await qc.invalidateQueries([queryKeys.TASK_PRODUCTS, data.task_id]);
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_PRODUCT]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateProduct() {
  const params = useParams();
  const { data: projectData } = useGetDetailedProject(Number(params.projectId));
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: TaskProductBodyModel) => {
      const res = await $api.post(endpoints.CREATE_PRODUCT, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, data) => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_PRODUCT]);
        await qc.invalidateQueries([queryKeys.TASK_PRODUCTS, data.task_id]);

        message.success(res.message[i18n.language]);

        if (projectData?.status === projectStatuses.PROCESS) {
          openEstimateNotification("mahsulot");
        }
      }
    }
  );
}

export function useUpdateProduct() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: TaskProductBodyModel) => {
      const res = await $api.put(endpoints.UPDATE_PRODUCT, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async (res, data) => {
        await qc.invalidateQueries([queryKeys.TASK_PRODUCTS, data.task_id]);
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.WORK_PLAN]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_TASK]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_PRODUCT]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useSectionImportData() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation(
    async (data: EstimateModel) => {
      const res = await $api.post(endpoints.SECTION_IMPORT_DATA, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCopyProject(id: number, is_archived: boolean) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number | string>(
    async id => {
      const res = await $api.post(`${endpoints.PROJECT_COPY}`, { id });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        if (id) {
          await qc.invalidateQueries([queryKeys.DETAILED_PROJECT_FOLDER, String(id), is_archived], {
            type: "active"
          });
        } else {
          await qc.invalidateQueries([queryKeys.PROJECTS]);
        }
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCopySection() {
  const qc = useQueryClient();

  return useMutation<
    SuccessRes,
    ErrorRes,
    { id: number; project_id: number; custom_field_values?: ReqCustomFieldValueModel[] }
  >(
    async payload => {
      const { data } = await $api.put("section/copy", payload);

      return data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        await qc.invalidateQueries([queryKeys.SECTIONS]);
        await qc.invalidateQueries([queryKeys.GROUP_CUSTOM_FIELD]);
        await qc.invalidateQueries([queryKeys.DYNAMIC_ESTIMATE_SECTION]);

        message.success(res.message[i18n.language]);
      }
    }
  );
}
