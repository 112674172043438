import React from "react";
import { Switch } from "antd";

import { useCreateWidget, useUpdateWidget } from "../../mutations";
import { Widget } from "../../schema";

export const Checked: React.FC<Widget> = props => {
  const { status, recommended, name, users, id } = props;

  const updateMutation = useUpdateWidget();
  const createMutation = useCreateWidget();

  const onChangeStatus = (e: boolean) => {
    if (recommended) {
      createMutation.mutate({
        name,
        widget_id: id,
        user_ids: users?.map(i => i.id)
      });
    } else {
      // @ts-ignore
      updateMutation.mutate({ ...props, status: e ? "active" : "passive" });
    }
  };

  return (
    <Switch
      onClick={(_, e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onChange={onChangeStatus}
      defaultChecked={status === "active"}
      loading={updateMutation.isLoading || createMutation.isLoading}
    />
  );
};

export default Checked;
