import React from "react";
import { Tabs } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { ProjectByResourceViewDataTabCount, useWidget } from "modules/dashboard";
import { useGetProjectByResourceDatTabCount } from "modules/dashboard/queries/use-get-project-by-resource-count";
import { useProjectByResourceView } from "modules/dashboard/queries/use-project-by-resource-view";

import TabContent from "./tab-content/tab-content";

import styles from "./project-by-resource.module.scss";

const ProjectByResource: React.FC = () => {
  const { queries, append } = useQueryParams();

  const { t, i18n } = useTranslation();

  const { tab } = queries();

  const { id, visible, props } = useWidget();

  const comingWidgetData = props as ProjectReportProductSpendModel;

  const { projectByResourceView, isLoading } = useProjectByResourceView({
    enabled: visible,
    id,
    resource_type: comingWidgetData?.data[0]?.type
  });

  const { countData } = useGetProjectByResourceDatTabCount({ enabled: visible, id });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = comingWidgetData?.data?.map(el => ({
    key: `${el?.id}-${el?.type === null ? "another" : el?.type}`,
    label: (
      <p className={styles.tab}>
        {el.name[i18n.language] as string}
        {countData &&
          (countData[el?.type as keyof ProjectByResourceViewDataTabCount] > 0 || countData?.another > 0) && (
            <span className={styles.tab__count}>
              {el?.type ? countData[el?.type as keyof ProjectByResourceViewDataTabCount] : countData?.another}
            </span>
          )}
      </p>
    ),
    children: <TabContent data={projectByResourceView} isLoading={isLoading} />
  }));

  return (
    <div className={styles.bar}>
      <div className={styles.tabs}>
        <Tabs
          activeKey={tab || `${comingWidgetData?.data[0]?.id}-${comingWidgetData?.data[0]?.type}`}
          items={items}
          onChange={onChangeTabs}
        />
      </div>
    </div>
  );
};

export default ProjectByResource;
