import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal } from "antd";
import dayjs from "dayjs";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";

import { useAppSelector } from "../../../../../hooks/redux";
import { RootState } from "../../../../../store";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import { useGetCurrenySelect } from "../../../../app/service/queries";
import { colors } from "../../../../app/utils/constants/colors";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../app/utils/helpers/baseCurrency";
import { cx } from "../../../../app/utils/helpers/cx";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import CreditCardDownloadIcon from "../../../assets/icons/CreditCardDownloadIcon";
import CreditCardUploadIcon from "../../../assets/icons/CreditCardUploadIcon";
import { useCreatePay, useCreatePayTemplate } from "../../../service/mutation";
import FirstStep from "../income-or-expense/first/FirstStep";
import SecondStep from "../income-or-expense/second/SecondStep";

import PayThirdStep from "./PayThirthStep";

import styles from "../income-or-expense/incomeOrExpense.module.scss";
import { useGetCashMoneyPaymentTypeSelect, useGetCashMoneySelect } from "features/payment/service/queries";
import { RU } from "features/app/utils/constants/languages";

interface PayFormModel {
  amount: string;
  date?: Date;
  description: string;
  currency_amount: string;
  project_id?: number;
}

const PayModal: React.FC = () => {
  const [autoAdvance, setAutoAdvance] = useState<boolean>(false);
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const createPay = useCreatePay();
  const baseCurrency = getBaseCurrency();
  const [form] = Form.useForm<PayFormModel>();
  const [step, setStep] = useState<number>(0);
  const { setPayModal } = paymentReducerActions;
  const createPayTemplate = useCreatePayTemplate();
  const { data: currencies } = useGetCurrenySelect();
  const payModalArg = useAppSelector((state: RootState) => state.paymentReducer.pay_modal);
  const { isTemplate, visible, form_data, cash, type, payment_type, currency, queryKeys } = payModalArg;

  const onAfterOpen = (open: boolean) => {
    if (open) {
      setAutoAdvance(true);
      if (form_data) {
        form.setFieldsValue({
          ...form_data,
          date: form_data?.date ? dayjs(form_data?.date, dayjsFormats.DATE) : dayjs(),
          amount: localeFormatter(String(form_data?.amount))
        });
      }
    } else {
      setAutoAdvance(false);
      form.resetFields();

      dispatch(
        setPayModal({
          visible: false,
          type
        })
      );

      setStep(0);
    }
  };

  const onCancel = () => {
    dispatch(
      setPayModal({
        ...payModalArg,
        visible: false
      })
    );
  };

  const prev = () => {
    setAutoAdvance(false);
    setStep(step - 1);
  };

  const onOk = () => {
    form?.submit();
  };

  const onFinish = (values: PayFormModel) => {
    const basicCurrencyAmount = currencies?.find(item => item.id === currency?.id);
    const isCurrencyAmount = baseCurrency?.id !== currency?.id;

    const reqData = {
      id: form_data!.id,
      cash_id: cash?.id,
      currency_id: currency?.id,
      description: values.description,
      payment_type_id: payment_type?.id,
      amount: parseLocaledString(values.amount),
      project_id: form_data?.project_id || values?.project_id,
      currency_amount: parseLocaledString(String(values.currency_amount)) ?? basicCurrencyAmount!.amount
    };

    // @ts-ignore
    if (!isCurrencyAmount) delete reqData.currency_amount;

    if (isTemplate) {
      createPayTemplate.mutateAsync(reqData).then(async () => {
        onCancel();

        if (queryKeys) {
          await qc.invalidateQueries(queryKeys[0]);
          if (queryKeys[1]) await qc.invalidateQueries(queryKeys[1]);
        }
      });
    } else {
      createPay.mutateAsync(reqData).then(onCancel);
    }
  };

  const title = () => {
    const paymentTypeTitle = {
      [PaymentTypeEnum.INCOME]: "Kirim",
      [PaymentTypeEnum.EXPENSE]: "Chiqim"
    };

    const activePaymentTypeTitle = paymentTypeTitle[type as keyof typeof paymentTypeTitle];

    const description = [
      `${activePaymentTypeTitle}ni qaysi kassadan amalga oshirishni tanlang`,
      `${activePaymentTypeTitle} uchun to’lov turi va valyutasini tanlang`,
      `${cash?.name} / ${payment_type?.name}`
    ];

    return (
      <div className={styles.title}>
        <div
          className={cx(
            styles.title__icon,
            { "bg-success-100": type === PaymentTypeEnum.INCOME },
            { "bg-error-100": type === PaymentTypeEnum.EXPENSE }
          )}
        >
          {type === PaymentTypeEnum.INCOME ? (
            <CreditCardUploadIcon isNotArrow stroke={colors.SUCCESS_500} />
          ) : (
            <CreditCardDownloadIcon isNotArrow stroke={colors.ERROR_500} />
          )}
        </div>
        <div className={styles.title__info}>
          <h4>{activePaymentTypeTitle}</h4>
          <div className="m-0 flex items-center justify-between">
            <span className="text-sm font-normal text-gray-600">{description[step]}</span>
            <span className="text-sm font-medium text-gray-600">
              {step === 2 ? (
                <>
                  {currency?.amount?.toLocaleString(RU)} {currency?.symbol}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const footer = () => (
    <div className={styles.footer}>
      {step > 0 && <Button onClick={prev}>Ortga</Button>}
      {step !== 2 && <Button onClick={onCancel}>Yopish</Button>}
      {step === 2 && (
        <Button type="primary" onClick={onOk} loading={createPay.isLoading || createPayTemplate.isLoading}>
          Saqlash
        </Button>
      )}
    </div>
  );

  const content: Record<number, React.ReactNode> = {
    0: <FirstStep isExpectedPayment setStep={setStep} autoAdvance={autoAdvance} setAutoAdvance={setAutoAdvance} />,
    1: (
      <SecondStep
        isExpectedPayment
        setStep={setStep}
        form={form}
        autoAdvance={autoAdvance}
        setAutoAdvance={setAutoAdvance}
      />
    ),
    2: <PayThirdStep form={form} />
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      zIndex={11112}
      title={title()}
      footer={footer()}
      onCancel={onCancel}
      maskClosable={false}
      afterOpenChange={onAfterOpen}
      className={styles.income_expense_modal}
      width={step === 2 ? "34.5rem" : "64rem"}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        {content[step]}
      </Form>
    </Modal>
  );
};

export default PayModal;
