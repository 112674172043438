import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Dropdown, MenuProps, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../store/reducers/projectsReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../app/utils/constants/languages";
import { getFirstLetter } from "../../../../../app/utils/helpers/getFirstLetter";
import { useGetWorks } from "../../../../service/queries";
import { workStatusValue } from "../../../../utils/constants/workStatusValue";
import { ColorClassEnum } from "../../../../utils/enums/colorClassEnum";
import { WorkStatusEnum } from "../../../../utils/enums/workStatusEnum";
import { CompanyPersonModel, WorkModel } from "../../../../utils/models/workModel";

import styles from "./tables.module.scss";

const Works: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { data: works, isLoading } = useGetWorks(+projectId!);
  const { setWork } = projectsReducerActions;

  const onWorkDetail = (record: WorkModel) => ({
    onClick: () => {
      dispatch(setWork({ visible: true, id: record?.id }));
    }
  });

  const onSetColorDate = useCallback((record: WorkModel) => {
    const dueDate = dayjs(record?.due_date, dayjsFormats.DATE).startOf("day");
    const completedDate = dayjs(record?.completed_date, dayjsFormats.DATE).startOf("day");
    const today = dayjs().startOf("day");

    if (
      (record.status === WorkStatusEnum.COMPLETED || record.status === WorkStatusEnum.CLOSED) &&
      (dueDate.isAfter(completedDate) || dueDate.isSame(completedDate))
    ) {
      return ColorClassEnum.SUCCESS;
    }
    if (today.isAfter(dueDate)) {
      return ColorClassEnum.REJECT;
    }

    return ColorClassEnum.PENDING;
  }, []);

  const onSetColorStatus = useCallback((record: WorkModel, status: boolean) => {
    const dueDate = dayjs(record?.due_date, dayjsFormats.DATE).startOf("day");
    const completedDate = dayjs(record?.completed_date, dayjsFormats.DATE).startOf("day");
    const today = dayjs().startOf("day");

    if ((dueDate.isAfter(today) || dueDate.isSame(today)) && record?.status === WorkStatusEnum.OPEN) {
      return `${ColorClassEnum.PENDING}${status ? "__status" : ""}`;
    }
    if (
      ((dueDate.isAfter(completedDate) || dueDate.isSame(completedDate) || !record.completed_date) &&
        record?.status === WorkStatusEnum.COMPLETED) ||
      record?.status === WorkStatusEnum.CLOSED
    ) {
      return `${ColorClassEnum.SUCCESS}${status ? "__status" : ""}`;
    }
    if (today.isAfter(dueDate) || completedDate.isAfter(dueDate)) {
      return `${ColorClassEnum.REJECT}${status ? "__status" : ""}`;
    }
    if ((dueDate.isAfter(today) || dueDate.isSame(today)) && record?.status === WorkStatusEnum.PROCESS) {
      return `${ColorClassEnum.PROGRESS}${status ? "__status" : ""}`;
    }

    if (record?.status === WorkStatusEnum.COMPLETED) {
      return `${ColorClassEnum.SUCCESS}${status ? "__status" : ""}`;
    }
    if (record?.status === WorkStatusEnum.OPEN) {
      return `${ColorClassEnum.PENDING}${status ? "__status" : ""}`;
    }
    if (record?.status === WorkStatusEnum.PROCESS) {
      return `${ColorClassEnum.PROGRESS}${status ? "__status" : ""}`;
    }

    return ColorClassEnum.NONE;
  }, []);

  const confirmationUserItems = (users: CompanyPersonModel[]): MenuProps["items"] =>
    users?.map(user => ({
      key: user.id,
      label: <div className={styles.user_option}>{user?.person?.name}</div>
    }));

  const columns: ColumnsType<WorkModel> = [
    {
      title: "Ish nomi va bo’limi",
      render: (record: WorkModel) => (
        <div className={styles.name}>
          <p className={styles.bold}>
            {record?.place}{" "}
            {record?.name.length > 80 ? (
              <Popover title={record?.name} openClassName={styles.popover}>
                {record?.name.substring(0, 80)}...
              </Popover>
            ) : (
              record?.name
            )}
          </p>
          <p>{record?.section?.name}</p>
        </div>
      )
    },
    {
      title: "Ish tugash vaqti",
      render: (record: WorkModel) => (
        <span className={styles[onSetColorDate(record)]}>
          {record?.due_date}{" "}
          {onSetColorDate(record) === ColorClassEnum.REJECT && (
            <span className={styles.diff}>
              {Math.abs(
                dayjs(record?.due_date, dayjsFormats.DATE).diff(
                  record?.completed_date
                    ? dayjs(record?.completed_date, dayjsFormats.DATE).startOf("day")
                    : dayjs().startOf("day"),
                  "day"
                )
              )}{" "}
              kun
            </span>
          )}
        </span>
      )
    },
    {
      title: "Ish hajmi",
      dataIndex: "quantity",
      render: (quantity: number) => quantity.toLocaleString(RU)
    },
    {
      title: "Bajarildi",
      render: (record: WorkModel) => `${record?.total_progress} %`
    },
    {
      title: "Birligi",
      render: (record: WorkModel) => record?.unit?.symbol[i18n.language]
    },
    {
      title: "Holati",
      render: (record: WorkModel) => (
        <span className={styles[onSetColorStatus(record, true)]}>{workStatusValue[record?.status]}</span>
      )
    },
    {
      title: "Mas’ul xodim",
      render: (record: WorkModel) => (
        <Dropdown
          placement="top"
          trigger={["click", "hover"]}
          menu={{ items: confirmationUserItems(record?.company_persons) }}
        >
          <div className={styles.company_persons}>
            {record?.company_persons?.slice(0, 3).map(user => (
              <div className={styles.person_name} key={user.id}>
                {user?.name?.length > 0 && getFirstLetter(user?.name)}
              </div>
            ))}
            {record?.company_persons?.length > 3 && (
              <div className={styles.person_name}>+{record?.company_persons.length - 3}</div>
            )}
          </div>
        </Dropdown>
      )
    }
  ];

  const rowKey = (record: WorkModel) => record.id;

  return (
    <>
      <div className={styles.table}>
        <Table<WorkModel>
          dataSource={works?.data}
          columns={columns}
          pagination={false}
          rowKey={rowKey}
          onRow={onWorkDetail}
          rowClassName={() => styles.row}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: works?.total
          }}
        />
      </div>
    </>
  );
};

export default Works;
