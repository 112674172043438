import { Route, Routes } from "react-router-dom";

import { useAuth } from "modules/auth/hooks";

import { exactRouteFixer } from "../../features/app/utils/helpers/exactRouterFixer";
import { RouteModel } from "../../features/app/utils/models/RouteModel";

import { projectsRoutes } from "./projectsRoutes";

export const ProjectsRouter = () => {
  const { permissions } = useAuth();

  const map = (routes: RouteModel[]) =>
    routes.map((item, index) => (
      <Route
        key={index}
        path={item.path ? exactRouteFixer(item.path, item.isExact) : undefined}
        element={<item.element  />}
        index={item.index as false}
      >
        {item.children ? map(item.children) : undefined}
      </Route>
    ));

  return <Routes>{map(projectsRoutes({ permissions }))}</Routes>;
};
