import React from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import DeleteIcon from "../../../../../../../app/assets/icons/DeleteIcon";
import ConditionalRender from "../../../../../../../app/components/conditional-render/ConditionalRender";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { ErrorRes, SuccessRes } from "../../../../../../../app/utils/models/responseType";
import { numberRound } from "../../../../../../utils/helpers/numberRound";
import { formatterPrice } from "../../../../../../utils/helpers/partyModalTableActions";
import { PartyFormProductsModel } from "../../../../../../utils/models/partyModalFormModel";
import { PartyCreateOfferAction } from "../PartyCreateOfferAction";

import styles from "../../partyModalLeft.module.scss";

const { Item, useWatch } = Form;

type Props = {
  index: number;
  rowId?: string;
  form: FormInstance;
  isWarehouse?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const PartyTotalAmount: React.FC<Props> = ({ index, form, rowId, isWarehouse = false, deleteProduct }) => {
  const { t } = useTranslation();
  const status = useWatch("status", form) as StatusEnums;
  const product = useWatch(["warehouse_products", index]);
  const { isView, updateProduct, isEditing } = useAppSelector(state => state.supplyReducer.partyModal);

  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];

  const onChangeTotal = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const findProduct = products[index];
    const newValue = localeFormatter(e.currentTarget.value);
    const quantity = parseLocaledString(products[index]?.quantity);

    form.setFieldValue(["warehouse_products", index, "total"], newValue);
    form.setFieldValue(
      ["warehouse_products", index, "amount"],
      formatterPrice(parseLocaledString(newValue) / quantity)
    );

    if (!isEmptyArr(cacheProducts)) {
      const findIndex = cacheProducts?.findIndex(item => item.row_id === findProduct.row_id);

      form.setFieldValue(["cache_products", findIndex, "total"], newValue);
      form.setFieldValue(
        ["cache_products", findIndex, "amount"],
        localeFormatter(String(numberRound(parseLocaledString(newValue) / quantity)))
      );
    }
  };

  const onDeleteProduct = (index: number) => () => {
    const findProduct = products[index];

    const newWarehouseProduct = products?.filter(item => item.row_id !== findProduct?.row_id);

    form.setFieldsValue({
      warehouse_products: newWarehouseProduct,
      is_table: !isEmptyArr(newWarehouseProduct)
    });

    if (!isEmptyArr(cacheProducts)) {
      form.setFieldsValue({
        cache_products: cacheProducts?.filter(item => item.row_id !== findProduct?.row_id)
      });
    }
  };

  // const value = () => form.getFieldValue(["warehouse_products", index, "total"]);

  return (
    <div className={styles.total}>
      {/* <PerfItem placeholder={<div>{value()}</div>} visible={!!(isView && updateProduct?.index !== rowId)}> */}
      <Item name={[index, "total"]}>
        <Input
          autoComplete="off"
          placeholder="Umumiy summa"
          onChange={e => onChangeTotal(e, index)}
          disabled={isView && updateProduct?.index !== rowId}
        />
      </Item>
      {/* </PerfItem> */}
      {isView ? (
        <div>
          <span style={{ opacity: 0 }}>
            <DeleteIcon />
          </span>
        </div>
      ) : (
        <ConditionalRender
          if={!(isEditing && status === StatusEnums.PARTIALLY_RECIEVED && product?.status === StatusEnums.ORDERED)}
        >
          <div className={cx("delete")} onClick={onDeleteProduct(index)}>
            <DeleteIcon />
          </div>
        </ConditionalRender>
      )}
      <ConditionalRender if={!isWarehouse}>
        <PartyCreateOfferAction rowId={rowId} form={form} index={index} deleteProduct={deleteProduct} />
      </ConditionalRender>
    </div>
  );
};

export default PartyTotalAmount;
