import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createTransform, PersistConfig } from "redux-persist";
import persistReducer from "redux-persist/lib/persistReducer";
import storage from "redux-persist/lib/storage";

import { Currency, Permission, User } from "./schema";

export type IAuth = {
  user: User | null;
  accessToken: string | null;
  isFetched: boolean;
  permissions: Permission[];
  currencies: Currency[];
};

const initialState: IAuth = {
  user: null,
  accessToken: null,
  isFetched: false,
  permissions: [],
  currencies: []
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, { payload }: PayloadAction<Pick<IAuth, "user" | "permissions" | "currencies">>) => {
      state.user = payload.user;
      state.isFetched = true;
      state.permissions = payload.permissions;
      state.currencies = payload.currencies;
    },
    logout: state => {
      state.user = null;
      state.accessToken = null;
      state.isFetched = true;
      state.currencies = [];
    },
    changeAccessToken: (state, { payload }: PayloadAction<Pick<IAuth, "accessToken">>) => {
      state.accessToken = payload.accessToken;
    },
    changeUser: (state, { payload }: PayloadAction<Pick<IAuth, "user">>) => {
      state.user = payload.user;
    }
  }
});

const isFetchedTransform = createTransform<boolean, boolean, IAuth, any>(
  state => state,
  (isFetched, key, stored) => {
    const accessToken = JSON.parse(stored.accessToken);

    return !accessToken;
  },
  { whitelist: ["isFetched"] }
);

const config: PersistConfig<IAuth> = {
  key: "auth",
  storage,
  whitelist: ["accessToken", "isFetched"],
  transforms: [isFetchedTransform]
};

export default persistReducer<IAuth>(config, slice.reducer);
export const { login, logout, changeAccessToken, changeUser } = slice.actions;
