import dayjs from "dayjs";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";

import { dayjsFormats, isEmptyValue } from "modules/common";

export const transformReqData = (values: TransferBodyModel) => {
  const { selected_products, ...others } = values;

  return Object.entries({
    ...others,
    date: values?.date ? dayjs(others?.date).format(dayjsFormats.DATE) : null,
    products: others?.products
      ?.map(item => {
        const { total_quantity, uniqueId, unit, product, rowId, ...rest } = item;

        const transformedProduct = {
          ...rest,
          product_id:
            typeof item?.product_id === "string" ? Number(String(item.product_id)?.split("-")[0]) : item.product_id,
          quantity: item.quantity ? String(parseLocaledString(String(item.quantity))) : undefined
        };

        return Object.entries(transformedProduct)
          .filter(([key, value]) => !isEmptyValue(value))
          .reduce((result: Record<string, string | number | undefined>, [key, value]) => {
            result[key] = value;
            return result;
          }, {});
      })
      .filter(product => Object.keys(product).length > 0)
  })
    .filter(([key, value]) => !isEmptyValue(value))
    .reduce((result: Record<string, any>, [key, value]) => {
      result[key] = value;
      return result;
    }, {});
};
