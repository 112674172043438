import React from "react";
import { Collapse, DatePicker, Form, Input } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import DatepickerSuffixIcon from "../../../../app/assets/icons/DatepickerSuffixIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";

import styles from "../order-payment/third-step/thirdStep.module.scss";

const { Item } = Form;
const { Panel } = Collapse;

const Additional: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Collapse
      ghost
      rootClassName={styles.additional}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel
        key="1"
        header={
          <div className="flex w-max cursor-pointer items-center gap-2 text-sm font-medium text-primary-500">
            {t("payment.Qo'shimcha ma'lumotlar")}
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <Item name="date" label={t("payment.Sana")} initialValue={dayjs()}>
            <DatePicker
              format={dayjsFormats.DATE}
              suffixIcon={<DatepickerSuffixIcon />}
              placeholder={t("payment.Sanani tanlang")}
            />
          </Item>
          <Item name="description" label={t("payment.Izoh")}>
            <Input.TextArea placeholder={t("payment.Izohini kiriting")} />
          </Item>
        </div>
      </Panel>
    </Collapse>
  );
};

export default Additional;
