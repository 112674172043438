import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { colors } from "features/app/utils/constants/colors";
import { formRules } from "features/app/utils/constants/formRules";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { useGetWarehouseProductsSelect } from "features/supply/service/queries";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { useSelector } from "store";
import { supplyActions } from "store/reducers/supplyReducer";

import { LoadingIndicator, TooltipShortName } from "components";

import styles from "./card.module.scss";

const { Option } = Select;
const { Item, useWatch } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
  index: number;
  rowId: number | string;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const ProductListSelect: React.FC<Props> = ({ form, index, rowId }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setCreateProductModal } = supplyActions;
  const [searchSelect, setSearchSelect] = useState("");
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);

  const products = useWatch("products", form) as LaboratoryDrawerProducts[];
  const warehouseId = useWatch("warehouse_id", form);

  const projectId = useWatch("project_id", form);
  const singleProduct = useWatch(["products", index], form);

  const [time, setTime] = useState<TimeoutModel>();
  const { data, isLoading, fetchNextPage } = useGetWarehouseProductsSelect(warehouseId, searchSelect, projectId!);

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearchSelect(e);
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: colors.ORANGE_600,
            color: colors.ORANGE_600
          }}
        >
          ---
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("warehouse.Resurs nomi")}</div>
    </div>
  );

  const pagesData = () => {
    let newPagesData: WarehouseProductModel[] = [];

    if (isViewing) {
      data?.pages?.forEach(item => {
        const data = !item?.data?.some(
          el =>
            el?.product?.id === Number(String(singleProduct?.id)?.split("-")[0]) &&
            el?.unit?.id === singleProduct?.expense_unit_id
        )
          ? [
              {
                product: singleProduct?.product,
                unit: singleProduct?.unit,
                total_quantity: singleProduct?.total_quantity
              },
              ...item!.data
            ]
          : item?.data;

        newPagesData = [...newPagesData, ...((data as never) ?? [])];
      });
    } else {
      data?.pages?.forEach(item => {
        const data =
          products && products?.length > 1 && !singleProduct?.id
            ? item?.data?.filter(
                el =>
                  !products.some(
                    item =>
                      Number(String(item?.id)?.split("-")[0]) === el?.product?.id &&
                      item?.expense_unit_id === el?.unit?.id
                  )
              )
            : item?.data;

        newPagesData = [...newPagesData, ...data];
      });
    }

    return newPagesData;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (e: number, option: DefaultOptionType) => {
    const { unit_symbol: unitSymbol, total_quantity, unit_id } = option!.props;

    form.setFieldsValue({
      products: products?.map(item => {
        if (item?.rowId === rowId)
          return {
            ...item,
            id: e,
            expense_quantity: total_quantity,
            unit_symbol: unitSymbol,
            expense_unit_id: unit_id,
            total_quantity
          };
        return item;
      })
    });
  };

  const onAddProduct = () => {
    dispatch(
      setCreateProductModal({
        visible: true,
        index,
        name: searchSelect!
      })
    );
  };

  return (
    <Item name={[index, "id"]} rules={formRules()}>
      <Select
        showSearch
        suffixIcon={null}
        disabled={isViewing}
        onSearch={onSearch}
        onChange={onChangeProduct}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        notFoundContent={
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            <SelectNotContent title={t("warehouse.Mahsulot")} action={onAddProduct} />
          </Spin>
        }
      >
        {pagesData()?.map(item => (
          <Option
            key={`${item?.product?.id}-${item?.unit?.id}`}
            value={`${item?.product?.id}-${item?.unit?.id}`}
            props={{
              name: item?.product?.name[i18n.language],
              unit_symbol: item?.unit?.symbol[i18n.language],
              total_quantity: item?.total_quantity,
              unit_id: item?.unit?.id
            }}
            rootClassName="flex justify-between"
          >
            <div className="flex w-full gap-3">
              <div
                className="flex items-center rounded border border-solid px-2 text-xs font-medium"
                style={{
                  borderColor: item?.product?.resource?.color,
                  color: item?.product?.resource?.color
                }}
              >
                {item?.product?.resource?.symbol?.[i18n.language]}
              </div>
              <div className="flex flex-1 items-center justify-between gap-4 text-base font-normal text-gray-800">
                <TooltipShortName length={20} title={item?.product?.name?.[i18n.language]} />
                <div className="text-sm font-medium text-gray-400">
                  {isViewing ? singleProduct?.unit_symbol : item?.unit?.symbol[i18n.language]}
                </div>
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductListSelect;
