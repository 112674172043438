import React from "react";
import { Form, FormInstance, message, Upload, UploadFile as UploadFileType } from "antd";
import { Rule } from "antd/es/form";
import { UploadChangeParam } from "antd/lib/upload";
import { useTranslation } from "react-i18next";

import UploadIcon from "../../assets/icons/UploadIcon";
import XCloseCircleIcon from "../../assets/icons/XCloseCircleIcon";

const { Item } = Form;
const { Dragger } = Upload;

type Props = {
  name?: string;
  form: FormInstance;
  disabled?: boolean;
  viewImage?: boolean;
  image: Blob | string | undefined;
  setImage: React.Dispatch<React.SetStateAction<string | Blob | undefined>>;
};

const UploadFile: React.FC<Props> = ({ name, image, setImage, form, disabled }) => {
  const { t } = useTranslation();

  const onFileFromEvent = (e: UploadChangeParam<UploadFileType>) => {
    const file = e?.file?.originFileObj;

    if (file) return file;
    return undefined;
  };

  const onChange = (e: UploadChangeParam<UploadFileType>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const image = e?.file?.originFileObj;

      form.setFieldValue("image", image);
      setImage(image);
    }
  };

  const imageSource = () => {
    if (typeof image === "string") return image as string;

    return window.URL && window.URL.createObjectURL(image!);
  };

  const onDelete = () => {
    form.setFieldValue("image", null);
    setImage(undefined);
  };

  const imageRule: Rule[] = [
    () => ({
      validator(_, value: File) {
        if (!value) Promise.reject();
        return Promise.resolve();
      },
      message: "",
      required: true
    })
  ];

  return (
    <Item
      name={name ?? "image"}
      rules={imageRule}
      valuePropName="file"
      className="upload"
      getValueFromEvent={onFileFromEvent}
    >
      {!disabled && (
        <Dragger
          name="images"
          maxCount={1}
          multiple={false}
          disabled={disabled}
          onChange={onChange}
          showUploadList={false}
          customRequest={() => null}
          className="upload__dragger"
          accept=".jpg, .jpeg, .png, .svg"
        >
          <div className="contents">
            <UploadIcon />
            <p>
              <span>{t("products.Yuklash uchun bosing")}</span> {t("products.yoki sudrab olib tashlang")} SVG, PNG, JPG
              or GIF (max. 800x400px)
            </p>
          </div>
        </Dragger>
      )}
      {image && (
        <div className="upload__content">
          <div className="upload__content__item">
            <img className="upload__image" src={imageSource()} alt="" />
            {!disabled && (
              <div className="upload__content__item__close" onClick={onDelete}>
                <XCloseCircleIcon />
              </div>
            )}
          </div>
        </div>
      )}
    </Item>
  );
};

export default UploadFile;
