import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button, Drawer, Form, Select } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { useGetWarehouseProductsInTransfers, useGetWarehouseSelect } from "features/warehouse/service/query";

import DefectNoData from "./defect-no-data/DefectNoData";
import DefectWarehouseProducts from "./defect-warehouse-products/DefectWarehouseProducts";

import styles from "./makingDefetDrawer.module.scss";

const { Option } = Select;

interface IProps {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

const MakingDefectDrawer: React.FC<IProps> = ({ drawerOpen, setDrawerOpen }) => {
  const { data: warehouses } = useGetWarehouseSelect(true, drawerOpen);
  const [searchText, setSearchText] = useState<string>();

  const [form] = Form.useForm();
  const warehouseId = Form.useWatch("warehouseId", form);

  const [page, setPage] = useState<number>(1);

  const { data: warehouseProducts, isLoading } = useGetWarehouseProductsInTransfers(warehouseId, searchText, page);

  const onCancel = () => {
    setDrawerOpen(false);
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      setPage(1);
      setSearchText("");
      form.resetFields();
    }
  };

  useEffect(() => {
    if (warehouses && warehouses?.length > 0) {
      form.setFieldsValue({
        warehouseId: warehouses[0]?.id
      });
    }
  }, [warehouses, form, drawerOpen]);

  return (
    <Drawer
      open={drawerOpen}
      afterOpenChange={onAfterOpen}
      onClose={onCancel}
      rootClassName={styles.defect_drawer}
      title="Yaroqsizga chiqarish"
      footer={
        <div className="flex items-center justify-end">
          <Button onClick={onCancel}>Yopish</Button>
        </div>
      }
    >
      <div className={styles.body}>
        <div className="flex  flex-col justify-center gap-3  rounded-2xl border border-solid border-gray-200 bg-white p-4">
          <div className={styles.form_content}>
            <Form layout="vertical" form={form}>
              <Form.Item label="Omborxona" name="warehouseId">
                <Select className={styles.select} placeholder="Tanlang" allowClear>
                  {warehouses?.map(warehouse => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </div>

        <ConditionalRender if={warehouseId && warehouses && warehouses?.length > 0} else={<DefectNoData />}>
          <DefectWarehouseProducts
            setPage={setPage}
            setSearchText={setSearchText}
            warehouseProducts={warehouseProducts}
            isLoading={isLoading}
            warehouseId={warehouseId}
            warehouses={warehouses!}
          />
        </ConditionalRender>
      </div>
    </Drawer>
  );
};

export default MakingDefectDrawer;
