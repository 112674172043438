import React from "react";

const CoinsHandIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.0396 11.1356C17.2764 11.6798 16.3424 11.9998 15.3337 11.9998C12.7563 11.9998 10.667 9.9105 10.667 7.33317C10.667 4.75584 12.7563 2.6665 15.3337 2.6665C17.0043 2.6665 18.4699 3.54439 19.2943 4.86403M8.00033 26.7827H11.4807C11.9345 26.7827 12.3855 26.8367 12.8254 26.9447L16.5028 27.8383C17.3008 28.0327 18.132 28.0516 18.9383 27.895L23.0043 27.104C24.0784 26.8948 25.0664 26.3804 25.8408 25.6272L28.7175 22.8288C29.539 22.031 29.539 20.7364 28.7175 19.9373C27.9779 19.2178 26.8066 19.1368 25.9698 19.7469L22.6171 22.193C22.137 22.544 21.5527 22.7329 20.9519 22.7329H17.7143L19.7751 22.7329C20.9366 22.7329 21.8775 21.8176 21.8775 20.6877V20.2787C21.8775 19.3405 21.2211 18.5225 20.2858 18.2957L17.1051 17.5222C16.5875 17.3966 16.0574 17.3332 15.5245 17.3332C14.2381 17.3332 11.9095 18.3983 11.9095 18.3983L8.00033 20.033M26.667 8.6665C26.667 11.2438 24.5777 13.3332 22.0003 13.3332C19.423 13.3332 17.3337 11.2438 17.3337 8.6665C17.3337 6.08918 19.423 3.99984 22.0003 3.99984C24.5777 3.99984 26.667 6.08918 26.667 8.6665ZM2.66699 19.4665L2.66699 27.1998C2.66699 27.9466 2.66699 28.3199 2.81232 28.6052C2.94015 28.856 3.14412 29.06 3.395 29.1878C3.68022 29.3332 4.05359 29.3332 4.80032 29.3332H5.86699C6.61373 29.3332 6.9871 29.3332 7.27231 29.1878C7.5232 29.06 7.72717 28.856 7.855 28.6052C8.00033 28.3199 8.00033 27.9466 8.00033 27.1998V19.4665C8.00033 18.7198 8.00033 18.3464 7.855 18.0612C7.72717 17.8103 7.5232 17.6063 7.27231 17.4785C6.9871 17.3332 6.61373 17.3332 5.86699 17.3332L4.80033 17.3332C4.05359 17.3332 3.68022 17.3332 3.39501 17.4785C3.14412 17.6063 2.94015 17.8103 2.81232 18.0612C2.66699 18.3464 2.66699 18.7198 2.66699 19.4665Z"
        stroke="#F79009" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CoinsHandIcon;