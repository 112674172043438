import { Dropdown, MenuProps, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useParams } from "react-router-dom";

import { useAppDispatch } from "../../../../../../hooks/redux";
import { chatActions } from "../../../../../../store/reducers/chatReducer";
import Chat from "../../../../../app/components/chat/Chat";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import UnreadMessageAction from "../../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../../app/utils/enums/chatEnum";
import { getFirstLetter } from "../../../../../app/utils/helpers/getFirstLetter";
import { useGetCompletedWorks } from "../../../../service/queries";
import { queryKeys } from "../../../../utils/constants/queryKeys";
import { CompletedWorkModel } from "../../../../utils/models/completedWorkModel";

import styles from "./tables.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

const CompletedWorks = () => {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { data: completed_works, isLoading } = useGetCompletedWorks(+projectId!);
  const { setVisible } = chatActions;

  const onOpenChat = (record: CompletedWorkModel) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.TASK_PROGRESSES,
        dataKeys: [queryKeys.COMPLETED_WORKS],
        onAfterOpen: () => {
          if (record?.unread_message_count && record?.unread_message_count > 0) {
            qc.setQueryData(
              [queryKeys.COMPLETED_WORKS, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as CompletedWorkModel[];
                return {
                  total: total,
                  current_page: current_page,
                  data: initialData?.map(item => {
                    if (item?.id === record?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
          }
        }
      })
    );
  };

  const dropdownItems = (record: CompletedWorkModel): MenuProps["items"] => {
    const allItems = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown_item}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        onClick: () => onOpenChat(record),
      },
    ];

    return allItems;
  };

  const columns: ColumnsType<CompletedWorkModel> = [
    {
      title: "Ish nomi va bo’limi",
      render: (record: CompletedWorkModel) => (
        <div className={styles.name}>
          <p className={styles.bold}>
            {record?.place} {record?.task?.name}
          </p>
          <p>{record?.section?.name}</p>
        </div>
      ),
    },
    {
      title: "Sana",
      dataIndex: "date",
    },
    {
      title: "Foizi",
      render: (record: CompletedWorkModel) => (
        <>{((record?.quantity / record?.total_quantity) * 100).toFixed(2)} %</>
      ),
    },
    {
      title: "Mas’ul xodim",
      render: (record: CompletedWorkModel) => (
        <div className={styles.actions}>
          <Popover
            title={record?.creator?.full_name}
            overlayClassName={styles.popover}
          >
            <div className={styles.creator}>
              {record?.creator?.image ? (
                <img src={record?.creator?.image} alt={record?.creator?.full_name} />
              ) : (
                getFirstLetter(record?.creator?.full_name)
              )}
            </div>
          </Popover>
        </div>
      ),
    },
    {
      title: "",
      render: (record: CompletedWorkModel) => (
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          menu={{ items: dropdownItems(record) }}
        >
          <UnreadMessageDots count={record?.unread_message_count} />
        </Dropdown>
      ),
      width: "5%",
    },
  ];

  const rowKey = (record: CompletedWorkModel) => {
    return record.id;
  };

  return (
    <>
      <div className={styles.table}>
        <Table<CompletedWorkModel>
          dataSource={completed_works?.data}
          columns={columns}
          pagination={false}
          rowKey={rowKey}
          rowClassName={() => styles.row}
          locale={{
            emptyText: <TableEmpty />,
          }}
          loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: completed_works?.total,
          }}
        />
      </div>
      <Chat />
    </>
  );
};

export default CompletedWorks;
