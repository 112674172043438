export enum PaymentFilterKeys {
  ORDER = "order",
  EXPECTED_PAYMENT = "expected_payment",
  ALL = "all"
}

export const paymentFilterNames = {
  [PaymentFilterKeys.ORDER]: "Partiyalar",
  [PaymentFilterKeys.EXPECTED_PAYMENT]: "Kutilayotgan to'lovlar",
  [PaymentFilterKeys.ALL]: "Barchasi"
};
