const FullScreenOpenIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width={props.width || "20"}
    height={props.height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 7.5L17.5 2.5M17.5 2.5H12.5M17.5 2.5V7.5M7.5 7.5L2.5 2.5M2.5 2.5L2.5 7.5M2.5 2.5L7.5 2.5M7.5 12.5L2.5 17.5M2.5 17.5H7.5M2.5 17.5L2.5 12.5M12.5 12.5L17.5 17.5M17.5 17.5V12.5M17.5 17.5H12.5"
      stroke={props.stroke || "#344054"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FullScreenOpenIcon;
