import { Form, FormInstance } from "antd";
import { forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

import {
  PartyFormPersonGroupModel,
  PartyFormProductsModel,
  PartyGroupProductsModel,
} from "../../../../utils/models/partyModalFormModel";
import {
  PartyPrintAllDataModel,
  PartyPrintGenerateDataModel,
} from "../../../../utils/models/PartyPrintDataModel";
import {
  generatePersonToWarehouseProduct,
  unGroupProducts,
} from "../../../../utils/helpers/generateProductsData";
import PartyPrintCard from "./PartyPrintCard";
import PartyPrintTable from "./PartyPrintTable";
import productsData from "../../../../utils/helpers/productsData";
import { OrderDetailModel } from "../../../../utils/models/orderModel";
import { useGetProjectSelect } from "../../../../../payment/service/queries";
import { useGetWarehouseSelect } from "../../../../../warehouse/service/query";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import { formationPrintProductsData } from "../../../../utils/helpers/partyPrintGenerateData";

import styles from "./partyPrint.module.scss";

type Props = {
  partyId?: number;
  oneOrder: OrderDetailModel | undefined;
  form: FormInstance;
};

const { useWatch } = Form;

const PartyPrint = forwardRef<HTMLDivElement | null, Props>(
  ({ partyId, oneOrder, form }, ref) => {
    const tr = useTranslation();
    const selectProductsData = productsData({ form });
    const { data: companyPerson } = useGetCompanyPersonSelect();
    const { data: projects } = useGetProjectSelect({ enabled: true });
    const { data: warehouses } = useGetWarehouseSelect(true, true);

    const groupProducts = useWatch(
      "group_products",
      form
    ) as PartyGroupProductsModel[];

    const warehouseProducts = useWatch(
      "warehouse_products",
      form
    ) as PartyFormProductsModel[];

    const personGroup = useWatch(
      "person_group",
      form
    ) as PartyFormPersonGroupModel[];

    const tableData = useMemo(() => {
      const parseProductData: PartyPrintAllDataModel = formationPrintProductsData(
        [
          ...(warehouseProducts ?? []),
          ...unGroupProducts(groupProducts),
          ...generatePersonToWarehouseProduct(personGroup),
        ],
        selectProductsData,
        companyPerson,
        tr
      );

      const generatePrintData: PartyPrintGenerateDataModel = {
        ...parseProductData,
        sameProject: projects?.find(
          (item) => item.id === parseProductData.sameProject
        )?.name,
        sameWarehouse: warehouses?.find(
          (item) => item.id === parseProductData.sameWarehouse
        )?.name,
      };

      return generatePrintData;
    }, [
      tr,
      projects,
      warehouses,
      personGroup,
      groupProducts,
      companyPerson,
      warehouseProducts,
      selectProductsData,
    ]);

    const totalPrice = useMemo(() => {
      return oneOrder?.warehouse_products?.reduce(
        (first, second) =>
          first + Number(second?.amount ?? 0) * Number(second?.quantity ?? 0),
        0
      );
    }, [oneOrder]);

    return (
      <div className={styles.party_print} ref={ref}>
        <PartyPrintCard
          oneOrder={oneOrder}
          partyId={partyId}
          sameProject={tableData?.sameProject}
          sameWarehouse={tableData?.sameWarehouse}
        />
        <PartyPrintTable
          data={tableData}
          projects={projects}
          totalPrice={totalPrice}
          warehouses={warehouses}
        />
      </div>
    );
  }
);

export default PartyPrint;
