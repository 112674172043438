import { Button } from "antd";
import queryString from "query-string";
import React, { useMemo, useState } from "react";

import FilterDrawer from "../../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetProjectsSelect, useGetUsersSelect, useProjectSelect } from "../../../../../app/service/queries";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import BuildingIcon from "../../../../../payment/assets/icons/BuildingIcon";
import UsersIcon from "../../../../../payment/assets/icons/UsersIcon";
import UserIcon from "../../../../../projects/assets/icons/UserIcon";
import ZapIcon from "../../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../../../warehouse/assets/icons/FilterLinesIcon";
import PackageXIcon from "../../../../assets/icons/PackageXIcon";
import { DefectStatusEnum } from "../../../../utils/enums/defectStatusEnum";
import { DefectTypesEnum } from "../../../../utils/enums/defectTypesEnum";

import dayjs, { Dayjs } from "dayjs";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import styles from "./filterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { inventoryDefectsTableDefaultData } from "features/inventory/utils/constants/inventoryTableDefaultData";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useTranslation } from "react-i18next";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";

const queryStringArr = [
  queryParamsKeys.STATUSES,
  queryParamsKeys.MIN_CREATED_AT_DEFECT,
  queryParamsKeys.MAX_CREATED_AT_DEFECT,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.CREATORS_IDS,
  queryParamsKeys.CONFIRMATION_USER_IDS,
  queryParamsKeys.TYPES,
  queryParamsKeys.MIN_CREATED_AT_DEFECT,
  queryParamsKeys.MAX_CREATED_AT_DEFECT
];

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
};

const FilterDrawerDefects: React.FC<Props> = ({ exportExcelMutate }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { queries, necessaryQueries } = useQueryParams();
  const { min_created_at_defect, max_created_at_defect } = queries();
  const [creatorsSelected, setCreatorsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CREATORS_IDS] as string[] | null
  );
  const [statusesSelected, setStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [confirmationUsersSelected, setConfirmationUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CONFIRMATION_USER_IDS] as string[] | null
  );
  const [typesSelected, setTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.TYPES] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: projectsSelect } = useProjectSelect({ enabled: open });
  const { data: confirmationsSelect } = useGetCompanyPersonSelect(open);

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });

    return result;
  }, [adminsSelect]);

  const confirmationUsers = useMemo(() => {
    const result = confirmationsSelect?.map(item => {
      return { ...item };
    });

    return result;
  }, [confirmationsSelect]);

  const defectsTypeData = useMemo(() => {
    return [
      {
        id: DefectTypesEnum.DISPOSED,
        name: `${t("Inventory.Singan")}`
      },
      {
        id: DefectTypesEnum.BROKEN,
        name: `${t("Inventory.Buzilgan")}`
      },
      {
        id: DefectTypesEnum.LOST,
        name: `${t("Inventory.Yo’qolgan")}`
      }
    ];
  }, []);

  const statusesData = useMemo(() => {
    return [
      {
        id: DefectStatusEnum.OPEN,
        name: `${t("Inventory.Ochiq")}`
      },
      {
        id: DefectStatusEnum.PENDING,
        name: `${t("Inventory.Kutilmoqda")}`
      },
      {
        id: DefectStatusEnum.RECIEVED,
        name: `${t("Inventory.Qabul qilingan")}`
      },
      {
        id: DefectStatusEnum.REJECTED,
        name: `${t("Inventory.Rad etilgan")}`
      }
    ];
  }, []);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig: inventoryDefectsTableDefaultData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);

    const reqBody = {
      ...otherParamsData,
      columns: data,
      creator_ids: creatorsSelected,
      confirmation_user_ids: confirmationUsersSelected,
      statuses: statusesSelected,
      types: typesSelected,
      project_ids: projectsSelected,
      min_created_at_defect,
      max_created_at_defect
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Inventory.Yuklab olish")}
    </Button>
  );

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button className={styles.filter} onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Inventory.Filter")}
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.CREATORS_IDS,
          selectedOptions: creatorsSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CONFIRMATION_USER_IDS,
          selectedOptions: confirmationUsersSelected
        },
        {
          queryKey: queryParamsKeys.TYPES,
          selectedOptions: typesSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_CREATED_AT_DEFECT,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_CREATED_AT_DEFECT,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setSelectedOptionsArr={
        [
          setCreatorsSelected,
          setStatusesSelected,
          setProjectsSelected,
          setConfirmationUsersSelected,
          setTypesSelected
        ] as never
      }
      setDateValue={[setDateValue]}
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={confirmationUsersSelected}
          setSelectedOptions={setConfirmationUsersSelected}
          data={admins}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder={t("inventory.Guvohlar")}
          isPersons
          className={styles.admins__select}
          showSearch
        />
        <FilterSelect
          selectedOptions={typesSelected}
          setSelectedOptions={setTypesSelected}
          data={defectsTypeData}
          multiple="multiple"
          icon={<PackageXIcon />}
          placeholder={t("inventory.Inventar turi")}
          showSearch
        />
        <FilterSelect
          selectedOptions={creatorsSelected}
          setSelectedOptions={setCreatorsSelected}
          data={confirmationUsers}
          multiple="multiple"
          icon={<UsersIcon />}
          placeholder={t("inventory.Kontragent")}
          showSearch
        />
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projectsSelect}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("inventory.Loyihalar")}
          showSearch
        />
        <FilterSelect
          selectedOptions={statusesSelected}
          setSelectedOptions={setStatusesSelected}
          data={statusesData}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder={t("inventory.Status")}
          showSearch
        />
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerDefects;
