import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { cx } from "../../../../../app/utils/helpers/cx";
import { GetComponentProps } from "../../../../../app/utils/models/getComponentPropsType";
import { PaginationType } from "../../../../../app/utils/models/PaginationType";
import { counterpartsQueryKeys } from "../../../../../counterparts/utils/constants/counterpartsQueryKeys";
import { CalendarHistoryPaymentModel } from "../../../../utils/models/calendarHistoryPaymentModel";
import { PaymentSourceElement } from "../../income-expense-view/PaymentSourceElement";

import styles from "./calendarHistory.module.scss";

type Props = {
  data: PaginationType<CalendarHistoryPaymentModel[]> | undefined;
  isLoading: boolean;
};

const CalendarPaymentTable: React.FC<Props> = ({ data, isLoading }) => {
  const dispatch = useDispatch();
  const { severalSearchParams } = useQueryParams();
  const { setCalendarHistoryPage, setExpectedPaymentView } = paymentReducerActions;
  const { page } = useAppSelector(state => state.paymentReducer.calendarHistory);
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);

  const onChangePage = (e: number) => {
    dispatch(setCalendarHistoryPage(e));
  };

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total || 10,
        pageSize: 10,
        current: page,
        onChange: onChangePage,
        style: {
          padding: "1rem"
        }
      }}
    />
  );

  const columns: ColumnsType<CalendarHistoryPaymentModel> = [
    {
      title: "Nomi",
      render: (_, record) => <PaymentSourceElement record={record} isViewKey={false} />
    },
    {
      title: "Summa",
      render: (_, record) => (
        <div className={styles.amount}>
          {record.amount?.toLocaleString(RU)}
          <span>{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: "Qoldiq",
      render: (_, record) => (
        <div className={styles.amount}>
          {record.debt_amount?.toLocaleString(RU)}
          <span>{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: "Sana",
      dataIndex: "date"
    }
  ];

  const onRow: GetComponentProps<CalendarHistoryPaymentModel> | undefined = record => ({
    onClick: () => {
      dispatch(
        setExpectedPaymentView({
          visible: true,
          id: record?.id,
          forPayQueryKeys: [[counterpartsQueryKeys.CALENDAR_VIEW, searchParams]]
        })
      );
    }
  });

  return (
    <Table<CalendarHistoryPaymentModel>
      onRow={onRow}
      footer={footer}
      columns={columns}
      pagination={false}
      dataSource={data?.data}
      rowClassName="cursor-pointer"
      className={cx("footer_table")}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
};

export default CalendarPaymentTable;
