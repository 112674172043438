import React from "react";
import { Spin } from "antd";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetCompanyPersonStatistics } from "../../../../service/queries";

import InfoLeft from "./left/InfoLeft";
import InfoRight from "./right/InfoRight";

import styles from "./counterpartsInfo.module.scss";

type Props = {
  defaultTabKey?: string;
};

const CounterpartsInfo: React.FC<Props> = ({ defaultTabKey }) => {
  const { data, isLoading } = useGetCompanyPersonStatistics(defaultTabKey);

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.info}>
        <InfoLeft statistics={data} defaultTabKey={defaultTabKey} />
        <InfoRight />
      </div>
    </Spin>
  );
};

export default CounterpartsInfo;
