import React from "react";
import { Form, FormInstance, Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { useGetCurrenciesSelect } from "../../../../../../../app/service/queries";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";

const { Item } = Form;
const { Option } = Select;

type Props = {
  index: number;
  isWarehouse?: boolean;
  form: FormInstance;
};

const PartyCurrencySelect: React.FC<Props> = ({ index, isWarehouse, form }) => {
  const { data: currencies } = useGetCurrenciesSelect();

  const { isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  // const selectedId = () => form.getFieldValue(["warehouse_products", index, "currency_id"]);
  //
  // const selected = () => currencies?.find(item => item?.id === selectedId());

  return (
    // <PerfItem
    //   placeholder={<div className="px-[0.62rem] py-4">{selected()?.symbol}</div>}
    //   visible={!(isView && !updateProduct?.condition)}
    // >
    <Item name={[index, "currency_id"]} rules={!isEditing && !isWarehouse ? formRules()! : []}>
      <Select
        suffixIcon={null}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        disabled={isView && !updateProduct?.condition}
        placeholder={isView ? "Tanlanmagan" : "Valyuta"}
      >
        {currencies?.map(item => (
          <Option value={item.id} key={item.id}>
            {item?.symbol}
          </Option>
        ))}
      </Select>
    </Item>
    // </PerfItem>
  );
};

export default PartyCurrencySelect;
