import React, { useEffect, useRef, useState } from "react";
import { ColumnType as BaseColumnType } from "antd/es/table";
import BaseTable, { TableProps as BaseTableProps } from "antd/lib/table";
import ChevronLeftIcon from "features/projects/assets/icons/ChevronLeftIcon";
import { Resizable, ResizableProps, ResizeCallbackData } from "react-resizable";

import { cx } from "modules/common";

import cls from "./table.module.css";

type ColumnsType<TRecord extends object = any> = Array<
  BaseColumnType<TRecord> & {
    minWidth?: number;
  }
>;
export interface TableProps<TRecord extends object = any> extends Omit<BaseTableProps<TRecord>, "columns"> {
  onChangeColumns: (columns: ColumnsType<TRecord>) => void;
  columns: ColumnsType<TRecord>;
}
const ResizableTitle = ({ onResize, width, ...restProps }: ResizableProps) => {
  if (!width) return <th {...restProps} />;
  return (
    <Resizable
      width={width}
      height={0}
      handle={<span className="handler" onClick={e => e.stopPropagation()} />}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

function Table<TRecord extends object>({ columns = [], onChangeColumns, ...props }: TableProps<TRecord>) {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const [scrollInterval, setScrollInterval] = useState<NodeJS.Timeout | null>(null);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);
  const updateScrollPositions = (scrollElement: HTMLDivElement) => {
    setIsAtStart(scrollElement.scrollLeft === 0);
    setIsAtEnd(scrollElement.scrollLeft + scrollElement.clientWidth >= scrollElement.scrollWidth);
  };
  const checkHorizontalScroll = (scrollElement: HTMLDivElement) => {
    setHasHorizontalScroll(scrollElement.scrollWidth > scrollElement.clientWidth);
  };
  const handleScroll = (direction: "left" | "right") => {
    if (scrollableRef.current) {
      const scrollDistance = direction === "left" ? -25 : 25;

      scrollableRef.current.scrollLeft += scrollDistance;
      updateScrollPositions(scrollableRef.current);
    }
  };
  const startScrolling = (direction: "left" | "right") => {
    if (!scrollInterval) {
      const interval = setInterval(() => handleScroll(direction), 40);

      setScrollInterval(interval);
    }
  };
  const stopScrolling = () => {
    if (scrollInterval) {
      clearInterval(scrollInterval);
      setScrollInterval(null);
    }
  };
  const converted = columns.map((column, index) => ({
    ...column,
    onHeaderCell: () =>
      ({
        width: column.width,
        onResize(e: React.SyntheticEvent, { size }: ResizeCallbackData) {
          const nextColumns = [...columns];

          column.minWidth = 150;
          if (size.width < column.minWidth) {
            size.width = column.minWidth;
          }
          nextColumns[index] = { ...nextColumns[index], width: size.width };
          onChangeColumns(nextColumns);
        }
      }) as ResizableProps
  })) as ColumnsType<TRecord>;

  const x = converted.reduce((a, c) => a + Number(c.width) || 0, 0);

  useEffect(() => {
    if (tableContainerRef.current) {
      scrollableRef.current =
        (tableContainerRef.current.querySelector(".ant-table-body") as HTMLDivElement) ||
        (tableContainerRef.current.querySelector(".ant-table-content") as HTMLDivElement);
      if (scrollableRef.current) {
        updateScrollPositions(scrollableRef.current);
        checkHorizontalScroll(scrollableRef.current);
        const handleScrollEvent = () => {
          updateScrollPositions(scrollableRef.current!);
        };

        scrollableRef.current!.addEventListener("scroll", handleScrollEvent);
        return () => {
          scrollableRef.current!.removeEventListener("scroll", handleScrollEvent);
        };
      }
    }
    return () => {};
  }, [columns]);
  return (
    <div className={cx(cls.container, "custom_table_container")} ref={tableContainerRef}>
      {hasHorizontalScroll && (
        <div className={cls.scrolls_container}>
          <span
            className={cx(cls.left_scroll, isAtStart && cls.scroll_none)}
            onMouseDown={() => !isAtStart && startScrolling("left")}
            onMouseUp={stopScrolling}
            onMouseLeave={stopScrolling}
            onMouseEnter={() => !isAtStart && startScrolling("left")}
          >
            <ChevronLeftIcon />
          </span>
          <span
            className={cx(cls.right_scroll, isAtEnd && cls.scroll_none)}
            onMouseDown={() => !isAtEnd && startScrolling("right")}
            onMouseUp={stopScrolling}
            onMouseLeave={stopScrolling}
            onMouseEnter={() => !isAtEnd && startScrolling("right")}
          >
            <ChevronLeftIcon rotate={180} />
          </span>
        </div>
      )}
      <div className={cls.table_container}>
        <BaseTable
          components={{ header: { cell: ResizableTitle } }}
          columns={converted}
          className={cls.wrapper}
          scroll={{ x }}
          {...props}
          onScroll={(e: React.UIEvent<HTMLDivElement>) => updateScrollPositions(e.currentTarget)}
        />
      </div>
    </div>
  );
}
export default Table;
