import React, { useState } from "react";
import { Breadcrumb, Button, Input, Tabs, TabsProps } from "antd";

import {
  useGetCategories,
  useGetCategoryProducts,
  useGetSearchProducts,
  useGetSubCategories,
  useGetSubCategoryProducts,
} from "../../../settings/service/queries";
import DrawerTable from "./table/DrawerTable";
import DrawerFolders from "./folders/DrawerFolders";
import { SearchIcon } from "../../../app/assets/icons/SearchIcon";
import { BreadcrumbItemType } from "antd/lib/breadcrumb/Breadcrumb";
import DrawerDetailedFolder from "./detailed-folder/DrawerDetailedFolder";
import BreadcrumbSeparatorIcon from "../../../app/assets/icons/BreadcrumbSeparatorIcon";

import styles from "./drawerChildren.module.scss";
import { useAppSelector } from "../../../../hooks/redux";

type Props = {
  page: number;
  tabKey: string;
  visible: boolean;
  isPrice?: boolean;
  breadcrumbItems: BreadcrumbItemType[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setVisibleCreateProduct: React.Dispatch<React.SetStateAction<boolean>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
};

const DrawerChildren: React.FC<Props> = ({
  page,
  tabKey,
  visible,
  setPage,
  isPrice,
  setTabKey,
  breadcrumbItems,
  setBreadcrumbItems,
  setVisibleCreateProduct,
}) => {
  const [searchValue, setSearchValue] = useState<string>();
  const { folderId } = useAppSelector((state) => state.supplyReducer.productDrawer);

  const { data: searchProducts, isLoading: isLoadingSearch } = useGetSearchProducts(
    searchValue,
    tabKey
  );

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTimeout(() => {
      setSearchValue(e.currentTarget?.value);
    }, 800);
  };

  const onOpenCreateProduct = () => {
    setVisibleCreateProduct(true);
  };

  const { data: categories, isLoading: isLoadingCategories } =
    useGetCategories(visible);
  const { data: subCategories, isLoading: isLoadingSubCategories } =
    useGetSubCategories(folderId?.category_id);
  const { data: categoryProducts, isLoading: isLoadingCategoryProducts } =
    useGetCategoryProducts(folderId?.category_id, page, searchValue, tabKey);
  const { data: subCategoryProducts, isLoading: isLoadingSubCategoryProducts } =
    useGetSubCategoryProducts(folderId?.sub_category_id, page, searchValue, tabKey);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "",
      children: (
        <DrawerFolders
          data={categories}
          isCategory={true}
          setTabKey={setTabKey}
          isLoading={isLoadingCategories}
          setBreadcrumbItems={setBreadcrumbItems}
          setPage={setPage}
        />
      ),
    },
    {
      key: "2",
      label: "",
      children: (
        <DrawerDetailedFolder
          page={page}
          setPage={setPage}
          isPrice={isPrice}
          data={subCategories}
          setTabKey={setTabKey}
          products={categoryProducts}
          isLoading={isLoadingSubCategories}
          setBreadcrumbItems={setBreadcrumbItems}
          isLoadingProducts={isLoadingCategoryProducts}
        />
      ),
    },
    {
      key: "3",
      label: "",
      children: (
        <DrawerTable
          page={page}
          setPage={setPage}
          isPrice={isPrice}
          data={subCategoryProducts}
          isLoading={isLoadingSubCategoryProducts}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className={styles.template__top}>
        <div className={styles.template__actions}>
          <Breadcrumb
            items={breadcrumbItems}
            separator={<BreadcrumbSeparatorIcon />}
          />
          <div className={styles.template__filter}>
            <Button onClick={onOpenCreateProduct} disabled={+tabKey <= 1}>
              Mahsulot qo’shish
            </Button>
            <div className={styles.line} />
            <Input
              placeholder="Qidirish"
              prefix={<SearchIcon />}
              onChange={onChangeSearch}
              className={styles.search}
            />
          </div>
        </div>
      </div>
      <div className={styles.template__bottom}>
        {searchValue && tabKey === "1" ? (
          <DrawerTable
            data={searchProducts}
            isLoading={isLoadingSearch}
            page={page}
            setPage={setPage}
          />
        ) : (
          <Tabs
            activeKey={tabKey}
            items={items}
            tabBarStyle={{
              display: "none",
            }}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default DrawerChildren;
