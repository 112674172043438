import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Select } from "antd";
import { Rule } from "antd/es/form";
import { FormInstance } from "antd/lib";
import { colors } from "features/app/utils/constants/colors";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "../../../../../../app/components/project-select/project-select";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../../app/utils/constants/languages";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { useGetFinancial } from "../../../../../../settings/service/queries";
import { useGetCashMoneyProjects, useGetInventorySelect } from "../../../../../service/queries";
import Additional from "../../../pay-modal/additional";

import styles from "./thirdStep.module.scss";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { useGetCompanyPerson } from "features/counterparts/service/queries";
import { cx } from "modules/common";

const { Item } = Form;
const { Option } = Select;

type Props = {
  step: number;
  form: FormInstance;
};

const ThirdStep = ({ step, form }: Props) => {
  const { t } = useTranslation();
  const [isVisibleMoreInfo, setIsVisibleMoreInfo] = useState<boolean>(false);
  const { currencies } = useAuth();
  const { cash, currency, debtAmount, isInventory, payment_type, defaultCurrency } = useAppSelector(
    state => state.paymentReducer.paymentInventoryWorkModal
  );

  const baseCurrency = currencies.find(item => item.type === "base");

  const activeCurrency = currencies?.find(item => item.id === currency?.id);
  const { data: getCompanyPerson } = useGetCompanyPerson(Number(payment_type?.company_person?.id));

  const { data: cashMoney } = useGetCashMoneyProjects({
    cash_id: cash?.id,
    currency_id: currency?.id,
    payment_type_id: payment_type?.id
  });
  const { data: financial } = useGetFinancial(step === 2);
  const { data: inventories, isLoading: isLoadingInventories } = useGetInventorySelect(step, isInventory);
  const isCurrencyAmount = baseCurrency?.id !== currency?.id;

  const acceptedAmountRules = (): Rule[] | undefined => [
    {
      validator(_, value: string) {
        const parseValue = parseLocaledString(value);
        let newDebtAmount = 0;

        if (defaultCurrency?.id === currency?.id) {
          newDebtAmount = Number(debtAmount);
        } else {
          const orderCurrencyAmount = Number(currencies?.find(item => item?.id === defaultCurrency?.id)?.amount);

          if (orderCurrencyAmount > Number(activeCurrency?.amount))
            newDebtAmount = Number(debtAmount) * orderCurrencyAmount;
          else newDebtAmount = Number(debtAmount) / Number(activeCurrency?.amount);
        }

        const projectId = form.getFieldValue("project_id");
        const projectAmount = cashMoney?.find(item => item.project?.id === projectId)?.amount ?? 1;

        if (projectAmount < newDebtAmount && projectId) {
          if (parseValue > projectAmount || !value) {
            return Promise.reject();
          }
          return Promise.resolve();
        }
        if (parseValue > newDebtAmount || !value) {
          return Promise.reject();
        }
        return Promise.resolve();
      },
      message: "Mablag' yetarli emas"
    }
  ];

  const onOpenMoreInfo = () => {
    setIsVisibleMoreInfo(!isVisibleMoreInfo);
  };

  const onChangeCurrencyAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("currency_amount", localeFormatter(e.currentTarget.value));
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("amount", localeFormatter(e.currentTarget.value));
  };

  const debtAmountElement = () => {
    let totalDebtAmount: number | string = 0;

    if (defaultCurrency?.id === currency?.id) {
      totalDebtAmount = Number(debtAmount)?.toLocaleString(RU);
    } else {
      const orderCurrencyAmount = Number(currencies?.find(item => item?.id === defaultCurrency?.id)?.amount);

      if (orderCurrencyAmount > Number(activeCurrency?.amount))
        totalDebtAmount = (Number(debtAmount) * orderCurrencyAmount)?.toLocaleString(RU);
      else totalDebtAmount = (Number(debtAmount) / Number(activeCurrency?.amount)).toFixed(3);
    }

    return (
      <p className={styles.remainder}>
        <span>Qoldiq:</span>
        <span>
          <b>{totalDebtAmount}</b>
        </span>
        <span>
          <b>{currency?.symbol}</b>
        </span>
      </p>
    );
  };

  const initialTotalAmount = () => {
    let totalAmount: number = 0;

    if (defaultCurrency?.id === currency?.id) {
      totalAmount = Number(debtAmount);
    } else {
      const orderCurrencyAmount = Number(currencies?.find(item => item?.id === defaultCurrency?.id)?.amount);

      if (orderCurrencyAmount > Number(activeCurrency?.amount)) totalAmount = Number(debtAmount) * orderCurrencyAmount;
      else totalAmount = Number(debtAmount) / Number(activeCurrency?.amount);
    }

    return totalAmount;
  };

  useEffect(() => {
    if (step === 2) {
      if (isCurrencyAmount) {
        form.setFieldValue("currency_amount", localeFormatter(String(activeCurrency?.amount)));
      }

      form.setFieldValue("amount", localeFormatter(String(initialTotalAmount())));
    }
  }, [step, isCurrencyAmount]);

  const personColorStyle = (amount: number): React.CSSProperties => {
    if (amount > 0) {
      return {
        background: colors.SUCCESS_50,
        color: colors.SUCCESS_500
      };
    }
    if (amount < 0) {
      return {
        background: colors.ERROR_50,
        color: colors?.ERROR_500
      };
    }

    return {
      background: colors.GRAY_50,
      color: colors.GRAY_500
    };
  };

  return (
    <div className={styles.payment_create}>
      <ConditionalRender if={payment_type?.company_person?.id}>
        <div className="mb-5 flex items-center gap-2">
          <span className="text-sm font-normal text-gray-600">{t("payment.Kontragent")}</span>
          <span className="text-base font-semibold text-gray-700">{payment_type?.company_person?.name}</span>
        </div>
      </ConditionalRender>
      <div className={styles.form}>
        <ProjectSelect label={t("payment.Loyiha")} />
        <Row gutter={16} className={styles.form__block}>
          <Col span={isCurrencyAmount ? 14 : 24}>
            <Item name="amount" label={t("payment.Summa")} rules={acceptedAmountRules()}>
              <Input onChange={onChangeAmount} suffix={currency?.symbol} />
            </Item>
          </Col>
          {isCurrencyAmount && (
            <Col span={10}>
              <Item name="currency_amount" label={t("payment.Valyuta kursi")} rules={formRules()}>
                <Input
                  placeholder={t("payment.Summa")}
                  onChange={onChangeCurrencyAmount}
                  prefix={`${currency?.symbol} = `}
                  suffix={baseCurrency?.symbol}
                />
              </Item>
            </Col>
          )}
        </Row>
        {!isInventory && debtAmountElement()}
        {payment_type?.company_person?.id && getCompanyPerson?.balances && getCompanyPerson?.balances?.length > 0 && (
          <div className="my-4 flex items-center gap-2">
            {t("payment.Balans")}:{" "}
            <div className="flex items-center gap-2">
              {getCompanyPerson?.balances?.map((item, i) => (
                <span
                  key={item.id}
                  className={cx(
                    { "text-error-500": item?.amount < 0, "bg-error-50": item?.amount < 0 },
                    { "text-success-500": item?.amount > 0, "bg-success-50": item?.amount > 0 },
                    { "text-gray-700": item?.amount === 0, "bg-gray-50": item?.amount === 0 },
                    "rounded-lg px-2 py-1 font-medium"
                  )}
                >
                  {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                </span>
              ))}
            </div>
          </div>
        )}
        {isInventory && (
          <Item name="inventory" label={t("payment.Inventarlar")} rules={formRules()}>
            <Select
              showSearch
              placeholder={t("payment.Inventarni tanlang")}
              loading={isLoadingInventories}
              suffixIcon={<SelectSuffixIcon />}
              filterOption={selectFilterOption}
              notFoundContent={<TableEmpty />}
            >
              {inventories?.map(inventory => (
                <Option
                  key={inventory.id}
                  value={JSON.stringify(inventory)}
                  props={{
                    name: inventory?.name
                  }}
                >
                  {inventory.name}
                </Option>
              ))}
            </Select>
          </Item>
        )}
        {isInventory && (
          <Item name="financial_id" label={t("payment.Xarajat")} rules={formRules()}>
            <Select
              showSearch
              placeholder={t("payment.Xarajat tanlang")}
              loading={isLoadingInventories}
              suffixIcon={<SelectSuffixIcon />}
              filterOption={selectFilterOption}
              notFoundContent={<TableEmpty />}
            >
              {financial?.data?.expense?.map(finance => (
                <Option
                  key={finance.id}
                  value={finance?.id}
                  props={{
                    name: finance?.name
                  }}
                >
                  {finance.name}
                </Option>
              ))}
            </Select>
          </Item>
        )}
        <Additional />
      </div>
    </div>
  );
};

export default ThirdStep;
