import { CustomFieldTypesEnum } from "../enums/customFieldTypesEnum";

export const customFieldColumnSize: Record<CustomFieldTypesEnum, number> = {
  [CustomFieldTypesEnum.CHECKBOX]: 120,
  [CustomFieldTypesEnum.FILE]: 170,
  [CustomFieldTypesEnum.DATE]: 170,
  [CustomFieldTypesEnum.LABEL]: 120,
  [CustomFieldTypesEnum.SELECT]: 150,
  [CustomFieldTypesEnum.TEXT]: 150,
};
