import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { memo } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import { ProductModel } from "../../../../../utils/models/estimateModel";
import { EditInput } from "../editInput/EditInput";

import styles from "./Product.module.scss";

type Props = {
  product: ProductModel;
  sectionIndex: number;
  taskIndex: number;
  index: number;
};

const Product = memo(({ product, sectionIndex, taskIndex, index }: Props) => {
  const dispatch = useAppDispatch();
  const sections = useAppSelector((state) => state.projectsReducer.sections);
  const { setEditProduct } = projectsReducerActions;

  const onChange = (event: CheckboxChangeEvent) => {
    dispatch(
      setEditProduct({
        sectionIndex,
        taskIndex: taskIndex,
        productIndex: index,
        editKey: "checked",
        editValue: event.target.checked,
      })
    );
  };

  return (
    <div
      className={`${styles.product} ${
        !product.checked ||
        !sections[sectionIndex].checked ||
        !sections[sectionIndex].tasks[taskIndex].checked
          ? styles.disabled
          : ""
      }`}
    >
      <Checkbox
        onChange={onChange}
        disabled={
          !sections[sectionIndex].checked ||
          !sections[sectionIndex].tasks[taskIndex].checked
        }
        checked={
          product.checked &&
          sections[sectionIndex].checked &&
          sections[sectionIndex].tasks[taskIndex].checked
        }
      />
      <div className={styles.info}>
        <EditInput
          classNames={styles.info__name}
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          productIndex={index}
          editKey="name"
        >
          {String(product.name)}
        </EditInput>
        <EditInput
          classNames={styles.info__quantity}
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          productIndex={index}
          editKey="quantity"
        >
          {String(product.quantity)}
        </EditInput>
        <EditInput
          classNames={styles.info__unit}
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          productIndex={index}
          editKey="unit"
        >
          {String(product.unit)}
        </EditInput>
        <EditInput
          classNames={styles.info__amount}
          sectionIndex={sectionIndex}
          taskIndex={taskIndex}
          productIndex={index}
          editKey="amount"
        >
          {String(product.amount)}
        </EditInput>
        <div className={styles.info__total}>
          {(product.amount * product.quantity).toFixed(2)}
        </div>
      </div>
    </div>
  );
});

export default Product;
