import React from "react";
import { Collapse, Spin, Tooltip } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import ExpectedTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/section/expected/ExpectedTotalAmount";
import { useGetDynamicCustomField } from "features/projects/service/queries";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { colors, RU } from "modules/common";
import {
  EstimateStatus,
  estimateStatusIcons,
  typeValues,
  useProjectColumnSections,
  useWidget,
  WidgetNoData
} from "modules/dashboard";

import Task from "./task";

import styles from "../project-by-section.module.scss";

const { Panel } = Collapse;

type Props = {
  isCustomField: boolean;
};

const Section: React.FC<Props> = ({ isCustomField }) => {
  const { i18n } = useTranslation();
  const { id, visible, props, methods, widget } = useWidget();
  const projectId = props?.projectId;
  const { queries } = useQueryParams();
  const { estimate_type: estimateType } = queries();
  const { sections, isLoading } = useProjectColumnSections({
    id,
    enabled: visible && !isCustomField,
    project_id: projectId
  });

  const { data, isLoading: isCustomFieldLoading } = useGetDynamicCustomField({
    projectId: isCustomField && projectId,
    customFieldId: String(widget?.filter?.custom_field_id),
    type: widget?.filter?.type || ""
  });

  const onChangeCollapse = (key: string | string[]) => {
    if (typeof key === "object") {
      const newKey = key.length ? key[key.length - 1] : undefined;

      methods.setWidgetDrawer({
        props: {
          ...props,
          sectionId: newKey || props?.sectionId,
          sectionKeys: key
        }
      });
    }
  };

  return (
    <div className="w-full">
      {!isCustomField ? (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <div className="box-border p-3">
            {!isEmptyArr(sections) && (
              <Collapse
                accordion
                bordered={false}
                expandIcon={() => null}
                onChange={onChangeCollapse}
                rootClassName={styles.section}
                activeKey={props?.sectionKeys || []}
              >
                {sections?.map(section => (
                  <Panel
                    key={section.id}
                    header={
                      <div className="flex items-center justify-between">
                        <h3 className="m-0 text-2xl font-semibold text-gray-700">
                          {section.place}. {section.name}
                        </h3>
                        <div className="flex items-center gap-2">
                          {estimateType !== typeValues.dynamic ? (
                            <h3 className="m-0 text-2xl font-semibold text-gray-700">
                              {section?.total_amount?.toLocaleString(RU)}
                            </h3>
                          ) : (
                            <ExpectedTotalAmount
                              isPopover
                              section={section as never}
                              className="m-0 text-2xl font-semibold text-gray-700"
                            />
                          )}
                          {estimateType === typeValues.dynamic && (
                            <Tooltip title={`${section.process} %`} placement="top">
                              <div className="flex items-center">
                                {estimateStatusIcons[section.status as EstimateStatus]}
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    }
                  >
                    <Task sectionPlace={section.place} />
                  </Panel>
                ))}
              </Collapse>
            )}
            {isEmptyArr(sections) && <WidgetNoData />}
          </div>
        </Spin>
      ) : (
        <Spin spinning={isCustomFieldLoading} indicator={LoadingIndicator}>
          <div className="flex flex-col gap-4">
            {data?.map(item => (
              <div
                key={item?.id}
                className="flex flex-col gap-3 rounded-2xl border border-solid border-gray-100 bg-white py-12"
              >
                <div className="flex items-center justify-between px-7">
                  <div className="flex items-center gap-2">
                    <span
                      className="m-0 px-2 text-2xl font-semibold"
                      style={{
                        color: item?.color ? item?.color : colors.GRAY_700,
                        borderRight: item?.process > 0 ? `3px solid ${colors.GRAY_300}` : ""
                      }}
                    >
                      {item.name[i18n.language]}
                    </span>
                    {item?.process > 0 && (
                      <span className="text-2xl font-semibold text-gray-400">{item?.process}%</span>
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    {estimateType !== typeValues.dynamic ? (
                      <h3 className="m-0 text-2xl font-semibold text-gray-700">
                        {item?.total_amount?.toLocaleString(RU)}
                      </h3>
                    ) : (
                      <ExpectedTotalAmount isPopover section={item as never} className="m-0 text-2xl font-semibold" />
                    )}
                  </div>
                </div>
                <div className="box-border px-4 py-[0.0625rem]">
                  {!isEmptyArr(item?.sections) && (
                    <Collapse
                      accordion
                      bordered={false}
                      expandIcon={() => null}
                      onChange={onChangeCollapse}
                      rootClassName={styles.custom_field_section}
                      activeKey={props?.sectionKeys || []}
                    >
                      {item?.sections?.map(section => (
                        <Panel
                          key={section.id}
                          header={
                            <div className="flex items-center justify-between">
                              <h3 className="m-0 text-2xl font-semibold text-gray-600">
                                {section.place}. {section.name}
                              </h3>
                              <div className="flex items-center gap-2">
                                {estimateType !== typeValues.dynamic ? (
                                  <h3 className="m-0 text-2xl font-semibold text-gray-600">
                                    {section?.total_amount?.toLocaleString(RU)}
                                  </h3>
                                ) : (
                                  <ExpectedTotalAmount
                                    isPopover
                                    section={section as never}
                                    className="m-0 text-2xl font-semibold"
                                  />
                                )}
                                {estimateType === typeValues.dynamic && (
                                  <Tooltip title={`${section.process} %`} placement="top">
                                    <div className="flex items-center">
                                      {estimateStatusIcons[section?.status as EstimateStatus]}
                                    </div>
                                  </Tooltip>
                                )}
                              </div>
                            </div>
                          }
                        >
                          <Task sectionPlace={section.place} />
                        </Panel>
                      ))}
                    </Collapse>
                  )}
                </div>
              </div>
            ))}
          </div>
          {isEmptyArr(data) && <WidgetNoData />}
        </Spin>
      )}
    </div>
  );
};

export default Section;
