import { Button, Modal, Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import CloseIcon from "../../../../../../app/assets/icons/CloseIcon";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { useGetWorkPlan } from "../../../../../service/queries";
import { WorkProgressModel } from "../../../../../utils/models/WorkProgressModel";

import styles from "./workPlan.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  id?: number;
};

const WorkPlan = ({ open, setOpen, id }: Props) => {
  const { i18n } = useTranslation();
  const { currencies } = useAuth();
  const baseCurrency = currencies?.find(item => item.type === "base");
  const { data: plan, isLoading } = useGetWorkPlan(open, id);

  const onCancel = () => {
    setOpen(false);
  };

  const footer = () => (
    <div className={styles.footer}>
      <Button onClick={onCancel}>Yopish</Button>
    </div>
  );

  const columns: ColumnsType<WorkProgressModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: WorkProgressModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language]?.length > 50 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 50)}...</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      ),
      width: "40%"
    },
    {
      title: "Hajmi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.quantity?.toLocaleString(RU) ?? 0} {record?.unit?.symbol[i18n.language]}
        </>
      ),
      width: "20%"
    },
    {
      title: "Birlik summasi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.amount?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
        </>
      ),
      width: "20%"
    },
    {
      title: "Umumiy summasi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.total_amount?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
        </>
      ),
      width: "20%"
    }
  ];

  const rowKey = (record: WorkProgressModel) => record.id;

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      title="Reja"
      closeIcon={<CloseIcon />}
      footer={footer()}
      className={styles.work_plan}
      width={1111}
    >
      <Table<WorkProgressModel>
        dataSource={plan}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        className={styles.table}
      />
    </Modal>
  );
};

export default WorkPlan;
