import React from "react";
import { Button, Drawer } from "antd";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../../hooks/redux";
import EstimateTabs from "../../estimate-tabs/EstimateTabs";
import { useGetSupplyProjects } from "../../../service/queries";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";

import styles from "./partyEstimate.module.scss";
import {useTranslation} from "react-i18next";

type Props = {
  importAction: () => void;
  importAsyncAction?: () => Promise<unknown>;
  clearAction: () => void;
  isLoading?: boolean;
};

const PartyEstimate: React.FC<Props> = ({
  importAction,
  clearAction,
  isLoading,
  importAsyncAction,
}) => {
  const dispatch = useDispatch();
  const { setPartyEstimate, setEstimateProjectId, setEstimateData } = supplyActions;
  const { visible } = useAppSelector((state) => state.supplyReducer.partyEstimate);
  const { t } = useTranslation();

  const { products, projectId } = useAppSelector(
    (state) => state.supplyReducer.estimateData
  );

  const { data: projects } = useGetSupplyProjects(visible);

  const onOpenAfter = (open: boolean) => {
    if (open) {
      if (!projectId) {
        dispatch(setEstimateProjectId(String(projects?.[0]?.id)));
      }
    } else {
      dispatch(
        setEstimateData({
          key: "sectionIds",
          data: [],
        })
      );

      dispatch(
        setEstimateData({
          key: "taskIds",
          data: [],
        })
      );

      dispatch(
        setEstimateData({
          key: "products",
          data: [],
        })
      );
    }
  };

  const onClear = () => {
    dispatch(
      setEstimateData({
        key: "sectionIds",
        data: [],
      })
    );

    dispatch(
      setEstimateData({
        key: "taskIds",
        data: [],
      })
    );

    dispatch(
      setEstimateData({
        key: "products",
        data: [],
      })
    );

    clearAction();
  };

  const onImportData = () => {
    // qc.invalidateQueries([
    //   paymentQueryKeys.PROJECT_SELECT,
    //   undefined,
    //   undefined,
    //   warehouses?.[0]?.id,
    // ]).finally(() => {
    //   setIsLoading(false);
    // });
    if (importAsyncAction) {
      importAsyncAction().then((isSuccess) => {
        if (isSuccess) {
          onClose();
        }
      });
    } else {
      importAction();
      onClose();
    }
  };

  const footer = () => (
    <div className={styles.footer}>
      <div className={styles.footer__left}>
        <h4>Tanlangan mahsulotlar: {products?.length ?? 0} ta</h4>
      </div>
      <div className={styles.footer__right}>
        <Button onClick={onClose}>{t("partyModal.Yopish")}</Button>
        <Button
          className={styles.footer__clear}
          disabled={!(products?.length > 0)}
          onClick={onClear}
        >
          <XCloseIcon /> {t("partyModal.Qo’shilganlarni bekor qilish")}
        </Button>
        <Button
          type="primary"
          loading={isLoading}
          onClick={onImportData}
          disabled={!(products?.length > 0)}
        >
          {t("partyModal.Qo'shish")}
        </Button>
      </div>
    </div>
  );

  const onClose = () => {
    dispatch(
      setPartyEstimate({
        visible: false,
      })
    );
  };

  return (
    <Drawer
      afterOpenChange={onOpenAfter}
      open={visible}
      className={styles.estimate}
      onClose={onClose}
      footer={footer()}
      title={null}
      width="80rem"
    >
      <EstimateTabs projects={projects} isPrice />
    </Drawer>
  );
};

export default PartyEstimate;
