import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { useAppSelector } from "../../../../../../hooks/redux";
import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import SelectNotContent from "../../../../../app/components/select-not-content/SelectNotContent";
import { useGetUsersSelect } from "../../../../../app/service/queries";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";

import styles from "./offerPartyModalRight.module.scss";

const { Option } = Select;

const AgentSelect: React.FC = () => {
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const { setOfferPartyRightItemData } = supplyOfferActions;
  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const { visible, rightData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const { data: agents } = useGetUsersSelect(visible);

  const onAddAdmin = () => {
    dispatch(
      setAdminModal({
        visible: true,
        initialName: searchValue,
        afterFunc: (id: number) => {
          dispatch(
            setOfferPartyRightItemData({
              key: "agent_id",
              value: id
            })
          );
        }
      })
    );
  };

  const onChangeAgent = (e: number) => {
    dispatch(
      setOfferPartyRightItemData({
        key: "agent_id",
        value: e
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  return (
    <div className={styles.item}>
      <label>Vositachi</label>
      <Select
        showSearch
        allowClear
        onSearch={onSearch}
        placeholder="Tanlang"
        onChange={onChangeAgent}
        searchValue={searchValue}
        value={rightData?.agent_id}
        suffixIcon={<SelectSuffixIcon />}
        filterOption={selectFilterOption}
        notFoundContent={<SelectNotContent title="Vositachi" action={onAddAdmin} />}
      >
        {agents?.map(item => (
          <Option
            value={item.id}
            key={item.id}
            props={{
              name: item?.full_name
            }}
          >
            {item?.full_name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default AgentSelect;
