import { Button, Dropdown, MenuProps, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { ConfirmationPaymentView } from "modules/dashboard";

import { useAppDispatch } from "../../../../../hooks/redux";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { RU } from "../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { PaymentSourceElement } from "../../../../payment/components/bottom/income-expense-view/PaymentSourceElement";
import { paymentFilterType } from "../../../../payment/utils/constants/paymentFilterType";
import { useImpostPaymentsRecieved, useImpostPaymentsRejected } from "../../../service/mutations";
import { impostQueryKeys } from "../../../utils/constants/impostQueryKeys";

import styles from "./paymentView.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { PaymentImpostModel } from "features/impost/utils/models/paymentImpostModel";

// interface SourceModel {
//   name?: string;
//   key: string;
//   background: string;
//   color: string;
// }

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  record?: ConfirmationPaymentView;
  isView?: boolean;
};

const PaymentView = ({ visible, setVisible, record, isView }: Props) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const dispatch = useAppDispatch();
  const { setVisible: setVisibleChat } = chatActions;
  const recievedPayment = useImpostPaymentsRecieved();
  const rejectedPayment = useImpostPaymentsRejected();

  const onCancel = () => {
    setVisible(false);
  };

  const onRecievedPayment = () => {
    recievedPayment.mutateAsync({ id: record?.id }).then(onCancel);
  };

  const onRejectedPayment = () => {
    rejectedPayment.mutateAsync({ id: record?.id }).then(onCancel);
  };

  const onOpenChat = () => {
    dispatch(
      setVisibleChat({
        visible: true,
        type: ChatEnum.PAYMENTS,
        objectId: record?.id,
        // dataKeys: [impostQueryKeys.PAYMENTS]
        onAfterOpen: () => {
          if (record?.unread_message_count && record?.unread_message_count > 0) {
            qc.setQueryData(
              [impostQueryKeys.PAYMENTS, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as PaymentImpostModel[];
                return {
                  total: total,
                  current_page: current_page,
                  data: initialData?.map(item => {
                    if (item?.id === record?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
          }
        }
      })
    );
    onCancel();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className={styles.dropdown__item}>
          <UnreadMessageAction count={record?.unread_message_count} />
        </div>
      ),
      onClick: onOpenChat
    }
  ];

  const title = (
    <div className={styles.title}>
      <p> {t(`Monitoring.${paymentFilterType[record?.type as PaymentTypeEnum]}`)}</p>
      <div className={styles.title__actions}>
        <Dropdown menu={{ items }} trigger={["contextMenu", "click"]} overlayClassName={styles.dropdown}>
          <Button>
            <UnreadMessageDots count={record?.unread_message_count} />
          </Button>
        </Dropdown>
        <div onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footer}>
      {/* <div className={styles.footer__left}>
        <Button onClick={onOpenChat}>
          <MessageIcon />
        </Button>
      </div> */}
      <div className={styles.footer__right}>
        <Button onClick={onCancel}>{t("products.Yopish")}</Button>
        {record?.status === "passive" && (
          <>
            <Button onClick={onRejectedPayment} loading={rejectedPayment.isLoading}>
              {t("Xodimlar.Bekor qilish")}
            </Button>
            <Button type="primary" onClick={onRecievedPayment} loading={recievedPayment.isLoading}>
              {t("Monitoring.Tasdiqlash")}
            </Button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <Modal
      centered
      open={visible}
      footer={isView ?? footer}
      title={title}
      closeIcon={false}
      width={679}
      onCancel={onCancel}
      className={styles.income_expense_view}
    >
      <div className={styles.content}>
        <div className={styles.content__item}>
          <p>{t("Monitoring.To’lov sanasi")}</p>
          <p>{record?.date}</p>
        </div>
        <div className={styles.content__item}>
          <p>{t("Monitoring.Summa")}</p>
          <p>
            {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
          </p>
        </div>
        <div className={styles.content__item}>
          <p>{t("Monitoring.To'lov turi")}</p>
          <p
            className={styles.payment_type}
            style={{
              background: `${record?.payment_type.color}40`
            }}
          >
            <span
              style={{
                color: record?.payment_type.color
              }}
            >
              {record?.payment_type?.name}
            </span>
          </p>
        </div>
        <div className={styles.content__item}>
          <p>{t("Kassa.Kassa")}</p>
          <p>{record?.cash?.name as string}</p>
        </div>
        <div className={styles.content__item}>
          <p>{t("MainLayoutLinks.Loyiha")}</p>
          <p>{record?.project?.name as string}</p>
        </div>
        {!(record?.type === PaymentTypeEnum.EXCHANGE || record?.type === PaymentTypeEnum.TRANSFER) && (
          <div className={styles.content__item}>
            <p>{t("Monitoring.Manbaa")}</p>
            <PaymentSourceElement record={record as never} />
            {/* {paymentSourceElement(record)} */}
          </div>
        )}
        <div className={styles.content__item}>
          <p>{t("Monitoring.Xodim")}</p>
          <p>{record?.creator?.full_name}</p>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentView;
