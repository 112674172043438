const GanttSettingsIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 9L21 9M9 3L9 21M21 12.5V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21H12.5"
        stroke="#1E90FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g clipPath="url(#clip0_12763_91930)">
        <path
          d="M17.6975 22.6856L17.9897 23.3428C18.0766 23.5384 18.2184 23.7046 18.3979 23.8213C18.5773 23.938 18.7868 24 19.0009 24C19.2149 24 19.4244 23.938 19.6039 23.8213C19.7833 23.7046 19.9251 23.5384 20.012 23.3428L20.3042 22.6856C20.4082 22.4524 20.5832 22.2579 20.8042 22.13C21.0266 22.0017 21.2839 21.9471 21.5392 21.9739L22.2542 22.05C22.467 22.0725 22.6818 22.0328 22.8725 21.9357C23.0632 21.8385 23.2217 21.6882 23.3286 21.5028C23.4357 21.3175 23.4867 21.1051 23.4755 20.8914C23.4643 20.6777 23.3912 20.4719 23.2653 20.2989L22.842 19.7172C22.6912 19.5086 22.6107 19.2574 22.612 19C22.6119 18.7433 22.6932 18.4932 22.8442 18.2856L23.2675 17.7039C23.3935 17.5309 23.4665 17.325 23.4777 17.1113C23.489 16.8976 23.4379 16.6853 23.3309 16.5C23.2239 16.3146 23.0655 16.1642 22.8748 16.0671C22.684 15.97 22.4692 15.9303 22.2564 15.9528L21.5414 16.0289C21.2861 16.0557 21.0288 16.0011 20.8064 15.8728C20.585 15.7441 20.4099 15.5487 20.3064 15.3144L20.012 14.6572C19.9251 14.4616 19.7833 14.2954 19.6039 14.1787C19.4244 14.062 19.2149 14 19.0009 14C18.7868 14 18.5773 14.062 18.3979 14.1787C18.2184 14.2954 18.0766 14.4616 17.9897 14.6572L17.6975 15.3144C17.594 15.5487 17.419 15.7441 17.1975 15.8728C16.9751 16.0011 16.7179 16.0557 16.4625 16.0289L15.7453 15.9528C15.5325 15.9303 15.3177 15.97 15.127 16.0671C14.9362 16.1642 14.7778 16.3146 14.6709 16.5C14.5638 16.6853 14.5127 16.8976 14.524 17.1113C14.5352 17.325 14.6082 17.5309 14.7342 17.7039L15.1575 18.2856C15.3085 18.4932 15.3898 18.7433 15.3897 19C15.3898 19.2567 15.3085 19.5068 15.1575 19.7144L14.7342 20.2961C14.6082 20.4691 14.5352 20.675 14.524 20.8887C14.5127 21.1024 14.5638 21.3147 14.6709 21.5C14.7779 21.6853 14.9364 21.8356 15.1271 21.9327C15.3177 22.0298 15.5325 22.0696 15.7453 22.0472L16.4603 21.9711C16.7156 21.9443 16.9729 21.9989 17.1953 22.1272C17.4176 22.2555 17.5934 22.451 17.6975 22.6856Z"
          stroke="#1E90FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 20.5C19.8284 20.5 20.5 19.8284 20.5 19C20.5 18.1716 19.8284 17.5 19 17.5C18.1715 17.5 17.5 18.1716 17.5 19C17.5 19.8284 18.1715 20.5 19 20.5Z"
          stroke="#1E90FF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_12763_91930">
          <rect width="12" height="12" fill="white" transform="translate(13 13)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GanttSettingsIcon;
