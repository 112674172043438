import React from "react";
import { Form, FormInstance } from "antd";
import PlusCircleIcon from "features/app/assets/icons/PlusCircleIcon";
import Pagination from "features/app/components/pagination/Pagination";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useSelector } from "store";

import styles from "./selectedProductsTable.module.scss";

const { useWatch } = Form;

type Props = {
  data: PaginationType<WarehouseProductModel[]> | undefined;
  form: FormInstance<TransferBodyModel>;
  onPageChange: (page: number) => void;
};

const Footer: React.FC<Props> = ({ data, form, onPageChange }) => {
  const { t } = useTranslation();
  const { id } = useSelector(state => state.transferMakingReducer);
  const products = useWatch("products", form) as ITransferringResourcesModel[];

  const onAddProduct = () => {
    if (products?.length > 0) {
      form.setFieldValue("products", [
        ...products,
        {
          rowId: uid()
        }
      ]);
    } else {
      form.setFieldValue("products", [
        {
          rowId: uid()
        }
      ]);
    }
  };

  return (
    <div className={styles.footer}>
      {!id ? (
        <Pagination
          paginationProps={{
            total: data?.total,
            current: data?.current_page,
            onChange: onPageChange
          }}
        />
      ) : (
        <div className={styles.append_product} onClick={onAddProduct}>
          <PlusCircleIcon fill />
          <span>{t("warehouse.Resurs qo'shish")}</span>
        </div>
      )}
    </div>
  );
};

export default Footer;
