import { AllSubmodules } from "../../../../hooks/useRoutePermissions";
import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../app/utils/constants/queryParamsKeys";
import { PaymentTabKeys } from "../../../payment/utils/constants/paymentTabKeys";

type CompanyPersonRouteSubmodules = [
  {
    key: "Papkalar";
    actions: [
      { view: true; key: "Papkalar ro'yxati" },
      { create: true; key: "Papka yaratish" },
      { edit: true; key: "Papkani tahrirlash" },
      { delete: true; key: "Papkani o'chirish" }
    ];
  },
  {
    key: "Kontragentlar";
    actions: [
      { view: true; key: "Kontragentlar ro'yxati" },
      { create: true; key: "Kontragent yaratish" },
      { edit: true; key: "Kontragentni tahrirlash" },
      { delete: true; key: "Kontragentni o'chirish" },
      { act: true; key: "Akt Sverka ni ko'rish" }
    ];
  }
];

type CompanyPersonBalanceRouteSubmodules = [
  {
    key: "Kirim-chiqim";
    tabKey: string;
    actions: [
      {
        key: "Kirim-chiqimni ko'rish";
        view: true;
      },
      {
        key: "Statistika";
        statistics: true;
      }
    ];
  },
  {
    key: "Kutilayotgan to'lovlar";
    tabKey: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.INCOME}`;
    actions: [
      {
        key: "Kutilayotgan to'lovlarni ko'rish";
        view: true;
      },
      {
        key: "Statistika";
        statistics: true;
      }
    ];
  },
  {
    key: "Qarzlarim";
    tabKey: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.EXPENSE}`;
    actions: [
      {
        key: "Qarzlarimni ko'rish";
        view: true;
      },
      {
        key: "Statistika";
        statistics: true;
      }
    ];
  },
  {
    key: "Inventarlar";
    tabKey: string;
    actions: [
      {
        key: "Inventarlarni ko'rish";
        view: true;
      },
      {
        key: "Statistika";
        statistics: true;
      }
    ];
  },
  {
    key: "Act sverka";
    actions: [
      {
        key: "Act sverka ko'rish";
        view: true;
      }
    ];
  },
  {
    key: "Buyurtmalar";
    tabKey: string;
    actions: [
      {
        key: "Buyurtmalarni ko'rish";
        view: true;
      }
    ];
  },
  {
    key: "Ishlarga to'lovlar";
    tabKey: string;
    actions: [
      {
        key: "Ishlarga to'lovlarni ko'rish";
        view: true;
      }
    ];
  },
  {
    key: "To'lov kalendari";
    tabKey: string;
    actions: [
      {
        key: "To'lov kalendarini ko'rish";
        view: true;
      }
    ];
  }
];

export const companyPersonSubmodules: CompanyPersonRouteSubmodules = [
  {
    key: "Papkalar",
    actions: [
      { view: true, key: "Papkalar ro'yxati" },
      { create: true, key: "Papka yaratish" },
      { edit: true, key: "Papkani tahrirlash" },
      { delete: true, key: "Papkani o'chirish" }
    ]
  },
  {
    key: "Kontragentlar",
    actions: [
      { view: true, key: "Kontragentlar ro'yxati" },
      { create: true, key: "Kontragent yaratish" },
      { edit: true, key: "Kontragentni tahrirlash" },
      { delete: true, key: "Kontragentni o'chirish" },
      { act: true, key: "Akt Sverka ni ko'rish" }
    ]
  }
];

export const companyPersonBalanceSubmodules: CompanyPersonBalanceRouteSubmodules = [
  {
    key: "Kirim-chiqim",
    tabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INCOME_EXPENSE}`,
    actions: [
      {
        key: "Kirim-chiqimni ko'rish",
        view: true
      },
      {
        key: "Statistika",
        statistics: true
      }
    ]
  },
  {
    key: "Kutilayotgan to'lovlar",
    tabKey: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.INCOME}`,
    actions: [
      {
        key: "Kutilayotgan to'lovlarni ko'rish",
        view: true
      },
      {
        key: "Statistika",
        statistics: true
      }
    ]
  },
  {
    key: "Qarzlarim",
    tabKey: `statistics_type=${PaymentTabKeys.PAYMENT}&type=${PaymentTypeEnum.EXPENSE}`,
    actions: [
      {
        key: "Qarzlarimni ko'rish",
        view: true
      },
      {
        key: "Statistika",
        statistics: true
      }
    ]
  },
  {
    key: "Inventarlar",
    tabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INVENTORY}`,
    actions: [
      {
        key: "Inventarlarni ko'rish",
        view: true
      },
      {
        key: "Statistika",
        statistics: true
      }
    ]
  },
  {
    key: "Act sverka",
    actions: [
      {
        key: "Act sverka ko'rish",
        view: true
      }
    ]
  },
  {
    key: "Buyurtmalar",
    tabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.ORDER}`,
    actions: [
      {
        key: "Buyurtmalarni ko'rish",
        view: true
      }
    ]
  },
  {
    key: "Ishlarga to'lovlar",
    tabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.WORK}`,
    actions: [
      {
        key: "Ishlarga to'lovlarni ko'rish",
        view: true
      }
    ]
  },
  {
    key: "To'lov kalendari",
    tabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.CALENDAR}`,
    actions: [
      {
        key: "To'lov kalendarini ko'rish",
        view: true
      }
    ]
  }
];
