import { useParams } from "react-router-dom";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetResourcesWork } from "../../../../service/queries";
import { ResourcesWorkModel } from "../../../../utils/models/resourcesWorkModel";

import styles from "./tables.module.scss";

const ResourcesWork = () => {
  const { i18n } = useTranslation();
  const { projectId } = useParams();
  const { data: resources_work, isLoading } = useGetResourcesWork(+projectId!);

  const columns: ColumnsType<ResourcesWorkModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: ResourcesWorkModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language]?.length > 100 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 100)}...</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      )
    },
    {
      title: "Sana",
      dataIndex: "date"
    },
    {
      title: "Hajmi",
      render: (record: ResourcesWorkModel) => (
        <>
          {record?.quantity?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
        </>
      )
    },
    {
      title: "Birlik summasi",
      render: (record: ResourcesWorkModel) => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      )
    },
    {
      title: "Umumiy summasi",
      render: (record: ResourcesWorkModel) => (
        <>
          {(record?.quantity * record?.amount)?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      )
    }
  ];

  const rowKey = (record: ResourcesWorkModel) => record.id;

  return (
    <>
      <div className={styles.table}>
        <Table<ResourcesWorkModel>
          dataSource={resources_work?.data}
          columns={columns}
          pagination={false}
          rowKey={rowKey}
          rowClassName={() => styles.row}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{ spinning: isLoading, indicator: LoadingIndicator }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: resources_work?.total
          }}
        />
      </div>
    </>
  );
};

export default ResourcesWork;
