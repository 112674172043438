import React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  color?: string;
}

const PlusCircleIcon = (props: IProps) => {
  const { size = 20, color = "#1570EF" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.3084 6.66669V13.3334M6.9751 10H13.6418M18.6418 10C18.6418 14.6024 14.9108 18.3334 10.3084 18.3334C5.70606 18.3334 1.9751 14.6024 1.9751 10C1.9751 5.39765 5.70606 1.66669 10.3084 1.66669C14.9108 1.66669 18.6418 5.39765 18.6418 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusCircleIcon;
