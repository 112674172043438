import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { Button, Dropdown, Form, FormInstance, message, Popover, Spin } from "antd";
import classNames from "classnames";
import dayjs from "dayjs";
import WalletIcon from "features/payment/assets/icons/WalletIcon";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../../../store/reducers/supplyReducer";
import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../../app/assets/icons/EditIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../../../app/components/not-image/NotUserImage";
import { StatusEnums } from "../../../../../../app/components/statuses/statusEnums";
import { useGetUnitSelect } from "../../../../../../app/service/queries";
import { colors } from "../../../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { RU } from "../../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import { ErrorRes, SuccessRes } from "../../../../../../app/utils/models/responseType";
import XCircleIcon from "../../../../../../warehouse/assets/icons/XCircleIcon";
import CalculateIcon from "../../../../../assets/icons/CalculateIcon";
import CheckCircleIcon from "../../../../../assets/icons/CheckCircleIcon";
import PhoneCallIcon from "../../../../../assets/icons/PhoneCallIcon";
import {
  useOfferProductRecieved,
  useOfferProductRejected,
  useSupplyProductUpdate
} from "../../../../../service/mutations";
import { routeSubmodules } from "../../../../../utils/constants/routeSubmodules";
import { supplyQueryNames } from "../../../../../utils/constants/supplyQueryNames";
import { ProductOfferModel } from "../../../../../utils/models/OfferModel";
import {
  PartyFormOfferModel,
  PartyFormPersonGroupModel,
  PartyFormProductsModel
} from "../../../../../utils/models/partyModalFormModel";

import styles from "../../../../offers/table/offers.module.scss";
import parentStyles from "../person-group/partyPersonGroup.module.scss";

type Props = {
  index: number;
  form: FormInstance;
  groupIndex?: number;
  rowId?: string;
  data: PartyFormOfferModel[];
  isPerson?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { useWatch } = Form;

const PartyModalTableOffers: React.FC<Props> = ({
  data,
  rowId,
  form,
  index,
  groupIndex,
  isPerson = false,
  deleteProduct
}) => {
  const { t } = useTranslation();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const { data: units } = useGetUnitSelect();
  const { setOfferModal } = supplyOfferActions;
  const { setPartyUpdateProduct } = supplyActions;
  const acceptedOffer = useOfferProductRecieved();
  const rejectedOffer = useOfferProductRejected();
  const { severalSearchParams } = useQueryParams();
  const productUpdate = useSupplyProductUpdate();
  const searchParam = severalSearchParams(queryParamsKeys.TAB);

  const { party_id, isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);
  const status = useWatch("status", form) as StatusEnums;
  const isPersonGroup = useWatch("is_group_person", form) as boolean;
  const offers = useWatch("offers", form) as PartyFormOfferModel[];
  const product = useWatch(isPerson ? ["person_group", groupIndex, "data", index] : ["warehouse_products", index]);

  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");
  const productAction = actions("Buyurtmalar");

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: colors.SUCCESS_500,
    [StatusEnums.REJECTED]: colors.ERROR_500
  };

  const products = useMemo(() => {
    if (isPersonGroup && typeof groupIndex !== "undefined") {
      const personGroup = form.getFieldValue("person_group") as PartyFormPersonGroupModel[];

      const rowId = personGroup?.[groupIndex]?.data?.[index]?.row_id;

      return data?.filter(item => item.product_row_id === rowId);
    }
    const warehouseProducts = form.getFieldValue("warehouse_products") as PartyFormProductsModel[];

    const rowId = warehouseProducts?.[index]?.row_id;

    return data?.filter(item => item.product_row_id === rowId);
  }, [data, form, index, groupIndex, isPersonGroup]);

  const checkButtonClass = (item: ProductOfferModel, status: StatusEnums) => {
    if (item.status === status) {
      return styles[status];
    }
    if (item.status === StatusEnums.OPEN) {
      return styles[StatusEnums.OPEN];
    }
    return `${styles[StatusEnums.OPEN]}`;
  };

  const notUserBg = () => {
    const sliceProducts = products?.slice(2, products?.length);

    const isRecievedProduct = sliceProducts?.some(item => item.status === StatusEnums.RECIEVED);

    if (isRecievedProduct) return "#12B76A";
    return "";
  };

  const onAfterCheck = (status: StatusEnums, item: PartyFormOfferModel) => () => {
    qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]).then(() => {
      form.setFieldValue(
        "offers",
        data?.map(offer => {
          if (offer.product_row_id === item.product_row_id) {
            return {
              ...offer,
              status
            };
          }
          return offer;
        })
      );
      qc.invalidateQueries([supplyQueryNames.ORDERS, searchParam]).then();
    });
  };

  const onCheckProduct = (item: PartyFormOfferModel, status: StatusEnums) => () => {
    if (status === StatusEnums.RECIEVED) {
      acceptedOffer.mutateAsync(item.id).then(onAfterCheck(StatusEnums.RECIEVED, item));
    }
  };

  const productsItems = (
    <Spin spinning={acceptedOffer.isLoading || rejectedOffer.isLoading} indicator={LoadingIndicator}>
      <div className={styles.products__content}>
        {products?.map(item => (
          <div className={styles.products__label} key={item?.id}>
            <div className={styles.products__top}>
              <div className={styles.products__label__left}>
                <NotUserImage
                  key={item?.id}
                  name={item?.name ? item?.name : item?.company_person?.name}
                  background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
                />
                <div className={styles.products__name}>
                  <h5>{item?.name ? item?.name : item?.company_person?.name}</h5>
                  <div className="flex items-center gap-1">
                    <p>
                      {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                    </p>
                    {item?.vat && (
                      <span className="flex w-5 items-center justify-center rounded-lg border border-solid border-primary-500 bg-primary-500 p-0.5 text-[0.5rem] font-medium text-white">
                        {t("payment.NDS")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.products__label__right}>
                {offerActions.rejected && (
                  <ModalConfirm
                    title={t("payment.Taklifni rad qilish")}
                    description={t("payment.Nega taklifni rad etmoqdasiz sababini yozing.")}
                    mutation={rejectedOffer}
                    payload={{ id: item.id }}
                    onAfterClose={onAfterCheck(StatusEnums.REJECTED, item)}
                    isDescription={true}
                    okText={t("payment.Rad qilish")}
                    cancelText={t("payment.Yopish")}
                    confirmStatus={item.status}
                    requestKey="rejected_description"
                  >
                    <Button
                      className={classNames(styles.rejected__hover, checkButtonClass(item, StatusEnums.REJECTED))}
                    >
                      <XCircleIcon />
                    </Button>
                  </ModalConfirm>
                )}
                {offerActions.recieved && (
                  <Button
                    onClick={onCheckProduct(item, StatusEnums.RECIEVED)}
                    className={classNames(styles.recieved__hover, checkButtonClass(item, StatusEnums.RECIEVED))}
                  >
                    <CheckCircleIcon />
                  </Button>
                )}
              </div>
            </div>
            {(item?.phone || item?.description) && (
              <div className={styles.products__bottom}>
                {item?.phone && (
                  <div className={styles.products__bottom__item}>
                    <PhoneCallIcon />
                    <span>{item?.phone}</span>
                  </div>
                )}
                {item?.description && (
                  <div className={styles.products__bottom__item}>
                    <XCircleIcon />
                    <span>{item?.description}</span>
                  </div>
                )}
                {item?.rejected_description && (
                  <div className={styles.products__bottom__item}>
                    <XCircleIcon />
                    <span>{item?.rejected_description}</span>
                  </div>
                )}
              </div>
            )}
            {item?.payment_type && (
              <div className="flex items-center gap-2">
                <WalletIcon />
                {item?.payment_type?.name}
              </div>
            )}
          </div>
        ))}
      </div>
    </Spin>
  );

  const viewOffers = useMemo(() => isView && !isEmptyArr(offers), [isView, offers]);

  const onOpenOffer = (isEdit?: boolean) => () => {
    const itemData = form.getFieldValue(
      isPersonGroup ? ["person_group", groupIndex, "data", index] : ["warehouse_products", index]
    ) as PartyFormProductsModel;

    const selectProducts = form.getFieldValue("select_products") as ProductSelectModel[];

    const deliveryDate = form.getFieldValue("delivery_date");

    const findProduct = selectProducts.find(product => product.id === itemData.product_id) as ProductSelectModel;

    const findUnit = units?.find(unit => unit?.id === itemData.unit_id);

    dispatch(
      setOfferModal({
        isEdit,
        visible: true,
        viewPartySwitch: false,
        data: [
          {
            offers: products?.map((item, index) => ({
              amount: localeFormatter(String(item?.amount)),
              id: item?.id,
              company_person_id: item?.company_person?.id,
              currency_id: item?.currency?.id,
              name: item.company_person ? item.company_person?.name : item.name,
              description: item?.description,
              phone: item?.phone,
              uniqueId: uid(12),
              ordinalNumber: index + 1,
              vat: item?.vat,
              payment_type_id: item?.payment_type?.id
            })),
            amount: parseLocaledString(itemData?.amount),
            quantity: parseLocaledString(itemData?.quantity),
            delivery_date: dayjs(deliveryDate, dayjsFormats.DATE).format(dayjsFormats.DATE),
            product: {
              id: findProduct.id!,
              name: findProduct?.name,
              resource: findProduct?.resource
            },
            id: itemData.id!,
            unit: findUnit!
          }
        ]
      })
    );
  };

  const onUpdateProduct = () => {
    dispatch(
      setPartyUpdateProduct({
        condition: true,
        index: rowId
      })
    );
  };

  const onDeleteProduct = () => {
    deleteProduct.mutateAsync([product?.id]).then(async () => {
      await qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]);
    });
  };

  const offerActionItems = () => {
    const allItems = [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("payment.Taklifni tahrirlash")}
          </div>
        ),
        onClick: onOpenOffer(true),
        isView: viewOffers && !isEmptyArr(products)
      },
      {
        key: "2",
        label: (
          <div className="flex items-center gap-2">
            <CalculateIcon />
            {t("payment.Taklif yaratish")}
          </div>
        ),
        onClick: onOpenOffer(false),
        isView: !viewOffers && isEmptyArr(products)
      },
      {
        key: "3",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("payment.Tahrirlash")}
          </div>
        ),
        onClick: onUpdateProduct,
        isView: productAction?.edit
      },
      {
        key: "4",
        label: (
          <div className="flex items-center gap-2">
            <DeleteIcon />
            {t("payment.O'chirish")}
          </div>
        ),
        onClick: onDeleteProduct,
        isView: productAction?.delete && !product?.received_quantity
      }
    ];

    return {
      items: allItems?.filter(item => item?.isView)
    };
  };

  const onProduct = () => {
    const product = form.getFieldValue(
      isPerson ? ["person_group", groupIndex, "data", index] : ["warehouse_products", index]
    );

    productUpdate
      .mutateAsync({
        id: product?.id,
        product_id: product?.product_id,
        unit_id: product?.unit_id,
        warehouse_id: product?.warehouse_id,
        project_id: product?.project_id,
        quantity: product?.quantity,
        company_person_id: product?.company_person_id,
        currency_id: product?.currency_id,
        amount: parseLocaledString(product?.amount || "0")
      })
      .then(async res => {
        dispatch(
          setPartyUpdateProduct({
            condition: false
          })
        );

        message.success(res.message[i18n.language]);
        await qc.invalidateQueries([supplyQueryNames.ORDER_DETAIL, party_id]);
      });
  };

  const confirmProduct = (
    <Spin indicator={LoadingIndicator} spinning={productUpdate.isLoading}>
      <div
        onClick={onProduct}
        className="box-border flex cursor-pointer items-center rounded-lg border border-solid border-primary-600 bg-primary-500 px-2 py-1.5"
      >
        <CheckCircleIcon color={colors.WHITE} />
      </div>
    </Spin>
  );

  return (
    <div className={parentStyles.offers}>
      <Popover placement="top" trigger={["hover"]} content={productsItems}>
        <div className={styles.products}>
          {products?.slice(0, 2)?.map(item => (
            <div className={styles.products__item} key={item.id}>
              <NotUserImage
                key={item?.id}
                name={item?.name ? item?.name : item?.company_person?.name}
                background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
              />
            </div>
          ))}
          {products?.length > 2 ? (
            <div className={styles.products__item}>
              <NotUserImage background={notUserBg()} title={`+${products?.length - 2}`} />
            </div>
          ) : null}
        </div>
      </Popover>
      <ConditionalRender
        if={updateProduct?.condition && updateProduct?.index && rowId === updateProduct?.index}
        else={
          !(status === StatusEnums.RECIEVED || status === StatusEnums.REJECTED) &&
          !isEditing && (
            <Dropdown trigger={["hover"]} menu={offerActionItems()} placement="bottom">
              <div className={cx("actions")}>
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          )
        }
      >
        {confirmProduct}
      </ConditionalRender>
    </div>
  );
};

export default PartyModalTableOffers;
