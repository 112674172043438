const WarningIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1991 18.1334L14.0006 3.89563C13.7957 3.54681 13.5033 3.25758 13.1522 3.05662C12.8011 2.85566 12.4036 2.74994 11.9991 2.74994C11.5945 2.74994 11.197 2.85566 10.8459 3.05662C10.4948 3.25758 10.2024 3.54681 9.99749 3.89563L1.79905 18.1334C1.60193 18.4708 1.49805 18.8546 1.49805 19.2453C1.49805 19.6361 1.60193 20.0198 1.79905 20.3572C2.0013 20.7081 2.29327 20.9989 2.64502 21.1997C2.99676 21.4006 3.3956 21.5042 3.80061 21.5H20.1975C20.6022 21.5039 21.0006 21.4001 21.352 21.1993C21.7034 20.9985 21.9951 20.7078 22.1972 20.3572C22.3946 20.02 22.4988 19.6363 22.4991 19.2456C22.4995 18.8548 22.3959 18.471 22.1991 18.1334ZM11.2491 10.25C11.2491 10.0511 11.3281 9.86032 11.4687 9.71967C11.6094 9.57902 11.8001 9.5 11.9991 9.5C12.198 9.5 12.3887 9.57902 12.5294 9.71967C12.67 9.86032 12.7491 10.0511 12.7491 10.25V14C12.7491 14.1989 12.67 14.3897 12.5294 14.5303C12.3887 14.671 12.198 14.75 11.9991 14.75C11.8001 14.75 11.6094 14.671 11.4687 14.5303C11.3281 14.3897 11.2491 14.1989 11.2491 14V10.25ZM11.9991 18.5C11.7765 18.5 11.559 18.434 11.374 18.3104C11.189 18.1868 11.0448 18.0111 10.9597 17.8055C10.8745 17.6 10.8523 17.3738 10.8957 17.1555C10.9391 16.9373 11.0462 16.7368 11.2036 16.5795C11.3609 16.4222 11.5613 16.315 11.7796 16.2716C11.9978 16.2282 12.224 16.2505 12.4296 16.3356C12.6351 16.4208 12.8108 16.565 12.9345 16.75C13.0581 16.935 13.1241 17.1525 13.1241 17.375C13.1241 17.6734 13.0055 17.9595 12.7945 18.1705C12.5836 18.3815 12.2974 18.5 11.9991 18.5Z"
        fill="#F04438"
      />
    </svg>
  );
};

export default WarningIcon;
