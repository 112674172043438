import React from "react";
import { useDispatch } from "react-redux";
import { DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import DatepickerSuffixIcon from "features/app/assets/icons/DatepickerSuffixIcon";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "features/app/components/project-select/project-select";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { useGetCashSelect } from "features/app/service/queries";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useReportExportExcel } from "features/payment/service/mutation";
import { ReportPaymentFormModel, ReportPaymentModel } from "features/payment/utils/models/ReportPaymentModel";

import { useAppSelector } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { isEmptyArr } from "modules/common";

import styles from "./report-payment-modal.module.scss";

const { Option } = Select;
const { Item, useForm } = Form;

const ReportPaymentModal: React.FC = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const reportMutation = useReportExportExcel();
  const { setReportPayment } = paymentReducerActions;
  const { visible } = useAppSelector(state => state.paymentReducer.reportPayment);

  const { data: cashes, isLoading: isLoadingCashes, isFetching: isFetchingCashes } = useGetCashSelect(visible, true);

  const onAfterOpen = (visible: boolean) => {
    if (!visible) {
      form.resetFields();
    }
  };

  const onCancel = () => {
    dispatch(setReportPayment(false));
  };

  const onFinish = ({ project_ids, cash_ids, date }: ReportPaymentFormModel) => {
    const baseData = {
      cash_ids: isEmptyArr(cash_ids) ? undefined : cash_ids,
      project_ids: isEmptyArr(project_ids) ? undefined : project_ids,
      min_date: date && dayjs(date[0]).format(dayjsFormats.DATE),
      max_date: date && dayjs(date[1]).format(dayjsFormats.DATE)
    };

    // @ts-ignore
    delete baseData.date;

    const reqData: ReportPaymentModel = { ...baseData };

    reportMutation.mutate(reqData);
  };

  const onOk = () => form.submit();

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      onCancel={onCancel}
      cancelText="Yopish"
      okText="Yuklab olish"
      title="Hisobotni yuklab olish"
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: reportMutation.isLoading
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <ProjectSelect
          multiple
          allowClear
          mode="form"
          label="Loyiha"
          name="project_ids"
          initialValue={undefined}
          rootClassName={styles.select}
        />
        <Item rootClassName={styles.select} name="cash_ids" label="Kassa" initialValue={undefined}>
          <Select
            allowClear
            mode="multiple"
            placeholder="Tanlang"
            notFoundContent={<TableEmpty />}
            filterOption={selectFilterOption}
            suffixIcon={<SelectSuffixIcon />}
            loading={isLoadingCashes || isFetchingCashes}
          >
            {cashes?.map(item => (
              <Option key={item?.id} value={item?.id} props={{ name: item?.name }}>
                {item?.name}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="date" label="Sana">
          <DatePicker.RangePicker
            format={dayjsFormats.DATE}
            suffixIcon={<DatepickerSuffixIcon />}
            placeholder={["Boshlang'ich sana", "Oxirigi sana"]}
            separator={<span className="text-gray-400">-</span>}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default ReportPaymentModal;
