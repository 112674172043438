import React from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "hooks/redux";

import { changeAccessToken } from "store/index";

import { http } from "services";

import { baseErrorSchema } from "modules/common";

import { User } from "../schema";

import { LoginForm, loginSchema } from "./schema";

interface LoginProps {
  defaultValues?: Partial<LoginForm>;
  onSuccess?: (user: User, variables: LoginForm) => void;
  children: (props: UseFormReturn<LoginForm>) => React.ReactNode;
}

const Login: React.FC<LoginProps> = ({ defaultValues = {}, children }) => {
  const { i18n } = useTranslation();
  const form = useForm<LoginForm>({ resolver: zodResolver(loginSchema), defaultValues: { ...defaultValues } });
  const dispatch = useAppDispatch();

  const mutation = useMutation<User, any, LoginForm>(
    async values => {
      const { data } = await http.request.post("auth/login", values);

      // const user = userSchema.parse(data.data);

      return data.data;
    },
    {
      onSuccess: user => {
        dispatch(changeAccessToken({ accessToken: user.Authorization! }));

        Sentry.setUser({
          id: user?.id,
          username: user?.full_name,
          companyName: user?.company_name,
          role: user?.role_name,
          status: user?.status,
          phone: user?.phone,
          login: user?.login
        });
      },
      onError: err => {
        if (err instanceof AxiosError) {
          const error = baseErrorSchema.parse(err.response?.data);

          form.setError("password", { type: "manual", message: error?.message?.[i18n.language] });
        }
      }
    }
  );

  const onSubmit = async (data: LoginForm) => {
    await new Promise(onSettled => mutation.mutate(data, { onSettled }));
  };

  return <form onSubmit={form.handleSubmit(onSubmit)}>{children(form)}</form>;
};

export default Login;
