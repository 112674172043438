import React from "react";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { FormInstance } from "antd/lib";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import { cx } from "features/app/utils/helpers/cx";

import { useAppSelector } from "../../../../../../hooks/redux";
import CheckCircleIcon from "../../../../../app/assets/icons/CheckCircleIcon";
import PlusCircleIcon from "../../../../../app/assets/icons/PlusCircleIcon";
import XCloseCircleIcon from "../../../../../app/assets/icons/XCloseCircleIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "../../../../../app/components/not-image/NotUserImage";
import { StatusEnums } from "../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { colors } from "../../../../../app/utils/constants/colors";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import ClockCircleIcon from "../../../../../supply/assets/icons/ClockCircleIcon";
import { IRelatedOffers, OfferModel } from "../../../../../supply/utils/models/OfferModel";
import { IProductList } from "../../../../utils/models/productListModel";

import ProductListAmount from "./ProductListAmount";
import ProductListCode from "./ProductListCode";
import ProductListCurrencySelect from "./ProductListCurrencySelect";
import ProductListName from "./ProductListName";
import ProductListResourceSelect from "./ProductListResourceSelect";
import ProductListSelect from "./ProductListSelect";
import { useTranslation } from "react-i18next";
import ProductListUnitSelect from "./ProductListUnitSelect";

import styles from "./productListModalTable.module.scss";

export interface Props {
  form: FormInstance<IProductList>;
  isLoading?: boolean;
  field: FormInstance<IProductList>;
  onAddProduct: () => void;
  onDelete: (id: string | number) => void;
  isCounterParts?: boolean;
}

const ProductListTable: React.FC<Props> = ({ form, isLoading, field, onAddProduct, onDelete, isCounterParts }) => {
  const { t } = useTranslation();
  const { company_person_name } = useAppSelector(state => state.companyPersonReducer.productListModal);
  const notUserBg = (products: IRelatedOffers[]) => {
    const sliceProducts = products?.slice(4, products?.length);

    const isRecievedProduct = sliceProducts?.some(item => item.status === StatusEnums.RECIEVED);

    if (isRecievedProduct) return colors.SUCCESS_500;
    return "";
  };

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: colors.SUCCESS_500,
    [StatusEnums.REJECTED]: colors.ERROR_500
  };

  const statusIcons: Record<string, React.ReactNode> = {
    [StatusEnums.RECIEVED]: <CheckCircleIcon width={30} />,
    [StatusEnums.REJECTED]: <XCloseCircleIcon stroke={colors.ERROR_500} width={30} />
  };

  const productsItems = (products: IRelatedOffers[]) => (
    <div className={styles.products__content}>
      {products?.map(item => (
        <div className={styles.products__label} key={item?.id}>
          <div className={styles.products__top}>
            <div className={styles.products__label__left}>
              {statusIcons[item.status] || <ClockCircleIcon width={30} color={colors.GRAY_500} />}
            </div>
            <div className={styles.products__label__right}>
              <span className={styles.offer__amount}>
                {localeFormatter(String(item.amount))} {item.currency?.symbol}
                {item.vat && (
                  <span className="flex h-[0.953rem] w-[1.625rem] items-center justify-center rounded-[0.313rem] border border-solid border-primary-500 bg-primary-500  text-[0.563rem] text-white">
                    NDS
                  </span>
                )}
              </span>
              <span className={styles.offer__date}>{item.created_date}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  const offerUsers = (product: OfferModel) => (
    <Popover
      placement="top"
      trigger={["hover"]}
      overlayClassName={styles.popover}
      content={productsItems(product?.offers && product?.offers?.length > 0 ? product?.offers : [])}
    >
      <div className={styles.products}>
        {product?.offers?.slice(0, 4)?.map(item => (
          <div className={styles.products__item} key={product.id}>
            <NotUserImage
              key={item?.id}
              name={company_person_name}
              background={statusBackgroundColors[item?.status as keyof typeof statusBackgroundColors]}
            />
          </div>
        ))}
        {product.offers && product?.offers?.length > 4 ? (
          <div className={styles.products__item}>
            <NotUserImage background={notUserBg(product.offers)} title={`+${product.offers?.length - 4}`} />
          </div>
        ) : null}
      </div>
    </Popover>
  );

  const columns: ColumnsType<IProductList> = [
    {
      title: "",
      render: (_, record) => (
        <div
          onClick={() => onDelete(record?.rowId)}
          className={cx({
            hidden: typeof record?.rowId === "number",
            [styles.trash_icon]: typeof record?.rowId === "string"
          })}
        >
          <DeleteIcon />
        </div>
      ),
      align: "center",
      width: "5%"
    }
  ];

  const getColumns = () => {
    const counterPartsColumns: ColumnsType<IProductList> = [
      {
        title: `${t("Counterparts.Resurs turi va nomi")}`,
        render: (_, record, index) => <ProductListSelect rowId={record?.rowId} form={form} index={index} />
      },
      {
        title: `${t("Counterparts.Birlik summasi")}`,
        render: (_, record, index) => <ProductListAmount rowId={record?.rowId as number} form={form} index={index} />
      },
      {
        title: `${t("Counterparts.Birligi")}`,
        render: (_, record, index: number) => <ProductListUnitSelect rowId={record?.rowId} form={form} index={index} />,
        key: "unit"
      },
      {
        title: `${t("Counterparts.Valyuta")}`,
        render: (_, record, index) => <ProductListCurrencySelect form={form} index={index} rowId={record?.rowId} />
      },
      {
        title: `${t("Counterparts.Takliflar")}`,
        render: (_, record) => offerUsers(record as unknown as OfferModel)
      }
    ];

    const catalogColumns: ColumnsType<IProductList> = [
      {
        title: `${t("Counterparts.Resurs turi va nomi")}`,
        render: (_, record, index) => <ProductListResourceSelect form={form} index={index} rowId={record?.rowId} />
      },
      {
        title: `${t("Counterparts.Mahsulot nomi")}`,
        render: (_, record, index) => <ProductListName rowId={record?.rowId as number} form={form} index={index} />
      },
      {
        title: `${t("Counterparts.Birligi")}`,
        render: (_, record, index: number) => <ProductListUnitSelect rowId={record?.rowId} form={form} index={index} />,
        key: "unit"
      },
      {
        title: `${t("Counterparts.Kodi")}`,
        render: (__, record, index) => <ProductListCode form={form} index={index} rowId={record?.rowId as number} />
      }
    ];

    if (!isCounterParts) {
      return [...counterPartsColumns, ...columns];
    }
    return [...catalogColumns, ...columns];
  };

  const footer = () => (
    <span className={styles.table_footer} onClick={onAddProduct}>
      <PlusCircleIcon fill={true} />
      {t("Counterparts.Resurs qo'shish")}
    </span>
  );

  return (
    <div className={styles.container}>
      <Table
        columns={getColumns() as ColumnsType<IProductList>}
        pagination={false}
        className={cx("", styles.table)}
        dataSource={field.getFieldValue("products")}
        rowKey={row => row?.rowId}
        footer={footer}
        rowClassName={cx("cursor-pointer", styles.table_row)}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        scroll={{ y: 400 }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
    </div>
  );
};

export default ProductListTable;
