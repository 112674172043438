import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, MenuProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import OrderSortBy from "features/app/components/order-sort-by/OrderSortBy";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import { parseToCrumbName } from "features/app/utils/helpers/parseToCrumbName";
import { MenuEventHandlerModel } from "features/app/utils/models/menuEventHandlerModel";
import { PaginationType } from "features/app/utils/models/PaginationType";
import FileCheckIcon from "features/payment/assets/icons/FileCheckIcon";
import { useTranslation } from "react-i18next";
import { rootPaths } from "routes/root/rootPaths";
import { rootRoutes } from "routes/root/rootRoutes";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { companyPersonActions } from "store/reducers/companyPersonReducer";

import { useAuth } from "modules/auth/hooks";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import TableSettings from "../../../../../app/components/table-settings/TableSettings";
import PaymentSearch from "../../../../../payment/components/top/search/PaymentSearch";
import CornerUpLeftIcon from "../../../../../projects/assets/icons/CornerUpLeftIcon";
import EyeIcon from "../../../../../projects/assets/icons/EyeIcon";
import ArchiveIcon from "../../../../../supply/assets/icons/ArchiveIcon";
import AddOfferIcon from "../../../../assets/icons/AddOfferIcon";
import { useDeletePerson, useExportCounterpartsExcel, useUpdatePerson } from "../../../../service/mutation";
import { companyPersonSubmodules } from "../../../../utils/constants/routeSubmodules";
import { companyPersonTableDefaultData } from "../../../../utils/constants/tableDefaultData";
import { CounterpartsCompanyPersonModel } from "../../../../utils/models/counterpartsCompanyPersonModel";
import FilterDrawerCounterparts from "../../../filter-drawer/FilterDrawerCounterparts";
import ProductListModal from "../../product-list-modal/ProductListModal";

import styles from "./counterpartsTable.module.scss";

type Props = {
  isLoading: boolean;
  isCounterpartsPage?: boolean;
  folderActions: Record<string, boolean>;
  data?: PaginationType<CounterpartsCompanyPersonModel[]> | undefined;
};

const CounterpartsTable: React.FC<Props> = ({ data, isLoading, isCounterpartsPage }) => {
  const { searchParams } = useQueryParams();
  const dispatch = useDispatch();
  const { setVisibleModal, setProductListModalOpen } = companyPersonActions;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const { permissions, currencies } = useAuth();
  const { id, name } = parseParamsId(params?.id);
  const deletePerson = useDeletePerson(id);
  const archiveCounterPart = useUpdatePerson();
  // translation tags
  const someDetailedCompanyPerson = rootRoutes({ permissions }).some(
    item => item.permissionName === "Kontragent balansi"
  );

  const folderPath = (row: CounterpartsCompanyPersonModel): string => {
    let path = id
      ? `${rootPaths.COUNTERPARTS}/${name}-${id}/${parseToCrumbName(row.name)}-${row.id}`
      : `${rootPaths.DETAILED_COUNTERPART}/${parseToCrumbName(row.name)}-${row.id}`;

    if (searchParams && searchParams?.length > 0) {
      path += `${searchParams}`;
    }
    return path;
  };
  const baseCurrency = currencies?.find(item => item.type === "base");
  const { actions } = useRoutePermissions("Kontragent", companyPersonSubmodules);
  const routeCompanyPersonActions = actions("Kontragentlar");
  const exportExcel = useExportCounterpartsExcel();
  const { setVisibleAct } = companyPersonActions;

  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.COMPANY_PERSON, companyPersonTableDefaultData);

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.COMPANY_PERSON]);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.COMPANY_PERSON, true);

  const onOpenAct = (record: CounterpartsCompanyPersonModel) => {
    dispatch(
      setVisibleAct({
        visible: true,
        name: record?.name,
        company_person_id: record?.id
      })
    );
  };

  const onDelete = (person_id: number) => () => deletePerson.mutateAsync(person_id);

  const onOpenModal = (record?: CounterpartsCompanyPersonModel) => {
    dispatch(setVisibleModal({ data: record, visible: true }));
  };

  const counterpartBalanceClass = (totalAmount: number) => {
    if (totalAmount > 0) {
      return styles.success;
    }
    if (totalAmount < 0) {
      return styles.error;
    }
    return "";
  };

  const onArchive = (record: CounterpartsCompanyPersonModel) => {
    archiveCounterPart.mutateAsync({
      id: record?.id,
      is_archived: !!isCounterpartsPage
    });
  };

  const toDetailedCounterpart = (row: CounterpartsCompanyPersonModel) => {
    if (someDetailedCompanyPerson) {
      if (id) {
        navigate(folderPath(row));
      } else {
        navigate(folderPath(row));
      }
    }
  };

  const dropdownItems = (record: CounterpartsCompanyPersonModel) => {
    const newItems = [];

    if (routeCompanyPersonActions?.view) {
      newItems.push({
        key: "1",
        label: (
          <div
            className={styles.update}
            onClick={() => {
              toDetailedCounterpart(record);
            }}
          >
            <EyeIcon />
            <span>{t("counterparts.Ko'rish")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (routeCompanyPersonActions?.edit) {
      newItems.push({
        key: "2",
        label: (
          <div className={styles.update}>
            <EditIcon /> <span>{t("counterparts.Tahrirlash")}</span>
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
          onOpenModal(record);
        }
      });
    }
    newItems?.push({
      key: "3",
      label: (
        <div className={styles.delete}>
          {isCounterpartsPage ? <ArchiveIcon /> : <CornerUpLeftIcon />}
          <span>{isCounterpartsPage ? t("Counterparts.Arxivlash") : t("Counterparts.Arxivdan chiqarish")}</span>
        </div>
      ),
      onClick: (e: MenuEventHandlerModel) => {
        e.domEvent.stopPropagation();
        onArchive(record);
      }
    });

    if (routeCompanyPersonActions?.delete) {
      newItems.push({
        key: "5",
        label: (
          <ModalConfirm onOk={onDelete(record.id)} title={t("Counterparts.Kontragentni o'chirish")}>
            <div className={styles.delete}>
              <DeleteIcon />
              <span>{t("Counterparts.O'chirish")}</span>
            </div>
          </ModalConfirm>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }
    if (routeCompanyPersonActions?.act) {
      newItems.push({
        key: "6",
        label: (
          <div className={styles.delete}>
            <FileCheckIcon stroke="#475467" width={20} /> {t("Counterparts.ACT SVERKA")}
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
          onOpenAct(record);
        }
      });
    }
    newItems?.splice(2, 0, {
      key: "4",
      label: (
        <div className={styles.delete}>
          <AddOfferIcon />
          {t("Counterparts.Taklif qo'shish")}
        </div>
      ),
      onClick: (e: MenuEventHandlerModel) => {
        e.domEvent.stopPropagation();

        dispatch(
          setProductListModalOpen({
            visible: true,
            id: record.id,
            company_person_name: record.name
          })
        );
      }
    });

    return newItems;
  };

  const personColorStyle = (amount: number): React.CSSProperties => {
    if (amount > 0) {
      return {
        background: colors.SUCCESS_50,
        color: colors.SUCCESS_500
      };
    }
    if (amount < 0) {
      return {
        background: colors.ERROR_50,
        color: colors?.ERROR_500
      };
    }
    return {
      background: colors.GRAY_50,
      color: colors.GRAY_500
    };
  };

  const [renderColumns, setColumns] = useState<ColumnsType<CounterpartsCompanyPersonModel>>([]);

  const columns: ColumnsType<CounterpartsCompanyPersonModel> = [
    {
      key: "name",
      title: `${t("Counterparts.Nomi")}`,
      render: (record: CounterpartsCompanyPersonModel) => {
        const columnWidth = renderColumns?.find(c => c.key === "name")?.width;

        return <PopoverShortName title={record.name} length={Number(columnWidth) / 10} />;
      },
      width: 850,
      className: "counterparts_name",
      fixed: "left"
    },

    {
      key: "phone",
      title: `${t("Counterparts.Telefon raqam")}`,
      render: (record: CounterpartsCompanyPersonModel) => record.person.phone ?? "Mavjud emas",
      width: 250,
      className: "counterparts_phone"
    },

    {
      key: "balances",
      title: `${t("Counterparts.Balans")}`,
      render: (record: CounterpartsCompanyPersonModel) => (
        <div className={styles.balances}>
          {record?.balances?.map(item => (
            <div className={styles.balances__item} key={item?.id} style={personColorStyle(item.amount)}>
              <span>{item?.amount?.toLocaleString(RU)}</span>
              <span>{item?.currency?.symbol}</span>
            </div>
          ))}
        </div>
      ),
      width: 250,
      className: "counterparts_balances"
    },
    {
      key: "total_amount",
      title: <OrderSortBy title={t("Counterparts.Umumiy balans")} queryParamKey={queryParamsKeys.SORT_TOTAL_AMOUNT} />,
      render: (record: CounterpartsCompanyPersonModel) => (
        <div className={counterpartBalanceClass(record?.total_amount)}>
          {record?.total_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
        </div>
      ),
      width: 250,
      className: "counterparts_total_amount"
    },
    {
      key: "debt_amount",
      title: <OrderSortBy title={t("Counterparts.Debit")} queryParamKey={queryParamsKeys.SORT_DEBT_AMOUNT} />,
      render: (record: CounterpartsCompanyPersonModel) => (
        <>
          {record.debt_amount! > 0 ? (
            <div>
              {record?.debt_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
            </div>
          ) : null}
        </>
      ),
      width: 250,
      className: "counterparts_debt_amount"
    },
    {
      key: "credit_amount",
      title: <OrderSortBy title={t("Counterparts.Kredit")} queryParamKey={queryParamsKeys.SORT_CREDIT_AMOUNT} />,
      render: (record: CounterpartsCompanyPersonModel) => (
        <>
          {record.credit_amount! > 0 ? (
            <div>
              {record?.credit_amount?.toLocaleString(RU)} {baseCurrency?.symbol}
            </div>
          ) : null}
        </>
      ),
      width: 250,
      className: "counterparts_credit_amount"
    }
  ];

  const title = () => (
    <div className="flex items-center justify-between">
      <h4 className="m-0 text-lg font-medium text-gray-700">{t("Counterparts.Kontragentlar")}</h4>
      <div className={styles.title}>
        {routeCompanyPersonActions?.create && isCounterpartsPage && (
          <Button onClick={() => onOpenModal()}>
            <PlusIcon />
            {t("counterparts.Kontragent qo'shish")}
          </Button>
        )}
        {isCounterpartsPage && <div className={styles.title__line} />}
        <PaymentSearch />
        <FilterDrawerCounterparts
          tableKey={tableConfigKeys.COMPANY_PERSON}
          customFieldEnum={CustomFieldLocationEnum.COMPANY_PERSON}
          exportExcelMutate={exportExcel}
        />
      </div>
    </div>
  );

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.COMPANY_PERSON,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<CounterpartsCompanyPersonModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  // const isViewDots = () => (
  //   routeCompanyPersonActions?.edit || routeCompanyPersonActions?.delete || routeCompanyPersonActions?.view || someDetailedCompanyPerson || routeCompanyPersonActions?.act
  // );

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              configKey={tableConfigKeys.COMPANY_PERSON}
              defaultData={companyPersonTableDefaultData}
              locations={[CustomFieldLocationEnum.COMPANY_PERSON]}
            />
          ),
          render: (record: CounterpartsCompanyPersonModel) => (
            // keyinchalik isViewDots ni ulab ketish kerak sababi permissionlarga ulash kerak
            <ConditionalRender if={true}>
              <Dropdown trigger={["click"]} menu={{ items: dropdownItems(record) as MenuProps["items"] }}>
                <div className={styles.dots} onClick={e => e.stopPropagation()}>
                  <DotsVerticalIcon />
                </div>
              </Dropdown>
            </ConditionalRender>
          ),
          className: "counterparts_action_dots",
          width: 70,
          fixed: "right"
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return (
    <div className={styles.table}>
      <div className={styles.top}>
        <Table<CounterpartsCompanyPersonModel>
          onChangeColumns={onChangeColumns}
          title={title}
          pagination={false}
          dataSource={data?.data}
          columns={renderColumns}
          rowKey={row => row.id}
          scroll={{ y: "calc(100vh - 18.125rem)" }}
          rowClassName={() => styles.row}
          locale={{
            emptyText: <TableEmpty />
          }}
          onRow={row => ({
            onClick: () => toDetailedCounterpart(row)
          })}
          loading={{
            indicator: LoadingIndicator,
            spinning: isLoading || deletePerson.isLoading
          }}
        />
      </div>
      <div className={styles.bottom}>
        <div className={styles.pagination}>
          <Pagination
            paginationProps={{
              total: data?.total,
              current: data?.current_page
            }}
            configKey={tableConfigKeys.COMPANY_PERSON}
          />
        </div>
      </div>
      <ProductListModal />
    </div>
  );
};

export default CounterpartsTable;
