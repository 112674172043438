import CheckCircleIcon from "features/app/assets/icons/CheckCircleIcon";
import { ConfirmationUserStatusEnum } from "../enums/confirmationUserStatusEnum";
import CloseCircleIcon from "features/app/assets/icons/CloseCircleIcon";
import { colors } from "modules/common";
import { useTranslation } from "react-i18next";

export const statusContent = () => {
  const { t } = useTranslation();
  const status: {
    [k in Exclude<ConfirmationUserStatusEnum, ConfirmationUserStatusEnum.OPEN>]: {
      text: string;
      icon: React.ReactNode;
      color: string;
    };
  } = {
    [ConfirmationUserStatusEnum.RECIEVED]: {
      text: t("confirMationWorkCreated.Tasdiqlangan"),
      icon: <CheckCircleIcon />,
      color: colors.SUCCESS_500
    },
    [ConfirmationUserStatusEnum.REJECTED]: {
      text: t("confirMationWorkCreated.Rad etilgan"),
      icon: <CloseCircleIcon />,
      color: colors.ERROR_500
    }
  };
  return status;
};
