import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Modal, Switch } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import { formRules } from "../../../../app/utils/constants/formRules";
import { useCreateVat } from "../../../service/mutations";
import { VatModel } from "../../../utils/models/financialModel";

const { useForm, Item } = Form;

const VatModal: React.FC = () => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const createVat = useCreateVat();
  const { setVat } = settingsActions;
  const { visible } = useAppSelector(state => state.settingsReducer.vat);
  const { t } = useTranslation();

  const onOk = () => form.submit();

  const onCancel = () => {
    dispatch(
      setVat({
        visible: false
      })
    );
  };

  const onFinish = (values: Omit<VatModel, "id">) => {
    createVat.mutateAsync(values).then(onCancel);
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      form.resetFields();
    }
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      okText={t("vat.Saqlash")}
      cancelText={t("vat.Yopish")}
      onCancel={onCancel}
      title={t("vat.NDS qo'shish")}
      okButtonProps={{
        loading: createVat.isLoading
      }}
      afterOpenChange={onAfterOpen}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="value" label={t("vat.NDS foizi")} className="my-4" rules={formRules()}>
          <Input type="number" placeholder={t("vat.Foiz")} suffix="%" />
        </Item>
        <div className="mb-4 flex items-center justify-between">
          <span className="text-base font-normal text-gray-400">{t("vat.Asosiy")}</span>
          <Item label={null} name="basic" valuePropName="switch" className="m-0">
            <Switch />
          </Item>
        </div>
      </Form>
    </Modal>
  );
};

export default VatModal;
