import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { ProductsEstimateDataModel } from "../../features/warehouse/utils/models/productsEstimateDataModel";

interface ProductsTemplateReducerModel {
  visible: boolean;
  products?: ProductsEstimateDataModel[];
  ids: number[];
  task_id?: number;
}

const initialState: ProductsTemplateReducerModel = {
  ids: [],
  visible: false,
  products: [],
  task_id: undefined,
};

const productsTemplateSlice = createSlice({
  name: sliceNames.PRODUCTS_TEMPLATE,
  initialState,
  reducers: {
    setIds: (state, action: PayloadAction<number[]>) => {
      state.ids = action.payload;
    },
    setVisibleTemplate: (
      state,
      action: PayloadAction<{
        visible: boolean;
        products?: ProductsEstimateDataModel[];
      }>
    ) => {
      state.visible = action.payload.visible;
    },
    setTemplateData: (state, action: PayloadAction<ProductsEstimateDataModel[] | undefined>) => {
      state.products = action.payload;
    },
    setTaskId: (state, action: PayloadAction<number | undefined>) => {
      state.task_id = action.payload;
    },
  },
});

export default productsTemplateSlice.reducer;
export const productsTemplateActions = productsTemplateSlice.actions;
