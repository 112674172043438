import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { dayjsFormats } from "modules/common";

dayjs.extend(isoWeek);
dayjs.extend(isSameOrBefore);

export function getWeekdaysBetweenDates(startDate: string, endDate: string) {
  const start = dayjs(startDate, dayjsFormats.DATE);
  const end = dayjs(endDate, dayjsFormats.DATE);
  const weekdays: Array<string> = [];

  const weekdayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "Monday"];

  for (let current = start; current.isSameOrBefore(end, "day"); current = current.add(1, "day")) {
    const weekdayName = current.format(dayjsFormats.DATE_NAME);

    if (!weekdays?.includes(weekdayName)) {
      weekdays.push(weekdayName);
    }
  }

  weekdays.sort((a, b) => weekdayNames.indexOf(a) - weekdayNames.indexOf(b));

  return weekdays;
}
