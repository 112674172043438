import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal } from "antd";
import { useGetCashMoneySelect } from "features/payment/service/queries";

import { useAppSelector } from "../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import { colors } from "../../../../app/utils/constants/colors";
import { RU } from "../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../app/utils/helpers/cx";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import CreditCardDownloadIcon from "../../../assets/icons/CreditCardDownloadIcon";
import { useCreatePayment } from "../../../service/mutation";

import FirstStep from "./first-step/FirstStep";
import SecondStep from "./second-step/SecondStep";
import ThirdStep from "./third-step/ThirdStep";

import styles from "./orderPayment.module.scss";

const { useForm } = Form;

interface FinishProps {
  amount: string;
  cash_id: number;
  project_id: number;
  financial_id?: number;
  payment_type_id: number;
  currency_amount: string;
  financials: { id: number; name: string; amount: string; percent_amount: number }[];
}

const OrderPayment: React.FC = () => {
  const [form] = useForm();
  const qc = useQueryClient();
  const dispatch = useDispatch();
  const createPayment = useCreatePayment();
  const { setPaymentOrderModal } = paymentReducerActions;
  const { paymentOrderModal: paymentOrder } = useAppSelector(state => state.paymentReducer);
  const { visible, order, isOrderDelete, queryKey } = paymentOrder;
  const payload = useAppSelector(state => state.paymentReducer.paymentOrderModal);
  const [autoAdvance, setAutoAdvance] = useState<boolean>(false);
  const { cash } = payload;

  const [step, setStep] = useState(0);
  const steps: Record<number, React.ReactNode> = {
    0: (
      <FirstStep
        key={`${cash?.id}first`}
        setStep={setStep}
        autoAdvance={autoAdvance}
        setAutoAdvance={setAutoAdvance}
        visible={visible}
      />
    ),
    1: (
      <SecondStep
        key={`${cash?.id}second`}
        setStep={setStep}
        form={form}
        autoAdvance={autoAdvance}
        setAutoAdvance={setAutoAdvance}
      />
    ),
    2: <ThirdStep key={`${cash?.id}third`} order={order} form={form} step={step} />
  };

  const onCancel = () => {
    dispatch(
      setPaymentOrderModal({
        ...paymentOrder,
        visible: false
      })
    );
  };

  const prev = () => {
    setAutoAdvance(false);
    setStep(step - 1);
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: FinishProps) => {
    createPayment
      .mutateAsync({
        order_id: order?.id,
        project_id: values.project_id,
        type: PaymentTypeEnum.EXPENSE,
        cash_id: paymentOrder?.cash?.id,
        financial_id: values?.financial_id,
        amount: parseLocaledString(values.amount),
        company_person_id: order?.company_person?.id,
        payment_type_id: paymentOrder?.payment_type?.id,
        currency_id: paymentOrder?.currency?.id as number,
        financials: values?.financials?.map(item => ({
          id: item?.id,
          amount: item?.percent_amount || parseLocaledString(item?.amount || "0")
        })),
        currency_amount: values?.currency_amount ? parseLocaledString(values.currency_amount) : 1
      })
      .then(async () => {
        if (isOrderDelete && queryKey) {
          await qc.invalidateQueries(queryKey);
        }

        onCancel();
      });
  };

  const title = () => {
    const description = [
      "Chiqimni qaysi kassadan amalga oshirishni tanlang",
      "Chiqim to’lov turi va valyutasini tanlang",
      `${paymentOrder?.cash?.name} / ${paymentOrder?.payment_type?.name}`
    ];

    return (
      <div className={styles.title}>
        <div className={cx(styles.title__icon, "bg-error-100")}>
          <CreditCardDownloadIcon isNotArrow stroke={colors.ERROR_500} />
        </div>
        <div className={styles.title__info}>
          <h4>Chiqim</h4>
          <p>
            {description[step]}

            <span className={styles.title__balance}>
              {step === 2 ? (
                <>
                  {paymentOrder?.currency?.amount?.toLocaleString(RU)} {paymentOrder?.currency?.symbol}
                </>
              ) : (
                ""
              )}
            </span>
          </p>
        </div>
      </div>
    );
  };

  const footer = () => (
    <div className={styles.footer}>
      {step > 0 && <Button onClick={prev}>Ortga</Button>}
      {step !== 2 && <Button onClick={onCancel}>Yopish</Button>}
      {step === 2 && (
        <Button type="primary" onClick={onOk} loading={createPayment.isLoading}>
          Saqlash
        </Button>
      )}
    </div>
  );

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      setAutoAdvance(false)
      setStep(0);
      form.resetFields();
      dispatch(
        setPaymentOrderModal({
          visible: false
        })
      );
    }else{
      setAutoAdvance(true)
    }
  };

  return (
    <Modal
      centered
      open={visible}
      title={title()}
      footer={footer()}
      onCancel={onCancel}
      maskClosable={false}
      afterOpenChange={onAfterOpen}
      className={styles.payment_step}
      zIndex={isOrderDelete ? 11118 : 11114}
      width={step === 2 ? "34.5rem" : "64rem"}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className={styles.content}>{steps[step]}</div>
      </Form>
    </Modal>
  );
};

export default OrderPayment;
