import { Form, Input, Modal } from "antd";
import { Rule } from "antd/es/form";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../../hooks/redux";
import { transferActions } from "../../../../../store/reducers/transferReducer";
import { RootState } from "../../../../../store";
import Comments from "../../../../app/components/comments/Comments";
import { formRules } from "../../../../app/utils/constants/formRules";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { useCreateExcess } from "../../../service/mutation";
import { useGetExcessView } from "../../../service/query";
import { DefectStatus } from "../../../utils/constants/DefectStatus";
import { ExcessFormModel } from "../../../utils/models/ExcessFormModel";
import ExcessDifferenceTable from "./ExcessDifferenceTable";

import styles from "./excessModal.module.scss";
import ExcessModalBottom from "./ExcessModalBottom";

const { Item } = Form;

const ExcessModal: React.FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const createExcess = useCreateExcess();
  const { setVisibleExcess } = transferActions;
  const [form] = Form.useForm<ExcessFormModel>();
  const [quantityErrCount, setQuantityErrCount] = useState<number>();

  const { data: transfer } = useAppSelector((state: RootState) => state.transferReducer.view);

  const { visible, data, isDifference } = useAppSelector((state: RootState) => state.transferReducer.excess);

  const { data: excess, isLoading: isLoadingExcess } = useGetExcessView(data?.product.id, data?.transfer_id);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      form.setFieldsValue({
        expected_quantity: data?.expected_quantity,
        quantity: data?.quantity,
        product_id: data?.product.id,
        transfer_id: data?.transfer_id
      });

      if (isDifference) {
        form.setFieldsValue({
          additionals: [],
          expected_quantity: data?.expected_quantity,
          quantity: data?.quantity,
          product_id: data?.product.id,
          transfer_id: data?.transfer_id,
          accepted_quantity: data!.expected_quantity! - data!.quantity!
        });
      }
    }
  };

  const onCancel = () => {
    dispatch(
      setVisibleExcess({
        data: undefined,
        visible: false
      })
    );

    setQuantityErrCount(undefined);

    form.resetFields();
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (fields: ExcessFormModel) => {
    const { expected_quantity, quantity, accepted_quantity, additionals, product_id, transfer_id } =
      fields as Required<ExcessFormModel>;

    let errCount = 0;

    if (expected_quantity > quantity) {
      errCount = Math.abs(expected_quantity - accepted_quantity);
      setQuantityErrCount(errCount ? errCount : undefined);
    } else {
      errCount = Math.abs(quantity - accepted_quantity);
      setQuantityErrCount(errCount ? errCount : undefined);
    }

    if (!errCount) {
      createExcess.mutateAsync({ product_id, transfer_id, additionals }).then(onCancel);
    } else {
      const additionalsQuantity = additionals.reduce((first, second) => Number(first) + Number(second.quantity), 0);

      if (additionalsQuantity === errCount) {
        createExcess.mutateAsync({ product_id, transfer_id, additionals }).then(onCancel);
        setQuantityErrCount(undefined);
      } else {
        setQuantityErrCount(value => Number(value) - additionalsQuantity);
      }
    }
  };

  const acceptedQuantityRules = (): Rule[] | undefined => {
    return [
      {
        validator(_, value: string) {
          const acceptedQuantity = value;
          const quantity = form.getFieldValue("quantity");

          if (+acceptedQuantity > quantity || !acceptedQuantity) {
            return Promise.reject();
          } else {
            return Promise.resolve();
          }
        }
      }
    ];
  };

  const visibleOkButton = () => {
    return transfer?.status === DefectStatus.RECIEVED;
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      onCancel={onCancel}
      className={styles.excess}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createExcess.isLoading,
        className: visibleOkButton() ? "d_n" : ""
      }}
      title={isDifference ? "Farqni ko'rish" : "Kamomat qilish"}
      cancelText="Yopish"
      okText="Saqlash"
      width="77.4rem"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.top}>
              <div className={styles.name}>
                <label>Nomi</label>
                <div className={styles.name__bottom}>
                  <div className="resource">
                    <div
                      style={{
                        borderColor: data?.product?.resource?.color,
                        color: data?.product?.resource?.color
                      }}
                    >
                      {data?.product.resource.symbol[i18n.language]}
                    </div>
                    <span>{data?.product.name[i18n.language]}</span>
                  </div>
                </div>
              </div>
              <div className={styles.counts}>
                <Item name="expected_quantity" label="Kelishi kerak" initialValue={data?.expected_quantity}>
                  <Input disabled suffix="dona" />
                </Item>
                <Item name="quantity" label="Keldi" rules={formRules()}>
                  <Input disabled={isDifference} type="number" suffix="dona" />
                </Item>
                <Item name="accepted_quantity" label="Qabul qilindi" rules={acceptedQuantityRules()}>
                  <Input disabled={isDifference} type="number" suffix="dona" />
                </Item>
              </div>
              <Item className="d_n" name="transfer_id" />
              <Item className="d_n" name="product_id" />
            </div>
            {!isDifference && (
              <ExcessModalBottom
                form={form}
                quantityErrCount={quantityErrCount}
                setQuantityErrCount={setQuantityErrCount}
              />
            )}
            {(!isEmptyArr(excess) || isDifference) && <ExcessDifferenceTable />}
          </div>
          <div className={styles.right}>
            <Comments objectId={data?.transfer_id} type={ChatEnum.WAREHOUSE_TRANSFERS} />
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ExcessModal;
