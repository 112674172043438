import React from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../hooks/redux";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import PlusIcon from "../../../../../app/assets/icons/PlusIcon";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { PaginationType } from "../../../../../app/utils/models/PaginationType";
import { ProductSelectModel } from "../../../../../app/utils/models/productSelectModel";
import { supplyQueryNames } from "../../../../utils/constants/supplyQueryNames";
import { OrderFormProductModel } from "../../../../utils/models/orderModalFormModel";

import OrderModalTable from "./table/OrderModalTable";

import styles from "./orderModalLeft.module.scss";

type Props = {
  form: FormInstance;
};

const { Item, useWatch } = Form;

const OrderModalLeft: React.FC<Props> = ({ form }) => {
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const { setOrderEstimate, setOrderTableView } = supplyActions;

  const { orderId, isTable } = useAppSelector(state => state.supplyReducer.orderModal);

  const allProducts: PaginationType<ProductSelectModel[] | undefined> | undefined = qc.getQueryData(
    [supplyQueryNames.SELECT_PRODUCTS, undefined, undefined],
    {
      exact: true,
      type: "active"
    }
  );

  const products = useWatch("task_products", form) as OrderFormProductModel[];
  const searchValue = useWatch("search", form) as string;

  const onAddProduct = () => {
    dispatch(setOrderTableView(true));
    const warehouseId = form.getFieldValue("warehouse_id");
    const projectId = form.getFieldValue("project_id");
    let lastProduct: Partial<OrderFormProductModel> = {
      warehouse_id: undefined,
      project_id: undefined
    };

    if (!isEmptyArr(products)) {
      lastProduct = products[products?.length - 1];
    }

    form.setFieldValue("task_products", [
      {
        id: undefined,
        product_id: undefined,
        unit_id: undefined,
        warehouse_id: warehouseId ?? lastProduct?.warehouse_id,
        project_id: projectId ?? lastProduct?.project_id,
        quantity: undefined,
        row_id: uid(12)
      }
    ]);
  };

  const onOpenEstimateDrawer = () => {
    dispatch(
      setOrderEstimate({
        visible: true
      })
    );
  };

  const notFoundContent = (
    <div className={styles.not_found}>
      <h4>{t("supply.Buyurtmalar tanlanmagan!")}</h4>
      <p>{t("supply.Mahsulotlar ro’yxatidan kerakli mahsultolarni tanlab buyurtma berishingiz mumkin.")}</p>
      <div className={styles.not_found__bottom}>
        <Button onClick={onOpenEstimateDrawer}>{t("supply.Smetadagi resurslar")}</Button>
        <Button type="primary" onClick={onAddProduct}>
          <PlusIcon color="#fff" />
          {t("supply.Resurs qo'shish")}
        </Button>
      </div>
    </div>
  );

  return <div className={styles.left}>{isTable || orderId ? <OrderModalTable form={form} /> : notFoundContent}</div>;
};

export default OrderModalLeft;
