import React, { FC, ReactNode, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Spin } from "antd";
import { MenuProps } from "antd/lib";
import ProjectReportIcon from "features/app/assets/icons/ProjectReportIcon";
import { useTranslation } from "react-i18next";

import { useDispatch } from "store";
import { projectReportModalActions } from "store/reducers/projectReportModalReducer";

import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../app/assets/icons/EditIcon";
import FolderIcon from "../../../app/assets/icons/FolderIcon";
import Editable from "../../../app/components/folder-card/editable/Editable";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../app/components/modal-confirm/ModalConfirm";
import { menuColors } from "../../../app/utils/constants/menuColors";
import ArchiveIcon from "../../../supply/assets/icons/ArchiveIcon";
import CornerUpLeftIcon from "../../assets/icons/CornerUpLeftIcon";
import { useCreateProjectFolder, useDeleteProjectFolder, useUpdateProjectFolder } from "../../service/mutations";
import { ProjectFolderModel } from "../../utils/models/projectFolderModel";
import { ProjectProgress } from "../project-progress/ProjectProgress";

import styles from "./projectFolderCard.module.scss";

type Props = Partial<ProjectFolderModel> & {
  isCreating?: boolean;
  setIsCreating?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProjectFolderCard: FC<Props> = ({
  id,
  name,
  color,
  isCreating,
  percent,
  setIsCreating,
  projects_count,
  isEditing: editing,
  ...rest
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const updateProjectFolder = useUpdateProjectFolder();
  const deleteProjectFolder = useDeleteProjectFolder();
  const createProjectFolder = useCreateProjectFolder();
  const archiveProject = useUpdateProjectFolder();
  const location = useLocation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const { setVisible } = projectReportModalActions;
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(isCreating));

  const onClickCard = () => {
    !isCreating && navigate(`${name}-${id!.toString()}`);
  };

  const onFinish = (values: { name: string }) => {
    if (id) {
      updateProjectFolder.mutate({
        id,
        name: values.name
      });
    } else {
      createProjectFolder.mutateAsync({ name: values.name }).finally(() => {
        setIsEditing(false);
        setIsCreating && setIsCreating(false);
      });
    }
  };

  const onChangeColor = (color: string) => {
    updateProjectFolder.mutate({ color, id });
  };

  const onDelete = () => deleteProjectFolder.mutateAsync(id!);

  const onOpenEdit = () => {
    setIsEditing(true);
  };
  const onArchive = () =>
    archiveProject.mutateAsync({
      id: id as number,
      is_archived: !!isProjectsPage
    });

  const dropdownItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className={styles.colors}>
          {menuColors.map((color, index) => (
            <span
              key={index}
              className={styles.item}
              onClick={() => onChangeColor(color.background)}
              style={{
                background: color.border
              }}
            >
              <span
                style={{
                  background: color.background
                }}
              />
            </span>
          ))}
        </div>
      )
    },
    {
      key: "2",
      label: (
        <div className={styles.update}>
          <EditIcon /> <span>{t("project.Tahrirlash")}</span>
        </div>
      ),
      onClick: onOpenEdit
    },

    {
      key: "4",
      label: (
        <div className={styles.update} onClick={onArchive}>
          {isProjectsPage ? <ArchiveIcon /> : <CornerUpLeftIcon />}
          <span>{isProjectsPage ? t("project.Arxivlash") : t("project.Arxivdan chiqarish")}</span>
        </div>
      )
    },
    {
      key: "3",
      label: (
        <ModalConfirm onOk={onDelete}>
          <div className={styles.delete}>
            <DeleteIcon />
            <span>{t("project.O'chirish")}</span>
          </div>
        </ModalConfirm>
      )
    },
    {
      key: "5",
      label: (
        <div
          className={styles.delete}
          onClick={() =>
            dispatch(
              setVisible({
                data: {
                  id: Number(id),
                  name: String(name),
                  percent: percent!,
                  total_amount: rest.total_amount!,
                  is_archived: rest.is_archived!,
                  prediction_date: rest.prediction_date!,
                  prediction_amount: rest.prediction_amount!,
                  spend_amount: rest.spend_amount!,
                  start_date: rest.start_date!,

                  due_date: rest.due_date!
                },
                visible: true
              })
            )
          }
        >
          <ProjectReportIcon />
          <span>{t("project.Loyiha hisoboti")}</span>
        </div>
      )
    }
  ];

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  return (
    <Spin indicator={LoadingIndicator} spinning={updateProjectFolder.isLoading || createProjectFolder.isLoading}>
      <div className={styles.card} onClick={onClickCard}>
        <div className={styles.card__content}>
          <div className={styles.content__left}>
            <FolderIcon color={color} />
          </div>
          <div className={styles.content__right}>
            <div className={styles.content__right__top}>
              <Editable
                text={name}
                isEdit={isEditing}
                onFinish={onFinish}
                setEdit={setIsEditing}
                isCreating={isCreating}
                setIsCreating={setIsCreating}
              />
              <div className={styles.project_count}>
                {projects_count ?? 0} {t("project.loyiha")}
              </div>
            </div>
            <div className={styles.content__right__bottom}>
              <ProjectProgress percent={percent} />
            </div>
          </div>
        </div>
        <div className={styles.dots_icon}>
          <Dropdown trigger={["click"]} menu={{ items: dropdownItems }} dropdownRender={dropdownRender}>
            <span
              onClick={stopPropagation}
              style={{
                opacity: isCreating ? 0 : 1
              }}
            >
              <DotsVerticalIcon />
            </span>
          </Dropdown>
        </div>
      </div>
    </Spin>
  );
};
