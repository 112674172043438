import React, { useState } from "react";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { useExportSupplyOrdersExcel } from "features/supply/service/mutations";
import { tabKeys } from "features/supply/utils/constants/tabKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { Table } from "components";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { appReducerActions } from "../../../../../store/reducers/appReducer";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { generalEditKeys } from "../../../../app/utils/constants/generalEditData";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { PaginationType } from "features/app/utils/models/PaginationType";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { ProductModel } from "../../../utils/models/productModel";
import FilterDrawerOrders from "../filter-drawer/FilterDrawerOrders";
import {  UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

import OrderColumns from "./OrderColumns";

import "./orders.css";
import styles from "./orders.module.scss";

type Props = {
  products: PaginationType<ProductModel[]> | undefined;
  isLoadingProducts: boolean;
  tableConfigData:UserTableDataModel | undefined;
};

const Orders: React.FC<Props> = ({ isLoadingProducts, products,tableConfigData }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { tab } = queries();

  const { setOrderModal, setAddItemSelectOrderProducts, setSelectOrderProducts: setSelectProducts } = supplyActions;
  const { setGeneralEdit } = appReducerActions;

  const [renderColumns, setColumns] = useState<ColumnsType<ProductModel>>([]);

  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const ordersActions = actions("Buyurtmalar");
  const { selectOrderProducts: selectProducts } = useAppSelector(state => state.supplyReducer);

  const exportExcelMutate = useExportSupplyOrdersExcel();

  const onOpenOrderModal = () => {
    dispatch(
      setOrderModal({
        visible: true,
        selectProducts: []
      })
    );
  };

  const tableTitle = () => (
    <div className={styles.title}>
      <div className={styles.title__top}>
        <div className={styles.left}>
          <ConditionalRender if={ordersActions.create}>
            <Button onClick={onOpenOrderModal}>
              <PlusIcon />
              <span>{t("Supply.Buyurtma berish")}</span>
            </Button>
          </ConditionalRender>
        </div>
        <div className={styles.line} />
        <div className={styles.right}>
          <div className={styles.search}>
            <PaymentSearch />
          </div>
          <ConditionalRender if={tab === tabKeys.ORDERS || !tab}>
            <FilterDrawerOrders
              tableKey={tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG}
              exportExcelMutate={exportExcelMutate}
            />
          </ConditionalRender>
        </div>
      </div>
    </div>
  );

  const rowKey = (record: ProductModel) => record.id;

  const onSelectRow = (value: ProductModel) => {
    const someProduct = selectProducts?.some(item => item.id === value.id);
    let newSelectProducts = [...selectProducts];

    if (!someProduct) newSelectProducts.push(value);
    else {
      newSelectProducts = newSelectProducts?.filter(item => item.id !== value?.id);
    }

    dispatch(setSelectProducts(newSelectProducts));
  };

  const rowSelection: TableRowSelection<ProductModel> | undefined = {
    fixed: "left",
    type: "checkbox",
    onSelect: onSelectRow,
    selectedRowKeys: selectProducts?.map(item => item?.id),
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        selectedRows?.forEach(item => {
          if (item) dispatch(setAddItemSelectOrderProducts(item));
        });
      } else {
        dispatch(setSelectProducts([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.ORDER_PRODUCTS,
          customFieldKeys: [CustomFieldLocationEnum.WAREHOUSE_PRODUCT]
        })
      );
    }
  };

  return (
    <div className={styles.orders}>
      <div className={styles.top}>
        <Table<ProductModel>
          rowKey={rowKey}
          onChangeColumns={OrderColumns({ renderColumns, setColumns,tableConfigData }).onChangeColumns}
          title={tableTitle}
          pagination={false}
          tableLayout="fixed"
          scroll={{ y: "65vh" }}
          dataSource={products?.data}
          rowSelection={rowSelection}
          locale={{ emptyText: <TableEmpty /> }}
          columns={OrderColumns({ renderColumns, setColumns,tableConfigData }).columns as ColumnsType<ProductModel>}
          loading={{ spinning: isLoadingProducts, indicator: LoadingIndicator }}
        />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{
            total: products?.total
          }}
          configKey={tableConfigKeys.WAREHOUSE_PRODUCT_CONFIG}
        />
      </div>
    </div>
  );
};

export default Orders;
