import React from "react";
import { Form, FormInstance, Input } from "antd";
import { Rule } from "antd/es/form";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { useGetUnitSelect } from "../../../../../../../app/service/queries";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { pieceRemoveNaN } from "../../../../../../../app/utils/helpers/pieceRemoveNaN";
import { formatterPrice } from "../../../../../../utils/helpers/partyModalTableActions";
import { PartyFormProductsModel } from "../../../../../../utils/models/partyModalFormModel";

type Props = {
  index: number;
  form: FormInstance;
};

const { Item, useWatch } = Form;

const PartyQuantity: React.FC<Props> = ({ index, form }) => {
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: units } = useGetUnitSelect();
  const products = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];

  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const findProduct = products[index];
    const unitValue = units?.find(item => item.id === findProduct.unit_id);
    const removeNanValue = pieceRemoveNaN(e.currentTarget.value, unitValue?.is_piece);
    const newValue = localeFormatter(removeNanValue);

    form.setFieldValue(["warehouse_products", index, "quantity"], newValue);

    form.setFieldValue(
      ["warehouse_products", index, "total"],
      formatterPrice(parseLocaledString(newValue) * parseLocaledString(findProduct?.amount))
    );

    if (!isEmptyArr(cacheProducts)) {
      const findIndex = cacheProducts?.findIndex(item => item.row_id === findProduct.row_id);

      form.setFieldValue(["cache_products", findIndex, "quantity"], newValue);
      form.setFieldValue(
        ["cache_products", findIndex, "total"],
        formatterPrice(parseLocaledString(newValue) * parseLocaledString(findProduct?.amount))
      );
    }
  };

  const quantityRules: Rule[] = [
    ...formRules()!,
    {
      validator: (_, value: string) => {
        const parseQuantity = parseLocaledString(String(value));

        if (parseQuantity > 0) return Promise.resolve();

        return Promise.reject();
      }
    }
  ];

  // const value = () => form.getFieldValue(["warehouse_products", index, "quantity"]);

  return (
    // <PerfItem
    //   placeholder={<div className="px-[0.62rem] py-4">{value()}</div>}
    //   visible={!(isView && !updateProduct?.condition)}
    // >
    <Item name={[index, "quantity"]} rules={quantityRules}>
      <Input
        step="0.01"
        placeholder="Soni"
        autoComplete="off"
        disabled={isView && !updateProduct?.condition}
        onChange={e => onChangeCount(e, index)}
      />
    </Item>
    // </PerfItem>
  );
};

export default PartyQuantity;
