import { RU } from "../../../../../../app/utils/constants/languages";
import { diffFeatureAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateTaskModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverAmount from "../../diff-popover/DiffPopoverAmount";

import styles from "../task.module.scss";

type Props = {
  task: DynamicEstimateTaskModel;
  getPopoverContainer?:()=>HTMLElement
};

const ExpectedAmount = ({ task,getPopoverContainer }: Props) => {
  return (
    <>
      {diffFeatureAmount(
        task?.total_amount,
        task?.expected_total_amount,
        task?.spend_total_amount
      ) !== 0 ? (
        <DiffPopoverAmount
          record={task}
          classNames={styles.amount}
          isFeature={true}
          getPopoverContainer={getPopoverContainer}
        />
      ) : (
        <p className={styles.amount}>
          {task?.amount?.toLocaleString(RU) ?? 0}
        </p>
      )}
    </>
  );
};

export default ExpectedAmount;
