import React from "react";

const DropIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6663 11.6667C16.6663 15.3486 13.6816 18.3334 9.99967 18.3334C6.31778 18.3334 3.33301 15.3486 3.33301 11.6667C3.33301 10.7826 3.5051 9.93871 3.81761 9.16669C4.80658 6.7235 9.99967 1.66669 9.99967 1.66669C9.99967 1.66669 15.1928 6.7235 16.1817 9.16669C16.4943 9.93871 16.6663 10.7826 16.6663 11.6667Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropIcon;
