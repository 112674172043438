import React, { Dispatch, SetStateAction, useEffect } from "react";
import { Form, Radio, Spin } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetCashMoneySelect } from "../../../../../service/queries";

import styles from "./firstStep.module.scss";

type Props = {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  visible: boolean;
  autoAdvance: boolean;
  setAutoAdvance: Dispatch<SetStateAction<boolean>>;
};

const { Item } = Form;

const FirstStep: React.FC<Props> = ({ setStep, visible, setAutoAdvance, autoAdvance }) => {
  const dispatch = useAppDispatch();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const { paymentInventoryWorkModal } = useAppSelector(state => state.paymentReducer);

  const {
    data: cashes,
    isLoading: isLoadingCash,
    isFetching
  } = useGetCashMoneySelect(visible, {
    type: paymentInventoryWorkModal.type
  });

  const onChangeCash = (e: number) => {
    const selectCash = cashes?.find(item => item.id === e);

    dispatch(
      setPaymentInventoryWorkModal({
        ...paymentInventoryWorkModal,
        type: paymentInventoryWorkModal?.type,
        cash: selectCash
      })
    );
    setAutoAdvance(true);
    setStep(1);
  };

  useEffect(() => {
    if (cashes && autoAdvance) {
      if (cashes?.length === 1) {
        dispatch(
          setPaymentInventoryWorkModal({
            ...paymentInventoryWorkModal,
            type: paymentInventoryWorkModal?.type,
            cash: cashes[0]
          })
        );

        setStep(state => {
          if (state === 0) {
            return state + 1;
          }

          return state - 1;
        });
      }
    }
  }, [isFetching, autoAdvance]);

  return (
    <Spin spinning={isLoadingCash || isFetching} indicator={LoadingIndicator}>
      <Item name="cash_id">
        <Radio.Group value={paymentInventoryWorkModal?.cash?.id}>
          <div className={styles.cash}>
            {cashes?.map(item => (
              <Radio key={item.id} value={item.id} onClick={() => onChangeCash(item.id)}>
                <div className={styles.item}>
                  <h3>{item.name}</h3>
                  <p>Kassa</p>
                  <div className={styles.currency}>
                    {item?.currencies?.map(currency => (
                      <div className={styles.currency__item} key={currency.id}>
                        <img src={currency.icon} alt={currency.symbol} />
                        <span>
                          {currency.amount?.toLocaleString("ru")} {currency.symbol}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Radio>
            ))}
          </div>
        </Radio.Group>
      </Item>
    </Spin>
  );
};

export default FirstStep;
