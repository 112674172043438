import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { memo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../../../../../../hooks/useQueryParams";
import {
  GanttDateType,
  GanttDateWidth,
} from "../../../../../../../../../../../store/reducers/ganttReducer";
import { dayjsFormats } from "../../../../../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../../../../../app/utils/constants/queryParamsKeys";
import PlusCircleIcon from "../../../../../../../../../assets/icons/PlusCircleIcon";
import { projectStatuses } from "../../../../../../../../../utils/enums/projectStatuses";
import { useTaskUpdate } from "../../../services/mutation";
import { useGanttTask } from "../../../services/queries";
import { endPoints } from "../../../utils/constants/endPoints";
import { queryKeys } from "../../../utils/constants/queryKeys";
import { GanttSection } from "../../../utils/models/GanttSection";
import { GanttTaskType } from "../../../utils/models/GanttTaskType";
import GanttLine from "./gantt-line/GanttLine";
import styles from "./ganttChartTask.module.scss";

interface IProps extends GanttSection {
  sectionId: number;
  name: string;
  elementType?: GanttTaskType;
  active: boolean;
  startDateGantt: dayjs.Dayjs | undefined;
  dueDateGantt: dayjs.Dayjs | undefined;
  countOfDays: number;
  countOfMonth: number;
  countOfYear: number;
  projectStatus?: projectStatuses;
}

const GanttChartTask = (props: IProps) => {
  const {
    name,
    sectionId,
    elementType,
    active,
    start_date,
    due_date,
    dueDateGantt,
    startDateGantt,
    id,
    countOfDays,
    countOfMonth,
    countOfYear,
    is_title,
    projectStatus,
  } = props;
  const params = useParams();
  const projectId = Number(params?.projectId);
  const { reqQueryParam, searchParams } = useQueryParams();
  const { type } = useAppSelector((state) => state.ganttReducer);
  const { data: dataTask } = useGanttTask(sectionId, active);
  const taskUpdate = useTaskUpdate(sectionId);
  const qc = useQueryClient();
  const [position, setPosition] = useState({ move: false, offsetX: 0 });
  const noneTask =
    !(start_date || due_date) &&
    elementType !== "section" &&
    projectStatus === projectStatuses.PLANNING;

  const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (noneTask) {
      setPosition({
        move: true,
        offsetX: e?.nativeEvent?.offsetX - 10,
      });
    }
  };

  const mouseOut = () => {
    if (noneTask) {
      setPosition((prev) => ({
        ...prev,
        move: false,
      }));
    }
  };

  const handleCreateDate = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (noneTask) {
      const startDate = Math.round(position.offsetX / GanttDateWidth[type]);

      const obj = {
        [GanttDateType.DAY]: {
          start_date: startDateGantt
            ?.add(startDate, "day")
            .format(dayjsFormats.DATE)!,
          due_date: startDateGantt?.add(startDate, "day").format(dayjsFormats.DATE)!,
        },
        [GanttDateType.MONTH]: {
          start_date: startDateGantt
            ?.add(startDate, "month")
            .startOf("month")
            .format(dayjsFormats.DATE)!,
          due_date: startDateGantt
            ?.add(startDate, "month")
            .endOf("month")
            .format(dayjsFormats.DATE)!,
        },
        [GanttDateType.YEAR]: {
          start_date: startDateGantt
            ?.add(startDate, "year")
            .startOf("year")
            .format(dayjsFormats.DATE)!,
          due_date: startDateGantt
            ?.add(startDate, "year")
            .endOf("year")
            .format(dayjsFormats.DATE)!,
        },
      };

      taskUpdate.mutate({
        id,
        start_date: obj[type].start_date,
        due_date: obj[type].due_date,
      });

      let url = endPoints.GANTT_SECTION(projectId);
      if (searchParams && searchParams?.length > 0) {
        url += `&${reqQueryParam(
          queryParamsKeys.GENERAL_SEARCH,
          queryParamsKeys.MAX_DUE_DATE,
          queryParamsKeys.MIN_DUE_DATE,
          queryParamsKeys.MAX_START_DATE,
          queryParamsKeys.MIN_DUE_DATE,
          queryParamsKeys.SECTION_IDS_ARR,
          queryParamsKeys.TASK_STATUSES_ARR,
          queryParamsKeys.USER_IDS_ARR,
          queryParamsKeys.COMPANY_PERSON_IDS_ARR
        )}`;
      }

      qc.setQueryData<GanttSection[]>(
        [queryKeys.GANTT_TASK, sectionId, url],
        (data) =>
          data?.map((item) => {
            return item?.id === id
              ? {
                  ...item,
                  start_date: obj[type].start_date,
                  due_date: obj[type].due_date,
                }
              : {
                  ...item,
                };
          })
      );
    }
  };

  return is_title ? (
    <>
      <div className={styles.container}></div>
    </>
  ) : (
    <>
      <div
        className={styles.container}
        onMouseMove={mouseMove}
        onMouseOut={mouseOut}
        style={{ cursor: noneTask ? "pointer" : "default" }}
        onClick={handleCreateDate}
      >
        {start_date || due_date ? (
          <GanttLine
            {...props}
            due_date={due_date}
            start_date={start_date}
            startDateGantt={startDateGantt}
            dueDateGantt={dueDateGantt}
            sectionId={sectionId}
            elementType={elementType}
            active={active}
            countOfDays={countOfDays || 0}
            countOfMonth={countOfMonth || 0}
            countOfYear={countOfYear || 0}
            projectStatus={projectStatus}
          />
        ) : projectStatus === projectStatuses.PLANNING ? (
          <PlusCircleIcon
            pointerEvents={"none"}
            className={styles.plus}
            style={{ left: position.offsetX, opacity: position.move ? 1 : 0 }}
          />
        ) : (
          <></>
        )}
      </div>
      {elementType === "section" &&
        dataTask &&
        active &&
        dataTask?.map((item) => (
          <GanttChartTask
            key={item?.id}
            {...item}
            elementType="task"
            startDateGantt={startDateGantt}
            dueDateGantt={dueDateGantt}
            projectStatus={projectStatus}
            id={item?.id}
            sectionId={sectionId}
            countOfDays={countOfDays || 0}
            countOfMonth={countOfMonth || 0}
            countOfYear={countOfYear || 0}
          />
        ))}
    </>
  );
};

export default memo(GanttChartTask);
