import { Button, Drawer, Dropdown, MenuProps } from "antd";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../../hooks/redux";
import { chatActions } from "../../../../../../store/reducers/chatReducer";
import { inventoryReducerActions } from "../../../../../../store/reducers/inventoryReducer";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import UnreadMessageAction from "../../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../../app/utils/enums/chatEnum";
import { getFirstLetter } from "../../../../../app/utils/helpers/getFirstLetter";
import BackwardIcon from "../../../../assets/icons/BackwardIcon";
import { useDeleteDefect } from "../../../../service/mutations";
import { useGetDefect } from "../../../../service/queries";
import { ConfirmationStatusIcons } from "../../../../utils/constants/confirmationStatusIcons";
import { ConfirmationStatusValues } from "../../../../utils/constants/confirmationStatusValues";
import { defectTypes } from "../../../../utils/constants/defectTypes";
import { inventoryQueryKeys } from "../../../../utils/constants/inventoryQueryKeys";
import { DefectStatusEnum } from "../../../../utils/enums/defectStatusEnum";
import { DefectTypesEnum } from "../../../../utils/enums/defectTypesEnum";
import { DefectModel } from "../../../../utils/models/defectModel";
import { InventoryDetailModel } from "../../../../utils/models/inventoryModel";

import styles from "./defectView.module.scss";
import { MenuEventHandlerModel } from "../../../../../app/utils/models/menuEventHandlerModel";
import { useQueryParams } from "hooks/useQueryParams";
import { useQueryClient } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { Dispatch, SetStateAction } from "react";
import DeleteDefect from "../delete-defect/DeleteDefect";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setId: Dispatch<SetStateAction<Partial<DefectModel>>>;
  record?: DefectModel;
};

const DefectView = ({ open, setOpen, record, setId }: Props) => {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: defect } = useGetDefect(record?.id);
  const { setVisible } = chatActions;
  const { setDefectModalData } = inventoryReducerActions;
  const deleteDefect = useDeleteDefect();

  const onClose = () => {
    setOpen(false);
    setId({});
  };

  const onDeleteDefect = () => {
    deleteDefect.mutateAsync({ object_id: record?.id! }).then(() => onClose());
  };

  const onUpdateDefect = () => {
    dispatch(
      setDefectModalData({
        visible: true,
        product: defect! as DefectModel & InventoryDetailModel
      })
    );
    onClose();
  };

  const onOpenChat = () => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.INVENTORY_DEFECTS,
        // dataKeys: [
        //   inventoryQueryKeys.DEFECT_VIEW,
        //   inventoryQueryKeys.DEFECT_DETAIL_VIEW,
        // ],
        onAfterOpen: () => {
          if (record?.unread_message_count && record?.unread_message_count > 0) {
            qc.setQueryData(
              [inventoryQueryKeys.DEFECT_VIEW, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as DefectModel[];
                return {
                  total: total,
                  current_page: current_page,
                  data: initialData?.map(item => {
                    if (item?.id === record?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
            qc.setQueryData([inventoryQueryKeys.DEFECT_DETAIL_VIEW], (data: any) => {
              const initialData = data as DefectModel;

              return { ...initialData, unread_message_count: 0 };
            });
          }
        }
      })
    );
  };

  const dropdownItems = () => {
    const newItems = [
      {
        key: "2",
        label: (
          <div onClick={onOpenChat} className={styles.dropdown__item}>
            <UnreadMessageAction count={defect?.unread_message_count} />
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      }
    ];

    if (defect?.status === DefectStatusEnum.OPEN) {
      newItems.splice(0, 0, {
        key: "1",
        label: (
          <div onClick={onUpdateDefect} className={styles.dropdown__item}>
            <EditIcon /> {t("inventory.Tahrirlash")}
          </div>
        ),
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    if (defect?.status === DefectStatusEnum.OPEN) {
      newItems.push({
        key: "3",
        label: <DeleteDefect id={record?.id!} />,
        onClick: (e: MenuEventHandlerModel) => {
          e.domEvent.stopPropagation();
        }
      });
    }

    return newItems;
  };

  const title = (
    <div className={styles.title}>
      <div className={styles.title__left}>
        <div className="resource">
          <div className={styles.product}>MEX</div>
        </div>
        <h2>{defect?.inventory?.name}</h2>
        <span>({defect?.inventory?.code})</span>
      </div>
      <div className={styles.title__right}>
        <Dropdown menu={{ items: dropdownItems() as MenuProps["items"] }} trigger={["click"]} placement="bottom">
          <Button className={styles.title__actions}>
            <UnreadMessageDots count={defect?.unread_message_count} />
          </Button>
        </Dropdown>
        <div className={styles.close} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const footer = (
    <div className={styles.footer}>
      {defect?.status === (DefectStatusEnum.RECIEVED || DefectStatusEnum.REJECTED) ? (
        <div className={styles.actions}>
          <Button onClick={onUpdateDefect}>
            <EditIcon />
            {t("products.Tahrirlash")}
          </Button>
          <Button onClick={onDeleteDefect} loading={deleteDefect.isLoading}>
            <BackwardIcon /> {t("Inventar.Ortga qaytarish")}
          </Button>
        </div>
      ) : (
        <div />
      )}
      <Button onClick={onClose}>{t("products.Yopish")}</Button>
    </div>
  );

  return (
    <Drawer
      open={open}
      onClose={onClose}
      closeIcon={false}
      width={911}
      title={title}
      footer={footer}
      className={styles.defect_view}
    >
      <div className={styles.info}>
        <div className={styles.info__item}>
          <p>{t("Inventar.Yaroqsizlik turi")}</p>
          <p className={styles[defect?.type as DefectTypesEnum]}>
            {t(`Inventar.${defectTypes[defect?.type as DefectTypesEnum]}`)}
          </p>
        </div>
        <div className={styles.info__item}>
          <p>{t("Inventar.Kiritilgan sana")}</p>
          <p>{defect?.created_at_inventory}</p>
        </div>
        <div className={styles.info__item}>
          <p>{t("Inventar.Yaroqsizga chiqarildi")} </p>
          <p>{defect?.created_at_defect}</p>
        </div>
        <div className={styles.info__item}>
          <p>{t("Inventar.Yaroqsizga chiqardi")}</p>
          <p>{defect?.creator?.full_name}</p>
        </div>
        <div className={styles.info__item}>
          <p>{t("MainLayoutLinks.Kontragent")}</p>
          <p>{defect?.company_person?.name}</p>
        </div>
        {defect?.description && (
          <div className={styles.info__item}>
            <p>{t("Xodimlar.Izoh")}</p>
            <p className={styles.info__comment}>{defect?.description}</p>
          </div>
        )}
      </div>
      <div className={styles.users}>
        {defect?.confirmation_users?.map(user => (
          <div className={styles.user} key={user?.id}>
            <div className={styles.user__info}>
              {user?.user?.image ? (
                <img src={user?.user?.image} alt={user?.user?.full_name} />
              ) : (
                <span className={styles.non_image}>{getFirstLetter(user?.user?.full_name)}</span>
              )}

              <span>{user?.user.full_name}</span>
            </div>
            <div className={styles[user?.status]}>
              {ConfirmationStatusIcons[user?.status]}
              {t(`Inventar.${ConfirmationStatusValues[user?.status]}`)}
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default DefectView;
