import { useAuth } from "modules/auth/hooks";
import { Currency, CurrencyTypeEnum } from "modules/auth/schema";

export const getBaseCurrency = (type: CurrencyTypeEnum = "base"): Currency => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { currencies } = useAuth();

  const currency = currencies?.find(item => item?.type === type);

  return currency!;
};
