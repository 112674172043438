import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, ProjectByResourceDetailViewData } from "..";

interface Query {
  projectByResourceInDetailView: ProjectByResourceDetailViewData[];
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  detailedId: number;
  type: string;
}

export function useGetProjectByResourceInDetailView({ enabled = false, detailedId, type }: Params) {
  const initialValue: Query = { projectByResourceInDetailView: [] };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    task_product_id: detailedId
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_BY_RESOURCE_DETAIL_DATA_VIEW, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`task-progress-detail/resource-view?${searchParams}`);

      return { projectByResourceInDetailView: data };
    },
    { enabled: enabled && type !== "another" }
  );

  return { ...data, ...args };
}
