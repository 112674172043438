const ProjectPlusIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.625 3H6.825C5.7049 3 5.14484 3 4.71702 3.21799C4.34069 3.40973 4.03473 3.71569 3.84299 4.09202C3.625 4.51984 3.625 5.0799 3.625 6.2V8M8.625 21H6.825C5.7049 21 5.14484 21 4.71702 20.782C4.34069 20.5903 4.03473 20.2843 3.84299 19.908C3.625 19.4802 3.625 18.9201 3.625 17.8V16M21.625 8V6.2C21.625 5.0799 21.625 4.51984 21.407 4.09202C21.2153 3.71569 20.9093 3.40973 20.533 3.21799C20.1052 3 19.5451 3 18.425 3H16.625M21.625 16V17.8C21.625 18.9201 21.625 19.4802 21.407 19.908C21.2153 20.2843 20.9093 20.5903 20.533 20.782C20.1052 21 19.5451 21 18.425 21H16.625M12.625 17L12.625 7M7.625 12H17.625"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ProjectPlusIcon;
