import React, { useState } from "react";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";
import ConfirmImpost from "features/impost/components/confirm-impost/ConfirmImpost";
import { WorksInventoryImpostModel } from "features/impost/utils/models/worksImpostModel";
import { useTranslation } from "react-i18next";

import { RU } from "modules/common";
import { ConfirmationWorkView } from "modules/dashboard";

import styles from "./works.module.scss";

interface IWorks {
  data: PaginationType<ConfirmationWorkView[]> | undefined;
  isLoading: boolean;
}

const Works: React.FC<IWorks> = ({ data: paymentsWork, isLoading }) => {
  const { t } = useTranslation();
  const [visibleWorkConfirm, setVisibleWorkConfirm] = useState(false);
  const [workImpostData, setWorkImpostData] = useState<ConfirmationWorkView>();

  const onDetailWork = (record: ConfirmationWorkView) => ({
    onClick: () => {
      setVisibleWorkConfirm(true);
      setWorkImpostData(record);
    }
  });

  const columns: ColumnsType<ConfirmationWorkView> = [
    {
      title: `${t("Monitoring.Ish nomi")}`,
      render: (record: ConfirmationWorkView) => (
        <div className={styles.name}>
          {(record?.task?.name as string)?.length > 35 ? (
            <Popover title={record?.task?.name as string} overlayClassName={styles.popover}>
              <p>
                {record?.place}. {(record?.task?.name as string)?.substring(0, 35)}...
              </p>
            </Popover>
          ) : (
            <p>
              {record?.place}. {record?.task?.name as string}
            </p>
          )}
          <p>{record?.project?.name as string}</p>
        </div>
      ),
      width: "65%"
    },

    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: (record: ConfirmationWorkView) => (
        <span className={styles.bold}>
          {(record?.amount * record?.quantity)?.toLocaleString(RU)}
          {"  "}
          {record?.currency?.symbol}
        </span>
      )
    },
    {
      title: `${t("Monitoring.Sana")}`,
      render: (record: ConfirmationWorkView) => <span className={styles.bold}>{record?.completed_date}</span>
    },

    {
      title: "",
      render: (record: ConfirmationWorkView) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} />
        </div>
      ),
      width: "5%"
    }
  ];

  return (
    <div className={styles.works}>
      <Table<ConfirmationWorkView>
        columns={columns}
        pagination={false}
        dataSource={paymentsWork?.data}
        rowKey={record => record.id}
        onRow={onDetailWork}
        rowClassName={styles.row}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        className={`${styles.table} footer_table`}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: paymentsWork?.total
          }}
        />
      </div>
      <ConfirmImpost
        open={visibleWorkConfirm}
        setOpen={setVisibleWorkConfirm}
        record={workImpostData as unknown as WorksInventoryImpostModel}
        // isView={true}
      />
    </div>
  );
};

export default Works;
