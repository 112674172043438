import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Spin } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { cx } from "features/app/utils/helpers/cx";
import { getLocationPathValue } from "features/app/utils/helpers/getLocationValue";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import {
  useGetDetailedProject,
  useGetGeneralSearchSections,
  useGetGroupCustomField,
  useGetSections
} from "../../../service/queries";
import { EstimateSegmentedEnums } from "../../../utils/enums/estimateSegmentedEnum";
import { groupTypes } from "../../../utils/enums/groupTypes";
import { DynamicEstimateSectionModel } from "../../../utils/models/dynamicEstimateModel";

import { SectionAppend } from "./section/append/SectionAppend";
import Section from "./section/Section";
import TaskProductsTemplate from "./task/template/TaskProductsTemplate";

import styles from "./staticEstimate.module.scss";

type Props = {
  expandedAll: boolean;
  startPlanning: boolean;
  sections: DynamicEstimateSectionModel[] | undefined;
};

export const StaticEstimate: React.FC<Props> = ({ startPlanning, expandedAll, sections }) => {
  const params = useParams();
  const { i18n, t } = useTranslation();
  const { queries } = useQueryParams();
  const projectId = getLocationPathValue(3);
  const { estimate_group } = useAppSelector(state => state.localStorageReducer);
  const { estimate_type, general_search: searchValue, estimate_group: estimateGroupId, type } = queries();
  const estimateType = estimate_type ?? EstimateSegmentedEnums.PLAN;

  const groupCustomFiled = useMemo(() => {
    if (estimateGroupId) {
      return {
        projectId,
        id: estimateGroupId,
        type: type || groupTypes.SECTION
      };
    }

    return estimate_group?.find(item => item?.projectId === projectId);
  }, [estimateGroupId, type, projectId, estimate_group]);

  const { data: groupCustomField, isLoading: isLoadingGroup } = useGetGroupCustomField({
    projectId: params.projectId,
    customFieldId: groupCustomFiled?.id,
    type
  });
  const { data, isLoading } = useGetSections(params.projectId);
  const { data: projectData } = useGetDetailedProject(Number(params.projectId));
  const { data: searchSections, isLoading: isLoadingSearch } = useGetGeneralSearchSections(projectData?.id);

  const totalAmount = useMemo(() => {
    if (groupCustomFiled?.id) {
      return groupCustomField?.reduce((first, second) => first + second.total_amount, 0);
    }
    if (!isEmptyArr(searchSections)) {
      return searchSections?.reduce((first, second) => first + second.total_amount, 0);
    }
    if (estimateType === EstimateSegmentedEnums.FEATURE) {
      return sections?.reduce((first, second) => first + second.spend_total_amount + second.expected_total_amount, 0);
    }
    if (estimateType === EstimateSegmentedEnums.FACT) {
      return sections?.reduce((first, second) => first + second.spend_total_amount, 0);
    }

    return data?.reduce((first, second) => first + second.total_amount, 0);
  }, [data, sections, estimateType, searchSections, groupCustomField, groupCustomFiled?.id]);

  const isGroup = useMemo(() => {
    if (groupCustomFiled) {
      return Boolean(groupCustomFiled?.id && groupCustomFiled?.projectId === projectId);
      // if (groupCustomFiled?.id && groupCustomFiled?.projectId === projectId) {
      //   return true;
      // }
      // return false;
    }

    return false;
  }, [groupCustomFiled, projectId]);

  const bottomContent = useMemo(
    () => (
      <>
        <ConditionalRender if={!isGroup}>
          <SectionAppend index={data?.length} />
        </ConditionalRender>
        <TaskProductsTemplate />
        <div className={styles.total_amount}>
          <h2 className={styles.total_amount__left}>{t("project.Jami")}:</h2>
          <h2 className={styles.total_amount__right}>
            <span>{Number(totalAmount?.toFixed(2))?.toLocaleString(RU)}</span>
            <span> {projectData?.currency?.symbol}</span>
          </h2>
        </div>
      </>
    ),
    [isGroup, data?.length, t, totalAmount, projectData?.currency?.symbol]
  );

  return (
    <div className={styles.plan_page__body}>
      <ConditionalRender
        if={!isGroup && estimateType === EstimateSegmentedEnums.PLAN && (!isEmptyArr(data) || startPlanning)}
      >
        {searchValue ? (
          <Spin spinning={isLoadingSearch} indicator={LoadingIndicator}>
            {searchSections?.map(item => (
              <div className={styles.container} key={item?.id}>
                <Section isSearch item={item as never} key={item.id} expandedAll={expandedAll} />
              </div>
            ))}
          </Spin>
        ) : (
          <Spin spinning={isLoading} indicator={LoadingIndicator}>
            {data?.map(item => (
              <div className={styles.container} key={item?.id}>
                <Section item={item} key={item.id} isSearch={false} expandedAll={expandedAll} />
              </div>
            ))}
          </Spin>
        )}
        {bottomContent}
      </ConditionalRender>
      <ConditionalRender if={isGroup && estimateType === EstimateSegmentedEnums.PLAN}>
        <Spin spinning={isLoadingGroup} indicator={LoadingIndicator}>
          {groupCustomField?.map(item =>
            !isEmptyArr(item?.sections) ? (
              <div className={styles.container} key={item?.id}>
                <div
                  className={cx(styles.custom_field, {
                    "pb-4": isEmptyArr(item?.sections)
                  })}
                >
                  <div className={styles.custom_field__title}>
                    <h2 className={styles.custom_field__name} style={{ color: item?.color || colors.GRAY_500 }}>
                      {item?.name[i18n.language]}
                    </h2>
                  </div>
                  <h2 className={styles.custom_field__amount}>{item?.total_amount?.toLocaleString(RU)}</h2>
                </div>
                {item?.sections.map(section => (
                  <Section isSearch key={item.id} item={section} expandedAll={expandedAll} />
                ))}
                <SectionAppend
                  customField={{
                    value: item?.id,
                    custom_field_id: Number(groupCustomFiled?.id)
                  }}
                  index={item?.sections?.[item?.sections?.length - 1]?.place || 1}
                />
              </div>
            ) : null
          )}
        </Spin>
        {bottomContent}
      </ConditionalRender>
    </div>
  );
};

export default StaticEstimate;
