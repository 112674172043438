import React, { Key, useEffect, useState } from "react";
import { Checkbox, Form, FormInstance, Tree as AntdTree } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { DataNode } from "antd/es/tree";

import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon";
import { stringifyTreeData } from "../../utils/helpers/stringifyTreeData";

import styles from "./tree.module.scss";

type Props = {
  formInstance: FormInstance;
  name: string;
  title: string;
  label?: string;
  data: DataNode[];
  disabled?: boolean;
  initialData?: DataNode[];
  // eslint-disable-next-line no-unused-vars
  setData?: (data: Key[]) => void;
  isCleared?: boolean;
  // search?: string;
};

const { Item } = Form;

export const Tree: React.FC<Props> = ({
  data,
  name,
  label,
  title,
  setData,
  disabled,
  initialData,
  formInstance,
  isCleared
  // search
}) => {
  // const generateTreeData = useMemo(() => {
  //   if (search) {
  //     const treeData = [...data];
  //     const filteredByChildren = treeData?.filter(tree =>
  //       tree.children?.some(child => String(child?.title)?.toLowerCase().includes(search.toLowerCase()))
  //     );
  //     const filteredByTitlte = treeData?.filter(tree =>
  //       String(tree?.title)?.toLowerCase().includes(search.toLowerCase())
  //     );

  //     if (filteredByChildren.length > 0 && filteredByTitlte?.length === 0) return filteredByChildren;
  //     if (filteredByChildren.length > 0 && filteredByTitlte?.length > 0)
  //       return [...filteredByChildren, ...filteredByTitlte];
  //     return filteredByTitlte;
  //   }
  //   return data;
  // }, [search, data]);

  const flattenTree = (nodes: DataNode[]): Key[] => {
    let keys: Key[] = [];

    nodes?.forEach(node => {
      keys?.push(node.key);
      if (node.children) {
        keys = keys.concat(flattenTree(node.children));
      }
    });
    return keys;
  };

  const allKeys = flattenTree(data);

  const [checkedKeys, setCheckedKeys] = useState<{
    halfChecked: Key[];
    checked: Key[];
  }>({
    halfChecked: [],
    checked: []
  });

  const [checkedAll, setCheckedAll] = useState(false);

  const setKeys = (checked: Key[], halfChecked?: Key[]) => {
    setCheckedKeys({
      checked,
      halfChecked: halfChecked ?? []
    });

    setData && setData(checked);
    formInstance.setFieldValue(name, (checked as Key[]).concat(halfChecked ?? []));
    const allChecked = allKeys.every(key => checked.includes(key));

    setCheckedAll(allChecked);
  };

  useEffect(() => {
    if (initialData && data) {
      setKeys(stringifyTreeData(initialData));
      setCheckedAll(initialData?.length === data.length);
    }

    if (isCleared) {
      setKeys([]);
    }
  }, [initialData?.length, isCleared, data?.length]);

  const onCheck = (checked: { halfChecked: Key[]; checked: Key[] } | Key[], info: { halfCheckedKeys?: Key[] }) => {
    setKeys(checked as Key[], info.halfCheckedKeys);
  };

  const onCheckAll = (e: CheckboxChangeEvent) => {
    setCheckedAll(e.target.checked);

    if (e.target.checked) {
      setKeys(stringifyTreeData(data, true));
    } else {
      setKeys([]);
    }
  };

  return (
    <div className={styles.tree}>
      <div className={styles.tree__header}>
        <div className={styles.tree__header__left}>
          <p>{title}</p>
        </div>
        <div className={styles.tree__header__right}>
          <Checkbox onChange={onCheckAll} checked={checkedAll} />
        </div>
      </div>
      <Item name={name} label={label ?? undefined} className={styles.tree__body}>
        <AntdTree
          multiple
          checkable
          treeData={data}
          onCheck={onCheck}
          selectable={true}
          disabled={disabled}
          checkedKeys={checkedKeys}
          switcherIcon={<SelectSuffixIcon />}
          className={styles.tree}
          expandAction="click"
        />
      </Item>
    </div>
  );
};
