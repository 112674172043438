import React, { useState } from "react";
import { Button, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import FolderCard from "../../../app/components/folder-card/FolderCard";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../app/components/not-data/NotData";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";
import SettingsCashCard from "../../components/cash/card/SettingsCashCard";
import { SettingsCashModal } from "../../components/cash/modal/SettingsCashModal";
import { useCreateCashFolder, useDeleteCashFolder, useUpdateCashFolder } from "../../service/mutations";
import { useGetCash } from "../../service/queries";
import { folderName } from "../../utils/helper/folderName";

import emptyImage from "../../assets/images/kassa.png";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCash();

  const deleteFolder = useDeleteCashFolder();
  const updateFolder = useUpdateCashFolder();
  const createFolder = useCreateCashFolder();

  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    visible: boolean;
    id?: number;
  }>({
    visible: false
  });

  const onCreateFolder = () => {
    setIsCreating(true);
  };

  const onOpenModal = () => {
    setModalData({
      visible: true
    });
  };

  const isAddCash = () => {
    if (data?.cashs && data?.cashs?.length > 0) return true;
    if (data?.cash_folders && data?.cash_folders.length > 0) return true;
    return false;
  };

  return (
    <div className={styles.cash}>
      <div className={styles.cash__top}>
        <div className={styles.left}>
          <h3>{t("Kassa.Kassa")}</h3>
          <p>{t("Kassa.Bu yerda siz kassa yaratishingiz mumkin")}</p>
        </div>
        <Button onClick={onCreateFolder}>
          <FolderPlusIcon /> {t("Kassa.Papka yaratish")}
        </Button>
      </div>
      <div className={styles.cash__bottom}>
        {!isEmptyArr(data?.cash_folders) && <h4>{t("Kassa.Papkalar")}</h4>}
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {isEmptyArr(data?.cash_folders) && isEmptyArr(data?.cashs) && !isCreating && (
            <NotData
              containerClassName={styles.empty__container}
              img={{
                src: emptyImage,
                className: styles.empty_image
              }}
              name={{ text: t("Kassa.Kassa katologi mavjud emas") }}
              text={{
                text: t("Kassa.Ushbu kassalar katologini qo’shish orqali kassalaringizni boshqaring")
              }}
              defaultButton={{
                text: t("products.Katalog yaratish"),
                onClick: onCreateFolder,
                icon: <FolderPlusIcon />
              }}
              primaryButton={{
                text: t("Kassa.Kassa qo'shish"),
                onClick: onOpenModal,
                icon: <PlusIcon color="#fff" />
              }}
            />
          )}
          <div className={styles.folders}>
            {!isEmptyArr(data?.cash_folders) &&
              data?.cash_folders?.map(item => (
                <FolderCard
                  id={item.id}
                  key={item.id}
                  color={item.color}
                  sliceTextCount={32}
                  placeholder={t("Kassa.Kassa")}
                  count={item.cashs_count}
                  name={folderName(item.name)}
                  updateMutation={updateFolder}
                  deleteMutation={deleteFolder}
                  path={`${rootPaths.SETTINGS}${settingsPaths.CASH}/${item.name}-${item?.id}`}
                />
              ))}
            {isCreating && (
              <FolderCard
                placeholder={t("Kassa.Kassa")}
                isCreating={isCreating}
                setIsCreating={setIsCreating}
                createMutation={createFolder}
              />
            )}
          </div>
        </Spin>
        <div className={styles.cards}>
          {!isEmptyArr(data?.cashs) &&
            data?.cashs?.map((card, index) => <SettingsCashCard {...card} key={index} setModalData={setModalData} />)}
          {isAddCash() && (
            <div className={styles.card_add} onClick={onOpenModal}>
              <PlusCircleIcon fill={false} />
              <p>{t("Kassa.Kassa qo'shish")}</p>
            </div>
          )}
        </div>
      </div>

      <SettingsCashModal data={modalData} setData={setModalData} />
    </div>
  );
};

export default Index;
