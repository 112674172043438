import { isEmptyArr, isEmptyObj } from "../index";

export const isEmptyValue = (value: any) => {
  if (typeof value === "object" && !Array.isArray(value)) {
    return isEmptyObj(value);
  }

  if (typeof value === "object" && Array.isArray(value)) {
    return isEmptyArr(value);
  }

  return !value;
};
