import React from "react";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import i18n from "i18next";

import { colors, RU } from "modules/common";
import { Gantt as GanttType } from "modules/dashboard";

import { CheckCircleIcon } from "components";

import XCircleIcon from "../../../../../../features/warehouse/assets/icons/XCircleIcon";

type Props = {
  item: GanttType["data"][number];
};

const Tooltip: React.FC<Props> = ({ item }) => {
  const status: Record<"recieved" | "rejected" | "open", JSX.Element> = {
    recieved: <CheckCircleIcon />,
    rejected: <XCircleIcon />,
    open: <CheckCircleIcon stroke={colors.GRAY_300} />
  };

  return (
    <div
      style={{
        boxShadow: `0 0 10 0 rgba(0,0,0,0.25)`
      }}
      className="box-border flex flex-col gap-3 rounded-md border border-solid border-gray-300 bg-white px-5 py-4"
    >
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-medium text-gray-400">Soni</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{item?.quantity}</span>
          <span>{item?.unit?.symbol?.[i18n.language]}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-medium text-gray-400">Narxi</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{item?.amount?.toLocaleString(RU)}</span>
          <span>{item?.currency?.symbol}</span>
        </div>
      </div>
      <ConditionalRender if={item?.order_id}>
        <div className="flex items-center justify-between gap-6">
          <span className="text-sm font-medium text-gray-400">Partiya</span>
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">P-{item?.order_id}</div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={item?.company_person}>
        <div className="flex items-center justify-between gap-6">
          <span className="text-sm font-medium text-gray-400">Ta'minotchi</span>
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">{item?.company_person?.name}</div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={item?.company_person}>
        <div className="flex items-center justify-between gap-6">
          <span className="text-sm font-medium text-gray-400">Telefon raqami</span>
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            {item?.company_person?.person?.phone}
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender if={item?.company_person}>
        <div className="flex items-center justify-between gap-6">
          <span className="text-sm font-medium text-gray-400">Holati</span>
          <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
            {status[(item?.status as keyof typeof status) || "open"]}
          </div>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default Tooltip;
