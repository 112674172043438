import React, { useCallback, useRef, useState } from "react";
import { useDispatch } from "react-redux";


import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import { useDeletePayment } from "../../../../service/mutation";


import IncomeExpenseTable from "./IncomeExpenseTable";

const PaymentIncomeExpenseTable: React.FC = () => {
  const deletePayment = useDeletePayment();
  const dispatch = useDispatch();
  const { setConversionInfoModal, setTransferHistoryModal } = paymentReducerActions;
  const printComponentRef = useRef<HTMLDivElement | null>(null);

  const [paymentDeleteModal, setPaymentDeleteModal] = useState({
    visible: false,
    id: 0
  });

  const setPaymentDeleteModalOpen = (visible: boolean) => {
    setPaymentDeleteModal(prev => ({
      ...prev,
      id: visible ? prev?.id : 0,
      visible
    }));
  };

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const onDeletePayment = () => {
    dispatch(setConversionInfoModal(undefined));
    dispatch(setTransferHistoryModal(undefined));
  };

  return (
    <>
      <IncomeExpenseTable
        printComponentRef={printComponentRef}
        reactToPrintContent={reactToPrintContent}
        setPaymentDeleteModal={setPaymentDeleteModal}
      />
      <ModalConfirm
        isDescription
        mutation={deletePayment}
        onAfterClose={onDeletePayment}
        visible={paymentDeleteModal.visible}
        setVisible={setPaymentDeleteModalOpen}
        payload={{ id: paymentDeleteModal.id }}
      />
    </>
  );
};

export default PaymentIncomeExpenseTable;
