import React, { FC, useEffect, useMemo } from "react";
import {useOutletContext, useParams} from "react-router-dom";
import { Button, Form, Input, Spin, Tooltip } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../../../app/assets/icons/DeleteIcon";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../../app/components/modal-confirm/ModalConfirm";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../../app/service/queries";
import { RU } from "../../../../../../app/utils/constants/languages";
import { tableConfigKeys } from "../../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../../app/utils/enums/customFieldLocationEnum";
import { areObjectsEqual } from "../../../../../../app/utils/helpers/areObjectsEqual";
import { CustomFieldSelectModel } from "../../../../../../app/utils/models/customFieldSelectModel";
import { ReqCustomFieldValueModel } from "../../../../../../app/utils/models/reqCustomFieldValueModel";
import WarningIcon from "../../../../../assets/icons/WarningIcon";
import { useDeleteSection, useUpdateSection } from "../../../../../service/mutations";
import { estimateTableDefaultData } from "../../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../../utils/helpers/estimateColumnsWidth";
import { SectionModel } from "../../../../../utils/models/sectionModel";
import EstimateCustomField from "../../../estimate-custom-field/EstimateCustomField";

import styles from "../section.module.scss";
import {OutletContextType} from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";

type Props = {
  name: string;
  id: number;
  place: number;
  totalAmount: number;
  plan: boolean;
  active: boolean;
  handleSection: () => void;
  data: SectionModel;
};

const { useForm, Item } = Form;

export const SectionHeader: FC<Props> = ({
  id,
  plan,
  data,
  place,
  active,
  totalAmount,
  handleSection,
  name: sectionName
}) => {
  const { t } = useTranslation();
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const projectId = useParams()?.projectId;
  const [form] = useForm();
  const updateSection = useUpdateSection();
  const deleteSection = useDeleteSection();
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  const onBlurForm = () => {
    form.submit();
  };

  const onDelete = () => deleteSection.mutateAsync(id);

  const onFinish = (fields: { name: string; custom_field_values: ReqCustomFieldValueModel[] }) => {
    const newData = {
      id,
      name: fields?.name,
      project_id: Number(projectId),
      custom_field_values: fields?.custom_field_values?.filter(item => item?.value)
    };
    const oldData = {
      id,
      name: data?.name,
      project_id: Number(projectId),
      custom_field_values: data?.custom_field_values?.map(item => ({
        custom_field_id: item?.custom_field?.id,
        value: item?.value
      }))
    };

    if (areObjectsEqual(newData, oldData)) {
      updateSection.mutate(newData);
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data?.name,
        custom_field_values: customFieldsColumnRender?.map(item => {
          const currentValue = data?.custom_field_values?.find(custom => item?.id === custom?.custom_field?.id);

          return {
            value: currentValue?.value,
            custom_field_id: currentValue?.custom_field?.id
          };
        })
      });
    }
  }, [customFieldsColumnRender, data, form]);

  const sectionHeader = useMemo(() => {
    const filterConfigData = tableConfigData?.column?.filter(column => column?.checked);

    const basicElements = filterConfigData
      ?.filter(item => typeof item?.id === "string")
      ?.map(item => <div key={item.id} />);

    return {
      data: filterConfigData,
      elements: basicElements
    };
  }, [tableConfigData]);

  const headerStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth({
      // @ts-ignore
      column: sectionHeader.data,
      size: 10
    })} 16.25rem`
  };

  return (
    <Spin indicator={LoadingIndicator} wrapperClassName={styles.spin} spinning={updateSection.isLoading}>
      <Form form={form} autoComplete="off" onFinish={onFinish} style={headerStyle} className={styles.section__header}>
        <div className={styles.section__header__left}>
          <ConditionalRender if={!plan}>
            <Tooltip getPopupContainer={() => smetaContainer} title={t("project.Rejadan tashqari")}>
              <div className={styles.warning}>
                <WarningIcon />
              </div>
            </Tooltip>
          </ConditionalRender>
          <SelectSuffixIcon
            onClick={handleSection}
            className={styles.selectIcon}
            placement={active ? "bottom" : "right"}
          />
          <div className={styles.name}>
            <p>{place}.</p>
            <Item name="name" initialValue={sectionName}>
              <Input placeholder={t("project.Bo'lim nomini kiriting")} className={styles.name} />
            </Item>
          </div>
        </div>
        {sectionHeader?.elements}
        {EstimateCustomField({
          form,
          onBlur: onBlurForm,
          values: data?.custom_field_values,
          customFields: customFieldsColumnRender,
          className: classNames(styles.form__item),
          locations: CustomFieldLocationEnum.SECTION
        })}
        <div className={styles.section__header__right}>
          <p className={styles.amount}>{Number(totalAmount?.toFixed(2))?.toLocaleString(RU)}</p>
          <div className={styles.actions}>
            <div className={styles.svg_wrapper}>
              <ModalConfirm getPopupContainer={() => smetaContainer} onOk={onDelete}>
                <DeleteIcon />
              </ModalConfirm>
            </div>
          </div>
        </div>
        <Button className="hidden" htmlType="submit" />
      </Form>
    </Spin>
  );
};
