import { useQuery } from "@tanstack/react-query";
import { endPoints } from "features/app/utils/constants/endPoints";
import $api from "features/app/utils/helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import { queryKeys } from "../query-keys";
import { LanguageSelect, languageSelectSchema } from "../schema";

interface Query {
  languages: LanguageSelect[];
}

export function useLanguagesSelect() {
  const { i18n } = useTranslation();
  const initialValue: Query = {
    languages: []
  };

  const { data = initialValue, ...arg } = useQuery(
    [queryKeys.LANGUAGES_SELECT],
    async () => {
      const { data } = await $api.get(endPoints.LANGUAGES);

      const baseLanguages: LanguageSelect[] = data?.data?.map((item: LanguageSelect) =>
        languageSelectSchema.parse(item)
      );

      return {
        languages: baseLanguages?.sort((a: LanguageSelect) => {
          if (a.symbol !== i18n.language) return 1;
          return -1;
        })
      };
    },
    {
      staleTime: Infinity
    }
  );

  return { ...data, ...arg };
}
