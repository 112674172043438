import React from "react";
import { Form, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import { cx } from "../../../../../../../app/utils/helpers/cx";
import ProjectCard from "../../project-card/project-card";

import Select from "./select";

type Props = {
  name: string[];
  formInstance: FormInstance;
};

const { List } = Form;

const Projects: React.FC<Props> = ({ formInstance, name }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-2.5">
      <div className={cx("ant-form-item-label")}>
        <label>{t("payment.Loyihalar")}</label>
      </div>
      <List name={name}>
        {(fields, operation) => (
          <>
            {fields.map((_, key) => (
              <ProjectCard key={key} index={key} name={name} operation={operation} formInstance={formInstance} />
            ))}
            <Select operation={operation} />
          </>
        )}
      </List>
    </div>
  );
};

export default Projects;
