import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { DynamicEstimateProductModel } from "features/projects/utils/models/dynamicEstimateModel";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys } from "modules/dashboard";

interface Query {
  projectByResourceView: PaginationType<DynamicEstimateProductModel[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id?: number;
  resource_type?: string;
  project_id?: number;
}

export function useProjectByResourceView({ enabled = false, id, resource_type, project_id }: Params) {
  const initialValue: Query = { projectByResourceView: { data: [], total: 0, current_page: 1 } };

  const { generateSearchParam, queries } = useQueryParams();
  const { page, tab } = queries();

  const getType = () => {
    if (tab) {
      if (tab?.split("-")[1] === "null") {
        return "another";
      }
      return tab?.split("-")[1];
    }
    return resource_type;
  };

  const searchParams = generateSearchParam({
    id,
    page,
    size: 10,
    resource_type: getType(),
    project_id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_BY_RESOURCE_DATA_VIEW, searchParams, resource_type],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-resource/view?${searchParams}`);

      return { projectByResourceView: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
