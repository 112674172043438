import { useEffect, useRef, useState } from "react";
import { Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import ConfirmationUsersList from "features/app/components/confirmation-users-list/ConfirmationUsersList";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { useExportMonitoringWorksExcel } from "features/impost/service/mutations";
import { inventoryWorksTableDefaultData } from "features/impost/utils/constants/impostTableDefaultData";
import { useTranslation } from "react-i18next";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../app/components/pagination/Pagination";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { ImpostStatusIcons } from "../../../app/utils/constants/importStatusIcons";
import { RU } from "../../../app/utils/constants/languages";
import CompanyPersonSourceElement from "../../../payment/components/bottom/income-expense-view/CompanyPersonSourceElement";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import { useGetWorksImpost } from "../../service/queries";
import { WorksInventoryImpostModel } from "../../utils/models/worksImpostModel";
import ConfirmImpost from "../confirm-impost/ConfirmImpost";

import ActionDropdown from "./action-dropdown/ActionDropdown";
import FilterDrawerWorks from "./filter-drawer/FilterDrawerWorks";

import styles from "./works.module.scss";

const Works = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const [visibleWorkConfirm, setVisibleWorkConfirm] = useState(false);
  const [workImpostData, setWorkImpostData] = useState<WorksInventoryImpostModel>();
  const { data: paymentsWork, isLoading } = useGetWorksImpost();

  const exportExcelMutate = useExportMonitoringWorksExcel();

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.MONITORING_WORKS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.MONITORING_WORKS, inventoryWorksTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.MONITORING_WORKS]);

  const onDetailWork = (record: WorksInventoryImpostModel) => ({
    onClick: () => {
      setVisibleWorkConfirm(true);
      setWorkImpostData(record);
    }
  });

  const [renderColumns, setColumns] = useState<ColumnsType<WorksInventoryImpostModel>>([]);

  const columns = [
    {
      title: `${t("Monitoring.Ish nomi")}`,
      render: (record: WorksInventoryImpostModel) => (
        <div className={styles.name}>
          {record?.task?.name.length > 35 ? (
            <Popover title={record?.task?.name} overlayClassName={styles.popover}>
              <p className="m-0">
                {record?.place}. {record?.task?.name.substring(0, 35)}...
              </p>
            </Popover>
          ) : (
            <p className="m-0">
              {record?.place}. {record?.task?.name}
            </p>
          )}
          <p className="m-0">{record?.project?.name}</p>
        </div>
      ),
      width: 750,
      className: "works-name",
      key: "task.name",
      fixed: "left"
    },
    {
      title: `${t("Monitoring.Guvohlar")}`,
      render: (record: WorksInventoryImpostModel) => {
        const columnWidth = renderColumns?.find(column => column?.className === "works-victims")?.width;

        return (
          <ConfirmationUsersList
            record={record as never}
            length={columnWidth ? (columnWidth as number) / record?.confirmation_users?.length : 10}
          />
        );
      },
      width: 300,
      className: "works-victims",
      key: "works-victims"
    },
    {
      title: `${t("Monitoring.Ish tugallandi")}`,
      dataIndex: "completed_date",
      width: 300,
      className: "works-completed-date",
      key: "completed_date"
    },
    {
      title: `${t("Monitoring.Umumiy summa")}`,
      render: (record: WorksInventoryImpostModel) => (
        <span className={styles.bold}>
          {(record?.amount * record?.quantity)?.toLocaleString(RU)}
          {"  "}
          {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "works-amount",
      key: "amount"
    },
    {
      title: `${t("MainLayoutLinks.Kontragent")}`,
      render: (record: WorksInventoryImpostModel) => (
        <CompanyPersonSourceElement
          isViewCompanyPersonBalance
          record={record?.company_person}
          name={record?.company_person?.name}
        />
      ),
      width: 400,
      className: "works-company-person",
      key: "company_person.name"
    },
    {
      title: `${t("Xodimlar.Xodim")}`,
      render: (record: WorksInventoryImpostModel) => (
        <div className={styles.creator}>
          <CustomAvatar image={record?.creator?.image} name={record?.creator?.full_name} />
        </div>
      ),
      width: 300,
      className: "works-creator",
      key: "creator.full_name"
    },
    {
      title: `${t("Monitoring.Tasdiqlash")}`,
      render: (record: WorksInventoryImpostModel) => (
        <div className={styles.status}>{ImpostStatusIcons[record?.confirmation_payment]}</div>
      ),
      width: 250,
      className: "works-status",
      align: "center",
      key: "confirmation_payment"
    }
  ];

  useEffect(() => {
    // @ts-ignore
    if (tableConfig?.width_data?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    const columnsToSave = renderColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.MONITORING_WORKS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<WorksInventoryImpostModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const title = () => (
    <div className={styles.title}>
      <div className={styles.search}>
        <PaymentSearch />
      </div>
      <FilterDrawerWorks exportExcelMutate={exportExcelMutate} />
    </div>
  );

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={inventoryWorksTableDefaultData}
              locations={[CustomFieldLocationEnum.MONITORING_WORKS]}
              configKey={tableConfigKeys.MONITORING_WORKS}
            />
          ),

          render: (record: WorksInventoryImpostModel) => <ActionDropdown record={record} />,
          width: 70,
          className: "works-action",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  const footer = () => (
    <Pagination
      paginationProps={{
        total: paymentsWork?.total
      }}
    />
  );

  return (
    <div className={styles.works}>
      <div className={styles.top}>
        <Table<WorksInventoryImpostModel>
          title={title}
          columns={renderColumns}
          pagination={false}
          onChangeColumns={onChangeColumns}
          dataSource={paymentsWork?.data}
          rowKey={record => record.id}
          onRow={onDetailWork}
          rowClassName={() => styles.row}
          scroll={{ y: "calc(100vh - 18.125rem)" }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
          footer={footer}
        />
      </div>

      <ConfirmImpost open={visibleWorkConfirm} setOpen={setVisibleWorkConfirm} record={workImpostData} />
    </div>
  );
};

export default Works;
