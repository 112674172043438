import React from "react";

const BuyingIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    {...props}
    width={props?.width || "16"}
    height={props?.height || "16"}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.00065 9.9987L5.33398 11.332L8.33398 8.33203M5.33398 5.33203V3.46536C5.33398 2.71863 5.33398 2.34526 5.47931 2.06004C5.60714 1.80916 5.81111 1.60519 6.062 1.47736C6.34721 1.33203 6.72058 1.33203 7.46732 1.33203H12.534C13.2807 1.33203 13.6541 1.33203 13.9393 1.47736C14.1902 1.60519 14.3942 1.80916 14.522 2.06004C14.6673 2.34526 14.6673 2.71863 14.6673 3.46536V8.53203C14.6673 9.27877 14.6673 9.65214 14.522 9.93735C14.3942 10.1882 14.1902 10.3922 13.9393 10.52C13.6541 10.6654 13.2807 10.6654 12.534 10.6654H10.6673M3.46732 14.6654H8.53398C9.28072 14.6654 9.65409 14.6654 9.93931 14.52C10.1902 14.3922 10.3942 14.1882 10.522 13.9374C10.6673 13.6521 10.6673 13.2788 10.6673 12.532V7.46536C10.6673 6.71863 10.6673 6.34526 10.522 6.06004C10.3942 5.80916 10.1902 5.60519 9.93931 5.47736C9.65409 5.33203 9.28072 5.33203 8.53398 5.33203H3.46732C2.72058 5.33203 2.34721 5.33203 2.062 5.47736C1.81111 5.60519 1.60714 5.80916 1.47931 6.06004C1.33398 6.34526 1.33398 6.71863 1.33398 7.46536V12.532C1.33398 13.2788 1.33398 13.6521 1.47931 13.9374C1.60714 14.1882 1.81111 14.3922 2.062 14.52C2.34721 14.6654 2.72058 14.6654 3.46732 14.6654Z"
      stroke={props?.stroke || "#F79009"}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BuyingIcon;
