import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal, Popover, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { warehouseProductsAction } from "../../../../../../store/reducers/warehouseProductsReducer";
import PackageSearch from "../../../../../app/assets/icons/PackageSearch";
import { cx } from "../../../../../app/utils/helpers/cx";
import { OrderDetailModel, WarehouseProductModel } from "../../../../utils/models/orderModel";

import styles from "./resourceAvailability.module.scss";

type Props = {
  oneOrder?: OrderDetailModel;
};

const ResourceAvailabilityModal: React.FC<Props> = ({ oneOrder }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { setResource } = warehouseProductsAction;

  const generatePercent = (quantity?: number, warehouseTotalQuantity?: number) => {
    const float = parseFloat(((warehouseTotalQuantity || 0) / (quantity || 1)).toFixed(2));

    if (!quantity || float >= 1) return 100;

    return parseFloat((((warehouseTotalQuantity || 0) * 100) / quantity).toFixed(2));
  };

  const content = (quantity?: number, warehouseTotalQuantity?: number, symbol?: string) => (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Buyurtma qilingan")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{quantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <span className="text-sm font-normal text-gray-500">{t("impost.Mavjud")}</span>
        <div className="flex items-center gap-1 text-sm font-medium text-gray-600">
          <span>{warehouseTotalQuantity}</span>
          <span className="text-gray-400">{symbol}</span>
        </div>
      </div>
    </div>
  );

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: t("impost.Resurs turi va nomi"),
      render: (_, record) => (
        <div className="flex items-center gap-2">
          <div
            className="flex items-center rounded border border-solid px-2 py-0.5 text-xs font-medium"
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol?.[i18n.language]}
          </div>
          <span>{record?.product?.name?.[i18n.language]}</span>
        </div>
      )
    },
    {
      title: t("impost.Birligi"),
      render: (_, record) => record?.unit?.symbol?.[i18n.language]
    },
    {
      title: t("impost.Mavjud"),
      dataIndex: "warehouse_total_quantity"
    },
    {
      title: t("impost.Buyurtma qilingan"),
      dataIndex: "quantity"
    },
    {
      title: t("impost.Foiz"),
      render: (_, record) => {
        const percent = generatePercent(record?.quantity, record?.warehouse_total_quantity);

        return (
          <Popover
            arrow={false}
            placement="top"
            content={content(record?.quantity, record?.warehouse_total_quantity, record?.unit?.symbol?.[i18n.language])}
          >
            <div
              className={cx("cursor-pointer rounded-2xl px-3 py-1", {
                "bg-error-50 text-error-500": percent >= 0 && percent <= 30,
                "bg-warning-50 text-warning-500": percent > 30 && percent <= 70,
                "bg-success-50 text-success-500": percent > 70
              })}
            >
              {percent} %
            </div>
          </Popover>
        );
      }
    }
  ];

  const onOpen = () => setOpen(true);

  const onCancel = () => {
    setOpen(false);
  };

  const onRow: TableProps["onRow"] = (record: WarehouseProductModel) => ({
    onClick: () => {
      dispatch(
        setResource({
          visible: true,
          product: record as never
        })
      );
    }
  });

  return (
    <>
      <div className="flex items-center gap-2.5" onClick={onOpen}>
        <PackageSearch />
        <span>{t("impost.Resurs mavjudligi")}</span>
      </div>
      <Modal
        centered
        open={open}
        footer={null}
        zIndex={11115}
        onCancel={onCancel}
        rootClassName={styles.modal}
        title={t("impost.Resurs mavjudligi")}
      >
        <Table
          onRow={onRow}
          className="mt-7"
          columns={columns}
          pagination={false}
          rowClassName="cursor-pointer"
          dataSource={oneOrder?.warehouse_products}
        />
      </Modal>
    </>
  );
};

export default ResourceAvailabilityModal;
