import React from "react";

import { useWidget } from "modules/dashboard";
import { useWarehouseDefect } from "modules/dashboard/queries/use-warehouse-defect";

import WarehouseDefectViewTable from "./table/WarehouseDefectViewTable";

const WarehouseDefect: React.FC = () => {
  const { visible, id } = useWidget();
  const { companyWarehouseDefectView, isLoading } = useWarehouseDefect({ enabled: visible, id });

  return (
    <div className="w-[59.75rem] p-5">
      <WarehouseDefectViewTable data={companyWarehouseDefectView} isLoading={isLoading} />
    </div>
  );
};

export default WarehouseDefect;
