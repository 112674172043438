export function parseTreeValue<T>(value: string[], result: T): T {
  const parsed: { key: string, value: number }[] = value.map(item => JSON.parse(item))

  parsed.forEach(item => {
    const key: keyof T = item.key as keyof T

    (result[key] as number[])?.push(item.value)
  })

  return result as T
}
