import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

export interface IAddressModel {
  addressModal: {
    visible: boolean;
    address?: string;
    center?: {
      lat?: number;
      lng?: number;
    };
    district_id?: number;
    region_id?: number;
  };
}

const initialState: IAddressModel = {
  addressModal: {
    visible: false,
    address: "",
  },
};

const addressSlice = createSlice({
  initialState,
  name: sliceNames.ADDRESS,
  reducers: {
    setAddressModalVisible: (state, action: PayloadAction<boolean>) => {
      state.addressModal.visible = action.payload;
    },
    setAddress: (state, action: PayloadAction<string>) => {
      state.addressModal.address = action.payload;
    },
    setLatLongAddress: (state, action: PayloadAction<{ lat: number | undefined; lng: number | undefined }>) => {
      state.addressModal.center = action.payload;
    },
    setDistrictId: (state, action: PayloadAction<number | undefined>) => {
      state.addressModal.district_id = action.payload;
    },
    setRegionId: (state, action: PayloadAction<number | undefined>) => {
      state.addressModal.region_id = action.payload;
    },
    setAddressDataToEmpty: state => {
      state.addressModal = {
        visible: false,
        address: undefined,
        center: undefined,
        district_id: undefined,
        region_id: undefined,
      };
    },
  },
});

export default addressSlice.reducer;
export const addressReducerActions = addressSlice.actions;
