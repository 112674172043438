import React from "react";

const DropdownCloudIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33341 13.5352C2.32843 12.8625 1.66675 11.7168 1.66675 10.4167C1.66675 8.46369 3.15967 6.85941 5.06653 6.68281C5.45659 4.31011 7.51695 2.5 10.0001 2.5C12.4832 2.5 14.5436 4.31011 14.9336 6.68281C16.8405 6.85941 18.3334 8.46369 18.3334 10.4167C18.3334 11.7168 17.6717 12.8625 16.6667 13.5352M6.66675 14.1667L10.0001 17.5M10.0001 17.5L13.3334 14.1667M10.0001 17.5V10"
        stroke="#344054"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropdownCloudIcon;
