import { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import { parseParamsId } from "../../../utils/helpers/parseParamsId";
import { parseToCrumbName } from "../../../utils/helpers/parseToCrumbName";

export const dynamicCrumb = (match: BreadcrumbMatch, key: string, key2?: string) => {
  const { name } = parseParamsId(match?.params[key]);
  let name2: string | undefined = "";

  if (key2) {
    name2 = parseParamsId(match?.params[key2])?.name;
  }
  if (name2) {
    return parseToCrumbName(name2, true) ?? "";
  }
  return parseToCrumbName(name, true) ?? "";
};
