import React from "react";
import { Skeleton } from "antd";

import { Widget } from "modules/dashboard/schema/schema";

import WidgetCard from "./card";

type Props = {
  data: Widget[] | undefined;
  isLoading?: boolean;
  isCompany?: boolean;
};

const WidgetList: React.FC<Props> = ({ data, isLoading, isCompany }) => {
  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <div className="flex flex-col gap-3">
      {data?.map(item => <WidgetCard key={item?.id} {...item} isCompany={isCompany} />)}
    </div>
  );
};

export default WidgetList;
