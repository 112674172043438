import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, FormInstance } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

type Props = {
  customField: CustomFieldSelectModel;
  index: number;
  disabled?: boolean;
  form?: FormInstance;
};

const { Item } = Form;

const CustomFieldCheckbox: React.FC<Props> = ({
  customField,
  index,
  disabled,
  form,
}) => {
  const { i18n } = useTranslation();

  const onChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    form?.setFieldValue(
      ["custom_field_values", index, "value"],
      checked ? customField?.id : undefined
    );
  };

  return (
    <React.Fragment>
      <Item
        className="d_n"
        name={["custom_field_values", index, "custom_field_id"]}
        initialValue={customField?.id}
      />
      <Item
        className="d_n"
        name={["custom_field_values", index, "type"]}
        initialValue={customField?.type}
      />
      <Item valuePropName="checked" name={["custom_field_values", index, "value"]}>
        <Checkbox value={customField?.id} disabled={disabled} onChange={onChange}>
          {customField?.name[i18n.language]}
        </Checkbox>
      </Item>
    </React.Fragment>
  );
};

export default CustomFieldCheckbox;
