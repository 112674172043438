import { Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useGetUsersSelect } from "../../../../../app/service/queries";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import SelectNotContent from "../../../../../app/components/select-not-content/SelectNotContent";

import styles from "./confirmPartyModal.module.scss";

const { Option } = Select;

const ConfirmPartyAgentSelect: React.FC = () => {
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const { setConfirmPartyModalItem } = supplyOfferActions;
  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const { visible, agentId } = useAppSelector(
    (state) => state.supplyOfferReducer.offerModal.partyModal
  );

  const { data: agents } = useGetUsersSelect(visible);

  const onAddAdmin = () => {
    dispatch(
      setAdminModal({
        visible: true,
        initialName: searchValue,
        afterFunc: (id: number) => {
          dispatch(
            setConfirmPartyModalItem({
              key: "agentId",
              value: id as never,
            })
          );
        },
      })
    );
  };

  const onChangeAgent = (e: number) => {
    dispatch(
      setConfirmPartyModalItem({
        key: "agentId",
        value: e as never,
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  return (
    <div className={styles.item}>
      <label>Vositachi</label>
      <Select
        showSearch
        allowClear
        value={agentId}
        onSearch={onSearch}
        placeholder="Tanlang"
        onChange={onChangeAgent}
        searchValue={searchValue}
        suffixIcon={<SelectSuffixIcon />}
        filterOption={selectFilterOption}
        notFoundContent={<SelectNotContent title="Vositachi" action={onAddAdmin} />}
      >
        {agents?.map((item) => (
          <Option
            value={item.id}
            key={item.id}
            props={{
              name: item?.full_name,
            }}
          >
            {item?.full_name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ConfirmPartyAgentSelect;
