import { useAppDispatch } from "../../../../hooks/redux";
import CreateCardPlusIcon from "../../assets/icons/CreateCardPlusIcon";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";

import styles from "./createProjectCard.module.scss";
import { useTranslation } from "react-i18next";

export const ProjectCreateCard = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setProjectModalData } = projectsReducerActions

  const onCreateProject = () => {
    dispatch(setProjectModalData({ visible: true }))
  }

  return (
    <div onClick={onCreateProject} className={styles.create_card}>
      <CreateCardPlusIcon />
      <p>{t("project.Loyiha qo'shish")}</p>
    </div>
  )
}