import React from "react";

const SelectNotContentIcon: React.FC = () => {
  return (
    <svg
      width="76"
      height="75"
      viewBox="0 0 76 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_12306_66845)">
        <mask
          id="mask0_12306_66845"
          // style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="76"
          height="75"
        >
          <path d="M76 0H0V75H76V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_12306_66845)">
          <path
            d="M38 67.25C54.796 67.25 68.4 53.825 68.4 37.25C68.4 20.675 54.796 7.25 38 7.25C21.204 7.25 7.59998 20.675 7.59998 37.25C7.59998 53.825 21.204 67.25 38 67.25Z"
            fill="url(#paint0_linear_12306_66845)"
          />
          <path
            d="M26.6 15.9004H50.0333C52.2626 15.9004 54.0866 17.7004 54.0866 19.9004V50.6504C54.0866 52.8504 52.2626 54.6504 50.0333 54.6504H26.6C24.3706 54.6504 22.5466 52.8504 22.5466 50.6504V19.9004C22.5466 17.6754 24.3706 15.9004 26.6 15.9004Z"
            fill="url(#paint1_linear_12306_66845)"
          />
          <path
            d="M26.5746 20.9004H36.7586C37.4426 20.9004 38 21.4504 38 22.1254C38 22.8004 37.4426 23.3504 36.7586 23.3504H26.5746C25.8906 23.3504 25.3333 22.8004 25.3333 22.1254C25.3333 21.4254 25.8906 20.9004 26.5746 20.9004Z"
            fill="black"
          />
          <path
            d="M26.5746 27.8496H49.4253C50.1093 27.8496 50.6666 28.3996 50.6666 29.0746C50.6666 29.7496 50.1093 30.2996 49.4253 30.2996H26.5746C25.8906 30.2996 25.3333 29.7496 25.3333 29.0746C25.3333 28.3746 25.8906 27.8496 26.5746 27.8496Z"
            fill="#D5D5D5"
          />
          <path
            d="M26.5746 34.8008H49.4253C50.1093 34.8008 50.6666 35.3508 50.6666 36.0258C50.6666 36.7008 50.1093 37.2508 49.4253 37.2508H26.5746C25.8906 37.2508 25.3333 36.7008 25.3333 36.0258C25.3333 35.3258 25.8906 34.8008 26.5746 34.8008Z"
            fill="#D5D5D5"
          />
          <path
            d="M26.5746 41.75H49.4253C50.1093 41.75 50.6666 42.3 50.6666 42.975C50.6666 43.65 50.1093 44.2 49.4253 44.2H26.5746C25.8906 44.2 25.3333 43.65 25.3333 42.975C25.3333 42.275 25.8906 41.75 26.5746 41.75Z"
            fill="#D5D5D5"
          />
          <path
            d="M26.5746 48.6992H49.4253C50.1093 48.6992 50.6666 49.2492 50.6666 49.9242C50.6666 50.5992 50.1093 51.1492 49.4253 51.1492H26.5746C25.8906 51.1492 25.3333 50.5992 25.3333 49.9242C25.3333 49.2242 25.8906 48.6992 26.5746 48.6992Z"
            fill="#D5D5D5"
          />
          <path
            d="M72.9853 11.5996H57.76C57.0506 11.5996 56.468 12.1996 56.468 12.9246V20.3496C56.468 21.0746 57.0506 21.6746 57.76 21.6746H72.9853C73.6946 21.6746 74.2773 21.0746 74.2773 20.3496V12.9496C74.2773 12.1996 73.72 11.5996 72.9853 11.5996Z"
            fill="white"
          />
          <path
            d="M60.2933 18.25C61.1293 18.25 61.8133 17.575 61.8133 16.75C61.8133 15.925 61.1293 15.25 60.2933 15.25C59.4573 15.25 58.7733 15.925 58.7733 16.75C58.7733 17.575 59.4573 18.25 60.2933 18.25Z"
            fill="#CCC6D9"
          />
          <path
            d="M65.36 15.25H70.4266C71.2626 15.25 71.9466 15.925 71.9466 16.75C71.9466 17.575 71.2626 18.25 70.4266 18.25H65.36C64.524 18.25 63.84 17.575 63.84 16.75C63.84 15.925 64.524 15.25 65.36 15.25Z"
            fill="#D5D5D5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.892 56.4004C50.16 56.4004 53.1747 55.4254 55.708 53.7504L66.0187 63.4754L69.768 59.2004L59.812 49.8254C61.6613 47.2754 62.7253 44.1504 62.7253 40.7754C62.7253 32.1504 55.632 25.1504 46.892 25.1504C38.152 25.1504 31.0587 32.1504 31.0587 40.7754C31.0587 49.4004 38.152 56.4004 46.892 56.4004ZM60.3947 40.7754C60.3947 48.0754 54.3907 54.0004 46.9933 54.0004C39.596 54.0004 33.592 48.0754 33.592 40.7754C33.592 33.4754 39.596 27.5504 46.9933 27.5504C54.3907 27.5504 60.3947 33.4504 60.3947 40.7754Z"
            fill="#CCC6D9"
          />
          <path
            d="M46.9933 54.25C54.6186 54.25 60.8 48.2 60.8 40.75C60.8 33.3 54.6186 27.25 46.9933 27.25C39.368 27.25 33.1866 33.3 33.1866 40.75C33.1866 48.2 39.368 54.25 46.9933 54.25Z"
            fill="white"
            fillOpacity="0.3"
          />
          <path
            d="M49.3747 40.75L52.7187 37.475C53.0227 37.15 53.2 36.725 53.2 36.275C53.2 35.825 52.9973 35.4 52.6933 35.075C52.364 34.75 51.9333 34.575 51.4773 34.575C51.0213 34.575 50.5907 34.725 50.2613 35.05L46.9173 38.325L43.5733 35.05C43.4213 34.875 43.2187 34.75 43.016 34.65C42.8133 34.55 42.56 34.5 42.332 34.5C42.104 34.5 41.876 34.55 41.648 34.625C41.42 34.7 41.2427 34.85 41.0653 35C40.888 35.15 40.7613 35.35 40.6853 35.575C40.6093 35.8 40.5587 36.025 40.5587 36.25C40.5587 36.475 40.6093 36.7 40.7107 36.925C40.812 37.125 40.9387 37.325 41.116 37.475L44.46 40.75L41.116 44.025C40.9387 44.175 40.812 44.375 40.7107 44.575C40.6093 44.775 40.5587 45 40.5587 45.25C40.5587 45.475 40.6093 45.7 40.6853 45.925C40.7613 46.15 40.9133 46.325 41.0653 46.5C41.2427 46.65 41.42 46.8 41.648 46.875C41.876 46.95 42.104 47 42.332 47C42.56 47 42.788 46.95 43.016 46.85C43.2187 46.75 43.4213 46.625 43.5733 46.45L46.9173 43.175L50.2613 46.45C50.5907 46.75 51.0213 46.9 51.4773 46.875C51.9333 46.85 52.3387 46.675 52.668 46.375C52.972 46.075 53.1747 45.65 53.1747 45.225C53.2 44.8 53.0227 44.375 52.744 44.025L49.3747 40.75Z"
            fill="black"
          />
          <path
            d="M66.0186 63.4508L69.7679 59.1758L70.3253 59.7008C70.8826 60.2258 71.1866 60.9258 71.2373 61.7008C71.2626 62.4758 71.0093 63.2258 70.5279 63.7758C70.0213 64.3508 69.3373 64.6758 68.6026 64.7258C67.8679 64.7508 67.1333 64.5008 66.6013 63.9758L66.0186 63.4508Z"
            fill="#E1DCEB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2626 22.7992C14.2626 22.4492 14.212 22.0742 14.1106 21.7242C13.7813 20.4492 12.3626 19.6492 10.868 19.4492C9.34798 19.2492 7.80265 19.6992 7.19465 20.7992C6.83998 21.4242 6.78931 21.9742 6.94131 22.4242C7.09331 22.8742 7.42265 23.2492 7.87865 23.5492C9.14531 24.3742 11.3493 24.5492 12.3626 24.1992C12.8186 24.0492 13.2746 23.8492 13.7306 23.6242C13.4773 25.0492 12.54 26.4242 11.2733 27.6492C8.53731 30.3242 4.25598 32.3492 1.95065 33.0742C1.82398 33.1242 1.74798 33.2492 1.79865 33.3742C1.84931 33.4992 1.97598 33.5742 2.10265 33.5242C4.45865 32.7742 8.81598 30.6992 11.6026 27.9742C13.0466 26.5742 14.0853 24.9992 14.2626 23.3242C17.5306 21.5242 20.216 18.2492 22.5213 15.4992C22.5973 15.3992 22.5973 15.2492 22.496 15.1492C22.3946 15.0742 22.2426 15.0742 22.1666 15.1742C19.9373 17.8742 17.3786 20.9992 14.2626 22.7992ZM13.7813 23.0492C13.8066 22.6492 13.756 22.2492 13.6546 21.8242C13.376 20.7242 12.1093 20.0742 10.8173 19.8992C10.0066 19.7992 9.19598 19.8742 8.53731 20.1742C8.13198 20.3492 7.80265 20.6242 7.59998 20.9992C7.34665 21.4742 7.27065 21.8992 7.39731 22.2492C7.49865 22.5992 7.77731 22.8992 8.13198 23.1242C9.27198 23.8742 11.2986 24.0242 12.2106 23.7242C12.7426 23.5492 13.2746 23.3242 13.7813 23.0492Z"
            fill="black"
          />
          <path
            d="M72.2 47.5C73.0395 47.5 73.72 46.8284 73.72 46C73.72 45.1716 73.0395 44.5 72.2 44.5C71.3605 44.5 70.68 45.1716 70.68 46C70.68 46.8284 71.3605 47.5 72.2 47.5Z"
            fill="#E3E3E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7947 56.3492C15.0733 56.2492 15.352 56.1242 15.58 55.9242C15.8333 55.6992 15.96 55.3992 16.0107 55.0992C16.112 54.6992 16.1373 54.2742 16.2387 53.8742C16.2893 53.7242 16.3653 53.6742 16.3907 53.6492C16.4667 53.5742 16.568 53.5742 16.644 53.5742C16.7453 53.5742 16.872 53.6242 16.9733 53.7992C16.9733 53.8242 16.9987 53.8492 17.024 53.8992C17.024 53.9242 17.0493 54.0492 17.0493 54.0992C17.0747 54.2242 17.1 54.3242 17.1 54.4492C17.1507 54.8492 17.176 55.1742 17.3533 55.5242C17.5813 56.0242 17.8347 56.3242 18.1387 56.4492C18.4427 56.5742 18.8227 56.5492 19.304 56.4492C19.3547 56.4492 19.4053 56.4242 19.4307 56.4242C19.6333 56.3742 19.836 56.5242 19.8867 56.7492C19.9373 56.9742 19.7853 57.1742 19.5827 57.2242C19.532 57.2242 19.5067 57.2492 19.456 57.2492C18.8227 57.4242 18.088 57.9992 17.6573 58.5242C17.5307 58.6742 17.328 59.1242 17.1253 59.3992C16.9733 59.5992 16.8213 59.7492 16.6693 59.7992C16.568 59.8242 16.492 59.8242 16.4413 59.7992C16.34 59.7742 16.264 59.7242 16.2133 59.6492C16.188 59.5992 16.1627 59.5492 16.1373 59.4742C16.1373 59.4242 16.1373 59.3492 16.1373 59.2992C16.0867 59.1492 16.036 59.0242 16.0107 58.8742C15.9347 58.5242 15.7573 58.2992 15.5547 58.0242C15.3773 57.7492 15.1747 57.5742 14.8707 57.4492C14.82 57.4492 14.516 57.3492 14.4147 57.3242C14.2627 57.2492 14.1867 57.1492 14.1613 57.0992C14.1107 56.9992 14.1107 56.8992 14.1107 56.8242C14.136 56.7242 14.1613 56.6242 14.2627 56.5492C14.3133 56.4992 14.3893 56.4492 14.4907 56.4242C14.5413 56.3492 14.7693 56.3492 14.7947 56.3492ZM16.6187 55.7742C16.644 55.7992 16.644 55.8492 16.6693 55.8742C17.024 56.5992 17.404 56.9992 17.86 57.1742H17.8853C17.5813 57.4242 17.2773 57.6992 17.0747 57.9492C16.9733 58.0492 16.872 58.2742 16.7453 58.4992C16.6187 58.0992 16.4413 57.8242 16.188 57.4742C16.0107 57.1992 15.808 56.9992 15.58 56.8492C15.7573 56.7492 15.9347 56.6492 16.0867 56.4992C16.3147 56.3242 16.492 56.0492 16.6187 55.7742Z"
            fill="#CCC6D9"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_12306_66845"
          x1="37.7649"
          y1="-2.51872"
          x2="38.3713"
          y2="102.837"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_12306_66845"
          x1="38.3213"
          y1="15.8894"
          x2="38.3213"
          y2="54.6466"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.7188" stopColor="#FAFAFA" />
        </linearGradient>
        <clipPath id="clip0_12306_66845">
          <rect width="76" height="75" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SelectNotContentIcon;
