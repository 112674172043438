import { useQuery } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { useQueryParams } from "hooks/useQueryParams";

import { CashStatusDetailedTable, dashboardQueryKeys } from "..";

type Query = PaginationType<CashStatusDetailedTable[]>;

interface Params {
  cashId: number;
  id: number;
}

export function useCashStatusDetailedTable({ cashId, id }: Params) {
  const { searchParams, reqQueryParam } = useQueryParams();

  const initialValue: Query = {
    data: [],
    current_page: 1,
    total: 1
  };

  let url = `company-widget/cash-status/cash-payment-view?id=${id}&cash_id=${cashId}`;

  if (searchParams) {
    url += `&${reqQueryParam(queryParamsKeys.PAGE)}`;
  }

  const { data = initialValue, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PAYMENT_CARD_DETAILED_BOTTOM, id, cashId, searchParams],
    async () => {
      const { data: baseData } = await $api.get(url);

      return { ...baseData.data };
    },
    { enabled: !!id }
  );

  return { ...data, ...arg };
}
