import React from "react";

import { ChartType, Widget, WidgetKeys, WidgetSettings, WidgetType } from "../schema";

export interface IWidgetModal {
  visibleWidgetModal: boolean;
  widget: Widget;
  isUpdateWidget?: boolean;
  visible?: boolean;
}

export interface IWidgetDrawerProps {
  id: number;
  props?: any;
  key: WidgetKeys;
  visible: boolean;
  title: React.ReactNode;
  widget?: Widget;
}

export interface IDetailedWidgetDrawerProps {
  id: number;
  visible: boolean;
  title: string;
  props?: any;
}

export interface ITableWidgetContentModalProps {
  tableWidgetModalVisible: boolean;
  widget?: Widget;
  widgetProps?: IWidgetProps | null;
}

export interface ISettingsProps {
  id: number;
  key: WidgetKeys;
  settingsVisible: boolean;
  settingsProps?: WidgetSettings;
  widget?: Widget;
  visible?: boolean;
  props?: string;
}

export interface IWidgetProps {
  productId?: number;
  unitId?: number;
}

export interface IContext {
  // data
  id: number;
  key: WidgetKeys;
  widget?: Widget;
  type: WidgetType;
  chartType: ChartType;
  widgetProps?: IWidgetProps | null;

  // create or update modal
  visibleWidgetModal: boolean;
  isUpdateWidget?: boolean;

  // widget drawer
  visible: boolean;
  title: React.ReactNode;
  props?: any;

  // widget detailed drawer
  detailedVisible: boolean;
  detailedTitle: string;
  detailedId: number;
  detailedProps?: any;

  // settings
  settingsVisible: boolean;
  settingsProps?: WidgetSettings;
  // tableWidgetModal
  tableWidgetModalVisible: boolean;
  modalProps?: any;

  methods: {
    // widget
    setWidget: (widget: Widget) => void;
    setWidgetId: (widgetId: number) => void;
    setWidgetKey: (key: WidgetKeys) => void;
    setSettings: (payload: ISettingsProps) => void;
    setWidgetType: (widgetType: WidgetType) => void;
    setWidgetChartType: (chartType: ChartType) => void;
    setWidgetProps: (props: IWidgetProps) => void;

    // modal
    setWidgetModal: (payload: IWidgetModal) => void;
    setTableWidgetContentModal: (payload: ITableWidgetContentModalProps) => void;

    // drawer
    setWidgetDrawer: (data: Partial<IWidgetDrawerProps>) => void;
    setWidgetDetailedDrawer: (data: IDetailedWidgetDrawerProps) => void;
  };
}

const WidgetContext = React.createContext<IContext>({} as IContext);

export default WidgetContext;
