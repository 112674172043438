import { Tooltip } from "antd";
import dayjs from "dayjs";
import { handleLeftOrWentDays } from "features/app/utils/helpers/handleLeftOrWentDays";

import { dayjsFormats } from "../../utils/constants/dayjsFormats";
import { StatusEnums } from "../statuses/statusEnums";

import styles from "./dateStatus.module.scss";

export const DateStatus = (record: { delivery_date: string; status: string }) => {
  const dateStatusClass = (record: { delivery_date: string; status: string }) => {
    const diffDate = dayjs().diff(dayjs(record.delivery_date, dayjsFormats.DATE), "day");

    if (record.status !== StatusEnums.PARTIALLY_RECIEVED && record.status !== StatusEnums.RECIEVED) {
      if (diffDate >= -3 && diffDate <= 0) {
        return styles.delivery_date__error;
      }
      if (diffDate > -7 && diffDate < -3) {
        return styles.delivery_date__warning;
      }
      if (diffDate > 0) {
        return styles.delivery__error_text;
      }
      return styles.delivery_date__success;
    }

    return styles.delivery_date__success;
  };

  if (record?.delivery_date) {
    return (
      <Tooltip title={handleLeftOrWentDays(record.delivery_date)}>
        <div className={dateStatusClass(record)}>{record?.delivery_date}</div>
      </Tooltip>
    );
  }
  return "-";
};
