import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Form, Modal, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { RU } from "../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import EmptyOfferHistoryIcon from "../../../assets/icons/EmptyOfferHistoryIcon";
import { useGetOfferHistory } from "../../../service/queries";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { OfferHistoryModel } from "../../../utils/models/offerHistoryModel";

import styles from "./offerHistory.module.scss";

const { Item, List, useWatch } = Form;

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const OfferHistory: React.FC<{ zIndex?: number }> = ({ zIndex }) => {
  const dispatch = useDispatch();
  const { setOfferHistory, setAddOfferItem } = supplyOfferActions;
  const [form] = Form.useForm();
  const { data, isLoading, fetchNextPage } = useGetOfferHistory();
  const checkedIds = useWatch("checkedIds", form) as number[] | undefined[];
  const { warehouseOffers } = useAppSelector(state => state.supplyOfferReducer.offerModal);
  const { visible, productKey, isCheckedView } = useAppSelector(state => state.supplyOfferReducer.offerHistory);

  const { t } = useTranslation();

  const generateData = useMemo(() => {
    let newData: OfferHistoryModel[] = [];
    let total = 0;
    let current_page = 1;

    data?.pages?.forEach(item => {
      total = item?.total;
      current_page = item?.current_page;

      newData = [...newData, ...(item?.data || [])];
    });

    return {
      total,
      current_page,
      data: newData
    };
  }, [data]);

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      form.resetFields();
    }
  };

  const onCancel = () => {
    dispatch(setOfferHistory({ visible: false, productKey: "", productId: 0, unitId: 0 }));
  };

  const onOk = () => form.submit();

  const footerStyle = () => ({
    justifyContent:
      isCheckedView || !(checkedIds?.length > 0 && !checkedIds?.every(el => !el)) || generateData?.data?.length === 0
        ? "flex-end"
        : "space-between"
  });

  const footer = () => (
    <div className={styles.footer} style={footerStyle()}>
      {checkedIds?.length > 0 && !checkedIds?.every(el => !el) && (
        <p className="text-base font-semibold text-gray-600">
          {t("Supply.Tanlangan takliflar")}: {checkedIds?.filter(el => el)?.length}
        </p>
      )}
      <div className="flex items-center gap-2">
        <Button onClick={onCancel}>{t("Supply.Yopish")}</Button>
        <ConditionalRender if={!isCheckedView && generateData?.data?.length > 0}>
          <Button type="primary" onClick={onOk} disabled={isEmptyArr(checkedIds?.filter(i => i))}>
            {t("Supply.Qo'shish")}
          </Button>
        </ConditionalRender>
      </div>
    </div>
  );

  const onFinish = (values: { checkedIds: number[] }) => {
    const { checkedIds } = values;

    generateData?.data?.forEach(item => {
      const someItemId = checkedIds?.some(i => i === item?.id);

      if (someItemId) {
        const ordinalNumber = warehouseOffers?.find(item => item.productKey === productKey);

        dispatch(
          setAddOfferItem({
            productKey: productKey!,
            data: {
              vat: item?.vat,
              uniqueId: uid(12),
              phone: item?.phone,
              description: item?.description,
              currency_id: item?.currency?.id,
              company_person_id: item?.company_person?.id,
              amount: localeFormatter(String(item?.amount)),
              name: item?.name || item?.company_person?.name,
              ordinalNumber: (ordinalNumber?.offers?.length ?? 0) + 1
            }
          })
        );
      }
    });

    onCancel();
  };

  const onChangeAll = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    if (checked) {
      form.setFieldValue(
        "checkedIds",
        generateData?.data?.map(item => item?.id)
      );
    } else form.setFieldValue("checkedIds", []);
  };

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: "#12B76A",
    [StatusEnums.REJECTED]: "#F04438"
  };

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;
    const scrollMath = target.scrollTop + target.offsetHeight;
    const { scrollHeight } = target;

    const resultScroll = scrollMath - scrollHeight;
    const isFetching = Math.ceil(generateData?.total / 10) !== generateData?.current_page;

    if (resultScroll <= 1 && resultScroll >= 0) {
      isFetching && fetchNextPage();
    }
  };

  const title = <span className="text-lg font-semibold text-gray-900">{t("Supply.Ta'minotchilardan takliflar")}</span>;

  return (
    <Modal
      centered
      title={title}
      open={visible}
      footer={footer}
      onCancel={onCancel}
      zIndex={zIndex || 11111}
      afterOpenChange={onAfterOpen}
      rootClassName={styles.offer_history_modal}
    >
      <div className={styles.history}>
        <ConditionalRender if={!isEmptyArr(generateData?.data) && !isCheckedView}>
          <label className={styles.checked_all}>
            <Checkbox
              id="all"
              onChange={onChangeAll}
              checked={generateData?.data?.length === checkedIds?.filter(item => item)?.length}
            >
              {t("Supply.Barchasini belgilash")}
            </Checkbox>
            {generateData?.data?.length > 0 && (
              <p>
                {t("Supply.Jami takliflar")}: {generateData?.total} ta
              </p>
            )}
          </label>
        </ConditionalRender>
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <Form form={form} onFinish={onFinish}>
            <div className={styles.body} onScroll={onScroll}>
              <List name="checkedIds">
                {() =>
                  generateData?.data?.map((item, index) => (
                    <label className={styles.card} key={item.id}>
                      <div className={styles.card__left}>
                        <ConditionalRender if={!isCheckedView}>
                          <Item
                            name={[index]}
                            getValueFromEvent={(e: CheckboxChangeEvent) => {
                              const { value, checked } = e.target;

                              return checked ? value : undefined;
                            }}
                            getValueProps={(props: number | CheckboxChangeEvent) =>
                              typeof props === "object" ? props?.target?.value : props
                            }
                          >
                            <Checkbox
                              value={item?.id}
                              id={String(item.id)}
                              checked={checkedIds?.some(i => i === item?.id)}
                            />
                          </Item>
                        </ConditionalRender>
                        <div className="flex items-center gap-2">
                          <span className="text-base font-normal text-gray-900">
                            {item?.amount?.toLocaleString(RU)}
                          </span>
                          <span className="text-base font-normal text-gray-900">{item?.currency?.symbol}</span>
                          {item.vat && (
                            <span className="flex w-8 items-center justify-center rounded-lg border border-solid border-primary-500 bg-primary-500 p-1 text-xs font-medium text-white">
                              {t("Supply.NDS")}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={styles.card__right}>
                        <p className="text-sm font-medium text-gray-600">{item?.created_date}</p>
                        <div>
                          <NotUserImage
                            isTooltip
                            arrow={false}
                            zIndex={11112}
                            key={item?.id}
                            name={item?.name || item?.company_person?.name}
                            background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
                          />
                        </div>
                      </div>
                    </label>
                  ))
                }
              </List>
              <ConditionalRender if={isEmptyArr(generateData?.data)}>
                <div className={styles.empty}>
                  <EmptyOfferHistoryIcon />
                  <h4>{t("Supply.Takliflar topilmadi")}</h4>
                  <p>{t("Supply.Bu mahsulot uchun hozircha takliflar mavjud emas.")}</p>
                </div>
              </ConditionalRender>
            </div>
          </Form>
        </Spin>
      </div>
    </Modal>
  );
};

export default OfferHistory;
