const NotificationWorkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 8.33366V3.00033C15 2.53362 15 2.30026 14.9092 2.122C14.8293 1.9652 14.7018 1.83771 14.545 1.75782C14.3667 1.66699 14.1334 1.66699 13.6667 1.66699H6.33333C5.86662 1.66699 5.63327 1.66699 5.45501 1.75782C5.29821 1.83771 5.17072 1.9652 5.09083 2.122C5 2.30026 5 2.53362 5 3.00033V8.33366M15 8.33366H5M15 8.33366V8.50033C15 9.90046 15 10.6005 14.7275 11.1353C14.4878 11.6057 14.1054 11.9882 13.635 12.2278C13.1002 12.5003 12.4001 12.5003 11 12.5003H9C7.59987 12.5003 6.8998 12.5003 6.36502 12.2278C5.89462 11.9882 5.51217 11.6057 5.27248 11.1353C5 10.6005 5 9.90046 5 8.50033V8.33366M12.0833 12.5003V16.2503C12.0833 17.4009 11.1506 18.3337 10 18.3337C8.84941 18.3337 7.91667 17.4009 7.91667 16.2503V12.5003"
        stroke="#FDB022"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationWorkIcon;
