import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseTransferRecieved = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      className={styles.description}
      onClick={() => onNavigatePage("/warehouse?tab=transfer", record)}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.warehouse_transfer?.recieved_warehouse?.name} sizning{" "}
        <span className={styles.name}>T -{record?.warehouse_transfer?.id}</span>{" "}
        raqamli o’tkazmani qabul qildi.
      </p>
    </div>
  );
};

export default WarehouseTransferRecieved;
