import React from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Form, FormInstance, Input } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import ConditionalRender from "../../../../../../../app/components/conditional-render/ConditionalRender";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { ErrorRes, SuccessRes } from "../../../../../../../app/utils/models/responseType";
import { formatterPrice } from "../../../../../../utils/helpers/partyModalTableActions";
import { PartyFormProductsModel } from "../../../../../../utils/models/partyModalFormModel";
import { PartyCreateOfferAction } from "../../table/PartyCreateOfferAction";

import styles from "../partyPersonGroup.module.scss";

type Props = {
  index: number;
  groupIndex: number;
  form: FormInstance;
  rowId?: string;
  isWarehouse?: boolean;
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[], unknown>;
};

const { Item, useWatch } = Form;

const PersonTotalAmount: React.FC<Props> = ({ index, form, rowId, groupIndex, isWarehouse, deleteProduct }) => {
  const fieldName = ["person_group", groupIndex, "data", index, "total"];
  const amountFieldName = ["person_group", groupIndex, "data", index, "amount"];
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const products = useWatch(["person_group", groupIndex, "data"], form) as PartyFormProductsModel[];

  const onChangeTotal = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = localeFormatter(e.currentTarget.value);
    const quantity = parseLocaledString(products[index]?.quantity);

    form.setFieldValue(fieldName, newValue);

    form.setFieldValue(amountFieldName, formatterPrice(parseLocaledString(newValue) / quantity));
  };

  // const value = () => form.getFieldValue(fieldName);

  return (
    <div className={styles.total}>
      {/* <PerfItem */}
      {/*  placeholder={<div className="w-[220px] p-[0.62rem]">{value()}</div>} */}
      {/*  visible={!(isView && updateProduct?.index !== rowId)} */}
      {/* > */}
      <Item name={fieldName}>
        <Input
          autoComplete="off"
          onChange={onChangeTotal}
          placeholder="Umumiy summa"
          disabled={isView && updateProduct?.index !== rowId}
        />
      </Item>
      {/* </PerfItem> */}
      <ConditionalRender if={!isWarehouse}>
        <PartyCreateOfferAction
          isPerson
          rowId={rowId}
          form={form}
          index={index}
          groupIndex={groupIndex}
          deleteProduct={deleteProduct}
        />
      </ConditionalRender>
    </div>
  );
};

export default PersonTotalAmount;
