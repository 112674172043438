import React from "react";

const UyqurTextIcon: React.FC = () => {
  return (
    <svg
      width="100"
      height="32"
      viewBox="0 0 100 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16903 7H11.804V16.5028C11.804 17.5445 11.5578 18.4607 11.0653 19.2514C10.5777 20.0421 9.8911 20.66 9.00568 21.1051C8.12027 21.5455 7.0857 21.7656 5.90199 21.7656C4.71354 21.7656 3.67661 21.5455 2.79119 21.1051C1.90578 20.66 1.21922 20.0421 0.731534 19.2514C0.243845 18.4607 0 17.5445 0 16.5028V7H2.63494V16.2827C2.63494 16.8887 2.76752 17.4285 3.03267 17.902C3.30256 18.3755 3.68134 18.7472 4.16903 19.017C4.65672 19.2822 5.23438 19.4148 5.90199 19.4148C6.5696 19.4148 7.14725 19.2822 7.63494 19.017C8.12737 18.7472 8.50616 18.3755 8.77131 17.902C9.03646 17.4285 9.16903 16.8887 9.16903 16.2827V7Z"
        fill="#1D2939"
      />
      <path
        d="M16.1523 25.6364C15.802 25.6364 15.4776 25.608 15.1793 25.5511C14.8858 25.4991 14.6514 25.4375 14.4762 25.3665L15.0728 23.3636C15.4469 23.4725 15.7807 23.5246 16.0742 23.5199C16.3678 23.5152 16.6258 23.4228 16.8484 23.2429C17.0756 23.0677 17.2674 22.7741 17.4236 22.3622L17.6438 21.7727L13.6879 10.6364H16.4151L18.9293 18.875H19.043L21.5643 10.6364H24.2987L19.9308 22.8665C19.7272 23.4441 19.4573 23.9389 19.1211 24.3509C18.7849 24.7675 18.373 25.0848 17.8853 25.3026C17.4023 25.5251 16.8247 25.6364 16.1523 25.6364Z"
        fill="#1D2939"
      />
      <path
        d="M32.9865 25.6364V19.8267H32.88C32.7474 20.0919 32.5556 20.3736 32.3047 20.6719C32.0537 20.9654 31.7223 21.2164 31.3104 21.4247C30.8984 21.633 30.38 21.7372 29.755 21.7372C28.898 21.7372 28.1309 21.517 27.4538 21.0767C26.7768 20.6364 26.2417 19.9972 25.8487 19.1591C25.4557 18.321 25.2592 17.303 25.2592 16.1051C25.2592 14.893 25.4581 13.8703 25.8558 13.0369C26.2583 12.1989 26.8004 11.5668 27.4822 11.1406C28.1641 10.7098 28.924 10.4943 29.7621 10.4943C30.4013 10.4943 30.9268 10.6032 31.3388 10.821C31.7507 11.0341 32.0774 11.2921 32.3189 11.5952C32.5604 11.8935 32.7474 12.1752 32.88 12.4403H33.0362V10.6364H35.5646V25.6364H32.9865ZM30.4723 19.6278C31.0168 19.6278 31.4808 19.4811 31.8643 19.1875C32.2479 18.8892 32.5391 18.4749 32.7379 17.9446C32.9368 17.4143 33.0362 16.7964 33.0362 16.0909C33.0362 15.3854 32.9368 14.7723 32.7379 14.2514C32.5438 13.7306 32.255 13.3258 31.8714 13.0369C31.4927 12.7481 31.0263 12.6037 30.4723 12.6037C29.8994 12.6037 29.4212 12.7528 29.0376 13.0511C28.6541 13.3494 28.3653 13.7614 28.1712 14.2869C27.977 14.8125 27.88 15.4138 27.88 16.0909C27.88 16.7727 27.977 17.3812 28.1712 17.9162C28.37 18.4465 28.6612 18.8655 29.0447 19.1733C29.433 19.4763 29.9089 19.6278 30.4723 19.6278Z"
        fill="#1D2939"
      />
      <path
        d="M45.1598 16.9574V10.6364H47.7308V21.5455H45.2379V19.6065H45.1243C44.8781 20.2173 44.4732 20.7169 43.9098 21.1051C43.3511 21.4934 42.6622 21.6875 41.843 21.6875C41.1281 21.6875 40.496 21.5289 39.9467 21.2116C39.4022 20.8897 38.9761 20.4233 38.6683 19.8125C38.3606 19.197 38.2067 18.4536 38.2067 17.5824V10.6364H40.7777V17.1847C40.7777 17.8759 40.9671 18.4252 41.3459 18.8324C41.7247 19.2396 42.2218 19.4432 42.8374 19.4432C43.2161 19.4432 43.5831 19.3509 43.9382 19.1662C44.2933 18.9815 44.5845 18.7069 44.8118 18.3423C45.0438 17.973 45.1598 17.5114 45.1598 16.9574Z"
        fill="#1D2939"
      />
      <path
        d="M50.3746 21.5455V10.6364H52.8675V12.4545H52.9812C53.18 11.8248 53.521 11.3395 54.0039 10.9986C54.4916 10.6529 55.0479 10.4801 55.6729 10.4801C55.815 10.4801 55.9736 10.4872 56.1488 10.5014C56.3287 10.5109 56.4779 10.5275 56.5962 10.5511V12.9162C56.4873 12.8783 56.3145 12.8452 56.0778 12.8168C55.8458 12.7836 55.6209 12.767 55.4031 12.767C54.9343 12.767 54.5129 12.8688 54.1389 13.0724C53.7695 13.2713 53.4783 13.5483 53.2653 13.9034C53.0522 14.2585 52.9457 14.6681 52.9457 15.1321V21.5455H50.3746Z"
        fill="#1D2939"
      />
    </svg>
  );
};

export default UyqurTextIcon;
