import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import SettingsAdminsModal from "./modal/SettingsAdminsModal";
import SettingsAdminsTable from "./table/SettingsAdminsTable";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";

import styles from "./settingsAdmins.module.scss";

const SettingsAdmins: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;

  const onOpenModal = () => {
    dispatch(
      setAdminModal({
        visible: true,
        isEdited: true,
      })
    );
  };

  return (
    <div className={styles.settings_admins}>
      <div className={styles.settings_admins__top}>
        <h2>{t("Xodimlar.Xodimlar")}</h2>
        <Button onClick={onOpenModal}>+ {t("Xodimlar.Xodim qo'shish")}</Button>
      </div>
      <div className={styles.settings_admins__bottom}>
        <SettingsAdminsTable />
      </div>
      <SettingsAdminsModal />
    </div>
  );
};

export default SettingsAdmins;
