const ScalesIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.70067 13H8.70067M15.7007 13H21.7007M12.2007 7V21M12.2007 7C13.5814 7 14.7007 5.88071 14.7007 4.5M12.2007 7C10.82 7 9.70067 5.88071 9.70067 4.5M4.20067 21L20.2007 21M4.20067 4.50001L9.70067 4.5M9.70067 4.5C9.70067 3.11929 10.82 2 12.2007 2C13.5814 2 14.7007 3.11929 14.7007 4.5M14.7007 4.5L20.2007 4.5M9.08111 14.3364C8.68041 15.8706 7.31877 17 5.70067 17C4.08257 17 2.72093 15.8706 2.32023 14.3364C2.28749 14.211 2.27112 14.1483 2.26954 13.8979C2.26858 13.7443 2.32563 13.3904 2.37479 13.2449C2.45497 13.0076 2.54178 12.8737 2.71538 12.6059L5.70067 8L8.68596 12.6059C8.85956 12.8737 8.94636 13.0076 9.02655 13.2449C9.07571 13.3904 9.13276 13.7443 9.1318 13.8979C9.13022 14.1483 9.11385 14.211 9.08111 14.3364ZM22.0811 14.3364C21.6804 15.8706 20.3188 17 18.7007 17C17.0826 17 15.7209 15.8706 15.3202 14.3364C15.2875 14.211 15.2711 14.1483 15.2695 13.8979C15.2686 13.7443 15.3256 13.3904 15.3748 13.2449C15.455 13.0076 15.5418 12.8737 15.7154 12.6059L18.7007 8L21.686 12.6059C21.8596 12.8737 21.9464 13.0076 22.0266 13.2449C22.0757 13.3904 22.1328 13.7443 22.1318 13.8979C22.1302 14.1483 22.1138 14.211 22.0811 14.3364Z"
        stroke="#475467"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScalesIcon;
