import { useQuery } from "@tanstack/react-query";

import $api from "../../../features/app/utils/helpers/axiosInstance";
import { dashboardQueryKeys } from "../query-keys";
import { Gantt } from "../schema";

type Query = {
  products: Gantt["data"];
};

type Props = {
  unitId?: number;
  productId?: number;
};

export function useProductAnalyze({ unitId, productId }: Props) {
  const initialData: Query = {
    products: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PRODUCT_ANALYZE, productId, unitId],
    async () => {
      const { data } = await $api.get(`product/price-analyze?product_id=${productId}&unit_id=${unitId}`);

      return { products: data?.data };
    },
    {
      enabled: !!(productId && unitId)
    }
  );

  return { ...data, ...arg };
}
