import { useLayoutEffect, useState } from "react";

import useGetTableColumns from "../../../../../../../hooks/useGetTableColumns";
import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import SettingsIcon from "../../../../../../app/assets/icons/SettingsIcon";
import TableConfig from "../../../../../../app/components/table-config/TableConfig";
import {
  useGetCustomFieldSelect,
  useGetTableConfigs,
} from "../../../../../../app/service/queries";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../../app/utils/enums/customFieldLocationEnum";
import { UserTableDataModel } from "../../../../../../app/utils/models/user/userTableConfigModel";
import { paymentOrderTableDefaultData } from "../../../../../../supply/utils/constants/paymentOrderTableDefaultData";

import styles from "./partTableSettings.module.scss";

type Props = {
  classNames?: string;
  isNeedSize?: boolean;
};

const PartTableSettings = ({ classNames, isNeedSize }: Props) => {
  const [open, setOpen] = useState(false);
  const { append } = useQueryParams();
  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.PAYMENT_ORDER_CONFIG,
    paymentOrderTableDefaultData
  );
  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.ORDER,
  ]);

  const data = useGetTableColumns({
    defaultData: tableConfigData,
    customFieldSelect: customFields,
  });

  const onOpen = () => {
    setOpen(true);
  };

  useLayoutEffect(() => {
    if (tableConfigData?.size) append(queryParamsKeys.SIZE, tableConfigData?.size);
  }, [tableConfigData]);

  return (
    <TableConfig
      open={open}
      setOpen={setOpen}
      isNeedSize={isNeedSize}
      data={data as unknown as UserTableDataModel}
      tableConfigKey={tableConfigKeys.PAYMENT_ORDER_CONFIG}
      defaultData={paymentOrderTableDefaultData as UserTableDataModel | undefined}
      customFields={customFields}
    >
      <div className={`${styles.settings} ${classNames}`} onClick={onOpen}>
        <SettingsIcon size={18} />
      </div>
    </TableConfig>
  );
};

export default PartTableSettings;
