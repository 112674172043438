import { Popconfirm } from "antd";
import React, { ReactNode } from "react";
import { LEFT } from "../../utils/constants/placements";
import { useTranslation } from "react-i18next";
type Props = {
  title?: string;
  onDelete: () => void;
  children: ReactNode;
};

const DeletePopconfirm: React.FC<Props> = ({ title, onDelete, children }) => {
  const { t } = useTranslation();
  return (
    <Popconfirm
      title={title ? title : t("Xodimlar.O'chirmoqchimisiz ?")}
      onConfirm={onDelete}
      placement={LEFT}
      okText={t("Xodimlar.Ha")}
      cancelText={t("Xodimlar.Yoq")}
    >
      {children}
    </Popconfirm>
  );
};

export default DeletePopconfirm;
