import React, { MouseEvent, ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Button, Dropdown } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import ProjectReportIcon from "features/app/assets/icons/ProjectReportIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import { parseParamsId } from "features/app/utils/helpers/parseParamsId";
import CopyIcon from "features/payment/assets/icons/CopyIcon";
import CornerUpLeftIcon from "features/projects/assets/icons/CornerUpLeftIcon";
import EyeIcon from "features/projects/assets/icons/EyeIcon";
import { useCopyProject, useDeleteProject, useUpdateProject } from "features/projects/service/mutations";
import { routeSubmodules } from "features/projects/utils/constants/routeSubmodules";
import { ProjectModel } from "features/projects/utils/models/projectModel";
import ArchiveIcon from "features/supply/assets/icons/ArchiveIcon";
import FlipBackwardIcon from "features/warehouse/assets/icons/FlipBackwardIcon";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { projectReportModalActions } from "store/reducers/projectReportModalReducer";
import { projectsReducerActions } from "store/reducers/projectsReducer";

import { cx } from "modules/common";

import styles from "../projectsTable.module.scss";

type Props = {
  record: ProjectModel;
  onClickColumn: (id: number | string) => void;
};
const ActionDots: React.FC<Props> = ({ record, onClickColumn }) => {
  const deleteProject = useDeleteProject();
  const archiveProject = useUpdateProject();
  const location = useLocation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const params = useParams();
  const { id: folderId } = parseParamsId(params?.folderId);
  const copyProject = useCopyProject(Number(folderId), !isProjectsPage);
  const { setEvictionModal, setProjectModalData } = projectsReducerActions;
  const dispatch = useDispatch();
  const { setVisible } = projectReportModalActions;
  const [open, setOpen] = useState(false);
  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const projectActions = actions("Loyihalar");
  const { t } = useTranslation();

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation as never}>{originNode}</div>;

  const onUpdate = (id: number) => {
    dispatch(
      setProjectModalData({
        visible: true,
        id
      })
    );
    setOpen(false);
  };

  const onDelete = (id: number) => deleteProject.mutateAsync(id);

  const onArchive = (e: MouseEvent, id: number) => {
    stopPropagation(e as never);
    return archiveProject
      .mutateAsync({
        id,
        is_archived: !!isProjectsPage
      })
      .then(() => setOpen(false));
  };

  const onCopy = (e: MouseEvent, id: number) => {
    stopPropagation(e as never);
    return copyProject.mutateAsync(id).then(() => setOpen(false));
  };

  const onOpenAddFolder = (id: number) => {
    dispatch(
      setEvictionModal({
        id,
        visible: true
      })
    );
    setOpen(false);
  };

  const dropdownItems = (record: ProjectModel) => [
    {
      key: "0",
      label: (
        <div className={styles.update} onClick={() => onOpenAddFolder(record.id)}>
          <FlipBackwardIcon />
          <span>{t("project.Ko'chirish")}</span>
        </div>
      ),
      isView: true
    },
    {
      key: "1",
      label: (
        <div className={styles.update}>
          <EyeIcon /> <span>{t("project.Ko'rish")}</span>
        </div>
      ),
      onClick: () => onClickColumn(record.id!),
      isView: true
    },
    {
      key: "2",
      label: (
        <div className={styles.update}>
          <EditIcon /> <span>{t("project.Tahrirlash")}</span>
        </div>
      ),
      onClick: () => onUpdate(record.id!),
      isView: true
    },
    {
      key: "3",
      label: (
        <Button
          loading={archiveProject.isLoading}
          type="link"
          className={cx(styles.delete, "h-max p-0 shadow-none")}
          onClick={e => onArchive(e, record?.id)}
        >
          {isProjectsPage ? <ArchiveIcon /> : <CornerUpLeftIcon />}
          <span>{isProjectsPage ? t("project.Arxivlash") : t("project.Arxivdan chiqarish")}</span>
        </Button>
      ),
      isView: true
    },
    {
      key: "copy",
      label: (
        <Button
          loading={copyProject.isLoading}
          type="link"
          className={cx(styles.delete, "h-max p-0 shadow-none")}
          onClick={e => onCopy(e, record?.id)}
        >
          <CopyIcon />
          <span>{t("project.Nusxa olish")}</span>
        </Button>
      ),
      isView: true,

      permissionKey: "copy"
    },
    {
      key: "4",
      label: (
        <ModalConfirm onOk={() => onDelete(record.id!)} onAfterClose={() => setOpen(false)}>
          <div className={styles.delete}>
            <DeleteIcon />
            <span>{t("project.O'chirish")}</span>
          </div>
        </ModalConfirm>
      ),
      isView: true
    },
    {
      key: "project_report",
      label: (
        <div
          className={cx(styles.delete)}
          onClick={() => {
            dispatch(
              setVisible({
                data: record,
                visible: true
              })
            );
            setOpen(false);
          }}
        >
          <ProjectReportIcon />
          <span>{t("project.Loyiha hisoboti")}</span>
        </div>
      ),
      isView: true
    }
  ];

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Dropdown
      open={open}
      menu={{
        items: dropdownItems(record!)?.filter(el => {
          if (el?.permissionKey) return projectActions[el?.permissionKey] && el?.isView;
          return el?.isView;
        })
      }}
      dropdownRender={dropdownRender}
      onOpenChange={onOpenChange}
      trigger={["click"]}
    >
      <div className="c_p three_point__content" onClick={stopPropagation as never}>
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default ActionDots;
