import React from "react";
import { Spin, Tooltip } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "features/app/components/not-image/NotUserImage";

import { IdAndName, RU } from "modules/common";
import { useWidget } from "modules/dashboard";
import { useCashStatusDetailed } from "modules/dashboard/queries";

const Top: React.FC = () => {
  const { detailedId, id } = useWidget();
  const { data, isLoading } = useCashStatusDetailed({ cashId: detailedId, id });

  const generateProjectNames = (projects: IdAndName[]) => {
    let result = "";

    projects?.forEach((project, index) => {
      if (index) result += ` , ${project.name}`;
      else result += project.name;
    });

    return result;
  };

  return (
    <Spin wrapperClassName="w-full" spinning={isLoading} indicator={LoadingIndicator}>
      <div className="flex w-full flex-col rounded-xl border border-solid border-gray-200 bg-white">
        <div className="box-border flex w-full items-center justify-between border-0 border-b border-solid border-gray-200 p-5">
          <div className="flex flex-col gap-2">
            <h3 className="m-0 text-2xl font-semibold text-gray-900">{data.name}</h3>
            <div className="m-0 flex items-center gap-1 text-base font-medium text-gray-600">
              <span className="w-max">{data?.projects?.slice(0, 3)?.map((p, i) => `${i ? ", " : " "}${p.name}`)}</span>
              {data.projects?.length > 3 && (
                <Tooltip title={generateProjectNames(data.projects.slice(3, data?.projects.length))}>
                  <div>
                    <NotUserImage
                      isTooltip={false}
                      title={`+${data.projects?.length - data.projects?.slice(0, 3).length}`}
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {data?.currencies?.map(currency => (
              <div className="flex items-center gap-2" key={currency.id}>
                <img src={currency.icon} alt="img" className="h-6 w-6 rounded object-cover" />
                <p className="m-0 text-base font-normal text-gray-600">
                  {currency?.amount?.toLocaleString(RU)} {currency.symbol}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="box-border grid grid-cols-2 gap-3 p-3">
          {data?.payment_types?.map(item => (
            <div
              className="box-border flex flex-col gap-2 rounded-lg p-4"
              style={{ background: `${item.color}1E` }}
              key={item.id}
            >
              <h4 className="m-0 text-base font-medium" style={{ color: item.color }}>
                {item.name}
              </h4>
              <div className="flex flex-wrap gap-2">
                {item.currencies?.map((currency, index) => (
                  <div className="flex gap-2" key={currency.id}>
                    {index ? <div className="h-full w-px bg-gray-300" /> : null}
                    <h5 className="m-0 text-base font-normal" style={{ color: item.color }}>
                      {currency?.amount?.toLocaleString(RU)}
                    </h5>
                    <span className="text-base font-normal text-gray-400">{currency.symbol}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Spin>
  );
};

export default Top;
