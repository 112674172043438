import { Button } from "antd";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import InventoryIcon from "../../../../../app/assets/icons/InventoryIcon";
import FilterDrawer from "../../../../../app/components/filter-drawer/FilterDrawer";
import FilterSelect from "../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetProjectsSelect, useGetUsersSelect, useProjectSelect } from "../../../../../app/service/queries";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { useGetInventorySelect } from "../../../../../payment/service/queries";
import ZapIcon from "../../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../../../warehouse/assets/icons/FilterLinesIcon";
import { InventoryStatusEnum } from "../../../../utils/enums/inventoryStatusEnum";
import BuildingIcon from "../../../../../payment/assets/icons/BuildingIcon";
import UserIcon from "../../../../../projects/assets/icons/UserIcon";

import styles from "./filterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { inventoryRequestsTableDefaultData } from "features/inventory/utils/constants/inventoryTableDefaultData";
import { useQueryParams } from "hooks/useQueryParams";
import { generateExcelPayload } from "features/app/utils/helpers/generateExcelPayload";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";

const queryStringArr = [
  queryParamsKeys.STATUSES,
  queryParamsKeys.INVENTORY_IDS,
  queryParamsKeys.CREATORS_IDS,
  queryParamsKeys.PROJECT_IDS
];

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, any, unknown>;
};

const FilterDrawerRequests: React.FC<Props> = ({ exportExcelMutate }) => {
  const { necessaryQueries } = useQueryParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [inventoriesSelected, setInventoriesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.INVENTORY_IDS] as string[] | null
  );
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CREATORS_IDS] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: inventoriesSelect } = useGetInventorySelect(2, open);
  const { data: projectsSelect } = useProjectSelect({ enabled: open });

  const admins = useMemo(() => {
    return adminsSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });
  }, [adminsSelect]);

  const paymentTypeData = useMemo(() => {
    return [
      {
        id: InventoryStatusEnum.PENDING,
        name: `${t("Inventar.Kutilmoqda")}`
      },
      {
        id: InventoryStatusEnum.RECIEVED,
        name: `${t("Inventar.Qabul qilingan")}`
      },
      {
        id: InventoryStatusEnum.REJECTED,
        name: `${t("Inventar.Rad etilgan")}`
      }
    ];
  }, []);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig: inventoryRequestsTableDefaultData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    const otherParamsData = necessaryQueries(queryParamsKeys.SEARCH);
    const reqBody = {
      ...otherParamsData,
      columns: data,
      inventory_ids: inventoriesSelected,
      creator_ids: usersSelected,
      project_ids: projectsSelected
    };

    if (data) {
      exportExcelMutate?.mutate(generateExcelPayload(reqBody));
    }
  };

  const footerContent = (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("Inventory.Yuklab olish")}
    </Button>
  );

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button className={styles.filter} onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("Monitoring.Filter")}
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.INVENTORY_IDS,
          selectedOptions: inventoriesSelected
        },
        {
          queryKey: queryParamsKeys.CREATORS_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        }
      ]}
      setSelectedOptionsArr={
        [setInventoriesSelected, setUsersSelected, setStatusSelected, setProjectsSelected] as never
      }
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={inventoriesSelected}
          setSelectedOptions={setInventoriesSelected}
          data={inventoriesSelect}
          multiple="multiple"
          icon={<InventoryIcon />}
          placeholder={t("Inventar.Inventar")}
          showSearch
        />
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projectsSelect}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("Monitoring.Loyihalar")}
          showSearch
        />
        <FilterSelect
          selectedOptions={statusSelected}
          setSelectedOptions={setStatusSelected}
          data={paymentTypeData}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder={t("Xodimlar.Holati")}
          showSearch
        />
        <FilterSelect
          selectedOptions={usersSelected}
          setSelectedOptions={setUsersSelected}
          data={admins}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder={t("Xodimlar.Xodimlar")}
          isPersons
          className={styles.admins__select}
          showSearch
        />
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerRequests;
