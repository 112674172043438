export enum DefectEnum {
  WORK = "work",
  ORDER = "order",
  TRANSFER = "transfer"
}

export enum NewDefectEnum {
  BROKEN = "broken",
  LOST = "lost"
}
