import React from "react";
import { FormInstance } from "antd";
import { LaboratoryDrawerFormModel } from "features/warehouse/utils/models/laboratoryDrawerFormModel";

import LaboratoryProjectSelect from "../selects/LaboratoryProjectSelect";
import WarehouseSelect from "../selects/WarehouseSelect";

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
};

const Header: React.FC<Props> = ({ form }) => (
  <div className="flex items-center gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-4">
    <WarehouseSelect form={form} />
    <LaboratoryProjectSelect form={form} />
  </div>
);

export default Header;
