import React from "react";
import { DatePicker, Form } from "antd";
import { useTranslation } from "react-i18next";

import { dayjsFormats } from "../../../utils/constants/dayjsFormats";
import DatepickerSuffixIcon from "../../../assets/icons/DatepickerSuffixIcon";
import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

const { Item } = Form;

type Props = {
  index: number;
  disabled?: boolean;
  customField: CustomFieldSelectModel;
};

const CustomFieldDate = ({ customField, index, disabled = false }: Props) => {
  const { i18n } = useTranslation();

  return (
    <React.Fragment>
      <Item
        className="d_n"
        initialValue={customField?.id}
        name={["custom_field_values", index, "custom_field_id"]}
      />
      <Item
        className="d_n"
        initialValue={customField?.type}
        name={["custom_field_values", index, "type"]}
      />
      <Item
        label={customField?.name[i18n.language]}
        name={["custom_field_values", index, "value"]}
      >
        <DatePicker
          disabled={disabled}
          placeholder="Tanlang"
          format={dayjsFormats.DATE}
          suffixIcon={<DatepickerSuffixIcon />}
        />
      </Item>
    </React.Fragment>
  );
};

export default CustomFieldDate;
