import React from "react";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { colors, isEmptyArr, useAmountFixer } from "modules/common";
import { GanttLine as GanttLineType } from "modules/dashboard";

import CustomTooltip from "./tooltip";

type Props = {
  data: GanttLineType;
};

const GanttLine: React.FC<Props> = ({ data }) => {
  const amountFixed = useAmountFixer;

  return (
    <div className="h-full w-full">
      <ConditionalRender
        if={!isEmptyArr(data)}
        else={
          <div className="flex h-full w-full flex-1 items-center justify-center">
            <TableEmpty />
          </div>
        }
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="0 0" stroke={colors.GRAY_100} />
            <XAxis
              dataKey="name"
              fontSize={11}
              tickMargin={10}
              tickLine={false}
              strokeOpacity={0.2}
              stroke={colors.GRAY_400}
              padding={{ left: 30, right: 30 }}
              tickFormatter={(value: string) => {
                const [day, month] = value.split(".");

                return `${day}.${month}`;
              }}
            />
            <YAxis
              fontSize={11}
              tickMargin={0}
              tickLine={false}
              strokeOpacity={0.2}
              stroke={colors.GRAY_400}
              tickFormatter={(value: number) => amountFixed(String(value))}
            />
            <Tooltip content={props => <CustomTooltip item={props?.payload?.[0]?.payload?.item} />} />
            <Line
              dot={{
                r: 6
              }}
              dataKey="uv"
              connectNulls
              type="linear"
              stroke="transparent"
              fill={colors.SUCCESS_500}
            />
            <Line
              dot={{
                r: 6
              }}
              dataKey="pv"
              connectNulls
              type="linear"
              fill={colors.PRIMARY}
              stroke={colors.PRIMARY}
            />
          </LineChart>
        </ResponsiveContainer>
      </ConditionalRender>
    </div>
  );
};

export default GanttLine;
