import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import ConfirmationUsersList from "features/app/components/confirmation-users-list/ConfirmationUsersList";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { GetComponentProps } from "features/app/utils/models/getComponentPropsType";
import { PaginationType } from "features/app/utils/models/PaginationType";
import DefectView from "features/warehouse/components/defect/defect-view/DefectView";
import { useDefectRejected } from "features/warehouse/service/mutation";
import { defectTypes } from "features/warehouse/utils/constants/DefectType";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { defectViewActions } from "store/reducers/defectViewReducer";

import { RU } from "modules/common";
import { WarehouseDefect } from "modules/dashboard";

import { LoadingIndicator } from "components";

import styles from "./warehouseDefectViewTable.module.scss";

interface IProps {
  data: PaginationType<WarehouseDefect[]> | undefined;
  isLoading: boolean;
}

const WarehouseDefectViewTable: React.FC<IProps> = ({ data, isLoading }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const defectRejected = useDefectRejected();
  const { setDefectView } = defectViewActions;
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const defectActions = actions("Yaroqsiz mahsulotlar");

  const columns: ColumnsType<WarehouseDefect> = [
    {
      title: `${t("dashboard.Resurs turi va nomi")}`,
      render: (record: WarehouseDefect) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource?.symbol[i18n.language]}
          </div>
          <span>{record?.product?.name[i18n.language]}</span>
        </div>
      ),
      width: "35%"
    },
    {
      title: `${t("dashboard.Birligi")}`,
      render: (record: WarehouseDefect) => record.unit.symbol[i18n.language]
    },
    {
      title: `${t("dashboard.Soni")}`,
      dataIndex: "quantity",
      render: (quantity: number) => quantity?.toLocaleString(RU)
    },

    {
      title: `${t("dashboard.Yaroqsizlik turi")}`,
      render: (record: WarehouseDefect) => (
        <div className={`${styles.type} ${styles[record.type]}`}>{defectTypes[record.type as never]}</div>
      )
    },

    {
      title: `${t("dashboard.Guvohlar")}`,
      render: (record: WarehouseDefect) => <ConfirmationUsersList record={record as never} />
    }
  ];

  const onRow: GetComponentProps<WarehouseDefect> | undefined = record => ({
    onClick: () => {
      defectActions.edit &&
        defectActions.rejected &&
        dispatch(
          setDefectView({
            visible: true,
            data: record as never
          })
        );
    }
  });

  const footer = () => (
    <div className="p-2">
      <Pagination
        paginationProps={{
          total: data?.total
        }}
      />
    </div>
  );

  return (
    <div className={styles.defect}>
      <div className={styles.top}>
        <Table<WarehouseDefect>
          columns={columns}
          dataSource={data?.data}
          pagination={false}
          className="footer_table"
          footer={footer}
          onRow={onRow}
          rowKey={row => row.id}
          rowClassName={() => styles.on_row}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading || defectRejected.isLoading,
            indicator: LoadingIndicator
          }}
        />
      </div>
      <DefectView />
    </div>
  );
};

export default WarehouseDefectViewTable;
