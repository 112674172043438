import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { WarehouseProductModel } from "../../features/warehouse/utils/models/WarehouseProductModel";

interface WarehouseTransferReducerModel {
  visible: boolean;
  data?: WarehouseProductModel[];
  sender_warehouse_id?: number;
  recieved_warehouse_id?: number;
  recieved_project_id?: number;
  id?: number;
  selectProducts?: WarehouseProductModel[];
  productKeys?: React.Key[];
  isClickedRow?: boolean;
}

const initialState: WarehouseTransferReducerModel = {
  visible: false,
  data: [],
  sender_warehouse_id: undefined,
  recieved_warehouse_id: undefined,
  recieved_project_id: undefined,
  id: undefined,
  selectProducts: [],
  productKeys: [],
  isClickedRow: false,
};

const warehouseTransferSlice = createSlice({
  name: sliceNames.WAREHOUSE_TRANSFER,
  initialState,
  reducers: {
    setTransferModal: (state, action: PayloadAction<WarehouseTransferReducerModel>) => {
      state.visible = action.payload.visible;
      state.data = action.payload.data;
      state.id = action.payload.id;
      state.sender_warehouse_id = action.payload.sender_warehouse_id;
      state.recieved_project_id = action.payload.recieved_project_id;
      state.recieved_warehouse_id = action.payload.recieved_warehouse_id;
    },
    setTransferDataModal: (state, action: PayloadAction<WarehouseProductModel[]>) => {
      state.data = action.payload;
    },
    setProductKeys: (state, action: PayloadAction<React.Key[] | undefined>) => {
      state.productKeys = action.payload;
    },
    setSelectProducts: (state, action: PayloadAction<WarehouseProductModel[] | undefined>) => {
      state.selectProducts = action.payload;
    },
    setIsClickedRow: (state, action: PayloadAction<boolean>) => {
      state.isClickedRow = action.payload;
    },
  },
});

export default warehouseTransferSlice.reducer;
export const warehouseTransferActions = warehouseTransferSlice.actions;
