type RouteSubmodules = [
  {
    key: "Papkalar";
    actions: [
      {
        key: "Papkalarni ko'rish";
        view: true;
      },
      {
        key: "Papka yaratish";
        create: true;
      },
      {
        key: "Papkani yangilash";
        edit: true;
      },
      {
        key: "Papkani o'chirish";
        delete: true;
      }
    ];
  },
  {
    key: "Loyihalar";
    actions: [
      {
        key: "Loyihalarni ko'rish";
        view: true;
      },
      {
        key: "Loyihani yangilash";
        edit: true;
      },
      {
        key: "Loyiha yaratish";
        create: true;
      },
      {
        key: "Loyihani o'chirish";
        delete: true;
      },
      {
        copy: true;
        key: "Loyihadan nusxa olish";
      }
    ];
  },
  {
    key: "Smeta";
    actions: [
      {
        key: "Smetani ko'rish";
        view: true;
      },
      {
        key: "Smetaga ish bo'limi qo'shish";
        add_word: true;
      },
      {
        key: "Ish bo'limini yangilash";
        edit_work: true;
      },
      {
        key: "Ish bo'limini o'chirish";
        delete_work: true;
      },
      {
        key: "Ishlar";
        view_work: true;
      },
      {
        key: "Ish qo'shish";
        create_work: true;
      },
      {
        key: "Smetani import qilib olish";
        import: true;
      },
      {
        key: "Gant chart";
        chart: true;
      }
    ];
  },
  {
    key: "Ishlar";
    actions: [
      {
        key: "Ishlar ro'yxati";
        view: true;
      },
      {
        key: "Ishlar bo'yicha statistika";
        statistics: true;
      }
    ];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "Papkalar",
    actions: [
      {
        key: "Papkalarni ko'rish",
        view: true
      },
      {
        key: "Papka yaratish",
        create: true
      },
      {
        key: "Papkani yangilash",
        edit: true
      },
      {
        key: "Papkani o'chirish",
        delete: true
      }
    ]
  },
  {
    key: "Loyihalar",
    actions: [
      {
        key: "Loyihalarni ko'rish",
        view: true
      },
      {
        key: "Loyihani yangilash",
        edit: true
      },
      {
        key: "Loyiha yaratish",
        create: true
      },
      {
        key: "Loyihani o'chirish",
        delete: true
      },
      {
        copy: true,
        key: "Loyihadan nusxa olish"
      }
    ]
  },
  {
    key: "Smeta",
    actions: [
      {
        key: "Smetani ko'rish",
        view: true
      },
      {
        key: "Smetaga ish bo'limi qo'shish",
        add_word: true
      },
      {
        key: "Ish bo'limini yangilash",
        edit_work: true
      },
      {
        key: "Ish bo'limini o'chirish",
        delete_work: true
      },
      {
        key: "Ishlar",
        view_work: true
      },
      {
        key: "Ish qo'shish",
        create_work: true
      },
      {
        key: "Smetani import qilib olish",
        import: true
      },
      {
        key: "Gant chart",
        chart: true
      }
    ]
  },
  {
    key: "Ishlar",
    actions: [
      {
        key: "Ishlar ro'yxati",
        view: true
      },
      {
        key: "Ishlar bo'yicha statistika",
        statistics: true
      }
    ]
  }
];
