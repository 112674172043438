import React from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Modal } from "antd";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import ProductListTable from "features/counterparts/components/counterparts/product-list-modal/table/ProductListTable";
import { IProductList } from "features/counterparts/utils/models/productListModel";
import { useImportCatalogProducts } from "features/settings/service/mutations";
import CreateProductModal from "features/supply/components/create-product-modal/CreateProductModal";
import { uid } from "uid";

import { useAppSelector } from "hooks/redux";

import { settingsActions } from "store/reducers/settingsReducer";

import styles from "./categoriesProductModal.module.scss";

const { useWatch, Item } = Form;

type Props = {
  form: FormInstance<any>;
  isNotSubCategory?: boolean;
};

const CategoriesProductListModal: React.FC<Props> = ({ form, isNotSubCategory }) => {
  const dispatch = useDispatch();
  const { setCategoriesProductsModal } = settingsActions;
  const { visible, folderId, folderName, subCategoryId } = useAppSelector(
    state => state.settingsReducer.categoriesProducts
  );
  const baseCurrency = getBaseCurrency();
  const products = useWatch(["products"], { ...form }) as IProductList[];
  const mutation = useImportCatalogProducts(isNotSubCategory!);

  const onCancel = () => {
    dispatch(
      setCategoriesProductsModal({
        visible: false,
        folderId: undefined,
        folderName: ""
      })
    );
  };

  const onOk = () => {
    form.submit();
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      form.setFieldValue("products", []);
    } else {
      form.validateFields();
    }
  };

  const onFinish = () => {
    const filteredProducts = products
      .filter(product => typeof product.rowId === "string")
      .map(p => {
        const { currency_id, amount, related_offers, ...rest } = p;

        return rest;
      });

    const requestBody = subCategoryId
      ? {
          category_id: folderId!,
          subcategory_id: subCategoryId,
          products: filteredProducts?.map(product => ({
            name: {
              uz: String(product?.product_id),
              ru: String(product?.product_id),
              en: String(product?.product_id)
            },
            unit_id: product?.unit_id,
            code: product?.code,
            resource_id: product.resource_id!
          }))
        }
      : {
          category_id: folderId!,
          products: filteredProducts?.map(product => ({
            name: {
              uz: String(product?.product_id),
              ru: String(product?.product_id),
              en: String(product?.product_id)
            },
            unit_id: product?.unit_id,
            code: product?.code,
            resource_id: product.resource_id!
          }))
        };

    mutation.mutateAsync(requestBody).then(() => onCancel());
  };

  const onAddProduct = () => {
    if (products?.length > 0) {
      form.setFieldValue("products", [
        ...products,
        {
          rowId: uid(),
          currency_id: baseCurrency?.id
        }
      ]);
    } else {
      form.setFieldValue("products", [
        {
          rowId: uid(),
          currency_id: baseCurrency?.id
        }
      ]);
    }
  };

  const onDelete = (rowId: string | number) => {
    form.setFieldValue(
      "products",
      products?.filter(product => product?.rowId !== rowId)
    );
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      okText="Saqlash"
      onCancel={onCancel}
      cancelText="Yopish"
      afterOpenChange={onAfterOpen}
      title={
        <div className="flex items-center justify-between">
          <span>{folderName}</span>
        </div>
      }
      wrapClassName={styles.product_list_modal_wrapper}
      okButtonProps={{
        loading: mutation.isLoading
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <div className={styles.body}>
          <div className={styles.header}>
            <Item name="products" shouldUpdate className="d_n" />
            <Item className="d_n" name="select_search" />
            <Item className="d_n" name="selectedProducts" />
          </div>
          <div className={styles.content}>
            {visible && (
              <Item shouldUpdate>
                {field => (
                  <ProductListTable
                    onDelete={onDelete}
                    field={field}
                    form={form}
                    isCounterParts
                    onAddProduct={onAddProduct}
                    isLoading={false}
                  />
                )}
              </Item>
            )}
          </div>
        </div>
      </Form>
      <CreateProductModal afterFunc={() => {}} />
    </Modal>
  );
};

export default CategoriesProductListModal;
