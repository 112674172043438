import React from "react";

const EllipseFileIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2E90FA" />
      <path
        d="M17.5 8.60693V11.5327C17.5 11.9294 17.5 12.1278 17.5817 12.2793C17.6537 12.4126 17.7684 12.521 17.9095 12.5889C18.0699 12.6661 18.28 12.6661 18.7 12.6661H21.7979M17.5 19.041H13M19 16.2077H13M22 14.0743V19.1827C22 20.3728 22 20.9678 21.7548 21.4224C21.539 21.8223 21.1948 22.1473 20.7715 22.3511C20.2902 22.5827 19.6601 22.5827 18.4 22.5827H13.6C12.3399 22.5827 11.7098 22.5827 11.2285 22.3511C10.8052 22.1473 10.461 21.8223 10.2452 21.4224C10 20.9678 10 20.3728 10 19.1827V11.816C10 10.6259 10 10.0308 10.2452 9.57629C10.461 9.17644 10.8052 8.85136 11.2285 8.64763C11.7098 8.41602 12.3399 8.41602 13.6 8.41602H16.0088C16.5592 8.41602 16.8343 8.41602 17.0933 8.47473C17.3229 8.52679 17.5423 8.61264 17.7436 8.72916C17.9707 8.86057 18.1653 9.04433 18.5544 9.41185L20.9456 11.6702C21.3347 12.0377 21.5293 12.2215 21.6684 12.4359C21.7918 12.626 21.8827 12.8333 21.9378 13.0501C22 13.2947 22 13.5546 22 14.0743Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EllipseFileIcon;
