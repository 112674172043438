import React, { useState } from "react";
import { Popover, Spin } from "antd";

import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { colors } from "../../../../app/utils/constants/colors";
import { RU } from "../../../../app/utils/constants/languages";
import { PaymentModel } from "../../../../app/utils/models/payment/paymentModel";
import { useGetOneCompanyPerson } from "../../../service/queries";

import styles from "./incomeExpenseView.module.scss";

type Props = {
  name?: string;
  record?: Omit<PaymentModel["company_person"], "balances">;
  isViewCompanyPersonBalance?: boolean;
};

const CompanyPersonSourceElement: React.FC<Props> = ({ record, isViewCompanyPersonBalance, name }) => {
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useGetOneCompanyPerson(record?.id, open);

  const popoverContent = () => {
    if (record) {
      const personColorStyle = (amount: number): React.CSSProperties => {
        if (amount > 0) {
          return {
            background: colors.SUCCESS_50,
            color: colors.SUCCESS_500
          };
        }
        if (amount < 0) {
          return {
            background: colors.ERROR_50,
            color: colors?.ERROR_500
          };
        }
        return {
          background: colors.GRAY_50,
          color: colors.GRAY_500
        };
      };

      return (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <div className={styles.person_balance}>
            <div className={styles.person_balance__top}>
              <NotUserImage name={data?.name} />
              <div className={styles.person_balance__info}>
                <h4>{data?.name}</h4>
                <div>
                  <span>{data?.person?.tin || "-"}</span>
                  <span>|</span>
                  <span>{data?.person?.phone || "-"}</span>
                </div>
              </div>
            </div>
            <ConditionalRender if={isViewCompanyPersonBalance}>
              <div className={styles.person_balance__bottom}>
                <span>Balans: </span>
                {data?.balances?.map(item => (
                  <div key={item?.id} className={styles.person_balance__item} style={personColorStyle(item.amount)}>
                    <span>{item?.amount?.toLocaleString(RU)}</span>
                    <span>{item?.currency?.symbol}</span>
                  </div>
                ))}
              </div>
            </ConditionalRender>
          </div>
        </Spin>
      );
    }
    return null;
  };

  return (
    <Popover zIndex={99999999} arrow={false} placement="top" trigger={["click"]} onOpenChange={setOpen} title={popoverContent()}>
      <span
        className={styles.person_name}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {name && name?.length > 25 ? `${name?.substring(0, 25)}...` : name}
      </span>
    </Popover>
  );
};

export default CompanyPersonSourceElement;
