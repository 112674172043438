import { useAppDispatch } from "../../../../../../hooks/redux";
import { integrationActions } from "../../../../../../store/reducers/integrationReducer";
import FolderIcon from "../../../../../app/assets/icons/FolderIcon";
import SettingsIcon from "../../../../../app/assets/icons/SettingsIcon";
import LinkIcon from "../../assets/icons/LinkIcon";
import ProjectPlus from "../../assets/icons/ProjectPlusIcon";
import LogoUysot from "../../assets/images/logo-uysot.png";
import Tower from "../../assets/images/tower.png";
import styles from "./connectProjects.module.scss";

type Props = {
  isEmpty?: boolean;
  isProject?: boolean;
  isNeedSettingsIcon?: boolean;
};

const ConnectProjects = ({ isEmpty, isProject, isNeedSettingsIcon }: Props) => {
  const dispatch = useAppDispatch();
  const { setSettingsData, setProjectsConnectData } = integrationActions;

  const onOpenAddProject = () => {
    dispatch(setProjectsConnectData({ visible: true }));
  };

  const onOpenSettings = () => {
    dispatch(setSettingsData({ visible: true }));
  };

  return (
    <div className={styles.connect_projects}>
      <div className={styles.connect_projects__item}>
        <div className={styles.left}>
          {isProject ? <img src={Tower} alt="Tower" /> : <FolderIcon />}
          <div>
            <h4>Minor building</h4>
            <p>3 loyiha</p>
          </div>
        </div>
      </div>
      <div className={styles.link_icon}>
        <LinkIcon />
      </div>
      {!isEmpty && (
        <div className={styles.connect_projects__item}>
          <div className={styles.left}>
            <img src={isProject ? Tower : LogoUysot} alt="LogoUysot" />
            <div>
              <h4>Minor building</h4>
              <p>3 bino</p>
            </div>
          </div>
          {isNeedSettingsIcon && (
            <div className={styles.setting__icon} onClick={onOpenSettings}>
              <SettingsIcon />
            </div>
          )}
        </div>
      )}
      {isEmpty && (
        <div className={styles.connect_projects__empty} onClick={onOpenAddProject}>
          <ProjectPlus />
        </div>
      )}
    </div>
  );
};

export default ConnectProjects;
