import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";
import { PaymentModel } from "../../../app/utils/models/payment/paymentModel";

interface SourceModel {
  name?: string;
  key: string;
  background: string;
  color: string;
  label: string;
}

export const paymentSource = (record?: Partial<PaymentModel>): SourceModel | undefined => {
  let source: SourceModel | undefined;
  const isPaymentTypeExpense = PaymentTypeEnum.EXPENSE === record?.type;

  if (record?.user?.full_name) {
    source = {
      name: record.user.full_name,
      key: "XD",
      label: "Xodim",
      background: "#FECDCA",
      color: "#B42318"
    };
  } else if (record?.company_person?.name) {
    source = {
      name: record?.company_person?.name,
      key: "KN",
      label: "Kontragent",
      background: "#FEF0C7",
      color: "#B54708"
    };
  } else if (record?.type === PaymentTypeEnum.TRANSFER) {
    source = {
      name: `O'tkazma - ${record?.related || record?.id}`,
      key: "TR",
      label: "O'tkazma",
      background: !record?.related ? "#A6F4C5" : "#FEF0C7",
      color: !record?.related ? "#027A48" : "#B54708"
    };
  } else if (record?.type === PaymentTypeEnum.EXCHANGE) {
    source = {
      name: `Konvertatsiya - ${record?.related || record?.id}`,
      key: "CV",
      label: "Konvertatsiya",
      background: !record?.related ? "#A6F4C5" : "#FEF0C7",
      color: !record?.related ? "#027A48" : "#B54708"
    };
  } else if (record?.financial?.name) {
    source = {
      name: record?.financial.name,
      key: isPaymentTypeExpense ? "CH" : "KR",
      label: isPaymentTypeExpense ? "Chiqim" : "Kirim",
      background: isPaymentTypeExpense ? "#FEF0C7" : "#A6F4C5",
      color: isPaymentTypeExpense ? "#B54708" : "#027A48"
    };
  }

  return source;
};
