import { Button } from "antd";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../store/reducers/projectsReducer";
import DownloadIcon from "../../../../../app/assets/icons/DownloadIcon";
import PriceUploadModal from "./price-upload-modal/PriceUploadModal";

import styles from "./Top.module.scss";

const Top = () => {
  const dispatch = useAppDispatch();
  const [openPriceModal, setOpenPriceModal] = useState(false);
  const { sections, emptyInputs, projectCurrency } = useAppSelector(
    (state) => state.projectsReducer
  );
  const { setEmptyInput } = projectsReducerActions;

  const onOpenPriceModal = () => {
    setOpenPriceModal(true);
  };

  useEffect(() => {
    if (sections) {
      dispatch(setEmptyInput());
    }
  }, [sections]);

  return (
    <div className={styles.top}>
      <div className={styles.actions}>
        <p>
          To’ldirilmagan bo’shliqlar <span>{emptyInputs}</span>
        </p>
        <Button onClick={onOpenPriceModal}>
          <DownloadIcon /> Narxini yuklash
        </Button>
      </div>
      <div className={styles.head}>
        <p className={styles.head__name}>Ishlar va resurslar</p>
        <p className={styles.head__quantity}>Hajmi</p>
        <p className={styles.head__unit}>Birligi</p>
        <p className={styles.head__amount}>Narxi</p>
        <p className={styles.head__total}>
          Umumiy narxi ({projectCurrency?.symbol})
        </p>
      </div>
      <PriceUploadModal open={openPriceModal} setOpen={setOpenPriceModal} />
    </div>
  );
};

export default Top;
