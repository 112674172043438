import React, { useState } from "react";
import classNames from "classnames";

import useGetTableColumns from "../../../../hooks/useGetTableColumns";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../service/queries";
import { tableConfigKeys } from "../../utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../utils/enums/customFieldLocationEnum";
import { UserTableDataModel } from "../../utils/models/user/userTableConfigModel";
import TableConfig from "../table-config/TableConfig";

import styles from "./tableSettings.module.scss";

type Props = {
  isNeedSize?: boolean;
  configKey: tableConfigKeys;
  defaultData: UserTableDataModel;
  locations: CustomFieldLocationEnum[];
};

const TableSettings: React.FC<Props> = ({ isNeedSize, configKey, defaultData, locations }) => {
  const [open, setOpen] = useState(false);
  const { data: tableConfigData } = useGetTableConfigs(configKey, defaultData);

  const { data: customFields } = useGetCustomFieldSelect(locations);
  const data = useGetTableColumns({
    defaultData: tableConfigData,
    customFieldSelect: customFields
  });

  const onOpen = () => {
    setOpen(true);
  };

  return (
    <TableConfig
      open={open}
      setOpen={setOpen}
      isNeedSize={isNeedSize}
      tableConfigKey={configKey}
      data={data as unknown as UserTableDataModel}
      defaultData={defaultData as UserTableDataModel | undefined}
      customFields={customFields}
    >
      <div className={classNames(styles.settings, "settings_table")} onClick={onOpen}>
        <SettingsIcon size={18} />
      </div>
    </TableConfig>
  );
};

export default TableSettings;
