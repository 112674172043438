import { colors } from "features/app/utils/constants/colors";
import React from "react";

export interface IProps {
  fill?: string;
  rotate?: string | number;
}

const ArrowDownRightIcon: React.FC<IProps> = ({ fill, rotate }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M7.75 17L17.75 7M17.75 7V17M17.75 7H7.75"
        stroke={fill ? fill : colors.GRAY_700}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownRightIcon;
