import { antd, dayjs, http, i18n, sentry } from "./services";

dayjs.init();
antd.init();

i18n.init({
  languages: ["uz", "ru", "en"],
  ns: "uyqur",
  defaultLanguage: "uz"
});

http.init({
  config: {
    baseURL: import.meta.env.VITE_BASE_URL,
    withCredentials: true
  }
});

sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME
});
