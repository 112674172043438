import { ConfigProviderProps } from "antd/lib/config-provider";
import en_GB from "antd/locale/en_GB";

import { colors } from "./colors";

export const antdConfigProvider: ConfigProviderProps = {
    locale: en_GB,
    theme: {
        token: { colorPrimary: colors.PRIMARY },
        components: {
            Button: {
                // // base
                // colorBorder: colors.primary,
                // fontWeightStrong: 600,
                // boxShadow: "0",
                // boxShadowTertiary: "0",
                // boxShadowSecondary: "0",
                // // lg
                // borderRadiusLG: 8,
                // fontSizeLG: 16,
                // paddingContentHorizontalLG: 24,
                // paddingContentVerticalLG: 10,
                // // md
                // paddingContentHorizontal: 24,
                // paddingContentVertical: 10,
                // borderRadius: 8,
                // fontSize: 16,
                // // sm
                // borderRadiusSM: 6,
                // fontSizeSM: 16,
            },
            Select: {
                controlHeight: 44
            }
        }
    }
}