import React from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import PlusIcon from "../../../../../../app/assets/icons/PlusIcon";
import { PaymentTypeEnum } from "../../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import { parseParamsId } from "../../../../../../app/utils/helpers/parseParamsId";
import CreditCardDownloadIcon from "../../../../../../payment/assets/icons/CreditCardDownloadIcon";
import CreditCardUploadIcon from "../../../../../../payment/assets/icons/CreditCardUploadIcon";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";

import styles from "./infoRight.module.scss";

const InfoRight: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { setIncomeExpenseModal, setVisibleExpectedPayment } = paymentReducerActions;
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const type = searchParams.get(queryParamsKeys.TYPE);

  const onOpenIncomeExpenseModal = (isIncome: boolean) => {
    dispatch(
      setIncomeExpenseModal({
        visible: true,
        type: isIncome ? PaymentTypeEnum.INCOME : PaymentTypeEnum.EXPENSE,
        companyPersonId: +id!,
        isVisibleMoreInfo: false
      })
    );
  };

  const onOpenPendingPaymentModal = () => {
    dispatch(
      setVisibleExpectedPayment({
        visible: true,
        type: type as PaymentTypeEnum
      })
    );
  };

  const items = {
    [PaymentTabKeys.INCOME_EXPENSE]: (
      <div className={styles.right}>
        <div className={styles.income_or_expense}>
          <div onClick={() => onOpenIncomeExpenseModal(true)} className={styles.income_btn}>
            <CreditCardDownloadIcon />
            <span className="text-success-500">{t("payment.Kirim")}</span>
          </div>
          <div onClick={() => onOpenIncomeExpenseModal(false)} className={styles.expense_btn}>
            <CreditCardUploadIcon />
            <span className="text-error-500">{t("payment.Chiqim")}</span>
          </div>
        </div>
      </div>
    ),
    [PaymentTabKeys.PAYMENT]:
      type === PaymentTypeEnum.INCOME ? (
        <div className={styles.right}>
          <div className={styles.income} onClick={onOpenPendingPaymentModal}>
            <PlusIcon />
            <span>{t("Counterparts.Kirim shartnoma yaratish")}</span>
          </div>
        </div>
      ) : (
        <div className={styles.right}>
          <div className={styles.expense} onClick={onOpenPendingPaymentModal}>
            <PlusIcon />
            <span>{t("Counterparts.Chiqim shartnoma yaratish")}</span>
          </div>
        </div>
      ),
    [PaymentTabKeys.ORDER]: null,
    [PaymentTabKeys.WORK]: null,
    [PaymentTabKeys.INVENTORY]: null
  };

  return tabKey ? items[tabKey as keyof typeof items] : items[PaymentTabKeys.INCOME_EXPENSE];
};

export default InfoRight;
