import { FC, useState } from "react";
import { Spin } from "antd";
import classNames from "classnames";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import SectionName from "features/projects/components/detailed-project/static-estimate/section/append/SectionName";
import { useTranslation } from "react-i18next";

import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import { useCopySection, useCreateSection } from "../../../../../service/mutations";

import styles from "./sectionAppend.module.scss";

type Props = {
  customField?: {
    value: number;
    custom_field_id: number;
  };
  index: number | undefined;
};

export const SectionAppend: FC<Props> = ({ index, customField }) => {
  const { t } = useTranslation();
  const createSection = useCreateSection();
  const [creating, setCreating] = useState(false);
  const copySection = useCopySection();

  const onOpenInput = () => {
    setCreating(true);
  };

  const onBlur = () => {
    setCreating(false);
  };

  // @ts-ignore
  return (
    <Spin indicator={LoadingIndicator} spinning={createSection.isLoading || copySection.isLoading}>
      <div
        className={classNames(
          styles.section_append,
          { [styles.active]: creating },
          { "ml-3 border-none": customField }
        )}
        onClick={onOpenInput}
      >
        {creating && (
          <div className={classNames(styles.create, { [styles.active]: creating })}>
            {index && <p>{index + 1}.</p>}
            <SectionName
              // @ts-ignore
              copySection={copySection}
              // @ts-ignore
              createSection={createSection}
              creating={creating}
              setCreating={setCreating}
              customField={customField}
            />
          </div>
        )}
        {!creating && (
          <div className={classNames(styles.button, { [styles.active]: !creating })}>
            <PlusCircleIcon />
            <p>{t("project.Ish bo’limi qo’shish")}</p>
          </div>
        )}
      </div>
    </Spin>
  );
};
