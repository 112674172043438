import React, { useMemo } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { PaginationType } from "features/app/utils/models/PaginationType";
import OfferHistory from "features/supply/components/offers/offer-history/OfferHistory";
import OfferPartyModal from "features/supply/components/offers/offer-party-modal/OfferPartyModal";
import { useGetOffers } from "features/supply/service/queries";

import { ConfirmationOfferView } from "modules/dashboard";

import OfferColumns from "./columns/columns";

import styles from "./offers.module.scss";

interface Props {
  isImpost?: boolean;
  data: PaginationType<ConfirmationOfferView[]> | undefined;
  isLoading: boolean;
  isView?: boolean;
}

const Offers: React.FC<Props> = ({ isImpost, data: impostData, isLoading: isLoadingImpost, isView }) => {
  const { data: supplyData, isLoading: isLoadingSupply } = useGetOffers(!isImpost);

  const data = useMemo(() => {
    if (isImpost) {
      return {
        ...impostData,
        isLoading: isLoadingImpost
      };
    }
    return {
      ...supplyData,
      isLoading: isLoadingSupply
    };
  }, [isImpost, supplyData, impostData, isLoadingImpost, isLoadingSupply]);

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total
      }}
      configKey={tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG}
    />
  );

  return (
    <div className={styles.offers}>
      <Table<ConfirmationOfferView>
        pagination={false}
        dataSource={data?.data as never}
        className={`${styles.offers__table} footer_table`}
        rowKey={record => record?.id}
        columns={OfferColumns({ isImpost, isView: true }) as ColumnsType<ConfirmationOfferView>}
        footer={footer}
        loading={{
          spinning: data?.isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        rowClassName={styles.row}
        scroll={{ y: "calc(100vh - 18.125rem)" }}
      />

      <OfferPartyModal />
      <OfferHistory />
    </div>
  );
};

export default Offers;
