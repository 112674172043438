import React from "react";
import { Spin } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { colors } from "features/app/utils/constants/colors";

import { useQueryParams } from "hooks/useQueryParams";

import { RU } from "modules/common";
import { CompanyPersonCard, typeValues, useOneCompanyWidget, useWidget } from "modules/dashboard";
import { useTranslation } from "react-i18next";

const Top: React.FC = () => {
  const { queries } = useQueryParams();
  const { t } = useTranslation();
  const { balance_total_amount_type } = queries();
  const type = balance_total_amount_type || typeValues.creditor;
  const { id, visible } = useWidget();
  const { data, isLoading } = useOneCompanyWidget({
    id,
    enabled: visible,
    otherProps: { balance_total_amount_type: type }
  });
  const statistics = data?.data as CompanyPersonCard[];

  const staticData: Record<string, { name: string; color: string }> = {
    [typeValues.debtor]: {
      color: colors.ERROR_500,
      name: `${t("dashboard.Debitorlar")}`
    },
    [typeValues.creditor]: {
      color: colors.SUCCESS_500,
      name: `${t("dashboard.Kreditorlar")}`
    }
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className="box-border flex flex-col gap-5 rounded-xl border border-solid border-gray-200 bg-white px-5 py-4">
        <h3 className="m-0 text-lg font-medium text-gray-700">{t("dashboard.Kontragentlar statistikasi")}</h3>
        <div className="box-border flex flex-col gap-3 rounded-xl border border-solid border-gray-100 bg-gray-50 px-4 py-3">
          <h4 className="m-0 text-base font-normal text-gray-700">{staticData[type].name}</h4>
          <div className="flex items-center gap-3">
            {statistics?.map((item, index) => (
              <div className="flex items-center gap-1" key={Math.random()}>
                {index ? <div className="mr-2 h-6 w-px bg-gray-300" /> : null}
                <h2 className="m-0 text-[1.75rem] font-medium" style={{ color: staticData[type].color }}>
                  {item?.amount?.toLocaleString(RU)}
                </h2>
                <span className="text-sm font-semibold text-gray-400">{item?.symbol}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default Top;
