import Chat from "../../../app/components/chat/Chat";
import DefectModal from "../../components/defect-modal/DefectModal";
import AttachToProject from "../../components/inventories/attach-to-project/AttachToProject";
import InventoryCreate from "../../components/inventories/inventory-create/InventoryCreate";
import InventoryView from "../../components/inventories/inventory-view/InventoryView";
import InventoryTable from "../../components/inventories/tables/inventory-table/InventoryTable";
// import Top from "../../components/inventories/top/Top";
import { useGetFolderInventories } from "../../service/queries";

// import styles from "./DetailedInventory.module.scss";

const DetailedInventory = () => {
  const { data: inventories, isLoading: isLoadingInventories } = useGetFolderInventories();

  return (
    <div>
      {/* <Top /> */}
      <InventoryTable inventories={inventories?.data} total={inventories?.total} isLoading={isLoadingInventories} />
      <InventoryCreate />
      <DefectModal />
      <InventoryView />
      <AttachToProject />
      <Chat />
    </div>
  );
};

export default DetailedInventory;
