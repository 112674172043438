type Props = {
  stroke?: string;
  isBackground?: boolean;
};

const withBackground = (stroke: string) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 7.49995V10.8333M10.0001 14.1666H10.0085M8.84622 3.24305L1.99215 15.0819C1.61198 15.7386 1.42189 16.0669 1.44999 16.3364C1.47449 16.5714 1.59763 16.785 1.78876 16.924C2.0079 17.0833 2.38728 17.0833 3.14605 17.0833H16.8542C17.613 17.0833 17.9923 17.0833 18.2115 16.924C18.4026 16.785 18.5258 16.5714 18.5503 16.3364C18.5783 16.0669 18.3883 15.7386 18.0081 15.0819L11.154 3.24305C10.7752 2.58875 10.5858 2.26159 10.3387 2.15172C10.1232 2.05587 9.87709 2.05587 9.66154 2.15172C9.41443 2.26159 9.22503 2.58875 8.84622 3.24305Z"
        stroke={`${stroke ?? "#F79009"}`}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const AlertIcon = ({ stroke, isBackground }: Props) => {
  return (
    <div
      style={{
        backgroundColor: isBackground ? "#FEF0C7" : "transparent",
        width: isBackground ? "30px" : "",
        height: isBackground ? "30px" : "",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {withBackground(stroke!)}
    </div>
  );
};

export default AlertIcon;
