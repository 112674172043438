import { Avatar, Tooltip } from "antd";
import React from "react";
import { getFirstLetter } from "../../utils/helpers/getFirstLetter";

interface IProps {
  name?: string;
  image?: string;
}

const CustomAvatar = ({ image, name = "" }: IProps) => {
  return (
    <Tooltip title={`${name}`}>
      <Avatar src={image} className="avatarFull">
        {getFirstLetter(name)}
      </Avatar>
    </Tooltip>
  );
};

export default CustomAvatar;
