import { RU } from "features/app/utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const ConfirmationOrderRecieved = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/supply?tab=parties", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record?.type]}</h3>
      <p>
        {record?.user?.full_name} {record?.confirmation_order?.amount?.toLocaleString(RU)}{" "}
        {record?.confirmation_order?.currency?.symbol}{" "}
        <span className={styles.name}>P - {record?.confirmation_order?.id}</span>{" "}
        partiya tasdiqlandi.
      </p>
    </div>
  );
};

export default ConfirmationOrderRecieved;
