import React, { ErrorInfo, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { Button } from "antd";

import NotFoundIcon from "../../../not-found/assets/icons/NotFoundIcon";

import styles from "./errorBoundary.module.scss";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false
    };
    this.toProjectsPage = this.toProjectsPage.bind(this);
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.captureException(error, { extra: errorInfo as any });

    console.error("Uncaught error:", error, errorInfo);
  }

  toProjectsPage() {
    this.setState({ hasError: false });
    // window?.location.reload();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.not_found}>
          <h6>Obbooooo !</h6>
          <h1>Tizimda xatolik yuz berdi</h1>
          <span>
            <NotFoundIcon />
          </span>
          <p>Kechirasiz, tizimda xatolik mavjud, iltimos admin bilan bog'laning: +998 94 250 81 51 (Kozimjon)</p>
          <Button type="primary" onClick={this.toProjectsPage}>
            Sahifani qayta yuklash
          </Button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
