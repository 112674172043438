import { ReactNode } from "react";

import { colors } from "../../../app/utils/constants/colors";
import CheckCircleIcon from "../../../payment/assets/icons/CheckCircleIcon";
import ClockCircleIcon from "../../../supply/assets/icons/ClockCircleIcon";
import { DynamicEstimateEnum } from "../enums/dynamicEstimateEnum";

export const DynamicEstimateStatusIcon: Record<DynamicEstimateEnum, ReactNode> = {
  [DynamicEstimateEnum.CLOSED]: <CheckCircleIcon />,
  [DynamicEstimateEnum.OPEN]: <ClockCircleIcon color={colors.GRAY_400} />,
  [DynamicEstimateEnum.COMPLETED]: <CheckCircleIcon />,
  [DynamicEstimateEnum.PROCESS]: <ClockCircleIcon color={colors.PRIMARY} />
};

export const estimateStatusNames = {
  [DynamicEstimateEnum.CLOSED]: "Yopilgan",
  [DynamicEstimateEnum.OPEN]: "Ochiq",
  [DynamicEstimateEnum.COMPLETED]: "Tugallangan",
  [DynamicEstimateEnum.PROCESS]: "Jarayonda"
};

export function getEstimateStatusIcon(payload: string | number) {
  if (typeof payload === "string") {
    return DynamicEstimateStatusIcon[payload as DynamicEstimateEnum];
  }

  if (payload === 0) return <ClockCircleIcon color={colors.GRAY_400} />;
  if (payload > 0 && payload < 100) return <ClockCircleIcon color={colors.PRIMARY} />;
  if (payload === 100) return <CheckCircleIcon />;

  return null;
}
