import React from "react";
import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import { useAmountFixer } from "modules/common";

import styles from "./custom-widget-tooltip.module.scss";

type Props = {
  name: string;
  factAmount: number;
  planAmount: number;
  predictionAmount: number;
  factColor: string;
  planColor: string;
  predictColor: string;
  children: React.ReactNode;
};

const CustomWidgetTooltip: React.FC<Props> = ({
  factAmount,
  predictionAmount,
  planAmount,
  planColor,
  predictColor,
  factColor,
  name,
  children
}) => {
  const { t } = useTranslation();
  const amountFixer = useAmountFixer;

  const content = () => (
    <div className="flex w-[248px] flex-col gap-3 rounded-lg border border-solid border-white bg-white p-0">
      <span className="text-sm font-medium text-gray-500">{name}</span>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {factColor?.length > 0 && (
              <span
                className="h-3 w-3 rounded-[3px] border border-solid"
                style={{
                  borderColor: factColor,
                  backgroundColor: factColor
                }}
              />
            )}
            <span className="text-xs font-normal text-gray-500">{t("project.Fakt")}</span>
          </div>
          <span className="text-sm font-medium text-gray-600">{amountFixer(String(factAmount))}</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            {predictColor?.length > 0 && (
              <span
                className="h-3 w-3 rounded-[3px] border border-solid"
                style={{
                  borderColor: predictColor,
                  backgroundColor: predictColor
                }}
              />
            )}
            <span className="text-xs font-normal text-gray-500">{t("project.Bashorat")}</span>
          </div>
          <span className="text-sm font-medium text-gray-600">{amountFixer(String(predictionAmount))}</span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            {planColor?.length > 0 && (
              <span
                className="ml-1.5 h-[18px] w-px rounded-xl border border-solid"
                style={{
                  borderColor: planColor,
                  backgroundColor: planColor
                }}
              />
            )}
            <span className="text-xs font-normal text-gray-500">{t("project.Plan")}</span>
          </div>
          <span className="text-sm font-medium text-gray-600">{amountFixer(String(planAmount))}</span>
        </div>
      </div>
    </div>
  );

  return (
    <Popover arrow={false} content={content} className={styles.popover}>
      {children}
    </Popover>
  );
};

export default CustomWidgetTooltip;
