import React from "react";

type Props = {
  color?: string;
  width?: number;
  onClick?: () => void;
};

const CloseIcon: React.FC<Props> = ({ color, width, onClick }) => (
    <svg
      width={width ?? "24"}
      height={width ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="cursor-pointer"
    >
      <path
        d="M18 6L6 18M6 6L18 18"
        stroke={color ?? "#667085"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default CloseIcon;
