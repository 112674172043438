export const endPoints = {
  GANTT_SECTION: (id: number) => `section/gant-chart?project_id=${id}`,
  GANTT_SEARCH_SECTION: (id: number) => `section/gant-chart-search?project_id=${id}`,
  GANTT_TASK: (id: number) => `task/gant-chart?section_id=${id}`,
  TASK_UPDATE: "/task/update",
  SEQUENCE_TASK: {
    CREATE: "sequence-task/create",
    UPDATE: "sequence-task/update",
    DELETE: (id: number) => `sequence-task/delete?id=${id}`,
  },
};
