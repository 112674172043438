import { FC, MouseEvent, ReactNode, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Dropdown, MenuProps, Popover } from "antd";
import dayjs from "dayjs";
import ProjectReportIcon from "features/app/assets/icons/ProjectReportIcon";
import CopyIcon from "features/payment/assets/icons/CopyIcon";
import { useTranslation } from "react-i18next";

import { projectReportModalActions } from "store";

import { cx } from "modules/common";

import { useAppDispatch } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { rootPaths } from "../../../../routes/root/rootPaths";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import DeleteIcon from "../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../app/assets/icons/EditIcon";
import ModalConfirm from "../../../app/components/modal-confirm/ModalConfirm";
import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { dayjsUnits } from "../../../app/utils/enums/dayjsUnits";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import ArchiveIcon from "../../../supply/assets/icons/ArchiveIcon";
import FlipBackwardIcon from "../../../warehouse/assets/icons/FlipBackwardIcon";
import { CompletedIcon } from "../../assets/icons/CompletedIcon";
import CornerUpLeftIcon from "../../assets/icons/CornerUpLeftIcon";
import { PlanningIcon } from "../../assets/icons/PlanningIcon";
import { ProcessIcon } from "../../assets/icons/ProcessIcon";
import { SuspendedIcon } from "../../assets/icons/SuspendedIcon";
import { useCopyProject, useDeleteProject, useUpdateProject } from "../../service/mutations";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { projectStatuses } from "../../utils/enums/projectStatuses";
import { ProjectModel } from "../../utils/models/projectModel";
import { ProjectProgress } from "../project-progress/ProjectProgress";

import styles from "./projectCard.module.scss";

export const ProjectCard: FC<ProjectModel> = props => {
  const { id, name, status, percent, due_date, prediction_date } = props;
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setProjectModalData, setEvictionModal } = projectsReducerActions;
  const location = useLocation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const deleteProject = useDeleteProject();
  const archiveProject = useUpdateProject();
  const { id: folderId } = parseParamsId(params?.folderId);
  const copyProject = useCopyProject(Number(folderId), !isProjectsPage);
  const navigate = useNavigate();
  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const estimateActions = actions("Smeta");
  const workActions = actions("Ishlar");
  const projectActions = actions("Loyihalar");
  const paramsValue = parseParamsId(params?.folderId);
  const [open, setOpen] = useState(false);

  const { setVisible } = projectReportModalActions;

  const onClickCard = () => {
    if (estimateActions.view || workActions.view) {
      navigate(`${rootPaths.PROJECTS}/project/${id.toString()}/estimate`, {
        state: { path: location.pathname }
      });
    }
  };

  const diffClassName = (diff: number) => {
    if (diff > 0) {
      return styles.red;
    }
    if (diff < 0) {
      return styles.green;
    }
    return "";
  };

  const diffOperator = (diff: number) => {
    if (diff > 0) {
      return "-";
    }
    if (diff < 0) {
      return "+";
    }
    return "";
  };

  const stopPropagation = (e: MouseEvent) => {
    e.stopPropagation();
  };

  const popoverContent = (due_date: string, diff: number) => (
    <div className={styles.popover__content}>
      <div className={styles.popover__item}>
        <p>{t("project.Reja")}</p>
        <p>{due_date}</p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("project.Farq")}</p>
        <p className={`${styles.due_date} ${diffClassName(diff)}`}>
          {diffOperator(diff)} {Math.abs(diff)} kun
        </p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("project.Fakt")}</p>
        <p>{prediction_date}</p>
      </div>
    </div>
  );

  const prediction = useMemo(() => {
    const diff = dayjs(prediction_date, dayjsFormats.DATE).diff(dayjs(due_date, dayjsFormats.DATE), dayjsUnits.DAY);

    return (
      <>
        {due_date ? (
          <Popover title={popoverContent(due_date, diff)} overlayClassName={styles.popover}>
            <span className={`${styles.due_date} ${diffClassName(diff)}`}>{due_date}</span>
          </Popover>
        ) : (
          t("project.Ma'lum emas")
        )}
      </>
    );
  }, [due_date, prediction_date]);

  const onUpdate = () => {
    // onClose();
    dispatch(
      setProjectModalData({
        visible: true,
        id
      })
    );
    setOpen(false);
  };

  const onRemovalProject = () => {
    // onClose();
    dispatch(
      setEvictionModal({
        visible: true,
        folderId: paramsValue.id ? +paramsValue.id : undefined,
        id
      })
    );
    setOpen(false);
  };

  const onDelete = () => deleteProject.mutateAsync(id);

  const onCopy = (e: MouseEvent) => {
    stopPropagation(e);
    return copyProject.mutateAsync(id).then(() => setOpen(false));
  };

  const onArchive = (e: MouseEvent) => {
    stopPropagation(e);
    return archiveProject
      .mutateAsync({
        id,
        is_archived: isProjectsPage
      })
      .then(() => setOpen(false));
  };

  const dropdownItems = [
    {
      key: "removal",
      label: (
        <div className={styles.dropdown_item} onClick={onRemovalProject}>
          <FlipBackwardIcon />
          <span>{t("project.Ko'chirish")}</span>
        </div>
      ),
      isView: true
    },
    {
      key: "edit",
      label: (
        <div className={styles.dropdown_item}>
          <EditIcon />
          <span>{t("project.Tahrirlash")}</span>
        </div>
      ),
      onClick: onUpdate,
      isView: true
    },
    {
      key: "4",
      label: (
        <Button
          loading={archiveProject.isLoading}
          className={cx(styles.dropdown_item, "h-max p-0 shadow-none")}
          type="link"
          onClick={onArchive}
        >
          {isProjectsPage ? <ArchiveIcon /> : <CornerUpLeftIcon />}
          <span>{isProjectsPage ? t("project.Arxivlash") : t("project.Arxivdan chiqarish")}</span>
        </Button>
      ),
      isView: true
    },
    {
      key: "copy",
      label: (
        <Button
          loading={copyProject.isLoading}
          type="link"
          className={cx(styles.dropdown_item, "h-max p-0 shadow-none")}
          onClick={e => onCopy(e)}
        >
          <CopyIcon />
          <span>{t("project.Nusxa olish")}</span>
        </Button>
      ),
      isView: true,

      permissionKey: "copy"
    },
    {
      key: "delete",
      label: (
        <ModalConfirm onOk={onDelete}>
          <div className={styles.dropdown_item}>
            <DeleteIcon />
            <span>{t("project.O'chirish")}</span>
          </div>
        </ModalConfirm>
      ),
      isView: true
    },
    {
      key: "project_report",
      label: (
        <div
          className={cx(styles.dropdown_item)}
          onClick={() =>
            dispatch(
              setVisible({
                data: props,
                visible: true
              })
            )
          }
        >
          <ProjectReportIcon />
          <span>{t("project.Loyiha hisoboti")}</span>
        </div>
      ),
      isView: true
    }
  ];

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const statuses = {
    [projectStatuses.PROCESS]: (
      <div className={`${styles.status} ${styles[projectStatuses.PROCESS]}`}>
        <ProcessIcon />
        <span>{t("project.Jarayonda")}</span>
      </div>
    ),
    [projectStatuses.PLANNING]: (
      <div className={`${styles.status} ${styles[projectStatuses.PLANNING]}`}>
        <PlanningIcon />
        <span>{t("project.Rejalanmoqda")}</span>
      </div>
    ),
    [projectStatuses.COMPLETED]: (
      <div className={`${styles.status} ${styles[projectStatuses.COMPLETED]}`}>
        <CompletedIcon />
        <span>{t("project.Yopildi")}</span>
      </div>
    ),
    [projectStatuses.SUSPENDED]: (
      <div className={`${styles.status} ${styles[projectStatuses.SUSPENDED]}`}>
        <SuspendedIcon />
        <span>{t("project.To’xtatilgan")}</span>
      </div>
    )
  };

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <div className={styles.card} onClick={onClickCard}>
      <div className={styles.card__header}>
        <div className={styles.top}>
          <p>{name}</p>
          <Dropdown
            open={open}
            trigger={["click"]}
            onOpenChange={onOpenChange}
            menu={{
              items: dropdownItems?.filter(item => {
                if (item?.permissionKey) return projectActions[item?.permissionKey] && item?.isView;
                return item?.isView;
              }) as MenuProps["items"]
            }}
            dropdownRender={dropdownRender}
          >
            <div onClick={stopPropagation}>
              <DotsVerticalIcon />
            </div>
          </Dropdown>
        </div>
        <div className={styles.bottom}>
          <p>{t("project.Reja")}:</p>
          <p className={styles.due_date}>{prediction}</p>
        </div>
      </div>
      <div className={styles.card__body}>
        <div className={styles.top}>
          <ProjectProgress percent={percent} />
        </div>
        <div className={styles.bottom}>
          {statuses[status!]}
          <span className={styles.arrow}>
            <ArrowNarrowIcon />
          </span>
        </div>
      </div>
    </div>
  );
};
