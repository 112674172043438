const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.125 3.6182C3.125 3.01122 3.125 2.70774 3.25156 2.54044C3.36181 2.3947 3.53033 2.30451 3.71275 2.29362C3.92215 2.28111 4.17467 2.44946 4.6797 2.78615L11.2519 7.16763C11.6692 7.44584 11.8779 7.58494 11.9506 7.76026C12.0142 7.91355 12.0142 8.08582 11.9506 8.2391C11.8779 8.41443 11.6692 8.55353 11.2519 8.83173L4.6797 13.2132C4.17466 13.5499 3.92215 13.7183 3.71275 13.7057C3.53033 13.6949 3.36181 13.6047 3.25156 13.4589C3.125 13.2916 3.125 12.9881 3.125 12.3812V3.6182Z"
      stroke={props.stroke ? props.stroke : "#1E90FF"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlayIcon;
