import React from "react";

import { useWidget } from "modules/dashboard";
import { useProjectActivity } from "modules/dashboard/queries/use-project-activity";

import ProjectActiveViewTable from "./table";

const ProjectActivity: React.FC = () => {
  const { id, props, visible } = useWidget();

  console.log("object", props);

  const { projectActivityCalendarView, isLoading } = useProjectActivity({
    id,
    enabled: visible,
    completed_date: props?.date
  });

  return (
    <div className="w-[53.688rem] p-5">
      <ProjectActiveViewTable data={projectActivityCalendarView} isLoading={isLoading} />
    </div>
  );
};

export default ProjectActivity;
