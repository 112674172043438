import { TaskStatus } from "../models/TaskStatus";

export const taskStatus = {
    [TaskStatus.OPEN]: {
        color: "#98A2B3",
        text: "Ochiq",
    },
    [TaskStatus.PROCESS]: {
        color: "#1e90ff",
        text: "Bajarilmoqda",
    },
    [TaskStatus.COMPLETED]: {
        color: "#32d583",
        text: "Bajarildi",
    },
    [TaskStatus.CLOSED]: {
        color: "#98a2b3",
        text: "Bekor qilindi",
    },
};