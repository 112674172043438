import React, { useEffect, useMemo, useState } from "react";
import { Button } from "antd";

import styles from "../../orders/filter-drawer/filterDrawer.module.scss";
import FilterLinesIcon from "../../../../warehouse/assets/icons/FilterLinesIcon";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import MainWarehouseIcon from "../../../../app/assets/icons/MainWarehouseIcon";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { useGetProjectSelect } from "../../../../payment/service/queries";
import { useGetWarehouseSelect } from "../../../../warehouse/service/query";
import queryString from "query-string";
import dayjs, { Dayjs } from "dayjs";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { useTranslation } from "react-i18next";
import { useGetCustomFieldSelect, useProjectSelect } from "features/app/service/queries";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { colors } from "modules/common";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";

const queryStringArr = [
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.MIN_DELIVERY_DATE,
  queryParamsKeys.MAX_DELIVERY_DATE,
  queryParamsKeys.CUSTOM_FIELDS
];

const OffersFilter: React.FC = () => {
  const { queries } = useQueryParams();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const { data: selectableCustomFields } = useGetCustomFieldSelect(
    [CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER],
    true
  );

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => {
    return Object.values(customFieldStates).flat();
  }, [customFieldStates]);

  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );

  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DELIVERY_DATE]
      ? dayjs(queries()[queryParamsKeys.MIN_DELIVERY_DATE], dayjsFormats.DATE)
      : null,
    queries()[queryParamsKeys.MAX_DELIVERY_DATE]
      ? dayjs(queries()[queryParamsKeys.MAX_DELIVERY_DATE], dayjsFormats.DATE)
      : null
  ]);

  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: warehouses } = useGetWarehouseSelect(false, open);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const childContent = (
    <Button className={styles.filter} onClick={onOpenFilter}>
      <FilterLinesIcon /> {t("Supply.Filter")}
      {<FiltersCount queryStringArr={queryStringArr} />}
    </Button>
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];
          initialCustomFieldStates[field.id] = valuesArray.filter(value => {
            return field?.custom_field_options?.some(option => String(option.id) === value);
          }) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  return (
    <FilterDrawer
      open={open}
      height={170}
      setOpen={setOpen}
      filterButton={childContent}
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      setSelectedOptionsArr={[setWarehousesSelected, setProjectsSelected, setCustomFieldStates] as never}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DELIVERY_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DELIVERY_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("Supply.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              selectedOptions={warehousesSelected}
              setSelectedOptions={setWarehousesSelected}
              data={warehouses}
              multiple="multiple"
              icon={<MainWarehouseIcon />}
              placeholder={t("Supply.Omborxonalar")}
              showSearch
            />
            <FilterTreeSelect
              selectedOptions={projectsSelected}
              setSelectedOptions={setProjectsSelected}
              data={projects}
              multiple={true}
              icon={<BuildingIcon />}
              placeholder={t("Supply.Loyihalar")}
              showSearch
            />
            <FilterDateRange value={dateValue} setValue={setDateValue} />
          </div>
        </div>
        {selectableCustomFields?.length! > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("Supply.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </FilterDrawer>
  );
};

export default OffersFilter;
