import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { memo } from "react";

import { useAppDispatch } from "../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import { SectionModel } from "../../../../../utils/models/estimateModel";
import { EditInput } from "../editInput/EditInput";
import Task from "../task/Task";

import styles from "./Section.module.scss";

type Props = {
  section: SectionModel;
  index: number;
};

const Section = memo(({ section, index }: Props) => {
  const dispatch = useAppDispatch();
  const { setEditSection } = projectsReducerActions;

  const onChange = (event: CheckboxChangeEvent) => {
    dispatch(
      setEditSection({
        sectionIndex: index,
        editKey: "checked",
        editValue: event.target.checked,
      })
    );
  };

  return (
    <div className={styles.section}>
      <div
        className={`${styles.section__name} ${
          !section.checked ? styles.disabled : ""
        }`}
      >
        <Checkbox onChange={onChange} checked={section.checked} />
        {section?.place}.{" "}
        <EditInput sectionIndex={index} editKey="name">
          {section?.name}
        </EditInput>
      </div>
      {section?.tasks?.map((task, taskIndex) => (
        <Task
          task={task}
          place={section?.place}
          key={task.name + taskIndex}
          index={taskIndex}
          sectionIndex={index}
        />
      ))}
    </div>
  );
});

export default Section;
