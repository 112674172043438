import React from "react";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { cx } from "features/app/utils/helpers/cx";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { Product } from "modules/common";

type Props = {
  product?: Product;
};

const Top: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 rounded-2xl border border-solid border-gray-200 bg-white p-4">
      <span className="text-sm font-medium text-gray-600">{t("dashboard.Nomi")}</span>
      <div className="flex items-center justify-between gap-2 rounded-lg border border-solid border-gray-300 px-3.5 py-[10px]">
        <div className={cx("resource")}>
          <div style={{ borderColor: product?.resource?.color, color: product?.resource?.color }}>
            {product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={product?.name[i18n.language]} length={45} />
          </span>
        </div>
        <span className="text-sm font-medium text-gray-400">{product?.resource?.symbol[i18n.language]}</span>
      </div>
    </div>
  );
};

export default Top;
