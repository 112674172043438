import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const InventoryDefectRejected = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/inventory?tab=invalids", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} ${record?.inventory_defect?.inventory?.code}{" "}
        raqamli {record?.inventory_defect?.inventory?.name} yaroqsizligini
        tasdiqlamadi
      </p>
    </div>
  );
};

export default InventoryDefectRejected;
