import React from "react";

const ExpectedExpenseIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.49999 14.1666L9.99999 16.6666M9.99999 16.6666L12.5 14.1666M9.99999 16.6666V10.8333M18.3333 7.49992H1.66666M4.58332 14.9999H4.33332C3.3999 14.9999 2.93319 14.9999 2.57667 14.8183C2.26307 14.6585 2.0081 14.4035 1.84831 14.0899C1.66666 13.7334 1.66666 13.2667 1.66666 12.3333V5.99992C1.66666 5.0665 1.66666 4.59979 1.84831 4.24327C2.0081 3.92966 2.26307 3.6747 2.57667 3.51491C2.93319 3.33325 3.3999 3.33325 4.33332 3.33325H15.6667C16.6001 3.33325 17.0668 3.33325 17.4233 3.51491C17.7369 3.6747 17.9919 3.92967 18.1517 4.24327C18.3333 4.59979 18.3333 5.0665 18.3333 5.99992V12.3333C18.3333 13.2667 18.3333 13.7334 18.1517 14.0899C17.9919 14.4035 17.7369 14.6585 17.4233 14.8183C17.0668 14.9999 16.6001 14.9999 15.6667 14.9999H15.4167"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExpectedExpenseIcon;
