import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Input, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SearchIcon } from "features/app/assets/icons/SearchIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { RU } from "features/app/utils/constants/languages";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import PackageXIcon from "features/inventory/assets/icons/PackageXIcon";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "hooks/redux";

import { defectModalActions } from "store/reducers/defectModalReducer";

import styles from "./defectWarehouseProducts.module.scss";

interface IProps {
  warehouseProducts: PaginationType<WarehouseProductModel[]> | undefined;
  setPage: Dispatch<SetStateAction<number>>;
  setSearchText: Dispatch<SetStateAction<string | undefined>>;
  isLoading: boolean;
  warehouseId: number | undefined;
  warehouses: {
    id: number;
    name: string;
  }[];
}

const DefectWarehouseProducts: React.FC<IProps> = ({
  isLoading,
  setPage,
  setSearchText,
  warehouseProducts,
  warehouseId,
  warehouses
}) => {
  const [time, setTime] = useState<TimeoutModel>();
  const { i18n } = useTranslation();

  const dispatch = useAppDispatch();

  const { setDefectModal } = defectModalActions;

  const selectedWarehouse = warehouses?.find(el => el.id === warehouseId);

  const onPageChange = (page: number) => {
    setPage(page);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    clearTimeout(time);
    if (value) {
      setTime(
        setTimeout(() => {
          setPage(1);
          setSearchText(value);
        }, 800)
      );
    } else {
      setSearchText(undefined);
    }
  };

  const handleOpenDefectModal = (record: WarehouseProductModel) => {
    dispatch(
      setDefectModal({
        visible: true,
        data: { ...record, warehouse: selectedWarehouse! }
      })
    );
  };

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: WarehouseProductModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          <span>
            <PopoverShortName title={record?.product?.name[i18n.language]} length={35} />
          </span>
        </div>
      ),
      width: "65%"
    },
    {
      title: "Miqdori",
      render: (record: WarehouseProductModel) => record?.total_quantity?.toLocaleString(RU),
      align: "center"
    },

    {
      title: "Birligi",
      render: (record: WarehouseProductModel) => record.unit.symbol[i18n.language]
    },
    {
      title: "Loyihasi",
      render: (record: WarehouseProductModel) => record?.project?.name,
      align: "center"
    },
    {
      title: "",
      render: (record: WarehouseProductModel) => (
        <div className={styles.container}>
          <Button onClick={() => handleOpenDefectModal(record)}>
            <PackageXIcon /> Yaroqsiz
          </Button>
        </div>
      ),
      align: "right"
    }
  ];

  const footer = () => (
    <Pagination
      paginationProps={{
        total: warehouseProducts?.total,
        current: warehouseProducts?.current_page,
        onChange: onPageChange
      }}
    />
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <span className="flex-1 text-base font-medium text-gray-900">Mahsulotlari</span>
        <Input placeholder="Qidiruv" prefix={<SearchIcon />} className="w-[18.75rem]" onChange={onChange} />
      </div>
      <div>
        <Table<WarehouseProductModel>
          columns={columns}
          dataSource={warehouseProducts?.data}
          rowKey={record => record.product?.id + record?.unit?.id}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          className="footer_table"
          footer={footer}
          rowClassName={styles.table_row}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default DefectWarehouseProducts;
