import React, { useState } from "react";
import { Button, Modal, Spin } from "antd";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { colors } from "features/app/utils/constants/colors";

import { useWidgetView } from "modules/dashboard";

import WidgetModalList from "./list";

import styles from "./view-modal.module.scss";
import { useTranslation } from "react-i18next";

const ViewModal: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const { widgetViews, isLoading } = useWidgetView({ enabled: visible });
  const { t } = useTranslation();

  const onCancel = () => {
    setVisible(false);
  };

  const onOpenModal = () => {
    setVisible(true);
  };

  const footer = <Button onClick={onCancel}>{t("dashboard.Yopish")}</Button>;

  return (
    <>
      <Button className="border-dashed text-sm font-medium text-gray-700" onClick={onOpenModal}>
        <PlusIcon color={colors.GRAY_700} />
        {t("dashboard.Vidjet yaratish")}
      </Button>
      <Modal
        centered
        zIndex={9999}
        open={visible}
        footer={footer}
        onCancel={onCancel}
        title={t("dashboard.Vidjet yaratish")}
        rootClassName={styles.widget_modal}
      >
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <div className="flex flex-col gap-6">
            <WidgetModalList data={widgetViews} isLoading={isLoading} />
          </div>
        </Spin>
      </Modal>
    </>
  );
};

export default ViewModal;
