import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { DatePicker, Form, Input, message, Modal } from "antd";

import { RootState } from "../../../../../../store";
import { useAppSelector } from "../../../../../../hooks/redux";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { expectedPaymentsLastId } from "../../../../utils/helpers/expectedPaymentsLastId";

import styles from "./appendElementModal.module.scss";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";

interface AppendElementFormModel {
  count: string;
  amount: string;
  date: Date;
}

const { Item } = Form;

type Props = {
  state: {
    startDate?: string;
    count?: number;
    mountRange?: number;
    customMountRange?: number;
  };
};

const AppendElementModal: React.FC<Props> = ({ state }) => {
  const dispatch = useDispatch();
  const { setVisibleAppendElement, setAppendOneElement } = paymentReducerActions;
  const { element, form } = useAppSelector((state: RootState) => state.paymentReducer.expected_payment);
  const [formInstance] = Form.useForm<AppendElementFormModel>();

  const onFinish = (values: AppendElementFormModel) => {
    const { amount, date, count } = values;
    const lastId = expectedPaymentsLastId(form.expected_payments);
    const mountRange = Number(state.mountRange) ?? 1;

    if (mountRange) {
      if (isNotDate()) {
        for (let i = 1; i <= Number(count); i++) {
          dispatch(
            setAppendOneElement({
              date: dayjs(date)
                .add((i - 1) * mountRange, "month")
                .format(dayjsFormats.DATE),
              amount: parseLocaledString(amount),
              id: lastId + i
            })
          );
        }

        onCancel();
      } else {
        message.warning("Ushbu sanada jadvalga to'lov kiritilgan !");
      }
    } else {
      message.warning("Jadvalda vaqt oralig'i kiritilmagan !");
    }
  };

  const isNotDate = (): boolean => {
    const date = formInstance.getFieldValue("date") as string;
    const count = Number(formInstance.getFieldValue("count"));
    const { mountRange, customMountRange } = state;
    let result = true;
    const allDate: string[] = [];

    for (let i = 1; i <= Number(count); i++) {
      allDate.push(
        dayjs(date)
          .add((i - 1) * (mountRange ?? customMountRange!), "month")
          .format(dayjsFormats.DATE)
      );
    }
    if (form.expected_payments) {
      const expectedPayments = form.expected_payments.map(item => item.date);

      for (const date of expectedPayments) {
        if (allDate.includes(date)) {
          result = false;
          break;
        }
      }
    }

    return result;
  };

  const onCancel = () => {
    formInstance.resetFields();
    dispatch(setVisibleAppendElement(false));
  };

  const onOk = () => {
    formInstance.submit();
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue("amount", localeFormatter(e.currentTarget.value));
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={element.visible}
      onCancel={onCancel}
      title="Jadvalga element qo’shish"
      cancelText="Bekor qilish"
      okText="Saqlash"
    >
      <Form layout="vertical" form={formInstance} onFinish={onFinish} className={styles.append_element}>
        <div className={styles.top}>
          <Item name="count" label="Soni" rules={formRules()}>
            <Input type="number" placeholder="Sonini kiriting" />
          </Item>
          <Item name="date" label="Sana" initialValue={dayjs()} rules={formRules()}>
            <DatePicker placeholder="Sanani tanlang" format={dayjsFormats.DATE} />
          </Item>
        </div>
        <Item name="amount" label="To'lov summasi" rules={formRules()}>
          <Input onChange={onChangeAmount} placeholder="Summani kiriting" />
        </Item>
      </Form>
    </Modal>
  );
};

export default AppendElementModal;
