import React, { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Dropdown, Popover } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import classNames from "classnames";
import DateStatusWithPercent from "features/app/components/date-status-with-percent/DateStatusWithPercent";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import { useChangeTableConfig } from "features/app/service/mutation";
import { paymentQueryKeys } from "features/payment/utils/constants/paymentQueryKeys";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { cx } from "modules/common";

import { Table } from "components";

import { useAppSelector } from "../../../../../../hooks/redux";
import { appReducerActions } from "../../../../../../store/reducers/appReducer";
import { chatActions } from "../../../../../../store/reducers/chatReducer";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import OrderSortBy from "../../../../../app/components/order-sort-by/OrderSortBy";
import Pagination from "../../../../../app/components/pagination/Pagination";
import { PageKeys, StatusEnums } from "../../../../../app/components/statuses/statusEnums";
import Statuses from "../../../../../app/components/statuses/Statuses";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import TemplateDownload from "../../../../../app/components/template-download/TemplateDownload";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../app/service/queries";
import { generalEditKeys } from "../../../../../app/utils/constants/generalEditData";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "../../../../../app/utils/helpers/filterColumns";
import PrinterIcon from "../../../../../counterparts/assets/icons/PrinterIcon";
import { paymentOrderTableDefaultData } from "../../../../../supply/utils/constants/paymentOrderTableDefaultData";
import { OneOrderModel } from "../../../../../warehouse/utils/models/oneOrderModel";
import { useGetOnePaymentOrder } from "../../../../service/mutation";
import { useGetPaymentsOrder } from "../../../../service/queries";
import { PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";
import OrderPrint from "../../order-print/OrderPrint";

import PartTableSettings from "./part-table-settings/PartTableSettings";

import styles from "./orderTable.module.scss";
import useDebounce from "components/use-debounce/use-debounce";

interface IPrintPartyDataModel {
  id: number;
  data: OneOrderModel[];
  oneData: PaymentOrderModel | undefined;
}

const OrderTable: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const partyActions = actions("Buyurtmalarga to'lovlar");
  const { t } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  const dispatch = useDispatch();
  const [oneOrderData, setOneOrderData] = useState<IPrintPartyDataModel>({
    id: 0,
    data: [],
    oneData: undefined
  });
  const { setVisible } = chatActions;
  const { setPartyModal } = supplyActions;
  const { setGeneralEdit } = appReducerActions;
  const { setSelectOrder, setSelectOrderItem, setOrderView } = paymentReducerActions;

  const getOnePaymentOrder = useGetOnePaymentOrder();
  const printComponentRef = useRef<HTMLDivElement | null>(null);
  const { data: paymentsOrder, isLoading } = useGetPaymentsOrder();
  const { selectOrders } = useAppSelector(state => state.paymentReducer);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.PAYMENT_ORDER_CONFIG, true);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.PAYMENT_ORDER_CONFIG,
    paymentOrderTableDefaultData
  );

  // const openPayment = (visible: boolean) => {
  //   setPayment(prev => ({ ...prev, visible }));
  // };

  const onDetailOrder = (record: PaymentOrderModel) => ({
    onClick: () => {
      dispatch(
        setOrderView({
          visible: true,
          orderId: record?.id
        })
      );
    }
  });

  const onEditParty = (record: PaymentOrderModel) => {
    dispatch(
      setPartyModal({
        visible: true,
        party_id: record?.id,
        isView: false,
        isEditing: true
      })
    );
  };

  const emptyFunc = () => {};

  const reactToPrintContent = useCallback(() => printComponentRef.current, [printComponentRef]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `P-${oneOrderData?.id}`,
    onBeforePrint: emptyFunc,
    onAfterPrint: () => {
      setOneOrderData({
        id: 0,
        data: [],
        oneData: undefined
      });
    },
    removeAfterPrint: true
  });

  const onPrintParty = (record: PaymentOrderModel) => () => {
    getOnePaymentOrder.mutateAsync(record?.id).then(data => {
      setOneOrderData({
        data,
        id: record?.id,
        oneData: record
      });

      setTimeout(() => {
        handlePrint();
      }, 500);
    });
  };

  const onOpenChat = (record: PaymentOrderModel) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: "orders",
        dataKeys: [paymentQueryKeys.PAYMENTS_ORDER_VIEW, searchParams],
        record
      })
    );
  };

  const actionItems = (record: PaymentOrderModel) => [
    {
      key: "1",
      label: <UnreadMessageAction count={record?.unread_message_count} />,
      className: styles.dropdown_item,
      onClick: () => onOpenChat(record),
      permissionKey: "chatView",
      isView: true
    },
    {
      key: "2",
      label: (
        <>
          <EditIcon />
          <span>{t("payment.Tahrirlash")}</span>
        </>
      ),
      className: styles.dropdown_item,
      onClick: () => {
        onEditParty(record);
      },
      isView:
        record?.status === StatusEnums.OPEN ||
        record?.status === StatusEnums.ORDERED ||
        record?.status === StatusEnums.PENDING,
      permissionKey: "update"
    },
    {
      key: "3",
      label: (
        <>
          <PrinterIcon />
          <span>{t("payment.Chop etish")}</span>
        </>
      ),
      className: styles.dropdown_item,
      onClick: onPrintParty(record),
      isView: true
    },
    {
      key: "4",
      label: <TemplateDownload object_id={record.id} location={CustomFieldLocationEnum.ORDER} styles={styles} />,
      isView: true,
      permissionKey: "upload"
    }
  ];

  const paymentPopoverTitle = (amount: number, debtAmount: number, currency: string) => (
    <div className={styles.popover__payment}>
      <div className={styles.popover__item}>
        <p>{t("payment.To’langan summa")}</p>
        <p>
          {(amount - debtAmount)?.toLocaleString(RU)} {currency}
        </p>
      </div>
      <div className={styles.popover__item}>
        <p>{t("payment.Qoldiq")}</p>
        <p>
          {debtAmount?.toLocaleString(RU)} {currency}
        </p>
      </div>
    </div>
  );

  const columns: ColumnsType<PaymentOrderModel> = [
    {
      title: `${t("payment.Partiya")}`,
      render: (record: PaymentOrderModel) => <span className={styles.bold}>P-{record?.id}</span>,
      width: 100,
      fixed: "left",
      key: "id",
      className: "payment-order-parts"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PAYMENT_DATE} title={t("payment.To’lov sanasi")} />,
      render: (record: PaymentOrderModel) => (
        <DateStatusWithPercent date={record.payment_date!} percent={record?.payment_percent} />
      ),
      key: "new_payment_date",
      width: 150,
      className: "payment-order-payment_date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_ORDERED_DATE} title={t("payment.Buyurtma  sanasi")} />,
      render: (record: PaymentOrderModel) => record?.ordered_date,
      key: "new_ordered_date",
      width: 150,
      className: "payment-order-ordered_date"
    },
    {
      title: (
        <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("payment.Yetkazilish  sanasi")} />
      ),
      render: (record: PaymentOrderModel) => record?.delivery_date,
      key: "delivery_date",
      width: 150,
      className: "payment-order-delivery_date"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AGENT_FULL_NAME} title={t("payment.Vositachi")} />,
      render: (record: PaymentOrderModel) => record?.agent?.full_name || "-",
      key: "agent.full_name",
      width: 250,
      className: "payment-order-agent"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PERCENT} title={t("payment.Jarayon")} />,
      render: (record: PaymentOrderModel) => (record?.percent ? `${record?.percent} %` : "-"),
      width: 100,
      key: "percent",
      className: "payment-order-percent"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_AMOUNT} title={t("payment.Umumiy summa")} />,
      render: (record: PaymentOrderModel) => (
        <span className={styles.bold}>
          {record?.total_amount.toLocaleString("ru")} {record?.currency?.symbol}
        </span>
      ),
      key: "amount",
      width: 300,
      className: "payment-order-amount"
    },
    {
      title: `${t("payment.Holati")}`,
      render: (record: PaymentOrderModel) => <Statuses statusKey={record?.status} pageKey={PageKeys.PARTY} />,
      width: 170,
      key: "status",
      className: "payment-order-status"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_PAYMENT_PERCENT} title={t("payment.To'lov holati")} />,
      render: (record: PaymentOrderModel) => (
        <Popover
          title={paymentPopoverTitle(record?.total_amount, record?.debt_amount, record?.currency?.symbol)}
          overlayClassName={styles.popover}
        >
          <span>{record?.payment_percent} %</span>
        </Popover>
      ),
      width: 150,
      key: "payment_percent",
      className: "payment-order-payment_percent"
    },
    {
      title: `${t("payment.Xodim")}`,
      render: (record: PaymentOrderModel) => (
        <div className={styles.avatar}>
          <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />
        </div>
      ),
      width: 100,
      key: "creator.full_name",
      className: "payment-order-creator"
    }
  ];

  const [tableColumns, setColumns] = useState<ColumnsType<PaymentOrderModel>>([]);

  const saveColumns = () => {
    const columnsToSave = tableColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.PAYMENT_ORDER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentOrderModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const rowSelection: TableRowSelection<PaymentOrderModel> | undefined = {
    type: "checkbox",
    selectedRowKeys: selectOrders?.map(item => item?.id),
    onSelect: record => {
      const isSomeParty = selectOrders?.some(item => item?.id === record?.id);

      if (!isSomeParty) {
        dispatch(setSelectOrder([...selectOrders, record]));
      } else {
        dispatch(setSelectOrder(selectOrders?.filter(item => item?.id !== record?.id)));
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        selectedRows?.forEach(item => {
          dispatch(setSelectOrderItem(item));
        });
      } else {
        dispatch(setSelectOrder([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.PAYMENT_ORDER,
          customFieldKeys: [CustomFieldLocationEnum.ORDER]
        })
      );
    }
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: <PartTableSettings classNames="order-table-config" isNeedSize />,
          render: record => (
            <Dropdown
              menu={{
                items: actionItems(record).filter(item => {
                  if (item.permissionKey) {
                    return item?.isView && partyActions[item.permissionKey];
                  }
                  return item?.isView;
                })
              }}
              trigger={["click"]}
              placement="bottom"
              dropdownRender={dropdownRender}
              overlayStyle={{ zIndex: 1000 }}
            >
              <div
                className={classNames("d_f ai_c jc_c c_p", {
                  hasBadge: !!record?.unread_message_count
                })}
                style={{ width: 24 }}
                onClick={e => e.stopPropagation()}
              >
                <DotsVerticalIcon />
              </div>
            </Dropdown>
          ),
          width: 70,
          fixed: "right",
          className: "payment-order-actions"
        }
      ]);
    }
  }, [tableConfigData, customFields, paymentsOrder?.data]);

  return (
    <div className={styles.order_table}>
      <div className={styles.top}>
        <Table<PaymentOrderModel>
          onChangeColumns={onChangeColumns}
          columns={tableColumns}
          pagination={false}
          scroll={{ y: "65vh" }}
          onRow={onDetailOrder}
          rowKey={record => record.id}
          rowClassName={cx(partyActions?.orderView ? styles.row : "pointer-events-none")}
          dataSource={paymentsOrder?.data}
          loading={{
            spinning: isLoading || getOnePaymentOrder.isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
          rowSelection={rowSelection}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: paymentsOrder?.total
          }}
        />
      </div>
      {/* <OrderPayment /> */}
      <OrderPrint ref={printComponentRef} data={oneOrderData} />
      {/* <OrderView /> */}
    </div>
  );
};

export default OrderTable;
