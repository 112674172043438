type RouteSubmodules = [
  {
    key: "Partiyalar";
    actions: [
      { view: true; key: "Ko'rish" },
      { delete: true; key: "Partiyani o'chirish" },
      {
        deleteOrders: true;
        key: "Partiya to'lovini o'chirish";
      },
      {
        orderHistory: true;
        key: "Partiya to'lovlar tarixi";
      },
      {
        edit: true;
        key: "Partiyani tahrirlash";
      }
    ];
  },
  {
    key: "Ishlar";
    actions: [{ view: true; key: "Ko'rish" }];
  },
  {
    key: "Inventar";
    actions: [{ view: true; key: "Ko'rish" }];
  },
  {
    key: "Kirim-chiqim";
    actions: [{ view: true; key: "Ko'rish" }];
  },
  {
    key: "Takliflar";
    actions: [
      {
        view: true;
        key: "Ko'rish";
      },
      {
        key: "Bekor qilish";
        rejected: true;
      },
      {
        key: "Tasdiqlash";
        recieved: true;
      },
      {
        key: "O'chirish";
        delete: true;
      },
      {
        create: true;
        key: "Taklif yaratish";
      },
      {
        edit: true;
        key: "Taklifni tahrirlash";
      },
      {
        add_resource: true;
        key: "Resurs qo'shish";
      }
    ];
  }
];

export const routeSubmodules: RouteSubmodules = [
  {
    key: "Partiyalar",
    actions: [
      { view: true, key: "Ko'rish" },
      { delete: true, key: "Partiyani o'chirish" },
      {
        deleteOrders: true,
        key: "Partiya to'lovini o'chirish"
      },
      {
        orderHistory: true,
        key: "Partiya to'lovlar tarixi"
      },
      {
        edit: true,
        key: "Partiyani tahrirlash"
      }
    ]
  },
  {
    key: "Ishlar",
    actions: [{ view: true, key: "Ko'rish" }]
  },
  {
    key: "Inventar",
    actions: [{ view: true, key: "Ko'rish" }]
  },
  {
    key: "Kirim-chiqim",
    actions: [{ view: true, key: "Ko'rish" }]
  },
  {
    key: "Takliflar",
    actions: [
      {
        view: true,
        key: "Ko'rish"
      },
      {
        key: "Bekor qilish",
        rejected: true
      },
      {
        key: "Tasdiqlash",
        recieved: true
      },
      {
        key: "O'chirish",
        delete: true
      },
      {
        create: true,
        key: "Taklif yaratish"
      },
      {
        edit: true,
        key: "Taklifni tahrirlash"
      },
      {
        add_resource: true,
        key: "Resurs qo'shish"
      }
    ]
  }
];
