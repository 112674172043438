import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Popover, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { colors } from "features/app/utils/constants/colors";
import { useTranslation } from "react-i18next";

import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "../../../../../app/components/select-not-content/SelectNotContent";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import { ProductSelectModel } from "../../../../../app/utils/models/productSelectModel";
import { TimeoutModel } from "../../../../../app/utils/models/TimeoutModel";
import { useGetProductsSelect } from "../../../../../supply/service/queries";
import { IProductList } from "../../../../utils/models/productListModel";

import styles from "./productListModalTable.module.scss";

const { Option } = Select;
const { Item, useWatch } = Form;

type Props = {
  form: FormInstance;
  index: number;
  rowId: number | string;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const ProductListSelect: React.FC<Props> = ({ form, index, rowId }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { setCreateProductModal } = supplyActions;

  const searchSelect = useWatch("select_search", form);
  const formProductSelect = useWatch("selectedProducts", form) as ProductSelectModel[];
  const products = useWatch("products", form) as IProductList[];

  const [time, setTime] = useState<TimeoutModel>();
  const { data, isLoading, fetchNextPage } = useGetProductsSelect(searchSelect);

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        form.setFieldsValue({
          select_search: e
        });
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: colors.ORANGE_600,
            color: colors.ORANGE_600
          }}
        >
          ---
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("Counterparts.Resurs nomi")}</div>
    </div>
  );

  const pagesData = () => {
    let newPagesData: ProductSelectModel[] = [];

    data?.pages?.forEach(item => {
      newPagesData = [...newPagesData, ...(item?.data ?? [])];
    });

    return newPagesData;
  };

  const productsData = () => {
    const newData: ProductSelectModel[] = [];

    [...(pagesData() ?? []), ...(formProductSelect ?? [])]?.forEach(item => {
      if (!newData?.some(value => value?.id === item.id)) {
        newData.push(item);
      }
    });

    return newData;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (e: number, option: DefaultOptionType) => {
    const unitId = option?.props?.unit_id;

    form.setFieldValue(
      "products",
      products?.map(item => {
        if (item?.rowId === rowId) return { ...item, product_id: e, unit_id: unitId };
        return item;
      })
    );
  };

  const onAddProduct = () => {
    dispatch(
      setCreateProductModal({
        visible: true,
        index,
        name: searchSelect!
      })
    );
  };

  return (
    <Item
      name={["products", index, "product_id"]}
      rules={
        !(typeof rowId === "number")
          ? [
              {
                validator(rule, value, callback) {
                  if (typeof value === "number") {
                    return Promise.resolve();
                  }
                  return Promise.reject();
                }
              }
            ]
          : []
      }
    >
      <Select
        showSearch
        suffixIcon={null}
        disabled={typeof rowId === "number"}
        onSearch={onSearch}
        onChange={onChangeProduct}
        popupMatchSelectWidth={false}
        onPopupScroll={onPopupScroll}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        notFoundContent={
          <Spin spinning={isLoading && searchSelect} indicator={LoadingIndicator}>
            <SelectNotContent title={t("Counterparts.Mahsulot")} action={onAddProduct} />
          </Spin>
        }
      >
        {productsData()?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language],
              unit_id: item?.unit?.id
            }}
          >
            <div className={styles.product}>
              <div className="resource">
                <div
                  style={{
                    borderColor: item?.resource?.color ?? colors.ORANGE_600,
                    color: item?.resource?.color ?? colors.ORANGE_600
                  }}
                >
                  {item.resource?.symbol[i18n.language]}
                </div>
              </div>
              <div className={styles.product__name}>
                {item.name[i18n.language]?.length > 18 ? (
                  <Popover title={item.name[i18n.language]} zIndex={9999999}>
                    {sliceText(item.name[i18n.language], 18)}
                  </Popover>
                ) : (
                  sliceText(item.name[i18n.language], 18)
                )}
              </div>
            </div>
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default ProductListSelect;
