import { RU } from "../../../../../../app/utils/constants/languages";
import { diffFactAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateSectionModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverTotalAmount from "../../diff-popover/DiffPopoverTotalAmount";

import styles from "../section.module.scss";

type Props = {
  section: DynamicEstimateSectionModel;
  getPopoverContainer?: () => HTMLElement;
};

const SpendTotalAmount = ({ section, getPopoverContainer }: Props) => {
  return (
    <>
      {diffFactAmount(section?.total_amount, section?.spend_total_amount) !== 0 ? (
        <DiffPopoverTotalAmount
          record={section}
          classNames={styles.amount}
          isFeature={false}
          getPopoverContainer={getPopoverContainer}
        />
      ) : (
        <p className={styles.amount}>{section?.total_amount?.toLocaleString(RU) ?? 0}</p>
      )}
    </>
  );
};

export default SpendTotalAmount;
