import React from "react";
import { Link } from "react-router-dom";
import useBreadcrumbs, { BreadcrumbMatch } from "use-react-router-breadcrumbs";

import BreadcrumbSeparatorIcon from "../../../assets/icons/BreadcrumbSeparatorIcon";

import { breadcrumbIcons } from "./breadcrumbIcons";
import { crumbRoutes } from "./CrumbRoutes";

import styles from "./breadcrumb.module.scss";
import { useQueryParams } from "hooks/useQueryParams";

const Breadcrumbs: React.FC = () => {
  const { searchParams } = useQueryParams();
  const breadcrumbs = useBreadcrumbs(crumbRoutes());

  const toPath = (match: BreadcrumbMatch<string>) => {
    const propsPathName = match.route?.props?.path;

    if (searchParams && searchParams.length > 0) {
      if (propsPathName) {
        return `${propsPathName}${searchParams}`;
      } else {
        return `${match.pathname}${searchParams}`;
      }
    }

    return propsPathName || match.pathname;
  };

  const returnIcon = (match: BreadcrumbMatch<string>) => {
    const { pathname } = match;

    return breadcrumbIcons[pathname];
  };

  const breadcrumbContent = breadcrumbs.map(({ match, breadcrumb }, index) => {
    const active = index === breadcrumbs?.length - 1;

    if (match.pathname !== "/")
      // return (
      // <div className={styles.item} key={index}>
      //   <HomeLineIcon />
      //   <BreadcrumbSeparatorIcon />
      // </div>
      // );

      return (
        <div className={styles.item} key={index}>
          {returnIcon(match)}
          <BreadcrumbSeparatorIcon />
          {active ? (
            <div className={styles.active}>{breadcrumb}</div>
          ) : (
            <>
              <Link key={match.pathname} to={toPath(match)} className={styles.link}>
                {breadcrumb}
              </Link>
              {/* <BreadcrumbSeparatorIcon /> */}
            </>
          )}
        </div>
      );
    return null;
  });

  return <nav className={styles.breadcrumb}>{breadcrumbContent}</nav>;
};

export default Breadcrumbs;
