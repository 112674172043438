import { Button, Modal, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import * as XLSX from "xlsx";

import { useAppDispatch } from "../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import CloseIcon from "../../../../../../app/assets/icons/CloseIcon";
import DownloadIcon from "../../../../../../app/assets/icons/DownloadIcon";
import UploadIcon from "../../../../../../app/assets/icons/UploadIcon";

import styles from "./priceUploadModal.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const PriceUploadModal = ({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { setPricesEstimate } = projectsReducerActions;

  const onCancel = () => {
    setOpen(false);
  };

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj;
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });

      const result: { sheetName: string; data: unknown[] }[] = [];
      workbook.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        result.push({ sheetName, data });
      });

      const rows: any = result[0].data;

      const pricesByCode: Record<string, number> = {};
      const pricesByName: Record<string, number> = {};

      for (let i = 0; i < rows.length; i += 1) {
        if (rows[i].length === 7) {
          pricesByCode[rows[i][1]] = +rows[i][5];
          pricesByName[rows[i][2]] = +rows[i][5];
        }
      }

      dispatch(setPricesEstimate({ code: pricesByCode, name: pricesByName }));
    };

    reader.readAsBinaryString(file as any);
    if (reader.DONE) {
      onCancel();
    }
  };

  const modalFooter = (
    <Button href="https://developer.uyqur.uz/public/upload/import/Форма%20№%206a.xls">
      <DownloadIcon /> Namuna
    </Button>
  );

  return (
    <Modal
      title="Fayl yuklash"
      centered
      width={548}
      closeIcon={<CloseIcon />}
      onCancel={onCancel}
      open={open}
      footer={modalFooter}
    >
      <div className={styles.content}>
        <Upload
          onChange={onChange}
          customRequest={() => null}
          showUploadList={false}
        >
          <div className={styles.upload}>
            <UploadIcon />
            <p>
              Loyiha <span>"Форма №6a"</span> faylini yuklash uchun bosing yoki
              faylni shu yerga tashlang (.xls, .xlsx)
            </p>
          </div>
        </Upload>
      </div>
    </Modal>
  );
};

export default PriceUploadModal;
