import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

export const warehouseProductsTableDefaultDataFunc = (lang: string): UserTableDataModel => {
  const warehouseProductsTableDefaultData: UserTableDataModel = {
    column: [
      {
        checked: true,
        id: "resource_name_and_type",
        name: "resource_name_and_type",
        title: "Resurs nomi va turi"
      },
      {
        checked: true,
        id: "total_quantity",
        name: "total_quantity",
        title: "Soni"
      },
      {
        checked: true,
        id: `unit.name.${lang}`,
        name: `unit.name.${lang}`,
        title: "Birligi"
      }
    ],
    size: 10
  };

  return warehouseProductsTableDefaultData;
};
