import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const warehouseDefectTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "victims",
      name: "victims",
      title: "Guvohlar",
      checked: true
    },
    {
      id: "unit",
      name: "unit",
      title: "Birligi",
      checked: true
    },
    {
      id: "quantity",
      name: "quantity",
      title: "Soni",
      checked: true
    },
    {
      id: "warehouse",
      name: "warehouse",
      title: "Omborxona",
      checked: true
    },
    {
      id: "project",
      name: "project",
      title: "Loyiha",
      checked: true
    },
    {
      id: "type",
      name: "type",
      title: "Yaroqsizlik turi",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Status",
      checked: true
    }
  ],
  size: 10,
  width_data: []
};
