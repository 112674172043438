import React, { Dispatch, SetStateAction } from "react";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import Pagination from "features/app/components/pagination/Pagination";
import { PageKeys, StatusEnums } from "features/app/components/statuses/statusEnums";
import Statuses from "features/app/components/statuses/Statuses";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { PaginationType } from "features/app/utils/models/PaginationType";

import { ActiveCompanyPersonView } from "modules/dashboard";

import { LoadingIndicator } from "components";
import { useTranslation } from "react-i18next";

type Props = {
  isLoading: boolean;
  data: PaginationType<ActiveCompanyPersonView[]>;
  setPage: Dispatch<SetStateAction<number>>;
};

const ActiveCompanyPersonTable: React.FC<Props> = ({ data, isLoading, setPage }) => {
  const { t } = useTranslation();
  const columns: ColumnsType<ActiveCompanyPersonView> = [
    {
      title: `${t("dashboard.Partiya")}`,
      render: (record: ActiveCompanyPersonView) => (
        <span className="text-sm font-medium text-gray-600">P - {record?.id}</span>
      )
    },
    {
      title: `${t("dashboard.Summa")}`,
      render: (record: ActiveCompanyPersonView) => (
        <div className="flex items-center justify-center gap-2 text-sm font-medium text-gray-600">
          {record?.calculated_amount}
          <span className="text-sm font-medium text-gray-400">{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: `${t("dashboard.Holati")}`,
      render: (record: ActiveCompanyPersonView) => (
        <Statuses statusKey={record?.status as StatusEnums} pageKey={PageKeys.ORDER} />
      )
    },
    {
      title: `${t("dashboard.Sana")}`,
      render: (record: ActiveCompanyPersonView) => (
        <div className="text-sm font-medium text-gray-600">{record?.created_at}</div>
      )
    }
  ];

  const footer = () => (
    <div>
      <Pagination
        paginationProps={{
          total: data?.total,
          current: data?.current_page,
          onChange: (page: number) => setPage(page)
        }}
      />
    </div>
  );

  return (
    <Table
      dataSource={data?.data || []}
      columns={columns}
      className="footer_table"
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
      footer={data?.total > 10 ? footer : () => false}
      pagination={false}
    />
  );
};

export default ActiveCompanyPersonTable;
