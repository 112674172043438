export const SuspendedIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_650_22447)">
        <path d="M6.33203 10V6.00004M9.66536 10V6.00004M14.6654 8.00004C14.6654 11.6819 11.6806 14.6667 7.9987 14.6667C4.3168 14.6667 1.33203 11.6819 1.33203 8.00004C1.33203 4.31814 4.3168 1.33337 7.9987 1.33337C11.6806 1.33337 14.6654 4.31814 14.6654 8.00004Z" stroke="#667085" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_650_22447">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
}