import { projectStatuses } from "../../../../../../../../../utils/enums/projectStatuses";
import { GanttSection } from "../../../utils/models/GanttSection";
import GanttTableTask from "./GanttTableTask";

type Props = {
  sectionId: number;
  sectionPlace: number;
  projectStatus?: projectStatuses;
  dataTasks?: GanttSection[];
};

const GanttTableTasks = ({
  sectionId,
  sectionPlace,
  dataTasks,
  projectStatus,
}: Props) => {
  return (
    <>
      {dataTasks &&
        dataTasks
          ?.sort((a, b) => a.place - b.place)
          ?.map((item) => (
            <GanttTableTask
              key={item.id}
              {...item}
              sectionId={sectionId}
              sectionPlace={sectionPlace}
              projectStatus={projectStatus}
              elementType="task"
            />
          ))}
    </>
  );
};

export default GanttTableTasks;
