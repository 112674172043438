import React from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import DatepickerSuffixIcon from "../../../../app/assets/icons/DatepickerSuffixIcon";
import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";

type Props = {
  item: CustomFieldSelectModel;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingFieldDate: React.FC<Props> = ({ item: propsField, setData }) => {
  const onChange = (e: Dayjs) => {
    setData(
      (old) =>
        old?.map((item) => ({
          // @ts-ignore
          ...item,
          // @ts-ignore
          custom_field_values: item?.custom_field_values?.map((field) => ({
            ...field,
            value:
              field?.custom_field_id === propsField?.id
                ? dayjs(e, dayjsFormats.DATE).format(dayjsFormats.DATE)
                : field?.value,
          })),
        }))
    );
  };

  return (
    <DatePicker
      placeholder="Sana"
      format={dayjsFormats.DATE}
      suffixIcon={<DatepickerSuffixIcon />}
      onChange={onChange}
    />
  );
};

export default GeneralEditingFieldDate;
