import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetInventoryExpense } from "../../../../service/queries";
import { InventoryExpenseModel } from "../../../../utils/models/inventoryExpenseModel";

import styles from "./expenses.module.scss";

const Expenses = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);
  const { data: inventoryExpenses, isLoading } = useGetInventoryExpense(page, true);

  const columns: ColumnsType<InventoryExpenseModel> = [
    {
      title: `${t("Monitoring.To’lov sanasi")}`,
      dataIndex: "date",
    },
    {
      title: `${t("Inventar.Xarajat summasi")}`,
      render: (record: InventoryExpenseModel) => (
        <span className={styles.bold}>
          {record?.amount?.toLocaleString(RU) ?? 0} {record?.currency?.symbol}
        </span>
      ),
    },
    {
      title: `${t("Inventar.Xarajat turi")}`,
      render: (record: InventoryExpenseModel) => (
        <div
          style={{ backgroundColor: `${record?.payment_type?.color}30` }}
          className={styles.payment}
        >
          <p
            style={{
              color: record?.payment_type?.color,
            }}
            className={styles.payment__paragraph}
          >
            {record?.payment_type?.name}
          </p>
        </div>
      ),
    },
    {
      title: `${t("Inventar.Kassa va kassir")}`,
      render: (record: InventoryExpenseModel) => (
        <div className={styles.creator}>
          <p>{record?.cash?.name}</p>
          <p>{record?.creator?.full_name}</p>
        </div>
      ),
    },
  ];

  const rowKey = (record: InventoryExpenseModel) => {
    return record.id;
  };

  return (
    <div className={styles.expenses}>
      <Table<InventoryExpenseModel>
        dataSource={inventoryExpenses?.data}
        columns={columns}
        pagination={false}
        rowKey={rowKey}
        locale={{
          emptyText: <TableEmpty />,
        }}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator,
        }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            pageSize: 7,
            total: inventoryExpenses?.total,
            current: inventoryExpenses?.current_page,
            onChange: (page) => {
              setPage(page);
            },
          }}
        />
      </div>
    </div>
  );
};

export default Expenses;
