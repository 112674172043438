import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { dayjsLocales } from "features/app/utils/constants/dayjsLocales";
import { localeUz } from "features/app/utils/constants/localeUz";

export function init() {
  dayjs.locale(dayjsLocales.EN_GB);
  dayjs.extend(updateLocale);
  dayjs.updateLocale(dayjsLocales.EN_GB, localeUz);
}
