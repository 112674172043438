import React from "react";
import { Popover } from "antd";

import styles from "./popoverShortName.module.scss";

type Props = {
  title?: string;
  length?: number;
  getPopoverContainer?: () => HTMLElement;
};

const PopoverShortName: React.FC<Props> = ({ title, length = 10, getPopoverContainer }) => {
  return (
    <React.Fragment>
      {title && title.length > length ? (
        <Popover getPopupContainer={getPopoverContainer} title={title} overlayClassName={styles.popover}>
          {title.substring(0, length)}...
        </Popover>
      ) : (
        title
      )}
    </React.Fragment>
  );
};

export default PopoverShortName;
