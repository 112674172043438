import React from "react";
import { CalendarDatum, ColorScale, ResponsiveTimeRange, TimeRangeDayData } from "@nivo/calendar";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { colors, dayjsFormats } from "modules/common";
import { Calendar, getColor, useWidget, WidgetKeys } from "modules/dashboard";

import { WidgetCustomTooltip } from "../../tooltip";
interface IProps {
  id: number;
  data: Calendar[];
  widgetKey: WidgetKeys;
  name: Record<string, string>;
}

const YearlyCalendar: React.FC<IProps> = ({ data, id, widgetKey, name }) => {
  const {
    methods: { setWidgetDrawer }
  } = useWidget();

  const { i18n, t } = useTranslation();

  const timeRangeData: CalendarDatum[] = data?.map(el => ({
    value: el.count,
    day: dayjs(el.date, dayjsFormats.DATE).format(dayjsFormats.KEBAB_DATE)
  }));

  const stopPropagation = (e: React.MouseEvent<SVGRectElement | HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenViewDrawer = ({ day, e }: { day: TimeRangeDayData; e: React.MouseEvent<SVGRectElement, MouseEvent> }) => {
    stopPropagation(e);

    setWidgetDrawer({
      id,
      visible: true,
      key: widgetKey,
      props: { date: dayjs(day.day).format(dayjsFormats.DATE) },
      title: (
        <div className="flex gap-1">
          <span className="text-lg font-semibold text-gray-900">{name[i18n.language]}</span>
          <span className="text-lg font-semibold text-gray-400">({dayjs(day.day).format(dayjsFormats.DATE)})</span>
        </div>
      )
    });
  };

  const customTooltip = (label: string, value: string, color: string) => (
    <div className="flex flex-col gap-4">
      <span className="text-sm font-normal text-gray-300">
        {dayjs(label, dayjsFormats.KEBAB_DATE).format(dayjsFormats.DATE_WITH_MONTH_NAME)}
      </span>
      <div
        style={{ borderColor: color }}
        className="flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2"
      >
        <span className="text-sm font-semibold text-white">
          {t("dashboard.Progress")}: {value}
        </span>
      </div>
    </div>
  );
  const colorScale = (value: number | number[] | undefined) => {
    if (typeof value === "number") {
      return getColor(value);
    }
    return colors.PRIMARY_600;
  };

  return (
    <div className="flex h-full w-full" onMouseDown={stopPropagation} onTouchStart={stopPropagation as never}>
      <ResponsiveTimeRange
        data={timeRangeData}
        from={timeRangeData[0].day}
        to={timeRangeData[timeRangeData.length - 1].day}
        colors={[
          colors.GRAY_100,
          colors.PRIMARY_200,
          colors.PRIMARY_300,
          colors.PRIMARY_400,
          colors.PRIMARY,
          colors.PRIMARY_600
        ]}
        emptyColor={colors.GRAY_100}
        dayRadius={3}
        colorScale={colorScale as unknown as ColorScale}
        minValue={0}
        maxValue="auto"
        margin={{
          top: 70
        }}
        onClick={(day, e) => onOpenViewDrawer({ day, e })}
        tooltip={value => <WidgetCustomTooltip children={customTooltip(value.day, value.value, value.color)} />}
        monthLegend={(year, month, date) => dayjs(date).format(dayjsFormats.MONTH_NAME)}
        weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
        firstWeekday="monday"
      />
    </div>
  );
};

export default YearlyCalendar;
