import React from "react";
import { useTranslation } from "react-i18next";

import FolderPlusIcon from "../../../../../../settings/assets/icons/FolderPlusIcon";

import styles from "./counterpartsNewFolder.module.scss";

interface Props {
  setIsCreating: React.Dispatch<React.SetStateAction<boolean>>;
}

const CounterpartsNewFolder: React.FC<Props> = ({ setIsCreating }) => {
  const { t } = useTranslation()
  const onOpenNewFolder = () => {
    setIsCreating(true);
  };

  return (
    <div className={styles.new_folder} onClick={onOpenNewFolder}>
      <FolderPlusIcon />
      <span>{t("Counterparts.Papka qo'shish")}</span>
    </div>
  );
};

export default CounterpartsNewFolder;
