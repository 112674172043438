import React from "react";
import { Form, FormInstance, Input } from "antd";

import { formRules } from "../../../../../app/utils/constants/formRules";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { useTranslation } from "react-i18next";

type Props = {
  index: number;
  form: FormInstance;
  rowId: number;
};

const { Item } = Form;

const ProductListAmount: React.FC<Props> = ({ index, form, rowId }) => {
  const fieldName = ["products", index, "amount"];
  const { t } = useTranslation()
  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    form.setFieldValue(fieldName, localeFormatter(value));
  };

  return (
    <Item name={fieldName} rules={!(typeof rowId === "number") ? formRules() : []}>
      <Input
        step="0.01"
        disabled={typeof rowId === "number"}
        placeholder={t("Counterparts.Birlik summasi")}
        autoComplete="off"
        onChange={onChangeAmount}
      />
    </Item>
  );
};

export default ProductListAmount;
