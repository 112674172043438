import React from "react";
import { DatePicker, Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";

import DatepickerSuffixIcon from "../../../../../../../app/assets/icons/DatepickerSuffixIcon";
import UploadFiles from "../../../../../../../app/components/upload-files/upload-files";
import { dayjsFormats } from "../../../../../../../app/utils/constants/dayjsFormats";

const { Item } = Form;
const { TextArea } = Input;

type Props = {
  form: FormInstance;
};

const { useWatch } = Form;

const Others: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const fileIds = useWatch("file_ids", form);

  console.log(fileIds);
  return (
    <div className="flex flex-col">
      <Item name="date" label={t("payment.Sana")}>
        <DatePicker placeholder={t("payment.Sana")} format={dayjsFormats.DATE} suffixIcon={<DatepickerSuffixIcon />} />
      </Item>
      <Item name="description" label={t("payment.Izoh")}>
        <TextArea placeholder={t("payment.Izoh")} />
      </Item>
      <UploadFiles
        isArray
        form={form}
        name="file_ids"
        accept=".jpg, .jpeg, .png, .svg, .cv, .docx, .doc, .pdf, .xls, .xlsx, .ppt, .pptx"
      />
    </div>
  );
};

export default Others;
