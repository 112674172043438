import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import $api from "../../../features/app/utils/helpers/axiosInstance";
import { dashboardQueryKeys } from "../query-keys";
import { Widget } from "../schema";

interface Query {
  companyWidgets: Widget[];
}

interface Params extends Pick<UseQueryOptions, "enabled"> {}

export function useCompanyWidgetsSelect({ enabled = false }: Params) {
  const initialValue: Query = {
    companyWidgets: []
  };

  const { data = initialValue, ...arg } = useQuery(
    [dashboardQueryKeys.COMPANY_WIDGETS_SELECT],
    async () => {
      const { data } = await $api.get("company-widget/select");

      // const companyWidgets: Widget[] = data?.data?.map((item: Widget) => widgetSchema.parse(item));

      return { companyWidgets: data?.data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
