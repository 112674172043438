import { useMemo } from "react";
import { Popover, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../../app/utils/constants/languages";
import { WorkProductTypeEnum } from "../../../../../../utils/enums/workProductTypeEnum";
import { WorkProgressModel } from "../../../../../../utils/models/WorkProgressModel";

import ExpandedRow from "./ExpandedRow";

import styles from "../workModalTabs.module.scss";

type Props = {
  resources?: WorkProgressModel[];
  type: WorkProductTypeEnum;
  isLoading: boolean;
  progressId?: number;
  taskId?: number;
};

const ResourceTable = ({ resources, type, isLoading, progressId, taskId }: Props) => {
  const { i18n } = useTranslation();
  const { currencies } = useAuth();
  const baseCurrency = currencies?.find(item => item.type === "base");

  const filteredData = useMemo(() => {
    const result = resources?.filter(resource => resource?.product?.resource?.type === type);

    return result;
  }, [resources, type]);

  const columns: ColumnsType<WorkProgressModel> = [
    {
      title: "Resurs turi va nomi",
      render: (record: WorkProgressModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language]?.length > 50 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 50)}...</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      ),
      width: "40%"
    },
    {
      title: "Hajmi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.total_quantity?.toLocaleString(RU) ?? 0} {record?.unit?.symbol[i18n.language]}
        </>
      ),
      width: "20%"
    },
    {
      title: "Birlik summasi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.amount?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
        </>
      ),
      width: "20%"
    },
    {
      title: "Umumiy summasi",
      render: (record: WorkProgressModel) => (
        <>
          {record?.total_amount?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
        </>
      ),
      width: "20%"
    }
  ];

  const rowKey = (record: WorkProgressModel) => {
    return record.id;
  };

  return (
    <Table<WorkProgressModel>
      dataSource={filteredData}
      columns={columns}
      pagination={false}
      rowKey={rowKey}
      rowClassName={() => styles.row}
      expandable={{
        expandedRowRender: (record, _, __, expanded) => (
          <ExpandedRow
            id={record?.id}
            expanded={expanded}
            type={type}
            progressId={progressId}
            taskId={taskId}
            key={record?.id}
          />
        )
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
      loading={{ spinning: isLoading, indicator: LoadingIndicator }}
      className={styles.table}
    />
  );
};

export default ResourceTable;
