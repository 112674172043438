import { useLocation, useParams } from "react-router-dom";
import { Segmented, Spin } from "antd";
import { SegmentedLabeledOption, SegmentedValue } from "antd/es/segmented";
import NotData from "features/app/components/not-data/NotData";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import ViewTypeCardIcon from "../../assets/icons/ViewTypeCardIcon";
import ViewTypeListIcon from "../../assets/icons/ViewTypeListIcon";
import { ProjectCreateCard } from "../../components/create-project-card/CreateProjectCard";
import EvictionModal from "../../components/eviction-modal/EvictionModal";
import { ProjectCard } from "../../components/project-card/ProjectCard";
import { ProjectModal } from "../../components/project-modal/ProjectModal";
import { ProjectProgress } from "../../components/project-progress/ProjectProgress";
import ProjectsTable from "../../components/projects-table/ProjectsTable";
import { useGetDetailedProjectFolder } from "../../service/queries";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { viewTypes } from "../../utils/constants/viewTypes";

import styles from "./detailedProjectFolder.module.scss";
import { useTranslation } from "react-i18next";
import ProjectReportModal from "features/projects/components/project-report-modal/project-report-modal";
import ProductSpentWidgetDrawer from "features/projects/components/product-spent-widget-drawer/product-spent-widget-drawer";
import ProductSpentWidgetDetailDrawer from "features/projects/components/product-spent-widget-detail-drawer/product-spent-widget-detail-drawer";
import React from "react";

export const DetailedProjectFolder = () => {
  const params = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const dispatch = useAppDispatch();
  const { id, name } = parseParamsId(params?.folderId);
  const { viewType } = useAppSelector(state => state.projectsReducer);
  const { data, isLoading } = useGetDetailedProjectFolder(id, !isProjectsPage, viewType);
  const { setViewType } = projectsReducerActions;
  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const projectActions = actions("Loyihalar");

  const viewTypeItems: (SegmentedValue | SegmentedLabeledOption)[] = [
    {
      value: viewTypes.CARD,
      label: <ViewTypeCardIcon />
    },
    {
      value: viewTypes.LIST,
      label: <ViewTypeListIcon />
    }
  ];

  const onChangeViewType = (value: SegmentedValue) => {
    // append(queryParamsKeys.VIEW_TYPE, value);
    dispatch(setViewType(value as string));
  };

  const viewProjectContent = {
    [viewTypes.CARD]: (
      <div>
        <div className={styles.projects__body}>
          {!isEmptyArr(data?.projects) && data?.projects.map(item => <ProjectCard key={item.id} {...item} />)}
          {projectActions.create && isProjectsPage && <ProjectCreateCard />}
        </div>
        {isEmptyArr(data?.projects) && !isProjectsPage && (
          <div className="flex h-[12.5rem] w-full items-center justify-center">
            <NotData
              containerClassName="py-2.5 px-0"
              name={{ text: `${t("project.Loyihalar katalogi mavjud emas")}` }}
              text={{
                text: `${t("project.Ushbu loyihalar katologini qo’shish orqali loyihalaringizni boshqaring")}`
              }}
            />
          </div>
        )}
      </div>
    ),
    [viewTypes.LIST]: <ProjectsTable data={data?.projects} isLoading={isLoading} />
  };

  return (
    <div className={styles.page}>
      <div className={styles.page__header}>
        <div className={styles.name}>
          <h4>{name}</h4>
          <p>
            {data?.projects_count} {t("project.loyiha")}
          </p>
        </div>

        <ProjectProgress
          percent={data?.percent}
          // total={data?.total_amount ?? 0}
          // spend={data?.spend_amount ?? 0}
          // prediction={data?.prediction_amount ?? 0}
        />
      </div>
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.projects}>
          <div className={styles.projects__title}>
            <p>{t("project.Loyihalar")}</p>
            <Segmented options={viewTypeItems} onChange={onChangeViewType} value={viewType} />
          </div>
          <div className={styles.page__body}>{viewProjectContent[viewType ?? viewTypes.CARD]}</div>
        </div>
      </Spin>
      <ProjectModal />
      <EvictionModal />
      <ProjectReportModal />
      <ProductSpentWidgetDrawer />
      <ProductSpentWidgetDetailDrawer />
    </div>
  );
};
