import { useMutation, useQueryClient } from "@tanstack/react-query";

import { logout as baseLogout, useDispatch } from "store";

import { http } from "services";

export const useLogout = () => {
  const dispatch = useDispatch();
  const client = useQueryClient();

  const { mutateAsync, ...args } = useMutation({
    mutationFn: async () => {
      await http.request.get("auth/logout");
    }
  });

  const logout = async () => {
    dispatch(baseLogout());
    client.clear();
    await mutateAsync();
  };

  return { logout, ...args };
};
