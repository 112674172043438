import React, { useEffect, useMemo, useState } from "react";
import { Link, matchPath, Outlet, useLocation } from "react-router-dom";
import { Dropdown, Tooltip } from "antd";
import { cx } from "features/app/utils/helpers/cx";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { projectsPaths } from "../../../../routes/projects/projectsPaths";
import { rootPaths } from "../../../../routes/root/rootPaths";
import LogOutIcon from "../../../inventory/assets/icons/LogOutIcon";
import UyqurIcon from "../../assets/icons/UyqurIcon";
import UyqurTextIcon from "../../assets/icons/UyqurTextIcon";
import { LEFT } from "../../utils/constants/placements";
import { getFirstLetter } from "../../utils/helpers/getFirstLetter";
import { RootLinkModel } from "../../utils/models/RootLinkModel";
import GlobalChat from "../chat/global-chat/GlobalChat";
import Notification from "../notification/Notification";

import Breadcrump from "./breadcrump/Breadcrumbs";
import RootLinks from "./RootLinks";

import cls from "./mainLayout.module.scss";

const MainLayout: React.FC = () => {
  const { t } = useTranslation();
  const { methods, user } = useAuth();

  const rootLinks = RootLinks();
  const location = useLocation();
  const [isClosed, setIsClosed] = useState<boolean>(false);

  const isNeedLayout = useMemo(
    () =>
      !matchPath(
        {
          path: `${rootPaths.PROJECTS}${projectsPaths.DETAILED_PROJECT.INDEX}`,
          end: false
        },
        location.pathname
      ),
    [location.pathname]
  );

  useEffect(() => {
    setIsClosed(location.pathname.includes(rootPaths.SETTINGS));
  }, [location.pathname]);

  // eslint-disable-next-line consistent-return
  const isActive = (path: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, firstPathname] = location.pathname.split("/");

    if (firstPathname) {
      const [first, second] = firstPathname.split("-");

      if (path.includes(second || first)) return cls.active;
    } else return "";
  };

  const links = (data: RootLinkModel[]) =>
    data.map(item => (
      <React.Fragment key={item.name}>
        {isClosed ? (
          <Tooltip title={isClosed && t(`MainLayoutLinks.${item.name}`)} placement={LEFT}>
            <Link className={`${isActive(item.link)} ${cls.item}`} to={item.link}>
              {item.icon}
              {!isClosed && <span>{t(`MainLayoutLinks.${item.name}`)}</span>}
            </Link>
          </Tooltip>
        ) : (
          <Link className={`${isActive(item.link)} ${cls.item}`} to={item.link}>
            {item.icon}
            {!isClosed && <span>{t(`MainLayoutLinks.${item.name}`)}</span>}
          </Link>
        )}
      </React.Fragment>
    ));

  const customDropdown = () => (
    <div className={cls.dropdown}>
      <div className={cls.user}>
        {user?.image ? (
          <img src={user?.image} alt={user?.full_name} />
        ) : (
          <div className={cls.username}>{getFirstLetter(user?.full_name || "")}</div>
        )}
        <div className={cls.user__info}>
          <p>{user?.full_name}</p>
          <p>{user?.role_name}</p>
        </div>
      </div>
      <div onClick={methods.logout} className={cls.actions}>
        <LogOutIcon />
        {t("login.Chiqish")}
      </div>
    </div>
  );

  return isNeedLayout ? (
    <div className={cx(cls.main, isClosed && cls.closed)}>
      <div className={cls.left}>
        <div className={cls.top}>
          <div className={cls.logo}>
            <div>
              <UyqurIcon />
            </div>
            <UyqurTextIcon />
          </div>
          <div className={cls.links}>{links(rootLinks.top)}</div>
        </div>
        <div className={cls.bottom}>
          <div className={cls.links}>{links(rootLinks.bottom)}</div>
        </div>
      </div>
      <div className={cls.right}>
        <div className={cx(cls.top)}>
          <Breadcrump />
          <div className={cls.top__left}>
            <GlobalChat />
            <div className={cls.top__left_line} />
            <Notification />
            <div className={cls.top__left_profile} />
            <Dropdown dropdownRender={customDropdown} trigger={["click"]}>
              {user?.image ? (
                <img src={user.image} alt="" />
              ) : (
                <div className={cls.username}>{getFirstLetter(user?.full_name || "")}</div>
              )}
            </Dropdown>
          </div>
        </div>
        <div className={cls.bottom}>
          <Outlet />
        </div>
      </div>
    </div>
  ) : (
    <Outlet />
  );
};

export default MainLayout;
