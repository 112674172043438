import React from "react";
import { FormInstance } from "antd";

import DateCustomField from "../../../../app/components/custom-fields/DateCustomField";
import SelectCustomField from "../../../../app/components/custom-fields/SelectCustomField";
import TextCustomField from "../../../../app/components/custom-fields/TextCustomField";
import { CustomFieldTypesEnum } from "../../../../app/utils/enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";
import { CustomFieldValueModel } from "../../../../app/utils/models/customFIeldValueModel";
import { CustomFieldOption } from "../../../../settings/utils/models/custom-field/customFieldModel";

import CheckboxCustomField from "./checkbox-custom-field/CheckboxCustomField";
import FileCustomField from "./file-custom-field/FileCustomField";

export interface CustomFieldProps {
  hasValue?: boolean;
  value?: string;
  placeholder?: string;
  options?: CustomFieldOption[];
  onChange?: (value: string | number | null | boolean | undefined) => void;
  suffixIcon?: React.ReactNode;
  disabled?: boolean;
  onBlur?: () => void;
  fileName?: string;
  field?: CustomFieldSelectModel;
  name?: unknown[];
  form?: FormInstance;
  labelValue?: number[];
  defaultValues?: CustomFieldValueModel[];
}

export const EstimateCustomFieldsContent: {
  [key in CustomFieldTypesEnum]?: (props?: CustomFieldProps) => JSX.Element;
} = {
  select: props => <SelectCustomField {...props} />,
  text: props => <TextCustomField {...props} />,
  date: props => <DateCustomField {...props} />,
  file: props => <FileCustomField {...props} />,
  checkbox: props => <CheckboxCustomField {...props} />
};
