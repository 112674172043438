import { PaginationType } from "features/app/utils/models/PaginationType";
import { TableDataTypeWidgetKey, Widget } from "modules/dashboard";
import BaseTable from "./content";
import TableContentModal from "./modal/table-content-modal";

type Props<TKey extends keyof TableDataTypeWidgetKey> = {
  data: PaginationType<TableDataTypeWidgetKey[TKey][]>;
  widget: Widget;
};

const Table = <TKey extends keyof TableDataTypeWidgetKey>(props: Props<TKey>) => (
  <div>
    <BaseTable<TKey> {...props} />
    <TableContentModal />
  </div>
);

export default Table;
