import { RU } from "../../../../utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const PaymentExpenseRejected = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/payment", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        <span className={styles.name}>{record?.user?.full_name}</span>{" "}
        {record?.payment_expense?.amount?.toLocaleString(RU)}{" "}
        {record?.payment_expense?.currency?.symbol}{" "}
        {record?.payment_expense?.financial?.name} uchun to'lovni bekor qildi.
      </p>
    </div>
  );
};

export default PaymentExpenseRejected;
