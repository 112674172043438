import React from "react";

const AddressIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99992 10.8333C11.3806 10.8333 12.4999 9.71405 12.4999 8.33334C12.4999 6.95263 11.3806 5.83334 9.99992 5.83334C8.61921 5.83334 7.49992 6.95263 7.49992 8.33334C7.49992 9.71405 8.61921 10.8333 9.99992 10.8333Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99992 18.3333C13.3333 15 16.6666 12.0152 16.6666 8.33334C16.6666 4.65144 13.6818 1.66667 9.99992 1.66667C6.31802 1.66667 3.33325 4.65144 3.33325 8.33334C3.33325 12.0152 6.66659 15 9.99992 18.3333Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddressIcon;
