import { Popover, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../../../app/utils/constants/languages";
import { useGetWorkProgressResource } from "../../../../../../service/queries";
import { WorkProductTypeEnum } from "../../../../../../utils/enums/workProductTypeEnum";
import { WorkProgressResourceModel } from "../../../../../../utils/models/workProgressResourceModel";

import styles from "../workModalTabs.module.scss";

type Props = {
  id: number;
  expanded: boolean;
  type: WorkProductTypeEnum;
  progressId?: number;
  taskId?: number;
};

const ExpandedRow = ({ id, expanded, type, progressId, taskId }: Props) => {
  const { i18n } = useTranslation();
  const { currencies } = useAuth();
  const baseCurrency = currencies?.find(item => item.type === "base");
  const { data: resources, isLoading } = useGetWorkProgressResource(expanded, id, progressId, taskId);

  const setName = (record: WorkProgressResourceModel) => {
    if (type === WorkProductTypeEnum.PRODUCT) {
      return (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product?.resource?.symbol[i18n.language]}
          </div>
          {record?.product?.name[i18n.language]?.length > 50 ? (
            <Popover title={record?.product?.name[i18n.language]} overlayClassName={styles.popover}>
              <span className={styles.bold}>{record?.product?.name[i18n.language]?.substring(0, 50)}...</span>
            </Popover>
          ) : (
            <span className={styles.bold}>{record?.product?.name[i18n.language]}</span>
          )}
        </div>
      );
    }
    if (type === WorkProductTypeEnum.PERSON) {
      return (
        <>
          {record?.user ? (
            <div className={styles.person}>
              <span className={styles.person__name}>
                {record?.user?.full_name?.split(" ")[0][0].toUpperCase()}{" "}
                {record?.user?.full_name?.split(" ")[1][0].toUpperCase()}
              </span>{" "}
              <span>{record?.user?.full_name}</span>
            </div>
          ) : (
            <div className={styles.person}>
              <span className={styles.person__name}>
                {record?.company_person?.name?.split(" ")[0][0].toUpperCase()}{" "}
                {record?.company_person?.name?.split(" ")[1][0].toUpperCase()}
              </span>{" "}
              <span>{record?.company_person?.name}</span>
            </div>
          )}
        </>
      );
    }
    return (
      <div className="resource">
        <div className={styles.inventory}>MEX</div>
        <span className={styles.bold}>{record?.inventory?.name}</span>
      </div>
    );
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.expanded}>
        {resources?.map(resource => (
          <div className={styles.resource} key={resource?.id}>
            <div className={styles.name}>{setName(resource)}</div>
            <div className={styles.quantity}>
              {resource?.total_quantity?.toLocaleString(RU) ?? 0} {resource?.unit?.symbol[i18n.language]}
            </div>
            <div className={styles.amount}>
              {(resource?.total_amount / resource?.total_quantity)?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
            </div>
            <div className={styles.total}>
              {resource?.total_amount?.toLocaleString(RU) ?? 0} {baseCurrency?.symbol}
            </div>
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default ExpandedRow;
