import React from "react";

const NotificationWorkCreatedIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 8.33464V3.0013C15 2.53459 15 2.30124 14.9092 2.12298C14.8293 1.96617 14.7018 1.83869 14.545 1.7588C14.3667 1.66797 14.1334 1.66797 13.6667 1.66797H6.33333C5.86662 1.66797 5.63327 1.66797 5.45501 1.7588C5.29821 1.83869 5.17072 1.96617 5.09083 2.12298C5 2.30124 5 2.53459 5 3.0013V8.33464M15 8.33464H5M15 8.33464V8.5013C15 9.90143 15 10.6015 14.7275 11.1363C14.4878 11.6067 14.1054 11.9891 13.635 12.2288C13.1002 12.5013 12.4001 12.5013 11 12.5013H9C7.59987 12.5013 6.8998 12.5013 6.36502 12.2288C5.89462 11.9891 5.51217 11.6067 5.27248 11.1363C5 10.6015 5 9.90143 5 8.5013V8.33464M12.0833 12.5013V16.2513C12.0833 17.4019 11.1506 18.3346 10 18.3346C8.84941 18.3346 7.91667 17.4019 7.91667 16.2513V12.5013"
        stroke="#FDB022"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationWorkCreatedIcon;
