import React from "react";
import { Collapse, CollapseProps } from "antd";

import { useAppSelector } from "../../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import { RU } from "../../../../../../app/utils/constants/languages";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../../../app/utils/helpers/parseLocaledString";
import { useGetCompanyPersonSelect } from "../../../../../../counterparts/service/queries";
import { OfferPartyLeftModel } from "../../../../../utils/models/offerPartyModel";
import OfferPartyModalTable from "../table/OfferPartyModalTable";

import styles from "./offerPartyModalGroup.module.scss";

const OfferPartyModalGroup: React.FC = () => {
  const { leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const { data: companyPersons } = useGetCompanyPersonSelect();

  const findCompanyPerson = (id: number) => companyPersons?.find(item => item.id === id)?.name;

  const randomColor = () => {
    const hexDigits = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "a", "b", "c", "d", "e", "f"];

    let bgColor = "#";

    for (let i = 0; i < 6; i++) {
      const index = Math.floor(Math.random() * hexDigits.length);

      bgColor += hexDigits[index];
    }
    return bgColor;
  };

  const generateGroupData = () => {
    const newData: {
      id?: number;
      name?: string;
      data: OfferPartyLeftModel[];
      color: string;
      viewCompanyPerson?: boolean;
    }[] = [];

    const notPersonsData = leftData?.filter(e => !e.company_person?.id);

    if (!isEmptyArr(notPersonsData)) {
      newData.push({
        id: 0,
        name: "Belgilanmagan",
        data: notPersonsData,
        color: randomColor(),
        viewCompanyPerson: true
      });
    }

    leftData.forEach(item => {
      if (item.company_person?.id) {
        const isSomeCompanyPerson = newData?.some(c => c?.id === item?.company_person?.id);

        !isSomeCompanyPerson &&
          newData.push({
            id: item.company_person?.id,
            color: randomColor(),
            data: leftData?.filter(e => e.company_person?.id === item.company_person?.id),
            name: findCompanyPerson(item.company_person?.id)
          });
      }
    });

    return newData;
  };

  const generateTotal = (data: OfferPartyLeftModel[]) =>
    data?.reduce(
      (first, second) =>
        first +
        parseLocaledString(second?.amount ? second?.amount : "0") *
          parseLocaledString(second?.quantity ? String(second?.quantity) : "0"),
      0
    );

  const items: CollapseProps["items"] = generateGroupData()?.map(item => ({
    key: `${item?.id}`,
    label: (
      <div className={styles.label}>
        <div className={`${styles.label__left} ${item.id === 0 && styles.label__not_data}`}>{item.name}</div>
        <div className={styles.total}>
          <span>Jami: </span>
          <h5>{generateTotal(item.data)?.toLocaleString(RU)}</h5>
        </div>
      </div>
    ),
    children: <OfferPartyModalTable forGroup fields={item.data} isViewCompanyPerson={item.viewCompanyPerson} />,
    defaultOpen: true
  }));

  return (
    <div className={styles.group}>
      <Collapse
        items={items}
        defaultActiveKey={generateGroupData()?.map(item => item.id!)}
        expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
      />
    </div>
  );
};

export default OfferPartyModalGroup;
