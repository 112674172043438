import axios from "axios";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { LocalStorage } from "../../service/LocalStorage";

const $api = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
  withCredentials: true
});

$api.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === 401) {
      LocalStorage.clear();
      window.location.pathname !== rootPaths.AUTH && (window.location.href = window.location.origin);
    }
    return Promise.reject(error);
  }
);

export default $api;
