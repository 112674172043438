import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "../../../../../../../warehouse/service/query";
import { OfferPartyErrorModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

const { Option } = Select;

type Props = {
  uniqueId: string;
};

const OfferPartyWarehouseSelect: React.FC<Props> = ({ uniqueId }) => {
  const dispatch = useDispatch();
  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;
  const { leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const leftDataItem = useMemo(() => leftData?.find(item => item.unique_id === uniqueId), [leftData]);

  const { data: warehouses } = useGetWarehouseSelect(true, true);

  const onChangeWarehouse = (e: number) => {
    dispatch(
      setOfferPartyLefItemData({
        key: "warehouse_id",
        value: e,
        uniqueId
      })
    );

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "warehouse",
          uniqueId,
          action: !e
        })
      );
    }
  };

  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, uniqueId) ? styles.item__error : "";

  return (
    <div className={`${styles.item} ${errorClassName("warehouse")}`}>
      <Select
        showSearch
        suffixIcon={null}
        placeholder="Omborxona"
        onChange={onChangeWarehouse}
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        value={leftDataItem?.warehouse_id}
        notFoundContent={<TableEmpty description="Siz qidirgan omborxona mavjud emas" />}
      >
        {warehouses?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name
            }}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfferPartyWarehouseSelect;
