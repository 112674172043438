import React from "react";
import { Drawer } from "antd";

import { useQueryParams } from "hooks/useQueryParams";

import { useWidget } from "modules/dashboard";

import ProjectByResource from "./content/project-by-resource/project-by-resource";
import { CashStatus, WarehouseDefect } from "./content";

import styles from "./detailed-drawer.module.scss";

const DetailedDrawer: React.FC = () => {
  const { detailedVisible, detailedId, detailedTitle, methods, key } = useWidget();
  const { clearQuery } = useQueryParams();

  const onClose = () => {
    if (key !== "project_by_resource") {
      clearQuery();
    }

    methods.setWidgetDetailedDrawer({
      id: detailedId,
      title: detailedTitle,
      visible: false
    });
  };

  const content: Record<string, React.ReactNode> = {
    cash_status: <CashStatus />,
    warehouse_defect: <WarehouseDefect />,
    project_by_resource: <ProjectByResource />
  };

  return (
    <Drawer title={detailedTitle} open={detailedVisible} onClose={onClose} rootClassName={styles.drawer}>
      {content?.[key] || <></>}
    </Drawer>
  );
};

export default DetailedDrawer;
