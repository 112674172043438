import { RU } from "../../../../../../app/utils/constants/languages";
import { diffFeatureAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateSectionModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverTotalAmount from "../../diff-popover/DiffPopoverTotalAmount";

import styles from "../section.module.scss";

type Props = {
  section: DynamicEstimateSectionModel;
  className?: string;
  isPopover?: boolean;
  getPopoverContainer?: () => HTMLElement;
};

const ExpectedTotalAmount = ({ section, className, isPopover, getPopoverContainer }: Props) => (
  <>
    {diffFeatureAmount(section?.total_amount, section?.expected_total_amount, section?.spend_total_amount) !== 0 ? (
      <DiffPopoverTotalAmount
        classNames={className || styles.amount}
        isPopover={isPopover}
        record={section}
        isFeature={true}
        getPopoverContainer={getPopoverContainer}
      />
    ) : (
      <p className={className || styles.amount}>{section?.total_amount?.toLocaleString(RU) ?? 0}</p>
    )}
  </>
);

export default ExpectedTotalAmount;
