import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Product, Unit } from "modules/common";

import { generalEditKeys } from "../../features/app/utils/constants/generalEditData";
import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { CustomFieldLocationEnum } from "../../features/app/utils/enums/customFieldLocationEnum";

interface AppSliceModel {
  generalEdit: {
    isView?: boolean;
    key: generalEditKeys;
    isOpenChild?: boolean;
    customFieldKeys: CustomFieldLocationEnum[];
  };
  productMarketPrice: {
    unitId?: number;
    productId?: number;
    defaultUnit?: Unit;
    defaultProduct?: Product;
  };
  productMarketPriceModal: {
    visible: boolean;
  };
}

const initialState: AppSliceModel = {
  generalEdit: {
    isView: false,
    customFieldKeys: [],
    isOpenChild: undefined,
    key: generalEditKeys.WAREHOUSE_PRODUCTS
  },
  productMarketPrice: {
    unitId: undefined,
    productId: undefined
  },
  productMarketPriceModal: {
    visible: false
  }
};

const appSlice = createSlice({
  name: sliceNames.APP,
  initialState,
  reducers: {
    setGeneralEdit: (
      state,
      action: PayloadAction<{
        key: generalEditKeys;
        customFieldKeys: CustomFieldLocationEnum[];
        isView?: boolean;
      }>
    ) => {
      if (action.payload) state.generalEdit = action.payload;
    },
    setGeneralEditIsOpenChild: (state, action: PayloadAction<boolean>) => {
      state.generalEdit.isOpenChild = action.payload;
    },
    setProductMarketPrice: (state, action: PayloadAction<AppSliceModel["productMarketPrice"]>) => {
      state.productMarketPrice = action.payload;
    },
    setProductMarketPriceModal: (state, action: PayloadAction<AppSliceModel["productMarketPriceModal"]>) => {
      state.productMarketPriceModal = action.payload;
    }
  }
});

export default appSlice.reducer;
export const appReducerActions = appSlice.actions;
