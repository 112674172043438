import React, { useMemo } from "react";
import { Col, Form, FormInstance, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "../../../../../../app/components/project-select/project-select";
import { StatusEnums } from "../../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { useGetCurrenciesSelect } from "../../../../../../app/service/queries";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { sliceText } from "../../../../../../app/utils/helpers/sliceText";
import { useGetCompanyPersonSelect } from "../../../../../../counterparts/service/queries";
import { useGetWarehouseSelect } from "../../../../../../warehouse/service/query";
import { PartyFormOfferModel, PartyFormProductsModel } from "../../../../../utils/models/partyModalFormModel";

import styles from "../partyModalLeft.module.scss";

const { List, Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance;
};

const PartyModalTableHeader: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const status = useWatch("status", form);
  const offers = useWatch("offers", form) as PartyFormOfferModel[];
  const { isView } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: currencies } = useGetCurrenciesSelect();
  const { data: companyPerson } = useGetCompanyPersonSelect(true);
  const { data: warehouses } = useGetWarehouseSelect(false, true);

  const emptyElem = () => <></>;

  const changeSelectItem = (value: unknown, key: keyof PartyFormProductsModel) => {
    const oldWarehouseProducts = form.getFieldValue("warehouse_products") as PartyFormProductsModel[];
    // const oldGroupProducts = form.getFieldValue("group_products") as PartyGroupProductsModel[];

    form.setFieldsValue({
      warehouse_products: [
        ...(oldWarehouseProducts?.map(item => ({
          ...item,
          [key]: value
        })) || [])
      ]
      // group_products: oldGroupProducts?.map(item => ({
      //   ...item,
      //   children: item?.children?.map(child => ({
      //     ...child,
      //     [key]: value
      //   }))
      // }))
    });
  };

  const onChangeWarehouse = (e: number) => {
    changeSelectItem(e, "warehouse_id");
  };

  const onChangeProject = (e: number) => {
    changeSelectItem(e, "project_id");
  };

  const viewOffers = useMemo(() => isView && !isEmptyArr(offers), [isView, offers]);

  const viewReceived = status === StatusEnums.RECIEVED || status === StatusEnums.PARTIALLY_RECIEVED;

  return (
    <>
      <div className={styles.table__header}>
        <Row gutter={0}>
          <Col span={viewReceived ? 3 : 5} className={styles.table__header__item}>
            {t("partyModal.Resurs turi va nomi")}
          </Col>
          <Col span={2} className={styles.table__header__item}>
            {t("partyModal.Birligi")}
          </Col>
          <Col span={2} className={styles.table__header__item}>
            {t("partyModal.Soni")}
          </Col>
          {viewReceived && (
            <Col span={2} className={styles.table__header__item}>
              {t("partyModal.Qabul qilindi")}
            </Col>
          )}
          <Col span={2} className={styles.table__header__item}>
            {t("partyModal.Birlik summasi")}
          </Col>
          <Col span={viewOffers ? 2 : 3} className={styles.table__header__item}>
            <Select
              showSearch
              value={null}
              disabled={isView}
              placeholder={t("partyModal.Omborxona")}
              onChange={onChangeWarehouse}
              filterOption={selectFilterOption}
              notFoundContent={<TableEmpty />}
              suffixIcon={isView ? null : <SelectSuffixIcon />}
            >
              {warehouses?.map(item => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  props={{
                    name: item?.name
                  }}
                >
                  {sliceText(item?.name, 11)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={2} className={styles.table__header__item}>
            <ProjectSelect
              mode="state"
              value={undefined}
              isNotValue={true}
              allowClear={false}
              disabled={isView}
              suffixIcon={isView ? null : <SelectSuffixIcon />}
              onChange={onChangeProject}
              placeholder={t("supply.Loyiha")}
            />
            {/* <Select */}
            {/*  showSearch */}
            {/*  value={null} */}
            {/*  disabled={isView} */}
            {/*  placeholder="Loyiha" */}
            {/*  onChange={onChangeProject} */}
            {/*  notFoundContent={<TableEmpty />} */}
            {/*  filterOption={selectFilterOption} */}
            {/*  suffixIcon={isView ? null : <SelectSuffixIcon />} */}
            {/* > */}
            {/*  {projects?.map(item => ( */}
            {/*    <Option */}
            {/*      key={item?.id} */}
            {/*      value={item?.id} */}
            {/*      props={{ */}
            {/*        name: item?.name */}
            {/*      }} */}
            {/*    > */}
            {/*      {sliceText(item?.name, 11)} */}
            {/*    </Option> */}
            {/*  ))} */}
            {/* </Select> */}
          </Col>
          <Col span={viewOffers ? 2 : 3} className={styles.table__header__item}>
            <Select
              showSearch
              value={null}
              disabled={isView}
              placeholder={t("partyModal.Ta'minotchi")}
              notFoundContent={<TableEmpty />}
              filterOption={selectFilterOption}
              suffixIcon={isView ? null : <SelectSuffixIcon />}
              onChange={e => changeSelectItem(e, "company_person_id")}
            >
              {companyPerson?.map(item => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  props={{
                    name: item?.name
                  }}
                >
                  {sliceText(item?.person?.name, 11)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={2} className={styles.table__header__item}>
            <Select
              value={null}
              disabled={isView}
              placeholder={t("partyModal.Valyuta")}
              suffixIcon={isView ? null : <SelectSuffixIcon />}
              onChange={e => changeSelectItem(e, "currency_id")}
            >
              {currencies?.map(item => (
                <Option
                  key={item?.id}
                  value={item?.id}
                  props={{
                    name: item?.name
                  }}
                >
                  {sliceText(item?.symbol, 11)}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={3} className={styles.table__header__item}>
            {t("partyModal.Umumiy summa")}
          </Col>
          {viewOffers && (
            <Col span={2} className={styles.table__header__item}>
              {t("partyModal.Takliflar")}
            </Col>
          )}
        </Row>
      </div>
      <Item name="select_search" className="hidden" />
      <List name="cache_products">{emptyElem}</List>
      <List name="select_products">{emptyElem}</List>
      {/* <List name="group_products">{emptyElem}</List> */}
    </>
  );
};

export default PartyModalTableHeader;
