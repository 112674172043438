import React from "react";
import { Col, Dropdown, MenuProps, Row, Spin, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import EditIcon from "../../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { CurrencyTypeEnum } from "../../../../../app/utils/enums/currencyTypeEnum";
import { cx } from "../../../../../app/utils/helpers/cx";
import { useAutoRefreshCurrency, useChangeBasicCurrency, useDeleteCurrency } from "../../../../service/mutations";
import { CurrenciesRes } from "../../../../utils/models/currency/currenciesRes";
import { CurrencyModalDataModel } from "../../../../utils/models/currency/currencyModalDataModel";
import { CurrencyBasicModel } from "../../../../utils/models/currency/currencyModel";

import styles from "./settingsCurrencyTable.module.scss";

type Props = {
  isLoading: boolean;
  data: CurrenciesRes | undefined;
  setModalData: React.Dispatch<React.SetStateAction<CurrencyModalDataModel>>;
};

const SettingsCurrencyTable: React.FC<Props> = ({ data, isLoading, setModalData }) => {
  const { i18n, t } = useTranslation();
  const autoRefresh = useAutoRefreshCurrency();
  const deleteCurrency = useDeleteCurrency();
  const changeBasicCurrency = useChangeBasicCurrency();

  const onOpenModal = (record?: CurrencyBasicModel) => () => {
    const secondCurrency = data?.data?.find(item => item.type !== record?.type);

    setModalData({
      visible: true,
      data: record,
      secondSymbol: secondCurrency?.symbol
    });
  };

  const onChangeBasicCurrency = (id: number) => {
    changeBasicCurrency.mutate(id);
  };

  const menu = (record: CurrencyBasicModel): MenuProps => ({
    items: [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            <span>{t("settings.Tahrirlash")}</span>
          </div>
        ),
        onClick: onOpenModal(record)
      }
    ]
  });

  const columns: ColumnsType<CurrencyBasicModel> = [
    {
      title: `${t("Moliya.Valyuta nomi")}`,
      render: (record: CurrencyBasicModel) => (
        <div className={styles.currency_name}>
          <img src={record.icon} alt="" />
          <span>{record.name[i18n.language]}</span>
          {record?.type === CurrencyTypeEnum.BASE && <div className={cx("status__active")}>{t("Moliya.Asosiy")}</div>}
        </div>
      )
    },
    {
      title: `${t("Moliya.Kodi")}`,
      dataIndex: "symbol"
    },
    {
      title: `${t("Moliya.Qiymati")}`,
      dataIndex: "amount"
    },
    {
      title: `${t("Moliya.O'zgartirilgan sana")}`,
      dataIndex: "updated_at",
      render: (value: string) => (value ? dayjs(+value * 1000).format("DD.MM.YYYY") : `${t("Moliya.O'zgartirilmagan")}`)
    },
    {
      title: `${t("Moliya.Asosiy valyuta")}`,
      render: (record: CurrencyBasicModel) => (
        <Switch
          disabled={record?.type === CurrencyTypeEnum.BASE}
          onChange={() => onChangeBasicCurrency(record.id)}
          checked={record?.type === CurrencyTypeEnum.BASE}
        />
      )
    },
    {
      title: "",
      render: (record: CurrencyBasicModel) =>
        record.type !== CurrencyTypeEnum.BASE && (
          <Dropdown trigger={["click"]} menu={menu(record)}>
            <div className="flex cursor-pointer items-center justify-end gap-2">
              <UnreadMessageDots />
            </div>
          </Dropdown>
        )
    }
  ];

  // change auto refresh
  const onChangeAutoRefresh = () => {
    autoRefresh.mutate();
  };

  return (
    <Spin
      spinning={autoRefresh.isLoading || isLoading || deleteCurrency.isLoading || changeBasicCurrency.isLoading}
      indicator={LoadingIndicator}
    >
      <Row gutter={32} className={styles.currency_table}>
        <Col span={6} className={styles.left}>
          <h4>{t("Moliya.Valyuta")}</h4>
          <p>{t("Moliya.Bu yerda valyutani qo'shishingiz mumkin")}</p>
        </Col>
        <Col span={18} className={styles.right}>
          <Table<CurrencyBasicModel>
            className={cx("footer_table")}
            locale={{
              emptyText: <TableEmpty />
            }}
            columns={columns}
            dataSource={data?.data}
            pagination={false}
            rowKey={e => e.id}
          />
        </Col>
      </Row>
      <div className={styles.auto_refresh}>
        <label htmlFor="">{t("Moliya.Avtomatik yangilash")}</label>
        <Switch defaultChecked={data?.auto_refresh_currencies} onChange={onChangeAutoRefresh} />
      </div>
    </Spin>
  );
};

export default SettingsCurrencyTable;
