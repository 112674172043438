import { JSX } from "react";
import BuyingIcon from "features/app/assets/icons/BuyingIcon";

import ClockCircleIcon from "../../../supply/assets/icons/ClockCircleIcon";
import XCloseIcon from "../../../warehouse/assets/icons/XCloseIcon";
import { CheckIcon } from "../../assets/icons/CheckIcon";
import DraftIcon from "../../assets/icons/DraftIcon";
import NewStatusIcon from "../../assets/icons/NewStatusIcon";
import OrderedIcon from "../../assets/icons/OrderedIcon";

import { PageKeys, StatusEnums } from "./statusEnums";

export const statusData: {
  [p: string]: {
    [k: string]: {
      text: string;
      icon: JSX.Element;
    };
  };
} = {
  [PageKeys.ORDER]: {
    [StatusEnums.PASSIVE]: {
      text: "Qoralama",
      icon: <DraftIcon />
    },
    [StatusEnums.OPEN]: {
      text: "Yangi",
      icon: <NewStatusIcon />
    },
    [StatusEnums.PENDING]: {
      text: "Kutilmoqda",
      icon: <ClockCircleIcon width={16} />
    },
    [StatusEnums.ORDERED]: {
      text: "Partiya qilingan",
      icon: <OrderedIcon />
    },
    [StatusEnums.RECIEVED]: {
      text: "Yopilgan",
      icon: <CheckIcon color="#12B76A" />
    },
    [StatusEnums.REJECTED]: {
      text: "Bekor qilingan",
      icon: <XCloseIcon color="#F04438" />
    },
    [StatusEnums.PARTIALLY_RECIEVED]: {
      text: "Jarayonda",
      icon: <ClockCircleIcon color="#1E90FF" width={16} />
    },
    [StatusEnums.BUYING]: {
      text: "Sotib olindi",
      icon: <BuyingIcon width={16} />
    }
  },
  [PageKeys.PARTY]: {
    [StatusEnums.OPEN]: {
      text: "Qoralama",
      icon: <DraftIcon />
    },
    [StatusEnums.PENDING]: {
      text: "Kutilmoqda",
      icon: <ClockCircleIcon width={16} />
    },
    [StatusEnums.ORDERED]: {
      text: "Partiya qilingan",
      icon: <OrderedIcon />
    },
    [StatusEnums.RECIEVED]: {
      text: "Yopilgan",
      icon: <CheckIcon color="#12B76A" />
    },
    [StatusEnums.REJECTED]: {
      text: "Bekor qilingan",
      icon: <XCloseIcon color="#F04438" />
    },
    [StatusEnums.PARTIALLY_RECIEVED]: {
      text: "Jarayonda",
      icon: <ClockCircleIcon color="#1E90FF" width={16} />
    },
    [StatusEnums.BUYING]: {
      text: "Sotib olinmoqda",
      icon: <BuyingIcon width={16} />
    }
  }
};
