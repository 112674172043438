import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import { useAppDispatch } from "../../../../../../../hooks/redux";
import { useDebounce } from "../../../../../../../hooks/useDebounce";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";

import styles from "./editInput.module.scss";

type Props = {
  sectionIndex?: number;
  taskIndex?: number;
  productIndex?: number;
  children?: string;
  classNames?: string;
  placeholder?: string;
  editKey: string;
};

export const EditInput: FC<Props> = ({
  children,
  classNames,
  placeholder,
  sectionIndex,
  taskIndex,
  productIndex,
  editKey,
}) => {
  const dispatch = useAppDispatch();
  const initialValueRef = useRef(children);
  const [value, setValue] = useState(children ?? "");
  const inputRef = useRef<HTMLInputElement>(null);
  const debouncedValue = useDebounce<string>(value, 750);
  const { setEditSection, setEditTask, setEditProduct } = projectsReducerActions;

  const onClick = (e: MouseEvent<HTMLParagraphElement>) => {
    e?.stopPropagation();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value);
  };

  const onEdit = () => {
    if (
      (sectionIndex as number) >= 0 &&
      (taskIndex as number) >= 0 &&
      (productIndex as number) >= 0
    ) {
      dispatch(
        setEditProduct({
          sectionIndex: sectionIndex as number,
          taskIndex: taskIndex as number,
          productIndex: productIndex as number,
          editKey,
          editValue: value,
        })
      );
      return;
    }
    if ((sectionIndex as number) >= 0 && (taskIndex as number) >= 0) {
      dispatch(
        setEditTask({
          sectionIndex: sectionIndex as number,
          taskIndex: taskIndex as number,
          editKey,
          editValue: value,
        })
      );
      return;
    }
    if ((sectionIndex as number) >= 0) {
      dispatch(
        setEditSection({
          sectionIndex: sectionIndex as number,
          editKey: "name",
          editValue: value,
        })
      );
      return;
    }
  };

  const onBlur = () => {
    if (initialValueRef.current !== value) {
      onEdit();
    }
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEdit();
    }

    if (e.key === "Escape") {
      inputRef.current?.blur();
    }
  };

  useEffect(() => {
    onEdit();
  }, [debouncedValue]);

  useEffect(() => {
    if (children) {
      setValue(children);
    }
  }, [children]);

  return (
    <div
      onClick={onClick}
      className={`${styles.editable} ${!value ? styles.empty : ""} ${classNames} ${
        +value <= 0 ? styles.error : ""
      }`}
    >
      <input
        ref={inputRef}
        onBlur={onBlur}
        className={classNames}
        onKeyDown={onKeyDown}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={onChange}
      />
    </div>
  );
};
