import { RU } from "../../../../../../app/utils/constants/languages";
import { diffQuantity } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateTaskModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverQuantity from "../../diff-popover/DiffPopoverQuantity";

import styles from "../task.module.scss";

type Props = {
  task: DynamicEstimateTaskModel;
  getPopoverContainer: () => HTMLElement;
};

const SpendQuantity = ({ task, getPopoverContainer }: Props) => {
  return (
    <>
      {diffQuantity(task?.quantity, task?.spend_total_quantity) !== 0 ? (
        <DiffPopoverQuantity
          record={task}
          classNames={styles.quantity}
          taskStatus={task?.status}
          isFeature={false}
          getPopoverContainer={getPopoverContainer}
        />
      ) : (
        <p className={styles.quantity}>{task?.quantity?.toLocaleString(RU) ?? 0}</p>
      )}
    </>
  );
};

export default SpendQuantity;
