import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Form, FormInstance } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import ProjectSelect from "../../../../../../../app/components/project-select/project-select";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import { useProjectSelect } from "../../../../../../../app/service/queries";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import { ProjectSelectModel } from "../../../../../../../app/utils/models/projectSelectModel";
import { supplyQueryNames } from "../../../../../../utils/constants/supplyQueryNames";
import { OrderDetailModel } from "../../../../../../utils/models/orderModel";

type Props = {
  name: (string | number)[];
  renderName?: (string | number)[];
  form: FormInstance;
};

const { useWatch } = Form;

const PartyProjectSelect: React.FC<Props> = ({ name, form, renderName }) => {
  const qc = useQueryClient();
  const { data: projects } = useProjectSelect({ enabled: true });
  const { isView, updateProduct, party_id, isEditing } = useAppSelector(state => state.supplyReducer.partyModal);
  const status = useWatch("status", form) as StatusEnums;
  const oneOrder = qc.getQueryData<OrderDetailModel>([supplyQueryNames.ORDER_DETAIL, party_id]);

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  const generateProjects = () => {
    if (oneOrder && !isEmptyArr(oneOrder?.warehouse_products) && !isEditing) {
      const products = [...(oneOrder?.warehouse_products || [])];
      const nonExistent: ProjectSelectModel[] = [];

      products?.forEach(item => {
        const isProject = projects?.some(
          parent =>
            parent?.id === item?.project?.id || parent?.projects?.some(project => project?.id === item?.project?.id)
        );

        if (!isProject && !nonExistent?.some(e => e?.id === item?.project?.id)) {
          nonExistent.push(item?.project);
        }
      });
      return [...(projects || []), ...nonExistent];
    }

    return projects;
  };

  return (
    <ProjectSelect
      mode="form"
      form={form}
      name={name}
      label={null}
      enabled={false}
      suffixIcon={null}
      renderName={renderName}
      disabled={isView && disabled()}
      payloadData={generateProjects()}
    />
  );
};

export default PartyProjectSelect;
