import React from "react";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import { CategoryModel } from "../../../../../../../settings/utils/models/category/CategoryModel";
import { ProductModel } from "../../../../../../../settings/utils/models/product/productModel";
import TaskProductsTemplateFolders from "../folders/TaskProductsTemplateFolders";
import TaskProductsTemplateTable from "../table/TaskProductsTemplateTable";

import styles from "./taskProductsTemplateDetailedFolder.module.scss";
import { PaginationType } from "../../../../../../../app/utils/models/PaginationType";

type Props = {
  data?: CategoryModel[];
  isLoading: boolean;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
  products?: PaginationType<ProductModel[]>;
  isLoadingProducts: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const TaskProductsTemplateDetailedFolder: React.FC<Props> = ({
  page,
  setPage,
  data,
  products,
  isLoading,
  setTabKey,
  setFolderId,
  isLoadingProducts,
  setBreadcrumbItems,
}) => {
  return (
    <div className={styles.detailed_folder}>
      <TaskProductsTemplateFolders
        data={data}
        setPage={setPage}
        isCategory={false}
        setTabKey={setTabKey}
        isLoading={isLoading}
        setFolderId={setFolderId}
        setBreadcrumbItems={setBreadcrumbItems}
      />
      <TaskProductsTemplateTable
        page={page}
        setPage={setPage}
        isLoading={isLoadingProducts}
        data={products}
      />
    </div>
  );
};

export default TaskProductsTemplateDetailedFolder;
