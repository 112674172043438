import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { PaymentModel } from "features/app/utils/models/payment/paymentModel";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys } from "..";

interface Query {
  projectByResourceOthersInDetailView: PaginationType<PaymentModel[]>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  detailedId: number;
  type: string;
}

export function useGetProjectByResourceOthersInDetailView({ enabled = false, detailedId, type }: Params) {
  const initialValue: Query = { projectByResourceOthersInDetailView: { current_page: 1, total: 0, data: [] } };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    task_id: detailedId
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_BY_RESOURCE_OTHERS_DETAIL_DATA_VIEW, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`payment/view?${searchParams}`);

      return { projectByResourceOthersInDetailView: data };
    },
    { enabled: enabled && type === "another" }
  );

  return { ...data, ...args };
}
