import React, { useState } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { DatePicker } from "antd";
import CalendarPlusIcon from "features/app/assets/icons/CalendarPlusIcon";

import { cx, dayjsFormats } from "modules/common";
import {
  DateOptions,
  defaultWidgetDateOptions,
  useWidget,
  WidgetSettingsForm,
  widgetStaticData
} from "modules/dashboard";

import { Select } from "components/fields";

import styles from "./settings.module.scss";

const { RangePicker } = DatePicker;

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>>[0];

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
  rootClassName: string;
};

const Date = ({ form, rootClassName }: Props) => {
  const { key } = useWidget();
  const { dateOptions, isNotSpecialDatPicker } = widgetStaticData[key];
  const maxDate = useWatch({
    control: form.control,
    name: "max_date"
  });
  const minDate = useWatch({
    control: form.control,
    name: "min_date"
  });

  const [dropdown, setDropdown] = useState({
    visible: false,
    specialVisible: false
  });

  const onOpenChangeSpecialPicker = (open: boolean) => {
    setDropdown(prev => ({
      ...prev,
      specialVisible: open
    }));
  };

  const onRangeSelection = (_: RangeValue, formatString: [string, string]) => {
    form.setValue("min_date", formatString[0]);
    form.setValue("max_date", formatString[1]);
    form.setValue("special_date", "special");
  };

  const getAllSelectOptions = () => {
    const selectOptions: DateOptions[] = [...(dateOptions || defaultWidgetDateOptions)];

    if (!isNotSpecialDatPicker) {
      selectOptions.splice(0, 0, {
        label: (
          <div className={cx("flex w-full items-center gap-5", styles.special)} onClick={e => e.stopPropagation()}>
            <span>Maxsus</span>
            <RangePicker
              placement="topRight"
              onChange={onRangeSelection}
              open={dropdown?.specialVisible}
              suffixIcon={<CalendarPlusIcon />}
              className={styles.range_picker}
              placeholder={["Boshlanish", "Tugash"]}
              onOpenChange={onOpenChangeSpecialPicker}
              format={[dayjsFormats.DATE, dayjsFormats.DATE]}
            />
          </div>
        ) as never,
        value: "special",
        props: {
          name: "Maxsus"
        },
        onClick: () => {
          setDropdown({ specialVisible: true, visible: true });
        }
      });
    }
    return selectOptions;
  };

  const onVisibleChangeDropdown = (open: boolean) => {
    if (!dropdown?.specialVisible) {
      setDropdown({ specialVisible: false, visible: open });
    }
  };

  return (
    <Select
      label="Muddati"
      name="special_date"
      control={form.control}
      options={getAllSelectOptions()}
      open={dropdown.visible}
      popupMatchSelectWidth={false}
      rootClassName={cx(rootClassName, styles.date)}
      onDropdownVisibleChange={onVisibleChangeDropdown}
      labelRender={({ value, label }) => (value !== "special" ? label : `${minDate} - ${maxDate}`)}
      suffixIcon={<CalendarPlusIcon />}
    />
  );
};

export default Date;
