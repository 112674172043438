import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { WarehouseDefectModel } from "../../features/warehouse/utils/models/warehouseDefectModel";

interface DefectViewReducerModel {
  visible: boolean;
  data?: WarehouseDefectModel;
}

const initialState: DefectViewReducerModel = {
  visible: false,
  data: undefined,
};

const defectViewSlice = createSlice({
  name: sliceNames.DEFECT_VIEW,
  initialState,
  reducers: {
    setDefectView: (state, action: PayloadAction<DefectViewReducerModel>) => {
      state.visible = action.payload.visible;
      state.data = action.payload.data;
    },
  },
});

export default defectViewSlice.reducer;
export const defectViewActions = defectViewSlice.actions;
