import { Checkbox } from "antd";

import { CustomFieldProps } from "../EstimateCustomFieldsContent";

const CheckboxCustomField = ({ disabled, onChange, value, onBlur }: CustomFieldProps) => (
  <div className="mr-4 flex h-full items-center justify-end">
    <Checkbox
      value={value}
      disabled={disabled}
      checked={Boolean(value)}
      onChange={e => {
        if (onChange) {
          onChange(e.target.checked);
          onBlur && onBlur();
        }
      }}
    />
  </div>
);

export default CheckboxCustomField;
