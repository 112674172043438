import { UseTranslationResponse } from "react-i18next";

import { localeFormatter } from "../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../app/utils/helpers/parseLocaledString";
import { ProductSelectModel } from "../../../app/utils/models/productSelectModel";
import { CounterpartsPersonSelectModel } from "../../../counterparts/utils/models/counterpartsPersonSelectModel";
import { PartyFormProductsModel } from "../models/partyModalFormModel";
import { PartyPrintDataModel } from "../models/PartyPrintDataModel";

const findProduct = (selectProductsData: ProductSelectModel[], product_id: number) =>
  selectProductsData?.find(item => item?.id === product_id);

export function formationPrintProductsData(
  warehouseProducts: PartyFormProductsModel[],
  selectProductsData: ProductSelectModel[],
  companyPerson: CounterpartsPersonSelectModel[] | undefined,
  tr: UseTranslationResponse<"translation", undefined>
) {
  const newData: {
    name?: string;
    id?: number | string;
    data: PartyPrintDataModel[];
  }[] = [];

  let sameWarehouse: number | undefined = warehouseProducts?.[0]?.warehouse_id;
  let sameProject: number | undefined = warehouseProducts?.[0]?.project_id;

  const findCompanyPerson = (id?: number | string) => companyPerson?.find(item => item.id === id)?.name;

  const generateData = (personId?: string | number) => {
    const data: PartyPrintDataModel[] = [];

    warehouseProducts?.forEach(item => {
      if (item?.company_person_id === personId) {
        const findProductData = findProduct(selectProductsData, item?.product_id);

        data.push({
          id: item?.product_id,
          productName: findProductData?.name[tr.i18n.language],
          unitName: findProductData?.unit?.symbol[tr.i18n.language],
          quantity: localeFormatter(String(item?.quantity)),
          projectId: item?.project_id,
          warehouseId: item?.warehouse_id,
          amount: localeFormatter(String(item?.amount)),
          total: localeFormatter(
            String(parseLocaledString(String(item?.quantity)) * parseLocaledString(String(item?.amount)))
          )
        });
      }
    });

    return data;
  };

  warehouseProducts.forEach(item => {
    const isSomeCompanyPerson = newData?.some(c => c?.id === item?.company_person_id);

    sameWarehouse = sameWarehouse === item?.warehouse_id ? sameWarehouse : undefined;

    sameProject = sameProject === item?.project_id ? sameProject : undefined;

    !isSomeCompanyPerson &&
      newData.push({
        id: item?.company_person_id,
        data: generateData(item?.company_person_id),
        name: findCompanyPerson(item?.company_person_id)
      });
  });

  return { data: newData, sameProject, sameWarehouse };
}
