import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import { counterpartsQueryKeys } from "../../../../../counterparts/utils/constants/counterpartsQueryKeys";
import CheckCircleIcon from "../../../../../supply/assets/icons/CheckCircleIcon";
import { PaymentFilterKeys } from "../../../../utils/enums/paymentFilterKeys";
import { CalendarItemModel } from "../../../../utils/models/paymentCalendarModel";

import styles from "./paymentCalendarContent.module.scss";

type Props = CalendarItemModel & {
  date?: string;
  isCompanyPerson?: boolean;
};

const PaymentCalendarItem: React.FC<Props> = props => {
  const dispatch = useDispatch();
  const { severalSearchParams } = useQueryParams();
  const { setOrderView, setExpectedPaymentView } = paymentReducerActions;
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);
  const { currency_symbol, paid_amount, percent, total_amount, type, id, name, isCompanyPerson } = props;

  const names = {
    order: `P-${id}`,
    expected_payment: sliceText(name || "", 11)
  };

  const isComplete = percent === 100;

  const itemClassName = isComplete
    ? styles.card__item__complete
    : type === "order"
      ? styles.card__item__order
      : styles.card__item__payment;

  const onOpenViewContent = () => {
    if (type === PaymentFilterKeys.ORDER) {
      dispatch(
        setOrderView({
          orderId: id,
          visible: true
        })
      );
    } else {
      dispatch(
        setExpectedPaymentView({
          id,
          visible: true,
          forPayQueryKeys: [[counterpartsQueryKeys.CALENDAR_VIEW, searchParams]]
        })
      );
    }
  };

  return (
    <div className={classNames(styles.card__item, itemClassName)} onClick={onOpenViewContent}>
      <div className={styles.item__top}>
        <h3>{names[type]}</h3>
        <p>{isComplete ? <CheckCircleIcon /> : `${percent} %`}</p>
      </div>
      <div className={styles.item__bottom}>
        {isComplete
          ? `${total_amount?.toLocaleString(RU)} ${currency_symbol}`
          : `${paid_amount?.toLocaleString(RU)} / ${total_amount?.toLocaleString(RU)} ${currency_symbol}`}
      </div>
    </div>
  );
};

export default PaymentCalendarItem;
