import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Button, message } from "antd";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { routeSubmodules } from "../../../../supply/utils/constants/routeSubmodules";
import { ProductOfferModel } from "../../../../supply/utils/models/OfferModel";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import ConditionalRender from "../../conditional-render/ConditionalRender";
import { StatusEnums } from "../../statuses/statusEnums";

const OfferButtons: React.FC = () => {
  const dispatch = useDispatch();
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const partyActions = actions("Partiyalar");

  const { setOfferPartyModal } = supplyOfferActions;

  const { data: selectOffers } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const isCreateParty = useMemo(() => !isEmptyArr(selectOffers), [selectOffers]);

  const isCompanyPersonsAccepted = (offers?: ProductOfferModel[]) =>
    offers?.some(item => item.status === StatusEnums.RECIEVED);

  const onOpenOfferPartyModal = () => {
    let isEmptyCompanyPerson = false;

    for (const item of selectOffers) {
      if (!isCompanyPersonsAccepted(item.warehouse_product_offers)) {
        isEmptyCompanyPerson = true;
        break;
      }
    }

    if (!isEmptyCompanyPerson) {
      dispatch(
        setOfferPartyModal({
          visible: true,
          selectData: selectOffers
        })
      );
    } else {
      message.info("Tasdiqlangan narx mavjud emas");
    }
  };

  return (
    <ConditionalRender if={isCreateParty && partyActions.create}>
      <Button onClick={onOpenOfferPartyModal}>
        <PlusIcon />
        <span>Partiya yaratish</span>
      </Button>
    </ConditionalRender>
  );
};

export default OfferButtons;
