import React from "react";
import { Button, Col, Dropdown, MenuProps, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import PlusCircleIcon from "../../../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { Status, StatusNames } from "../../../../../app/utils/models/status";
import { useDeletePaymentType } from "../../../../service/mutations";
import { useGetPaymentTypes } from "../../../../service/queries";
import { PaymentTypeModalDataModel } from "../../../../utils/models/payment-type/paymentTypeModalDataModel";
import { PaymentTypeModel } from "../../../../utils/models/payment-type/paymentTypeModel";

import styles from "./settingsPaymentTable.module.scss";

type Props = {
  setModalData: React.Dispatch<React.SetStateAction<PaymentTypeModalDataModel>>;
};

const SettingsPaymentTable: React.FC<Props> = ({ setModalData }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPaymentTypes();
  const deletePaymentType = useDeletePaymentType();

  const onDelete = (id: number) => deletePaymentType.mutateAsync(id);

  const onCreate = () => {
    setModalData({
      visible: true
    });
  };

  const onUpdate = (record: PaymentTypeModel) => () => {
    setModalData({
      visible: true,
      id: record?.id
    });
  };

  const menu = (record: PaymentTypeModel): MenuProps => ({
    items: [
      {
        key: "1",
        label: (
          <div className="d_f ai_c cg_8">
            <EditIcon />
            <span>{t("settings.Tahrirlash")}</span>
          </div>
        ),
        onClick: onUpdate(record)
      },
      {
        key: "2",
        label: (
          <ModalConfirm onOk={() => onDelete(record.id)}>
            <div className="d_f ai_c cg_8">
              <DeleteIcon />
              <span>{t("settings.O'chirish")}</span>
            </div>
          </ModalConfirm>
        )
      }
    ]
  });

  const columns: ColumnsType<PaymentTypeModel> = [
    {
      title: `${t("Moliya.To'lov turi")}`,
      dataIndex: "name"
    },
    {
      title: `${t("Xodimlar.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            status__active: value === Status.ACTIVE,
            status__passive: value === Status.PASSIVE
          })}
        >
          {StatusNames[value]}
        </span>
      )
    },
    {
      title: "",
      render: (record: PaymentTypeModel) => (
        <Dropdown trigger={["click"]} menu={menu(record)}>
          <div className="flex cursor-pointer items-center justify-end gap-2">
            <UnreadMessageDots />
          </div>
        </Dropdown>
      )
    }
  ];

  // table footer
  const tableFooter = () => (
    <div className={styles.footer}>
      <Button type="text" className={styles.footer} onClick={onCreate}>
        <PlusCircleIcon fill={true} /> {t("Moliya.To'lov turi qo'shish")}
      </Button>
    </div>
  );

  return (
    <Spin spinning={false} indicator={LoadingIndicator}>
      <Row gutter={32} className={styles.payment_type_table}>
        <Col span={6} className={styles.left}>
          <h4>{t("Moliya.To'lov turi")}</h4>
          <p>{t("Moliya.Bu yerda to'lov turini qo'shishingiz mumkin")}</p>
        </Col>
        <Col span={18} className={styles.right}>
          <Table<PaymentTypeModel>
            className="footer_table"
            locale={{
              emptyText: <TableEmpty />
            }}
            columns={columns}
            loading={{
              spinning: isLoading || deletePaymentType.isLoading,
              indicator: LoadingIndicator
            }}
            dataSource={data}
            pagination={false}
            rowKey={e => e.id}
            footer={tableFooter}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default SettingsPaymentTable;
