import React from "react";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import SettingsProductsTemplateTable from "../table/SettingsProductsTemplateTable";
import { ProductDevModel } from "../../../../utils/models/product-dev/productDevModel";
import { CategoryDevModel } from "../../../../utils/models/product-dev/categoryDevModel";
import SettingsProductsTemplateFolders from "../folders/SettingsProductsTemplateFolders";

import styles from "./settingsProductsTemplateDetailedFolder.module.scss";

type Props = {
  data?: CategoryDevModel[];
  isLoading: boolean;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
  products?: ProductDevModel[];
  isLoadingProducts: boolean;
};

const SettingsProductsTemplateDetailedFolder: React.FC<Props> = ({
  data,
  products,
  isLoading,
  setTabKey,
  setFolderId,
  isLoadingProducts,
  setBreadcrumbItems,
}) => {
  return (
    <div className={styles.detailed_folder}>
      <SettingsProductsTemplateFolders
        data={data}
        isCategory={false}
        setTabKey={setTabKey}
        isLoading={isLoading}
        setFolderId={setFolderId}
        setBreadcrumbItems={setBreadcrumbItems}
      />
      <SettingsProductsTemplateTable isLoading={isLoadingProducts} data={products} />
    </div>
  );
};

export default SettingsProductsTemplateDetailedFolder;
