import React from "react";

import Bottom from "./bottom";
import Top from "./top";

const Wrapper: React.FC = () => (
  <div className="box-border flex flex-col gap-4 p-5">
    <Top />
    <Bottom />
  </div>
);

export default Wrapper;
