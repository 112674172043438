import { Popover, Spin } from "antd";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetDynamicEstimateTaskProgress } from "../../../../service/queries";
import { DynamicEstimateProductModel } from "../../../../utils/models/dynamicEstimateModel";
import { TaskProgressModel } from "../../../../utils/models/estimateModel";

import styles from "./productView.module.scss";

type Props = {
  expanded: boolean;
  id?: number;
  product?: DynamicEstimateProductModel;
};

const ExpandedRow = ({ id, expanded, product }: Props) => {
  const { i18n } = useTranslation();
  const currency = useAppSelector((state) => state.projectsReducer.projectCurrency);
  const { data: resources, isLoading } = useGetDynamicEstimateTaskProgress(
    expanded,
    id
  );

  const getStatus = useCallback(
    (record: TaskProgressModel) => {
      if (resources && product) {
        if (record?.currency?.id === currency?.id) {
          if (record?.amount < product?.amount) {
            return "success";
          }
          if (record?.amount > product?.amount) {
            return "error";
          }
        } else {
          if (record?.exchange < product?.amount) {
            return "success";
          }
          if (record?.exchange > product?.amount) {
            return "error";
          }
        }
      }
      return "";
    },
    [currency?.id, product, resources]
  );

  const popoverContent = (record: TaskProgressModel) => (
    <div className={styles.popover}>
      <div className={styles.popover__title}>
        <p>Partaya raqami</p>
        <p className={styles.popover__title__part}>P-{record?.order?.id}</p>
      </div>
      <div className={styles.popover__title}>
        <p>Yetkazuvchi</p>
        <p>{record?.order?.company_person?.name}</p>
      </div>
      <div className={styles.popover__title}>
        <p>Yetkazilgan sanasi</p>
        <p>{record?.order?.delivery_date}</p>
      </div>
      <div className={styles.popover__title}>
        <p>Mas’ul</p>
        <p>{record?.order?.agent?.full_name}</p>
      </div>
    </div>
  );

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.expanded}>
        {resources?.map((resource, index) => (
          <div className={styles.resource} key={resource?.id}>
            <div className={styles.name}>{index + 1}</div>
            <div className={styles.quantity}>
              {resource?.quantity?.toLocaleString(RU)}
            </div>
            <div className={styles.unit}>
              {resource?.unit?.symbol[i18n.language]}
            </div>
            {resource?.order ? (
              <Popover content={popoverContent(resource)}>
                <div
                  className={`${styles.amount} ${
                    product?.plan ? styles[getStatus(resource)] : ""
                  }`}
                >
                  {resource?.amount?.toLocaleString(RU) ?? 0}
                </div>
              </Popover>
            ) : (
              <div
                className={`${styles.amount} ${
                  product?.plan ? styles[getStatus(resource)] : ""
                }`}
              >
                {resource?.amount?.toLocaleString(RU) ?? 0}
              </div>
            )}
            <div
              className={`${styles.total} ${
                product?.plan ? styles[getStatus(resource)] : ""
              }`}
            >
              {resource?.total_amount?.toLocaleString(RU) ?? 0}
            </div>
            <div className={styles.currency}>{resource?.currency?.symbol}</div>
          </div>
        ))}
      </div>
    </Spin>
  );
};

export default ExpandedRow;
