import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { Widget, WidgetKeys } from "modules/dashboard/schema/schema";

import { WidgetContext } from "../../../../modules/dashboard/context";
import {
  area,
  bar,
  calendar,
  card,
  column,
  count,
  defaultImg,
  gantt,
  histogram,
  line,
  radar,
  range,
  stream_graph,
  table,
  table_card,
  warehouse_defect
} from "../../images";

type Props = Omit<Widget, "key"> & {
  widgetKey: WidgetKeys;
};

const WidgetModalCard: React.FC<Props> = props => {
  const { chart_type, name, widgetKey } = props;
  const { i18n } = useTranslation();
  const { methods } = useContext(WidgetContext);

  const images: Record<string, string> = {
    bar,
    area,
    card,
    line,
    range,
    table,
    histogram,
    column,
    table_card,
    count,
    radar,
    calendar,
    gantt,
    stream_graph,
    warehouse_defect
  };

  const onOpenModal = () => {
    methods.setWidgetModal({
      widget: { ...props, key: widgetKey },
      isUpdateWidget: false,
      visibleWidgetModal: true
    });
  };

  return (
    <div
      onClick={onOpenModal}
      className="flex w-[17.625rem] cursor-pointer flex-col overflow-hidden rounded-lg border border-solid border-gray-200 transition-all ease-in-out hover:border-primary-500"
    >
      <div className="flex items-center justify-center bg-gray-100">
        <img
          alt={name[i18n.language]}
          className="h-[9.75rem] w-[13.25rem] object-contain"
          src={widgetKey === "warehouse_defect" ? images.warehouse_defect : images[chart_type] || defaultImg}
        />
      </div>
      <div className="box-border border-x-0 border-b-0 border-t border-solid border-gray-200 px-4 py-3 text-base font-medium text-gray-700">
        {name[i18n.language]}
      </div>
    </div>
  );
};

export default WidgetModalCard;
