import React from "react";
import { Form, FormInstance, Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "../../../../../../../warehouse/service/query";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  index: number;
  groupIndex: number;
  form: FormInstance;
};

const PersonWarehouseSelect: React.FC<Props> = ({ form, index, groupIndex }) => {
  const status = useWatch("status", form) as StatusEnums;
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);
  const { data: warehouses } = useGetWarehouseSelect(false, true);

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  const selectedId = () => form.getFieldValue(["person_group", groupIndex, "data", index, "warehouse_id"]);

  const selected = () => warehouses?.find(item => item?.id === selectedId());

  return (
    <>
      {/* <PerfItem placeholder={<div className="p-[0.62rem]">{selected()?.name}</div>} visible={!(isView && disabled())}> */}
      <Item name={["person_group", groupIndex, "data", index, "warehouse_id"]} rules={formRules()}>
        <Select
          showSearch
          suffixIcon={null}
          placeholder="Omborxona"
          popupMatchSelectWidth={false}
          disabled={isView && disabled()}
          filterOption={selectFilterOption}
          notFoundContent={<TableEmpty description="Siz qidirgan omborxona mavjud emas" />}
        >
          {warehouses?.map(item => (
            <Option
              key={item.id}
              value={item.id}
              props={{
                name: item?.name
              }}
            >
              {item.name}
            </Option>
          ))}
        </Select>
      </Item>
      {/* </PerfItem> */}
    </>
  );
};

export default PersonWarehouseSelect;
