import React, { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Form, Modal } from "antd";
import dayjs from "dayjs";
import { uid } from "uid";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import { useGetCustomFieldSelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import FileQuestionIcon from "../../../../payment/assets/icons/FileQuestionIcon";
import { useCreateReservation, useUpdateWarehouseProduct } from "../../../../warehouse/service/mutation";
import { useCreateOrder, useUpdateOrder } from "../../../service/mutations";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { generateCustomFieldData, passedCustomFieldData } from "../../../utils/helpers/generateCustomFieldData";
import { OrderFormModel, OrderFormProductModel } from "../../../utils/models/orderModalFormModel";
import { OrderReqModel, OrderUpdateReqModel } from "../../../utils/models/orderReqModel";
import OrderDrawer from "../order-drawer/OrderDrawer";
import OrderEstimate from "../order-estimate/OrderEstimate";

import OrderModalLeft from "./left/OrderModalLeft";
import OrderModalRight from "./right/OrderModalRight";

import styles from "./orderModal.module.scss";

const { Item, useWatch } = Form;

const OrderModal: React.FC<{ isWarehouse?: boolean }> = ({ isWarehouse }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setSelectOrderProducts, setOrderModal, setProductDrawerData, setOrderTableView } = supplyActions;
  const [status, setStatus] = useState(StatusEnums.OPEN);
  const { visible, selectProducts, isEdit } = useAppSelector(state => state.supplyReducer.orderModal);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT]);

  const { products } = useAppSelector(state => state.supplyReducer.productDrawerData);

  const { products: estimateProducts } = useAppSelector(state => state.supplyReducer.estimateData);

  const taskProducts = useWatch("task_products", form);

  const createOrder = useCreateOrder();
  const updateOrder = useUpdateOrder();
  const createWarehouseOrder = useCreateReservation();
  const updateWarehouseOrder = useUpdateWarehouseProduct();

  const setFormFields = () => ({
    task_products: selectProducts?.map(item => ({
      // id: item.id,
      unit_id: item?.unit?.id,
      product_id: item?.product?.id,
      quantity: String(item.quantity),
      warehouse_id: item?.warehouse?.id,
      project_id: item?.project?.id,
      row_id: uid(12)
    })),
    select_products:
      selectProducts?.map(item => ({
        id: item.product?.id,
        name: item?.product?.name,
        resource: item.product?.resource,
        price: 0,
        status: "",
        unit: item.unit
      })) ?? []
  });

  useLayoutEffect(() => {
    if (!isEmptyArr(selectProducts)) {
      const newFormObj = setFormFields();
      const firstProduct = selectProducts?.[0];

      if (isEdit) {
        // @ts-ignore
        newFormObj.id = firstProduct?.id;
      }

      form.setFieldsValue({
        ...newFormObj,
        delivery_date: firstProduct?.delivery_date ? dayjs(firstProduct?.delivery_date, dayjsFormats.DATE) : null
      });

      if (selectProducts?.length) {
        passedCustomFieldData(form, selectProducts[0]?.custom_field_values, customFields);
      }

      dispatch(setOrderTableView(true));
    }
  }, [selectProducts, customFields, isEdit]);

  const onOpenAfter = (open: boolean) => {
    if (!open) {
      form.resetFields();
      dispatch(setOrderTableView(false));

      dispatch(
        setProductDrawerData({
          ids: [],
          products: []
        })
      );
    }
  };

  const onCancel = () => {
    dispatch(
      setOrderModal({
        visible: false
      })
    );
  };

  const onOk = () => form.submit();

  const onAfterFinish = () => {
    onCancel();
    dispatch(setSelectOrderProducts([]));
  };

  const onFinish = (fields: OrderFormModel) => {
    const customFiledData = generateCustomFieldData(fields?.custom_field_values, customFields) as never[];

    if (isEdit) {
      const oneProduct = fields?.task_products?.[0];

      const reqData: OrderUpdateReqModel = {
        id: fields?.id,
        delivery_date: fields.delivery_date ? dayjs(fields.delivery_date).format(dayjsFormats.DATE) : undefined,
        product_id: oneProduct?.product_id,
        project_id: oneProduct?.project_id,
        quantity: parseLocaledString(oneProduct?.quantity),
        unit_id: oneProduct?.unit_id,
        warehouse_id: oneProduct?.warehouse_id,
        custom_field_values: customFiledData
      };

      if (isWarehouse) {
        updateWarehouseOrder.mutateAsync(reqData).then(onAfterFinish);
      } else {
        updateOrder.mutateAsync(reqData).then(onAfterFinish);
      }
    } else {
      const reqPassedProducts = fields?.search ? fields?.cache_products : fields?.task_products;

      const reqData: OrderReqModel = {
        delivery_date: fields?.delivery_date ? dayjs(fields.delivery_date).format(dayjsFormats.DATE) : undefined,
        status,
        custom_field_values: customFiledData,
        task_products:
          reqPassedProducts?.map(item => ({
            product_id: item.product_id,
            project_id: item.project_id,
            quantity: parseLocaledString(item.quantity),
            unit_id: item.unit_id,
            warehouse_id: item.warehouse_id,
            id: item.id,
            amount: item?.amount
          })) ?? []
      };

      if (isWarehouse) {
        createWarehouseOrder.mutateAsync(reqData).then(onAfterFinish);
      } else {
        createOrder.mutateAsync(reqData).then(onAfterFinish);
      }
    }
  };

  const onClickDraft = () => {
    setStatus(StatusEnums.PASSIVE);
    form.submit();
  };

  const buttonActiveClass = (initialClass?: string) =>
    `${initialClass ? styles[initialClass] : ""} ${
      isEmptyArr(taskProducts) ? styles.button__disabled : styles.button__active
    }`;

  const footer = (
    <div className={styles.footer}>
      <Item name="status" style={{ margin: 0 }}>
        {isWarehouse && !isEdit && (
          <Button
            onClick={onClickDraft}
            value={StatusEnums.OPEN}
            className={buttonActiveClass("footer__left")}
            loading={status === StatusEnums.PASSIVE && createOrder.isLoading}
          >
            <FileQuestionIcon />
            Qoralama
          </Button>
        )}
      </Item>
      <div className={styles.footer__right}>
        <Button onClick={onCancel}>Yopish</Button>
        <Button
          type="primary"
          onClick={onOk}
          className={buttonActiveClass()}
          loading={
            status === StatusEnums.OPEN &&
            (createOrder.isLoading ||
              createWarehouseOrder.isLoading ||
              updateOrder.isLoading ||
              updateWarehouseOrder.isLoading)
          }
        >
          Saqlash
        </Button>
      </div>
    </div>
  );

  const onClearProduct = (isTaskProduct: boolean) => () => {
    const oldProducts = form.getFieldValue("task_products") ?? [];

    form.setFieldsValue({
      task_products: oldProducts?.filter((item: OrderFormProductModel) => (isTaskProduct ? !item.id : item.id))
    });
  };

  const onImportEstimateProduct = () => {
    const oldProducts = form.getFieldValue("task_products") ?? [];
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];
    const warehouseId = form.getFieldValue("warehouse_id");
    const projectId = form.getFieldValue("project_id");

    form.setFieldsValue({
      task_products: [
        ...(oldProducts?.filter((item: OrderFormProductModel) => item?.product_id) ?? []),
        ...(estimateProducts?.map(item => ({
          id: item.id,
          unit_id: item?.unit?.id,
          product_id: item?.product?.id,
          quantity: String(
            item.quantity - item.total_ordered_quantity > 0 ? item.quantity - item.total_ordered_quantity : 0
          ),
          amount: item?.amount,
          warehouse_id: warehouseId,
          project_id: projectId,
          row_id: uid(12)
        })) ?? [])
      ],
      select_products: [
        ...oldSelectProducts,
        ...(estimateProducts?.map(item => ({
          id: item.product?.id,
          name: item?.product?.name,
          resource: item.product?.resource,
          price: 0,
          status: item?.status,
          unit: item.unit,
          isForm: true
        })) ?? [])
      ]
    });
  };

  const onImportProduct = () => {
    const oldProducts: OrderFormProductModel[] = form.getFieldValue("task_products") ?? [];
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];
    const warehouseId = form.getFieldValue("warehouse_id");
    const projectId = form.getFieldValue("project_id");

    form.setFieldsValue({
      task_products: [
        ...(oldProducts?.filter((item: OrderFormProductModel) => item.product_id) ?? []),
        ...(products?.map(item => ({
          product_id: item?.id,
          unit_id: item?.unit?.id,
          quantity: undefined,
          warehouse_id: warehouseId,
          project_id: projectId,
          row_id: uid(12)
        })) ?? [])
      ],
      select_products: [
        ...(oldSelectProducts ?? []),
        ...(products?.map(item => ({
          id: item.id,
          name: item?.name,
          resource: item?.resource,
          price: item.price,
          status: item?.status,
          unit: item.unit,
          isForm: true
        })) ?? [])
      ]
    });
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      footer={footer}
      onCancel={onCancel}
      afterOpenChange={onOpenAfter}
      className={styles.order_modal}
      title={isEdit ? "Buyurtmani tahrirlash" : "Buyurtma yaratish"}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className={styles.order_modal__content}>
          <OrderModalLeft form={form} />
          <OrderModalRight form={form} />
        </div>
        <OrderEstimate clearAction={onClearProduct(true)} importAction={onImportEstimateProduct} />
        <OrderDrawer clearAction={onClearProduct(false)} importAction={onImportProduct} />
      </Form>
    </Modal>
  );
};

export default OrderModal;
