import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Popover, PopoverProps } from "antd";

import { appReducerActions } from "../../../../store/reducers/appReducer";
import { generalEditArgKeys } from "../../utils/constants/generalEditData";

import styles from "./generalEditing.module.scss";

type Props = {
  title: ReactNode;
  content: ReactNode;
  editKey: generalEditArgKeys;
  onOk: () => Promise<unknown>;
  setOpenParent: React.Dispatch<React.SetStateAction<boolean>>;
};

const GeneralEditingPopconfirm: React.FC<Props> = ({ onOk, content, setOpenParent, title: titleProps }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { setGeneralEditIsOpenChild } = appReducerActions;

  const onCancel = () => {
    setOpenPopover(false);
    dispatch(setGeneralEditIsOpenChild(false));
  };

  const onSave = () => {
    setLoading(true);

    onOk()
      .then(() => {
        setLoading(false);
        onCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const title = (
    <div className={styles.popconfirm}>
      <div className={styles.popconfirm__title}>{titleProps}</div>
      <div className={styles.popconfirm__body}>{content}</div>
      <div className={styles.popconfirm__footer}>
        <Button onClick={onCancel}>Yopish</Button>
        <Button type="primary" onClick={onSave} loading={loading}>
          Saqlash
        </Button>
      </div>
    </div>
  );

  const onOpenChange: PopoverProps["onOpenChange"] = open => {
    dispatch(setGeneralEditIsOpenChild(open));
    setOpenPopover(open);
    setOpenParent(true);
  };

  return (
    <Popover
      title={title}
      arrow={false}
      open={openPopover}
      trigger={["click"]}
      placement="leftBottom"
      onOpenChange={onOpenChange}
    >
      {titleProps}
    </Popover>
  );
};

export default GeneralEditingPopconfirm;
