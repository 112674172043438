import React from "react";

const RefreshCircleIcon: React.FC = () => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" width="32" height="32" rx="16" fill="#1E90FF" />
    <path
      d="M19.833 11.4172C21.2472 12.4475 22.1663 14.1165 22.1663 16.0002C22.1663 19.1298 19.6293 21.6669 16.4997 21.6669H16.1663M13.1663 20.5833C11.7521 19.5529 10.833 17.8839 10.833 16.0002C10.833 12.8706 13.3701 10.3336 16.4997 10.3336H16.833M17.1663 22.9336L15.833 21.6002L17.1663 20.2669M15.833 11.7336L17.1663 10.4002L15.833 9.06689"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RefreshCircleIcon;
