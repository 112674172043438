import { Tabs, Form, Input, Modal, Select, Switch, ColorPicker, FormListOperation } from "antd";
import React from "react";
import { Color } from "antd/es/color-picker";
import { useTranslation } from "react-i18next";

import { useCreateCustomField, useUpdateCustomField } from "../../../service/mutations";
import { Status } from "../../../../app/utils/models/status";
import { useGetCustomFieldView } from "../../../service/queries";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import { useGetLanguages } from "../../../../app/service/queries";
import { formRules } from "../../../../app/utils/constants/formRules";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { CustomFieldOptions } from "../../../utils/constants/customFieldTypes";
import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import { selectFilterOption } from "../../../../app/utils/helpers/selectFilterOption";
import { CustomFieldTypesEnum } from "../../../../app/utils/enums/customFieldTypesEnum";
import { ReqDataCustomField } from "../../../utils/models/custom-field/reqDataCustomField";
import { CustomFieldLocationValue } from "../../../utils/constants/customFieldLocationValue";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";

import styles from "./customFieldModal.module.scss";

const { Option } = Select;
const { useForm, useWatch, Item, List } = Form;

interface FormDataModel extends Omit<ReqDataCustomField, "status"> {
  status: boolean;
}

type Props = {
  afterFunc?: () => void;
};

const CustomFieldModal: React.FC<Props> = ({ afterFunc }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [form] = useForm<FormDataModel>();
  const type = useWatch("type", form);
  const { visible, id } = useAppSelector(state => state.settingsReducer.customField);
  const { data } = useGetCustomFieldView(id);
  const { data: languages } = useGetLanguages();
  const { setCustomFieldModal } = settingsActions;
  const createCustomField = useCreateCustomField();
  const updateCustomField = useUpdateCustomField();

  const generateLangKeys = () => {
    let result = {};

    languages?.forEach(item => {
      result = {
        ...result,
        [item.symbol]: ""
      };
    });

    return result;
  };

  const getMyColor = (): string => {
    const n = (Math.random() * 0xfffffff * 800000).toString(16);
    return "#" + n.slice(0, 6);
  };

  const onOk = () => form.submit();

  const onCancel = () => {
    dispatch(setCustomFieldModal({ visible: false }));
  };



  const onAfterOpenModal = (open: boolean) => {
    if (open) {
      if (id) {
        form.setFieldsValue({
          id: data?.id,
          name: data?.name,
          type: data?.type,
          description: data?.description,
          options: data?.custom_field_options,
          status: data?.status === Status.ACTIVE,
          locations: data?.custom_field_locations
        });
      }
    } else {
      form.resetFields();
    }
  };

  const tabChildren = (lang: string, index: number) => (
    <React.Fragment>
      <Item
        label={t("settings.Nomi")}
        name={["name", lang]}
        className={styles.name}
        rules={index === 0 ? formRules() : []}
      >
        <Input placeholder={t("products.Nomini kiriting")} />
      </Item>
      <ConditionalRender if={type === CustomFieldTypesEnum.LABEL || type === CustomFieldTypesEnum.SELECT}>
        <List
          name="options"
          initialValue={[
            {
              name: generateLangKeys(),
              color: getMyColor()
            }
          ]}
        >
          {(fields, operation) => (
            <div className={styles.options}>
              <p>{t("settings.Teglar")}</p>
              {fields.map((field, index) => (
                <div className={styles.option} key={field.key}>
                  <div className={styles.option__inner}>
                    <Item name={[index, "color"]}>
                      <ColorPicker size="small" />
                    </Item>
                    <Item
                      name={[index, "name", lang]}
                      className={styles.option__input}
                      rules={lang === i18n.language ? formRules()! : []}
                    >
                      <Input className="option__input" placeholder={t("settings.Tag nomini kiriting")} />
                    </Item>
                  </div>
                  <div className={styles.option__delete} onClick={onRemoveOption(operation, index)}>
                    <DeleteIcon />
                  </div>
                </div>
              ))}
              <div className={styles.options__add} onClick={onAddOption(operation)}>
                <PlusCircleIcon fill />
                <span>{t("settings.Yangi tag qo’shish")}</span>
              </div>
            </div>
          )}
        </List>
      </ConditionalRender>
    </React.Fragment>
  );

  const items =
    languages?.map((language, index) => ({
      key: language.id,
      forceRender: true,
      label: language?.symbol?.toUpperCase(),
      children: tabChildren(language.symbol, index)
    })) ?? [];

  const onAddOption = (operation: FormListOperation) => () => {
    operation.add({ name: generateLangKeys(), color: getMyColor() });
  };

  const onRemoveOption = (operation: FormListOperation, index: number) => () => {
    operation.remove(index);
  };

  const onAfterCreate = () => {
    afterFunc && afterFunc();
    onCancel();
  };

  const generateReqLangData = (values: { name: Record<string, string> }) => {
    const { name } = values;
    let result = {};

    for (const key in name) {
      result = {
        ...result,
        [key]: name[key] ? name[key] : name[i18n.language]
      };
    }

    return result;
  };

  const generateReqOptionData = (values: FormDataModel) => {
    const { options } = values;

    return options?.map(item => ({
      ...item,
      name: generateReqLangData({ name: item?.name }),
      color: typeof item.color === "string" ? item.color : (item.color as Color).toHexString()
    }));
  };

  const onFinish = (values: FormDataModel) => {
    const reqData = {
      ...values,
      name: generateReqLangData(values),
      options: generateReqOptionData(values),
      status: values?.status ? "active" : "passive"
    };

    if (id) {
      reqData.id = id;
      updateCustomField.mutateAsync(reqData).then(onCancel);
    } else {
      createCustomField.mutateAsync(reqData).then(onAfterCreate);
    }
  };

  return (
    <Modal
      width={744}
      onOk={onOk}
      open={visible}
      title={t("settings.O'zgaruvchi")}
      onCancel={onCancel}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
      afterOpenChange={onAfterOpenModal}
      className={styles.custom_field_modal}
      okButtonProps={{
        loading: createCustomField.isLoading || updateCustomField.isLoading
      }}
    >
      <Form form={form} layout="vertical" onFinish={onFinish} className={styles.form}>
        <Item name="type" label={t("settings.Turi")} rules={formRules()}>
          <Select placeholder={t("settings.Tanlang")} suffixIcon={<SelectSuffixIcon />}>
            {Object.entries(CustomFieldOptions).map(([key, value]) => (
              <Option key={key} value={key}>
                <div className={styles.select__type}>
                  <span>{value.icon}</span>
                  <span>{value.name[i18n.language]}</span>
                </div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="locations" label="Joylashuv" rules={formRules()}>
          <Select
            showSearch
            mode="multiple"
            placeholder={t("settings.Tanlang")}
            notFoundContent={<TableEmpty />}
            suffixIcon={<SelectSuffixIcon />}
            filterOption={selectFilterOption}
          >
            {Object.entries(CustomFieldLocationValue).map(([key, value]) => (
              <Option
                key={key}
                value={key}
                props={{
                  name: value
                }}
              >
                <div className={styles.select__location}>{value}</div>
              </Option>
            ))}
          </Select>
        </Item>
        <Item name="description" label={t("settings.Izoh")}>
          <Input placeholder={t("settings.Izoh kiring")} />
        </Item>
        <Item name="status" label={t("settings.Status")} initialValue={true} valuePropName="checked">
          <Switch />
        </Item>
        <Tabs defaultActiveKey="1" items={items as never} className={styles.tab} />
      </Form>
    </Modal>
  );
};

export default CustomFieldModal;
