import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Select, Tabs } from "antd";
import classNames from "classnames";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import WorkModal from "features/projects/components/detailed-project/work/modal/WorkModal";
import { routeSubmodules } from "features/projects/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { projectsReducerActions } from "store/reducers/projectsReducer";

import { useAppDispatch } from "../../../../hooks/redux";
import { rootPaths } from "../../../../routes/root/rootPaths";
import { useUpdateProject } from "../../service/mutations";
import { useGetDetailedProject } from "../../service/queries";
import { statusOptions } from "../../utils/constants/statusOptions";
import { EstimateKeyEnum } from "../../utils/enums/estimateKeyEnum";

import styles from "./detailedProject.module.scss";

const { Option } = Select;

export const DetailedProject: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId } = useParams();
  const dispatch = useAppDispatch();
  const updateProjectStatus = useUpdateProject();
  const { setProjectCurrency } = projectsReducerActions;
  const { data } = useGetDetailedProject(Number(projectId));
  const [statusProject, setStatusProject] = useState<string>();
  const tabDefaultKey = location?.pathname?.split("/");

  const { submodules } = useRoutePermissions("Loyiha", routeSubmodules);

  useEffect(() => {
    if (data) {
      setStatusProject(data.status);
      dispatch(setProjectCurrency(data?.currency));
    }
  }, [data]);

  const allTabsItems = [
    {
      key: EstimateKeyEnum.ESTIMATE,
      label: `${t("project.Smeta")}`,
      submoduleKey: "Smeta"
    },
    {
      key: EstimateKeyEnum.WORK,
      label: `${t("project.Ishlar")}`,
      submoduleKey: "Ishlar"
    }
  ];

  const tabsItems = () =>
    allTabsItems?.filter(item => submodules.some(submodule => submodule.key === item.submoduleKey));

  const onTabChange = (key: string) => {
    navigate(key);
  };

  const goBack = () => {
    if (location?.state?.path) {
      navigate(location?.state?.path);
    } else {
      navigate(rootPaths.PROJECTS);
    }
  };

  const onChangeStatus = (e: string) => {
    setStatusProject(e);

    updateProjectStatus.mutate({
      status: e,
      id: data?.id,
      name: data!.name!
    });
  };

  return (
    <>
      <div className={styles.page}>
        <div className={styles.page__header}>
          <div className={styles.page__header__left}>
            <span onClick={goBack}>
              <ArrowNarrowIcon />
            </span>
            <h4>{data?.name}</h4>
            <Select suffixIcon={null} value={statusProject} onChange={onChangeStatus} popupMatchSelectWidth={false}>
              {statusOptions.map((item, index) => (
                <Option value={item.value} key={index} className="status-option">
                  <div className={classNames("status", item.value, styles.option)}>
                    {item.icon}
                    <span>{t(`project.${item.label}`)}</span>
                    <SelectSuffixIcon stroke={item.stroke} className={styles.suffix__icon} />
                  </div>
                </Option>
              ))}
            </Select>
          </div>
          <div className={styles.page__header__center}>
            <Tabs
              items={tabsItems()}
              onChange={onTabChange}
              defaultActiveKey={tabDefaultKey[tabDefaultKey.length - 1]}
            />
          </div>
          <div className={styles.page__header__right}>{/* <SettingsIcon /> */}</div>
        </div>
        <div className={styles.page__body}>
          <Outlet />
        </div>
      </div>
      <WorkModal />
    </>
  );
};
