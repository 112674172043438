import React from "react";
import { Form, Input } from "antd";
import { formRules } from "../../../utils/constants/formRules";

const { Item } = Form;
export interface Props {
  className: string;
  inputClassName: string;
}

const CorporateItem: React.FC<Props> = ({ className, inputClassName }) => {
  return (
    <div className={className}>
      <Item
        name="name"
        label="Korxona nomi"
        rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
      <Item
        name="owner"
        label="Korxona rahbari"
        rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
    </div>
  );
};

export default CorporateItem;
