import { DataNode } from "antd/es/tree";
import { FC, Key, useEffect, useMemo, useState } from "react";
import { FormInstance, Select } from "antd";

import { Tree } from "../tree/Tree";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon";

import styles from "./treeSelect.module.scss";
import { stringifyTreeData } from "../../utils/helpers/stringifyTreeData";

type Props = {
  formInstance: FormInstance;
  data: DataNode[];
  className?: string;
  name: string;
  initialData?: DataNode[];
  title?: string;
  addBtn?: React.ReactNode;
  // showSearch?: boolean;
  notFoundContent?: React.ReactNode;
  // onSearch?: (e: string) => void;
  // searchText?: string;
};

export const TreeSelect: FC<Props> = ({
  formInstance,
  data,
  className,
  name,
  initialData,
  title,
  addBtn,
  // showSearch,
  notFoundContent,
  // onSearch,
  // searchText,
}) => {
  const [keys, setKeys] = useState<Key[]>([]);
  const [isCleared, setIsCleared] = useState<boolean>(false);

  const selected = useMemo(() => {
    const parsed = keys.map((key) => JSON.parse(key as string));
    const result: string[] = [];

    const append = (arr: DataNode[]) => {
      arr.forEach((item) => {
        const parsedItem = JSON.parse(item.key as string);
        const includes = parsed.find(
          (item) => item.value === parsedItem.value && item.key === parsedItem.key
        );

        if (includes) result.push(item.title as string);

        if (!isEmptyArr(item.children)) append(item.children!);
      });
    };

    append(data);

    return result;
  }, [keys, data]);

  const dropdown = () => {
    return (
      <>
        {data.length > 0 && (
          <div className={styles.dropdown}>
            <Tree
              formInstance={formInstance}
              data={data}
              setData={setKeys}
              initialData={initialData}
              title={title ?? "Barcha kassalar"}
              name={name}
              isCleared={isCleared}
            />
            {addBtn}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (initialData) {
      setKeys(stringifyTreeData(initialData));
    }
  }, [initialData]);

  return (
    <Select
      className={`${className} ${styles.select}`}
      dropdownRender={dropdown}
      mode="tags"
      value={selected.map((item, index) => {
        return {
          key: index,
          value: item,
        };
      })}
      removeIcon={false}
      suffixIcon={<SelectSuffixIcon />}
      allowClear={true}
      onClear={() => {
        setIsCleared(!isCleared);
        setKeys([]);
      }}
      notFoundContent={notFoundContent}
      placeholder="Tanlang"
      showSearch={false}
    />
  );
};
