import React from "react";

import { OrderDetailModel } from "../../../../utils/models/orderModel";

import styles from "./partyPrint.module.scss";

type Props = {
  partyId?: number;
  oneOrder: OrderDetailModel | undefined;
  sameProject?: string;
  sameWarehouse?: string;
};

const PartyPrintCard: React.FC<Props> = ({
  partyId,
  oneOrder,
  sameProject,
  sameWarehouse,
}) => {
  return (
    <div className={styles.party_print__top}>
      <div className={styles.card}>
        <h5>P-{partyId}</h5>
        <div className={styles.card__bottom}>
          <div className={styles.card__item}>
            <div className={styles.card__item__name}>Vositachi</div>
            <div className={styles.card__item__value}>
              {oneOrder?.agent?.full_name ?? "-"}
            </div>
          </div>
          {sameWarehouse && (
            <div className={styles.card__item}>
              <div className={styles.card__item__name}>Omborxona</div>
              <div className={styles.card__item__value}>{sameWarehouse}</div>
            </div>
          )}
          {sameProject && (
            <div className={styles.card__item}>
              <div className={styles.card__item__name}>Loyiha</div>
              <div className={styles.card__item__value}>{sameProject}</div>
            </div>
          )}
          <div className={styles.card__item}>
            <div className={styles.card__item__name}>Y/S</div>
            <div className={styles.card__item__value}>
              {oneOrder?.delivery_date ?? "-"}
            </div>
          </div>
          <div className={styles.card__item}>
            <div className={styles.card__item__name}>T/S</div>
            <div className={styles.card__item__value}>
              {oneOrder?.payment_date ?? "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartyPrintCard;
