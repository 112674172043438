import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

export const warehouseExportProductsExcel: UserTableDataModel = {
  column: [
    {
      id: "name",
      name: "name",
      title: "Omborxona nomi",
      checked: true
    },
    {
      id: "total_quantity",
      name: "total_quantity",
      title: "Mahsulot umumiy soni",
      checked: true
    }
  ],
  size: 10
};
