import React from "react";
import { FormInstance } from "antd";
import { DataNode } from "antd/es/tree";

import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import SelectNotContent from "../../../../app/components/select-not-content/SelectNotContent";
import { TreeSelect } from "../../../../app/components/tree-select/TreeSelect";
import { ProjectFormFieldsModel } from "../../../utils/models/projectFormFieldsModel";

import styles from "../projectModal.module.scss";
import { useTranslation } from "react-i18next";

export interface Props {
  formInstance: FormInstance<ProjectFormFieldsModel>;
  data: DataNode[];
  initialData: DataNode[] | undefined;
  className: string;
  setOpenCreateCashModal: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id?: number;
      name?: string;
    }>
  >;
}

const CashTreeSelect: React.FC<Props> = ({ formInstance, data, initialData, className, setOpenCreateCashModal }) => {
  const { t } = useTranslation();
  const onOpenCashCreateModal = () => {
    setOpenCreateCashModal({
      visible: true
    });
  };

  const addBtn = (
    <div className={styles.add_project} onClick={onOpenCashCreateModal}>
      <PlusCircleIcon fill /> {t("project.Kassa yaratish")}
    </div>
  );

  return (
    <TreeSelect
      formInstance={formInstance}
      data={data}
      className={className}
      name="cash_ids"
      initialData={initialData}
      addBtn={addBtn}
      notFoundContent={
        <SelectNotContent
          action={onOpenCashCreateModal}
          title={t("project.Kassa")}
          description={t("project.Kassa mavjud emas,iltimos yangi qo'shing")}
        />
      }
    />
  );
};

export default CashTreeSelect;
