import React, { ReactNode, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Popover, PopoverProps } from "antd";
import { useTranslation } from "react-i18next";

import { appReducerActions } from "../../../../store/reducers/appReducer";
import FileAttachmentIcon from "../../../payment/assets/icons/FileAttachmentIcon";
import ArrowSquareDownIcon from "../../../settings/assets/icons/ArrowSquareDownIcon";
import CheckSquareIcon from "../../../settings/assets/icons/CheckSquareIcon";
import TagIcon from "../../../settings/assets/icons/TagIcon";
import TypeSquareIcon from "../../../settings/assets/icons/TypeSquareIcon";
import CalendarIcon from "../../../supply/assets/icons/CalendarIcon";
import { CustomFieldTypesEnum } from "../../utils/enums/customFieldTypesEnum";
import { CustomFieldSelectModel } from "../../utils/models/customFieldSelectModel";

import GeneralEditingFieldCheckbox from "./custom-field-items/GeneralEditingFieldCheckbox";
import GeneralEditingFieldDate from "./custom-field-items/GeneralEditingFieldDate";
import GeneralEditingFieldFile from "./custom-field-items/GeneralEditingFieldFile";
import GeneralEditingFieldInput from "./custom-field-items/GeneralEditingFieldInput";
import GeneralEditingFieldLabel from "./custom-field-items/GeneralEditingFieldLabel";
import GeneralEditingFieldSelect from "./custom-field-items/GeneralEditingFieldSelect";

import styles from "./generalEditing.module.scss";

type Props = {
  item: CustomFieldSelectModel;
  onOk: () => Promise<unknown>;
  setOpenParent: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

type CustomFieldContentType = Record<
  string,
  {
    content: ReactNode;
    titleIcon: ReactNode;
  }
>;

const GeneralEditingCustomFieldPopconfirm: React.FC<Props> = ({ item, setOpenParent, onOk, setData }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const titleText = item?.name[i18n.language];
  const [loading, setLoading] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const { setGeneralEditIsOpenChild } = appReducerActions;

  const onCancel = () => {
    setOpenParent(false);
    setOpenPopover(false);
    dispatch(setGeneralEditIsOpenChild(false));
  };

  const onSave = () => {
    setLoading(true);

    onOk()
      .then(() => {
        setLoading(false);
        onCancel();
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const popconfirmCustomFieldContent: CustomFieldContentType = useMemo(
    () => ({
      [CustomFieldTypesEnum.FILE]: {
        content: <GeneralEditingFieldFile item={item} setData={setData} />,
        titleIcon: <FileAttachmentIcon />
      },
      [CustomFieldTypesEnum.DATE]: {
        content: <GeneralEditingFieldDate setData={setData} item={item} />,
        titleIcon: <CalendarIcon />
      },
      [CustomFieldTypesEnum.TEXT]: {
        content: <GeneralEditingFieldInput setData={setData} item={item} />,
        titleIcon: <TypeSquareIcon />
      },
      [CustomFieldTypesEnum.SELECT]: {
        content: <GeneralEditingFieldSelect setData={setData} item={item} />,
        titleIcon: <ArrowSquareDownIcon />
      },
      [CustomFieldTypesEnum.CHECKBOX]: {
        content: <GeneralEditingFieldCheckbox setData={setData} item={item} />,
        titleIcon: <CheckSquareIcon />
      },
      [CustomFieldTypesEnum.LABEL]: {
        content: <GeneralEditingFieldLabel setData={setData} item={item} />,
        titleIcon: <TagIcon />
      }
    }),
    [item, setData]
  );

  const title = (
    <div className="flex items-center gap-2">
      {item?.type && popconfirmCustomFieldContent[item.type]?.titleIcon}
      <span>{titleText}</span>
    </div>
  );

  const titleContent = (
    <div className={styles.popconfirm}>
      <div className={styles.popconfirm__title}>{title}</div>
      <div className={styles.popconfirm__body}>{item?.type && popconfirmCustomFieldContent[item?.type]?.content}</div>
      <div className={styles.popconfirm__footer}>
        <Button onClick={onCancel}>Yopish</Button>
        <Button type="primary" onClick={onSave} loading={loading}>
          Saqlash
        </Button>
      </div>
    </div>
  );

  const onOpenChange: PopoverProps["onOpenChange"] = open => {
    dispatch(setGeneralEditIsOpenChild(open));
    setOpenPopover(open);
    setOpenParent(true);
  };

  return (
    <Popover
      arrow={false}
      open={openPopover}
      trigger={["click"]}
      title={titleContent}
      placement="leftBottom"
      onOpenChange={onOpenChange}
    >
      {title}
    </Popover>
  );
};

export default GeneralEditingCustomFieldPopconfirm;
