import React from "react";
import { CheckIcon } from "features/app/assets/icons/CheckIcon";
import { StatusEnums } from "features/app/components/statuses/statusEnums";
import { colors } from "features/app/utils/constants/colors";

import { partyProcessData } from "./party-process-data";

import styles from "./party-process.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  supply_status: StatusEnums;
  monitoring_status: StatusEnums;
  warehouse_status: number;
  payment_status: number;
};

const PartyProcess: React.FC<Props> = ({ monitoring_status, payment_status, supply_status, warehouse_status }) => {
  const { t } = useTranslation();
  const getWarehouseStylesAndText = () => {
    if (warehouse_status === 0) {
      return {
        icon: null,
        borderColor: colors.GRAY_100,
        backgroundColor: colors.GRAY_100,
        textAndIconColor: colors.GRAY_600,
        text: t("partyProcess.Qabul qilinmagan"),
        process_number: 3
      };
    }
    if (warehouse_status > 0 && warehouse_status < 100) {
      return {
        icon: null,
        borderColor: colors.PRIMARY_100,
        backgroundColor: colors.PRIMARY_100,
        textAndIconColor: colors.PRIMARY_600,
        text: `${t("partyProcess.Jarayonda")}-${warehouse_status}%`,
        process_number: 3
      };
    }
    return {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      borderColor: colors.SUCCESS_100,
      backgroundColor: colors.SUCCESS_100,
      textAndIconColor: colors.SUCCESS_500,
      text: `${t("partyProcess.Qabul qilingan")}-${warehouse_status}%`,
      process_number: 3
    };
  };

  const getCashStylesAndText = () => {
    if (payment_status === 0) {
      return {
        icon: null,
        borderColor: colors.GRAY_100,
        backgroundColor: colors.GRAY_100,
        textAndIconColor: colors.GRAY_600,
        text: t("partyProcess.To'lanmagan"),
        process_number: 4
      };
    }
    if (payment_status > 0 && payment_status < 100) {
      return {
        icon: null,
        borderColor: colors.PRIMARY_100,
        backgroundColor: colors.PRIMARY_100,
        textAndIconColor: colors.PRIMARY_600,
        text: `${t("partyProcess.Jarayonda")}-${payment_status}%`,
        process_number: 4
      };
    }
    return {
      icon: <CheckIcon color={colors.WHITE} width={16} />,
      borderColor: colors.SUCCESS_100,
      backgroundColor: colors.SUCCESS_100,
      textAndIconColor: colors.SUCCESS_500,
      text: t("partyProcess.To'langan"),
      process_number: 4
    };
  };

  return (
    <div className="flex items-center justify-center">
      <div
        className={styles.card}
        style={{
          borderColor: partyProcessData["Ta'minot"][supply_status]?.bg_color,
          backgroundColor: partyProcessData["Ta'minot"][supply_status]?.bg_color
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: partyProcessData["Ta'minot"][supply_status]?.icon_container_bg_color,
            backgroundColor: partyProcessData["Ta'minot"][supply_status]?.icon_container_bg_color
          }}
        >
          {partyProcessData["Ta'minot"][supply_status]?.icon ||
            partyProcessData["Ta'minot"][supply_status]?.process_number}
        </div>
        <span className={styles.title}>{t("partyProcess.Ta'minot")}</span>
        <span
          className={styles.status_container}
          style={{
            color: partyProcessData["Ta'minot"][supply_status]?.icon_container_bg_color
          }}
        >
          {t(`partyProcess.${partyProcessData["Ta'minot"][supply_status]?.text}`)}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: partyProcessData["Ta'minot"][supply_status]?.icon_container_bg_color,
          backgroundColor: partyProcessData["Ta'minot"][supply_status]?.icon_container_bg_color
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: partyProcessData.Monitoring[monitoring_status]?.bg_color,
          backgroundColor: partyProcessData.Monitoring[monitoring_status]?.bg_color
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: partyProcessData.Monitoring[monitoring_status]?.icon_container_bg_color,
            backgroundColor: partyProcessData.Monitoring[monitoring_status]?.icon_container_bg_color
          }}
        >
          {partyProcessData.Monitoring[monitoring_status]?.icon ||
            partyProcessData.Monitoring[monitoring_status]?.process_number}
        </div>
        <span className={styles.title}>{t("partyProcess.Monitoring")}</span>
        <span
          className={styles.status_container}
          style={{
            color: partyProcessData.Monitoring[monitoring_status]?.icon_container_bg_color
          }}
        >
          {t(`partyProcess.${partyProcessData.Monitoring[monitoring_status]?.text}`)}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: partyProcessData.Monitoring[monitoring_status]?.icon_container_bg_color,
          backgroundColor: partyProcessData.Monitoring[monitoring_status]?.icon_container_bg_color
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: getWarehouseStylesAndText().borderColor,
          backgroundColor: getWarehouseStylesAndText().backgroundColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: getWarehouseStylesAndText().textAndIconColor,
            backgroundColor: getWarehouseStylesAndText().textAndIconColor
          }}
        >
          {getWarehouseStylesAndText().icon || getWarehouseStylesAndText().process_number}
        </div>
        <span className={styles.title}>{t("partyProcess.Omborxona")}</span>
        <span
          className={styles.status_container}
          style={{
            color: getWarehouseStylesAndText().textAndIconColor
          }}
        >
          {getWarehouseStylesAndText().text}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: getWarehouseStylesAndText().textAndIconColor,
          backgroundColor: getWarehouseStylesAndText().textAndIconColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: getCashStylesAndText().borderColor,
          backgroundColor: getCashStylesAndText().backgroundColor
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: getCashStylesAndText().textAndIconColor,
            backgroundColor: getCashStylesAndText().textAndIconColor
          }}
        >
          {getCashStylesAndText().icon || getCashStylesAndText().process_number}
        </div>
        <span className={styles.title}>Kassa</span>
        <span
          className={styles.status_container}
          style={{
            color: getCashStylesAndText().textAndIconColor
          }}
        >
          {getCashStylesAndText().text}
        </span>
      </div>
      <span
        className={styles.line}
        style={{
          borderColor: getCashStylesAndText().textAndIconColor,
          backgroundColor: getCashStylesAndText().textAndIconColor
        }}
      />
      <div
        className={styles.card}
        style={{
          borderColor: partyProcessData["Oxirgi_Ta'minot"][supply_status]?.bg_color,
          backgroundColor: partyProcessData["Oxirgi_Ta'minot"][supply_status]?.bg_color
        }}
      >
        <div
          className={styles.icon_container}
          style={{
            borderColor: partyProcessData["Oxirgi_Ta'minot"][supply_status]?.icon_container_bg_color,
            backgroundColor: partyProcessData["Oxirgi_Ta'minot"][supply_status]?.icon_container_bg_color
          }}
        >
          {partyProcessData["Oxirgi_Ta'minot"][supply_status]?.icon ||
            partyProcessData["Oxirgi_Ta'minot"][supply_status]?.process_number}
        </div>
        <span className={styles.title}>{t("partyProcess.Ta'minot")}</span>
        <span
          className={styles.status_container}
          style={{
            color: partyProcessData["Oxirgi_Ta'minot"][supply_status]?.icon_container_bg_color
          }}
        >
          {t(`partyProcess.${partyProcessData["Oxirgi_Ta'minot"][supply_status]?.text}`)}
        </span>
      </div>
    </div>
  );
};

export default PartyProcess;
