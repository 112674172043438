import { AllSubmodules } from "../../../../hooks/useRoutePermissions";

export function findFirstCommonElement(arr1: string[], arr2: AllSubmodules[]) {
  const set1 = new Set(arr1);

  for (let i = 0; i < arr2.length; i++) {
    if (set1.has(arr2[i]?.key)) {
      return arr2[i]?.key; // Birinchi teng bo'lgan qiymatni qaytarish
    }
  }

  return null; // Teng bo'lgan element topilmadi
}
