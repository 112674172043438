import { ReactNode } from "react";
import CheckCircleIcon from "../../assets/icons/CheckCircleIcon";
import { ConfirmationUserStatusEnum } from "../enums/confirmationUserStatusEnum";
import CloseCircleIcon from "features/app/assets/icons/CloseCircleIcon";

export const ConfirmationStatusIcons: Record<ConfirmationUserStatusEnum, ReactNode> = {
  [ConfirmationUserStatusEnum.OPEN]: <CheckCircleIcon stroke="#D0D5DD" />,
  [ConfirmationUserStatusEnum.RECIEVED]: <CheckCircleIcon />,
  [ConfirmationUserStatusEnum.REJECTED]: <CloseCircleIcon />
};
