import React, { useState } from "react";
import { Button, Spin } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { CategoryModel } from "features/settings/utils/models/category/CategoryModel";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import FolderCard from "../../../app/components/folder-card/FolderCard";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../app/components/not-data/NotData";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import { parseToCrumbName } from "../../../app/utils/helpers/parseToCrumbName";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import DropdownCloudIcon from "../../assets/icons/DropdownCloudIcon";
import DropIcon from "../../assets/icons/DropIcon";
import FolderPlusIcon from "../../assets/icons/FolderPlusIcon";
import SettingsProductsTemplate from "../../components/products/template/SettingsProductsTemplate";
import UnitSettingsModal from "../../components/products/unit-modal/UnitSettingsModal";
import { useCreateCategory, useDeleteCategory, useUpdateCategory } from "../../service/mutations";
import { useGetCategories } from "../../service/queries";
import { folderName } from "../../utils/helper/folderName";

import emptyImage from "../../assets/images/products.png";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { data, isLoading } = useGetCategories();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [unitModalVisible, setUnitModalVisible] = useState<boolean>(false);
  const { searchParams, reqQueryParam } = useQueryParams();

  const createCategory = useCreateCategory();
  const updateCategory = useUpdateCategory();
  const deleteCategory = useDeleteCategory();

  const onCreateFolder = () => {
    setIsCreating(true);
  };

  const onShowDrawer = () => {
    setVisibleDrawer(true);
  };

  const onOpenUnitModal = () => {
    setUnitModalVisible(true);
  };

  const folderPath = (record: CategoryModel): string => {
    let path = `${rootPaths.SETTINGS}${
      settingsPaths.PRODUCTS
    }/${parseToCrumbName(record.name[i18n.language])}-${record.id}`;

    if (searchParams && searchParams?.length > 0) {
      path += `?${reqQueryParam(queryParamsKeys.SEARCH)}`;
    }
    return path;
  };

  return (
    <div className={styles.products}>
      <div className={styles.products__top}>
        <div className={styles.left}>
          <h2>{t("products.Mahsulotlar katalogi")}</h2>
          <p>{t("products.Bu yerda siz mahsulotlar katalogi yaratishingiz mumkin")}</p>
        </div>
        <div className={styles.right}>
          <Button onClick={onOpenUnitModal}>
            <DropIcon /> {t("products.Birlik sozlanmasi")}
          </Button>
          <Button onClick={onShowDrawer}>
            <DropdownCloudIcon /> {t("products.Tayyor shablonlar")}
          </Button>
        </div>
      </div>
      <div className={styles.products__bottom}>
        {!isEmptyArr(data) && (
          <div className={styles.products__bottom__header}>
            <h4> {t("products.Kataloglar")} </h4>
            <div>
              <PaymentSearch />
            </div>
          </div>
        )}
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {isEmptyArr(data) && !isCreating ? (
            <div className={styles.no_data_container}>
              <div className={styles.search_container}>
                <span>
                  <PaymentSearch />
                </span>
              </div>
              <NotData
                containerClassName={styles.empty__container}
                img={{
                  src: emptyImage,
                  className: styles.empty_image
                }}
                name={{ text: `${t("Mahsulotlar katalogi mavjud emas")}` }}
                text={{
                  text: `${t("Ushbu mahsulotlar katologini qo’shish orqali mahsulotlaringizni boshqaring")}`
                }}
                defaultButton={{
                  text: `${t("products.Katalog yaratish")}`,
                  onClick: onCreateFolder,
                  icon: <FolderPlusIcon />
                }}
                primaryButton={{
                  text: `${t("products.Shablon yuklash")}`,
                  onClick: onShowDrawer,
                  className: styles.primary__button,
                  icon: <DropdownCloudIcon />
                }}
              />
            </div>
          ) : (
            <div className={styles.catalogs}>
              {data?.map(item => (
                <FolderCard
                  id={item.id}
                  key={item.id}
                  isReqNameObject
                  color={item.color}
                  sliceTextCount={20}
                  placeholder={t("products.mahsulot")}
                  count={item.products_count}
                  name={folderName(item.name)}
                  updateMutation={updateCategory}
                  deleteMutation={deleteCategory}
                  path={folderPath(item)}
                />
              ))}

              {isCreating && (
                <FolderCard
                  isReqNameObject
                  placeholder={t("products.mahsulot")}
                  isCreating={isCreating}
                  setIsCreating={setIsCreating}
                  createMutation={createCategory}
                />
              )}
              <div className={styles.new_folder} onClick={onCreateFolder}>
                <FolderPlusIcon /> {t("products.Katalog yaratish")}
              </div>
            </div>
          )}
        </Spin>
      </div>
      <SettingsProductsTemplate setVisibleDrawer={setVisibleDrawer} visibleDrawer={visibleDrawer} />
      <UnitSettingsModal visible={unitModalVisible} setVisible={setUnitModalVisible} />
    </div>
  );
};

export default Index;
