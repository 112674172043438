import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Col, Row } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { OfferPartyLeftModel } from "../../../../../../utils/models/offerPartyModel";

import HeaderProjectSelect from "./HeaderProjectSelect";
import HeaderWarehouseSelect from "./HeaderWarehouseSelect";

import styles from "../../offerPartyModalLeft.module.scss";

type Props = {
  fields: OfferPartyLeftModel[];
  isViewCompanyPerson?: boolean;
};

const OfferPartyModalTableHeader: React.FC<Props> = ({ fields, isViewCompanyPerson }) => {
  const dispatch = useDispatch();
  const { setCheckData } = supplyOfferActions;
  const { checkData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onCheck = (e: CheckboxChangeEvent) => {
    if (e.target.checked) {
      const newCheckData: OfferPartyLeftModel[] = [...checkData];

      fields?.forEach(item => {
        const isSome = checkData?.some(c => c.unique_id === item.unique_id);

        if (!isSome) {
          newCheckData.push(item);
        }
      });

      dispatch(setCheckData(newCheckData));
    } else {
      dispatch(setCheckData(checkData?.filter(item => !fields.some(s => s.unique_id === item.unique_id))));
    }
  };

  const checkValue = () => {
    const newData = [];

    checkData?.forEach(item => {
      if (fields?.some(s => s.unique_id === item.unique_id)) {
        newData.push(item);
      }
    });

    return newData?.length === fields?.length;
  };

  return (
    <div className={styles.table__header}>
      <Row gutter={0}>
        <Col span={1} className={styles.table__header__item}>
          <span className={styles.table__header__checkbox}>
            <Checkbox checked={checkValue()} onChange={onCheck} />
          </span>
        </Col>
        <Col span={isViewCompanyPerson ? 4 : 5} className={styles.table__header__item}>
          Resurs turi va nomi
        </Col>
        <Col span={2} className={styles.table__header__item}>
          Birligi
        </Col>
        <Col span={2} className={styles.table__header__item}>
          Soni
        </Col>
        <Col span={2} className={styles.table__header__item}>
          Birlik summasi
        </Col>
        <Col
          span={isViewCompanyPerson ? 2 : 3}
          className={cx(styles.table__header__item, styles.table__header__select)}
        >
          <HeaderWarehouseSelect />
        </Col>
        <Col
          span={isViewCompanyPerson ? 2 : 3}
          className={cx(styles.table__header__item, styles.table__header__select)}
        >
          <HeaderProjectSelect />
        </Col>
        {isViewCompanyPerson && (
          <Col span={3} className={cx(styles.table__header__item)}>
            Ta'minotchi
          </Col>
        )}
        <Col span={2} className={cx(styles.table__header__item)}>
          Valyuta
        </Col>
        <Col span={4} className={styles.table__header__item}>
          Umumiy summa
        </Col>
      </Row>
    </div>
  );
};

export default OfferPartyModalTableHeader;
