import React from "react";
import { Form, FormInstance, FormListOperation, Input } from "antd";
import { Rule } from "antd/es/form";

import BuildingIcon from "../../../../../app/assets/icons/BuildingIcon";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../app/utils/constants/languages";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import { ExchangeFormModel } from "../../../../utils/models/exchangeFormModel";

import styles from "../../transfer-drawer/top/project-card/project-card.module.scss";

type Props = {
  index: number;
  parentName: string[];
  name: (string | number)[];
  operation: FormListOperation;
  form: FormInstance<ExchangeFormModel>;
  currencyFormName: "first_currency" | "second_currency";
};

const { Item, useWatch } = Form;

const Card: React.FC<Props> = ({ form, name, index, operation, parentName, currencyFormName }) => {
  const currency = JSON.parse(useWatch(currencyFormName, form) || "{}") as CurrencyModel;

  const record = useWatch(
    [...parentName, ...name, index],
    form
  ) as ExchangeFormModel["expense_payment"]["payment_types"][number]["projects"][number];

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const parseValue = parseLocaledString(value || "0");
        const paymentAmount = parseLocaledString(
          form.getFieldValue(
            parentName?.length > 0 ? [...parentName, name[0], "amount"] : ["income_payment", "payment_type", "amount"]
          )
        );
        const financials = (
          parentName?.length > 0 ? form.getFieldValue([...parentName, name[0], "financials"]) : []
        ) as ExchangeFormModel["expense_payment"]["payment_types"][number]["financials"];
        const financialsAmount = financials?.reduce(
          (previousValue, currentValue) => previousValue + parseLocaledString(currentValue?.amount || "0"),
          0
        );
        const projects = form.getFieldValue([
          ...parentName,
          ...name
        ]) as ExchangeFormModel["income_payment"]["payment_type"]["projects"];
        const projectsAmount = projects?.reduce((previousValue, currentValue, currentIndex) => {
          if (index !== currentIndex) {
            return previousValue + parseLocaledString(currentValue?.amount || "0");
          }

          return previousValue;
        }, 0);

        if (projectsAmount + parseValue > financialsAmount + paymentAmount) {
          return Promise.reject(
            `Kiritilgan miqdor ${(financialsAmount + paymentAmount - projectsAmount)?.toLocaleString(RU)} ${currency?.symbol} dan kichik bo'lishi kerak`
          );
        }

        return Promise.resolve();
      }
    }
  ];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue([...parentName, ...name, index, "amount"], localeFormatter(e?.target?.value));
  };

  const onRemoveItem = () => {
    operation.remove(index);
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <BuildingIcon />
        <div className={styles.text}>
          <h3>{record?.name}</h3>
        </div>
      </div>
      <div className={styles.right}>
        <Item name={[index, "id"]} className="hidden" />
        <Item name={[index, "name"]} className="hidden" />
        <Item name={[index, "amount"]} rules={amountRule}>
          <Input placeholder="0" onChange={onChangeAmount} suffix={currency?.symbol} />
        </Item>
        <div className={styles.close} onClick={onRemoveItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default Card;
