import React from "react";
import CalendarEmptyIcon from "features/payment/assets/icons/CalendarEmptyIcon";

const CalendarEmpty: React.FC = () => (
  <div className="box-border flex h-full w-full flex-col items-center justify-center gap-4">
    <CalendarEmptyIcon />
    <div className="flex flex-col items-center justify-center gap-1">
      <span className="text-base font-semibold text-gray-900">Ma'lumotlar mavjud emas</span>
      <span className="text-sm font-normal text-gray-600">Ushbu kalendarda to'lov bo'yicha ma'lumotlar topilmadi</span>
    </div>
  </div>
);

export default CalendarEmpty;
