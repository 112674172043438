import React from "react";
import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { formRules } from "../../../../../app/utils/constants/formRules";
import ArrowRightCircleIcon from "../../../../assets/icons/ArrowRightCircleIcon";
import { useCreateCurrency, useUpdateCurrency } from "../../../../service/mutations";
import { CurrencyFormType } from "../../../../utils/models/currency/currencyFormModel";
import { CurrencyModalDataModel } from "../../../../utils/models/currency/currencyModalDataModel";

import styles from "./settingsCurrencyModal.module.scss";

type Props = {
  modalData: CurrencyModalDataModel;
  setModalData: React.Dispatch<React.SetStateAction<CurrencyModalDataModel>>;
  basicCurrencyId?: number;
};

const { Item } = Form;

const SettingsCurrencyModal: React.FC<Props> = ({ modalData, setModalData }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<CurrencyFormType>();
  const createCurrency = useCreateCurrency();
  const updateCurrency = useUpdateCurrency();

  // after open modal
  const onAfterOpenModal = () => {
    const currency = modalData.data;

    if (modalData.visible && currency) {
      form.setFieldsValue({
        currency_id: currency.id,
        amount: String(currency.amount),
      });
    }
  };

  // cancel modal
  const onCancel = () => {
    form.resetFields();
    setModalData({ visible: false, data: undefined });
  };

  // finish form
  const onFinish = (values: CurrencyFormType) => {
    updateCurrency
      .mutateAsync({ ...values, currency_id: modalData.data!.id! })
      .then(onCancel);
  };

  return (
    <Modal
      centered
      open={modalData.visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      afterOpenChange={onAfterOpenModal}
      className={styles.currency_modal}
      okButtonProps={{
        loading: createCurrency.isLoading || updateCurrency.isLoading,
      }}
      title={t("Moliya.Valyuta")}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item label={t("Moliya.Asosiy")}>
          <div className={styles.currency}>
            <Input disabled value={1} suffix={modalData.data?.symbol} />
            <span className={styles.currency__icon}>
              <ArrowRightCircleIcon />
            </span>
            <Item rules={formRules()} name="amount">
              <Input type="number" placeholder="1" suffix={modalData.secondSymbol} />
            </Item>
          </div>
        </Item>
      </Form>
    </Modal>
  );
};

export default SettingsCurrencyModal;
