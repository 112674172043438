import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { useGetUnitSelect } from "../../../../../../../app/service/queries";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { OfferPartyErrorModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  unique_id: string;
};

const { Option } = Select;

const OfferPartyProductUnit: React.FC<Props> = ({ unique_id }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;
  const { data: units } = useGetUnitSelect();
  const { leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onChangeUnit = (e: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const unitOption = option as DefaultOptionType;

    dispatch(
      setOfferPartyLefItemData({
        key: "unit_id",
        value: e,
        uniqueId: unique_id
      })
    );

    if (!unitOption?.props?.isPiece) {
      const oldLeftDataQuantity = leftData?.find(item => item.unique_id === unique_id)?.quantity;

      dispatch(
        setOfferPartyLefItemData({
          key: "quantity",
          value: localeFormatter(String(Math.round(parseLocaledString(String(oldLeftDataQuantity))))),
          uniqueId: unique_id
        })
      );
    }

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "unit",
          uniqueId: unique_id,
          action: !e
        })
      );
    }
  };

  const unitValue = () => leftData?.find(item => item.unique_id === unique_id)?.unit_id;

  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, unique_id) ? styles.item__error : "";

  return (
    <div className={`${styles.item} ${errorClassName("unit")}`}>
      <Select
        showSearch
        suffixIcon={null}
        value={unitValue()}
        placeholder="Tanlang"
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        onChange={(e, option) => onChangeUnit(Number(e), option)}
        notFoundContent={<TableEmpty description="Kerakli resurs topilmadi" />}
      >
        {units?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name[i18n.language],
              isPiece: item?.is_piece
            }}
          >
            {item.name[i18n.language]}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfferPartyProductUnit;
