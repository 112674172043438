import React from "react";
import { useOutletContext } from "react-router-dom";
import { Input, Tooltip } from "antd";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../../app/components/conditional-render/ConditionalRender";
import WarningIcon from "../../../../../assets/icons/WarningIcon";

import styles from "../task.module.scss";

type Props = {
  plan?: boolean;
  sectionPlan?: boolean;
  isActive?: boolean;
  onTask: () => void;
  sectionPlace?: number;
  place?: number;
  onChange?: () => void;
  value?: string;
  isCreate?: boolean;
};

const TaskName: React.FC<Props> = props => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const { value, onTask, place, sectionPlace, sectionPlan, plan, onChange, isActive, isCreate = false } = props;

  return (
    <div className={styles.name__inner}>
      <ConditionalRender if={!isCreate}>
        <ConditionalRender if={!plan && sectionPlan}>
          <Tooltip getPopupContainer={() => smetaContainer} title="Rejadan tashqari">
            <div className={styles.warning}>
              <WarningIcon />
            </div>
          </Tooltip>
        </ConditionalRender>
        <SelectSuffixIcon onClick={onTask} className={styles.selectIcon} placement={isActive ? "bottom" : "right"} />
        <ConditionalRender if={sectionPlace && place}>
          <span>
            {sectionPlace}.{place}
          </span>
        </ConditionalRender>
      </ConditionalRender>
      <Input placeholder="Nomi" value={value} onChange={onChange} />
    </div>
  );
};

export default TaskName;
