import React from "react";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import styles from "./taskProductsTemplateFolderCard.module.scss";
import { CategoryModel } from "../../../../../../../settings/utils/models/category/CategoryModel";
import FolderIcon from "../../../../../../../app/assets/icons/FolderIcon";
import { useTranslation } from "react-i18next";
import { sliceText } from "../../../../../../../app/utils/helpers/sliceText";

interface Props extends CategoryModel {
  isCategory: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
}

const TaskProductsTemplateFolderCard: React.FC<Props> = ({
  id,
  setPage,
  products_count,
  name,
  color,
  setTabKey,
  isCategory,
  setFolderId,
  setBreadcrumbItems,
}) => {
  const { i18n } = useTranslation();

  const changeFolderId = () => {
    setFolderId((value) => {
      if (isCategory) {
        return {
          category_id: id,
          sub_category_id: value.sub_category_id,
        };
      } else {
        return {
          category_id: value.category_id,
          sub_category_id: id,
        };
      }
    });
  };

  const onClick = () => {
    setTabKey(isCategory ? "2" : "3");
    changeFolderId();
    setPage && setPage(1);

    setBreadcrumbItems((value) => {
      return [
        ...value,
        {
          title: name[i18n.language],
          onClick: () => {
            changeFolderId();
            setPage && setPage(1);
            setTabKey(isCategory ? "2" : "3");
            setBreadcrumbItems((value) =>
              isCategory ? [value[0], value[1]] : value
            );
          },
        },
      ];
    });
  };

  return (
    <div className={styles.folder} onClick={onClick}>
      <FolderIcon color={color} />
      <div className={styles.right}>
        <h4>{sliceText(name[i18n.language], 16)}</h4>
        <p>{products_count} shablon</p>
      </div>
    </div>
  );
};

export default TaskProductsTemplateFolderCard;
