import React from "react";

import DrawerTable from "../table/DrawerTable";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import DrawerFolders from "../folders/DrawerFolders";
// import { PaginationType } from "../../../../../app/utils/models/PaginationType";
// import { ProductModel } from "../../../../../settings/utils/models/product/productModel";
// import { CategoryModel } from "../../../../../settings/utils/models/category/CategoryModel";

import styles from "./drawerDetailedFolder.module.scss";
import { CategoryModel } from "../../../../settings/utils/models/category/CategoryModel";
import { PaginationType } from "../../../../app/utils/models/PaginationType";
import { ProductModel } from "../../../../settings/utils/models/product/productModel";

type Props = {
  data?: CategoryModel[];
  isLoading: boolean;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  // setFolderId: React.Dispatch<
  //   React.SetStateAction<{
  //     category_id?: number | undefined;
  //     sub_category_id?: number | undefined;
  //   }>
  // >;
  products?: PaginationType<ProductModel[]>;
  isLoadingProducts: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  isPrice?: boolean
};

const DrawerDetailedFolder: React.FC<Props> = ({
  page,
  setPage,
  data,
  products,
  isPrice,
  isLoading,
  setTabKey,
  // setFolderId,
  isLoadingProducts,
  setBreadcrumbItems,
}) => {
  return (
    <div className={styles.detailed_folder}>
      <DrawerFolders
        data={data}
        setPage={setPage}
        isCategory={false}
        setTabKey={setTabKey}
        isLoading={isLoading}
        // setFolderId={setFolderId}
        setBreadcrumbItems={setBreadcrumbItems}
      />
      <DrawerTable
        page={page}
        setPage={setPage}
        isLoading={isLoadingProducts}
        data={products}
        isPrice={isPrice}
      />
    </div>
  );
};

export default DrawerDetailedFolder;
