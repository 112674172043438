import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Button, Dropdown, Input, MenuProps, Segmented, Tabs } from "antd";
import MessageConfig from "features/app/components/message-config/message-config";
import FullScreenCloseIcon from "features/projects/assets/icons/FullScreenCloseIcon";
import FullScreenOpenIcon from "features/projects/assets/icons/FullScreenOpenIcon";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useTranslation } from "react-i18next";

import { colors, cx } from "modules/common";

import { useAppDispatch } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { useRoutePermissions } from "../../../../../../hooks/useRoutePermissions";
import { projectsReducerActions } from "../../../../../../store/reducers/projectsReducer";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "../../../../../app/assets/icons/DownloadIcon";
import { SearchIcon } from "../../../../../app/assets/icons/SearchIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LocalStorage } from "../../../../../app/service/LocalStorage";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { getLocationPathValue } from "../../../../../app/utils/helpers/getLocationValue";
import { EstimateGroupType } from "../../../../../app/utils/models/estimateGroupType";
import EstimateGroup from "../../../../components/detailed-project/estimate-group/EstimateGroup";
import EstimateSettings from "../../../../components/detailed-project/estimate-settings/EstimateSettings";
import EstimateStatistics from "../../../../components/detailed-project/estimate-statistics/EstimateStatistics";
import EstimateImportModal from "../../../../components/estimate-import-modal/EstimateImportModal";
import { routeSubmodules } from "../../../../utils/constants/routeSubmodules";
import { EstimateKeyEnum } from "../../../../utils/enums/estimateKeyEnum";
import { EstimateSegmentedEnums } from "../../../../utils/enums/estimateSegmentedEnum";

import FilterDrawerGantt from "./pages/gantt/components/filter-drawer/FilterDrawerGantt";

import styles from "./projectEstimate.module.scss";

export const ProjectEstimate = () => {
  const handler = useFullScreenHandle();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeKey = getLocationPathValue(5);
  const projectId = getLocationPathValue(3);
  const { append, queries, remove } = useQueryParams();
  const { setOpenImportModal } = projectsReducerActions;
  const { estimate_type: estimateType, general_search: generalSearch, estimate_group: groupCustomField } = queries();
  const segmentedQueryValue = estimateType ?? EstimateSegmentedEnums.PLAN;
  const [searchValue, setSearchValue] = useState<string>(generalSearch);
  const [ganttSearchValue, setGanttSearchValue] = useState<string>(generalSearch);

  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const estimateActions = actions("Smeta");

  const onOpenImportModal = () => {
    dispatch(setOpenImportModal(true));
  };

  const allTabsItems = [
    {
      key: "",
      label: `${t("project.Smeta")}`,
      permissionKey: "view"
    },
    {
      key: "gantt",
      label: `${t("project.Ish grafigi")}`,
      permissionKey: "chart"
    }
  ];

  const tabsItems = () =>
    allTabsItems.filter(item => estimateActions[item.permissionKey as keyof typeof estimateActions]);

  const onTabChange = (key: string) => {
    navigate(key);
  };

  const onSearch = () => {
    if (searchValue) {
      append(queryParamsKeys.GENERAL_SEARCH, searchValue);
    } else remove(queryParamsKeys.GENERAL_SEARCH);
  };

  const onSearchGantt = () => {
    if (ganttSearchValue) {
      append(queryParamsKeys.GENERAL_SEARCH, ganttSearchValue);
    } else remove(queryParamsKeys.GENERAL_SEARCH);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const target = e?.target as HTMLInputElement;
    const { value } = target;

    if (!value) {
      remove(queryParamsKeys.GENERAL_SEARCH);
    }

    setSearchValue(value);
  };

  const onChangeGanttSearch = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const target = e?.target as HTMLInputElement;
    const { value } = target;

    setGanttSearchValue(value);
  };

  const onChangeSegmented = (value: string | number) => {
    append(queryParamsKeys.ESTIMATE_TYPE, value);
  };

  const itemsRender = () => {
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item}>
            <EstimateSettings smetaContainer={handler.node.current as HTMLElement} />
          </div>
        )
      },
      {
        key: "3",
        label: <EstimateGroup smetaContainer={handler.node.current as HTMLElement} projectId={projectId} />
      },
      {
        key: "4",
        label: (
          <div className={cx("flex items-center gap-5 text-sm font-medium text-gray-900")}>
            <div className="flex items-center gap-3">
              {handler.active ? (
                <FullScreenCloseIcon stroke={colors.GRAY_500} />
              ) : (
                <FullScreenOpenIcon stroke={colors.GRAY_500} />
              )}{" "}
              {t("projects.Ko'rish")}
            </div>
          </div>
        ),
        onClick: handler.active ? handler.exit : handler.enter
      }
    ];

    if (estimateActions.import) {
      items.push({
        key: "2",
        label: (
          <div className={styles.dropdown__item} onClick={onOpenImportModal}>
            <DownloadIcon />
            {t("project.Import")}
          </div>
        )
      });
    }

    return items;
  };

  const isGroupCustomField = () => {
    const localGroupField = LocalStorage.get<EstimateGroupType[]>("estimate_group");

    const findLocalField = localGroupField?.find(item => item?.projectId === String(projectId));

    return groupCustomField || findLocalField;
  };

  const tabBarExtraContent = {
    [EstimateKeyEnum.SMETA]: (
      <div className={styles.page__extra_content}>
        <div className={styles.actions}>
          <Input
            value={searchValue}
            placeholder="Qidiruv"
            prefix={<SearchIcon />}
            onPressEnter={onSearch}
            onChange={onChangeSearch}
            className={styles.search}
          />
        </div>
        <div className={styles.segmented}>
          <Segmented
            options={[
              { label: `${t("project.Plan")}`, value: EstimateSegmentedEnums.PLAN },
              { label: `${t("project.Fakt")}`, value: EstimateSegmentedEnums.FACT },
              { label: `${t("project.Bashorat")}`, value: EstimateSegmentedEnums.FEATURE }
            ]}
            value={segmentedQueryValue}
            onChange={onChangeSegmented}
            className={styles.segmented__inner}
          />
        </div>
        <Dropdown
          menu={{ items: itemsRender() }}
          trigger={["contextMenu", "click"]}
          overlayClassName={styles.dropdown}
          getPopupContainer={() => handler.node.current as HTMLElement}
        >
          <Button className={styles.dots}>
            <DotsVerticalIcon />
            {isGroupCustomField() && <span className={styles.group_length}>1</span>}
          </Button>
        </Dropdown>
      </div>
    ),
    [EstimateKeyEnum.GANTT]: (
      <div className={styles.page__gantt_content}>
        <Input
          prefix={<SearchIcon />}
          placeholder={t("project.Qidiruv")}
          onPressEnter={onSearchGantt}
          onChange={onChangeGanttSearch}
          value={ganttSearchValue}
          className={styles.search}
        />
        <FilterDrawerGantt />
      </div>
    )
  };

  return (
    <div className={styles.page}>
      <ConditionalRender if={activeKey !== EstimateKeyEnum.GANTT}>
        <EstimateStatistics />
      </ConditionalRender>

      <FullScreen className={cx("bg-gray-50", handler.active ? "p-2" : "h-[96%]")} handle={handler}>
        <div className={styles.page__header}>
          <Tabs
            items={tabsItems()}
            activeKey={activeKey}
            onChange={onTabChange}
            tabBarExtraContent={
              tabBarExtraContent[activeKey as keyof typeof tabBarExtraContent] || tabBarExtraContent.smeta
            }
          />
        </div>
        <div className={styles.page__body}>
          <Outlet
            context={{
              smetaContainer: handler.node.current as HTMLElement
            }}
          />
          <EstimateImportModal smetaContainer={handler.node.current as HTMLElement} />
        </div>
        {MessageConfig({ getContainer: () => handler.node.current as HTMLElement })}
      </FullScreen>
    </div>
  );
};
