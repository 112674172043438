import React from "react";
import { useDispatch } from "react-redux";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { productSpentWidgetDrawerActions, useSelector } from "store";

import { cx } from "modules/common";

import Content from "./content/content";

import styles from "./product-spent-widget-drawer.module.scss";

const ProductSpentWidgetDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { clearQuery } = useQueryParams();
  const { t } = useTranslation();
  const { visible } = useSelector(state => state.productSpentWidgetDrawerReducer);
  const { setOpenDrawer } = productSpentWidgetDrawerActions;

  const onClose = () => {
    dispatch(
      setOpenDrawer({
        visible: false,
        props: null
      })
    );
    clearQuery();
  };

  return (
    <Drawer
      width="max-content"
      rootClassName={cx(styles.drawer_container)}
      open={visible}
      onClose={onClose}
      title={t("project.Smeta")}
    >
      <Content />
    </Drawer>
  );
};

export default ProductSpentWidgetDrawer;
