import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import CloseIcon from "../../../../../../app/assets/icons/CloseIcon";
import { RU } from "../../../../../../app/utils/constants/languages";
import { InventoryWorkModel } from "../../../../../utils/models/inventoryWorkModel";

import styles from "./workDetail.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  record?: InventoryWorkModel;
};

const WorkDetail = ({ open, setOpen, record }: Props) => {
  const { i18n, t } = useTranslation();

  const onCancel = () => {
    setOpen(false);
  };

  const setStatus = () => {
    if (record && record?.amount * record?.quantity === record?.debt_amount) {
      return <p className={styles.error}>{t("Inventory.To'lanmagan")}</p>;
    }
    if (record?.debt_amount === 0) {
      return <p className={styles.success}>{t("Inventory.To'langan")}</p>;
    }
    return <p className={styles.warning}>{t("Inventory.Qisman to'langan")}</p>;
  };

  const footer = () => (
    <div className={styles.footer}>
      <Button onClick={onCancel}>{t("Inventory.Ortga")}</Button>
    </div>
  );

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      footer={footer()}
      width={877}
      title={t("Inventory.Batafsil ma’lumot")}
      className={styles.work_detail}
    >
      <div className={styles.content}>
        <div className={styles.item}>
          <p>{t("Inventory.Loyiha")}</p>
          <p>{record?.project?.name}</p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.Ish bo’limi va ish nomi")}</p>
          <p>
            {record?.section?.name} / {record?.task?.name}
          </p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.Ish hajmi")}</p>
          <p>
            {record?.quantity?.toLocaleString(RU) ?? 0} {record?.unit?.symbol[i18n.language]}
          </p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.Ishlagan vaqti")}</p>
          <p>{record?.completed_date}</p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.Mas’ul xodim")}</p>
          <p>{record?.company_person?.name}</p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.Ish uchun to’lanadigan summa")}</p>
          <p>{(record && (record?.amount * record?.quantity).toLocaleString(RU)) ?? 0}</p>
        </div>
        <div className={styles.item}>
          <p>{t("Inventory.To’lov holati")}</p>
          <p>{setStatus()}</p>
        </div>
      </div>
    </Modal>
  );
};

export default WorkDetail;
