import { Rule } from "antd/es/form";

export const formRules = (message?: string): Rule[] => {
  let rules: Rule[] = [
    {
      required: true,
      message: ""
    }
  ];

  if (message) {
    rules = [
      {
        required: true,
        message
      }
    ];
  }

  return rules;
};
