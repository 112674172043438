import React from "react";
import { Form, FormInstance, Spin } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import {
  LaboratoryDrawerFormModel
  // LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";

import { useSelector } from "store";

import { LoadingIndicator } from "components";

import Content from "./content/Content";
import Header from "./header/Header";
import LeftNoData from "./no-data/LeftNoData";

import styles from "./left.module.scss";

const { useWatch } = Form;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
  isLoading: boolean;
};

const Left: React.FC<Props> = ({ form, isLoading }) => {
  const warehouseId = useWatch("warehouse_id", form);
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);

  return (
    <div className="flex flex-1 flex-col gap-3 p-7">
      <Header form={form} />
      <ConditionalRender if={warehouseId}>
        <Content form={form} />
      </ConditionalRender>
      <Spin spinning={isViewing ? isLoading : false} indicator={LoadingIndicator} wrapperClassName={styles.spinner}>
        <ConditionalRender if={!warehouseId}>
          <div className={styles.no_data_container}>
            <LeftNoData />
          </div>
        </ConditionalRender>
      </Spin>
    </div>
  );
};

export default Left;
