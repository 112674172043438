import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Modal, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { RU } from "../../../../modules/common";
import { paymentReducerActions } from "../../../../store/reducers/paymentReducer";
import SwitchCircleHorizontalIcon from "../../../payment/assets/icons/SwitchCircleHorizontalIcon";
import { useCheckTransfer, useDeletePayment } from "../../../payment/service/mutation";
import { routeSubmodules } from "../../../payment/utils/constants/routeSubmodules";
import DeleteIcon from "../../assets/icons/DeleteIcon";
import { usePaymentTransferHistory } from "../../service/queries";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import ConditionalRender from "../conditional-render/ConditionalRender";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";
import ModalConfirm from "../modal-confirm/ModalConfirm";
import { StatusEnums } from "../statuses/statusEnums";

import styles from "./transferHistoryModal.module.scss";

const TransferHistoryModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const record = useAppSelector(state => state.paymentReducer.transferHistoryModal);
  const { setTransferHistoryModal } = paymentReducerActions;
  const { data, isLoading } = usePaymentTransferHistory(record?.id, record?.isDeleted);
  const [status, setStatus] = useState<StatusEnums>(StatusEnums.CANCEL);

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const transferActions = actions("Kirim-chiqim");

  const transferDelete = useDeletePayment();
  const transferCheck = useCheckTransfer();

  const onCancel = () => {
    dispatch(setTransferHistoryModal(undefined));
  };

  const onCheckTransfer = (status: StatusEnums) => () => {
    setStatus(status);

    data &&
      transferCheck
        .mutateAsync({
          id: data.income_payment?.id,
          status
        })
        .then(() => {
          dispatch(setTransferHistoryModal(undefined));
        });
  };

  const onAfterDelete = () => {
    dispatch(setTransferHistoryModal(undefined));
  };

  const footer = (
    <div className="flex gap-3">
      <ConditionalRender if={transferActions?.delete && !record?.isDeleted}>
        <ModalConfirm
          isDescription
          title={`${t("payment.O'tkazma")} - ${data?.id}`}
          mutation={transferDelete}
          payload={{
            id: data?.id
          }}
          onAfterClose={onAfterDelete}
        >
          <Button>
            <DeleteIcon />
            {t("payment.O'chirish")}
          </Button>
        </ModalConfirm>
      </ConditionalRender>
      <ConditionalRender if={data?.access && data?.status === StatusEnums.PENDING && transferActions?.transferCheck}>
        <Button
          onClick={onCheckTransfer(StatusEnums.CANCEL)}
          loading={transferCheck.isLoading && status === StatusEnums.CANCEL}
        >
          {t("payment.Bekor qilish")}
        </Button>
        <Button
          type="primary"
          onClick={onCheckTransfer(StatusEnums.ACTIVE)}
          loading={transferCheck.isLoading && status === StatusEnums.ACTIVE}
        >
          {t("payment.Tasdiqlash")}
        </Button>
      </ConditionalRender>
    </div>
  );

  return (
    <Modal
      centered
      width={679}
      zIndex={19999}
      open={!!record}
      footer={footer}
      onCancel={onCancel}
      title={t("payment.O’tkazmalar tarixi")}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className="flex flex-col gap-3">
          <div className={styles.card}>
            <p>
              {t("payment.Kassadan")} <span>{data?.expense_payment?.cash?.name}</span>
            </p>
            <p>
              {t("payment.To’lov turi")} <span>{data?.expense_payment?.payment_type?.name}</span>
            </p>
            <p>
              {t("payment.Summa")}{" "}
              <span>
                {data?.expense_payment?.amount?.toLocaleString(RU)} {data?.expense_payment?.currency?.symbol}
              </span>
            </p>
            <ConditionalRender if={!isEmptyArr(data?.expense_payment?.projects)}>
              <Divider rootClassName={styles.divider} plain>
                {t("payment.Birikkan loyihalar")}
              </Divider>
              {data?.expense_payment?.projects?.map(project => (
                <div className={styles.card_child} key={project?.id}>
                  <p>
                    {t("payment.Loyiha")} <span>{project?.name}</span>
                  </p>
                  <p>
                    {t("payment.Summa")}
                    <span>
                      {project?.amount?.toLocaleString(RU)} {data?.expense_payment?.currency?.symbol}
                    </span>
                  </p>
                </div>
              ))}
            </ConditionalRender>
            <ConditionalRender if={!isEmptyArr(data?.expense_payment?.financials)}>
              <Divider rootClassName={styles.divider} plain>
                {t("payment.Birikkan xarajatlar")}
              </Divider>
              {data?.expense_payment?.financials?.map(finance => (
                <div className={styles.card_child} key={finance?.id}>
                  <p>
                    {t("payment.Xarajat")} <span>{finance?.name}</span>
                  </p>
                  <p>
                    {t("payment.Summa")}
                    <span>
                      {finance?.amount?.toLocaleString(RU)} {data?.expense_payment?.currency?.symbol}
                    </span>
                  </p>
                </div>
              ))}
            </ConditionalRender>
          </div>
          <span className={styles.narrowIcon}>
            <SwitchCircleHorizontalIcon rotate={90} />
          </span>
          <div className={styles.card}>
            <p>
              {t("payment.Kassaga")} <span>{data?.income_payment?.cash?.name}</span>
            </p>
            <p>
              {t("payment.To’lov turi")} <span>{data?.income_payment?.payment_type?.name}</span>
            </p>
            <p>
              {t("payment.Summa")}
              <span>
                {data?.income_payment?.amount?.toLocaleString(RU)} {data?.income_payment?.currency?.symbol}
              </span>
            </p>
            <ConditionalRender if={!isEmptyArr(data?.income_payment?.projects)}>
              <Divider rootClassName={styles.divider} plain>
                {t("payment.Birikkan loyihalar")}
              </Divider>
              {data?.income_payment?.projects?.map(project => (
                <div className={styles.card_child} key={project?.id}>
                  <p>
                    {t("payment.Loyiha")} <span>{project?.name}</span>
                  </p>
                  <p>
                    {t("payment.Summa")}
                    <span>
                      {project?.amount?.toLocaleString(RU)} {data?.expense_payment?.currency?.symbol}
                    </span>
                  </p>
                </div>
              ))}
            </ConditionalRender>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default TransferHistoryModal;
