import emptyMessage from "../../assets/images/emty-message.png";
import { useTranslation } from "react-i18next";
import styles from "./chat.module.scss";

const EmptyMessage = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.empty_image}>
      <div className={styles.empty_image__inner}>
        <img src={emptyMessage} alt="emptyMessage" />
        <h3>{t("Monitoring.Yozishmalar mavjud emas")}</h3>
        <p>
          {t(
            "Monitoring.Sizda hali hech qanday xabar yo'q. Suhbatni birinchi bo'lib boshlang"
          )}
        </p>
      </div>
    </div>
  );
};

export default EmptyMessage;
