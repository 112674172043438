import { useInfiniteQuery } from "@tanstack/react-query";

import $api from "../../../features/app/utils/helpers/axiosInstance";
import { PaginationType } from "../../../features/app/utils/models/PaginationType";
import { dashboardQueryKeys } from "../query-keys";
import { ProductAll } from "../schema";

type Query = PaginationType<ProductAll[]>;

type Props = {
  search?: string;
};

export function useProductAll({ search }: Props) {
  const initial = {
    pages: [
      {
        total: 0,
        current_page: 1,
        data: []
      }
    ],
    pageParams: []
  };

  const { data = initial, ...arg } = useInfiniteQuery<Query>({
    queryKey: [dashboardQueryKeys.PRODUCT_ALL, search],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await $api.get(
        `product/select-all?page=${pageParam}&size=50${search ? `&search=${search}` : ""}`
      );

      return data?.data;
    },
    getNextPageParam: (lastPage, page) => {
      if (page.length < lastPage?.data.length) {
        return page.length + 1;
      }

      return undefined;
    }
  });

  return { pages: data.pages, ...arg };
}
