import { useState } from "react";
import classNames from "classnames";
import { Form, FormInstance } from "antd";

import { CheckIcon } from "../../../assets/icons/CheckIcon";

import styles from "./sizeConfig.module.scss";
import {useTranslation} from "react-i18next";

const { Item } = Form;

type Props = {
  form: FormInstance;
  defaultSize?: number;
};

const pageSizeArray = [10, 20, 30, 50];

const SizeConfig = ({ form, defaultSize = 10 }: Props) => {
  const [size, setSize] = useState(defaultSize);
  const { t } = useTranslation()

  const onChangeSize = (currentSize: number) => {
    setSize(currentSize);
    form.setFieldValue("size", currentSize);
  };

  const content = (contentSize: number) => (
    <div
      className={classNames(styles.size__button, {
        [styles.active]: size === contentSize,
      })}
      onClick={() => onChangeSize(contentSize)}
      key={contentSize}
    >
      {contentSize}
      <div>
        <CheckIcon color="#ffffff" width={14} />
      </div>
    </div>
  );

  return (
    <div className={styles.size}>
      <p>{t("tableSettings.Sahifa hajmi")}</p>
      <Item className="d_n" name="size" />
      <div className={styles.size__block}>
        {pageSizeArray.map((page) => content(page))}
      </div>
    </div>
  );
};

export default SizeConfig;
