import React from "react";
import { Form, FormInstance, FormListOperation, Input } from "antd";
import { Rule } from "antd/es/form";

import DeleteIcon from "../../../../../../../app/assets/icons/DeleteIcon";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { RU } from "../../../../../../../app/utils/constants/languages";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { CurrencySelectModel } from "../../../../../../../app/utils/models/currencySelectModel";
import MarkIcon from "../../../../../../assets/icons/MarkIcon";
import { PaymentCashMoneyModel } from "../../../../../../utils/models/paymentCashMoneyModel";
import { TransferBodyModel } from "../../../../../../utils/models/transferBodyModel";

import styles from "../../project-card/project-card.module.scss";

type Props = {
  index: number;
  operation: FormListOperation;
  formInstance: FormInstance<TransferBodyModel>;
};

const { Item, useWatch } = Form;

const Card: React.FC<Props> = ({ operation, index, formInstance }) => {
  const currency = JSON.parse(useWatch("currency", formInstance) || "{}") as CurrencySelectModel;

  const record = useWatch(
    ["expense_payment", "financials", index],
    formInstance
  ) as TransferBodyModel["income_payment"]["projects"][number];

  const amountRule: Rule[] = [
    ...formRules(),
    {
      validator: (_, value) => {
        const parseValue = parseLocaledString(value || "0");
        const financials = formInstance.getFieldValue([
          "expense_payment",
          "financials"
        ]) as TransferBodyModel["expense_payment"]["financials"];
        const totalAmount = parseLocaledString(formInstance.getFieldValue(["expense_payment", "amount"]) || "0");

        const checkCash = JSON.parse(
          formInstance.getFieldValue("cash") || "{}"
        ) as PaymentCashMoneyModel["currencies"][number];

        if (!parseValue) {
          return Promise.reject();
        }

        if (totalAmount) {
          const totalFinancialAmount = financials?.reduce(
            (first, second) =>
              parseLocaledString(String(second?.amount) || "0") + parseLocaledString(String(first) || "0"),
            0
          );

          if (totalFinancialAmount + totalAmount > (checkCash?.amount || 0)) {
            const diffAmount = Math.abs(totalFinancialAmount + totalAmount - parseValue - (checkCash?.amount || 0));

            return Promise.reject(
              `Kiritilgan miqdor ${diffAmount?.toLocaleString(RU)} ${checkCash?.symbol} dan oshmasligi zarur`
            );
          }
        } else {
          return Promise.reject();
        }

        return Promise.resolve();
      }
    }
  ];

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue(["expense_payment", "financials", index, "amount"], localeFormatter(e?.target?.value));
  };

  const onRemoveItem = () => {
    operation.remove(index);
  };

  return (
    <div className={styles.card}>
      <div className={styles.left}>
        <MarkIcon />
        <div className={styles.text}>
          <h3>{record?.name}</h3>
        </div>
      </div>
      <div className={styles.right}>
        <Item name={[index, "id"]} className="hidden" />
        <Item name={[index, "amount"]} rules={amountRule}>
          <Input placeholder="0" onChange={onChangeAmount} suffix={currency?.symbol} />
        </Item>
        <div className={styles.close} onClick={onRemoveItem}>
          <DeleteIcon />
        </div>
      </div>
    </div>
  );
};

export default Card;
