import React, { useState } from "react";
import classNames from "classnames";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import ChevronLeftIcon from "../../../../projects/assets/icons/ChevronLeftIcon";

import styles from "./paymentCalendar.module.scss";
import { useTranslation } from "react-i18next";

type ViewType = PaymentTypeEnum.ANNUAL | PaymentTypeEnum.DAILY | PaymentTypeEnum.MONTHLY;

const PaymentCalendarFloadButtons: React.FC = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(true);
  const { append, queries, remove } = useQueryParams();
  const { view_type, fullscreen } = queries();

  const onOpen = () => {
    setIsOpen(!isOpen);
  };

  const onChangeCalendarType = (e: ViewType) => () => {
    append(queryParamsKeys.VIEW_TYPE, e, false, [queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE]);
  };

  const activeViewType = (e: ViewType) => view_type === e;

  const onChangeFullSize = () => {
    if (!fullscreen) append(queryParamsKeys.FULLSCREEN, String(true));
    else remove(queryParamsKeys.FULLSCREEN);
  };

  return (
    <div className={styles.float}>
      <div className={styles.float__content}>
        <div className={styles.float__collapse} onClick={onOpen}>
          <ChevronLeftIcon rotate={isOpen ? 180 : 0} />
        </div>
        <div
          className={classNames(styles.float__items, {
            [styles.float__items__disabled]: !isOpen
          })}
        >
          <div
            className={classNames(styles.float__item, {
              [styles.float__active_item]: activeViewType(PaymentTypeEnum.DAILY)
            })}
            onClick={onChangeCalendarType(PaymentTypeEnum.DAILY)}
          >
            <span>{t("payment.Kunlik")}</span>
          </div>
          <div
            className={classNames(styles.float__item, {
              [styles.float__active_item]: activeViewType(PaymentTypeEnum.MONTHLY)
            })}
            onClick={onChangeCalendarType(PaymentTypeEnum.MONTHLY)}
          >
            <span>{t("payment.Oylik")}</span>
          </div>
          <div
            className={classNames(styles.float__item, {
              [styles.float__active_item]: activeViewType(PaymentTypeEnum.ANNUAL)
            })}
            onClick={onChangeCalendarType(PaymentTypeEnum.ANNUAL)}
          >
            <span>{t("payment.Yillik")}</span>
          </div>
          {/* <div className={styles.float__item} onClick={onChangeFullSize}> */}
          {/*   <span> */}
          {/*     <ExpandIcon /> */}
          {/*   </span> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentCalendarFloadButtons;
