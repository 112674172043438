import { Tooltip } from "antd";

import WarningIcon from "../../../../assets/icons/WarningIcon";

import styles from "./whitOutEstimate.module.scss";

const WithOutEstimate = () => (
  <Tooltip title="Rejadan tashqari">
    <div className={styles.warning}>
      <WarningIcon />
    </div>
  </Tooltip>
);

export default WithOutEstimate;
