const TagIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.66602 6.66666H6.67435M1.66602 4.33333L1.66602 8.06209C1.66602 8.46974 1.66602 8.67357 1.71207 8.86538C1.75289 9.03544 1.82023 9.19802 1.91162 9.34714C2.01468 9.51533 2.15881 9.65946 2.44706 9.94771L8.83759 16.3382C9.82763 17.3283 10.3226 17.8233 10.8935 18.0088C11.3956 18.1719 11.9364 18.1719 12.4386 18.0088C13.0094 17.8233 13.5044 17.3283 14.4944 16.3382L16.3376 14.4951C17.3276 13.505 17.8226 13.01 18.0081 12.4392C18.1713 11.9371 18.1713 11.3962 18.0081 10.8941C17.8226 10.3233 17.3276 9.82828 16.3376 8.83824L9.94706 2.44771C9.65881 2.15946 9.51468 2.01533 9.34649 1.91226C9.19737 1.82088 9.03479 1.75354 8.86473 1.71271C8.67292 1.66666 8.4691 1.66666 8.06144 1.66666L4.33268 1.66666C3.39926 1.66666 2.93255 1.66666 2.57603 1.84832C2.26243 2.00811 2.00746 2.26308 1.84767 2.57668C1.66602 2.9332 1.66602 3.39991 1.66602 4.33333ZM7.08268 6.66666C7.08268 6.89678 6.89613 7.08333 6.66602 7.08333C6.4359 7.08333 6.24935 6.89678 6.24935 6.66666C6.24935 6.43655 6.4359 6.25 6.66602 6.25C6.89613 6.25 7.08268 6.43655 7.08268 6.66666Z"
        stroke="#667085"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TagIcon;
