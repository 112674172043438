import React from "react";
import { Spin } from "antd";

import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetPaymentStatistics } from "../../../service/queries";

import PaymentInfoLeft from "./left/PaymentInfoLeft";
import PaymentInfoRight from "./right/PaymentInfoRight";

import styles from "./paymentInfo.module.scss";

const PaymentInfo: React.FC = () => {
  const { data: statistics, isLoading } = useGetPaymentStatistics();

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.info}>
        <PaymentInfoLeft statistics={statistics} />
        <PaymentInfoRight />
      </div>
    </Spin>
  );
};

export default PaymentInfo;
