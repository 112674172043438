import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const warehouseOfferTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "work_name",
      name: "work_name",
      title: "Ish nomi va bo’limi",
      checked: false
    },
    {
      id: "unit",
      name: "unit",
      title: "Birligi",
      checked: true
    },
    {
      id: "quantity",
      name: "quantity",
      title: "Soni",
      checked: true
    },
    {
      id: "delivery_date",
      name: "delivery_date",
      title: "Kerak bo'lish sanasi",
      checked: true
    },
    {
      id: "offers",
      name: "offers",
      title: "Takliflar",
      checked: true
    }
  ],
  size: 10
};
