import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import FilterDrawer from "../../../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FiltersCount from "../../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import FilterLinesIcon from "../../../../../../warehouse/assets/icons/FilterLinesIcon";

import styles from "../topActions.module.scss";

const queryStringArr = [queryParamsKeys.MAX_DATE, queryParamsKeys.MIN_DATE];

const WorkResourcesFilter = () => {
  const { queries } = useQueryParams();
  const [open, setOpen] = useState<boolean>(false);
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE]
      ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE)
      : null,
    queries()[queryParamsKeys.MAX_DATE]
      ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE)
      : null,
  ]);

  const onOpenFilterDrawer = () => {
    setOpen(true);
  };

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilterDrawer}>
          <FilterLinesIcon />
          Filter
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      queryStringArr={queryStringArr}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0],
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1],
        },
      ]}
      setDateValue={[setDateValue]}
      height={165}
    >
      <div className={styles.content}>
        <FilterDateRange value={dateValue} setValue={setDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default WorkResourcesFilter;
