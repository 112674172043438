import { RU } from "../../../../../../app/utils/constants/languages";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { diffFeatureAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateProductModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverTotalAmount from "../../diff-popover/DiffPopoverTotalAmount";

import styles from "../product.module.scss";

type Props = {
  product: DynamicEstimateProductModel;
  className?: string;
  isBordered?: boolean;
  getPopoverContainer?:()=>HTMLElement;
};

const ExpectedTotalAmount = ({ product, className, isBordered = false,getPopoverContainer }: Props) => (
  <>
    {diffFeatureAmount(product?.total_amount, product?.expected_total_amount, product?.spend_total_amount) !== 0 ? (
      <DiffPopoverTotalAmount
        isBordered={isBordered}
        record={product}
        classNames={cx(styles.total_amount, className)}
        isFeature={true}
        getPopoverContainer={getPopoverContainer}
      />
    ) : (
      <p className={styles.total_amount}>{product?.total_amount?.toLocaleString(RU) ?? 0} </p>
    )}
  </>
);

export default ExpectedTotalAmount;
