import React, { useState } from "react";
import { Button, Modal, Radio, RadioChangeEvent, Select } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";

import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";

import IncomeExpenseTable from "./tables/IncomeExpenseTable";
import IncomeOrExpenseTable from "./tables/IncomeOrExpenseTable";
import TemplateTable from "./tables/TemplateTable";

import styles from "./deleteArchive.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

enum ContentViewEnum {
  INCOME = "income",
  EXPENSE = "expense",
  INCOME_EXPENSE = "income-expense"
}

enum PaymentEnum {
  PAYMENT = "payment",
  TEMPLATE = "template"
}

const { Option } = Select;

const DeleteArchive = ({ open, setOpen }: Props) => {
  const [paymentState, setPaymentState] = useState<PaymentEnum>(PaymentEnum.PAYMENT);
  const [tabPosition, setTabPosition] = useState<ContentViewEnum>(ContentViewEnum.INCOME_EXPENSE);
  const { t } = useTranslation();

  const onCancel = () => {
    setOpen(false);
  };

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabPosition(e.target.value);
  };

  const footer = () => (
    <div onClick={onCancel}>
      <Button>{t("payment.Yopish")}</Button>
    </div>
  );

  const onChangePaymentType = (e: PaymentEnum) => {
    setPaymentState(e);
  };

  return (
    <Modal
      centered
      open={open}
      width={1400}
      footer={footer()}
      onCancel={onCancel}
      title={t("payment.To’lovlar arxivi")}
      className={styles.delete__archive}
      rootClassName={styles.archive__modal}
    >
      <div className={styles.top}>
        <div className={styles.radio_group}>
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button value={ContentViewEnum.INCOME_EXPENSE}>{t("payment.Kirim-chiqim")}</Radio.Button>
            <Radio.Button value={ContentViewEnum.INCOME}>{t("payment.Kirim Shartnoma")}</Radio.Button>
            <Radio.Button value={ContentViewEnum.EXPENSE}>{t("payemnt.Chiqim Shartnoma")}</Radio.Button>
          </Radio.Group>
        </div>
        <ConditionalRender if={tabPosition !== ContentViewEnum.INCOME_EXPENSE}>
          <Select
            value={paymentState}
            placeholder="Tanlash"
            className={styles.select}
            popupMatchSelectWidth={false}
            onChange={onChangePaymentType}
            suffixIcon={<SelectSuffixIcon />}
          >
            <Option value={PaymentEnum.PAYMENT}>{t("payment.To’lovlar bo’yicha")}</Option>
            <Option value={PaymentEnum.TEMPLATE}>{t("payment.Guruhlangan holda")}</Option>
          </Select>
        </ConditionalRender>
      </div>
      <div className={styles.bottom}>
        <ConditionalRender if={tabPosition === ContentViewEnum.INCOME_EXPENSE}>
          <IncomeExpenseTable />
        </ConditionalRender>
        <ConditionalRender if={tabPosition === ContentViewEnum.INCOME}>
          <ConditionalRender
            if={paymentState === PaymentEnum.PAYMENT}
            else={<TemplateTable type={PaymentTypeEnum.INCOME} />}
          >
            <IncomeOrExpenseTable type={PaymentTypeEnum.INCOME} />
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender if={tabPosition === ContentViewEnum.EXPENSE}>
          <ConditionalRender
            if={paymentState === PaymentEnum.PAYMENT}
            else={<TemplateTable type={PaymentTypeEnum.EXPENSE} />}
          >
            <IncomeOrExpenseTable type={PaymentTypeEnum.EXPENSE} />
          </ConditionalRender>
        </ConditionalRender>
      </div>
    </Modal>
  );
};

export default DeleteArchive;
