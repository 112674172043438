export const inventoryQueryKeys = {
  FOLDERS_VIEW: "folders-view",
  FOLDERS_SELECT: "folders-select",
  INVENTORY_VIEW: "inventory-view",
  INVENTORY_DETAIL_VIEW: "inventory-detail-view",
  INVENTORY_WORK: "inventory-detail-work",
  INVENTORY_PAY: "inventory-detail-pay",
  INVENTORY_EXPENSE: "inventory-detail-expense",
  CURRENCY_SELECT: "currency-select",
  COMPANY_PERSONS_SELECT: "company-persons-select",
  REQUESTS_VIEW: "requests-view",
  PROJECTS_SELECT: "projects-select",
  ADMINS_SELECT: "admins-select",
  DEFECT_VIEW: "defects-view",
  DEFECT_DETAIL_VIEW: "defect-detail-view",
  CP_INVENTORY_WORK: "inventory-detail-work-cp",
  CP_INVENTORY_PAY: "inventory-detail-pay-cp",
};
