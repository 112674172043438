import React from "react";
import { Form, FormInstance, Select } from "antd";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { formRules } from "features/app/utils/constants/formRules";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import {
  LaboratoryDrawerFormModel,
  LaboratoryDrawerProducts
} from "features/warehouse/utils/models/laboratoryDrawerFormModel";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useSelector } from "store";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
};

const WarehouseSelect: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();
  const { data: warehouses } = useGetWarehouseSelect(true, true);
  const products = useWatch("products", form) as LaboratoryDrawerProducts[];
  const { isViewing } = useSelector(state => state.laboratoryDrawerReducer);
  //   const [searchValue, setSearchValue] = useState<string>();

  //   const onSearch = (e: string) => {
  //     setSearchValue(e);
  //   };

  const onChange = (e: number) => {
    if (e && (products?.length === 0 || !products)) {
      form.setFieldsValue({
        products: [
          {
            rowId: uid()
          }
        ]
      });
    }
  };

  return (
    <div className="w-full">
      <Item label={t("warehouse.Omborxona")} name="warehouse_id" rules={formRules()}>
        <Select
          showSearch
          allowClear
          // searchValue={searchValue}
          // onSearch={onSearch}
          suffixIcon={<SelectSuffixIcon />}
          notFoundContent={<SelectNotContent title={t("Omborxona")} />}
          filterOption={selectFilterOption}
          placeholder={t("warehouse.Tanlang")}
          onChange={onChange}
          disabled={(products && products?.length > 0 && products?.some(item => item?.id)) || isViewing}
        >
          {warehouses?.map(item => (
            <Option
              key={item.id}
              props={{
                name: item?.name
              }}
              value={item?.id}
            >
              {item?.name}
            </Option>
          ))}
        </Select>
      </Item>
    </div>
  );
};

export default WarehouseSelect;
