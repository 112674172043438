import dayjs from "dayjs";

import { dayjsFormats } from "modules/common";

export const handleLeftOrWentDays = (date: string) => {
  const leftOrWentDays = dayjs(date, dayjsFormats.DATE).diff(dayjs(), "day");

  if (leftOrWentDays === 0) {
    return "Bugun";
  }

  if (leftOrWentDays > 0) {
    return `${leftOrWentDays} kun qoldi.`;
  }
  return `${Math.abs(leftOrWentDays)} kun o'tib ketdi.`;
};
