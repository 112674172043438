import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import Dragger from "antd/es/upload/Dragger";
import { useTranslation } from "react-i18next";
import { UploadChangeParam } from "antd/es/upload";
import { Form, FormInstance, Progress, Spin, UploadFile, message } from "antd";

import DeleteIcon from "../../../assets/icons/DeleteIcon";
import UploadIcon from "../../../assets/icons/UploadIcon";
import { useUploadFile } from "../../../service/mutation";
import { useGetFileData } from "../../../service/queries";
import { sliceText } from "../../../utils/helpers/sliceText";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import EllipseFileIcon from "../../../assets/icons/EllipseFileIcon";
import ConditionalRender from "../../conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../loading-indicator/LoadingIndicator";
import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

import styles from "./customFieldFile.module.scss";

const { Item } = Form;

type Props = {
  customField: CustomFieldSelectModel;
  index: number;
  disabled?: boolean;
  form?: FormInstance;
  visible?: boolean;
  isView?: boolean;
};

type FileType = {
  id?: number;
  file: RcFile | undefined;
};

const CustomFieldFile = ({ customField, index, disabled = false, form, visible, isView }: Props) => {
  const { i18n, t } = useTranslation();
  const [file, setFile] = useState<FileType>();
  const uploadFile = useUploadFile();
  const fileId = Form.useWatch(["custom_field_values", index, "value"], form);
  const { data } = useGetFileData(fileId, isView);

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error(t("message.File hajmi 5120 kb dan oshmasligi zarur"));
    } else {
      const image = e?.file?.originFileObj;
      const formData = new FormData();

      formData.append("files[]", image as Blob);

      uploadFile.mutateAsync(formData).then(res => {
        form?.setFieldValue(["custom_field_values", index, "value"], res[0]);

        setFile({ id: res[0], file: image! });
      });
    }
  };

  const onDeleteFile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();

    setFile({
      id: undefined,
      file: undefined
    });

    form?.setFieldValue(["custom_field_values", index, "value"], undefined);
  };

  useEffect(() => {
    if (!visible) {
      setFile(undefined);
    }
  }, [visible]);

  return (
    <>
      <Item className="d_n" name={["custom_field_values", index, "custom_field_id"]} initialValue={customField?.id} />
      <Item className="d_n" name={["custom_field_values", index, "type"]} initialValue={customField?.type} />
      <Item className="d_n" name={["custom_field_values", index, "value"]} />
      <div className={styles.upload}>
        <label className={styles.upload__label}>{customField?.name[i18n.language]}</label>
        <Dragger
          name="images"
          beforeUpload={() => {
            setFile(undefined);
          }}
          onChange={onChange}
          disabled={disabled}
          showUploadList={false}
          customRequest={() => null}
          fileList={file?.file ? [file.file] : []}
          accept=".jpg, .jpeg, .png, .svg, .cv, .docx, .doc, .pdf, .xlsx, .xls, .vsdx, .pptx, .ppt, .vsd"
        >
          <div className={styles.upload__info}>
            <UploadIcon />
            {disabled && isEmptyArr(data) ? (
              <p>Yuklangan fayllar mavjud emas</p>
            ) : (
              <p>
                <span>{t("products.Yuklash uchun bosing")} </span>
                {t("Inventar.yoki faylni surib keling")}. (
                {t("Inventar.fayl hajmi 5120 kb dan yuqori bo'lmasligi zarur")} )
              </p>
            )}
          </div>
        </Dragger>
      </div>
      <div className={styles.content}>
        <Spin indicator={LoadingIndicator} style={{ marginTop: "0.75rem" }} spinning={uploadFile.isLoading}>
          {uploadFile.isLoading && <span>{t("Inventar.Yuklanmoqda")}</span>}
          <ConditionalRender if={file && Boolean(file?.file)}>
            <div className={styles.upload__files}>
              <div className={styles.file}>
                <div>
                  <EllipseFileIcon />
                </div>
                <div className={styles.file__right}>
                  <div className={styles.name}>
                    <p>
                      {sliceText(file?.file?.name || "", 45)}
                      <span>{((file?.file?.size || 1) / 1000000).toFixed(2)} MB</span>
                    </p>
                    <ConditionalRender if={!disabled}>
                      <div className={styles.delete} onClick={onDeleteFile}>
                        <DeleteIcon />
                      </div>
                    </ConditionalRender>
                  </div>
                  <ConditionalRender if={!disabled}>
                    <Progress status="active" percent={100} />
                  </ConditionalRender>
                </div>
              </div>
            </div>
          </ConditionalRender>
        </Spin>
        <ConditionalRender if={!file && isView && !isEmptyArr(data)}>
          <div className={styles.upload__files}>
            <a href={data?.[0]?.name} className={styles.file}>
              <div>
                <EllipseFileIcon />
              </div>
              <div className={styles.file__right}>
                <div className={styles.name}>
                  <p>
                    {sliceText(data?.[0]?.original_name || "", 45)}
                    <span>{((data?.[0]?.size || 1) / 1000000).toFixed(2)} MB</span>
                  </p>
                  <ConditionalRender if={!disabled}>
                    <div className={styles.delete} onClick={onDeleteFile}>
                      <DeleteIcon />
                    </div>
                  </ConditionalRender>
                </div>
                <Progress status="active" percent={100} />
              </div>
            </a>
          </div>
        </ConditionalRender>
      </div>
    </>
  );
};

export default CustomFieldFile;
