import React, { ReactNode, useMemo } from "react";
import { Dropdown, Spin } from "antd";
import { ItemType } from "antd/es/menu/interface";

import { LoadingIndicator } from "../../../../../../components";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { cx } from "../../../../../app/utils/helpers/cx";
import { useGetPaymentTypeSelect } from "../../../../../settings/service/queries";
import { useCreateCompanyPersonPaymentType } from "../../../../../supply/service/mutations";
import { CompanyPersonModel, PaymentOrderModel } from "../../../../utils/models/paymentOrderModel";

import styles from "./orderViewBottom.module.scss";

type Props = {
  order: PaymentOrderModel | undefined;
  person?: CompanyPersonModel;
};

const OrderCompanyPersonName: React.FC<Props> = ({ person, order }) => {
  const { data: paymentTypes } = useGetPaymentTypeSelect();
  const createPaymentType = useCreateCompanyPersonPaymentType();

  const getPaymentTypeValue = useMemo(
    () => order?.company_person_payment_types?.find(item => item?.company_person_id === person?.id)?.payment_type,
    [order]
  );

  const onChangePayment = (e: number) => () => {
    createPaymentType.mutate({
      payment_type_id: e === getPaymentTypeValue?.id ? undefined : e,
      company_person_id: Number(person?.id),
      order_id: Number(order?.id)
    });
  };

  const items: ItemType[] =
    paymentTypes?.map(item => ({
      key: item?.id,
      label: (
        <div
          style={{
            background: `${item?.color}20`,
            color: item?.color
          }}
          className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
        >
          {item?.name}
        </div>
      ),
      onClick: onChangePayment(item?.id)
    })) || [];

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="flex items-center gap-3">
      <div className={cx(styles.lable)}>{person?.name || person?.person?.name}</div>
      <ConditionalRender if={person?.id}>
        <Spin spinning={createPaymentType.isLoading} indicator={LoadingIndicator}>
          <Dropdown
            menu={{
              items,
              selectable: true,
              selectedKeys: getPaymentTypeValue ? [String(getPaymentTypeValue?.id)] : []
            }}
            trigger={["click"]}
            dropdownRender={dropdownRender}
          >
            {getPaymentTypeValue ? (
              <div
                onClick={stopPropagation}
                style={{
                  background: `${getPaymentTypeValue?.color}20`,
                  color: getPaymentTypeValue?.color
                }}
                className="flex h-5 w-max items-center gap-2 rounded-xl px-2 py-0.5 text-xs font-medium"
              >
                {getPaymentTypeValue?.name}
              </div>
            ) : (
              <div
                onClick={stopPropagation}
                className={cx(
                  "person-select",
                  "rounded-[4px] border border-dashed border-gray-300 px-3 py-1 text-xs font-medium text-gray-300"
                )}
              >
                To'lov turi
              </div>
            )}
          </Dropdown>
        </Spin>
      </ConditionalRender>
    </div>
  );
};

export default OrderCompanyPersonName;
