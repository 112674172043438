import { Form, Input, Modal, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import {
  useGetLanguages,
  useGetResourceSelect,
  useGetUnitSelect,
} from "../../../../app/service/queries";
import { formRules } from "../../../../app/utils/constants/formRules";
import { useCreateProduct } from "../../../../settings/service/mutations";
import { ProductBodyModel } from "../../../utils/models/productBodyModel";
import { ProductFormModel } from "../../../utils/models/productFormModel";
import { FolderIdType } from "./OrderProductTemplate";
import { useAppSelector } from "../../../../../hooks/redux";
import { selectFilterOption } from "../../../../app/utils/helpers/selectFilterOption";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tabKey: string;
  folderId?: FolderIdType;
};

const { Item } = Form;
const { Option } = Select;

const CreateProductModal: React.FC<Props> = ({
  open,
  setOpen,
  tabKey,
  folderId: PropsFolderId,
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const [form] = Form.useForm<ProductFormModel>();
  const { data: languages } = useGetLanguages(open);
  const { data: resources } = useGetResourceSelect();
  const { data: units } = useGetUnitSelect();
  const createProduct = useCreateProduct();
  const { folderId } = useAppSelector((state) => state.supplyReducer.productDrawer);

  const onCancel = () => {
    setOpen(false);

    form.resetFields();
  };

  const onFinish = (values: ProductFormModel) => {
    let reqData: ProductBodyModel = { ...values, name: {} };

    if (tabKey === "3") {
      reqData = Object.assign(reqData, {
        subcategory_id: PropsFolderId?.sub_category_id
          ? PropsFolderId?.sub_category_id
          : folderId?.sub_category_id,
      });
    }
    if (tabKey === "2") {
      reqData = Object.assign(reqData, {
        category_id: PropsFolderId?.category_id
          ? PropsFolderId?.category_id
          : folderId?.category_id,
      });
    }

    languages?.forEach((lang) => {
      reqData = Object.assign(reqData, {
        name: {
          ...reqData.name,
          [lang.symbol]: values.name,
        },
      });
    });

    createProduct.mutateAsync(reqData).then(onCancel);
  };

  const onOk = () => form.submit();

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{
        loading: createProduct.isLoading,
      }}
      title="Mahsulot"
      okText="Saqlash"
      cancelText="Yopish"
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" rules={formRules()} label="Nomi">
          <Input placeholder="Nomini kiriting" />
        </Item>
        <Item label="Resursi" name="resource_id" rules={formRules()}>
          <Select placeholder="Resursni tanlang" suffixIcon={<SelectSuffixIcon />}>
            {resources?.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Birligi" name="unit_id" rules={formRules()}>
          <Select
            showSearch
            placeholder="Birligini tanlang"
            suffixIcon={<SelectSuffixIcon />}
            filterOption={selectFilterOption}
            notFoundContent={<TableEmpty />}
          >
            {units?.map((item) => (
              <Option
                key={item.id}
                value={item.id}
                props={{
                  name: item.name[language],
                }}
              >
                {item.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Mahsulot kodi" name="code">
          <Input placeholder="Mahsulot kodini kiriting" />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateProductModal;
