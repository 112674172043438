import React from "react";

const PackageSearch: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0833 6.06331L9.99997 9.9985M9.99997 9.9985L2.91664 6.06331M9.99997 9.9985L10 17.9152M17.5 9.99852V6.6164C17.5 6.33086 17.5 6.18809 17.4579 6.06076C17.4207 5.94811 17.3599 5.84471 17.2795 5.75747C17.1886 5.65885 17.0638 5.58952 16.8142 5.45085L10.6475 2.02493C10.4112 1.89363 10.293 1.82798 10.1679 1.80224C10.0571 1.77946 9.94288 1.77946 9.83213 1.80224C9.70698 1.82798 9.58881 1.89363 9.35248 2.02493L3.18581 5.45085C2.93621 5.58952 2.8114 5.65886 2.72053 5.75747C2.64013 5.84471 2.57929 5.94811 2.54207 6.06076C2.5 6.18809 2.5 6.33086 2.5 6.6164V13.3807C2.5 13.6662 2.5 13.809 2.54207 13.9363C2.57929 14.0489 2.64013 14.1523 2.72053 14.2396C2.8114 14.3382 2.93621 14.4075 3.18581 14.5462L9.35248 17.9721C9.58881 18.1034 9.70698 18.1691 9.83213 18.1948C9.94288 18.2176 10.0571 18.2176 10.1679 18.1948C10.293 18.1691 10.4112 18.1034 10.6475 17.9721L10.8333 17.8689M6.25 3.74852L13.75 7.91519M18.3333 17.9152L17.5 17.0819M18.3333 14.9985C18.3333 16.3792 17.214 17.4985 15.8333 17.4985C14.4526 17.4985 13.3333 16.3792 13.3333 14.9985C13.3333 13.6178 14.4526 12.4985 15.8333 12.4985C17.214 12.4985 18.3333 13.6178 18.3333 14.9985Z"
      stroke="#344054"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PackageSearch;
