import React from "react";

import { useAppSelector } from "../../../../hooks/redux";
import Header from "../../components/builder/header/Header";
import BuilderTable from "../../components/builder/table/BuilderTable";
import BuilderModal from "../../../app/components/builder-modal/BuilderModal";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { visible } = useAppSelector((state) => state.builderReducer.builderModal);

  return (
    <div className={styles.builder_container}>
      <span>
        <Header />
      </span>

      <span className={styles.container}>
        <BuilderTable />
      </span>
      {visible && <BuilderModal />}
    </div>
  );
};

export default Index;
