import React, { useId, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown, Input, MenuProps, Table, TableColumnsType, Tooltip } from "antd";
import { ExpandableConfig } from "antd/es/table/interface";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../store/reducers/companyPersonReducer";
import { inventoryReducerActions } from "../../../../../../../store/reducers/inventoryReducer";
import DotsVerticalIcon from "../../../../../../app/assets/icons/DotsVerticalIcon";
import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../../../app/utils/constants/languages";
import { parseParamsId } from "../../../../../../app/utils/helpers/parseParamsId";
import { PaymentTabKeys } from "../../../../../../payment/utils/constants/paymentTabKeys";
import { useCreateInventoryGroup, useDeleteInventory } from "../../../../../service/mutation";
import { useGetInventoryView } from "../../../../../service/queries";
import {
  CounterPartsInventoryDataModel,
  CounterPartsInventoryFolderModel
} from "../../../../../utils/models/counterpartsInventoryModel";

import styles from "./inventoryTable.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  defaultTabKey?: string;
};

const InventoryTable: React.FC<Props> = ({ defaultTabKey }) => {
  const { t } = useTranslation();
  const id = useId();
  const params = useParams();
  const { id: companyPersonId } = parseParamsId(params?.second_id);
  const dispatch = useDispatch();
  const deleteInventory = useDeleteInventory();
  const { data, isLoading } = useGetInventoryView(defaultTabKey === PaymentTabKeys.INVENTORY);
  const { setAddGroup, setVisibleInventory } = companyPersonActions;
  const { setInventoryDetailModalData } = inventoryReducerActions;
  const createGroup = useCreateInventoryGroup();
  const { addGroup } = useAppSelector(state => state.companyPersonReducer.inventory);

  const onBlur = () => {
    dispatch(setAddGroup(false));
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.stopPropagation();
    const target = event.target as HTMLInputElement;
    const { value } = target;
    const { key } = event;

    if (key === "Enter") {
      if (!value) {
        onBlur();
      } else {
        createGroup.mutateAsync({ name: value }).then(onBlur);
      }
    }
  };

  const inventories: CounterPartsInventoryFolderModel[] = useMemo(() => {
    const newData: CounterPartsInventoryFolderModel[] = [
      ...((data?.folders_with_inventories as never) ?? []),
      {
        id,
        name: `${t("Counterparts.Birikmagan inventorlar")}`,
        color: "",
        inventories_count: 0,
        inventories: [...(data?.inventories_without_folder ?? [])]
      }
    ];

    if (addGroup) {
      newData.unshift({
        id: uid(),
        isGroup: true,
        inventories: []
      });
    }

    return newData;
  }, [data, addGroup, id]);

  const onDelete = (id: number) => {
    deleteInventory.mutate(id);
  };

  const onOpenModal = (record?: CounterPartsInventoryDataModel, folderId?: number | string) => {
    let inventory_folder_id;

    if (typeof folderId === "string") {
      inventory_folder_id = undefined;
    } else {
      inventory_folder_id = Number(folderId);
    }

    dispatch(
      setVisibleInventory({
        visible: true,
        data: {
          id: record?.id,
          amount: record?.amount,
          amount_currency_id: record?.amount_currency?.id,
          code: record?.code,
          name: record?.name,
          service_amount: record?.service_amount,
          service_amount_currency_id: record?.service_amount_currency?.id,
          inventory_folder_id
        }
      })
    );
  };

  const columns: TableColumnsType<CounterPartsInventoryFolderModel> = [
    {
      title: `${t("Counterparts.Nomi")}`,
      render: (_, record) =>
        !record?.isGroup ? (
          <div className={styles.inventory_name}>
            <span>{record?.name}</span>
            <Tooltip title={t("Counterparts.Inventor qo'shish")}>
              <span onClick={() => onOpenModal(undefined, record?.id)}>
                <PlusCircleIcon fill />
              </span>
            </Tooltip>
          </div>
        ) : (
          <Input
            autoFocus
            className={styles.inventory__group}
            placeholder={t("Counterparts.Nomini kiriting")}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
        )
    },
    {
      title: `${t("Counterparts.Inventar kod")}`
    },
    {
      title: `${t("Counterparts.Tan narxi")}`
    },
    {
      title: `${t("Counterparts.Ish narxi")}`
    },
    {
      title: ""
    }
  ];

  const menuItems = (record: CounterPartsInventoryDataModel, folderId: number | string): MenuProps["items"] => [
    {
      key: "1",
      label: `${t("Counterparts.Tahrirlash")}`,
      onClick: e => {
        e.domEvent.stopPropagation();
        onOpenModal(record, folderId);
      }
    },
    {
      key: "2",
      label: `${t("Counterparts.O'chirish")}`,
      onClick: e => {
        e.domEvent.stopPropagation();
        onDelete(record?.id);
      }
    }
  ];

  const inventoryColumns = (folderId: number | string): TableColumnsType<CounterPartsInventoryDataModel> => [
    {},
    {
      render: (_, record) => (
        <div className={styles.inventory__name}>
          <div>MEX</div>
          <span>{record?.name}</span>
        </div>
      )
    },
    {
      dataIndex: "code"
    },
    {
      render: (_, record) => (
        <div className={styles.inventory__amount}>
          <span>{record?.amount?.toLocaleString(RU)}</span>
          <span>{record?.amount_currency?.symbol}</span>
        </div>
      )
    },
    {
      render: (_, record) => (
        <div className={styles.inventory__amount}>
          <span>{record?.service_amount?.toLocaleString(RU)}</span>
          <span>{record?.service_amount_currency?.symbol}</span>
        </div>
      )
    },
    {
      render: (_, record) => (
        <div className="flex justify-end">
          <Dropdown menu={{ items: menuItems(record, folderId) }} trigger={["click"]}>
            <span className="cursor-pointer" onClick={e => e.stopPropagation()}>
              <DotsVerticalIcon />
            </span>
          </Dropdown>
        </div>
      )
    }
  ];

  const expandable: ExpandableConfig<CounterPartsInventoryFolderModel> = {
    expandedRowRender: record => (
      <Table
        showHeader={false}
        dataSource={record?.inventories}
        columns={inventoryColumns(record?.id)}
        pagination={false}
        rowKey={row => row.id}
        className={styles.inventory__table}
        onRow={row => ({
          onClick: () => {
            dispatch(
              setInventoryDetailModalData({
                visible: true,
                inventory_id: row.id,
                company_person_id: +companyPersonId!
              })
            );
          }
        })}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
    ),
    expandIcon: ({ expanded, onExpand, record }) =>
      record?.isGroup ? null : (
        <span
          className={`${styles.expanded_icon} ${expanded ? styles.expanded_icon__active : ""}`}
          onClick={e => onExpand(record, e)}
        >
          <SelectSuffixIcon />
        </span>
      ),
    expandRowByClick: true
  };

  const onOpenAddGroup = () => {
    dispatch(setAddGroup(true));
  };

  const footer = () => (
    <div className={styles.add_category} onClick={onOpenAddGroup}>
      <PlusCircleIcon fill />
      Kategoriya qo'shish
    </div>
  );

  return (
    <>
      <Table
        footer={footer}
        columns={columns}
        pagination={false}
        expandable={expandable}
        rowKey={row => row.id}
        dataSource={inventories ?? []}
        className={`${styles.inventory} footer_table`}
        rowClassName="cursor-pointer"
        loading={{
          spinning: isLoading || createGroup.isLoading || deleteInventory.isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
    </>
  );
};

export default InventoryTable;
