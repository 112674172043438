import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { cx } from "features/app/utils/helpers/cx";

import { useQueryParams } from "hooks/useQueryParams";

import { RU, tableIndex } from "modules/common";
import { CompanyPersonCardTable, typeValues, useCompanyPersonBalance, useWidget } from "modules/dashboard";

const Bottom: React.FC = () => {
  const { queries } = useQueryParams();
  const { page, balance_total_amount_type } = queries();
  const type = balance_total_amount_type || typeValues.creditor;
  const { id, visible } = useWidget();
  const { data, total, isLoading } = useCompanyPersonBalance({
    id,
    type,
    enabled: visible
  });

  const { t } = useTranslation();

  const columns: ColumnsType<CompanyPersonCardTable> = [
    {
      title: "№",
      render: (_, __, index) => `${tableIndex(index, 1)}`
    },
    {
      title: `${t("dashboard.Nomi")}`,
      dataIndex: "name"
    },
    {
      title: `${t("dashboard.Telefon raqam")}`,
      render: (record: CompanyPersonCardTable) => record?.person?.phone
    },
    {
      title: `${t("dashboard.Ummumiy balans")}`,
      render: (record: CompanyPersonCardTable) => (
        <div className="flex gap-3">
          {record.balances.map(item => (
            <div
              key={item.id}
              className={cx("w-max rounded-md bg-gray-50 px-2 py-1 text-sm font-medium text-gray-600", {
                "bg-error-50 text-error-500": item.amount < 0,
                "bg-success-50 text-success-500": item.amount > 0
              })}
            >
              {item?.amount?.toLocaleString(RU)} {item?.currency.symbol}
            </div>
          ))}
        </div>
      )
    }
  ];

  return (
    <div className="flex flex-col gap-2.5">
      <Table<CompanyPersonCardTable>
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <Pagination
        paginationProps={{
          total,
          current: page ? Number(page) : 1
        }}
      />
    </div>
  );
};

export default Bottom;
