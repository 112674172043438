import React from "react";
import { Form, Input } from "antd";
// import { formRules } from "../../../utils/constants/formRules";

const { Item } = Form;

export interface Props {
  className: string;
  inputClassName: string;
}

const MfoOker: React.FC<Props> = ({ className, inputClassName }) => {
  return (
    <div className={className}>
      <Item
        name="mfo"
        label="MFO"
        // rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
      <Item
        name="oked"
        label="OKER"
        // rules={formRules()}
        className={inputClassName}
      >
        <Input placeholder="Kiriting.." />
      </Item>
    </div>
  );
};

export default MfoOker;
