import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { DefectModel } from "../../features/inventory/utils/models/defectModel";
import { InventoryDetailModel } from "../../features/inventory/utils/models/inventoryModel";

interface InventoryModalDataModel {
  visible: boolean;
  inventory?: InventoryDetailModel;
}

interface AttachModalDataModel {
  visible: boolean;
  inventory_id?: number;
  project_id?: number;
  project_name?: string;
  start_date?: string;
  due_date?: string;
}

interface DefectModalDataModel {
  product?: (InventoryDetailModel & DefectModel) | null;
  visible: boolean;
}

interface InventoryDetailModalData {
  visible: boolean;
  inventory_id?: number;
  company_person_id?: number;
}

interface initialState {
  creatingFolder: boolean;
  inventoryModalData: InventoryModalDataModel;
  attachModalData: AttachModalDataModel;
  defectModalData: DefectModalDataModel;
  inventoryDetailModalData: InventoryDetailModalData;
}

const initialState: initialState = {
  creatingFolder: false,
  inventoryModalData: { visible: false },
  attachModalData: { visible: false },
  defectModalData: { visible: false },
  inventoryDetailModalData: { visible: false },
};

const inventorySlice = createSlice({
  name: sliceNames.INVENTORY,
  initialState,
  reducers: {
    setCreatingFolder: (state, action: PayloadAction<boolean>) => {
      state.creatingFolder = action.payload;
    },
    setInventoryModal: (state, action: PayloadAction<InventoryModalDataModel>) => {
      state.inventoryModalData = action.payload;
    },
    setAttachModalData: (state, action: PayloadAction<AttachModalDataModel>) => {
      state.attachModalData = action.payload;
    },
    setDefectModalData: (
      state,
      action: PayloadAction<{
        visible: boolean;
        product: (DefectModel & InventoryDetailModel) | null;
      }>
    ) => {
      state.defectModalData = action.payload;
    },
    setInventoryDetailModalData: (
      state,
      action: PayloadAction<InventoryDetailModalData>
    ) => {
      state.inventoryDetailModalData = action.payload;
    },
  },
});

export default inventorySlice.reducer;
export const inventoryReducerActions = inventorySlice.actions;
