import { memo } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../store/reducers/projectsReducer";
import { useSectionImportData } from "../../../service/mutations";
import { SectionModel, TaskModel } from "../../../utils/models/estimateModel";

import Bottom from "./bottom/Bottom";
import Top from "./top/Top";

import styles from "./EstimateSectionsModal.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const EstimateSectionsModal = memo(({ open, setOpen }: Props) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const { sections } = useAppSelector(state => state.projectsReducer);
  const { setSections } = projectsReducerActions;
  const sectionImport = useSectionImportData();

  const onCancel = () => {
    setOpen(false);
  };

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      dispatch(setSections([]));
    }
  };

  const onOk = async () => {
    const result: SectionModel[] = [];

    for (let i = 0; i < sections.length; i += 1) {
      if (sections[i].checked) {
        const sectionResult: SectionModel = { ...sections[i], tasks: [] };
        const { tasks } = sections[i];

        for (let j = 0; j < tasks.length; j += 1) {
          if (tasks[j].checked) {
            const taskResult: TaskModel = { ...tasks[j], products: [] };
            const { products } = tasks[j];

            for (let k = 0; k < products?.length; k += 1) {
              if (products[k].checked) {
                taskResult.products.push(products[k]);
              }
            }
            sectionResult.tasks.push(taskResult);
          }
          if (tasks[j]?.is_title) {
            sectionResult.tasks.push(tasks[j]);
          }
        }
        result.push(sectionResult);
      }
    }

    sectionImport.mutateAsync({ sections: result, project_id: projectId! }).then(() => onCancel());
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={open}
      width={1428}
      okText="Saqlash"
      onCancel={onCancel}
      title="Smeta import"
      afterOpenChange={onAfterOpen}
      className={styles.sections_modal}
      okButtonProps={{
        loading: sectionImport.isLoading
      }}
      cancelText={<div onClick={onCancel}>Yopish</div>}
    >
      <Top />
      <Bottom />
    </Modal>
  );
});

export default EstimateSectionsModal;
