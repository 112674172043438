import { UserTableDataModel } from "features/app/utils/models/user/userTableConfigModel";

export const warehouseTransfersTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "created_at",
      name: "created_at",
      title: "Sana",
      checked: true
    },
    {
      id: "sender_warehouse.name",
      name: "sender_warehouse.name",
      title: "O’tkazuvchi omborxona",
      checked: true
    },
    {
      id: "recieved_warehouse.name",
      name: "recieved_warehouse.name",
      title: "Qabul qiluvchi omborxona",
      checked: true
    },
    {
      id: "sender_user.full_name",
      name: "sender_user.full_name",
      title: "Yuboruvchi",
      checked: true
    },
    {
      id: "recieved_user.full_name",
      name: "recieved_user.full_name",
      title: "Qabul qiluvchi",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "status",
      name: "status",
      title: "Holati",
      checked: true
    }
  ],
  size: 10
};
