import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AutoComplete, DatePicker, Form, Input, Modal, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import classNames from "classnames";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import ReactInputMask from "react-input-mask";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../hooks/redux";
import { addressReducerActions } from "../../../../../store/reducers/addressReducer";
import { companyPersonActions } from "../../../../../store/reducers/companyPersonReducer";
import DatepickerSuffixIcon from "../../../../app/assets/icons/DatepickerSuffixIcon";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { useGetCurrenySelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { formRules } from "../../../../app/utils/constants/formRules";
import { PHONE_MASK } from "../../../../app/utils/constants/phoneMask";
import { CurrencyTypeEnum } from "../../../../app/utils/enums/currencyTypeEnum";
import { lengthValidator } from "../../../../app/utils/helpers/lengthValidator";
import { negativeLocaleFormatter } from "../../../../app/utils/helpers/negativeLocaleFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import { removeNaN } from "../../../../app/utils/helpers/removeNaN";
import { replacePhone } from "../../../../app/utils/helpers/replacePhone";
import { TimeoutModel } from "../../../../app/utils/models/TimeoutModel";
import { useCreatePerson, useUpdatePerson } from "../../../service/mutation";
import { useGetCompanyPersonFoldersSelect, useGetPersonSelectSearch } from "../../../service/queries";
import { CounterpartsFormModel } from "../../../utils/models/counterpartsFormModel";
import { CounterpartsPersonModel } from "../../../utils/models/counterpartsPersonModel";

import CounterpartsAddress from "./CounterpartsAddress";

import styles from "./counterpartsModal.module.scss";

interface InnOptionModel {
  label: ReactNode;
  value: string;
  key: string;
}

const { Item, useWatch } = Form;
const { TextArea } = Input;
const { Option } = Select;

const CounterpartsModal: React.FC = () => {
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;
  const { visible, data, onAfterFunc, initialData } = useAppSelector(
    state => state.companyPersonReducer.createAndUpdateModal
  );
  const { t } = useTranslation();
  // const { center } = useAppSelector((state) => state.addressReducer.addressModal);
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const createPerson = useCreatePerson();
  const updatePerson = useUpdatePerson();
  const [form] = Form.useForm<CounterpartsFormModel>();
  const [isAddition, setIsAddition] = useState<boolean>(false);
  const [fieldsCount, setFieldsCount] = useState(0);
  const [search, setSearch] = useState<string>();
  const { data: persons } = useGetPersonSelectSearch(search);
  const { data: folders, isLoading: isLoadingFolders } = useGetCompanyPersonFoldersSelect(visible, data?.id);
  const [time, setTime] = useState<TimeoutModel>();
  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCurrenySelect();
  const { setAddressDataToEmpty, setDistrictId, setRegionId, setLatLongAddress } = addressReducerActions;

  const activeCurrency = useAuth().currencies.find(item => item?.type === CurrencyTypeEnum.BASE);

  const firstFieldCurrency = useWatch(["balances", 0, "currency_id"], form);

  const secondFieldCurrency = currencies?.filter(item => item.id !== firstFieldCurrency);

  const onChangeFirstCurrency = () => {
    if (secondFieldCurrency) {
      form.setFieldValue(["balances", 1, "currency_id"], secondFieldCurrency[0].id);
    }
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      if (data) {
        form.setFieldsValue({
          ...data.person,
          name: data?.name,
          type: data.type,
          description: data.description,
          start_date: dayjs(data.start_date, dayjsFormats.DATE),
          region_id: data?.person?.region?.id,
          district_id: data?.person?.district?.id,
          latitude: data?.person?.latitude,
          longitude: data?.person?.longitude
        });

        dispatch(setDistrictId(data?.person?.district?.id));
        dispatch(setRegionId(data?.person?.region?.id));
        dispatch(
          setLatLongAddress({
            lat: data?.person?.latitude,
            lng: data?.person?.longitude
          })
        );

        if (data?.balances[0]) {
          form.setFieldValue(["balances", 0, "currency_id"], data?.balances[0]?.currency.id);

          form.setFieldValue(["balances", 0, "start_amount"], String(data?.balances[0]?.start_amount) ?? "0");
        }
        if (data?.balances[1]) {
          setFieldsCount(1);

          form.setFieldValue(["balances", 1, "currency_id"], data?.balances[1]?.currency.id);

          form.setFieldValue(["balances", 1, "start_amount"], String(data?.balances[1]?.start_amount) ?? "0");
        }
      }

      if (!onAfterFunc) {
        form.setFieldValue("company_person_folder_id", id && +id);
      }

      if (onAfterFunc) {
        form.setFieldValue("name", initialData?.name);
        form.setFieldValue("phone", initialData?.phone);
      }
    } else {
      form.resetFields();

      dispatch(
        setVisibleModal({
          data: undefined,
          visible: false,
          onAfterFunc: undefined
        })
      );
    }
  };

  const addSecondField = () => {
    setFieldsCount(1);
  };

  const onCancel = () => {
    setIsAddition(false);
    setFieldsCount(0);

    dispatch(
      setVisibleModal({
        data,
        visible: false,
        onAfterFunc: undefined
      })
    );

    dispatch(setAddressDataToEmpty());
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: CounterpartsFormModel) => {
    const person_id = data?.id;
    const start_date = dayjs(values.start_date).format(dayjsFormats.DATE);
    const phone = replacePhone(values.phone);
    let balances: {
      currency_id: number;
      start_amount: string | number;
    }[] = [];

    if (values?.balances) {
      balances = values?.balances?.map(item => ({
        currency_id: item.currency_id,
        start_amount: parseLocaledString(item.start_amount as string) ?? 0
      }));
    }

    let reqData = {
      ...values,
      balances,
      start_date,
      phone
    };

    if (values?.latitude || values?.longitude) {
      reqData = {
        ...reqData,
        // @ts-ignore
        latitude: values?.latitude,
        longitude: values?.longitude
      };
    }

    if (!person_id) {
      if (id) {
        reqData.company_person_folder_id = id;
      }
    }

    if (onAfterFunc && !values?.company_person_folder_id) {
      delete reqData.company_person_folder_id;
    }

    if (person_id) {
      updatePerson
        .mutateAsync({
          id: person_id,
          ...reqData
        })
        .then(onCancel);
    } else {
      createPerson.mutateAsync(reqData).then(data => {
        onAfterFunc &&
          onAfterFunc({
            id: data?.data?.id as number,
            name: data?.data?.name,
            phone: data?.data?.phone
          });
        onCancel();
      });
    }
  };

  const onChangeAddition = () => {
    setIsAddition(!isAddition);
  };

  const tinOptions: InnOptionModel[] | undefined = persons?.map(item => ({
    label: (
      <div className={styles.inn__item}>
        <h4>{item.tin}</h4>
        <p>{item.name}</p>
        <p>{item.phone}</p>
      </div>
    ),
    value: item.tin,
    key: JSON.stringify(item)
  }));

  const onSelectInn = (_: string, option: InnOptionModel) => {
    const person = JSON.parse(option.key) as CounterpartsPersonModel;

    form.setFieldsValue({
      name: person.name,
      phone: person.phone,
      address: person.address
    });
  };

  const onSearchInn = (text: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(text);
      }, 800)
    );

    if (!text) {
      form.setFieldsValue({
        name: undefined,
        phone: "",
        address: undefined
      });
    }
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    form.setFieldValue(["balances", index, "start_amount"], negativeLocaleFormatter(e.currentTarget.value));
  };

  const typeOptions: DefaultOptionType[] = [
    {
      label: `${t("Counterparts.Ta'minotchi")}`,
      value: "supplier"
    },
    {
      label: `${t("Counterparts.Ishchi")}`,
      value: "worker"
    }
  ];

  const onPastePhone = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const value = replacePhone(event.clipboardData.getData("Text"));

    form.setFieldValue("phone", value);
    event.preventDefault();
  };

  const onChangeInn = (e: string) => {
    form.setFieldValue("tin", removeNaN(e));
  };

  const phoneRules = [lengthValidator(12), ...formRules()];

  const modalTitle = data ? t("Counterparts.Kontragentni tahrirlash") : t("Counterparts.Kontragent  qo'shish");

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      okText={t("Counterparts.Saqlash")}
      title={modalTitle}
      onCancel={onCancel}
      cancelText={t("Counterparts.Yopish")}
      className={styles.modal}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createPerson.isLoading || updatePerson.isLoading
      }}
    >
      <Form name="dynamic_form_nest_item" form={form} onFinish={onFinish} layout="vertical" autoComplete="off">
        <Item className="d_n" name="region_id" />
        <Item className="d_n" name="district_id" />
        <Item className="d_n" name="latitude" />
        <Item className="d_n" name="longitude" />
        <Item className="d_n" name="_id" />

        <Item name="tin" label={t("Counterparts.INN")} rules={formRules()}>
          <AutoComplete
            showSearch
            className={styles.inn}
            options={tinOptions}
            onSelect={onSelectInn}
            onChange={onChangeInn}
            onSearch={onSearchInn}
            optionFilterProp="children"
            placeholder={t("Counterparts.INN kiriting")}
          />
        </Item>
        <Item name="name" label={t("Counterparts.Nomi")} rules={formRules()}>
          <Input placeholder={t("Counterparts.Nomini kiriting")} />
        </Item>
        <Item name="phone" label={t("Counterparts.Telefon raqami")} rules={phoneRules}>
          <ReactInputMask
            maskChar=""
            mask={PHONE_MASK}
            onPaste={onPastePhone}
            placeholder="+998 99 999 99 99"
            className="ant-input mask css-dev-only-do-not-override-tacjbp"
          />
        </Item>
        <div
          className={classNames(styles.addition__btn, {
            [styles.addition__btn_active]: isAddition
          })}
          onClick={onChangeAddition}
        >
          <span>{t("Counterparts.Qo'shimcha ma'lumotlar")}</span>
          <SelectSuffixIcon />
        </div>
        <ConditionalRender if={isAddition}>
          <div
            className={classNames(styles.addition, {
              [styles.addition_close]: !isAddition
            })}
          >
            <Item name="type" label={t("Counterparts.Turi")} initialValue="supplier">
              <Select
                suffixIcon={<SelectSuffixIcon />}
                options={typeOptions}
                placeholder={t("Counterparts.Turni tanlang")}
              />
            </Item>
            <Item name="company_person_folder_id" label={t("Counterparts.Papka")}>
              <Select
                placeholder={t("Counterparts.Papka tanlang")}
                suffixIcon={<SelectSuffixIcon />}
                loading={isLoadingFolders}
                allowClear
              >
                {folders?.map(item => (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Item>
            <CounterpartsAddress form={form} />
            <div className={styles.balans}>
              <Item
                name={["balances", 0, "start_amount"]}
                label={t("Counterparts.Kontragent balansi")}
                style={{
                  gridArea: "1 / 1 / 2 / 3"
                }}
                initialValue="0"
              >
                <Input onChange={e => onChangeAmount(e, 0)} placeholder={t("Counterparts.Sarmoya narxini kiriting")} />
              </Item>
              <Item
                name={["balances", 0, "currency_id"]}
                style={{
                  gridArea: "1 / 3 / 2 / 4"
                }}
                label=" "
                initialValue={activeCurrency?.id}
              >
                <Select
                  suffixIcon={<SelectSuffixIcon />}
                  loading={isLoadingCurrencies}
                  onChange={onChangeFirstCurrency}
                  placeholder={t("Counterparts.Valyuta")}
                >
                  {currencies?.map(item => (
                    <Option value={item.id} key={item.id}>
                      <div className={styles.currency}>
                        <img src={item.icon} alt="" />
                        <span>{item.symbol}</span>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Item>
            </div>
            {fieldsCount === 1 && (
              <div className={styles.balans}>
                <Item
                  name={["balances", 1, "start_amount"]}
                  style={{
                    gridArea: "1 / 1 / 2 / 3"
                  }}
                  initialValue="0"
                >
                  <Input
                    onChange={e => onChangeAmount(e, 1)}
                    placeholder={t("Counterparts.Sarmoya narxini kiriting")}
                  />
                </Item>
                <Item
                  name={["balances", 1, "currency_id"]}
                  style={{
                    gridArea: "1 / 3 / 2 / 4"
                  }}
                  initialValue={secondFieldCurrency && secondFieldCurrency[0].id}
                >
                  <div className={styles.second__currency}>
                    <img src={secondFieldCurrency && secondFieldCurrency[0]?.icon} alt="" />
                    <span>{secondFieldCurrency && secondFieldCurrency[0]?.symbol}</span>
                  </div>
                </Item>
              </div>
            )}
            {fieldsCount < 1 && (
              <div className={styles.balans__add} onClick={addSecondField}>
                <PlusCircleIcon fill />
                {t("Counterparts.Sarmoya")}
              </div>
            )}
            <Item name="start_date" label={t("Counterparts.Balans sanasi")} initialValue={dayjs()}>
              <DatePicker
                suffixIcon={<DatepickerSuffixIcon />}
                format={dayjsFormats.DATE}
                placeholder={t("Counterparts.Sanani tanlang")}
              />
            </Item>
            <Item name="description" label={t("Counterparts.Izohi")}>
              <TextArea placeholder={t("Counterparts.Izohni kiriting")} />
            </Item>
          </div>
        </ConditionalRender>
      </Form>
    </Modal>
  );
};

export default CounterpartsModal;
