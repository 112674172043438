import React from "react";
import { useDispatch } from "react-redux";
import { Button, message, Modal } from "antd";
import dayjs from "dayjs";
import { uid } from "uid";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { ProductSelectModel } from "../../../../app/utils/models/productSelectModel";
import CounterpartsModal from "../../../../counterparts/components/counterparts/modal/CounterpartsModal";
import FileQuestionIcon from "../../../../payment/assets/icons/FileQuestionIcon";
import { useGetWarehouseSelect } from "../../../../warehouse/service/query";
import { useCreateParty, useGetWarehouseProjects } from "../../../service/mutations";
import { StatusEnums } from "../../../utils/enums/statusEnums";
import { numberRound } from "../../../utils/helpers/numberRound";
import { OfferPartyErrorModel, OfferPartyLeftModel } from "../../../utils/models/offerPartyModel";
import { PartyReqBottomModel, PartyReqModel } from "../../../utils/models/partyReqModel";
import PartyEstimate from "../../parts/party-estimate/PartyEstimate";
import ProductDrawer from "../../parts/product-drawer/ProductDrawer";

import OfferPartyModalLeft from "./left/OfferPartyModalLeft";
import OfferPartyModalRight from "./right/OfferPartyModalRight";
import OfferPartyModalTitle from "./title/OfferPartyModalTitle";

import styles from "./offerPartyModal.module.scss";

const OfferPartyModal: React.FC = () => {
  const dispatch = useDispatch();
  const {
    setOfferPartyModal,
    setOfferPartySelectProducts,
    setOfferPartyRightData,
    setOfferPartyLeftData,
    setSelectOffers,
    setClearSelectProducts,
    setOfferPartyModalError,
    setOfferPartyIsGrouping,
    setCheckData
  } = supplyOfferActions;
  const { visible, selectData, leftData, rightData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const createParty = useCreateParty();

  const { products } = useAppSelector(state => state.supplyReducer.productDrawerData);

  const { products: estimateProducts } = useAppSelector(state => state.supplyReducer.estimateData);

  const getProjects = useGetWarehouseProjects();
  const { data: warehouses } = useGetWarehouseSelect(true, visible);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      const generateProductsData: ProductSelectModel[] = [];
      const newLeftData: OfferPartyLeftModel[] = [];

      selectData?.forEach(item => {
        generateProductsData.push({
          price: 0,
          status: "  ",
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          id: +item?.product?.id!,
          unit: item.unit as never,
          // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
          name: item?.product?.name!,
          resource: item?.product?.resource as never
        });

        item?.warehouse_product_offers?.forEach(offer => {
          const newPerson = offer?.company_person
            ? {
                id: offer?.company_person?.id,
                name: offer?.company_person?.name,
                phone: offer?.phone
              }
            : {
                id: undefined,
                name: offer?.name,
                phone: offer?.phone
              };

          if (offer?.status === StatusEnums.RECIEVED) {
            newLeftData.push({
              id: item?.id,
              unit_id: item?.unit?.id,
              unique_id: uid(12),
              company_person: newPerson,
              project_id: item?.project?.id,
              // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
              product_id: +item?.product?.id!,
              warehouse_id: item.warehouse?.id,
              currency_id: offer?.currency?.id,
              amount: localeFormatter(String(offer?.amount)),
              quantity: localeFormatter(String(item?.quantity)),
              total: localeFormatter(String((offer?.amount ?? 0) * (item?.quantity ?? 0)))
            });
          }
        });
      });

      dispatch(setOfferPartyLeftData(newLeftData));
      dispatch(setOfferPartySelectProducts(generateProductsData));

      dispatch(
        setOfferPartyRightData({
          agent_id: undefined,
          status: StatusEnums.ORDERED,
          payment_date: undefined,
          delivery_date: undefined
        })
      );
    } else {
      dispatch(setCheckData([]));
      dispatch(setClearSelectProducts());
      dispatch(setOfferPartyLeftData([]));
      dispatch(setOfferPartyRightData({}));
      dispatch(setOfferPartyIsGrouping(false));
    }
  };

  const onCancel = () => {
    dispatch(
      setOfferPartyModal({
        visible: false
      })
    );
  };

  const afterFinish = () => {
    onCancel();
    dispatch(setSelectOffers([]));
  };

  const onSubmit = (status: StatusEnums) => () => {
    const generateErrors: OfferPartyErrorModel[] = [];
    const bottomData: PartyReqBottomModel[] = [];

    leftData?.forEach(item => {
      const parseQuantity = parseLocaledString(String(item?.quantity)) ?? 0;
      const parseAmount = parseLocaledString(String(item?.amount)) ?? 0;

      bottomData.push({
        id: item?.id,
        task_product_id: item?.task_product_id,
        product_id: item.product_id!,
        unit_id: item.unit_id!,
        warehouse_id: item?.warehouse_id,
        project_id: item?.project_id,
        quantity: parseQuantity,
        amount: parseAmount,
        company_person_id: item?.company_person?.id,
        currency_id: item?.currency_id
      });

      if (
        !parseQuantity ||
        !item.product_id ||
        !item.unit_id ||
        !item.quantity ||
        !item.warehouse_id ||
        !item.company_person?.id ||
        !item.currency_id ||
        !parseAmount
      ) {
        generateErrors.push({
          uniqueId: item.unique_id,
          amount: !parseAmount,
          product: !item.product_id,
          unit: !item.unit_id,
          quantity: !parseQuantity,
          warehouse: !item.warehouse_id,
          companyPerson: !item.company_person?.id,
          currency: !item.currency_id
        });
      }
    });

    dispatch(setOfferPartyModalError(generateErrors));

    if (isEmptyArr(bottomData)) {
      message.info("Kamida bitta mahsulot tanlanishi zarur");
    } else if (isEmptyArr(generateErrors)) {
      const reqData: PartyReqModel = {
        status,
        agent_id: rightData?.agent_id,
        warehouse_products: bottomData,
        payment_date: dayjs(rightData?.payment_date).format(dayjsFormats.DATE),
        delivery_date: dayjs(rightData?.delivery_date).format(dayjsFormats.DATE)
      };

      createParty.mutateAsync(reqData).then(afterFinish);
    }
  };

  const footer = () => (
    <div className={styles.footer}>
      <div className={styles.footer__left}>
        <Button onClick={onSubmit(StatusEnums.OPEN)}>
          <FileQuestionIcon />
          Qoralama
        </Button>
      </div>
      <div className={styles.footer__right}>
        <Button onClick={onCancel}>Yopish</Button>
        <Button onClick={onSubmit(StatusEnums.ORDERED)} loading={createParty.isLoading} type="primary">
          Saqlash
        </Button>
      </div>
    </div>
  );

  const onClearProduct = (isTaskProduct: boolean) => () => {
    dispatch(
      setOfferPartyLeftData(leftData?.filter(item => (isTaskProduct ? !item.task_product_id : item.task_product_id)))
    );
  };

  const onImportEstimateProduct = () =>
    new Promise(resolve => {
      dispatch(
        setOfferPartySelectProducts(
          estimateProducts?.map(item => ({
            id: item.product?.id,
            name: item?.product?.name,
            resource: item.product?.resource,
            price: 0,
            status: item?.status,
            unit: item.unit
          })) ?? []
        )
      );

      getProjects.mutateAsync(warehouses?.[0]?.id).then(({ data }) => {
        const generateEstimateProducts = estimateProducts?.map(item => {
          const newQuantity = String(
            numberRound(
              item.quantity - item.total_ordered_quantity > 0 ? item.quantity - item.total_ordered_quantity : 0
            )
          );

          return {
            quantity: newQuantity,
            currency_id: undefined,
            unit_id: item?.unit?.id,
            unique_id: uid(12),
            task_product_id: item.id,
            project_id: data?.[0]?.id,
            company_person: {
              id: undefined,
              name: undefined
            },
            product_id: item?.product?.id,
            warehouse_id: warehouses?.[0]?.id,
            amount: localeFormatter(String(numberRound(item.amount))),
            total: localeFormatter(String(numberRound(parseLocaledString(newQuantity) * item.amount)))
          };
        });

        dispatch(
          setOfferPartyLeftData([
            ...(leftData?.filter((item: OfferPartyLeftModel) => item?.product_id) ?? []),
            ...(generateEstimateProducts ?? [])
          ])
        );

        resolve(true);
      });
    });

  const onImportProduct = () => {
    dispatch(
      setOfferPartySelectProducts(
        products?.map(item => ({
          id: item.id,
          name: item?.name,
          resource: item?.resource,
          price: item.price,
          status: item?.status,
          unit: item.unit
        })) ?? []
      )
    );

    dispatch(
      setOfferPartyLeftData([
        ...(leftData?.filter((item: OfferPartyLeftModel) => item?.product_id) ?? []),
        ...(products?.map(item => ({
          amount: undefined,
          quantity: undefined,
          product_id: item?.id,
          currency_id: undefined,
          unit_id: item?.unit?.id,
          unique_id: uid(12),
          task_product_id: undefined,
          company_person: {
            id: undefined,
            name: undefined
          }
        })) ?? [])
      ])
    );
  };

  return (
    <Modal
      centered
      open={visible}
      closeIcon={null}
      footer={footer()}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      className={styles.party_modal}
      title={<OfferPartyModalTitle />}
    >
      <div className={styles.party_modal__content}>
        <OfferPartyModalLeft />
        <OfferPartyModalRight />
      </div>
      <PartyEstimate importAction={onImportEstimateProduct} clearAction={onClearProduct(true)} />
      <ProductDrawer importAction={onImportProduct} clearAction={onClearProduct(false)} />
      <CounterpartsModal />
    </Modal>
  );
};

export default OfferPartyModal;
