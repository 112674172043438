import { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import DatepickerSuffixIcon from "../../../assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../../utils/constants/dayjsFormats";

import styles from "./filterDateRange.module.scss";

const { RangePicker } = DatePicker;

type Props = {
  value: null | (Dayjs | null)[];
  setValue: (value: null | (Dayjs | null)[]) => void;
  placeholder?: string;
  classNames?: string;
};

type RangeValue = Parameters<NonNullable<React.ComponentProps<typeof DatePicker.RangePicker>["onChange"]>>[0];

const FilterDateRange = ({ value, setValue, placeholder }: Props) => {
  const { t } = useTranslation();
  const min_date = value![0] ? dayjs(value![0], dayjsFormats.DATE).format(dayjsFormats.DATE) : null;
  const max_date = value![1] ? dayjs(value![1], dayjsFormats.DATE).format(dayjsFormats.DATE) : null;
  const [dropdown, setDropdownVisible] = useState({
    visible: false,
    specialVisible: false,
    specialChange: false
  });

  const [specialDateValue, setSpecialDateValue] = useState<{ value: (null | Dayjs)[] }>({ value: [null, null] });
  const [dateValue, setDateValue] = useState<string | { value: string }>("");

  const onChange = (value: string | { value: string }) => {
    if (typeof value === "object") {
      const { maxDate, minDate } = JSON.parse(value?.value);

      setValue([dayjs(minDate, dayjsFormats.DATE), dayjs(maxDate, dayjsFormats.DATE)]);
      setDateValue(value?.value);
    } else {
      setDateValue(value?.split("/")[1]);
    }
  };

  const onChangeRangePicker = (values: RangeValue, formatString: [string, string]) => {
    onChange(`special/${values?.[0]?.format(dayjsFormats.DATE)}-${values?.[1]?.format(dayjsFormats.DATE)}`);

    setSpecialDateValue({
      value: formatString[0] ? ([values?.[0], values?.[1]] as any) : ["", ""]
    });
    setDropdownVisible(prev => ({
      ...prev,
      specialChange: true
    }));
  };

  const onOpenChangeSpecialPicker = (open: boolean) => {
    setDropdownVisible(prev => ({
      ...prev,
      specialVisible: open
    }));
  };

  const dateOptions: DefaultOptionType[] | undefined = [
    {
      value: JSON.stringify({
        minDate: dayjs().format(dayjsFormats.DATE),
        maxDate: dayjs().add(1, "day").format(dayjsFormats.DATE)
      }),
      label: `${t("filterDatePicker.Bugun")}`
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("week")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("week").format(dayjsFormats.DATE)
      }),
      label: `${t("filterDatePicker.Oxirgi hafta")}`
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("month")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("month").format(dayjsFormats.DATE)
      }),
      label: `${t("filterDatePicker.Oxirgi oy")}`
    },
    {
      value: JSON.stringify({
        minDate: dayjs().startOf("year")?.format(dayjsFormats.DATE),
        maxDate: dayjs().endOf("year").format(dayjsFormats.DATE)
      }),
      label: `${t("filterDatePicker.Oxirgi yil")}`
    },
    {
      value: "special",
      className: styles.specialOption,
      label: (
        <div
          className={cx("flex cursor-pointer items-center gap-2", styles.specialOption_item)}
          onClick={e => e.stopPropagation()}
        >
          <span>{t("filterDatePicker.Maxsus")}</span>
          <RangePicker
            size="small"
            onChange={onChangeRangePicker}
            className={styles.rangePicker}
            open={dropdown.specialVisible}
            onOpenChange={onOpenChangeSpecialPicker}
            // @ts-ignore
            value={specialDateValue.value}
            suffixIcon={<DatepickerSuffixIcon size={20} />}
            format={dayjsFormats.DATE}
          />
        </div>
      )
    }
  ];

  const onVisibleChangeDropdown = (open: boolean) => {
    if (!dropdown.specialVisible) {
      setDropdownVisible(prev => ({ ...prev, visible: open }));
    }
  };

  useEffect(() => {
    if (value?.length === 0) {
      setDateValue("");
      setSpecialDateValue({ value: [null, null] });
    }
  }, [value]);

  useEffect(() => {
    if (dropdown.specialChange && !dropdown.specialVisible) {
      setDropdownVisible(prev => ({
        ...prev,
        visible: false
      }));
    }
  }, [dropdown.specialVisible, dropdown.specialChange]);

  useEffect(() => {
    setValue(specialDateValue.value);
  }, [specialDateValue]);

  useEffect(() => {
    if (min_date && max_date) {
      const dateValue = JSON.stringify({
        minDate: min_date,
        maxDate: max_date
      });

      const filteredDateOptions = dateOptions.filter(el => el.value !== "special");

      const foundOne = filteredDateOptions?.find(el => el.value === dateValue);

      if (foundOne) {
        setDateValue({ value: dateValue });
      } else {
        setSpecialDateValue({ value: [dayjs(min_date, dayjsFormats.DATE), dayjs(max_date, dayjsFormats.DATE)] });
        setDateValue(`${min_date}-${max_date}`);
      }
    }
  }, [window.location.search]);

  return (
    <Select
      placeholder={placeholder || t("filterDatePicker.Sana")}
      allowClear
      labelInValue
      options={dateOptions}
      open={dropdown.visible}
      onChange={onChange}
      popupMatchSelectWidth={false}
      className={styles.datePicker}
      value={dateValue || undefined}
      suffixIcon={<DatepickerSuffixIcon />}
      onDropdownVisibleChange={onVisibleChangeDropdown}
    />
  );
};

export default FilterDateRange;
