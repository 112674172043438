import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { warehouseOrderActions } from "../../../../../store/reducers/warehouseOrderReducer";
import { RootState } from "../../../../../store";
import Comments from "../../../../app/components/comments/Comments";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import AdditionalLeft from "./left/AdditionalLeft";

import styles from "./additionalModal.module.scss";

const AdditionalModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { setVisibleAdditional } = warehouseOrderActions;
  const { visible, data, order_id } = useAppSelector((state: RootState) => state.warehouseOrderReducer.additional);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const onCancel = () => {
    dispatch(
      setVisibleAdditional({
        data: undefined,
        visible: false,
        order_id: undefined
      })
    );
    setSelectedIds([]);
  };

  const footer = <Button onClick={onCancel}>{t("warehouse.Yopish")}</Button>;

  return (
    <Modal
      centered
      open={visible}
      onCancel={onCancel}
      className={styles.additional}
      title={t("warehouse.Mahsulot qabul qilish")}
      footer={footer}
      width="77.4rem"
    >
      <div className={styles.content}>
        <div className={styles.left}>
          <AdditionalLeft selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
        </div>
        <div className={styles.right}>
          <Comments objectId={data?.id || order_id} type={ChatEnum.WAREHOUSE_PRODUCTS} />
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalModal;
