import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useAppSelector } from "../../../hooks/redux";
import useGetTableSize from "../../../hooks/useGetTableSize";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { PaymentTypeEnum } from "../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../app/utils/constants/tableConfigKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { parseParamsId } from "../../app/utils/helpers/parseParamsId";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { PaymentModel } from "../../app/utils/models/payment/paymentModel";
import { counterpartsEndPoints } from "../../counterparts/utils/constants/counterpartsEndPoints";
import { CounterpartsCompanyPersonModel } from "../../counterparts/utils/models/counterpartsCompanyPersonModel";
import { groupTypes } from "../../projects/utils/enums/groupTypes";
import { GroupSectionModel, SectionModel } from "../../projects/utils/models/sectionModel";
import { paymentEndPoints } from "../utils/constants/paymentEndPoints";
import { paymentQueryKeys } from "../utils/constants/paymentQueryKeys";
import { PaymentTabKeys } from "../utils/constants/paymentTabKeys";
import { PaymentFilterKeys } from "../utils/enums/paymentFilterKeys";
import { AccurateCashMoneySelectModel } from "../utils/models/accurateCashMoneySelectModel";
import { ICalendarHistoryOrderModel } from "../utils/models/calendarHistoryOrderModel";
import { CalendarHistoryPaymentModel } from "../utils/models/calendarHistoryPaymentModel";
import { ExpectedPaymentModel } from "../utils/models/expectedPaymentModel";
import { ExpectedPaymentTemplateModel } from "../utils/models/expectedPaymentTemplateModel";
import { FinancialSelectModel } from "../utils/models/financialSelectModel";
import { InventorySelectModel } from "../utils/models/inventorySelectModel";
import { OneExpectedPaymentTemplateModel } from "../utils/models/oneExpectedPaymentTemplateModel";
import { PaymentCashMoneyModel } from "../utils/models/paymentCashMoneyModel";
import { PaymentDeleteArchive } from "../utils/models/paymentDeleteArchive";
import { PaymentInventoryWorkModel } from "../utils/models/paymentInventoryModel";
import { PaymentOrderAdditionalModel } from "../utils/models/paymentOrderAdditionalModel";
import { PaymentOrderAdditionalRejectModel } from "../utils/models/paymentOrderAdditionalRejectModel";
import { PaymentOrderDetailModel } from "../utils/models/paymentOrderDetailModel";
import { PaymentOrderModel } from "../utils/models/paymentOrderModel";
import { TransferModel } from "../utils/models/TransferModel";

export function useGetPayment() {
  const { queries, severalSearchParams, reqQueryParam } = useQueryParams();
  const { statistics_type } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY);
  const { firstStatisticsType } = useAppSelector(state => state.paymentReducer);

  let url = useGetTableSize({
    endpoint: paymentEndPoints.PAYMENTS,
    tableConfigKey: tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE
  });

  if (searchParams) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.TYPE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.SORT_DATE,
      queryParamsKeys.SORT_DATE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.FILTER_TYPE,
      queryParamsKeys.SORT_AMOUNT,
      queryParamsKeys.CASH_IDS_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SORT_CASH_NAME,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.SORT_PROJECT_NAME,
      queryParamsKeys.FINANCIAL_IDS_ARR,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.SORT_FINANCIAL_NAME,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.SIZE,
      queryParamsKeys.INCOME_FINANCIAL_IDS_ARR,
      queryParamsKeys.EXPENSE_FINANCIAL_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<PaymentModel[]>>(
    [paymentQueryKeys.PAYMENT_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled:
        firstStatisticsType.first === PaymentTabKeys.INCOME_EXPENSE || statistics_type === PaymentTabKeys.INCOME_EXPENSE
    }
  );
}

export function useGetPaymentStatistics<T>() {
  let url = paymentEndPoints.PAYMENT_STATISTICS;
  const { reqQueryParam, queries, severalSearchParams } = useQueryParams();
  const { statistics_type } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  const { firstTabKey } = useAppSelector(state => state.paymentReducer);

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.STATISTICS_TYPE,
      queryParamsKeys.TYPE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.CASH_IDS_ARR,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.FINANCIAL_IDS_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.SEARCH,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.VIEW_TYPE,
      queryParamsKeys.FILTER_TYPE,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.CUSTOM_FIELDS_ARR,
      queryParamsKeys.INCOME_FINANCIAL_IDS_ARR,
      queryParamsKeys.EXPENSE_FINANCIAL_IDS_ARR
    )}${!statistics_type ? `&${firstTabKey}` : ""}`;
  } else {
    url = `${paymentEndPoints.PAYMENT_STATISTICS}?${firstTabKey}`;
  }

  return useQuery<T>([paymentQueryKeys.PAYMENT_STATISTICS, searchParams], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetCashMoneySelect(enabled: boolean, data?: Record<string, number | string | undefined>) {
  let url = paymentEndPoints.CASH_MONEY_SELECT;
  const { generateSearchParam } = useQueryParams();

  if (data) {
    const searchParams = generateSearchParam(data);

    url += `?${searchParams}`;
  }

  return useQuery<PaymentCashMoneyModel[]>(
    [paymentQueryKeys.CASH_MONEY_SELECT, data],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetProjectSelect(argument: {
  id?: number;
  step?: number;
  enabled?: boolean;
  warehouse_id?: number;
}) {
  let url = paymentEndPoints.PROJECT_SELECT;
  const { id, step, enabled, warehouse_id } = argument;

  if (id) {
    url += `?cash_id=${id}`;
  }

  if (warehouse_id && !id) {
    url += `?warehouse_id=${warehouse_id}`;
  }

  return useQuery<{ id: number; name: string }[]>(
    [paymentQueryKeys.PROJECT_SELECT, id, step, warehouse_id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: (!!id && step !== 2) || enabled
    }
  );
}

export function useGetCashMoneyPaymentTypeSelect(arg?: {
  cashId?: number;
  projectId?: number;
  currency_id?: number;
  type?: PaymentTypeEnum;
  enabled: boolean;
}) {
  const { generateSearchParam } = useQueryParams();
  let url = paymentEndPoints.CASH_MONEY_PAYMENT_TYPE_SELECT;

  const searchParam = generateSearchParam({
    type: arg?.type,
    cash_id: arg?.cashId,
    project_id: arg?.projectId,
    currency_id: arg?.currency_id
  });

  if (searchParam) {
    url += `?${searchParam}`;
  }

  return useQuery<PaymentCashMoneyModel[]>(
    [paymentQueryKeys.CASH_MONEY_PAYMENT_TYPE_SELECT, searchParam],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: arg?.enabled
    }
  );
}

export function useGetArchivePaymentTemplate(argument: { type: string; page: number }) {
  return useQuery<PaginationType<ExpectedPaymentTemplateModel[]>>(
    [paymentQueryKeys.ARCHIVE_PAYMENT_TEMPLATE, argument.type, argument.page],
    async () => {
      const res = await $api.get(
        `${paymentEndPoints.EXPECTED_PAYMENT_TEMPLATE}?type=${argument.type}&page=${argument.page}`
      );

      return res.data?.data;
    }
  );
}

export function useGetExpectedPaymentTemplate(counterPartsId?: string) {
  let url = paymentEndPoints.EXPECTED_PAYMENT_TEMPLATE;
  const { reqQueryParam, searchParams } = useQueryParams();
  // const {} = queries();

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.TYPE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.PAGE
    )}${counterPartsId ? `&${queryParamsKeys.COMPANY_PERSON_IDS_ARR}=${counterPartsId}` : ""}`;
  }

  return useQuery<PaginationType<ExpectedPaymentTemplateModel[]>>(
    [paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    }
  );
}

export function useGetOneExpectedPaymentTemplate(id?: number) {
  return useQuery<OneExpectedPaymentTemplateModel>(
    [paymentQueryKeys.ONE_EXPECTED_PAYMENT_TEMPLATE, id],
    async () => {
      const res = await $api.get(`${paymentEndPoints.EXPECTED_PAYMENT_TEMPLATE}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetExpectedPayments(counterPartsId?: string) {
  let url = paymentEndPoints.EXPECTED_PAYMENTS;
  const { reqQueryParam, searchParams } = useQueryParams();
  const { firstStatisticsType } = useAppSelector(state => state.paymentReducer);

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.TYPE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.PAGE
    )}${counterPartsId ? `&${queryParamsKeys.COMPANY_PERSON_IDS_ARR}=${counterPartsId}` : ""}`;
  } else {
    url += `?type=${firstStatisticsType.second}`;
  }

  return useQuery<PaginationType<ExpectedPaymentModel[]>>(
    [paymentQueryKeys.EXPECTED_PAYMENTS, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    }
  );
}

// export function useGetCashMoneyPayments(cashId?: number) {
//   return useQuery<AccurateCashMoneySelectModel[]>(
//     [paymentQueryKeys.CASH_MONEY_PAYMENTS, cashId],
//     async () => {
//       const res = await $api.get(`${paymentEndPoints.ACCURATE_CASH_MONEY_SELECT}?cash_id=${cashId}`);
//
//       return res.data.data;
//     },
//     {
//       enabled: !!cashId
//     }
//   );
// }

// export function useGetAccurateCashMoneySelect(
//   data?: {
//     cash_id?: number;
//     payment_type_id?: number;
//     currency_id?: number;
//     project_id?: number;
//   },
//   enabled?: boolean
// ) {
//   let url = paymentEndPoints.ACCURATE_CASH_MONEY_SELECT;
//   const { generateSearchParam } = useQueryParams();
//   const searchParams = generateSearchParam(data || {});
//
//   if (searchParams) {
//     url += `?${searchParams}`;
//   }
//
//   return useQuery<AccurateCashMoneySelectModel[]>(
//     [paymentQueryKeys.ACCURATE_CASH_MONEY_SELECT, searchParams],
//     async () => {
//       const res = await $api.get(url);
//
//       return res.data.data;
//     },
//     {
//       enabled: enabled ?? Boolean(data)
//     }
//   );
// }

export function useGetTransfers(enabled: boolean) {
  return useQuery<TransferModel[]>(
    [paymentQueryKeys.TRANSFER_VIEW],
    async () => {
      const res = await $api.get(paymentEndPoints.TRANSFERS);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetPaymentsOrder() {
  const { severalSearchParams, reqQueryParam } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);

  let url = useGetTableSize({
    endpoint: paymentEndPoints.PAYMENTS_ORDER,
    tableConfigKey: tableConfigKeys.PAYMENT_ORDER_CONFIG
  });

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery<PaginationType<PaymentOrderModel[]>>(
    [paymentQueryKeys.PAYMENTS_ORDER_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    }
  );
}

export function useGetPaymentOrder(id?: number, isCounterpart?: boolean) {
  const params = useParams();
  const { id: counterpartId } = parseParamsId(params?.second_id);
  let url: string = "";

  if (isCounterpart && counterpartId) {
    url = `${counterpartsEndPoints.ORDER}?id=${id}&company_person_id=${counterpartId}`;
  } else {
    url = `${paymentEndPoints.PAYMENTS_ORDER}?id=${id}`;
  }

  return useQuery<PaymentOrderDetailModel>(
    [paymentQueryKeys.PAYMENT_ORDER_VIEW, id, Boolean(isCounterpart)],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id
    }
  );
}

export function useGetPaymentOrderAdditional(enabled: boolean, id: number) {
  return useQuery<PaymentOrderAdditionalModel>(
    [paymentQueryKeys.PAYMENT_ORDER_ADDITIONAL, id],
    async () => {
      const res = await $api.get(`${paymentEndPoints.PAYMENT_ORDER_ADDITIONAL}?id=${id}`);

      return res.data.data;
    },
    {
      enabled: enabled && !!id
    }
  );
}

export function useGetPaymentOrderRejectAdditional(enabled: boolean, id?: number) {
  return useQuery<PaymentOrderAdditionalRejectModel[]>(
    [paymentQueryKeys.PAYMENT_ORDER_ADDITIONAL_REJECT, id],
    async () => {
      const res = await $api.get(`${paymentEndPoints.PAYMENT_ORDER_ADDITIONAL_REJECT}?order_id=${id}`);

      return res.data.data;
    },
    {
      enabled: enabled && !!id
    }
  );
}

export function useGetWorkPayments() {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = paymentEndPoints.PAYMENT_WORK_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<PaymentInventoryWorkModel[]>>([paymentQueryKeys.PAYMENT_WORK_VIEW, url], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetInventoryPayments() {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = paymentEndPoints.PAYMENT_INVENTORY_VIEW;

  if (searchParams && searchParams?.length > 0) {
    url += `?${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE
    )}`;
  }

  return useQuery<PaginationType<PaymentInventoryWorkModel[]>>(
    [paymentQueryKeys.PAYMENT_INVENTORY_VIEW, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    }
  );
}

export function useGetInventorySelect(step: number, enabled?: boolean) {
  return useQuery<InventorySelectModel[]>(
    [paymentQueryKeys.INVENTORY_SELECT],
    async () => {
      const res = await $api.get(paymentEndPoints.INVENTORY_SELECT);

      return res.data.data;
    },
    {
      enabled: enabled && step === 2
    }
  );
}

export function useGetCashMoneyProjects(data: { cash_id?: number; payment_type_id?: number; currency_id?: number }) {
  let url = paymentEndPoints.ACCURATE_CASH_MONEY_SELECT;

  if (data) {
    const searchParams = new URLSearchParams("");

    Object.keys(data)?.forEach(key => {
      // @ts-ignore
      searchParams.append(key, data[key]);
    });

    url += `?${searchParams}`;
  }

  return useQuery<AccurateCashMoneySelectModel[]>(
    [paymentQueryKeys.CASH_MONEY_PROJECTS, data],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: Boolean(data?.cash_id && data?.currency_id && data?.payment_type_id)
    }
  );
}

export function useGetIncomeExpenseDelete(page: number, enabled?: boolean) {
  return useQuery<PaginationType<PaymentDeleteArchive[]>>(
    [paymentQueryKeys.INCOME_EXPENSE_DELETE_ARCHIVE, page],
    async () => {
      const res = await $api.get(`${paymentEndPoints.INCOME_EXPENSE_DELETE_ARCHIVE}?page=${page}`);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetIncomeOrExpenseDelete(page: number, enabled?: boolean, type?: PaymentTypeEnum) {
  return useQuery<PaginationType<PaymentDeleteArchive[]>>(
    [paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE_DELETED, page, type],
    async () => {
      const res = await $api.get(`${paymentEndPoints.EXPECTED_PAYMENT_TEMPLATE_DELETED}?page=${page}&type=${type}`);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetFinancialSelect(enabled: boolean, type: PaymentTypeEnum) {
  return useQuery<FinancialSelectModel[]>(
    [paymentQueryKeys.FINANCIAL_SELECT, type],
    async () => {
      const res = await $api.get(`${paymentEndPoints.FINANCIAL_SELECT}?type=${type}`);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

// export function useGetCashMoneyPaymentTypes(data: { cash_id?: number; project_id?: number; currency_id?: number }) {
//   let url = paymentEndPoints.ACCURATE_CASH_MONEY_SELECT;
//
//   if (data) {
//     const searchParams = new URLSearchParams("");
//
//     Object.keys(data)?.forEach(key => {
//       // @ts-ignore
//       searchParams.append(key, data[key]);
//     });
//
//     url += `?${searchParams}`;
//   }
//
//   return useQuery<AccurateCashMoneySelectModel[]>(
//     [paymentQueryKeys.CASH_MONEY_PAYMENT_TYPES, data],
//     async () => {
//       const res = await $api.get(url);
//
//       return res.data.data;
//     },
//     {
//       enabled: Boolean(data?.cash_id && data?.project_id && data?.currency_id)
//     }
//   );
// }

export function useGetPaymentCalendar(isCounterPart?: boolean) {
  const { severalSearchParams } = useQueryParams();
  const location = useLocation();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);
  let url = paymentEndPoints.CALENDAR;

  if (isCounterPart) {
    const companyPersonId = location.pathname?.split("-")[2];

    url += `?company_person_id=${companyPersonId}`;
  }

  if (searchParams) {
    url += `${isCounterPart ? "" : "?"}${searchParams}`;
  }

  return useQuery(
    [paymentQueryKeys.CALENDAR, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !isCounterPart
    }
  );
}

export function useGetCalendarOrderData(isCompanyPerson?: boolean) {
  const { severalSearchParams } = useQueryParams();
  const location = useLocation();
  const searchParams = severalSearchParams(
    queryParamsKeys.STATISTICS_TYPE,
    queryParamsKeys.FILTER_TYPE,
    queryParamsKeys.VIEW_TYPE,
    queryParamsKeys.PAGE
  );
  const { visible, page, minDate, maxDate, tabKey } = useAppSelector(state => state.paymentReducer.calendarHistory);
  let url = `${paymentEndPoints.CALENDAR_HISTORY_ORDER}?${queryParamsKeys.PAGE}=${page}&${queryParamsKeys.MIN_DATE}=${minDate}&${queryParamsKeys.MAX_DATE}=${maxDate}`;

  if (isCompanyPerson) {
    const companyPersonId = location.pathname?.split("-")[2];

    url += `&company_person_id=${companyPersonId}`;
  }

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery<PaginationType<ICalendarHistoryOrderModel[]>>(
    [paymentQueryKeys.CALENDAR_HISTORY_ORDER, page, minDate, maxDate, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: visible && tabKey === PaymentFilterKeys.ORDER
    }
  );
}

export function useGetCalendarPaymentData(isCompanyPerson?: boolean) {
  const { severalSearchParams } = useQueryParams();
  const location = useLocation();
  const searchParams = severalSearchParams(
    queryParamsKeys.STATISTICS_TYPE,
    queryParamsKeys.FILTER_TYPE,
    queryParamsKeys.VIEW_TYPE,
    queryParamsKeys.PAGE
  );
  const { visible, page, minDate, maxDate, tabKey } = useAppSelector(state => state.paymentReducer.calendarHistory);
  let url = `${paymentEndPoints.CALENDAR_HISTORY_PAYMENT}?${queryParamsKeys.PAGE}=${page}&${queryParamsKeys.MIN_DATE}=${minDate}&${queryParamsKeys.MAX_DATE}=${maxDate}`;

  if (isCompanyPerson) {
    const companyPersonId = location.pathname?.split("-")[2];

    url += `&company_person_id=${companyPersonId}`;
  }

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery<PaginationType<CalendarHistoryPaymentModel[]>>(
    [paymentQueryKeys.CALENDAR_HISTORY_PAYMENT, page, minDate, maxDate, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: visible && tabKey === PaymentFilterKeys.EXPECTED_PAYMENT
    }
  );
}

export function useGetCalendarHistoryStatistics(isCompanyPerson?: boolean) {
  const { severalSearchParams } = useQueryParams();
  const location = useLocation();
  const searchParams = severalSearchParams(
    queryParamsKeys.STATISTICS_TYPE,
    queryParamsKeys.FILTER_TYPE,
    queryParamsKeys.VIEW_TYPE,
    queryParamsKeys.PAGE
  );
  const { minDate, maxDate, tabKey } = useAppSelector(state => state.paymentReducer.calendarHistory);
  let url = `${paymentEndPoints.PAYMENT_STATISTICS}?${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.CALENDAR}&${queryParamsKeys.MIN_DATE}=${minDate}&${queryParamsKeys.MAX_DATE}=${maxDate}&${queryParamsKeys.FILTER_TYPE}=${tabKey}`;

  if (isCompanyPerson) {
    const companyPersonId = location.pathname?.split("-")[2];

    url += `&company_person_id=${companyPersonId}`;
  }

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery<Record<string, number>>(
    [paymentQueryKeys.CALENDAR_HISTORY_STATISTICS, minDate, maxDate, tabKey],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    }
  );
}

export function useGetOneExpectedPayment(id?: number, visible?: boolean) {
  return useQuery<ExpectedPaymentModel>(
    [paymentQueryKeys.ONE_EXPECTED_PAYMENT, id],
    async () => {
      const res = await $api.get(`${paymentEndPoints?.ONE_EXPECTED_PAYMENT}?${queryParamsKeys.ID}=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id && visible,
      staleTime: Infinity,
      cacheTime: Infinity
    }
  );
}

export function useGetOneCompanyPerson(id?: number, enabled?: boolean) {
  return useQuery<CounterpartsCompanyPersonModel>(
    [paymentQueryKeys.ONE_COMPANY_PERSON, id],
    async () => {
      const res = await $api.get(`${paymentEndPoints.ONE_COMPANY_PERSON}${id}`);

      return res.data?.data;
    },
    {
      enabled: !!id && enabled
    }
  );
}

export function useGetPaymentSectionView({ id, type }: { id?: number; type?: groupTypes }) {
  const { generateSearchParam } = useQueryParams();
  const { projectId, visible } = useAppSelector(state => state.paymentReducer.estimateDrawer);
  let url = `${paymentEndPoints.SECTION_VIEW}${projectId}`;
  const searchParams = generateSearchParam({
    custom_field_id: id,
    type
  });

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery<typeof id extends number ? GroupSectionModel[] : SectionModel[]>(
    [paymentQueryKeys.PROJECT_VIEW, projectId, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data?.data;
    },
    {
      select(data) {
        return data?.map(item => ({
          ...item,
          tasks: item?.tasks?.filter(task => !task?.is_title)
        }));
      },
      enabled: !!projectId && visible
    }
  );
}

export function useOrderDeleteArchive(orderId: number, page: number) {
  return useQuery<PaginationType<PaymentModel[]>>(
    [paymentQueryKeys.ORDER_DELETE_ARCHIVE, orderId, page],
    async () => {
      const res = await $api.get(`payment/view?order_id=${orderId}&page=${page}`);

      return res.data.data;
    },
    {
      enabled: !!orderId
    }
  );
}

export function useGetPaymentView({
  id,
  enabled,
  isDeleteArchive
}: {
  id?: number | string;
  enabled: boolean;
  isDeleteArchive?: boolean;
}) {
  return useQuery<PaymentModel>(
    [paymentQueryKeys.ONE_PAYMENT_VIEW, id],
    async () => {
      const res = await $api.get(
        `${isDeleteArchive ? paymentEndPoints.INCOME_EXPENSE_DELETE_ARCHIVE : paymentEndPoints.PAYMENT_VIEW}?id=${id}`
      );

      return res.data.data;
    },
    {
      enabled: !!id && enabled
    }
  );
}
