export const warehouseQueryNames = {
  WAREHOUSE_SELECT: "warehouse-select",
  WAREHOUSE_PRODUCTS: "warehouse-products",
  WAREHOUSE_DEFECT: "warehouse-defect",
  TRANSFER_VIEW: "transfer-view",
  ONE_TRANSFER: "one-transfer",
  WAREHOUSE_PRODUCT_SELECT: "warehouse_product_select",
  EXCESS_VIEW: "excess-view",
  WAREHOUSE_ORDERS: "warehouse-orders",
  ONE_WAREHOUSE_ORDER: "one-warehouse-order",
  WAREHOUSE_RESERVATION: "warehouse-reservation",
  WAREHOUSE_RESERVATION_PROJECT: "warehouse-reservation-project",
  WAREHOUSE_RESERVATION_SECTION: "warehouse-reservation-section",
  WAREHOUSE_RESERVATION_PRODUCT: "warehouse-reservation-product",
  WAREHOUSE_SELECT_MINE: "warehouse-select-mine",
  PRODUCT_CREATE: "product/create",
  ADDITIONAL_VIEW: "additional_view",
  HISTORY_ORDER: "history-order",
  WAREHOUSE_BY_PRODUCT_ID: "warehouse-by-product-id",
  WAREHOUSE_PRODUCT_BY_WAREHOUSE_ID: "warehouse-product-by-warehoue-id",
  WAREHOUSE_LABORATORY_VIEW:'warehouse-laboratory-view',
  WAREHOUSE_LABORATORY_VIEW_BY_ID:'warehouse-laboratory-view-by-id',

};
