import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { TooltipShortName } from "modules/common";
import { ProductAll, TargetType, useProductAll } from "modules/dashboard";

import SelectSuffixIcon from "../../features/app/assets/icons/SelectSuffixIcon";
import SelectNotContent from "../../features/app/components/select-not-content/SelectNotContent";
import { selectFilterOption } from "../../features/app/utils/helpers/selectFilterOption";
import { TimeoutModel } from "../../features/app/utils/models/TimeoutModel";
import { useAppSelector } from "../../hooks/redux";
import { appReducerActions } from "../../store/reducers/appReducer";
import { LoadingIndicator } from "../loading-indicator";

const { Option } = Select;

const ProductSelect: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState<string>();
  const [time, setTime] = useState<TimeoutModel>();
  const { setProductMarketPrice } = appReducerActions;
  const { pages, fetchNextPage, isLoading } = useProductAll({ search });
  const { productId, defaultProduct, defaultUnit } = useAppSelector(state => state.appReducer.productMarketPrice);

  const products = () => {
    let result: ProductAll[] = [];

    pages?.forEach(page => {
      result = [...result, ...(page?.data || [])];
    });

    if (defaultProduct && !result?.some(item => item?.id === defaultProduct?.id)) {
      // @ts-ignore
      result.unshift({ ...defaultProduct, unit: { ...defaultUnit } });
    }
    console.log(defaultProduct, !result?.some(item => item?.id === defaultProduct?.id));
    return result;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 1) {
      fetchNextPage();
    }
  };

  const onChange: SelectProps["onChange"] = (e, option) => {
    const arg = { ...option } as DefaultOptionType;

    dispatch(
      setProductMarketPrice({
        productId: e,
        unitId: arg.props?.unitId
      })
    );
  };

  const onSearch: SelectProps["onSearch"] = value => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 800)
    );
  };

  return (
    <Select
      showSearch
      value={productId}
      onChange={onChange}
      onSearch={onSearch}
      onPopupScroll={onPopupScroll}
      suffixIcon={<SelectSuffixIcon />}
      filterOption={selectFilterOption}
      defaultValue={products()?.[0]?.id}
      placeholder={t("dashboard.Mahsulotni tanlang")}
      notFoundContent={
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <SelectNotContent title={t("dashboard.Mahsulot")} />
        </Spin>
      }
    >
      {products()?.map(product => (
        <Option
          value={product?.id}
          key={`${product?.id}-${product?.unit?.id}`}
          props={{
            unitId: product?.unit?.id,
            name: product?.name?.[i18n.language]
          }}
        >
          <div className="flex w-full gap-3">
            <div
              className="flex items-center rounded border border-solid px-2 text-xs font-medium"
              style={{
                borderColor: product?.resource?.color,
                color: product?.resource?.color
              }}
            >
              {product?.resource?.symbol?.[i18n.language]}
            </div>
            <div className="flex flex-1 items-center justify-between gap-4 text-base font-normal text-gray-800">
              <TooltipShortName length={54} title={product?.name?.[i18n.language]} />
              <div className="text-sm font-medium text-gray-400">{product?.unit?.symbol?.[i18n.language]}</div>
            </div>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default ProductSelect;
