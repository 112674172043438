import { ProductSelectModel } from "../../../app/utils/models/productSelectModel";
import { useGetProductsSelect } from "../../service/queries";
import { Form, FormInstance } from "antd";

const { useWatch } = Form;

const ProductsData = ({ form }: { form: FormInstance }): ProductSelectModel[] => {
  const searchSelect = useWatch("select_search", form);
  const { data } = useGetProductsSelect(searchSelect);

  const formProductSelect = useWatch(
    "select_products",
    form
  ) as ProductSelectModel[];

  const pagesData = () => {
    let newPagesData: ProductSelectModel[] = [];

    data?.pages?.forEach((item) => {
      newPagesData = [...newPagesData, ...(item?.data ?? [])];
    });

    return newPagesData;
  };

  const productsData = () => {
    const newData: ProductSelectModel[] = [];

    [...(pagesData() ?? []), ...(formProductSelect ?? [])]?.forEach((item) => {
      if (!newData?.some((value) => value?.id === item.id)) {
        newData.push(item);
      }
    });

    return newData;
  };

  return productsData();
};

export default ProductsData;
