import React, { useMemo } from "react";
import { Segmented, Spin, Tabs, TabsProps } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { typeValues, useProjectByCustomFieldProjects, useProjectColumnProjects, useWidget } from "modules/dashboard";

import Wrapper from "./wrapper/wrapper";

import styles from "./project-by-section.module.scss";

type Props = {
  isCustomField: boolean;
};

const ProjectBySection: React.FC<Props> = ({ isCustomField }) => {
  const { t } = useTranslation();
  const { queries, append } = useQueryParams();
  const { estimate_type: estimateType } = queries();
  const { id, visible, props, methods } = useWidget();
  const { projects, isLoading } = useProjectColumnProjects({ id, enabled: visible && !isCustomField });
  const { customFieldProjects, isLoading: isCustomFieldLoading } = useProjectByCustomFieldProjects({
    id,
    enabled: visible && isCustomField
  });

  const items = useMemo(
    (): TabsProps["items"] =>
      projects?.map(item => ({
        key: String(item.id),
        label: <div>{item.name}</div>,
        children: <Wrapper isCustomField={false} />
      })),
    [projects]
  );

  const itemsByCustomField = useMemo(
    (): TabsProps["items"] =>
      customFieldProjects?.map(item => ({
        key: String(item.id),
        label: <div>{item.name}</div>,
        children: <Wrapper isCustomField={true} />
      })),
    [customFieldProjects]
  );

  const onChangeSegmented = (key: string) => {
    append(queryParamsKeys.ESTIMATE_TYPE, key);
  };

  const extraContent = (
    <Segmented
      value={estimateType || typeValues.static}
      onChange={onChangeSegmented}
      options={[
        {
          label: `${t("dashboard.Statik smeta")}`,
          value: typeValues.static
        },
        {
          label: `${t("dashboard.Dinamik smeta")}`,
          value: typeValues.dynamic
        }
      ]}
    />
  );

  const onChangeTab = (key: string) => {
    methods.setWidgetDrawer({
      props: {
        ...props,
        projectId: key,
        sectionKeys: [],
        taskKeys: []
      }
    });
  };

  return (
    <>
      {!isCustomField ? (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <Tabs
            items={items}
            className="w-[75rem]"
            onChange={onChangeTab}
            rootClassName={styles.column}
            tabBarExtraContent={extraContent}
            activeKey={String(props?.projectId)}
          />
        </Spin>
      ) : (
        <Spin spinning={isCustomFieldLoading} indicator={LoadingIndicator}>
          <Tabs
            items={itemsByCustomField}
            className="w-[75rem]"
            onChange={onChangeTab}
            rootClassName={styles.column}
            tabBarExtraContent={extraContent}
            activeKey={String(props?.projectId)}
          />
        </Spin>
      )}
    </>
  );
};

export default ProjectBySection;
