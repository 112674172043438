import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

type InitialState<T = any> = {
  visible: boolean;
  objectId?: number;
  type: string;
  dataKeys?: string[];
  onAfterOpen?: () => void;
  zIndex?: number;
  record?: T;
  isTWoDifferentQueryKeys?: boolean;
};

const initialState: InitialState = {
  visible: false,
  objectId: undefined,
  type: "",
  dataKeys: [""],
  zIndex: 1001,
  isTWoDifferentQueryKeys: false
};

const chatReducer = createSlice({
  name: sliceNames.DEFECT_CHAT,
  initialState,
  reducers: {
    setVisible: <T>(
      state: InitialState<T>,
      action: PayloadAction<{
        visible: boolean;
        objectId?: number;
        type: string;
        dataKeys?: string[];
        onAfterOpen?: () => void;
        zIndex?: number;
        record?: T;
        isTWoDifferentQueryKeys?: boolean;
      }>
    ) => {
      console.log("isTWoDifferentQueryKeys", action.payload.isTWoDifferentQueryKeys);
      state.visible = action.payload.visible;
      state.objectId = action.payload.objectId;
      state.type = action.payload.type;
      state.dataKeys = action.payload.dataKeys;
      state.onAfterOpen = action.payload.onAfterOpen;
      state.record = action.payload.record;
      state.isTWoDifferentQueryKeys = action.payload.isTWoDifferentQueryKeys;

      if (action.payload.zIndex) {
        state.zIndex = action.payload.zIndex;
      }
    }
  }
});

export default chatReducer.reducer;
export const chatActions = chatReducer.actions;
