import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { inventoryEndPoints } from "../utils/constants/inventoryEndPoints";
import { inventoryQueryKeys } from "../utils/constants/inventoryQueryKeys";
import { ConnectProjectBodyModel } from "../utils/models/connectProjectBodyModel";
import { DefectBodyModel } from "../utils/models/defectBodyModel";
import { DefectUpdateBodyModel } from "../utils/models/defectUpdateBodyModel";
import { InventoryBodyModel } from "../utils/models/inventoryBodyModel";
import { InventoryFolderBodyModel } from "../utils/models/inventoryFolderBodyModel";
import { RequestCheckBodyModel } from "../utils/models/requestCheckBodyModel";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import { FileType } from "features/app/utils/enums/fileType";

export function useUpdateInventoryFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryFolderBodyModel>(
    async req => {
      const res = await $api.put(inventoryEndPoints.UPDATE_INVENTORY_FOLDER, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateInventoryFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryFolderBodyModel>(
    async req => {
      const res = await $api.post(inventoryEndPoints.CREATE_INVENTORY_FOLDER, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteInventoryFolder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${inventoryEndPoints.DELETE_INVENTORY_FOLDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryBodyModel>(
    async req => {
      const res = await $api.post(inventoryEndPoints.INVENTORY_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, InventoryBodyModel>(
    async req => {
      const res = await $api.put(inventoryEndPoints.INVENTORY_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_DETAIL_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteInventory() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number | undefined>(
    async id => {
      const res = await $api.delete(`${inventoryEndPoints.INVENTORY_DELETE}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.FOLDERS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCheckRequest() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, RequestCheckBodyModel>(
    async req => {
      const res = await $api.post(inventoryEndPoints.CHECK_REQUEST, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.REQUESTS_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateDefect() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, DefectBodyModel>(
    async req => {
      const res = await $api.put(inventoryEndPoints.DEFECT_CREATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateDefect() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, DefectUpdateBodyModel>(
    async req => {
      const res = await $api.put(inventoryEndPoints.DEFECT_UPDATE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.DEFECT_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteDefect() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { object_id: number }>(
    async req => {
      const res = await $api.delete(`${inventoryEndPoints.DELETE_DEFECT}?object_id=${req.object_id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.DEFECT_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUploadFile() {
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<number[]>, ErrorRes, FormData>(
    async req => {
      const res = await $api.post(inventoryEndPoints.UPLOAD_FILE, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useConnectToProject() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, ConnectProjectBodyModel>(
    async req => {
      const res = await $api.put(inventoryEndPoints.CONNECT_PROJECT, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_DETAIL_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useLeaveFromProject() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { inventory_id?: number }>(
    async req => {
      const res = await $api.put(inventoryEndPoints.LEAVE_PROJECT, req);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_VIEW],
          refetchType: "active"
        });
        qc.invalidateQueries({
          queryKey: [inventoryQueryKeys.INVENTORY_DETAIL_VIEW],
          refetchType: "active"
        });
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useExportInventoryDefectExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${inventoryEndPoints.INVENTORY_DEFECT_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Inventory-defects", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
export function useExportInventoryRequestsExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${inventoryEndPoints.INVENTORY_REQUESTS_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Inventory-requests", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
export function useExportInventoryExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${inventoryEndPoints.INVENTORY_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Inventories", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
