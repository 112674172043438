import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { TableRowSelection } from "antd/es/table/interface";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { productsTemplateActions } from "../../../../../../../../store/reducers/productsTempleteReducer";
import { RootState } from "../../../../../../../../store";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { PaginationType } from "../../../../../../../app/utils/models/PaginationType";
import { ProductModel } from "../../../../../../../settings/utils/models/product/productModel";

import styles from "./taskProductsTemplateTable.module.scss";

type Props = {
  data?: PaginationType<ProductModel[]>;
  isLoading: boolean;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

const TaskProductsTemplateTable: React.FC<Props> = ({ data, isLoading, page, setPage }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const dispatch = useDispatch();
  const { setIds, setTemplateData } = productsTemplateActions;
  const { ids } = useAppSelector((state: RootState) => state.productsTemplate);

  const columns: ColumnsType<ProductModel> = [
    {
      title: "Nomi",
      render: (record: ProductModel) => (
        <span>
          <span
            className={styles.symbol}
            style={{
              borderColor: record?.resource?.color,
              color: record?.resource?.color
            }}
          >
            {record?.resource?.symbol[language]}
          </span>
          {record?.name[i18n.language]}
        </span>
      )
    },
    {
      title: "Birligi",
      render: (record: ProductModel) => record?.unit?.symbol[language]
    },
    {
      title: "Resursi",
      render: (record: ProductModel) => record?.resource?.name[language]
    }
  ];

  const dataIds = useMemo(() => data?.data?.map(item => item.id), [data]);

  const rowSelection: TableRowSelection<ProductModel> | undefined = {
    onChange: (selectedRowKeys: React.Key[], selectedRows) => {
      const result = ids.filter(id => {
        if (!dataIds?.includes(id)) {
          return true;
        }
        if (
          (dataIds?.includes(id) && selectedRowKeys?.includes(id)) ||
          (dataIds?.includes(id) && !selectedRowKeys?.includes(id))
        ) {
          return false;
        }

        return false;
      });

      dispatch(setIds([...result, ...(selectedRowKeys as number[])]));

      dispatch(
        setTemplateData(
          selectedRows?.map(item => ({
            id: uid(),
            amount: 1,
            unit: item.unit,
            isTemplate: true,
            quantity: "1",
            product: {
              id: item.id,
              name: item.name,
              resource: item.resource
            }
          }))
        )
      );
    },
    selectedRowKeys: ids
  };

  return (
    <div className={styles.table}>
      <Table<ProductModel>
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data?.data}
        pagination={false}
        rowKey={row => row?.id}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <Pagination
        paginationProps={{
          style: {
            marginTop: "1rem"
          },
          total: data?.total,
          current: page,
          onChange: page => {
            setPage(page);
          }
        }}
      />
    </div>
  );
};

export default TaskProductsTemplateTable;
