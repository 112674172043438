import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

const { Item } = Form;

type Props = {
  customField: CustomFieldSelectModel;
  index: number;
  disabled?: boolean;
};

const CustomFieldInput = ({ customField, index, disabled }: Props) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <Item className="d_n" name={["custom_field_values", index, "custom_field_id"]} initialValue={customField?.id} />
      <Item className="d_n" name={["custom_field_values", index, "type"]} initialValue={customField?.type} />
      <Item label={customField?.name[i18n.language]} name={["custom_field_values", index, "value"]}>
        <Input placeholder={t("customFields.Kiriting")} disabled={disabled} />
      </Item>
    </>
  );
};

export default CustomFieldInput;
