import React from "react";

const PaperClipIcon: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.1527 10.8995L12.1371 19.9151C10.0869 21.9654 6.76275 21.9654 4.71249 19.9151C2.66224 17.8649 2.66224 14.5408 4.71249 12.4905L13.7281 3.47489C15.0949 2.10806 17.311 2.10806 18.6779 3.47489C20.0447 4.84173 20.0447 7.05781 18.6779 8.42464L10.0158 17.0867C9.33238 17.7701 8.22434 17.7701 7.54092 17.0867C6.8575 16.4033 6.8575 15.2952 7.54092 14.6118L15.1423 7.01043"
        stroke="#475467" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PaperClipIcon;