import React, { useEffect } from "react";
import { Dropdown, MenuProps, Segmented, Tooltip } from "antd";
import { SegmentedLabeledOption, SegmentedValue } from "antd/lib/segmented";

import { useAuth } from "modules/auth/hooks";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { CheckIcon } from "../../../../../app/assets/icons/CheckIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LocalStorage } from "../../../../../app/service/LocalStorage";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { CurrencyTypeEnum } from "../../../../../app/utils/enums/currencyTypeEnum";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";
import { PaymentFilterKeys, paymentFilterNames } from "../../../../utils/enums/paymentFilterKeys";
import { useTranslation } from "react-i18next";

const CurrencySegmented: React.FC = () => {
  const { remove, appends, queries } = useQueryParams();
  const { currencies } = useAuth();
  const baseCurrency = currencies?.find(item => item.type === CurrencyTypeEnum.BASE);
  const paymentCurrency = LocalStorage.get("payment_currency");
  const { t } = useTranslation();

  const { currency_id: currencyId, check_currency: checkCurrencyId, statistics_type: tabKey } = queries();
  const checkCurrency = LocalStorage.get("payment_currency");

  const currencyValue = () => {
    if (!currencyId) {
      return PaymentFilterKeys.ALL;
    }

    return currencies?.find(item => item.id === Number(currencyId))?.id ?? baseCurrency?.id;
  };

  const onCheckCurrency = (id: string | number) => () => {
    appends([{ key: queryParamsKeys.CHECK_CURRENCY, value: id }]);

    LocalStorage.set("payment_currency", id);
  };

  const checkingCurrencyValue = () => {
    if (checkCurrencyId || paymentCurrency) return String(checkCurrencyId || paymentCurrency);

    return String(baseCurrency?.id);
  };

  const allCurrencyItems: MenuProps["items"] = [
    ...(currencies?.map(item => ({
      key: item?.id,
      label: (
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <img src={item?.icon} alt={item?.symbol} className="h-4 w-6 object-cover" />
            <span>{item?.symbol}</span>
          </div>
          <ConditionalRender if={String(item?.id) === checkingCurrencyValue()}>
            <CheckIcon />
          </ConditionalRender>
        </div>
      ),
      onClick: onCheckCurrency(item?.id)
    })) || []),
    {
      key: PaymentFilterKeys.ALL,
      label: (
        <div className="flex items-center justify-between gap-6">
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2">
              <img src={currencies[0]?.icon} alt={currencies[0]?.symbol} className="h-4 w-6 object-cover" />
              <span>{currencies[0]?.symbol}</span>
            </div>
            <span>+</span>
            <div className="flex items-center gap-2">
              <img src={currencies[1]?.icon} alt={currencies[1]?.symbol} className="h-4 w-6 object-cover" />
              <span>{currencies[1]?.symbol}</span>
            </div>
          </div>
          <ConditionalRender if={(checkCurrencyId || paymentCurrency) === PaymentFilterKeys.ALL}>
            <CheckIcon />
          </ConditionalRender>
        </div>
      ),
      onClick: onCheckCurrency(PaymentFilterKeys.ALL)
    }
  ];

  const segmentedOption: (SegmentedValue | SegmentedLabeledOption)[] = [
    ...(currencies?.map(item => ({
      value: item.id,
      label: item.symbol
    })) ?? []),
    {
      value: PaymentFilterKeys.ALL,
      label:
        tabKey !== PaymentTabKeys.INCOME_EXPENSE || currencyId ? (
          paymentFilterNames[PaymentFilterKeys.ALL]
        ) : (
          <Tooltip title="Sichqonchani chap tomonini bosib valyutalar kesimida ko‘rishingiz mumkin.">
            <Dropdown placement="bottom" trigger={["contextMenu"]} menu={{ items: allCurrencyItems }}>
              <div>{t(`payment.${paymentFilterNames[PaymentFilterKeys.ALL]}`)}</div>
            </Dropdown>
          </Tooltip>
        )
    }
  ];

  const onChangeSegmented = (value: SegmentedValue) => {
    if (value === PaymentFilterKeys.ALL) {
      remove(queryParamsKeys.CURRENCY_ID);
    } else {
      appends([
        { key: queryParamsKeys.PAGE, value: "1" },
        { key: queryParamsKeys.CURRENCY_ID, value: String(value) },
        { key: queryParamsKeys.CHECK_CURRENCY, value: undefined }
      ]);
    }

    // LocalStorage.set("payment_currency", undefined);
  };

  useEffect(() => {
    if (checkCurrency) {
      console.log("checkCurrency", checkCurrency);
      onCheckCurrency(checkCurrency as string);
    }
  }, [checkCurrency, window.location.search]);

  return <Segmented value={currencyValue()} options={segmentedOption} onChange={onChangeSegmented} />;
};

export default CurrencySegmented;
