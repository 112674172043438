import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { CurrencySelectModel } from "../../features/app/utils/models/currencySelectModel";
import { OneOrderModel } from "../../features/warehouse/utils/models/oneOrderModel";
import { ProductsEstimateDataModel } from "../../features/warehouse/utils/models/productsEstimateDataModel";
import { WarehouseOrderModel } from "../../features/warehouse/utils/models/warehouseOrderModel";

interface WarehouseOrderReducerModel {
  create: {
    visible: boolean;
    currency?: CurrencySelectModel;
  };
  view: {
    visible: boolean;
    data?: WarehouseOrderModel;
  };
  additional: {
    visible: boolean;
    data?: OneOrderModel;
    order_id?: number;
  };
  template: {
    visible: boolean;
    products?: ProductsEstimateDataModel[];
    ids?: number[];
    search?: string;
    page?: number;
  };
  accept: {
    visible: boolean;
  };
}

const initialState: WarehouseOrderReducerModel = {
  create: {
    visible: false
  },
  view: {
    visible: false,
    data: undefined
  },
  additional: {
    visible: false,
    data: undefined
  },
  template: {
    visible: false
  },
  accept: {
    visible: false
  }
};

const warehouseOrderSlice = createSlice({
  name: sliceNames.WAREHOUSE_ORDER,
  initialState,
  reducers: {
    setVisibleCreateOrder: (state, action: PayloadAction<boolean>) => {
      state.create.visible = action.payload;
    },
    setCreateOrderValuta: (state, action: PayloadAction<CurrencySelectModel | undefined>) => {
      state.create.currency = action.payload;
    },
    setVisibleViewOrder: (state, action: PayloadAction<{ visible: boolean; data?: WarehouseOrderModel }>) => {
      state.view.visible = action.payload.visible;
      state.view.data = action.payload.data;
    },
    setVisibleAdditional: (state, action: PayloadAction<{ visible: boolean; data?: OneOrderModel,order_id?:number }>) => {
      state.additional.visible = action.payload.visible;
      state.additional.data = action.payload.data;
      state.additional.order_id = action.payload.order_id
    },
    setVisibleTemplate: (state, action: PayloadAction<boolean>) => {
      state.template.visible = action.payload;
    },
    setIds: (state, action: PayloadAction<number[]>) => {
      state.template.ids = action.payload;
    },
    setTemplateData: (state, action: PayloadAction<ProductsEstimateDataModel[] | undefined>) => {
      state.template.products = action.payload;
    },
    setTemplateSearch: (state, action: PayloadAction<string | undefined>) => {
      state.template.search = action.payload;
    },
    setTemplatePage: (state, action: PayloadAction<number | undefined>) => {
      state.template.page = action.payload;
    },
    setIsAccept: (state, action: PayloadAction<boolean>) => {
      state.accept.visible = action.payload;
    }
  }
});

export default warehouseOrderSlice.reducer;
export const warehouseOrderActions = warehouseOrderSlice.actions;
