import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import queryString from "query-string";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { TABLE } from "../../app/utils/constants/localStorageKeys";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../app/utils/constants/tableConfigKeys";
import $api from "../../app/utils/helpers/axiosInstance";
import { parseParamsId } from "../../app/utils/helpers/parseParamsId";
import { PaginationType } from "../../app/utils/models/PaginationType";
import { PaymentTabKeys } from "../../payment/utils/constants/paymentTabKeys";
import { PaymentInventoryWorkModel } from "../../payment/utils/models/paymentInventoryModel";
import { PaymentOrderModel } from "../../payment/utils/models/paymentOrderModel";
import { OfferModel } from "../../supply/utils/models/OfferModel";
import { counterpartsEndPoints } from "../utils/constants/counterpartsEndPoints";
import { counterpartsQueryKeys } from "../utils/constants/counterpartsQueryKeys";
import { ActViewModel } from "../utils/models/actViewModel";
import { CounterpartsCompanyPersonModel } from "../utils/models/counterpartsCompanyPersonModel";
import { CounterpartsFolderModel } from "../utils/models/counterpartsFolderModel";
import { CounterPartsInventoryModel } from "../utils/models/counterpartsInventoryModel";
import { CounterpartsPaymentModel } from "../utils/models/counterpartsPaymentModel";
import { CounterpartsPersonModel } from "../utils/models/counterpartsPersonModel";
import { CounterpartsPersonSelectModel } from "../utils/models/counterpartsPersonSelectModel";
import {
  CounterpartsAmountStatisticsModel,
  CounterpartsStatisticsModel
} from "../utils/models/counterpartsStatisticsModel";

export function useGetCompanyPersonFolders() {
  let url = counterpartsEndPoints.FOLDERS;
  const { reqQueryParam, severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(
    queryParamsKeys.SORT_DEBT_AMOUNT,
    queryParamsKeys.SORT_CREDIT_AMOUNT,
    queryParamsKeys.SORT_TOTAL_AMOUNT
  );

  if (searchParams) {
    url += `?${reqQueryParam(
      queryParamsKeys.SEARCH,
      queryParamsKeys.TYPES_ARR,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE
    )}`;
  }

  return useQuery<CounterpartsFolderModel[]>([counterpartsQueryKeys.FOLDERS_VIEW, searchParams], async () => {
    const res = await $api.get(url);

    return res.data.data;
  });
}

export function useGetCompanyPersonFoldersSelect(enabled?: boolean, isCreateCounterpart?: number) {
  const { id } = useParams();

  return useQuery<CounterpartsFolderModel[]>(
    [counterpartsQueryKeys.FOLDERS_SELECT],
    async () => {
      const res = await $api.get(counterpartsEndPoints.FOLDERS_SELECT);

      if (id && isCreateCounterpart) {
        return [{ id: null, name: "Papkadan chiqarish" }, ...res.data.data];
      }
      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetCompanyPersons(is_archived: boolean) {
  const { reqQueryParam, searchParams } = useQueryParams();
  const pageSize = JSON.parse(localStorage.getItem(TABLE) || "{}")?.[tableConfigKeys.COMPANY_PERSON] || 10;

  let url = `${counterpartsEndPoints.PERSONS}?${queryParamsKeys.IS_ARCHIVED}=${is_archived}&${queryParamsKeys.SIZE}=${pageSize}`;

  if (searchParams) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SIZE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.TYPES_ARR,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE,
      queryParamsKeys.SORT_TOTAL_AMOUNT,
      queryParamsKeys.SORT_DEBT_AMOUNT,
      queryParamsKeys.SORT_CREDIT_AMOUNT,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<PaginationType<CounterpartsCompanyPersonModel[]>>(
    [counterpartsQueryKeys.PERSONS_VIEW, searchParams, is_archived],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetOneCompanyFolder(id?: string) {
  let url = `${counterpartsEndPoints.FOLDERS}?company_person_folder_id=${id}`;
  const { reqQueryParam, searchParams, queries } = useQueryParams();

  if (searchParams) {
    const isDebtorType = queries()[queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE] === "debtor";

    if (isDebtorType) {
      const minAmount = queries()[queryParamsKeys.MIN_TOTAL_AMOUNT];
      const maxAmount = queries()[queryParamsKeys.MAX_TOTAL_AMOUNT];

      url += `&${reqQueryParam(queryParamsKeys.SEARCH, queryParamsKeys.TYPES_ARR, queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE)}`;
      if (minAmount) {
        url += `&${queryParamsKeys.MAX_TOTAL_AMOUNT}=${+minAmount * -1}`;
      }
      if (maxAmount) {
        url += `&${queryParamsKeys.MIN_TOTAL_AMOUNT}=${+maxAmount * -1}`;
      }
    } else {
      url += `&${reqQueryParam(
        queryParamsKeys.SEARCH,
        queryParamsKeys.TYPES_ARR,
        queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE,
        queryParamsKeys.MIN_TOTAL_AMOUNT,
        queryParamsKeys.MAX_TOTAL_AMOUNT,
        queryParamsKeys.SORT_TOTAL_AMOUNT,
        queryParamsKeys.SORT_DEBT_AMOUNT,
        queryParamsKeys.SORT_CREDIT_AMOUNT
      )}`;
    }
  }

  return useQuery<PaginationType<CounterpartsCompanyPersonModel[]>>(
    [counterpartsQueryKeys.ONE_FOLDER_VIEW, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
}

export function useGetPersonSelectSearch(search?: string) {
  let url = counterpartsEndPoints.PERSON_SELECT;

  if (search) {
    url += `?search=${search}`;
  } else {
    url = counterpartsEndPoints.PERSON_SELECT;
  }

  return useQuery<CounterpartsPersonModel[]>(
    [counterpartsQueryKeys.PERSON_SELECT_SEARCH, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!search
    }
  );
}

export function useGetPersonSelect(search?: string) {
  let url = counterpartsEndPoints.PERSON_SELECT;

  if (search) {
    url += `?search=${search}`;
  } else {
    url = counterpartsEndPoints.PERSON_SELECT;
  }

  return useQuery<CounterpartsPersonModel[]>(
    [counterpartsQueryKeys.PERSON_SELECT, search],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCompanyPersonSelect(enabled?: boolean) {
  return useQuery<CounterpartsPersonSelectModel[]>(
    [counterpartsQueryKeys.COMPANY_PERSON_SELECT],
    async () => {
      const res = await $api.get(counterpartsEndPoints.COMPANY_PERSON_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: enabled ?? true
    }
  );
}

export function useGetCompanyPersonPayment(isFirstTab?: boolean) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { queries, reqQueryParam, severalSearchParams } = useQueryParams();
  const { tab } = queries();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY);
  let url = `${counterpartsEndPoints.PAYMENT}?company_person_id=${id}`;

  if (searchParams) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.TYPE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.SORT_DATE,
      queryParamsKeys.SORT_DATE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.FILTER_TYPE,
      queryParamsKeys.SORT_AMOUNT,
      queryParamsKeys.CASH_IDS_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SORT_CASH_NAME,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.SORT_PROJECT_NAME,
      queryParamsKeys.FINANCIAL_IDS_ARR,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.SORT_FINANCIAL_NAME,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  const enabled = tab === "income-expense" || isFirstTab;

  return useQuery<PaginationType<CounterpartsPaymentModel[]>>(
    [counterpartsQueryKeys.PAYMENT, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled
    }
  );
}

export function useGetCompanyPersonStatistics(defaultTabKey?: string) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  let url = `${counterpartsEndPoints.STATISTICS}?company_person_id=${id}`;
  const { statistics_type } = queries();

  if (searchParams) {
    url += `&${reqQueryParam(
      queryParamsKeys.STATISTICS_TYPE,
      queryParamsKeys.TYPE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.CASH_IDS_ARR,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.FINANCIAL_IDS_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.SEARCH,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.VIEW_TYPE,
      queryParamsKeys.FILTER_TYPE,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}${!statistics_type ? `&${queryParamsKeys.STATISTICS_TYPE}=${defaultTabKey}` : ""}`;
  } else {
    url += `&${queryParamsKeys.STATISTICS_TYPE}=${defaultTabKey}`;
  }

  return useQuery<CounterpartsStatisticsModel>(
    [counterpartsQueryKeys.STATISTICS, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
}

export function useGetCompanyPersonExpectedPayment(tabKeys: { key: string; type?: string }) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { reqQueryParam, queries, severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  let url = `${counterpartsEndPoints.EXPECTED_PAYMENT}?company_person_id=${id}`;
  const { statistics_type, ...rest } = queries();

  if (Object.keys(rest).length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.TYPE,
      queryParamsKeys.PAGE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE,
      queryParamsKeys.SEARCH
    )}`;
  }

  const enabled = statistics_type === PaymentTabKeys.PAYMENT || tabKeys.key === PaymentTabKeys.PAYMENT;

  return useQuery(
    [counterpartsQueryKeys.EXPECTED_PAYMENT, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetCompanyPersonExpectedTemplatePayment(enabled: boolean) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { reqQueryParam, severalSearchParams, queries } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  let url = `${counterpartsEndPoints.EXPECTED_TEMPLATE_PAYMENT}?company_person_id=${id}`;
  const { statistics_type, ...rest } = queries();

  if (Object.keys(rest).length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.TYPE,
      queryParamsKeys.PAGE,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_DATE,
      queryParamsKeys.MIN_DATE
    )}`;
  } else {
    url += `&${queryParamsKeys.TYPE}=${PaymentTypeEnum.INCOME}`;
  }

  return useQuery(
    [counterpartsQueryKeys.EXPECTED_TEMPLATE_PAYMENT, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled
    }
  );
}

export function useGetInventoryView(isFirstTabKey?: boolean) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { queries, searchParams, reqQueryParam } = useQueryParams();
  const { statistics_type, ...rest } = queries();

  let url = `${counterpartsEndPoints.INVENTORY}?company_person_id=${id}`;

  if (Object.keys(rest).length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MAX_COMPLETED_DATE,
      queryParamsKeys.MIN_COMPLETED_DATE
    )}`;
  }

  return useQuery<CounterPartsInventoryModel>(
    [counterpartsQueryKeys.INVENTORY, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: statistics_type === "inventory" || isFirstTabKey
    }
  );
}

export function useGetInventoryFolderSelect(enabled?: boolean) {
  return useQuery<{ id: number; name: string }[]>(
    [counterpartsQueryKeys.INVENTORY_FOLDER_SELECT],
    async () => {
      const res = await $api.get(counterpartsEndPoints.INVENTORY_FOLDER_SELECT);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: enabled ?? true
    }
  );
}

export function useGetOrderView(isFirstTabKey?: boolean) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { queries, reqQueryParam, severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  let url = `${counterpartsEndPoints.ORDER}?company_person_id=${id}`;
  const { statistics_type, ...rest } = queries();

  if (Object.keys(rest).length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SORT,
      queryParamsKeys.SEARCH,
      queryParamsKeys.MIN_PAYMENT_DATE,
      queryParamsKeys.MAX_PAYMENT_DATE,
      queryParamsKeys.MAX_AMOUNT,
      queryParamsKeys.MIN_AMOUNT,
      queryParamsKeys.SORT_AMOUNT,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.SORT_PERCENT,
      queryParamsKeys.SORT_PAYMENT_DATE,
      queryParamsKeys.SORT_DELIVERY_DATE,
      queryParamsKeys.SORT_ORDERED_DATE,
      queryParamsKeys.SORT_PAYMENT_PERCENT,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.SORT_AGENT_FULL_NAME,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.SORT_COMPANY_PERSON_NAME,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.SORT_CUSTOM_FIELDS,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.WAREHOUSE_IDS_ARR,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.AGENT_IDS_ARR,
      queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
      queryParamsKeys.STATUSES_ARR,
      queryParamsKeys.CUSTOM_FIELDS_ARR,
      queryParamsKeys.MIN_ORDERED_DATE,
      queryParamsKeys.MAX_ORDERED_DATE,
      queryParamsKeys.MIN_DELIVERY_DATE,
      queryParamsKeys.MAX_DELIVERY_DATE,
      queryParamsKeys.PAYMENT_TYPE_IDS_ARR
    )}`;
  }

  return useQuery<PaginationType<PaymentOrderModel[]>>(
    [counterpartsQueryKeys.ORDER, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: queries()?.[queryParamsKeys.STATISTICS_TYPE] === "order" || isFirstTabKey
    }
  );
}

export function useGetWorkView(isFirstTabKey?: boolean) {
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const { queries, severalSearchParams, reqQueryParam } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  let url = `${counterpartsEndPoints.WORK}?company_person_id=${id}`;
  const { statistics_type, ...rest } = queries();

  if (Object.keys(rest).length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.PAGE,
      queryParamsKeys.SEARCH,
      queryParamsKeys.CURRENCY_ID,
      queryParamsKeys.MIN_COMPLETED_DATE,
      queryParamsKeys.MAX_COMPLETED_DATE,
      queryParamsKeys.PROJECT_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR,
      queryParamsKeys.PAYMENT_STATUSES_ARR,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT
    )}`;
  }

  return useQuery<PaginationType<PaymentInventoryWorkModel[]>>(
    [counterpartsQueryKeys.WORK, id, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: statistics_type === "work" || isFirstTabKey
    }
  );
}

export function useGetCompanyPersonAct(params: {
  company_person_id: number;
  currency_id?: number;
  min_date?: string;
  max_date?: string;
  enabled: boolean;
}) {
  let url = `${counterpartsEndPoints.ACT}?company_person_id=${params.company_person_id}`;
  const keys = ["currency_id", "min_date", "max_date"];
  let result: { [key: string]: string | number } = {};

  keys?.forEach(key => {
    if (params[key as keyof typeof params]) {
      result = Object.assign(result, { [key]: params[key as keyof typeof params] });
    }
  });

  if (result.currency_id === "all") {
    delete result.currency_id;
  }

  const stringifyParams = queryString.stringify(result);

  if (stringifyParams) {
    url += `&${stringifyParams}`;
  }

  return useQuery<ActViewModel>(
    [counterpartsQueryKeys.ACT, params],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: params.enabled
    }
  );
}

export function useGetOneCompanyPerson(id?: number) {
  return useQuery<CounterpartsCompanyPersonModel>(
    [counterpartsQueryKeys.ONE_COMPANY_PERSON, id],
    async () => {
      const res = await $api.get(`${counterpartsEndPoints.PERSONS}?id=${id}`);

      return res.data.data;
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!id
    }
  );
}

export function useGetCompanyPerson(id?: number) {
  const url = `${counterpartsEndPoints.PERSONS}?id=${id}`;

  return useQuery<CounterpartsCompanyPersonModel>(
    [counterpartsQueryKeys.PERSONS_VIEW_ID, id],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCompanyPersonOfferView(id?: number) {
  return useQuery<PaginationType<OfferModel[]>>(
    [counterpartsQueryKeys.PERSONS_OFFER_VIEW_ID, id],
    async () => {
      const res = await $api.get(`${counterpartsEndPoints.PERSONS_OFFER}?company_person_id=${id}`);

      return res.data.data;
    },
    {
      enabled: !!id,
      retry: false,
      refetchOnWindowFocus: false
    }
  );
}

export function useGetCompanyPersonAmountStatistics() {
  const { reqQueryParam, severalSearchParams } = useQueryParams();
  let url = counterpartsEndPoints.AMOUNT_STATISTICS;
  const searchParams = severalSearchParams(
    queryParamsKeys.SORT_DEBT_AMOUNT,
    queryParamsKeys.SORT_CREDIT_AMOUNT,
    queryParamsKeys.SORT_TOTAL_AMOUNT
  );

  if (searchParams) {
    url += `&${reqQueryParam(
      queryParamsKeys.SEARCH,
      queryParamsKeys.TYPES_ARR,
      queryParamsKeys.MIN_TOTAL_AMOUNT,
      queryParamsKeys.MAX_TOTAL_AMOUNT,
      queryParamsKeys.BALANCE_TOTAL_AMOUNT_TYPE,
      queryParamsKeys.CUSTOM_FIELDS_ARR
    )}`;
  }

  return useQuery<CounterpartsAmountStatisticsModel>(
    [counterpartsQueryKeys.AMOUNT_STATISTICS, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data?.data;
    }
  );
}

export function useGetCounterPartCalendarView(isCounterPart: boolean) {
  const { severalSearchParams } = useQueryParams();
  const params = useParams();
  const { id } = parseParamsId(params?.second_id);
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);
  let url = `${counterpartsEndPoints.CALENDAR}?company_person_id=${id}`;

  if (searchParams) {
    url += `&${searchParams}`;
  }

  return useQuery(
    [counterpartsQueryKeys.CALENDAR_VIEW, searchParams],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: isCounterPart
    }
  );
}
