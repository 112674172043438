import { useState } from "react";
import { Button, Drawer, Dropdown, Radio, RadioChangeEvent, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { RU } from "../../../../app/utils/constants/languages";
import LogOutIcon from "../../../assets/icons/LogOutIcon";
import PackageXIcon from "../../../assets/icons/PackageXIcon";
import PlusCircleIcon from "../../../assets/icons/PlusCircleIcon";
import { useGetInventory } from "../../../service/queries";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { DefectModel } from "../../../utils/models/defectModel";
import { InventoryDetailModel } from "../../../utils/models/inventoryModel";

import DeleteInventory from "./delete-inventory/DeleteInventory";
import Expenses from "./expenses/Expenses";
import LeaveProject from "./leave-project/LeaveProject";
import Pays from "./pays/Pays";
import Works from "./works/Works";

import styles from "./inventoryView.module.scss";

enum ContentViewEnum {
  WORKS = "works",
  PAYS = "pays",
  EXPENSE = "expense"
}

type Props = {
  isCompanyPerson?: boolean;
};

const InventoryView: React.FC<Props> = ({ isCompanyPerson }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [visibleDeleteInventory, setVisibleDeleteInventory] = useState(false);
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [visibleLeaveProject, setVisibleLeaveProject] = useState(false);
  const [tabPosition, setTabPosition] = useState<ContentViewEnum>(ContentViewEnum.WORKS);
  const { visible, inventory_id } = useAppSelector(state => state.inventoryReducer.inventoryDetailModalData);
  const { data: inventory, isLoading } = useGetInventory();
  const { setInventoryDetailModalData, setAttachModalData, setDefectModalData, setInventoryModal } =
    inventoryReducerActions;
  const { actions } = useRoutePermissions("Inventar", routeSubmodules);
  const inventoryActions = actions("Inventarlar");

  const changeTabPosition = (e: RadioChangeEvent) => {
    setTabPosition(e.target.value);
  };

  const onClose = () => {
    dispatch(setInventoryDetailModalData({ visible: false, inventory_id: undefined }));
  };

  const onCloseDropdown = () => {
    setVisibleDropdown(false);
  };

  const onChangeProject = () => {
    dispatch(
      setAttachModalData({
        visible: true,
        inventory_id,
        project_id: inventory?.project?.id,
        project_name: inventory?.project?.name,
        start_date: inventory?.start_date,
        due_date: inventory?.due_date
      })
    );
  };

  const onAttachToProject = () => {
    dispatch(
      setAttachModalData({
        visible: true,
        inventory_id
      })
    );
  };

  const toggleVisibleDropdown = () => {
    setVisibleDropdown(prev => !prev);
  };

  const onLeaveProject = () => {
    setVisibleLeaveProject(true);
  };

  const onDeleteInventory = () => {
    setVisibleDeleteInventory(true);
  };

  const onDefectInventory = () => {
    dispatch(
      setDefectModalData({
        visible: true,
        product: inventory as DefectModel & InventoryDetailModel
      })
    );
  };

  const onEditInventory = () => {
    dispatch(setInventoryModal({ visible: true, inventory }));
  };

  const customDropdown = () => (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.dropdown} onClick={onCloseDropdown}>
        {inventory?.project?.id && (
          <div className={styles.dropdown__item} onClick={onChangeProject}>
            <PlusCircleIcon /> <span>{t("Inventar.Loyihani o’zgartirish")}</span>
          </div>
        )}
        {inventory?.project?.id && (
          <div className={styles.dropdown__item} onClick={onLeaveProject}>
            <LogOutIcon /> <span>{t("Inventar.Loyihadan chiqarish")}</span>
          </div>
        )}
        <div className={styles.dropdown__item} onClick={onDefectInventory}>
          <PackageXIcon /> <span>{t("Inventar.Yaroqsiz")}</span>
        </div>
        <div className={styles.dropdown__item} onClick={onDeleteInventory}>
          <DeleteIcon /> <span>{t("products.O'chirish")}</span>
        </div>
        <div className={styles.dropdown__item} onClick={onEditInventory}>
          <EditIcon /> <span>{t("products.Tahrirlash")}</span>
        </div>
      </div>
    </Spin>
  );

  const title = () => (
    <div className={styles.title}>
      <div className={styles.title__left}>
        <h2>
          {inventory?.name} <span>({inventory?.code})</span>
        </h2>
      </div>
      <div className={styles.title__right}>
        <Dropdown
          dropdownRender={() => customDropdown()}
          open={visibleDropdown}
          onOpenChange={toggleVisibleDropdown}
          trigger={["click"]}
          placement="bottom"
        >
          <Button className={styles.title__actions}>
            <DotsVerticalIcon />
          </Button>
        </Dropdown>
        <div onClick={onClose} className={styles.title__close}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const footer = () => (
    <div className={styles.footer}>
      {inventoryActions.toProject}
      <Button onClick={onAttachToProject} disabled={!!inventory?.project?.id} loading={isLoading}>
        <PlusIcon /> {t("Inventar.Loyihaga biriktirish")}
      </Button>
      <Button onClick={onClose}>{t("products.Yopish")}</Button>
    </div>
  );

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      closeIcon={false}
      width={825}
      title={title()}
      footer={footer()}
      className={styles.inventory_view}
    >
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <div className={styles.info}>
          <div className={styles.info__item}>
            <p>{t("Inventar.Yaratildi")} </p>
            <p>{inventory?.created_date ?? "-"}</p>
          </div>
          <div className={styles.info__item}>
            <p>{t("Inventar.Tan narxi")}</p>
            <p>
              {inventory?.amount?.toLocaleString(RU) ?? 0} {inventory?.amount_currency?.symbol}
            </p>
          </div>
          <div className={styles.info__item}>
            <p>{t("Inventar.Birlik narxi")}</p>
            <p>
              {inventory?.service_amount?.toLocaleString(RU) ?? 0} {inventory?.service_amount_currency?.symbol} /{" "}
              {inventory?.service_amount_unit?.symbol[i18n.language]}
            </p>
          </div>
          <div className={styles.info__item}>
            <p>{t("Inventar.Joriy loyiha")}</p>
            <p>{inventory?.project?.name ?? "-"}</p>
          </div>
        </div>
        <div className={styles.content}>
          <Radio.Group value={tabPosition} onChange={changeTabPosition}>
            <Radio.Button value={ContentViewEnum.WORKS}>{t("Inventar.Bajarilgan ishlar")}</Radio.Button>
            <Radio.Button value={ContentViewEnum?.PAYS}>{t("Inventar.To’lovlar")}</Radio.Button>
            <Radio.Button value={ContentViewEnum?.EXPENSE}>{t("Inventar.Xarajatlar")}</Radio.Button>
          </Radio.Group>
          {tabPosition === ContentViewEnum.WORKS && <Works isCompanyPerson={isCompanyPerson} />}
          {tabPosition === ContentViewEnum.PAYS && <Pays isCompanyPerson={isCompanyPerson} />}
          {tabPosition === ContentViewEnum.EXPENSE && <Expenses />}
        </div>
      </Spin>
      <LeaveProject
        open={visibleLeaveProject}
        setOpen={setVisibleLeaveProject}
        id={inventory?.id}
        name={inventory?.name}
      />
      <DeleteInventory
        open={visibleDeleteInventory}
        setOpen={setVisibleDeleteInventory}
        id={inventory?.id}
        onClose={onClose}
      />
    </Drawer>
  );
};

export default InventoryView;
