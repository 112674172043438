import { useContext } from "react";

import { WidgetContext } from "../context";

export const useWidget = () => {
  const data = useContext(WidgetContext);

  // if (!data) {
  //   throw new Error("useWidget must be used within a WidgetProvider");
  // }

  return data;
};
