import React, { useMemo } from "react";
import { UseFormReturn, useWatch } from "react-hook-form";
import { Segmented } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { Select } from "components";

import { widgetStaticData } from "../../constants";
import { useWidget } from "../../hooks";
import { useDynamicSettingsSelect } from "../../queries";
import { WidgetSettingsForm } from "../../schema";

import styles from "./settings.module.scss";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
  control: UseFormReturn<WidgetSettingsForm>["control"];
};

const Segments: React.FC<Props> = ({ form, control }) => {
  const { t } = useTranslation();
  const { key } = useWidget();
  const type = useWatch({
    control,
    name: "type"
  });
  const { settings } = widgetStaticData[key];
  const { segments } = settings;

  const findSelect = useMemo(() => {
    const findKey = type || segments?.[0].value;

    return segments?.find(item => item.value === findKey)?.select;
  }, [type, segments]);

  const onChangeSegment = (value: string) => {
    form.setValue("type", value);
  };

  const onChange = (value: number) => {
    form.setValue("custom_field_id", value);

    if (findSelect?.name === "task_custom_field_id") {
      form.setValue("type", "task");
      form.setValue("task_custom_field_id", value);
      form.setValue("section_custom_field_id", null);
    }

    if (findSelect?.name === "section_custom_field_id") {
      form.setValue("type", "section");
      form.setValue("section_custom_field_id", value);
      form.setValue("task_custom_field_id", null);
    }
  };

  const { select, isLoading } = useDynamicSettingsSelect({
    enabled: Boolean(findSelect),
    endPoint: findSelect?.endPoint || "",
    queryKey: findSelect?.queryKey || ""
  });

  return (
    <div className="flex flex-col gap-2">
      <Segmented
        rootClassName="w-max"
        options={
          segments?.map(item => ({
            value: item.value,
            label: item.title
          })) as never
        }
        onChange={onChangeSegment}
      />
      <Select
        label={false}
        maxTagCount={2}
        control={control}
        loading={isLoading}
        onChange={onChange}
        name={findSelect?.name || ""}
        placeholder={t("dashboard.Tanlang")}
        rootClassName={styles.multiple_select}
        mode={findSelect?.isNotMultiple ? undefined : "multiple"}
        options={select?.map(item => ({
          value: item.id,
          label: typeof item.name === "string" ? item.name : item.name?.[i18n.language],
          props: {
            name: typeof item.name === "string" ? item.name : item.name?.[i18n.language]
          }
        }))}
      />
    </div>
  );
};

export default Segments;
