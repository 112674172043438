import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { dashboardQueryKeys, projectSalePriceSchema, ProjectSalePriceView, WidgetKeys } from "..";

interface Query {
  companyProjectSalePriceView: Partial<ProjectSalePriceView>;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id: number;
  widgetKey: WidgetKeys;
}

export function useProjectSalePriceView({ enabled = false, id, widgetKey }: Params) {
  const initialValue: Query = { companyProjectSalePriceView: {} };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_SALE_PRICE, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-sale-price/view?${searchParams}`);

      const companyProjectSalePriceView: ProjectSalePriceView = projectSalePriceSchema.parse(data);

      return { companyProjectSalePriceView };
    },
    { enabled: enabled && widgetKey === "project_sale_price" }
  );

  return { ...data, ...args };
}
