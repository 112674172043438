import React from "react";
import { useLocation } from "react-router-dom";
import { UseMutationResult } from "@tanstack/react-query";
import { Form, FormInstance, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { ProductSelectModel } from "features/app/utils/models/productSelectModel";
import CreateProductModal from "features/supply/components/create-product-modal/CreateProductModal";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { rootPaths } from "../../../../../../../routes/root/rootPaths";
import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import { StatusEnums } from "../../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { isEmptyArr } from "../../../../../../app/utils/helpers/isEmptyArr";
import { ErrorRes, SuccessRes } from "../../../../../../app/utils/models/responseType";
import { PartyFormOfferModel, PartyFormProductsModel } from "../../../../../utils/models/partyModalFormModel";
import PartyProjectSelect from "../table/items/PartyProjectSelect";
import ResourceAvailability from "../table/items/ResourceAvailability";
import PartyModalTableOffers from "../table/PartyModalTableOffers";

import PersonAmount from "./items/PersonAmount";
import PersonProductSelect from "./items/PersonProductSelect";
import PersonQuantity from "./items/PersonQuantity";
import PersonSelect from "./items/PersonSelect";
import PersonTotalAmount from "./items/PersonTotalAmount";
import PersonUnitSelect from "./items/PersonUnitSelect";
import PersonWarehouseSelect from "./items/PersonWarehouseSelect";

import styles from "./partyPersonGroup.module.scss";

type Props = {
  form: FormInstance;
  groupIndex: number;
  isWarehouse?: boolean;
  isNotCompanyPerson?: boolean;
  data?: PartyFormProductsModel[];
  deleteProduct: UseMutationResult<SuccessRes, ErrorRes, number[]>;
};

const { useWatch } = Form;

const PartyPersonGroupTable: React.FC<Props> = ({
  data,
  form,
  groupIndex,
  isWarehouse,
  deleteProduct,
  isNotCompanyPerson
}) => {
  const status = useWatch("status");
  const searchValue = useWatch("search", form) as string;
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isImpostPage = pathname?.includes(rootPaths.IMPOST);
  const offers = useWatch("offers", form) as PartyFormOfferModel[];
  const { isView, isEditing } = useAppSelector(state => state.supplyReducer.partyModal);

  const viewOffers = isView && !isEmptyArr(offers);
  const viewReceived = status === StatusEnums.RECIEVED || status === StatusEnums.PARTIALLY_RECIEVED;

  const columns = (): ColumnsType<PartyFormProductsModel> => {
    const allColumn: ColumnsType<PartyFormProductsModel> = [
      {
        title: t("partyModal.Resurs turi va nomi"),
        render: (_, __, index) => <PersonProductSelect form={form} index={index} groupIndex={groupIndex} />,
        fixed: "left"
      },
      {
        title: t("partyModal.Birligi"),
        render: (_, __, index) => <PersonUnitSelect form={form} index={index} groupIndex={groupIndex} />
      },
      {
        title: t("partyModal.Soni"),
        width: 130,
        render: (_, __, index) => <PersonQuantity form={form} index={index} groupIndex={groupIndex} />
      },
      {
        title: t("partyModal.Qabul qilindi"),
        dataIndex: "received_quantity",
        render: (value: number) => <div className="px-3">{value}</div>,
        className: viewReceived ? "" : "hidden"
      },
      {
        title: t("partyModal.Birlik summasi"),
        render: (_, __, index) => (
          <PersonAmount form={form} index={index} groupIndex={groupIndex} isWarehouse={isWarehouse} />
        )
      },
      {
        title: t("partyModal.Omborxona"),
        render: (_, __, index) => <PersonWarehouseSelect index={index} groupIndex={groupIndex} form={form} />
      },
      {
        title: t("partyModal.Loyiha"),
        render: (_, __, index) => (
          <PartyProjectSelect name={["person_group", groupIndex, "data", index, "project_id"]} form={form} />
          // <ProjectSelect
          //   form={form}
          //   label={null}
          //   suffixIcon={null}
          //   disabled={isView && disabled()}
          //   name={["person_group", groupIndex, "data", index, "project_id"]}
          // />
        )
      },
      {
        title: t("partyModal.Ta'minotchi"),
        render: (_, __, index) => <PersonSelect index={index} groupIndex={groupIndex} form={form} />,
        key: "supply"
      },
      {
        key: "impost",
        title: t("partyModal.Resurs mavjudligi"),
        render: (_, __, index) => (
          <ResourceAvailability name={["person_group", groupIndex, "data", index]} form={form} />
        ),
        className: !isImpostPage || isEditing ? "hidden" : ""
      },
      {
        title: t("partyModal.Umumiy summa"),
        render: (_, record, index) => (
          <PersonTotalAmount
            form={form}
            index={index}
            rowId={record?.row_id}
            groupIndex={groupIndex}
            isWarehouse={isWarehouse}
            deleteProduct={deleteProduct}
          />
        ),
        fixed: "right"
      },
      {
        title: t("partyModal.Takliflar"),
        render: (_, record, index) => (
          <PartyModalTableOffers
            isPerson
            form={form}
            index={index}
            data={offers}
            rowId={record.row_id}
            groupIndex={groupIndex}
            deleteProduct={deleteProduct}
          />
        ),
        className: viewOffers ? "" : "hidden"
      }
    ];

    return allColumn.filter(item => (isNotCompanyPerson ? item.key !== "supply" : true));
  };

  const rowKey = (record: PartyFormProductsModel) => record.row_id!;

  const onAddProduct = () => {
    const oldPersonData = [
      ...(form.getFieldValue(["person_group", groupIndex, "data"]) ?? [])
    ] as PartyFormProductsModel[];

    const lastProduct = oldPersonData?.[oldPersonData?.length - 1];

    form.setFieldValue(
      ["person_group", groupIndex, "data"],
      [
        ...oldPersonData,
        {
          id: undefined,
          total: undefined,
          amount: undefined,
          unit_id: undefined,
          quantity: undefined,
          product_id: undefined,
          row_id: uid(12),
          project_id: lastProduct?.project_id,
          currency_id: lastProduct?.currency_id,
          warehouse_id: lastProduct?.warehouse_id,
          company_person_id: lastProduct?.company_person_id
        }
      ]
    );
  };

  const footer = () =>
    !isView &&
    !searchValue && (
      <div className={styles.footer}>
        <div className={styles.add_resource} onClick={onAddProduct}>
          <PlusCircleIcon fill />
          {t("partyModal.Resurs qo'shish")}
        </div>
      </div>
    );

  const onAfterProductAdded = (
    productId: number,
    unitId: number,
    res: ProductSelectModel,
    fieldName: string | Array<string>
  ) => {
    const oldSelectProducts = form.getFieldValue("select_products") ?? [];

    form.setFieldValue([...fieldName, "product_id"], productId);
    form.setFieldValue([...fieldName, "unit_id"], unitId);
    form.setFieldValue(["select_products"], [res, ...oldSelectProducts]);
  };

  return (
    <>
      <Table<PartyFormProductsModel>
        rowKey={rowKey}
        footer={footer}
        dataSource={data}
        pagination={false}
        columns={columns()}
        className={styles.table}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <CreateProductModal afterFunc={onAfterProductAdded as never} />
    </>
  );
};

export default PartyPersonGroupTable;
