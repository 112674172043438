import React from "react";
import { useDispatch } from "react-redux";
import { Button, Form as AntForm, Spin, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import LayoutIcon from "../../../../payment/assets/icons/LayoutIcon";
// import ChevronDownIcon from "../../../../supply/assets/icons/ChevronDownIcon";
import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { cx } from "../../../utils/helpers/cx";
import { selectFilterOption } from "../../../utils/helpers/selectFilterOption";
import {
  ProjectSelectBasePropsModel,
  ProjectSelectFormModel,
  ProjectSelectModel
} from "../../../utils/models/projectSelectModel";
import { LoadingIndicator } from "../../loading-indicator/LoadingIndicator";
import SelectNotContent from "../../select-not-content/SelectNotContent";

import styles from "../project-select.module.scss";

type Props = Partial<ProjectSelectFormModel & ProjectSelectBasePropsModel> & {
  data: ProjectSelectModel[] | undefined;
  isLoading: boolean;
};

const { Item } = AntForm;

const Form: React.FC<Props> = ({
  form,
  rule,
  label,
  data,
  isTask,
  onChange,
  variant,
  disabled,
  isLoading,
  allowClear,
  placeholder,
  renderName,
  onAfterChange,
  initialValue,
  rootClassName,
  multiple = false,
  name = "project_id",
  suffixIcon = <SelectSuffixIcon />,
  className
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const value = AntForm.useWatch(name, form);
  const { setEstimateDrawer } = paymentReducerActions;

  const onChangeBasic = (value: number) => {
    form?.setFieldValue(name, value);

    onAfterChange && onAfterChange();
  };

  const onOpenEstimate = (id?: number) => () => {
    dispatch(
      setEstimateDrawer({
        visible: true,
        projectId: id
      })
    );
  };

  const generateProjectLabel = (name?: string, projectId?: number, projects?: Array<{ id: number }>) => {
    if (isTask && typeof projects === "undefined") {
      return (
        <div className={cx("project_name flex w-full items-center justify-between")}>
          <span>{name}</span>
          <Button
            className={cx("project_name__task box-border h-9 w-9 p-1.5 opacity-0")}
            onClick={onOpenEstimate(projectId)}
          >
            <LayoutIcon />
          </Button>
        </div>
      );
    }

    return name;
  };

  return (
    <Item
      rules={rule}
      label={label}
      name={renderName || name}
      initialValue={initialValue}
      rootClassName={rootClassName || ""}
    >
      <TreeSelect
        showSearch
        variant={variant}
        multiple={multiple}
        disabled={disabled}
        suffixIcon={suffixIcon}
        allowClear={allowClear}
        popupMatchSelectWidth={false}
        rootClassName={styles.tree_select}
        onChange={onChange || onChangeBasic}
        className={className}
        filterTreeNode={selectFilterOption as never}
        placeholder={placeholder || t("app.Tanlang")}
        treeData={data?.map(project => ({
          key: project?.id,
          value: project?.id,
          label: generateProjectLabel(project?.name, project?.id, project?.projects),
          props: {
            name: project?.name
          },
          children: project?.projects?.map(child => ({
            key: child?.id,
            value: child?.id,
            label: generateProjectLabel(child?.name, child?.id),
            props: {
              name: child?.name
            }
          }))
        }))}
        notFoundContent={
          isLoading ? (
            <Spin spinning={isLoading} indicator={LoadingIndicator}>
              <SelectNotContent />
            </Spin>
          ) : (
            <SelectNotContent />
          )
        }
      />
    </Item>
  );
};

export default Form;
