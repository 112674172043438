import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import CreditCardIcon from "features/payment/assets/icons/CreditCardIcon";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import { useGetPaymentTypes } from "features/settings/service/queries";
import { useGetWarehouseOrderExcelFile } from "features/warehouse/service/mutation";
import { orderTableDefaultData } from "features/warehouse/utils/constants/orderTableDefaultData";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { colors } from "modules/common";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import UserIcon from "../../../../app/assets/icons/UserIcon";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetCustomFieldSelect, useGetTableConfigs, useGetUsersSelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { ImpostStatusEnums } from "../../../../app/utils/enums/impostStatusEnums";
import { useGetCompanyPersonSelect } from "../../../../counterparts/service/queries";
import UsersIcon from "../../../../payment/assets/icons/UsersIcon";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import { StatusEnums } from "../../../../supply/utils/enums/statusEnums";
import FilterLinesIcon from "../../../assets/icons/FilterLinesIcon";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.STATUSES,
  queryParamsKeys.AGENT_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS,
  queryParamsKeys.CONFIRMATION_PAYMENTS,
  queryParamsKeys.PAYMENT_STATUSES,
  queryParamsKeys.MIN_DELIVERY_DATE,
  queryParamsKeys.MAX_DELIVERY_DATE,
  queryParamsKeys.MIN_ORDERED_DATE,
  queryParamsKeys.MAX_ORDERED_DATE,
  queryParamsKeys.CUSTOM_FIELDS,
  queryParamsKeys.MIN_PAYMENT_DATE,
  queryParamsKeys.MAX_PAYMENT_DATE,
  queryParamsKeys.PAYMENT_TYPE_IDS
];

const FilterDrawerOrders = () => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const orderActions = actions("Partiyalar");
  const { queries } = useQueryParams();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const {
    search,
    min_delivery_date,
    max_delivery_date,
    min_payment_date,
    max_payment_date,
    min_ordered_date,
    max_ordered_date
  } = queries();
  const [open, setOpen] = useState(false);
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [agentsSelected, setAgentsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.AGENT_IDS] as string[] | null
  );
  const [confirmationPaymentsSelected, setConfirmationPaymentsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CONFIRMATION_PAYMENTS] as string[] | null
  );
  const [paymentStatusesSelected, setPaymentStatusesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_STATUSES] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    min_payment_date ? dayjs(min_payment_date, dayjsFormats.DATE) : null,
    max_payment_date ? dayjs(max_payment_date, dayjsFormats.DATE) : null
  ]);
  const [deliveryDateValue, setDeliveryDateValue] = useState<null | (Dayjs | null)[]>([
    min_delivery_date ? dayjs(min_delivery_date, dayjsFormats.DATE) : null,
    max_delivery_date ? dayjs(max_delivery_date, dayjsFormats.DATE) : null
  ]);
  const [orderedDateValue, setOrderedDateValue] = useState<null | (Dayjs | null)[]>([
    min_ordered_date ? dayjs(min_ordered_date, dayjsFormats.DATE) : null,
    max_ordered_date ? dayjs(max_delivery_date, dayjsFormats.DATE) : null
  ]);

  const [paymentTypesSelected, setPaymentTypesSelected] = useState(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PAYMENT_TYPE_IDS] as string[] | null
  );

  const exportExcel = useGetWarehouseOrderExcelFile();
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: companyPersonsSelect } = useGetCompanyPersonSelect(open);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_ORDER_CONFIG, orderTableDefaultData);
  const { data: paymentTypes } = useGetPaymentTypes();
  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const selectableCustomFields = customFields?.filter(
    customField => customField?.type === "select" || customField?.type === "label"
  );

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => Object.values(customFieldStates).flat(), [customFieldStates]);

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => ({ name: item?.full_name, ...item }));

    return result;
  }, [adminsSelect]);

  const companyPersons = useMemo(() => {
    const result = companyPersonsSelect?.map(item => ({ ...item }));

    return result;
  }, [companyPersonsSelect]);

  const confirmationPaymentData = useMemo(
    () => [
      {
        id: ImpostStatusEnums.PENDING,
        name: `${t("warehouse.Kutilmoqda")}`
      },
      {
        id: ImpostStatusEnums.RECIEVED,
        name: `${t("warehouse.Qabul qilingan")}`
      },
      {
        id: ImpostStatusEnums.REJECTED,
        name: `${t("warehouse.Rad qilingan")}`
      }
    ],
    []
  );

  const statusesData = useMemo(
    () => [
      {
        id: StatusEnums.PASSIVE,
        name: `${t("warehouse.Qoralama")}`
      },
      {
        id: StatusEnums.OPEN,
        name: `${t("warehouse.Yangi")}`
      },
      {
        id: StatusEnums.PENDING,
        name: `${t("warehouse.Kutilmoqda")}`
      },
      {
        id: StatusEnums.ORDERED,
        name: `${t("warehouse.Partiya qilingan")}`
      },
      {
        id: StatusEnums.RECIEVED,
        name: `${t("warehouse.Qabul qilingan")}`
      },
      {
        id: StatusEnums.REJECTED,
        name: `${t("warehouse.Bekor qilingan")}`
      }
    ],
    []
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  const onExportExcel = () => {
    const data = filterColumns({
      customFields,
      tableConfig
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    exportExcel.mutate({
      search,
      columns: data,
      min_delivery_date,
      max_delivery_date,
      statuses: statusSelected,
      agent_ids: agentsSelected,
      payment_statuses: paymentStatusesSelected,
      company_person_ids: companyPersonsSelected,
      confirmation_payments: confirmationPaymentsSelected
    });
  };

  const extraFooter = orderActions?.upload ? (
    <Button className={styles.downloadBtn} onClick={onExportExcel} loading={exportExcel.isLoading}>
      <DownloadIcon />
      {t("warehouse.Yuklab olish")}
    </Button>
  ) : (
    <span />
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];

          initialCustomFieldStates[field.id] = valuesArray.filter(value =>
            field?.custom_field_options?.some(option => String(option.id) === value)
          ) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("warehouse.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      height="max-content"
      queryStringArr={queryStringArr}
      footerContent={extraFooter}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.AGENT_IDS,
          selectedOptions: agentsSelected
        },
        {
          queryKey: queryParamsKeys.CONFIRMATION_PAYMENTS,
          selectedOptions: confirmationPaymentsSelected
        },
        {
          queryKey: queryParamsKeys.PAYMENT_STATUSES,
          selectedOptions: paymentStatusesSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        },
        {
          queryKey: queryParamsKeys.PAYMENT_TYPE_IDS,
          selectedOptions: paymentTypesSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_PAYMENT_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_PAYMENT_DATE,
          selectedValue: dateValue && dateValue[1]
        },
        {
          queryKey: queryParamsKeys.MIN_DELIVERY_DATE,
          selectedValue: deliveryDateValue && deliveryDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DELIVERY_DATE,
          selectedValue: deliveryDateValue && deliveryDateValue[1]
        },
        {
          queryKey: queryParamsKeys.MIN_ORDERED_DATE,
          selectedValue: orderedDateValue && orderedDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_ORDERED_DATE,
          selectedValue: orderedDateValue && orderedDateValue[1]
        }
      ]}
      setDateValue={[setDateValue, setDeliveryDateValue, setOrderedDateValue]}
      setSelectedOptionsArr={
        [
          setConfirmationPaymentsSelected,
          setCompanyPersonsSelected,
          setAgentsSelected,
          setPaymentStatusesSelected,
          setStatusSelected,
          setCustomFieldStates,
          setPaymentTypesSelected
        ] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("warehouse.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              selectedOptions={companyPersonsSelected}
              setSelectedOptions={setCompanyPersonsSelected}
              data={companyPersons}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("warehouse.Kontragent")}
              showSearch
            />
            <FilterSelect
              selectedOptions={agentsSelected}
              setSelectedOptions={setAgentsSelected}
              data={admins}
              multiple="multiple"
              icon={<UserIcon />}
              placeholder={t("warehouse.Vositachi")}
              showSearch
            />
            <FilterSelect
              selectedOptions={confirmationPaymentsSelected}
              setSelectedOptions={setConfirmationPaymentsSelected}
              data={confirmationPaymentData}
              multiple="multiple"
              icon={<ZapIcon />}
              placeholder={t("warehouse.Tasdiqlanish")}
              showSearch
            />
            <FilterSelect
              selectedOptions={paymentTypesSelected}
              setSelectedOptions={setPaymentTypesSelected}
              data={paymentTypes}
              placeholder={t("warehouse.To'lov turi")}
              multiple="multiple"
              icon={<CreditCardIcon />}
              showSearch
            />
            <FilterSelect
              selectedOptions={statusSelected}
              setSelectedOptions={setStatusSelected}
              data={statusesData}
              multiple="multiple"
              icon={<ZapIcon />}
              placeholder={t("warehouse.Holati")}
              showSearch
            />
            <FilterDateRange
              key="warehouse_payment_date"
              value={dateValue}
              setValue={setDateValue}
              placeholder={t("warehouse.To'lov sanasi")}
            />
            <FilterDateRange
              key="warehouse_delivery_date"
              value={deliveryDateValue}
              setValue={setDeliveryDateValue}
              placeholder={t("warehouse.Yetkazilish sanasi")}
            />
            <FilterDateRange
              key="warehouse_ordered_date"
              value={orderedDateValue}
              setValue={setOrderedDateValue}
              placeholder={t("warehouse.Buyurtma sanasi")}
            />
          </div>
        </div>
        {selectableCustomFields && selectableCustomFields.length! > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("warehouse.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerOrders;
