import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseTransferCreated = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      className={styles.description}
      onClick={() => onNavigatePage("/warehouse?tab=transfer", record)}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.warehouse_transfer?.recieved_warehouse?.name} sizga T -
        <span className={styles.name}>{record?.warehouse_transfer?.id}</span> raqamli
        o’tkazma yubordi.
      </p>
    </div>
  );
};
 
export default WarehouseTransferCreated;
