import React, { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Dropdown, MenuProps, Popover, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import classNames from "classnames";
import { DateStatus } from "features/app/components/date-status/DateStatus";
import { useChangeTableConfig } from "features/app/service/mutation";
import WalletIcon from "features/payment/assets/icons/WalletIcon";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useQueryParams } from "hooks/useQueryParams";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import OrderSortBy from "../../../../app/components/order-sort-by/OrderSortBy";
import PopoverShortName from "../../../../app/components/popover-short-name/PopoverShortName";
import { StatusEnums } from "../../../../app/components/statuses/statusEnums";
import TableSettings from "../../../../app/components/table-settings/TableSettings";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../app/service/queries";
import { RU } from "../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../../app/utils/helpers/cx";
import { filterColumns } from "../../../../app/utils/helpers/filterColumns";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { sliceText } from "../../../../app/utils/helpers/sliceText";
import { impostQueryKeys } from "../../../../impost/utils/constants/impostQueryKeys";
import { routeSubmodules as impostRouteSubmodules } from "../../../../impost/utils/constants/routeSubmodules";
import PencilIcon from "../../../../payment/assets/icons/PencilIcon";
import XCircleIcon from "../../../../warehouse/assets/icons/XCircleIcon";
import CheckCircleIcon from "../../../assets/icons/CheckCircleIcon";
import ClockFastForwardIcon from "../../../assets/icons/ClockFastForwardIcon";
import PhoneCallIcon from "../../../assets/icons/PhoneCallIcon";
import { useDeleteOffer, useOfferProductRecieved, useOfferProductRejected } from "../../../service/mutations";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { supplyQueryNames } from "../../../utils/constants/supplyQueryNames";
import { warehouseOfferTableDefaultData } from "../../../utils/constants/warehouseOfferTableDefaultData";
import { OfferModel, ProductOfferModel } from "../../../utils/models/OfferModel";

import styles from "./offers.module.scss";

import useDebounce from "components/use-debounce/use-debounce";


const OfferColumns = ({
  isImpost,
  renderColumns,
  setColumns
}: {
  isImpost?: boolean;
  renderColumns: ColumnsType<OfferModel>;
  setColumns: Dispatch<SetStateAction<ColumnsType<OfferModel>>>;
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { severalSearchParams } = useQueryParams();
  const qc = useQueryClient();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const deleteOffer = useDeleteOffer();
  const acceptedOffer = useOfferProductRecieved();
  const rejectedOffer = useOfferProductRejected();
  const { setSelectOffers, setOfferModal, setOfferHistory } = supplyOfferActions;
  const { setOrderModal } = supplyActions;
  const { setVisible } = chatActions;
  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const offerActions = actions("Takliflar");

  const { actions: impostActions } = useRoutePermissions("Monitoring", impostRouteSubmodules);

  const impostOfferActions = impostActions("Takliflar");

  const { data: selectOffers } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG, true);

  const { data: tableConfigData } = useGetTableConfigs(
    tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG,
    warehouseOfferTableDefaultData
  );

  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER]);

  const statusBackgroundColors = {
    [StatusEnums.RECIEVED]: "#12B76A",
    [StatusEnums.REJECTED]: "#F04438"
  };

  const notUserBg = (products: ProductOfferModel[]) => {
    const sliceProducts = products?.slice(4, products?.length);

    const isRecievedProduct = sliceProducts?.some(item => item.status === StatusEnums.RECIEVED);

    if (isRecievedProduct) return "#12B76A";
    return "";
  };

  const checkButtonClass = (item: ProductOfferModel, status: StatusEnums) => {
    if (item.status === status) {
      return styles[status];
    }
    if (item.status === StatusEnums.OPEN) {
      return styles[StatusEnums.OPEN];
    }
    return `${styles[StatusEnums.OPEN]}`;
  };

  const changeSelectOffersData = (status: StatusEnums, id: number) => () => {
    if (!isEmptyArr(selectOffers)) {
      const newSelectOffers: OfferModel[] = [];

      selectOffers.forEach(offer => {
        let newOfferValue = offer;

        const indexWarehouseProduct = offer?.warehouse_product_offers?.findIndex(product => product.id === id);

        if (indexWarehouseProduct! >= 0) {
          newOfferValue = {
            ...newOfferValue,
            warehouse_product_offers: newOfferValue.warehouse_product_offers?.map((item, index) => {
              if (index === indexWarehouseProduct) {
                return {
                  ...item,
                  status
                };
              }
              return item;
            })
          };
        }

        newSelectOffers.push(newOfferValue);
      });

      dispatch(setSelectOffers(newSelectOffers));
    }
  };

  const onCheckProduct = (item: ProductOfferModel, status: StatusEnums) => () => {
    if (status === StatusEnums.RECIEVED) {
      acceptedOffer.mutateAsync(item.id).then(changeSelectOffersData(StatusEnums.RECIEVED, item.id));
    }
    // if (status === StatusEnums.REJECTED) {
    //   rejectedOffer
    //     .mutateAsync(item.id)
    //     .then(changeSelectOffersData(StatusEnums.REJECTED, item.id));
    // }
  };

  const productsItems = (products: ProductOfferModel[]) => (
    <Spin spinning={acceptedOffer.isLoading || rejectedOffer.isLoading} indicator={LoadingIndicator}>
      <div className={styles.products__content}>
        {products?.map(item => (
          <div className={styles.products__label} key={item?.id}>
            <div className={styles.products__top}>
              <div className={styles.products__label__left}>
                <NotUserImage
                  key={item?.id}
                  name={item?.name ? item?.name : item?.company_person?.name}
                  background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
                />
                <div className={styles.products__name}>
                  <h5>{item?.name ? item?.name : item?.company_person?.name}</h5>
                  <div className="flex items-center gap-1">
                    <p>
                      {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                    </p>
                    {item?.vat && (
                      <span className="flex w-5 items-center justify-center rounded-lg border border-solid border-primary-500 bg-primary-500 p-0.5 text-[0.5rem] font-medium text-white">
                        NDS
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.products__label__right}>
                {(isImpost ? impostOfferActions.rejected : offerActions.rejected) && (
                  <ModalConfirm
                    title="Taklifni rad qilish"
                    description="Nega taklifni rad etmoqdasiz sababini yozing."
                    mutation={rejectedOffer}
                    cancelText="Yopish"
                    okText="Rad qilish"
                    confirmStatus={item.status}
                    payload={{ id: item.id }}
                    onAfterClose={changeSelectOffersData(StatusEnums.REJECTED, item.id)}
                    isDescription={true}
                    requestKey="rejected_description"
                  >
                    <Button
                      className={classNames(styles.rejected__hover, checkButtonClass(item, StatusEnums.REJECTED))}
                    >
                      <XCircleIcon />
                    </Button>
                  </ModalConfirm>
                )}
                {(isImpost ? impostOfferActions.recieved : offerActions.recieved) && (
                  <Button
                    onClick={onCheckProduct(item, StatusEnums.RECIEVED)}
                    className={classNames(styles.recieved__hover, checkButtonClass(item, StatusEnums.RECIEVED))}
                  >
                    <CheckCircleIcon />
                  </Button>
                )}
              </div>
            </div>
            {(item?.phone || item?.description) && (
              <div className={styles.products__bottom}>
                {item?.phone && (
                  <div className={styles.products__bottom__item}>
                    <PhoneCallIcon />
                    <span>{item?.phone}</span>
                  </div>
                )}
                {item?.description && (
                  <div className={styles.products__bottom__item}>
                    <PencilIcon />
                    <span>{item?.description}</span>
                  </div>
                )}
                {item.rejected_description && (
                  <div className={styles.products__bottom__item}>
                    <XCircleIcon />
                    <span>{item?.rejected_description}</span>
                  </div>
                )}
              </div>
            )}
            {item?.payment_type && (
              <div className="flex items-center gap-2">
                <WalletIcon />
                {item?.payment_type?.name}
              </div>
            )}
          </div>
        ))}
      </div>
    </Spin>
  );

  const offerUsers = (products: ProductOfferModel[]) => (
    <Popover placement="top" trigger={["hover"]} content={productsItems(products)}>
      <div className={styles.products}>
        {products?.slice(0, 4)?.map(item => (
          <div className={styles.products__item} key={item.id}>
            <NotUserImage
              key={item?.id}
              name={item?.name ? item?.name : item?.company_person?.name}
              background={statusBackgroundColors[item.status as keyof typeof statusBackgroundColors]}
            />
          </div>
        ))}
        {products?.length > 4 ? (
          <div className={styles.products__item}>
            <NotUserImage background={notUserBg(products)} title={`+${products?.length - 4}`} />
          </div>
        ) : null}
      </div>
    </Popover>
  );

  const sectionName = (record: OfferModel) => {
    const { task, section } = record;

    return (
      <div className={styles.work}>
        <h5>
          {section?.name ? (
            <>
              <span>
                {section?.place}.{task?.place}
              </span>
              <span>
                {section?.name?.length > 16 ? (
                  <Popover title={section?.name} overlayClassName={styles.popover}>
                    {sliceText(section?.name, 16)}
                  </Popover>
                ) : (
                  section?.name
                )}
              </span>
            </>
          ) : (
            "-"
          )}
        </h5>
        {task?.name && (
          <span>
            {task?.name?.length > 18 ? (
              <Popover title={task?.name} overlayClassName={styles.popover}>
                {sliceText(task?.name, 18)}
              </Popover>
            ) : (
              task?.name
            )}
          </span>
        )}
      </div>
    );
  };

  const columns: ColumnsType<OfferModel> = [
    {
      title: `${t("Supply.Resurs turi va nomi")}`,
      render: (record: OfferModel) => {
        const columnWidth = renderColumns?.find(column => column.className === "resource_name_type")?.width;

        return (
          <div className={cx("resource")}>
            <div
              style={{
                borderColor: record?.product?.resource?.color,
                color: record?.product?.resource?.color
              }}
            >
              {record?.product?.resource?.symbol?.[i18n.language]}
            </div>
            <PopoverShortName
              title={record?.product?.name[i18n.language]}
              length={((columnWidth as number) + 150) / 10}
            />
          </div>
        );
      },
      width: 350,
      fixed: "left",
      className: "resource_name_type"
    },
    {
      title: `${t("Supply.Ish nomi va bo’limi")}`,
      render: sectionName,
      key: "work_name",
      width: 350,
      className: "work_name"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_UNIT} title={t("Supply.Birligi")} />,
      render: (record: OfferModel) => record?.unit?.symbol[i18n.language],
      key: "unit",
      width: 120,
      className: "offer-unit"
    },
    {
      title: <OrderSortBy queryParamKey={queryParamsKeys.SORT_QUANTITY} title={t("Supply.Soni")} />,
      dataIndex: "quantity",
      key: "quantity",
      width: 120,
      className: "offer-quantity"
    },
    {
      title: (
        <OrderSortBy queryParamKey={queryParamsKeys.SORT_DELIVERY_DATE} title={t("Supply.Kerak bo'lish sanasi")} />
      ),
      key: "delivery_date",
      width: 170,
      render: (record: OfferModel) => <DateStatus status={StatusEnums.OPEN} delivery_date={record.delivery_date!} />,
      className: "offer-delivery-date"
    },
    {
      title: `${t("Supply.Takliflar")}`,
      render: (_, record) => offerUsers(record?.warehouse_product_offers || []),
      key: "offers",
      width: 200,
      className: "offers"
    }
  ];

  const onEdit = (record: OfferModel) => () => {
    dispatch(
      setOfferModal({
        visible: true,
        isEdit: true,
        data: [
          {
            product: {
              id: +record.product!.id,
              name: record.product!.name,
              code: String(record.product!.code),
              resource: {
                ...record.product!.resource,
                status: ""
              }
            },
            quantity: record.quantity!,
            amount: record.amount!,
            id: record.id,
            delivery_date: record.delivery_date!,
            creator: record.creator,
            unit: {
              ...record.unit!,
              status: "",
              description: {}
            },
            project: record.project,
            warehouse: record.warehouse,
            offers:
              record.warehouse_product_offers?.map((item, index) => ({
                uniqueId: uid(12),
                id: item.id,
                ordinalNumber: index + 1,
                amount: localeFormatter(String(item.amount)),
                currency_id: item.currency?.id,
                description: undefined,
                phone: item.phone,
                company_person_id: item.company_person?.id,
                name: item.company_person ? item.company_person?.name : item.name,
                vat: item?.vat,
                payment_type_id: item?.payment_type?.id
              })) || []
          }
        ]
      })
    );
  };

  const onOpenChat = (record: OfferModel) => () => {
    if (isImpost) {
      dispatch(
        setVisible({
          visible: true,
          type: ChatEnum.WAREHOUSE_PRODUCTS,
          objectId: record?.id,
          dataKeys: [impostQueryKeys.OFFERS, severalSearchParams(queryParamsKeys.TAB)],
          record
        })
      );
    } else {
      dispatch(
        setVisible({
          visible: true,
          type: ChatEnum.WAREHOUSE_PRODUCTS,
          objectId: record?.id,
          dataKeys: [supplyQueryNames.OFFERS, severalSearchParams(queryParamsKeys.TAB)],
          record
        })
      );
    }
  };

  const onEditWarehouseProduct = (record: OfferModel) => () => {
    dispatch(
      setOrderModal({
        isEdit: true,
        visible: true,
        // @ts-ignore
        selectProducts: [record]
      })
    );
  };

  const onDeleteOffer = (id: number) => () => deleteOffer.mutateAsync(id);

  const onOpenOffersHistoryModal = (id: number) => {
    dispatch(
      setOfferHistory({
        productId: id,
        visible: true,
        isCheckedView: true
      })
    );
  };

  const dropdownMenu = (record: OfferModel): MenuProps["items"] => {
    const defaultMenuItems = [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("Supply.Taklif")}
          </div>
        ),
        onClick: onEdit(record),
        permissionKey: "edit"
      },
      {
        key: "2",
        label: (
          <div className="flex items-center gap-2">
            <EditIcon />
            {t("Supply.Buyurtma")}
          </div>
        ),
        onClick: onEditWarehouseProduct(record),
        permissionKey: "edit"
      },
      {
        key: "3",
        label: (
          <div className="flex items-center gap-2">
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        onClick: onOpenChat(record),
        permissionKey: "chatView"
      },
      {
        key: "4",
        label: (
          <div className="flex items-center gap-2">
            <ClockFastForwardIcon />
            {t("Supply.Takliflar tarixi")}
          </div>
        ),
        onClick: () => {
          onOpenOffersHistoryModal(Number(record.product?.id));
        },
        permissionKey: "offerHistory"
      },
      {
        key: "5",
        label: (
          <ModalConfirm title="Taklifni o'chirish" onOk={onDeleteOffer(record?.id)}>
            <div className="flex items-center gap-2">
              <DeleteIcon />
              {t("Supply.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        permissionKey: "delete"
      }
    ];

    return defaultMenuItems.filter(item => {
      if (item.permissionKey) {
        if (isImpost) {
          return item.key !== "2" && impostOfferActions[item.permissionKey];
        }
        return offerActions[item.permissionKey];
      }
      return true;
    });
  };

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800,timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<OfferModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfigData && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          customFields,
          tableConfig: tableConfigData
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={warehouseOfferTableDefaultData}
              configKey={tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG}
              locations={[CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER]}
            />
          ),
          render: (record: OfferModel) =>
            (isImpost ? impostOfferActions.delete : offerActions.delete) ? (
              <Dropdown trigger={["click"]} menu={{ items: dropdownMenu(record) }}>
                <UnreadMessageDots count={record?.unread_message_count} />
              </Dropdown>
            ) : null,
          className: "c_p",
          fixed: "right",
          width: 75
        }
      ]);
    }
  }, [tableConfigData, customFields]);

  return { columns: renderColumns, onChangeColumns };
};

export default OfferColumns;
