import React from "react";

type Props = {
  stroke?: string;
};

const FileHistoryIcon: React.FC<Props> = ({ stroke }) => (
  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6663 11.1667V6.41675C16.6663 5.01662 16.6663 4.31655 16.3939 3.78177C16.1542 3.31137 15.7717 2.92892 15.3013 2.68923C14.7665 2.41675 14.0665 2.41675 12.6663 2.41675H7.33301C5.93288 2.41675 5.23281 2.41675 4.69803 2.68923C4.22763 2.92892 3.84517 3.31137 3.60549 3.78177C3.33301 4.31655 3.33301 5.01662 3.33301 6.41675V15.0834C3.33301 16.4835 3.33301 17.1836 3.60549 17.7184C3.84517 18.1888 4.22763 18.5712 4.69803 18.8109C5.23281 19.0834 5.93288 19.0834 7.33301 19.0834H9.99967M11.6663 9.91675H6.66634M8.33301 13.2501H6.66634M13.333 6.58341H6.66634M12.083 16.5834L13.7497 18.2501L17.4997 14.5001"
      stroke={stroke || "#344054"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse cx="15.5" cy="16.75" rx="4.5" ry="4" fill="white" />
    <g clipPath="url(#clip0_4861_6208)">
      <path
        d="M19.5125 16.5625L18.7627 17.3125L18.0125 16.5625M18.8544 17.125C18.868 17.0019 18.875 16.8768 18.875 16.75C18.875 14.886 17.364 13.375 15.5 13.375C13.636 13.375 12.125 14.886 12.125 16.75C12.125 18.614 13.636 20.125 15.5 20.125C16.5602 20.125 17.5063 19.6361 18.125 18.8715M15.5 14.875V16.75L16.625 17.5"
        stroke={stroke || "#344054"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4861_6208">
        <rect width="9" height="9" fill="white" transform="translate(11 12.25)" />
      </clipPath>
    </defs>
  </svg>
);

export default FileHistoryIcon;
