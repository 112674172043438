import { ReqCustomFieldValueModel } from "../models/reqCustomFieldValueModel";

export function isEmptyObj(object: object | undefined) {
  let result = false;

  if (object) {
    const keys = Object.keys(object);

    for (const key of keys) {
      result = !object[key as keyof unknown];
    }
  } else {
    result = true;
  }

  return result;
}

export function areObjectsEqual<T, D>(obj1: T, obj2: D): boolean {
  const keys1 = Object.keys(obj1 as object);
  let isEqual = false;

  for (const key of keys1) {
    const firstObjValue = obj1[key as keyof unknown];
    const secondObjValue = obj2[key as keyof unknown];

    if (key === "custom_field_values") {
      const firstArr = [...(firstObjValue ?? [])] as ReqCustomFieldValueModel[];
      const secondArr = [...secondObjValue] as ReqCustomFieldValueModel[];

      if (firstArr?.length !== secondArr?.length) {
        isEqual = true;
      } else {
        for (const item of firstArr) {
          const findSecondItem = secondArr?.find(e => e.custom_field_id === item.custom_field_id);

          if (findSecondItem?.value !== item?.value) {
            isEqual = true;
          }
        }
      }
    } else if (firstObjValue !== secondObjValue) {
      isEqual = true;
      break;
    }
  }

  return isEqual;
}
