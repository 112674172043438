import { RU } from "../../../../../../app/utils/constants/languages";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { DynamicEstimateEnum } from "../../../../../utils/enums/dynamicEstimateEnum";
import { diffQuantity } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateProductModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverQuantity from "../../diff-popover/DiffPopoverQuantity";

import styles from "../product.module.scss";

type Props = {
  product: DynamicEstimateProductModel;
  taskStatus?: DynamicEstimateEnum;
  className?: string;
  isBordered?: boolean;
  getPopoverContainer?:()=>HTMLElement
};

const ExpectedQuantity = ({ product, taskStatus, className, isBordered = false,getPopoverContainer }: Props) => (
  <>
    {diffQuantity(product?.quantity, product?.spend_total_quantity) !== 0 ? (
      <DiffPopoverQuantity
        isFeature={true}
        record={product}
        taskStatus={taskStatus}
        isBordered={isBordered}
        classNames={cx(styles.quantity, className)}
        getPopoverContainer={getPopoverContainer}
      />
    ) : (
      <p className={styles.quantity}>{product?.quantity?.toLocaleString(RU) ?? 0} </p>
    )}
  </>
);

export default ExpectedQuantity;
