import React from "react";
import TransferNoDataIcon from "features/warehouse/assets/icons/TransferNoDataIcon";

const DefectNoData: React.FC = () => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-4">
    <TransferNoDataIcon />
    <span>
      <h1 className="text-center text-sm font-semibold  text-gray-900">
        Yaroqsizga chiqarish uchun mahsulotlar mavjud emas
      </h1>
      <p className="text-center text-sm font-normal  text-gray-600">
        Omborxona tanlang va yaroqsizga chiqarishni boshlang
      </p>
    </span>
  </div>
);

export default DefectNoData;
