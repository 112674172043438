import React from "react";
import { Skeleton } from "antd";
import { useTranslation } from "react-i18next";

import { isEmptyArr } from "modules/common";
import { useWidgetSelect } from "modules/dashboard";

import WidgetCard from "./card";

import styles from "./drawer.module.scss";

const RecommendedList: React.FC = () => {
  const { widgetsSelect, isLoading } = useWidgetSelect({ enabled: true, isRecommended: true });
  const { t } = useTranslation();

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    !isEmptyArr(widgetsSelect) && (
      <div className="flex flex-col gap-3">
        <p className={styles.title}>{t("dashboard.Tavsiya etiladigan vidjetlar")}</p>
        <div className="flex flex-col gap-3">
          {widgetsSelect?.map(item => {
            const { key, ...rest } = item;

            return <WidgetCard key={item.id} {...rest} widgetKey={item.key} isCompany={false} />;
          })}
        </div>
      </div>
    )
  );
};

export default RecommendedList;
