import { useMemo } from "react";
import classNames from "classnames";

import { useQueryParams } from "../../../../hooks/useQueryParams";
import ArrowDownIcon from "../../assets/icons/ArrowDownIcon";
import ChevronHorizontalIcon from "../../assets/icons/ChevronHorizontalIcon";
import { SortOrderEnum } from "../../utils/enums/sortOrderEnum";

import styles from "./orderSortBy.module.scss";

type Props = {
  queryParamKey: string;
  title: string;
  classNames?: string;
};

const OrderSortBy = ({ queryParamKey, title, classNames: propsClass }: Props) => {
  const { append, remove, queries } = useQueryParams();

  const queryParamValue = useMemo(() => queries()[queryParamKey], [queries, queryParamKey]) as SortOrderEnum;

  const sortIcon = {
    [SortOrderEnum.DESC]: <ArrowDownIcon rotate={180} />,
    [SortOrderEnum.ASC]: <ArrowDownIcon />
  };

  const onChangeSort = () => {
    if (queryParamValue === SortOrderEnum.DESC) {
      remove(queryParamKey);
    } else if (queryParamValue === SortOrderEnum.ASC) {
      append(queryParamKey, SortOrderEnum.DESC);
    } else {
      append(queryParamKey, SortOrderEnum.ASC);
    }
  };

  return (
    <div
      className={classNames(styles.order_sort_by, queryParamValue, {
        propsClass: Boolean(propsClass)
      })}
      onClick={onChangeSort}
    >
      <span>{title}</span>
      {sortIcon[queryParamValue] || <ChevronHorizontalIcon className="settings_table" />}
    </div>
  );
};

export default OrderSortBy;
