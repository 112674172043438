import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, FormInstance, Select } from "antd";

import { useAppSelector } from "../../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import { selectFilterOption } from "../../../../../app/utils/helpers/selectFilterOption";
import { companyPersonActions } from "../../../../../../store/reducers/companyPersonReducer";
import SelectNotContent from "../../../../../app/components/select-not-content/SelectNotContent";

const { Item } = Form;
const { Option } = Select;

const CompanyPersonSelect: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;
  const [searchValue, setSearchValue] = useState<string>();
  const { visible, inventory } = useAppSelector(
    (state) => state.inventoryReducer.inventoryModalData
  );
  const { data: companyPerson } = useGetCompanyPersonSelect(visible);

  const onAddCompanyPerson = () => {
    dispatch(
      setVisibleModal({
        visible: true,
        initialData: {
          name: searchValue,
          phone: "",
        },
        onAfterFunc: (data: { id: number }) => {
          form.setFieldValue("company_person_id", data?.id);
        },
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  return (
    <Item label={t("MainLayoutLinks.Kontragent")} name="company_person_id">
      <Select
        showSearch
        allowClear
        onSearch={onSearch}
        searchValue={searchValue}
        filterOption={selectFilterOption}
        placeholder={inventory?.id ? t("Inventory.Tanlanmagan") : t("Inventory.Tanlang")}
        suffixIcon={inventory?.id ? null : <SelectSuffixIcon />}
        notFoundContent={
          <SelectNotContent
            title={t("MainLayoutLinks.Kontragent")}
            action={onAddCompanyPerson}
          />
        }
      >
        {companyPerson?.map((item) => (
          <Option
            value={item.id}
            key={item.id}
            props={{
              name: item?.name,
            }}
          >
            {item?.name}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default CompanyPersonSelect;
