import { ColumnConfigModel } from "features/app/utils/models/user/userTableConfigModel";

export const generatePaymentReqColumns = (columns: ColumnConfigModel[]): ColumnConfigModel[] => {
  const updatedColumns = [...columns];
  let i = 0;

  while (i < updatedColumns.length) {
    if (updatedColumns[i].id === "amount") {
      updatedColumns.splice(i + 1, 0, {
        id: "currency.symbol",
        name: "currency.symbol",
        title: "Valyuta",
        checked: true
      });
      updatedColumns?.splice(i + 2, 0, {
        id: "exchange",
        name: "exchange",
        title: "Valyuta kursi",
        checked: true
      });
      i += 1;
    }
    if (updatedColumns[i].id === "user.full_name") {
      updatedColumns[i].id = "user.full_name,financial.name,company_person.name,type";
    }
    i += 1;
  }

  return updatedColumns;
};
