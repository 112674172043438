import React from "react";
import { Button, Form, FormInstance, FormListOperation } from "antd";
import { useTranslation } from "react-i18next";

import PlusCircleIcon from "../../../../../app/assets/icons/PlusCircleIcon";

import Card from "./card";

type Props = {
  form: FormInstance;
};

const { List } = Form;

const Body: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  const onAddPaymentType = (operation: FormListOperation) => () => {
    operation.add({
      id: undefined,
      amount: undefined
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-sm font-medium text-gray-400">{t("payment.Chiqim konvertatsiya ma’lumotlari")}</h3>
      <div className="flex flex-col gap-2">
        <List name={["expense_payment", "payment_types"]}>
          {(fields, operation) => (
            <>
              {fields.map((_, key) => (
                <Card key={key} form={form} index={key} operation={operation} />
              ))}
              <Button
                onClick={onAddPaymentType(operation)}
                className="w-full rounded-2xl border-gray-200 text-sm font-medium text-gray-600"
              >
                <PlusCircleIcon width="24" height="24" />
                {t("payment.To'lov turi")}
              </Button>
            </>
          )}
        </List>
      </div>
    </div>
  );
};

export default Body;
