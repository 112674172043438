import React from "react";
import { Col, Collapse, Row, Spin, Tooltip } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ExpectedTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/task/expected/ExpectedTotalAmount";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import i18n from "i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { isEmptyArr, RU } from "modules/common";
import {
  EstimateStatus,
  estimateStatusIcons,
  estimateStatusNames,
  typeValues,
  useProjectColumnTasks,
  useWidget
} from "modules/dashboard";

import Product from "./product";

import styles from "../project-by-section.module.scss";

const { Panel } = Collapse;

type Props = {
  sectionPlace: number;
};

const Task: React.FC<Props> = ({ sectionPlace }) => {
  const { id, visible, props, methods } = useWidget();
  const { queries } = useQueryParams();
  const { estimate_type } = queries();
  const { tasks, isLoading } = useProjectColumnTasks({ id, enabled: visible, sectionId: props?.sectionId });

  const onChangeCollapse = (key: string | string[]) => {
    if (typeof key === "object") {
      const newKey = key.length ? key[key.length - 1] : undefined;

      methods.setWidgetDrawer({
        props: {
          ...props,
          taskId: newKey || props.taskId,
          taskKeys: key
        }
      });
    }
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <ConditionalRender if={!isEmptyArr(tasks) && !isLoading} else={<TableEmpty />}>
        <Collapse
          accordion
          bordered={false}
          rootClassName={styles.task}
          onChange={onChangeCollapse}
          activeKey={props?.taskKeys || []}
          expandIcon={({ isActive }) => <ChevronDownIcon rotate={isActive} />}
        >
          {tasks?.map(task => (
            <Panel
              header={
                <Row>
                  <Col span={9} className={styles.task__name}>
                    {sectionPlace}.{task.place} {task.name}
                  </Col>
                  <Col span={2} className={styles.task__item}>
                    {task?.start_date}
                  </Col>
                  <Col span={3} />
                  <Col span={3} className={styles.task__item}>
                    {task.quantity}
                  </Col>
                  <Col span={3} className={styles.task__item}>
                    {task.unit?.symbol[i18n.language]}
                  </Col>
                  <Col span={4} className={styles.task__item}>
                    {estimate_type === typeValues.dynamic ? (
                      <div className="flex items-center gap-2">
                        <ExpectedTotalAmount isBordered task={task as never} className="m-0 rounded-lg p-2" />
                        <Tooltip title={estimateStatusNames[task.status as EstimateStatus]}>
                          <div className="flex items-center">{estimateStatusIcons[task.status as EstimateStatus]}</div>
                        </Tooltip>
                      </div>
                    ) : (
                      // ? task?.prediction_total_amount / (task?.quantity || 1)
                      task.amount?.toLocaleString(RU)
                    )}
                  </Col>
                </Row>
              }
              key={task.id}
            >
              <Product />
            </Panel>
          ))}
        </Collapse>
      </ConditionalRender>
    </Spin>
  );
};

export default Task;
