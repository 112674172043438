import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";

import { Table } from "components";

import { useAppSelector } from "../../../../../hooks/redux";
import { appReducerActions } from "../../../../../store/reducers/appReducer";
import { supplyOfferActions } from "../../../../../store/reducers/supplyOfferReducer";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { generalEditKeys } from "../../../../app/utils/constants/generalEditData";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";
import { useDeleteOffer } from "../../../service/mutations";
import { useGetImpostOffers, useGetOffers } from "../../../service/queries";
import { OfferModel } from "../../../utils/models/OfferModel";
import OffersFilter from "../filter/OffersFilter";
import OfferPartyModal from "../offer-party-modal/OfferPartyModal";

import OfferColumns from "./OfferColumns";

import styles from "./offers.module.scss";

type Props = {
  isImpost?: boolean;
};

const Offers: React.FC<Props> = ({ isImpost }) => {
  const dispatch = useDispatch();
  const deleteOffer = useDeleteOffer();

  const { data: supplyData, isLoading: isLoadingSupply } = useGetOffers(!isImpost);
  const { data: impostData, isLoading: isLoadingImpost } = useGetImpostOffers(isImpost);
  const [renderColumns, setColumns] = useState<ColumnsType<OfferModel>>([]);

  const data = useMemo(() => {
    if (isImpost) {
      return {
        ...impostData,
        isLoading: isLoadingImpost
      };
    }
    return {
      ...supplyData,
      isLoading: isLoadingSupply
    };
  }, [isImpost, supplyData, impostData, isLoadingImpost, isLoadingSupply]);

  const { setGeneralEdit } = appReducerActions;
  const { setSelectOffers, setAddItemSelectOffers } = supplyOfferActions;

  const { data: selectOffers, ids: selectOfferIds } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);

  const rowSelection: TableRowSelection<OfferModel> | undefined = {
    type: "checkbox",
    selectedRowKeys: selectOfferIds,
    onSelect: record => {
      const isSomeOffer = selectOffers?.some(item => item?.id === record?.id);

      if (!isSomeOffer) {
        dispatch(setSelectOffers([...selectOffers, record]));
      } else {
        dispatch(setSelectOffers(selectOffers?.filter(item => item?.id !== record?.id)));
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        selectedRows?.forEach(item => {
          dispatch(setAddItemSelectOffers(item));
        });
      } else {
        dispatch(setSelectOffers([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.WAREHOUSE_PRODUCTS,
          customFieldKeys: [CustomFieldLocationEnum.WAREHOUSE_PRODUCT_OFFER]
        })
      );
    }
  };

  const title = () => (
    <div className={styles.title}>
      <div className={styles.title__top}>
        <div className={styles.left} />
        <div className={styles.right}>
          <div className={styles.search}>
            <PaymentSearch />
          </div>
          <OffersFilter />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.offers}>
      <Table
        title={title}
        onChangeColumns={OfferColumns({ isImpost, renderColumns, setColumns }).onChangeColumns}
        pagination={false}
        dataSource={data?.data}
        rowKey={record => record?.id}
        rowSelection={isImpost ? undefined : rowSelection}
        columns={OfferColumns({ isImpost, renderColumns, setColumns }).columns as ColumnsType<OfferModel>}
        loading={{
          spinning: data?.isLoading || deleteOffer.isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        tableLayout="fixed"
        scroll={{ y: "65vh" }}
      />
      <div className={styles.offers__bottom}>
        <Pagination
          paginationProps={{
            total: data?.total
          }}
          configKey={tableConfigKeys.WAREHOUSE_PRODUCT_OFFER_CONFIG}
        />
      </div>
      <OfferPartyModal />
    </div>
  );
};

export default Offers;
