import { Spin } from "antd";
import { Suspense, lazy, useState } from "react";

import GanttVerticalLine from "./components/gantt-vertical-line/GanttVerticalLine";
import { GanttContext, GanttContextDataState } from "./hooks/GanttContext";
import { useGanttSection } from "./services/queries";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
const GanttChart = lazy(() => import("./components/gantt-chart/GanttChart"));
const GanttTable = lazy(() => import("./components/gantt-table/GanttTable"));

import styles from "./projectGantt.module.scss";

export const ProjectGantt = () => {
  const [ganttContext, setGanttContext] = useState<GanttContextDataState>({
    sequence: null,
    sequenceSectionId: null,
  });
  const setContext = (value: GanttContextDataState) => {
    setGanttContext((prev) => ({ ...prev, ...value }));
  };
  const { data } = useGanttSection();

  return (
    <GanttContext.Provider
      value={{
        ...ganttContext,
        setGanttContext: setContext,
      }}
    >
      <div className={styles.container} id="gantt">
        <Suspense fallback={<Spin indicator={LoadingIndicator} />}>
          <GanttTable data={data} />
        </Suspense>
        <GanttVerticalLine />
        <Suspense fallback={<Spin indicator={LoadingIndicator} />}>
          <GanttChart sections={data?.sections} />
        </Suspense>
      </div>
    </GanttContext.Provider>
  );
};
