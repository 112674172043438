export const onSetStatusQuantity = (quantity: number, spendTotalQuantity: number) => {
  if (spendTotalQuantity > quantity) {
    return "error";
  }
  if (spendTotalQuantity < quantity) {
    return "success";
  }
  return "";
};

export const onSetStatusBorderQuantity = (quantity: number, spendTotalQuantity: number) => {
  if (spendTotalQuantity > quantity) {
    return "error_border";
  }
  if (spendTotalQuantity < quantity) {
    return "success_border";
  }
  return "";
};
