import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { FileType } from "features/app/utils/enums/fileType";
import { downloadFile } from "features/app/utils/helpers/downloadFile";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import $api from "../../app/utils/helpers/axiosInstance";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { paymentQueryKeys } from "../../payment/utils/constants/paymentQueryKeys";
import { impostEndPoints } from "../utils/constants/impostEndPoints";
import { impostQueryKeys } from "../utils/constants/impostQueryKeys";
import { ImpostConfirmationBodyModel } from "../utils/models/impostConfirmationBodyModel";
import { PartRecievedBodyModel } from "../utils/models/partRecievedBodyModel";

export function useImpostWorkRecieved() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ImpostConfirmationBodyModel>(
    async data => {
      const res = await $api.put(impostEndPoints.WORKS_RECIEVED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.WORKS]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostWorkRejected() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.put(impostEndPoints.WORKS_REJECTED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.WORKS]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostInventoryRecieved() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ImpostConfirmationBodyModel>(
    async data => {
      const res = await $api.put(impostEndPoints.INVENTORIES_RECIEVED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.INVENTORIES]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostInventoryRejected() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.put(impostEndPoints.INVETORIES_REJECTED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.INVENTORIES]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostOrderRecieved() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PartRecievedBodyModel>(
    async data => {
      const res = await $api.put(impostEndPoints.PARTS_RECIEVED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.PARTS]);
        qc.invalidateQueries([impostQueryKeys.PARTS_DETAIL]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostOrderConfirmation() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.put(impostEndPoints.PARTS_RECIEVED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.PARTS]);
        qc.invalidateQueries([impostQueryKeys.PARTS_DETAIL]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostOrderRejected() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.put(impostEndPoints.PARTS_REJECTED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.PARTS]);
        qc.invalidateQueries([impostQueryKeys.PARTS_DETAIL]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostPaymentsRecieved() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, ImpostConfirmationBodyModel>(
    async data => {
      const res = await $api.put(impostEndPoints.PAYMENTS_RECIEVED, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([impostQueryKeys.PAYMENTS]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useImpostPaymentsRejected() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number }>(
    async data => {
      const res = await $api.put(impostEndPoints.PAYMENTS_REJECTED, data);

      return res.data;
    },
    {
      onSuccess: data => {
        qc.invalidateQueries([impostQueryKeys.PAYMENTS]);
        qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        qc.invalidateQueries([impostQueryKeys.IMPOST_ALL_COUNTS]);
        qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}
export function useExportMonitoringPaymentExcel() {
  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${impostEndPoints.MONITORING_PAYMENT_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Monitoring-payments", FileType.XLSX);
      }
    }
  );
}
export function useExportMonitoringPartsExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(
        `${impostEndPoints.MONITORING_PARTS_EXCEL_EXPORT}`,
        { ...data },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Monitoring-parts", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
export function useExportMonitoringWorksExcel() {
  const { searchParams, reqQueryParam } = useQueryParams();

  return useMutation(
    async (data: IExportExcelParam) => {
      let url = "";

      if (searchParams) {
        url += `?${reqQueryParam(queryParamsKeys.PROJECT_IDS_ARR, queryParamsKeys.CONFIRMATION_PAYMENTS_ARR, queryParamsKeys.USER_IDS_ARR, queryParamsKeys.CURRENCY_ID, queryParamsKeys.MIN_AMOUNT, queryParamsKeys.MAX_AMOUNT, queryParamsKeys.SEARCH)}`;
      }
      const res = await $api.post(
        `${impostEndPoints.MONITORING_WORKS_EXCEL_EXPORT}`,
        { ...data, ...queryString.parse(url) },
        { responseType: "blob" }
      );

      return res;
    },
    {
      onSuccess: (response, variables, context) => {
        const data = response?.data;

        downloadFile(data, "Monitoring-works", FileType.XLSX);
      }
    }
  );
}

export function useDeletePart() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.delete(`${impostEndPoints.DELETE_ORDER}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries([impostQueryKeys.PARTS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}
