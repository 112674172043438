import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const laboratoryTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "id",
      name: "id",
      title: "Laboratoriya",
      checked: true
    },
    {
      id: "date",
      name: "date",
      title: "Sana",
      checked: true
    },
    {
      id: "warehouse.name",
      name: "warehouse.name",
      title: "Omborxona",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "description",
      name: "description",
      title: "Izoh",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    }
  ],
  size: 10
};
