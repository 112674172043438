import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { TransferBodyModel } from "features/warehouse/utils/models/transferBodyModel";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { useQueryParams } from "../../../hooks/useQueryParams";
import { rootPaths } from "../../../routes/root/rootPaths";
import { queryParamsKeys } from "../../app/utils/constants/queryParamsKeys";
import { FileType } from "../../app/utils/enums/fileType";
import $api from "../../app/utils/helpers/axiosInstance";
import { downloadFile } from "../../app/utils/helpers/downloadFile";
import { errorHandler } from "../../app/utils/helpers/errorHandler";
import { parseParamsId } from "../../app/utils/helpers/parseParamsId";
import { IExportExcelParam } from "../../app/utils/models/IExportExcelParam";
import { ErrorRes, SuccessRes } from "../../app/utils/models/responseType";
import { counterpartsQueryKeys } from "../../counterparts/utils/constants/counterpartsQueryKeys";
import { warehouseEndPoints } from "../../warehouse/utils/constants/warehouseEndPoints";
import { OneOrderModel } from "../../warehouse/utils/models/oneOrderModel";
import { paymentEndPoints } from "../utils/constants/paymentEndPoints";
import { paymentQueryKeys } from "../utils/constants/paymentQueryKeys";
import { PaymentTabKeys } from "../utils/constants/paymentTabKeys";
import { ExpectedPaymentBodyModel } from "../utils/models/expectedPaymentBodyModel";
import { PayBodyModel } from "../utils/models/payBodyModel";
import { PaymentReqBodyModel } from "../utils/models/paymentReqBodyModel";
import { PaymentUpdateReqBodyModel } from "../utils/models/paymentUpdateReqBodyModel";
import { ReportPaymentModel } from "../utils/models/ReportPaymentModel";

export function useDeletePayment() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { selectIncomeExpense } = useAppSelector(state => state.paymentReducer);
  const dispatch = useDispatch();
  const { setSelectIncomeExpense } = paymentReducerActions;

  return useMutation<SuccessRes, ErrorRes, { id?: number; description?: string }>(
    async ({ id, description }) => {
      const res = await $api.delete(`${paymentEndPoints.DELETE_PAYMENT}?id=${id}&description=${description}`);

      return res.data;
    },
    {
      onSuccess: async (data, { id }) => {
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.INCOME_EXPENSE_DELETE_ARCHIVE]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        await qc.invalidateQueries([counterpartsQueryKeys.PAYMENT]);
        await qc.invalidateQueries([counterpartsQueryKeys.STATISTICS]);

        if (selectIncomeExpense?.length > 0) {
          const isSomeParty = selectIncomeExpense?.some(item => item?.id === id);

          if (isSomeParty) {
            dispatch(setSelectIncomeExpense(selectIncomeExpense?.filter(item => item?.id !== id)));
          }
        }

        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useCreatePayment() {
  const qc = useQueryClient();
  const params = useParams();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { severalSearchParams } = useQueryParams();
  const isCompanyPerson = pathname.includes(rootPaths.DETAILED_COUNTERPART);
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  const companyPersonStatisticsSearchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  const calendarSearchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);
  const statisticsSearchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY);

  return useMutation<SuccessRes, ErrorRes, PaymentReqBodyModel>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_WORK_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_INVENTORY_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.CALENDAR, calendarSearchParams]);

        if (isCompanyPerson) {
          const { id } = parseParamsId(params?.second_id);

          await qc.invalidateQueries([counterpartsQueryKeys.ORDER, id, searchParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.PAYMENT, statisticsSearchParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.CALENDAR_VIEW, calendarSearchParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.STATISTICS, id, companyPersonStatisticsSearchParams]);
        }

        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useUpdatePayment() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PaymentUpdateReqBodyModel>(
    async data => {
      const res = await $api.put(paymentEndPoints.UPDATE_PAYMENT, data);

      return res.data;
    },
    {
      onSuccess: async data => {
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENTS_ORDER_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_ORDER_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_WORK_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_INVENTORY_VIEW]);
        await qc.invalidateQueries([counterpartsQueryKeys.PAYMENT]);
        message.success(data.message[i18n.language]);
      },
      onError: errorHandler
    }
  );
}

export function useCreateExpectedPayment(isCounterParts?: boolean) {
  const qc = useQueryClient();
  const params = useParams();
  const { i18n } = useTranslation();
  const { searchParams, queries, severalSearchParams } = useQueryParams();
  const { payment_type: paymentType } = queries();
  const isCalendarPage = searchParams?.includes("calendar");
  const companyPersonStatisticsSearchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  const severalParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);

  return useMutation<SuccessRes, ErrorRes, ExpectedPaymentBodyModel>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_EXPECTED_PAYMENT, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);

        if (isCounterParts) {
          const { id } = parseParamsId(params?.second_id);

          await qc.invalidateQueries([counterpartsQueryKeys.STATISTICS, id, companyPersonStatisticsSearchParams]);

          if (paymentType === "template") {
            await qc.invalidateQueries([counterpartsQueryKeys.EXPECTED_TEMPLATE_PAYMENT]);
          } else {
            await qc.invalidateQueries([counterpartsQueryKeys.EXPECTED_PAYMENT]);
          }
        } else {
          await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
          await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
          await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE]);
          await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENTS, searchParams]);
        }

        if (isCalendarPage) {
          await qc.invalidateQueries([paymentQueryKeys.CALENDAR, severalParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.CALENDAR_VIEW, severalParams], { type: "active" });
        }
      }
    }
  );
}

export function useDeleteExpectedPayment() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number; description?: string }>(
    async ({ id, description }) => {
      const res = await $api.delete(`${paymentEndPoints.DELETE_EXPECTED_PAYMENT}?id=${id}&description=${description}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: data => {
        message.success(data.message[i18n.language]);
        qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENTS]);
        qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
      }
    }
  );
}

export function useCreatePayTemplate(isCompanyPersonPage?: boolean) {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, PayBodyModel>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_PAY_TEMPLATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
      }
    }
  );
}

export function useCreatePay() {
  const params = useParams();
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { queries, searchParams, severalSearchParams } = useQueryParams();
  const isCompanyPerson = pathname?.includes(rootPaths.DETAILED_COUNTERPART);
  const { statistics_type: type } = queries();
  const companyPersonSearchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE);
  const companyPersonStatisticsSearchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);
  const { page, minDate, maxDate, tabKey } = useAppSelector(state => state.paymentReducer.calendarHistory);
  const calendarSearchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.FULLSCREEN);

  return useMutation<SuccessRes, ErrorRes, PayBodyModel>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_PAY, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENTS, searchParams]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW, searchParams]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, searchParams]);

        if (type === PaymentTabKeys.CALENDAR) {
          await qc.invalidateQueries([paymentQueryKeys.CALENDAR, calendarSearchParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.CALENDAR_VIEW, calendarSearchParams]);
        }

        if (isCompanyPerson) {
          const { id } = parseParamsId(params?.second_id);

          await qc.invalidateQueries([counterpartsQueryKeys.EXPECTED_PAYMENT, id, companyPersonSearchParams]);
          await qc.invalidateQueries([counterpartsQueryKeys.STATISTICS, id, companyPersonStatisticsSearchParams]);
        }

        if (minDate && maxDate) {
          await qc.invalidateQueries([paymentQueryKeys.CALENDAR_HISTORY_STATISTICS, minDate, maxDate, tabKey]);
          await qc.invalidateQueries([paymentQueryKeys.CALENDAR_HISTORY_STATISTICS, minDate, maxDate, tabKey]);
          await qc.invalidateQueries([
            paymentQueryKeys.CALENDAR_HISTORY_PAYMENT,
            page,
            minDate,
            maxDate,
            severalSearchParams(
              queryParamsKeys.STATISTICS_TYPE,
              queryParamsKeys.FILTER_TYPE,
              queryParamsKeys.VIEW_TYPE,
              queryParamsKeys.PAGE
            )
          ]);
        }
      }
    }
  );
}

export function useDeleteExpectedPaymentTemplate() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id?: number; description?: string }>(
    async ({ id, description }) => {
      const res = await $api.delete(
        `${paymentEndPoints.DELETE_EXPECTED_PAYMENT_TEMPLATE}?id=${id}&description=${description}`
      );

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS]);
        await qc.invalidateQueries([paymentQueryKeys.EXPECTED_PAYMENT_TEMPLATE_DELETED]);
      }
    }
  );
}

export function useCreateTransfer() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);

  return useMutation<SuccessRes, ErrorRes, TransferBodyModel>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_TRANSFER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, searchParams]);
      }
    }
  );
}

export function useCheckTransfer() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id: number; status: string }>(
    async data => {
      const res = await $api.post(paymentEndPoints.TRANSFER_CHECK, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.TRANSFER_VIEW]);
      }
    }
  );
}

export function useCreateExchange() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.CHECK_CURRENCY);

  return useMutation<SuccessRes, ErrorRes, any>(
    async data => {
      const res = await $api.post(paymentEndPoints.CREATE_EXCHANGE, data);

      return res.data;
    },
    {
      retry: false,
      onSuccess: async data => {
        message.success(data.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_STATISTICS, searchParams]);
      },
      onError: errorHandler
    }
  );
}

export function usePaymentOrderExportExcel() {
  const { i18n } = useTranslation();
  const { searchParams, reqQueryParam } = useQueryParams();

  return useMutation(
    async (data: IExportExcelParam) => {
      let url = "";

      if (searchParams) {
        url += `?${reqQueryParam(
          queryParamsKeys.PAGE,
          queryParamsKeys.SEARCH,
          queryParamsKeys.MIN_DATE,
          queryParamsKeys.MAX_DATE,
          queryParamsKeys.USER_IDS_ARR,
          queryParamsKeys.COMPANY_PERSON_IDS_ARR,
          queryParamsKeys.STATUSES_ARR,
          queryParamsKeys.MIN_AMOUNT,
          queryParamsKeys.MAX_AMOUNT,
          queryParamsKeys.PAYMENT_STATUSES_ARR,
          queryParamsKeys.CONFIRMATION_PAYMENTS_ARR,
          queryParamsKeys.SORT_PAYMENT_DATE,
          queryParamsKeys.SORT_COMPANY_PERSON_NAME,
          queryParamsKeys.SORT_AGENT_FULL_NAME,
          queryParamsKeys.SORT_AMOUNT,
          queryParamsKeys.SORT,
          queryParamsKeys.SORT_PAYMENT_PERCENT,
          queryParamsKeys.SORT_PERCENT
        )}`;
      }
      const res = await $api.post(
        paymentEndPoints.PAYMENT_ORDER_EXPORT_EXCEL,
        { ...data, ...queryString.parse(url) },
        { responseType: "blob" }
      );

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Kassa-partiyasi", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useGetOnePaymentOrder() {
  return useMutation<OneOrderModel[], ErrorRes, number>(async orderId => {
    const res = await $api.get(`${warehouseEndPoints.ORDERS_VIEW}?order_id=${orderId}`);

    return res.data?.data;
  });
}

export function usePaymentGeneralEditing() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();
  const { severalSearchParams } = useQueryParams();
  const searchParams = severalSearchParams(queryParamsKeys.STATISTICS_TYPE, queryParamsKeys.CHECK_CURRENCY);

  return useMutation<SuccessRes, ErrorRes, Record<string, unknown[]>>(
    async data => {
      const res = await $api.put(paymentEndPoints.PAYMENT_GENERAL_UPDATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        message.success(res.message[i18n.language]);
        await qc.invalidateQueries([paymentQueryKeys.PAYMENT_VIEW, searchParams]);
      }
    }
  );
}

export function useReportExportExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: ReportPaymentModel) => {
      const res = await $api.post(paymentEndPoints.REPORT_EXPORT_EXCEL, data, { responseType: "blob" });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Kassa-hisobot", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useExportPaymentExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(paymentEndPoints.EXPORT_PAYMENT_EXCEL, data, { responseType: "blob" });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Kirim-chiqimlar", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}

export function useExportPaymentWorksExcel() {
  const { i18n } = useTranslation();

  return useMutation(
    async (data: Record<string, any>) => {
      const res = await $api.post(paymentEndPoints.EXPORT_PAYMENT_WORKS_EXCEL, data, { responseType: "blob" });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        downloadFile(data, "Ishlar", FileType.XLSX);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
export function useDeleteDeletedArchive() {
  const { i18n } = useTranslation();
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async id => {
      const res = await $api.delete(paymentEndPoints.DELETE_DELETED_ARCHIVE, { data: { id } });

      return res.data;
    },
    {
      retry: false,
      onSuccess: data => {
        qc.invalidateQueries([paymentQueryKeys.INCOME_EXPENSE_DELETE_ARCHIVE]);
        message.success(data.message[i18n.language]);
      }
    }
  );
}
