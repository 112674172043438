import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import { useDeleteInventory } from "../../../../service/mutations";

import styles from "./deleteInventory.module.scss";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onClose: () => void;
  id?: number;
};

const DeleteInventory = ({ open, setOpen, onClose, id }: Props) => {
  const { t } = useTranslation();
  const deleteInventory = useDeleteInventory();

  const onDelete = () => {
    deleteInventory.mutateAsync(id).then(() => {
      onCancel();
      onClose();
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      centered
      open={open}
      onCancel={onCancel}
      closeIcon={false}
      title={false}
      footer={false}
      width={400}
      okButtonProps={{
        loading: deleteInventory.isLoading,
      }}
      className={styles.delete_inventory}
    >
      <div className={styles.top}>
        <div className={styles.delete}>
          <div className={styles.delete__inner}>
            <DeleteIcon />
          </div>
        </div>
        <div className={styles.close} onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles.content}>
        <h3>{t("Inventar.Mexanizmni o’chirish")}</h3>
        <p>
          {t(
            "Inventar.Mexanizmni o’chirishdan so’ng uni qayta tiklash imkoni mavjud bo’lmaydi"
          )}
        </p>
      </div>
      <div className={styles.footer}>
        <Button className={styles.cancel} onClick={onCancel}>
          {t("Xodimlar.Bekor qilish")}
        </Button>
        <Button
          className={styles.submit}
          onClick={onDelete}
          loading={deleteInventory.isLoading}
        >
          {t("Xodimlar.O'chirish")}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteInventory;
