import React from "react";
import { Tabs } from "antd";
import dayjs from "dayjs";
import ConversionInfoModal from "features/app/components/conversion-info-modal/ConversionInfoModal";
import TransferHistoryModal from "features/app/components/transfer-history-modal/TransferHistoryModal";
import { dayjsFormats } from "features/app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tabKeys } from "features/impost/utils/constants/tabKeys";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { LineData, useExpensePaymentByMonth, useWidget } from "modules/dashboard";

import TabContent from "./tab-content/tab-content";

import styles from "./expense-payment-by-month.module.scss";

const ExpensePaymentByMonth: React.FC = () => {
  const { queries, append } = useQueryParams();
  const { tab } = queries();
  const { id, visible, props } = useWidget();
  const comingWidgetData = { ...props } as LineData;
  const [month, year] = props ? props?.date?.split(".") : "";
  const firstDay = dayjs(`${year}-${month}-01`).startOf("month").format(dayjsFormats.DATE);
  const lastDay = dayjs(`${year}-${month}-01`).endOf("month").format(dayjsFormats.DATE);
  const { t } = useTranslation();

  const { paymentLineViewData, isLoading } = useExpensePaymentByMonth({
    id,
    enabled: visible,
    min_date: firstDay,
    max_date: lastDay
  });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = props
    ? comingWidgetData?.values?.map(el => ({
        key: String(el.id),
        label: <p className={styles.tab}>{el.name as string}</p>,
        children: <TabContent data={paymentLineViewData} isLoading={isLoading} />
      }))
    : [];

  const itemsWithAllTab = [
    {
      key: tabKeys.ALL,
      label: <p className={styles.tab}>{t("dashboard.Barchasi")}</p>,
      children: <TabContent data={paymentLineViewData} isLoading={isLoading} />
    },
    ...items
  ];

  return (
    <div className={styles.bar}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab} items={itemsWithAllTab} onChange={onChangeTabs} />
      </div>

      <IncomeExpenseView />
      <TransferHistoryModal />
      <ConversionInfoModal />
    </div>
  );
};

export default ExpensePaymentByMonth;
