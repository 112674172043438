import { DataNode } from "antd/es/tree";
import { useTranslation } from "react-i18next";
import { Form, Input, Modal, Radio, Switch } from "antd";
import React, { useEffect, useMemo } from "react";

import { useCreatePaymentType, useUpdatePaymentType } from "../../../../service/mutations";
import { Tree } from "../../../../../app/components/tree/Tree";
import { Status } from "../../../../../app/utils/models/status";
import CheckBoxIcon from "../../../../assets/icons/CheckBoxIcon";
import { useGetOnePaymentType } from "../../../../service/queries";
import { useGetCashTree } from "../../../../../app/service/queries";
import { formRules } from "../../../../../app/utils/constants/formRules";
import { parseTreeValue } from "../../../../../app/utils/helpers/parseTreeValue";
import { CashTreeModel } from "../../../../../app/utils/models/cash/cashTreeModel";
import { PaymentTypeBodyModel } from "../../../../utils/models/payment-type/paymentTypeBodyMdel";
import { PaymentTypeFormModel } from "../../../../utils/models/payment-type/paymentTypeFormModel";
import { PaymentTypeModalDataModel } from "../../../../utils/models/payment-type/paymentTypeModalDataModel";
import { PaymentTypeParsedTreeModel } from "../../../../utils/models/payment-type/paymentTypeParsedTreeModel";
import { colors as baseColors } from "features/app/utils/constants/colors";

import styles from "./settingsPaymentType.module.scss";

type Props = {
  modalData: PaymentTypeModalDataModel;
  setModalData: React.Dispatch<React.SetStateAction<PaymentTypeModalDataModel>>;
};

const { Item } = Form;

const PaymentTypeModal: React.FC<Props> = ({ modalData, setModalData }) => {
  const { t } = useTranslation();
  const createPaymentType = useCreatePaymentType();
  const updatePaymentType = useUpdatePaymentType();
  const [form] = Form.useForm<PaymentTypeFormModel>();
  const { data: cash } = useGetCashTree(modalData?.visible);
  const { data: onePaymentType } = useGetOnePaymentType(modalData.id);

  const colors: string[] = [
    baseColors.ERROR_500,
    baseColors.WARNING_500,
    baseColors.SUCCESS_500,
    baseColors.PRIMARY_500,
    baseColors.GRAY_500,
    "#7A5AF8",
    "#EE46BC",
    "#F63D68",
    "#15B79E",
    "#4E5BA6"
  ];

  const isUpdating = useMemo(() => Boolean(onePaymentType), [onePaymentType]);

  // initial form fields
  useEffect(() => {
    if (isUpdating) {
      form.setFieldsValue({
        name: onePaymentType?.name,
        color: onePaymentType?.color,
        status: onePaymentType?.status === Status.ACTIVE
      });
    }
  }, [isUpdating]);

  const onCancel = () => {
    setModalData({
      visible: false
    });

    form.resetFields();
  };

  const onFinish = (values: PaymentTypeFormModel) => {
    const parsedTree: PaymentTypeParsedTreeModel = {
      folder_ids: [],
      cash_ids: []
    };
    const req: PaymentTypeBodyModel = {
      ...values,
      ...parseTreeValue<PaymentTypeParsedTreeModel>(values.cash ?? [], parsedTree),
      status: values.status ? Status.ACTIVE : Status.PASSIVE
    };

    delete req?.cash;
    delete req?.folder_ids;

    if (isUpdating) {
      updatePaymentType.mutateAsync({ ...req, id: modalData.id }).then(onCancel);
    } else {
      createPaymentType.mutateAsync(req).then(onCancel);
    }
  };

  const onOk = () => {
    form.submit();
  };

  const treeData: (cash: CashTreeModel | undefined) => DataNode[] = cash => {
    return (cash?.cash_folders ?? [])
      .map(folder => ({
        title: folder.name,
        key: JSON.stringify({
          value: folder.id,
          key: "folder_ids"
        }),
        children: folder.cashs.map(cash => ({
          title: cash.name,
          key: JSON.stringify({
            value: cash.id,
            key: "cash_ids"
          })
        }))
      }))
      .concat(
        (cash?.cashs ?? []).map(cash => ({
          title: cash.name,
          key: JSON.stringify({
            value: cash.id,
            key: "cash_ids"
          }),
          children: []
        }))
      );
  };

  return (
    <Modal
      title={t("Moliya.To'lov turi")}
      okText={t("products.Saqlash")}
      cancelText={t("products.Yopish")}
      okButtonProps={{
        loading: createPaymentType.isLoading || updatePaymentType.isLoading
      }}
      className={styles.modal}
      open={modalData?.visible}
      onCancel={onCancel}
      onOk={onOk}
      centered
    >
      <Form layout="vertical" form={form} onFinish={onFinish} className={styles.modal__form}>
        <div className={styles.top}>
          <Item name="name" label={t("products.Nomi")} rules={formRules()}>
            <Input placeholder={t("products.Nomini kiriting")} />
          </Item>
          <div className={styles.color}>
            <label className={styles.color__label}>{t("Moliya.Rangi")}</label>
            <Item name="color">
              <Radio.Group>
                {colors.map((color, index) => (
                  <Radio.Button
                    value={color}
                    key={index}
                    style={{
                      borderColor: color,
                      color: color
                    }}
                  >
                    <div className="check">
                      <span>A</span>
                      <CheckBoxIcon color={color} />
                    </div>
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Item>
          </div>
          <Item name="status" initialValue={true} valuePropName="checked" label={t("Xodimlar.Joriy holati")}>
            <Switch />
          </Item>
        </div>
        <Tree
          formInstance={form}
          data={treeData(cash)}
          initialData={treeData({
            cash_folders: [],
            cashs: onePaymentType?.cashs ?? []
          })}
          title={t("Moliya.Barcha kassalar")}
          name="cash"
        />
      </Form>
    </Modal>
  );
};

export default PaymentTypeModal;
