import React from "react";

export interface IProps {
  className: string;
}

const ChevronHorizontalIcon: React.FC<IProps> = ({ className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.66675 10.0002L8.00008 13.3335L11.3334 10.0002M4.66675 6.00016L8.00008 2.66683L11.3334 6.00016"
      stroke="#475467"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronHorizontalIcon;
