import { UploadFile } from "antd/lib";
import React, { useState } from "react";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import { UploadChangeParam } from "antd/es/upload";
import { Progress, Spin, Upload, message } from "antd";

import UploadIcon from "../../../../app/assets/icons/UploadIcon";
import { useUploadFile } from "../../../../app/service/mutation";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import { sliceText } from "../../../../app/utils/helpers/sliceText";
import EllipseFileIcon from "../../../../app/assets/icons/EllipseFileIcon";
import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";

import styles from "../generalEditing.module.scss";

type Props = {
  item: CustomFieldSelectModel;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

type FileType = {
  id?: number;
  file: RcFile | undefined;
};

const { Dragger } = Upload;

const GeneralEditingFieldFile: React.FC<Props> = ({ item: propsField, setData }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<FileType>();
  const uploadFile = useUploadFile();

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const image = e?.file?.originFileObj;
      const formData = new FormData();

      formData.append("files[]", image as Blob);

      uploadFile.mutateAsync(formData).then((res) => {
        setFile({ id: res[0], file: image! });

        setData(
          (old) =>
            old?.map((item) => ({
              // @ts-ignore
              ...item,
              // @ts-ignore
              custom_field_values: item?.custom_field_values?.map((field) => ({
                ...field,
                value:
                  field?.custom_field_id === propsField?.id ? res[0] : field?.value,
              })),
            }))
        );
      });
    }
  };

  const onDeleteFile = () => {
    setFile({
      id: undefined,
      file: undefined,
    });
  };

  return (
    <React.Fragment>
      <Dragger
        name="images"
        beforeUpload={() => {
          setFile(undefined);
        }}
        onChange={onChange}
        showUploadList={false}
        className={styles.upload}
        customRequest={() => null}
        style={{ marginBottom: "0.75rem" }}
        fileList={file?.file ? [file.file] : []}
        accept=".jpg, .jpeg, .png, .svg, .cv, .docx, .doc, .pdf, .xlsx, .xls, .vsdx, .pptx, .ppt, .vsd"
      >
        <div className={styles.upload__info}>
          <UploadIcon />
          <p>
            <span>{t("products.Yuklash uchun bosing")}</span>{" "}
            {t("Inventar.yoki faylni surib keling")}. ({" "}
            {t("Inventar.fayl hajmi 5120 kb dan yuqori bo'lmasligi zarur")} )
          </p>
        </div>
      </Dragger>
      <Spin
        indicator={LoadingIndicator}
        style={{ marginTop: "0.75rem" }}
        spinning={uploadFile.isLoading}
      >
        {uploadFile.isLoading && <span>{t("Inventar.Yuklanmoqda")}</span>}
        <ConditionalRender if={file && Boolean(file?.file)}>
          <div className={styles.upload__files}>
            <div className={styles.file} key={propsField.id}>
              <div>
                <EllipseFileIcon />
              </div>
              <div className={styles.file__right}>
                <div className={styles.name}>
                  <p>
                    {sliceText(file?.file?.name || "", 45)}
                    <span>{((file?.file?.size || 1) / 1000000).toFixed(2)} MB</span>
                  </p>
                  <div className={styles.delete} onClick={onDeleteFile}>
                    <DeleteIcon />
                  </div>
                </div>
                <Progress status="active" percent={100} />
              </div>
            </div>
          </div>
        </ConditionalRender>
      </Spin>
    </React.Fragment>
  );
};

export default GeneralEditingFieldFile;
