import React, { useState } from "react";
import { Breadcrumb, Button, Drawer, Input, Tabs, TabsProps } from "antd";

import {
  useGetCategoriesDev,
  useGetCategoryProductsDev,
  useGetSubCategoriesDev,
  useGetSubCategoryProductsDev,
} from "../../../service/queries";
import { useImportProducts } from "../../../service/mutations";
import CloseIcon from "../../../../app/assets/icons/CloseIcon";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";
import SmallFolderIcon from "../../../assets/icons/SmallFolderIcon";
import SettingsProductsTemplateTable from "./table/SettingsProductsTemplateTable";
import SettingsProductsTemplateFolders from "./folders/SettingsProductsTemplateFolders";
import BreadcrumbSeparatorIcon from "../../../../app/assets/icons/BreadcrumbSeparatorIcon";
import SettingsProductsTemplateDetailedFolder from "./detailed-folder/SettingsProductsTemplateDetailedFolder";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../../../app/assets/icons/SearchIcon";
import { TimeoutModel } from "../../../../app/utils/models/TimeoutModel";

import styles from "./settingsProductsTemplate.module.scss";

type Props = {
  setVisibleDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  visibleDrawer: boolean;
};

const SettingsProductsTemplate: React.FC<Props> = ({
  setVisibleDrawer,
  visibleDrawer,
}) => {
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState<string>("1");
  const [searchValue, setSearchValue] = useState<string>();

  const [time, setTime] = useState<TimeoutModel>();

  const [folderId, setFolderId] = useState<{
    category_id?: number;
    sub_category_id?: number;
  }>({
    category_id: undefined,
    sub_category_id: undefined,
  });

  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItemType[]>([
    {
      title: <SmallFolderIcon />,
      onClick: () => {
        setTabKey("1");
        setBreadcrumbItems((value) => [value[0]]);
      },
    },
  ]);

  const importProducts = useImportProducts();

  const { data: categories, isLoading: isLoadingCategories } = useGetCategoriesDev(
    visibleDrawer,
    tabKey === "1" ? searchValue : undefined
  );
  const { data: subCategories, isLoading: isLoadingSubCategories } =
    useGetSubCategoriesDev(folderId.category_id);
  const { data: categoryProducts, isLoading: isLoadingCategoryProducts } =
    useGetCategoryProductsDev(
      folderId.category_id,
      tabKey === "2" ? searchValue : undefined
    );
  const { data: subCategoryProducts, isLoading: isLoadingSubCategoryProducts } =
    useGetSubCategoryProductsDev(
      folderId?.sub_category_id,
      tabKey === "3" ? searchValue : undefined
    );

  const onClose = () => {
    setVisibleDrawer(false);
    setTabKey("1");
    setFolderId({
      category_id: undefined,
      sub_category_id: undefined,
    });
    setBreadcrumbItems([
      {
        title: <SmallFolderIcon />,
        onClick: () => {
          setTabKey("1");
          setBreadcrumbItems((value) => [value[0]]);
        },
      },
    ]);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "",
      children: (
        <SettingsProductsTemplateFolders
          data={categories}
          isCategory={true}
          setTabKey={setTabKey}
          setFolderId={setFolderId}
          isLoading={isLoadingCategories}
          setBreadcrumbItems={setBreadcrumbItems}
        />
      ),
    },
    {
      key: "2",
      label: "",
      children: (
        <SettingsProductsTemplateDetailedFolder
          data={subCategories}
          setTabKey={setTabKey}
          setFolderId={setFolderId}
          products={categoryProducts}
          isLoading={isLoadingSubCategories}
          setBreadcrumbItems={setBreadcrumbItems}
          isLoadingProducts={isLoadingCategoryProducts}
        />
      ),
    },
    {
      key: "3",
      label: "",
      children: (
        <SettingsProductsTemplateTable
          data={subCategoryProducts}
          isLoading={isLoadingSubCategoryProducts}
        />
      ),
    },
  ];

  const onImportProducts = () => {
    importProducts.mutate({ id: folderId.category_id });
  };

  const drawerFooter = tabKey !== "1" && (
    <Button
      type="primary"
      loading={importProducts.isLoading}
      onClick={onImportProducts}
    >
      {t("products.Yuklash")}
    </Button>
  );

  const onSearch = (value: string) => {
    clearTimeout(time);
    if (value) {
      setTime(
        setTimeout(() => {
          setSearchValue(value);
        }, 800)
      );
    } else {
      setSearchValue("");
    }
  };

  return (
    <div className={styles.template}>
      <Drawer
        title={t("products.Shablon qo'shish")}
        placement="right"
        onClose={onClose}
        open={visibleDrawer}
        styles={{ wrapper: { width: "68.8rem" } }}
        closeIcon={<CloseIcon />}
        footer={drawerFooter}
      >
        <div className={styles.template__top}>
          <Breadcrumb
            items={breadcrumbItems}
            separator={<BreadcrumbSeparatorIcon />}
          />
          <div>
            <Input
              className={styles.search_input}
              onChange={(e) => onSearch(e.target.value)}
              prefix={<SearchIcon />}
              placeholder="Qidiruv"
            />
          </div>
        </div>
        <div className={styles.template__bottom}>
          <Tabs
            activeKey={tabKey}
            items={items}
            tabBarStyle={{
              display: "none",
            }}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default SettingsProductsTemplate;
