export enum PaymentTabKeys {
  INCOME_EXPENSE = "income_or_expense",
  DEBT = "debt",
  PAYMENT = "expected",
  ORDER = "order",
  RESOURCE = "resource",
  WORK = "work",
  INVENTORY = "inventory",
  CALENDAR = "calendar"
}
