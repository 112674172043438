import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { PartImpostModel } from "../../features/impost/utils/models/partImpostModel";

interface PartModalModel {
  visible: boolean;
  data?: PartImpostModel;
}

interface ImpostReducerModel {
  partModal: PartModalModel;
}

const initialState: ImpostReducerModel = {
  partModal: { visible: false },
};

const impostSlice = createSlice({
  name: sliceNames.IMPOST,
  initialState,
  reducers: {
    setPartModal: (state, action: PayloadAction<PartModalModel>) => {
      state.partModal.visible = action.payload.visible;
      state.partModal.data = action.payload.data;
    },
  },
});

export default impostSlice.reducer;
export const impostActions = impostSlice.actions;
