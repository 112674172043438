import React from "react";
import { Form, Select } from "antd";

import SelectSuffixIcon from "../../../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../../../app/components/conditional-render/ConditionalRender";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { INCOME_EXPENSE_TYPE } from "../../../../../../utils/enums/incomeExpenseTypeEnums";

type Props = {
  search: string;
  projectId: number;
  incomeExpenseType: any;
  isLoadingInventories?: boolean;
  NotFoundContent: () => JSX.Element;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  incomeExpenseTypeData: Record<string, { id: number; name: string }[]>;
};

const { Item } = Form;
const { Option } = Select;

const FinancialItem: React.FC<Props> = ({
  setSearch,
  NotFoundContent,
  incomeExpenseType,
  isLoadingInventories,
  incomeExpenseTypeData
}) => (
  <ConditionalRender if={incomeExpenseType === INCOME_EXPENSE_TYPE.inventory_id}>
    <Item name="financial" rules={formRules()} label="Xarajat">
      <Select
        showSearch
        placeholder="Tanlang"
        onSearch={setSearch}
        loading={isLoadingInventories}
        suffixIcon={<SelectSuffixIcon />}
        filterOption={selectFilterOption}
        notFoundContent={<NotFoundContent />}
      >
        {incomeExpenseTypeData?.financial_id?.map(item => (
          <Option
            key={item?.id}
            value={item?.id}
            props={{
              name: item?.name
            }}
          >
            {item?.name}
          </Option>
        ))}
      </Select>
    </Item>
  </ConditionalRender>
);

export default FinancialItem;
