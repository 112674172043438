import { Button } from "antd";
import { PropsWithChildren } from "react";

import { useCheckRequest } from "../../../service/mutations";
import { InventoryStatusEnum } from "../../../utils/enums/inventoryStatusEnum";
import { RequestCheckBodyModel } from "../../../utils/models/requestCheckBodyModel";

type Props = {
  id: number;
  status: InventoryStatusEnum;
};

const ChangeStatus = ({ id, status, children }: PropsWithChildren<Props>) => {
  const checkRequest = useCheckRequest();

  const onCheckRequest = (req: RequestCheckBodyModel) => {
    checkRequest.mutate(req);
  };

  return (
    <Button
      onClick={() =>
        onCheckRequest({
          id,
          status
        })
      }
      loading={checkRequest.isLoading}
    >
      {children}
    </Button>
  );
};

export default ChangeStatus;
