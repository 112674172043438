import React from "react";
import { Col, Row, Spin } from "antd";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import ExpectedQuantity from "features/projects/components/detailed-project/dynamic-estimate/product/expected/ExpectedQuantity";
import ExpectedTotalAmount from "features/projects/components/detailed-project/dynamic-estimate/product/expected/ExpectedTotalAmount";
import i18n from "i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { isEmptyArr, RU } from "modules/common";
import { typeValues, useProjectColumnProducts, useWidget } from "modules/dashboard";

import styles from "../project-by-section.module.scss";
import { useTranslation } from "react-i18next";

const Product: React.FC = () => {
  const { id, props, visible } = useWidget();
  const { queries } = useQueryParams();
  const { estimate_type } = queries();
  const { products, isLoading } = useProjectColumnProducts({ id, taskId: props?.taskId, enabled: visible });
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <ConditionalRender
        if={!isEmptyArr(products) && !isLoading}
        else={<TableEmpty description={t("dashboard.Mahsulotlar mavjud emas")} />}
      >
        <div className="flex flex-col">
          {products?.map(item => (
            <Row key={item.id} className="bg-gray-100 px-5 py-4">
              <Col span={9}>
                <div className="flex gap-2">
                  <div
                    className="flex h-max items-center justify-center rounded border border-solid px-2 py-0.5 text-xs"
                    style={{
                      color: item.product?.resource.color,
                      borderColor: item.product?.resource.color
                    }}
                  >
                    {item?.product?.resource?.symbol[i18n.language]}
                  </div>
                  <div className={styles.product__name}>{item.product?.name[i18n.language]}</div>
                </div>
              </Col>
              <Col span={2} />
              <Col span={3} className={styles.product__item}>
                {item?.ordered_total_quantity}
              </Col>
              <Col span={3} className={styles.product__item}>
                {/* {item?.quantity} */}
                <ExpectedQuantity
                  isBordered
                  product={item as never}
                  taskStatus={props?.status}
                  className="m-0 rounded-lg p-2"
                />
              </Col>
              <Col span={3} className={styles.product__item}>
                {item?.unit?.symbol[i18n.language]}
              </Col>
              <Col span={4} className={styles.product__item}>
                {estimate_type === typeValues.dynamic ? (
                  <ExpectedTotalAmount isBordered product={item as never} className="m-0 rounded-lg p-2" />
                ) : (
                  // ? item?.prediction_total_amount / (item?.quantity || 1)
                  item.amount?.toLocaleString(RU)
                )}
              </Col>
            </Row>
          ))}
        </div>
      </ConditionalRender>
    </Spin>
  );
};

export default Product;
