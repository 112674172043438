import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import $api from "features/app/utils/helpers/axiosInstance";
import { useTranslation } from "react-i18next";

import { ErrorRes, SuccessRes } from "modules/common";

import { defaultWidgetSizes } from "../constants";
import { UpdateWidget } from "../forms";
import { dashboardQueryKeys } from "../query-keys";
import { CompanyWidget, Widget } from "../schema";

export function useUpdateWidget(props?: { isNotSuccessMessage?: boolean }) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes<CompanyWidget>, ErrorRes, UpdateWidget>(
    async body => {
      const { data } = await $api.put("company-widget/update", body);

      return data;
    },
    {
      onSuccess: async res => {
        await qc.invalidateQueries({ queryKey: [dashboardQueryKeys.COMPANY_WIDGETS_SELECT] });

        if (res?.data?.status === "passive") {
          qc.setQueryData([dashboardQueryKeys.COMPANY_WIDGETS], ({ companyWidgets }: any) => ({
            companyWidgets: companyWidgets?.filter((item: Widget) => item.id !== res.data.id)
          }));
        } else if (res?.data?.status === "active") {
          await qc.setQueryData([dashboardQueryKeys.COMPANY_WIDGETS], data => {
            const { companyWidgets } = data as { companyWidgets: CompanyWidget[] };
            const widgetProps = defaultWidgetSizes[res?.data?.chart_type];
            const foundWidget = (companyWidgets as Widget[]).find((item: Widget) => item.id === res.data.id);

            if (foundWidget) {
              return {
                companyWidgets: companyWidgets?.map((item: Widget) => (item.id === res.data.id ? res.data : item))
              };
            }

            if (res?.data?.chart_type === "calendar" && res?.data?.filter && res?.data?.filter?.special_date) {
              if (res?.data?.filter?.special_date?.includes("month")) {
                return {
                  companyWidgets: [
                    ...companyWidgets,
                    {
                      ...res?.data,
                      props: JSON.stringify({
                        ...widgetProps,
                        x: 0,
                        w: 14,
                        minW: 14,
                        h: 30,
                        minH: 30,
                        y: 0,
                        i: res?.data?.id
                      })
                    }
                  ].sort((a, b) => a.id - b.id)
                };
              }
              return {
                companyWidgets: [
                  ...companyWidgets,
                  {
                    ...res?.data,
                    props: JSON.stringify({
                      ...widgetProps,
                      w: 28,
                      minW: 28,
                      h: 35,
                      minH: 35,
                      x: 0,
                      y: 0,
                      i: res?.data?.id
                    })
                  }
                ].sort((a, b) => a.id - b.id)
              };
            }

            return {
              companyWidgets: [
                ...companyWidgets,
                { ...res?.data, props: JSON.stringify({ ...widgetProps, x: 0, y: 0, i: res?.data?.id }) }
              ].sort((a, b) => a.id - b.id)
            };
          });
        }

        if (!props?.isNotSuccessMessage) {
          message.success(res.message[i18n.language]);
        }
      }
    }
  );
}
