import { useQueryClient } from "@tanstack/react-query";
import { Spin } from "antd";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import InfoHexagonIcon from "../../../../../../../../../../../../assets/icons/InfoHexagonIcon";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../../../../../../../hooks/useQueryParams";
import {
  GanttDateType,
  GanttDateWidth,
} from "../../../../../../../../../../../../store/reducers/ganttReducer";
import { projectsReducerActions } from "../../../../../../../../../../../../store/reducers/projectsReducer";
import { dayjsFormats } from "../../../../../../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../../../../../../app/utils/constants/queryParamsKeys";
import CaretIcon from "../../../../../../../../../../assets/icons/CaretIcon";
import PlusCircleIcon from "../../../../../../../../../../assets/icons/PlusCircleIcon";
import { projectStatuses } from "../../../../../../../../../../utils/enums/projectStatuses";
import { GanttContext } from "../../../../hooks/GanttContext";
import { useSequenceCreate, useTaskUpdate } from "../../../../services/mutation";
import { endPoints } from "../../../../utils/constants/endPoints";
import { queryKeys } from "../../../../utils/constants/queryKeys";
import { taskStatus } from "../../../../utils/constants/taskStatus";
import { GanttSection } from "../../../../utils/models/GanttSection";
import { GanttTaskType } from "../../../../utils/models/GanttTaskType";
import GanttLineSquence from "./gantt-line-squence/GanttLineSquence";
import styles from "./ganttLine.module.scss";

interface IProps extends GanttSection {
  startDateGantt: dayjs.Dayjs | undefined;
  dueDateGantt: dayjs.Dayjs | undefined;
  elementType?: GanttTaskType;
  sectionId: number;
  countOfDays: number;
  countOfMonth: number;
  countOfYear: number;
  projectStatus?: projectStatuses;
}

const GanttLine = ({
  due_date,
  start_date,
  startDateGantt,
  dueDateGantt,
  id,
  sectionId,
  elementType,
  sequence_task_ids,
  active,
  countOfDays,
  countOfMonth,
  countOfYear,
  status,
  projectStatus,
}: IProps) => {
  const params = useParams();
  const projectId = Number(params?.projectId);
  const { reqQueryParam, searchParams } = useQueryParams();
  const { setWork } = projectsReducerActions;
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.ganttReducer);
  const { setGanttContext, sequence, sequenceSectionId } = useContext(GanttContext);
  const sequenceCreate = useSequenceCreate(sectionId, sequenceSectionId!);
  const taskUpdate = useTaskUpdate(sectionId);
  const qc = useQueryClient();

  // topshiriq chetidan chiqadigan linialar
  const gantt_line_left = document.getElementById("gantt_line_left");
  const gantt_line_right = document.getElementById("gantt_line_right");

  const startDate = dayjs(start_date, dayjsFormats.DATE);
  const dueDate = dayjs(due_date, dayjsFormats.DATE);

  // kunlar uzunligi turi bo'yicha
  const dayWidth = {
    [GanttDateType.DAY]: GanttDateWidth[GanttDateType.DAY],
    [GanttDateType.MONTH]:
      (GanttDateWidth[GanttDateType.MONTH] * (countOfMonth + 1)) / (countOfDays + 1),
    [GanttDateType.YEAR]:
      (GanttDateWidth[GanttDateType.YEAR] * (countOfYear + 1)) / (countOfDays + 1),
  };

  // width va left
  const width = (dueDate.diff(startDate, "day") + 1) * dayWidth[type];
  const left = startDate.diff(startDateGantt, "day") * dayWidth[type];

  // position
  const [position, setPosition] = useState({
    left: left,
    width: width,
    dragging: false,
    draggingLeft: false,
    draggingRight: false,
    startWidth: 0,
  });
  const [linePosition, setLinePosition] = useState({
    isLine: false,
    x: 0,
    y: 0,
    clientX: 0,
    clientY: 0,
    angle: 0,
  });

  useEffect(() => {
    setPosition((prev) => ({ ...prev, left: left, width: width }));
  }, [width, left, type]);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setPosition((prev) => ({
      ...prev,
      dragging: true,
      draggingLeft: false,
      draggingRight: false,
      startWidth: e.clientX,
    }));
  };

  const onMouseDownLeft = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPosition((prev) => ({
      ...prev,
      dragging: false,
      draggingLeft: true,
      draggingRight: false,
      startWidth: e.clientX,
    }));
  };

  const onMouseDownRight = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    setPosition((prev) => ({
      ...prev,
      dragging: false,
      draggingLeft: false,
      draggingRight: true,
      startWidth: e.clientX,
    }));
  };

  useEffect(() => {
    const windowMouseMove = (e: MouseEvent) => {
      if (position.dragging) {
        setPosition((prev) => {
          return {
            ...prev,
            left: left - (prev.startWidth - e.clientX),
          };
        });
      }
      if (position.draggingRight) {
        setPosition((prev) => {
          return {
            ...prev,
            width: width - (prev.startWidth - e.clientX),
          };
        });
      }
      if (position.draggingLeft) {
        setPosition((prev) => {
          return {
            ...prev,
            width: width + (prev.startWidth - e.clientX),
            left: left - (prev.startWidth - e.clientX),
          };
        });
      }
      if (linePosition.isLine) {
        const a = e.clientX - linePosition.clientX;
        const h = e.clientY - linePosition.clientY;

        const angle = Math.atan2(-h, -a);
        setLinePosition((prev) => ({
          ...prev,
          x: a,
          y: h,
          angle: angle,
        }));
      }
    };

    const windowMouseUp = (e: MouseEvent) => {
      if (position.dragging || position.draggingLeft || position.draggingRight) {
        setPosition((prev) => ({
          ...prev,
          dragging: false,
          draggingLeft: false,
          draggingRight: false,
        }));

        const dayCount = Math.round(position.width / dayWidth[type]) - 1;
        const startDate = Math.round(position.left / dayWidth[type]);
        const newStartDate = startDateGantt
          ?.add(startDate, "day")
          .format(dayjsFormats.DATE)!;
        const newDueDate = startDateGantt
          ?.add(startDate + dayCount, "day")
          .format(dayjsFormats.DATE)!;

        if (newStartDate !== start_date || newDueDate !== due_date) {
          taskUpdate
            .mutateAsync({ id, start_date: newStartDate, due_date: newDueDate })
            .catch(() => {
              setPosition((prev) => ({ ...prev, left, width }));
            });

          let url = endPoints.GANTT_SECTION(projectId);
          if (searchParams && searchParams?.length > 0) {
            url += `&${reqQueryParam(
              queryParamsKeys.GENERAL_SEARCH,
              queryParamsKeys.MAX_DUE_DATE,
              queryParamsKeys.MIN_DUE_DATE,
              queryParamsKeys.MAX_START_DATE,
              queryParamsKeys.MIN_DUE_DATE,
              queryParamsKeys.SECTION_IDS_ARR,
              queryParamsKeys.TASK_STATUSES_ARR,
              queryParamsKeys.USER_IDS_ARR,
              queryParamsKeys.COMPANY_PERSON_IDS_ARR
            )}`;
          }

          qc.setQueryData<GanttSection[]>(
            [queryKeys.GANTT_TASK, sectionId, url],
            (data) =>
              data?.map((item) => {
                return item?.id === id
                  ? { ...item, start_date: newStartDate, due_date: newDueDate }
                  : {
                      ...item,
                    };
              })
          );
        }
        gantt_line_left?.setAttribute(
          "style",
          `left: ${position.left}px; opacity:0; height: ${
            gantt_line_left.getBoundingClientRect().height
          }px;`
        );
        gantt_line_right?.setAttribute(
          "style",
          `left: ${position.left + position.width}px; opacity:0; height: ${
            gantt_line_right.getBoundingClientRect().height
          }px;`
        );
      }
      if (linePosition.isLine) {
        setLinePosition((prev) => ({
          ...prev,
          isLine: false,
          angle: 0,
          x: 0,
          y: 0,
        }));
      }
    };

    window.addEventListener("mousemove", windowMouseMove);
    window.addEventListener("mouseup", windowMouseUp);
    return () => {
      window.removeEventListener("mousemove", windowMouseMove);
      window.removeEventListener("mouseup", windowMouseUp);
    };
  }, [
    position.dragging,
    position.draggingLeft,
    position.draggingRight,
    position.left,
    position.width,
    sectionId,
    linePosition.isLine,
    type,
  ]);

  useEffect(() => {
    if (position.dragging || position.draggingLeft || position.draggingRight) {
      gantt_line_left?.setAttribute(
        "style",
        `left: ${position.left}px; opacity:1; height: ${
          gantt_line_left.getBoundingClientRect().height
        }px;`
      );
      gantt_line_right?.setAttribute(
        "style",
        `left: ${position.left + position.width}px; opacity:1; height: ${
          gantt_line_right.getBoundingClientRect().height
        }px;`
      );
    }
  }, [position]);

  const plusMouseDown = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setGanttContext({ sequence: id, sequenceSectionId: sectionId });
    setLinePosition((prev) => ({
      ...prev,
      isLine: true,
      clientX: e.clientX,
      clientY: e.clientY,
      x: 0,
      y: 0,
    }));
  };

  const onMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (sequence && id !== sequence) {
      setGanttContext({ sequence: null, sequenceSectionId: null });
      sequenceCreate.mutateAsync({
        task_id: sequence,
        task_child_id: id,
      });
    }
  };

  const handleTask = () => {
    setLinePosition((prev) => ({ ...prev, isLine: false }));
    setPosition((prev) => ({
      ...prev,
      dragging: false,
      draggingLeft: false,
      draggingRight: false,
    }));
    dispatch(setWork({ visible: true, id }));
  };

  return elementType === "section" ? (
    <div
      className={styles.container}
      style={{
        width: position.width,
        left: position.left,
        background: taskStatus[status]?.color,
        cursor: "default",
      }}
      id={`section_${sectionId}`}
    ></div>
  ) : projectStatus === projectStatuses.PLANNING ? (
    <>
      <Spin
        spinning={taskUpdate.isLoading}
        className={styles.spinning}
        style={{
          left: position.left + position.width / 2,
        }}
        size="small"
      ></Spin>
      <div
        className={styles.container}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        style={{
          width: position.width,
          left: position.left,
          background: taskStatus[status]?.color,
          opacity: taskUpdate.isLoading ? 0.5 : "",
          pointerEvents: taskUpdate.isLoading ? "none" : "inherit",
        }}
        id={`task_${id}`}
        onDoubleClick={handleTask}
      >
        <InfoHexagonIcon className={styles.info} onClick={handleTask} />
        <CaretIcon
          placement="left"
          className={styles.leftIcon}
          onMouseDown={onMouseDownLeft}
        />
        <CaretIcon className={styles.rightIcon} onMouseDown={onMouseDownRight} />
        <PlusCircleIcon
          size={16}
          color="#475467"
          className={`${styles.plusLine} ${linePosition.isLine}`}
          onMouseDown={plusMouseDown}
        />
        <div
          className={styles.line}
          style={{
            width: Math.sqrt(linePosition.x ** 2 + linePosition.y ** 2),
            transform: `rotate(${linePosition.angle}rad)`,
          }}
        ></div>
        {sequence_task_ids &&
          sequence_task_ids?.map((item) => (
            <GanttLineSquence
              key={item.id}
              squenceTaskId={item.task_child_id}
              squenceId={item.id}
              id={id}
              taskSectionId={sectionId}
              squenceSectionId={sequenceSectionId}
              active={active}
            />
          ))}
      </div>
    </>
  ) : (
    <div
      className={styles.container}
      style={{
        width: position.width,
        left: position.left,
        background: taskStatus[status]?.color,
        cursor: "default",
      }}
      id={`task_${id}`}
      onDoubleClick={handleTask}
    >
      <InfoHexagonIcon className={styles.info} onClick={handleTask} />
    </div>
  );
};

export default GanttLine;
