import { Button } from "antd";

import { useAppDispatch } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { settingsActions } from "../../../../store/reducers/settingsReducer";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../app/components/conditional-render/ConditionalRender";
import PaymentDocsModal from "../../components/template/modal/TemplateModal";
import PaymentDocsTable from "../../components/template/table/TemplateTable";
import { templateSubmodules } from "../../utils/constants/templateSubmodules";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Template = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { setTemplateModal } = settingsActions;
  const routePermissions = useRoutePermissions("Sozlamalar", templateSubmodules);
  const actions = routePermissions.actions("Hujjatlar");

  const onOpenModal = () => {
    dispatch(setTemplateModal({ visible: true }));
  };

  return (
    <section className={styles.payment_docs}>
      <div className={styles.title}>
        <div className={styles.left}>
          <h2>{t("settings.Hujjatlar")}</h2>
          <p>{t("settings.Bu yerda siz hujjatlarni o'zgartirishingiz mumkin")}</p>
        </div>
        <ConditionalRender if={actions["create"]}>
          <Button onClick={onOpenModal}>
            <PlusIcon /> {t("settings.Hujjat qo’shish")}
          </Button>
        </ConditionalRender>
      </div>
      <ConditionalRender if={actions["view"]}>
        <PaymentDocsTable />
      </ConditionalRender>
      <ConditionalRender if={actions["create"] && actions["update"]}>
        <PaymentDocsModal />
      </ConditionalRender>
    </section>
  );
};

export default Template;
