import { Button, Dropdown, Form, Input, Switch } from "antd";
import { useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../hooks/redux";
import {
  GanttSettingsModel,
  setGanttTableHeaderSettings,
} from "../../../../../../../../../../../../store/reducers/ganttReducer";
import { SearchIcon } from "../../../../../../../../../../../app/assets/icons/SearchIcon";
import GanttSettingsIcon from "../../../../../../../../../../assets/icons/GanttSettingsIcon";

import styles from "./ganttTableHeaderSettings.module.scss";
import { useTranslation } from "react-i18next";

const { Item, useForm } = Form;

const GanttTableHeaderSettings = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const settingsValue = useAppSelector((state) => state.ganttReducer.settings);
  const { t } = useTranslation()

  const toggleOpenDropdown = () => {
    form.setFieldsValue({ ...settingsValue });
    setOpen((prev) => !prev);
  };

  const onCloseDropdown = () => {
    setOpen(false);
  };

  const onFinish = (values: GanttSettingsModel) => {
    dispatch(setGanttTableHeaderSettings(values));
    onCloseDropdown();
  };

  const customDropdown = () => (
    <div className={styles.dropdown}>
      <div className={styles.dropdown__top}>
        <Input
          prefix={<SearchIcon />}
          className={styles.dropdown__search}
          placeholder={t("project.Qidiruv")}
        />
      </div>
      <div className={styles.dropdown__bottom}>
        <Form onFinish={onFinish} form={form}>
          <div className={styles.dropdown__item}>
            <p>{t("project.Foiz")}</p>
            <Item<GanttSettingsModel>
              name="percent"
              valuePropName="checked"
              initialValue={settingsValue.percent ?? false}
            >
              <Switch />
            </Item>
          </div>
          <div className={styles.dropdown__item}>
            <p>{t("project.Mas’ul xodim")}</p>
            <Item<GanttSettingsModel>
              name="confirmation_person"
              valuePropName="checked"
              initialValue={settingsValue.confirmation_person ?? false}
            >
              <Switch />
            </Item>
          </div>
          <div className={styles.dropdown__item}>
            <p>{t("project.Holati")}</p>
            <Item<GanttSettingsModel>
              name="status"
              valuePropName="checked"
              initialValue={settingsValue.status ?? false}
            >
              <Switch />
            </Item>
          </div>
          <div className={styles.dropdown__item}>
            <p>{t("project.Boshlanish sanasi")}</p>
            <Item<GanttSettingsModel>
              name="start_date"
              valuePropName="checked"
              initialValue={settingsValue.start_date ?? false}
            >
              <Switch />
            </Item>
          </div>
          <div className={styles.dropdown__item}>
            <p>{t("project.Yakunlangan sana")}</p>
            <Item<GanttSettingsModel>
              name="due_date"
              valuePropName="checked"
              initialValue={settingsValue.due_date ?? false}
            >
              <Switch />
            </Item>
          </div>
          <div className={styles.dropdown__footer}>
            <Button onClick={onCloseDropdown}>{t("project.Yopish")}</Button>
            <Button type="primary" htmlType="submit">
              {t("project.Saqlash")}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );

  return (
    <Dropdown
      dropdownRender={customDropdown}
      trigger={["click"]}
      open={open}
      onOpenChange={toggleOpenDropdown}
    >
      <div className={styles.dropdown__button}>
        <GanttSettingsIcon />
      </div>
    </Dropdown>
  );
};

export default GanttTableHeaderSettings;
