import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, FormInstance, Input, message, Modal, Radio, RadioChangeEvent, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";

import { useAppSelector } from "../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { RootState } from "../../../../../../store";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { tableIndex } from "../../../../../app/utils/helpers/tableIndex";
import { CurrencyModel } from "../../../../../settings/utils/models/currency/currencyModel";
import CoinsHandIcon from "../../../../assets/icons/CoinsHandIcon";
import CurrencyDollarCircleIcon from "../../../../assets/icons/CurrencyDollarCircleIcon";
import PlusBorderIcon from "../../../../assets/icons/PlusBorderIcon";
import { expectedPaymentsLastId } from "../../../../utils/helpers/expectedPaymentsLastId";
import AppendElementModal from "../add-element-modal/AppendElementModal";

import styles from "./formTableModal.module.scss";

interface TableDataModel {
  id: number;
  date: string;
  amount: number;
}

type Props = {
  parentForm: FormInstance;
};

const FormTableModal: React.FC<Props> = ({ parentForm }) => {
  const {
    setAmountFormTable,
    setDataFormTable,
    setClearDataFormTable,
    setAppendOneElement,
    setVisibleFormTable,
    setDeleteElement,
    setVisibleAppendElement,
    setFormTableState
  } = paymentReducerActions;
  const { visible, expected_payments, total_amount, state } = useAppSelector(
    (state: RootState) => state.paymentReducer.expected_payment.form
  );
  const dispatch = useDispatch();

  const date = parentForm.getFieldValue("start_date");
  const amount = parseLocaledString(parentForm.getFieldValue("amount"));
  const currency = JSON.parse(parentForm.getFieldValue("currency") ?? "{}") as CurrencyModel;

  useEffect(() => {
    dispatch(setAmountFormTable());
  }, [expected_payments, dispatch, setAmountFormTable]);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      dispatch(
        setFormTableState({
          startDate: date,
          mountRange: state?.mountRange ?? 1,
          count: state?.count
        })
      );

      if (!(expected_payments && expected_payments?.length > 0)) {
        dispatch(
          setAppendOneElement({
            id: 1,
            amount,
            date: dayjs(date).format(dayjsFormats.DATE)
          })
        );
      }

      dispatch(setAmountFormTable());
    }
  };

  const onCancel = () => {
    dispatch(setVisibleFormTable(false));
  };

  const isNotDate = (date: string) => expected_payments!.some(item => item.date === date);

  const onDeleteElement = (index: number) => {
    dispatch(setDeleteElement(index));
  };

  const onChangeOneElementDate = (e: dayjs.Dayjs | null, index: number) => {
    const newDate = dayjs(e).format(dayjsFormats.DATE);

    expected_payments &&
      dispatch(
        setDataFormTable(
          expected_payments.map((item, i) => {
            if (index === i) {
              if (!isNotDate(newDate)) {
                return {
                  ...item,
                  date: newDate
                };
              }
              message.warning("Ushbu sanada to'lov amalga oshirilgan");

              return item;
            }
            return item;
          })
        )
      );
  };

  const createTable = () => {
    const { startDate, mountRange, customMountRange, count } = state;
    const lastId = expectedPaymentsLastId(expected_payments);

    if (startDate && count && (mountRange || customMountRange)) {
      for (let i = 1; i <= Number(count); i++) {
        dispatch(
          setAppendOneElement({
            date: dayjs(startDate, dayjsFormats.DATE)
              .add((i - 1) * (mountRange! ?? customMountRange!), "month")
              .format(dayjsFormats.DATE),
            amount: Number(amount) / count!,
            id: lastId + i
          })
        );
      }
    }
  };

  const columns: ColumnsType<TableDataModel> = [
    {
      title: "№",
      render: (_, __, index) => tableIndex(index, 1),
      width: "5rem"
    },
    {
      title: "Sana",
      render: (_, record, index) => (
        <DatePicker
          suffixIcon={null}
          format={dayjsFormats.DATE}
          placeholder="Sanani tanlang"
          className={styles.payment_date}
          value={record.date ? dayjs(record.date, dayjsFormats.DATE) : null}
          onChange={e => onChangeOneElementDate(e, index)}
        />
      )
    },
    {
      title: "Summa",
      render: (record: TableDataModel) => (
        <>
          {record.amount?.toLocaleString()} {currency.symbol}
        </>
      )
    },
    {
      title: "",
      render: (_, __, index) => (
        <div className={styles.delete}>
          <span onClick={() => onDeleteElement(index)}>
            <DeleteIcon />
          </span>
        </div>
      )
    }
  ];

  const onChangeDate = (e: dayjs.Dayjs | null) => {
    dispatch(
      setFormTableState({
        ...state,
        startDate: e ? dayjs(e, dayjsFormats.DATE).format(dayjsFormats.DATE) : undefined
      })
    );

    const mountRange = state.mountRange ?? 1;

    if (e) {
      if (expected_payments && expected_payments?.length > 0) {
        dispatch(
          setDataFormTable(
            expected_payments?.map((item, index) => ({
              id: item.id,
              amount: item.amount,
              date: dayjs(e)
                .add(index * mountRange, "month")
                .format(dayjsFormats.DATE)
            })) ?? []
          )
        );
      } else {
        createTable();
      }
    }
  };

  const onChangeExpectedCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFormTableState({
        ...state,
        count: Number(e.currentTarget.value)
      })
    );

    const value = Number(e.currentTarget.value);
    const { customMountRange, mountRange, startDate } = state;

    if (value && value >= 1) {
      if (startDate && (mountRange || customMountRange)) {
        dispatch(setClearDataFormTable());

        for (let i = 1; i <= value; i++) {
          dispatch(
            setAppendOneElement({
              id: i,
              amount: amount / value,
              date: dayjs(startDate, dayjsFormats.DATE)
                .add((i - 1) * (mountRange ?? customMountRange!), "month")
                .format(dayjsFormats.DATE)
            })
          );
        }
      } else {
        message.warning("Ma'lumotlar to'liq emas");
      }
    }
  };

  const onChangeMountRange = (e: RadioChangeEvent) => {
    dispatch(
      setFormTableState({
        ...state,
        mountRange: Number(e.target.value)
      })
    );

    if (expected_payments && expected_payments?.length > 0) {
      const firstDate = expected_payments[0].date ?? dayjs().format(dayjsFormats.DATE);

      dispatch(
        setDataFormTable(
          expected_payments.map((item, index) => ({
            id: item.id,
            amount: item.amount,
            date: dayjs(firstDate, dayjsFormats.DATE)
              .add(index * e.target.value, "month")
              .format(dayjsFormats.DATE)
          }))
        )
      );
    } else {
      createTable();
    }
  };

  const onChangeCustomMountRange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      setFormTableState({
        ...state,
        mountRange: undefined,
        customMountRange: Number(e.currentTarget.value)
      })
    );

    const value = Number(e.currentTarget.value);

    if (value) {
      if (expected_payments && expected_payments?.length > 0) {
        const defaultDate = expected_payments?.[0].date ?? dayjs().format(dayjsFormats.DATE);

        dispatch(
          setDataFormTable(
            expected_payments?.map((item, index) => ({
              id: item.id,
              amount: item.amount,
              date: dayjs(defaultDate, dayjsFormats.DATE)
                .add(index * value, "month")
                .format(dayjsFormats.DATE)
            })) ?? []
          )
        );
      } else {
        createTable();
      }
    }
  };

  const onOpenAppendElementModal = () => {
    dispatch(setVisibleAppendElement(true));
  };

  const onOk = () => {
    if (amount - total_amount !== 0) {
      message.warning("Qoldiq summa mavjud");
    } else {
      dispatch(setVisibleFormTable(false));
    }
  };

  const onClearFormTable = () => {
    dispatch(setClearDataFormTable());
  };

  return (
    <>
      <Modal
        centered
        title="Jadval shakllantirish"
        okText="Saqlash"
        cancelText="Ortga"
        afterOpenChange={onAfterOpen}
        open={visible}
        onCancel={onCancel}
        onOk={onOk}
        className={styles.create_table}
      >
        <div className={styles.content}>
          <div className={styles.left}>
            <div className={styles.top}>
              <Button onClick={onOpenAppendElementModal}>
                <PlusBorderIcon /> To'lov grafigi qo'shish
              </Button>
              <Button onClick={onClearFormTable}>
                <DeleteIcon /> Jadvalni tozalash
              </Button>
            </div>
            <div className={styles.bottom}>
              <Table<TableDataModel>
                columns={columns}
                pagination={false}
                rowKey={row => row.id}
                dataSource={expected_payments}
                locale={{ emptyText: <TableEmpty /> }}
              />
              <Pagination
                paginationProps={{
                  total: 10
                }}
              />
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.amount}>
              <div className={styles.icon}>
                <CurrencyDollarCircleIcon />
              </div>
              <p>Jadvaldagi summa</p>
              <h3>
                {total_amount?.toLocaleString()} {currency.symbol}
              </h3>
            </div>
            <div className={styles.amount}>
              <div className={styles.icon}>
                <CoinsHandIcon />
              </div>
              <p>Qoldiq summa</p>
              <h3>
                {(amount - total_amount)?.toLocaleString()} {currency.symbol}
              </h3>
            </div>
            <div className={styles.form}>
              <div className={styles.form}>
                <div className={styles.form__item}>
                  <label className={styles.form__label}>Boshlang'ich sana</label>
                  <DatePicker
                    suffixIcon={<DatepickerSuffixIcon />}
                    value={state.startDate ? dayjs(state.startDate, dayjsFormats.DATE) : null}
                    placeholder="Sanani tanlang"
                    onChange={onChangeDate}
                    format={dayjsFormats.DATE}
                  />
                </div>
                <div className={styles.form__item}>
                  <label className={styles.form__label}>To'lovlar soni</label>
                  <Input
                    type="number"
                    value={state.count ?? undefined}
                    onChange={onChangeExpectedCount}
                    placeholder="To'lovlar sonini kiriting"
                  />
                </div>
                <div className={styles.form__item}>
                  <label className={styles.form__label}>Vaqt oralig'i (oy)</label>
                  <div className={styles.mount_range__bottom}>
                    <Radio.Group onChange={onChangeMountRange} value={state.mountRange ?? null}>
                      <Radio.Button value={1}>1</Radio.Button>
                      <Radio.Button value={2}>2</Radio.Button>
                      <Radio.Button value={3}>3</Radio.Button>
                      <Radio.Button value={4}>4</Radio.Button>
                    </Radio.Group>
                    <Input
                      type="number"
                      value={state.customMountRange}
                      placeholder="Ixtiyoriy kiriting"
                      onChange={onChangeCustomMountRange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AppendElementModal state={state} />
    </>
  );
};

export default FormTableModal;
