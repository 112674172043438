import React, { ReactNode, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import { SearchIcon } from "../../../../app/assets/icons/SearchIcon";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { TimeoutModel } from "../../../../app/utils/models/TimeoutModel";

type Props = {
  suffix?: ReactNode;
  searchQueryName?: string;
  onChange?: (value: React.ChangeEvent<HTMLInputElement>) => void;
};

const PaymentSearch: React.FC<Props> = ({ suffix, searchQueryName, onChange }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { append, severalRemove } = useQueryParams();
  const [time, setTime] = useState<TimeoutModel>();
  const [searchValue, setSearchValue] = useState<string>();

  useLayoutEffect(() => {
    const query = new URLSearchParams(search);

    if (
      query.get(
        searchQueryName ? queryParamsKeys[searchQueryName as keyof typeof queryParamsKeys] : queryParamsKeys.SEARCH
      )
    ) {
      setSearchValue(
        query.get(
          searchQueryName ? queryParamsKeys[searchQueryName as keyof typeof queryParamsKeys] : queryParamsKeys.SEARCH
        ) as string
      );
    } else {
      setSearchValue(undefined);
    }
  }, [search, searchQueryName]);

  const onSearch = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const target = e?.target as HTMLInputElement;
    const { value } = target;

    setSearchValue(value);
    clearTimeout(time);

    if (value) {
      setTime(
        setTimeout(() => {
          append(
            searchQueryName ? queryParamsKeys[searchQueryName as keyof typeof queryParamsKeys] : queryParamsKeys.SEARCH,
            value,
            false,
            [queryParamsKeys.PAGE]
          );
        }, 800)
      );
    } else {
      severalRemove(searchQueryName ? queryParamsKeys.TEMPLATE_SEARCH : queryParamsKeys.SEARCH, queryParamsKeys.PAGE);
    }
  };

  return (
    <Input
      prefix={<SearchIcon />}
      placeholder={t("Kassa.Qidiruv")}
      onChange={onChange || onSearch}
      value={searchValue}
      style={{ width: 300 }}
      suffix={suffix ?? null}
    />
  );
};

export default PaymentSearch;
