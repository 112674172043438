import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import ProjectSelect from "../../../../../../../app/components/project-select/project-select";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  uniqueId: string;
};

const OfferPartyProjectSelect: React.FC<Props> = ({ uniqueId }) => {
  const dispatch = useDispatch();
  const { setOfferPartyLefItemData } = supplyOfferActions;
  const { leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const leftDataItem = useMemo(() => leftData?.find(item => item.unique_id === uniqueId), [leftData]);

  // const { data: projects } = useGetProjectSelect({
  //   enabled: true,
  //   warehouse_id: leftDataItem?.warehouse_id
  // });

  const onChangeProject = (e: number) => {
    dispatch(
      setOfferPartyLefItemData({
        key: "project_id",
        value: e,
        uniqueId
      })
    );
  };

  return (
    <div className={styles.item}>
      <ProjectSelect mode="state" suffixIcon={null} onChange={onChangeProject} value={leftDataItem?.project_id} />
      {/* <Select */}
      {/*  showSearch */}
      {/*  suffixIcon={null} */}
      {/*  placeholder="Loyiha" */}
      {/*  onChange={onChangeProject} */}
      {/*  popupMatchSelectWidth={false} */}
      {/*  filterOption={selectFilterOption} */}
      {/*  value={leftDataItem?.project_id} */}
      {/*  notFoundContent={<TableEmpty description="Siz qidirgan loyiha mavjud emas" />} */}
      {/* > */}
      {/*  {projects?.map(item => ( */}
      {/*    <Option */}
      {/*      key={item.id} */}
      {/*      value={item.id} */}
      {/*      props={{ */}
      {/*        name: item?.name */}
      {/*      }} */}
      {/*    > */}
      {/*      {item.name} */}
      {/*    </Option> */}
      {/*  ))} */}
      {/* </Select> */}
    </div>
  );
};

export default OfferPartyProjectSelect;
