export const paymentEndPoints = {
  PAYMENTS: "payment/view",
  PAYMENT_STATISTICS: "payment/statistics",
  DELETE_PAYMENT: "payment/delete",
  CASH_MONEY_SELECT: "cash-money/select-cash",
  CASH_MONEY_PAYMENT_TYPE_SELECT: "cash-money/select-payment-type",
  CREATE_PAYMENT: "payment/create",
  UPDATE_PAYMENT: "payment/update",
  PROJECT_SELECT: "project/select",
  CREATE_EXPECTED_PAYMENT: "expected-payment-template/create",
  EXPECTED_PAYMENT_TEMPLATE: "expected-payment-template/view",
  DELETE_EXPECTED_PAYMENT: "expected-payment/delete",
  EXPECTED_PAYMENT_TEMPLATE_DELETED: "expected-payment-template/view-deleted",
  DELETE_EXPECTED_PAYMENT_TEMPLATE: "expected-payment-template/delete",
  EXPECTED_PAYMENTS: "expected-payment/view",
  CREATE_PAY_TEMPLATE: "expected-payment-template/payment",
  CREATE_PAY: "expected-payment/payment",
  ACCURATE_CASH_MONEY_SELECT: "cash-money/select",
  CREATE_TRANSFER: "payment/transfer/create",
  TRANSFERS: "payment/transfer/view",
  TRANSFER_CHECK: "payment/transfer/check",
  CREATE_EXCHANGE: "payment/exchange/create",
  PAYMENTS_ORDER: "payment/order/view",
  PROJECTS_SELECT: "project/select",
  PAYMENT_ORDER_ADDITIONAL: "payment/additional-view",
  PAYMENT_ORDER_ADDITIONAL_REJECT: "payment/additional-reject-view-all",
  PAYMENT_WORK_VIEW: "payment/work/view",
  PAYMENT_INVENTORY_VIEW: "payment/inventory/view",
  INVENTORY_SELECT: "inventory/select",
  INCOME_EXPENSE_DELETE_ARCHIVE: "payment/view-deleted",
  FINANCIAL_SELECT: "financial/select",
  CASHMONEY_PAYMENT_TYPES: "cash-money/select-payment-type",
  PAYMENT_ORDER_EXPORT_EXCEL: "payment/order/export-excel",
  PAYMENT_GENERAL_UPDATE: "payment/all-update",
  CALENDAR: "payment/calendar/view",
  CALENDAR_HISTORY_ORDER: "payment/calendar/order-history-view",
  CALENDAR_HISTORY_PAYMENT: "payment/calendar/expected-payment-history-view",
  ONE_EXPECTED_PAYMENT: "expected-payment/view",
  ONE_COMPANY_PERSON: "company-person/view?id=",
  SECTION_VIEW: "payment/section-view?project_id=",
  REPORT_EXPORT_EXCEL: "payment/report-export-excel",
  EXPORT_PAYMENT_EXCEL: "payment/export-excel",
  EXPORT_PAYMENT_WORKS_EXCEL: "payment/work/export-excel",
  PAYMENT_VIEW: "payment/view",
  DELETE_DELETED_ARCHIVE:"payment/delete-deleted",
};
