import { RU } from "../../../../../../app/utils/constants/languages";
import { cx } from "../../../../../../app/utils/helpers/cx";
import { diffFeatureAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateTaskModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverTotalAmount from "../../diff-popover/DiffPopoverTotalAmount";

import styles from "../task.module.scss";

type Props = {
  task: DynamicEstimateTaskModel;
  className?: string;
  isBordered?: boolean;
  getPopoverContainer?: () => HTMLElement;
};

const ExpectedTotalAmount = ({ task, className, isBordered, getPopoverContainer }: Props) => (
  <>
    {diffFeatureAmount(task?.total_amount, task?.expected_total_amount, task?.spend_total_amount) !== 0 ? (
      <DiffPopoverTotalAmount
        isBordered={isBordered}
        record={task}
        classNames={cx(styles.total_amount, className)}
        isFeature={true}
        getPopoverContainer={getPopoverContainer}
      />
    ) : (
      <p className={cx(styles.total_amount, className)}>{task?.total_amount?.toLocaleString(RU) ?? 0}</p>
    )}
  </>
);

export default ExpectedTotalAmount;
