export enum StatusEnums {
  OPEN = "open",
  PENDING = "pending",
  ORDERED = "ordered",
  PARTIALLY_RECIEVED = "partially_recieved",
  RECIEVED = "recieved",
  REJECTED = "rejected",
  PASSIVE = "passive",
  ACTIVE = "active",
  CANCEL = "cancel",
  BUYING = "buying"
}

export enum PageKeys {
  ORDER = "order",
  PARTY = "party",
  OFFER = "offer"
}
