import React from "react";
import { Collapse, FormInstance } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import Projects from "../../projects/projects";

import Financials from "./financials/financials";

import styles from "../../../transfer-drawer/top/additions/projects/projects.module.scss";

const { Panel } = Collapse;

type Props = {
  index: number;
  form: FormInstance;
};

const Additions: React.FC<Props> = ({ form, index }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      rootClassName={styles.projects}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel key="1" header={t("payment.Qo'shimcha ma'lumotlar")}>
        <Projects
          form={form}
          name={[index, "projects"]}
          currencyFormName="first_currency"
          parentName={["expense_payment", "payment_types"]}
        />
        <Financials form={form} parentName={["expense_payment", "payment_types", index]} />
      </Panel>
    </Collapse>
  );
};

export default Additions;
