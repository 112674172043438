import React from "react";

const DraftIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.33268 1.51367V4.26737C9.33268 4.64073 9.33268 4.82742 9.40534 4.97003C9.46926 5.09547 9.57125 5.19745 9.69669 5.26137C9.8393 5.33403 10.026 5.33403 10.3993 5.33403H13.153M6.33268 8.00065L9.66602 11.334M9.66602 8.00065L6.33268 11.334M9.33268 1.33398H5.86602C4.74591 1.33398 4.18586 1.33398 3.75803 1.55197C3.38171 1.74372 3.07575 2.04968 2.884 2.426C2.66602 2.85383 2.66602 3.41388 2.66602 4.53398V11.4673C2.66602 12.5874 2.66602 13.1475 2.884 13.5753C3.07575 13.9516 3.38171 14.2576 3.75803 14.4493C4.18586 14.6673 4.74591 14.6673 5.86602 14.6673H10.1327C11.2528 14.6673 11.8128 14.6673 12.2407 14.4493C12.617 14.2576 12.9229 13.9516 13.1147 13.5753C13.3327 13.1475 13.3327 12.5874 13.3327 11.4673V5.33398L9.33268 1.33398Z"
        stroke="#667085"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DraftIcon;
