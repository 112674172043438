import React from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";

import { dayjsFormats } from "../../../utils/constants/dayjsFormats";
import { generalEditArgKeys } from "../../../utils/constants/generalEditData";

type Props = {
  argKey: generalEditArgKeys;
  setReturnData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingDate: React.FC<Props> = ({ setReturnData, argKey }) => {
  const onChange = (e: Dayjs) => {
    setReturnData(old =>
      old?.map(item => ({
        ...(item as object),
        [argKey]: e ? dayjs(e, dayjsFormats.DATE).format(dayjsFormats.DATE) : undefined
      }))
    );
  };

  return <DatePicker placeholder="Sana" format={dayjsFormats.DATE} onChange={onChange} />;
};

export default GeneralEditingDate;
