import React from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { projectsReducerActions } from "../../../../../../../store/reducers/projectsReducer";
import SelectSuffixIcon from "../../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../../app/components/table-empty/TableEmpty";
import { useGetLanguages, useGetResourceSelect, useGetUnitSelect } from "../../../../../../app/service/queries";
import { formRules } from "../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../app/utils/helpers/selectFilterOption";
import { ProductSelectModel } from "../../../../../../app/utils/models/productSelectModel";
import { useCreateProduct } from "../../../../../../settings/service/mutations";
import { useGetCategories, useGetSubCategories } from "../../../../../../settings/service/queries";
import { supplyQueryNames } from "../../../../../../supply/utils/constants/supplyQueryNames";
import { ProductBodyModel } from "../../../../../../warehouse/utils/models/productBodyModel";
import { ProductFormModel } from "../../../../../../warehouse/utils/models/productFormModel";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";

const { Item } = Form;
const { Option } = Select;

type Props = {
  afterFunc: (data: ProductSelectModel) => void;
};

const CreateProduct: React.FC<Props> = ({ afterFunc }) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const qc = useQueryClient();
  const { setCreateProduct } = projectsReducerActions;
  const { visible, name } = useAppSelector(state => state.projectsReducer.createProduct);
  const [form] = Form.useForm<ProductFormModel>();

  const categoryId = Form.useWatch("category_id", form) as number | undefined;
  const { data: units } = useGetUnitSelect();
  const createProduct = useCreateProduct();
  const { data: resources } = useGetResourceSelect();
  const { data: languages } = useGetLanguages(visible);
  const { data: categories } = useGetCategories(visible);
  const { data: subCategories } = useGetSubCategories(categoryId);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      form.setFieldValue(["name"], name);
    }
  };

  const onCancel = () => {
    dispatch(
      setCreateProduct({
        visible: false,
        name: ""
      })
    );

    form.resetFields();
  };

  const onFinish = (values: ProductFormModel) => {
    let reqData: ProductBodyModel = { ...values, name: {} };

    languages?.forEach(lang => {
      reqData = Object.assign(reqData, {
        name: {
          ...reqData.name,
          [lang.symbol]: values.name
        }
      });
    });

    if (reqData?.subcategory_id) {
      delete reqData?.category_id;
    }

    createProduct.mutateAsync(reqData).then(({ data }) => {
      qc.invalidateQueries([supplyQueryNames.SELECT_PRODUCTS], { type: "active" }).then(() => {
        afterFunc(data as ProductSelectModel);
      });

      onCancel();
    });
  };

  const onOk = () => form.submit();

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      title="Mahsulot"
      okText="Saqlash"
      cancelText="Yopish"
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createProduct.isLoading
      }}
      getContainer={() => smetaContainer}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="name" rules={formRules()} label="Nomi">
          <Input placeholder="Nomini kiriting" />
        </Item>
        <Item label="Resursi" name="resource_id" rules={formRules()}>
          <Select
            placeholder="Resursni tanlang"
            getPopupContainer={() => smetaContainer}
            suffixIcon={<SelectSuffixIcon />}
          >
            {resources?.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Birligi" name="unit_id" rules={formRules()}>
          <Select
            getPopupContainer={() => smetaContainer}
            showSearch
            placeholder="Birligini tanlang"
            suffixIcon={<SelectSuffixIcon />}
            filterOption={selectFilterOption}
            notFoundContent={<TableEmpty />}
          >
            {units?.map(item => (
              <Option
                key={item.id}
                value={item.id}
                props={{
                  name: item.name[language]
                }}
              >
                {item.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item label="Mahsulot kodi" name="code">
          <Input placeholder="Mahsulot kodini kiriting" />
        </Item>
        <Item name="category_id" label="Katalog" rules={formRules()}>
          <Select
            getPopupContainer={() => smetaContainer}
            placeholder="Katalogni tanlang"
            suffixIcon={<SelectSuffixIcon />}
          >
            {categories?.map(item => (
              <Option key={item?.id} value={item?.id}>
                {item?.name[language]}
              </Option>
            ))}
          </Select>
        </Item>
        {categoryId && (
          <Item name="sub_category_id" label="Kategoriya">
            <Select
              getPopupContainer={() => smetaContainer}
              allowClear
              placeholder="Kategoriyani tanlang"
              suffixIcon={<SelectSuffixIcon />}
              notFoundContent={<TableEmpty description="Kategoriyalar mavjud emas" />}
            >
              {subCategories?.map(item => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name[language]}
                </Option>
              ))}
            </Select>
          </Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateProduct;
