import { FC, useState } from "react";
import { Form, FormInstance } from "antd";
import { DataNode } from "antd/es/tree";

import { useGetCashTree, useGetCurrenciesSelect, useGetWarehouseTree } from "../../../../app/service/queries";
import { CashTreeModel } from "../../../../app/utils/models/cash/cashTreeModel";
import { WarehouseTreeModel } from "../../../../app/utils/models/warehouse/warehouseTreeModel";
import { SettingsCashModal } from "../../../../settings/components/cash/modal/SettingsCashModal";
import { DetailedProjectModel } from "../../../utils/models/detailedProjectModel";
import { ProjectFormFieldsModel } from "../../../utils/models/projectFormFieldsModel";

import CashTreeSelect from "./CashTreeSelect";
import CurrencyAnProfit from "./CurrencyAnProfit";
import WareHouseTreeSelect from "./WareHouseTreeSelect";

import styles from "../projectModal.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  formInstance: FormInstance<ProjectFormFieldsModel>;
  data: DetailedProjectModel | undefined;
  defaultCurrencySymbol?: string;
};

export const ProjectSettings: FC<Props> = ({ formInstance, data, defaultCurrencySymbol }) => {
  const { data: cash } = useGetCashTree();
  const { data: warehouses } = useGetWarehouseTree();
  const { data: currencies } = useGetCurrenciesSelect();
  const { t } = useTranslation()

  const [openCashCreateModal, setOpenCreateCashModal] = useState<{
    visible: boolean;
    id?: number;
    name?: string;
  }>({
    visible: false
  });

  const cashTreeData: (cash: CashTreeModel | undefined) => DataNode[] = cash =>
    (cash?.cash_folders ?? [])
      .map(folder => ({
        title: folder.name,
        key: JSON.stringify({
          value: folder.id,
          key: "cash_folder_ids"
        }),
        children: folder.cashs.map(cash => ({
          title: cash.name,
          key: JSON.stringify({
            value: cash.id,
            key: "cash_ids"
          })
        }))
      }))
      .concat(
        (cash?.cashs ?? []).map(cash => ({
          title: cash.name,
          key: JSON.stringify({
            value: cash.id,
            key: "cash_ids"
          }),
          children: []
        }))
      );

  const warehouseTreeData: (warehouses: WarehouseTreeModel | undefined) => DataNode[] = warehouses =>
    (warehouses?.warehouse_folders ?? [])
      .map(folder => ({
        title: folder.name,
        key: JSON.stringify({
          value: folder.id,
          key: "warehouse_folder_ids"
        }),
        children: folder.warehouses.map(warehouse => ({
          title: warehouse.name,
          key: JSON.stringify({
            value: warehouse.id,
            key: "warehouse_ids"
          })
        }))
      }))
      .concat(
        (warehouses?.warehouses ?? []).map(warehouse => ({
          title: warehouse.name,
          key: JSON.stringify({
            value: warehouse.id,
            key: "warehouse_ids"
          }),
          children: []
        }))
      );

  return (
    <div className={styles.settings}>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Kassa biriktirish")}</span>
          <p>{t("project.Loyiha kassalarini tanlang")}</p>
        </div>
        <Form.Item name="cash_ids" className={styles.right}>
          <CashTreeSelect
            data={cashTreeData(cash)}
            initialData={cashTreeData({
              cash_folders: [],
              cashs: data?.cashs ?? []
            })}
            className={styles.select}
            formInstance={formInstance}
            setOpenCreateCashModal={setOpenCreateCashModal}
          />
        </Form.Item>
      </div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Omborxona biriktirish")}</span>
          <p>{t("project.Loyiha omborxonalarini tanlang")}</p>
        </div>
        <Form.Item name="warehouse_ids" className={styles.right}>
          <WareHouseTreeSelect
            formInstance={formInstance}
            className={styles.select}
            data={warehouseTreeData(warehouses)}
            initialData={warehouseTreeData({
              warehouse_folders: [],
              warehouses: data?.warehouses ?? []
            })}
          />
        </Form.Item>
      </div>
      <CurrencyAnProfit formInstance={formInstance} currencies={currencies} styles={styles} />
      {openCashCreateModal.visible && <SettingsCashModal data={openCashCreateModal} setData={setOpenCreateCashModal} />}
    </div>
  );
};
