import { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import ErrorBoundary from "features/app/components/error-boundary/ErrorBoundary";
import { antdConfigProvider } from "features/app/utils/constants/antdProviderProps";
import { PersistGate } from "redux-persist/integration/react";
import RootRouter from "routes/root/RootRouter";

import { persistor, store } from "store";

import { error } from "services";

import { Auth } from "modules/auth/containers";

import { NetworkMessage } from "components";
import FileViewer from "components/file-viewer/file-viewer";

import Loading from "./features/app/components/loading/Loading";

import "./styles/main.scss";
import "./styles/table.css";
import "./styles/common.scss";
import "./styles/tailwind.css";
import "react-resizable/css/styles.css";

const queryClient = new QueryClient({
  defaultOptions: { queries: { retry: false, refetchOnWindowFocus: false }, mutations: { onError: error as any } },
  queryCache: new QueryCache({ onError: error as any })
});

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <ConfigProvider {...antdConfigProvider}>
                <Auth>
                  <Suspense fallback={<Loading />}>
                    <RootRouter />
                    <FileViewer />
                    <NetworkMessage />
                  </Suspense>
                  <ReactQueryDevtools initialIsOpen={false} />
                </Auth>
              </ConfigProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
