import React from "react";
import { Dropdown, Form, FormInstance, FormListOperation, Spin } from "antd";
import { useTranslation } from "react-i18next";

import PlusCircleIcon from "../../../../../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { PaymentTypeEnum } from "../../../../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { useGetFinancialSelect } from "../../../../../../service/queries";
import { FinancialSelectModel } from "../../../../../../utils/models/financialSelectModel";

import Card from "./card";

type Props = {
  formInstance: FormInstance;
};

const { List } = Form;

const Expenses: React.FC<Props> = ({ formInstance }) => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetFinancialSelect(true, PaymentTypeEnum.EXPENSE);

  const onAddFinance = (item: FinancialSelectModel, operation: FormListOperation) => () => {
    operation.add({
      id: item?.id,
      name: item?.name,
      amount: 0
    });
  };

  return (
    <div className="mb-2.5">
      <div className={cx("ant-form-item-label")}>
        <label>{t("payment.Xarajatlar")}</label>
      </div>
      <Spin spinning={isLoading} indicator={LoadingIndicator}>
        <List name={["expense_payment", "financials"]}>
          {(fields, operation) => (
            <div className="flex flex-col">
              {fields.map((_, key) => (
                <Card key={key} index={key} formInstance={formInstance} operation={operation} />
              ))}
              <Dropdown
                arrow={false}
                placement="bottom"
                trigger={["click"]}
                menu={{
                  items:
                    data?.map(type => ({
                      key: type?.id,
                      label: type?.name,
                      onClick: onAddFinance(type, operation)
                    })) || []
                }}
              >
                <div className="flex w-max cursor-pointer items-center gap-2 rounded-lg px-2 py-1 text-sm font-medium text-blue-500 duration-300 ease-in-out hover:bg-primary-50">
                  <PlusCircleIcon fill />
                  {t("payment.Xarajat qo'shish")}
                </div>
              </Dropdown>
            </div>
          )}
        </List>
      </Spin>
    </div>
  );
};

export default Expenses;
