import { useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input, message, Spin, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import React, { useEffect, useRef } from "react";

import PaperClipIcon from "../../../projects/assets/icons/PaperClipIcon";
import SendMessageIcon from "../../../projects/assets/icons/SendMessageIcon";
import { useCreateChat, useUploadFile } from "../../service/mutation";
import { useGetChat } from "../../service/queries";
import { endPoints } from "../../utils/constants/endPoints";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import { ChatBodyModel } from "../../utils/models/chatBodyModel";
import ChatMessage from "../chat/ChatMessage";
import EmptyMessage from "../chat/EmptyMessage";
import { LoadingIndicator } from "../loading-indicator/LoadingIndicator";

import styles from "./comments.module.scss";

type Props = {
  objectId?: number;
  type: string;
};

const Comments: React.FC<Props> = ({ type, objectId }) => {
  const qc = useQueryClient();
  const [form] = Form.useForm();
  const ref = useRef<HTMLDivElement>(null);
  const createChat = useCreateChat();
  const uploadFile = useUploadFile();
  const { data, isLoading } = useGetChat(type, objectId);

  const onChangeFile = (e: UploadChangeParam<UploadFile>) => {
    if (e.file.size! >= 5120000) {
      message.error("File hajmi 5120 kb dan oshmasligi zarur");
    } else {
      const file = e?.file?.originFileObj;
      form.setFieldsValue({ file });
      form.submit();
    }
  };

  const onFinish = (fields: { message: string; files: UploadFile[] }) => {
    const reqData: ChatBodyModel = {
      message: undefined,
      object_id: objectId,
      file_ids: undefined,
      type,
    };

    if (fields.files?.length > 0) {
      delete reqData.message;
      const formData = new FormData();

      fields.files?.forEach((file) => {
        formData.append("files[]", file.originFileObj as Blob);
      });

      uploadFile.mutateAsync(formData).then((res) => {
        reqData.file_ids = res;
        onCreateChat(reqData);
      });
    } else {
      delete reqData.file_ids;
      reqData.message = fields.message;
      onCreateChat(reqData);
    }
  };

  const onCreateChat = (reqData: ChatBodyModel) => {
    if (reqData.message || reqData.file_ids) {
      createChat.mutateAsync(reqData).then(() => {
        qc.invalidateQueries([endPoints.CHAT_VIEW, objectId, type]);
        form.resetFields();
      });
    } else {
      message.info("Xabar yoki fayl kiritilmagan");
    }
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  useEffect(() => {
    ref.current?.scrollTo({ top: ref.current?.scrollHeight });
  }, [objectId]);

  return (
    <div className={styles.comments}>
      <div
        className={`${styles.top} ${isEmptyArr(data) ? styles.empty_comments : ""}`}
        ref={ref}
      >
        <Spin
          spinning={isLoading || createChat.isLoading || uploadFile.isLoading}
          indicator={LoadingIndicator}
        >
          {!isEmptyArr(data) ? (
            data?.map((item, index, arr) => {
              if (index === 0) {
                return <ChatMessage {...item} key={index} />;
              } else {
                return (
                  <ChatMessage
                    {...item}
                    key={index}
                    prevDate={arr[index - 1]?.created_at}
                  />
                );
              }
            })
          ) : (
            <EmptyMessage />
          )}
        </Spin>
      </div>
      <div className={styles.bottom}>
        <Form form={form} layout="inline" onFinish={onFinish}>
          <Form.Item name="message">
            <Input
              placeholder="Message "
              autoFocus
              disabled={createChat.isLoading || uploadFile.isLoading}
            />
          </Form.Item>
          <Form.Item
            name="files"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload
              multiple
              name="files"
              showUploadList={false}
              onChange={onChangeFile}
              customRequest={() => null}
            >
              <Button>
                <PaperClipIcon />
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item>
            <Button type="primary" onClick={() => form.submit()}>
              <SendMessageIcon />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Comments;
