import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LaboratoryViewData } from "features/warehouse/utils/models/laboratoryViewData";

type InitialState = {
  visible: boolean;
  isUpdating?: boolean;
  id?: number | null;
  isViewing?: boolean;
  record?: LaboratoryViewData;
};

const initialState: InitialState = {
  visible: false,
  isUpdating: false,
  isViewing: false
};

const laboratoryDrawerSlice = createSlice({
  name: "laboratoryDrawer",
  initialState,
  reducers: {
    setOpenDrawer: (
      state,
      action: PayloadAction<{
        visible: boolean;
        isUpdating?: boolean;
        id?: number | null;
        isViewing?: boolean;
        record?: LaboratoryViewData;
      }>
    ) => {
      state.visible = action.payload.visible;
      state.isUpdating = action.payload.isUpdating;
      state.id = action.payload.id;
      state.isViewing = action.payload.isViewing;
      state.record = action.payload.record;
    }
  }
});

export const laboratoryDrawerActions = laboratoryDrawerSlice.actions;
export default laboratoryDrawerSlice.reducer;
