import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Spin } from "antd";
import classNames from "classnames";
import { NotificationTypeEnum } from "features/app/utils/enums/notificationTypeEnum";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";

import { useAppDispatch } from "../../../../../hooks/redux";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import NotificationNotDataIcon from "../../../assets/icons/NotificationNotDataIcon";
import { useReadNotification } from "../../../service/mutation";
import { useGetNotifications } from "../../../service/queries";
import { notificationsTabKeys } from "../../../utils/constants/notificationsTabKeys";
import {
  notificationClassNames,
  notificationTypesIcons,
  notificationWithoutIcons
} from "../../../utils/constants/notificationTypes";
import { getFirstLetter } from "../../../utils/helpers/getFirstLetter";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import { NotificationModel } from "../../../utils/models/notificationModel";
import { LoadingIndicator } from "../../loading-indicator/LoadingIndicator";
import ComingSoon from "../notificationTypes/coming-soon/ComingSoon";
import { NotificationTypes } from "../notificationTypes/NotificationTypes";

import styles from "../notification.module.scss";

type Props = {
  setOpen: (open: boolean) => void;
  type: notificationsTabKeys;
};

const NotificationsContent = ({ setOpen, type }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { ref, inView } = useInView();
  const { data: notifications, fetchNextPage, isLoading, isFetching } = useGetNotifications(type);
  const { setVisible } = chatActions;
  const readNotification = useReadNotification();

  const onClose = () => {
    setOpen(false);
  };

  const onOpenChat = (objectId: number, type: string) => {
    dispatch(setVisible({ visible: true, objectId, type }));
  };

  const onSetImage = (record: NotificationModel) => {
    if (notificationWithoutIcons.includes(record?.type)) {
      if (
        !(
          record?.type === NotificationTypeEnum.WAREHOUSE_DEFECT_CREATED ||
          record?.type === NotificationTypeEnum.WAREHOUSE_DEFECT_RECIEVED ||
          record?.type === NotificationTypeEnum.WAREHOUSE_DEFECT_REJECTED
        )
      ) {
        if (record?.user?.image) {
          return <img src={record?.user?.image} alt={record?.user?.full_name} className={styles.avatar} />;
        }
        if (record?.user?.full_name) {
          return <div className={styles.nonAvatar}>{getFirstLetter(record?.user?.full_name)}</div>;
        }
        return <div className={styles.nonAvatar}>A</div>;
      }
    }
    return notificationTypesIcons[record.type];
  };

  const onNavigatePage = (url: string, record: NotificationModel) => {
    if (record?.created_at) {
      readNotification.mutateAsync(record?.id);
    }
    navigate(url);
    onClose();
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Spin spinning={isLoading || isFetching} indicator={LoadingIndicator}>
      <div className={styles.notifications}>
        {!isEmptyArr(notifications?.pages) && notifications!.pages[0]?.total > 0 ? (
          notifications?.pages?.map((page, index) => (
            <React.Fragment key={page.current_page + index}>
              {page?.data.map(notification => (
                <div className={styles.notification} key={notification.id} ref={ref}>
                  <div className={classNames(styles.left, styles[notificationClassNames[notification.type]])}>
                    {!notification.viewed_at && <div className={styles.viewed} />}
                    {onSetImage(notification)}
                  </div>
                  <div className={styles.right}>
                    {notification?.type in NotificationTypes ? (
                      NotificationTypes[notification?.type]?.(notification, onNavigatePage, onOpenChat)
                    ) : (
                      <ComingSoon />
                    )}
                    <p className={styles.create_date}>{notification.created_at}</p>
                  </div>
                </div>
              ))}
            </React.Fragment>
          ))
        ) : (
          <div className={styles.not__notification}>
            <NotificationNotDataIcon />
            <h4>{t("login.Bildirishnoma mavjud emas")} !</h4>
            <p>{t("login.Sizga ish jarayonidagi o'zgarishlarni taqdim etadi")}.</p>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default NotificationsContent;
