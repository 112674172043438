import { getHueFromLetter } from "./get-light-color";
// export const getDarkColor = () => {
//   const letters = "0123456789ABCDEF".split("");
//   let color = "#";

//   for (let i = 0; i < 6; i++) {
//     color += letters[Math.round(Math.random() * 15)];
//   }

//   return color;
// };

export const getDarkColor = (letter: string) => {
  const hue = getHueFromLetter(letter);
  return `hsl(${hue}, 80%, 60%)`; // Lightness at 30% for a dark color
};
