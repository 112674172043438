import * as localStorageKeys from "../utils/constants/localStorageKeys";

type LocalStorageAllKey = typeof localStorageKeys;
type LocalStorageKey = Lowercase<keyof LocalStorageAllKey>;

export const LocalStorage = {
  get<T>(key: LocalStorageKey): T | null {
    const item = localStorage.getItem(key);

    if (!item) return null;

    try {
      return JSON.parse(item);
    } catch (err) {
      return null;
    }
  },
  set<T>(key: LocalStorageKey, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  },
  remove(key: LocalStorageKey) {
    localStorage.removeItem(key);
  },
  clear() {
    const notDeleteKeys = [localStorageKeys.ESTIMATE_GROUP, localStorageKeys.PAYMENT_CURRENCY];
    const keys = Object.values(localStorageKeys);

    for (const key of keys) {
      if (!notDeleteKeys.includes(key)) {
        localStorage.removeItem(key);
      }
    }
  }
};
