import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useTranslation } from "react-i18next";

import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { tableConfigKeys } from "../utils/constants/tableConfigKeys";
import $api from "../utils/helpers/axiosInstance";
import { downloadFile } from "../utils/helpers/downloadFile";
import { downloadFileData } from "../utils/helpers/downloadFileData";
import { errorHandler } from "../utils/helpers/errorHandler";
import { IBuildersReqBody } from "../utils/models/buildersModalModel";
import { ChatBodyModel } from "../utils/models/chatBodyModel";
import { ConfirmationUserBodyModel } from "../utils/models/confirmationUserBodyModel";
import { ReqDataCustomFieldAttachModel } from "../utils/models/reqDataCustomFieldAttachModel";
import { ErrorRes, SuccessRes } from "../utils/models/responseType";
import { UserTableConfigModel } from "../utils/models/user/userTableConfigModel";

export function useCreateChat() {
  return useMutation<SuccessRes, ErrorRes, ChatBodyModel>(
    async data => {
      const res = await $api.post(endPoints.CREATE_CHAT, data);

      return res.data;
    },
    {
      onError: errorHandler
    }
  );
}

export function useUploadFile() {
  return useMutation<number[], ErrorRes, FormData>(
    async data => {
      const res = await $api.post(endPoints.UPLOAD_FILE, data);

      return res.data.data;
    },
    {
      onError: errorHandler,
      retry: false
    }
  );
}

export function useReadAllNotifications() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes>(
    async () => {
      const res = await $api.get(endPoints.READ_NOTIFICATION);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.NOTIFICATIONS]);
      }
    }
  );
}

export function useReadNotification() {
  const qc = useQueryClient();

  return useMutation<SuccessRes, ErrorRes, number>(
    async id => {
      const res = await $api.get(`${endPoints.NOTIFICATION}?id=${id}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.NOTIFICATIONS]);
      }
    }
  );
}

export function useChangeStatusConfirmationUser() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, ConfirmationUserBodyModel>(
    async data => {
      const res = await $api.put(endPoints.CONFIRMATION_USER_STATUS, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.NOTIFICATIONS]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCreateDeviceToken() {
  return useMutation<SuccessRes, ErrorRes, { device_token: string }>(async data => {
    const res = await $api.post(endPoints.CREATE_DEVICE_TOKEN, data);

    return res.data;
  });
}

export function useChangeTableConfig(key: tableConfigKeys, isNotMessage?: boolean, notValidateQuery?: boolean) {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, UserTableConfigModel>(
    async data => {
      const res = await $api.post(endPoints.TABLE_CONFIG_CREATE, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: async res => {
        if (!notValidateQuery) {
          await qc.invalidateQueries([queryKeys.TABLE_CONFIGS, key]);
        }
        await qc.invalidateQueries([queryKeys.CUSTOM_FIELD_SELECT]);
        if (!isNotMessage) {
          message.success(res.message[i18n.language]);
        }
      }
    }
  );
}

export function useDeleteTableConfig() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, string>(
    async key => {
      const res = await $api.delete(`${endPoints.TABLE_CONFIG_DELETE}?key=${key}`);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: (res, variables) => {
        qc.invalidateQueries([queryKeys.TABLE_CONFIGS, variables]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useCustomFieldAttach() {
  return useMutation<SuccessRes, ErrorRes, ReqDataCustomFieldAttachModel>(
    async data => {
      const res = await $api.put(endPoints.CUSTOM_FIELD_ATTACH, data);

      return res.data;
    },
    {
      onError: errorHandler
    }
  );
}

export function useDownloadFile() {
  return useMutation<Blob | string, ErrorRes, { id: number; name: string }>(
    async data => {
      const res = await $api.get(`${endPoints.DOWNLOAD_FIELD}?id=${data.id}`, {
        responseType: "blob"
      });

      return res.data;
    },
    {
      onSuccess: (res, variables) => {
        const { fileName } = downloadFileData(variables.name);

        downloadFile(res, fileName);
      },
      onError: errorHandler
    }
  );
}

export function useCreateBuilder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, IBuildersReqBody>(
    async data => {
      const res = await $api.post(endPoints.CREATE_BUILDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.BUILDERS]);
        qc.invalidateQueries([queryKeys.BUILDERS_VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useDeleteBuilder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, { id: number }>(
    async data => {
      const res = await $api.delete(endPoints.DELETE_BUILDER, {
        data
      });

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.BUILDERS]);
        qc.invalidateQueries([queryKeys.BUILDERS_VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}

export function useUpdateBuilder() {
  const qc = useQueryClient();
  const { i18n } = useTranslation();

  return useMutation<SuccessRes, ErrorRes, IBuildersReqBody & { id: number }>(
    async data => {
      const res = await $api.put(endPoints.UPDATE_BUILDER, data);

      return res.data;
    },
    {
      onError: errorHandler,
      onSuccess: res => {
        qc.invalidateQueries([queryKeys.BUILDERS]);
        qc.invalidateQueries([queryKeys.BUILDERS_VIEW]);
        message.success(res.message[i18n.language]);
      }
    }
  );
}
