import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys } from "../query-keys";
import { ProjectColumnProduct } from "../schema";

type Query = {
  products: ProjectColumnProduct[];
};

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
  taskId: number;
}

export function useProjectColumnProducts({ taskId, id, enabled }: Params) {
  const initialData = {
    products: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_COLUMN_PRODUCT, taskId, id],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-section/task-product-view?id=${id}&task_id=${taskId}`);

      return { products: data };
    },
    { enabled: enabled && !!taskId }
  );

  return { ...data, ...arg };
}
