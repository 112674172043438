import { Button } from "antd";

import { useAppDispatch } from "../../../../hooks/redux";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { settingsActions } from "../../../../store/reducers/settingsReducer";
import CustomFieldModal from "../../components/custom-field/modal/CustomFieldModal";
import CustomFieldTable from "../../components/custom-field/table/CustomFieldTable";
import ConditionalRender from "../../../app/components/conditional-render/ConditionalRender";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const CustomField = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch();
  const { setCustomFieldModal } = settingsActions;

  const routePermissions = useRoutePermissions("Sozlamalar", routeSubmodules);
  const actions = routePermissions.actions("O'zgaruvchilar");

  const onOpenModal = () => {
    dispatch(setCustomFieldModal({ visible: true }));
  };

  return (
    <section className={styles.custom_field}>
      <div className={styles.title}>
        <div className={styles.left}>
          <h2>{t("settings.O'zgaruvchilar")}</h2>
          <p>{t("settings.Bu yerda siz o'zgaruvchilarni o'zgartirishingiz mumkin")}</p>
        </div>
        <ConditionalRender if={actions["create"]}>
          <Button onClick={onOpenModal}>
            <PlusIcon /> {t("settings.O'zgaruvchi qo’shish")}
          </Button>
        </ConditionalRender>
      </div>
      <ConditionalRender if={actions["view"]}>
        <CustomFieldTable />
      </ConditionalRender>
      <ConditionalRender if={actions["create"] && actions["update"]}>
        <CustomFieldModal />
      </ConditionalRender>
    </section>
  );
};

export default CustomField;
