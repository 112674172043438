import React, { useState } from "react";
import { Dropdown, MenuProps } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
// import EditIcon from "features/app/assets/icons/EditIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";
import UnreadMessageAction from "features/app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "features/app/components/unread-message/UnreadMessageDots";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { ChatEnum } from "features/app/utils/enums/chatEnum";
// import CopyIcon from "features/payment/assets/icons/CopyIcon";
import { useDeleteLaboratory } from "features/warehouse/service/mutation";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { warehouseQueryNames } from "features/warehouse/utils/constants/warehouseQueryNames";
import { LaboratoryViewData } from "features/warehouse/utils/models/laboratoryViewData";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { useDispatch } from "store";
import { chatActions } from "store/reducers/chatReducer";

import { cx } from "modules/common";

type Props = {
  record: LaboratoryViewData;
};

const ActionDropDown: React.FC<Props> = ({ record }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setVisible: setVisibleChat } = chatActions;
  const { severalSearchParams } = useQueryParams();
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const deleteMutation = useDeleteLaboratory();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const laboratoryActions = actions("Laboratoriya");

  const deleteLaboratory = () => deleteMutation.mutateAsync({ id: record?.id });

  const onToggleDropdown = (open: boolean) => {
    setVisibleDropdown(open);
  };

  const onClickActionIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const onOpenChat = (e: React.MouseEvent<HTMLElement>, id?: number) => {
    dispatch(
      setVisibleChat({
        visible: true,
        objectId: id,
        type: ChatEnum.LABORATORIES,
        dataKeys: [warehouseQueryNames.WAREHOUSE_LABORATORY_VIEW, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
    e.stopPropagation();
    setVisibleDropdown(false);
  };

  const dropdownItems = [
    {
      key: "0",
      label: (
        <div className="flex items-center gap-2" onClick={e => onOpenChat(e, record?.id)}>
          <UnreadMessageAction count={record?.unread_message_count} />
        </div>
      ),
      isView: true
    },
    // {
    //   key: "1",
    //   label: (
    //     <span className="flex items-center gap-2">
    //       <CopyIcon /> {t("warehouse.Nusxa olish")}
    //     </span>
    //   ),
    //   isView: true
    // },
    // {
    //   key: "2",
    //   label: (
    //     <span className="flex items-center gap-2">
    //       <EditIcon />
    //       {t("warehouse.Tahrirlash")}
    //     </span>
    //   ),
    //   isView: true
    // },
    {
      key: "3",
      label: (
        <ModalConfirm onOk={deleteLaboratory}>
          <span className="flex items-center gap-2">
            <DeleteIcon />
            {t("warehouse.O'chirish")}
          </span>
        </ModalConfirm>
      ),
      isView: true,
      permissionKey: "delete"
    }
  ];

  return (
    <div className={cx("flex h-full w-full cursor-pointer items-center")} onClick={onClickActionIcon}>
      <Dropdown
        menu={{
          items: dropdownItems?.filter(item => {
            if (item.permissionKey) return laboratoryActions[item.permissionKey] && item.isView;
            return item.isView;
          }) as MenuProps["items"]
        }}
        trigger={["click"]}
        open={visibleDropdown}
        onOpenChange={onToggleDropdown}
      >
        <UnreadMessageDots count={record?.unread_message_count} />
      </Dropdown>
    </div>
  );
};

export default ActionDropDown;
