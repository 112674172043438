import { message } from "antd";

type Props = {
  getContainer?: () => HTMLElement;
};

const MessageConfig = ({ getContainer }: Props) =>
  message.config({
    getContainer
  });

export default MessageConfig;
