import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { dayjsFormats } from "../../features/app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../features/app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../features/app/utils/constants/queryParamsKeys";
import { sliceNames } from "../../features/app/utils/constants/sliceNames";
import { PaymentModel } from "../../features/app/utils/models/payment/paymentModel";
import { PaymentTabKeys } from "../../features/payment/utils/constants/paymentTabKeys";
import { PaymentFilterKeys } from "../../features/payment/utils/enums/paymentFilterKeys";
import { PaymentOrderModel } from "../../features/payment/utils/models/paymentOrderModel";
import {
  AmountDrawerModel,
  CalendarHistoryReducerModel,
  ExchangeOrTransferDataModel,
  FormTableStateModel,
  IncomeExpenseModalModel,
  PaymentInventoryWorkModalModel,
  PaymentOrderModalModel,
  PaymentReducerModel,
  PayModal
} from "../types/PaymentReducerModel";

const initialState: PaymentReducerModel = {
  amounts_drawer: {
    visible: false,
    isProjects: false
  },
  income_expense_modal: {
    visible: false,
    isVisibleMoreInfo: false,
    type: PaymentTypeEnum.INCOME
  },
  expected_payment: {
    visible: false,
    type: PaymentTypeEnum.INCOME,
    form: {
      visible: false,
      total_amount: 0,
      expected_payments: [],
      state: {
        startDate: dayjs().format(dayjsFormats.DATE),
        count: 1,
        mountRange: 1
      }
    },
    element: {
      visible: false
    }
  },
  pay_modal: {
    visible: false,
    type: PaymentTypeEnum.INCOME
  },
  paymentOrderModal: {
    visible: false
  },
  paymentInventoryWorkModal: { visible: false },
  incomeOrExpenseView: { visible: false, record: undefined, isNotDelete: true },
  firstTabKey: `${queryParamsKeys.STATISTICS_TYPE}=${PaymentTabKeys.INCOME_EXPENSE}`,
  firstStatisticsType: {
    first: PaymentTabKeys.INCOME_EXPENSE,
    second: ""
  },
  selectIncomeExpense: [],
  selectOrders: [],
  calendarHistory: {
    visible: false,
    tabKey: PaymentFilterKeys.ORDER,
    minDate: "",
    maxDate: ""
  },
  orderView: {
    orderId: 0,
    visible: false
  },
  expectedPaymentView: {
    visible: false,
    isNotPay: false
  },
  estimateDrawer: {
    visible: false,
    projectId: 0
  },
  reportPayment: {
    visible: false
  },
  orderDeleteArchive: {
    visible: false,
    orderId: 0
  },
  transfer: {
    visible: false
  },
  transferCheck: {
    visible: false
  },
  exchange: {
    visible: false
  }
};

const paymentSlice = createSlice({
  name: sliceNames.PAYMENT,
  initialState,
  reducers: {
    setPaymentAmount: (state, action: PayloadAction<AmountDrawerModel>) => {
      state.amounts_drawer = action.payload;
    },
    setIncomeExpenseModal: (state, action: PayloadAction<IncomeExpenseModalModel>) => {
      state.income_expense_modal = action.payload;
    },
    setVisibleExpectedPayment: (
      state,
      action: PayloadAction<{
        visible: boolean;
        type: PaymentTypeEnum;
        date?: string;
      }>
    ) => {
      state.expected_payment.visible = action.payload.visible;
      state.expected_payment.type = action.payload.type;
      state.expected_payment.date = action.payload.date;
    },
    setVisibleFormTable: (state, action: PayloadAction<boolean>) => {
      state.expected_payment.form.visible = action.payload;
    },
    setVisibleAppendElement: (state, action: PayloadAction<boolean>) => {
      state.expected_payment.element.visible = action.payload;
    },
    setAmountFormTable: state => {
      state.expected_payment.form.total_amount =
        state.expected_payment.form.expected_payments?.reduce((first, second) => second.amount + first, 0) ?? 0;
    },
    setAppendOneElement: (state, action: PayloadAction<{ date: string; amount: number; id: number }>) => {
      state.expected_payment.form.expected_payments = [
        ...(state.expected_payment.form.expected_payments ?? []),
        action.payload
      ];
    },
    setDataFormTable: (state, action: PayloadAction<{ date: string; amount: number; id: number }[]>) => {
      state.expected_payment.form.expected_payments = [...action.payload];
    },
    setDeleteElement: (state, action: PayloadAction<number>) => {
      state.expected_payment.form.expected_payments = state.expected_payment.form.expected_payments?.filter(
        (_, index) => index !== action.payload
      );
    },
    setClearDataFormTable: state => {
      state.expected_payment.form.expected_payments = [];
    },
    setFormTableState: (state, action: PayloadAction<FormTableStateModel>) => {
      state.expected_payment.form.state = action.payload;
    },
    setPayModal: (state, action: PayloadAction<PayModal>) => {
      state.pay_modal = action.payload;
    },
    setPaymentOrderModal: (state, action: PayloadAction<PaymentOrderModalModel>) => {
      state.paymentOrderModal = action.payload;
    },
    setPaymentInventoryWorkModal: (state, action: PayloadAction<PaymentInventoryWorkModalModel>) => {
      state.paymentInventoryWorkModal = action.payload;
    },
    setIncomeOrExpenseViewVisible: (state, action: PayloadAction<PaymentReducerModel["incomeOrExpenseView"]>) => {
      state.incomeOrExpenseView = action.payload;
    },
    setFirstTabKey: (state, action: PayloadAction<string>) => {
      state.firstTabKey = action.payload;
    },
    setFirstStatisticsType: (
      state,
      action: PayloadAction<{
        first: string;
        second?: string;
      }>
    ) => {
      state.firstStatisticsType.first = action.payload.first;
      state.firstStatisticsType.second = action.payload?.second;
    },
    setSelectIncomeExpense: (state, action: PayloadAction<PaymentModel[]>) => {
      state.selectIncomeExpense = action.payload;
    },
    setSelectIncomeExpenseItem: (state, action: PayloadAction<PaymentModel>) => {
      const newSelectPayment = [...state.selectIncomeExpense];

      const isNotParty = newSelectPayment?.some(item => item.id === action.payload.id);

      if (!isNotParty) {
        newSelectPayment.push(action.payload);
      }

      state.selectIncomeExpense = newSelectPayment;
    },
    setSelectOrder: (state, action: PayloadAction<PaymentOrderModel[]>) => {
      state.selectOrders = action.payload;
    },
    setSelectOrderItem: (state, action: PayloadAction<PaymentOrderModel>) => {
      const newSelectOrder = [...state.selectOrders];

      const isNotParty = newSelectOrder?.some(item => item.id === action.payload.id);

      if (!isNotParty) {
        newSelectOrder.push(action.payload);
      }

      state.selectOrders = newSelectOrder;
    },
    setCalendarHistory: (state, action: PayloadAction<CalendarHistoryReducerModel>) => {
      state.calendarHistory = action.payload;
    },
    setCalendarHistoryPage: (state, action: PayloadAction<number>) => {
      state.calendarHistory.page = action.payload;
    },
    setCalendarHistoryTab: (state, action: PayloadAction<PaymentFilterKeys>) => {
      state.calendarHistory.tabKey = action.payload;
      state.calendarHistory.page = 1;
    },
    setOrderView: (state, action: PayloadAction<{ orderId: number; visible: boolean }>) => {
      state.orderView = action.payload;
    },
    setExpectedPaymentView: (state, action: PayloadAction<PaymentReducerModel["expectedPaymentView"]>) => {
      state.expectedPaymentView = action.payload;
    },
    setEstimateDrawer: (
      state,
      action: PayloadAction<{
        projectId?: number;
        visible: boolean;
        task?: {
          id: number;
          name?: string;
          index: number;
          sectionName?: string;
        };
      }>
    ) => {
      state.estimateDrawer = action.payload;
    },
    setIncomeExpenseTask: (
      state,
      action: PayloadAction<
        { id: number; name?: string; sectionName?: string; projectId?: number; index: number } | undefined
      >
    ) => {
      state.income_expense_modal.task = action.payload;
    },
    setEstimateTask: (
      state,
      action: PayloadAction<
        { id: number; name?: string; sectionName?: string; projectId?: number; index: number } | undefined
      >
    ) => {
      state.estimateDrawer.task = action.payload;
    },
    setPaymentMoreInfo: (state, action: PayloadAction<boolean>) => {
      state.income_expense_modal.isVisibleMoreInfo = action.payload;
    },
    setReportPayment: (state, action: PayloadAction<boolean>) => {
      state.reportPayment.visible = action.payload;
    },
    setConversionInfoModal: (state, action: PayloadAction<ExchangeOrTransferDataModel | undefined>) => {
      state.conversionInfoModal = action.payload;
    },
    setTransferHistoryModal: (state, action: PayloadAction<ExchangeOrTransferDataModel | undefined>) => {
      state.transferHistoryModal = action.payload;
    },
    setOrderDeleteArchive: (state, action: PayloadAction<PaymentReducerModel["orderDeleteArchive"]>) => {
      state.orderDeleteArchive = action.payload;
    },
    setTransfer: (state, action: PayloadAction<PaymentReducerModel["transfer"]>) => {
      state.transfer = action.payload;
    },
    setTransferCheck: (state, action: PayloadAction<PaymentReducerModel["transferCheck"]>) => {
      state.transferCheck = action.payload;
    },
    setExchange: (state, action: PayloadAction<PaymentReducerModel["exchange"]>) => {
      state.exchange = action.payload;
    }
  }
});

export default paymentSlice.reducer;
export const paymentReducerActions = paymentSlice.actions;
