import { FC, MouseEvent, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import { TaskHeader } from "../header/TaskHeader";

import styles from "../task.module.scss";

type Props = {
  sectionId: number;
};

export const TaskAppend: FC<Props> = ({ sectionId }) => {
  const [creating, setCreating] = useState(false);
  const { t } = useTranslation();

  const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onOpenForm = (e: MouseEvent<HTMLDivElement>) => {
    stopPropagation(e);

    if (!creating) setCreating(true);
  };

  const onCloseForm = useCallback(() => {
    if (creating) setCreating(false);
  }, [creating]);

  useEffect(() => {
    window.addEventListener("click", onCloseForm);

    return () => {
      window.removeEventListener("click", onCloseForm);
    };
  }, [onCloseForm]);

  return (
    <div className={styles.task_append}>
      {creating ? (
        <div onClick={stopPropagation} className={classNames(styles.create, { [styles.active]: creating })}>
          <TaskHeader
            create={{
              sectionId,
              afterSuccess: onCloseForm
            }}
          />
        </div>
      ) : (
        <div onClick={onOpenForm} className={classNames(styles.button, { [styles.active]: !creating })}>
          <PlusCircleIcon fill />
          <p>{t("project.Ish qo'shish")}</p>
        </div>
      )}
    </div>
  );
};
