import { ReactNode } from "react";
import { CustomFieldTypesEnum } from "../../../app/utils/enums/customFieldTypesEnum";
import FileAttachmentIcon from "../../../payment/assets/icons/FileAttachmentIcon";
import CustomFieldDateIcon from "../../assets/icons/CustomFieldDateIcon";
import TypeSquareIcon from "../../assets/icons/TypeSquareIcon";
import ArrowSquareDownIcon from "../../assets/icons/ArrowSquareDownIcon";
import CheckSquareIcon from "../../assets/icons/CheckSquareIcon";
import TagIcon from "../../assets/icons/TagIcon";

export const CustomFieldType: Record<string, ReactNode> = {
  [CustomFieldTypesEnum.FILE]: <FileAttachmentIcon />,
  [CustomFieldTypesEnum.DATE]: <CustomFieldDateIcon />,
  [CustomFieldTypesEnum.CHECKBOX]: <CheckSquareIcon />,
  [CustomFieldTypesEnum.TEXT]: <TypeSquareIcon />,
  [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
  [CustomFieldTypesEnum.LABEL]: <TagIcon />,
};

export const CustomFieldOptions: Record<
  string,
  { name: Record<string, string>; icon: ReactNode }
> = {
  [CustomFieldTypesEnum.FILE]: {
    name: {
      uz: "Fayl",
      ru: "файл",
      en: "File",
    },
    icon: <FileAttachmentIcon />,
  },
  [CustomFieldTypesEnum.DATE]: {
    icon: <CustomFieldDateIcon />,
    name: {
      uz: "Sana",
      ru: "дата",
      en: "Date",
    },
  },
  [CustomFieldTypesEnum.CHECKBOX]: {
    icon: <CheckSquareIcon />,
    name: {
      uz: "Belgilash katagi",
      ru: "флажок",
      en: "Checkbox",
    },
  },
  [CustomFieldTypesEnum.TEXT]: {
    icon: <TypeSquareIcon />,
    name: {
      uz: "Yozuv",
      ru: "текст",
      en: "Text",
    },
  },
  [CustomFieldTypesEnum.SELECT]: {
    icon: <ArrowSquareDownIcon />,
    name: {
      uz: "Tanlash",
      ru: "выбирать",
      en: "Select",
    },
  },
  [CustomFieldTypesEnum.LABEL]: {
    icon: <TagIcon />,
    name: {
      uz: "Teg",
      ru: "этикетка",
      en: "Label",
    },
  },
};
