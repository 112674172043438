import React from "react";

const DotsVerticalIcon: React.FC = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.75 9.75C10.1642 9.75 10.5 9.41421 10.5 9C10.5 8.58579 10.1642 8.25 9.75 8.25C9.33579 8.25 9 8.58579 9 9C9 9.41421 9.33579 9.75 9.75 9.75Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 4.5C10.1642 4.5 10.5 4.16421 10.5 3.75C10.5 3.33579 10.1642 3 9.75 3C9.33579 3 9 3.33579 9 3.75C9 4.16421 9.33579 4.5 9.75 4.5Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.75 15C10.1642 15 10.5 14.6642 10.5 14.25C10.5 13.8358 10.1642 13.5 9.75 13.5C9.33579 13.5 9 13.8358 9 14.25C9 14.6642 9.33579 15 9.75 15Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DotsVerticalIcon;
