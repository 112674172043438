import React from "react";

const ArchiveIcon: React.FC = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.83329 6.66383C3.69662 6.6603 3.59737 6.65239 3.50814 6.63464C2.84699 6.50313 2.33016 5.9863 2.19865 5.32515C2.16663 5.16415 2.16663 4.97055 2.16663 4.58333C2.16663 4.19612 2.16663 4.00251 2.19865 3.84152C2.33016 3.18037 2.84699 2.66354 3.50814 2.53202C3.66914 2.5 3.86275 2.5 4.24996 2.5H16.75C17.1372 2.5 17.3308 2.5 17.4918 2.53202C18.1529 2.66354 18.6698 3.18037 18.8013 3.84152C18.8333 4.00251 18.8333 4.19612 18.8333 4.58333C18.8333 4.97055 18.8333 5.16415 18.8013 5.32515C18.6698 5.9863 18.1529 6.50313 17.4918 6.63464C17.4025 6.65239 17.3033 6.6603 17.1666 6.66383M8.83329 10.8333H12.1666M3.83329 6.66667H17.1666V13.5C17.1666 14.9001 17.1666 15.6002 16.8941 16.135C16.6545 16.6054 16.272 16.9878 15.8016 17.2275C15.2668 17.5 14.5668 17.5 13.1666 17.5H7.83329C6.43316 17.5 5.7331 17.5 5.19832 17.2275C4.72791 16.9878 4.34546 16.6054 4.10578 16.135C3.83329 15.6002 3.83329 14.9001 3.83329 13.5V6.66667Z"
        stroke="#344054" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArchiveIcon;