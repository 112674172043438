import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { useGetPartImpost } from "features/impost/service/queries";
import { impostTableDefaultData } from "features/impost/utils/constants/impostTableDefaultData";
import { PartImpostModel } from "features/impost/utils/models/partImpostModel";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";

import { supplyActions } from "store/reducers/supplyReducer";

import { RU } from "modules/common";
import { ConfirmationOrderView } from "modules/dashboard";

import styles from "./parts.module.scss";

interface IParts {
  data: PaginationType<ConfirmationOrderView[]> | undefined;
  isLoading: boolean;
}

const Parts: React.FC<IParts> = ({ data: orders, isLoading: isLoadingOrders }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setPartyModal } = supplyActions;
  const { visible, tableData: data } = useAppSelector(state => state.supplyReducer.partyModal);
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.IMPOST_ORDER_CONFIG, impostTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.ORDER]);

  useGetPartImpost(visible, data?.id);

  const onDetailPart = (record: ConfirmationOrderView) => ({
    onClick: () => {
      dispatch(
        setPartyModal({
          visible: true,
          party_id: record?.id,
          isEditing: true,
          tableData: record as unknown as PartImpostModel,
          selectProducts: record?.warehouse_products as never
        })
      );
    }
  });

  const columns: ColumnsType<ConfirmationOrderView> = [
    {
      title: `${t("Monitoring.Partya")}`,
      render: (record: ConfirmationOrderView) => <span className={styles.bold}>P-{record?.id}</span>,
      fixed: "left",
      width: "60%"
    },

    {
      title: `${t("dashboard.Sanasi")}`,
      dataIndex: "ordered_date",
      key: "ordered_date",
      width: 170
    },
    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: (record: ConfirmationOrderView) => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      ),
      key: "total_amount",
      width: 200
    },
    {
      title: "",
      render: (record: ConfirmationOrderView) => (
        <div className={styles.arrow}>
          <ArrowNarrowIcon rotate={180} />
        </div>
      )
    }
  ];

  const rowKey = (record: ConfirmationOrderView) => record.id;

  return (
    <div className={styles.parties}>
      <Table<ConfirmationOrderView>
        rowKey={rowKey}
        className={`${styles.parties__table} footer_table`}
        pagination={false}
        onRow={onDetailPart}
        dataSource={orders?.data}
        scroll={{ y: "calc(100vh - 18.125rem)" }}
        columns={columns}
        rowClassName={styles.row}
        rootClassName="cursor-pointer"
        locale={{
          emptyText: <TableEmpty />
        }}
        loading={{ spinning: isLoadingOrders, indicator: LoadingIndicator }}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: orders?.total,
            pageSize: tableConfigData?.size || 10
          }}
        />
      </div>
    </div>
  );
};

export default Parts;
