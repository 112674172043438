import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer, Form, message } from "antd";
import dayjs from "dayjs";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import Header from "features/warehouse/components/transfers/transfer-making-drawer/header/Header";
import { transformReqData } from "features/warehouse/components/transfers/transfer-making-drawer/helpers/transformReqData";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { transferMakingReducerActions, useSelector } from "store";
import { warehouseTransferActions } from "store/reducers/warehouseTransferReducer";

import { cx, dayjsFormats } from "modules/common";

import { useCreateTransfer, useUpdateTransfer } from "../../../service/mutation";
import { useGetOneTransfer, useGetWarehouseProductsInTransfers } from "../../../service/query";
import { TransferBodyModel } from "../../../utils/models/transferBodyModel";
import SelectedResourcesModal from "../selected-resources-modal/SelectedResourcesModal";

import TransferNoData from "./no-data/TransferNoData";
import SelectedProductsTable from "./selected-products-table/SelectedProductsTable";

import styles from "./transferMakingDrawer.module.scss";

const { useForm, useWatch, Item } = Form;

const TransferMakingDrawer: React.FC = () => {
  const [form] = useForm<TransferBodyModel>();
  const { queries } = useQueryParams();
  const { size } = queries();
  const { t } = useTranslation();

  const { data, visible, id } = useSelector(state => state.transferMakingReducer);
  const { setDrawerVisible } = transferMakingReducerActions;

  const { data: oneTransferProducts, isLoading: isOneTransferLoading } = useGetOneTransfer(
    data?.products && data?.products?.length === 0 ? id : undefined
  );

  const createTransfer = useCreateTransfer();
  const updateTransfer = useUpdateTransfer(id);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>();
  const warehouseId = useWatch("sender_warehouse_id", form);
  const receiverWarehouseId = useWatch("recieved_warehouse_id", form);
  const [selectedResourcesModalVisible, setSelectProductsModalVisible] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);

  const { data: warehouseProducts, isLoading } = useGetWarehouseProductsInTransfers(warehouseId, searchText, page);

  const { setSelectProducts } = warehouseTransferActions;
  const { selectProducts } = useAppSelector(state => state.warehouseTransferReducer);
  const products = useWatch("products", form) as ITransferringResourcesModel[];

  const onCancel = () => {
    dispatch(
      setDrawerVisible({
        visible: false,
        id: undefined,
        data: undefined
      })
    );
    dispatch(setSelectProducts([]));
    setPage(1);
    form.resetFields();
    form.setFieldValue("products", []);
    form.setFieldValue("selected_products", []);
    setSearchText(undefined);
  };

  const onOk = () => {
    if (warehouseId !== receiverWarehouseId) {
      const condition = products?.every(el => Number(el?.quantity) <= Number(el?.total_quantity));

      form.validateFields().then(() => {
        if (condition) {
          form.submit();
        }
      });
    } else {
      message.warning(t("warehouse.Jo'natuvchi omborxona va qabul qiluvchi omborxona bir xil bo'lmasligi kerak!!!"));
    }
  };

  const onOpenSelectedResourcesModal = () => {
    setSelectProductsModalVisible(true);
  };

  const filteredSelectedProducts = selectProducts?.filter(
    item => item.changed_quantity_value! <= item.selected_total_value!
  );
  const filteredProducts = products?.filter(el => el?.product_id);

  const footer = (
    <div className={cx(styles.transfer_drawer_footer, id ? "justify-end" : "justify-between")}>
      {!id && (
        <Button
          disabled={filteredSelectedProducts?.length === 0}
          className={styles.selected_ones}
          onClick={onOpenSelectedResourcesModal}
        >
          Tanlanganlar resurlar: {filteredSelectedProducts?.length}
        </Button>
      )}
      <div className={styles.button_container}>
        <Button onClick={onCancel}>Yopish</Button>
        <Button
          type="primary"
          htmlType="submit"
          loading={createTransfer.isLoading || updateTransfer.isLoading}
          onClick={onOk}
          disabled={
            !warehouseId || filteredProducts?.length === 0 || createTransfer.isLoading || updateTransfer.isLoading
          }
        >
          O'tkazma qilish
        </Button>
      </div>
    </div>
  );

  const onFinish = (values: TransferBodyModel) => {
    const req = transformReqData(values);

    if (id) {
      updateTransfer.mutateAsync(req as unknown as TransferBodyModel).then(() => {
        onCancel();
      });
    } else {
      createTransfer.mutateAsync(req as unknown as TransferBodyModel).then(() => {
        onCancel();
      });
    }
  };

  const onPageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  useEffect(() => {
    if (id) {
      if (data?.products && data?.products?.length > 0) {
        form.setFieldsValue({
          id,
          recieved_project_id: data?.recieved_project_id,
          recieved_warehouse_id: data?.recieved_warehouse_id,
          sender_warehouse_id: data?.sender_warehouse_id,
          date: data?.date ? (dayjs(data?.date, dayjsFormats.DATE) as never) : null,
          products: data?.products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.quantity),
            total_quantity: el?.total_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          })),
          selected_products: data?.products?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.quantity),
            total_quantity: el?.total_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          }))
        });
        const selectedOnes =
          data?.products && data?.products?.length > 0
            ? data?.products?.map(item => ({
                ...(item as unknown as WarehouseProductModel),
                changed_quantity_value: Number(item?.quantity),
                selected_total_value: item?.total_quantity,
                rowId: Number(item?.product?.id) - Number(item?.unit?.id)
              }))
            : [];

        dispatch(setSelectProducts(selectedOnes));
      } else {
        form.setFieldsValue({
          id,
          recieved_project_id: data?.recieved_project_id,
          recieved_warehouse_id: data?.recieved_warehouse_id,
          sender_warehouse_id: data?.sender_warehouse_id,
          date: data?.date ? (dayjs(data?.date, dayjsFormats.DATE) as never) : null,
          products: oneTransferProducts?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.initial_quantity),
            total_quantity: el?.warehouse_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          })),
          selected_products: oneTransferProducts?.map(el => ({
            product_id: `${el?.product?.id}-${el?.unit?.id}` as never,
            unit_id: el?.unit?.id,
            quantity: String(el?.initial_quantity),
            total_quantity: el?.warehouse_quantity,
            unit: el?.unit,
            product: el?.product,
            rowId: Number(el?.product?.id) - Number(el?.unit?.id)
          }))
        });
        const selectedOnes =
          oneTransferProducts && oneTransferProducts?.length > 0
            ? oneTransferProducts?.map(item => ({
                ...(item as unknown as WarehouseProductModel),
                changed_quantity_value: Number(item?.initial_quantity),
                selected_total_value: item?.warehouse_quantity,
                rowId: Number(item?.product?.id) - Number(item?.unit?.id)
              }))
            : [];

        dispatch(setSelectProducts(selectedOnes));
      }
    }
  }, [id, data, form, dispatch, oneTransferProducts]);

  useEffect(() => {
    setPage(1);
  }, [size]);

  return (
    <Drawer
      title="O'tkazma qilish"
      footer={footer}
      onClose={onCancel}
      open={visible}
      rootClassName={styles.transfer_drawer}
    >
      <div className={styles.body}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Item shouldUpdate name="products" className="hidden" initialValue={[]} />
          <Item shouldUpdate name="selected_products" className="hidden" initialValue={[]} />
          <div className={styles.form_content}>
            <Header form={form} setPage={setPage} />
            <ConditionalRender if={warehouseId}>
              <Item shouldUpdate>
                <SelectedProductsTable
                  isLoading={
                    !id ? isLoading : data?.products && data?.products?.length === 0 ? isOneTransferLoading : false
                  }
                  form={form}
                  createTransfer={createTransfer}
                  data={warehouseProducts}
                  onPageChange={onPageChange}
                  page={page}
                  setPage={setPage}
                  setSearchText={setSearchText}
                />
              </Item>
            </ConditionalRender>
            <ConditionalRender if={!warehouseId}>
              <div className={styles.no_data_container}>
                <TransferNoData />
              </div>
            </ConditionalRender>
          </div>
        </Form>
        <SelectedResourcesModal
          form={form}
          visible={selectedResourcesModalVisible}
          setVisible={setSelectProductsModalVisible}
        />
      </div>
    </Drawer>
  );
};

export default TransferMakingDrawer;
