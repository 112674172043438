import { PermissionKey } from "features/app/utils/models/permissionModel";

import { useAuth } from "modules/auth/hooks";

export interface SubmoduleActions {
  [dynamicActionKey: string]: boolean | string;
  key: string;
}

export interface AllSubmodules {
  key: string;
  tabKey?: string;
  actions: SubmoduleActions[];
}

type UnionType<TModules extends AllSubmodules, TSubKey extends TModules["key"]> = TModules extends { key: infer K }
  ? K extends TSubKey
    ? { key: K; actions: TModules["actions"] }
    : never
  : never;

type ExtractSubmoduleByKey<TModules extends AllSubmodules, TSubKey extends TModules["key"]> = Extract<
  UnionType<TModules, TSubKey>,
  { key: TModules["key"] }
>;

type ActionKeys<T> = T extends { actions: (infer A)[] }
  ? A extends { key: any; [key: string]: infer K }
    ? Exclude<keyof A, "key">
    : never
  : never;

type ActionsType<TModules extends Array<AllSubmodules>, TSubKey extends TModules[number]["key"]> = {
  [K in ActionKeys<ExtractSubmoduleByKey<TModules[number], TSubKey>>]: boolean;
};

export const useRoutePermissions = <TModule extends Array<AllSubmodules>>(
  permissionKey: PermissionKey,
  allSubmodules: TModule
) => {
  const { permissions: allPermissions } = useAuth();
  const permissions = allPermissions?.find(item => item.name.uz === permissionKey);

  const submodules: TModule =
    permissions?.submodules?.reduce(
      (acc, item) => {
        const foundSubmodule = allSubmodules.find(submodule => submodule.key === item.name.uz);

        if (foundSubmodule) {
          acc.push(foundSubmodule);
        }
        return acc;
      },
      [] as unknown as TModule
    ) || ([] as unknown as TModule);

  const actions = <K extends TModule[number]["key"]>(key: K) => {
    const findCustomSubmodule = allSubmodules.find(item => item.key === key);

    if (!findCustomSubmodule) return {} as ActionsType<TModule, typeof key>;

    const submodulePermissions = permissions?.submodules?.find(item => item.name.uz === key);

    if (!submodulePermissions) return {} as ActionsType<TModule, typeof key>;

    return submodulePermissions.actions.reduce(
      (acc, action) => {
        const matchingAction = findCustomSubmodule.actions.find(a => a.key === action.name.uz);

        if (matchingAction) {
          const { key, ...rest } = matchingAction;

          acc = { ...acc, ...rest };
        }
        return acc;
      },
      {} as ActionsType<TModule, typeof key>
    );
  };

  return {
    permissions,
    submodules,
    actions
  };
};
