import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoComplete } from "antd";
import { DefaultOptionType } from "antd/es/select";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../../store/reducers/companyPersonReducer";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetCompanyPersonSelect } from "../../../../../../../counterparts/service/queries";
import { OfferPartyErrorModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  uniqueId: string;
  isViewCompanyPerson?: boolean;
};

const OfferPartyPersonSelect: React.FC<Props> = ({ uniqueId, isViewCompanyPerson }) => {
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;
  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;
  const { leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);
  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const { data: companyPerson } = useGetCompanyPersonSelect();

  useLayoutEffect(() => {
    setSearchValue(leftData?.find(item => item.unique_id === uniqueId)?.company_person?.name);
  }, []);

  const leftDataItem = useMemo(
    () => leftData?.find(item => item.unique_id === uniqueId)?.company_person,
    [leftData, uniqueId]
  );

  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, uniqueId) || !leftDataItem?.id ? styles.item__error : "";

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  const onSelectCompanyPerson = (value: string, option: DefaultOptionType) => {
    dispatch(
      setOfferPartyLefItemData({
        key: "company_person",
        value: {
          id: Number(value),
          name: option?.props?.name
        },
        uniqueId
      })
    );

    setSearchValue(option?.props?.name);

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "companyPerson",
          uniqueId,
          action: !Number(value)
        })
      );
    }
  };

  const onAddCompanyPerson = () => {
    dispatch(
      setVisibleModal({
        visible: true,
        initialData: {
          name: searchValue,
          phone: leftDataItem?.phone
        },
        onAfterFunc: (data: { id: number; name: string; phone: string }) => {
          dispatch(
            setOfferPartyLefItemData({
              value: {
                id: data?.id,
                name: data?.name,
                phone: data?.phone
              },
              uniqueId,
              key: "company_person"
            })
          );

          if (partyErrors?.length > 0) {
            dispatch(
              setOfferPartyModalErrorItem({
                key: "companyPerson",
                uniqueId,
                action: !Number(data?.id)
              })
            );
          }
        }
      })
    );
  };

  return (
    <div className={`${styles.item} ${errorClassName("companyPerson")}`}>
      {/* <Select */}
      {/*  showSearch */}
      {/*  suffixIcon={null} */}
      {/*  placeholder="Ta'minotchi" */}
      {/*  onChange={onChangePerson} */}
      {/*  popupMatchSelectWidth={false} */}
      {/*  disabled={!isViewCompanyPerson || !!leftDataItem?.company_person_id} */}
      {/*  filterOption={selectFilterOption} */}
      {/*  value={leftDataItem?.company_person_id} */}
      {/*  notFoundContent={ */}
      {/*    <TableEmpty description="Siz qidirgan ta'minotchi mavjud emas" /> */}
      {/*  } */}
      {/* > */}
      {/*  {companyPerson?.map((item) => ( */}
      {/*    <Option */}
      {/*      key={item.id} */}
      {/*      value={item.id} */}
      {/*      props={{ */}
      {/*        name: item?.person?.name, */}
      {/*      }} */}
      {/*    > */}
      {/*      {item?.person?.name} */}
      {/*    </Option> */}
      {/*  ))} */}
      {/* </Select> */}
      <AutoComplete
        showSearch
        suffixIcon={null}
        onSearch={onSearch}
        onChange={onSearch}
        value={searchValue}
        placeholder="Ta'minotchi"
        popupMatchSelectWidth={false}
        onSelect={onSelectCompanyPerson}
        filterOption={selectFilterOption}
        disabled={!isViewCompanyPerson || !!leftDataItem?.id}
        options={companyPerson?.map(item => ({
          label: item?.name,
          value: item?.id,
          props: {
            name: item?.name
          }
        }))}
        notFoundContent={<SelectNotContent title="Ta'minotchi" action={onAddCompanyPerson} />}
      />
    </div>
  );
};

export default OfferPartyPersonSelect;
