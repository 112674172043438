import React from "react";
import { Form, FormInstance, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { useGetUnitSelect } from "../../../../../../../app/service/queries";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { StatusEnums } from "../../../../../../utils/enums/statusEnums";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  index: number;
  groupIndex: number;
  form: FormInstance;
};

const PersonUnitSelect: React.FC<Props> = ({ index, form, groupIndex }) => {
  const { i18n } = useTranslation();
  const { data: units } = useGetUnitSelect();
  const fieldName = ["person_group", groupIndex, "data", index, "unit_id"];

  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const status = useWatch("status", form) as StatusEnums;

  const onChangeUnit = () => (_: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const unitOption = option as DefaultOptionType;

    if (!unitOption?.props?.isPiece) {
      const oldFormQuantity = form.getFieldValue(fieldName);
      const oldQuantity = parseLocaledString(oldFormQuantity ? String(oldFormQuantity) : "0");

      form.setFieldValue(fieldName, Math.round(oldQuantity));
    }

    form.setFieldValue(["person_group", groupIndex, "data", index, "unit_symbol"], unitOption?.props?.name);
  };

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  // const selectedId = () => form.getFieldValue(fieldName);
  // const selected = () => units?.find(item => item?.id === selectedId());

  return (
    <>
      {/* <PerfItem */}
      {/*  visible={!(isView && disabled())} */}
      {/*  placeholder={<div className="p-[0.62rem]">{selected()?.symbol[i18n.language]}</div>} */}
      {/* > */}
      <div>
        <Item name={fieldName} rules={formRules()}>
          <Select
            showSearch
            suffixIcon={null}
            placeholder="Birligi"
            onChange={onChangeUnit}
            popupMatchSelectWidth={false}
            disabled={isView && disabled()}
            filterOption={selectFilterOption}
            notFoundContent={<TableEmpty description="Kerakli resurs topilmadi" />}
          >
            {units?.map(item => (
              <Option
                key={item.id}
                value={item.id}
                props={{
                  name: item?.symbol[i18n.language],
                  isPiece: item?.is_piece
                }}
              >
                {item.symbol[i18n.language]}
              </Option>
            ))}
          </Select>
        </Item>
        <Item name={["person_group", groupIndex, "data", index, "unit_symbol"]} className="hidden" />
      </div>
      {/* </PerfItem> */}
    </>
  );
};

export default PersonUnitSelect;
