export const getAmountStatus = (debt_amount: number, amount: number) => {
  switch (amount - debt_amount) {
    case amount:
      return {
        color: "#027A48",
        background: "#ECFDF3",
        title: "To'langan",
      };
    case 0:
      return {
        color: "#B42318",
        background: "#FEF3F2",
        title: "To'lanmangan",
      };
    default:
      return {
        color: "#175CD3",
        background: "#EFF8FF",
        title: "Qisman to'langan",
      };
  }
};