import React from "react";
import { useDispatch } from "react-redux";
import { Col, Radio, Row } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";
import PopoverShortName from "../../../../../../app/components/popover-short-name/PopoverShortName";
import { SectionModel } from "../../../../../../projects/utils/models/sectionModel";

import styles from "./estimateDrawer.module.scss";

const EstimateDrawerSection: React.FC<SectionModel> = ({ name, tasks, place }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { setEstimateTask } = paymentReducerActions;
  const { task, projectId } = useAppSelector(state => state.paymentReducer.estimateDrawer);

  const onChangeTask = (id: number, taskName?: string) => () => {
    dispatch(
      setEstimateTask({
        id,
        projectId,
        index: place,
        name: taskName,
        sectionName: name
      })
    );
  };

  return (
    <div className={styles.section}>
      <h4>
        {place}. {name}
      </h4>
      <div className={styles.tasks}>
        {tasks?.map(item => (
          <Row key={item?.id} gutter={0} className={styles.tasks__item}>
            <Col span={15}>
              <Radio value={item?.id} checked={item?.id === task?.id} onChange={onChangeTask(item?.id, item?.name)}>
                <span>
                  {place}.{item?.place}{" "}
                </span>
                <PopoverShortName length={60} title={item?.name} />
              </Radio>
            </Col>
            <Col span={3}>{item?.quantity}</Col>
            <Col span={3}>
              <PopoverShortName length={12} title={item?.unit?.symbol?.[i18n.language]} />
            </Col>
            <Col span={3}>{item?.total_amount}</Col>
          </Row>
        ))}
      </div>
    </div>
  );
};

export default EstimateDrawerSection;
