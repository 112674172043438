import React from "react";
import { Form, Segmented } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { PaymentTypeEnum } from "../../../../../../../app/utils/constants/paymentTypeEnum";
import { cx } from "../../../../../../../app/utils/helpers/cx";
import { INCOME_EXPENSE_TYPE } from "../../../../../../utils/enums/incomeExpenseTypeEnums";
import { UZ } from "modules/common";

const { Item } = Form;

const IncomeExpenseTypeItem: React.FC = () => {
  const { t } = useTranslation();
  const { type } = useAppSelector(state => state.paymentReducer.income_expense_modal);
  const { permissions } = useAuth();

  return (
    <Item
      rules={formRules()}
      name="income_expense_type"
      initialValue={INCOME_EXPENSE_TYPE.financial_id}
      label={`${type === PaymentTypeEnum.EXPENSE ? t("payment.Chiqim") : t("payment.Kirim")} ${t("payment.manbasi")}`}
    >
      <Segmented
        className={cx("customeSegmented")}
        options={[
          {
            value: INCOME_EXPENSE_TYPE.financial_id,
            label: type === PaymentTypeEnum.EXPENSE ? t("payment.Xarajat turi") : t("payment.Daromat turi")
          },
          {
            value: INCOME_EXPENSE_TYPE.company_person_id,
            label: t("payment.Kontragent"),
            permissionName: "Kontragent"
          },
          {
            ...(type === PaymentTypeEnum.EXPENSE
              ? {
                  value: INCOME_EXPENSE_TYPE.inventory_id,
                  label: t("payment.Inventar"),
                  permissionName: "Inventar"
                }
              : { value: "", label: "" })
          }
        ].filter(item => {
          if (item?.permissionName) {
            const foundOne = permissions?.find(permission => permission?.name?.[UZ] === item?.permissionName);

            return !!foundOne;
          }
          return item.value;
        })}
        size="large"
      />
    </Item>
  );
};

export default IncomeExpenseTypeItem;
