import { RU } from "../../../../utils/constants/languages";
import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const PaymentTransferRecieved = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/payment", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.payment?.income_cash?.name}{" "}
        <span className={styles.name}>
          {record?.payment?.amount?.toLocaleString(RU)}{" "}
          {record?.payment?.currency?.symbol}
        </span>{" "}
        miqdorda pul mablag’ini qabul qildi.
      </p>
    </div>
  );
};

export default PaymentTransferRecieved;
