import React from "react";
import { Input } from "antd";

import { generalEditArgKeys } from "../../../utils/constants/generalEditData";

type Props = {
  type: "amount" | "text";
  argKey: generalEditArgKeys;
  setReturnData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingInput: React.FC<Props> = ({ type, argKey, setReturnData }) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setReturnData(
      (old) =>
        old?.map((item) => ({
          ...(item as object),
          [argKey]: type === "amount" ? Number(value) : value,
        }))
    );
  };

  return type === "amount" ? (
    <Input type="number" placeholder="Kiriting" onChange={onChange} />
  ) : (
    <Input placeholder="Kiriting" onChange={onChange} />
  );
};

export default GeneralEditingInput;
