import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { RU } from "../../../../../app/utils/constants/languages";
import { useGetHistoryOrder } from "../../../../service/query";

import AdditionalTable from "./AdditionalTable";

import styles from "./additionalModalLeft.module.scss";
import React, { Dispatch, SetStateAction } from "react";
import { isEmptyObj } from "modules/common";

type Props = {
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
};

const AdditionalLeft: React.FC<Props> = ({ selectedIds, setSelectedIds }) => {
  const { i18n,t } = useTranslation();
  const { data: record, order_id } = useAppSelector(state => state.warehouseOrderReducer.additional);
  const { data } = useGetHistoryOrder(record?.id);

  return (
    <div className={styles.left}>
      {!order_id && (
        <div className={styles.top}>
          <div className={styles.name}>
            <label>{t("warehouse.Nomi")}</label>
            <div className={styles.name__bottom}>
              <div className="resource">
                <div
                  style={{
                    borderColor: data?.product?.resource?.color,
                    color: data?.product?.resource?.color
                  }}
                >
                  {data?.product?.resource?.symbol[i18n.language]}
                </div>
                <span>{data?.product?.name[i18n.language]}</span>
              </div>
            </div>
          </div>
          <div className={styles.counts}>
            <div className={styles.counts__item}>
              <p>{t("warehouse.Buyurtma qilingan")}</p>
              <h2>
                {data?.quantity?.toLocaleString(RU) ?? 0} <span>{data?.unit?.symbol[i18n.language]}</span>
              </h2>
            </div>
            <div className={styles.counts__item}>
              <p>{t("warehouse.Qabul qilingan")}</p>
              <h2>
                {data?.recieved_quantity?.toLocaleString(RU) ?? 0} <span>{data?.unit?.symbol[i18n.language]}</span>
              </h2>
            </div>
            <div className={styles.counts__item}>
              <p>{t("warehouse.Jarayon")}</p>
              <h2>
                {!isEmptyObj(data) && ((data!.recieved_quantity / data!.quantity) * 100).toFixed(2)} <span>%</span>
              </h2>
            </div>
          </div>
        </div>
      )}
      <p className={styles.table__title}>{t("warhouse.Qabul tarixi")}</p>
      <AdditionalTable selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
    </div>
  );
};

export default AdditionalLeft;
