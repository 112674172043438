import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, FormInstance, Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { companyPersonActions } from "../../../../../../../../store/reducers/companyPersonReducer";
import SelectNotContent from "../../../../../../../app/components/select-not-content/SelectNotContent";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetCompanyPersonSelect } from "../../../../../../../counterparts/service/queries";

const { Item } = Form;
const { Option } = Select;

type Props = {
  form: FormInstance;
  index: number;
  groupIndex: number;
};

const PersonSelect: React.FC<Props> = ({ index, groupIndex, form }) => {
  const dispatch = useDispatch();
  const { setVisibleModal } = companyPersonActions;
  const filedName = ["person_group", groupIndex, "data", index, "company_person_id"];

  const [searchValue, setSearchValue] = useState<string | undefined>("");

  const { visible, isView, isEditing, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: companyPerson } = useGetCompanyPersonSelect(visible);

  const onAddCompanyPerson = () => {
    dispatch(
      setVisibleModal({
        visible: true,
        initialData: {
          name: searchValue,
          phone: ""
        },
        onAfterFunc: (data: { id: number }) => {
          form.setFieldValue(filedName, data?.id);
        }
      })
    );
  };

  const onSearch = (e: string) => {
    setSearchValue(e);
  };

  // const selectedId = () => form.getFieldValue(filedName);
  // const selected = () => companyPerson?.find(item => item?.id === selectedId());

  return (
    <>
      {/* <PerfItem */}
      {/*  placeholder={<div className="p-[0.62rem]">{selected()?.person?.name}</div>} */}
      {/*  visible={!(isView && !updateProduct?.condition)} */}
      {/* > */}
      <Item name={filedName} rules={!isEditing ? formRules()! : []}>
        <Select
          showSearch
          suffixIcon={null}
          onSearch={onSearch}
          searchValue={searchValue}
          popupMatchSelectWidth={false}
          filterOption={selectFilterOption}
          disabled={isView && !updateProduct?.condition}
          placeholder={isView ? "Tanlanmagan" : "Ta'minotchi"}
          notFoundContent={<SelectNotContent title="Ta'minotchi" action={onAddCompanyPerson} />}
        >
          {companyPerson?.map(item => (
            <Option
              value={item.id}
              key={item.id}
              props={{
                name: item?.name
              }}
            >
              {item.person?.name}
            </Option>
          ))}
        </Select>
      </Item>
      {/* </PerfItem> */}
    </>
  );
};

export default PersonSelect;
