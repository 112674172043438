import React from "react";

import { dailyItems } from "../../../../utils/constants/calendarItems";

import styles from "./paymentCalendarContent.module.scss";
import { useTranslation } from "react-i18next";

const PaymentCalendarHeader: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      {dailyItems?.map(item => (
        <div className={styles.header__item} key={item}>
          {t(`payment.${item?.toUpperCase()}`)}
        </div>
      ))}
    </div>
  );
};

export default PaymentCalendarHeader;
