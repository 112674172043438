import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import SelectSuffixIcon from "../../../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "../../../../../../../warehouse/service/query";

const { Option } = Select;

const HeaderWarehouseSelect: React.FC = () => {
  const dispatch = useDispatch();
  const { setOfferPartyLeftData } = supplyOfferActions;
  const { data: warehouses } = useGetWarehouseSelect(true, true);
  const { leftData } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const onChangeWarehouse = (e: number) => {
    dispatch(
      setOfferPartyLeftData(
        leftData?.map(item => ({
          ...item,
          warehouse_id: e
        }))
      )
    );
  };

  return (
    <Select
      showSearch
      value={null}
      placeholder="Omborxona"
      onChange={onChangeWarehouse}
      popupMatchSelectWidth={false}
      filterOption={selectFilterOption}
      notFoundContent={<TableEmpty />}
      suffixIcon={<SelectSuffixIcon />}
    >
      {warehouses?.map(item => (
        <Option
          key={item?.id}
          value={item?.id}
          props={{
            name: item?.name
          }}
        >
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};

export default HeaderWarehouseSelect;
