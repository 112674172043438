export const colors = {
  PRIMARY: "#1E90FF",
  GRAY_600: "#475467",
  GRAY_100: "#f2f4f7",
  ERROR_50: "#FEF3F2",
  ERROR_100: "#fee4e2",
  ERROR_500: "#F04438",
  SUCCESS_50: "#ECFDF3",
  SUCCESS_500: "#12B76A",
  SUCCESS_100: "#d1fadf",
  GRAY_50: "#F9FAFB",
  GRAY_500: "#667085",
  GRAY_700: "#344054",
  GRAY_800: "#1d2939",
  GRAY_400: "#98a2b3",
  GRAY_300: "#D0D5DD",
  WHITE: "#fff",
  WARNING_500: "#f79009",
  PRIMARY_200: "#B2DDFF",
  PRIMARY_400: "#53b1fd",
  PRIMARY_600: "#1570ef",
  PRIMARY_800: "#1849A9",
  PRIMARY_100: "#d1e9ff",
  PRIMARY_300: "#84caff",
  CUSTOM_PRIMARY: "#5654DC",
  CUSTOM_COLOR: "#F670C7",
  BLACK: "#000000",
  CUSTOM_TOOLTIP_COLOR: "#666973",
  SUCCESS_700: "#027a48"
};
