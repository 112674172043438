import React from "react";
import { Form, FormInstance, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { useGetUnitSelect } from "../../../../../../../app/service/queries";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";

const { Item, useWatch } = Form;
const { Option } = Select;

type Props = {
  index: number;
  form: FormInstance;
};

const PartyUnitSelect: React.FC<Props> = ({ index, form }) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const { data: units } = useGetUnitSelect();

  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);
  const status = useWatch("status", form) as StatusEnums;

  const onChangeUnit = (index: number) => (_: number, option: DefaultOptionType | DefaultOptionType[]) => {
    const unitOption = option as DefaultOptionType;

    if (!unitOption?.props?.isPiece) {
      const oldFormQuantity = form.getFieldValue(["warehouse_products", index, "quantity"]);
      const oldQuantity = parseLocaledString(oldFormQuantity ? String(oldFormQuantity) : "0");

      form.setFieldValue(["warehouse_products", index, "quantity"], Math.round(oldQuantity));
    }

    form.setFieldValue(["warehouse_products", index, "unit_symbol"], unitOption?.props?.name);
  };

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  // const selected = () => form?.getFieldValue(["select_products", index]);

  return (
    <>
      <Item name={[index, "unit_symbol"]} className="hidden" />
      {/* <PerfItem */}
      {/*  placeholder={<div className="px-[0.62rem] py-4">{selected()?.unit?.symbol[i18n.language]}</div>} */}
      {/*  visible={!(isView && disabled())} */}
      {/* > */}
      <Item name={[index, "unit_id"]} rules={formRules()}>
        <Select
          showSearch
          suffixIcon={null}
          placeholder="Birligi"
          popupMatchSelectWidth={false}
          onChange={onChangeUnit(index)}
          disabled={isView && disabled()}
          filterOption={selectFilterOption}
          notFoundContent={<TableEmpty description="Kerakli resurs topilmadi" />}
        >
          {units?.map(item => (
            <Option
              key={item.id}
              value={item.id}
              props={{
                name: item?.symbol[i18n.language],
                isPiece: item?.is_piece
              }}
            >
              {item.symbol[i18n.language]}
            </Option>
          ))}
        </Select>
      </Item>
      {/* </PerfItem> */}
    </>
  );
};

export default PartyUnitSelect;
