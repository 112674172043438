import React from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="box-border flex w-full bg-white px-6 py-4">
      <Row className="w-full">
        <Col span={9} className="text-sm font-medium text-gray-400">
          {t("dashboard.Ishlar va resurslar")}
        </Col>
        <Col span={2} className="text-end text-sm font-medium text-gray-400">
          {t("dashboard.Sana")}
        </Col>
        <Col span={3} className="text-end text-sm font-medium text-gray-400">
          {t("dashboard.Buyurtma")}
        </Col>
        <Col span={3} className="text-end text-sm font-medium text-gray-400">
          {t("dashboard.Hajmi")}
        </Col>
        <Col span={3} className="text-end text-sm font-medium text-gray-400">
          {t("dashboard.Birligi")}
        </Col>
        <Col span={4} className="text-end text-sm font-medium text-gray-400">
          {t("dashboard.Narxi (UZS)")}
        </Col>
      </Row>
    </div>
  );
};

export default Header;
