import React, { useEffect, useState } from "react";
import { Input } from "antd";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { SearchIcon } from "../../../../../app/assets/icons/SearchIcon";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { TimeoutModel } from "../../../../../app/utils/models/TimeoutModel";

import styles from "./left.module.scss";
import { useTranslation } from "react-i18next";

const ProductSearch: React.FC = () => {
  const { append, remove, queries } = useQueryParams();
  const { product_search: productSearch } = queries();
  const [time, setTime] = useState<TimeoutModel>();
  const [searchValue, setSearchValue] = useState(productSearch);
  const { visible } = useAppSelector(state => state.warehouseOrderReducer.view);
  const { t } = useTranslation();
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e?.currentTarget;
    const { value } = target;

    clearTimeout(time);

    setSearchValue(value);

    if (value) {
      setTime(
        setTimeout(() => {
          append(queryParamsKeys.PRODUCT_SEARCH, value);
        }, 800)
      );
    } else remove(queryParamsKeys.PRODUCT_SEARCH);
  };

  useEffect(() => {
    if (!visible) {
      setSearchValue("");
    }
  }, [visible]);

  return (
    <Input
      prefix={<SearchIcon />}
      value={searchValue}
      placeholder={t("orderModal.Qidiruv")}
      onChange={onSearch}
      className={styles.search}
    />
  );
};

export default ProductSearch;
