import React from "react";

const BankNoteIcon: React.FC = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.334 10.9999H13.4173C12.4508 10.9999 11.6673 11.7834 11.6673 12.7499C11.6673 13.7164 12.4508 14.4999 13.4173 14.4999H14.584C15.5505 14.4999 16.334 15.2834 16.334 16.2499C16.334 17.2164 15.5505 17.9999 14.584 17.9999H11.6673M14.0007 9.83325V10.9999M14.0007 17.9999V19.1666M21.0007 14.4999H21.0123M7.00065 14.4999H7.01232M2.33398 10.0666L2.33398 18.9333C2.33398 20.24 2.33398 20.8934 2.5883 21.3926C2.81201 21.8316 3.16896 22.1886 3.60801 22.4123C4.10713 22.6666 4.76053 22.6666 6.06732 22.6666L21.934 22.6666C23.2408 22.6666 23.8942 22.6666 24.3933 22.4123C24.8323 22.1886 25.1893 21.8316 25.413 21.3926C25.6673 20.8934 25.6673 20.24 25.6673 18.9333V10.0666C25.6673 8.7598 25.6673 8.1064 25.413 7.60728C25.1893 7.16823 24.8323 6.81128 24.3933 6.58757C23.8942 6.33325 23.2408 6.33325 21.934 6.33325L6.06732 6.33325C4.76053 6.33325 4.10713 6.33325 3.60801 6.58757C3.16896 6.81127 2.81201 7.16823 2.5883 7.60727C2.33398 8.1064 2.33398 8.7598 2.33398 10.0666ZM21.584 14.4999C21.584 14.8221 21.3228 15.0833 21.0007 15.0833C20.6785 15.0833 20.4173 14.8221 20.4173 14.4999C20.4173 14.1778 20.6785 13.9166 21.0007 13.9166C21.3228 13.9166 21.584 14.1778 21.584 14.4999ZM7.58398 14.4999C7.58398 14.8221 7.32282 15.0833 7.00065 15.0833C6.67848 15.0833 6.41732 14.8221 6.41732 14.4999C6.41732 14.1778 6.67848 13.9166 7.00065 13.9166C7.32282 13.9166 7.58398 14.1778 7.58398 14.4999Z"
        stroke="#475467"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BankNoteIcon;
