import { Modal, message } from "antd";
import { PropsWithChildren, useMemo, useState } from "react";

import { useAppDispatch } from "../../../../../../hooks/redux";
import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import { CheckIcon } from "../../../../../app/assets/icons/CheckIcon";
import { OrderDetailModel } from "../../../../utils/models/orderModel";
import { supplyActions } from "../../../../../../store/reducers/supplyReducer";
import { useOrderRecieved, useOrderRejected } from "../../../../service/mutations";

import styles from "./confirmModal.module.scss";

type Props = {
  id?: number;
  title: "recieved" | "rejected";
  oneOrder?: OrderDetailModel | undefined;
};

const ConfirmModal = ({
  id,
  title,
  children,
  oneOrder,
}: PropsWithChildren<Props>) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const { setPartyModal } = supplyActions;
  const orderRecieved = useOrderRecieved();
  const orderRejected = useOrderRejected();

  const onOpen = () => {
    setOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  const isSuppleir = useMemo(() => {
    if (oneOrder) {
      return oneOrder?.warehouse_products?.some(
        (item) => item?.company_person && item?.company_person?.id
      );
    } else return true;
  }, [oneOrder]);

  const onFinish = () => {
    if (title === "recieved") {
      if (isSuppleir) {
        orderRecieved.mutateAsync(id).then(() => {
          onCancel();

          dispatch(
            setPartyModal({
              visible: false,
            })
          );
        });
      } else {
        message.warning("Partiyani yopish uchun ta'minotchi bo'lishi zarur !");
      }
    } else {
      orderRejected.mutateAsync(id).then(() => {
        onCancel();

        dispatch(
          setPartyModal({
            visible: false,
          })
        );
      });
    }
  };

  const titleContent = () => {
    if (title === "recieved") {
      return (
        <div className={styles.recieved_title}>
          <div className={styles.recieved_title__inner}>
            <CheckIcon color="#1e90ff" />
          </div>
        </div>
      );
    }

    return (
      <div className={styles.rejected_title}>
        <div className={styles.rejected_title__inner}>
          <CloseIcon color="#f04438" />
        </div>
      </div>
    );
  };

  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <Modal
        centered
        open={open}
        onCancel={onCancel}
        onOk={onFinish}
        className={styles.modal_confirm}
        title={titleContent()}
        closeIcon={<CloseIcon />}
        okButtonProps={{
          danger: title === "recieved",
          loading: orderRecieved.isLoading || orderRejected.isLoading,
        }}
        okText={title === "recieved" ? "Yakunlash" : "Bekor qilish"}
        cancelText="Yopish"
      >
        <div className={styles.modal_confirm__content}>
          <h3>
            {title === "recieved" ? "Partiyani yakunlash" : "Partiyani bekor qilish"}
          </h3>
          <p>
            Partiya {title === "recieved" ? "yakunlangandan" : "bekor qilgandan"}{" "}
            so'ng unga boshqa o'zgartirish kiritib bo'lmaydi
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
