import { RU } from "../../../../../../app/utils/constants/languages";
import { diffFactAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateProductModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverTotalAmount from "../../diff-popover/DiffPopoverTotalAmount";

import styles from "../product.module.scss";

type Props = {
  product: DynamicEstimateProductModel;
  getPopoverContainer?: () => HTMLElement;
};

const SpendTotalAmount = ({ product, getPopoverContainer }: Props) => (
  <>
    {diffFactAmount(product?.total_amount, product?.spend_total_amount) !== 0 ? (
      <DiffPopoverTotalAmount
        getPopoverContainer={getPopoverContainer}
        record={product}
        isFeature={false}
        classNames={styles.total_amount}
      />
    ) : (
      <p className={styles.total_amount}>{product?.total_amount?.toLocaleString(RU) ?? 0} </p>
    )}
  </>
);

export default SpendTotalAmount;
