import React from "react";
import { useTranslation } from "react-i18next";

import { Icons } from "modules/dashboard";

const WidgetNoData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex h-[90%] w-full flex-col items-center justify-center gap-4">
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-primary-50">
        <Icons.TableIcon />
      </div>
      <div className="flex flex-col items-center justify-center gap-1">
        <span className="text-base font-semibold text-gray-900">{t("dashboard.Ma’lumotlar mavjud emas !")}</span>
        <span className="text-center text-sm font-normal text-gray-600">
          {t("dashboard.Vidjetni sozlash orqali ma’lumotlarni tahlil qilishingiz mumkun.")}
        </span>
      </div>
    </div>
  );
};

export default WidgetNoData;
