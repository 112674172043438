import React from "react";
import { useDispatch } from "react-redux";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import ConversionInfoModal from "features/app/components/conversion-info-modal/ConversionInfoModal";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import Pagination from "features/app/components/pagination/Pagination";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TransferHistoryModal from "features/app/components/transfer-history-modal/TransferHistoryModal";
import { colors } from "features/app/utils/constants/colors";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { cx } from "features/app/utils/helpers/cx";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { PaymentTypeIcon } from "features/payment/utils/helpers/paymentTypeIcon";

import { RU } from "modules/common";
import { CashStatusDetailedTable, useCashStatusDetailedTable, useWidget } from "modules/dashboard";

import { paymentReducerActions } from "../../../../../../../store/reducers/paymentReducer";

import styles from "./bottom.module.scss";
import { useTranslation } from "react-i18next";

const Bottom: React.FC = () => {
  const { detailedId, id } = useWidget();
  const dispatch = useDispatch();
  const { setIncomeOrExpenseViewVisible, setTransferHistoryModal, setConversionInfoModal } = paymentReducerActions;
  const { data, isLoading, total, current_page } = useCashStatusDetailedTable({ cashId: detailedId, id });
  const { t } = useTranslation();

  const columns: ColumnsType = [
    {
      title: `${t("dashboard.Turi")}`,
      render: (record: CashStatusDetailedTable) => PaymentTypeIcon(record.type, record.status as never)
    },
    {
      title: `${t("dashboard.Manbaa")}`,
      render: record =>
        PaymentSourceElement({
          isViewKey: false,
          record
        })
    },
    {
      title: `${t("dashboard.To'lov summasi")}`,
      render: record => (
        <span
          className={cx("text-gray-700", {
            "text-error-500": record.type === PaymentTypeEnum.EXPENSE,
            "text-success-500": record.type === PaymentTypeEnum.INCOME
          })}
        >
          {record.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </span>
      )
    },
    {
      title: `${t("dashboard.To'lov sanasi")}`,
      dataIndex: "date"
    },
    {
      title: "",
      render: () => (
        <div className={styles.arrow_icon}>
          <ArrowNarrowIcon rotate={180} stroke={colors.GRAY_500} />
        </div>
      )
    }
  ];

  const onRow = (record: CashStatusDetailedTable) => ({
    onClick: () => {
      if (record?.type === PaymentTypeEnum.EXCHANGE) {
        dispatch(setConversionInfoModal(record as never));
      } else if (record?.type === PaymentTypeEnum.TRANSFER) {
        dispatch(setTransferHistoryModal(record as never));
      } else {
        dispatch(
          setIncomeOrExpenseViewVisible({
            visible: true,
            record: record as never,
            isNotDelete: true
          })
        );
      }
    }
  });

  return (
    <div className="flex flex-col gap-3">
      <h3 className="m-0 text-lg font-medium text-gray-700">{t("dashboard.Oxirgi kirim-chiqimlar")}</h3>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        rowClassName={styles.table_row}
        onRow={onRow}
        rowKey="id"
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
      />
      <Pagination
        paginationProps={{
          total,
          current: current_page
        }}
      />
      <IncomeExpenseView />
      <TransferHistoryModal />
      <ConversionInfoModal />
    </div>
  );
};

export default Bottom;
