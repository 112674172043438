import React from "react";

import cls from "./loading.module.scss";

const Loading: React.FC = () => (
  <div className={cls.loading}>
    <div className={cls.content}>
      <div className={`${cls.item} ${cls.item__1}`} />
      <div className={`${cls.item} ${cls.item__2}`} />
      <div className={`${cls.item} ${cls.item__3}`} />
      <div className={`${cls.item} ${cls.item__4}`} />
    </div>
  </div>
);

export default Loading;
