import React from "react";

import { productSpentWidgetDetailDrawerActions, useSelector } from "store";

import { useDispatch } from "react-redux";
import { Drawer } from "antd";
import Content from "./content/content";

import styles from "./product-spent-widget-detail-drawer.module.scss";

const ProductSpentWidgetDetailDrawer: React.FC = () => {
  const dispatch = useDispatch();
  const { visible, title } = useSelector(state => state.productSpentWidgetDetailDrawerReducer);
  const { setOpenDetailDrawer } = productSpentWidgetDetailDrawerActions;

  const onClose = () => {
    dispatch(
      setOpenDetailDrawer({
        visible: false,
        props: null,
        title: null
      })
    );
  };

  return (
    <Drawer width="max-content" rootClassName={styles.detail_drawer} open={visible} onClose={onClose} title={title}>
      <Content />
    </Drawer>
  );
};

export default ProductSpentWidgetDetailDrawer;
