import React from "react";

export interface GanttContextDataState {
  sequence?: number | null;
  sequenceSectionId?: number | null;
}

export interface GanttContextData extends GanttContextDataState {
  setGanttContext: (value: GanttContextDataState) => void;
}

export const GanttContext = React.createContext<GanttContextData>({
  sequence: null,
  sequenceSectionId: null,
  setGanttContext: () => {},
});
