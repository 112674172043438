import dayjs from "dayjs";
import { useMemo } from "react";

import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import {
  GanttDateType,
  GanttDateWidth,
} from "../../../../../../../../../../../store/reducers/ganttReducer";
import { dayjsFormats } from "../../../../../../../../../../app/utils/constants/dayjsFormats";

import styles from "./ganttChartHeader.module.scss";

interface IProps {
  countOfDays: number | undefined;
  countOfMonth: number | undefined;
  countOfYear: number | undefined;
  startDate: dayjs.Dayjs | null | undefined;
  dueDate: dayjs.Dayjs | null | undefined;
}

const GanttChartHeader = ({
  countOfDays,
  countOfMonth,
  countOfYear,
  startDate,
  dueDate,
}: IProps) => {
  const { type } = useAppSelector((state) => state.ganttReducer);
  const days = useMemo(() => {
    if (countOfDays) {
      return Array.from({ length: countOfDays + 1 }).map((item, index) => ({
        date: startDate?.add(index, "day").format(dayjsFormats.DATE),
        days: startDate?.add(index, "day").date(),
      }));
    } else {
      return undefined;
    }
  }, [countOfDays, startDate]);

  const months = useMemo(() => {
    if (countOfMonth) {
      const arr = Array.from({ length: countOfMonth + 1 })?.map((item, index) =>
        index === 0
          ? startDate
          : index === countOfMonth
          ? dueDate
          : startDate?.startOf("month").add(index, "month")
      );
      return arr?.map((item, index) => ({
        date: item?.format(dayjsFormats.DATE),
        days:
          index === countOfMonth
            ? item?.date() || 0
            : (item?.endOf("month").diff(item, "day") || 0) + 1,
      }));
    } else {
      return undefined;
    }
  }, [countOfMonth, startDate, dueDate]);

  const years = useMemo(() => {
    if (countOfYear) {
      const arr = Array.from({ length: countOfYear + 1 })?.map((item, index) =>
        index === 0
          ? startDate
          : index === countOfYear
          ? dueDate
          : startDate?.startOf("year").add(index, "year")
      );
      return arr?.map((item, index) => ({
        date: item?.format(dayjsFormats.DATE),
        days:
          index === countOfYear
            ? (item?.diff(item.startOf("year"), "month") || 0) + 1
            : (item?.endOf("year").diff(item, "month") || 0) + 1,
      }));
    } else {
      return undefined;
    }
  }, [countOfYear, startDate, dueDate]);

  const today = {
    [GanttDateType.DAY]: dayjs(new Date()).diff(startDate, "day") + 1,
    [GanttDateType.MONTH]: dayjs(new Date()).diff(startDate, "month") + 1,
    [GanttDateType.YEAR]: dayjs(new Date()).diff(startDate, "year") + 1,
  };

  const getTop = {
    [GanttDateType.DAY]: months,
    [GanttDateType.MONTH]: years,
    [GanttDateType.YEAR]: undefined,
  };

  const getBottom = {
    [GanttDateType.DAY]: days,
    [GanttDateType.MONTH]: months,
    [GanttDateType.YEAR]: years,
  };

  const getDateFormat = (date: string | undefined) => {
    const obj = {
      [GanttDateType.DAY]: dayjs(date, dayjsFormats.DATE).format("DD"),
      [GanttDateType.MONTH]: dayjs(date, dayjsFormats.DATE).format("MMM"),
      [GanttDateType.YEAR]: dayjs(date, dayjsFormats.DATE).format("YYYY"),
    };

    return obj[type];
  };

  const isWeekend = (date?: string) => {
    if (type === GanttDateType.DAY) {
      const dayOfWeek = dayjs(date, dayjsFormats.DATE).day();
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        return true;
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        {getTop[type] &&
          getTop[type]?.map((item) => (
            <div
              key={item.date}
              className={styles.month}
              style={{ minWidth: item?.days * GanttDateWidth[type] }}
            >
              {item?.date}
            </div>
          ))}
      </div>
      <div className={styles.bottom}>
        {getBottom[type] &&
          getBottom[type]?.map((item, index) => (
            <div
              className={`${styles.day} ${
                isWeekend(item?.date) ? styles.weekend_day : ""
              }`}
              id={index + 1 === today[type] ? "ganttTodayDate" : ""}
              style={{
                minWidth: GanttDateWidth[type],
                maxWidth: GanttDateWidth[type],
              }}
              key={index}
            >
              {getDateFormat(item?.date)}{" "}
              {index + 1 === today[type] ? (
                <div className={styles.today} id="ganttToday"></div>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
      <div id="gantt_line_left" className={styles.line}></div>
      <div id="gantt_line_right" className={styles.line}></div>
    </div>
  );
};

export default GanttChartHeader;
