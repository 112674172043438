export const counterpartsEndPoints = {
  FOLDERS: "company-person-folder/view",
  FOLDERS_SELECT: "company-person-folder/select",
  CREATE_FOLDER: "company-person-folder/create",
  UPDATE_FOLDER: "company-person-folder/update",
  DELETE_FOLDER: "company-person-folder/delete",
  PERSONS: "company-person/view",
  CREATE_PERSON: "company-person/create",
  DELETE_PERSON: "company-person/delete",
  UPDATE_PERSON: "company-person/update",
  PERSON_SELECT: "person/select",
  COMPANY_PERSON_SELECT: "company-person/select",
  PAYMENT: "company-person/payment",
  EXPECTED_PAYMENT: "company-person/expected-payment-view",
  EXPECTED_TEMPLATE_PAYMENT: "company-person/expected-payment-template-view",
  STATISTICS: "company-person/statistics",
  INVENTORY: "company-person/inventory",
  CREATE_INVENTORY_GROUP: "inventory-folder/create",
  INVENTORY_FOLDER_SELECT: "inventory-folder/select",
  CREATE_INVENTORY: "inventory/create",
  UPDATE_INVENTORY: "inventory/update",
  DELETE_INVENTORY: "inventory/delete",
  ORDER: "company-person/order-view",
  WORK: "company-person/work-view",
  ACT: "company-person/act",
  PERSONS_OFFER: "company-person/offer-view",
  OFFER_CREATE: "company-person/offer-create",
  AMOUNT_STATISTICS: "company-person/statistics?statistics_type=general",
  CALENDAR: "company-person/calendar/view",
  EXPORT_EXCEL: "company-person/export-excel"
};
