import { Collapse, Spin } from "antd";
import { CollapseProps } from "antd/lib";
import { colors } from "features/app/utils/constants/colors";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../../app/components/not-data/NotData";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import FolderPlusIcon from "../../../assets/icons/FolderPlusIcon";
import { useGetFolders, useGetInventories } from "../../../service/queries";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import InventoryTable from "../tables/inventory-table/InventoryTable";

import CreateFolder from "./create-folder/CreateFolder";
import FolderCard from "./folder-card/FolderCard";

import emptyImage from "../../../assets/images/roller-brush.png";

import styles from "./bottom.module.scss";
import { useTranslation } from "react-i18next";

const Bottom: React.FC = () => {
  const dispatch = useAppDispatch();
  const { setCreatingFolder, setInventoryModal } = inventoryReducerActions;
  const { data: folders, isLoading: isLoadingFolder, isSuccess } = useGetFolders();
  const { data: inventories, isLoading: isLoadingInventories } = useGetInventories();
  const { actions } = useRoutePermissions("Inventar", routeSubmodules);
  const folderActions = actions("Inventarlar papkasi");
  const collapseInventoryActive = localStorage.getItem("collapseInventoryActive");
  const { t } = useTranslation()

  const creatingFolder = useAppSelector(state => state.inventoryReducer.creatingFolder);

  const afterCreateFolder = () => {
    dispatch(setCreatingFolder(false));
  };

  const onCreateInventory = () => {
    dispatch(setInventoryModal({ visible: true }));
  };

  const onCreateFolder = () => {
    dispatch(setCreatingFolder(true));
  };

  const collapseChildren = (
    <Spin spinning={isLoadingFolder} indicator={LoadingIndicator}>
      {isEmptyArr(folders) && isEmptyArr(inventories?.data) && isSuccess && !creatingFolder ? (
        <NotData
          containerClassName={styles.empty__container}
          img={{
            src: emptyImage,
            className: styles.empty_image
          }}
          name={{ text: t("inventory.Inventarlar mavjud emas") }}
          text={{
            text: t("inventory.Ushbu kategoriyaga inventarlarni qo’shish orqali inventalarni boshqaring")
          }}
          defaultButton={{
            text: `${t("Inventory.Papka qo'shish")}`,
            onClick: onCreateFolder,
            icon: <FolderPlusIcon />
          }}
          primaryButton={{
            text: `${t("Inventory.Inventar qo’shish")}`,
            onClick: onCreateInventory,
            icon: <PlusIcon />,
            className: styles.primary__button
          }}
        />
      ) : (
        <div className={styles.folders}>
          {folderActions.view && folders?.map(folder => <FolderCard {...folder} key={folder.id} />)}
          {creatingFolder && <CreateFolder afterSuccess={afterCreateFolder} />}
          {!creatingFolder && folderActions.create && (
            <div className={styles.new_folder} onClick={onCreateFolder}>
              <FolderPlusIcon />
              <span>{t("Inventory.Papka qo'shish")}</span>
            </div>
          )}
        </div>
      )}
    </Spin>
  );

  const items: CollapseProps["items"] = [
    {
      key: "1",
      label: <div className="text-lg font-medium text-gray-700">{t("Inventory.Papkalar")}</div>,
      children: collapseChildren
    }
  ];

  const onCollapseChange = (e: string | string[]) => {
    if (e.length === 1) {
      localStorage.setItem("collapseInventoryActive", e[0]);
    } else {
      localStorage.setItem("collapseInventoryActive", e[1]);
    }
  };

  return (
    <div className={styles.bottom}>
      <Collapse
        items={items}
        defaultActiveKey={collapseInventoryActive!}
        rootClassName={styles.folder_collapse}
        expandIcon={({ isActive }) => (
          <div className="flex h-7 w-7 items-center justify-center rounded-lg border border-solid border-gray-100 bg-gray-100 p-1.5">
            <ChevronDownIcon rotate={isActive} stroke={colors.GRAY_800} />
          </div>
        )}
        onChange={onCollapseChange}
      />

      <InventoryTable inventories={inventories?.data} total={inventories?.total} isLoading={isLoadingInventories} />
    </div>
  );
};

export default Bottom;
