import React from "react";
import { Button } from "antd";
import UyqurIcon from "features/app/assets/icons/UyqurIcon";
import { EN, RU, UZ } from "features/app/utils/constants/languages";
import { useTranslation } from "react-i18next";

import { Forms } from "modules/auth";

import { Input, Password } from "components";

import cls from "./right.module.scss";

const Right: React.FC = () => {
  const { t, i18n } = useTranslation();

  const onChangeLang = (locale: string) => {
    i18n.changeLanguage(locale);
    window.localStorage.setItem("locale", locale);
  };

  const activeLang = (locale: string) => {
    if (locale === i18n.language) {
      return cls.active;
    }
    return "";
  };

  return (
    <div className={cls.right}>
      <div className={cls.right__form}>
        <div className={cls.top}>
          <UyqurIcon />
          <h2>{t("login.xush_kelibsiz")}</h2>
          <p>{t("login.iltimos_hisob_malumotlarini_kiriting")}</p>
        </div>
        <Forms.Login>
          {({ control, formState: { isSubmitting } }) => (
            <div className="flex flex-col gap-2.5">
              <Input withoutMessage control={control} name="login" placeholder={t("login.loginni_kiriting")} />
              <Password withoutMessage control={control} name="password" placeholder={t("login.parolni_kiriting")} />
              <Button
                className={cls.submit}
                disabled={isSubmitting}
                loading={isSubmitting}
                type="primary"
                htmlType="submit"
              >
                {t("login.kirish")}
              </Button>
            </div>
          )}
        </Forms.Login>

        <div className={cls.right__intl}>
          <p className={`${activeLang(UZ)} ${cls.right__intl__lang}`} onClick={() => onChangeLang(UZ)}>
            O’zbek
          </p>
          <p className={cls.dots} />
          <p className={`${activeLang(RU)} ${cls.right__intl__lang}`} onClick={() => onChangeLang(RU)}>
            Русский
          </p>
          <p className={cls.dots} />
          <p className={`${activeLang(EN)} ${cls.right__intl__lang}`} onClick={() => onChangeLang(EN)}>
            English
          </p>
        </div>
      </div>
    </div>
  );
};

export default Right;
