import React from "react";
import { Tabs } from "antd";
import ConversionInfoModal from "features/app/components/conversion-info-modal/ConversionInfoModal";
import TransferHistoryModal from "features/app/components/transfer-history-modal/TransferHistoryModal";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { tabKeys } from "features/impost/utils/constants/tabKeys";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";

import { useQueryParams } from "hooks/useQueryParams";

import { Bar, useExpensePayment, useWidget } from "modules/dashboard";

import TabContent from "./tab-content/tab-content";

import styles from "./expense-payment.module.scss";
import { useTranslation } from "react-i18next";

const ExpensePayment: React.FC = () => {
  const { queries, append } = useQueryParams();
  const { t } = useTranslation();

  const { tab } = queries();

  const { id, visible, props } = useWidget();

  const comingWidgetData = props as Bar;

  const { paymentBarViewData, isLoading } = useExpensePayment({ enabled: visible, id });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = comingWidgetData?.values?.map(el => ({
    key: String(el.id),
    label: (
      <p className={styles.tab}>
        {el.name as string}
        {/* <span className={styles.tab__count}>{el.total}</span> */}
      </p>
    ),
    children: <TabContent data={paymentBarViewData} isLoading={isLoading} />
  }));

  const itemsWithAllTab = [
    {
      key: tabKeys.ALL,
      label: (
        <p className={styles.tab}>
          {t("dashboard.Barchasi")}
          {/* <span className={styles.tab__count}>{comingWidgetData?.total}</span> */}
        </p>
      ),
      children: <TabContent data={paymentBarViewData} isLoading={isLoading} />
    },
    ...(items || [])
  ];

  return (
    <div className={styles.bar}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab} items={itemsWithAllTab} onChange={onChangeTabs} />
      </div>
      <IncomeExpenseView />
      <TransferHistoryModal />
      <ConversionInfoModal />
    </div>
  );
};

export default ExpensePayment;
