import React from "react";
import { Tooltip } from "antd";
import { handleLeftOrWentDays } from "features/app/utils/helpers/handleLeftOrWentDays";

import styles from "./dateStatusWithPercent.module.scss";
import dayjs from "dayjs";
import { dayjsFormats } from "modules/common";

type Props = {
  date: string;
  percent: number;
};

const DateStatusWithPercent: React.FC<Props> = ({ date, percent }) => {
  const dateStatusClass = () => {
    const diffDate = dayjs().diff(dayjs(date, dayjsFormats.DATE), "day");

    if (percent < 100) {
      if (diffDate >= 0) {
        return styles.delivery_date__error;
      }
      if (diffDate <= 3 && diffDate >= 1) {
        return styles.delivery__error_text;
      }
      if (diffDate > 3 && diffDate <= 7) {
        return styles.delivery_date__warning;
      }
      return styles.delivery_date__success;
    }

    return styles.delivery_date__success;
  };

  if (date && percent < 100) {
    return (
      <Tooltip title={handleLeftOrWentDays(date)}>
        <div className={dateStatusClass()}>{date}</div>
      </Tooltip>
    );
  }

  if (date && percent === 100) {
    return <div className={dateStatusClass()}>{date}</div>;
  }

  return "-";
};

export default DateStatusWithPercent;
