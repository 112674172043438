import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { warehouseTransfersTableDefaultData } from "features/warehouse/utils/constants/warehouseTransfersTableDefaultData";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { transferMakingReducerActions } from "store/reducers/transferMakingReducer";

import { Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import { transferActions } from "../../../../store/reducers/transferReducer";
import { CheckIcon } from "../../../app/assets/icons/CheckIcon";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../app/components/pagination/Pagination";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import ClockIcon from "../../../payment/assets/icons/ClockIcon";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import XCloseIcon from "../../assets/icons/XCloseIcon";
import { useGetTransferView } from "../../service/query";
import { DefectStatus } from "../../utils/constants/DefectStatus";
import { TransferViewModel } from "../../utils/models/transferViewModel";
import TransferModal from "../warehouse/transfer-modal/TransferModal";

import ActionDots from "./action-dots/ActionDots";
import ExcessModal from "./excess-modal/ExcessModal";
import FilterDrawerTransfers from "./filter-drawer/FilterDrawerTransfers";
import TransferMakingDrawer from "./transfer-making-drawer/TransferMakingDrawer";
import TransferView from "./transfer-view/TransferView";

import styles from "./transfer.module.scss";

// const useDebounce = (callback: () => void, delay: number) => {
//   const timeoutRef = useRef<NodeJS.Timeout | null>(null);

//   const debouncedCallback = () => {
//     if (timeoutRef.current) {
//       clearTimeout(timeoutRef.current);
//     }
//     timeoutRef.current = setTimeout(() => {
//       callback();
//     }, delay);
//   };

//   useEffect(
//     () => () => {
//       if (timeoutRef.current) {
//         clearTimeout(timeoutRef.current);
//       }
//     },
//     []
//   );

//   return debouncedCallback;
// };

const Transfers: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useDispatch();
  const { setVisibleView } = transferActions;
  const { data, isLoading, isFetching } = useGetTransferView();
  const { t } = useTranslation();
  const { setDrawerVisible } = transferMakingReducerActions;

  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const transferPermissionActions = actions("O'tkazmalar");
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.WAREHOUSE_TRANSFERS, true);
  const { data: tableConfig } = useGetTableConfigs(
    tableConfigKeys.WAREHOUSE_TRANSFERS,
    warehouseTransfersTableDefaultData
  );
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_TRANSFER]);

  const transferStatus = {
    [DefectStatus.OPEN]: (
      <div className={styles.open}>
        <ClockIcon width={16} /> {t("warehouse.Ochiq")}
      </div>
    ),
    [DefectStatus.PENDING]: (
      <div className={styles.pending}>
        <ClockIcon width={16} /> {t("warehouse.Kutilmoqda")}
      </div>
    ),
    [DefectStatus.RECIEVED]: (
      <div className={styles.recieved}>
        <CheckIcon width={16} /> {t("warehouse.Qabul qilingan")}
      </div>
    ),
    [DefectStatus.REJECTED]: (
      <div className={styles.rejected}>
        <XCloseIcon /> {t("warehouse.Bekor qilingan")}
      </div>
    )
  };

  const onOpenTransfersView = (record?: TransferViewModel) => {
    dispatch(
      setVisibleView({
        data: record,
        visible: true
      })
    );
  };

  const columns: ColumnsType<TransferViewModel> = [
    {
      title: "№",
      dataIndex: "id",
      render: (value: number) => `T-${value}`,
      width: 100,
      className: "id",
      fixed: "left"
    },
    {
      title: `${t("warehouse.Sana")}`,
      dataIndex: "created_at",
      width: 300,
      className: "created_at",
      key: "created_at"
    },
    {
      title: `${t("warehouse.O’tkazuvchi omborxona")}`,
      render: (_, record) => record.sender_warehouse?.name,
      width: 320,
      className: "sender_warehouse",
      key: "sender_warehouse.name"
    },
    {
      title: `${t("warehouse.Qabul qiluvchi omborxona")}`,
      render: (_, record) => record.recieved_warehouse?.name ?? "-",
      width: 420,
      className: "receiver_warehouse",
      key: "recieved_warehouse.name"
    },
    {
      title: `${t("warehouse.Yuboruvchi")}`,
      render: (_, record) => record.sender_user?.full_name ?? "-",
      width: 200,
      className: "sender",
      key: "sender_user.full_name"
    },
    {
      title: `${t("warehouse.Qabul qiluvchi")}`,
      render: (_, record) => record.recieved_user?.full_name ?? "-",
      width: 200,
      className: "receiver",
      key: "recieved_user.full_name"
    },
    {
      title: `${t("warehouse.Loyiha")}`,
      render: (_, record) => record?.project?.name ?? "-",
      width: 400,
      className: "project",
      key: "project.name"
    },
    {
      title: `${t("warehouse.Holati")}`,
      render: (_, record) => transferStatus[record.status as keyof typeof transferStatus],
      width: 300,
      className: "status",
      key: "status"
    }
  ];

  const [renderColumns, setColumns] = useState<ColumnsType<TransferViewModel>>([]);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.WAREHOUSE_TRANSFERS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<TransferViewModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns,
          tableConfig,
          customFields
        }),
        {
          title: (
            <TableSettings
              defaultData={warehouseTransfersTableDefaultData}
              configKey={tableConfigKeys.WAREHOUSE_TRANSFERS}
              locations={[CustomFieldLocationEnum.WAREHOUSE_TRANSFER]}
              isNeedSize
            />
          ),
          render: record => <ActionDots record={record} />,
          width: 70,
          className: "action_dots",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  const title = () => (
    <div className={styles.title}>
      {transferPermissionActions.makeTransfer && (
        <div>
          <Button
            onClick={() =>
              dispatch(
                setDrawerVisible({
                  visible: true
                })
              )
            }
          >
            <PlusIcon />
            {t("warehouse.O'tkazma qilish")}
          </Button>
          <span className={styles.title__line} />
        </div>
      )}
      <PaymentSearch />
      <FilterDrawerTransfers />
    </div>
  );

  return (
    <div className={styles.transfer}>
      <div className={styles.top}>
        <Table<TransferViewModel>
          title={title}
          columns={renderColumns}
          dataSource={data?.data}
          onChangeColumns={onChangeColumns}
          rowKey={row => row.id}
          pagination={false}
          rowClassName={() => styles.on_row}
          onRow={record => ({
            onClick: () => onOpenTransfersView(record)
          })}
          loading={{
            spinning: isLoading || isFetching,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
          scroll={{ y: "65vh" }}
        />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{
            total: data?.total,
            current: data?.current_page
          }}
        />
      </div>
      <TransferView />
      <ExcessModal />
      <TransferMakingDrawer />
    </div>
  );
};

export default Transfers;
