import React from "react";

type Props = {
  active?: boolean;
  stroke?: string;
};

const ChevroDownIcon = ({ active, stroke }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: `rotate(${active ? 0 : -90}deg)`
    }}
  >
    <path
      d="M4 6L8 10L12 6"
      stroke={stroke || "white"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevroDownIcon;
