import { useState } from "react";

import { useAppSelector } from "../../../../../../../../../../hooks/redux";
import { GanttSectionData } from "../../utils/models/GanttSection";

import GanttTableFooter from "./gantt-table-footer/GanttTableFooter";
import GanttTableHeader from "./gantt-table-header/GanttTableHeader";
import GanttTableSections from "./gantt-table-section/GanttTableSections";

import styles from "./ganttTable.module.scss";

interface IProps {
  data: GanttSectionData | undefined;
  searchData?: GanttSectionData;
}

const GanttTable = ({ data }: IProps) => {
  const { lineSize } = useAppSelector(state => state.ganttReducer);
  const [activeAll, setActiveAll] = useState(false);

  const onToggleActiveAll = () => {
    setActiveAll(prev => !prev);
  };

  return (
    <div
      className={styles.container}
      style={{ minWidth: lineSize, width: lineSize }}
      id="gantt_table"
      onScroll={e => {
        const gantt_chart = document.getElementById("gantt_chart");

        if (gantt_chart) {
          gantt_chart.scrollTo({
            top: e.currentTarget.scrollTop
          });
        }
      }}
    >
      <GanttTableHeader onToggleActiveAll={onToggleActiveAll} activeAll={activeAll} />
      <GanttTableSections data={data} activeAll={activeAll} />
      <GanttTableFooter data={data} />
    </div>
  );
};

export default GanttTable;
