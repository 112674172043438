import React from "react";
import { Form, FormInstance, Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { StatusEnums } from "../../../../../../../app/components/statuses/statusEnums";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import { selectFilterOption } from "../../../../../../../app/utils/helpers/selectFilterOption";
import { useGetWarehouseSelect } from "../../../../../../../warehouse/service/query";
import { PartyFormProductsModel } from "../../../../../../utils/models/partyModalFormModel";

type Props = {
  index: number;
  form: FormInstance;
};

const { Item, useWatch } = Form;
const { Option } = Select;

const PartyWarehouseSelect: React.FC<Props> = ({ index, form }) => {
  const cacheProducts = useWatch("cache_products", form) as PartyFormProductsModel[];
  const warehouseProducts = useWatch("warehouse_products", form) as PartyFormProductsModel[];
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);
  const { data: warehouses } = useGetWarehouseSelect(false, true);
  const status = useWatch("status", form) as StatusEnums;

  const onChange = (e: number) => {
    const findProduct = warehouseProducts[index];

    form.setFieldValue(["warehouse_products", index, "warehouse_id"], e);

    if (!isEmptyArr(cacheProducts)) {
      const findCacheIndex = cacheProducts?.findIndex(item => item?.row_id === findProduct?.row_id);

      form.setFieldValue(["cache_products", findCacheIndex, "warehouse_id"], e);
    }
  };

  const disabled = () => !(updateProduct?.condition && status !== StatusEnums.PARTIALLY_RECIEVED);

  // const selectedId = () => form.getFieldValue(["warehouse_products", index, "warehouse_id"]);

  // const selected = () => warehouses?.find(item => item?.id === selectedId());

  return (
    // <PerfItem
    //   placeholder={<div className="px-[0.62rem] py-4">{selected()?.name}</div>}
    //   visible={!(isView && disabled())}
    // >
    <Item name={[index, "warehouse_id"]} rules={formRules()}>
      <Select
        showSearch
        suffixIcon={null}
        onChange={onChange}
        placeholder="Omborxona"
        popupMatchSelectWidth={false}
        filterOption={selectFilterOption}
        disabled={isView && disabled()}
        notFoundContent={<TableEmpty description="Siz qidirgan omborxona mavjud emas" />}
      >
        {warehouses?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name
            }}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </Item>
    // </PerfItem>
  );
};

export default PartyWarehouseSelect;
