import React, { useState } from "react";
import { Dropdown, MenuProps } from "antd";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "features/app/assets/icons/DotsVerticalIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import EditIcon from "features/app/assets/icons/EditIcon";
import SettingsIcon from "features/app/assets/icons/SettingsIcon";
import ModalConfirm from "features/app/components/modal-confirm/ModalConfirm";

import { Widget, useWidget } from "modules/dashboard";
import { useDeleteWidget } from "modules/dashboard/mutations/use-delete-widget";
import { useTranslation } from "react-i18next";

type Props = {
  data?: Widget;
};

const DrawerActions: React.FC<Props> = ({ data }) => {
  const {
    widget,
    methods: { setSettings, setWidgetModal }
  } = useWidget();

  const [open, setOpen] = useState(false);

  const { t } = useTranslation();

  const mutation = useDeleteWidget();

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenEditWidgetModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);
    setWidgetModal({
      visibleWidgetModal: true,
      isUpdateWidget: true,
      widget: widget ? widget : data!
    });
    setOpen(false);
  };

  const onOpenWidgetSettingsModal = (e: React.MouseEvent<HTMLDivElement>) => {
    stopPropagation(e);
    setSettings({
      id: widget ? widget.id! : data!.id!,
      key: widget ? widget.key! : data!.key!,
      settingsVisible: true,
      settingsProps: widget ? widget!.filter! : data!.filter!
    });
    setOpen(false);
  };

  const onDeleWidget = () => mutation.mutateAsync({ id: widget ? widget!.id! : data!.id! });

  const items = [
    {
      key: "1",
      label: (
        <div className="flex items-center gap-3" onClick={onOpenEditWidgetModal}>
          <EditIcon /> {t("dashboard.Tahrirlash")}
        </div>
      ),
      isView: true
    },
    {
      key: "2",
      label: (
        <div className="flex items-center gap-3" onClick={onOpenWidgetSettingsModal}>
          <SettingsIcon /> {t("dashboard.Sozlash")}
        </div>
      ),
      isView: !data
    },
    {
      key: "3",
      label: (
        <div className="flex items-center gap-3">
          <DownloadIcon /> {t("dashboard.Yuklash")}
        </div>
      ),
      isView: true
    },
    {
      key: "4",
      label: (
        <ModalConfirm
          cancelText={t("dashboard.Bekor qilish")}
          okText={t("dashboard.O'chirish")}
          title={t("dashboard.Ushbu vidjetni o'chirishni xohlaysizmi")}
          onOk={onDeleWidget}
          description=""
        >
          <div
            className="flex items-center gap-3"
            onClick={e => {
              setOpen(false);
            }}
          >
            <DeleteIcon /> {t("dashboard.O'chirish")}
          </div>
        </ModalConfirm>
      ),
      isView: true
    }
  ];

  const onOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);
    setOpen(true);
  };

  return (
    <Dropdown
      onOpenChange={open => setOpen(open)}
      menu={{ items: items?.filter(el => el.isView) }}
      trigger={["click"]}
      open={open}
    >
      <div
        className="flex cursor-pointer items-center justify-center rounded-lg border border-solid border-gray-200 bg-gray-50 p-2"
        onClick={onOpen}
      >
        <DotsVerticalIcon />
      </div>
    </Dropdown>
  );
};

export default DrawerActions;
