import { PaymentTypeEnum } from "../../../app/utils/constants/paymentTypeEnum";

export const dailyItems = [
  "Dushanba",
  "Seshanba",
  "Chorshanba",
  "Payshanba",
  "Juma",
  "Shanba",
  "Yakshanba",
];

export const dailySelectItems = [
  {
    vale: "01",
    name: "Yanvar",
  },
  {
    vale: "02",
    name: "Fevral",
  },
  {
    vale: "03",
    name: "Mart",
  },
  {
    vale: "04",
    name: "Aprel",
  },
  {
    vale: "05",
    name: "May",
  },
  {
    vale: "06",
    name: "Iyun",
  },
  {
    vale: "07",
    name: "Iyul",
  },
  {
    vale: "08",
    name: "Avgust",
  },
  {
    vale: "09",
    name: "Sentabr",
  },
  {
    vale: "10",
    name: "Oktabr",
  },
  {
    vale: "11",
    name: "Noyabr",
  },
  {
    vale: "12",
    name: "Dekabr",
  },
];

export const monthlyItems = [
  "Yanvar",
  "Fevral",
  "Mart",
  "Aprel",
  "May",
  "Iyun",
  "Iyul",
  "Avgust",
  "Sentabr",
  "Oktabr",
  "Noyabr",
  "Dekabr",
];

export const annualItems = [];

export const calendarItems = {
  [PaymentTypeEnum.DAILY]: dailyItems,
  [PaymentTypeEnum.MONTHLY]: monthlyItems,
  [PaymentTypeEnum.ANNUAL]: annualItems,
};
