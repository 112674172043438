export const counterpartsQueryKeys = {
  FOLDERS_VIEW: "folder-view",
  FOLDERS_SELECT: "folder-select",
  PERSONS_VIEW: "persons-view",
  ONE_FOLDER_VIEW: "one-folder-view",
  PERSON_SELECT: "person-select",
  PERSON_SELECT_SEARCH: "person-select-search",
  COMPANY_PERSON_SELECT: "company-person-select",
  EXPECTED_PAYMENT: "expected-payment",
  EXPECTED_TEMPLATE_PAYMENT: "expected-payment-template",
  PAYMENT: "payment",
  STATISTICS: "statistics",
  INVENTORY: "inventory",
  INVENTORY_FOLDER_SELECT: "inventory-folder-select",
  ORDER: "order",
  WORK: "work",
  ACT: "act",
  ONE_COMPANY_PERSON: "one_company_person",
  PERSONS_VIEW_ID: "persons-view-id",
  PERSONS_OFFER_VIEW_ID: "offers-view-id",
  AMOUNT_STATISTICS: "amount-statistics",
  CALENDAR_VIEW: "company-person-calendar-view"
};
