import React, { useState } from "react";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { generalEditKeys } from "features/app/utils/constants/generalEditData";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { cx } from "features/app/utils/helpers/cx";
import { useGetPartyExcelFile } from "features/supply/service/mutations";
import { tabKeys } from "features/supply/utils/constants/tabKeys";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { appReducerActions } from "store/reducers/appReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { Table } from "components";

import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import Pagination from "../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import PaymentSearch from "../../../../payment/components/top/search/PaymentSearch";
import { useGetSupplyOrders } from "../../../service/queries";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { OrderModel } from "../../../utils/models/orderModel";
import FilterDrawerParts from "../filter-drawer/FilterDrawerParts";

import PartyColumns from "./PartyColumns";

import styles from "./parts.module.scss";

const Parts: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setGeneralEdit } = appReducerActions;
  const { setPartyModal, setSelectParties, setSelectItemParties } = supplyActions;
  const { data: orders, isLoading: isLoadingOrders } = useGetSupplyOrders();
  const { selectParties } = useAppSelector(state => state.supplyReducer);
  const partyExportExcel = useGetPartyExcelFile();
  const [renderColumns, setColumns] = useState<ColumnsType<OrderModel>>([]);
  const { queries } = useQueryParams();
  const { tab } = queries();

  const { actions } = useRoutePermissions("Ta'minot", routeSubmodules);
  const partyActions = actions("Partiyalar");

  const onOpenPartyModal = (isView: boolean, party_id?: number) => {
    dispatch(
      setPartyModal({
        visible: true,
        party_id,
        isView,
        isEditing: !isView
      })
    );
  };

  const onDetailPart = (record: OrderModel) => ({
    onClick: () => {
      if (partyActions?.view) {
        onOpenPartyModal(true, record?.id);
      }
    }
  });

  const tableTitle = () => (
    <div className={styles.title}>
      <div className={styles.left} />
      <div className={styles.right}>
        {partyActions?.create && (
          <Button onClick={() => onOpenPartyModal(false, undefined)}>
            <PlusIcon /> {t("Supply.Partiya yaratish")}
          </Button>
        )}
        <div className={styles.border_line} />
        <div className={styles.search}>
          <PaymentSearch />
        </div>
        <ConditionalRender if={tab === tabKeys.PARTIES || !tab}>
          <FilterDrawerParts tableKey={tableConfigKeys.ORDER_CONFIG} exportExcelMutate={partyExportExcel} />
        </ConditionalRender>
      </div>
    </div>
  );

  const rowKey = (record: OrderModel) => record.id;

  const rowSelection: TableRowSelection<OrderModel> | undefined = {
    type: "checkbox",
    selectedRowKeys: selectParties?.map(item => item?.id),
    onSelect: record => {
      const isSomeParty = selectParties?.some(item => item?.id === record?.id);

      if (!isSomeParty) {
        dispatch(setSelectParties([...selectParties, record]));
      } else {
        dispatch(setSelectParties(selectParties?.filter(item => item?.id !== record?.id)));
      }
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        selectedRows?.forEach(item => {
          dispatch(setSelectItemParties(item));
        });
      } else {
        dispatch(setSelectParties([]));
      }
    },
    onChange: () => {
      dispatch(
        setGeneralEdit({
          isView: true,
          key: generalEditKeys.ORDERS,
          customFieldKeys: [CustomFieldLocationEnum.ORDER]
        })
      );
    }
  };

  return (
    <div className={styles.parties}>
      <div className={styles.top}>
        <Table<OrderModel>
          title={tableTitle}
          dataSource={orders?.data}
          columns={PartyColumns({ renderColumns, setColumns }).columns as ColumnsType<OrderModel>}
          scroll={{ y: "65vh" }}
          rowKey={rowKey}
          rowSelection={rowSelection}
          rowClassName={cx(!partyActions.view && "pointer-events-none")}
          pagination={false}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{ spinning: isLoadingOrders, indicator: LoadingIndicator }}
          onChangeColumns={PartyColumns({ renderColumns, setColumns }).onChangeColumns}
          onRow={onDetailPart}
        />
      </div>
      <div className={styles.bottom}>
        <Pagination
          paginationProps={{
            total: orders?.total
          }}
          configKey={tableConfigKeys.ORDER_CONFIG}
        />
      </div>
    </div>
  );
};

export default Parts;
