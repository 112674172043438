import React from "react";

const CurrencyDollarCircleIcon: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3337 19.5554C11.3337 21.2736 12.7265 22.6665 14.4448 22.6665H17.3337C19.1746 22.6665 20.667 21.1741 20.667 19.3332C20.667 17.4922 19.1746 15.9998 17.3337 15.9998H14.667C12.826 15.9998 11.3337 14.5075 11.3337 12.6665C11.3337 10.8256 12.826 9.33317 14.667 9.33317H17.5559C19.2741 9.33317 20.667 10.7261 20.667 12.4443M16.0003 7.33317V9.33317M16.0003 22.6665V24.6665M29.3337 15.9998C29.3337 23.3636 23.3641 29.3332 16.0003 29.3332C8.63653 29.3332 2.66699 23.3636 2.66699 15.9998C2.66699 8.63604 8.63653 2.6665 16.0003 2.6665C23.3641 2.6665 29.3337 8.63604 29.3337 15.9998Z"
        stroke="#12B76A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default CurrencyDollarCircleIcon;