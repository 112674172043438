import { ReactNode } from "react";

import { colors } from "modules/common";

import { CheckCircleIcon, ClockCircleIcon } from "components";

import { EstimateStatus } from "../../schema";

export const estimateStatusIcons: Record<EstimateStatus, ReactNode> = {
  closed: <CheckCircleIcon />,
  open: <ClockCircleIcon color={colors.GRAY_400} />,
  completed: <CheckCircleIcon />,
  process: <ClockCircleIcon color={colors.PRIMARY} />
};

export const estimateStatusNames: Record<EstimateStatus, string> = {
  closed: "Yopilgan",
  open: "Ochiq",
  completed: "Tugallangan",
  process: "Jarayonda"
};
