import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import BeakerV2 from "features/app/assets/icons/BeakerV2";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import Pagination from "features/app/components/pagination/Pagination";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import TableSettings from "features/app/components/table-settings/TableSettings";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import PaymentSearch from "features/payment/components/top/search/PaymentSearch";
import { useGetLaboratory } from "features/warehouse/service/query";
import { laboratoryTableDefaultData } from "features/warehouse/utils/constants/laboratoryTableDefaultData";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { LaboratoryViewData } from "features/warehouse/utils/models/laboratoryViewData";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { laboratoryDrawerActions, useDispatch } from "store";

import { cx } from "modules/common";

import { LoadingIndicator,Table } from "components";
import useDebounce from "components/use-debounce/use-debounce";

import ActionDropDown from "./action-dropdown/ActionDropDown";
import FilterLaboratoryDrawer from "./filter-drawer/FilterLaboratoryDrawer";
import LaboratoryDrawer from "./laboratory-drawer/LaboratoryDrawer";

import styles from "./laboratory.module.scss";

const Laboratory: React.FC = () => {
  const { t } = useTranslation();
  const { setOpenDrawer } = laboratoryDrawerActions;
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { data: laboratories, isLoading } = useGetLaboratory();
  const dispatch = useDispatch();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const laboratoryActions = actions("Laboratoriya");
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.LABORATORY, true);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.LABORATORIES]);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.LABORATORY, laboratoryTableDefaultData);
  const [renderColumns, setColumns] = useState<ColumnsType<any>>([]);

  const columns: ColumnsType = [
    {
      title: t("warehouse.Laboratoriya"),
      render: (record: LaboratoryViewData) => <div className="text-sm font-semibold text-gray-700">L-{record?.id}</div>,
      width: 200,
      className: "laboratory",
      fixed: "left",
      key: "id"
    },
    {
      title: t("warehouse.Sana"),
      dataIndex: "date",
      width: 250,
      className: "laboratory-date",
      key: "date"
    },
    {
      title: t("warehouse.Omborxona"),
      width: 400,
      className: "laboratory-warehouse",
      render: (record: LaboratoryViewData) => (
        <div className="text-sm font-semibold text-gray-700">{record?.warehouse?.name}</div>
      ),
      key: "warehouse.name"
    },
    {
      title: t("warehouse.Loyiha"),
      width: 300,
      className: "laboratory-project",
      render: (record: LaboratoryViewData) => (
        <div className="text-sm font-semibold text-gray-700">{record?.project?.name}</div>
      ),
      key: "project.name"
    },
    {
      title: t("warehouse.Izoh"),
      width: 500,
      className: "laboratory-description",
      render: (record: LaboratoryViewData) => <PopoverShortName title={record?.description} length={25} />,
      key: "description"
    },
    {
      title: t("warehouse.Xodim"),
      width: 200,
      className: "laboratory-user",
      key: "creator.full_name",
      render: (record: LaboratoryViewData) => (
        <CustomAvatar image={record?.creator?.image} name={record?.creator?.full_name} />
      )
    }
  ];

  const onOpeDrawer = () => {
    dispatch(
      setOpenDrawer({
        visible: true
      })
    );
  };

  const tableTitle = () => (
    <div className={styles.table_title}>
      {laboratoryActions?.create && (
        <div className="flex items-center gap-2">
          <Button onClick={onOpeDrawer}>
            <BeakerV2 /> {t("warehouse.Laboratoriya")}
          </Button>
          <span className="h-[1.5rem] w-[0.125rem] bg-gray-300" />
        </div>
      )}
      <PaymentSearch />
      <FilterLaboratoryDrawer />
    </div>
  );

  const footer = () => (
    <Pagination
      paginationProps={{
        total: laboratories?.total,
        current: laboratories?.current_page
      }}
    />
  );

  const onRow = (record: LaboratoryViewData) => ({
      onClick: () => {
        dispatch(
          setOpenDrawer({
            visible: true,
            id: record?.id,
            isViewing: true,
            record
          })
        );
      }
    });

  const saveColumns = () => {
    const columnsToSave = renderColumns.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutate({
      key: tableConfigKeys.LABORATORY,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<LaboratoryViewData>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({ columns, tableConfig, customFields }),
        {
          title: (
            <TableSettings
              defaultData={laboratoryTableDefaultData}
              configKey={tableConfigKeys.LABORATORY}
              locations={[CustomFieldLocationEnum.LABORATORIES]}
              isNeedSize
            />
          ),
          width: 70,
          className: "laboratory-actions",
          render: (record: LaboratoryViewData) => <ActionDropDown record={record} />,
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  return (
    <div>
      <div className={styles.top}>
        <Table<LaboratoryViewData>
          onChangeColumns={onChangeColumns}
          // className="footer_table"
          title={tableTitle}
          dataSource={laboratories?.data}
          columns={renderColumns as ColumnsType<LaboratoryViewData>}
          rowClassName={cx("cursor-pointer")}
          onRow={onRow}
          footer={footer}
          pagination={false}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
          scroll={{ y: "calc(100vh - 315px)" }}
        />
      </div>
      <LaboratoryDrawer />
    </div>
  );
};

export default Laboratory;
