import React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {
  placement?: "top" | "bottom" | "left" | "right";
  color?: string;
}

const CaretIcon = (props: IProps) => {
  const { placement = "right", color = "#175CD3" } = props;
  const placementRotate = {
    top: "rotate(90deg)",
    bottom: "rotate(-90deg)",
    left: "rotate(180deg)",
    right: "",
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      style={{ transform: placementRotate[placement] }}
      {...props}
    >
      <path
        d="M9.93453 7.30954L5.55953 11.6845C5.49834 11.7458 5.42036 11.7875 5.33545 11.8044C5.25053 11.8213 5.16251 11.8127 5.08253 11.7795C5.00254 11.7464 4.93419 11.6903 4.88612 11.6183C4.83805 11.5462 4.81243 11.4616 4.8125 11.375V2.62501C4.81243 2.53843 4.83805 2.45378 4.88612 2.38177C4.93419 2.30976 5.00254 2.25363 5.08253 2.22049C5.16251 2.18734 5.25053 2.17868 5.33545 2.19559C5.42036 2.2125 5.49834 2.25422 5.55953 2.31548L9.93453 6.69048C9.97521 6.73111 10.0075 6.77936 10.0295 6.83247C10.0515 6.88558 10.0628 6.94251 10.0628 7.00001C10.0628 7.0575 10.0515 7.11443 10.0295 7.16754C10.0075 7.22066 9.97521 7.26891 9.93453 7.30954Z"
        fill={color}
      />
    </svg>
  );
};

export default CaretIcon;
