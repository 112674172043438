import { useState } from "react";
import { Button, Modal, Upload } from "antd";
import { RcFile, UploadChangeParam } from "antd/es/upload";
import { UploadFile } from "antd/lib";
import * as XLSX from "xlsx";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import CloseIcon from "../../../app/assets/icons/CloseIcon";
import DownloadIcon from "../../../app/assets/icons/DownloadIcon";
import UploadIcon from "../../../app/assets/icons/UploadIcon";
import { SectionModel } from "../../utils/models/estimateModel";

import ImportFormModal from "./import-form-modal/EstimateSectionsModal";

import styles from "./estimateImportModal.module.scss";

type Props = {
  smetaContainer?: HTMLElement;
};
const EstimateImportModal: React.FC<Props> = ({ smetaContainer }) => {
  const dispatch = useAppDispatch();
  const [openForm, setOpenForm] = useState<boolean>(false);
  const { visibleImportModal } = useAppSelector(state => state.projectsReducer);
  const { setSections, setOpenImportModal } = projectsReducerActions;

  const onCancel = () => {
    dispatch(setOpenImportModal(false));
  };

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj;
    const reader = new FileReader();

    reader.onload = e => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: "binary" });

      const result: { sheetName: string; data: unknown[] }[] = [];

      workbook.SheetNames.forEach(sheetName => {
        const sheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        result.push({ sheetName, data });
      });

      const rows: any = result[0].data;

      const sections: SectionModel[] = [];

      // Agar smeta narxli bo'lsa
      if (rows[7][0] === "№2") {
        for (let i = 0; i < rows.length; i += 1) {
          if (rows[i].length === 2) {
            if (rows[i][0] === undefined) {
              // if (rows[i + 1]?.length === 8) {
              const section: SectionModel = {
                name: rows[i][1],
                place: sections.length + 1,
                code: "",
                checked: true,
                tasks: []
              };

              sections.push(section);
              // }
            }
          }
          if (
            rows[i]?.length === 8 &&
            sections[sections.length - 1]?.name &&
            // eslint-disable-next-line no-restricted-globals
            !isNaN(rows[i][4]) &&
            typeof rows[i][2] === "string" &&
            rows[i][5] === undefined
          ) {
            const lastSection = sections[sections.length - 1];
            const isTitleLength = lastSection?.tasks?.filter(item => item.is_title)?.length;
            const taskPlace = lastSection?.tasks?.length - isTitleLength + 1;

            lastSection.tasks.push({
              name: rows[i][2],
              place: taskPlace,
              code: rows[i][1],
              amount: +rows[i][6],
              quantity: Math.abs(rows[i][4]),
              unit: rows[i][3],
              checked: true,
              products: []
            });
          }
          if (rows[i]?.length === 2 && sections[sections.length - 1]?.name && rows[i][0] === "#") {
            const lastSection = sections[sections.length - 1];
            const isTitleLength = lastSection?.tasks?.filter(item => item.is_title)?.length;
            const taskPlace = lastSection?.tasks?.length - isTitleLength + 1;

            // @ts-ignore
            lastSection.tasks.push({
              name: rows[i][1],
              place: taskPlace,
              is_title: true
            });
          }
          if (
            rows[i].length === 8 &&
            sections[sections.length - 1]?.tasks?.length > 0 &&
            typeof rows[i][2] === "string" &&
            rows[i][5] !== undefined
          ) {
            const lastSection = sections[sections.length - 1];
            const lastTask = lastSection?.tasks[lastSection?.tasks?.length - 1];

            lastTask.products.push({
              name: rows[i][2],
              place: lastTask?.products.length + 1,
              code: rows[i][1],
              amount: +rows[i][6],
              quantity: Math.abs(rows[i][5]),
              unit: rows[i][3],
              checked: true
            });
          }
        }
      } else {
        // Bu narxsiz uchun
        for (let i = 0; i < rows.length; i += 1) {
          if (rows[i].length === 3) {
            if (rows[i][0] === undefined && rows[i][1] === undefined) {
              // if (rows[i + 1]?.length === 5) {
              const section: SectionModel = {
                name: rows[i][2],
                place: sections.length + 1,
                code: "",
                checked: true,
                tasks: []
              };

              sections.push(section);
              // }
            }
          }
          if (
            rows[i]?.length === 5 &&
            sections[sections.length - 1]?.name &&
            // eslint-disable-next-line no-restricted-globals
            !isNaN(rows[i][4]) &&
            typeof rows[i][2] === "string"
          ) {
            const lastSection = sections[sections.length - 1];

            lastSection.tasks.push({
              name: rows[i][2],
              place: lastSection.tasks.length + 1,
              code: rows[i][1],
              amount: 0,
              quantity: Math.abs(rows[i][4]),
              unit: rows[i][3],
              checked: true,
              products: []
            });
          }
          if (
            rows[i]?.length >= 2 &&
            rows[i]?.length <= 3 &&
            sections[sections.length - 1]?.name &&
            rows[i][0] === "#"
          ) {
            const lastSection = sections[sections.length - 1];

            // @ts-ignore
            lastSection.tasks.push({
              name: rows[i][1] ? rows[i][1] : rows[i][2],
              place: lastSection.tasks.length,
              is_title: true
            });
          }
          if (
            rows[i].length === 6 &&
            sections[sections.length - 1]?.tasks?.length > 0 &&
            typeof rows[i][2] === "string"
          ) {
            const lastSection = sections[sections.length - 1];
            const lastTask = lastSection?.tasks[lastSection?.tasks?.length - 1];

            lastTask.products.push({
              name: rows[i][2],
              place: lastTask?.products.length + 1,
              code: rows[i][1],
              amount: 0,
              quantity: Math.abs(rows[i][5]),
              unit: rows[i][3],
              checked: true
            });
          }
        }
      }

      dispatch(setSections(sections));
      console.log(sections);
    };

    reader.readAsBinaryString(file as RcFile | Blob);

    onCancel();
    setOpenForm(true);
  };

  const modalFooter = (
    <Button href={`${import.meta.env.VITE_BASE_URL.replace("/main/", "")}/public/upload/import/Форма%20№%205.xls`}>
      <DownloadIcon /> Namuna
    </Button>
  );

  return (
    <>
      <Modal
        title="Fayl yuklash"
        centered
        width={548}
        closeIcon={<CloseIcon />}
        onCancel={onCancel}
        open={visibleImportModal}
        footer={modalFooter}
        getContainer={() => smetaContainer!}
      >
        <div className={styles.content}>
          <Upload onChange={onChange} customRequest={() => null} showUploadList={false}>
            <div className={styles.upload}>
              <UploadIcon />
              <p>
                Loyiha <span>"Форма №5"</span> faylini yuklash uchun bosing yoki faylni shu yerga tashlang (.xls, .xlsx)
              </p>
            </div>
          </Upload>
        </div>
      </Modal>
      <ImportFormModal open={openForm} setOpen={setOpenForm} />
    </>
  );
};

export default EstimateImportModal;
