import React from "react";
import { Collapse, DatePicker, Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";

import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import UploadFiles from "../../../../../app/components/upload-files/upload-files";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import Projects from "../projects/projects";

import styles from "../../transfer-drawer/top/additions/projects/projects.module.scss";

type Props = {
  form: FormInstance;
};

const { Item } = Form;
const { TextArea } = Input;
const { Panel } = Collapse;

const Additions: React.FC<Props> = ({ form }) => {
  const { t } = useTranslation();

  return (
    <Collapse
      bordered={false}
      expandIconPosition="right"
      rootClassName={styles.projects}
      expandIcon={({ isActive }) => <SelectSuffixIcon placement={isActive ? "top" : "bottom"} />}
    >
      <Panel key="1" header={t("payment.Qo'shimcha ma'lumotlar")}>
        <Projects
          form={form}
          parentName={[]}
          currencyFormName="second_currency"
          name={["income_payment", "payment_type", "projects"]}
        />
        <Item name="date" label={t("payment.Sana")} className="mb-3">
          <DatePicker
            format={dayjsFormats.DATE}
            placeholder={t("payment.Sana")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </Item>
        <Item name="description" label={t("payment.Izoh")}>
          <TextArea placeholder={t("payment.Izoh")} />
        </Item>
        <UploadFiles isArray form={form} name="file_ids" />
      </Panel>
    </Collapse>
  );
};

export default Additions;
