import React from "react";
import ArrowNarrowIcon from "features/app/assets/icons/ArrowNarrowIcon";
import ConversionInfoModal from "features/app/components/conversion-info-modal/ConversionInfoModal";
import TransferHistoryModal from "features/app/components/transfer-history-modal/TransferHistoryModal";
import { colors } from "features/app/utils/constants/colors";
import { isEmptyArr } from "features/app/utils/helpers/isEmptyArr";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { PaymentTypeIcon } from "features/payment/utils/helpers/paymentTypeIcon";

import { useAppDispatch } from "hooks/redux";

import { paymentReducerActions } from "store/reducers/paymentReducer";

import { RU } from "modules/common";
import { CompanyWidget, FinalExpensesWidget, typeValues, WidgetKeys } from "modules/dashboard";

import styles from "./table-card.module.scss";

type Props = {
  data: CompanyWidget["data"];
  widgetKey: WidgetKeys;
};

const TableCard: React.FC<Props> = ({ data, widgetKey }) => {
  const tableData = data as FinalExpensesWidget[];

  const { setIncomeOrExpenseViewVisible, setConversionInfoModal, setTransferHistoryModal, setIncomeExpenseModal } =
    paymentReducerActions;

  const dispatch = useAppDispatch();

  const generateNewData = (data: FinalExpensesWidget[]) => {
    const newData = [];
    let i = 0;

    while (i < data.length) {
      const currentDate = data[i]?.date;
      const currentGroup = [data[i]];

      while (i + 1 < data.length && data[i + 1]?.date === currentDate) {
        currentGroup.push(data[i + 1]);
        i++;
      }

      newData.push({
        date: currentDate,
        data: currentGroup
      });

      i++;
    }

    return newData;
  };

  const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onOpenViewModal = (record: FinalExpensesWidget, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    stopPropagation(e);
    if (record?.type === typeValues.exchange) {
      dispatch(setConversionInfoModal(record as never));
    } else if (record?.type === typeValues.transfer) {
      dispatch(setTransferHistoryModal(record as never));
    } else {
      dispatch(
        setIncomeOrExpenseViewVisible({
          visible: true,
          record: record as never,
          isNotDelete: true
        })
      );
    }
  };

  const getAmountColor = (type: string, related: number | null) => {
    if ((type === typeValues.exchange || type === typeValues.transfer) && related) {
      return colors.ERROR_500;
    }
    if ((type === typeValues.exchange || type === typeValues.transfer) && !related) {
      return colors.SUCCESS_500;
    }
    if (type === typeValues.income) {
      return colors.SUCCESS_500;
    }

    return colors.ERROR_500;
  };

  const getAmountPrefix = (type: string, related: number | null) => {
    if ((type === typeValues.exchange || type === typeValues.transfer) && related) {
      return "-";
    }
    if ((type === typeValues.exchange || type === typeValues.transfer) && !related) {
      return "+";
    }
    if (type === typeValues.income) {
      return "+";
    }

    return "-";
  };

  return (
    <div
      className="flex h-[92%] w-full flex-col overflow-y-auto"
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation as never}
    >
      {!isEmptyArr(generateNewData(tableData)) &&
        generateNewData(tableData)?.map(el => (
          <div className="flex w-full flex-col" key={el.date}>
            <span className="flex items-center rounded-md border border-solid border-gray-100 bg-gray-100 px-3 py-2 text-sm font-medium">
              {el.date}
            </span>
            <div className={styles.container}>
              {el.data?.map(item => (
                <div className={styles.item} key={item.id} onClick={e => onOpenViewModal(item, e)}>
                  <div className="flex gap-2">
                    {PaymentTypeIcon(item.type as string, item.status as never)}
                    <span>
                      {PaymentSourceElement({
                        isViewKey: false,
                        record: item as never
                      })}
                    </span>
                  </div>
                  <span
                    className={styles.quantity_container}
                    style={{
                      color: getAmountColor(item.type!, item.related)
                    }}
                  >
                    {getAmountPrefix(item.type!, item.related)} {item?.amount?.toLocaleString(RU)}{" "}
                    {item?.currency?.symbol}
                    <span className={styles.arrow_container}>
                      <ArrowNarrowIcon rotate={180} />
                    </span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}

      <IncomeExpenseView />
      <ConversionInfoModal />
      <TransferHistoryModal />
    </div>
  );
};

export default TableCard;
