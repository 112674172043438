import { Button } from "antd";
import queryString from "query-string";
import React, { useEffect, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";

import { useQueryParams } from "../../../../../hooks/useQueryParams";
import MainWarehouseIcon from "../../../../app/assets/icons/MainWarehouseIcon";
import FilterDrawer from "../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetCustomFieldSelect, useGetUsersSelect, useProjectSelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import PackageXIcon from "../../../../inventory/assets/icons/PackageXIcon";
import BuildingIcon from "../../../../payment/assets/icons/BuildingIcon";
import UsersIcon from "../../../../payment/assets/icons/UsersIcon";
import { useGetProjectSelect } from "../../../../payment/service/queries";
import ZapIcon from "../../../../projects/assets/icons/ZapIcon";
import FilterLinesIcon from "../../../assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "../../../service/query";
import { NewDefectEnum } from "../../../utils/constants/DefectEnum";
import { DefectStatus } from "../../../utils/constants/DefectStatus";

import styles from "./filterDrawer.module.scss";
import { UseMutationResult } from "@tanstack/react-query";
import { IExportExcelParam } from "features/app/utils/models/IExportExcelParam";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { warehouseDefectTableDefaultData } from "features/warehouse/utils/constants/warehouseDefectTableDefaultData";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useTranslation } from "react-i18next";
import { useRoutePermissions } from "hooks/useRoutePermissions";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { CustomFieldTypesEnum } from "features/app/utils/enums/customFieldTypesEnum";
import ArrowSquareDownIcon from "features/settings/assets/icons/ArrowSquareDownIcon";
import TagIcon from "features/settings/assets/icons/TagIcon";
import CustomFieldIcon from "features/app/assets/icons/CustomFieldIcon";
import { colors } from "modules/common";

const queryStringArr = [
  queryParamsKeys.WAREHOUSE_IDS,
  queryParamsKeys.PROJECT_IDS,
  queryParamsKeys.CONFIRMATION_USER_IDS,
  queryParamsKeys.STATUSES,
  queryParamsKeys.TYPES,
  queryParamsKeys.MIN_DATE,
  queryParamsKeys.MAX_DATE
];
export interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exportExcelMutate?: UseMutationResult<any, unknown, IExportExcelParam, unknown>;
}

const FilterDrawerDefects: React.FC<Props> = ({ exportExcelMutate }) => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const defectActions = actions("Yaroqsiz mahsulotlar");
  const { queries } = useQueryParams();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [statusSelected, setStatusSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.STATUSES] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CONFIRMATION_USER_IDS] as string[] | null
  );
  const [typesSelected, setTypesSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.TYPES] as string[] | null
  );
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: warehouses } = useGetWarehouseSelect(false, open);
  const { data: adminsSelect } = useGetUsersSelect(open);
  const { data: selectableCustomFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_DEFECT], true);

  const [customFieldStates, setCustomFieldStates] = useState<{ [key: string]: string[] | null }>({});

  const handleCustomFieldChange = (id: string, value: string[] | null) => {
    setCustomFieldStates(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const aggregatedCustomFieldsSelected = useMemo(() => {
    return Object.values(customFieldStates).flat();
  }, [customFieldStates]);

  const admins = useMemo(() => {
    const result = adminsSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });

    return result;
  }, [adminsSelect]);

  const paymentTypeData = useMemo(() => {
    return [
      {
        id: DefectStatus.OPEN,
        name: `${t("warehouse.Ochiq")}`
      },
      {
        id: DefectStatus.PENDING,
        name: `${t("warehouse.Kutilmoqda")}`
      },
      {
        id: DefectStatus.RECIEVED,
        name: `${t("warehouse.Qabul qilingan")}`
      },
      {
        id: DefectStatus.REJECTED,
        name: `${t("warehouse.Bekor qilingan")}`
      }
    ];
  }, []);

  const typesData = useMemo(() => {
    return [
      {
        id: NewDefectEnum.BROKEN,
        name: `${t("warehouse.Singan")}`
      },
      {
        id: NewDefectEnum.LOST,
        name: `${t("warehouse.Yo'qolgan")}`
      }
    ];
  }, []);

  const onOpenFilter = () => {
    setOpen(true);
  };

  const handleExcelExport = () => {
    const data = filterColumns({
      tableConfig: warehouseDefectTableDefaultData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    if (data) {
      exportExcelMutate?.mutate({
        columns: data
      });
    }
  };

  const footerContent = defectActions["upload"] ? (
    <Button onClick={handleExcelExport} className={styles.downloadBtn} loading={exportExcelMutate?.isLoading}>
      <DownloadIcon /> {t("warehouse.Yuklab olish")}
    </Button>
  ) : (
    <span />
  );

  const customFieldIcons: Record<string, React.ReactNode> = {
    [CustomFieldTypesEnum.SELECT]: <ArrowSquareDownIcon />,
    [CustomFieldTypesEnum.LABEL]: <TagIcon />
  };

  const searchParams = queryString.parse(location.search, { arrayFormat: "bracket" });
  const fieldValues = searchParams[queryParamsKeys.CUSTOM_FIELDS];

  useEffect(() => {
    if (selectableCustomFields && open) {
      const initialCustomFieldStates: { [key: string]: string[] | null } = {};

      selectableCustomFields.forEach(field => {
        if (fieldValues) {
          const valuesArray = Array.isArray(fieldValues) ? fieldValues : [fieldValues];
          initialCustomFieldStates[field.id] = valuesArray.filter(value => {
            return field?.custom_field_options?.some(option => String(option.id) === value);
          }) as string[];
        } else {
          initialCustomFieldStates[field.id] = [];
        }
      });

      setCustomFieldStates(initialCustomFieldStates);
    }
  }, [open]);

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("warehouse.Filter")}
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      footerContent={footerContent}
      height="max-content"
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.CONFIRMATION_USER_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.STATUSES,
          selectedOptions: statusSelected
        },
        {
          queryKey: queryParamsKeys.TYPES,
          selectedOptions: typesSelected
        },
        {
          queryKey: queryParamsKeys.CUSTOM_FIELDS,
          selectedOptions: aggregatedCustomFieldsSelected as string[]
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
      setSelectedOptionsArr={
        [setWarehousesSelected, setProjectsSelected, setUsersSelected, setStatusSelected, setCustomFieldStates] as never
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-base font-medium text-gray-800">
            <FilterLinesIcon stroke={colors.GRAY_800} /> {t("warehouse.Umumiy filterlar")}
          </div>
          <div className={styles.content}>
            <FilterSelect
              selectedOptions={warehousesSelected}
              setSelectedOptions={setWarehousesSelected}
              data={warehouses}
              multiple="multiple"
              icon={<MainWarehouseIcon />}
              placeholder={t("warehouse.Omborxonalar")}
              showSearch
            />
            <FilterTreeSelect
              selectedOptions={projectsSelected}
              setSelectedOptions={setProjectsSelected}
              data={projects}
              multiple={true}
              icon={<BuildingIcon />}
              placeholder={t("warehouse.Loyihalar")}
              showSearch
            />
            <FilterSelect
              selectedOptions={statusSelected}
              setSelectedOptions={setStatusSelected}
              data={paymentTypeData}
              multiple="multiple"
              icon={<ZapIcon />}
              placeholder={t("warehouse.Holati")}
              showSearch
            />
            <FilterSelect
              selectedOptions={typesSelected}
              setSelectedOptions={setTypesSelected}
              data={typesData}
              multiple="multiple"
              icon={<PackageXIcon />}
              placeholder={t("warehouse.Yaroqsizlik turi")}
              showSearch
            />
            <FilterSelect
              selectedOptions={usersSelected}
              setSelectedOptions={setUsersSelected}
              data={admins}
              multiple="multiple"
              icon={<UsersIcon />}
              placeholder={t("warehouse.Xodimlar")}
              isPersons
              className={styles.admins__select}
              showSearch
            />
            <FilterDateRange value={dateValue} setValue={setDateValue} />
          </div>
        </div>
        {selectableCustomFields?.length! > 0 && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center gap-2 text-base font-medium text-gray-800">
              <CustomFieldIcon stroke={colors.GRAY_800} width={20} /> {t("warehouse.O'zgaruvchi filterlar")}
            </div>
            <div className={styles.content}>
              {selectableCustomFields?.map(el => (
                <FilterSelect
                  key={el.id}
                  selectedOptions={customFieldStates[el.id]}
                  setSelectedOptions={value => handleCustomFieldChange(String(el.id), value)}
                  data={el.custom_field_options as never}
                  multiple="multiple"
                  icon={customFieldIcons[el.type]}
                  placeholder={el.name[i18n.language]}
                  showSearch
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerDefects;
