import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Col, Form, Input, Row, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../../hooks/redux";
import { RootState } from "../../../../../../store";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import PlusCircleIcon from "../../../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import { RU } from "../../../../../app/utils/constants/languages";
import { localeFormatter } from "../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import { pieceRemoveNaN } from "../../../../../app/utils/helpers/pieceRemoveNaN";
import { useDeleteHistoryOrder, useDeleteHistoryOrders, useRecievedOrder } from "../../../../service/mutation";
import { useGetHistoryOrder, useGetHistoryOrderById } from "../../../../service/query";
import { OrderEnum } from "../../../../utils/constants/OrderEnum";
import { OrderHistoryModel } from "../../../../utils/models/orderHistoryModel";

import styles from "./additionalModalLeft.module.scss";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import PopoverShortName from "features/app/components/popover-short-name/PopoverShortName";
import { useRoutePermissions } from "hooks/useRoutePermissions";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { cx } from "modules/common";

const { Item } = Form;

type Props = {
  selectedIds: number[];
  setSelectedIds: Dispatch<SetStateAction<number[]>>;
};

const AdditionalTable: React.FC<Props> = ({ selectedIds, setSelectedIds }) => {
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const partyActions = actions("Partiyalar");
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const contentRef = useRef<HTMLDivElement>(null);
  const [isCreating, setIsCreating] = useState(false);
  const { data: order } = useAppSelector(state => state.warehouseOrderReducer.view);
  const { data: record, order_id } = useAppSelector((state: RootState) => state.warehouseOrderReducer.additional);
  const { data, isLoading } = useGetHistoryOrder(record?.id);
  const { data: orderData, isLoading: isOrderLoading } = useGetHistoryOrderById(order_id);
  const recievedOrder = useRecievedOrder();
  const deleteOrder = useDeleteHistoryOrder();
  const deleteHistoryOrders = useDeleteHistoryOrders();

  const onCreating = () => {
    setIsCreating(true);
    if (!isCreating) {
      form.resetFields();
    }
  };

  const onChangeAcceptQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const removeNanValue = pieceRemoveNaN(e.currentTarget.value, record?.unit?.is_piece);

    form.setFieldValue("quantity", localeFormatter(removeNanValue));
  };

  const onFinish = (values: OrderHistoryModel) => {
    const currentQuantity = parseLocaledString(String(values?.quantity));

    if (currentQuantity && currentQuantity > 0) {
      const reqData = {
        id: order!.id,
        warehouse_products: [
          {
            id: record!.id,
            quantity: currentQuantity
          }
        ]
      };

      recievedOrder.mutateAsync(reqData).finally(() => {
        form.resetFields();
        setIsCreating(false);
      });
    } else {
      form.resetFields();
      setIsCreating(false);
    }
  };

  const onDeleteHistoryOrder = (id: number) => deleteOrder.mutateAsync(id);
  const onDeleteHistoryOrders = () => deleteHistoryOrders.mutateAsync(selectedIds);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contentRef.current && !contentRef.current.contains(event.target as Node)) {
        setIsCreating(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [form, isCreating]);

  const onAllSelectChange = (e: CheckboxChangeEvent) => {
    if (selectedIds?.length === orderData?.length) {
      setSelectedIds([]);
    } else {
      setSelectedIds(orderData!?.map(el => el.id));
    }
  };

  const onChangeOneSelect = (e: CheckboxChangeEvent) => {
    const {
      target: { value }
    } = e;
    if (selectedIds?.includes(value)) {
      const filteredIds = selectedIds?.filter(id => id !== value);
      setSelectedIds(filteredIds);
    } else {
      setSelectedIds([...selectedIds, value]);
    }
  };

  return (
    <div className={styles.table}>
      <div className={styles.table__header}>
        <Row gutter={0}>
          {order_id && (
            <Col span={12} className={styles.table__header__item}>
              <div className="flex items-center gap-2">
                {!(order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED) &&
                  partyActions?.deleteAcceptanceView && (
                    <Checkbox
                      onChange={onAllSelectChange}
                      checked={orderData?.length! > 0 && selectedIds.length === orderData?.length}
                    />
                  )}
                {t("warehouse.Resurs turi va nomi")}
              </div>
            </Col>
          )}
          <Col span={order_id ? 6 : 12} className={styles.table__header__item}>
            {t("warehouse.Miqdori")}
          </Col>
          <Col span={order_id ? 6 : 12} className={styles.table__header__item}>
            {t("warehouse.Sana")}
          </Col>
          {selectedIds?.length > 0 &&
            !(order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED) &&
            partyActions?.deleteAcceptanceView && (
              <div className="absolute left-[93%] top-3 cursor-pointer">
                <ModalConfirm onOk={() => onDeleteHistoryOrders()} onAfterClose={() => setSelectedIds([])}>
                  <DeleteIcon />
                </ModalConfirm>{" "}
              </div>
            )}
        </Row>
      </div>
      {!order_id && (
        <Spin spinning={isLoading || deleteOrder.isLoading} indicator={LoadingIndicator}>
          <div className={styles.table__content}>
            {data?.histories?.map(item => (
              <Row gutter={0} key={item?.id}>
                <Col span={12} className={styles.table__content__item}>
                  <div className="flex w-full gap-2 px-3">
                    {item?.quantity?.toLocaleString(RU)} <span>{record?.unit?.symbol[i18n.language]}</span>
                  </div>
                </Col>
                <Col span={10} className={styles.table__content__item}>
                  {item?.recieved_date}
                </Col>
                {!(order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED) &&
                  partyActions?.deleteAcceptanceView && (
                    <Col span={2} className={styles.table__content__item}>
                      <ModalConfirm onOk={() => onDeleteHistoryOrder(item.id)}>
                        <DeleteIcon />
                      </ModalConfirm>
                    </Col>
                  )}
              </Row>
            ))}
            {isCreating && (
              <Form form={form} onFinish={onFinish}>
                <Row gutter={0}>
                  <Col span={6} className={cx(styles.table__content__item)} ref={contentRef}>
                    <div className="p-[0.75rem]">
                      <Item
                        name="quantity"
                        initialValue={
                          data && data?.recieved_quantity > data?.quantity
                            ? 0
                            : data && data?.quantity - data?.recieved_quantity
                        }
                      >
                        <Input onChange={e => onChangeAcceptQuantity(e)} disabled={recievedOrder.isLoading} autoFocus />
                      </Item>
                    </div>
                  </Col>
                </Row>
                <Button htmlType="submit" className="hidden" />
              </Form>
            )}
            {!isCreating &&
              !(
                order?.status === OrderEnum.REJECTED ||
                order?.status === OrderEnum.RECIEVED ||
                order?.status === OrderEnum.PENDING
              ) && (
                <div className={styles.table__footer}>
                  <div className={styles.add_resource} onClick={onCreating}>
                    <PlusCircleIcon fill />
                    {t("warehouse.Qabul qilish")}
                  </div>
                </div>
              )}
          </div>
        </Spin>
      )}
      {order_id && (
        <Spin spinning={isOrderLoading || deleteOrder.isLoading}>
          <div className={styles.table__content}>
            {orderData?.map(item => (
              <Row gutter={0} key={item?.id}>
                <Col span={12} className={styles.table__content__item}>
                  <div className="flex w-full gap-2 px-3">
                    {!(order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED) &&
                      partyActions?.deleteAcceptanceView && (
                        <Checkbox
                          onChange={onChangeOneSelect}
                          value={item?.id}
                          checked={selectedIds?.includes(item?.id)}
                        />
                      )}

                    <div className="resource">
                      <div
                        style={{
                          borderColor: item?.product?.resource?.color,
                          color: item?.product?.resource?.color
                        }}
                      >
                        {item?.product?.resource?.symbol[i18n.language]}
                      </div>
                      <span>
                        <PopoverShortName length={25} title={item?.product?.name[i18n.language]} />
                      </span>
                    </div>
                  </div>
                </Col>
                <Col span={5} className={styles.table__content__item}>
                  {item?.quantity?.toLocaleString(RU)} <span>{record?.unit?.symbol[i18n.language]}</span>
                </Col>
                <Col span={5} className={styles.table__content__item}>
                  {item?.recieved_date}
                </Col>
                {!(order?.status === OrderEnum.REJECTED || order?.status === OrderEnum.RECIEVED) &&
                  partyActions?.deleteAcceptanceView && (
                    <Col span={2} className={styles.table__content__item}>
                      <ModalConfirm onOk={() => onDeleteHistoryOrder(item.id)}>
                        <DeleteIcon />
                      </ModalConfirm>
                    </Col>
                  )}
              </Row>
            ))}
          </div>
        </Spin>
      )}
    </div>
  );
};

export default AdditionalTable;
