import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { ChartType, OneCompanyWidget, oneCompanyWidgetSchema, WidgetType } from "modules/dashboard";

import { dashboardQueryKeys } from "../query-keys";

interface Query {
  data: OneCompanyWidget;
}

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
  type?: WidgetType;
  chartType?: ChartType;
  otherProps?: object;
}

export function useOneCompanyWidget({ enabled = false, id, type, chartType, otherProps = {} }: Params) {
  const initialValue: Query = {
    data: {
      id: 0,
      name: {
        uz: ""
      },
      data: []
    }
  };
  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    id,
    type,
    chartType,
    ...otherProps
  } as never);

  const { data = initialValue, ...arg } = useQuery(
    [dashboardQueryKeys.ONE_COMPANY_WIDGETS, searchParams],
    async () => {
      const { data: baseData } = await $api.get(`company-widget/view?${searchParams}`);

      const data: OneCompanyWidget = oneCompanyWidgetSchema.parse(baseData?.data);

      return { data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
