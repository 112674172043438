import React from "react";
import { Button } from "antd";
import { useDispatch } from "react-redux";

import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import { builderActions } from "../../../../../store/reducers/builderReducer";

import styles from "./header.module.scss";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { setBuilderModal } = builderActions;

  const openBuilderModal = () => {
    dispatch(
      setBuilderModal({
        builderModal: {
          visible: true,
        },
      })
    );
  };
  return (
    <div className={styles.header_container}>
      <div>
        <h2>{t("settings.Quruvchi")}</h2>
        <p>{t("settings.Bu yerda siz quruvchini o'zgartirishingiz mumkin")}</p>
      </div>
      <div className={styles.btn_container}>
        <Button onClick={openBuilderModal}>
          <PlusIcon />
          {t("settings.Quruvchi qo'shish")}
        </Button>
      </div>
    </div>
  );
};

export default Header;
