import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, Spin } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import { dayjsFormats } from "../../../../../../../../../../app/utils/constants/dayjsFormats";
import CaretCircleDownIcon from "../../../../../../../../../assets/icons/CaretCircleIcon";
import { projectStatuses } from "../../../../../../../../../utils/enums/projectStatuses";
import { useTaskUpdate } from "../../../services/mutation";
import { useGanttTask } from "../../../services/queries";
import { queryKeys } from "../../../utils/constants/queryKeys";
import { GanttSection, GanttSectionData } from "../../../utils/models/GanttSection";
import { GanttTaskType } from "../../../utils/models/GanttTaskType";
import GanttTableConfirmationUsers from "../gantt-table-task/gantt-table-confirmation-users/GanttTableConfirmationUsers";
import GanttTableTaskStatus from "../gantt-table-task/gantt-table-task-status/GanttTableTaskStatus";
import GanttTableTasks from "../gantt-table-task/GanttTableTasks";
import { useQueryParams } from "../../../../../../../../../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../../../../../../../../../app/utils/constants/queryParamsKeys";
import { endPoints } from "../../../utils/constants/endPoints";
import { TaskStatus } from "../../../utils/models/TaskStatus";

import styles from "../gantt-table-task/ganttTableTask.module.scss";
import { LoadingIndicator } from "../../../../../../../../../../app/components/loading-indicator/LoadingIndicator";

interface IProps extends GanttSection {
  sectionId: number;
  activeAll: boolean;
  elementType?: GanttTaskType;
  projectStatus?: projectStatuses;
}

const GanttTableSection = (props: IProps) => {
  const { tableSetting } = useAppSelector(state => state.ganttReducer);
  const settingsValue = useAppSelector(state => state.ganttReducer.settings);
  const { id, place, name, start_date, due_date, elementType, sectionId, company_persons, projectStatus, activeAll } =
    props;
  const params = useParams();
  const projectId = Number(params?.projectId);
  const { reqQueryParam, searchParams } = useQueryParams();
  const qc = useQueryClient();
  const [activeSection, setActiveSection] = useState(false);
  const { data: dataTask, isFetching } = useGanttTask(id, activeSection);
  const taskUpdate = useTaskUpdate(sectionId);

  const hadleSection = () => {
    setActiveSection(!activeSection);
    let url = endPoints.GANTT_SECTION(projectId);
    if (searchParams && searchParams?.length > 0) {
      url += `&${reqQueryParam(
        queryParamsKeys.GENERAL_SEARCH,
        queryParamsKeys.MAX_DUE_DATE,
        queryParamsKeys.MIN_DUE_DATE,
        queryParamsKeys.MAX_START_DATE,
        queryParamsKeys.MIN_DUE_DATE,
        queryParamsKeys.SECTION_IDS_ARR,
        queryParamsKeys.TASK_STATUSES_ARR,
        queryParamsKeys.USER_IDS_ARR,
        queryParamsKeys.COMPANY_PERSON_IDS_ARR
      )}`;
    }
    qc.setQueryData<GanttSectionData>([queryKeys.GANTT_SECTION, projectId, url], data => {
      return data
        ? {
            ...data,
            sections: data?.sections?.map(item => ({
              ...item,
              active: item?.id === id ? !item?.active : item?.active || false
            }))
          }
        : undefined;
    });
  };

  useEffect(() => {
    setActiveSection(activeAll);
  }, [activeAll]);

  const changeTaskDate = (start: string | null | undefined, end: string | null | undefined) => {
    taskUpdate.mutate({
      id,
      start_date: start,
      due_date: end
    });
  };

  const formatDate = "DD MMM YYYY";

  const nameSize = (tableSetting["--gantt-chart-table-name"] || 10) / 10;

  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.item} ${styles.item_name}`} onClick={hadleSection} title={name}>
          <span className={styles.caret_icon_container}>
            <CaretCircleDownIcon placement={activeSection ? "bottom" : "right"} />
          </span>
          {place}. {name.length > nameSize ? name.slice(0, nameSize) + "..." : name}
          <Spin spinning={isFetching} style={{ position: "absolute", right: 32 }} indicator={LoadingIndicator}></Spin>
        </div>
        {settingsValue?.percent && (
          <div
            className={`${styles.item} ${styles.item_percent} ${
              props?.status === TaskStatus.COMPLETED ? styles.completed : ""
            }`}
          >
            {props?.done_percentage}
          </div>
        )}
        {settingsValue?.confirmation_person && (
          <GanttTableConfirmationUsers
            companyPersons={company_persons}
            users={props?.users}
            id={id}
            sectionId={sectionId}
            projectStatus={projectStatus}
            elementType={elementType}
          />
        )}
        {settingsValue?.status && (
          <div className={`${styles.item} ${styles.item_status}`}>
            {props?.is_title ? (
              ""
            ) : (
              <GanttTableTaskStatus
                status={props?.status}
                sectionId={sectionId}
                id={id}
                elementType="section"
                projectStatus={projectStatus}
              />
            )}
          </div>
        )}
        {settingsValue?.start_date && settingsValue?.due_date && (
          <div className={`${styles.item} ${styles.item_startDate}`}>
            {props?.is_title ? (
              ""
            ) : (
              <>
                {elementType === "section" || props?.projectStatus !== projectStatuses.PLANNING ? (
                  start_date ? (
                    dayjs(start_date, dayjsFormats.DATE).format(formatDate)
                  ) : (
                    "--"
                  )
                ) : (
                  <Spin spinning={taskUpdate.isLoading} indicator={LoadingIndicator}>
                    <DatePicker
                      {...(start_date && {
                        value: dayjs(start_date, dayjsFormats.DATE)
                      })}
                      format={formatDate}
                      size="small"
                      onChange={value => changeTaskDate(value?.format(dayjsFormats.DATE), due_date)}
                      disabledDate={date => {
                        return date > dayjs(due_date, dayjsFormats.DATE);
                      }}
                      placeholder="--"
                      
                    />
                  </Spin>
                )}
              </>
            )}
          </div>
        )}
        {settingsValue?.due_date && (
          <div className={`${styles.item} ${styles.item_dueDate}`}>
            {props?.is_title ? (
              ""
            ) : (
              <>
                {elementType === "section" || projectStatus !== projectStatuses.PLANNING ? (
                  due_date ? (
                    dayjs(due_date, dayjsFormats.DATE).format(formatDate)
                  ) : (
                    "--"
                  )
                ) : (
                  <Spin spinning={taskUpdate.isLoading} indicator={LoadingIndicator}>
                    <DatePicker
                      {...(due_date && {
                        value: dayjs(due_date, dayjsFormats.DATE)
                      })}
                      format={formatDate}
                      size="small"
                      onChange={value => changeTaskDate(start_date, value?.format(dayjsFormats.DATE))}
                      disabledDate={date => {
                        return date < dayjs(start_date, dayjsFormats.DATE);
                      }}
                      placeholder="--"
                    />
                  </Spin>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {activeSection && (
        <GanttTableTasks
          sectionId={sectionId}
          sectionPlace={place}
          dataTasks={dataTask}
          projectStatus={projectStatus}
        />
      )}
    </>
  );
};

export default GanttTableSection;
