import React from "react";

const PlusBorderIcon: React.FC = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 3H6.7C5.5799 3 5.01984 3 4.59202 3.21799C4.21569 3.40973 3.90973 3.71569 3.71799 4.09202C3.5 4.51984 3.5 5.0799 3.5 6.2V8M8.5 21H6.7C5.5799 21 5.01984 21 4.59202 20.782C4.21569 20.5903 3.90973 20.2843 3.71799 19.908C3.5 19.4802 3.5 18.9201 3.5 17.8V16M21.5 8V6.2C21.5 5.0799 21.5 4.51984 21.282 4.09202C21.0903 3.71569 20.7843 3.40973 20.408 3.21799C19.9802 3 19.4201 3 18.3 3H16.5M21.5 16V17.8C21.5 18.9201 21.5 19.4802 21.282 19.908C21.0903 20.2843 20.7843 20.5903 20.408 20.782C19.9802 21 19.4201 21 18.3 21H16.5M12.5 17L12.5 7M7.5 12H17.5"
        stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default PlusBorderIcon;