import React, { useContext } from "react";
import { FieldErrors, useForm, UseFormReturn } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import i18n from "i18next";

import { useLanguagesSelect } from "modules/common/queries";

import { WidgetContext } from "../../context";
import { useUpdateWidget } from "../../mutations";
import { UpdateWidgetForm, updateWidgetFormSchema } from "../schema";

type Props = {
  children: (props: UseFormReturn<UpdateWidgetForm>) => React.ReactNode;
};

export const Update: React.FC<Props> = ({ children }) => {
  const { widget, methods } = useContext(WidgetContext);
  const { languages } = useLanguagesSelect();
  const form = useForm<UpdateWidgetForm>({
    resolver: zodResolver(updateWidgetFormSchema),
    defaultValues: {
      id: widget?.id,
      name: widget?.name[i18n.language],
      user_ids: widget?.users?.map(i => i.id) || [],
      title: widget?.title[i18n.language]
    }
  });

  const mutation = useUpdateWidget();

  const onSubmit = async (values: UpdateWidgetForm) => {
    await new Promise(onSettled => {
      let generateName: Record<string, string> = {};

      let generateTitle: Record<string, string> = {};

      languages?.forEach(lang => {
        generateName = {
          ...generateName,
          [lang.symbol]: values.name
        };
        generateTitle = {
          ...generateTitle,
          [lang.symbol]: values.title!
        };
      });

      mutation.mutate(
        { ...values, name: generateName, title: generateTitle },
        {
          onSettled,
          onSuccess: () => {
            methods.setWidgetModal({
              visibleWidgetModal: false,
              isUpdateWidget: false,
              widget: widget!,
              visible: false
            });
          }
        }
      );
    });
  };

  const onError = (error: FieldErrors) => {
    throw new Error(error.root?.message);
  };

  return <form onSubmit={form.handleSubmit(onSubmit, onError)}>{children(form)}</form>;
};

export default Update;
