import { CurrencyBasicModel } from "features/settings/utils/models/currency/currencyModel";

import { useAuth } from "modules/auth/hooks";

export const useBaseCurrency = (): CurrencyBasicModel => {
  const { currencies } = useAuth();

  return (
    currencies?.find(item => item?.type === "base") ||
    ({
      symbol: "UZS",
      amount: 1,
      id: 0,
      type: "base",
      name: { uz: "O'zbek so'mi" }
    } as any)
  );
};
