import { Spin } from "antd";
import { FC, MouseEvent, useCallback, useEffect, useState } from "react";

import PlusCircleIcon from "../../../../../../app/assets/icons/PlusCircleIcon";
import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { useCreateTask } from "../../../../../service/mutations";
import Title from "../title/Title";

import classNames from "classnames";
import styles from "../task.module.scss";
import titleStyle from "./titleAppend.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  sectionId: number;
};

export const TitleAppend: FC<Props> = ({ sectionId }) => {
  const createTask = useCreateTask();
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const { t } = useTranslation();

  const stopPropagation = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onOpenForm = (e: MouseEvent<HTMLDivElement>) => {
    stopPropagation(e);

    if (!isCreating) setIsCreating(true);
  };

  const onCloseForm = useCallback(() => {
    if (isCreating) setIsCreating(false);
  }, [isCreating]);

  useEffect(() => {
    window.addEventListener("click", onCloseForm);

    return () => {
      window.removeEventListener("click", onCloseForm);
    };
  }, [onCloseForm]);

  return (
    <Spin spinning={createTask.isLoading} indicator={LoadingIndicator}>
      <div className={styles.title_append}>
        {isCreating && (
          <div className={classNames(styles.create, { [styles.active]: isCreating })} onClick={stopPropagation}>
            <Title name={undefined} afterSuccess={onCloseForm} sectionId={sectionId} />
          </div>
        )}
        <div
          onClick={onOpenForm}
          className={classNames(styles.button, titleStyle.title_button, {
            [styles.button__passive]: isCreating
          })}
        >
          <PlusCircleIcon />
          {t("project.Sarlavha qo'shish")}
        </div>
      </div>
    </Spin>
  );
};
