export const percentAndDateStyle = (amount: number) => ({
  "text-error-500": amount > 0,
  "border-error-100": amount > 0,
  "bg-error-100": amount > 0,
  "text-success-500": amount < 0,
  "border-success-100": amount < 0,
  "bg-success-100": amount < 0,
  "text-gray-700": amount === 0,
  "border-gray-500": amount === 0,
  "bg-transparent": amount === 0
});
