import { TypedUseSelectorHook, useDispatch as useBaseDispatch, useSelector as useBaseSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

import reducer from "./reducers";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useDispatch = () => useBaseDispatch<AppDispatch>();

const useSelector: TypedUseSelectorHook<RootState> = useBaseSelector;

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false })
});

const persistor = persistStore(store);

export { persistor, store, useDispatch, useSelector };

export * from "./reducers/reducer";
