import { notification } from "antd";

import AlertIcon from "../../../assets/icons/AlertIcon";

import styles from "./estimateNotification.module.scss";

export const openEstimateNotification = (title: string) => {
  notification.open({
    message: (
      <h4 className={styles.notification__title}>Smetadan tashqari {title}!</h4>
    ),
    description: `Smetadan tashqari ${title} qo‘shildi bu “Fakt” va “Bashoratga” ta'sir qilishi mumkin.`,
    placement: "topRight",
    icon: <AlertIcon />,
  });
};
