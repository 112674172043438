import React from "react";
import { Select } from "antd";
import TableEmpty from "features/app/components/table-empty/TableEmpty";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { sliceText } from "features/app/utils/helpers/sliceText";
import ChevroDownIcon from "features/projects/assets/icons/ChevroDownIcon";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import { useTranslation } from "react-i18next";

import { colors } from "modules/common";

import styles from "../../orderModalLeft.module.scss";

const { Option } = Select;

type Props = {
  changeSelectItem: (value: unknown, key: string) => void;
};

const OrderTableHeaderWarehouseSelect: React.FC<Props> = ({ changeSelectItem }) => {
  const { data: warehouses } = useGetWarehouseSelect(false, true);
  const { t } = useTranslation();

  const onChangeWarehouse = (e: number) => {
    changeSelectItem(e, "warehouse_id");
  };

  return (
    <Select
      showSearch
      value={null}
      placeholder={<span className='text-gray-600'>{t("supply.Omborxona")}</span>}
      onChange={onChangeWarehouse}
      filterOption={selectFilterOption}
      className={styles.header_warehouse_select}
      notFoundContent={<TableEmpty />}
      suffixIcon={<ChevroDownIcon active={true} stroke={colors.GRAY_500} />}
    >
      {warehouses?.map(item => (
        <Option
          key={item?.id}
          value={item?.id}
          props={{
            name: item?.name
          }}
        >
          {sliceText(item?.name, 11)}
        </Option>
      ))}
    </Select>
  );
};

export default OrderTableHeaderWarehouseSelect;
