import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { useWidget } from "../hooks";
import { dashboardQueryKeys } from "../query-keys";
import { ProjectColumnProject } from "../schema";

type Query = {
  customFieldProjects: ProjectColumnProject[];
};

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
}

export function useProjectByCustomFieldProjects({ id, enabled }: Params) {
  const { methods } = useWidget();

  const initialData = {
    customFieldProjects: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_BY_CUSTOM_FIELD_PROJECTS],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-custom-field/view?id=${id}`);

      return { customFieldProjects: data };
    },
    {
      enabled,
      onSuccess: ({ customFieldProjects }) => {
        methods.setWidgetDrawer({
          props: {
            projectId: customFieldProjects?.[0]?.id
          }
        });
      }
    }
  );

  return { ...data, ...arg };
}
