import React from "react";

type Props = {
  stroke?: string;
  width?: number;
};

const XCloseCircleIcon: React.FC<Props> = ({ stroke, width }) => {
  return (
    <svg
      width={width || "24"}
      height={width || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="11" fill="#0E0C0C" stroke="white" strokeWidth="2" />
      <path
        d="M16.5 7.5L7.5 16.5M7.5 7.5L16.5 16.5"
        stroke={stroke || "white"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default XCloseCircleIcon;
