export enum Status {
  ACTIVE = "active",
  PASSIVE = "passive",
  PENDING = "pending",
  CANCEL = "cancel",
  DELETED = "deleted"
}

export const StatusNames = {
  [Status.PASSIVE]: "Faol emas",
  [Status.ACTIVE]: "Faol",
  [Status.PENDING]: "Kutilmoqda",
  [Status.CANCEL]: "Yopilgan",
  [Status.DELETED]: "O'chirilgan",
};
