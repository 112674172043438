import React, { useState } from "react";
import { Button } from "antd";
import queryString from "query-string";

import FilterSelect from "../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDrawer from "../../../../../app/components/filter-drawer/FilterDrawer";
import FiltersCount from "../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { useGetCompanyPersonSelect } from "../../../../../counterparts/service/queries";
import FilterLinesIcon from "../../../../../warehouse/assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "../../../../../warehouse/service/query";
import { useGetProjectSelect } from "../../../../service/queries";

import styles from "./calendarFilterDrawer.module.scss";
import { useTranslation } from "react-i18next";
import { useProjectSelect } from "features/app/service/queries";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";

const queryStringArr = [queryParamsKeys.PROJECT_IDS, queryParamsKeys.WAREHOUSE_IDS, queryParamsKeys.COMPANY_PERSON_IDS];

export const CalendarFilterDrawer: React.FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { data: warehouses } = useGetWarehouseSelect(true);
  const { data: companyPersons } = useGetCompanyPersonSelect(visible);
  const { data: projects } = useProjectSelect({ enabled: visible });

  const [warehousesSelected, setWarehousesSelected] = useState<string[] | null>(
    queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );
  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );

  const onOpen = () => {
    setVisible(true);
  };

  return (
    <FilterDrawer
      open={visible}
      height="max-content"
      setOpen={setVisible}
      className={styles.calendar}
      queryStringArr={queryStringArr}
      filterButton={
        <Button onClick={onOpen}>
          <FilterLinesIcon />
          {t("Counterparts.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: warehousesSelected
        },
        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        },
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        }
      ]}
      setSelectedOptionsArr={[setWarehousesSelected, setProjectsSelected, setCompanyPersonsSelected] as never}
    >
      <div className={styles.content}>
        <FilterSelect
          showSearch
          data={warehouses}
          multiple="multiple"
          placeholder={t("Counterparts.Omborxona")}
          selectedOptions={warehousesSelected}
          setSelectedOptions={setWarehousesSelected}
        />
        <FilterTreeSelect
          showSearch
          data={projects}
          multiple={true}
          placeholder={t("Counterparts.Loyiha")}
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
        />
        <FilterSelect
          isPersons
          showSearch
          multiple="multiple"
          placeholder={t("Counterparts.Ta'minotchi")}
          data={
            companyPersons?.map(item => ({
              id: item?.id,
              name: item?.name
            })) as never[]
          }
          selectedOptions={companyPersonsSelected}
          setSelectedOptions={setCompanyPersonsSelected}
        />
      </div>
    </FilterDrawer>
  );
};

export default CalendarFilterDrawer;
