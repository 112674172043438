import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const estimateTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "quantity",
      name: "quantity",
      title: "Soni",
      checked: true
    },
    {
      id: "unit",
      name: "unit",
      title: "Birligi",
      checked: true
    },
    {
      id: "amount",
      name: "amount",
      title: "Narxi",
      checked: true
    }
  ],
  size: 10
};
