import React from "react";
import { Button, FormInstance, Modal, Upload, UploadFile, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import CloseIcon from "features/app/assets/icons/CloseIcon";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import UploadIcon from "features/app/assets/icons/UploadIcon";
import { useGetUnitSelect } from "features/app/service/queries";
import { IProductList } from "features/counterparts/utils/models/productListModel";
import { useGetCompanyCurrency } from "features/inventory/service/queries";
import { useGetProductsSelect } from "features/supply/service/queries";
import { useTranslation } from "react-i18next";
import { uid } from "uid";
import * as XLSX from "xlsx";

import styles from "./productListImportModal.module.scss";

type Props = {
  form: FormInstance<any>;
  visible: boolean;
  setVisibleModal: (value: boolean) => void;
  title: string;
  defaultLink: string;
  onAfterFunc?: () => void;
  isCatalog?: boolean;
};

const ProductListImportModal: React.FC<Props> = ({
  form,
  setVisibleModal,
  visible,
  defaultLink,
  title,
  onAfterFunc,
  isCatalog
}) => {
  const { i18n, t } = useTranslation();
  const onCancel = () => {
    setVisibleModal(false);
  };

  const { data: productsList } = useGetProductsSelect();
  const { data: units } = useGetUnitSelect();
  const { data: currencies } = useGetCompanyCurrency(visible);

  const oldProducts = useWatch("products", form) as IProductList[];

  const modalFooter = (
    <Button href={`${import.meta.env.VITE_BASE_URL.replace("/main/", "")}/public/upload/import/${defaultLink}`}>
      <DownloadIcon /> {t("Counterparts.Namuna")}
    </Button>
  );

  const onChange = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj;

    // Check if the file is an Excel file
    if (
      file?.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      file?.type !== "application/vnd.ms-excel"
    ) {
      message.error(`${t("Counterparts.Iltimos excel file yuklang!!!")}`);
      return;
    }

    const reader = new FileReader();

    reader.onload = e => {
      try {
        const data = e.target?.result;
        const workbook = XLSX.read(data, { type: "binary" });

        const result: { sheetName: string; data: unknown[] }[] = [];

        workbook.SheetNames.forEach(sheetName => {
          const sheet = workbook.Sheets[sheetName];
          const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });

          result.push({ sheetName, data });
        });

        const rows: any = result[0].data;

        const products: IProductList[] = [];

        for (let i = 1; i < rows.length; i++) {
          let productId = rows[i][0];
          let currencyId = rows[i][3];
          let unitId = rows[i][1];
          const foundProduct = productsList?.pages[0].data?.find(
            product => product.name[i18n.language]?.toLowerCase() === rows[i][0]?.toLowerCase()
          );
          const foundCurrency = currencies?.find(
            currency => currency.symbol.toLowerCase() === rows[i][3]?.toLowerCase()
          );
          const foundUnit = units?.find(unit => unit.symbol[i18n.language].toLowerCase() === rows[i][1]?.toLowerCase());

          if (foundProduct) {
            productId = foundProduct?.id;
          }
          if (foundCurrency) {
            currencyId = foundCurrency?.id;
          }

          if (foundUnit) {
            unitId = foundUnit?.id;
          }

          // Validate that the row contains required fields for either structure
          const isValidStructure1 = rows[i][2] && currencyId && productId && unitId;
          const isValidStructure2 = productId && unitId && rows[i][2];

          if (!isValidStructure1 && !isValidStructure2) {
            message.error(`Invalid data structure at row ${i + 1}`);
            return;
          }

          const newProduct: IProductList = isValidStructure1
            ? {
                amount: rows[i][2],
                currency_id: currencyId,
                product_id: isCatalog ? rows[i][0] : productId,
                unit_id: unitId,
                rowId: uid(),
                related_offers: [],
                code: rows[i][2]
              }
            : {
                product_id: isCatalog ? rows[i][0] : productId,
                unit_id: unitId,
                code: rows[i][2],
                rowId: uid(),
                related_offers: [],
                amount: null,
                currency_id: null
              };

          products.push(newProduct);
        }

        if (oldProducts?.length > 0) {
          form.setFieldsValue({ products: [...oldProducts, ...products] });
        } else {
          form.setFieldsValue({ products });
        }
      } catch (error) {
        message.error("Error reading the Excel file. Please check the file format.");
      }
    };

    reader.onerror = () => {
      message.error("Error reading the file. Please try again.");
    };

    setTimeout(() => {
      form.validateFields();
    }, 100);
    onCancel();
    setTimeout(() => {
      if (onAfterFunc) {
        onAfterFunc();
      }
    }, 200);

    reader.readAsBinaryString(file as RcFile | Blob);
  };

  return (
    <Modal
      title={t("Counterparts.Fayl yuklash")}
      centered
      width={548}
      closeIcon={<CloseIcon />}
      onCancel={onCancel}
      open={visible}
      footer={modalFooter}
    >
      <div className={styles.content}>
        <Upload onChange={onChange} customRequest={() => null} showUploadList={false}>
          <div className={styles.upload}>
            <UploadIcon />
            <p>
              <span>{title}</span> {t("Counterparts.faylini yuklash uchun bosing yoki faylni shu yerga tashlang")} (.xls, .xlsx)
            </p>
          </div>
        </Upload>
      </div>
    </Modal>
  );
};

export default ProductListImportModal;
