import React, { useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import { Select, SelectProps, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import SelectSuffixIcon from "features/app/assets/icons/SelectSuffixIcon";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { ErrorRes, Product, SuccessRes, Unit } from "modules/common";
import { CompanyWidget, ProductAll, TargetType, useProductAll, useWidget } from "modules/dashboard";
import { UpdateWidget } from "modules/dashboard/forms";

import { LoadingIndicator, TooltipShortName } from "components";

const { Option } = Select;

type Props = {
  id: number;
  defaultUnit?: Unit;
  defaultProduct?: Product;
  filter?: CompanyWidget["filter"];
  updateFilter: UseMutationResult<SuccessRes<CompanyWidget>, ErrorRes, UpdateWidget>;
};

const ProductSelect: React.FC<Props> = ({ filter, id, defaultProduct, defaultUnit, updateFilter }) => {
  const { t } = useTranslation();
  const { methods, widgetProps } = useWidget();
  const productId = widgetProps?.productId || filter?.product_id;
  const { setWidgetProps } = methods;
  const [search, setSearch] = useState<string>();
  const [time, setTime] = useState<TimeoutModel>();
  const { pages, fetchNextPage, isLoading } = useProductAll({ search });

  const products = () => {
    let result: ProductAll[] = [];

    pages?.forEach(page => {
      result = [...result, ...(page?.data || [])];
    });

    if (defaultProduct && !result?.some(item => item?.id === defaultProduct?.id)) {
      // @ts-ignore
      result.unshift({ ...defaultProduct, unit: { ...defaultUnit } });
    }

    return result;
  };

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.abs(target.scrollTop + target.offsetHeight - target.scrollHeight) < 1) {
      fetchNextPage();
    }
  };

  const onChange: SelectProps["onChange"] = (e, option) => {
    const arg = { ...option } as DefaultOptionType;

    setWidgetProps({
      productId: e,
      unitId: arg.props?.unitId
    });

    // @ts-ignore
    updateFilter?.mutate({
      id,
      filter: {
        ...filter,
        unit_id: arg.props?.unitId,
        product_id: e
      }
    });
  };

  const onSearch: SelectProps["onSearch"] = value => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearch(value);
      }, 800)
    );
  };

  return (
    <Select
      showSearch
      value={productId}
      onChange={onChange}
      onSearch={onSearch}
      onPopupScroll={onPopupScroll}
      suffixIcon={<SelectSuffixIcon />}
      filterOption={selectFilterOption}
      defaultValue={products()?.[0]?.id}
      placeholder={t("dashboard.Mahsulotni tanlang")}
      notFoundContent={
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          <SelectNotContent title={t("dashboard.Mahsulot")} />
        </Spin>
      }
    >
      {products()?.map(product => (
        <Option
          value={product?.id}
          key={`${product?.id}-${product?.unit?.id}`}
          props={{
            unitId: product?.unit?.id,
            name: product?.name?.[i18n.language]
          }}
        >
          <div className="flex w-full gap-3">
            <div
              className="flex items-center rounded border border-solid px-2 text-xs font-medium"
              style={{
                borderColor: product?.resource?.color,
                color: product?.resource?.color
              }}
            >
              {product?.resource?.symbol?.[i18n.language]}
            </div>
            <div className="flex flex-1 items-center justify-between gap-4 text-base font-normal text-gray-800">
              <TooltipShortName length={54} title={product?.name?.[i18n.language]} />
              <div className="text-sm font-medium text-gray-400">{product?.unit?.symbol?.[i18n.language]}</div>
            </div>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default ProductSelect;
