export const inventoryEndPoints = {
  FOLDERS_VIEW: "inventory-folder/view",
  FOLDERS_SELECT: "inventory-folder/select",
  INVENTORIES_VIEW: "inventory/view",
  INVENTORY_WORK: "inventory/work",
  CP_INVENTORY_WORK: "company-person/inventory/work",
  INVENTORY_PAY: "inventory/payment",
  CP_INVENTORY_PAY: "company-person/inventory/payment",
  INVENTORY_EXPENSE: "inventory/expense",
  INVENTORY_CREATE: "inventory/create",
  INVENTORY_UPDATE: "inventory/update",
  INVENTORY_DELETE: "inventory/delete",
  UPDATE_INVENTORY_FOLDER: "inventory-folder/update",
  CREATE_INVENTORY_FOLDER: "inventory-folder/create",
  DELETE_INVENTORY_FOLDER: "inventory-folder/delete",
  CURRENCY_SELECT: "company-currency/select",
  COMPANY_PERSON_SELECT: "company-person/select",
  REQUESTS_VIEW: "inventory-request/view",
  CHECK_REQUEST: "inventory-request/check",
  PROJECTS_SELECT: "project/select",
  ADMINS_SELECT: "admin/select",
  DEFECT_CREATE: "inventory-defect/create",
  DEFECT_UPDATE: "inventory-defect/update",
  DEFECTS_VIEW: "inventory-defect/view",
  DELETE_DEFECT: "inventory-defect/delete",
  UPLOAD_FILE: "file/upload",
  CONNECT_PROJECT: "inventory/connect-project",
  LEAVE_PROJECT: "inventory/remove-project",
  INVENTORY_DEFECT_EXCEL_EXPORT: "inventory-defect/export-excel",
  INVENTORY_REQUESTS_EXCEL_EXPORT: "inventory-request/export-excel",
  INVENTORY_EXCEL_EXPORT: "inventory/export-excel"
};
