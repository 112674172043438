export enum StatusEnums {
  OPEN = "open",
  PENDING = "pending",
  ORDERED = "ordered",
  PARTIALLY_RECIEVED = "partially_recieved",
  RECIEVED = "recieved",
  REJECTED = "rejected",
  PASSIVE = "passive",
  BUYING = "buying"
}
