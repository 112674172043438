import React from "react";
import { UseFormReturn } from "react-hook-form";
import ProjectSelectWithHookForm from "features/app/components/project-select-with-hook-form/project-select-with-hook-form";

// import { useGetProjectsSelect } from "features/app/service/queries";
import { useWidget, WidgetSettingsForm, widgetStaticData } from "modules/dashboard";

import SelectSuffixIcon from "../../../../features/app/assets/icons/SelectSuffixIcon";

// import { Select } from "components/fields";
import styles from "./settings.module.scss";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
};

const Project = ({ form }: Props) => {
  // const { data: projects } = useGetProjectsSelect(true);

  const { key } = useWidget();
  const { settings } = widgetStaticData[key];
  const { isNotMultipleProjectSelect } = settings;

  // const selectOptions = projects?.map(el => ({
  //   label: el.name,
  //   value: el.id,
  //   props: {
  //     name: el.name
  //   }
  // }));

  return (
    // <Select
    //   label="Loyiha"
    //   mode={!isNotMultipleProjectSelect ? "multiple" : undefined}
    //   maxTagCount={!isNotMultipleProjectSelect ? 2 : undefined}
    //   name={!isNotMultipleProjectSelect ? "project_ids" : "project_id"}
    //   options={selectOptions}
    //   control={form.control}
    //   rootClassName={styles.multiple_select}
    // />
    <ProjectSelectWithHookForm
      form={form}
      label="Loyiha"
      placeholder="Loyiha"
      suffixIcon={<SelectSuffixIcon />}
      multiple={!isNotMultipleProjectSelect}
      rootClassName={styles.multiple_select}
      maxTagCount={!isNotMultipleProjectSelect ? 2 : undefined}
      name={!isNotMultipleProjectSelect ? "project_ids" : "project_id"}
    />
  );
};

export default Project;
