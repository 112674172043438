import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, Form, Input, MenuProps, message, Modal, Select, Table } from "antd";
import { Rule } from "antd/es/form";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { RootState } from "../../../../../store";
import { warehouseTransferActions } from "../../../../../store/reducers/warehouseTransferReducer";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { formRules } from "../../../../app/utils/constants/formRules";
import { useGetProjectSelect } from "../../../../payment/service/queries";
import { useCreateTransfer, useUpdateTransfer } from "../../../service/mutation";
import { useGetWarehouseProductSelect, useGetWarehouseSelect } from "../../../service/query";
import { TransferBodyModel } from "../../../utils/models/transferBodyModel";
import { WarehouseProductModel } from "../../../utils/models/WarehouseProductModel";

import styles from "./transferModal.module.scss";

const { Item } = Form;
const { Option } = Select;

const TransferModal: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { setTransferModal, setTransferDataModal, setProductKeys, setSelectProducts, setIsClickedRow } =
    warehouseTransferActions;
  const { id, data, visible, recieved_project_id, recieved_warehouse_id, sender_warehouse_id } = useAppSelector(
    (state: RootState) => state.warehouseTransferReducer
  );
  const { data: transferView } = useAppSelector((state: RootState) => state.transferReducer.view);

  const createTransfer = useCreateTransfer();
  const updateTransfer = useUpdateTransfer(transferView?.id);
  const { data: warehouse } = useGetWarehouseSelect(false);
  const { data: projects } = useGetProjectSelect({ enabled: visible });
  const { data: products } = useGetWarehouseProductSelect(visible);

  const onCancel = () => {
    dispatch(
      setTransferModal({
        visible: false,
        data: [],
        id: undefined,
        recieved_project_id: undefined,
        sender_warehouse_id: undefined,
        recieved_warehouse_id: undefined
      })
    );
  };

  const onAfterOpen = (open: boolean) => {
    if (open) {
      form.setFieldsValue({
        id,
        recieved_project_id,
        sender_warehouse_id,
        recieved_warehouse_id,
        products:
          data?.map(item => ({
            quantity: item?.total_quantity,
            project_id: item?.project?.id,
            product_id: item?.product?.id,
            unit_id: item?.unit?.id
          })) ?? []
      });
    } else {
      form.resetFields();
    }
  };

  const formQuantityRule = (total: number): Rule[] => [
    {
      message: "",
      required: true
    },
    () => ({
      validator(_, value: string) {
        const quantity = Number(value);

        if (!quantity || quantity < 0 || quantity > total) {
          return Promise.reject();
        }
        return Promise.resolve();
      }
    })
  ];

  const onDeleteProduct = (record: WarehouseProductModel) => {
    if (data) {
      dispatch(setTransferDataModal(data.filter(item => item.product.id !== record.product.id) ?? []));
    }
  };

  const columns: ColumnsType<WarehouseProductModel> = [
    {
      title: "Resurs turi va nomiqwe",
      render: (record: WarehouseProductModel) => (
        <div className="resource">
          <div
            style={{
              borderColor: record?.product?.resource?.color,
              color: record?.product?.resource?.color
            }}
          >
            {record?.product.resource?.symbol[i18n.language]}
          </div>
          <span>{record?.product?.name[i18n.language]}</span>
        </div>
      )
    },
    {
      title: "Soni",
      render: (_, record, index) => (
        <>
          <Item
            name={["products", index, "quantity"]}
            initialValue={record.total_quantity}
            className={styles.quantity}
            rules={formQuantityRule(record?.total_quantity)}
          >
            <Input type="number" placeholder="Soni" />
          </Item>
          <Item name={["products", index, "project_id"]} initialValue={record?.project?.id} className="d_n" />
          <Item name={["products", index, "product_id"]} initialValue={record?.product?.id} className="d_n" />
          <Item name={["products", index, "unit_id"]} initialValue={record?.unit?.id} className="d_n" />
        </>
      )
    },
    {
      title: "Birligi",
      render: (record: WarehouseProductModel) => record?.unit?.symbol[i18n.language]
    },
    {
      title: "Joriy loyiha",
      render: (record: WarehouseProductModel) => record?.project?.name
    },
    id
      ? {
          title: "",
          render: (_, record) => (
            <span className={styles.delete_product} onClick={() => onDeleteProduct(record)}>
              <DeleteIcon />
            </span>
          )
        }
      : {
          className: "d_n"
        }
  ];

  const onFinish = (fields: TransferBodyModel) => {
    if (id) {
      updateTransfer.mutateAsync(fields).then(() => {
        onCancel();
        dispatch(setProductKeys([]));
        dispatch(setSelectProducts([]));
        dispatch(setIsClickedRow(false));
      });
    } else {
      createTransfer.mutateAsync(fields).then(() => {
        onCancel();
        dispatch(setProductKeys([]));
        dispatch(setSelectProducts([]));
        dispatch(setIsClickedRow(false));
      });
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onClickProductItem = (item: WarehouseProductModel) => {
    if (data) {
      const isThereElement = data.some(element => element.product.id === item.product.id);

      if (!isThereElement) {
        const newProducts = [...data, item];

        dispatch(setTransferDataModal(newProducts));

        form.setFieldValue(
          "products",
          newProducts.map(item => ({
            quantity: item.total_quantity,
            project_id: item.project.id,
            product_id: item.product.id,
            unit_id: item.unit.id
          }))
        );
      } else {
        message.warning("Ushbu element jadvalga qo'shilgan !");
      }
    } else {
      dispatch(setTransferDataModal([item]));

      form.setFieldValue("products", {
        quantity: item.total_quantity,
        project_id: item.project.id,
        product_id: item.product.id,
        unit_id: item.unit.id
      });
    }
  };

  const productItems: MenuProps["items"] =
    products?.map((item, index) => ({
      key: String(index),
      label: (
        <div className="resource">
          <div
            style={{
              borderColor: item?.product?.resource?.color,
              color: item?.product?.resource?.color
            }}
          >
            {item?.product.resource.symbol[i18n.language]}
          </div>
          <span>{item?.product.name[i18n.language]}</span>
        </div>
      ),
      onClick: () => onClickProductItem(item)
    })) ?? [];

  const footer = () =>
    id ? (
      <Dropdown overlayClassName={styles.dropdown} menu={{ items: productItems }} placement="top" trigger={["click"]}>
        <div className={styles.append_product}>
          <PlusCircleIcon fill />
          <span>Resurs qo'shish</span>
        </div>
      </Dropdown>
    ) : (
      <></>
    );

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: createTransfer.isLoading || updateTransfer.isLoading,
        disabled: data?.length === 0
      }}
      title="O'tkazma qilish"
      cancelText="Yopish"
      okText={id ? "Saqlash" : "O'tkazma qilish"}
      className={styles.transfer_modal}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Item name="id" className="d_n" />
        <Item name="sender_warehouse_id" className="d_n" />
        <div className={styles.top}>
          <Item name="recieved_warehouse_id" label="Omborxona" rules={formRules()}>
            <Select placeholder="Tanlang" suffixIcon={<SelectSuffixIcon />}>
              {warehouse?.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
          <Item name="recieved_project_id" label="Loyiha" rules={formRules()}>
            <Select placeholder="Tanlang" suffixIcon={<SelectSuffixIcon />}>
              {projects?.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className={styles.bottom}>
          <Table<WarehouseProductModel>
            className={id ? "footer_table" : styles.not_footer}
            columns={columns}
            pagination={false}
            dataSource={data}
            rowKey={(_, index) => index!}
            footer={footer}
            locale={{
              emptyText: <TableEmpty />
            }}
          />
          {!id && (
            <Item name="description" label="Izoh">
              <Input placeholder="Izoh" />
            </Item>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default TransferModal;
