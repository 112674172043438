import React from "react";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { useGetTableConfigs } from "../../../../app/service/queries";
import { tableConfigKeys } from "../../../../app/utils/constants/tableConfigKeys";
import { estimateTableDefaultData } from "../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../utils/helpers/estimateColumnsWidth";

import styles from "./staticEstimate.module.scss";

type Props = {
  expandedAll: boolean;
  setExpandedAll: (value: React.SetStateAction<boolean>) => void;
};

const StaticEstimateHeader: React.FC<Props> = ({ expandedAll, setExpandedAll }) => {
  const { t } = useTranslation();

  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);

  const customFieldHeader = () => {
    const filterConfigData = tableConfigData?.column?.filter(
      column =>
        // if (estimateType && estimateType !== EstimateSegmentedEnums.PLAN) {
        //   return typeof column?.id === "string" && column?.checked;
        // }
        column?.checked
    );

    return {
      data: filterConfigData,
      elements: filterConfigData?.map(column => <p key={column?.id}>{column?.title}</p>)
    };
  };

  const headerStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth({
      // @ts-ignore
      column: customFieldHeader().data,
      size: 10
    })} 16.375rem`
  };

  const onExpandedAllSections = () => {
    setExpandedAll(prev => !prev);
  };

  return (
    <div className={styles.plan_page__header} style={headerStyle}>
      <p className={styles.name} onClick={onExpandedAllSections}>
        <SelectSuffixIcon className={styles.selectIcon} placement={expandedAll ? "bottom" : "right"} />
        {t("project.Ishlar va resurslar")}
      </p>
      {customFieldHeader().elements}
      <p className={styles.last_column}>{t("project.Umumiy summa")}</p>
    </div>
  );
};

export default StaticEstimateHeader;
