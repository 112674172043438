import React, { useMemo, useState } from "react";
import { Form, FormInstance, Popover, Select, Spin } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import SelectNotContent from "features/app/components/select-not-content/SelectNotContent";
import { colors } from "features/app/utils/constants/colors";
import { selectFilterOption } from "features/app/utils/helpers/selectFilterOption";
import { sliceText } from "features/app/utils/helpers/sliceText";
import { TimeoutModel } from "features/app/utils/models/TimeoutModel";
import { useGetWarehouseProductsSelect } from "features/supply/service/queries";
import { ITransferringResourcesModel } from "features/warehouse/utils/models/transferringResourcesModel";
import { WarehouseProductModel } from "features/warehouse/utils/models/WarehouseProductModel";
import { useTranslation } from "react-i18next";

import styles from "./selectedProductsTable.module.scss";

const { Option } = Select;
const { useWatch } = Form;

type Props = {
  form: FormInstance;
  index: number;
  rowId: number | string;
};

type TargetType = EventTarget & {
  scrollTop: number;
  offsetHeight: number;
  scrollHeight: number;
};

const ProductSelect: React.FC<Props> = ({ form, index, rowId }) => {
  const { i18n, t } = useTranslation();

  const [searchSelect, setSearchSelect] = useState();
  const products = useWatch("products", form) as ITransferringResourcesModel[];
  const warehouseId = useWatch("sender_warehouse_id", form);
  const selectedProducts = useWatch("selected_products", form) as ITransferringResourcesModel[];
  const [time, setTime] = useState<TimeoutModel>();
  const { data, isLoading, fetchNextPage } = useGetWarehouseProductsSelect(warehouseId, searchSelect);
  const singleProduct = useWatch(["products", index], form);

  const onSearch = (e: string) => {
    clearTimeout(time);

    setTime(
      setTimeout(() => {
        setSearchSelect(e as never);
      }, 800)
    );
  };

  const productPlaceholder = (
    <div className={styles.product}>
      <div className="resource">
        <div
          style={{
            borderColor: colors.ORANGE_600,
            color: colors.ORANGE_600
          }}
        >
          ---
        </div>
      </div>
      <div className={styles.product__placeholder}>{t("Counterparts.Resurs nomi")}</div>
    </div>
  );

  const pagesData = useMemo((): WarehouseProductModel[] => {
    let newPagesData: WarehouseProductModel[] = [];

    data?.pages?.forEach(page => {
      let pageData: WarehouseProductModel[] = [...(page?.data ?? [])];

      if (selectedProducts && selectedProducts.length > 0 && singleProduct?.product_id) {
        const isProductInPage = pageData.some(
          el =>
            el?.product?.id === Number(String(singleProduct.product_id).split("-")[0]) &&
            el?.unit?.id === singleProduct.unit_id
        );

        if (!isProductInPage) {
          pageData = [
            {
              product: singleProduct.product,
              unit: singleProduct.unit,
              total_quantity: singleProduct.total_quantity
            },
            ...pageData
          ];
        }
      }

      if (!singleProduct?.product_id) {
        pageData = pageData.filter(
          el =>
            !products?.some(
              item =>
                Number(String(item?.product_id)?.split("-")[0]) === el?.product?.id && item?.unit?.id === el?.unit?.id
            )
        );
      }

      newPagesData = [...newPagesData, ...pageData];
    });

    return newPagesData;
  }, [products, data]);

  const onPopupScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.persist();
    const target = e.target as TargetType;

    if (Math.floor(target.scrollTop + target.offsetHeight) === target.scrollHeight) {
      fetchNextPage();
    }
  };

  const onChangeProduct = (e: number, option: DefaultOptionType) => {
    const { unit_id: unitId, total_quantity, unit } = option!.props;

    form.setFieldValue(
      "products",
      products?.map(item => {
        if (item?.rowId === rowId) {
          const foundOne = selectedProducts?.find(
            el => el?.product?.id === Number(String(e)?.split("-")[0]) && el?.unit?.id === unitId
          );

          if (foundOne) {
            return {
              ...item,
              product_id: e,
              unit_id: unitId,
              total_quantity: foundOne?.total_quantity,
              quantity: foundOne?.quantity,
              product: foundOne?.product,
              unit
            };
          }
          return { ...item, product_id: e, unit_id: unitId, total_quantity, quantity: undefined, unit };
        }

        return item;
      })
    );
  };

  const getValueOfSelect = useMemo(() => products?.find(el => el?.rowId === rowId)?.product_id, [products, rowId]);

  return (
    <>
      <Select
        value={getValueOfSelect}
        showSearch
        suffixIcon={null}
        disabled={typeof rowId === "number" || singleProduct?.product_id}
        onSearch={onSearch}
        onChange={onChangeProduct}
        popupMatchSelectWidth={false}
        className={styles.select}
        onPopupScroll={onPopupScroll}
        placeholder={productPlaceholder}
        filterOption={selectFilterOption}
        notFoundContent={
          <Spin spinning={isLoading && searchSelect} indicator={LoadingIndicator}>
            <SelectNotContent title={t("Counterparts.Mahsulot")} />
          </Spin>
        }
      >
        {pagesData?.map(item => (
          <Option
            key={`${item?.product?.id}-${item?.unit?.id}`}
            value={`${item?.product?.id}-${item?.unit?.id}`}
            props={{
              name: item?.product?.name[i18n.language],
              unit_id: item?.unit?.id,
              total_quantity: item?.total_quantity,
              unit: item?.unit
            }}
          >
            <div className="flex items-center justify-between">
              <div className={styles.product}>
                <div className="resource">
                  <div
                    style={{
                      borderColor: item?.product?.resource?.color ?? colors.ORANGE_600,
                      color: item?.product?.resource?.color ?? colors.ORANGE_600
                    }}
                  >
                    {item?.product?.resource?.symbol[i18n.language]}
                  </div>
                </div>
                <div className={styles.product__name}>
                  {item && item?.product && item?.product?.name?.[i18n.language]?.length > 18 ? (
                    <Popover title={item && item?.product && item?.product?.name[i18n.language]} zIndex={9999999}>
                      {sliceText(item?.product?.name[i18n.language], 18)}
                    </Popover>
                  ) : (
                    sliceText(item && item?.product ? item?.product?.name[i18n.language] : "", 18)
                  )}
                </div>
              </div>
              {!singleProduct?.product_id && (
                <span className="text-sm font-medium text-gray-400">{item?.unit?.symbol[i18n.language]}</span>
              )}
            </div>
          </Option>
        ))}
      </Select>
    </>
  );
};

export default ProductSelect;
