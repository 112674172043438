import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Form, Switch } from "antd";

import DndIcon from "../../../assets/icons/DndIcon";
import { ColumnConfigModel } from "../../../utils/models/user/userTableConfigModel";

import styles from "./columnConfig.module.scss";

const { Item } = Form;

const DndItem = ({ item, fieldName }: { item: ColumnConfigModel; fieldName: string }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: item.id
  });

  const style: React.CSSProperties = {
    cursor: "move",
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    transform: CSS.Transform.toString(transform)
  };

  return (
    <div style={style} {...listeners} {...attributes} ref={setNodeRef} className={styles.column}>
      <DndIcon />
      <Item className="d_n" name={["column", fieldName, item.name, "place"]} />
      <Item
        label={item.title}
        valuePropName="checked"
        className={styles.column__item}
        initialValue={item.checked ?? false}
        name={["column", fieldName, item.name, "check"]}
      >
        <Switch />
      </Item>
    </div>
  );
};

export default DndItem;
