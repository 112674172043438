import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys } from "../query-keys";
import { ProjectColumnTask } from "../schema";

type Query = {
  tasks: ProjectColumnTask[];
};

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
  sectionId: number;
}

export function useProjectColumnTasks({ id, enabled, sectionId }: Params) {
  const initialData = {
    tasks: []
  };

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_COLUMN_TASKS, sectionId, id],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-section/task-view?id=${id}&section_id=${sectionId}`);

      return { tasks: data };
    },
    {
      enabled: enabled && !!sectionId
    }
  );

  return { ...data, ...arg };
}
