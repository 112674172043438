import { RU } from "../../../../../../app/utils/constants/languages";
import { diffFactAmount } from "../../../../../utils/helpers/diffDynamicEstimate";
import { DynamicEstimateTaskModel } from "../../../../../utils/models/dynamicEstimateModel";
import DiffPopoverAmount from "../../diff-popover/DiffPopoverAmount";

import styles from "../task.module.scss";

type Props = {
  task: DynamicEstimateTaskModel;
  getPopoverContainer?: () => HTMLElement;
};

const SpendAmount = ({ task, getPopoverContainer }: Props) => {
  return (
    <>
      {diffFactAmount(task?.amount, task?.spend_total_amount / task?.quantity) !== 0 ? (
        <DiffPopoverAmount
          record={task}
          classNames={styles.amount}
          isFeature={false}
          getPopoverContainer={getPopoverContainer}
        />
      ) : (
        <p className={styles.amount}>{task?.amount?.toLocaleString(RU) ?? 0}</p>
      )}
    </>
  );
};

export default SpendAmount;
