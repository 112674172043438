import { Button, DatePicker, Dropdown, Form, Input, MenuProps, Modal, Popover } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../hooks/redux";
import { chatActions } from "../../../../store/reducers/chatReducer";
import CloseIcon from "../../../app/assets/icons/CloseIcon";
import DatepickerSuffixIcon from "../../../app/assets/icons/DatepickerSuffixIcon";
import UnreadMessageAction from "../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../app/components/unread-message/UnreadMessageDots";
import { dayjsFormats } from "../../../app/utils/constants/dayjsFormats";
import { ChatEnum } from "../../../app/utils/enums/chatEnum";
import { ImpostStatusEnums } from "../../../app/utils/enums/impostStatusEnums";
import { getFirstLetter } from "../../../app/utils/helpers/getFirstLetter";
import { localeFormatter } from "../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../app/utils/helpers/parseLocaledString";
import { useImpostWorkRecieved, useImpostWorkRejected } from "../../service/mutations";
import { impostQueryKeys } from "../../utils/constants/impostQueryKeys";
import { WorksInventoryImpostModel } from "../../utils/models/worksImpostModel";

import styles from "./confirmImpost.module.scss";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import ConfirmationUsersList from "features/app/components/confirmation-users-list/ConfirmationUsersList";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  record?: WorksInventoryImpostModel;
  isView?: boolean;
};

interface ReqDataWorkRecieved {
  amount: string;
  quantity: string;
  total: string;
  payment_date: string;
}

const { useForm, Item } = Form;

const ConfirmImpost = ({ open, setOpen, record, isView }: Props) => {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setVisible: setVisibleChat } = chatActions;
  const [form] = useForm<ReqDataWorkRecieved>();
  const isInventory = record?.inventory;
  const workRecievedConfirmation = useImpostWorkRecieved();
  const workRejectedConfirmation = useImpostWorkRejected();
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const onReject = () => {
    workRejectedConfirmation
      .mutateAsync({
        id: record?.id
      })
      .then(() => onCancel());
  };

  const onFinish = (values: ReqDataWorkRecieved) => {
    workRecievedConfirmation
      .mutateAsync({
        id: record?.id,
        amount: parseLocaledString(values?.amount),
        quantity: parseLocaledString(values?.quantity),
        payment_date: dayjs(values.payment_date).format(dayjsFormats.DATE)
      })
      .then(() => onCancel());
  };

  const footer = (
    <div className={styles.footer}>
      <Button onClick={onCancel}>{t("products.Yopish")}</Button>
      {record?.confirmation_payment === ImpostStatusEnums.PENDING && (
        <>
          <Button onClick={onReject} loading={workRejectedConfirmation.isLoading}>
            {t("Xodimlar.Bekor qilish")}
          </Button>
          <Button onClick={onOk} type="primary" loading={workRecievedConfirmation.isLoading}>
            {t("Monitoring.Tasdiqlash")}
          </Button>
        </>
      )}
    </div>
  );

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      form.resetFields();
    } else if (record) {
      form.setFieldsValue({
        quantity: localeFormatter(String(record?.quantity)),
        total: localeFormatter(String(record?.quantity * record?.amount)),
        amount: localeFormatter(String(record?.amount))
      });
    }
  };

  const onOpenChat = () => {
    dispatch(
      setVisibleChat({
        visible: true,
        type: ChatEnum.TASKS,
        objectId: record?.id,
        // dataKeys: [impostQueryKeys.WORKS]
        onAfterOpen: () => {
          if (record?.unread_message_count && record?.unread_message_count > 0) {
            qc.setQueryData(
              [impostQueryKeys.WORKS, severalSearchParams(queryParamsKeys.TAB)],
              ({ data, current_page, total }: any) => {
                const initialData = data as WorksInventoryImpostModel[];
                return {
                  total: total,
                  current_page: current_page,
                  data: initialData?.map(item => {
                    if (item?.id === record?.id) {
                      return {
                        ...item,
                        unread_message_count: 0
                      };
                    }
                    return item;
                  })
                };
              }
            );
          }
        }
      })
    );
    onCancel();
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div className={styles.dropdown__item}>
          <UnreadMessageAction count={record?.unread_message_count} />
        </div>
      ),
      onClick: onOpenChat
    }
  ];

  const title = (
    <div className={styles.title}>
      <p>{t("Monitoring.Ishlar uchun to’lovlar")}</p>
      <div className={styles.title__actions}>
        <Dropdown menu={{ items }} trigger={["contextMenu", "click"]} overlayClassName={styles.dropdown}>
          <Button>
            <UnreadMessageDots count={record?.unread_message_count} />
          </Button>
        </Dropdown>
        <div onClick={onCancel}>
          <CloseIcon />
        </div>
      </div>
    </div>
  );

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAmount = parseLocaledString(e.currentTarget.value);
    const newQuantity = parseLocaledString(String(form.getFieldValue("quantity")));

    form.setFieldsValue({
      total: localeFormatter(String(newAmount * newQuantity)),
      amount: localeFormatter(String(newAmount))
    });
  };

  const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseLocaledString(e.currentTarget.value);
    const newAmount = parseLocaledString(String(form.getFieldValue("amount")));

    form.setFieldsValue({
      total: localeFormatter(String(newQuantity * newAmount)),
      quantity: localeFormatter(String(newQuantity))
    });
  };

  const onChangeTotalAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTotalAmount = parseLocaledString(e.currentTarget.value);
    const quantity = form.getFieldValue("quantity");
    const newAmount = parseLocaledString(String(newTotalAmount / quantity));

    form.setFieldsValue({
      total: localeFormatter(String(newTotalAmount)),
      amount: localeFormatter(String(newAmount.toFixed(2)))
    });
  };

  return (
    <Modal
      centered
      footer={isView ?? footer}
      afterOpenChange={onAfterOpen}
      open={open}
      onCancel={onCancel}
      title={title}
      closeIcon={false}
      className={styles.confirm_impost}
      width={556}
    >
      <div className={styles.top}>
        <div className={styles.top__item}>
          <p>{t("Monitoring.Ish nomi")}</p>
          <p className={styles.top__name}>
            {record?.place}.{" "}
            {record?.task?.name && record?.task?.name?.length > 30 ? (
              <Popover title={record?.task?.name} overlayClassName={styles.popover}>
                {record?.task?.name.substring(0, 30)}...
              </Popover>
            ) : (
              record?.task?.name
            )}
          </p>
        </div>
        <div className={styles.top__item}>
          <p>{t("Monitoring.Ish bo’limi")}</p>
          <p>
            {record?.section?.place}. {record?.section?.name}
          </p>
        </div>
        <div className={styles.top__item}>
          <p>{t("MainLayoutLinks.Loyiha")}</p>
          <p>{record?.project?.name}</p>
        </div>
        <div className={styles.top__item}>
          <p>{t("Monitoring.Ish tuganlangan sana")}</p>
          <div className={styles.top__date}>
            <p className={styles.top__avatar}>
              <Popover title={record?.creator?.full_name} overlayClassName={styles.popover}>
                {record?.creator?.image ? (
                  <img src={record?.creator?.image} alt={record?.creator?.full_name} />
                ) : (
                  <p className={styles.top__creator_name}>
                    {record?.creator?.full_name && getFirstLetter(record?.creator?.full_name)}
                  </p>
                )}
              </Popover>
            </p>
            <p> {record?.completed_date}</p>
          </div>
        </div>
        <div className={styles.top__item}>
          <p>{t("MainLayoutLinks.Kontragent")}</p>
          <p>{record?.company_person?.name ?? "-"}</p>
        </div>
        {isInventory && (
          <div className={styles.top__item}>
            <p>{t("Monitoring.Inventar nomi")}</p>
            <p className={styles.top__name}>
              {record?.inventory?.name && record?.inventory?.name?.length > 30 ? (
                <Popover title={record?.inventory?.name} overlayClassName={styles.popover}>
                  {record?.inventory?.name.substring(0, 30)}...
                </Popover>
              ) : (
                record?.inventory?.name
              )}
            </p>
          </div>
        )}
        {isInventory && (
          <div className={styles.top__item}>
            <p>{t("Monitoring.Raqami")}</p>
            <p>{record?.inventory?.code}</p>
          </div>
        )}
        {record?.confirmation_users?.length! > 0 && (
          <div className={styles.top__item}>
            <p>{t("Monitoring.Guvohlar")}</p>
            <span className="mr-[4.5rem] cursor-pointer">
              <ConfirmationUsersList record={record as never} />
            </span>
          </div>
        )}
        <Form form={form} onFinish={onFinish} layout="vertical">
          <div className={styles.top__payment_date}>
            <p>{t("Monitoring.To'lash sanasi")}</p>
            <Item<ReqDataWorkRecieved> name="payment_date" initialValue={dayjs()}>
              <DatePicker
                disabled={isView}
                placeholder={t("Monitoring.Tanlanmagan")}
                format={dayjsFormats.DATE}
                suffixIcon={<DatepickerSuffixIcon />}
              />
            </Item>
          </div>
        </Form>
      </div>
      <Form form={form} onFinish={onFinish} layout="vertical" className={styles.form}>
        <div className={styles.form__block}>
          <Item<ReqDataWorkRecieved> name="quantity" label={t("Monitoring.Ish hajmi")} className={styles.form__amount}>
            <Input
              // disabled
              onChange={onChangeQuantity}
              suffix={record?.unit?.symbol[i18n.language]}
              disabled={isView}
            />
          </Item>
          <Item<ReqDataWorkRecieved> name="amount" label={t("Monitoring.Birlik summa")} className={styles.form__amount}>
            <Input
              onChange={onChangeAmount}
              suffix={record?.currency?.symbol}
              disabled={isView}
              // disabled
            />
          </Item>
        </div>
        <Item<ReqDataWorkRecieved> name="total" label={t("Monitoring.Umumiy summa")} className={styles.form__total}>
          <Input
            // disabled
            onChange={onChangeTotalAmount}
            suffix={record?.currency?.symbol}
            disabled={isView}
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default ConfirmImpost;
