import React from "react";
import { useSearchParams } from "react-router-dom";

import { useAppSelector } from "../../../../../hooks/redux";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { PaymentTabKeys } from "../../../utils/constants/paymentTabKeys";
import PaymentCalendar from "../calendar/PaymentCalendar";

import ExpectedPaymentTemplateTable from "./exected-payment-template/ExpectedPaymentTemplateTable";
import ExpectedPaymentTable from "./expected-payment/ExpectedPaymentTable";
import PaymentIncomeExpenseTable from "./income-expense/PaymentIncomeExpenseTable";
import InventoryTable from "./inventory/InventoryTable";
import OrderTable from "./order/OrderTable";
import WorkPaymentTable from "./work-payment/WorkPaymentTable";

import styles from "./paymentTables.module.scss";

const PaymentTables: React.FC = () => {
  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get(queryParamsKeys.STATISTICS_TYPE);
  const paymentType = searchParams.get(queryParamsKeys.PAYMENT_TYPE);
  const { firstStatisticsType } = useAppSelector(state => state.paymentReducer);

  const items = {
    [PaymentTabKeys.INCOME_EXPENSE]: <PaymentIncomeExpenseTable />,
    [PaymentTabKeys.PAYMENT]: paymentType === "template" ? <ExpectedPaymentTemplateTable /> : <ExpectedPaymentTable />,
    [PaymentTabKeys.ORDER]: <OrderTable />,
    [PaymentTabKeys.WORK]: <WorkPaymentTable />,
    [PaymentTabKeys.INVENTORY]: <InventoryTable />,
    [PaymentTabKeys.CALENDAR]: <PaymentCalendar />
  };

  return (
    <div className={`${styles.tables} `}>{items[(tabKey as keyof typeof items) ?? firstStatisticsType.first]}</div>
  );
};

export default PaymentTables;
