import React from "react";

const PlusIcon: React.FC<{ color?: string }> = ({ color }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99984 4.1665V15.8332M4.1665 9.99984H15.8332"
      stroke={color ?? "#344054"}
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
