import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { DatePicker, Form, FormInstance, Input, Select } from "antd";
import dayjs from "dayjs";

import { builderActions } from "../../../../../store/reducers/builderReducer";
import AddressModal from "../../../../app/components/address-modal/AddressModal";
import SelectNotContent from "../../../../app/components/select-not-content/SelectNotContent";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { IBuilders } from "../../../../app/utils/models/buildersSelectModel";
import ChevronDownIcon from "../../../../supply/assets/icons/ChevronDownIcon";
import AddressIcon from "../../../assets/icons/AddressIcon";
import RangePickerIcon from "../../../assets/icons/RangePickerIcon";
import { dateValidator } from "../../../utils/helpers/dateValidator";
import { DetailedProjectModel } from "../../../utils/models/detailedProjectModel";
import { ProjectFormFieldsModel } from "../../../utils/models/projectFormFieldsModel";

import styles from "../projectModal.module.scss";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { Option } = Select;

export type Props = {
  formInstance: FormInstance<ProjectFormFieldsModel>;
  data: DetailedProjectModel | undefined;
  builders: IBuilders[];
};

const ProjectParameters: React.FC<Props> = ({ formInstance, builders, data }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setBuilderModal } = builderActions;
  const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
  const [builderSelectSearchText, setBuilderSelectSearchText] = useState<string>();

  const onOpenAddressAddModal = () => {
    setAddressModalVisible(true);
  };

  // const { setDistrictId, setRegionId, setLatLongAddress } = addressReducerActions;

  const handleAreaChange = (e: ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue("area", localeFormatter(e.target.value));
  };

  const onSearch = (e: string) => {
    setBuilderSelectSearchText(e);
  };

  const generateBuilderData = useMemo(() => {
    if (builderSelectSearchText) {
      return builders?.filter(item => item.name.toLowerCase().includes(builderSelectSearchText.toLowerCase()));
    }
    return builders;
  }, [builders, builderSelectSearchText]);

  const onOpenBuilderModal = () => {
    dispatch(
      setBuilderModal({
        builderModal: {
          visible: true,
          name: builderSelectSearchText
        },
        name: builderSelectSearchText
      })
    );
  };

  useEffect(() => {
    if (data) {
      formInstance.setFieldsValue({
        dates: [
          data?.start_date ? dayjs(data?.start_date, dayjsFormats.DATE) : null,
          data?.due_date ? dayjs(data?.due_date, dayjsFormats.DATE) : null
        ],
        area: localeFormatter(String(data?.area)) as unknown as number,
        address: data?.address,
        builder_id: data?.builder_id,
        region_id: data?.region?.id,
        district_id: data?.district?.id,
        latitude: data?.latitude,
        longitude: data?.longitude
      });
    }
  }, [data, formInstance]);

  return (
    <div className={styles.settings}>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Quruvchi")}</span>
          <p>{t("project.Loyiha quruvchisini biriktiring")}</p>
          <p>{t("project.Tanlash...")}</p>
        </div>
        <Form.Item className="hidden" name="region_id" />
        <Form.Item className="hidden" name="district_id" />
        <Form.Item className="hidden" name="longitude" />
        <Form.Item className="hidden" name="latitude" />
        <Form.Item name="builder_id" className={styles.right}>
          <Select
            className={`${styles.select} ${styles.currency}`}
            suffixIcon={<ChevronDownIcon rotate={true} />}
            placeholder={t("project.Tanlash...")}
            onSearch={onSearch}
            searchValue={builderSelectSearchText}
            allowClear={true}
            notFoundContent={
              <SelectNotContent
                title={t("project.Quruvchi")}
                description={t("project.Quruvchi mavjud emas,iltimos yangi qo'shing!")}
                action={onOpenBuilderModal}
              />
            }
            showSearch
          >
            {generateBuilderData?.map(item => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Muddati")}</span>
          <p>{t("project.Loyiha boshlanish va tugash sanalari")}</p>
        </div>
        <Form.Item
          name="dates"
          className={styles.right}
          rules={[
            {
              required: true,
              validator: (__, value) => dateValidator(value)
            }
          ]}
        >
          <RangePicker
            separator="-"
            className={styles.date}
            suffixIcon={<RangePickerIcon />}
            placeholder={[t("project.Boshlanish"), t("project.Tugash")]}
            format={[dayjsFormats.DATE, dayjsFormats.DATE]}
          />
        </Form.Item>
      </div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Maydoni")}</span>
        </div>
        <Form.Item name="area" className={styles.right}>
          <Input
            className={styles.input}
            onChange={handleAreaChange}
            suffix="m²"
            placeholder={t("project.Kiritish...")}
          />
        </Form.Item>
      </div>
      <div className={styles.settings__select_cont}>
        <div className={styles.left}>
          <span>{t("project.Manzili")}</span>
        </div>
        <AddressModal
          visible={addressModalVisible}
          setVisibleModal={setAddressModalVisible}
          formInstance={formInstance}
        >
          <div onClick={onOpenAddressAddModal}>
            <Form.Item name="address" className={styles.right}>
              <Input className={styles.input} suffix={<AddressIcon />} placeholder={t("project.Kiritish...")} />
            </Form.Item>
          </div>
        </AddressModal>
      </div>
    </div>
  );
};

export default ProjectParameters;
