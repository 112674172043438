import React from "react";

interface IProps extends React.SVGProps<SVGSVGElement> {}

const InfoHexagonIcon = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clip-path="url(#clip0_9465_60536)">
        <path
          d="M8 5.33295L8 7.99962M8 10.6663L7.99333 10.6663M14 10.7053L14 5.29392C14 5.06549 14 4.95127 13.9663 4.84941C13.9366 4.75929 13.8879 4.67657 13.8236 4.60677C13.7509 4.52788 13.651 4.47242 13.4514 4.36148L8.51802 1.62074C8.32895 1.5157 8.23441 1.46318 8.1343 1.44259C8.04569 1.42437 7.95431 1.42437 7.8657 1.44259C7.76559 1.46318 7.67105 1.5157 7.48198 1.62074L2.54865 4.36148C2.34896 4.47241 2.24912 4.52788 2.17642 4.60677C2.1121 4.67657 2.06343 4.75929 2.03365 4.84941C2 4.95127 2 5.06549 2 5.29391L2 10.7053C2 10.9337 2 11.048 2.03365 11.1498C2.06343 11.2399 2.1121 11.3227 2.17642 11.3925C2.24912 11.4714 2.34896 11.5268 2.54864 11.6378L7.48198 14.3785C7.67105 14.4835 7.76558 14.5361 7.8657 14.5566C7.9543 14.5749 8.04569 14.5749 8.1343 14.5566C8.23441 14.5361 8.32895 14.4835 8.51802 14.3785L13.4514 11.6378C13.651 11.5268 13.7509 11.4714 13.8236 11.3925C13.8879 11.3227 13.9366 11.2399 13.9663 11.1498C14 11.048 14 10.9338 14 10.7053Z"
          stroke="#98A2B3"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_9465_60536">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(16 16) rotate(-180)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InfoHexagonIcon;
