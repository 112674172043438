import { parseLocaledString } from "../../../app/utils/helpers/parseLocaledString";
import { PartyFormProductsModel } from "../models/partyModalFormModel";

export const generateTotalAmount = (data: PartyFormProductsModel[]) =>
  data?.reduce((first: Record<string, number>, second) => {
    const keys = Object.keys(first);
    const isSomeKey = keys?.some(key => key === String(second.currency_id));

    const newTotalAmount =
      parseLocaledString(second?.amount ? second?.amount : "0") *
      parseLocaledString(second?.quantity ? String(second?.quantity) : "0");

    return {
      ...first,
      [String(second.currency_id)]: (isSomeKey ? first[String(second.currency_id)] : 0) + newTotalAmount
    };
  }, {});
