import React from "react";

import { FinancialModalPropsModel } from "../../settings/utils/models/financialModel";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";

export enum paymentSettingTabs {
  income_or_expense,
  income,
  expense,
  order,
  work,
  inventory
}

export interface InfoModalDataModel {
  visible: boolean;
  id?: number;
  creatorId?: number;
  type?: PaymentTypeEnum;
}

export interface PaymentContextDataState {
  settingTabs?: {
    income_or_expense?: boolean;
    income?: boolean;
    expense?: boolean;
    order?: boolean;
    work?: boolean;
    inventory?: boolean;
  };
  infoModalData?: InfoModalDataModel;
  financialModal?: FinancialModalPropsModel;
}

export interface PaymentContextData extends PaymentContextDataState {
  setContext: (value: PaymentContextDataState) => void;
}

export const PaymentContext = React.createContext<PaymentContextData>({
  settingTabs: {},
  infoModalData: { visible: false },
  setContext: () => {}
});
