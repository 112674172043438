import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";

import { dashboardQueryKeys } from "../query-keys";
import { CompanyWidget } from "../schema";

interface Query {
  companyWidgets: CompanyWidget[];
}

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {}

export function useCompanyWidgets({ enabled = true }: Params) {
  const initialValue: Query = {
    companyWidgets: []
  };

  const { data = initialValue, ...arg } = useQuery(
    [dashboardQueryKeys.COMPANY_WIDGETS],
    async () => {
      const { data } = await $api.get("company-widget/view");

      return { companyWidgets: data?.data };
    },
    { enabled }
  );

  return { ...data, ...arg };
}
