import { Input } from "antd";

import { CustomFieldProps } from "../../utils/helpers/customFields";

const TextCustomField = ({
  placeholder = "Kiriting",
  disabled,
  onChange,
  hasValue,
  value,
}: CustomFieldProps) => {
  return (
    <Input
      placeholder={placeholder}
      disabled={disabled}
      onChange={(e) => {
        if (onChange) {
          onChange(e.currentTarget.value);
        }
      }}
      {...((hasValue || value) && { value: value })}
    />
  );
};

export default TextCustomField;
