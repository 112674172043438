import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { store } from "store";

import packageJson from "../../package.json";

interface Params extends Pick<Sentry.BrowserOptions, "dsn" | "tracePropagationTargets"> {}

export function init(params: Params) {
  Sentry.init({
    ...params,
    release: `uyqur@${packageJson.version}`,
    allowUrls: ["https://app.uyqur.uz", "http://app.uyqur.uz"],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
      Sentry.contextLinesIntegration(),
      Sentry.extraErrorDataIntegration()
    ],
    denyUrls: [/node_modules/],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ["Non-Error promise rejection captured", "Object captured as promise rejection with keys"],
    beforeSend(event) {
      if (window.location.hostname === "localhost") {
        return null;
      }

      event.tags = { ...store.getState()?.auth.user };

      return event;
    }
  });
}

// Sentry.init({
//   release: `uyqur@${packageJson.version}`,
//   allowUrls: ["https://app.uyqur.uz", "http://app.uyqur.uz"],
//   dsn: "dsn URL",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes
//     }),
//     Sentry.replayIntegration(),
//     Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
//     Sentry.contextLinesIntegration(),
//     Sentry.extraErrorDataIntegration()
//   ],
//   denyUrls: [/node_modules/],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],./
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   ignoreErrors: ["Non-Error promise rejection captured", "Object captured as promise rejection with keys"]
// });
