import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
  useGetCompanyPersons,
  useGetSectionsSelect,
  useGetUsersSelect
} from "../../../../../../../../../app/service/queries";
import { TaskStatus } from "../../utils/models/TaskStatus";
import ZapIcon from "../../../../../../../../assets/icons/ZapIcon";
import UserIcon from "../../../../../../../../assets/icons/UserIcon";
import { useQueryParams } from "../../../../../../../../../../hooks/useQueryParams";
import { dayjsFormats } from "../../../../../../../../../app/utils/constants/dayjsFormats";
import CounterpartsIcon from "../../../../../../../../../app/assets/icons/CounterpartsIcon";
import FilterLinesIcon from "../../../../../../../../../warehouse/assets/icons/FilterLinesIcon";
import FilterDrawer from "../../../../../../../../../app/components/filter-drawer/FilterDrawer";
import { queryParamsKeys } from "../../../../../../../../../app/utils/constants/queryParamsKeys";
import FiltersCount from "../../../../../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import FilterSelect from "../../../../../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FilterDateRange from "../../../../../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";

import styles from "./filterDrawer.module.scss";

const queryStringArr = [
  queryParamsKeys.MAX_DUE_DATE,
  queryParamsKeys.MIN_DUE_DATE,
  queryParamsKeys.MAX_START_DATE,
  queryParamsKeys.MIN_START_DATE,
  queryParamsKeys.SECTION_IDS,
  queryParamsKeys.TASK_STATUSES,
  queryParamsKeys.USER_IDS,
  queryParamsKeys.COMPANY_PERSON_IDS
];

const FilterDrawerGantt = () => {
  const { queries } = useQueryParams();
  const { projectId } = useParams();
  const [open, setOpen] = useState(false);
  const [sectionsSelected, setSectionsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.SECTION_IDS] as string[] | null
  );
  const [tasksSelected, setTasksSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.TASK_STATUSES] as string[] | null
  );
  const [usersSelected, setUsersSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.USER_IDS] as string[] | null
  );
  const [companyPersonsSelected, setCompanyPersonsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.COMPANY_PERSON_IDS] as string[] | null
  );
  const [startDateValue, setStartDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_START_DATE]
      ? dayjs(queries()[queryParamsKeys.MIN_START_DATE], dayjsFormats.DATE)
      : null,
    queries()[queryParamsKeys.MAX_START_DATE]
      ? dayjs(queries()[queryParamsKeys.MAX_START_DATE], dayjsFormats.DATE)
      : null
  ]);
  const [dueDateValue, setDueDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DUE_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DUE_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DUE_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DUE_DATE], dayjsFormats.DATE) : null
  ]);
  const { data: usersSelect } = useGetUsersSelect(true);
  const { data: companyPersonsSelect } = useGetCompanyPersons(true);
  const { data: sectionsSelect } = useGetSectionsSelect(true, projectId);

  const usersData = useMemo(() => {
    return usersSelect?.map(item => {
      return { name: item?.full_name, ...item };
    });
  }, [usersSelect]);

  const companyPersonsData = useMemo(() => {
    return companyPersonsSelect?.map(item => {
      return { ...item };
    });
  }, [companyPersonsSelect]);

  const taskStatusData = useMemo(() => {
    return [
      {
        id: TaskStatus.OPEN,
        name: "Ochiq"
      },
      {
        id: TaskStatus.PROCESS,
        name: "Bajarilmoqda"
      },
      {
        id: TaskStatus.COMPLETED,
        name: "Bajarildi"
      }
    ];
  }, []);

  const onOpenFilter = () => {
    setOpen(true);
  };

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      height="max-content"
      queryStringArr={queryStringArr}
      filterButton={
        <Button className={styles.filter} onClick={onOpenFilter}>
          <FilterLinesIcon /> Filter
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.COMPANY_PERSON_IDS,
          selectedOptions: companyPersonsSelected
        },
        {
          queryKey: queryParamsKeys.USER_IDS,
          selectedOptions: usersSelected
        },
        {
          queryKey: queryParamsKeys.TASK_STATUSES,
          selectedOptions: tasksSelected
        },
        {
          queryKey: queryParamsKeys.SECTION_IDS,
          selectedOptions: sectionsSelected
        }
      ]}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_START_DATE,
          selectedValue: startDateValue && startDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_START_DATE,
          selectedValue: startDateValue && startDateValue[1]
        },
        {
          queryKey: queryParamsKeys.MIN_DUE_DATE,
          selectedValue: dueDateValue && dueDateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DUE_DATE,
          selectedValue: dueDateValue && dueDateValue[1]
        }
      ]}
      setDateValue={[setStartDateValue, setDueDateValue]}
      setSelectedOptionsArr={
        [setUsersSelected, setCompanyPersonsSelected, setSectionsSelected, setTasksSelected] as never
      }
    >
      <div className={styles.content}>
        <FilterSelect
          selectedOptions={sectionsSelected}
          setSelectedOptions={setSectionsSelected}
          data={sectionsSelect}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder="Bo'limlar"
          showSearch
        />
        <FilterSelect
          selectedOptions={usersSelected}
          setSelectedOptions={setUsersSelected}
          data={usersData}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder="Xodim"
          showSearch
        />
        <FilterSelect
          selectedOptions={companyPersonsSelected}
          setSelectedOptions={setCompanyPersonsSelected}
          data={companyPersonsData}
          multiple="multiple"
          icon={<CounterpartsIcon />}
          placeholder="Kontragent"
          showSearch
        />
        <FilterSelect
          selectedOptions={tasksSelected}
          setSelectedOptions={setTasksSelected}
          data={taskStatusData}
          multiple="multiple"
          icon={<ZapIcon />}
          placeholder="Status"
          showSearch
        />
        <FilterDateRange value={startDateValue} setValue={setStartDateValue} />
        <FilterDateRange value={dueDateValue} setValue={setDueDateValue} />
      </div>
    </FilterDrawer>
  );
};

export default FilterDrawerGantt;
