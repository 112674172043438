import React, { useState } from "react";
import { FormListOperation, Popover, Spin, Tree, TreeProps } from "antd";
import { useTranslation } from "react-i18next";

import PlusCircleIcon from "../../../../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../../../../app/assets/icons/SelectSuffixIcon";
import { LoadingIndicator } from "../../../../../../../app/components/loading-indicator/LoadingIndicator";
import { useProjectSelect } from "../../../../../../../app/service/queries";

import styles from "./projects.module.scss";

type Props = {
  operation: FormListOperation;
};

const Select: React.FC<Props> = ({ operation }) => {
  const { t } = useTranslation();
  const { data: projects, isLoading: isLoadingProjects } = useProjectSelect({});

  const [open, setOpen] = useState(false);

  const onAddProject: TreeProps["onSelect"] = (_, { node }) => {
    const record = JSON.parse(node.key as string);

    operation.add({
      id: record?.id,
      name: record?.name,
      amount: 0
    });

    setOpen(false);
  };

  const content = (
    <div className="flex max-h-80 w-72 overflow-y-auto">
      <Spin spinning={isLoadingProjects} indicator={LoadingIndicator}>
        <Tree
          multiple={false}
          checkable={false}
          onSelect={onAddProject}
          rootClassName={styles.project_select}
          treeData={projects?.map(project => ({
            title: project?.name,
            key: JSON.stringify(project),
            children: project?.projects?.map(child => ({
              title: child?.name,
              key: JSON.stringify(child)
            }))
          }))}
          switcherIcon={({ expanded }: any) => <SelectSuffixIcon placement={expanded ? "bottom" : "right"} />}
        />
      </Spin>
    </div>
  );

  const onOpenChange = (open: boolean) => {
    setOpen(open);
  };

  return (
    <Popover
      open={open}
      arrow={false}
      content={content}
      placement="bottom"
      trigger={["click"]}
      onOpenChange={onOpenChange}
    >
      <div className="flex w-max cursor-pointer items-center gap-2 rounded-lg px-2 py-1 text-sm font-medium text-blue-500 duration-300 ease-in-out hover:bg-primary-50">
        <PlusCircleIcon fill />
        {t("payment.Loyiha biriktirish")}
      </div>
    </Popover>
  );
};

export default Select;
