import { useTranslation } from "react-i18next";

import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";
import { RU } from "features/app/utils/constants/languages";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const ConfirmationWorkRecieved = ({ record, onNavigatePage }: Props) => {
  const { i18n } = useTranslation();
  return (
    <div
      onClick={() => onNavigatePage("/payment?statistics_type=work", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record?.type]}</h3>
      <p>
        {record?.confirmation_work?.creator?.full_name}{" "}
        {record?.confirmation_work?.amount?.toLocaleString(RU)}{" "}
        {record?.confirmation_work?.currency?.name[i18n.language]} bajarilgan ishni
        tasdiqladi.
      </p>
    </div>
  );
};

export default ConfirmationWorkRecieved;
