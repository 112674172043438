import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { UseMutationResult } from "@tanstack/react-query";
import { Button, Dropdown } from "antd";
import { ItemType } from "antd/es/menu/interface";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAppSelector } from "../../../../hooks/redux";
import { rootPaths } from "../../../../routes/root/rootPaths";
import { appReducerActions } from "../../../../store/reducers/appReducer";
import XCloseIcon from "../../../warehouse/assets/icons/XCloseIcon";
import { useGetWarehouseSelect } from "../../../warehouse/service/query";
import EditIcon from "../../assets/icons/EditIcon";
import {
  useGetCustomFieldSelect,
  useGetProjectsSelect,
  useGetUnitSelect,
  useGetUsersSelect
} from "../../service/queries";
import {
  generalEditArgKeys,
  generalEditData,
  generalEditFunc,
  generalEditingReqKeys,
  generalEditKeys,
  generateReqData
} from "../../utils/constants/generalEditData";
import { isEmptyArr } from "../../utils/helpers/isEmptyArr";
import ConditionalRender from "../conditional-render/ConditionalRender";

import GeneralEditingExtraContent from "./contents/GeneralEditingExtraContent";
import GeneralEditingDate from "./items/GeneralEditingDate";
import GeneralEditingInput from "./items/GeneralEditingInput";
import GeneralEditingSelect from "./items/GeneralEditingSelect";
import GeneralEditingCustomFieldPopconfirm from "./GeneralEditingCustomFieldPopconfirm";
import GeneralEditingPopconfirm from "./GeneralEditingPopconfirm";

import styles from "./generalEditing.module.scss";

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatchFunc: ActionCreatorWithPayload<any>;
  mutation: UseMutationResult<unknown, unknown, Record<string, unknown[]>, unknown>;
};

const GeneralEditing: React.FC<Props> = ({ mutation, dispatchFunc }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { pathname, search } = useLocation();
  const { data: units } = useGetUnitSelect();
  const { setGeneralEdit } = appReducerActions;
  const [data, setData] = useState<unknown[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const { data: agents } = useGetUsersSelect(true);
  const { data: projects } = useGetProjectsSelect();
  const { data: warehouses } = useGetWarehouseSelect(true);
  const { generalEdit } = useAppSelector(state => state.appReducer);
  const { key: editKey, customFieldKeys, isOpenChild } = generalEdit;
  const { data: customFields } = useGetCustomFieldSelect(customFieldKeys);
  const { data: selectOffers } = useAppSelector(state => state?.supplyOfferReducer?.selectOffers);
  const { selectParties, selectOrderProducts } = useAppSelector(state => state.supplyReducer);
  const { selectIncomeExpense, selectOrders } = useAppSelector(state => state.paymentReducer);

  const viewMode = useMemo(() => {
    const allData = {
      [generalEditKeys.ORDERS]: selectParties,
      [generalEditKeys.WAREHOUSE_PRODUCTS]: selectOffers,
      [generalEditKeys.ORDER_PRODUCTS]: selectOrderProducts,
      [generalEditKeys.PAYMENT]: selectIncomeExpense,
      [generalEditKeys.PAYMENT_ORDER]: selectOrders,
      [generalEditKeys.EMPTY]: []
    };

    const activeData = allData[editKey as keyof typeof allData];
    const generateData = generalEditFunc[editKey as keyof typeof generalEditFunc](activeData, customFields || []);

    setData(generateData);

    return {
      view: isEmptyArr(activeData),
      length: activeData?.length,
      copyData: generateData
    };
  }, [editKey, selectOffers, selectOrders, customFields, selectParties, selectOrderProducts, selectIncomeExpense]);

  const popconfirmContent = useMemo(
    () => ({
      [generalEditArgKeys.UNIT]: (
        <GeneralEditingSelect
          argKey={generalEditArgKeys.UNIT}
          setReturnData={setData}
          data={units?.map(item => ({
            id: item?.id,
            name: item?.name[i18n.language]
          }))}
        />
      ),
      [generalEditArgKeys.WAREHOUSE]: (
        <GeneralEditingSelect
          argKey={generalEditArgKeys.WAREHOUSE}
          setReturnData={setData}
          data={warehouses?.map(item => ({ id: item?.id, name: item?.name }))}
        />
      ),
      [generalEditArgKeys.PROJECT]: (
        <GeneralEditingSelect
          argKey={generalEditArgKeys.PROJECT}
          setReturnData={setData}
          data={projects?.map(item => ({ id: item?.id, name: item?.name }))}
        />
      ),
      [generalEditArgKeys.QUANTITY]: (
        <GeneralEditingInput argKey={generalEditArgKeys.QUANTITY} setReturnData={setData} type="amount" />
      ),
      [generalEditArgKeys.AGENT]: (
        <GeneralEditingSelect
          setReturnData={setData}
          argKey={generalEditArgKeys.AGENT}
          data={agents?.map(item => ({ id: item?.id, name: item?.full_name }))}
        />
      ),
      [generalEditArgKeys.DELIVERY_DATE]: (
        <GeneralEditingDate argKey={generalEditArgKeys.DELIVERY_DATE} setReturnData={setData} />
      ),
      [generalEditArgKeys.PAYMENT_DATE]: (
        <GeneralEditingDate argKey={generalEditArgKeys.PAYMENT_DATE} setReturnData={setData} />
      ),
      [generalEditArgKeys.DATE]: <GeneralEditingDate argKey={generalEditArgKeys.DATE} setReturnData={setData} />,
      [generalEditArgKeys.DESCRIPTION]: (
        <GeneralEditingInput argKey={generalEditArgKeys.DESCRIPTION} setReturnData={setData} type="text" />
      )
    }),
    [i18n.language, projects, units, warehouses, agents]
  );

  const onSave = useCallback(() => {
    const reqKey = generalEditingReqKeys[editKey];

    return mutation.mutateAsync({
      [reqKey]: generateReqData({
        data,
        copyData: viewMode.copyData,
        requiredKeys: ["id", "status", "project_id", "warehouse_id"]
      })
    });
  }, [data, editKey, mutation, viewMode.copyData]);

  const items = useMemo(
    (): ItemType[] => [
      ...(generalEditData[editKey as keyof typeof generalEditData]?.map(item => ({
        ...item,
        label: (
          <GeneralEditingPopconfirm
            onOk={onSave}
            title={item?.label}
            editKey={item?.editKey}
            setOpenParent={setOpen}
            content={popconfirmContent[item.editKey as keyof typeof popconfirmContent]}
          />
        )
      })) || []),
      {
        key: uid(),
        type: "group",
        label: "O'zgaruvchilar",
        children: []
      },
      ...(customFields?.map(item => ({
        key: item?.id,
        label: (
          <GeneralEditingCustomFieldPopconfirm item={item} onOk={onSave} setData={setData} setOpenParent={setOpen} />
        )
      })) || [])
    ],
    [editKey, onSave, popconfirmContent, customFields]
  );

  const onClear = () => {
    dispatch(dispatchFunc([]));
  };

  useEffect(() => {
    if (pathname?.includes(rootPaths.SUPPLY) && !search) {
      dispatch(
        setGeneralEdit({
          key: generalEditKeys.ORDER_PRODUCTS,
          customFieldKeys: []
        })
      );
    }
  }, [pathname, search, dispatch, setGeneralEdit]);

  return (
    <div
      className={classNames(styles.general_editing, {
        [styles.general_editing__disabled]: viewMode?.view
      })}
    >
      <ConditionalRender if={!viewMode?.view}>
        <div className={styles.left}>
          <div className={classNames("flex cursor-pointer items-center", styles.clear)} onClick={onClear}>
            <XCloseIcon />
          </div>
          <span>Belgilandi ({viewMode?.length})</span>
        </div>
        <div className={styles.right}>
          <div className={styles.buttons}>
            <GeneralEditingExtraContent />
          </div>
          <Dropdown
            open={open}
            menu={{ items }}
            trigger={["click"]}
            onOpenChange={(openValue, { source }) => {
              if (source === "trigger") {
                setOpen(openValue);
              } else {
                setOpen(true);
              }
            }}
          >
            <Button className={styles.edit} type="primary">
              <EditIcon />
              <span>Tahrirlash</span>
            </Button>
          </Dropdown>
        </div>
      </ConditionalRender>
    </div>
  );
};

export default GeneralEditing;
