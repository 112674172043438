import { Button, Modal, Popover } from "antd";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import { RU } from "../../../../app/utils/constants/languages";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { getFirstLetter } from "../../../../app/utils/helpers/getFirstLetter";
import { PaymentInventoryWorkModel } from "../../../utils/models/paymentInventoryModel";
import { useRoutePermissions } from "hooks/useRoutePermissions";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";

import styles from "./inventoryWorkPaymentView.module.scss";

type Props = {
  open: boolean;
  isInventory?: boolean;
  setOpen: (open: boolean) => void;
  record?: PaymentInventoryWorkModel;
};

const InventoryWorkPaymentView = ({ open, setOpen, record }: Props) => {
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const paymentActions = actions("Kirim-chiqim");
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const { setPaymentInventoryWorkModal } = paymentReducerActions;

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = () => {
    dispatch(
      setPaymentInventoryWorkModal({
        id: record?.id,
        visible: true,
        type: PaymentTypeEnum.EXPENSE,
        debtAmount: record?.debt_amount,
        defaultCurrency: record?.currency,
        personId: record?.company_person?.id,
        amount: record && record?.quantity * record?.amount
      })
    );
    onCancel();
  };

  const footer = () => (
    <div className={styles.footer}>
      <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
      {!(record?.debt_amount === 0) && paymentActions?.create && (
        <Button type="primary" onClick={onOk}>
          {t("payment.To’lov qilish")}
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      centered
      open={open}
      width={556}
      footer={footer()}
      onCancel={onCancel}
      title={t("payment.Ishlar uchun to’lovlar")}
      className={styles.work_payment_view}
    >
      <div className={styles.top}>
        <div className={styles.top__item}>
          <p>{t("payment.Ish nomi")}</p>
          <p className={styles.top__name}>
            {record?.place}.{" "}
            {record?.task?.name && record?.task?.name?.length > 30 ? (
              <Popover title={record?.task?.name} overlayClassName={styles.popover}>
                {record?.task?.name.substring(0, 30)}...
              </Popover>
            ) : (
              record?.task?.name
            )}
          </p>
        </div>
        <div className={styles.top__item}>
          <p>{t("payment.Ish bo’limi")}</p>
          <p>
            {record?.section?.place}. {record?.section?.name}
          </p>
        </div>
        <div className={styles.top__item}>
          <p>{t("payment.Loyiha")}</p>
          <p>{record?.project?.name}</p>
        </div>
        <div className={styles.top__item}>
          <p>{t("payment.Ish tugallangan sana")}</p>
          <div className={styles.top__date}>
            <p className={styles.top__avatar}>
              <Popover title={record?.creator?.full_name} overlayClassName={styles.popover}>
                {record?.creator?.image ? (
                  <img src={record?.creator?.image} />
                ) : (
                  <p className={styles.top__creator_name}>
                    {record?.creator?.full_name && getFirstLetter(record?.creator?.full_name)}
                  </p>
                )}
              </Popover>
            </p>
            <p> {record?.completed_date}</p>
          </div>
        </div>
        <div className={styles.top__item}>
          <p>{t("payment.Kontragent")}</p>
          <p>{record?.company_person?.name}</p>
        </div>
        {record?.inventory && (
          <div className={styles.top__item}>
            <p>{t("payment.Inventar nomi")}</p>
            <p className={styles.top__name}>
              {record?.inventory?.name && record?.inventory?.name?.length > 30 ? (
                <Popover title={record?.inventory?.name} overlayClassName={styles.popover}>
                  {record?.inventory?.name.substring(0, 30)}...
                </Popover>
              ) : (
                record?.inventory?.name
              )}
            </p>
          </div>
        )}
        {record?.inventory && (
          <div className={styles.top__item}>
            <p>{t("payment.Raqami")}</p>
            <p>{record?.inventory?.code}</p>
          </div>
        )}
        <div className={styles.top__item}>
          <p>{t("payment.Ish hajmi")}</p>
          <p>
            {record?.quantity.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
          </p>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottom__item}>
          <p>{t("payment.To’landi")}</p>
          <p>
            {(record && record?.amount * record?.quantity - record?.debt_amount)?.toLocaleString(RU)}{" "}
            {record?.currency?.symbol}
          </p>
        </div>
        <div className={styles.bottom__item}>
          <p>{t("payment.Qoldiq to’lov")}</p>
          <p>
            {record?.debt_amount?.toLocaleString(RU)} {record?.currency?.symbol}
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default InventoryWorkPaymentView;
