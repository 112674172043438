import React from "react";

interface IProps {
  placement?: "top" | "bottom" | "left" | "right";
}

const CaretCircleIcon = ({ placement = "top" }: IProps) => {
  const placementRotate = {
    top: "",
    bottom: "rotate(180deg)",
    left: "rotate(-90deg)",
    right: "rotate(90deg)",
  };

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: placementRotate[placement] }}
    >
      <path
        d="M10 18.125C8.39303 18.125 6.82214 17.6485 5.486 16.7557C4.14985 15.8629 3.10844 14.594 2.49348 13.1093C1.87852 11.6247 1.71762 9.99099 2.03112 8.41489C2.34463 6.83879 3.11846 5.39106 4.25476 4.25476C5.39106 3.11846 6.8388 2.34463 8.4149 2.03112C9.99099 1.71762 11.6247 1.87852 13.1093 2.49348C14.594 3.10844 15.8629 4.14984 16.7557 5.48599C17.6485 6.82214 18.125 8.39303 18.125 10C18.1227 12.1542 17.266 14.2195 15.7427 15.7427C14.2195 17.266 12.1542 18.1227 10 18.125ZM10 3.125C8.64026 3.125 7.31105 3.52821 6.18046 4.28365C5.04987 5.03908 4.16868 6.11281 3.64833 7.36905C3.12798 8.62529 2.99183 10.0076 3.2571 11.3412C3.52238 12.6749 4.17716 13.8999 5.13864 14.8614C6.10013 15.8228 7.32514 16.4776 8.65876 16.7429C9.99238 17.0082 11.3747 16.872 12.631 16.3517C13.8872 15.8313 14.9609 14.9501 15.7164 13.8195C16.4718 12.689 16.875 11.3597 16.875 10C16.8729 8.17727 16.1479 6.42979 14.8591 5.14092C13.5702 3.85206 11.8227 3.12707 10 3.125ZM13.5672 11.6922C13.6253 11.6341 13.6714 11.5652 13.7029 11.4893C13.7343 11.4135 13.7505 11.3321 13.7505 11.25C13.7505 11.1679 13.7343 11.0865 13.7029 11.0107C13.6714 10.9348 13.6253 10.8659 13.5672 10.8078L10.4422 7.68281C10.3841 7.6247 10.3152 7.5786 10.2393 7.54715C10.1635 7.5157 10.0821 7.49951 10 7.49951C9.91787 7.49951 9.83654 7.5157 9.76067 7.54715C9.68479 7.5786 9.61586 7.6247 9.55782 7.68281L6.43282 10.8078C6.31554 10.9251 6.24966 11.0841 6.24966 11.25C6.24966 11.4159 6.31554 11.5749 6.43282 11.6922C6.55009 11.8095 6.70915 11.8753 6.875 11.8753C7.04086 11.8753 7.19992 11.8095 7.31719 11.6922L10 9.00859L12.6828 11.6922C12.7409 11.7503 12.8098 11.7964 12.8857 11.8278C12.9615 11.8593 13.0429 11.8755 13.125 11.8755C13.2071 11.8755 13.2885 11.8593 13.3643 11.8278C13.4402 11.7964 13.5091 11.7503 13.5672 11.6922Z"
        fill="#1E90FF"
      />
    </svg>
  );
};

export default CaretCircleIcon;
