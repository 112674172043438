import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import $api from "features/app/utils/helpers/axiosInstance";
import { dashboardQueryKeys, ProjectByResourceViewDataTabCount } from "modules/dashboard";
import { useQueryParams } from "hooks/useQueryParams";

interface Query {
  countData: ProjectByResourceViewDataTabCount;
}

interface Params extends Pick<UseQueryOptions, "enabled"> {
  id?: number;
  project_id?: number;
}

export function useGetProjectByResourceDatTabCount({ enabled = false, id, project_id }: Params) {
  const initialValue: Query = { countData: { product: 0, another: 0, mechanism: 0, person: 0 } };

  const { generateSearchParam } = useQueryParams();
  const searchParams = generateSearchParam({
    id,
    project_id
  } as never);

  const { data = initialValue, ...args } = useQuery<Query>(
    [dashboardQueryKeys.PROJECT_BY_RESOURCE_DATA_TAB_COUNT, searchParams],
    async () => {
      const {
        data: { data }
      } = await $api.get(`company-widget/project-by-resource/all-count?${searchParams}`);

      return { countData: data };
    },
    { enabled }
  );

  return { ...data, ...args };
}
