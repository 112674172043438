import React from "react";

const NotFoundSelectIcon: React.FC = () => {
  return (
    <svg width="74" height="61" viewBox="0 0 74 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.9996 60.25C53.7956 60.25 67.3996 46.825 67.3996 30.25C67.3996 13.675 53.7956 0.25 36.9996 0.25C20.2036 0.25 6.59961 13.675 6.59961 30.25C6.59961 46.825 20.2036 60.25 36.9996 60.25Z"
        fill="url(#paint0_linear_11669_63414)" />
      <path
        d="M25.6002 8.90039H49.0335C51.2629 8.90039 53.0869 10.7004 53.0869 12.9004V43.6504C53.0869 45.8504 51.2629 47.6504 49.0335 47.6504H25.6002C23.3709 47.6504 21.5469 45.8504 21.5469 43.6504V12.9004C21.5469 10.6754 23.3709 8.90039 25.6002 8.90039Z"
        fill="url(#paint1_linear_11669_63414)" />
      <path
        d="M25.5753 13.9004H35.7593C36.4433 13.9004 37.0007 14.4504 37.0007 15.1254C37.0007 15.8004 36.4433 16.3504 35.7593 16.3504H25.5753C24.8913 16.3504 24.334 15.8004 24.334 15.1254C24.334 14.4254 24.8913 13.9004 25.5753 13.9004Z"
        fill="black" />
      <path
        d="M25.5753 20.8496H48.426C49.11 20.8496 49.6673 21.3996 49.6673 22.0746C49.6673 22.7496 49.11 23.2996 48.426 23.2996H25.5753C24.8913 23.2996 24.334 22.7496 24.334 22.0746C24.334 21.3746 24.8913 20.8496 25.5753 20.8496Z"
        fill="#D5D5D5" />
      <path
        d="M25.5753 27.7998H48.426C49.11 27.7998 49.6673 28.3498 49.6673 29.0248C49.6673 29.6998 49.11 30.2498 48.426 30.2498H25.5753C24.8913 30.2498 24.334 29.6998 24.334 29.0248C24.334 28.3248 24.8913 27.7998 25.5753 27.7998Z"
        fill="#D5D5D5" />
      <path
        d="M25.5753 34.75H48.426C49.11 34.75 49.6673 35.3 49.6673 35.975C49.6673 36.65 49.11 37.2 48.426 37.2H25.5753C24.8913 37.2 24.334 36.65 24.334 35.975C24.334 35.275 24.8913 34.75 25.5753 34.75Z"
        fill="#D5D5D5" />
      <path
        d="M25.5753 41.7002H48.426C49.11 41.7002 49.6673 42.2502 49.6673 42.9252C49.6673 43.6002 49.11 44.1502 48.426 44.1502H25.5753C24.8913 44.1502 24.334 43.6002 24.334 42.9252C24.334 42.2252 24.8913 41.7002 25.5753 41.7002Z"
        fill="#D5D5D5" />
      <path
        d="M71.9861 4.59961H56.7608C56.0514 4.59961 55.4688 5.19961 55.4688 5.92461V13.3496C55.4688 14.0746 56.0514 14.6746 56.7608 14.6746H71.9861C72.6954 14.6746 73.2781 14.0746 73.2781 13.3496V5.94961C73.2781 5.19961 72.7208 4.59961 71.9861 4.59961Z"
        fill="white" />
      <path
        d="M59.2934 11.25C60.1294 11.25 60.8134 10.575 60.8134 9.75C60.8134 8.925 60.1294 8.25 59.2934 8.25C58.4574 8.25 57.7734 8.925 57.7734 9.75C57.7734 10.575 58.4574 11.25 59.2934 11.25Z"
        fill="#CCC6D9" />
      <path
        d="M64.3598 8.25H69.4265C70.2625 8.25 70.9465 8.925 70.9465 9.75C70.9465 10.575 70.2625 11.25 69.4265 11.25H64.3598C63.5238 11.25 62.8398 10.575 62.8398 9.75C62.8398 8.925 63.5238 8.25 64.3598 8.25Z"
        fill="#D5D5D5" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M45.8919 49.4004C49.1599 49.4004 52.1746 48.4254 54.7079 46.7504L65.0186 56.4754L68.7679 52.2004L58.8119 42.8254C60.6613 40.2754 61.7253 37.1504 61.7253 33.7754C61.7253 25.1504 54.6319 18.1504 45.8919 18.1504C37.1519 18.1504 30.0586 25.1504 30.0586 33.7754C30.0586 42.4004 37.1519 49.4004 45.8919 49.4004ZM59.3946 33.7754C59.3946 41.0754 53.3906 47.0004 45.9933 47.0004C38.5959 47.0004 32.5919 41.0754 32.5919 33.7754C32.5919 26.4754 38.5959 20.5504 45.9933 20.5504C53.3906 20.5504 59.3946 26.4504 59.3946 33.7754Z"
            fill="#CCC6D9" />
      <path
        d="M45.9942 47.25C53.6195 47.25 59.8008 41.2 59.8008 33.75C59.8008 26.3 53.6195 20.25 45.9942 20.25C38.3688 20.25 32.1875 26.3 32.1875 33.75C32.1875 41.2 38.3688 47.25 45.9942 47.25Z"
        fill="white" fillOpacity="0.3" />
      <path
        d="M48.3746 33.75L51.7186 30.475C52.0226 30.15 52.1999 29.725 52.1999 29.275C52.1999 28.825 51.9973 28.4 51.6933 28.075C51.3639 27.75 50.9333 27.575 50.4773 27.575C50.0213 27.575 49.5906 27.725 49.2613 28.05L45.9173 31.325L42.5733 28.05C42.4213 27.875 42.2186 27.75 42.0159 27.65C41.8133 27.55 41.5599 27.5 41.3319 27.5C41.1039 27.5 40.8759 27.55 40.6479 27.625C40.4199 27.7 40.2426 27.85 40.0653 28C39.8879 28.15 39.7613 28.35 39.6853 28.575C39.6093 28.8 39.5586 29.025 39.5586 29.25C39.5586 29.475 39.6093 29.7 39.7106 29.925C39.8119 30.125 39.9386 30.325 40.1159 30.475L43.4599 33.75L40.1159 37.025C39.9386 37.175 39.8119 37.375 39.7106 37.575C39.6093 37.775 39.5586 38 39.5586 38.25C39.5586 38.475 39.6093 38.7 39.6853 38.925C39.7613 39.15 39.9133 39.325 40.0653 39.5C40.2426 39.65 40.4199 39.8 40.6479 39.875C40.8759 39.95 41.1039 40 41.3319 40C41.5599 40 41.7879 39.95 42.0159 39.85C42.2186 39.75 42.4213 39.625 42.5733 39.45L45.9173 36.175L49.2613 39.45C49.5906 39.75 50.0213 39.9 50.4773 39.875C50.9333 39.85 51.3386 39.675 51.6679 39.375C51.9719 39.075 52.1746 38.65 52.1746 38.225C52.1999 37.8 52.0226 37.375 51.7439 37.025L48.3746 33.75Z"
        fill="black" />
      <path
        d="M65.0195 56.4498L68.7689 52.1748L69.3262 52.6998C69.8835 53.2248 70.1875 53.9248 70.2382 54.6998C70.2635 55.4748 70.0102 56.2248 69.5289 56.7748C69.0222 57.3498 68.3382 57.6748 67.6035 57.7248C66.8689 57.7498 66.1342 57.4998 65.6022 56.9748L65.0195 56.4498Z"
        fill="#E1DCEB" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.2631 15.8001C13.2631 15.4501 13.2124 15.0751 13.1111 14.7251C12.7817 13.4501 11.3631 12.6501 9.86841 12.4501C8.34841 12.2501 6.80307 12.7001 6.19507 13.8001C5.84041 14.4251 5.78974 14.9751 5.94174 15.4251C6.09374 15.8751 6.42307 16.2501 6.87907 16.5501C8.14574 17.3751 10.3497 17.5501 11.3631 17.2001C11.8191 17.0501 12.2751 16.8501 12.7311 16.6251C12.4777 18.0501 11.5404 19.4251 10.2737 20.6501C7.53774 23.3251 3.25641 25.3501 0.951073 26.0751C0.824407 26.1251 0.748407 26.2501 0.799074 26.3751C0.84974 26.5001 0.976407 26.5751 1.10307 26.5251C3.45907 25.7751 7.81641 23.7001 10.6031 20.9751C12.0471 19.5751 13.0857 18.0001 13.2631 16.3251C16.5311 14.5251 19.2164 11.2501 21.5217 8.50014C21.5977 8.40014 21.5977 8.25014 21.4964 8.15014C21.3951 8.07514 21.2431 8.07514 21.1671 8.17514C18.9377 10.8751 16.3791 14.0001 13.2631 15.8001ZM12.7817 16.0501C12.8071 15.6501 12.7564 15.2501 12.6551 14.8251C12.3764 13.7251 11.1097 13.0751 9.81774 12.9001C9.00707 12.8001 8.19641 12.8751 7.53774 13.1751C7.13241 13.3501 6.80307 13.6251 6.60041 14.0001C6.34707 14.4751 6.27107 14.9001 6.39774 15.2501C6.49907 15.6001 6.77774 15.9001 7.13241 16.1251C8.27241 16.8751 10.2991 17.0251 11.2111 16.7251C11.7431 16.5501 12.2751 16.3251 12.7817 16.0501Z"
            fill="black" />
      <path
        d="M71.1997 40.5C72.0392 40.5 72.7197 39.8284 72.7197 39C72.7197 38.1716 72.0392 37.5 71.1997 37.5C70.3602 37.5 69.6797 38.1716 69.6797 39C69.6797 39.8284 70.3602 40.5 71.1997 40.5Z"
        fill="#E3E3E3" />
      <path fillRule="evenodd" clipRule="evenodd"
            d="M13.7953 49.3502C14.074 49.2502 14.3527 49.1252 14.5807 48.9252C14.834 48.7002 14.9607 48.4002 15.0113 48.1002C15.1127 47.7002 15.138 47.2752 15.2393 46.8752C15.29 46.7252 15.366 46.6752 15.3913 46.6502C15.4673 46.5752 15.5687 46.5752 15.6447 46.5752C15.746 46.5752 15.8727 46.6252 15.974 46.8002C15.974 46.8252 15.9993 46.8502 16.0247 46.9002C16.0247 46.9252 16.05 47.0502 16.05 47.1002C16.0753 47.2252 16.1007 47.3252 16.1007 47.4502C16.1513 47.8502 16.1767 48.1752 16.354 48.5252C16.582 49.0252 16.8353 49.3252 17.1393 49.4502C17.4433 49.5752 17.8233 49.5502 18.3047 49.4502C18.3553 49.4502 18.406 49.4252 18.4313 49.4252C18.634 49.3752 18.8367 49.5252 18.8873 49.7502C18.938 49.9752 18.786 50.1752 18.5833 50.2252C18.5327 50.2252 18.5073 50.2502 18.4567 50.2502C17.8233 50.4252 17.0887 51.0002 16.658 51.5252C16.5313 51.6752 16.3287 52.1252 16.126 52.4002C15.974 52.6002 15.822 52.7502 15.67 52.8002C15.5687 52.8252 15.4927 52.8252 15.442 52.8002C15.3407 52.7752 15.2647 52.7252 15.214 52.6502C15.1887 52.6002 15.1633 52.5502 15.138 52.4752C15.138 52.4252 15.138 52.3502 15.138 52.3002C15.0873 52.1502 15.0367 52.0252 15.0113 51.8752C14.9353 51.5252 14.758 51.3002 14.5553 51.0252C14.378 50.7502 14.1753 50.5752 13.8713 50.4502C13.8207 50.4502 13.5167 50.3502 13.4153 50.3252C13.2633 50.2502 13.1873 50.1502 13.162 50.1002C13.1113 50.0002 13.1113 49.9002 13.1113 49.8252C13.1367 49.7252 13.162 49.6252 13.2633 49.5502C13.314 49.5002 13.39 49.4502 13.4913 49.4252C13.542 49.3502 13.77 49.3502 13.7953 49.3502ZM15.6193 48.7752C15.6447 48.8002 15.6447 48.8502 15.67 48.8752C16.0247 49.6002 16.4047 50.0002 16.8607 50.1752H16.886C16.582 50.4252 16.278 50.7002 16.0753 50.9502C15.974 51.0502 15.8727 51.2752 15.746 51.5002C15.6193 51.1002 15.442 50.8252 15.1887 50.4752C15.0113 50.2002 14.8087 50.0002 14.5807 49.8502C14.758 49.7502 14.9353 49.6502 15.0873 49.5002C15.3153 49.3252 15.4927 49.0502 15.6193 48.7752Z"
            fill="#CCC6D9" />
      <defs>
        <linearGradient id="paint0_linear_11669_63414" x1="36.7646" y1="-9.51872" x2="37.371" y2="95.8368"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#F2F2F2" />
          <stop offset="1" stopColor="#EFEFEF" />
        </linearGradient>
        <linearGradient id="paint1_linear_11669_63414" x1="37.3216" y1="8.88939" x2="37.3216" y2="47.6466"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset="0.7188" stopColor="#FAFAFA" />
        </linearGradient>
      </defs>
    </svg>

  );
};

export default NotFoundSelectIcon;