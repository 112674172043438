import React from "react";
import { Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import NotUserImage from "features/app/components/not-image/NotUserImage";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import { useTranslation } from "react-i18next";

import { cx, dayjsFormats, IdAndName, isEmptyArr, RU, useBaseCurrency } from "modules/common";
import { Icons, PaymentCardData, useOneCompanyWidget, useWidget, WidgetNoData } from "modules/dashboard";

import { LoadingIndicator } from "components";

import { WidgetCustomTooltip } from "../../tooltip";

const CashStatus: React.FC = () => {
  const currency = getBaseCurrency();
  const { t } = useTranslation();
  const percent = 0;
  const { visible, id, methods } = useWidget();
  const { data, isLoading } = useOneCompanyWidget({
    id,
    enabled: visible
  });
  const payments = [...(data?.data || [])] as PaymentCardData[];
  const { symbol } = useBaseCurrency();

  const percentIcon = () => {
    if (percent > 0) return <Icons.ArrowUpIcon isRotate />;
    if (percent < 0) return <Icons.ArrowUpIcon />;
    return null;
  };

  const onOpenDetailedDrawer =
    ({ id, title }: { id: number; title: string }) =>
    () => {
      methods.setWidgetDetailedDrawer({
        id,
        title,
        visible: true
      });
    };

  const customTooltip = (max_date: string, min_date: string, total: number, total_min_date: number) => (
    <div className="flex flex-col gap-2">
      <div
        className={cx("flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2", {
          "border-l-success-500": percent && percent > 0,
          "border-l-error-500": percent && percent < 0,
          "border-l-white": percent === 0
        })}
      >
        <span className="text-xs font-medium text-white">
          {dayjs(max_date, dayjsFormats.DATE).format(dayjsFormats.DATE_WITH_SHORT_MONTH_NAME)}
        </span>
        <span className="text-sm font-semibold text-white">
          {total.toLocaleString(RU)} {currency?.symbol}
        </span>
        {(percent || percent === 0) && (
          <div
            className={cx(
              "flex w-max items-center gap-1 rounded-2xl border-white bg-white px-2 py-1 text-sm font-medium",
              {
                "text-success-500": percent && percent > 0,
                "text-error-500": percent && percent < 0,
                "text-gray-700": percent === 0
              }
            )}
          >
            {percentIcon()}
            <span>{percent}%</span>
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <span className="text-[0.625rem] font-medium leading-[1.125rem] text-gray-300">
          {t("dashboard.Solishtirildi")}
        </span>
        <div className={cx("flex flex-col gap-1 border-b-0 border-l-4 border-r-0 border-t-0 border-solid pl-2")}>
          <span className="text-xs font-medium text-gray-300">
            {dayjs(min_date, dayjsFormats.DATE).format(dayjsFormats.DATE_WITH_SHORT_MONTH_NAME)}
          </span>
          <span className="text-sm font-semibold text-gray-300">
            {total_min_date?.toLocaleString(RU)} {currency?.symbol}
          </span>
        </div>
      </div>
    </div>
  );

  const generateProjectNames = (projects: IdAndName[]) => {
    let result = "";

    projects?.forEach((project, index) => {
      if (index) result += ` , ${project.name}`;
      else result += project.name;
    });

    return result;
  };

  return (
    <Spin wrapperClassName="w-full" spinning={isLoading} indicator={LoadingIndicator}>
      <div className="flex flex-col gap-4 bg-white p-5">
        {!isEmptyArr(payments) &&
          payments.map(item => (
            <div
              key={item.id}
              onClick={onOpenDetailedDrawer({ id: item.id, title: item.name })}
              className="cursor-pointer rounded-xl border border-solid border-gray-200 transition-all hover:border-primary-500"
            >
              <div className="flex flex-col border-0 border-b border-solid border-b-gray-200 px-4 py-3">
                <h5 className="m-0 text-lg font-semibold text-gray-900">{item.name}</h5>
                <div className="m-0 flex w-[37.5rem] items-center gap-1.5 text-sm font-medium text-gray-600">
                  <span className="w-max">
                    {item?.projects?.slice(0, 3)?.map((p, i) => `${i ? ", " : " "}${p.name}`)}
                  </span>
                  {item.projects?.length > 3 && (
                    <Tooltip title={generateProjectNames(item.projects.slice(3, item?.projects.length))}>
                      <div>
                        <NotUserImage
                          isTooltip={false}
                          title={`+${item.projects?.length - item.projects?.slice(0, 3).length}`}
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="box-border flex w-full flex-col gap-3 p-4">
                <div className="flex flex-col gap-3 rounded-xl border border-solid border-transparent bg-gray-50 px-4 py-3">
                  <div className="flex items-center gap-2">
                    <h5 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Umumiy balans")}</h5>
                    <div className="flex items-center gap-2">
                      <WidgetCustomTooltip
                        isToolTip
                        content={customTooltip(
                          item.data.max_date!,
                          item.data.min_date!,
                          item.data.total_amount,
                          item.data.total_min_date!
                        )}
                      >
                        <div
                          className={cx("flex items-center justify-center rounded-2xl px-2 py-1 text-sm font-medium", {
                            "bg-success-50 text-success-500": percent > 0,
                            "bg-error-50 text-error-500": percent < 0,
                            "bg-gray-200 text-gray-700": percent === 0
                          })}
                        >
                          {percentIcon()}
                          <span>{item.data.difference_percentage}%</span>
                        </div>
                      </WidgetCustomTooltip>
                      <span className="text-sm font-normal text-gray-400">{t("dashboard.o'tgan kunga nisbatan")}</span>
                    </div>
                  </div>
                  <h2 className="m-0 flex items-end gap-1 text-4xl font-medium text-gray-700">
                    {item.data.total_amount?.toLocaleString(RU)}
                    <span className="text-xl font-medium text-gray-400">{symbol}</span>
                  </h2>
                </div>
                <div className="flex gap-3">
                  <div className="box-border flex w-1/2 gap-2.5 rounded-xl bg-success-50 px-4 py-3">
                    <div className="h-full w-1.5 rounded-md bg-success-500" />
                    <div className="flex flex-col gap-3">
                      <h5 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Kirim")}</h5>
                      <h2 className="m-0 flex items-end gap-1 text-xl font-medium text-success-500">
                        {item.data.total_income_amount?.toLocaleString(RU)}
                        <span className="text-sm font-medium text-gray-400">{symbol}</span>
                      </h2>
                    </div>
                  </div>
                  <div className="box-border flex w-1/2 gap-2.5 rounded-xl bg-error-50 px-4 py-3">
                    <div className="h-full w-1.5 rounded-md bg-error-500" />
                    <div className="flex flex-col gap-3">
                      <h5 className="m-0 text-base font-medium text-gray-700">{t("dashboard.Chiqim")}</h5>
                      <h2 className="m-0 flex items-end gap-1 text-xl font-medium text-error-500">
                        {Math.abs(item.data.total_expense_amount)?.toLocaleString(RU)}
                        <span className="text-sm font-medium text-gray-400">{symbol}</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {isEmptyArr(payments) && <WidgetNoData />}
      </div>
    </Spin>
  );
};

export default CashStatus;
