import React, { useState } from "react";
import { Button } from "antd";
import BuildingIcon from "features/app/assets/icons/BuildingIcon";
import MainWarehouseIcon from "features/app/assets/icons/MainWarehouseIcon";
import FilterSelect from "features/app/components/filter-drawer/filter-select/FilterSelect";
import FilterTreeSelect from "features/app/components/filter-drawer/filter-tree-select/filter-tree-select";
import FilterDrawer from "features/app/components/filter-drawer/FilterDrawer";
import FiltersCount from "features/app/components/filter-drawer/filters-count/FiltersCount";
import { useProjectSelect } from "features/app/service/queries";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import FilterLinesIcon from "features/warehouse/assets/icons/FilterLinesIcon";
import { useGetWarehouseSelect } from "features/warehouse/service/query";
import queryString from "query-string";
import { useTranslation } from "react-i18next";

import styles from "./filterLaboratoryDrawer.module.scss";

const queryStringArr = [queryParamsKeys.WAREHOUSE_IDS, queryParamsKeys.PROJECT_IDS];

const FilterLaboratoryDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { data: projects } = useProjectSelect({ enabled: open });
  const { data: senderWarehouses } = useGetWarehouseSelect(true, open);

  const [projectsSelected, setProjectsSelected] = useState<string[] | null>(
    queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.PROJECT_IDS] as string[] | null
  );

  const [senderWarehousesSelected, setSenderWarehousesSelected] = useState<string[] | null>(
    queryString.parse(window.location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.WAREHOUSE_IDS] as string[] | null
  );

  const onOpenFilter = () => {
    setOpen(true);
  };

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      height="max-content"
      filterButton={
        <Button onClick={onOpenFilter}>
          <FilterLinesIcon /> {t("warehouse.Filter")}
          <FiltersCount queryStringArr={queryStringArr} />
        </Button>
      }
      queryStringArr={queryStringArr}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.WAREHOUSE_IDS,
          selectedOptions: senderWarehousesSelected
        },

        {
          queryKey: queryParamsKeys.PROJECT_IDS,
          selectedOptions: projectsSelected
        }
      ]}
      setSelectedOptionsArr={[setSenderWarehousesSelected, setProjectsSelected] as never}
    >
      <div className={styles.content}>
        <FilterSelect
          data={senderWarehouses}
          selectedOptions={senderWarehousesSelected}
          setSelectedOptions={setSenderWarehousesSelected}
          multiple="multiple"
          icon={<MainWarehouseIcon />}
          placeholder={t("warehouse.Omborxonalar")}
          showSearch
          className={styles.transfer_select}
        />
        <FilterTreeSelect
          selectedOptions={projectsSelected}
          setSelectedOptions={setProjectsSelected}
          data={projects}
          multiple={true}
          icon={<BuildingIcon />}
          placeholder={t("warehouse.Loyihalar")}
          showSearch
        />
      </div>
    </FilterDrawer>
  );
};

export default FilterLaboratoryDrawer;
