import React from "react";

import Bottom from "./bottom";
import Top from "./top";

const CashStatus: React.FC = () => (
  <div className="box-border flex w-full flex-col gap-5 p-5">
    <Top />
    <Bottom />
  </div>
);

export default CashStatus;
