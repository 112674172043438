import React from "react";

const EditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.39735 14.0963C1.43564 13.7517 1.45478 13.5794 1.50691 13.4184C1.55316 13.2755 1.61851 13.1396 1.70118 13.0142C1.79436 12.8729 1.91694 12.7503 2.16209 12.5052L13.1673 1.49992C14.0878 0.579451 15.5802 0.579452 16.5007 1.49993C17.4211 2.4204 17.4211 3.91279 16.5007 4.83326L5.49542 15.8385C5.25027 16.0836 5.1277 16.2062 4.98639 16.2994C4.86102 16.3821 4.72506 16.4474 4.58219 16.4937C4.42115 16.5458 4.24887 16.5649 3.90429 16.6032L1.08398 16.9166L1.39735 14.0963Z"
      stroke="#475467"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditIcon;
