import React from "react";
import { UseFormReturn } from "react-hook-form";

import { WidgetSettingsForm } from "modules/dashboard/schema";

import { Select } from "components";

type Props = {
  form: UseFormReturn<WidgetSettingsForm>;
};

const CompanyPersonType: React.FC<Props> = ({ form }) => {
  const selectOptions = [
    {
      label: "Ishchi",
      value: "worker"
    },
    {
      label: "Ta'minotchi",
      value: "supplier"
    }
  ];

  return <Select name="type" options={selectOptions} control={form.control} allowClear label="Kontragent turi" />;
};

export default CompanyPersonType;
