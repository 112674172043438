import { useEffect, useRef, useState } from "react";
import { Table } from "components";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";

import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../app/components/pagination/Pagination";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../app/utils/constants/languages";
import { PaymentStatusEnums } from "../../../app/utils/enums/paymentStatusEnums";
import { PaymentSourceElement } from "../../../payment/components/bottom/income-expense-view/PaymentSourceElement";
import PaymentSearch from "../../../payment/components/top/search/PaymentSearch";
import { useGetPaymentsImpost } from "../../service/queries";
import { ImpostPaymentStatusIcons } from "../../utils/constants/impostPaymentStatusIcons";
import { PaymentImpostModel } from "../../utils/models/paymentImpostModel";

import ActionDropdown from "./action-dropdown/ActionDropdown";
import FilterDrawerPayments from "./filter-drawer/FilterDrawerPayments";
import PaymentView from "./payment-view/PaymentVIew";

import styles from "./payments.module.scss";
import { useExportMonitoringPaymentExcel } from "features/impost/service/mutations";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { impostPaymentsTableDefaultData } from "features/impost/utils/constants/impostTableDefaultData";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import TableSettings from "features/app/components/table-settings/TableSettings";
import useDebounce from "components/use-debounce/use-debounce";

// interface SourceModel {
//   name: string;
//   key: string;
//   background: string;
//   color: string;
// }

const Payment = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { t } = useTranslation();
  const [record, setRecord] = useState<PaymentImpostModel>();
  const [visibleDetail, setVisibleDetail] = useState(false);
  const { data, isLoading } = useGetPaymentsImpost();
  const exportExcelMutate = useExportMonitoringPaymentExcel();
  const changeTableConfig = useChangeTableConfig(tableConfigKeys.MONITORING_PAYMENTS, true);
  const { data: tableConfig } = useGetTableConfigs(tableConfigKeys.MONITORING_PAYMENTS, impostPaymentsTableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.MONITORING_PAYMENTS]);

  // const paymentSource = (record: PaymentImpostModel): SourceModel | undefined => {
  //   let source: SourceModel | undefined;
  //   const isPaymentTypeExpense = PaymentTypeEnum.EXPENSE === record.type;
  //
  //   if (record.user?.full_name) {
  //     source = {
  //       name: record.user.full_name,
  //       key: "XD",
  //       background: "#FECDCA",
  //       color: "#B42318",
  //     };
  //   } else if (record.financial?.name) {
  //     source = {
  //       name: record.financial.name,
  //       key: isPaymentTypeExpense ? "CH" : "KR",
  //       background: isPaymentTypeExpense ? "#EAECF0" : "#A6F4C5",
  //       color: isPaymentTypeExpense ? "#344054" : "#027A48",
  //     };
  //   } else if (record.company_person?.person?.name) {
  //     source = {
  //       name: record.company_person?.person?.name,
  //       key: "KN",
  //       background: "#FEF0C7",
  //       color: "#B54708",
  //     };
  //   }
  //
  //   return source;
  // };
  //
  // const paymentSourceElement = (record: PaymentImpostModel) => {
  //   const getSource = paymentSource(record);
  //
  //   return getSource ? (
  //     <div className={styles.source}>
  //       <div className={styles.source__key}>{getSource?.key}</div>
  //       <span>{getSource?.name}</span>
  //     </div>
  //   ) : (
  //     "-"
  //   );
  // };

  const onDetailIncomeOrExpense = (record: PaymentImpostModel) => ({
    onClick: () => {
      setRecord(record);
      setVisibleDetail(true);
    }
  });

  const columns = [
    {
      title: `${t("Monitoring.Manbaa")}`,
      render: (record: PaymentImpostModel) => <PaymentSourceElement record={record as never} />,
      width: 750,
      className: "monitoring-manbaa",
      fixed: "left",
      key: "financial.name"
    },
    {
      title: `${t("Monitoring.Sana")}`,
      dataIndex: "date",
      width: 200,
      className: "monitoring-date",
      key: "date"
    },
    {
      title: `${t("Monitoring.Summa")}`,
      render: (record: PaymentImpostModel) => (
        <>
          {record.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      ),
      width: 250,
      className: "monitoring-summa",
      key: "amount"
    },
    {
      title: `${t("Monitoring.To'lov turi")}`,
      render: (record: PaymentImpostModel) => (
        <div
          className={styles.payment_type}
          style={{
            color: record.payment_type.color,
            background: `${record.payment_type.color}20`
          }}
        >
          {record.payment_type.name}
        </div>
      ),
      width: 250,
      className: "monitoring-payment_type.name",
      key: "payment_type.name"
    },
    {
      title: `${t("Monitoring.Xodim")}`,
      render: (record: PaymentImpostModel) => (
        <div className={styles.person}>
          <CustomAvatar image={record?.creator?.image} name={record?.creator?.full_name} />
        </div>
      ),
      width: 200,
      className: "monitoring-creator",
      key: "creator.full_name"
    },
    {
      title: `${t("Monitoring.Tasdiqlash")}`,
      render: (record: PaymentImpostModel) => (
        <div className={styles.status}>{ImpostPaymentStatusIcons[record?.status as PaymentStatusEnums]}</div>
      ),
      width: 200,
      className: "monitoring-status",
      key: "status"
    }
  ];

  const [renderColumns, setColumns] = useState<ColumnsType<PaymentImpostModel>>([]);

  const saveColumns = () => {
    const columnsToSave = renderColumns?.map(column => {
      const { title, render, ...rest } = column;

      return rest;
    });

    changeTableConfig.mutateAsync({
      key: tableConfigKeys.MONITORING_PAYMENTS,
      width_data: JSON.stringify(columnsToSave)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<PaymentImpostModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  const title = () => (
    <div className={styles.title}>
      <div className={styles.search}>
        <PaymentSearch />
      </div>
      <FilterDrawerPayments exportExcelMutate={exportExcelMutate} />
    </div>
  );

  useEffect(() => {
    if (tableConfig && customFields) {
      setColumns([
        ...filterColumns({
          columns: columns as never,
          customFields,
          tableConfig
        }),
        {
          title: (
            <TableSettings
              isNeedSize
              defaultData={impostPaymentsTableDefaultData}
              locations={[CustomFieldLocationEnum.MONITORING_PAYMENTS]}
              configKey={tableConfigKeys.MONITORING_PAYMENTS}
            />
          ),

          render: (record: PaymentImpostModel) => <ActionDropdown record={record} />,
          width: 70,
          className: "monitoring-action",
          fixed: "right"
        }
      ]);
    }
  }, [tableConfig, customFields]);

  const footer = () => (
    <Pagination
      paginationProps={{
        total: data?.total
      }}
    />
  );

  return (
    <div className={styles.payment}>
      <div className={styles.top}>
        <Table<PaymentImpostModel>
          title={title}
          onChangeColumns={onChangeColumns}
          dataSource={data?.data}
          columns={renderColumns}
          pagination={false}
          rowKey={record => record.id}
          onRow={onDetailIncomeOrExpense}
          rowClassName={() => styles.row}
          scroll={{ y: "calc(100vh - 18.125rem)" }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
          locale={{
            emptyText: <TableEmpty />
          }}
          footer={footer}
        />
      </div>

      <PaymentView visible={visibleDetail} setVisible={setVisibleDetail} record={record as never} />
    </div>
  );
};

export default Payment;
