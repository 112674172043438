import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, DatePicker, Form, Input, message, Modal, Segmented, Select } from "antd";
import dayjs, { Dayjs } from "dayjs";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import TableIcon from "features/payment/assets/icons/TableIcon";
import { ExpectedPaymentBodyModel } from "features/payment/utils/models/expectedPaymentBodyModel";
import { FinancialModel } from "features/settings/utils/models/financialModel";
import ChevronDownIcon from "features/supply/assets/icons/ChevronDownIcon";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "hooks/redux";
import { useQueryParams } from "hooks/useQueryParams";

import { RootState } from "../../../../../store";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import DatepickerSuffixIcon from "../../../../app/assets/icons/DatepickerSuffixIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "../../../../app/components/project-select/project-select";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { useGetCurrenySelect } from "../../../../app/service/queries";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { formRules } from "../../../../app/utils/constants/formRules";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../app/utils/constants/queryParamsKeys";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import { selectFilterOption } from "../../../../app/utils/helpers/selectFilterOption";
import { useGetCompanyPersonSelect } from "../../../../counterparts/service/queries";
import { useGetFinancial } from "../../../../settings/service/queries";
import { CurrencyModel } from "../../../../settings/utils/models/currency/currencyModel";
import { useCreateExpectedPayment } from "../../../service/mutation";

import FormTableModal from "./form-table-modal/FormTableModal";

import styles from "./expectedPaymentModal.module.scss";

interface ExpectedPaymentFormModel {
  amount: string;
  currency: string;
  financial_id: number | string;
  project_id: number;
  start_date: Dayjs;
  company_person_id?: number;
  description: string | undefined;
}

type Props = {
  isCounterParts?: boolean;
};

enum FinancialType {
  COMPANY_PERSON = "company_person",
  INCOME = "income",
  EXPENSE = "expense"
}

const { Item, useWatch } = Form;
const { Option } = Select;

const ExpectedPaymentModal: React.FC<Props> = ({ isCounterParts }) => {
  const params = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { append } = useQueryParams();
  const baseCurrency = getBaseCurrency();
  const { id: companyPersonId } = parseParamsId(params?.second_id);
  const createExpectedPayment = useCreateExpectedPayment(isCounterParts);
  const [isCompanyPerson, setIsCompanyPerson] = useState<boolean>(false);
  const [formInstance] = Form.useForm<ExpectedPaymentFormModel>();
  const financialType = useWatch("type", formInstance);
  const { setVisibleExpectedPayment, setVisibleFormTable, setFormTableState, setDataFormTable } = paymentReducerActions;
  const { visible, form, type, date } = useAppSelector((state: RootState) => state.paymentReducer.expected_payment);

  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCurrenySelect();
  const { data: financial, isLoading: isLoadingFinancial } = useGetFinancial(visible);
  const { data: companyPerson, isLoading: isLoadingCompanyPerson } = useGetCompanyPersonSelect(isCompanyPerson);

  const onCancel = () => {
    dispatch(
      setVisibleExpectedPayment({
        visible: false,
        type: PaymentTypeEnum.INCOME
      })
    );

    dispatch(setDataFormTable([]));

    dispatch(
      setVisibleExpectedPayment({
        visible: false,
        type: PaymentTypeEnum.INCOME
      })
    );

    dispatch(setDataFormTable([]));

    dispatch(
      setFormTableState({
        mountRange: 1
      })
    );

    formInstance.resetFields();
  };

  const onOk = () => {
    formInstance.submit();
  };

  const onFinish = (values: ExpectedPaymentFormModel) => {
    const { expected_payments, state } = form;
    const currency = JSON.parse(values.currency) as CurrencyModel;

    const reqData: ExpectedPaymentBodyModel = {
      type,
      description: values.description,
      expected_payments_count: expected_payments!.length > 0 ? expected_payments!.length : 1,
      amount: parseLocaledString(values.amount),
      currency_id: currency.id,
      financial_id: values.financial_id,
      start_date: dayjs(values.start_date, dayjsFormats.DATE).format(dayjsFormats.DATE),
      project_id: values.project_id,
      mount_range: state.mountRange ?? 1,
      expected_payments: expected_payments ? expected_payments.map(({ amount, date }) => ({ amount, date })) : []
    };

    if (isCounterParts) {
      delete reqData.financial_id;

      reqData.company_person_id = Number(companyPersonId);
    } else if (values.company_person_id) {
      delete reqData.financial_id;

      reqData.company_person_id = values.company_person_id;
    }

    if (!date) {
      append(queryParamsKeys.CURRENCY_ID, reqData?.currency_id);
    }

    createExpectedPayment.mutateAsync(reqData).then(onCancel);
  };

  const onOpenCreateTableModal = () => {
    formInstance
      .validateFields()
      .then(() => {
        dispatch(setVisibleFormTable(true));
      })
      .catch(() => {
        message.warning("Ma'lumotlar to'liq kiritilmagan !");
      });
  };

  const financialLabel = {
    [PaymentTypeEnum.INCOME]: "Kirim",
    [PaymentTypeEnum.EXPENSE]: "Chiqim"
  };

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      if (isCounterParts) {
        setIsCompanyPerson(isCounterParts);

        formInstance.setFieldValue("company_person_id", companyPersonId);
      }

      formInstance.setFieldValue("type", type);
      formInstance.setFieldValue("start_date", date ? dayjs(date, dayjsFormats.DATE) : dayjs());
    }
  };

  const title = () => {
    const names = {
      [PaymentTypeEnum.INCOME]: "Kirim shartnoma kiritish",
      [PaymentTypeEnum.EXPENSE]: "Chiqim shartnoma kiritish"
    };

    return names[type as keyof typeof financialLabel] ?? "Kutilayotgan to'lovlar";
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    formInstance.setFieldValue("amount", localeFormatter(e.currentTarget.value));
  };

  const segmentOptions = [
    {
      value: FinancialType.INCOME,
      label: "Daromad turi",
      view: type === PaymentTypeEnum.INCOME
    },
    {
      value: FinancialType.EXPENSE,
      label: "Xarajat turi",
      view: type === PaymentTypeEnum.EXPENSE
    },
    {
      value: FinancialType.COMPANY_PERSON,
      label: "Kontragent",
      view: !isCompanyPerson
    }
  ];

  const initialCurrency = () => JSON.stringify(currencies?.find(item => item?.id === baseCurrency?.id));

  const getFinancialContent = (type: PaymentTypeEnum) => (
    <Item name="financial_id" rules={formRules()}>
      <Select
        showSearch
        loading={isLoadingFinancial}
        notFoundContent={<TableEmpty />}
        filterOption={selectFilterOption}
        suffixIcon={<SelectSuffixIcon />}
        placeholder="Tanlang"
      >
        {financial?.data?.[type as keyof FinancialModel]?.map(item => (
          <Option
            key={item.id}
            value={item.id}
            props={{
              name: item?.name
            }}
          >
            {item.name}
          </Option>
        ))}
      </Select>
    </Item>
  );

  const financialFormItem = {
    [FinancialType.INCOME]: getFinancialContent(PaymentTypeEnum.INCOME),
    [FinancialType.EXPENSE]: getFinancialContent(PaymentTypeEnum.EXPENSE),
    [FinancialType.COMPANY_PERSON]: (
      <Item name="company_person_id" rules={formRules()}>
        <Select
          showSearch
          placeholder="Tanlang"
          filterOption={selectFilterOption}
          notFoundContent={<TableEmpty />}
          loading={isLoadingCompanyPerson}
          suffixIcon={<SelectSuffixIcon />}
        >
          {companyPerson?.map(item => (
            <Option
              key={item.id}
              value={item.id}
              props={{
                name: item?.name
              }}
            >
              {item?.name}
            </Option>
          ))}
        </Select>
      </Item>
    )
  };

  const onChangeType = () => {
    formInstance.setFieldValue("financial_id", undefined);
    formInstance.setFieldValue("company_person_id", undefined);
  };

  return (
    <>
      <Modal
        centered
        title={title()}
        okText="Saqlash"
        cancelText="Yopish"
        open={visible}
        afterOpenChange={onAfterOpen}
        onOk={onOk}
        className={styles.pending_payment_modal}
        onCancel={onCancel}
        okButtonProps={{
          loading: createExpectedPayment.isLoading
        }}
      >
        <Form form={formInstance} onFinish={onFinish} layout="vertical">
          <ProjectSelect label={t("payment.Loyiha")} rule={formRules()} suffixIcon={<SelectSuffixIcon />} />
          <div className="flex flex-col gap-2 text-black">
            <label className="font-medium">Summa</label>
            <div className={styles.amount_wrapper}>
              <Item name="amount" rules={formRules()}>
                <Input onChange={onChangeAmount} rootClassName={styles.amount} placeholder="Summani kiriting" />
              </Item>
              <Item name="currency" rules={formRules()} initialValue={initialCurrency()}>
                <Select
                  loading={isLoadingCurrencies}
                  placeholder="Valyutani tanlang"
                  suffixIcon={<ChevronDownIcon rotate />}
                  rootClassName={styles.currency_select}
                >
                  {currencies?.map(item => (
                    <Option key={item.id} value={JSON.stringify(item)}>
                      {item.symbol}
                    </Option>
                  ))}
                </Select>
              </Item>
            </div>
          </div>
          <ConditionalRender if={!isCounterParts}>
            <Item name="type" label="Chiqim manbasi">
              <Segmented options={segmentOptions?.filter(item => item?.view)} onChange={onChangeType} />
            </Item>
          </ConditionalRender>
          {financialFormItem[financialType as keyof typeof financialFormItem]}
          <Item name="start_date" label="Sana" initialValue={dayjs()}>
            <DatePicker format={dayjsFormats.DATE} suffixIcon={<DatepickerSuffixIcon />} placeholder="Sanani tanlang" />
          </Item>
          <Item name="description" label="Izoh">
            <Input.TextArea placeholder="Izoh kiriting" />
          </Item>
          <Button className={styles.create_table_btn} onClick={onOpenCreateTableModal}>
            <TableIcon />
            <span className="font-medium">Jadval shakllantirish</span>
          </Button>
        </Form>
      </Modal>
      <FormTableModal parentForm={formInstance} />
    </>
  );
};

export default ExpectedPaymentModal;
