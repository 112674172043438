import { useParams } from "react-router-dom";
import dayjs from "dayjs";

import { useGetWorkStatistics } from "../../../../service/queries";

import styles from "./top.module.scss";

const Top = () => {
  const { projectId } = useParams();
  const { data: statistics } = useGetWorkStatistics(projectId);

  return (
    <div className={styles.top}>
      <div className={styles.card}>
        <p>Tugash sanasi</p>
        <h3>{statistics?.due_date ?? "Ma'lum emas"}</h3>
      </div>
      <div className={styles.card}>
        <p>Qancha vaqt qolgani</p>
        <h3>
          {statistics?.due_date && statistics?.delay_date ? dayjs(statistics?.due_date).diff(dayjs(), "day") : 0}{" "}
          <span>kun</span>
        </h3>
      </div>
      <div className={styles.card}>
        <p>Kechikish</p>
        <h3>
          {statistics?.delay_date ?? 0} <span>kun</span>
        </h3>
      </div>
    </div>
  );
};

export default Top;
