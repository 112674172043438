import React from "react";

const ExpectedIncomeIcon: React.FC = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50008 13.3333L10.0001 10.8333M10.0001 10.8333L12.5001 13.3333M10.0001 10.8333V16.6666M18.3334 7.49992H1.66675M4.58341 14.9999H4.33341C3.39999 14.9999 2.93328 14.9999 2.57676 14.8183C2.26316 14.6585 2.00819 14.4035 1.8484 14.0899C1.66675 13.7334 1.66675 13.2667 1.66675 12.3333V5.99992C1.66675 5.0665 1.66675 4.59979 1.8484 4.24327C2.00819 3.92966 2.26316 3.6747 2.57676 3.51491C2.93328 3.33325 3.39999 3.33325 4.33342 3.33325H15.6667C16.6002 3.33325 17.0669 3.33325 17.4234 3.51491C17.737 3.6747 17.992 3.92967 18.1518 4.24327C18.3334 4.59979 18.3334 5.0665 18.3334 5.99992V12.3333C18.3334 13.2667 18.3334 13.7334 18.1518 14.0899C17.992 14.4035 17.737 14.6585 17.4234 14.8183C17.0669 14.9999 16.6002 14.9999 15.6667 14.9999H15.4167"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ExpectedIncomeIcon;
