import { useEffect, useMemo, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Spin, Tooltip } from "antd";
import { DataNode } from "antd/es/tree";
import { useTranslation } from "react-i18next";

import PlusIcon from "../../../../app/assets/icons/PlusIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { AppendEmployee } from "../../../../app/components/append-employee/AppendEmployee";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { Tree } from "../../../../app/components/tree/Tree";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import { parseTreeValue } from "../../../../app/utils/helpers/parseTreeValue";
import { UserSelectModel } from "../../../../app/utils/models/user/userSelectModel";
import { useCreateCash, useUpdateCash } from "../../../service/mutations";
import { useGetOneCash, useGetPaymentTypeSelect, useGetProjectTree } from "../../../service/queries";
import { CashBodyModel } from "../../../utils/models/cash/cashBodyModel";
import { CashFormFieldsModel } from "../../../utils/models/cash/cashFormFieldsModel";
import { ProjectParsedTreeModel } from "../../../utils/models/project/projectParsedTreeModel";
import { ProjectTreeModel } from "../../../utils/models/project/projectTreeModel";
import SettingsAdminsModal from "../../admins/admins/modal/SettingsAdminsModal";

import styles from "./settingsCashModal.module.scss";

type Props = {
  data: {
    visible: boolean;
    id?: number;
    name?: string;
  };
  setData: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id?: number;
      name?: string;
    }>
  >;
  folderId?: number;
};

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => void;

const { Item } = Form;

export const SettingsCashModal: React.FC<Props> = ({ data, setData, folderId }) => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm<CashFormFieldsModel>();
  const [users, setUsers] = useState<UserSelectModel[] | undefined>([]);
  const { data: oneCash } = useGetOneCash(data?.id);
  const { data: projects, isLoading: isLoadingProjects } = useGetProjectTree(data.visible);
  const { data: paymentTypes, isLoading: isLoadingPaymentTypes } = useGetPaymentTypeSelect({ enabled: data.visible });
  const createCash = useCreateCash();
  const updateCash = useUpdateCash();

  const isUpdating = useMemo(() => Boolean(data?.id), [data?.id]);

  useEffect(() => {
    if (isUpdating) {
      formInstance.setFieldsValue({
        name: oneCash?.name,
        payment_type_ids: oneCash?.payment_types.map(item => item.id),
        user_ids: oneCash?.users?.map(item => item?.id)
      });

      oneCash?.users && setUsers(oneCash?.users);
    }
  }, [isUpdating, oneCash, formInstance]);

  const onAfterOpen = (open: boolean) => {
    if (open) {
      if (isUpdating) {
        formInstance.setFieldsValue({
          name: oneCash?.name,
          payment_type_ids: oneCash?.payment_types.map(item => item.id),
          user_ids: oneCash?.users?.map(item => item?.id)
        });

        oneCash?.users && setUsers(oneCash?.users);
      } else if (data.name) {
        formInstance.setFieldsValue({
          name: data.name
        });
      }
    } else {
      formInstance.resetFields();
      setUsers([]);
    }
  };

  const onCancel = () => {
    setData({ visible: false, name: "" });
  };

  const onOk = () => {
    formInstance.submit();
  };

  const onFinish = (values: CashFormFieldsModel) => {
    const parsedTree: ProjectParsedTreeModel = {
      project_folder_ids: [],
      project_ids: []
    };
    const req: CashBodyModel & ProjectParsedTreeModel = {
      payment_type_ids: values.payment_type_ids,
      user_ids: values.user_ids,
      name: values.name,
      ...parseTreeValue<ProjectParsedTreeModel>(values?.projects ?? [], parsedTree)
    };

    delete req.project_folder_ids;

    if (isUpdating) {
      updateCash
        .mutateAsync({
          ...req,
          id: data.id!
        })
        .then(onCancel);
    } else {
      createCash
        .mutateAsync({
          ...req,
          cash_folder_id: folderId
        })
        .then(onCancel);
    }
  };

  const paymentTypeOptions = paymentTypes?.map(item => ({
    label: item.name,
    value: item.id
  }));

  const treeData: (projects: ProjectTreeModel | undefined) => DataNode[] = data =>
    (data?.project_folders ?? [])
      .map(folder => ({
        title: folder.name,
        key: JSON.stringify({
          value: folder.id,
          key: "project_folder_ids"
        }),
        children: folder.projects.map(project => ({
          title: project.name,
          key: JSON.stringify({
            value: project.id,
            key: "project_ids"
          })
        }))
      }))
      .concat(
        (data?.projects ?? []).map(project => ({
          title: project.name,
          key: JSON.stringify({
            value: project.id,
            key: "project_ids"
          }),
          children: []
        }))
      );

  return (
    <Modal
      centered
      open={data.visible}
      onCancel={onCancel}
      onOk={onOk}
      className={styles.modal}
      okButtonProps={{
        loading: createCash.isLoading || updateCash.isLoading
      }}
      afterOpenChange={onAfterOpen}
      title={t("Kassa.Kassa")}
      cancelText={t("products.Yopish")}
      okText={t("products.Saqlash")}
    >
      <Form form={formInstance} layout="vertical" onFinish={onFinish} className={styles.modal__form}>
        <div className={styles.top}>
          <Item name="name" label={t("Kassa.Kassa nomi")} rules={[{ required: true, message: "" }]}>
            <Input />
          </Item>
          <Item name="payment_type_ids" label={t("Kassa.To'lov turlari")} rules={[{ required: true, message: "" }]}>
            <Select
              mode="multiple"
              allowClear
              loading={isLoadingPaymentTypes}
              suffixIcon={<SelectSuffixIcon />}
              options={paymentTypeOptions}
            />
          </Item>
          <AppendEmployee
            data={users}
            setData={setUsers as SetUserType}
            formInstance={formInstance}
            name="user_ids"
            initialValue={oneCash?.users}
          >
            <Item name="user_ids" label={t("Xodimlar.Xodim")} className={styles.users_cont}>
              {isEmptyArr(users) ? (
                <Button type="dashed" shape="circle">
                  <PlusIcon />
                </Button>
              ) : (
                <div className={styles.users}>
                  {users?.map(user => (
                    <Tooltip key={user.id} title={user.full_name}>
                      {user?.image ? (
                        <img src={user.image} alt={user.image} />
                      ) : (
                        <NotUserImage name={user?.full_name} isTooltip width={34} />
                      )}
                    </Tooltip>
                  ))}
                </div>
              )}
            </Item>
          </AppendEmployee>
        </div>
        <Spin spinning={isLoadingProjects} indicator={<LoadingOutlined spin />}>
          <div className={styles.bottom}>
            <Tree
              formInstance={formInstance}
              data={treeData(projects)}
              initialData={treeData({
                project_folders: [],
                projects: oneCash?.projects ?? []
              })}
              name="projects"
              title={t("Kassa.Barcha loyihalar")}
            />
          </div>
        </Spin>
      </Form>
      <SettingsAdminsModal />
    </Modal>
  );
};
