import React, { useState } from "react";
import { useParams, useOutletContext } from "react-router-dom";

import { useQueryParams } from "../../../../../../../../hooks/useQueryParams";
import ConditionalRender from "../../../../../../../app/components/conditional-render/ConditionalRender";
import { isEmptyArr } from "../../../../../../../app/utils/helpers/isEmptyArr";
import DynamicEstimate from "../../../../../../components/detailed-project/dynamic-estimate/DynamicEstimate";
import EmptyDynamicEstimate from "../../../../../../components/detailed-project/dynamic-estimate/EmptyDynamicEstimate";
import EmptyStaticEstimate from "../../../../../../components/detailed-project/static-estimate/EmptyStaticEstimate";
import { StaticEstimate } from "../../../../../../components/detailed-project/static-estimate/StaticEstimate";
import StaticEstimateHeader from "../../../../../../components/detailed-project/static-estimate/StaticEstimateHeader";
import { useGetDynamicEstimateSection, useGetSections } from "../../../../../../service/queries";
import { EstimateSegmentedEnums } from "../../../../../../utils/enums/estimateSegmentedEnum";

import styles from "./projectPlan.module.scss";

export type OutletContextType = {
  smetaContainer: HTMLElement;
};

export const ProjectPlan: React.FC = () => {

  const params = useParams();
  const { queries } = useQueryParams();
  const { estimate_type } = queries();
  const [expandedAll, setExpandedAll] = useState(false);
  const [startPlanning, setStartPlanning] = useState(false);
  const estimateType = estimate_type ?? EstimateSegmentedEnums.PLAN;
  const isDynamic = estimateType === EstimateSegmentedEnums.FEATURE || estimateType === EstimateSegmentedEnums.FACT;

  const { data } = useGetSections(params.projectId);

  const { data: sections, isLoading } = useGetDynamicEstimateSection(isDynamic, params.projectId);

  return (
    <div className={styles.plan_page}>
      <StaticEstimateHeader expandedAll={expandedAll} setExpandedAll={setExpandedAll} />
      <ConditionalRender if={estimateType === EstimateSegmentedEnums.FACT}>
        <DynamicEstimate
          isFeature={false}
          sections={sections}
          isLoading={isLoading}
          expandedAll={expandedAll}
          startPlanning={startPlanning}
        />
      </ConditionalRender>
      <ConditionalRender if={estimateType === EstimateSegmentedEnums.FEATURE}>
        <DynamicEstimate
          isFeature={true}
          sections={sections}
          isLoading={isLoading}
          expandedAll={expandedAll}
          startPlanning={startPlanning}
        />
      </ConditionalRender>
      <StaticEstimate sections={sections} expandedAll={expandedAll} startPlanning={startPlanning} />
      <ConditionalRender if={!startPlanning && isEmptyArr(data) && estimateType === EstimateSegmentedEnums.PLAN}>
        <EmptyStaticEstimate setStartPlanning={setStartPlanning} />
      </ConditionalRender>
      <ConditionalRender if={!startPlanning && isEmptyArr(data) && estimateType !== EstimateSegmentedEnums.PLAN}>
        <EmptyDynamicEstimate />
      </ConditionalRender>
    </div>
  );
};
