import React, { useState } from "react";
import { Button, Form, Modal } from "antd";

import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import { colors } from "../../../../../app/utils/constants/colors";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { cx } from "../../../../../app/utils/helpers/cx";
import { parseLocaledString } from "../../../../../app/utils/helpers/parseLocaledString";
import CreditCardDownloadIcon from "../../../../assets/icons/CreditCardDownloadIcon";
import { useCreatePayment } from "../../../../service/mutation";
import { InventorySelectModel } from "../../../../utils/models/inventorySelectModel";

import FirstStep from "./first-step/FirstStep";
import SecondStep from "./second-step/SecondStep";
import ThirdStep from "./third-step/ThirdStep";

import styles from "./paymentStep.module.scss";
import { RU } from "features/app/utils/constants/languages";

const { useForm } = Form;

interface FinishProps {
  cash_id: number;
  project_id: number;
  payment_type_id?: number;
  financial_id: number;
  amount: string;
  inventory?: string;
  currency_amount: string;
}

const PaymentStep = () => {
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(0);

  const [autoAdvance, setAutoAdvance] = useState<boolean>(false);

  const { append } = useQueryParams();
  const modalData = useAppSelector(state => state.paymentReducer.paymentInventoryWorkModal);
  const { visible, cash, payment_type, id, personId, isInventory, currency } = modalData;

  const { setPaymentInventoryWorkModal } = paymentReducerActions;
  const createPayment = useCreatePayment();

  const steps: Record<number, React.ReactNode> = {
    0: (
      <FirstStep
        key="first"
        setStep={setStep}
        visible={visible}
        setAutoAdvance={setAutoAdvance}
        autoAdvance={autoAdvance}
      />
    ),
    1: (
      <SecondStep
        key="second"
        setStep={setStep}
        form={form}
        autoAdvance={autoAdvance}
        setAutoAdvance={setAutoAdvance}
      />
    ),
    2: <ThirdStep key="third" step={step} form={form} />
  };

  const onCancel = () => {
    dispatch(setPaymentInventoryWorkModal({ ...modalData, visible: false }));
  };

  const prev = () => {
    setAutoAdvance(false);
    setStep(step - 1);
  };

  const onOk = () => {
    form.submit();
  };

  const onFinish = (values: FinishProps) => {
    if (isInventory) {
      const inventory: InventorySelectModel = JSON.parse(values.inventory!);

      createPayment
        .mutateAsync({
          cash_id: cash?.id,
          project_id: values.project_id,
          payment_type_id: payment_type?.id,
          currency_id: currency?.id as number,
          amount: parseLocaledString(values.amount),
          type: PaymentTypeEnum.EXPENSE,
          financial_id: values?.financial_id,
          inventory_id: inventory?.id,
          currency_amount: values?.currency_amount ? parseLocaledString(values?.currency_amount) : 1
        })
        .then(() => {
          append(queryParamsKeys.CURRENCY_ID, String(currency?.id));
          onCancel();
        });
    } else {
      createPayment
        .mutateAsync({
          cash_id: cash?.id,
          project_id: values.project_id,
          payment_type_id: payment_type?.id,
          currency_id: currency?.id as number,
          amount: parseLocaledString(values.amount),
          type: PaymentTypeEnum.EXPENSE,
          company_person_id: personId,
          task_progress_detail_id: id,
          currency_amount: values?.currency_amount ? parseLocaledString(values?.currency_amount) : 1
        })
        .then(() => {
          append(queryParamsKeys.CURRENCY_ID, String(currency?.id));
          onCancel();
        });
    }
  };

  const title = () => {
    const description = [
      "Chiqimni qaysi kassadan amalga oshirishni tanlang",
      "Chiqim to’lov turi va valyutasini tanlang",
      `${cash?.name} / ${payment_type?.name}`
    ];

    return (
      <div className={styles.title}>
        <div className={cx(styles.title__icon, "bg-error-100")}>
          <CreditCardDownloadIcon isNotArrow stroke={colors.ERROR_500} />
        </div>
        <div className={styles.title__info}>
          <h4>Chiqim</h4>
          <div>
            <span className="text-sm font-normal text-gray-600">{description[step]}</span>
            <span className="text-sm font-medium text-gray-600">
              {step === 2 ? (
                <>
                  {currency?.amount?.toLocaleString(RU)} {currency?.symbol}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const footer = () => (
    <div className={styles.footer}>
      {step > 0 && <Button onClick={prev}>Ortga</Button>}
      {step !== 2 && <Button onClick={onCancel}>Yopish</Button>}
      {step === 2 && (
        <Button type="primary" onClick={onOk} loading={createPayment.isLoading}>
          Saqlash
        </Button>
      )}
    </div>
  );

  const onAfterOpen = (open: boolean) => {
    if (!open) {
      setAutoAdvance(false);
      form.resetFields();
      dispatch(setPaymentInventoryWorkModal({ visible: false }));
      setStep(0);
    } else {
      setAutoAdvance(true);
    }
  };

  return (
    <Modal
      centered
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      open={visible}
      title={title()}
      footer={footer()}
      width={step === 2 ? "34.5rem" : "64rem"}
      className={styles.payment_step}
      maskClosable={false}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <div className={styles.content}>{steps[step]}</div>
      </Form>
    </Modal>
  );
};

export default PaymentStep;
