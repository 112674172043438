import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../hooks/redux";
import { RootState } from "../../../../../store";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import CheckCircleIcon from "../../../assets/icons/CheckCircleIcon";
import XCircleIcon from "../../../assets/icons/XCircleIcon";
import { useCheckExcess } from "../../../service/mutation";
import { useGetExcessView } from "../../../service/query";
import { DefectStatus } from "../../../utils/constants/DefectStatus";
import { ExcessEnum } from "../../../utils/constants/ExcessEnum";
import { excessOptions } from "../../../utils/constants/excessOptions";
import { ExcessViewModel } from "../../../utils/models/excessViewModel";

import styles from "./excessModal.module.scss";

const ExcessDifferenceTable: React.FC = () => {
  const { user } = useAuth();
  const { data: record } = useAppSelector((state: RootState) => state.transferReducer.excess);

  const { data, isLoading } = useGetExcessView(record?.product.id, record?.transfer_id);

  const checkExcess = useCheckExcess();

  const { data: transfer, isDifference } = useAppSelector((state: RootState) => state.transferReducer.excess);

  const { data: view } = useAppSelector((state: RootState) => state.transferReducer.view);

  const onCheckExcess = (status: string, type: ExcessEnum) => {
    checkExcess.mutate({
      product_id: transfer?.product.id,
      transfer_id: transfer?.transfer_id,
      status,
      type
    });
  };

  const viewCheckAction = () => {
    if (isDifference) {
      return view?.sender_user?.id === user?.id;
    }
  };

  const columns: ColumnsType<ExcessViewModel> = [
    {
      title: "Miqdori",
      dataIndex: "quantity",
      render: (value: number) => `${value} dona`
    },
    {
      title: "Farq turi",
      dataIndex: "type",
      render: (value: ExcessEnum) => <div className={styles.excess__type}>{excessOptions[value]}</div>
    },
    {
      title: "",
      render: (_, record) => (
        <div className={styles.excess__actions}>
          {viewCheckAction() && (
            <span onClick={() => onCheckExcess(DefectStatus.RECIEVED, record.type)}>
              <CheckCircleIcon />
            </span>
          )}
          <span onClick={() => onCheckExcess(!viewCheckAction() ? "deleted" : DefectStatus.REJECTED, record.type)}>
            <XCircleIcon />
          </span>
        </div>
      )
    }
  ];

  return (
    <Table<ExcessViewModel>
      columns={columns}
      dataSource={data}
      pagination={false}
      className={styles.table}
      rowKey={(_, index) => index!}
      loading={{
        spinning: isLoading || checkExcess.isLoading,
        indicator: LoadingIndicator
      }}
      locale={{
        emptyText: <TableEmpty />
      }}
    />
  );
};

export default ExcessDifferenceTable;
