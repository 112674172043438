import { message as toast } from "antd";
import { AxiosError } from "axios";
import i18next from "i18next";
import { ZodError } from "zod";

import { BaseError, ResBaseError } from "modules/common";

export const error = (err: BaseError): void => {
  if (err instanceof ZodError) {
    console.error(err.message);
  } else if (err instanceof AxiosError) {
    let message = "";
    const baseError = err?.response?.data as ResBaseError;

    if (typeof baseError?.message === "object") {
      message = baseError?.message?.[i18next.language];
    } else {
      message = baseError?.message;
    }

    toast.error(message);
  } else {
    // toast.error("Xatolik mavjud");
  }
};
