import React, { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Segmented, Spin } from "antd";
import { SegmentedLabeledOption, SegmentedValue } from "antd/lib/segmented";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";
import ProductSpentWidgetDetailDrawer from "features/projects/components/product-spent-widget-detail-drawer/product-spent-widget-detail-drawer";
import ProductSpentWidgetDrawer from "features/projects/components/product-spent-widget-drawer/product-spent-widget-drawer";
import ProjectReportModal from "features/projects/components/project-report-modal/project-report-modal";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import PlusIcon from "../../../app/assets/icons/PlusIcon";
import ConditionalRender from "../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import NotData from "../../../app/components/not-data/NotData";
import { isEmptyArr } from "../../../app/utils/helpers/isEmptyArr";
import FolderPlusIcon from "../../../settings/assets/icons/FolderPlusIcon";
import ViewTypeCardIcon from "../../assets/icons/ViewTypeCardIcon";
import ViewTypeListIcon from "../../assets/icons/ViewTypeListIcon";
import { ProjectCreateCard } from "../../components/create-project-card/CreateProjectCard";
import EvictionModal from "../../components/eviction-modal/EvictionModal";
import { ProjectCard } from "../../components/project-card/ProjectCard";
import ProjectCardsTable from "../../components/project-cards-table/ProjectCardsTable";
import { ProjectFolderCard } from "../../components/project-folder-card/ProjectFolderCard";
import { ProjectModal } from "../../components/project-modal/ProjectModal";
import ProjectsTable from "../../components/projects-table/ProjectsTable";
import { useGetProjects } from "../../service/queries";
import { routeSubmodules } from "../../utils/constants/routeSubmodules";
import { viewTypes } from "../../utils/constants/viewTypes";

import emptyImage from "../../assets/images/project.png";

import cls from "./projects.module.scss";

export const Projects = () => {
  const { viewType } = useAppSelector(state => state.projectsReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";

  const { data, isLoading } = useGetProjects(
    !isProjectsPage,
    viewType,
    isProjectsPage ? location.pathname.split("/")?.length === 2 : location.pathname.split("/")?.length === 3
  );
  const [creatingFolder, setCreatingFolder] = useState(false);
  const { setProjectModalData, setViewType } = projectsReducerActions;

  const { actions } = useRoutePermissions("Loyiha", routeSubmodules);
  const folderActions = actions("Papkalar");
  const projectActions = actions("Loyihalar");

  const onCreateProject = () => {
    dispatch(setProjectModalData({ visible: true }));
  };

  const onCreateFolder = () => {
    !creatingFolder && setCreatingFolder(true);
  };

  const isEmpty = () => isEmptyArr(data?.project_folders) && isEmptyArr(data?.projects);

  const viewTypeItems: (SegmentedValue | SegmentedLabeledOption)[] = [
    {
      value: viewTypes.CARD,
      label: <ViewTypeCardIcon />
    },
    {
      value: viewTypes.LIST,
      label: <ViewTypeListIcon />
    }
  ];

  const onChangeViewType = (value: SegmentedValue) => {
    dispatch(setViewType(value as string));
  };

  const viewCardContent = useMemo(() => {
    const allContents = {
      [viewTypes.CARD]: (
        <div className={cls.folders__body}>
          {!isEmptyArr(data?.project_folders) &&
            data?.project_folders.map(item => <ProjectFolderCard key={item.id} {...item} />)}
          {creatingFolder && isProjectsPage && (
            <ProjectFolderCard isCreating={creatingFolder} setIsCreating={setCreatingFolder} />
          )}
          {folderActions.create && isProjectsPage && !creatingFolder && (
            <div className={cls.new_folder} onClick={onCreateFolder}>
              <FolderPlusIcon />
              <span>{t("project.Papka qo'shish")}</span>
            </div>
          )}
        </div>
      ),
      [viewTypes.LIST]: (
        <ProjectCardsTable
          data={data}
          isLoading={isLoading}
          isCreating={creatingFolder}
          setIsCreating={setCreatingFolder}
          folderActions={folderActions}
          isProjectsPage={isProjectsPage}
          onCreateFolder={onCreateFolder}
        />
      )
    };

    return allContents[viewType ?? viewTypes.CARD];
  }, [creatingFolder, data, isLoading, viewType]);

  const viewProjectContent = {
    [viewTypes.CARD]: !isEmpty() && (
      <div className={cls.projects__body}>
        {!isEmptyArr(data?.projects) && data?.projects.map(item => <ProjectCard key={item.id} {...item} />)}
        {projectActions.create && isProjectsPage && <ProjectCreateCard />}
      </div>
    ),
    [viewTypes.LIST]: <ProjectsTable data={data?.projects} isLoading={isLoading} />
  };

  return (
    <div className={cls.page}>
      <div className={cls.page__body} style={{ padding: isProjectsPage ? "1rem" : 0 }}>
        {folderActions.view && (
          <div className={cls.folders}>
            <div className={cls.view_type}>
              {(!isEmptyArr(data?.project_folders) || creatingFolder || !isEmptyArr(data?.projects)) && (
                <>
                  <p className={cls.folders__title}>{t("project.Papkalar")}</p>
                  {(!isEmptyArr(data?.project_folders) || !isEmptyArr(data?.projects)) && (
                    <Segmented options={viewTypeItems} onChange={onChangeViewType} value={viewType} />
                  )}
                </>
              )}
            </div>
            <ConditionalRender
              if={!isEmptyArr(data?.project_folders) || !isEmptyArr(data?.projects) || creatingFolder}
              // else={<TableEmpty description={t("project.Loyiha papkalari mavjud emas")} />}
            >
              {viewCardContent}
            </ConditionalRender>
          </div>
        )}
        <div className={cls.projects}>
          <div className={cls.projects__title}>
            <p>{t("project.Loyihalar")}</p>
          </div>
          {viewProjectContent[viewType ?? viewTypes.CARD]}
        </div>
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {isEmptyArr(data?.project_folders) && isEmptyArr(data?.projects) && !creatingFolder && (
            <NotData
              containerClassName={cls.empty__container}
              img={{
                src: emptyImage,
                className: cls.empty_image
              }}
              name={{ text: t("project.Loyihalar katalogi mavjud emas") }}
              text={{
                text: t("project.Ushbu loyihalar katologini qo’shish orqali loyihalaringizni boshqaring")
              }}
              defaultButton={
                folderActions.create && isProjectsPage
                  ? {
                      text: t("project.Papka yaratish"),
                      onClick: onCreateFolder,
                      icon: <FolderPlusIcon />
                    }
                  : undefined
              }
              primaryButton={
                projectActions.create && isProjectsPage
                  ? {
                      text: t("project.Loyiha qo'shish"),
                      onClick: onCreateProject,
                      icon: <PlusIcon color="#fff" />
                    }
                  : undefined
              }
            />
          )}
        </Spin>
      </div>
      <ProjectModal />
      <EvictionModal />
      <ProjectReportModal />
      <ProductSpentWidgetDrawer />
      <ProductSpentWidgetDetailDrawer />
      <IncomeExpenseView />
    </div>
  );
};
