import { Button, Checkbox, Dropdown } from "antd";
import PlusCircleIcon from "../../../../../app/assets/icons/PlusCircleIcon";
import styles from "./customDropdown.module.scss";

const CustomDropdown = () => {
  const customDropdown = () => (
    <div className={styles.dropdown}>
      <div className={styles.checkbox__group}>
        <Checkbox className={styles.checkbox}>Naqd</Checkbox>
        <Checkbox className={styles.checkbox}>Naqd</Checkbox>
        <Checkbox className={styles.checkbox}>Naqd</Checkbox>
        <Checkbox className={styles.checkbox}>Naqd</Checkbox>
      </div>
      <div className={styles.dropdown__footer}>
        <Button type="primary">Saqlash</Button>
      </div>
    </div>
  );

  return (
    <Dropdown dropdownRender={customDropdown} trigger={["click"]}>
      <div className={styles.actions__add_btn}>
        <PlusCircleIcon fill />
        To‘lov turini biriktirish
      </div>
    </Dropdown>
  );
};

export default CustomDropdown;
