import React from "react";
import { Tabs } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { useSelector } from "store";

import { ProjectByResourceViewDataTabCount } from "modules/dashboard";
import { useGetProjectByResourceDatTabCount } from "modules/dashboard/queries/use-get-project-by-resource-count";
import { useProjectByResourceView } from "modules/dashboard/queries/use-project-by-resource-view";

import TabContent from "./tab-content/tab-content";

import styles from "./content.module.scss";

const Content: React.FC = () => {
  const { queries, append } = useQueryParams();

  const { t, i18n } = useTranslation();

  const { tab } = queries();

  const { props, visible } = useSelector(state => state.productSpentWidgetDrawerReducer);
  const { data } = useSelector(state => state.projectReportModalReducer);

  const { projectByResourceView, isLoading } = useProjectByResourceView({
    enabled: visible,
    project_id: Number(data?.id),
    resource_type: props?.data?.data[0]?.type
  });

  const { countData } = useGetProjectByResourceDatTabCount({ enabled: visible, project_id: Number(data?.id) });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const items = props?.data?.data?.map(el => ({
    key: `${el?.id}-${el?.type === null ? "another" : el?.type}`,
    label: (
      <p className={styles.tab}>
        {el.name[i18n.language] as string}
        {countData &&
          (countData[el?.type as keyof ProjectByResourceViewDataTabCount] > 0 || countData?.another > 0) && (
            <span className={styles.tab__count}>
              {el?.type ? countData[el?.type as keyof ProjectByResourceViewDataTabCount] : countData?.another}
            </span>
          )}
      </p>
    ),
    children: <TabContent data={projectByResourceView} isLoading={isLoading} />
  }));

  return (
    <div className={styles.bar}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab} items={items} onChange={onChangeTabs} />
      </div>
    </div>
  );
};

export default Content;
