export const queryParamsKeys = {
  ID: "id",
  PAGE: "page",
  SIZE: "size",
  SEARCH: "search",
  PRODUCT_SEARCH: "product_search",
  GENERAL_SEARCH: "general_search",
  TYPE: "type",
  TYPE_ARR: "type[]",
  TYPES: "types",
  TYPES_ARR: "types[]",
  TAB: "tab",
  WAREHOUSE_ID: "warehouse_id",
  CURRENCY: "currency",
  DATE: "date",
  STATISTICS_TYPE: "statistics_type",
  CURRENCY_ID: "currency_id",
  MIN_DATE: "min_date",
  MAX_DATE: "max_date",
  CASH_IDS: "cash_ids",
  CASH_IDS_ARR: "cash_ids[]",
  PAYMENT_TYPE_IDS: "payment_type_ids",
  PAYMENT_TYPE_IDS_ARR: "payment_type_ids[]",
  PAYMENT_TYPE: "payment_type",
  PROJECT_IDS: "project_ids",
  PROJECT_IDS_ARR: "project_ids[]",
  STATUS: "status",
  STATUS_IDS: "status_ids",
  STATUS_IDS_ARR: "status_ids[]",
  COMPANY_PERSON_IDS: "company_person_ids",
  COMPANY_PERSON_IDS_ARR: "company_person_ids[]",
  IS_DYNAMIC: "isDynamic",
  ESTIMATE_TYPE: "estimate_type",
  FINANCIAL_IDS: "financial_ids",
  FINANCIAL_IDS_ARR: "financial_ids[]",
  USER_IDS: "user_ids",
  USER_IDS_ARR: "user_ids[]",
  STATUSES: "statuses",
  STATUSES_ARR: "statuses[]",
  SECTION_IDS: "section_ids",
  SECTION_IDS_ARR: "section_ids[]",
  CREATORS_IDS: "creator_ids",
  CREATORS_IDS_ARR: "creator_ids[]",
  VIEW_TYPE: "view_type",
  WAREHOUSE_IDS: "warehouse_ids",
  WAREHOUSE_IDS_ARR: "warehouse_ids[]",
  CURRENCIES_IDS: "currencies_ids",
  CURRENCIES_IDS_ARR: "currencies_ids[]",
  START_PRICE: "start_price",
  END_PRICE: "end_price",
  INVENTORY_STATUS: "inventory_status",
  INVENTORY_IDS: "inventory_ids",
  INVENTORY_IDS_ARR: "inventory_ids[]",
  COUNTERPARTS_TYPE: "counterpart_type",
  MIN_START_DATE: "min_start_date",
  MAX_START_DATE: "max_start_date",
  MIN_DUE_DATE: "min_due_date",
  MAX_DUE_DATE: "max_due_date",
  TASK_STATUSES: "task_statuses",
  TASK_STATUSES_ARR: "task_statuses[]",
  WORK_STATUS: "work_status",
  MIN_CREATED_AT_DEFECT: "min_created_at_defect",
  MAX_CREATED_AT_DEFECT: "max_created_at_defect",
  CONFIRMATION_USER_IDS: "confirmation_user_ids",
  CONFIRMATION_USER_IDS_ARR: "confirmation_user_ids[]",
  MIN_AMOUNT: "min_amount",
  MAX_AMOUNT: "max_amount",
  SENDER_WAREHOUSE_IDS: "sender_warehouse_ids",
  SENDER_WAREHOUSE_IDS_ARR: "sender_warehouse_ids[]",
  RECIEVED_WAREHOUSE_IDS: "recieved_warehouse_ids",
  RECIEVED_WAREHOUSE_IDS_ARR: "recieved_warehouse_ids[]",
  MIN_DELIVERY_DATE: "min_delivery_date",
  MAX_DELIVERY_DATE: "max_delivery_date",
  AGENT_IDS: "agent_ids",
  AGENT_IDS_ARR: "agent_ids[]",
  CONFIRMATION_PAYMENTS: "confirmation_payments",
  CONFIRMATION_PAYMENTS_ARR: "confirmation_payments[]",
  PAYMENT_STATUSES: "payment_statuses",
  PAYMENT_STATUSES_ARR: "payment_statuses[]",
  MIN_ORDERED_DATE: "min_ordered_date",
  MAX_ORDERED_DATE: "max_ordered_date",
  MIN_TOTAL_AMOUNT: "min_total_amount",
  MAX_TOTAL_AMOUNT: "max_total_amount",
  BALANCE_TOTAL_AMOUNT_TYPE: "balance_total_amount_type",
  FILTER_TYPE: "filter_type",
  SORT_DELIVERY_DATE: "sort_delivery_date",
  SORT_CREATED_AT: "sort_created_at",
  SORT_ORDERED_DATE: "sort_ordered_date",
  SORT_QUANTITY: "sort_quantity",
  SORT_UNIT: "sort_unit",
  SORT_ORDER: "sort_order",
  SORT_WAREHOUSE: "sort_warehouse",
  SORT_PROJECT: "sort_project",
  SORT_STATUS: "sort_status",
  SORT_ID: "sort_id",
  SORT_PAYMENT_DATE: "sort_payment_date",
  SORT_AGENT: "sort_agent",
  TABLE_KEY: "table_key",
  SORT_DATE: "sort_date",
  SORT_AMOUNT: "sort_amount",
  SORT_CASH_NAME: "sort_cash_name",
  SORT_PROJECT_NAME: "sort_project_name",
  SORT_FINANCIAL_NAME: "sort_financial_name",
  SORT_COMPANY_PERSON_NAME: "sort_company_person_name",
  SORT_AGENT_FULL_NAME: "sort_agent_full_name",
  SORT: "sort",
  SORT_PERCENT: "sort_percent",
  SORT_PAYMENT_PERCENT: "sort_payment_percent",
  ESTIMATE_GROUP: "estimate_group",
  SORT_CUSTOM_FIELDS: "sort_custom_fields",
  FILE_ARR: "file[]",
  FULLSCREEN: "fullscreen",
  PRODUCT_ID: "product_id",
  UNIT_ID: "unit_id",
  TEMPLATE_SEARCH: "template_search",
  TEMPLATE_SUB_SEARCH: "template_sub_search",
  IS_ARCHIVED: "is_archived",
  AVAILABILITY: "availability",
  MIN_PAYMENT_DATE: "min_payment_date",
  MAX_PAYMENT_DATE: "max_payment_date",
  MIN_COMPLETED_DATE: "min_completed_date",
  MAX_COMPLETED_DATE: "max_completed_date",
  SORT_TOTAL_AMOUNT: "sort_total_amount",
  SORT_DEBT_AMOUNT: "sort_debt_amount",
  SORT_CREDIT_AMOUNT: "sort_credit_amount",
  CHECK_CURRENCY: "check_currency",
  CUSTOM_FIELDS: "custom_field_option_ids",
  CUSTOM_FIELDS_ARR: "custom_field_option_ids[]",
  INCOME_FINANCIAL_IDS_ARR: "income_financial_ids[]",
  EXPENSE_FINANCIAL_IDS_ARR: "expense_financial_ids[]",
  INCOME_FINANCIAL_IDS: "income_financial_ids",
  EXPENSE_FINANCIAL_IDS: "expense_financial_ids"
};
