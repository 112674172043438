import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../../../../../../../../../hooks/redux";
import { setGanttTableSetting } from "../../../../../../../../../../../../store/reducers/ganttReducer";
import styles from "./ganttTableHeaderResizeItem.module.scss";

interface IProps {
  className?: string;
  rootName: string;
  maxWidth?: number;
}

const GanttTableHeaderResizeItem = ({
  className = "",
  rootName,
  maxWidth = 100,
}: IProps) => {
  const dispatch = useAppDispatch();

  const [position, setPosition] = useState({
    isDrag: false,
    startClientX: 0,
  });

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPosition((prev) => ({
      ...prev,
      isDrag: true,
      startClientX: e.clientX,
    }));
  };

  useEffect(() => {
    const getWidth = Number(
      getComputedStyle(document.documentElement)
        .getPropertyValue(rootName)
        .replace(/[^0-9]/g, "")
    );

    const windowMouseMove = (e: MouseEvent) => {
      if (position.isDrag) {
        const x = e.clientX - position.startClientX;

        if (getWidth + x > 96) {
          if (maxWidth && getWidth + x < maxWidth) {
            document.documentElement.style.setProperty(
              rootName,
              `${getWidth + x}px`
            );
          }
        }
      }
    };

    const windowMouseUp = () => {
      if (position.isDrag) {
        setPosition((prev) => ({
          ...prev,
          isDrag: false,
        }));
        const getWidth = Number(
          getComputedStyle(document.documentElement)
            .getPropertyValue(rootName)
            .replace(/[^0-9]/g, "")
        );

        dispatch(
          setGanttTableSetting({
            [rootName]: getWidth,
          })
        );
      }
    };

    window.addEventListener("mousemove", windowMouseMove);
    window.addEventListener("mouseup", windowMouseUp);

    return () => {
      window.removeEventListener("mousemove", windowMouseMove);
      window.removeEventListener("mouseup", windowMouseUp);
    };
  }, [position.isDrag]);

  return (
    <div
      className={`${styles.container} ${className}`}
      onMouseDown={onMouseDown}
    ></div>
  );
};

export default GanttTableHeaderResizeItem;
