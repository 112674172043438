import { UserTableDataModel } from "../../../app/utils/models/user/userTableConfigModel";

export const paymentIncomeOrExpenseTableDefaultData: UserTableDataModel = {
  column: [
    {
      id: "type",
      name: "type",
      title: "Turi",
      checked: true
    },
    {
      id: "date",
      name: "date",
      title: "Sana",
      checked: true
    },
    {
      id: "amount",
      name: "amount",
      title: "Summa",
      checked: true
    },

    {
      id: "cash.name",
      name: "cash.name",
      title: "Kassa",
      checked: true
    },
    {
      id: "project.name",
      name: "project.name",
      title: "Loyiha",
      checked: true
    },
    {
      id: "payment_type.name",
      name: "payment_type.name",
      title: "To'lov turi",
      checked: true
    },
    {
      id: "user.full_name",
      name: "user.full_name",
      title: "Manba",
      checked: true
    },
    {
      id: "creator.full_name",
      name: "creator.full_name",
      title: "Xodim",
      checked: true
    },
    {
      id: "description",
      name: "description",
      title: "Izoh",
      checked: false
    }
  ],
  size: 10
};
