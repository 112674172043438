import { FC, useEffect, useState } from "react";
import { Form, FormInstance, Tooltip } from "antd";

import { useAppSelector } from "../../../../../hooks/redux";
import { AppendEmployee } from "../../../../app/components/append-employee/AppendEmployee";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { UserSelectModel } from "../../../../app/utils/models/user/userSelectModel";
import AvatarAddIcon from "../../../assets/icons/AvatarAddIcon";
import { DetailedProjectModel } from "../../../utils/models/detailedProjectModel";
import { ProjectFormFieldsModel } from "../../../utils/models/projectFormFieldsModel";

import styles from "../projectModal.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  formInstance: FormInstance<ProjectFormFieldsModel>;
  data: DetailedProjectModel | undefined;
};

type SetUserType = (data: UserSelectModel | UserSelectModel[] | undefined) => undefined;

export enum EmplyoyeeTypes {
  MANAGER = "manager",
  FOREMAN = "foreman",
  PARTICIPANT = "participant"
}

export const ProjectParticipants: FC<Props> = ({ formInstance, data }) => {
  const { t } = useTranslation()
  const [manager, setManager] = useState<UserSelectModel[]>([]);
  const [foreman, setForeman] = useState<UserSelectModel[]>([]);
  const [participant, setParticipant] = useState<UserSelectModel[]>([]);
  const { visible } = useAppSelector(state => state.projectsReducer.projectModalData);

  useEffect(() => {
    if (data && visible) {
      setManager(data?.project_users?.filter(el => el?.type === EmplyoyeeTypes.MANAGER));
      setForeman(data?.project_users?.filter(el => el?.type === EmplyoyeeTypes.FOREMAN));
      setParticipant(data?.project_users?.filter(el => el?.type === EmplyoyeeTypes.PARTICIPANT));
    }

    if (!visible) {
      setForeman([]);
      setManager([]);
      setParticipant([]);
    }

    return () => {
      setForeman([]);
      setManager([]);
    };
  }, [data, visible]);

  return (
    <div className={styles.participants}>
      <AppendEmployee
        name="manager_ids"
        data={manager}
        setData={setManager as SetUserType}
        formInstance={formInstance}
        title={t("project.Loyiha rahbari")}
        description={t("project.Loyiha rahbari yo'q,iltimos yangi yarating")}
      >
        <Form.Item name="manager_ids">
          <div className={styles.container}>
            <span>{t("project.Loyiha rahbari")}</span>
            <div className={styles.participant_container}>
              <div className={styles.append}>
                <Tooltip title={t("project.Qo'shish")}>
                  <AvatarAddIcon />
                </Tooltip>
              </div>
              {manager?.length > 0 && (
                <div className={styles.box}>
                  {manager.slice(0, 3)?.map(el => (
                    <div key={el?.id} className={styles.participant}>
                      {el?.image ? (
                        <img src={el?.image} alt={el?.full_name} />
                      ) : (
                        <NotUserImage name={el?.full_name} isTooltip={false} />
                      )}
                    </div>
                  ))}
                  {manager && manager?.length > 3 && (
                    <div className={styles.participant}>
                      <NotUserImage isTooltip={false} title={`+${manager.length - 3}`} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Form.Item>
      </AppendEmployee>
      <AppendEmployee
        name="foreman_ids"
        data={foreman}
        setData={setForeman as SetUserType}
        formInstance={formInstance}
        title={t("project.Prorab")}
        description={t("project.Prorab yo'q,iltimos yangi yarating")}
      >
        <Form.Item name="foreman_ids">
          <div className={styles.container}>
            <span>{t("project.Prorab")}</span>

            <div className={`${styles.participant_container}`}>
              <div className={styles.append}>
                <Tooltip title={t("project.Qo'shish")}>
                  <AvatarAddIcon />
                </Tooltip>
              </div>
              {foreman?.length > 0 && (
                <div className={styles.box}>
                  {foreman.slice(0, 3)?.map(el => (
                    <div key={el?.id} className={styles.participant}>
                      {el?.image ? (
                        <img src={el?.image} alt={el?.full_name} />
                      ) : (
                        <NotUserImage name={el?.full_name} isTooltip={false} />
                      )}
                    </div>
                  ))}
                  {foreman && foreman?.length > 3 && (
                    <div className={styles.participant}>
                      <NotUserImage isTooltip={false} title={`+${foreman.length - 3}`} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Form.Item>
      </AppendEmployee>
      <AppendEmployee
        name="participant_ids"
        data={participant}
        setData={setParticipant as SetUserType}
        formInstance={formInstance}
        title={t("project.Boshqa ishtirokchilar")}
        description={t("project.Boshqa ishtirokchilar yo'q,iltimos yangi yarating")}
      >
        <Form.Item name="participant_ids">
          <div className={styles.container}>
            <span>{t("project.Boshqa ishtirokchilar")}</span>

            <div className={`${styles.participant_container}`}>
              <div className={styles.append}>
                <Tooltip title={t("project.Qo'shish")}>
                  <AvatarAddIcon />
                </Tooltip>
              </div>
              {participant?.length > 0 && (
                <div className={styles.box}>
                  {participant.slice(0, 3)?.map(el => (
                    <div key={el?.id} className={styles.participant}>
                      {el?.image ? (
                        <img src={el?.image} alt={el?.full_name} />
                      ) : (
                        <NotUserImage name={el?.full_name} isTooltip={false} />
                      )}
                    </div>
                  ))}
                  {participant && participant?.length > 3 && (
                    <div className={styles.participant}>
                      <NotUserImage isTooltip={false} title={`+${participant.length - 3}`} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </Form.Item>
      </AppendEmployee>
    </div>
  );
};
