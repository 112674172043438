import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { CustomFieldSelectModel } from "../../../utils/models/customFieldSelectModel";

type Props = {
  item: CustomFieldSelectModel;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingFieldSelect: React.FC<Props> = ({ item: propsField, setData }) => {
  const { i18n } = useTranslation();

  const onChange = (e: number) => {
    setData(old =>
      old?.map(item => ({
        // @ts-ignore
        ...item,
        // @ts-ignore
        custom_field_values: item?.custom_field_values?.map(field => ({
          ...field,
          value: field?.custom_field_id === propsField?.id ? e : field?.value
        }))
      }))
    );
  };

  return (
    <Select placeholder="Tanlang" onChange={onChange} suffixIcon={<SelectSuffixIcon />}>
      {propsField?.custom_field_options?.map(i => (
        <Select.Option key={i?.id} value={i?.id}>
          {i?.name[i18n.language]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GeneralEditingFieldSelect;
