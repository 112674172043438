import { lazy, Suspense, useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";

import { SectionModel } from "../../../../utils/models/sectionModel";

import { SectionHeader } from "./header/SectionHeader";

const SectionBody = lazy(() => import("./body/SectionBody"));

type Props = {
  item: SectionModel;
  expandedAll: boolean;
  isSearch: boolean;
};

const Section = ({ item, expandedAll, isSearch }: Props) => {
  const { id, name, place, total_amount, plan, tasks } = item;
  const [active, setActive] = useState(false);

  const handleSection = () => {
    setActive(!active);
  };

  useEffect(() => {
    setActive(expandedAll);
  }, [expandedAll]);

  useEffect(() => {
    if (tasks?.length > 0) {
      setActive(true);
    }
  }, [tasks]);

  const suspenseLoader = (
    <div className="flex w-full items-center justify-center">
      <Spin indicator={LoadingIndicator} />
    </div>
  );

  return (
    <>
      <div>
        <SectionHeader
          id={id}
          name={name}
          data={item}
          plan={plan}
          place={place}
          active={active}
          totalAmount={total_amount}
          handleSection={handleSection}
        />
      </div>
      {active && (
        <Suspense fallback={suspenseLoader}>
          <SectionBody plan={plan} place={place} tasks={tasks} sectionId={id} active={active} isSearch={isSearch} />
        </Suspense>
      )}
    </>
  );
};

export default Section;
