import { FC } from "react";
import { Spin } from "antd";

import { LoadingIndicator } from "../../../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetTaskProducts } from "../../../../../service/queries";
import { TaskProductModel } from "../../../../../utils/models/taskProductModel";
import TaskProduct from "../product/TaskProduct";

type Props = {
  task_id: number;
  active: boolean;
  sectionPlan: boolean;
  taskPlan: boolean;
  products: TaskProductModel[];
  isSearch: boolean;
};

export const TaskBody: FC<Props> = ({ task_id, active, sectionPlan, taskPlan, products, isSearch }) => {
  const { data, isLoading } = useGetTaskProducts(task_id, active, products);

  return (
    <>
      {isSearch && products?.length > 0 ? (
        products?.map(item => (
          <TaskProduct
            key={item.id}
            data={item}
            active={active}
            task_id={task_id}
            sectionPlan={sectionPlan}
            taskPlan={taskPlan}
            isSearch
          />
        ))
      ) : (
        <Spin spinning={isLoading} indicator={LoadingIndicator}>
          {data?.map(item => (
            <TaskProduct
              key={item.id}
              data={item}
              active={active}
              task_id={task_id}
              sectionPlan={sectionPlan}
              taskPlan={taskPlan}
            />
          ))}
        </Spin>
      )}
    </>
  );
};
