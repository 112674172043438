import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import { useQueryParams } from "../../../../../../../../../hooks/useQueryParams";
import { queryParamsKeys } from "../../../../../../../../app/utils/constants/queryParamsKeys";
import $api from "../../../../../../../../app/utils/helpers/axiosInstance";
import { CompanyPersonModel } from "../../../../../../../../app/utils/models/companyPersonSelect";
import { UserSelectModel } from "../../../../../../../../app/utils/models/user/userSelectModel";
import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { GanttSection, GanttSectionData } from "../utils/models/GanttSection";

export function useGanttSection(project_id?: number) {
  const { reqQueryParam, searchParams } = useQueryParams();
  const params = useParams();
  const id = project_id || Number(params?.projectId);

  let url = endPoints.GANTT_SECTION(id);

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.GENERAL_SEARCH,
      queryParamsKeys.MAX_DUE_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.MAX_START_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.SECTION_IDS_ARR,
      queryParamsKeys.TASK_STATUSES_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR
    )}`;
  }
  return useQuery<GanttSectionData>(
    [queryKeys.GANTT_SECTION, id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGanttTask(id: number | null, enabled?: boolean) {
  const { reqQueryParam, searchParams } = useQueryParams();
  let url = endPoints.GANTT_TASK(id || 0);

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.GENERAL_SEARCH,
      queryParamsKeys.MAX_DUE_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.MAX_START_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.SECTION_IDS_ARR,
      queryParamsKeys.TASK_STATUSES_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR
    )}`;
  }
  return useQuery<GanttSection[]>(
    [queryKeys.GANTT_TASK, id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled: !!enabled && !!id,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGanttSearchSection() {
  const { reqQueryParam, searchParams } = useQueryParams();

  const params = useParams();
  const id = Number(params?.projectId);

  let url = endPoints.GANTT_SEARCH_SECTION(id);

  if (searchParams && searchParams?.length > 0) {
    url += `&${reqQueryParam(
      queryParamsKeys.GENERAL_SEARCH,
      queryParamsKeys.MAX_DUE_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.MAX_START_DATE,
      queryParamsKeys.MIN_DUE_DATE,
      queryParamsKeys.SECTION_IDS_ARR,
      queryParamsKeys.TASK_STATUSES_ARR,
      queryParamsKeys.USER_IDS_ARR,
      queryParamsKeys.COMPANY_PERSON_IDS_ARR
    )}`;
  }

  const enabled = !!(id && searchParams && searchParams?.length > 0);

  return useQuery<GanttSectionData>(
    [queryKeys.GANTT_SEARCH_SECTION, id, url],
    async () => {
      const res = await $api.get(url);

      return res.data.data;
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}

export function useGetUsersSelect() {
  return useQuery<UserSelectModel[]>(
    ["user-select"],
    async () => {
      const res = await $api.get(`admin/select`);

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    }
  );
}

export function useGetCompanyPersons() {
  return useQuery<CompanyPersonModel[]>(
    ["gantt-company-persons"],
    async () => {
      const res = await $api.get("company-person/select");

      return res.data.data;
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    }
  );
}
