import React from "react";

import TransferNoDataIcon from "../../../../assets/icons/TransferNoDataIcon";

import styles from "./transferNoData.module.scss";

const TransferNoData: React.FC = () => {
  return (
    <div className={styles.container}>
      <TransferNoDataIcon />
      <span>
        <h1>O'tkazma mahsulotlari mavjud emas</h1>
        <p>Omborxona tanlang va o'tkazma qilishni boshlang</p>
      </span>
    </div>
  );
};

export default TransferNoData;
