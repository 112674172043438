import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker, Modal } from "antd";

import ConfirmPartyAgentSelect from "./ConfirmPartyAgentSelect";
import { ConfirmPartyModel } from "../../../../utils/models/OfferFormModel";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { useAppDispatch, useAppSelector } from "../../../../../../hooks/redux";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import { supplyOfferActions } from "../../../../../../store/reducers/supplyOfferReducer";

import styles from "./confirmPartyModal.module.scss";

type Props = {
  onFinish: () => void;
  isLoading?: boolean;
};

const ConfirmPartyModal: React.FC<Props> = ({ onFinish, isLoading }) => {
  const dispatch = useAppDispatch();
  const { setConfirmPartyModalItem } = supplyOfferActions;
  const { visible, deliveryDate, paymentDate } = useAppSelector(
    (state) => state.supplyOfferReducer.offerModal.partyModal
  );

  const onChangeDate = (e: string | string[], key: keyof ConfirmPartyModel) => {
    dispatch(
      setConfirmPartyModalItem({
        key,
        value: dayjs(e as string, dayjsFormats.DATE) as never,
      })
    );
  };

  const onCancel = () => {
    dispatch(
      setConfirmPartyModalItem({
        key: "visible",
        value: false as never,
      })
    );
  };

  return (
    <Modal
      centered
      open={visible}
      okText="Saqlash"
      onOk={onFinish}
      onCancel={onCancel}
      cancelText="Yopish"
      title="Partiya qilib saqlash"
      className={styles.party_modal}
      okButtonProps={{ loading: isLoading }}
    >
      <div className={styles.content}>
        <ConfirmPartyAgentSelect />
        <div className={styles.item}>
          <label>Yetkazish sanasi</label>
          <DatePicker
            value={deliveryDate}
            placeholder="Tanlang"
            format={dayjsFormats.DATE}
            onChange={(_, dateString) => onChangeDate(dateString, "deliveryDate")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </div>
        <div className={styles.item}>
          <label>To'lash sanasi</label>
          <DatePicker
            value={paymentDate}
            placeholder="Tanlang"
            format={dayjsFormats.DATE}
            onChange={(_, dateString) => onChangeDate(dateString, "paymentDate")}
            suffixIcon={<DatepickerSuffixIcon />}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPartyModal;
