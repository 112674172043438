import React from "react";

const NotificationNotDataIcon: React.FC = () => {
  return (
    <svg
      width="152"
      height="146"
      viewBox="0 0 152 146"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="74.9358" cy="82.5823" r="62.471" fill="#EFF8FF" />
      <circle cx="16.3804" cy="40.4019" r="3.91556" fill="#EFF8FF" />
      <circle cx="117.653" cy="24.9163" r="7.29718" fill="#EFF8FF" />
      <circle cx="17.6268" cy="122.806" r="6.94122" fill="#EFF8FF" />
      <circle cx="141.857" cy="116.043" r="6.94122" fill="#EFF8FF" />
      <circle cx="128.331" cy="123.874" r="3.38162" fill="#EFF8FF" />
      <path
        d="M85.8428 30.0151C85.526 29.9477 85.526 29.4956 85.8428 29.4282L88.4159 28.881C88.5314 28.8565 88.6217 28.7665 88.6467 28.6512L89.202 26.0903C89.2704 25.775 89.72 25.775 89.7884 26.0903L90.3437 28.6512C90.3687 28.7665 90.459 28.8565 90.5745 28.881L93.1476 29.4282C93.4644 29.4956 93.4644 29.9477 93.1476 30.0151L90.5745 30.5623C90.459 30.5868 90.3687 30.6768 90.3437 30.7921L89.7884 33.353C89.72 33.6682 89.2704 33.6682 89.202 33.353L88.6467 30.7921C88.6217 30.6768 88.5314 30.5868 88.4159 30.5623L85.8428 30.0151Z"
        fill="#B2DDFF"
      />
      <path
        d="M9.15479 85.9982C9.27707 85.4205 10.1017 85.4205 10.224 85.9982L11.202 90.6189C11.2465 90.8293 11.4102 90.9939 11.6203 91.0397L16.2092 92.0394C16.7827 92.1643 16.7827 92.9823 16.2092 93.1072L11.6203 94.1069C11.4102 94.1526 11.2465 94.3173 11.202 94.5276L10.224 99.1484C10.1017 99.7261 9.27707 99.7261 9.15479 99.1484L8.17673 94.5276C8.13221 94.3173 7.9685 94.1526 7.75845 94.1069L3.16956 93.1072C2.59607 92.9823 2.59606 92.1643 3.16955 92.0394L7.75845 91.0397C7.9685 90.9939 8.13221 90.8293 8.17673 90.6189L9.15479 85.9982Z"
        fill="#B2DDFF"
      />
      <path
        d="M36.0165 11.5476C36.0845 11.2266 36.5426 11.2266 36.6105 11.5476L37.1538 14.1145C37.1786 14.2313 37.2695 14.3228 37.3862 14.3482L39.9355 14.9036C40.2541 14.973 40.2541 15.4274 39.9355 15.4968L37.3862 16.0521C37.2695 16.0776 37.1786 16.1691 37.1538 16.2859L36.6105 18.8528C36.5426 19.1738 36.0845 19.1738 36.0165 18.8528L35.4732 16.2859C35.4485 16.1691 35.3575 16.0776 35.2408 16.0521L32.6916 15.4968C32.373 15.4274 32.373 14.973 32.6916 14.9036L35.2408 14.3482C35.3575 14.3228 35.4485 14.2313 35.4732 14.1145L36.0165 11.5476Z"
        fill="#B2DDFF"
      />
      <path
        d="M130.581 42.9279C130.703 42.3502 131.528 42.3502 131.65 42.9279L132.132 45.2066C132.177 45.4169 132.341 45.5816 132.551 45.6273L134.831 46.1241C135.404 46.249 135.404 47.067 134.831 47.1919L132.551 47.6887C132.341 47.7344 132.177 47.8991 132.132 48.1094L131.65 50.3881C131.528 50.9658 130.703 50.9658 130.581 50.3881L130.099 48.1094C130.054 47.8991 129.89 47.7344 129.68 47.6887L127.4 47.1919C126.827 47.067 126.827 46.249 127.4 46.1241L129.68 45.6273C129.89 45.5816 130.054 45.4169 130.099 45.2066L130.581 42.9279Z"
        fill="#B2DDFF"
      />
      <path
        d="M145.117 94.625L146.208 99.7748L151.284 100.881L146.208 101.987L145.117 107.136L144.027 101.987L138.951 100.881L144.027 99.7748L145.117 94.625Z"
        fill="white"
      />
      <path
        d="M67.9593 21.5824C69.0492 25.6036 66.5092 29.8809 62.1525 31.0617C57.7959 32.2425 53.4436 29.8333 52.3537 25.8121C51.2638 21.7909 53.8038 17.5137 58.1605 16.3328C62.5171 15.152 66.8694 17.5612 67.9593 21.5824Z"
        fill="#B2DDFF"
        stroke="#B2DDFF"
        strokeWidth="2"
      />
      <path
        d="M57.4166 23.7369L60.0784 22.6602L65.0421 21.3148C68.8174 20.2916 72.7793 20.1638 76.6127 20.9415C78.0368 21.2305 79.4333 21.6424 80.7862 22.1728L85.8133 24.1432L88.8131 25.7609C91.0873 26.9874 93.207 28.4811 95.1269 30.2104C97.3357 32.1998 99.2607 34.4832 100.848 36.9967L103.718 41.5418L109.357 53.6765L113.248 62.2512L119.401 72.425L124.167 80.3722L127.81 85.6005C128.924 87.1991 130.591 88.3284 132.489 88.7703C133.919 89.1034 135.228 89.8297 136.268 90.8672L138.067 92.6628C138.313 92.9075 138.534 93.1747 138.729 93.4611C139.882 95.1547 139.999 97.3488 139.033 99.1555L138.911 99.3843C138.269 100.584 137.234 101.527 135.98 102.054L127.486 105.628L114.611 110.158L91.9285 116.867L78.9958 120.372L60.8437 124.732L44.6453 128.081L35.6503 129.294C34.0984 129.503 32.5399 128.959 31.4549 127.83C30.4717 126.807 29.9793 125.409 30.1047 123.995L30.2515 122.342C30.4019 120.646 31.2413 119.087 32.5735 118.028C33.884 116.986 34.7185 115.459 34.8879 113.794L35.7086 105.724L35.7086 95.109L34.3631 80.5092L33.6481 70.1628L33.2748 65.7405C33.0516 63.0956 33.0441 60.437 33.2525 57.7909L33.3499 56.554C33.585 53.5685 34.1691 50.6207 35.0904 47.7711C35.9158 45.2179 37.0069 42.7585 38.346 40.4332L38.7332 39.7607C40.0291 37.5103 41.5631 35.4059 43.3089 33.4836C45.106 31.5049 47.1165 29.7312 49.3038 28.1949L49.5775 28.0026C52.0198 26.2872 54.6498 24.856 57.4166 23.7369Z"
        fill="#D1E9FF"
      />
      <path
        d="M57.4147 23.7251L60.0764 22.6485L65.0402 21.3031C68.8154 20.2799 72.7774 20.1521 76.6107 20.9298C78.0349 21.2188 79.4313 21.6307 80.7843 22.161L85.8114 24.1315L88.8111 25.7492C91.0854 26.9757 93.205 28.4694 95.125 30.1987C97.3338 32.1881 99.2587 34.4715 100.846 36.985L103.716 41.5301L109.355 53.6648L113.246 62.2394L119.399 72.4133L124.165 80.3605L127.403 84.791C128.831 86.7454 130.93 88.1044 133.298 88.6079L133.514 88.6538C135.028 88.9758 136.433 89.6829 137.593 90.7069L138.272 91.306C139.192 92.1173 139.844 93.188 140.143 94.3771C140.443 95.5674 140.375 96.8206 139.948 97.9714L139.918 98.0518C139.762 98.4712 139.563 98.8731 139.323 99.2506L139.2 99.444C138.404 100.695 137.296 101.716 135.985 102.406L133.762 103.577L129.539 105.802L117.696 111.141L109.687 114.345L102.746 117.015L96.8724 118.972L90.8211 121.108L85.6597 122.532L82.1001 123.422L72.4892 125.38L63.4122 127.16L46.3261 129.615L41.1031 130.193C40.0796 130.306 39.0466 130.303 38.0238 130.183L35.39 129.874C33.9607 129.707 32.6341 129.048 31.637 128.01C30.5259 126.854 29.9054 125.313 29.9054 123.709L29.9054 123.147C29.9054 122.738 29.9385 122.329 30.0043 121.925L30.0417 121.695C30.3323 119.91 31.0798 118.231 32.2115 116.821L32.9706 115.875C35.2928 112.98 36.4671 109.332 36.2691 105.626L35.7066 95.0972L34.3611 80.4975L33.6462 70.1511L33.2729 65.7288C33.0496 63.0839 33.0422 60.4252 33.2505 57.7791L33.3479 56.5423C33.583 53.5568 34.1672 50.609 35.0884 47.7594C35.9138 45.2062 37.005 42.7468 38.344 40.4214L38.7313 39.7489C40.0271 37.4986 41.5612 35.3942 43.307 33.4719C45.104 31.4932 47.1146 29.7195 49.3018 28.1831L49.5755 27.9909C52.0178 26.2755 54.6479 24.8443 57.4147 23.7251Z"
        fill="url(#paint0_linear_12924_33986)"
        stroke="#B2DDFF"
        strokeWidth="2"
      />
      <ellipse
        cx="84.3447"
        cy="110.764"
        rx="48.6112"
        ry="8.50032"
        transform="rotate(-15.4595 84.3447 110.764)"
        fill="#B2DDFF"
      />
      <path
        d="M76.3987 113.404L75.87 112.839C75.3064 112.237 74.8252 111.563 74.439 110.834C74.1752 110.336 73.957 109.815 73.7873 109.278L72.8706 106.375L75.0208 105.612L77.1768 104.811L82.4797 103.382L88.4522 101.793L91.9657 101.029L92.1174 101.548C92.6261 103.288 92.8621 105.096 92.8169 106.909L92.6932 108.207C92.5468 109.742 92.0254 111.217 91.175 112.503L91.1298 112.572C90.7825 113.097 90.379 113.583 89.9265 114.02C88.661 115.244 87.0526 116.053 85.3157 116.339L84.6812 116.444C84.1407 116.533 83.5929 116.571 83.0453 116.557C81.2493 116.513 79.511 115.914 78.0679 114.844L77.7427 114.603C77.2596 114.245 76.8097 113.843 76.3987 113.404Z"
        fill="#D1E9FF"
        stroke="#B2DDFF"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12924_33986"
          x1="92.5575"
          y1="116.4"
          x2="63.5468"
          y2="22.0705"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#EFF8FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotificationNotDataIcon;
