import { FC } from "react";

type Props = {
  children: number;
  className?: string;
  symbolClassName?: string;
};

export const AmountFixer: FC<Props> = ({ children, className, symbolClassName }) => {
  const numbers = {
    ming: 1000,
    mln: 1000000,
    mlrd: 1000000000,
    trln: 1000000000000
  };

  const value = () => {
    let result = children;

    Object.keys(numbers).forEach(key => {
      const objKey = key as keyof typeof numbers;

      if (Math.abs(children) >= numbers[objKey]) {
        result = (
          <span>
            {(children / numbers[objKey])?.toFixed(2)} <span className={symbolClassName}>{objKey}</span>
          </span>
        ) as never;
      }
    });

    return result;
  };

  return <p className={className}>{value()}</p>;
};
