import React, { useState } from "react";
import { Button, Modal } from "antd";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "features/app/utils/helpers/baseCurrency";
import {
  useGetProjectReportAmount,
  useGetProjectReportCashFlow,
  useGetProjectReportProductSpend
} from "features/projects/service/queries";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { productSpentWidgetDrawerActions, projectReportModalActions, useDispatch, useSelector } from "store";

import { cx } from "modules/common";

import AmountCard from "./amount-card/amount-card";
import CashFlowCard from "./cash-flow-card/cash-flow-card";
import ProductSpentWidget from "./product-spent-widget/product-spent-widget";

import styles from "./project-report-modal.module.scss";

const ProjectReportModal: React.FC = () => {
  const [dateRange, setDateRange] = useState({ min_date: "", max_date: "" });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { severalRemove, append } = useQueryParams();
  const { visible, data } = useSelector(state => state.projectReportModalReducer);
  const { setVisible } = projectReportModalActions;
  const { setOpenDrawer } = productSpentWidgetDrawerActions;
  const baseCurrency = getBaseCurrency();
  const { data: amountData, isLoading: isAmountDataLoading } = useGetProjectReportAmount(Number(data?.id), visible);
  const { data: cashFlowData, isLoading: isCashFlowDataLoading } = useGetProjectReportCashFlow(
    Number(data?.id),
    visible
  );
  const { data: productSpendData, isLoading: isProductSpendDataLoading } = useGetProjectReportProductSpend(
    Number(data?.id),
    visible,
    dateRange?.min_date,
    dateRange?.max_date
  );

  const onCancel = () => {
    dispatch(
      setVisible({
        data: undefined,
        visible: false
      })
    );
    severalRemove(queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE, queryParamsKeys.CURRENCY_ID);
    setDateRange({ min_date: "", max_date: "" });
  };

  const footer = <Button onClick={onCancel}>{t("project.Yopish")}</Button>;

  const onOpenDrawer = (id: string) => {
    dispatch(
      setOpenDrawer({
        visible: true,
        props: {
          data: productSpendData,
          currency: data?.currency
        }
      })
    );
    append(queryParamsKeys.TAB, id, true);
  };

  return (
    <Modal
      centered
      wrapClassName={styles.project_report_modal}
      open={visible}
      footer={footer}
      onCancel={onCancel}
      title={
        <div className="flex items-center gap-1">
          <span className="text-lg font-semibold text-gray-900">{t("project.Loyiha hisoboti")}</span>
          <span className="text-lg font-semibold text-gray-400">( {data?.name} )</span>
        </div>
      }
    >
      <div className={cx("flex flex-col gap-4 p-5", styles.container)}>
        <AmountCard data={amountData!} isLoading={isAmountDataLoading} />
        <CashFlowCard baseCurrency={baseCurrency} data={cashFlowData!} isLoading={isCashFlowDataLoading} />
        <ProductSpentWidget
          data={productSpendData}
          baseCurrency={baseCurrency}
          isLoading={isProductSpendDataLoading}
          setDateRange={setDateRange}
          dateRange={dateRange}
          onOpenWidgetDrawer={onOpenDrawer}
        />
      </div>
    </Modal>
  );
};

export default ProjectReportModal;
