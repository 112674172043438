import queryString from "query-string";
import { Fragment, useMemo } from "react";

import styles from "./filtersCount.module.scss";

type Props = {
  queryStringArr: string[];
};

const FiltersCount = ({ queryStringArr }: Props) => {
  const queryValues = queryString.parse(location.search, {
    arrayFormat: "bracket",
  });

  const filtersLength = useMemo(() => {
    return Object.keys(queryValues).filter((item) => queryStringArr.includes(item))
      .length;
  }, [queryValues]);

  return (
    <Fragment>
      {filtersLength !== 0 && (
        <span className={styles.filter__count}>{filtersLength}</span>
      )}
    </Fragment>
  );
};

export default FiltersCount;
