import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Dropdown, Spin } from "antd";
import { cx } from "features/app/utils/helpers/cx";
import { useInView } from "react-intersection-observer";

import { useAppDispatch } from "../../../../../hooks/redux";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import CloseIcon from "../../../assets/icons/CloseIcon";
import MailIcon from "../../../assets/icons/MailIcon";
import { useGetAllUnreadMessage, useGetAllUnreadMessageCount } from "../../../service/queries";
import { queryKeys } from "../../../utils/constants/queryKeys";
import { getFirstLetter } from "../../../utils/helpers/getFirstLetter";
import { isEmptyArr } from "../../../utils/helpers/isEmptyArr";
import { GlobalChatModel } from "../../../utils/models/global-chat-model";
import { LoadingIndicator } from "../../loading-indicator/LoadingIndicator";

import EmptyMessageSvg from "../../../assets/images/emty-message.png";

import styles from "./globalChat.module.scss";

const GlobalChat = () => {
  const dispatch = useAppDispatch();
  const qc = useQueryClient();
  const [open, setOpen] = useState(false);
  const { ref, inView } = useInView();
  const { data: messages, isLoading, fetchNextPage } = useGetAllUnreadMessage();
  const { data: unreadMessagesCount } = useGetAllUnreadMessageCount();
  const { setVisible } = chatActions;

  const onOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onAfterOpenChat = () => {
    qc.invalidateQueries([queryKeys.GLOBAL_CHAT]);
    qc.invalidateQueries([queryKeys.GLOBAL_CHAT_COUNT]);
  };

  const userAvatarRender = (message: GlobalChatModel) => {
    const user = message?.user;

    if (user?.image) {
      return <img src={user?.image} className={styles.avatar} />;
    }
    return <div className={styles.non_avatar}>{getFirstLetter(user?.full_name)}</div>;
  };

  const messageDescriptionRender = (message: GlobalChatModel) => {
    if (!isEmptyArr(message?.files)) {
      return <img src={message?.files[0]?.name} className={styles.right__img} />;
    }
    return message?.message;
  };

  const onOpenChat = (message: GlobalChatModel) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: message?.object_id,
        type: message?.type,
        onAfterOpen: onAfterOpenChat
      })
    );
  };

  const customDropdown = () => (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h3>Xabarnoma</h3>
          <div onClick={onClose} className={styles.close_icon}>
            <CloseIcon />
          </div>
        </div>
        {!isEmptyArr(messages?.pages) && messages!.pages[0]?.total > 0 ? (
          <div className={styles.messages}>
            {messages?.pages?.map((page, index) => (
              <React.Fragment key={page.current_page + index}>
                {page?.data.map(message => (
                  <div className={styles.message} key={message.id} ref={ref} onClick={() => onOpenChat(message)}>
                    <div className={styles.left}>{userAvatarRender(message)}</div>
                    <div className={styles.right}>
                      <div>
                        <h3 className={styles.right__title}>
                          {message?.title} - {message?.object_id}
                        </h3>
                        <p className={styles.right__message}>
                          <span className={styles.right__user_name}>{message?.user?.full_name}</span>{" "}
                          {messageDescriptionRender(message)}
                        </p>
                      </div>
                      <div className={styles.right__bottom}>
                        {message?.unread_message_count > 0 ? (
                          <p className={styles.unread_messages}>
                            +{message?.unread_message_count > 100 ? "+99" : message?.unread_message_count} xabar
                          </p>
                        ) : (
                          <p />
                        )}
                        <p className={styles.create_date}>{message.created_at}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className={styles.not__messages}>
            <img src={EmptyMessageSvg} alt="EmptyMessageSvg" />
            <h4>Xabarnoma mavjud emas !</h4>
            <p>Sizga ish jarayonidagi o'zgarishlarni taqdim etadi.</p>
          </div>
        )}
      </div>
    </Spin>
  );

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <Dropdown
      open={open}
      dropdownRender={customDropdown}
      trigger={["click"]}
      overlayClassName={styles.global_chat_dropdown}
    >
      <div className={styles.dropdown_button}>
        <div
          className={cx(styles.click_icon, {
            [styles.click_icon__opened]: open
          })}
          onClick={onOpen}
        >
          <MailIcon />
        </div>
        <Badge count={unreadMessagesCount} overflowCount={9} />
      </div>
    </Dropdown>
  );
};

export default GlobalChat;
