import { z } from "zod";

import { statusSchema, widgetSchema, widgetSettingsSchema } from "..";

// CREATE

export const createWidgetSchema = z.object({
  widget_id: z.number(),
  name: z.record(z.string(), z.string()),
  user_ids: z.array(z.number()).optional(),
  title: z.record(z.string(), z.string()).optional()
});

export const createWidgetFormSchema = z.object({
  id: z.number(),
  name: z.coerce
    .string({ required_error: "Vidjet nomi kiritilishi zarur" })
    .min(3, "Vidjet nomi kamida 3 ta harfdan iborat bo'lishi lozim"),
  user_ids: z.array(z.number()).optional().default([]),
  title: z.coerce.string().optional()
});

export const widgetPropsSchema = widgetSchema.omit({ name: true, title: true }).merge(
  z.object({
    name: z.string(),
    title: z.string()
  })
);

export type CreateWidget = z.infer<typeof createWidgetSchema>;
export type CreateWidgetForm = z.infer<typeof createWidgetFormSchema>;
export type WidgetPropsType = z.infer<typeof widgetPropsSchema>;

// UPDATE

export const updateWidgetSchema = z.object({
  id: z.number(),
  status: statusSchema.optional(),
  widget_id: z.number().optional(),
  user_ids: z.array(z.number()).optional(),
  name: z.record(z.string(), z.string()).optional(),
  filter: widgetSettingsSchema.optional(),
  title: z.record(z.string(), z.string()).optional(),
  props: z.string().optional()
});

export const updateWidgetFormSchema = updateWidgetSchema.omit({ name: true, title: true }).merge(
  z.object({
    name: z.string(),
    title: z.string().optional()
  })
);

export type UpdateWidgetForm = z.infer<typeof updateWidgetFormSchema>;
export type UpdateWidget = z.infer<typeof updateWidgetSchema>;
