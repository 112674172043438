import { Dropdown, MenuProps } from "antd";
import { useState } from "react";

import { useAppDispatch } from "../../../../../hooks/redux";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import { impostQueryKeys } from "../../../utils/constants/impostQueryKeys";
import { PaymentImpostModel } from "../../../utils/models/paymentImpostModel";
import { useQueryParams } from "hooks/useQueryParams";
import { useQueryClient } from "@tanstack/react-query";

import styles from "./actionDropdown.module.scss";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

type Props = {
  record?: PaymentImpostModel;
};

const ActionDropdown = ({ record }: Props) => {
  const qc = useQueryClient();
  const { severalSearchParams } = useQueryParams();
  const dispatch = useAppDispatch();
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const { setVisible } = chatActions;

  const onToggleDropdown = () => {
    setVisibleDropdown(prev => !prev);
  };

  const onOpenChat = (e: React.MouseEvent<HTMLElement>, record: PaymentImpostModel | undefined) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.PAYMENTS,
        dataKeys: [impostQueryKeys.PAYMENTS, severalSearchParams(queryParamsKeys.TAB)],
        record
      })
    );
    setVisibleDropdown(false);
    e.stopPropagation();
  };

  const onClickActionIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const customItems = () => {
    const items: MenuProps["items"] = [
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenChat(e, record)}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        style: {
          padding: 0
        }
      }
    ];

    return { items };
  };

  return (
    <Dropdown
      menu={customItems()}
      trigger={["click", "contextMenu"]}
      open={visibleDropdown}
      onOpenChange={onToggleDropdown}
    >
      <UnreadMessageDots count={record?.unread_message_count} onClick={onClickActionIcon} className="cursor-pointer" />
    </Dropdown>
  );
};

export default ActionDropdown;
