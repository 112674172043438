import React, { ReactNode } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Dropdown, Modal, Spin, Table, TableProps, Tooltip } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import ArrowRightIcon from "../../../../app/assets/icons/ArrowRightIcon";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import TableEmpty from "../../../../app/components/table-empty/TableEmpty";
import { RU } from "../../../../app/utils/constants/languages";
import MessageIcon from "../../../../inventory/assets/icons/MessageIcon";
import EyeIcon from "../../../../projects/assets/icons/EyeIcon";
import { useCheckTransfer, useDeletePayment } from "../../../service/mutation";
import { useGetTransfers } from "../../../service/queries";
import { paymentQueryKeys } from "../../../utils/constants/paymentQueryKeys";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { TransferModel } from "../../../utils/models/TransferModel";

import styles from "./paymentTransferCheckModal.module.scss";

const PaymentTransferCheckModal: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const qc = useQueryClient();
  const { setVisible } = chatActions;
  const { setTransferCheck, setTransferHistoryModal } = paymentReducerActions;
  const { visible } = useAppSelector(state => state.paymentReducer.transferCheck);

  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const transferActions = actions("Kirim-chiqim");

  const checkTransfer = useCheckTransfer();
  const transferDelete = useDeletePayment();
  const { data, isLoading } = useGetTransfers(visible);

  const onCancel = () => {
    dispatch(
      setTransferCheck({
        visible: false
      })
    );
  };

  const onOpenChat = (objectId: number) => () => {
    dispatch(
      setVisible({
        visible: true,
        type: "payments",
        objectId
      })
    );
  };

  const onAfterDelete = () => {
    qc.invalidateQueries([paymentQueryKeys.TRANSFER_VIEW]).then();
  };

  const onOpenTransfer = (record: TransferModel) => () => {
    dispatch(setTransferHistoryModal(record as never));
  };

  const stopPropagation = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const dropdownRender = (originNode: ReactNode) => <div onClick={stopPropagation}>{originNode}</div>;

  const transferMenu = (record: TransferModel) =>
    [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EyeIcon /> {t("payment.Ko'rish")}
          </div>
        ),
        onClick: onOpenTransfer(record),
        isView: true
      },
      {
        key: "2",
        label: (
          <div className="flex items-center gap-2">
            <MessageIcon /> {t("payment.Chat")}
          </div>
        ),
        onClick: onOpenChat(record?.expense_payment?.id),
        isView: true
      },
      {
        key: "3",
        label: (
          <ModalConfirm
            isDescription
            mutation={transferDelete}
            payload={{ id: record?.id }}
            onAfterClose={onAfterDelete}
          >
            <div className="flex items-center gap-2">
              <DeleteIcon /> {t("payment.O'chirish")}
            </div>
          </ModalConfirm>
        ),
        isView: transferActions?.delete
      }
    ].filter(item => item?.isView);

  const columns: ColumnsType<TransferModel> = [
    {
      title: t("payment.Manbaa"),
      render: record => (
        <div className="flex items-center gap-2">
          <div className="box-border flex h-8 w-8 items-center justify-center rounded bg-primary-400 text-sm font-normal text-white">
            TR
          </div>
          <div>
            {t("payment.O'tkazma")} - {record?.id}
          </div>
        </div>
      )
    },
    {
      title: t("payment.Sana"),
      dataIndex: "date"
    },
    {
      title: t("payment.Kassadan"),
      render: (record: TransferModel) => record.expense_payment?.cash?.name
    },
    {
      title: t("payment.Kassaga"),
      render: (record: TransferModel) => record.income_payment?.cash?.name
    },
    {
      title: t("payment.O'tkazma summasi"),
      render: (record: TransferModel) => (
        <div className="flex items-center gap-2">
          <span>{record?.expense_payment?.amount?.toLocaleString(RU)}</span>
          <span>{record?.expense_payment?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: t("payment.To'lov turi"),
      render: (record: TransferModel) => (
        <div className="flex items-center gap-2">
          <div
            className={styles.payment_type}
            style={{
              background: `${record.expense_payment?.payment_type?.color}30`,
              color: record.expense_payment?.payment_type?.color
            }}
          >
            {record.expense_payment?.payment_type?.name}
          </div>
          <span>
            <ArrowRightIcon />
          </span>
          <div
            className={styles.payment_type}
            style={{
              background: `${record.income_payment?.payment_type?.color}30`,
              color: record.income_payment?.payment_type?.color
            }}
          >
            {record.income_payment?.payment_type?.name}
          </div>
        </div>
      )
    },
    {
      title: t("payment.Xodim"),
      render: (record: TransferModel) =>
        record?.creator?.image ? (
          <Tooltip title={record?.creator?.full_name}>
            <img
              src={record?.creator?.image}
              alt={record?.creator?.full_name}
              className="h-9 w-9 rounded-full object-cover"
            />
          </Tooltip>
        ) : (
          <div className="flex">
            <NotUserImage name={record?.creator?.full_name} isTooltip />
          </div>
        )
    },
    {
      title: "",
      render: (record: TransferModel) => (
        <Dropdown trigger={["click"]} menu={{ items: transferMenu(record) }} dropdownRender={dropdownRender}>
          <span className="cursor-pointer" onClick={stopPropagation}>
            <DotsVerticalIcon />
          </span>
        </Dropdown>
      )
    }
  ];

  const onRow: TableProps["onRow"] = record => ({
    onClick: onOpenTransfer(record)
  });

  const footer = (
    <div className="flex items-center justify-end">
      <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
    </div>
  );

  return (
    <Modal
      centered
      open={visible}
      footer={footer}
      onCancel={onCancel}
      className={styles.transfer_check}
      title={t("payment.Kutilayotgan o'tkazmalar")}
    >
      <Spin spinning={isLoading || checkTransfer.isLoading} indicator={LoadingIndicator}>
        <div className={styles.content}>
          <Table<TransferModel>
            rowKey="id"
            onRow={onRow}
            dataSource={data}
            columns={columns}
            pagination={false}
            rowClassName="cursor-pointer"
            loading={{
              spinning: isLoading,
              indicator: LoadingIndicator
            }}
            locale={{
              emptyText: <TableEmpty />
            }}
          />
        </div>
      </Spin>
    </Modal>
  );
};

export default PaymentTransferCheckModal;
