import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";

import styles from "../../notification.module.scss";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const WarehouseProductCreated = ({ record, onNavigatePage }: Props) => {
  return (
    <div
      onClick={() => onNavigatePage("/supply", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        {record?.user?.full_name} sizdan {record?.warehouse_products?.name}{" "}
        masulatlarga buyurtma berdi. Buyurtma yetkazilish vaqti{" "}
        {record?.warehouse_products?.delivery_date}.
      </p>
    </div>
  );
};

export default WarehouseProductCreated;
