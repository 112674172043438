import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown, Tooltip } from "antd";
import { ItemType } from "antd/lib/menu/interface";
import classNames from "classnames";
import ArrowDownIcon from "features/app/assets/icons/ArrowDownIcon";
import PlusIcon from "features/app/assets/icons/PlusIcon";
import { colors } from "features/app/utils/constants/colors";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { PaymentFilterKeys } from "features/payment/utils/enums/paymentFilterKeys";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { paymentReducerActions } from "store/reducers/paymentReducer";
import { supplyActions } from "store/reducers/supplyReducer";

import { rootPaths } from "../../../../../../routes/root/rootPaths";

import styles from "./paymentCalendarContent.module.scss";

type Props = {
  date: string;
};

const OrderOrPaymentAction: React.FC<Props> = ({ date }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { queries } = useQueryParams();
  const { setPartyModal } = supplyActions;
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const specificActions = actions("Maxsus imkoniyatlar");
  const [isViewAction, setIsViewAction] = useState(false);
  const { setVisibleExpectedPayment } = paymentReducerActions;
  const pendingPaymentActions = actions("Kutilayotgan to'lovlar");
  const { filter_type: filterType, view_type: viewType } = queries();

  const emptyFunc = () => {};

  const isCompanyPerson = useMemo(() => pathname?.includes(rootPaths.DETAILED_COUNTERPART), [pathname]);

  const generateDate = () => {
    if (viewType === PaymentTypeEnum.MONTHLY) return `01.${date}`;

    if (viewType === PaymentTypeEnum.ANNUAL) return `31.01.${date}`;

    return date;
  };

  const onOpenExpectedPayment = () => {
    dispatch(
      setVisibleExpectedPayment({
        visible: true,
        date: generateDate(),
        type: PaymentTypeEnum.EXPENSE
      })
    );
  };

  const onOpenPartyModal = () => {
    dispatch(
      setPartyModal({
        visible: true,
        isEditing: false,
        isView: false,
        selectProducts: [],
        paymentDate: generateDate()
      })
    );
  };

  const actionButton = (title?: string) => {
    let clickFunc = emptyFunc;

    if (isCompanyPerson) {
      clickFunc = onOpenExpectedPayment;
    } else {
      if (title && filterType === PaymentFilterKeys.ORDER && specificActions?.createParty) {
        clickFunc = onOpenPartyModal;
      }

      if (title && filterType === PaymentFilterKeys.EXPECTED_PAYMENT && pendingPaymentActions?.create) {
        clickFunc = onOpenExpectedPayment;
      }
    }

    return (
      <Tooltip title={title || t("payment.To'lov yoki partiya qo'shish")} placement="top">
        <div
          onClick={clickFunc}
          className={classNames(styles.card__index__action, {
            [styles.action_active]: isViewAction
          })}
        >
          <PlusIcon color={colors.WHITE} />
        </div>
      </Tooltip>
    );
  };

  const onOpenChangePaymentAction = (open: boolean) => {
    setIsViewAction(open);
  };

  const dropdownItems = [
    {
      ...(specificActions?.createParty
        ? {
            key: "1",
            label: (
              <div className="flex items-center gap-2">
                <ArrowDownIcon rotate={235} width={20} stroke={colors.GRAY_700} />
                <span>{t("payment.Partiya qo'shish")}</span>
              </div>
            ),
            onClick: onOpenPartyModal
          }
        : {})
    },
    {
      ...(pendingPaymentActions?.create
        ? {
            key: "2",
            label: (
              <div className="flex items-center gap-2">
                <PlusIcon color={colors.GRAY_700} />
                <span>{t("payemnt.Kutilayotgan to'lov")}</span>
              </div>
            ),
            onClick: onOpenExpectedPayment
          }
        : {})
    }
  ];

  const orderOrPaymentAction = () => {
    const actionView = {
      [PaymentFilterKeys.ORDER]: actionButton("Partiya qo'shish"),
      [PaymentFilterKeys.EXPECTED_PAYMENT]: actionButton("To'lov qo'shish")
    };

    if (isCompanyPerson) {
      return actionView[PaymentFilterKeys.EXPECTED_PAYMENT];
    }

    return filterType ? (
      actionView[filterType as keyof typeof actionView]
    ) : (
      <Dropdown
        trigger={["click"]}
        menu={{ items: dropdownItems as ItemType[] }}
        onOpenChange={onOpenChangePaymentAction}
      >
        <Tooltip title="To'lov yoki partiya qo'shish" placement="top">
          {actionButton()}
        </Tooltip>
      </Dropdown>
    );
  };

  return orderOrPaymentAction();
};

export default OrderOrPaymentAction;
