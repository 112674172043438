import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { DetailedProjectFolder } from "features/projects/pages/detailed-project-folder/DetailedProjectFolder";
import { projectsPaths } from "routes/projects/projectsPaths";

import { rootPaths } from "../../../../routes/root/rootPaths";
import { settingsPaths } from "../../../../routes/settings/settingsPaths";
import ArchiveCounterParts from "../../components/archive/archive-counterparts/ArchiveCounterParts";
import ArchiveProjects from "../../components/archive/archive-projects/ArchiveProjects";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Index: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const employeesPath = `${rootPaths.SETTINGS}${settingsPaths.ARCHIVE}`;
  const rolesPath = `${rootPaths.SETTINGS}${settingsPaths.ARCHIVE_COUNTERPARTS}`;

  // active
  const activeLink = (path: string) => (location.pathname === path ? styles.active : "");

  return (
    <div className={styles.archive}>
      <div className={styles.archive__top}>
        <h2>{t("settings.Arxiv")}</h2>
        <p>{t("settings.Bu yerda siz arxivni o'zgartirishingiz mumkin")}</p>
      </div>
      <div className={styles.archive__body}>
        <div className={styles.segment_container}>
          <Link to={employeesPath} className={activeLink(employeesPath)}>
            {t("settings.Loyihalar")}
          </Link>
          <Link to={rolesPath} className={activeLink(rolesPath)}>
            {t("settings.Kontragentlar")}
          </Link>
        </div>
        <div className={styles.routes_container}>
          <Routes>
            <Route path={settingsPaths.INDEX} element={<ArchiveProjects />} />
            <Route path={settingsPaths.COUNTERPARTS} element={<ArchiveCounterParts />} />
            <Route path={projectsPaths.DETAILED_FOLDER} element={<DetailedProjectFolder />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Index;
