import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import $api from "features/app/utils/helpers/axiosInstance";

import { useQueryParams } from "hooks/useQueryParams";

import { CompanyPersonBalance, dashboardQueryKeys } from "..";

interface Query {
  data: CompanyPersonBalance[];
  total: number;
  currentPage: number;
}

interface Params extends Partial<Pick<UseQueryOptions, "enabled">> {
  id: number;
  type: string;
}

export function useCompanyPersonBalance({ enabled, id, type }: Params) {
  const initialData = {
    data: [],
    total: 0,
    currentPage: 1
  };
  const { generateSearchParam, reqQueryParam } = useQueryParams();
  const otherSearchParams = reqQueryParam(queryParamsKeys.PAGE);
  const searchParams = `${generateSearchParam({ id, balance_total_amount_type: type })}${otherSearchParams ? `&${otherSearchParams}` : ""}`;
  const url = `company-widget/company-person-balance/view?${searchParams}`;

  const { data = initialData, ...arg } = useQuery<Query>(
    [dashboardQueryKeys.COMPANY_PERSON_CARD_TABLE, searchParams],
    async () => {
      const { data } = await $api.get(url);

      return { data: data?.data?.data, total: data?.data?.total, currentPage: data?.data?.current_page };
    },
    {
      enabled
    }
  );

  return { ...data, ...arg };
}
