import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";

import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { IProducts } from "../../../../utils/models/wareHouses";

export interface IProps {
  columns: ColumnsType<IProducts>;
  wareHouseProducts: IProducts[] | undefined;
  isLoading: boolean;
}

const CollapseItemTable: React.FC<IProps> = ({ columns, isLoading, wareHouseProducts }) => (
  <Table
    pagination={false}
    columns={columns}
    dataSource={wareHouseProducts}
    locale={{
      emptyText: <TableEmpty />
    }}
    loading={{
      spinning: isLoading,
      indicator: LoadingIndicator
    }}
  />
);

export default CollapseItemTable;
