import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { sliceNames } from "../../features/app/utils/constants/sliceNames";

interface TransferModalStateModel {
  visible: boolean;
  data: TransferModalDataModel;
}

interface TransferModalDataModel {}

const initialState: TransferModalStateModel = {
  visible: true,
  data: {},
};

const transferModalDataSlice = createSlice({
  name: sliceNames.TRANSFER_MODAL,
  initialState,
  reducers: {
    setTransferData: (state, action: PayloadAction<TransferModalStateModel>) => {
      state = action.payload;
    },
  },
});

export const transferModalDataAction = transferModalDataSlice.actions;
export default transferModalDataSlice.reducer;
