import { numberToFixed } from "../helpers";

export const useAmountFixer = (amount: string) => {
  const numbers = {
    ming: 1000,
    mln: 1000000,
    mlrd: 1000000000,
    trln: 1000000000000
  };

  const value = () => {
    let result = amount;

    Object.keys(numbers).forEach(key => {
      const objKey = key as keyof typeof numbers;

      if (Math.abs(Number(amount)) >= numbers[objKey]) {
        // oldin => (Number(amount) / numbers[objKey])?.toFixed(2)
        result = `${numberToFixed(Number(amount) / numbers[objKey])} ${objKey}` as never;
      }
    });

    return result;
  };

  return value();
};
