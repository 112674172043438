import { Form, FormInstance, FormListOperation, Input, Select } from "antd";
import React from "react";
import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";

import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { formRules } from "../../../../app/utils/constants/formRules";
import InfoIcon from "../../../assets/icons/InfoIcon";
import RefreshCcwIcon from "../../../assets/icons/RefreshCcwIcon";
import { ExcessEnum } from "../../../utils/constants/ExcessEnum";
import { excessOptions } from "../../../utils/constants/excessOptions";
import { ExcessFormModel } from "../../../utils/models/ExcessFormModel";

import styles from "./excessModal.module.scss";

const { Option } = Select;
const { List, Item } = Form;

type Props = {
  quantityErrCount?: number;
  setQuantityErrCount: React.Dispatch<React.SetStateAction<number | undefined>>;
  form: FormInstance<ExcessFormModel>;
};

const ExcessModalBottom: React.FC<Props> = ({
  quantityErrCount,
  setQuantityErrCount,
  form,
}) => {
  const excessErrorCount = () => {
    return quantityErrCount! < 0
      ? `${Math.abs(quantityErrCount!)} dona ko'p`
      : `${quantityErrCount} dona`;
  };

  const onClearData = () => {
    form.setFieldsValue({
      quantity: undefined,
      accepted_quantity: undefined,
      additionals: [],
    });

    setQuantityErrCount(undefined);
  };

  const onAddAdditionalItem = (operation: FormListOperation) => {
    operation.add({
      quantity: undefined,
      type: undefined,
    });
  };

  const onDeleteExcess = (operation: FormListOperation, index: number) => {
    operation.remove(index);
  };

  return (
    <div className={styles.bottom}>
      <div
        className={`${styles.error_message} ${
          quantityErrCount ? styles.error_message__active : ""
        }`}
      >
        <InfoIcon />
        <span>Kamomat: {excessErrorCount()}</span>
      </div>
      <List name="additionals">
        {(fields, operation) => (
          <>
            {fields.map((_, index) => (
              <div className={styles.additionals} key={index}>
                <Item name={[index, "quantity"]} label="Hajmi" rules={formRules()}>
                  <Input type="number" placeholder="Soni" suffix="dona" />
                </Item>
                <div className={styles.additionals__right}>
                  <Item
                    name={[index, "type"]}
                    label="Kamomat turi"
                    rules={formRules()}
                  >
                    <Select placeholder="Turi" suffixIcon={<SelectSuffixIcon />}>
                      {Object.values(ExcessEnum).map((key, index) => (
                        <Option value={key} key={index}>
                          {excessOptions[key]}
                        </Option>
                      ))}
                    </Select>
                  </Item>
                  <div
                    className={styles.delete}
                    onClick={() => onDeleteExcess(operation, index)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
            <div className={styles.actions}>
              <div
                className={styles.add_item}
                onClick={() => onAddAdditionalItem(operation)}
              >
                <PlusCircleIcon fill />
                <span>Kamomatli resurs qo'shish</span>
              </div>
              <div className={styles.clear_data} onClick={onClearData}>
                <RefreshCcwIcon />
                <span>Ma'lumotlarni tozalash</span>
              </div>
            </div>
          </>
        )}
      </List>
    </div>
  );
};

export default ExcessModalBottom;
