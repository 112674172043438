import React from "react";
import { Select } from "antd";

import SelectSuffixIcon from "../../../assets/icons/SelectSuffixIcon";
import { generalEditArgKeys } from "../../../utils/constants/generalEditData";
import { selectFilterOption } from "../../../utils/helpers/selectFilterOption";
import ProjectSelect from "../../project-select/project-select";
import TableEmpty from "../../table-empty/TableEmpty";

type Props = {
  argKey: generalEditArgKeys;
  setReturnData: React.Dispatch<React.SetStateAction<unknown[]>>;
  data?: {
    id: number;
    name?: string;
  }[];
};

const { Option } = Select;

const GeneralEditingSelect: React.FC<Props> = ({ data, setReturnData, argKey }) => {
  const onChange = (e: number) => {
    setReturnData(old =>
      old?.map(item => ({
        ...(item as object),
        [argKey]: e
      }))
    );
  };

  return argKey === "project_id" ? (
    <ProjectSelect allowClear={false} onChange={onChange} suffixIcon={<SelectSuffixIcon />} />
  ) : (
    <Select
      showSearch
      onChange={onChange}
      placeholder="Tanlang"
      notFoundContent={<TableEmpty />}
      filterOption={selectFilterOption}
      suffixIcon={<SelectSuffixIcon />}
    >
      {data?.map(item => (
        <Option key={item?.id} value={item?.id} props={{ name: item?.name }}>
          {item?.name}
        </Option>
      ))}
    </Select>
  );
};

export default GeneralEditingSelect;
