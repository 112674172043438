import { useOutletContext } from "react-router-dom";
import { Select } from "antd";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";
import i18n from "i18next";

import SelectSuffixIcon from "../../assets/icons/SelectSuffixIcon";
import { CustomFieldProps } from "../../utils/helpers/customFields";

const { Option } = Select;

const SelectCustomField = ({
  hasValue,
  value,
  placeholder = "Tanlang",
  suffixIcon,
  onChange,
  disabled,
  options,
  onBlur
}: CustomFieldProps) => {
  const { smetaContainer } = useOutletContext<OutletContextType>();

  return (
    <Select
      getPopupContainer={() => smetaContainer}
      {...((hasValue || value) && { value })}
      placeholder={placeholder}
      allowClear
      suffixIcon={suffixIcon || <SelectSuffixIcon />}
      onChange={e => {
        if (onChange) {
          onChange(e);
        }
      }}
      onClear={() => {
        onChange && onChange("");
      }}
      onBlur={onBlur}
      disabled={disabled}
    >
      {options?.map(item => (
        <Option value={String(item?.id)} key={item?.id}>
          {item?.name[i18n.language]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectCustomField;
