import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import DatepickerSuffixIcon from "../../../../../app/assets/icons/DatepickerSuffixIcon";
import { dayjsFormats } from "../../../../../app/utils/constants/dayjsFormats";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";

const PaymentCalendarSelect: React.FC = () => {
  const { appends, queries, severalRemove } = useQueryParams();
  const { view_type: viewType, statistics_type: tabKey, min_date: minDate, max_date: maxDate } = queries();

  const onChangeDaily = (e: dayjs.Dayjs) => {
    const year = dayjs(e)?.get("year");
    const month = dayjs(e)?.get("month") + 1;

    if (year && month) {
      const startDate = `01.${month > 9 ? month : `0${month}`}.${year}`;
      const lastDayOfMonth = dayjs(startDate, dayjsFormats.DATE).endOf("month");
      const endDate = lastDayOfMonth.format(dayjsFormats.DATE);

      appends([
        {
          key: queryParamsKeys.MIN_DATE,
          value: startDate
        },
        {
          key: queryParamsKeys.MAX_DATE,
          value: endDate
        }
      ]);
    } else {
      severalRemove(queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE);
    }
  };

  const onChangeMonthly = (e: dayjs.Dayjs) => {
    const year = dayjs(e)?.get("year");

    if (year) {
      const startDate = `01.01.${year}`;
      const endDate = `31.12.${year}`;

      appends([
        {
          key: queryParamsKeys.MIN_DATE,
          value: startDate
        },
        {
          key: queryParamsKeys.MAX_DATE,
          value: endDate
        }
      ]);
    } else {
      severalRemove(queryParamsKeys.MIN_DATE, queryParamsKeys.MAX_DATE);
    }
  };

  const dailySelectValue = () => {
    if (minDate && maxDate) {
      return dayjs(minDate, dayjsFormats.DATE);
    }

    const year = dayjs()?.get("year");
    const month = dayjs()?.get("month") + 1;
    const date = `01.${month > 9 ? month : `0${month}`}.${year}`;

    return dayjs(date, dayjsFormats.DATE);
  };

  const monthlySelectValue = () => {
    if (minDate && maxDate) {
      return dayjs(minDate, dayjsFormats.DATE);
    }

    return dayjs(`01.01.${dayjs()?.get("year")}`, dayjsFormats.DATE);
  };

  const dailySelect = (
    <DatePicker
      picker="month"
      placeholder="Tanlang"
      onChange={onChangeDaily}
      value={dailySelectValue()}
      format={dayjsFormats.MONTH_DATE}
      suffixIcon={<DatepickerSuffixIcon />}
    />
  );

  const monthlySelect = (
    <DatePicker
      picker="year"
      placeholder="Tanlang"
      onChange={onChangeMonthly}
      value={monthlySelectValue()}
      suffixIcon={<DatepickerSuffixIcon />}
    />
  );

  if (tabKey === PaymentTabKeys.CALENDAR) {
    if (viewType === PaymentTypeEnum.DAILY) {
      return dailySelect;
    }
    if (viewType === PaymentTypeEnum.MONTHLY) {
      return monthlySelect;
    }
    if (viewType === PaymentTypeEnum.ANNUAL) {
      return null;
    }
    return dailySelect;
  }
  return null;
};

export default PaymentCalendarSelect;
