export const localeUz = {
  months: [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr"
  ],
  monthsShort: ["Yan", "Fev", "Ma", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
  weekdays: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
  weekdaysShort: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
  weekdaysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
  calendar: {
    sameDay: "Bugun"
  }
};
