import classNames from "classnames";
import React, { ReactNode } from "react";
import { Dropdown, Table } from "antd";
import { useDispatch } from "react-redux";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { ItemType } from "antd/es/menu/hooks/useItems";

import { useGetEmployees } from "../../../../service/queries";
import { useDeleteAdmin } from "../../../../service/mutations";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import EyeIcon from "../../../../../projects/assets/icons/EyeIcon";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import { RoleModel } from "../../../../utils/models/roles/roleModel";
import { AdminModel } from "../../../../utils/models/admin/adminModel";
import { SUPER_ADMIN_ROLE } from "../../../../utils/constants/defaultRoles";
import Pagination from "../../../../../app/components/pagination/Pagination";
import { Status, StatusNames } from "../../../../../app/utils/models/status";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import NotUserImage from "../../../../../app/components/not-image/NotUserImage";
import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import ModalConfirm from "../../../../../app/components/modal-confirm/ModalConfirm";
import UnreadMessageDots from "../../../../../app/components/unread-message/UnreadMessageDots";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";

import styles from "../settingsAdmins.module.scss";

const SettingsAdminsTable: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setAdminModal } = settingsActions;
  const { data, isLoading } = useGetEmployees();
  const deleteAdmin = useDeleteAdmin();

  // delete admin
  const onDeleteAdmin = (id: number) => {
    return deleteAdmin.mutateAsync(id);
  };

  // open modal
  const onOpenModal = (record: AdminModel, isEdited: boolean) => () => {
    dispatch(
      setAdminModal({
        data: record,
        visible: true,
        isEdited,
      })
    );
  };

  const menuItems = (record: AdminModel) => {
    let items: ItemType[] = [
      {
        key: "1",
        label: (
          <div className="d_f ai_c cg_8">
            <EyeIcon />
            <span>{t("settings.Ko'rish")}</span>
          </div>
        ),
        onClick: onOpenModal(record, false),
      },
    ];

    if (record?.role?.name !== SUPER_ADMIN_ROLE) {
      items = [
        ...items,
        {
          key: "2",
          label: (
            <div className="d_f ai_c cg_8">
              <EditIcon /> <span>{t("settings.Tahrirlash")}</span>
            </div>
          ),
          onClick: onOpenModal(record, true),
        },
        {
          key: "3",
          label: (
            <ModalConfirm
              onOk={() => onDeleteAdmin(record.id)}
              title={t("Xodimlar.Xodimni o'chirmoqchimisiz ?")}
            >
              <div className="d_f ai_c jc_c cg_8">
                <DeleteIcon />
                <span>{t("settings.O'chirish")}</span>
              </div>
            </ModalConfirm>
          ),
        },
      ];
    }

    return items;
  };

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  // table columns
  const columns: ColumnsType<AdminModel> = [
    {
      title: `${t("Xodimlar.To'liq ism")}`,
      render: (record: AdminModel) => (
        <div className={styles.person}>
          {record?.image ? (
            <img src={record.image} alt="" />
          ) : (
            <NotUserImage name={record?.full_name} width={40} />
          )}
          <p>
            <span className={styles.person__name}>{record.full_name}</span>
            <span className={styles.person__phone}>{record.phone}</span>
          </p>
        </div>
      ),
    },
    {
      title: `${t("Xodimlar.Lavozim")}`,
      dataIndex: "role",
      render: (value: RoleModel) => value.name,
    },
    {
      title: `${t("settings.Login")}`,
      dataIndex: "login",
    },
    {
      title: `${t("Xodimlar.Holati")}`,
      dataIndex: "status",
      render: (value: Status) => (
        <span
          className={classNames({
            ["status__active"]: value === Status.ACTIVE,
            ["status__passive"]: value === Status.PASSIVE,
          })}
        >
          {StatusNames[value]}
        </span>
      ),
    },
    {
      title: "",
      render: (record: AdminModel) => (
        <Dropdown
          trigger={["click"]}
          placement="bottomLeft"
          dropdownRender={dropdownRender}
          menu={{ items: menuItems(record) }}
        >
          <div className="d_f ai_c c_p jc_fe" onClick={(e) => e.stopPropagation()}>
            <UnreadMessageDots />
          </div>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className={styles.settings_admins__content}>
      <Table<AdminModel>
        dataSource={data?.data.data}
        columns={columns}
        rowKey={(e) => e.id}
        locale={{
          emptyText: <TableEmpty />,
        }}
        loading={{
          spinning: isLoading || deleteAdmin.isLoading,
          indicator: LoadingIndicator,
        }}
        pagination={false}
        className={styles.settings_admins__table}
        onRow={(record) => ({
          onClick: onOpenModal(record, false),
          className: "c_p",
        })}
      />
      <Pagination
        paginationProps={{
          total: data?.data?.total,
        }}
      />
    </div>
  );
};

export default SettingsAdminsTable;
