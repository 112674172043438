import React from "react";
import { Dropdown, MenuProps, Popover } from "antd";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../app/assets/icons/EditIcon";
import ModalConfirm from "../../../../app/components/modal-confirm/ModalConfirm";
import NotUserImage from "../../../../app/components/not-image/NotUserImage";
import { useDeleteCash } from "../../../service/mutations";
import { CashModel } from "../../../utils/models/cash/cashModel";

import styles from "./settingsCashCard.module.scss";

type Props = {
  setModalData: React.Dispatch<
    React.SetStateAction<{
      visible: boolean;
      id?: number;
    }>
  >;
};

const SettingsCashCard: React.FC<Props & CashModel> = ({ setModalData, users, name, id }) => {
  const { t } = useTranslation();
  const deleteCash = useDeleteCash();

  const onOpenModal = () => {
    setModalData({
      visible: true,
      id
    });
  };

  const onDelete = () => deleteCash.mutateAsync({ id });

  const usersContent = (
    <div className={styles.user_popover}>
      {users?.map(user => (
        <div key={user?.id} className={styles.user}>
          {user?.image ? (
            <img src={user?.image} alt={user?.full_name} />
          ) : (
            <NotUserImage name={user?.full_name ? user?.full_name : name} width={24} />
          )}{" "}
          <p>{user?.full_name}</p>
        </div>
      ))}
    </div>
  );

  // menu items
  const items: MenuProps["items"] = [
    {
      key: "2",
      label: (
        <div className={styles.update}>
          <EditIcon /> <span>{t("products.Tahrirlash")}</span>
        </div>
      ),
      onClick: onOpenModal
    },
    {
      key: "3",
      label: (
        <ModalConfirm onOk={onDelete}>
          <div className={styles.delete}>
            <DeleteIcon />
            <span>{t("products.O'chirish")}</span>
          </div>
        </ModalConfirm>
      )
    }
  ];

  return (
    <>
      <div className={styles.card}>
        <div className={styles.top}>
          <Popover placement="top" arrow={false} content={usersContent} overlayClassName={styles.popover}>
            <div className={styles.images}>
              {users?.map((user, index) =>
                user?.image ? (
                  <img
                    key={user?.id}
                    src={user.image}
                    alt={user.full_name}
                    style={{
                      left: index * 20
                    }}
                  />
                ) : (
                  <span
                    key={user?.id}
                    style={{
                      left: index * 20
                    }}
                  >
                    <NotUserImage name={user?.full_name ? user?.full_name : name} />
                  </span>
                )
              )}
            </div>
          </Popover>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <span>
              <DotsVerticalIcon />
            </span>
          </Dropdown>
        </div>
        <h4 className={styles.bottom}>{name}</h4>
      </div>
    </>
  );
};

export default SettingsCashCard;
