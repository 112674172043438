import { Button, Form, Input, Modal } from "antd";
import LogoUysot from "../../../integration/assets/images/logo-uysot.png";

import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import { formRules } from "../../../../../app/utils/constants/formRules";
import styles from "./integrationCreate.module.scss";

const { useForm, Item } = Form;

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const IntegrationCreate = ({ open, setOpen }: Props) => {
  const [form] = useForm();

  const onFinish = () => {};

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      centered
      open={open}
      footer={false}
      closeIcon={<CloseIcon />}
      title={false}
      onCancel={onCancel}
      width={520}
      className={styles.integration_modal}
    >
      <div className={styles.title}>
        <div className={styles.title__logo}>
          <img src={LogoUysot} alt="LogoUysot" />
        </div>
        <h2>Uysot</h2>
        <p>Integratsiya qilish bilan ikki dastur ma’lumotlarini o’zaro almashing</p>
      </div>
      <div className={styles.content}>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          className={styles.form}
        >
          <Item name="id" label="Maxfiy kalit" rules={formRules()}>
            <Input placeholder="Maxsus maxfiy kalitni kiriting" />
          </Item>
          <Item>
            <Button type="primary" htmlType="submit" className={styles.button}>
              Faollashtirish
            </Button>
          </Item>
          <Item>
            <Button className={styles.button}>
              <img src={LogoUysot} alt="LogoUysot" /> Uysot dasturi bilan
            </Button>
          </Item>
          <p>Yopish</p>
        </Form>
      </div>
    </Modal>
  );
};

export default IntegrationCreate;
