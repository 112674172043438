import { useLocation } from "react-router-dom";

export function getLocationPathValue(props?: { index?: number; separator?: string } | number) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const location = useLocation();

  if (props && typeof props === "number") {
    return location?.pathname?.split("/")?.[props || 0] || "";
  }

  if (props && typeof props === "object") {
    return location?.pathname?.split(props?.separator || "/")?.[props?.index || 0] || "";
  }

  return location?.pathname;
}
