import React from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import { useGetOneCompanyFolder } from "../../service/queries";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";
import { useRoutePermissions } from "../../../../hooks/useRoutePermissions";
import { companyPersonSubmodules } from "../../utils/constants/routeSubmodules";
import CounterpartsModal from "../../components/counterparts/modal/CounterpartsModal";
import CounterpartsTable from "../../components/counterparts/content/table/CounterpartsTable";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const { data, isLoading } = useGetOneCompanyFolder(id);

  const { actions } = useRoutePermissions("Kontragent", companyPersonSubmodules);
  const folderActions = actions("Papkalar");

  const toCounterparts = () => {
    navigate(-1);
  };

  return (
    <div className={styles.detailed_counterparts}>
      <div className={styles.top}>
        <div className={styles.left}>
          <Button onClick={toCounterparts}>
            <ArrowNarrowIcon />
          </Button>
        </div>
      </div>
      <div className={styles.bottom}>
        <CounterpartsTable
          folderActions={folderActions}
          data={data}
          isLoading={isLoading}
        />
        <CounterpartsModal />
      </div>
    </div>
  );
};

export default Index;
