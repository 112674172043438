import React from "react";
import { Checkbox, Spin } from "antd";
import i18n from "i18next";

import { LoadingIndicator } from "../../../../app/components/loading-indicator/LoadingIndicator";
import { useGetCustomFieldSelect } from "../../../../app/service/queries";
import { CustomFieldLocationEnum } from "../../../../app/utils/enums/customFieldLocationEnum";
import { cx } from "../../../../app/utils/helpers/cx";
import { CustomFieldOptions } from "../../../../settings/utils/constants/customFieldTypes";
import { groupTypes } from "../../../utils/enums/groupTypes";

import styles from "./estimateGroup.module.scss";

type Props = {
  type: groupTypes;
  location: CustomFieldLocationEnum;
  checkedValue: { id?: number | undefined; type?: string | undefined };
  setCheckedValue: React.Dispatch<React.SetStateAction<{ id?: number | undefined; type?: groupTypes }>>;
};

const Content: React.FC<Props> = ({ location, checkedValue, setCheckedValue, type }) => {
  const { data: customFields, isLoading } = useGetCustomFieldSelect([location], true);

  const onChange =
    ({ id }: { id: number }) =>
    () => {
      if (checkedValue.id === id) {
        setCheckedValue({
          id: 0,
          type: undefined
        });
      } else {
        setCheckedValue({
          id,
          type
        });
      }
    };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      {customFields?.map(item => (
        <div
          key={item?.id}
          onClick={onChange({ id: item.id })}
          className={cx(styles.row, "cursor-pointer transition-all hover:bg-gray-100")}
        >
          <label htmlFor={String(item.id)} className="flex items-center gap-2">
            {CustomFieldOptions[item.type].icon}
            <span className="flex items-center">{item?.name[i18n.language]}</span>
          </label>
          <Checkbox
            id={String(item.id)}
            onChange={onChange({ id: item.id })}
            checked={item?.id === checkedValue.id && type === checkedValue.type}
          />
        </div>
      ))}
    </Spin>
  );
};

export default Content;
