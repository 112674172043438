import { Dropdown, MenuProps } from "antd";
import { useState } from "react";

import { useAppDispatch } from "../../../../../hooks/redux";
import { useRoutePermissions } from "../../../../../hooks/useRoutePermissions";
import { chatActions } from "../../../../../store/reducers/chatReducer";
import { ChatEnum } from "../../../../app/utils/enums/chatEnum";
import FlipBackwardIcon from "../../../assets/icons/FlipBackwardIcon";
import { useDefectRejected } from "../../../service/mutation";
import { DefectStatus } from "../../../utils/constants/DefectStatus";
import { routeSubmodules } from "../../../utils/constants/routeSubmodules";
import { WarehouseDefectModel } from "../../../utils/models/warehouseDefectModel";
import { defectViewActions } from "../../../../../store/reducers/defectViewReducer";
import UnreadMessageAction from "../../../../app/components/unread-message/UnreadMessageAction";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import EyeIcon from "../../../../projects/assets/icons/EyeIcon";
import { warehouseQueryNames } from "../../../utils/constants/warehouseQueryNames";

import styles from "./actionDropdown.module.scss";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "@tanstack/react-query";
import { useQueryParams } from "hooks/useQueryParams";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";

type Props = {
  record?: WarehouseDefectModel;
};

const ActionDropdown = ({ record }: Props) => {
  const dispatch = useAppDispatch();
  const qc = useQueryClient();
  const { severalSearchParams, searchParams } = useQueryParams();
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const defectActions = actions("Yaroqsiz mahsulotlar");
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const { setVisible } = chatActions;
  const defectRejected = useDefectRejected();
  const { setDefectView } = defectViewActions;

  const { t } = useTranslation();

  const onToggleDropdown = () => {
    setVisibleDropdown(prev => !prev);
  };

  const onDefectReject = (e: React.MouseEvent<HTMLElement>, id: number) => {
    defectRejected.mutate(id);
    setVisibleDropdown(false);
    e.stopPropagation();
  };

  const onOpenChat = (e: React.MouseEvent<HTMLElement>, record: WarehouseDefectModel | undefined) => {
    dispatch(
      setVisible({
        visible: true,
        objectId: record?.id,
        type: ChatEnum.WAREHOUSE_DEFECTS,
        dataKeys: [warehouseQueryNames.WAREHOUSE_DEFECT, searchParams],
        record
        // onAfterOpen: () => {
        //   if (record?.unread_message_count && record?.unread_message_count > 0) {
        //     qc.setQueryData(
        //       [warehouseQueryNames.WAREHOUSE_DEFECT, severalSearchParams(queryParamsKeys.TAB)],
        //       ({ data, current_page, total }: any) => {
        //         const initialData = data as WarehouseDefectModel[];
        //         return {
        //           total: total,
        //           current_page: current_page,
        //           data: initialData?.map(item => {
        //             if (item?.id === record?.id) {
        //               return {
        //                 ...item,
        //                 unread_message_count: 0
        //               };
        //             }
        //             return item;
        //           })
        //         };
        //       }
        //     );
        //   }
        // }
      })
    );
    setVisibleDropdown(false);
    e.stopPropagation();
  };

  const onClickActionIcon = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };

  const onOpenDefectView = () => {
    dispatch(
      setDefectView({
        visible: true,
        data: record
      })
    );

    setVisibleDropdown(false);
  };

  const customItems = () => {
    const items: MenuProps["items"] = [
      {
        key: "0",
        label: (
          <div className={styles.dropdown__item} onClick={onOpenDefectView}>
            <EyeIcon />
            Ko'rish
          </div>
        ),
        style: {
          padding: 0
        }
      }
    ];

    if (defectActions["chat"]) {
      items.push({
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenChat(e, record)}>
            <UnreadMessageAction count={record?.unread_message_count} />
          </div>
        ),
        style: {
          padding: 0
        }
      });
    }

    if (record?.status === DefectStatus.OPEN && defectActions["rejected"]) {
      items.push({
        key: "2",
        label: (
          <div className={styles.dropdown__item} onClick={e => onDefectReject(e, record?.id)}>
            <FlipBackwardIcon /> {t("warehouse.Qaytarish")}
          </div>
        ),
        style: {
          padding: 0
        }
      });
    }

    return { items };
  };

  return (
    <Dropdown
      menu={customItems()}
      trigger={["click", "contextMenu"]}
      open={visibleDropdown}
      onOpenChange={onToggleDropdown}
    >
      <UnreadMessageDots count={record?.unread_message_count} onClick={onClickActionIcon} />
    </Dropdown>
  );
};

export default ActionDropdown;
