import React, { useMemo } from "react";
import { Col, Form, FormInstance, Input, Row } from "antd";
import { Rule } from "antd/es/form";
import ConditionalRender from "features/app/components/conditional-render/ConditionalRender";
import { colors } from "features/app/utils/constants/colors";
import { RU } from "features/app/utils/constants/languages";
import { parseLocaledString } from "features/app/utils/helpers/parseLocaledString";
import { useGetCompanyPerson } from "features/counterparts/service/queries";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";
import { cx } from "modules/common";

import { useAppSelector } from "../../../../../hooks/redux";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import ProjectSelect from "../../../../app/components/project-select/project-select";
import { formRules } from "../../../../app/utils/constants/formRules";
import { PaymentTypeEnum } from "../../../../app/utils/constants/paymentTypeEnum";
import { getBaseCurrency } from "../../../../app/utils/helpers/baseCurrency";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";

import Additional from "./additional";

import styles from "../income-or-expense/thirth/thirdStep.module.scss";

const { Item } = Form;

const PayThirdStep: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { t } = useTranslation();
  const baseCurrency = getBaseCurrency();
  const { currencies } = useAuth();
  const { currency, type, form_data, payment_type } = useAppSelector(state => state.paymentReducer.pay_modal);
  const isCurrencyAmount = baseCurrency?.id !== currency?.id;

  const activeCurrency = useMemo(() => currencies?.find(item => item.id === currency?.id), [currency, currencies]);
  const { data: getCompanyPerson } = useGetCompanyPerson(Number(payment_type?.company_person?.id));

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("amount", localeFormatter(e.currentTarget.value));
  };

  const onChangeCurrencyAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldValue("currency_amount", localeFormatter(e.currentTarget.value));
  };

  const amountRules: Rule[] = [
    ...formRules(),
    {
      validator(_, value) {
        const parseValue = parseLocaledString(value || 0);
        const currencyValue = currency?.amount || 0;
        const formAmount = form_data?.amount || 0;

        if (!parseValue) return Promise.reject();

        if (type === PaymentTypeEnum.EXPENSE) {
          if (currencyValue > formAmount) {
            if (formAmount < parseValue) {
              return Promise.reject(
                `Kiritilgan miqdor ${formAmount?.toLocaleString(RU)} ${currency?.symbol} dan kichik bo'lishi kerak`
              );
            }
          } else if (currencyValue < parseValue) {
            return Promise.reject(
              `Kiritilgan miqdor ${currencyValue?.toLocaleString(RU)} ${currency?.symbol} dan kichik bo'lishi kerak`
            );
          }
        }

        return Promise.resolve();
      }
    }
  ];

  const personColorStyle = (amount: number): React.CSSProperties => {
    if (amount > 0) {
      return {
        background: colors.SUCCESS_50,
        color: colors.SUCCESS_500
      };
    }
    if (amount < 0) {
      return {
        background: colors.ERROR_50,
        color: colors?.ERROR_500
      };
    }

    return {
      background: colors.GRAY_50,
      color: colors.GRAY_500
    };
  };

  return (
    <div className={styles.thirth}>
      <ConditionalRender if={payment_type?.company_person?.id}>
        <div className="mb-5 mt-5 flex items-center gap-2">
          <span className="text-sm font-normal text-gray-600">{t("payment.Kontragent")}</span>
          <span className="text-base font-semibold text-gray-700">{payment_type?.company_person?.name}</span>
        </div>
      </ConditionalRender>
      <ConditionalRender if={!form_data?.project_id}>
        <ProjectSelect form={form} label={t("payment.Loyiha")} suffixIcon={<SelectSuffixIcon />} />
      </ConditionalRender>
      <Row gutter={16}>
        <Col span={isCurrencyAmount ? 14 : 24}>
          <Item name="amount" label="Summa" rules={amountRules} initialValue={form_data?.amount?.toLocaleString(RU)}>
            <Input placeholder="Summa" onChange={onChangeAmount} suffix={currency?.symbol} />
          </Item>
        </Col>
        <ConditionalRender if={isCurrencyAmount}>
          <Col span={10}>
            <Item
              name="currency_amount"
              label="Valyuta kursi"
              rules={formRules()}
              initialValue={activeCurrency?.amount}
            >
              <Input
                placeholder="Summa"
                onChange={onChangeCurrencyAmount}
                prefix={`${currency?.symbol} = `}
                suffix={baseCurrency?.symbol}
              />
            </Item>
          </Col>
        </ConditionalRender>
      </Row>
      {payment_type?.company_person?.id && getCompanyPerson?.balances && getCompanyPerson?.balances?.length > 0 && (
        <div className="my-4 flex items-center gap-2">
          {t("payment.Balans")}:{" "}
          <div className="flex items-center gap-2">
            {getCompanyPerson?.balances?.map((item, i) => (
              <span
                key={item.id}
                className={cx(
                  { "text-error-500": item?.amount < 0, "bg-error-50": item?.amount < 0 },
                  { "text-success-500": item?.amount > 0, "bg-success-50": item?.amount > 0 },
                  { "text-gray-700": item?.amount === 0, "bg-gray-50": item?.amount === 0 },
                  "rounded-lg px-2 py-1 font-medium"
                )}
              >
                {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
              </span>
            ))}
          </div>
        </div>
      )}
      <Additional />
    </div>
  );
};

export default PayThirdStep;
