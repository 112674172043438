import React, { useEffect, useMemo, useState } from "react";
import { Spin, Tooltip } from "antd";
import classNames from "classnames";

import { useQueryParams } from "hooks/useQueryParams";

import { useAppSelector } from "../../../../../../hooks/redux";
import { cx } from "../../../../../../modules/common";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import PopoverShortName from "../../../../../app/components/popover-short-name/PopoverShortName";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../app/service/queries";
import { tableConfigKeys } from "../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { getLocationPathValue } from "../../../../../app/utils/helpers/getLocationValue";
import { isEmptyArr } from "../../../../../app/utils/helpers/isEmptyArr";
import { CustomFieldSelectModel } from "../../../../../app/utils/models/customFieldSelectModel";
import WarningIcon from "../../../../assets/icons/WarningIcon";
import { useGetDynamicEstimateTask } from "../../../../service/queries";
import { DynamicEstimateStatusIcon } from "../../../../utils/constants/dynamicEstimateStatusIcon";
import { estimateTableDefaultData } from "../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../utils/helpers/estimateColumnsWidth";
import { DynamicEstimateSectionModel } from "../../../../utils/models/dynamicEstimateModel";
import EstimateCustomField from "../../estimate-custom-field/EstimateCustomField";
import Task from "../task/Task";

import ExpectedTotalAmount from "./expected/ExpectedTotalAmount";
import SpendTotalAmount from "./spend/SpendTotalAmount";

import styles from "./section.module.scss";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";

type Props = {
  section: DynamicEstimateSectionModel;
  expandedAll: boolean;
  isFeature: boolean;
};

const Section = ({ section, expandedAll, isFeature }: Props) => {
  const { queries } = useQueryParams();
  const projectId = getLocationPathValue(3);
  const [isActive, setIsActive] = useState(false);
  const { estimate_group: estimateGroupId } = queries();
  const { estimate_group } = useAppSelector(state => state.localStorageReducer);
  const { data: tasks, isLoading } = useGetDynamicEstimateTask(isActive, section?.id);
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);
  const { smetaContainer } = useOutletContext<OutletContextType>();

  const isGroup = useMemo(
    () => Boolean(estimateGroupId || (estimate_group && estimate_group?.some(item => item?.projectId === projectId))),
    [estimate_group, estimateGroupId, projectId]
  );

  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  const onSection = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    setIsActive(expandedAll);
  }, [expandedAll]);

  const sectionHeader = useMemo(() => {
    const filterConfigData = tableConfigData?.column?.filter(column => column?.checked);

    const basicElements = filterConfigData
      ?.filter(item => typeof item?.id === "string")
      ?.map(item => <div key={item.id} />);

    return {
      data: filterConfigData,
      elements: basicElements
    };
  }, [tableConfigData]);

  const headerStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth({
      // @ts-ignore
      column: sectionHeader.data,
      size: 10
    })} 16.25rem`
  };

  return (
    <div
      className={classNames(styles.section, {
        [styles.active]: isActive,
        [styles.group]: isGroup
      })}
    >
      <div className={styles.section__item} style={headerStyle}>
        <div className={cx("flex items-center", styles.left)}>
          <ConditionalRender if={!section?.plan}>
            <Tooltip getPopupContainer={() => smetaContainer} title="Rejadan tashqari">
              <div className={styles.warning}>
                <WarningIcon />
              </div>
            </Tooltip>
          </ConditionalRender>
          <div onClick={onSection}>
            <SelectSuffixIcon className={styles.selectIcon} placement={isActive ? "bottom" : "right"} />
          </div>
          <h3 className={styles.name}>
            <span>{section?.place}.</span>
            <PopoverShortName getPopoverContainer={() => smetaContainer} length={50} title={section?.name} />
          </h3>
        </div>
        {sectionHeader?.elements}
        {EstimateCustomField({
          isDynamicEstimate: true,
          values: section?.custom_field_values,
          customFields: customFieldsColumnRender,
          className: classNames(styles.form__item),
          locations: CustomFieldLocationEnum.SECTION
        })}
        <div className={cx(styles.right)}>
          {isFeature ? (
            <ExpectedTotalAmount getPopoverContainer={() => smetaContainer} section={section} />
          ) : (
            <SpendTotalAmount getPopoverContainer={() => smetaContainer} section={section} />
          )}
          <Tooltip getPopupContainer={() => smetaContainer} title={`${section.process} %`}>
            <div className={styles.status}>{DynamicEstimateStatusIcon[section.status]}</div>
          </Tooltip>
        </div>
      </div>
      <Spin spinning={isLoading && isActive} indicator={LoadingIndicator}>
        <ConditionalRender if={isActive && !isEmptyArr(tasks)}>
          {tasks?.map((task, index: number) =>
            task?.is_title ? (
              <h4 className={styles.title} key={task?.id}>
                {task?.name}
              </h4>
            ) : (
              <Task
                task={task}
                index={index}
                key={task?.id}
                isFeature={isFeature}
                sectionPlan={section?.plan}
                sectionPlace={section?.place}
              />
            )
          )}
        </ConditionalRender>
        <ConditionalRender if={isActive && isEmptyArr(tasks)}>
          <div className={styles.empty_task}>
            <TableEmpty description="Yaratilgan ishlar mavjud emas" />
          </div>
        </ConditionalRender>
      </Spin>
    </div>
  );
};

export default Section;
