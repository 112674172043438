import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Form, Modal, Select } from "antd";

import { useAppSelector } from "../../../../hooks/redux";
import { projectsReducerActions } from "../../../../store/reducers/projectsReducer";
import SelectSuffixIcon from "../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../app/components/table-empty/TableEmpty";
import { useUpdateProject } from "../../service/mutations";
import { useGetProjects } from "../../service/queries";

const EvictionModal: React.FC = () => {
  const [form] = Form.useForm();
  const location = useLocation();
  const { viewType } = useAppSelector(state => state.projectsReducer);
  const isProjectsPage = location.pathname?.split("/")[1] === "projects";
  const dispatch = useDispatch();
  const { setEvictionModal } = projectsReducerActions;
  const { data } = useGetProjects(
    !isProjectsPage,
    viewType,
    isProjectsPage ? location.pathname.split("/")?.length === 2 : location.pathname.split("/")?.length === 3
  );
  const updateProject = useUpdateProject();
  const { visible, id, folderId } = useAppSelector(state => state.projectsReducer.evictionModal);

  const onFinish = (values: { project_folder_id: number }) => {
    const projectFolderId = values.project_folder_id > 0 && values.project_folder_id ? values.project_folder_id : null;

    updateProject.mutateAsync({ id: id!, project_folder_id: projectFolderId }).then(onCancel);
  };

  const onCancel = () => {
    dispatch(
      setEvictionModal({
        visible: false
      })
    );
  };

  const onOk = () => form.submit();

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      folderId && form.setFieldValue("project_folder_id", folderId);
    } else {
      form.resetFields();
    }
  };

  return (
    <Modal
      centered
      onOk={onOk}
      title={null}
      open={visible}
      closeIcon={null}
      cancelText="Yopish"
      okText="Ko'chirish"
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      okButtonProps={{
        loading: updateProject.isLoading
      }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item label="Papka nomi" name="project_folder_id">
          <Select placeholder="Tanlash" suffixIcon={<SelectSuffixIcon />} notFoundContent={<TableEmpty />}>
            {data?.project_folders?.map(item => (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            ))}
            <Select.Option value={-1} key="clear">
              Papkadan chiqarish
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EvictionModal;
