import React, { Dispatch, SetStateAction } from "react";
import { FormInstance } from "antd";
import { FileListType } from "features/app/utils/models/fileListModel";
import { LaboratoryDrawerFormModel } from "features/warehouse/utils/models/laboratoryDrawerFormModel";

import AdditionalInfo from "./additional-info/AdditionalInfo";
import Footer from "./footer/Footer";

// import styles from "./right.module.scss";

type Props = {
  form: FormInstance<LaboratoryDrawerFormModel>;
  fileList: FileListType[];
  setFileList: Dispatch<SetStateAction<FileListType[]>>;
};

const Right: React.FC<Props> = ({ form, fileList, setFileList }) => (
  <div className="flex flex-col justify-between bg-white p-4">
    <div className="flex w-[404px] flex-col items-center gap-4">
      <AdditionalInfo form={form} fileList={fileList} setFileList={setFileList} />
    </div>
    <Footer form={form} />
  </div>
);

export default Right;
