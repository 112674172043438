import { Button } from "antd";
import dayjs, { Dayjs } from "dayjs";
import queryString from "query-string";
import { useMemo, useState } from "react";

import { useQueryParams } from "../../../../../../../hooks/useQueryParams";
import FilterDrawer from "../../../../../../app/components/filter-drawer/FilterDrawer";
import FilterDateRange from "../../../../../../app/components/filter-drawer/filter-date-range/FilterDateRange";
import FilterSelect from "../../../../../../app/components/filter-drawer/filter-select/FilterSelect";
import FiltersCount from "../../../../../../app/components/filter-drawer/filters-count/FiltersCount";
import { useGetUsersSelect } from "../../../../../../app/service/queries";
import { dayjsFormats } from "../../../../../../app/utils/constants/dayjsFormats";
import { queryParamsKeys } from "../../../../../../app/utils/constants/queryParamsKeys";
import FilterLinesIcon from "../../../../../../warehouse/assets/icons/FilterLinesIcon";
import UserIcon from "../../../../../assets/icons/UserIcon";

import styles from "../topActions.module.scss";

const queryStringArr = [queryParamsKeys.CREATORS_IDS, queryParamsKeys.MAX_DATE, queryParamsKeys.MIN_DATE];

const CompletedWorkFilter = () => {
  const { queries } = useQueryParams();
  const [open, setOpen] = useState<boolean>(false);
  const [creatorsSelected, setCreatorsSelected] = useState<string[] | null>(
    queryString.parse(location.search, {
      arrayFormat: "bracket"
    })[queryParamsKeys.CREATORS_IDS] as string[] | null
  );
  const { data: companyPersons } = useGetUsersSelect(open);
  const [dateValue, setDateValue] = useState<null | (Dayjs | null)[]>([
    queries()[queryParamsKeys.MIN_DATE] ? dayjs(queries()[queryParamsKeys.MIN_DATE], dayjsFormats.DATE) : null,
    queries()[queryParamsKeys.MAX_DATE] ? dayjs(queries()[queryParamsKeys.MAX_DATE], dayjsFormats.DATE) : null
  ]);

  const persons = useMemo(() => {
    const result = companyPersons?.map(item => {
      return { name: item?.full_name, ...item };
    });

    return result;
  }, [companyPersons]);

  const onOpenFilterDrawer = () => {
    setOpen(true);
  };

  return (
    <FilterDrawer
      open={open}
      setOpen={setOpen}
      filterButton={
        <Button onClick={onOpenFilterDrawer}>
          <FilterLinesIcon />
          Filter
          {<FiltersCount queryStringArr={queryStringArr} />}
        </Button>
      }
      queryStringArr={queryStringArr}
      height={165}
      selectedOptionsArr={[
        {
          queryKey: queryParamsKeys.CREATORS_IDS,
          selectedOptions: creatorsSelected
        }
      ]}
      setSelectedOptionsArr={[setCreatorsSelected] as never}
      dateValueArr={[
        {
          queryKey: queryParamsKeys.MIN_DATE,
          selectedValue: dateValue && dateValue[0]
        },
        {
          queryKey: queryParamsKeys.MAX_DATE,
          selectedValue: dateValue && dateValue[1]
        }
      ]}
      setDateValue={[setDateValue]}
    >
      <div className={styles.content}>
        <FilterDateRange value={dateValue} setValue={setDateValue} />
        <FilterSelect
          selectedOptions={creatorsSelected}
          setSelectedOptions={setCreatorsSelected}
          data={persons}
          multiple="multiple"
          icon={<UserIcon />}
          placeholder="Xodim"
          showSearch
        />
      </div>
    </FilterDrawer>
  );
};

export default CompletedWorkFilter;
