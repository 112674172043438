import { useState } from "react";

import { TaskModel } from "../../../../utils/models/taskModel";

import { TaskBody } from "./body/TaskBody";
import { TaskHeader } from "./header/TaskHeader";
import ProductAppend from "./product-append/ProductAppend";

import styles from "./task.module.scss";

type Props = {
  item: TaskModel;
  sectionPlan: boolean;
  place: number;
  isSearch: boolean;
};

export const Task = ({ item, sectionPlan, isSearch, place }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  return (
    <div className={styles.task}>
      <TaskHeader
        data={item}
        isActive={isActive}
        sectionPlace={place}
        sectionPlan={sectionPlan}
        setIsActive={setIsActive}
      />
      {isActive && (
        <div className="flex flex-col justify-center">
          <TaskBody
            task_id={item.id}
            active={isActive}
            taskPlan={item.plan}
            sectionPlan={sectionPlan}
            products={item.task_products}
            isSearch={isSearch}
          />
          <ProductAppend task_id={item.id} />
        </div>
      )}
    </div>
  );
};
