import React from "react";
import { NavLink } from "react-router-dom";
import { cx } from "features/app/utils/helpers/cx";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { rootPaths } from "../../../../routes/root/rootPaths";

import { getLinks } from "./SettingsLinks";

import cls from "./settingsLayout.module.scss";

type Props = {
  children: React.ReactNode;
};

const SettingsLayout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  const { permissions } = useAuth();
  const links = getLinks({ permissions });

  return (
    <div className={cls.settings}>
      <div className={cls.left}>
        <h4>{t("settings.interface.Sozlamalar")}</h4>
        <div className={cls.links}>
          {links.map(({ name, icon, link }) => (
            <NavLink
              key={name}
              to={`${rootPaths.SETTINGS}${link}`}
              className={({ isActive }) => cx(cls.item, isActive && cls.active)}
            >
              {icon} <span>{t(name)}</span>
            </NavLink>
          ))}
        </div>
      </div>
      <div className={cls.right}>{children}</div>
    </div>
  );
};

export default SettingsLayout;
