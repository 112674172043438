import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "antd";
import DownloadIcon from "features/app/assets/icons/DownloadIcon";
import { useGetCustomFieldSelect, useGetTableConfigs } from "features/app/service/queries";
import { tableData } from "features/app/utils/constants/defaultTableData";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "features/app/utils/enums/customFieldLocationEnum";
import { filterColumns } from "features/app/utils/helpers/filterColumns";
import { useWarehouseProductsExportExcel } from "features/warehouse/service/mutation";
import { routeSubmodules } from "features/warehouse/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { warehouseProductsAction } from "../../../../../store/reducers/warehouseProductsReducer";
import UnreadMessageDots from "../../../../app/components/unread-message/UnreadMessageDots";
import { cx } from "../../../../app/utils/helpers/cx";
import EyeIcon from "../../../../projects/assets/icons/EyeIcon";
import { WarehouseProductModel } from "../../../utils/models/WarehouseProductModel";

import styles from "./actionDots.module.scss";

type Props = {
  record: WarehouseProductModel;
};

const ActionDots = ({ record }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setResource } = warehouseProductsAction;
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const tableDefaultData = tableData(tableConfigKeys.WAREHOUSE_SINGLE_PRODUCT);
  const { actions } = useRoutePermissions("Omborxona", routeSubmodules);
  const productActions = actions("Omborxona");

  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.WAREHOUSE_SINGLE_PRODUCT, tableDefaultData);
  const { data: customFields } = useGetCustomFieldSelect([CustomFieldLocationEnum.WAREHOUSE_PRODUCT]);

  const exportExcelMutate = useWarehouseProductsExportExcel();

  const onToggleDropdown = () => {
    setVisibleDropdown(prev => !prev);
  };

  const onOpenTransfersView = (e: MouseEvent<HTMLElement>, record?: WarehouseProductModel) => {
    dispatch(
      setResource({
        visible: true,
        product: record
      })
    );
    e.stopPropagation();
    setVisibleDropdown(false);
  };

  const onExportExcel = (e: MouseEvent<HTMLElement>, record: WarehouseProductModel) => {
    const data = filterColumns({
      customFields,
      tableConfig: tableConfigData || tableDefaultData
    })
      ?.map(value => ({
        key: String(value?.id),
        name: value?.title
      }))
      ?.filter(item => typeof item?.name !== "object");

    if (data) {
      exportExcelMutate?.mutateAsync({
        columns: data,
        product_id: record.product.id,
        unit_id: record.unit.id
      });
    }
    e.stopPropagation();
    setVisibleDropdown(false);
  };

  const onClickDropdown = (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const resultItems = () => {
    const items = [
      {
        key: "0",
        label: (
          <div className={styles.dropdown__item} onClick={e => onOpenTransfersView(e, record)}>
            <EyeIcon />
            {t("warehouse.Ko'rish")}
          </div>
        ),
        style: {
          padding: 0
        },
        permissionKey: "view"
      },
      {
        key: "1",
        label: (
          <div className={styles.dropdown__item} onClick={e => onExportExcel(e, record)}>
            <DownloadIcon />
            {t("warehouse.Yuklab olish")}
          </div>
        ),
        style: {
          padding: 0
        },
        permissionKey: "upload"
      }
    ];

    return { items: items?.filter(el => productActions[el.permissionKey]) };
  };

  return (
    <div className={cx("action-dots flex h-full cursor-pointer items-center")} onClick={onClickDropdown}>
      <Dropdown menu={resultItems()} trigger={["click"]} open={visibleDropdown} onOpenChange={onToggleDropdown}>
        <UnreadMessageDots />
      </Dropdown>
    </div>
  );
};

export default ActionDots;
