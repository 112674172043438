import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CurrencySelectModel } from "features/app/utils/models/currencySelectModel";
import { ProjectReportProductSpendModel } from "features/projects/utils/models/projectModel";

interface InitialState {
  visible: boolean;
  props?: {
    data: ProjectReportProductSpendModel | undefined;
    currency: CurrencySelectModel | undefined;
  } | null;
}

const initialState: InitialState = {
  visible: false
};

const productSpentWidgetDrawerSlice = createSlice({
  name: "productSpentWidgetDrawer",
  initialState,
  reducers: {
    setOpenDrawer: (
      state,
      action: PayloadAction<{
        visible: boolean;
        props?: {
          data: ProjectReportProductSpendModel | undefined;
          currency: CurrencySelectModel | undefined;
        } | null;
      }>
    ) => {
      state.visible = action.payload.visible;
      state.props = action.payload.props;
    }
  }
});

export const productSpentWidgetDrawerActions = productSpentWidgetDrawerSlice.actions;
export default productSpentWidgetDrawerSlice.reducer;
