import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { supplyOfferActions } from "../../../../../../../../store/reducers/supplyOfferReducer";
import TableEmpty from "../../../../../../../app/components/table-empty/TableEmpty";
import { useGetCurrenciesSelect } from "../../../../../../../app/service/queries";
import { OfferPartyErrorModel } from "../../../../../../utils/models/offerPartyModel";

import styles from "../offerPartyModalTable.module.scss";

type Props = {
  uniqueId: string;
};

const { Option } = Select;

const OfferPartyCurrencySelect: React.FC<Props> = ({ uniqueId }) => {
  const dispatch = useDispatch();

  const { setOfferPartyLefItemData, setOfferPartyModalErrorItem } = supplyOfferActions;

  const { leftData, partyErrors } = useAppSelector(state => state.supplyOfferReducer.partyModal);

  const { data: currencies } = useGetCurrenciesSelect();

  const leftDataItem = useMemo(() => leftData?.find(item => item.unique_id === uniqueId), [leftData, uniqueId]);

  const onChangePerson = (e: number) => {
    dispatch(
      setOfferPartyLefItemData({
        key: "currency_id",
        value: e,
        uniqueId
      })
    );

    if (partyErrors?.length > 0) {
      dispatch(
        setOfferPartyModalErrorItem({
          key: "currency",
          uniqueId,
          action: !e
        })
      );
    }
  };

  const getOfferItemError = useCallback(
    (key: keyof OfferPartyErrorModel, uniqueId: string) => {
      if (partyErrors?.length > 0) {
        return partyErrors?.find(item => item.uniqueId === uniqueId)?.[key];
      }
      return false;
    },
    [partyErrors]
  );

  const errorClassName = (key: keyof OfferPartyErrorModel) =>
    getOfferItemError(key, uniqueId) ? styles.item__error : "";

  return (
    <div className={`${styles.item} ${errorClassName("currency")}`}>
      <Select
        disabled
        suffixIcon={null}
        placeholder="Ta'minotchi"
        onChange={onChangePerson}
        popupMatchSelectWidth={false}
        value={leftDataItem?.currency_id}
        notFoundContent={<TableEmpty description="Siz qidirgan valyuta mavjud emas" />}
      >
        {currencies?.map(item => (
          <Option key={item.id} value={item.id}>
            {item?.symbol}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default OfferPartyCurrencySelect;
