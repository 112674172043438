import { useParams } from "react-router-dom";
import { Form, Input, Modal, Select } from "antd";
import { useTranslation } from "react-i18next";

import { useAuth } from "modules/auth/hooks";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { inventoryReducerActions } from "../../../../../store/reducers/inventoryReducer";
import SelectSuffixIcon from "../../../../app/assets/icons/SelectSuffixIcon";
import { useGetUnitSelect } from "../../../../app/service/queries";
import { formRules } from "../../../../app/utils/constants/formRules";
import { CurrencyTypeEnum } from "../../../../app/utils/enums/currencyTypeEnum";
import { localeFormatter } from "../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import { parseParamsId } from "../../../../app/utils/helpers/parseParamsId";
import { selectFilterOption } from "../../../../app/utils/helpers/selectFilterOption";
import CounterpartsModal from "../../../../counterparts/components/counterparts/modal/CounterpartsModal";
import { useCreateInventory, useUpdateInventory } from "../../../service/mutations";
import { useGetCompanyCurrency, useGetFoldersSelect } from "../../../service/queries";
import { InventoryBodyModel } from "../../../utils/models/inventoryBodyModel";

import CompanyPersonSelect from "./company-person-select/CompanyPersonSelect";

import styles from "./inventoryCreate.module.scss";

const { Item, useForm } = Form;
const { Option } = Select;

const InventoryCreate = () => {
  const { i18n, t } = useTranslation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { id: inventoryFolderId } = parseParamsId(params?.id);
  const [form] = useForm<InventoryBodyModel>();
  const basicCurrency = useAuth().currencies.find(item => item?.type === CurrencyTypeEnum.BASE);
  const { visible, inventory } = useAppSelector(state => state.inventoryReducer.inventoryModalData);
  const { data: currencies, isLoading: isLoadingCurrencies } = useGetCompanyCurrency(visible);

  const { data: folders, isLoading: isLoadingFolders } = useGetFoldersSelect(inventory?.id);
  const { data: units, isLoading: isLoadingUnits } = useGetUnitSelect(visible);
  const { setInventoryModal } = inventoryReducerActions;
  const createInventory = useCreateInventory();
  const updateInventory = useUpdateInventory();

  const onAfterOpen = (visible: boolean) => {
    if (visible) {
      if (inventory?.id) {
        form.setFieldsValue({
          name: inventory?.name,
          code: inventory?.code,
          amount: localeFormatter(String(inventory?.amount)),
          amount_currency_id: inventory?.amount_currency?.id,
          service_amount: localeFormatter(String(inventory?.service_amount)),
          service_amount_currency_id: inventory?.service_amount_currency?.id,
          company_person_id: inventory?.company_person?.id,
          service_amount_unit_id: inventory?.service_amount_unit?.id
        });
      } else {
        form.setFieldValue("service_amount_unit_id", units && units[0].id);
      }
      form.setFieldValue("inventory_folder_id", inventoryFolderId && +inventoryFolderId);
    }
  };

  const onOk = () => {
    form.submit();
  };

  const onCancel = () => {
    dispatch(setInventoryModal({ visible: false }));
    form.resetFields();
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    form.setFieldValue(fieldName, localeFormatter(e.currentTarget.value));
  };

  const onFinish = (values: InventoryBodyModel) => {
    if (inventory?.id) {
      updateInventory
        .mutateAsync({
          ...values,
          amount: parseLocaledString(String(values.amount)),
          service_amount: parseLocaledString(String(values.service_amount)),
          id: inventory?.id,
          inventory_folder_id: values.inventory_folder_id
        })
        .then(() => onCancel());
    } else {
      createInventory
        .mutateAsync({
          ...values,
          amount: parseLocaledString(String(values.amount)),
          service_amount: parseLocaledString(String(values.service_amount)),
          inventory_folder_id: values.inventory_folder_id
        })
        .then(() => onCancel());
    }
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={visible}
      onCancel={onCancel}
      afterOpenChange={onAfterOpen}
      width={548}
      okButtonProps={{
        loading: createInventory.isLoading || updateInventory.isLoading
      }}
      title={inventory?.id ? t("Inventory.Inventarni tahrirlash") : t("Inventar.Inventar qo’shish")}
      cancelText={t("Inventar.Ortga")}
      okText={t("products.Saqlash")}
      className={styles.inventory_modal}
      zIndex={1001}
    >
      <Form form={form} onFinish={onFinish} layout="vertical" className={styles.form}>
        <Item<InventoryBodyModel> name="name" label={t("products.Nomi")} rules={formRules()}>
          <Input />
        </Item>
        <Item<InventoryBodyModel> name="code" label={t("Moliya.Kodi")} rules={formRules()}>
          <Input />
        </Item>
        <div className={styles.amount__block}>
          <Item<InventoryBodyModel> name="amount" label={t("Inventar.Tan narxi")} rules={formRules()}>
            <Input className={styles.amount} onChange={e => onChangeAmount(e, "amount")} />
          </Item>
          <Item<InventoryBodyModel>
            name="amount_currency_id"
            label={t("Moliya.Valyuta")}
            initialValue={basicCurrency?.id}
            rules={formRules()}
          >
            <Select
              suffixIcon={<SelectSuffixIcon />}
              loading={isLoadingCurrencies}
              className={styles.currencies}
              popupClassName={styles.currencies__option}
            >
              {currencies?.map(item => (
                <Option value={item.id} key={item.id}>
                  <div className={styles.currency}>
                    <img src={item.icon} alt={item.symbol} width={18} height={16} />
                    <span>{item.symbol}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <div className={styles.service__block}>
          <Item<InventoryBodyModel> name="service_amount" label={t("Inventar.Ish narxi")} rules={formRules()}>
            <Input className={styles.service_amount} onChange={e => onChangeAmount(e, "service_amount")} />
          </Item>
          <Item<InventoryBodyModel>
            name="service_amount_currency_id"
            label={t("Moliya.Valyuta")}
            initialValue={basicCurrency?.id}
            rules={formRules()}
          >
            <Select
              suffixIcon={<SelectSuffixIcon />}
              loading={isLoadingCurrencies}
              className={styles.currencies}
              popupClassName={styles.currencies__option}
            >
              {currencies?.map(item => (
                <Option value={item.id} key={item.id}>
                  <div className={styles.currency}>
                    <img src={item.icon} alt={item.symbol} width={18} height={16} />
                    <span>{item.symbol}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Item>
          <Item<InventoryBodyModel>
            label={t("products.Birligi")}
            name="service_amount_unit_id"
            initialValue={units && units[0]?.id}
          >
            <Select
              showSearch
              suffixIcon={<SelectSuffixIcon />}
              loading={isLoadingUnits}
              className={styles.unit}
              filterOption={selectFilterOption}
            >
              {units?.map(item => (
                <Option
                  value={item?.id}
                  key={item?.id}
                  props={{
                    name: item?.name[i18n.language]
                  }}
                >
                  {item?.name[i18n.language]}
                </Option>
              ))}
            </Select>
          </Item>
        </div>
        <CompanyPersonSelect form={form} />
        <Item<InventoryBodyModel> name="inventory_folder_id" label={t("Inventar.Papka")}>
          <Select suffixIcon={<SelectSuffixIcon />} loading={isLoadingFolders} className={styles.currencies}>
            {folders?.map(item => (
              <Option value={item.id} key={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Item>
      </Form>
      <CounterpartsModal />
    </Modal>
  );
};

export default InventoryCreate;
