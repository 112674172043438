import React, {
  // useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AddressModal from "../../../../app/components/address-modal/AddressModal";
import { Form, FormInstance, Input } from "antd";

import AddressIcon from "../../../../projects/assets/icons/AddressIcon";
// import { useAppSelector } from "../../../../../hooks/redux";

import styles from "./counterpartsModal.module.scss";

type Props = {
  form: FormInstance;
};

const CounterpartsAddress: React.FC<Props> = ({ form }) => {
  // const { address } = useAppSelector((state) => state.addressReducer.addressModal);
  const [addressModalVisible, setAddressModalVisible] = useState<boolean>(false);
  const { t } = useTranslation()

  const onOpenAddressAddModal = () => {
    setAddressModalVisible(true);
  };

  // useEffect(() => {
  //   if (address) {
  //     form.setFieldValue("address", address);
  //   }
  // }, [form, address]);

  return (
    <AddressModal
      visible={addressModalVisible}
      setVisibleModal={setAddressModalVisible}
      formInstance={form}
    >
      <div onClick={onOpenAddressAddModal}>
        <Form.Item name="address" label={t("Counterparts.Manzili")}>
          <Input
            onPressEnter={() => {
              setAddressModalVisible(false);
            }}
            className={styles.input}
            suffix={<AddressIcon />}
            placeholder={t("Counterparts.Manzili")}
          />
        </Form.Item>
      </div>
    </AddressModal>
  );
};

export default CounterpartsAddress;
