import React, { useState } from "react";

import SettingsPaymentTable from "./table/SettingsPaymentTable";
import SettingsPaymentTypeModal from "./modal/SettingsPaymentTypeModal";
import { PaymentTypeModalDataModel } from "../../../utils/models/payment-type/paymentTypeModalDataModel";

import styles from "./settingsPaymentTypes.module.scss";

const SettingsPaymentTypes: React.FC = () => {
  const [modalData, setModalData] = useState<PaymentTypeModalDataModel>({
    visible: false,
  });

  return (
    <div className={styles.payment_types}>
      <SettingsPaymentTable setModalData={setModalData} />
      <SettingsPaymentTypeModal modalData={modalData} setModalData={setModalData} />
    </div>
  );
};

export default SettingsPaymentTypes;
