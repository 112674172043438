import React, { useMemo, useState } from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import { cx } from "../../../../../../modules/common";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import ConditionalRender from "../../../../../app/components/conditional-render/ConditionalRender";
import PopoverShortName from "../../../../../app/components/popover-short-name/PopoverShortName";
import { useGetCustomFieldSelect, useGetTableConfigs } from "../../../../../app/service/queries";
import { tableConfigKeys } from "../../../../../app/utils/constants/tableConfigKeys";
import { CustomFieldLocationEnum } from "../../../../../app/utils/enums/customFieldLocationEnum";
import { CustomFieldSelectModel } from "../../../../../app/utils/models/customFieldSelectModel";
import { DynamicEstimateStatusIcon, estimateStatusNames } from "../../../../utils/constants/dynamicEstimateStatusIcon";
import { estimateTableDefaultData } from "../../../../utils/constants/estimateTableDefaultData";
import { estimateColumnsWidth } from "../../../../utils/helpers/estimateColumnsWidth";
import { DynamicEstimateTaskModel } from "../../../../utils/models/dynamicEstimateModel";
import EstimateCustomField from "../../estimate-custom-field/EstimateCustomField";
import Product from "../product/Product";
import WithOutEstimate from "../with-out-estimate/WithOutEstimate";

import ExpectedAmount from "./expected/expectedAmount";
import ExpectedQuantity from "./expected/expectedQuantity";
import ExpectedTotalAmount from "./expected/ExpectedTotalAmount";
import SpendAmount from "./spend/spendAmount";
import SpendQuantity from "./spend/spendQuantity";
import SpendTotalAmount from "./spend/SpendTotalAmount";

import styles from "./task.module.scss";
import { useOutletContext } from "react-router-dom";
import { OutletContextType } from "features/projects/pages/detailed-project/pages/estimate/pages/plan/ProjectPlan";

type Props = {
  task: DynamicEstimateTaskModel;
  sectionPlace: number;
  index: number;
  isFeature: boolean;
  sectionPlan: boolean;
};

const Task = ({ task, sectionPlace, index, isFeature, sectionPlan }: Props) => {
  const { i18n } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { smetaContainer } = useOutletContext<OutletContextType>();

  const onChange = () => {
    setIsActive(!isActive);
  };

  const { data: customFields } = useGetCustomFieldSelect([
    CustomFieldLocationEnum.SECTION,
    CustomFieldLocationEnum.TASK,
    CustomFieldLocationEnum.TASK_PRODUCT
  ]);
  const { data: tableConfigData } = useGetTableConfigs(tableConfigKeys.ESTIMATE_CONFIG, estimateTableDefaultData);

  const customFieldsColumnRender = useMemo(() => {
    const customFieldColumns: CustomFieldSelectModel[] = [];

    tableConfigData?.column?.forEach(column => {
      const currentCustomField = customFields?.find(col => col.id === column?.id);

      if (column?.id === currentCustomField?.id && column?.checked) {
        customFieldColumns.push(currentCustomField);
      }
    });

    return customFieldColumns;
  }, [customFields, tableConfigData?.column]);

  const gridStyle: React.CSSProperties | undefined = {
    gridTemplateColumns: `minmax(31.25rem, 1fr) ${estimateColumnsWidth(tableConfigData)} 16.25rem`
  };

  const getPopoverContainer = () => smetaContainer;

  return (
    <div className={`${styles.tasks} ${index !== 0 ? styles.child : ""}`}>
      <div className={styles.task} style={gridStyle} key={task.id}>
        <div className={styles.left}>
          <ConditionalRender if={!task?.plan && sectionPlan}>
            <WithOutEstimate />
          </ConditionalRender>
          <SelectSuffixIcon
            onClick={onChange}
            className="mr-[5px] cursor-pointer"
            placement={isActive ? "bottom" : "right"}
          />
          <p className={styles.name}>
            {sectionPlace}.{task.place}.{" "}
            <PopoverShortName getPopoverContainer={getPopoverContainer} title={task?.name} length={75} />
          </p>
        </div>
        {isFeature ? (
          <ExpectedQuantity getPopoverContainer={getPopoverContainer} task={task} />
        ) : (
          <SpendQuantity getPopoverContainer={getPopoverContainer} task={task} />
        )}
        <p className={styles.unit}>{task?.unit?.symbol[i18n.language]}</p>
        {isFeature ? (
          <ExpectedAmount getPopoverContainer={getPopoverContainer} task={task} />
        ) : (
          <SpendAmount getPopoverContainer={getPopoverContainer} task={task} />
        )}
        {EstimateCustomField({
          isDynamicEstimate: true,
          className: cx(styles.form__item),
          values: task?.custom_field_values,
          customFields: customFieldsColumnRender,
          locations: CustomFieldLocationEnum.TASK
        })}
        <div className={styles.right}>
          {isFeature ? (
            <ExpectedTotalAmount getPopoverContainer={getPopoverContainer} task={task} />
          ) : (
            <SpendTotalAmount getPopoverContainer={getPopoverContainer} task={task} />
          )}
          <Tooltip getPopupContainer={getPopoverContainer} title={estimateStatusNames[task.status]}>
            <div className={styles.status}>{DynamicEstimateStatusIcon[task.status]}</div>
          </Tooltip>
        </div>
      </div>
      <ConditionalRender if={isActive}>
        <Product
          taskId={task?.id}
          isActive={isActive}
          isFeature={isFeature}
          taskPlan={task?.plan}
          taskStatus={task?.status}
          sectionPlan={sectionPlan}
        />
      </ConditionalRender>
      {/* <Collapse items={items} collapsible="header" onChange={onChange} /> */}
    </div>
  );
};

export default Task;
