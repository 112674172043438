export const menuColors: { background: string; border: string }[] = [
  {
    background: "#F79009",
    border: "#F78609"
  },
  {
    background: "#12B76A",
    border: "#12AF6A"
  },
  {
    background: "#1E90FF",
    border: "#1E87FF"
  },
  {
    background: "#7A5AF8",
    border: "#7A50F8"
  },
  {
    background: "#F63D68",
    border: "#F63768"
  }
];
