import { ReactNode } from "react";

import { CheckIcon } from "../../../app/assets/icons/CheckIcon";
import CloseIcon from "../../../app/assets/icons/CloseIcon";
import ClockCircleIcon from "../../../supply/assets/icons/ClockCircleIcon";
import { InventoryStatusEnum } from "../enums/inventoryStatusEnum";

export const InventoryStatusValues: Record<InventoryStatusEnum, string> = {
  [InventoryStatusEnum.PENDING]: "Kutilmoqda",
  [InventoryStatusEnum.RECIEVED]: "Qabul qilingan",
  [InventoryStatusEnum.REJECTED]: "Rad etilgan"
};

export const InventoryStatusIcons: Record<InventoryStatusEnum, ReactNode> = {
  [InventoryStatusEnum.PENDING]: <ClockCircleIcon width={18} />,
  [InventoryStatusEnum.RECIEVED]: <CheckIcon width={18} />,
  [InventoryStatusEnum.REJECTED]: <CloseIcon width={18} />
};
