import { memo } from "react";

import { useAppSelector } from "../../../../../../hooks/redux";
import Section from "./section/Section";

import styles from "./Bottom.module.scss";

const Bottom = memo(() => {
  const sections = useAppSelector((state) => state.projectsReducer.sections);
  return (
    <div className={styles.bottom}>
      <div className={styles.sections}>
        {sections &&
          sections.map((estimate, index: number) => (
            <Section section={estimate} index={index} key={estimate.name + index} />
          ))}
      </div>
    </div>
  );
});

export default Bottom;
