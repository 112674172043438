import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Select } from "antd";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { uid } from "uid";

import { useAuth } from "modules/auth/hooks";

import { useAppSelector } from "../../../../../../hooks/redux";
import { useQueryParams } from "../../../../../../hooks/useQueryParams";
import SelectSuffixIcon from "../../../../../app/assets/icons/SelectSuffixIcon";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { LocalStorage } from "../../../../../app/service/LocalStorage";
import { useGetMineCashSelect } from "../../../../../app/service/queries";
import { RU } from "../../../../../app/utils/constants/languages";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { getBaseCurrency } from "../../../../../app/utils/helpers/baseCurrency";
import { paymnetInfoData } from "../../../../utils/constants/paymentInfoData";
import { PaymentTabKeys } from "../../../../utils/constants/paymentTabKeys";
import { PaymentFilterKeys, paymentFilterNames } from "../../../../utils/enums/paymentFilterKeys";

import CurrencySegmented from "./CurrencySegmented";
import PaymentCalendarSelect from "./PaymentCalendarSelect";

import styles from "./paymentInfoLeft.module.scss";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";
import { useChangeTableConfig } from "features/app/service/mutation";

const { Option } = Select;

type Props = {
  statistics: any;
};

const PaymentInfoLeft: React.FC<Props> = ({ statistics }) => {
  const { t } = useTranslation();
  const data = paymnetInfoData;
  const location = useLocation();
  const navigate = useNavigate();
  const { queries: allQuery, append, remove } = useQueryParams();

  const {
    statistics_type: tabKey,
    currency_id: currencyId,
    payment_type: paymentType,
    type: expectedPaymentType,
    filter_type: filterType,
    check_currency: checkCurrency
  } = allQuery();

  const { firstStatisticsType } = useAppSelector(state => state.paymentReducer);
  const queries = queryString.parse(location.search, { arrayFormat: "bracket" });
  const cashIds = queries[queryParamsKeys.CASH_IDS] as string[];
  const { currencies } = useAuth();
  const baseCurrency = getBaseCurrency();
  const secondCurrency = getBaseCurrency("second");

  const { data: cashes, isLoading } = useGetMineCashSelect(!tabKey || tabKey === PaymentTabKeys.INCOME_EXPENSE);
  const changeTableConfiguration = useChangeTableConfig(tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE, true, true);

  const currencySymbol = useMemo(
    () =>
      currencies?.find(item => {
        if (currencyId) return item.id === Number(currencyId);

        return item.type === "base";
      })?.symbol,
    [currencyId, currencies]
  );

  const onChangeCash = (value: number[]) => {
    const queryValue = queryString.parse(location.search, {
      arrayFormat: "bracket"
    });

    delete queryValue.cash_ids;

    if (value?.length > 0) {
      navigate({
        search: queryString.stringify({ ...queryValue, cash_ids: value }, { arrayFormat: "bracket" })
      });
      changeTableConfiguration.mutate({
        filter: queryString.stringify({ ...queryValue, cash_ids: value }, { arrayFormat: "bracket" })!,
        key: tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE
      });
    } else {
      navigate({
        search: queryString.stringify(queryValue, { arrayFormat: "bracket" })
      });
      changeTableConfiguration.mutate({
        filter: queryString.stringify(queryValue, { arrayFormat: "bracket" })!,
        key: tableConfigKeys.PAYMENT_INCOME_OR_EXPENSE
      });
    }
  };

  const onChangePaymentType = (value: string) => {
    const queryValue = queryString.parse(location.search);

    delete queryValue.cash_ids;
    delete queryValue.payment_type;

    navigate({
      search: `${queryString.stringify(queryValue, { arrayFormat: "bracket" })}&${
        value ? `${queryParamsKeys.PAYMENT_TYPE}=${value}` : ""
      }`
    });
  };

  const cashIdsSelectValue = cashIds?.map(item => Number(item)) ?? [];

  const onChangeCalendarFilter = (e: PaymentFilterKeys) => {
    if (e === PaymentFilterKeys.ALL) {
      remove(queryParamsKeys.FILTER_TYPE);
    } else {
      append(queryParamsKeys.FILTER_TYPE, e);
    }
  };

  const calendarFilterTypeValue = () => {
    if (filterType) return filterType as PaymentFilterKeys;

    return PaymentFilterKeys.ALL;
  };

  const filterSelect = {
    [PaymentTabKeys.INCOME_EXPENSE]:
      cashes && cashes?.length === 1 ? (
        <h3 className={styles.topName}>{cashes[0]?.name}</h3>
      ) : (
        <Select
          allowClear
          mode="multiple"
          showSearch={false}
          loading={isLoading}
          onChange={onChangeCash}
          value={cashIdsSelectValue}
          style={{
            minWidth: "10.6rem"
          }}
          placeholder={t("payment.Barcha kassalar")}
          notFoundContent={<TableEmpty />}
          popupMatchSelectWidth={false}
          suffixIcon={<SelectSuffixIcon />}
          className={styles.select}
        >
          {cashes?.map(item => (
            <Option key={item.id} value={item.id}>
              {item?.name}
            </Option>
          ))}
        </Select>
      ),
    [PaymentTabKeys.PAYMENT]: (
      <Select
        value={!paymentType ? "payment" : paymentType}
        loading={isLoading}
        placeholder={t("payment.Tanlash")}
        style={{
          minWidth: "6rem"
        }}
        defaultValue="payment"
        popupMatchSelectWidth={false}
        onChange={onChangePaymentType}
        suffixIcon={<SelectSuffixIcon />}
        className={styles.select}
      >
        <Option value="payment">{t("payment.To’lovlar bo’yicha")}</Option>
        <Option value="template">{t("payment.Guruhlangan holda")}</Option>
      </Select>
    ),
    [PaymentTabKeys.ORDER]: null,
    [PaymentTabKeys.CALENDAR]: (
      <Select
        placeholder={t("payment.Tanlang")}
        className={styles.select}
        popupMatchSelectWidth={false}
        value={calendarFilterTypeValue()}
        onChange={onChangeCalendarFilter}
        suffixIcon={<SelectSuffixIcon />}
        defaultValue={PaymentFilterKeys.ALL}
      >
        <Option value={PaymentFilterKeys.ORDER}>{t(`payment.${paymentFilterNames[PaymentFilterKeys.ORDER]}`)}</Option>
        <Option value={PaymentFilterKeys.EXPECTED_PAYMENT}>
          {t(`payment.${paymentFilterNames[PaymentFilterKeys.EXPECTED_PAYMENT]}`)}
        </Option>
        <Option value={PaymentFilterKeys.ALL}>{t(`payment.${paymentFilterNames[PaymentFilterKeys.ALL]}`)}</Option>
      </Select>
    )
  };

  const dataKey = () => {
    if (expectedPaymentType) return `${tabKey}-${expectedPaymentType}`;
    return tabKey;
  };

  const defaultDataKey = () =>
    firstStatisticsType.first === PaymentTabKeys.PAYMENT
      ? `${firstStatisticsType.first}-${firstStatisticsType?.second}`
      : firstStatisticsType.first;

  const returnBottomName = (
    item:
      | string
      | {
          name: string;
          key: string;
        },
    key?: "name" | "key"
  ): string => {
    if (typeof item === "object" && key) {
      return item?.[key];
    }

    return String(item);
  };

  const amountContent = (key: string) => {
    const paymentCurrency = LocalStorage.get("payment_currency");
    const checkCurrencyValue = checkCurrency || paymentCurrency;
    const { amount__sum, amount__item } = styles;
    const baseAmount = statistics?.[key]?.toLocaleString(RU) ?? 0;
    const baseCurrencySymbol = currencySymbol;

    if (!checkCurrencyValue || tabKey !== PaymentTabKeys.INCOME_EXPENSE) {
      return (
        <div className={amount__item}>
          {baseAmount}
          <h5>{baseCurrencySymbol}</h5>
        </div>
      );
    }

    if (checkCurrencyValue === PaymentFilterKeys.ALL) {
      if (!currencyId) {
        return (
          <div className="flex flex-wrap items-center gap-4">
            {statistics?.currencies?.map((item: any) => {
              const { id, symbol, statistics } = item;
              return (
                <div key={id} className="flex items-end gap-1.5">
                  <div className={amount__sum}>{statistics?.[key]?.toLocaleString(RU) ?? 0}</div>
                  <h5>{symbol}</h5>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div className={amount__item}>
            {baseAmount}
            <h5>{baseCurrencySymbol}</h5>
          </div>
        );
      }
    }

    if (currencyId && checkCurrencyValue !== PaymentFilterKeys.ALL) {
      return (
        <div className={amount__item}>
          {baseAmount}
          <h5>{baseCurrencySymbol}</h5>
        </div>
      );
    }

    if (!currencyId && String(checkCurrencyValue) !== String(baseCurrency?.id)) {
      const secondAmount = statistics?.[`second_${key}`]?.toLocaleString(RU) ?? 0;
      const secondCurrencySymbol = secondCurrency?.symbol;

      return (
        <div className={amount__item}>
          {secondAmount}
          <h5>{secondCurrencySymbol}</h5>
        </div>
      );
    }

    return (
      <div className={amount__item}>
        {baseAmount}
        <h5>{baseCurrencySymbol}</h5>
      </div>
    );
  };

  return (
    <div className={styles.left}>
      <div className={styles.top}>
        <div className={styles.name}>
          <h2>
            {t(`payment.${data[dataKey() as keyof typeof data]?.name}`) ??
              t(`payment.${data[defaultDataKey() as keyof typeof data]?.name}`)}
          </h2>
          <span>{filterSelect[(tabKey ?? firstStatisticsType.first) as keyof typeof filterSelect]}</span>
        </div>
        <div className={styles.filter}>
          {/* {(firstStatisticsType?.first === PaymentTabKeys.INCOME_EXPENSE || tabKey === PaymentTabKeys.INCOME_EXPENSE) &&
            (viewDatePicker ? <PaymentDatePicker /> : null)} */}
          <PaymentCalendarSelect />
          <CurrencySegmented />
        </div>
      </div>
      <div className={styles.bottom}>
        {data[(dataKey() ?? defaultDataKey()) as keyof typeof data]?.label?.map(item => {
          const name = returnBottomName(item, "name");
          const key = returnBottomName(item, "key");

          return (
            <div className={styles.amount} key={uid()}>
              <p>{t(`payment.${name}`)}</p>
              {amountContent(key)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PaymentInfoLeft;
