import React from "react";
import { useTranslation } from "react-i18next";
import { BreadcrumbItemType } from "antd/es/breadcrumb/Breadcrumb";

import FolderIcon from "../../../../../app/assets/icons/FolderIcon";
import { CategoryDevModel } from "../../../../utils/models/product-dev/categoryDevModel";

import styles from "./settingsProductsTemplateFolderCard.module.scss";
import { sliceText } from "../../../../../app/utils/helpers/sliceText";
import { Tooltip } from "antd";

interface Props extends CategoryDevModel {
  isCategory: boolean;
  setTabKey: React.Dispatch<React.SetStateAction<string>>;
  setBreadcrumbItems: React.Dispatch<React.SetStateAction<BreadcrumbItemType[]>>;
  setFolderId: React.Dispatch<
    React.SetStateAction<{
      category_id?: number | undefined;
      sub_category_id?: number | undefined;
    }>
  >;
}

const SettingsProductsTemplateFolderCard: React.FC<Props> = ({
  id,
  products_count,
  name,
  color,
  setTabKey,
  isCategory,
  setFolderId,
  setBreadcrumbItems,
}) => {
  const { i18n, t } = useTranslation();

  const changeFolderId = () => {
    setFolderId((value) => {
      if (isCategory) {
        return {
          category_id: id,
          sub_category_id: value.sub_category_id,
        };
      } else {
        return {
          category_id: value.category_id,
          sub_category_id: id,
        };
      }
    });
  };

  const onClick = () => {
    setTabKey(isCategory ? "2" : "3");
    changeFolderId();

    setBreadcrumbItems((value) => {
      return [
        ...value,
        {
          title: name[i18n.language],
          onClick: () => {
            setTabKey(isCategory ? "2" : "3");
            changeFolderId();
            setBreadcrumbItems((value) =>
              isCategory ? [value[0], value[1]] : value
            );
          },
        },
      ];
    });
  };

  return (
    <div className={styles.folder} onClick={onClick}>
      <FolderIcon color={color} />
      <div className={styles.right}>
        <h4>
          {name[i18n.language]?.length > 15 ? (
            <Tooltip title={name[i18n.language]}>
              {sliceText(name[i18n.language], 15)}
            </Tooltip>
          ) : (
            name[i18n.language]
          )}
        </h4>
        <p>
          {products_count} {t("products.shablon")}
        </p>
      </div>
    </div>
  );
};

export default SettingsProductsTemplateFolderCard;
