import React, { useState } from "react";

import { useGetCurrencies } from "../../../service/queries";
import { CurrencyModalDataModel } from "../../../utils/models/currency/currencyModalDataModel";
import SettingsCurrencyModal from "./modal/SettingsCurrencyModal";
import SettingsCurrencyTable from "./table/SettingsCurrencyTable";

import styles from "./settingsCurrencies.module.scss";

const SettingsCurrencies: React.FC = () => {
  const { data, isLoading } = useGetCurrencies();
  const [modalData, setModalData] = useState<CurrencyModalDataModel>({
    visible: false,
  });

  return (
    <div className={styles.currencies}>
      <SettingsCurrencyTable
        data={data}
        isLoading={isLoading}
        setModalData={setModalData}
      />
      <SettingsCurrencyModal
        modalData={modalData}
        setModalData={setModalData}
        basicCurrencyId={data?.basic_currency}
      />
    </div>
  );
};

export default SettingsCurrencies;
