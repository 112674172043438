import React from "react";
import IncomeExpenseView from "features/payment/components/bottom/income-expense-view/IncomeExpenseView";

import { useSelector } from "store";

import { useGetProjectByResourceInDetailView } from "modules/dashboard/queries/use-get-project-by-resource-in-detail-view";
import { useGetProjectByResourceOthersInDetailView } from "modules/dashboard/queries/use-get-project-by-resource-others-in-detail";

import Bottom from "./bottom/bottom";
import OthersTable from "./others-table/others-table";
import Top from "./top/top";

const Content: React.FC = () => {
  const { props, id, visible } = useSelector(state => state.productSpentWidgetDetailDrawerReducer);

  const { projectByResourceInDetailView, isLoading } = useGetProjectByResourceInDetailView({
    enabled: visible,
    detailedId: Number(id),
    type: props?.type
  });

  const { projectByResourceOthersInDetailView, isLoading: isTheOthersLoading } =
    useGetProjectByResourceOthersInDetailView({ enabled: visible, detailedId: Number(id), type: props?.type });

  return (
    <div className="flex w-max flex-col gap-4 p-5">
      {props?.type !== "another" && <Top product={props?.product} />}
      {props?.type !== "another" ? (
        <Bottom data={projectByResourceInDetailView} isLoading={isLoading} />
      ) : (
        <OthersTable data={projectByResourceOthersInDetailView} isLoading={isTheOthersLoading} />
      )}
      <IncomeExpenseView />
    </div>
  );
};

export default Content;
