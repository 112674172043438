import React from "react";
import { Form, FormInstance, Input } from "antd";
import { Rule } from "antd/es/form";

import { useAppSelector } from "../../../../../../../../hooks/redux";
import { useGetUnitSelect } from "../../../../../../../app/service/queries";
import { formRules } from "../../../../../../../app/utils/constants/formRules";
import { localeFormatter } from "../../../../../../../app/utils/helpers/localeFormatter";
import { parseLocaledString } from "../../../../../../../app/utils/helpers/parseLocaledString";
import { pieceRemoveNaN } from "../../../../../../../app/utils/helpers/pieceRemoveNaN";
import { formatterPrice } from "../../../../../../utils/helpers/partyModalTableActions";
import { PartyFormProductsModel } from "../../../../../../utils/models/partyModalFormModel";

type Props = {
  index: number;
  groupIndex: number;
  form: FormInstance;
};

const { Item, useWatch } = Form;

const PersonQuantity: React.FC<Props> = ({ groupIndex, index, form }) => {
  const fieldName = ["person_group", groupIndex, "data", index, "quantity"];
  const totalFieldName = ["person_group", groupIndex, "data", index, "total"];
  const { isView, updateProduct } = useAppSelector(state => state.supplyReducer.partyModal);

  const { data: units } = useGetUnitSelect();
  // const products = useWatch(["person_group", groupIndex, "data"], form) as PartyFormProductsModel[];
  const products = form.getFieldValue(["person_group", groupIndex, "data"]) as PartyFormProductsModel[];

  const onChangeCount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const findProduct = products[index];
    const unitValue = units?.find(item => item.id === findProduct.unit_id);
    const removeNanValue = pieceRemoveNaN(e.currentTarget.value, unitValue?.is_piece);
    const newValue = localeFormatter(removeNanValue);

    form.setFieldValue(fieldName, newValue);

    form.setFieldValue(
      totalFieldName,
      formatterPrice(parseLocaledString(newValue) * parseLocaledString(findProduct?.amount))
    );
  };

  const quantityRules: Rule[] = [
    ...formRules()!,
    {
      validator: (_, value: string) => {
        const parseQuantity = parseLocaledString(String(value));

        if (parseQuantity > 0) return Promise.resolve();
        return Promise.reject();
      }
    }
  ];

  // const value = () => form.getFieldValue(fieldName);

  return (
    <>
      {/* <PerfItem */}
      {/*  placeholder={<div className="p-[0.62rem]">{value()}</div>} */}
      {/*  visible={!(isView && !updateProduct?.condition)} */}
      {/* > */}
      <Item name={fieldName} rules={quantityRules}>
        <Input
          step="0.01"
          placeholder="Soni"
          autoComplete="off"
          onChange={onChangeCount}
          disabled={isView && !updateProduct?.condition}
        />
      </Item>
      {/* </PerfItem> */}
    </>
  );
};

export default PersonQuantity;
