import React, { useCallback } from "react";
import { Col, Row, Spin } from "antd";
import { LoadingIndicator } from "features/app/components/loading-indicator/LoadingIndicator";
import { RU } from "features/app/utils/constants/languages";
import { PaymentTypeEnum } from "features/app/utils/constants/paymentTypeEnum";
import { useTranslation } from "react-i18next";

import { cx } from "modules/common";

import { ActViewModel } from "../../../../../utils/models/actViewModel";

import styles from "./actModal.module.scss";

type Props = {
  mutual?: boolean;
  isLoading: boolean;
  data: ActViewModel | undefined;
};

const ActViewTable: React.FC<Props> = ({ data, isLoading, mutual }) => {
  const { t } = useTranslation();

  const generateTransactionsAmounts = useCallback(
    (type: "income" | "expense") => {
      const amounts: { amount: number; symbol: string; id: number }[] = [];

      data?.payments
        ?.filter(item => type === item?.type)
        ?.forEach(item => {
          const findAmountIndex = amounts?.findIndex(i => i?.id === item?.currency?.currency_id);

          if (findAmountIndex < 0) {
            amounts.push({
              amount: item?.amount,
              id: item?.currency?.currency_id,
              symbol: item?.currency?.symbol
            });
          } else {
            amounts[findAmountIndex] = {
              ...amounts[findAmountIndex],
              amount: item?.amount + amounts[findAmountIndex]?.amount
            };
          }
        });

      return amounts;
    },
    [data?.payments]
  );

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={cx(styles.act__table, "max-h-[56vh] overflow-y-auto")}>
        <Row className={cx(styles.header, "sticky top-0")}>
          <Col span={6}>Boshlanish sanasi</Col>
          <Col span={6}>Manba</Col>
          <Col span={6}>{mutual ? "Debit" : "Kredit"}</Col>
          <Col span={6}>{mutual ? "Kredit" : "Debit"}</Col>
        </Row>
        <Row className={styles.initial}>
          <Col span={12} className={styles.bold}>
            Dastlabki balans
          </Col>
          <Col span={6} className={styles.initial__col}>
            {data?.balances?.map(item =>
              item?.start_amount >= 0 ? (
                <div key={item?.id} className={styles.initial__item}>
                  {item?.start_amount?.toLocaleString(RU)} {item?.currency?.symbol}
                </div>
              ) : null
            )}
          </Col>
          <Col span={6} className={styles.initial__col}>
            {data?.balances?.map(item =>
              item?.start_amount <= 0 ? (
                <div key={item?.id} className={styles.initial__item}>
                  {item?.start_amount?.toLocaleString(RU)} {item?.currency?.symbol}
                </div>
              ) : null
            )}
          </Col>
        </Row>
        <Row className={styles.payments}>
          {data?.payments?.map(item => (
            <React.Fragment key={item.id}>
              <Col span={6}>{item?.date}</Col>
              <Col span={6} className={styles.source}>
                {item?.sources || `${t("Counterparts.To'lov")}-${item?.id}`}
              </Col>
              <Col span={6} className={styles.bold}>
                {item?.type === PaymentTypeEnum.EXPENSE
                  ? `${item?.amount?.toLocaleString(RU)}  ${item?.currency?.symbol}`
                  : null}
              </Col>
              <Col span={6} className={`${styles.bold} ${styles.not_border}`}>
                {item?.type === PaymentTypeEnum.INCOME
                  ? `${item?.amount?.toLocaleString(RU)}  ${item?.currency?.symbol}`
                  : null}
              </Col>
            </React.Fragment>
          ))}
        </Row>
        <Row className={styles.initial}>
          <Col span={12} className={styles.bold}>
            Davrdagi operatsiyalar
          </Col>
          <Col span={6} className={styles.initial__col}>
            {/* {data?.balances?.map(item => */}
            {/*  item?.amount - item?.start_amount >= 0 ? ( */}
            {/*    <div key={item?.id} className={styles.initial__item}> */}
            {/*      {(item?.amount - item?.start_amount)?.toLocaleString(RU)} {item?.currency?.symbol} */}
            {/*    </div> */}
            {/*  ) : null */}
            {/* )} */}
            {generateTransactionsAmounts("expense")?.map(item => (
              <div key={item?.id} className={styles.initial__item}>
                {item?.amount?.toLocaleString(RU)} {item?.symbol}
              </div>
            ))}
          </Col>
          <Col span={6} className={styles.initial__col}>
            {/* {data?.balances?.map(item => */}
            {/*  item?.amount - item?.start_amount <= 0 ? ( */}
            {/*    <div key={item?.id} className={styles.initial__item}> */}
            {/*      {(item?.amount - item?.start_amount)?.toLocaleString(RU)} {item?.currency?.symbol} */}
            {/*    </div> */}
            {/*  ) : null */}
            {/* )} */}
            {generateTransactionsAmounts("income")?.map(item => (
              <div key={item?.id} className={styles.initial__item}>
                {item?.amount?.toLocaleString(RU)} {item?.symbol}
              </div>
            ))}
          </Col>
        </Row>
        <Row className={`${styles.initial} ${styles.final}`}>
          <Col span={12} className={styles.bold}>
            Yakuniy balans
          </Col>
          <Col span={6} className={styles.initial__col}>
            {data?.balances?.map(item =>
              item?.amount >= 0 ? (
                <div key={item?.id} className={styles.initial__item}>
                  {item?.amount?.toLocaleString(RU)} {item?.currency?.symbol}
                </div>
              ) : null
            )}
          </Col>
          <Col span={6} className={styles.initial__col}>
            {data?.balances?.map(item =>
              item?.amount <= 0 ? (
                <div key={item?.id} className={styles.initial__item}>
                  {Math.abs(item?.amount)?.toLocaleString(RU)} {item?.currency?.symbol}
                </div>
              ) : null
            )}
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default ActViewTable;
