import React from "react";
import { Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/es/checkbox";

import { CustomFieldSelectModel } from "../../../../app/utils/models/customFieldSelectModel";

type Props = {
  item: CustomFieldSelectModel;
  setData: React.Dispatch<React.SetStateAction<unknown[]>>;
};

const GeneralEditingFieldCheckbox: React.FC<Props> = ({
  item: propsField,
  setData,
}) => {
  const { i18n } = useTranslation();

  const onChange = (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    setData(
      (old) =>
        old?.map((item) => ({
          // @ts-ignore
          ...item,
          // @ts-ignore
          custom_field_values: item?.custom_field_values?.map((field) => ({
            ...field,
            value:
              field?.custom_field_id === propsField?.id && checked
                ? propsField?.id
                : field?.value,
          })),
        }))
    );
  };

  return <Checkbox onChange={onChange}>{propsField?.name[i18n.language]}</Checkbox>;
};

export default GeneralEditingFieldCheckbox;
