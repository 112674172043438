import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ColumnsType } from "antd/lib/table";
import { colors } from "features/app/utils/constants/colors";
import ExpectedExpenseIcon from "features/payment/assets/icons/ExpectedExpenseIcon";
import ExpectedIncomeIcon from "features/payment/assets/icons/ExpectedIncomeIcon";

import { Table } from "components";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import { queryParamsKeys } from "../../../../../app/utils/constants/queryParamsKeys";
import { tableIndex } from "../../../../../app/utils/helpers/tableIndex";
import { PaymentContext } from "../../../../hooks/PaymentContext";
import { useGetExpectedPaymentTemplate } from "../../../../service/queries";
import { ExpectedPaymentTemplateModel } from "../../../../utils/models/expectedPaymentTemplateModel";
import { PaymentSourceElement } from "../../income-expense-view/PaymentSourceElement";
import { useChangeTableConfig } from "features/app/service/mutation";
import { useGetTableConfigs } from "features/app/service/queries";
import { tableConfigKeys } from "features/app/utils/constants/tableConfigKeys";

import styles from "./expectedPaymentTemplateTable.module.scss";
import { useRoutePermissions } from "hooks/useRoutePermissions";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import useDebounce from "components/use-debounce/use-debounce";

const ExpectedPaymentTemplateTable: React.FC = () => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const incomeExpenseActions = actions("Kirim-chiqim");
  const { t } = useTranslation();
  const { setContext } = useContext(PaymentContext);
  const dispatch = useDispatch();
  const { setPayModal } = paymentReducerActions;
  const [searchParams] = useSearchParams();
  const type = searchParams.get(queryParamsKeys.TYPE) as PaymentTypeEnum;
  const { data, isLoading } = useGetExpectedPaymentTemplate();

  const changeTableConfig = useChangeTableConfig(
    type === "income"
      ? tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_INCOME
      : tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_EXPENSE,
    true
  );
  const { data: tableConfig } = useGetTableConfigs(
    type === "income"
      ? tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_INCOME
      : tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_EXPENSE
  );

  const onPayment = (record: ExpectedPaymentTemplateModel) => {
    dispatch(
      setPayModal({
        type,
        visible: true,
        isTemplate: true,
        form_data: {
          id: record.id,
          description: undefined,
          amount: record.debt_amount,
          project_id: record?.project?.id,
          currency_id: record.currency.id
        }
      })
    );
  };

  const onOpenView = (record: ExpectedPaymentTemplateModel) => {
    setContext({
      infoModalData: {
        visible: true,
        id: record.id,
        creatorId: record?.creator?.id,
        type
      }
    });
  };

  const paidAmountPercent = (paidAmount: number, amount: number) => {
    const percentAmount = (paidAmount / amount) * 100;
    const percent = percentAmount || 0;
    const percentFixed = percent?.toFixed(2);
    let color = colors.GRAY_700;

    if (percent <= 10) {
      color = colors.ERROR_500;
    }
    if (percent >= 99) {
      color = colors.SUCCESS_500;
    }

    return (
      <span
        style={{
          color
        }}
      >
        {percent ? percentFixed : 0} %
      </span>
    );
  };

  const [columns, setColumns] = useState<ColumnsType<ExpectedPaymentTemplateModel>>([
    {
      title: "№",
      render: (_, __, index) => tableIndex(index, data!?.current_page),
      width: 100,
      className: "index-column"
    },
    {
      title: `${t("payment.Umumiy summa")}`,
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.amount?.toLocaleString()} {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "payment-template-amount"
    },
    {
      title: `${t("payment.Qoldiq summa")}`,
      render: (record: ExpectedPaymentTemplateModel) => (
        <span>
          {record.debt_amount?.toLocaleString()} {record?.currency?.symbol}
        </span>
      ),
      width: 250,
      className: "payment-template-debt-amount"
    },
    {
      title: `${t("payment.Jarayon (foizda)")}`,
      render: (_, record) => paidAmountPercent(record.paid_amount, record.amount),
      width: 250,
      className: "payment-template-paid-amount"
    },
    {
      title: `${t("payment.Manbaa")}`,
      render: (record: ExpectedPaymentTemplateModel) => <PaymentSourceElement record={record as never} />,
      width: 250,
      className: "payment-template-source"
    },
    {
      title: `${t("payment.Loyiha")}`,
      render: (record: ExpectedPaymentTemplateModel) => record.project?.name,
      width: 250,
      className: "payment-template-project"
    },
    {
      title: `${t("payment.Xodim")}`,
      align: "right",
      render: (_, record) => <CustomAvatar name={record?.creator?.full_name} image={record?.creator?.image} />,
      width: 250,
      className: "payment-template-avatar"
    },
    {
      ...(incomeExpenseActions?.create
        ? {
            width: 180,
            render(record) {
              return (
                <div className={styles.action}>
                  <Button
                    onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                      e.stopPropagation();
                      onPayment(record);
                    }}
                  >
                    {type === PaymentTypeEnum.INCOME ? <ExpectedIncomeIcon /> : <ExpectedExpenseIcon />}
                    <span>
                      {type === PaymentTypeEnum.INCOME ? t("payment.Kirim qilish") : t("payment.Qarzni to'lash")}
                    </span>
                  </Button>
                </div>
              );
            },
            className: "payment-template-actions"
          }
        : {})
    }
  ]);

  useEffect(() => {
    if (tableConfig!?.width_data!?.length > 0) {
      const newColumns = tableConfig?.width_data?.map(column => ({
        ...columns?.find(el => el.className === column.className),
        width: column.width
      }));

      setColumns(newColumns as never);
    }
  }, [tableConfig]);

  const saveColumns = () => {
    changeTableConfig.mutateAsync({
      key:
        type === "income"
          ? tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_INCOME
          : tableConfigKeys.PAYMENT_EXPECTED_TEMPLATE_EXPENSE,
      width_data: JSON.stringify(columns)
    });
  };

  const debouncedSaveColumns = useDebounce(saveColumns, 800, timeoutRef);

  const onChangeColumns = (newColumns: ColumnsType<ExpectedPaymentTemplateModel>) => {
    setColumns(newColumns);
    debouncedSaveColumns();
  };

  return (
    <div className={styles.pending_payments}>
      <div className={styles.top}>
        <Table<ExpectedPaymentTemplateModel>
          onChangeColumns={onChangeColumns}
          columns={columns}
          rowClassName={styles.on_row}
          dataSource={data?.data}
          pagination={false}
          rowKey={row => row.id}
          onRow={record => ({
            onClick: () => onOpenView(record)
          })}
          locale={{
            emptyText: <TableEmpty />
          }}
          loading={{
            spinning: isLoading,
            indicator: LoadingIndicator
          }}
        />
      </div>
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            total: data?.total ?? 10
          }}
        />
      </div>
    </div>
  );
};

export default ExpectedPaymentTemplateTable;
