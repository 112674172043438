import React from "react";
import { NotificationModel } from "features/app/utils/models/notificationModel";
import { useTranslation } from "react-i18next";
import { useAuth } from "modules/auth/hooks";
import { ConfirmationUserStatusEnum } from "features/app/utils/enums/confirmationUserStatusEnum";

import { RU, colors, cx } from "modules/common";
import { notificationTypesTitleValue } from "features/app/utils/constants/notificationTypes";
import { Button, Collapse } from "antd";
import { ConfirmationStatusIcons } from "features/app/utils/constants/confirmationStatusIcons";
import { getFirstLetter } from "features/app/utils/helpers/getFirstLetter";
import MessageIcon from "features/inventory/assets/icons/MessageIcon";
import RejectStatusConfirmationUser from "../../reject-status-confirmation-user/rejectStatusConfirmationUser";
import RecievedStatusConfirmationUser from "../../recieved-status-confirmation-user/recievedStatusConfirmationUser";

import styles from "../../notification.module.scss";
import BuildingIcon from "features/app/assets/icons/BuildingIcon";
import GitBranchIcon from "features/supply/assets/icons/GitBranchIcon";
import NotificationWorkCreatedReceived from "features/app/assets/icons/NotificationWorkCreatedReceived";
import NotificationWorkCreatedRejected from "features/app/assets/icons/NotificationWorkCreatedRejected";
import CheckCircleIcon from "features/app/assets/icons/CheckCircleIcon";
import CloseCircleIcon from "features/app/assets/icons/CloseCircleIcon";
import { statusContent } from "features/app/utils/constants/statusContent";

type Props = {
  record: NotificationModel;
  onOpenChat: (id: number, type: string) => void;
};

const ConfirmationWorkCreated: React.FC<Props> = ({ record, onOpenChat }) => {
  const { i18n, t } = useTranslation();
  const status = statusContent();

  const id = useAuth().user?.id;

  const confirmationUserStatus = record?.task_progress?.confirmation_users?.find(user => user?.user?.id === id)?.status;

  return (
    <div className={styles.description}>
      <h3>{notificationTypesTitleValue[record.type]}</h3>
      <p>
        <span className={styles.name}>{record?.user?.full_name}</span> "{record?.task_progress?.quantity}{" "}
        {record?.task_progress?.task?.unit?.symbol[i18n.language]}" bajarilgan ishni tasdiqlashingizni so'radi
      </p>
      <div className="flex flex-col">
        <div className={styles.products}>
          <span className={cx(styles.bold, "flex items-center gap-2")}>
            <BuildingIcon /> {record?.task_progress?.project?.name}
          </span>
        </div>
        <div className={styles.products}>
          <span className={cx(styles.bold, "flex items-center gap-2")}>
            <GitBranchIcon /> {record?.task_progress?.task?.name}
          </span>
        </div>
        {confirmationUserStatus !== ConfirmationUserStatusEnum.OPEN && (
          <div className={styles.products}>
            <div className="flex items-center gap-2">
              <span className="flex items-center">
                {status[confirmationUserStatus as keyof ReturnType<typeof statusContent>]?.icon}{" "}
              </span>
              <span
                style={{
                  color: status[confirmationUserStatus as keyof ReturnType<typeof statusContent>].color
                }}
                className="flex items-center text-sm font-medium"
              >
                {status[confirmationUserStatus as keyof ReturnType<typeof statusContent>]?.text}
              </span>
            </div>
          </div>
        )}
      </div>
      <Collapse
        className={styles.collapse}
        items={[
          {
            key: "1",
            label: "Guvohlar",
            children: (
              <>
                {record?.task_progress?.confirmation_users?.map(user => (
                  <div className={styles.collapse__item} key={user.id}>
                    <div className={styles.collapse__item__info}>
                      {user?.user?.image ? (
                        <img
                          src={user?.user?.image}
                          alt={user?.user?.full_name}
                          className={styles.collapse__item__img}
                        />
                      ) : (
                        <span className={styles.collapse__item__img}>{getFirstLetter(user?.user?.full_name)}</span>
                      )}
                      <p>{user?.user?.full_name}</p>
                    </div>
                    <div>{ConfirmationStatusIcons[user?.status]}</div>
                  </div>
                ))}
              </>
            )
          }
        ]}
        expandIconPosition="end"
      />
      <div className={styles.actions}>
        <Button
          className={styles.actions__chat}
          onClick={() => onOpenChat(record?.task_progress?.task?.id, "task_progresses")}
        >
          <MessageIcon />
        </Button>
        {confirmationUserStatus === ConfirmationUserStatusEnum.OPEN && (
          <RejectStatusConfirmationUser users={record?.task_progress?.confirmation_users} />
        )}
        {confirmationUserStatus === ConfirmationUserStatusEnum.OPEN && (
          <RecievedStatusConfirmationUser users={record?.task_progress?.confirmation_users} />
        )}
      </div>
    </div>
  );
};

export default ConfirmationWorkCreated;
