import { Popover } from "antd";
import { useTranslation } from "react-i18next";

import { RU } from "../../../../../app/utils/constants/languages";
import { cx } from "../../../../../app/utils/helpers/cx";
import { DynamicEstimateEnum } from "../../../../utils/enums/dynamicEstimateEnum";
import { diffQuantity } from "../../../../utils/helpers/diffDynamicEstimate";
import { onSetStatusBorderQuantity, onSetStatusQuantity } from "../../../../utils/helpers/onSetStatus";
import { DynamicEstimateProductModel, DynamicEstimateTaskModel } from "../../../../utils/models/dynamicEstimateModel";

import styles from "./diffPopover.module.scss";

type Props = {
  record: DynamicEstimateProductModel | DynamicEstimateTaskModel;
  classNames: string;
  isFeature: boolean;
  isBordered?: boolean;
  taskStatus?: DynamicEstimateEnum;
  getPopoverContainer?: () => HTMLElement;
};

const DiffPopoverQuantity = ({ record, classNames, isFeature, taskStatus, isBordered, getPopoverContainer }: Props) => {
  const { i18n } = useTranslation();

  const currentQuantity =
    record?.quantity > record?.spend_total_quantity ? record?.quantity : record?.spend_total_quantity;

  const setQuantity = () => {
    if (isFeature) {
      if (taskStatus === DynamicEstimateEnum.COMPLETED || taskStatus === DynamicEstimateEnum?.CLOSED) {
        return (
          <p
            className={cx(classNames, styles[onSetStatusQuantity(record?.quantity, record?.spend_total_quantity)], {
              [styles[onSetStatusBorderQuantity(record?.quantity, record?.spend_total_quantity)]]: isBordered
            })}
          >
            {record?.spend_total_quantity?.toLocaleString(RU)}
          </p>
        );
      }
      return (
        <p
          className={cx(classNames, styles[record?.quantity < record?.spend_total_quantity ? "error" : "success"], {
            [styles[record?.quantity < record?.spend_total_quantity ? "error_border" : "success_border"]]: isBordered
          })}
        >
          {currentQuantity?.toLocaleString(RU)}
        </p>
      );
    }
    return (
      <p className={`${classNames} ${styles[onSetStatusQuantity(record?.quantity, record?.spend_total_quantity)]}`}>
        {record?.spend_total_quantity?.toLocaleString(RU)}
      </p>
    );
  };

  const setDiffClassName = () => {
    if (isFeature) {
      if (diffQuantity(record?.quantity, currentQuantity) <= 0) {
        return styles.success_amount;
      }
      return styles.error_amount;
    }
    if (diffQuantity(record?.quantity, record?.spend_total_quantity) <= 0) {
      return styles.success_amount;
    }
    return styles.error_amount;
  };

  const title = (record: DynamicEstimateProductModel | DynamicEstimateTaskModel) => (
    <div className={styles.popover__inner}>
      <div className={styles.popover__inner__item}>
        <p>Plan</p>
        <p>
          {record?.quantity?.toLocaleString(RU) ?? 0} {record?.unit?.symbol[i18n.language]}
        </p>
      </div>
      <div className={styles.popover__inner__item}>
        <p>Fakt</p>
        <p>
          {record?.spend_total_quantity?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
        </p>
      </div>
      {isFeature && (
        <div className={styles.popover__inner__item}>
          <p>Bashorat</p>
          <p>
            {currentQuantity?.toLocaleString(RU)} {record?.unit?.symbol[i18n.language]}
          </p>
        </div>
      )}
      <div className={styles.popover__inner__item}>
        <p>Tafovut</p>
        <p className={setDiffClassName()}>
          {isFeature
            ? (Math.abs(diffQuantity(record?.quantity, currentQuantity))?.toLocaleString(RU) ?? 0)
            : (Math.abs(diffQuantity(record?.quantity, record?.spend_total_quantity))?.toLocaleString(RU) ?? 0)}{" "}
          {record?.unit?.symbol[i18n.language]}
        </p>
      </div>
    </div>
  );

  return (
    <Popover getPopupContainer={getPopoverContainer} title={title(record)} overlayClassName={styles.diff__popover}>
      {setQuantity()}
    </Popover>
  );
};

export default DiffPopoverQuantity;
