import { Modal } from "antd";

import CloseIcon from "../../../../../app/assets/icons/CloseIcon";
import { useLeaveFromProject } from "../../../../service/mutations";

import styles from "./leaveProject.module.scss";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  id?: number;
  name?: string;
};

const LeaveProject = ({ open, setOpen, id, name }: Props) => {
  const leaveFromProject = useLeaveFromProject();
  const { t } = useTranslation();

  const onOk = () => {
    leaveFromProject.mutateAsync({ inventory_id: id }).then(() => onCancel());
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      centered
      onOk={onOk}
      open={open}
      onCancel={onCancel}
      closeIcon={<CloseIcon />}
      width={548}
      okButtonProps={{
        loading: leaveFromProject.isLoading,
      }}
      title="Loyihadan chiqarish"
      cancelText="Ortga"
      okText="Tasdiqlash"
      className={styles.leave_project}
    >
      <div className={styles.name}>
        <p>{t("Inventory.Loyiha")}</p>
        <p>{name}</p>
      </div>
    </Modal>
  );
};

export default LeaveProject;
