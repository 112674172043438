import React from "react";
import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import { queryParamsKeys } from "features/app/utils/constants/queryParamsKeys";
import { PaginationType } from "features/app/utils/models/PaginationType";
import { routeSubmodules } from "features/impost/utils/constants/routeSubmodules";
import { tabKeys } from "features/impost/utils/constants/tabKeys";
import OfferModal from "features/supply/components/offers/offer-modal/OfferModal";
import PartyModal from "features/supply/components/parts/party-modal/PartyModal";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";
import { useRoutePermissions } from "hooks/useRoutePermissions";

import { ConfirmationPaymentView, useWidget } from "modules/dashboard";
import { useConfirmationPaymentCount } from "modules/dashboard/queries/use-confirmation-payment-count";

import IncomeExpense from "./incom-expense/income-expense";
import Offers from "./offers/offers";
import Parts from "./parts/parts";
import Works from "./works/works";

import styles from "./confirmation-payment-count.module.scss";

const ConfirmationPaymentCount: React.FC = () => {
  const { t } = useTranslation();
  const { queries, append } = useQueryParams();
  const { submodules } = useRoutePermissions("Monitoring", routeSubmodules);
  const { tab } = queries();
  const { id, visible, props } = useWidget();

  const { confirmationPaymentView, isLoading } = useConfirmationPaymentCount({ enabled: visible, id });

  const onChangeTabs = (key: string) => {
    append(queryParamsKeys.TAB, key, true);
  };

  const tabDataCount = (text: string, key: keyof Record<tabKeys, number>) => (
    <p className={styles.tab}>
      {text}
      {props?.[key] > 0 && <span className={styles.tab__count}>{props[key]}</span>}
    </p>
  );

  const items = [
    {
      key: tabKeys.PAYMENT,
      label: tabDataCount(t("Monitoring.Chiqim"), tabKeys.PAYMENT),
      children: (
        <IncomeExpense
          data={confirmationPaymentView as PaginationType<ConfirmationPaymentView[]> | undefined}
          isLoading={isLoading}
        />
      ),
      permissionKey: "Kirim-chiqim"
    },
    {
      key: tabKeys.WORK,
      label: tabDataCount(t("Monitoring.Ishlar"), tabKeys.WORK),
      children: <Works data={confirmationPaymentView} isLoading={isLoading} />,
      permissionKey: "Ishlar"
    },
    {
      key: tabKeys.ORDER,
      label: tabDataCount(t("Monitoring.Partiya"), tabKeys.ORDER),
      children: <Parts data={confirmationPaymentView} isLoading={isLoading} />,
      permissionKey: "Partiyalar"
    },

    {
      key: tabKeys.OFFER,
      label: tabDataCount(t("Monitoring.Takliflar"), tabKeys.OFFER),
      children: <Offers isImpost data={confirmationPaymentView} isLoading={isLoading} />,
      permissionKey: "Takliflar"
    }
  ];

  const impostItems = (): TabsProps["items"] => {
    const newItems: TabsProps["items"] = [];

    items.forEach(item => {
      const findItem = submodules?.find(submodule => submodule.key === item.permissionKey);

      if (findItem && findItem?.key) {
        newItems.push(item);
      }
    });

    return newItems;
  };

  return (
    <div className={styles.radar}>
      <div className={styles.tabs}>
        <Tabs activeKey={tab || tabKeys.PAYMENT} items={impostItems()} onChange={onChangeTabs} />
      </div>
      <PartyModal />
      <OfferModal invalidateKey="party" />
    </div>
  );
};

export default ConfirmationPaymentCount;
