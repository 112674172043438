import React from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import CustomAvatar from "features/app/components/custom-avatar/CustomAvatar";
import { useTranslation } from "react-i18next";

import { useQueryParams } from "hooks/useQueryParams";

import { cx, RU } from "modules/common";
import { ProjectByResourceDetailViewData } from "modules/dashboard";

import { LoadingIndicator } from "components";

import styles from "./bottom.module.scss";

type Props = {
  data: ProjectByResourceDetailViewData[];
  isLoading: boolean;
};
const Bottom: React.FC<Props> = ({ isLoading, data }) => {
  const { t } = useTranslation();
  const { queries } = useQueryParams();
  const { tab } = queries();

  const getLastColumn = () => {
    if (tab?.split("-")[1] === "person") {
      return {
        title: t("dashboard.Bajardi"),
        render: (record: ProjectByResourceDetailViewData) => <CustomAvatar name={record?.company_person?.full_name} />
      };
    }
    if (tab?.split("-")[1] === "mechanism") {
      return {
        title: t("dashboard.Inventar va Kontragent"),
        render: (record: ProjectByResourceDetailViewData) => (
          <div className="flex items-center justify-center gap-2">
            <span className="text-sm font-medium text-gray-900">{record?.inventory?.name}</span>
            <CustomAvatar name={record?.inventory?.company_person?.full_name} />
          </div>
        )
      };
    }
    return {
      title: t("dashboard.Omborxona"),
      render: (record: ProjectByResourceDetailViewData) => record?.warehouse?.name
    };
  };

  const columns: ColumnsType<ProjectByResourceDetailViewData> = [
    {
      title: "№",
      render: (record: ProjectByResourceDetailViewData) => record?.id
    },
    {
      title: t("dashboard.Miqdori"),
      render: (__: number, record: ProjectByResourceDetailViewData, index: number) => index + 1
    },
    {
      title: t("dashboard.Summa"),
      render: (record: ProjectByResourceDetailViewData) => (
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-gray-600">{record?.quantity?.toLocaleString(RU)}</span>
          <span className="text-sm font-medium text-gray-400">{record?.currency?.symbol}</span>
        </div>
      )
    },
    {
      title: t("dashboard.Sana"),
      dataIndex: "date"
    },
    {
      ...getLastColumn()
    }
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      scroll={{ y: 400 }}
      rootClassName={cx(styles.table)}
      loading={{
        spinning: isLoading,
        indicator: LoadingIndicator
      }}
    />
  );
};

export default Bottom;
