import React from "react";
import { Form, FormInstance, Input } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  index: number;
  form: FormInstance;
  rowId: number;
};

const { Item } = Form;

const ProductListCode: React.FC<Props> = ({ index, form, rowId }) => {
  const fieldName = ["products", index, "code"];
  const { t } = useTranslation()

  return (
    <Item name={fieldName}>
      <Input step="0.01" disabled={typeof rowId === "number"} placeholder={t("Counterparts.Mahsulot kodi")} autoComplete="off" />
    </Item>
  );
};

export default ProductListCode;
