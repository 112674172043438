import { FileType } from "../enums/fileType";

export const downloadFile = (data: Blob | string, name: string, type?: FileType) => {
  let url = "";

  if (typeof data !== "string") {
    url = window.URL.createObjectURL(data);
  } else {
    url = data;
  }
  
  const a = document.createElement("a");

  a.href = url;
  a.target = "_blank";
  a.setAttribute("download", `${name}.${type || "docx"}`);
  document.body.appendChild(a);
  a.click();
  a.remove();
};
