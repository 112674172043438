import React from "react";

const EmptyOfferHistoryIcon = () => {
  return (
    <svg
      width="57"
      height="56"
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#D1E9FF" />
      <rect
        x="4.5"
        y="4"
        width="48"
        height="48"
        rx="24"
        stroke="#F5FAFF"
        strokeWidth="8"
      />
      <path
        d="M34 22.5L23 33.5M25 26.5V22.5M23 24.5H27M30 31.5H34M24.3 37H32.7C34.3802 37 35.2202 37 35.862 36.673C36.4265 36.3854 36.8854 35.9265 37.173 35.362C37.5 34.7202 37.5 33.8802 37.5 32.2V23.8C37.5 22.1198 37.5 21.2798 37.173 20.638C36.8854 20.0735 36.4265 19.6146 35.862 19.327C35.2202 19 34.3802 19 32.7 19H24.3C22.6198 19 21.7798 19 21.138 19.327C20.5735 19.6146 20.1146 20.0735 19.827 20.638C19.5 21.2798 19.5 22.1198 19.5 23.8V32.2C19.5 33.8802 19.5 34.7202 19.827 35.362C20.1146 35.9265 20.5735 36.3854 21.138 36.673C21.7798 37 22.6198 37 24.3 37Z"
        stroke="#1E90FF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmptyOfferHistoryIcon;
