import React from "react";
import { useTranslation } from "react-i18next";

import { useGetLanguages } from "../../../app/service/queries";

import styles from "./index.module.scss";

const Index: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { data } = useGetLanguages();

  // onchange locale
  const onChangeLang = (locale: string) => {
    i18n.changeLanguage(locale);
    window.localStorage.setItem("locale", locale);
  };

  // active language
  const activeLang = (locale: string) => {
    if (locale === i18n.language) {
      return styles.active;
    } else return "";
  };

  return (
    <div className={styles.interface}>
      <div className={styles.interface__top}>
        <h2>{t("settings.interface.Interfeys sozlamalari")}</h2>
        <p>
          {t("settings.interface.Bu erda siz interfeysni o'zgartirishingiz mumkin")}
        </p>
      </div>
      <div className={styles.interface__bottom}>
        <div className={styles.left}>
          <h4>{t("settings.interface.Til o’zgartirish")}</h4>
          <p>
            {t(
              "settings.interface.Agar siz tilni o'zgartirsangiz, u butun interfeysingizdagi tillarni o'zgartiradi."
            )}
          </p>
        </div>
        <div className={styles.right}>
          {data?.map((item) => (
            <div
              className={`${activeLang(item?.symbol)} ${styles.flag}`}
              onClick={() => onChangeLang(item?.symbol)}
            >
              <img src={item?.icon} alt={item?.name} />
              <span>{item?.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Index;
