import { Button } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import SettingsRolesTable from "./table/SettingsRolesTable";

import styles from "./settingsRoles.module.scss";

const SettingsRoles: React.FC = () => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState<{
    visible: boolean;
    isView?: boolean;
    id?: number;
  }>({ visible: false });

  // open modal
  const onOpenModal = () => {
    setModalData({
      visible: true,
      isView: false,
    });
  };

  return (
    <div className={styles.settings_roles}>
      <div className={styles.settings_roles__top}>
        <h2>{t("Xodimlar.Lavozimlar")}</h2>
        <Button onClick={onOpenModal}>+ {t("Xodimlar.Lavozim qo'shish")}</Button>
      </div>
      <div className={styles.settings_roles__bottom}>
        <SettingsRolesTable setModalData={setModalData} modalData={modalData} />
      </div>
    </div>
  );
};

export default SettingsRoles;
