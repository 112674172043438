import AttachToProject from "./attach-to-project/AttachToProject";
import Bottom from "./bottom/Bottom";
import InventoryCreate from "./inventory-create/InventoryCreate";
import InventoryView from "./inventory-view/InventoryView";

const Inventories = () => (
  <div>
    {/* <Top /> */}
    <Bottom />
    <InventoryCreate />
    <AttachToProject />
    <InventoryView />
  </div>
);

export default Inventories;
