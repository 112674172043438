import { Button } from "antd";

import { useAuth } from "modules/auth/hooks";

import { ConfirmationStatusEnum } from "../../../../inventory/utils/enums/confirmationStatusEnum";
import { useChangeStatusConfirmationUser } from "../../../service/mutation";
import { ConfirmationUserModel } from "../../../utils/models/confirmationUser";

import styles from "../notification.module.scss";

type Props = {
  users: ConfirmationUserModel[];
};

const RecievedStatusConfirmationUser = ({ users }: Props) => {
  const userId = useAuth().user?.id;
  const changeStatusConfirmationUser = useChangeStatusConfirmationUser();

  const onRejectedConfirmationUser = () => {
    let objectId = 0;

    users.forEach(user => {
      if (userId === user?.user?.id) {
        objectId = user.id;
      }
    });

    if (objectId > 0) {
      changeStatusConfirmationUser.mutate({
        id: objectId,
        status: ConfirmationStatusEnum.RECIEVED
      });
    }
  };

  return (
    <Button
      className={styles.actions__reject}
      onClick={onRejectedConfirmationUser}
      type="primary"
      loading={changeStatusConfirmationUser.isLoading}
      disabled={users?.filter(user => user.user.id === userId).length === 0}
    >
      Tasdiqlash
    </Button>
  );
};

export default RecievedStatusConfirmationUser;
