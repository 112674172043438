import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { Badge, Dropdown, Table, TableProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { ItemType } from "antd/lib/menu/interface";
import classNames from "classnames";
import DeleteIcon from "features/app/assets/icons/DeleteIcon";
import { PaymentSourceElement } from "features/payment/components/bottom/income-expense-view/PaymentSourceElement";
import { useDeleteDeletedArchive } from "features/payment/service/mutation";
import { routeSubmodules } from "features/payment/utils/constants/routeSubmodules";
import { useTranslation } from "react-i18next";

import { useRoutePermissions } from "hooks/useRoutePermissions";

import { RU } from "../../../../../../modules/common";
import { chatActions } from "../../../../../../store/reducers/chatReducer";
import { paymentReducerActions } from "../../../../../../store/reducers/paymentReducer";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import CustomAvatar from "../../../../../app/components/custom-avatar/CustomAvatar";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import Pagination from "../../../../../app/components/pagination/Pagination";
import TableEmpty from "../../../../../app/components/table-empty/TableEmpty";
import { PaymentTypeEnum } from "../../../../../app/utils/constants/paymentTypeEnum";
import MessageIcon from "../../../../../inventory/assets/icons/MessageIcon";
import EyeIcon from "../../../../../projects/assets/icons/EyeIcon";
import { useGetIncomeExpenseDelete } from "../../../../service/queries";
import { paymentQueryKeys } from "../../../../utils/constants/paymentQueryKeys";
import { PaymentTypeIcon } from "../../../../utils/helpers/paymentTypeIcon";
import { PaymentDeleteArchive } from "../../../../utils/models/paymentDeleteArchive";

import styles from "../deleteArchive.module.scss";

const IncomeExpenseTable = () => {
  const qc = useQueryClient();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const { setVisible: setVisibleChat } = chatActions;
  const { setIncomeOrExpenseViewVisible, setConversionInfoModal, setTransferHistoryModal } = paymentReducerActions;

  const { data: incomeExpenseDeleteArchive, isLoading } = useGetIncomeExpenseDelete(page, true);
  const { actions } = useRoutePermissions("Kassa", routeSubmodules);
  const incomeOrExpenseActions = actions("Kirim-chiqim");

  const deleteMutation = useDeleteDeletedArchive();

  const onDelete = (id: number) => deleteMutation.mutate({ id });

  const actionItems = (record: PaymentDeleteArchive) =>
    [
      {
        key: "1",
        label: (
          <div className="flex items-center gap-2">
            <EyeIcon />
            {t("payment.Ko'rish")}
          </div>
        ),
        onClick: e => {
          e.domEvent.stopPropagation();

          dispatch(
            setIncomeOrExpenseViewVisible({
              visible: true,
              record: record as never,
              isNotDelete: true,
              isDeleteArchive: true
            })
          );
        },
        isView: true,
        permissionName: ""
      },
      {
        key: "2",
        label: (
          <>
            <MessageIcon /> {t("payment.Izohlar")} <Badge count={record?.unread_message_count} />
          </>
        ),
        className: styles.dropdown_item,
        onClick: e => {
          e.domEvent.stopPropagation();

          dispatch(
            setVisibleChat({
              visible: true,
              type: "payments",
              objectId: record?.id,
              onAfterOpen: () => {
                qc.invalidateQueries([paymentQueryKeys.INCOME_EXPENSE_DELETE_ARCHIVE, page]).then();
              }
            })
          );
        },
        isView: true,
        permissionName: ""
      },
      {
        key: "3",
        label: (
          <div className="flex items-center gap-2">
            <DeleteIcon />
            {t("payment.O'chirish")}
          </div>
        ),
        className: styles.dropdown_item,
        permissionName: "deleteDeletedArchive",
        isView: true,
        onClick: e => {
          e.domEvent.stopPropagation();
          onDelete(record?.id);
        }
      }
    ] as (ItemType & { permissionName: string; isView: boolean })[];

  const dropdownRender = (originNode: ReactNode) => (
    <div
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {originNode}
    </div>
  );

  const columns: ColumnsType<PaymentDeleteArchive> = [
    {
      title: t("payment.Turi"),
      render: (record: PaymentDeleteArchive) => PaymentTypeIcon(record.type, record?.status, t),
      width: 80
    },
    {
      title: t("payment.O’chirilgan sana"),
      render: (record: PaymentDeleteArchive) => record?.deleted_date
    },
    {
      title: t("payment.Summa"),
      render: record => (
        <>
          {record?.amount?.toLocaleString(RU)} {record?.currency?.symbol}
        </>
      )
    },
    {
      title: t("payment.To’lov turi"),
      render: (record: PaymentDeleteArchive) => (
        <div
          className={styles.payment_type}
          style={{
            color: record?.payment_type?.color,
            background: `${record?.payment_type?.color}20`
          }}
        >
          {record?.payment_type?.name}
        </div>
      )
    },
    {
      title: t("payment.Kassa"),
      render: (record: PaymentDeleteArchive) => record?.cash?.name
    },
    {
      title: t("payment.Loyiha"),
      render: (record: PaymentDeleteArchive) => record?.project?.name
    },
    {
      title: t("payment.Manbaa"),
      render: (record: PaymentDeleteArchive) => <PaymentSourceElement record={record as never} />
    },
    {
      title: t("payment.Xodim"),
      render: (record: PaymentDeleteArchive) => (
        <div className={styles.actions}>
          <CustomAvatar image={record.creator.image} name={record?.creator?.full_name} />
        </div>
      ),
      width: 100
    },
    {
      title: "",
      render: (record: PaymentDeleteArchive) => (
        <Dropdown
          trigger={["click"]}
          placement="bottom"
          dropdownRender={dropdownRender}
          menu={{
            items: actionItems(record)?.filter(el => {
              if (el?.permissionName !== "")
                return el?.isView && incomeOrExpenseActions[el?.permissionName as keyof typeof incomeOrExpenseActions];
              return el?.isView;
            }) as ItemType[]
          }}
        >
          <div
            onClick={e => e.stopPropagation()}
            className={classNames("d_f ai_c jc_c c_p", {
              hasBadge: !!record?.unread_message_count
            })}
          >
            <DotsVerticalIcon />
          </div>
        </Dropdown>
      ),
      width: 70
    }
  ];

  const onRow: TableProps["onRow"] = (record: PaymentDeleteArchive) => ({
    onClick: () => {
      if (record?.type === PaymentTypeEnum.EXCHANGE) {
        dispatch(setConversionInfoModal({ ...record, isDeleted: true } as never));
      } else if (record?.type === PaymentTypeEnum.TRANSFER) {
        dispatch(setTransferHistoryModal({ ...record, isDeleted: true } as never));
      } else {
        dispatch(
          setIncomeOrExpenseViewVisible({
            visible: true,
            record: record as never,
            isNotDelete: true,
            isDeleteArchive: true
          })
        );
      }
    }
  });

  return (
    <>
      <Table<PaymentDeleteArchive>
        onRow={onRow}
        columns={columns}
        pagination={false}
        rowKey={record => record.id}
        rowClassName="cursor-pointer"
        dataSource={incomeExpenseDeleteArchive?.data}
        loading={{
          spinning: isLoading,
          indicator: LoadingIndicator
        }}
        locale={{
          emptyText: <TableEmpty />
        }}
        className={styles.table}
      />
      <div className={styles.pagination}>
        <Pagination
          paginationProps={{
            pageSize: 10,
            total: incomeExpenseDeleteArchive?.total,
            current: incomeExpenseDeleteArchive?.current_page,
            onChange: page => {
              setPage(page);
            }
          }}
        />
      </div>
    </>
  );
};

export default IncomeExpenseTable;
