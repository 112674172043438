import React from "react";
import { useDispatch } from "react-redux";
import { FormInstance } from "antd";
import { DataNode } from "antd/es/tree";

import { settingsActions } from "../../../../../store/reducers/settingsReducer";
import PlusCircleIcon from "../../../../app/assets/icons/PlusCircleIcon";
import SelectNotContent from "../../../../app/components/select-not-content/SelectNotContent";
import { TreeSelect } from "../../../../app/components/tree-select/TreeSelect";
import { ProjectFormFieldsModel } from "../../../utils/models/projectFormFieldsModel";

import styles from "../projectModal.module.scss";
import { useTranslation } from "react-i18next";

export interface Props {
  formInstance: FormInstance<ProjectFormFieldsModel>;
  data: DataNode[];
  initialData: DataNode[] | undefined;
  className: string;
}

const WareHouseTreeSelect: React.FC<Props> = ({ className, formInstance, data, initialData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setWarehouseModal } = settingsActions;

  const onOpenWareHouseCreateModal = () => {
    dispatch(
      setWarehouseModal({
        visible: true
      })
    );
  };

  const addBtn = (
    <div className={styles.add_project} onClick={onOpenWareHouseCreateModal}>
      <PlusCircleIcon fill /> {t("project.Omborxona yaratish")}
    </div>
  );

  return (
    <TreeSelect
      data={data}
      addBtn={addBtn}
      name="warehouse_ids"
      className={className}
      initialData={initialData}
      formInstance={formInstance}
      title={t("project.Barcha omborxonalar")}
      notFoundContent={
        <SelectNotContent
          description="Omborxona yo'q, iltimos yangi qo'shing"
          action={onOpenWareHouseCreateModal}
          title="Omborxona"
        />
      }
    />
  );
};

export default WareHouseTreeSelect;
