import { notificationTypesTitleValue } from "../../../../utils/constants/notificationTypes";
import { NotificationModel } from "../../../../utils/models/notificationModel";
import { useTranslation } from "react-i18next";

import styles from "../../notification.module.scss";
import { RU } from "features/app/utils/constants/languages";

type Props = {
  record: NotificationModel;
  onNavigatePage: (url: string, record: NotificationModel) => void;
};

const ConfirmationOrderPending = ({ record, onNavigatePage }: Props) => {
  const { t } = useTranslation();
  return (
    <div
      onClick={() => onNavigatePage("/impost?tab=order", record)}
      className={styles.description}
    >
      <h3>{notificationTypesTitleValue[record?.type]}</h3>
      <p>
        {record?.user?.full_name} {record?.confirmation_order?.amount?.toLocaleString(RU)}{" "}
        {record?.confirmation_order?.currency?.symbol}{" "}
        <span className={styles.name}>P - {record?.confirmation_order?.id}</span>{" "}
        {t("login.partiyani tasdiqlashinigizni so'radi")}.
      </p>
    </div>
  );
};

export default ConfirmationOrderPending;
