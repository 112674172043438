import React from "react";
import { useDispatch } from "react-redux";
import { Dropdown, MenuProps, Popover, Spin } from "antd";
import { useTranslation } from "react-i18next";

import { settingsActions } from "../../../../../../store/reducers/settingsReducer";
import DeleteIcon from "../../../../../app/assets/icons/DeleteIcon";
import DotsVerticalIcon from "../../../../../app/assets/icons/DotsVerticalIcon";
import EditIcon from "../../../../../app/assets/icons/EditIcon";
import { LoadingIndicator } from "../../../../../app/components/loading-indicator/LoadingIndicator";
import NotUserImage from "../../../../../app/components/not-image/NotUserImage";
import { useDeleteWarehouse } from "../../../../service/mutations";
import { WarehouseModel } from "../../../../utils/models/warehouse/warehouseModel";

import styles from "./settingsWarehouseCard.module.scss";

const SettingsWarehouseCard: React.FC<WarehouseModel> = ({ id, name, users }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setWarehouseModal } = settingsActions;
  const deleteWarehouse = useDeleteWarehouse();

  const onOpenModal = () => {
    dispatch(
      setWarehouseModal({
        visible: true,
        data: {
          id,
          name,
          users
        }
      })
    );
  };

  const onDelete = () => {
    deleteWarehouse.mutate(Number(id));
  };

  // menu items
  const items: MenuProps["items"] = [
    {
      key: "2",
      label: (
        <div className={styles.update}>
          <EditIcon /> <span>{t("products.Tahrirlash")}</span>
        </div>
      ),
      onClick: onOpenModal
    },
    {
      key: "3",
      label: (
        <div className={styles.delete} onClick={onDelete}>
          <DeleteIcon />
          <span>{t("products.O'chirish")}</span>
        </div>
      )
    }
  ];

  const usersContent = (
    <div className={styles.user_popover}>
      {users?.map(user => (
        <div key={user?.id} className={styles.user}>
          {user?.image ? (
            <img src={user?.image} alt={user?.full_name} />
          ) : (
            <NotUserImage name={user?.full_name ? user?.full_name : name} width={24} />
          )}{" "}
          <p>{user?.full_name}</p>
        </div>
      ))}
    </div>
  );

  return (
    <Spin spinning={deleteWarehouse.isLoading} indicator={LoadingIndicator}>
      <div className={styles.card}>
        <div className={styles.top}>
          <Popover content={usersContent} overlayClassName={styles.popover}>
            <div className={styles.images}>
              {users?.length > 0 &&
                users?.map((user, index) =>
                  user?.image ? (
                    <img
                      key={user?.id}
                      src={user.image}
                      alt={user.full_name}
                      style={{
                        left: index * 20
                      }}
                    />
                  ) : (
                    <span
                      key={user?.id}
                      style={{
                        left: index * 20
                      }}
                    >
                      <NotUserImage name={user?.full_name} />
                    </span>
                  )
                )}
            </div>
          </Popover>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <span>
              <DotsVerticalIcon />
            </span>
          </Dropdown>
        </div>
        <h4 className={styles.bottom}>{name}</h4>
      </div>
    </Spin>
  );
};

export default SettingsWarehouseCard;
