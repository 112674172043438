export const dayjsFormats = {
  DATE: "DD.MM.YYYY",
  TIME: "HH:mm",
  DATE_NAME: "dddd",
  DATE_TIME: "DD.MM.YYYY HH:mm",
  MONTH_DATE: "MM.YYYY",
  MONTH_NAME: "MMM",
  KEBAB_DATE: "YYYY-MM-DD",
  DATE_WITH_MONTH_NAME: "D-MMMM YYYY",
  DATE_WITH_SHORT_MONTH_NAME: "MMM D"
} as const;
