import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { Button, Spin } from "antd";

import { LoadingIndicator } from "../../../app/components/loading-indicator/LoadingIndicator";
import { useGetDetailedCashFolder } from "../../service/queries";
import ArrowNarrowIcon from "../../../app/assets/icons/ArrowNarrowIcon";
import SettingsCashCard from "../../components/cash/card/SettingsCashCard";
import PlusCircleIcon from "../../../app/assets/icons/PlusCircleIcon";
import { SettingsCashModal } from "../../components/cash/modal/SettingsCashModal";

import styles from "./index.module.scss";
import { parseParamsId } from "../../../app/utils/helpers/parseParamsId";

const DetailedCash = () => {
  const params = useParams();
  const { id } = parseParamsId(params?.id);
  const navigate = useNavigate();
  const { data, isLoading } = useGetDetailedCashFolder(id as string);
  const [modalData, setModalData] = useState<{
    visible: boolean;
    id?: number;
  }>({
    visible: false,
  });

  const onBack = () => {
    navigate(-1);
  };

  const onOpenModal = () => {
    setModalData({
      visible: true,
    });
  };

  return (
    <Spin spinning={isLoading} indicator={LoadingIndicator}>
      <div className={styles.detailed_cash}>
        <div className={styles.detailed_cash__top}>
          <Button onClick={onBack}>
            <ArrowNarrowIcon />
          </Button>
          <h2>{data?.name}</h2>
        </div>
        <div className={styles.detailed_cash__bottom}>
          {data?.cashs?.map((item, index) => (
            <SettingsCashCard {...item} key={index} setModalData={setModalData} />
          ))}
          <div className={styles.card_add} onClick={onOpenModal}>
            <PlusCircleIcon fill={false} />
          </div>
        </div>
        <SettingsCashModal
          data={modalData}
          setData={setModalData}
          folderId={Number(id)}
        />
      </div>
    </Spin>
  );
};

export default DetailedCash;
