import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer, Form } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../../../hooks/redux";
import { paymentReducerActions } from "../../../../../store/reducers/paymentReducer";
import { colors } from "../../../../app/utils/constants/colors";
import { dayjsFormats } from "../../../../app/utils/constants/dayjsFormats";
import { cx } from "../../../../app/utils/helpers/cx";
import { parseLocaledString } from "../../../../app/utils/helpers/parseLocaledString";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import SwitchCircleHorizontalIcon from "../../../assets/icons/SwitchCircleHorizontalIcon";
import { useCreateTransfer } from "../../../service/mutation";
import { TransferBodyModel } from "../../../utils/models/transferBodyModel";

import Bottom from "./bottom/bottom";
import Top from "./top/top";

import styles from "./transfer.module.scss";

const { useForm } = Form;

const Transfer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm<TransferBodyModel>();
  const { setTransfer } = paymentReducerActions;
  const [isRotate, setIsRotate] = useState(false);
  const { visible } = useAppSelector(state => state.paymentReducer?.transfer);

  const createTransfer = useCreateTransfer();

  const onCancel = () => {
    dispatch(
      setTransfer({
        visible: false
      })
    );
  };

  const onAfterClose = (open: boolean) => {
    if (!open) {
      form.resetFields();
      setIsRotate(false);
    }
  };

  const onFinish = (values: TransferBodyModel) => {
    const reqData: any = {
      file_ids: values.file_ids,
      description: values.description,
      date: values?.date ? dayjs(values.date).format(dayjsFormats.DATE) : undefined,
      expense_payment: {
        cash_id: values?.expense_payment?.cash_id,
        currency_id: values?.expense_payment?.currency_id,
        payment_type_id: values?.expense_payment?.payment_type_id,
        amount: parseLocaledString(values?.expense_payment?.amount),
        projects: values?.expense_payment?.projects?.map(item => ({
          id: item.id,
          amount: parseLocaledString(item?.amount)
        })),
        financials: values?.expense_payment?.financials?.map(item => ({
          id: item.id,
          amount: parseLocaledString(item?.amount)
        }))
      },
      income_payment: {
        cash_id: values?.income_payment?.cash_id,
        currency_id: values?.income_payment?.currency_id,
        payment_type_id: values?.income_payment?.payment_type_id,
        amount: parseLocaledString(values?.income_payment?.amount),
        projects: values?.income_payment?.projects?.map(item => ({
          id: item.id,
          amount: parseLocaledString(item?.amount)
        }))
      }
    };

    createTransfer.mutateAsync(reqData).then(onCancel);
  };

  const onOk = () => {
    form.submit();
  };

  const drawerFooter = (
    <div className={styles.footer}>
      <Button onClick={onCancel}>{t("payment.Yopish")}</Button>
      <Button loading={createTransfer.isLoading} onClick={onOk} type="primary">
        {t("payment.O'tkazish")}
      </Button>
    </div>
  );

  return (
    <Drawer
      open={visible}
      footer={drawerFooter}
      closeIcon={
        <div onClick={onCancel} className="flex h-max w-max items-center justify-center">
          <XCloseIcon width={20} color={colors.GRAY_400} />
        </div>
      }
      afterOpenChange={onAfterClose}
      rootClassName={styles.transfer}
      className={styles.transfer_drawer}
      title={t("payment.O'tkazma qilish")}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div
          className={cx(styles.content, {
            [styles.content__rotate]: isRotate
          })}
        >
          <Top visible={visible} formInstance={form} />
          <div className={styles.icon} onClick={() => setIsRotate(!isRotate)}>
            <SwitchCircleHorizontalIcon />
          </div>
          <Bottom formInstance={form} />
        </div>
      </Form>
    </Drawer>
  );
};

export default Transfer;
