import React from "react";
import { useDispatch } from "react-redux";
import { Button, Drawer } from "antd";

import { useAppSelector } from "../../../../../hooks/redux";
import { supplyActions } from "../../../../../store/reducers/supplyReducer";
import ConditionalRender from "../../../../app/components/conditional-render/ConditionalRender";
import EmptyEstimate from "../../../../app/components/empty-estimate/EmptyEstimate";
import { isEmptyArr } from "../../../../app/utils/helpers/isEmptyArr";
import XCloseIcon from "../../../../warehouse/assets/icons/XCloseIcon";
import { useGetSupplyProjects } from "../../../service/queries";
import EstimateTabs from "../../estimate-tabs/EstimateTabs";

import styles from "./orderEstimate.module.scss";

type Props = {
  importAction: () => void;
  clearAction: () => void;
};

const OrderEstimate: React.FC<Props> = ({ clearAction, importAction }) => {
  const dispatch = useDispatch();
  const { visible } = useAppSelector(state => state.supplyReducer.orderEstimate);
  const { setEstimateData, setOrderEstimate } = supplyActions;
  const { data: projects } = useGetSupplyProjects(visible);

  const { products } = useAppSelector(state => state.supplyReducer.estimateData);

  const onOpenAfter = (open: boolean) => {
    if (!open) {
      dispatch(
        setEstimateData({
          key: "sectionIds",
          data: []
        })
      );

      dispatch(
        setEstimateData({
          key: "taskIds",
          data: []
        })
      );

      dispatch(
        setEstimateData({
          key: "products",
          data: []
        })
      );
    }
  };

  const onClear = () => {
    dispatch(
      setEstimateData({
        key: "sectionIds",
        data: []
      })
    );

    dispatch(
      setEstimateData({
        key: "taskIds",
        data: []
      })
    );

    dispatch(
      setEstimateData({
        key: "products",
        data: []
      })
    );

    clearAction();
  };

  const onClose = () => {
    dispatch(setOrderEstimate({ visible: false }));
  };

  const onImportData = () => {
    importAction();
    onClose();
  };

  const footer = () => (
    <div className={styles.footer}>
      <div className={styles.footer__left}>
        <h4>Tanlangan mahsulotlar: {products?.length ?? 0} ta</h4>
      </div>
      <div className={styles.footer__right}>
        <Button onClick={onClose}>Yopish</Button>
        <Button className={styles.footer__clear} disabled={!(products?.length > 0)} onClick={onClear}>
          <XCloseIcon /> Qo’shilganlarni bekor qilish
        </Button>
        <Button type="primary" disabled={!(products?.length > 0)} onClick={onImportData}>
          Qo'shish
        </Button>
      </div>
    </div>
  );

  return (
    <Drawer
      afterOpenChange={onOpenAfter}
      open={visible}
      className={styles.estimate}
      onClose={onClose}
      footer={footer()}
      title={null}
      width="80rem"
    >
      <ConditionalRender if={isEmptyArr(projects)} else={<EstimateTabs projects={projects} />}>
        <EmptyEstimate />
      </ConditionalRender>
    </Drawer>
  );
};

export default OrderEstimate;
